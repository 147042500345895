import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import DJHPagination from 'components/shared/Pagination';
// constants
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import { getShorterLinkPageUrl } from 'constant/AppUrls';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// actions
import { getShorterLinksData } from 'store/shorterLink/ShorterLinkActions';
// seletors
import { getShorterLinksListData } from 'store/shorterLink/ShorterLinkSelectors';

const ShorterLinkDataGrid = () => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const shorterLinksData = useSelector((state) => getShorterLinksListData({ state }));
  const [params, setParams] = useState({ page: 1 });
  const ref = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location?.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(getShorterLinksData(queryObj));
      setParams(queryObj);
    } else {
      dispatch(getShorterLinksData(params));
    }
  }, []);

  // custom columns
  const finalLinkTemplate = (rowData) => {
    return (
      <a href={rowData.finialLink} target="_blank" rel="noreferrer">
        {rowData.finialLink}
      </a>
    );
  };

  const targetLinkTemplate = (rowData) => {
    return (
      <a href={rowData.finialLink} target="_blank" rel="noreferrer">
        {rowData.originalUrl}
      </a>
    );
  };

  const createdDateTemplate = (rowData) => <span>{rowData?.createdDate?.slice(0, 9)}</span>;

  const onPageChange = (i) => {
    const updatedParams = { page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getShorterLinkPageUrl(),
      search: queryString,
    });
    dispatch(getShorterLinksData(updatedParams));
    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      <div className="control-pane">
        <div className="control-section">
          <GridComponent
            dataSource={shorterLinksData?.data}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            ref={ref}
            toolbarClick={toolbarClick}
            showColumnChooser={true}
            rowHeight={60}
            allowTextWrap={true}
          >
            <ColumnsDirective>
              <ColumnDirective headerText="ID" field="id" width={50} textAlign="Center" />
              <ColumnDirective headerText="Token" field="token" width={80} textAlign="Center" />
              <ColumnDirective headerText="Views" field="viwes" width={50} textAlign="Center" />
              <ColumnDirective
                headerText="Created at"
                field="createdDate"
                template={createdDateTemplate}
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Short Link"
                field="finialLink"
                template={finalLinkTemplate}
                width={80}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Target Link"
                field="finialLink"
                template={targetLinkTemplate}
                width={100}
                textAlign="Center"
              />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
          </GridComponent>
        </div>
      </div>
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+shorterLinksData?.currentPage}
        pageSize={+shorterLinksData?.pageSize}
        itemsCount={+shorterLinksData?.rowCount}
      />
    </>
  );
};

export default React.memo(ShorterLinkDataGrid);
