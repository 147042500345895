const HOME = '/home';
const CREATE_ORDER = '/orders/create';
const ORDER_QUICK_ACTIONS = '/orders/quickactions';
const ORDER_QUICK_PRINTING = '/orders/quickPrinting';
const ORDER_PREMIUM_QUICK_ACTIONS = '/orders/premiumQuickactions';
const LOGIN = '/login';
const VIEW_ORDER = '/order/:id';
const ORDERED_ITEMS = '/orders/ordereditems';
const DRIVERS = '/delivery/drivers';
const ADMIN_PAGES = '/admin/pages';
const ADMIN_ADD_PAGE = '/admin/pages/add';
const ADMIN_EDIT_PAGE = '/admin/pages/:id';
const ADMIN_GROUPS = '/admin/groups';
const ADMIN_EDIT_GROUP = '/admin/groups/:id';
// users
const USERS = '/admin/users';
const USER_INFO = '/admin/users/:id';
const EDIT_USER = 'admin/users/edit/:id';
const USER_DEFAULT_ADDRESSES = '/admin/default-addresses';
const USERS_ACTIVITY = '/admin/UsersActivity';
const USERS_PICKERS = '/users/pickers';
const USERS_PACKERS = '/users/packers';
const USERS_FLORISTS = '/users/florists';
const EDIT_ITEMS = '/editInvoice/:id';
const ADMIN_MESSAGE = '/admin/message';
const ADMIN_NOTIFICATIONS_MESSAGES = '/admin/notificationsmessages';
const NOTIFICATIONS = '/notifications';
const ORDER_NOTIFICATIONS = '/notifications/order/:id';
const ORDERS_ZONES = '/orderszones';
// products
const EDIT_PRODUCT_PAGE = '/product/editProduct/:id/:tabVal';
const SUBSCRIPTIONS_PAGE = '/subscriptions';
const DELIVERY_SLOTS_PAGE = '/delivery/deliveryslots';
const QUICK_ORDERS_PAGE = '/QuickOrders';
// ticketing
const TOPICS_PAGE = '/ticketing/topics';
const TICKETS_PAGE = '/ticketing/tickets';
const MY_TICKETS_PAGE = '/ticketing/mytickets';
const MY_OPENED_TICKETS_PAGE = '/ticketing/myopenedtickets';
const MY_CLOSED_TICKETS_PAGE = '/ticketing/myclosedtickets';
const OPENED_TICKETS_PAGE = '/ticketing/openedtickets';
const CLOSED_TICKETS_PAGE = '/ticketing/closedtickets';
const TICKET_COMMENTS_PAGE = '/ticketing/ticket/:id';
const TECH_TICKETS_PAGE = '/ticketing/techtickets';
const OPENED_TECH_TICKETS_PAGE = '/ticketing/openedtechtickets';
const CLOSED_TECH_TICKETS_PAGE = '/ticketing/closedtechtickets';
//featured occasion
const FEATURED_OCCASION = '/featuredOccasions';
const NEW_FEATURED_OCCASION_PAGE = '/featuredOccasions/new';
const EDIT_FEATUREDOCCASION_PAGE = '/featuredOccasions/:id/edit';
// trip
const TRIP_DRIVERS_LIST_PAGE = '/trip/drivers';
// reports
// products
const PRODUCT_PRICE_BREAKDOWN = '/report/ProductPrice';
const PRODUCT_BREAKDOWN = '/report/ProductAnyaltic';
const CATEGORY_BREAKDOWN = '/report/CategoryBreakDown';
const PRODUCT_TYPE_SALES = '/report/ProductTypeSales';
const PRODUCT_TYPE_ATTRIBUTE = '/report/ProductTypeAttribute';
const LIVE_PRODUCTS_REPORT = '/report/LiveProducts';
const CART_PRODUCTS_DISCOUNTS = '/report/CartProductsDiscount';
const PRODUCT_TYPE_BREAKDOWN = '/report/ProductTypeBreakDown';
const PRODUCT_CATEGORY_PERCENTAGE = '/report/ProductCategoryPerventage';
const PRODUCT_WISE_PAGE = '/report/productWise';
const TOP_TEN_PRODUCTS = '/report/topTenProducts';
const OLD_PRODUCTS = '/report/oldProducts';
// sales
const SALES_REPORT_PAGE = '/report/SalesReport';
// orders
const ORDERS_REPORT_PAGE = '/report/OrdersReport';
const FINANCIAL_ORDERS_REPORT_PAGE = '/report/FinanicalOrdersReport';
const DAILY_TRACKER_ORDERS_PAGE = '/report/FlowardSalesReport';
const ORDER_AMOUNT_DETAIL = '/report/OrderAmountDetailReport';
const REVENUES_REPORT_PAGE = '/report/RevenuesReport';
const DEFFERED_REVENUES_REPORT_PAGE = '/report/DefferedRevenuesReport';
// designers
const DESIGNERS_DAILY_COMMISSIONS = '/report/DesignerCommissionReport';
const GIFTS_DAILY_SPEND = '/report/DesignerOrderBreakDownReport';
const DESIGNERS_DAILY_PERFORMANCE = '/report/DesignersPerformanceReport';
const DESIGNERS_PRODUCT_PERFORMANCE = '/report/DesignersProductPerformance';
const DESIGNERS_REPORT_PAGE = '/report/DesignersReport';
const ORDER_CANCELLATION_PAGE = '/report/orderCancellationReport';
// florists performance
const FLORIST_PERFORMACE_PAGE = '/report/floristPerformance';
// wallet reports
const WALLET_BALANCES_PAGE = '/report/WalletBalancesReport';
const WALLET_TRANSACTIONS_PAGE = '/report/WalletTransactionsReport';
// vouchers reports
const VOUCHERS_REPORTS_PAGE = '/report/VouchersReport';
const COUPON_DAILY_SPEND_PAGE = '/report/AllCouponDailySpend';
// delivery
const DRIVER_PERFORMANCE = '/report/DriverPerformanceReport';
const DELIVERY_PERFORMANCE = '/report/DeliveryPerformanceReport';
const DRIVER_LOGISTICS = '/report/DriverLogisticsReport';
const DRIVER_PERFORMANCE_DETAILS = '/report/DriverPerformanceDetailsReport';
const DRIVER_ORDERS = '/report/DriverPerformanceOrdersReport';
const DRIVERS_EXPENSES_PAGE = '/DriversExpenses';
// corporate reports
const CORPORATE_REPORT = '/report/corporate';
const CUSTOMER_CORPORATE_REPORT = '/report/customerCorporate';
// inventory reports
const INVENTORY_BALANCE_REPORT = '/report/inventoryBalance';
const INVENTORY_SUPPLIERS_REPORT = '/report/inventorySuppliers';
const INVENTORY_STATEMENTS_ACTUAL_DELIVERY_REPORT = '/report/inventoryStatementActualDelivery';
const INVENTORY_STATEMENTS_REPORT = '/report/InventoryStatementsReport';
const INVENTORY_FINANACE__STATEMENTS_REPORT = '/report/InventoryFinanceStatements';
const INVENTORY_STATEMENTS_CANCELLED_REPORT = '/report/InventoryStatementsCancelledReport';
const INVENTORY_STATEMENTS_DAMAGED_REPORT = '/report/InventoryStatementsDamagedReport';
const INVENTORY_REPORT = '/report/InventoryReport';
const INVENTORY_SALES_PERFORMANCE = '/report/salesPerformanceReport';
const INVENTORY_SALES_PERFORMANCE_DETAILS = '/report/salesPerformanceDetailsReport';
// brands
const BRANDS_REPORT_PAGE = '/report/BrandsReport';
// customer service
const CUSTOMER_SERVICE_PAGE = '/orders/customerService';
// inventory
const INVENTORY_ITEMS_PAGE = '/inventory/items';
const INVENTORY_ALL_ITEMS_PAGE = '/inventory/allItems';
const INVENTORY_ALL_ITEMS_ALL_OPS_PAGE = '/inventory/allItemsAllOps';
const INVENTORY_ALERT_QTY_PAGE = '/inventory/alertQtyItems';
const INVENTORY_SUPPLIERS_PAGE = '/inventory/suppliers';
const EDIT_INVENTORY_PAGE = '/inventory/:inventoryId/editInventory/:id';
// master-inventory
const MASTER_INVENTORY = '/master-inventory';
const EDIT_MASTER_INVENTORY = '/master-inventory/:id/edit';
// occasions
const OCCASIONS_PAGE = '/occasions';
// catgories
const CATEGORIES_PAGE = '/categories';
const MERCHANDISING_CATEGORIES_PAGE = '/merchandising-categories';
const SUB_MERCHANDISING_CATEGORIES_PAGE = '/merchandising-categories/:level/:id/sub';
const EDIT_MERCHANDISING_CATEGORIES_PAGE = '/merchandising-categories/:level/:id/edit';
const SUB_CATEGORIES_PAGE = '/categories/:id/sub';
const SECOND_SUB_CATEGORIES_PAGE = '/categories/:id/sublv2';
const EDIT_CATEGORY_PAGE = '/categories/:id/edit';
// late orders page
const LATE_ORDERS_PAGE = '/orders/lateOrders';
// registered users
const REGISTERED_USER_PAGE = '/report/registeredUsers';
// hours & areas breakdown
const HOURS_BREAKDOWN_PAGE = '/report/HoursBreakDown';
const AREAS_BREAKDOWN_PAGE = '/report/AreasBreakDown';
// Delivery areas
const AREAS_PAGE = '/delivery/areas';
const COVERAGE_AREAS_PAGE = '/delivery/coverage-areas';
const SUB_AREAS_PAGE = '/delivery/areas/:id/sub';
const EDIT_AREAS_PAGE = '/delivery/areas/:id/edit';
const EDIT_SUB_AREAS_PAGE = '/delivery/areas/:id/sub/:subId/edit';
const DRIVER_RADIUS_PAGE = '/delivery/driver-radius';
// Banners
const BANNERS_PAGE = '/banners/:id';
const NEW_BANNER_PAGE = '/banners/:id/new';
const EDIT_BANNER_PAGE = '/banners/:id/edit';
// florists
const FLORISTS_PAGE = '/florists';
// florist community
const FLORIST_COMMUNITY_UNDERREVIEW_PAGE = '/florist-community-underreview-designs';
const FLORIST_COMMUNITY_ACCEPTED_PAGE = '/florist-community-accepted-designs';
const FLORIST_COMMUNITY_REJECTED_PAGE = '/florist-community-rejected-designs';
const FLORIST_COMMUNITY_DELETED_PAGE = '/florist-community-deleted-designs';
const FLORIST_DESIGN_EDIT_PAGE = '/florist-community-designs/:id';
const FLORIST_TRANSACTION_PAGE = '/florist-transactions';
const FLORIST_TRANSACTION_EDIT_PAGE = '/florist-transaction/:id';
// Delivery postcode
const POSTCODE_PAGE = '/delivery/coverage-postcode';
const EDIT_POSTCODE_PAGE = '/delivery/coverage-postcode/:id/edit';
// vouchers
const VOUCHERS_LIST_PAGE = '/coupons';
const EDIT_COUPON_ITEM_PAGE = '/coupon/:id/edit';
const ADD_NEW_COUPON_PAGE = '/coupon/add';
// master catalog
const ACTIVE_INSTOCK_PAGE = '/masterCatalog/activeInstock';
const ACTIVE_OUTSTOCK_PAGE = '/masterCatalog/activeOutstock';
const INACTIVE_INSTOCK_PAGE = '/masterCatalog/inactiveInstock';
const INACTIVE_OUTSTOCK_PAGE = '/masterCatalog/inactiveOutstock';
const DRAFT_CATALOG_PAGE = '/masterCatalog/draft';
const ARCHIVE_CATALOG_PAGE = '/masterCatalog/archive';
const TOTAL_CATALOG_PAGE = '/masterCatalog/total';
const UNCATEGORIZED_MERCHANDISE_PAGE = '/masterCatalog/uncategorizedMerchandise';
// dupliacted and replaced
const DUPLIACTED_REPLACED_PAGE = '/orders/dupliactedAndReplaced';
// celebrities
const CELEBRITIES_PAGE = '/celebrities';
const ADD_CELEBRITY_PAGE = '/celebrity/new';
const EDIT_CELEBRITY_PAGE = '/celebrity/:id/edit';
// kpi
const KPI_PAGE = '/kpi-summary';
// ranking products
const RANKING_PRODUCTS_PAGE = '/ranking-products';
// country slots
const COUNTRY_SLOTS_PAGE = '/country-slots';
// customer loyality
const CUSTOMER_LOYALITY_PAGE = '/report/cutomerLoyaltyReport';
// key value
const KEY_VALUE_PAGE = '/key-values';
// seasonal prep
const SEASONAL_PREP_PAGE = '/seasonal-preparations';
// invoices
const INVOICES = '/invoices';
const CORPORATE_INVOICES = '/corporate-invoices';
const CONSOLIDATED_INVOICES = '/consolidated-invoices';
const GENERATED_INVOICES = '/generated-invoices';
// shorter link
const SHORTER_LINK_PAGE = '/shorterlink';
// attributes
const ATTRIBUTES_PAGE = '/attributes';
const SUB_ATTRIBUTES_PAGE = '/attributes/:id/sub';
const EDIT_ATTRIBUTES_PAGE = '/attributes/:id/edit';
const EDIT_SUB_ATTRIBUTES_PAGE = '/attributes/sub/:id/edit';
// generate payment link
const GENERATE_PAYMENT_LINK_PAGE = '/orders/generatePaymentLink';
// production system
const PRODUCTION_PAGE = '/productionPage';
// user feedback
const USER_FEEDBACK = '/userFeedback';

export const getHomePageUrl = () => HOME;
export const getCreateOrderPageUrl = () => CREATE_ORDER;
export const getOrderQuickActionsPageUrl = () => ORDER_QUICK_ACTIONS;
export const getOrderQuickPrintingPageUrl = () => ORDER_QUICK_PRINTING;
export const getPremiumQuickActionsPageUrl = () => ORDER_PREMIUM_QUICK_ACTIONS;
export const getLoginPageUrl = () => LOGIN;
export const getOrderDetailsPageUrl = () => VIEW_ORDER;
export const getOrderedItemsPageUrl = () => ORDERED_ITEMS;
export const getDriversPageUrl = () => DRIVERS;
export const getCustomerServicePageUrl = () => CUSTOMER_SERVICE_PAGE;
export const getQuickOrdersPageUrl = () => QUICK_ORDERS_PAGE;
// admin
export const getAdminPagesUrl = () => ADMIN_PAGES;
export const getAdminAddPageUrl = () => ADMIN_ADD_PAGE;
export const getAdminEditPageUrl = () => ADMIN_EDIT_PAGE;
export const getAdminGroupsUrl = () => ADMIN_GROUPS;
export const getAdminEditGroupUrl = () => ADMIN_EDIT_GROUP;
export const getAdminUsersUrl = () => USERS;
export const getAdminUserInfo = () => USER_INFO;
export const getPickersPageUrl = () => USERS_PICKERS;
export const getPackersPageUrl = () => USERS_PACKERS;
export const getFloristsPageUrl = () => USERS_FLORISTS;
export const getEditUserPage = () => EDIT_USER;
export const getUserDefaultAddressesPageUrl = () => USER_DEFAULT_ADDRESSES;
export const getUsersActivityPageUrl = () => USERS_ACTIVITY;
export const getEditItemsPageUrl = () => EDIT_ITEMS;
export const getAdminMessageUrl = () => ADMIN_MESSAGE;
export const getAdminNotificationsMessagesUrl = () => ADMIN_NOTIFICATIONS_MESSAGES;

export const getNotificationsPageUrl = () => NOTIFICATIONS;

export const getOrderNotificationsPageUrl = () => ORDER_NOTIFICATIONS;

export const getOrdersZonesPageUrl = () => ORDERS_ZONES;

// products
export const getEditProductPageUrl = () => EDIT_PRODUCT_PAGE;

export const getSubscriptionsPage = () => SUBSCRIPTIONS_PAGE;

export const getDeliverySlotsPageUrl = () => `${DELIVERY_SLOTS_PAGE}`;
export const getDeliverySlotsPage = () => `${DELIVERY_SLOTS_PAGE}?status=0&line=0`;
export const getDeliverySlotsPremiumPage = () => `${DELIVERY_SLOTS_PAGE}?status=0&line=1`;

// ticketing

export const getTopicsPageUrl = () => TOPICS_PAGE;

export const getOpsTicketsPageUrl = () => TICKETS_PAGE;

export const getMyTicketsPage = () => MY_TICKETS_PAGE;

export const getMyOpenedTicketsPage = () => MY_OPENED_TICKETS_PAGE;

export const getMyClosedTicketsPage = () => MY_CLOSED_TICKETS_PAGE;

export const getOpenedTicketsPage = () => OPENED_TICKETS_PAGE;

export const getClosedTicketsPage = () => CLOSED_TICKETS_PAGE;

export const getTicketCommentsPageUrl = () => TICKET_COMMENTS_PAGE;

export const getTechTicketsPageUrl = () => TECH_TICKETS_PAGE;
export const getOpenedTechTicketsPageUrl = () => OPENED_TECH_TICKETS_PAGE;
export const getClosedTechTicketsPageUrl = () => CLOSED_TECH_TICKETS_PAGE;

//featured occasion
export const getFeaturedOccasionPageUrl = () => FEATURED_OCCASION;
export const newFeaturedOccasionPageUrl = () => NEW_FEATURED_OCCASION_PAGE;
export const editFeaturedOccasionPageUrl = () => EDIT_FEATUREDOCCASION_PAGE;

// trip
export const getTripDriversListPage = () => TRIP_DRIVERS_LIST_PAGE;

// reports
// products
export const getProductPriceBreakDown = () => PRODUCT_PRICE_BREAKDOWN;
export const getProductBreakDown = () => PRODUCT_BREAKDOWN;
export const getCategoryBreakDown = () => CATEGORY_BREAKDOWN;
export const getProductTypeSales = () => PRODUCT_TYPE_SALES;
export const getProductTypeAttribute = () => PRODUCT_TYPE_ATTRIBUTE;
export const getLiveProductsReport = () => LIVE_PRODUCTS_REPORT;
export const getCartProductsDiscounts = () => CART_PRODUCTS_DISCOUNTS;
export const getProductTypeBreakDown = () => PRODUCT_TYPE_BREAKDOWN;
export const getProductCategoryPercentage = () => PRODUCT_CATEGORY_PERCENTAGE;
export const getOrdersProductWisePage = () => PRODUCT_WISE_PAGE;
export const getTopTenProductPage = () => TOP_TEN_PRODUCTS;
export const getOldProductPage = () => OLD_PRODUCTS;

// sales
export const getSalesReportPageUrl = () => SALES_REPORT_PAGE;
// orders
export const getOrdersReportPageUrl = () => ORDERS_REPORT_PAGE;
export const getFinancialOrdersPageUrl = () => FINANCIAL_ORDERS_REPORT_PAGE;
export const getDailyTrackerOrdersPageUrl = () => DAILY_TRACKER_ORDERS_PAGE;
export const getOrderAmountDetailReportPageUrl = () => ORDER_AMOUNT_DETAIL;
export const getRevenuesReportPageUrl = () => REVENUES_REPORT_PAGE;
export const getDeferredRevenuesReportPageUrl = () => DEFFERED_REVENUES_REPORT_PAGE;
// inventory reports
export const getInventoryBalanceReportPageUrl = () => INVENTORY_BALANCE_REPORT;
export const getInventorySupplierReportPageUrl = () => INVENTORY_SUPPLIERS_REPORT;
export const getInventoryStatementActualDeliveryReportPageUrl = () =>
  INVENTORY_STATEMENTS_ACTUAL_DELIVERY_REPORT;
export const getSalesPerformanceReportPageUrl = () => INVENTORY_SALES_PERFORMANCE;
export const getSalesPerformanceDetailsReportPageUrl = () => INVENTORY_SALES_PERFORMANCE_DETAILS;
// designers
export const getDesignerCommissionPageUrl = () => DESIGNERS_DAILY_COMMISSIONS;
export const getGiftsDailySpendPageUrl = () => GIFTS_DAILY_SPEND;
export const getDesignersDailyPerformancePageUrl = () => DESIGNERS_DAILY_PERFORMANCE;
export const getDesignersProductPerformancePageUrl = () => DESIGNERS_PRODUCT_PERFORMANCE;
export const getDesignersReportPageUrl = () => DESIGNERS_REPORT_PAGE;
export const getOrderCancellationPageUrl = () => ORDER_CANCELLATION_PAGE;
// florist performance
export const getFloristsPerformancePageUrl = () => FLORIST_PERFORMACE_PAGE;
// wallet reports
export const getWalletBalancesPageUrl = () => WALLET_BALANCES_PAGE;
export const getWalletTransactionsPageUrl = () => WALLET_TRANSACTIONS_PAGE;
// vouchers
export const getVouchersReportPageUrl = () => VOUCHERS_REPORTS_PAGE;
export const getCouponDailySpendReportPageUrl = () => COUPON_DAILY_SPEND_PAGE;

// delivery
export const getDriverPerformanceReportPageUrl = () => DRIVER_PERFORMANCE;
export const getDeliveryPerformanceReportPageUrl = () => DELIVERY_PERFORMANCE;
export const getDriverLogisticsReportPageUrl = () => DRIVER_LOGISTICS;
export const getDriverPerformanceDetailsReportPageUrl = () => DRIVER_PERFORMANCE_DETAILS;
export const getDriverOrdersReportPageUrl = () => DRIVER_ORDERS;
export const getDriversExpensesPageUrl = () => DRIVERS_EXPENSES_PAGE;
// corporate reports
export const getCorporateReportPageUrl = () => CORPORATE_REPORT;
export const getCustomerCorporateReportPageUrl = () => CUSTOMER_CORPORATE_REPORT;
// brands
export const getBrandsReportPageUrl = () => BRANDS_REPORT_PAGE;
// reports
// inventory
export const getInventoryItemsPageUrl = () => INVENTORY_ITEMS_PAGE;
export const getInStockInventoryItemsPageUrl = () =>
  `${INVENTORY_ITEMS_PAGE}?type=-1&sreackkey=&qty=-1&minimumQty=-1&status=0&page=1&inventorySorting=0`;
export const getDiscontinuedInventoryItemsPageUrl = () =>
  `${INVENTORY_ITEMS_PAGE}?type=-1&sreackkey=&qty=-1&minimumQty=-1&status=2&page=1&inventorySorting=0`;
export const getOutofStockInventoryItemsPageUrl = () =>
  `${INVENTORY_ITEMS_PAGE}?type=-1&sreackkey=&qty=-1&minimumQty=-1&status=1&page=1&inventorySorting=0`;
export const getAllInventoryItemsPageUrl = () => INVENTORY_ALL_ITEMS_PAGE;
export const getAllInventoryItemsAllOpsPageUrl = () => INVENTORY_ALL_ITEMS_ALL_OPS_PAGE;
export const getAlertQtyInventoryItemsPageUrl = () => INVENTORY_ALERT_QTY_PAGE;
export const getInventorySuppliersPageUrl = () => INVENTORY_SUPPLIERS_PAGE;
export const getEditInventoryPageUrl = () => EDIT_INVENTORY_PAGE;
export const getStatementsReportPageURL = () => INVENTORY_STATEMENTS_REPORT;
export const getFinanceInventoryStatementsPageUrl = () => INVENTORY_FINANACE__STATEMENTS_REPORT;
export const getStatementsCancelledReportPageURL = () => INVENTORY_STATEMENTS_CANCELLED_REPORT;
export const getStatementsDamagedReportPageURL = () => INVENTORY_STATEMENTS_DAMAGED_REPORT;
export const getInventoryReportPageURL = () => INVENTORY_REPORT;
// master-inventory
export const getMasterInventoryPageURL = () => MASTER_INVENTORY;
export const getEditMasterInventoryPageURL = () => EDIT_MASTER_INVENTORY;
// occasions
export const getOccasionsPageUrl = () => OCCASIONS_PAGE;
// categories
export const getCategoriesPage = () => CATEGORIES_PAGE;
export const getMerchandisingCategoriesPage = () => MERCHANDISING_CATEGORIES_PAGE;
export const getSubMerchandisingCategoriesPage = () => SUB_MERCHANDISING_CATEGORIES_PAGE;
export const getEditMerchandisingCategoriesPage = () => EDIT_MERCHANDISING_CATEGORIES_PAGE;
export const getSubCategoriesPage = () => SUB_CATEGORIES_PAGE;
export const getSecondSubCategoriesPage = () => SECOND_SUB_CATEGORIES_PAGE;
export const getEditCategoryPage = () => EDIT_CATEGORY_PAGE;
// late orders
export const getLateOrdersPageUrl = () => LATE_ORDERS_PAGE;
// registered users
export const getRegisteredUsersPageUrl = () => REGISTERED_USER_PAGE;
// hours and areas breakdown
export const getHoursBreakdownPageUrl = () => HOURS_BREAKDOWN_PAGE;
export const getAreasBreakdownPageUrl = () => AREAS_BREAKDOWN_PAGE;
// Delivery areas
export const getDeliveryAreasPageUrl = () => AREAS_PAGE;
export const getCoverageAreasPageUrl = () => COVERAGE_AREAS_PAGE;
export const getDeliverySubAreasPageUrl = () => SUB_AREAS_PAGE;
export const getEditDeliveryAreasPageUrl = () => EDIT_AREAS_PAGE;
export const getEditSubAreaPageUrl = () => EDIT_SUB_AREAS_PAGE;
export const getDriverRadiusPageUrl = () => DRIVER_RADIUS_PAGE;
// Banners
export const getBannersPageURL = () => BANNERS_PAGE;
export const getNewBannerPageURL = () => NEW_BANNER_PAGE;
export const getEditBannerPageURL = () => EDIT_BANNER_PAGE;
// florist community
export const getFloristcommunityUnderReviewPageUrl = () => FLORIST_COMMUNITY_UNDERREVIEW_PAGE;
export const getFloristcommunityAcceptedPageUrl = () => FLORIST_COMMUNITY_ACCEPTED_PAGE;
export const getFloristcommunityRejectedPageUrl = () => FLORIST_COMMUNITY_REJECTED_PAGE;
export const getFloristcommunityDeletedPageUrl = () => FLORIST_COMMUNITY_DELETED_PAGE;
export const getFloristDesignEditPageUrl = () => FLORIST_DESIGN_EDIT_PAGE;
export const getFloristTransactionsPageUrl = () => FLORIST_TRANSACTION_PAGE;
export const getFloristTransactionEditPageUrl = () => FLORIST_TRANSACTION_EDIT_PAGE;
// Delivery postcode
export const getCoveragePostcodePage = () => POSTCODE_PAGE;
export const getEditCoveragePostcodePage = () => EDIT_POSTCODE_PAGE;
// vouchers
export const getVouchersListPageUrl = () => VOUCHERS_LIST_PAGE;
export const getEditCouponPage = () => EDIT_COUPON_ITEM_PAGE;
export const getAddCouponPage = () => ADD_NEW_COUPON_PAGE;
// master catalog
export const getActiveInstockPageUrl = () => ACTIVE_INSTOCK_PAGE;
export const getActiveOutstockPageUrl = () => ACTIVE_OUTSTOCK_PAGE;
export const getInactiveInstockPageUrl = () => INACTIVE_INSTOCK_PAGE;
export const getInactiveOutstockPageUrl = () => INACTIVE_OUTSTOCK_PAGE;
export const getDraftCatalogPageUrl = () => DRAFT_CATALOG_PAGE;
export const getArchiveCatalogPageUrl = () => ARCHIVE_CATALOG_PAGE;
export const getTotalCatalogPageUrl = () => TOTAL_CATALOG_PAGE;
export const getUncategorizedMerchanidsePageUrl = () => UNCATEGORIZED_MERCHANDISE_PAGE;
// duplicated and replaced
export const getDuplicatedAndReplacedPageUrl = () => DUPLIACTED_REPLACED_PAGE;
// celebrities
export const getCelebritiesPageUrl = () => CELEBRITIES_PAGE;
export const getAddCelebrityPageUrl = () => ADD_CELEBRITY_PAGE;
export const getEditCelebrityPageUrl = () => EDIT_CELEBRITY_PAGE;
// kpi
export const getKpiPageUrl = () => KPI_PAGE;
// ranking products
export const getRankingProductsPage = () => RANKING_PRODUCTS_PAGE;
// country slots
export const getCountrySlotsPageUrl = () => COUNTRY_SLOTS_PAGE;
// customer loyality
export const getCustomerLoyalityPageUrl = () => CUSTOMER_LOYALITY_PAGE;
// key values
export const getKeyValuesPageUrl = () => KEY_VALUE_PAGE;
// seasonal prep
export const getSeasonalPrepPageUrl = () => SEASONAL_PREP_PAGE;
// invoices
export const getInvoicesPageUrl = () => INVOICES;
export const getCorporateInvoicesPageUrl = () => CORPORATE_INVOICES;
export const getConsolidatedInvoicesPageUrl = () => CONSOLIDATED_INVOICES;
export const getGeneratedInvoicesPageUrl = () => GENERATED_INVOICES;
// shorter link
export const getShorterLinkPageUrl = () => SHORTER_LINK_PAGE;
// attributes
export const getAttributesPageUrl = () => ATTRIBUTES_PAGE;
export const getEditAttributesPage = () => EDIT_ATTRIBUTES_PAGE;
export const getEditSubAttributesPage = () => EDIT_SUB_ATTRIBUTES_PAGE;
export const getSubAttributesPage = () => SUB_ATTRIBUTES_PAGE;
//generate payment link
export const getGeneratePaymentLinkPageUrl = () => GENERATE_PAYMENT_LINK_PAGE;
// production system
export const getProductionPage = () => PRODUCTION_PAGE;
// user feedback
export const getUserFeedbackPageUrl = () => USER_FEEDBACK;
