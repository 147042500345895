import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
// lodash
import cloneDeep from 'lodash/cloneDeep';
// modals
import WriteNoteModal from './modals/WriteNoteModal';
import ChangeStatusModal from './modals/ChangeStatusModal';
import CardMessageModal from './modals/CardMessageModal';
import EditAddressModal from '../viewOrder/modals/EditAddressModal';
import ActionBoxModal from './modals/ActionBoxModal';
import CartModal from './modals/CartModal';
import NoteForTeamModal from './modals/NoteForTeamModal';
// actions
import {
  filterQuickActions,
  setOrderActionsData,
  setRequiredParamsForQuickActionsTable,
  setTableData,
  setCurrentQuickActionsTablePage,
  getZones,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getTimeSlotData,
  getUserAgentData,
  getFilterAreas,
  getIsNoteModalOpen,
  getIsChangeStatusModalOpen,
  getOrderStatus,
  getIsCardModalOpen,
  getIsAddressModalOpen,
  getActionAddressData,
  getOrderId,
  getIsActionBoxModalOpen,
  getIsCartModalOpen,
  getOrderRowNote,
  getIsNoteForTeamModalOpen,
  getTableData,
  getPaymentDate,
  getZonesFilterData,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// constants
import { filteInputs, initialPayload } from 'constant/filteInputs';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
// components
import FilterComponent from 'components/shared/FilterComponent';
import QuickActionsDataTable from './QuickActionsDataTable';
import TableActions from './TableActions';
import PageTitle from 'components/shared/PageTitle';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';

const OrdersQuickActionsPage = () => {
  const timeSlots = useSelector((state) => getTimeSlotData({ state }));
  const userAgents = useSelector((state) => getUserAgentData({ state }));
  const areas = useSelector((state) => getFilterAreas({ state }));
  const isNoteModalOpen = useSelector((state) => getIsNoteModalOpen({ state }));
  const isNoteForTeamModalOpen = useSelector((state) => getIsNoteForTeamModalOpen({ state }));
  const isChangeStatusModalOpen = useSelector((state) => getIsChangeStatusModalOpen({ state }));
  const isCardModalOpen = useSelector((state) => getIsCardModalOpen({ state }));
  const isAddressModalOpen = useSelector((state) => getIsAddressModalOpen({ state }));
  const isActionBoxModalOpen = useSelector((state) => getIsActionBoxModalOpen({ state }));
  const isCartModalOpen = useSelector((state) => getIsCartModalOpen({ state }));
  const addressDetails = useSelector((state) => getActionAddressData({ state }));
  const orderId = useSelector((state) => getOrderId({ state }));
  const paymentDate = useSelector((state) => getPaymentDate({ state }));
  const orderNote = useSelector((state) => getOrderRowNote({ state }));
  const orderStatus = useSelector((state) => getOrderStatus({ state }));
  const data = useSelector((state) => getTableData({ state }));
  const zones = useSelector((state) => getZonesFilterData({ state }));
  const dispatch = useDispatch();
  const location = useLocation();

  const [inputValues, setInputValues] = useState(initialPayload);

  useEffect(() => {
    dispatch(getZones());
    const clonedInputValues = cloneDeep(inputValues);
    setInputValues(clonedInputValues);

    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(setCurrentQuickActionsTablePage(parseInt(queryObj.page, 10)));
      dispatch(setRequiredParamsForQuickActionsTable(queryObj));
      dispatch(setTableData(queryObj.searchkey));
    } else {
      if (!data.data) {
        dispatch(setRequiredParamsForQuickActionsTable(clonedInputValues));
        dispatch(setTableData(clonedInputValues.searchkey));
      }
    }
    dispatch(setOrderActionsData());
  }, []);

  const handleSubmit = async () => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      if (queryObj.isMbsOrder) {
        const updatedParams = { ...inputValues, isMbsOrder: true };
        dispatch(filterQuickActions(updatedParams, true));
      } else if (queryObj.isPreorder) {
        const updatedParams = { ...inputValues, orderstatus: 7, isPreorder: true };
        dispatch(filterQuickActions(updatedParams, true));
      } else {
        const updatedParams = { ...inputValues, orderstatus: orderStatus };
        dispatch(filterQuickActions(updatedParams, true));
      }
    } else {
      dispatch(filterQuickActions(inputValues, true));
    }
  };

  return (
    <Fragment>
      <PageTitle title="Orders" />
      {isChangeStatusModalOpen && (
        <ChangeStatusModal order={data?.data?.find((order) => order.id === orderId)} />
      )}
      {isNoteModalOpen && (
        <AppErrorBoundary>
          <WriteNoteModal isAction={true} orderNote={orderNote} premium={false} />
        </AppErrorBoundary>
      )}
      {isNoteForTeamModalOpen && (
        <AppErrorBoundary>
          <NoteForTeamModal />
        </AppErrorBoundary>
      )}
      {isCardModalOpen && (
        <AppErrorBoundary>
          <CardMessageModal />
        </AppErrorBoundary>
      )}
      {isAddressModalOpen && (
        <AppErrorBoundary>
          <EditAddressModal
            recipient={addressDetails.recipient}
            orderId={addressDetails.orderId}
            defaultLat={addressDetails.opsInfo.lat}
            defaultLng={addressDetails.opsInfo.lng}
            isAction={addressDetails.isAction}
            orderOps={addressDetails.orderOps}
            premium={false}
            countryIso={addressDetails.opsInfo.isoCode}
          />
        </AppErrorBoundary>
      )}
      {isActionBoxModalOpen && (
        <AppErrorBoundary>
          <ActionBoxModal
            orderId={orderId}
            paymentDate={paymentDate}
            isActionBoxModalOpen={isActionBoxModalOpen}
          />
        </AppErrorBoundary>
      )}
      {isCartModalOpen && (
        <AppErrorBoundary>
          <CartModal isAction={true} orderId={orderId} isCartModalOpen={isCartModalOpen} />
        </AppErrorBoundary>
      )}
      {data && (
        <h5>
          Page {data.currentPage} | <span>{data.data?.length} Orders</span>
        </h5>
      )}
      <TableActions premium={false} inputValues={inputValues} />
      <AppErrorBoundary>
        <FilterComponent
          handleSubmit={handleSubmit}
          filterValues={filteInputs}
          timeSlots={timeSlots}
          userAgents={userAgents}
          areas={areas}
          inputValues={inputValues}
          setInputValues={setInputValues}
          zones={zones}
        />
      </AppErrorBoundary>
      <QuickActionsDataTable params={inputValues} />
    </Fragment>
  );
};

export default OrdersQuickActionsPage;
