import { updateObject } from '../../utility';

const initialState = {
  driversData: {},
  isExporting: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DRIVERS_DATA': {
      return updateObject(state, {
        driversData: action.payload,
      });
    }

    case 'SET_IS_EXPORTING': {
      return updateObject(state, {
        isExporting: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
