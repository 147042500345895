import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import OrderCancellationDataGrid from 'components/reports/orders/OrderCancellationDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
// constant
import { convertToUnix, convertFromUnix, convertObjectIntoQueryParams } from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import OrdersReportService from 'services/reportsService/OrdersReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const OrderCancellationReportPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: convertToUnix(),
    toDate: convertToUnix(),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await OrdersReportService.orderCancellationReport(params);
      const updatedList = res.data.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      setData(updatedList);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Order Cancellation Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = JSON.parse(
        '{"' +
          decodeURI(location.search.substring(1).replace(/&/g, '","').replace(/=/g, '":"')) +
          '"}'
      );
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.fromDate = convertFromUnix(inputValues.fromDate);
    updatedParams.toDate = convertFromUnix(inputValues.toDate);
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: '/report/orderCancellationReport',
      search: queryString,
    });
    fetchData(updatedParams);
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Cancellation"
      />
      <OrderCancellationDataGrid data={data} />
    </div>
  );
};

export default OrderCancellationReportPage;
