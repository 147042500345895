import React from 'react';
import { useParams } from 'react-router-dom';
import SectionLoader from 'components/common/SectionLoader';
const FloristTransactionHeader = React.lazy(() =>
  import('components/floristCommunity/FloristTransactionHeader')
);
const FloristTransactionCommentsList = React.lazy(() =>
  import('components/floristCommunity/FloristTransactionCommentsList')
);

const FloristTransactionEditPage = () => {
  const params = useParams();

  return (
    <>
      <React.Suspense fallback={<SectionLoader />}>
        <FloristTransactionHeader transactionId={params?.id} />
        <FloristTransactionCommentsList transactionId={params?.id} />
      </React.Suspense>
    </>
  );
};

export default FloristTransactionEditPage;
