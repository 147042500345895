import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import { getLoginPageUrl } from 'constant/AppUrls';
import { HasPermission } from 'constant/Helpers';
// selectors
import { getIsLoggedIn, getIsLoggedoutClicked, getPermissionsList } from 'store/app/AppSelectors';
// components
import PermissionsCannotAccess from './PermissionsCannotAccess';

const RestrictedRoute = ({ component: Component, requiredPermissions, page, ...rest }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));
  const isLoggedoutClicked = useSelector((state) => getIsLoggedoutClicked({ state }));
  const location = useLocation();
  if (isLoggedIn) {
    if (HasPermission(permissionsList, page, 'view')) {
      return (
        <AppErrorBoundary>
          <Component {...rest} />
        </AppErrorBoundary>
      );
    } else {
      return (
        <AppErrorBoundary>
          <PermissionsCannotAccess requiredPermissions={requiredPermissions} />
        </AppErrorBoundary>
      );
    }
  } else {
    return (
      <Navigate
        to={{
          pathname:
            window.location.pathname !== getLoginPageUrl() && !isLoggedoutClicked
              ? `${getLoginPageUrl()}?hub-source=${window.location.href}`
              : getLoginPageUrl(),
          search: location.search,
          state: { from: location },
        }}
      />
    );
  }
};

export default RestrictedRoute;
