import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { setTicketDetailsData, setTicketCommentsList } from 'store/ticketing/TicketingActions';
// api
import TicketingService from 'services/TicketingService';
// constants
import { localStorageKeys } from 'constant/Enums';

const CloseNoteModal = ({ isCloseNoteModalOpen, setIsCloseNoteModalOpen, ticketId }) => {
  const [closeNote, setCloseNote] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const decodedToken = jwt_decode(getFromLocalStorage(localStorageKeys.ACCESS_TOKEN));
  const dispatch = useDispatch();

  const handleCloseThread = async () => {
    const dataBody = {
      TicketId: ticketId,
      AssignedTo: decodedToken.Id,
      AssignedToName: decodedToken.FullName,
      AssignedByName: decodedToken.FullName,
      AssignedToEmail: decodedToken.Email,
      AssignedBy: decodedToken.Id,
      comment: closeNote,
    };
    setDisableBtn(true);
    try {
      await TicketingService.handleCloseThread(dataBody);
      setTimeout(() => {
        dispatch(setTicketDetailsData(ticketId));
        dispatch(setTicketCommentsList(ticketId));
        setIsCloseNoteModalOpen((prev) => !prev);
        setDisableBtn(false);
        toast.success('Thread is closed successfully');
      }, 1500);
    } catch (err) {
      errorLogger('CloseNoteModal:handleCloseThread', err);
      setDisableBtn(false);
      setIsCloseNoteModalOpen((prev) => !prev);
      toast.error(err.response.data.message);
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isCloseNoteModalOpen} toggle={() => setIsCloseNoteModalOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsCloseNoteModalOpen((prev) => !prev)}>Note</ModalHeader>
        <ModalBody>
          <textarea
            className="form-control"
            type="text"
            placeholder="Add note (optional)"
            value={closeNote}
            onChange={(e) => setCloseNote(e.target.value)}
          ></textarea>
        </ModalBody>
        <ModalFooter>
          <Button disabled={disableBtn} onClick={handleCloseThread} color="primary">
            Save
          </Button>
          <Button color="light" onClick={() => setIsCloseNoteModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CloseNoteModal;
