class ErrorMessages {
  static getRequiredErrorMessage() {
    return 'Required Field.';
  }

  static getNumErrorMessage() {
    return 'Needs to be a number.';
  }

  static getGreaterThanZeroErrorMessage() {
    return 'Must be greater than zero.';
  }
}

export default ErrorMessages;
