import { updateObject } from '../utility';

const initialState = {
  couponsListData: [],
  currentPage: '',
  pageSize: '',
  rowCount: '',
  voucherCatList: [],
  couponDetails: {},
  voucherLimitCat: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_COUPONS_LIST_DATA': {
      return updateObject(state, {
        couponsListData: action.payload.data,
        currentPage: action.payload.currentPage,
        pageSize: action.payload.pageSize,
        rowCount: action.payload.rowCount,
      });
    }

    case 'SET_VOUCHERS_CATEGORIES_LIST': {
      return updateObject(state, {
        voucherCatList: action.payload,
      });
    }

    case 'SET_COUPON_DETAILS_BY_ID': {
      return updateObject(state, {
        couponDetails: action.payload,
      });
    }

    case 'SET_VOUCHER_LIMIT_CATEGORIES': {
      return updateObject(state, {
        voucherLimitCat: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
