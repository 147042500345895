/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState, Fragment } from 'react';
import DateRangePickerComponent from 'components/shared/DateRangePickerComponent';
import { translate } from 'react-switch-lang';
import cloneDeep from 'lodash/cloneDeep';
import CustomSelect from 'components/shared/CustomSelect';
import { dateFormat } from 'constant/Helpers';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const FilterComponent = ({
  handleSubmit,
  t,
  filterValues,
  timeSlots,
  userAgents,
  areas,
  inputValues,
  setInputValues,
  zones,
}) => {
  const date = new Date();
  const [accActive, setAccActive] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    key: 'selection',
  });
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      const updatedParams = cloneDeep(inputValues);
      updatedParams[name] = value;
      setInputValues(updatedParams);
    };

  const handleSelectChange = (e, name) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[name] = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleDateRange = (item) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.deliverydate =
      dateFormat(item.selection.startDate) + '-' + dateFormat(item.selection.endDate);
    setInputValues(updatedParams);
    setDateRange(item.selection);
  };

  return (
    <div className="card ">
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading onClick={() => setAccActive((prev) => !prev)}>
            <AccordionItemButton>
              <div className="card-header filter-accordion-card">
                <h4>Filter</h4>
                {accActive ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  {filterValues.map((filter, i) => {
                    let options = [];
                    if (filter.inputType === 'select') {
                      options = filter.selections;
                      if (filter.name === 'Agant') {
                        options = [...filter.selections, ...userAgents];
                      } else if (filter.name === 'timeslot') {
                        options = [...filter.selections, ...timeSlots];
                        options.forEach((o) => {
                          if (
                            o.name !== 'Any' &&
                            !o.name.includes('(Active)') &&
                            !o.name.includes('(Not Active)')
                          ) {
                            const newName = `${o.name}${
                              o.isActive ? ' (Active)' : ' (Not Active)'
                            }`;
                            o.name = newName;
                          }
                        });
                      } else if (filter.name === 'AreaId') {
                        options = [...filter.selections, ...areas.map((area) => area.area)];
                      } else if (filter.name === 'zone') {
                        options = [
                          ...filter.selections,
                          ...zones.map((zone) => {
                            return { name: zone.id, id: zone.id };
                          }),
                        ];
                      }
                      options.forEach((o) => {
                        o.id = o.id.toString();
                      });
                    }
                    return (
                      <div key={i} className="col-md-6 col-xl-3">
                        <div className="form-group filter-select">
                          <label className="bold-title">{t(filter.label)}</label>
                          {filter.inputType === 'select' ? (
                            <CustomSelect
                              listData={options}
                              handleChange={handleSelectChange}
                              selectName={filter.name}
                              textPropName="name"
                              valuePropName="id"
                              selectedValue={inputValues[filter.name]?.toString()}
                            />
                          ) : filter.inputType === 'date' ? (
                            <Fragment>
                              <input
                                className="form-control digits white-bg"
                                value={`${dateFormat(dateRange.startDate)} - ${dateFormat(
                                  dateRange.endDate
                                )}`}
                                onClick={() => setShowDatePicker((prev) => !prev)}
                                readOnly
                              />
                              {showDatePicker && (
                                <DateRangePickerComponent
                                  setShowDatePicker={setShowDatePicker}
                                  handleDateRange={handleDateRange}
                                  dateRange={dateRange}
                                />
                              )}
                            </Fragment>
                          ) : (
                            <input
                              className="form-control"
                              type="text"
                              placeholder={filter.placeHolder}
                              value={inputValues[filter.name]}
                              onChange={handleChange(filter.name)}
                              name={filter.name}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary mr-1" type="button" onClick={handleSubmit}>
                    Search
                  </button>
                </div>
              </div>
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default translate(FilterComponent);
