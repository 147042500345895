import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';

const SubAttributesDataGrid = ({ data }) => {
  const toolbarOptions = ['Search'];
  const grid = useRef(GridComponent);

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <Link
          to={`/attributes/sub/${rowData.id}/edit`}
          className="total-txt link sub-categoryid-link"
        >
          {parseInt(rowData.id, 10)}
        </Link>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <Link
        to={`/attributes/sub/${rowData.id}/edit`}
        className="total-txt link sub-categoryid-link"
      >
        {rowData.name}
      </Link>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${
          rowData.isActive ? 'btn-light-success' : 'btn-light-danger'
        }`}
      >
        {rowData.isActive ? 'Active' : 'Inactive'}
      </button>
    );
  };

  return (
    <div className="control-pane">
      <div className="control-section toolbar-table-style">
        <GridComponent
          ref={grid}
          dataSource={data}
          toolbar={toolbarOptions}
          allowTextWrap={true}
          allowSorting={true}
        >
          <ColumnsDirective>
            <ColumnDirective field="id" headerText="ID" template={idTemplate} width={90} />
            <ColumnDirective field="name" headerText="Name" template={nameTemplate} width={150} />
            <ColumnDirective
              field="isActive"
              headerText="Status"
              template={statusTemplate}
              width={100}
              customAttributes={{ class: 'category-status' }}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(SubAttributesDataGrid);
