import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
import GroupUsersDataGrid from './GroupUsersDataGrid';
// actions
import { getGroupUsersById } from 'store/user/UserActions';
// selectors
import { getGroupUsersList } from 'store/user/UserSelectors';

const GroupUsersContent = ({ toGroup }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const groupsUsersList = useSelector((state) => getGroupUsersList({ state }));
  const dispatch = useDispatch();

  const fetchGroupUsersList = async (groupId) => {
    setIsLoaderOpen(true);
    await dispatch(getGroupUsersById(groupId));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchGroupUsersList(toGroup.id);
  }, []);

  return (
    <div>
      <form>
        <div className="form-group col-12">
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <>
              <div className="table-responsive shopping-table text-center toolbar-table-style">
                <h5>{`Group ${toGroup.name} Users`}</h5>
                <GroupUsersDataGrid data={groupsUsersList} groupId={toGroup?.id} />
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default GroupUsersContent;
