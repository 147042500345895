import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
// print package
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { getOrderedItemsPageUrl } from 'constant/AppUrls';
import OrderedItemsFilter from './OrderedItemsFilter';
import OrderedItemsDataGrid from './OrderedItemsDataGrid';
// actions
import { setTimeSlotData } from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import { getTimeSlotData } from 'store/orderQuickActions/dataTable/DataTableSelector';
// api
import OrderedItemsService from 'services/OrderedItemsService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const today = new Date();

const OrderedItemsDataTable = () => {
  const [inputValues, setInputValues] = useState({
    Deliverydate: dateFormat(today) + '-' + dateFormat(today),
    TimeSlot: -1,
    PrintStatus: -1,
  });
  const [data, setData] = useState([]);
  const timeSlots = useSelector((state) => getTimeSlotData({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await OrderedItemsService.getOrderedItems(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (e) {
      errorLogger('OrderedItemsDataTable:fetchData', e);
      dispatch(setLoaderOpenFalse());
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      queryObj.PrintStatus = parseInt(queryObj.PrintStatus, 10);
      queryObj.TimeSlot = parseInt(queryObj.TimeSlot, 10);
      setInputValues(queryObj);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
    dispatch(setTimeSlotData());
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: getOrderedItemsPageUrl(),
      search: queryString,
    });
    fetchData(inputValues);
  };

  // const onPageChange = (i) => {
  //   const updatedParams = { ...inputValues, page: i };
  //   const queryString = convertObjectIntoQueryParams(updatedParams);
  //   navigate({
  //     pathname: getOrderedItemsPageUrl(),
  //     search: queryString,
  //   });
  //   fetchData(updatedParams);
  // };

  return (
    <div className="quick-actions-table toolbar-table-style">
      <OrderedItemsFilter
        timeSlots={timeSlots}
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
      />
      <OrderedItemsDataGrid data={data} />

      {/* <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={pageSize}
        itemsCount={+pageCount}
      /> */}
    </div>
  );
};

export default OrderedItemsDataTable;
