import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
} from '@syncfusion/ej2-react-grids';
import { dateFormatDDMM } from 'constant/Helpers';
import Tooltip from '@mui/material/Tooltip';
import { errorLogger } from 'datadog/DDUtils';
import { toast } from 'react-toastify';
// api
import PermissionService from 'services/PermissionService';

const PickersDataGrid = ({ data, fetchData, roleType }) => {
  const ref = useRef();

  const statusTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${
          rowData.isActive ? 'btn-light-success' : 'btn-light-danger'
        }`}
      >
        {rowData.isActive ? 'Active' : 'Inactive'}
      </button>
    );
  };

  const removeTemplate = (rowData) => (
    <Tooltip title="This button doesn't delete the user, it only removes the role.">
      <i className="fa fa-trash inventory-supp-trash" onClick={() => handleRemoveItem(rowData)} />
    </Tooltip>
  );

  const handleRemoveItem = async (rowData) => {
    const data = {
      userId: rowData.userId,
      groupType: roleType,
    };
    try {
      await PermissionService.removeUserRole(data);
      toast.success('Role has been removed from user successfully!');
      setTimeout(() => {
        fetchData();
      }, 1500);
    } catch (e) {
      errorLogger('FloristsDataGrid:handleRemoveItem', e);
      toast.error('Failed to remove role!');
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowExcelExport={true}
          allowPdfExport={true}
          allowGrouping={true}
          ref={ref}
          rowHeight={70}
          className="pointer"
          rowSelecting={(args) => {
            if (args.target) {
              if (args.target.className.indexOf('fa-trash') > -1) {
                args.cancel = true;
              }
            }
          }}
          rowSelected={(row) => {
            window.open(`/admin/users/edit/${row?.data?.userId}`, '_blank');
          }}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="Name" field="fullName" width={100} textAlign="Center" />
            <ColumnDirective headerText="Phone" field="phone" width={120} textAlign="Center" />
            <ColumnDirective
              headerText="Type"
              field="contractTypeString"
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              field="isActive"
              headerText="Status"
              template={statusTemplate}
              width={100}
              customAttributes={{ class: 'category-status' }}
            />
            <ColumnDirective
              headerText="Created Date"
              template={(rowData) => rowData?.createdDate && dateFormatDDMM(rowData?.createdDate)}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Remove"
              template={removeTemplate}
              width={120}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject />
        </GridComponent>
      </div>
    </div>
  );
};

export default PickersDataGrid;
