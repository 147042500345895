import { updateObject } from '../utility';

const initialState = {
  shorterLinksData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GENERATED_SHORTER_LINK': {
      return updateObject(state, {
        generatedShorterLink: action.payload,
      });
    }
    case 'GET_SHORTER_LINKS_DATA': {
      return updateObject(state, {
        shorterLinksData: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
