import { apiGatewayService } from '../apiService/HttpService';
import { setEndPoint } from '../apiService/SetEndPoint';
import { reportsEndPoints } from '../apiService/ReportsEndPoints';

class InventoryReportService {
  static getInventorySuppliersReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getInventorySuppliers, ...extraParams),
      params,
    });
  }

  static getInventoryBalanceReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getInventoryBalance, ...extraParams),
      params,
    });
  }

  static exportInventorySuppliersReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportInventorySuppliers, ...extraParams),
      params,
    });
  }

  static getInventoryStatementsReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.statements, ...extraParams),
      params,
    });
  }

  static getFinanceInventoryStatementsReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.financeInventoryStatement, ...extraParams),
      params,
    });
  }

  static exportInventoryStatementsReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportStatements, ...extraParams),
      params,
    });
  }

  static getInventoryStatementActualDeliveryReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.inventoryStatementActualDelivery, ...extraParams),
      params,
    });
  }

  static exportInventoryStatementActualDeliveryReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportInventoryStatementActualDelivery, ...extraParams),
      params,
      responseType: 'blob',
    });
  }

  static exportFinanceInventoryStatementsReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportFinanceInventoryStatements, ...extraParams),
      params,
      responseType: 'blob',
    });
  }

  static getInventoryReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.inventoryReport, ...extraParams),
      params,
    });
  }

  static exportInventoryReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportInventoryReport, ...extraParams),
      params,
      responseType: 'blob',
    });
  }

  static getSalesPerformanceReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.salesPerformanceReport, ...extraParams),
      params,
    });
  }

  static getSalesPerformanceDetailsReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.salesPerformanceDetails, ...extraParams),
      params,
    });
  }
}
export default InventoryReportService;
