import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { getInventoryData } from 'store/inventory/InventoryActions';
// api
import InventoryService from 'services/InventoryService';

const AddQtyModal = ({ isAddQtyModalOpen, setIsAddQtyModalOpen, defaultParams, damagedValue }) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [qty, setQty] = useState(1);
  const dispatch = useDispatch();

  const handleAddQty = async () => {
    setDisableBtn(true);

    const data = {
      barcode,
      qty,
      id: 0,
      isDamaged: damagedValue,
    };
    try {
      await InventoryService.addInventoryItemQty(data);

      setTimeout(() => {
        dispatch(getInventoryData(defaultParams));
        setDisableBtn(false);
        setIsAddQtyModalOpen((prev) => !prev);
        toast.success('Updated');
      }, 2000);
    } catch (err) {
      errorLogger('AddQtyModal:handleAddQty', err);
      setDisableBtn(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleQtyType = (damagedValue) => {
    switch (damagedValue) {
      case 0:
        return 'Add Qty';
      case 1:
        return 'Remove Qty';
      case 2:
        return 'Damaged Qty';
      default:
        return 'Variance Qty';
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddQtyModalOpen}
        toggle={() => setIsAddQtyModalOpen((prev) => !prev)}
        className="m-w-600"
      >
        <ModalBody>
          <Fragment>
            <div className="row">
              <div className="col-12 modal-header add-qty-title">
                <h6 className="primary">{handleQtyType(damagedValue)}</h6>
              </div>
              <div className="form-group col-12 col-sm-6">
                <label className="col-form-label" htmlFor="recipient-name">
                  Barcode
                </label>
                <input
                  onChange={(e) => setBarcode(e.target.value)}
                  value={barcode}
                  className="form-control"
                  type="text"
                />
              </div>
              <div className="form-group col-12 col-sm-6">
                <label className="col-form-label" htmlFor="recipient-name">
                  Qty
                </label>
                <input
                  onChange={(e) => setQty(e.target.value)}
                  value={qty}
                  className="form-control"
                  type="number"
                />
              </div>
            </div>
          </Fragment>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleAddQty} color="primary" disabled={!barcode || disableBtn}>
            Save
          </Button>
          <Button color="light" onClick={() => setIsAddQtyModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddQtyModal;
