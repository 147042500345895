import React from 'react';
// components
import PrimaryRecepientCalls from './PrimaryRecepientCalls';
import SecondaryRecepientCalls from './SecondaryRecepientCalls';

const ZiwoCallsContent = ({ recepientNumber, secondaryRecipientNumber, senderNumber }) => {
  return (
    <>
      <PrimaryRecepientCalls recepientNumber={recepientNumber} senderNumber={senderNumber} />
      {secondaryRecipientNumber && (
        <SecondaryRecepientCalls
          senderNumber={senderNumber}
          secondaryRecipientNumber={secondaryRecipientNumber}
        />
      )}
    </>
  );
};

export default ZiwoCallsContent;
