import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// components
import PageTitle from 'components/shared/PageTitle';
import FloristsDataGrid from 'components/administration/users/florists/FloristsDataGrid';
import UsersFilter from 'components/administration/users/UsersFilter';
import CreateUserModal from 'components/administration/users/modals/CreateUserModal';
import RestrictedSection from 'components/shared/RestrictedSection';
import AddExistingUserModal from 'components/administration/users/modals/AddExistingUserModal';
// constants
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import { userRoles } from 'constant/Enums';
import { getFloristsPageUrl } from 'constant/AppUrls';
// actions
import { getUsersListData } from 'store/user/UserActions';
// selectors
import { getUsersList } from 'store/user/UserSelectors';
import ResetPasswordModal from 'components/administration/users/pickers/ResetPasswordModal';

const FloristsPage = () => {
  const defaultParams = {
    searchKey: '',
    sortBy: 0,
    status: 0,
    userContractType: 0,
    type: 3,
  };
  const [inputValues, setInputValues] = useState(defaultParams);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const floristsListData = useSelector((state) => getUsersList({ state }));
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isExistingUserModalOpen, setIsExistingUserModalOpen] = useState(false);
  const [tempPassword, setTempPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(getUsersListData(queryObj));
    } else {
      dispatch(getUsersListData(inputValues));
    }
  }, []);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: getFloristsPageUrl(),
      search: queryString,
    });
    dispatch(getUsersListData(inputValues));
  };

  const fetchData = () => {
    dispatch(getUsersListData(inputValues));
  };

  const onModalToggle = () => setIsCreateUserModalOpen((prev) => !prev);
  const togglePasswordModal = () => setIsResetPasswordModalOpen((prev) => !prev);

  return (
    <AppErrorBoundary>
      <div className="toolbar-table-style">
        {isResetPasswordModalOpen && (
          <AppErrorBoundary>
            <ResetPasswordModal
              isResetModalOpen={isResetPasswordModalOpen}
              defaultPassword={tempPassword}
              onToggle={togglePasswordModal}
            />
          </AppErrorBoundary>
        )}
        <PageTitle title="Florists" />
        {isCreateUserModalOpen && (
          <AppErrorBoundary>
            <CreateUserModal
              isModalOpen={isCreateUserModalOpen}
              onToggle={onModalToggle}
              togglePasswordModal={togglePasswordModal}
              setTempPassword={setTempPassword}
              title="Add a new florist"
              userGroupType={userRoles[3].id}
              pageInputValues={inputValues}
            />
          </AppErrorBoundary>
        )}
        {isExistingUserModalOpen && (
          <AddExistingUserModal
            isExistingUserModalOpen={isExistingUserModalOpen}
            setIsExistingUserModalOpen={setIsExistingUserModalOpen}
            roleType={3}
            fetchData={fetchData}
          />
        )}
        <RestrictedSection page="admin_florists" action="add_florists">
          <div className="card">
            <div className="card-body row btn-showcase">
              <button
                className="btn btn-success mb-2 ml-3"
                onClick={() => setIsCreateUserModalOpen((prev) => !prev)}
              >
                <i className="fa fa-plus"></i> Add New
              </button>
              <button
                className="btn btn-info mb-2"
                onClick={() => setIsExistingUserModalOpen((prev) => !prev)}
              >
                <i className="fa fa-plus"></i> Add Existing User
              </button>
            </div>
          </div>
        </RestrictedSection>
        <AppErrorBoundary>
          <UsersFilter
            handleSubmitForm={handleSubmitForm}
            inputValues={inputValues}
            setInputValues={setInputValues}
            title="Florists"
          />
        </AppErrorBoundary>
        <FloristsDataGrid data={floristsListData} fetchData={fetchData} />
      </div>
    </AppErrorBoundary>
  );
};

export default FloristsPage;
