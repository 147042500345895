import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import { dateFormatDDMM, timeFormatHHMM } from 'constant/Helpers';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setKnetOrderHistory } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getKnetOrderHistoryData } from 'store/viewOrder/ViewOrderSelectors';

const KnetHistoryDataGrid = ({ orderId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const knetOrderHistory = useSelector((state) => getKnetOrderHistoryData({ state }));
  const ref = useRef();
  const dispatch = useDispatch();

  const fetchRecordedCall = async (id) => {
    setIsLoaderOpen(true);
    await dispatch(setKnetOrderHistory(id));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchRecordedCall(orderId);
  }, []);

  const orderIdTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <a href={`/order/${rowData.orderId}`} target="_blank" className="link" rel="noreferrer">
          {rowData.orderId}
        </a>
      </div>
    );
  };

  const dateTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>
          {dateFormatDDMM(rowData.resultDate)} {timeFormatHHMM(rowData.resultDate)}
        </p>
      </div>
    );
  };

  return (
    <div className="control-pane">
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="control-section">
          <GridComponent dataSource={knetOrderHistory} ref={ref} allowTextWrap={true}>
            <ColumnsDirective>
              <ColumnDirective field="reference" headerText="Reference" width={110} />
              <ColumnDirective field="paymentId" headerText="Payment ID" width={110} />
              <ColumnDirective field="transactionId" headerText="Transaction ID" width={110} />
              <ColumnDirective field="amount" headerText="Amount" width={100} />
              <ColumnDirective
                field="orderId"
                headerText="Order ID"
                template={orderIdTemplate}
                width={100}
              />
              <ColumnDirective field="result" headerText="Status" width={100} />
              <ColumnDirective field="trackId" headerText="Track ID" width={100} />
              <ColumnDirective
                field="resultDate"
                headerText="Result Date"
                template={dateTemplate}
                width={100}
              />
            </ColumnsDirective>
          </GridComponent>
        </div>
      )}
    </div>
  );
};

export default KnetHistoryDataGrid;
