import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { toast } from 'react-toastify';
// api
import CelebritiesService from '../../services/CelebritiesService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const setCelebritiesData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await CelebritiesService.getCelebritiesData(params);
    dispatch({
      type: 'GET_CELEBRITIES_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('CelebritiesActions:setCelebritiesData', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get celebrities data!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getCelebrityById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await CelebritiesService.getCelebrityById(id);
    dispatch({
      type: 'GET_CELEBRITY_BY_ID',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('CelebritiesActions:getCelebrityById', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get celebrity data!');
    dispatch(setLoaderOpenFalse());
  }
};

export const deleteCelebrity = (id, status, params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await CelebritiesService.deleteCelebrity(id, status);
    dispatch(setLoaderOpenFalse());
    toast.success(res?.data?.message ?? 'Deleted celebrity successfully!');
    dispatch(setCelebritiesData(params));
  } catch (err) {
    errorLogger('CelebritiesActions:deleteCelebrity', err);
    toast.error(err?.response?.data?.message ?? 'Failed to delete celebrity data!');
    dispatch(setLoaderOpenFalse());
  }
};
