import React from 'react';

const InlineErrorMessage = ({ message }) => {
  return (
    <div className="inline-error">
      <i className="fa fa-exclamation-triangle requird-asterisks" aria-hidden="true"></i>
      <span className="requird-asterisks ml-1">{message}</span>
    </div>
  );
};

export default InlineErrorMessage;
