import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import DJHPagination from 'components/shared/Pagination';
import LocalAvilabilityDataGrid from './LocalAvilabilityDataGrid';
import CatalogFilter from './CatalogFilter';
import CatalogActions from './CatalogActions';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import { toast } from 'react-toastify';
import PageTitle from 'components/shared/PageTitle';
// actions
import {
  setLocalAvailabilityList,
  setExportMasterCatalog,
} from 'store/masterCatalog/MasterCatalogActions';
import { setProductCategoriesFilterData } from 'store/products/ProductsActions';
// selectors
import { getLocalAvailabilityData } from 'store/masterCatalog/MasterCatalogSelectors';
import { getProductCategories } from 'store/products/ProductsSelectors';

const LocalAvailabilityDataTable = ({ inputValues, setInputValues, title, isTotalCatalog }) => {
  const localAvailabilityData = useSelector((state) => getLocalAvailabilityData({ state }));
  const categoriesFilterList = useSelector((state) => getProductCategories({ state }));
  const [qty, setQty] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(setLocalAvailabilityList(queryObj));
      setInputValues(queryObj);
      setQty(queryObj.qty === -1 ? '' : queryObj.qty);
    } else {
      dispatch(setLocalAvailabilityList(inputValues));
    }
    dispatch(setProductCategoriesFilterData());
  }, []);

  const onPageChange = (i) => {
    dispatch(setLocalAvailabilityList({ ...inputValues, page: i, qty: qty || -1 }));
    document.documentElement.scrollTop = 0;
  };

  const handleSubmit = () => {
    if (inputValues.priceTo >= 0) {
      const queryString = convertObjectIntoQueryParams({ ...inputValues, qty: qty || -1 });
      navigate({
        pathname: location.pathname,
        search: queryString,
      });
      dispatch(setLocalAvailabilityList({ ...inputValues, qty: qty || -1 }));
    } else {
      toast.error('Price should be greater than 0');
    }
  };

  const handleExportCatalog = () => {
    dispatch(setExportMasterCatalog(inputValues));
  };

  return (
    <div className="quick-actions-table">
      <PageTitle title="Master Catalog" />
      <h5>
        Page {localAvailabilityData.currentPage} |{' '}
        <span className={title === 'Draft' || title === 'Archive' ? 'red' : ''}>
          {localAvailabilityData.rowCount} {title}
        </span>
      </h5>
      <CatalogActions
        inputValues={inputValues}
        totalCatalog={isTotalCatalog}
        handleExportCatalog={handleExportCatalog}
      />
      <CatalogFilter
        categoriesFilterList={categoriesFilterList}
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        setQty={setQty}
        qty={qty}
        location={location}
      />
      <LocalAvilabilityDataGrid
        data={localAvailabilityData?.data}
        params={inputValues}
        totalCatalog={isTotalCatalog}
      />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+localAvailabilityData.currentPage}
        pageSize={+localAvailabilityData.pageSize}
        itemsCount={+localAvailabilityData.rowCount}
      />
    </div>
  );
};

export default LocalAvailabilityDataTable;
