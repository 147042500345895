// api end points
export const endPoints = {
  order: `order/{0}`,
  calender: `/Order/calender/{0}`,
  preorderCalendar: `/order/hub/calendar/{0}`,
  expressDeliveryCalendar: `/Order/calendar/{0}`,
  notDispatchedCalender: `order/calendar/not-dispatched`,
  dispatchedLastHourCalendar: `order/calendar/dispatched`,
  premiumCalendarOrders: `Order/calender/total/premiumorders`,
  dashboardSlots: `Calendar/orders/timeslot`,
  home: `/opscenter`,
  product: `catalog/hub/product/total`,
  signIn: `/user/hub/{0}`,
  signInSlider: `/catalog/hub/Product/random`,
  logOut: `/user/hub/users/logout`,
  hub: `/hub/{0}`,
  msLoginAuth: `user/authentication/sso/{0}/session`,
  // Picker & Packer
  createUser: `/user/hub/UserDetails`,
  editUser: `/user/hub/UserDetails/{0}`,
  getUserDetails: `/user/hub/UserDetails/{0}`,
  getAllRoles: `/user/hub/UserDetails/roles`,
  updateRole: `/user/hub/UserDetails/{0}/roles/{1}`,
  deleteRole: `/user/hub/UserDetails/{0}/roles/{1}`,
  changeUserStatus: `/user/hub/UserDetails/{0}/status`,
  getUserRoles: `/user/hub/UserDetails/{0}/roles`,
  //dashboard
  dashboardTotalOrder: `order/TotalOrders`,
  dashboardTodayRegisteredUsers: `order/TodayRegisteredUsers`,
  dashboardRegisteredUsers: `order/RegisteredUsers`,
  dashboardTimeSlots: `order/hub/orders/timeslot`,
  dashboardTimeSlotsWithDate: `order/hub/orders/timeslotwithdate`,
  dashboardTodayTotalOrders: `order/TodayTotalOrders`,
  dashboardTotalUsers: `order/TotalUsers`,
  dashboardCorporateOrders: `order/total/corporate`,
  dashboardTimeSlotsPrintStatus: `order/printed-not-dispatched`,
  // orderAction: `orders/list`,
  searchProduct: `catalog/hub/product/search/items`,
  createBulkProduct: `order/BulkOrders/create`,
  orderAction: `order/hub/orders`,
  quickPrinting: `/order/hub/print`,
  orderDetails: `order/hub/orders/{0}/details`,
  orderInvoice: `invoicing/{0}/invoice`,
  searchOrder: `order/hub/orders/search`,
  orderKnetHistory: `payment/hub/transactions/{0}/knet`,
  getUserAudit: `order/hub/{0}/audit/log`,
  timeSlot: `order/country/timeslot`,
  detailsPrinted: `order/UpdateDetailsPrinted/{0}/{1}`,
  userAgent: `user/hub/Agent`,
  filterAreas: `order/hub/address/mainarea`,
  cartDetails: `order/hub/cart/{0}/cartdetails`,
  cardMessage: `order/hub/card/{0}`,
  setSalesOfficer: `orders/{0}/sales-officer/{1}`,
  editOrderCost: `orders/{0}/corporate/cost`,
  // editCard: `order/hub/card/{0}`,
  // addCard: `order/hub/card/{0}`,
  AddNote: `order/hub/orders/{0}/note`,
  customerCalls: `order/hub/communication/Calls/{0}`,
  inventoryTrans: `order/GetAllOrderStatments/{0}`,
  editInventory: `order/updateOrderStatments/{0}`,
  actionsBox: `order/hub/orders/{0}/logs`,
  getActionBoxLogs: `logging/hub/{0}/order`,
  driver: `order/hub/driver/{0}`,
  searchDriver: `order/hub/driver/search/{0}`,
  validateBeforeAssign: `order/{0}/validate-before-assign`,
  addDriver: `drivers/hub/trips/{0}/assign-order`,
  updateDriverStatus: `drivers/hub/trips/{0}/{1}`,
  adjustLog: `addjust/{0}`,
  // adjustData: `orders/CallBack/{0}`,
  deliveryTimeSlot: `order/slots`,
  deliveryPremiumTimeSlot: `hub/order/premium/slots`,
  updateDate: `order/update/deliverydateandtime/{0}`,
  markPaid: `order/Paid/{0}`,
  orderType: `order/type/{0}`,
  updateCstep: `order/hub/update/Status/{0}`,
  generatePaymentLink: `order/Payment/{0}`,
  getLinkRequest: `order/Paymentrequest/{0}`,
  getComments: `order/Comment/{0}`,
  getAddress: `Address/map/drop/`,
  getAreasList: `order/hub/address/area`,
  getCountriesList: `Country`,
  addCredit: `order/wallet/policy/{0}`,
  getOpsList: `order/AvailbleOps/{0}`,
  getAllOps: `/hub/ops`,
  checkOps: `orders/Check`,
  changeOps: `order/{0}/move`,
  sendEmail: `order/Confirmemail/{0}`,
  updateAddress: `order/hub/Address/{0}`,
  addExtraShippingInfo: `/hub/Address/{0}/extranumber`,
  orderedItems: `order/OrderedItems`,
  orderPriority: `hub/order/priority`,
  coordinates: `order/hub/delivery/coordinates`,
  getQuickOrdersData: `orders/quicklist`,
  exportQuickOrders: `orders/quicklist/export`,
  exportAllOrders: `/order/hub/orders/export`,
  getDuplicatedAndReplacedOrders: `orders/duplicates`,
  //driver page
  driversList: `order/hub/driver/list`,
  addDriverData: `order/hub/driver/add`,
  updateDriver: `order/hub/driver/update/{0}`,
  exportDriversData: `order/hub/driver/list/export`,
  getDriversVehiclesTypes: `drivers/vehicles`,
  //delivery slots
  getAllDeliverySlots: `order/hub/slots/all`,
  addNewDeliverySlot: `order/hub/Slots/add`,
  updateDeliveySlot: `order/hub/Slots/{0}`,
  updateOrderStatus: `order/status/{0}`,
  permissionPages: `user/hub/permissions/pages`,
  permissionPageById: `user/hub/permissions/page/{0}`,
  permissionPageActions: `user/hub/permissions/page/action`,
  permissionGroups: `user/hub/permissions/groups`,
  permissionsGroupActions: `user/hub/permissions/group/action/{0}`,
  getGroupUsersList: `user/hub/permissions/group/{0}/users`,
  permissionsCopyGroup: `user/hub/permissions/group/action/{0}/copy`,
  permissionSearchUsers: `user/hub/search`,
  searchForFlowardUsers: `user/hub/floward/users`,
  getUserInfoById: `user/hub/{0}`,
  getUserBalance: `user/wallet/{0}/balance`,
  getUserPointsById: `loyalty/hub/user/{0}`,
  getUserPointsHistoryById: `loyalty/hub/user/{0}/statements`,
  addUserToGroup: `user/hub/permissions/user/{0}/group`,
  userPermissions: `user/hub/Permissions/user`,
  refund: `order/{0}/refund`,
  clearWallet: `user/wallet/clear/{0}`,
  addUserBalance: `user/wallet/addbalance`,
  earnPoints: `order/Earn/Points/{0}`,
  getUserLastOrders: `order/hub/order/{0}/last/userorders`,
  getUserLastAddresses: `order/hub/order/{0}/useraddress`,
  shareSenderDetails: `order/hub/orders/{0}/sharedetails`,
  getDriverOrders: `order/hub/driver/{0}/totalorders`,
  getRecipientOrders: `/order/hub/orders/{0}/recipientorders`,
  getUsersOrders: `/order/hub/orders/{0}/userorders`,
  getUsersTransactions: `/user/wallet/all/transaction`,
  getUserLatestTransactions: `/user/wallet/last/transaction`,
  sendLocationLink: `/order/whats/cm`,
  exportCm: `orders/export/cm`,
  createNewOrder: `order/hub/orders/new`,
  createNewPremiumOrder: `hub/order/new/premium`,
  getPrevAddressList: `/order/hub/address/{0}`,
  updatePrevAddress: `order/hub/address/update/{0}/{1}`,
  getRecipientTotalAddress: `order/hub/address/total`,
  getRecipientListAddress: `order/hub/address/list`,
  updateRecipientListAddress: `order/hub/address/update/{0}/{1}`,
  getUserTotalOrdersList: `order/hub/orders/userorders`,
  getRecepientTodayOrders: `order/hub/orders/recipient/todayorders`,
  getAddInventorySearchList: `order/hub/Inventory/search`,
  addOrderDetailsInventoryItem: `order/hub/Inventory/update/order`,
  getEditItemsOrderDetails: `order/hub/cart/{0}/list`,
  getEditItemsPriceInfo: `order/hub/orders/details`,
  editItemsSearchItem: `catalog/hub/product/search`,
  editItemsSearchPremiumItem: `catalog/hub/product/search/premium`,
  addCustomItem: `catalog/hub/product/add/custom`,
  updateItemStatus: `order/hub/cart/{0}/status`,
  selectEditOrderItem: `order/hub/Cart/{0}`,
  addHouseImage: `order/hub/address/upload/houseimage`,
  duplicateOrder: `order/hub/orders/{0}/duplicate`,
  updateDeliveryCharge: `order/hub/orders/{0}/delivery/charge`,
  timeSlotReason: `order/hub/orders/deliveryreason`,
  requestOtp: `user/hub/{0}/request/otp`,
  userTempPassword: `user/hub/{0}/request/password`,
  saveUserDetails: `user/hub/{0}`,
  userSavedCC: `user/hub/SavedCards/{0}`,
  //admin messages
  adminMessage: `logging/hub/messages`,
  //notifications
  getOldNotifications: `logging/hub/orders`,
  getOrderOldNotifications: `logging/hub/orders/{0}`,
  acknowledgeNotifications: `logging/hub/{0}/aka`,
  totalOrdersZone: `hub/order/total/zone`,
  //products
  getProductCategoriesFilter: `catalog/hub/categories/filters`,
  getProductCategories: `catalog/hub/categories/all`,
  getProductMainCategories: `catalog/hub/categories/master`,
  getProductAttributesFilter: `catalog/hub/product/attributes`,
  getProductExcludedSlots: `order/hub/slots/ops`,
  getProductExcludedSlotsById: `catalog/hub/product/{0}/excluded/slots`,
  updateProductExcludedSlotsById: `catalog/hub/product/{0}/excluded/slots/{1}`,
  getProductCategoriesById: `catalog/hub/product/{0}/categories`,
  addProductCategoryById: `catalog/hub/product/{0}/{1}/assign`,
  getProductMCById: `catalog/hub/MerchandisingCategory/{0}/product`,
  deleteProductCategoryById: `catalog/hub/product/{0}/{1}/category`,
  getProductAttributesById: `catalog/hub/product/{0}/attributes`,
  addProductAttributeById: `catalog/hub/product/{0}/attributes/{1}`,
  deleteProductAttributeById: `catalog/hub/product/{0}/attributes/{1}`,
  //product data by id
  getProductRankingById: `catalog/hub/ProductOptions/{0}`,
  getProductDetailsById: `catalog/hub/product/{0}/details`,
  getProductOptionsById: `catalog/hub/product/{0}/options`,
  updateProductOptionsById: `catalog/hub/product/{0}/options`,
  getLabelList: `catalog/hub/uiattributes/list`,
  getProductLabel: `catalog/hub/uiattributes`,
  updateProductLabel: `catalog/hub/uiattributes/{0}`,
  getProductAvailabilityById: `catalog/hub/productpricing/{0}/ops`,
  updateProductAvailability: `catalog/hub/productpricing/{0}`,
  updateOpsMasterAvailability: `catalog/hub/ProductPricing/{0}`,
  getProductInventoryById: `catalog/hub/product/{0}/inventory`,
  updateProductMargin: `hub/inventory/{0}/product/map/margin`,
  getProductMediaById: `catalog/hub/product/{0}/media`,
  updateProductMediaById: `catalog/hub/product/{0}/images`,
  reorderProductImages: `catalog/hub/product/images`,
  deleteProductMediaById: `catalog/hub/product/{0}/images/{1}`,
  getProductMasterInventoryById: `catalog/hub/inventory/{0}/product/master`,
  deleteProductMasterInventoryItem: `hub/inventory/{0}/product/master`,
  editProductMasterInventoryQty: `hub/inventory/{0}/product/master/{1}`,
  searchProductMasterInventoryItem: `hub/inventory/master/search`,
  addNewProductMasterInventoryItem: `hub/inventory/{0}/master/assign`,
  getProductGroupsById: `catalog/hub/product/{0}/basket`,
  getProductExcludedDaysById: `catalog/hub/product/{0}/excluded/days`,
  excludeDay: `catalog/hub/product/{0}/excluded/days/{1}`,
  addGroupBasket: `catalog/hub/product/{0}/basket`,
  editGroupBasket: `catalog/hub/product/{0}/basket/{1}`,
  addGroupBasketItem: `hub/product/{0}/basket/item`,
  editGroupBasketItem: `hub/product/basket/item`,
  searchBasketProduct: `hub/product/basket/search`,
  getProductSizes: `catalog/hub/ProductSizePricing/{0}`,
  addProductSize: `catalog/hub/ProductSizePricing/{0}`,
  getDuplicateProduct: `hub/product/copy`,
  //subscriptions
  getAllSubscriptions: `order/hub/Subscription/all`,
  subscriptionsBilling: `order/hub/Subscription/{0}/billing`,
  updateSubscriptions: `order/hub/subscription/{0}`,
  subscriptionsDetails: `order/hub/Subscription/{0}/details`,
  applyCopoun: `/hub/order/{0}/coupon`,
  //admin notifications messages
  getAdminNotificationsMessages: `notification/hub/messages`,
  //user profile
  getUserProfileInfo: `user/hub/users/{0}/profile`,
  //user corporate
  updateUserCorporate: `user/hub/users/{0}/corporate/profile`,
  //user activities
  getUserActivitiesById: `logging/hub/user/{0}`,
  //ticketing topics
  getTicketingTopicsList: `ticketing/hub/topics`,
  createNewTicket: `ticketing/hub/ticket/create`,
  createTechTicket: `ticketing/hub/ticket/techticket`,
  getTicketsList: `ticketing/hub/tickets`,
  getTicketsListByOrderId: `ticketing/hub/tickets/order/{0}`,
  getTicketById: `ticketing/hub/tickets/{0}`,
  addNewTicketComment: `ticketing/hub/ticket/comment`,
  getTicketCommentsList: `ticketing/hub/tickets/{0}/threads`,
  closeThread: `ticketing/hub/ticket/close`,
  assignUserToThread: `ticketing/hub/ticket/assign`,
  uploadAttachments: `ticketing/hub/ticket/upload/{0}`,
  //trip
  getTripDriversList: `hub/driver/orders/trip`,
  getTripDriverOrdersList: `hub/driver/{0}/trip/orders`,
  sortDriverOrdersList: `hub/driver/{0}/sort/manually`,
  //customer communications
  getCustomerCommunicationCases: `order/hub/communication/manually/options`,
  sendCustomerCommunicationMessage: `order/hub/communication/manually/options/send/{0}`,
  //premium collecting gift
  getPremiumGiftByOrderId: `CollectionItems/{0}`,
  addPremiumGift: `CollectionItems/{0}/items`,
  deletePremiumGift: `CollectionItems/215002/item`,
  //checkout
  getCheckoutPaymentDetails: `payment/Validator/{0}/card/details`,
  getCheckoutLogsDetails: `payment/Validator/{0}/details`,
  //customer service
  getCustomerService: `order/hub/order/customerservice`,
  // payment link
  getPaymentLink: 'payment/hub/Payment/generate-link',
  //ziwo
  getZiwoCallsLogs: `cs/calls`,
  getZiwoCallRecording: `cs/recording`,
  makeZiwoCall: 'order/hub/ziwo/cm',
  //favorite flowers
  getFavFlowersData: `catalog/hub/SubscriptionFavorites/{0}`,
  //inventory
  getAllInventoryData: `catalog/hub/inventory/all`,
  getAllItemsInventoryData: `/catalog/hub/Inventory/all/item`,
  getAllItemsAllOpsInventoryData: `/catalog/hub/Inventory/ops`,
  getInventorySuppliersList: `catalog/hub/Inventory/suppliers`,
  addInventoryItem: `catalog/hub/inventory/add`,
  editInvenoryItem: `catalog/hub/inventory/edit`,
  uploadInvenoryFile: `catalog/hub/inventory/upload`,
  addInventoryQty: `catalog/hub/inventory/update`,
  getInventoryStatements: `catalog/hub/inventory/{0}/statment`,
  getInventorySuppliers: `catalog/hub/Suppliers`,
  updateInventorySupplier: `catalog/hub/Suppliers/{0}`,
  exportInventoryItems: `catalog/hub/Inventory/all/export`,
  copyToBasket: `catalog/hub/Inventory/{0}/copybasketitem`,
  getInventoryCareById: `catalog/hub/inventorycaredetails/{0}`,
  addInventoryCareById: `catalog/hub/inventorycaredetails`,
  getInventoryItemDetailsById: `catalog/hub/inventory/{0}/inventoryitem`,
  getAlertQtyInventoryItems: `catalog/hub/Inventory/low-stock`,
  getAlertQtyStatus: `catalog/hub/inventory/alert`,
  exportAlertQtyInventoryData: `catalog/hub/Inventory/low-stock/export`,
  exportAllInventoryItems: `/catalog/hub/Inventory/all/item/export`,
  exportAllInventoryAllOpsItems: `/order/hub/reports/Inventory/ops/export`,
  exportMasterInventoryData: `/catalog/hub/inventoryparent/search/export`,
  //master-inventory
  getMasterInventoryList: `catalog/hub/inventoryparent/search`,
  getMasterInventoryById: `catalog/hub/inventoryparent/{0}`,
  getMasterInventoryChildren: `catalog/hub/inventoryparent/{0}/inventoryitems`,
  //occasions
  occasionsList: `catalog/hub/categories/all/occasion`,
  occcasionsCardslist: `card/hub/occasions/uncategorized`,
  savedCardOccasion: `card/hub/occasions/uncategorized/{0}`,
  getOccasionsPredictions: `predictor/predict`,
  //categories
  getCategoriesData: `catalog/hub/Categories/list`,
  getSubCategoriesData: `catalog/hub/Categories/{0}/Sub`,
  createNewCategory: `catalog/hub/Categories/new`,
  getCategoryOpsData: `catalog/hub/Categories/{0}/ops/available`,
  editCategory: `catalog/hub/Categories/{0}`,
  //Merchandising categories
  getMerchandisingCategoriesData: `catalog/hub/MerchandisingCategory`,
  //attributes
  getAttributesData: 'catalog/hub/attributes',
  getSubAttributesData: 'catalog/hub/attributes/{0}/values',
  createNewAttribute: 'catalog/hub/attributes',
  createNewSubAttribute: 'catalog/hub/attributes/values',
  getAttributeByIdData: 'catalog/hub/attributes/{0}',
  getSubAttributeByIdData: 'catalog/hub/attributes/values/{0}',
  getMCAttributes: 'catalog/hub/MerchandisingCategory/attributes?{0}',
  editAttribute: '',
  //late orders
  lateOrdersBalanceList: `order/hub/order/late`,
  approveLateOrderBalance: `user/wallet/lateorders`,
  // Covered areas
  getCoveredAreasList: `/home/hub/OperationCoveredAreas/areas?page={0}`,
  setCoveredAreas: `/home/hub/OperationCoveredAreas`,
  //Delivery areas
  getAreas: `home/hub/areas`,
  getAreaById: `home/hub/areas/{0}`,
  getSubAreas: `home/hub/areas/{0}/subareas`,
  getSubAreaById: `home/hub/areas/{0}/subareas/{1}`,
  //banners
  getBannerList: `home/hub/Banners/types/{0}`,
  addBanner: `home/hub/Banners`,
  editBanner: `home/hub/Banners/{0}`,
  opsCities: `Banners/ops`,
  getExcludedOps: `home/hub/Banners/{0}/exclude`,
  addExcludedOps: `home/hub/Banners/{0}/exclude/{1}`,
  removeExcludedOps: `home/hub/Banners/excluded/{0}`,
  //florists
  getFloristsList: `user/hub/florist`,
  updateFlorist: `user/hub/florist/{0}`,
  //Delivery postcodes
  getPostCodes: `order/hub/PostCodeCoverage`,
  getPostCodesById: `order/hub/PostCodeCoverage/{0}`,
  //drivers expenses
  getDriverExpensesData: `order/hub/driver/expenses`,
  exportDriverExpensesData: `order/hub/driver/expenses/export`,
  updateDriverExpensesStatus: `order/hub/driver/{0}/expense/{1}`,
  addNewDriverExpenses: `order/hub/driver/{0}/expense`,
  // Drivers radius
  driverRadius: `/home/hub/opscenter/radius`,
  //florist community
  getFloristCommunityDesigns: `community/hub/bouquets`,
  getFloristCommunityDesignById: `community/hub/bouquets/{0}`,
  getFloristCommunityUser: `user/hub/users/{0}`,
  approveFloristDesign: `community/hub/bouquets/{0}/approve`,
  rejectFloristDesign: `community/hub/bouquets/{0}/reject`,
  getFloristCommunityProduct: `catalog/hub/product/search`,
  getFloristCommunityTransactionList: `community/hub/paymentrequests`,
  getFloristCommunityTransactionById: `community/hub/paymentrequests/{0}`,
  assignFloristCommunityTransaction: `community/hub/paymentrequests/{0}/assign`,
  changeFloristCommunityTransactionStatus: `community/hub/paymentrequests/{0}/status`,
  floristCommunityTransactionComment: `community/hub/paymentrequestcomments/{0}`,
  //vouchers
  getAllCopouns: `order/coupons`,
  getVouchersCatList: `order/hub/reports/Voucher/Category`,
  getCouponById: `order/coupons/{0}`,
  getCreateNewCoupon: `order/coupons`,
  generateCouponCode: `order/coupons/codes`,
  getVoucherLimitCat: `order/coupons/{0}/voucher/categories`,
  getRemoveVoucherLimitCat: `order/coupons/voucher/categories/{0}`,
  searchVoucherLimitCat: `order/coupons/{0}/categories`,
  createVoucherLimitedCat: `order/coupons/voucher/categories`,
  //master catalog
  getLocalAvailabilityList: `catalog/hub/product/list`,
  addProduct: `catalog/hub/product`,
  getSyncMasterCaltalogProduct: `catalog/hub/product/{0}/sync`,
  getIndividualProductData: `catalog/hub/product/{0}/sync/status`,
  getProductLog: `logging/hub/ProductLog/{0}`,
  exportProductLog: `logging/hub/ProductLog/{0}/export`,
  uploadRankingFile: `catalog/hub/product/ranking/update/csv`,
  updateStatusBulk: `catalog/hub/product/bulk/update/status`,
  updateTypeBulk: `catalog/hub/product/bulk/update/type`,
  updateCategoriesBulk: `/catalog/hub/product/bulk/update/category`,
  updateMCbulk: `catalog/hub/MerchandisingCategory/products/bulk/update`,
  getExportMasterCatalog: `catalog/hub/product/list/export`,
  getUncategorizedMerch: `/catalog/hub/product/uncategorized`,
  getExportUncategorizedMerch: `/catalog/hub/product/uncategorized/export`,
  //celebrities
  getCelebrities: `catalog/hub/celebrities`,
  getCelebrityById: `catalog/hub/celebrities/{0}`,
  updateCelebrity: `catalog/hub/celebrities/{0}/{1}`,
  //kpi
  getSalesPerformanceByCountry: `order/hub/reports/kpi/summary/country`,
  getSalesPerformance: `order/hub/reports/kpi/summary/orderType`,
  getSalesPerformanceByChannel: `order/hub/reports/kpi/summary/channel`,
  //ranking products
  rankByType: `catalog/hub/product/rank/{0}`,
  //country slots
  countrySlots: `order/hub/orders/{0}/timeslots`,
  // Order invoices
  orderInvoices: `invoicing/{0}/invoices`,
  // Key Values
  getAllOrCreateKeyValue: `hub/KeyValues`,
  updateKeyValue: `hub/KeyValues/{0}`,
  updateKeyValueStatus: `hub/KeyValues/{0}/status`,
  //Seasonal prep
  seasonalCategories: `catalog/hub/categories/static`,
  getSeasonalData: `catalog/hub/seasonal`,
  getSeasonalProducts: `catalog/hub/Seasonal/missing`,
  updateSeasonalProduct: `catalog/hub/seasonal/{0}`,
  addSeasonalProducts: `catalog/hub/seasonal/add`,
  importSeasonalProducts: `catalog/hub/seasonal/upload`,
  exportSeasonalProducts: `catalog/hub/seasonal/export`,
  // Invoices
  getAllInvoices: `invoicing/invoices`,
  getCorporateInvoices: `invoicing/corporate-invoices`,
  getConsolidatedInvoices: `invoicing/corporate/consolidate`,
  getGeneratedConsolidatedInvoices: `invoicing/corporate/all`,
  lockConsolidatedInvoice: 'invoicing/corporate/{0}/lock/{1}',
  deleteConsolidatedInvoice: 'invoicing/corporate/{0}',
  //shorterlink
  getGenerateShorterLink: `short`,
  //Orders QuickActions Bulk Actions
  getShipOnFleet: `order/onfleet/{0}/task`,
  getShipOnFleetBulkAction: `order/OnFleet/task`,
  setOrdersPriority: '/order/hub/bulk/priority',
  setOrdersPrintStatus: '/order/hub/bulk/print',
  getZones: 'home/hub/areas/zones',
  //nw delivery
  getSwapToNwDelivery: `order/{0}/shipping/nw-london`,
  //user default addresses
  getUserAddressesList: `order/hub/address/user`,
  updateDefaultAddress: `order/hub/address/{0}/default`,
  //users Activity
  getUsersActivity: `/logging/hub/logging/users-activities`,
  exportUsersActivity: `/logging/hub/logging/users-activities/export`,
  addExistingUser: `/user/hub/UserDetails/assign-existing`,
  removeUserRole: `/user/hub/UserDetails/remove`,
  //coverage areas
  coverageAreasList: `home/hub/OperationCoveredAreas`,
  deleteCoverageArea: `home/hub/OperationCoveredAreas/{0}`,
  //pickers & packers
  getUsersList: `user/hub/UserDetails`,
  resetUserPassword: `/user/hub/UserDetails/{0}/password/reset`,
  // Production System
  getProductionProducts: `/catalog/hub/product/production`,
  setProductionProducts: `/prodapp/hub/production/bysku`,
  getProductionData: `prodapp/hub/production`,
  updateProductJob: `prodapp/hub/production/{0}`,
  exportProductionData: `prodapp/hub/production/export`,
  updateJobStatus: `prodapp/hub/production/status`,
  getJobDetails: `prodapp/hub/production/{0}`,
  getProductLimitId: `/catalog/hub/categories/validator/product-id`,
  assignBulkOccasionProduct: `/catalog/hub/categories/bulk/occasion`,
  featuredOccasions: {
    actionById: 'catalog/hub/featuredOccasions/{0}',
    getAll: 'catalog/hub/featuredOccasions',
    sort: 'catalog/hub/featuredOccasions/sort',
    create: 'catalog/hub/featuredOccasions',
    status: 'catalog/hub/featuredOccasions/status',
    activeCollections: 'catalog/hub/featuredOccasions/active-collections',
  },
  // user feedback
  getAllUserFeedbackReasons: 'order/hub/rating/reasons',
  createUserFeedbackReason: 'order/hub/rating/reasons',
  updateUserFeedbackReason: 'order/hub/rating/reasons/{0}',
};
