import React, { useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { CardBody } from 'reactstrap';
import { dateFormatDDMM } from 'constant/Helpers';
// api
import ViewOrderService from 'services/ViewOrderService';
// material ui
import ClickAwayListener from '@mui/base/ClickAwayListener';
import debounce from 'lodash/debounce';
import CustomSelect from 'components/shared/CustomSelect';

const tableHeader = [
  'Order',
  'Customer',
  'Ops',
  'Order Status',
  'Payment Status',
  'Cstep',
  'Delivery Date',
];

const SearchHeader = () => {
  const options = [
    { value: '0', label: 'order / sender' },
    { value: '1', label: 'by receipient' },
    // { value: "2", label: "by sender" },
  ];

  const [searchValue, setSearchValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [searchData, setSearchData] = useState('');
  const [searchLoader, setSearchLoader] = useState(false);
  const [isSearchByType, setIsSearchByType] = useState(false);

  const defaultParams = {
    Searchkey: '',
    type: selectedOption.value ? selectedOption.value : 0,
  };

  const delayedHandleChange = debounce(async (keyword) => {
    setSearchValue(keyword);
    setSearchLoader(true);
    try {
      const res = await ViewOrderService.getSearchOrder(defaultParams);
      setSearchData(res.data);
      setSearchLoader(false);
    } catch (e) {
      toast.error('No results match this keyword');
      setSearchLoader(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    if (keyword) {
      addFix();
    } else {
      removeFix();
    }
    if (keyword.length >= 3) {
      defaultParams.Searchkey = keyword;
      defaultParams.type = selectedOption.value;
      delayedHandleChange(keyword);
    }
  };

  const addFix = () => {
    document.querySelector('.Typeahead-menu').classList.add('is-open');
    document.body.classList.add('offcanvas');
  };

  const removeFix = () => {
    // setSearchValue('');
    if (isSearchByType) {
      setIsSearchByType(false);
    } else {
      document.querySelector('.Typeahead-menu').classList.remove('is-open');
      document.body.classList.remove('offcanvas');
    }
  };

  const handleSearchBy = (e) => {
    setIsSearchByType(true);
    addFix();
    const option = {
      value: e.itemData.value,
      label: e.itemData.label,
    };
    setSelectedOption(option);
    defaultParams.type = e.itemData.value;
    defaultParams.Searchkey = searchValue;
    if (searchValue?.length >= 3) {
      delayedHandleChange(searchValue);
    }
  };

  return (
    <ClickAwayListener onClickAway={removeFix}>
      <div className="ltr">
        <form className="form-inline search-form">
          <div
            className={`form-group searchHeader-select ltr ${
              window.screen.width < 500 ? 'search-mobile' : ''
            }`}
          >
            <input
              className="form-control-plaintext search-header"
              type="text"
              placeholder="Search order, sender or recepient"
              defaultValue={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div
              style={{
                height: searchLoader ? 200 : '',
              }}
              className="Typeahead-menu custom-scrollbar"
              id="search-outer"
            >
              {searchLoader ? (
                <div id="loading" className="theme-color">
                  <i className="fa fa-search"></i>
                </div>
              ) : (
                <Fragment>
                  <CardBody className="recent-notification">
                    <div className="table-responsive shopping-table text-center">
                      <table className="table table-bordernone">
                        <thead>
                          <tr>
                            {tableHeader.map((col, i) => (
                              <th key={i} scope="col no-wrap">
                                {col}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {searchData.length > 0 &&
                            searchData.map((item, i) => {
                              return (
                                <tr
                                  className="search-table-row pointer"
                                  key={i}
                                  onClick={() => {
                                    removeFix();
                                    window.open(`/order/${item.id}`, '_blank');
                                  }}
                                >
                                  <td>{item.id}</td>
                                  <td>
                                    <p className="m-0 block-display">{item.customer.name}</p>
                                    <p className="m-0 block-display">{item.customer.phone}</p>
                                  </td>

                                  <td className="around-flex">
                                    <p className="m-0">{item.ops.titleEn}</p>
                                    {/* <img
                                      className="header-ops-img"
                                      alt=""
                                      src={item.ops.iconFullPath}
                                    /> */}
                                  </td>
                                  <td>{item.statusString}</td>
                                  <td>{item.paymentSting}</td>
                                  <td>{item.cStepString}</td>
                                  <td>
                                    {item.deliveryDate ? dateFormatDDMM(item.deliveryDate) : ''}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Fragment>
              )}
            </div>
            <div className="Typeahead-menu empty-menu">
              <div className="tt-dataset tt-dataset-0">
                <div className="EmptyMessage">{'Opps!! There are no result found.'}</div>
              </div>
            </div>
            <CustomSelect
              listData={options}
              handleChange={handleSearchBy}
              placeholder="Search by"
              textPropName="label"
              valuePropName="value"
              selectedValue={selectedOption.value}
            />
          </div>
        </form>
      </div>
    </ClickAwayListener>
  );
};

export default SearchHeader;
