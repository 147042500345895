import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { errorLogger } from 'datadog/DDUtils';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import RestrictedSection from 'components/shared/RestrictedSection';
// api
import MasterCatalogService from 'services/MasterCatalogService';
// selectors
import { getSelectedProductsItems } from 'store/masterCatalog/MasterCatalogSelectors';
import {
  getProductAllCategoriesData,
  getEverydayOccasionsSubList,
  getEmotionsSubList,
  getSpecialOccasionsSubList,
  getFestivalsSubList,
} from 'store/products/ProductsSelectors';
// actions
import {
  setProductCategoriesData,
  getEverydaySubCategories,
  getEmotionsSubCategories,
  getSpecialOccasionsSubCategories,
  getFestivalsSubCategories,
} from 'store/products/ProductsActions';

const BulkCategoriesModal = ({ isBulkCategoryModalOpen, setIsBulkCategoryModalOpen }) => {
  const selectedProducts = useSelector((state) => getSelectedProductsItems({ state }));
  const allCategoriesListData = useSelector((state) => getProductAllCategoriesData({ state }));
  const everydayOccasionsSubList = useSelector((state) => getEverydayOccasionsSubList({ state }));
  const emotionsSubList = useSelector((state) => getEmotionsSubList({ state }));
  const specialOccasionsSubList = useSelector((state) => getSpecialOccasionsSubList({ state }));
  const festivalsSubList = useSelector((state) => getFestivalsSubList({ state }));
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const catField = { text: 'name', value: 'id' };
  const dispatch = useDispatch();

  const handleAddCategory = (e) => {
    const clonedList = cloneDeep(selectedCategories);
    const updatedList = [...clonedList, ...e.value].filter((c, index) => {
      return [...clonedList, ...e.value].indexOf(c) === index;
    });
    setSelectedCategories(updatedList);
  };
  const handleDeleteCategory = (e) => {
    const clonedList = cloneDeep(selectedCategories);
    const updatedList = clonedList.filter((item) => item !== e.itemData.id);
    setSelectedCategories(updatedList);
  };

  const handleBulkCategoriesSubmit = async () => {
    setDisableBtn(true);
    const data = {
      ProductIds: selectedProducts,
      CategoryIds: selectedCategories,
    };
    try {
      const res = await MasterCatalogService.bulkCategoriesUpdate(data);
      toast.success(res?.data?.message ?? 'Categories and Occasions were updated successfully');
      setDisableBtn(false);
      setIsBulkCategoryModalOpen(false);
    } catch (err) {
      errorLogger('BulkCategoriesModal:handleBulkCategoriesSubmit', err);
      toast.error(err?.response?.data?.message ?? 'Something went wrong');
      setDisableBtn(false);
    }
  };

  useEffect(() => {
    dispatch(setProductCategoriesData());
    dispatch(getEverydaySubCategories());
    dispatch(getEmotionsSubCategories());
    dispatch(getSpecialOccasionsSubCategories());
    dispatch(getFestivalsSubCategories());
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isBulkCategoryModalOpen}
        toggle={() => setIsBulkCategoryModalOpen((prev) => !prev)}
        className="m-w-900"
      >
        <ModalHeader>Add Categories and Occasions</ModalHeader>
        <ModalBody>
          <div className="form-group col-12">
            <form className="row">
              <div className="col-6 categories-field-box">
                <label className="bold-title" htmlFor="bulk-type">
                  Types
                </label>
                <div className="control-pane">
                  <AppErrorBoundary>
                    <MultiSelectComponent
                      id="bulk-type"
                      change={handleAddCategory}
                      removing={handleDeleteCategory}
                      value={selectedCategories.filter((el) => el.type === 8).map((el) => el.id)}
                      dataSource={allCategoriesListData.filter((el) => el.type === 8)}
                      mode="Box"
                      fields={catField}
                      placeholder="Select Type"
                      showDropDownIcon={true}
                    />
                  </AppErrorBoundary>
                </div>
              </div>
              <div className="col-6 categories-field-box">
                <label className="bold-title" htmlFor="bulk-addon">
                  Add-ons
                </label>
                <div className="control-pane">
                  <AppErrorBoundary>
                    <MultiSelectComponent
                      id="bulk-addon"
                      change={handleAddCategory}
                      removing={handleDeleteCategory}
                      value={selectedCategories.filter((el) => el.type === 6).map((el) => el.id)}
                      dataSource={allCategoriesListData.filter((el) => el.type === 6)}
                      mode="Box"
                      fields={catField}
                      placeholder="Select Add-on"
                      showDropDownIcon={true}
                    />
                  </AppErrorBoundary>
                </div>
              </div>
              <div className="col-6 categories-field-box">
                <label className="bold-title" htmlFor="bulk-occasion">
                  Everyday Occasions
                </label>
                <div className="control-pane">
                  <AppErrorBoundary>
                    <MultiSelectComponent
                      id="bulk-occasion"
                      change={handleAddCategory}
                      removing={handleDeleteCategory}
                      value={selectedCategories.filter((el) => el.type === 7).map((el) => el.id)}
                      dataSource={everydayOccasionsSubList}
                      mode="Box"
                      fields={catField}
                      placeholder="Select Occasions"
                      showDropDownIcon={true}
                    />
                  </AppErrorBoundary>
                </div>
              </div>
              <div className="col-6 categories-field-box">
                <label className="bold-title" htmlFor="bulk-emotion">
                  Emotions and Sentiments
                </label>
                <div className="control-pane">
                  <AppErrorBoundary>
                    <MultiSelectComponent
                      id="bulk-emotion"
                      change={handleAddCategory}
                      removing={handleDeleteCategory}
                      value={selectedCategories.filter((el) => el.type === 7).map((el) => el.id)}
                      dataSource={emotionsSubList}
                      mode="Box"
                      fields={catField}
                      placeholder="Select Occasions"
                      showDropDownIcon={true}
                    />
                  </AppErrorBoundary>
                </div>
              </div>
              <div className="col-6 categories-field-box">
                <label className="bold-title" htmlFor="bulk-special">
                  Special Occasions
                </label>
                <div className="control-pane">
                  <AppErrorBoundary>
                    <MultiSelectComponent
                      id="bulk-special"
                      change={handleAddCategory}
                      removing={handleDeleteCategory}
                      value={selectedCategories.filter((el) => el.type === 7).map((el) => el.id)}
                      dataSource={specialOccasionsSubList}
                      mode="Box"
                      fields={catField}
                      placeholder="Select Occasions"
                      showDropDownIcon={true}
                    />
                  </AppErrorBoundary>
                </div>
              </div>
              <div className="col-6 categories-field-box">
                <label className="bold-title" htmlFor="bulk-festival">
                  Festivals
                </label>
                <div className="control-pane">
                  <AppErrorBoundary>
                    <MultiSelectComponent
                      id="bulk-festival"
                      change={handleAddCategory}
                      removing={handleDeleteCategory}
                      value={selectedCategories.filter((el) => el.type === 7).map((el) => el.id)}
                      dataSource={festivalsSubList}
                      mode="Box"
                      fields={catField}
                      placeholder="Select Occasions"
                      showDropDownIcon={true}
                    />
                  </AppErrorBoundary>
                </div>
              </div>
              <div className="col-6 categories-field-box">
                <label className="bold-title" htmlFor="bulk-promotion">
                  Promotions
                </label>
                <div className="control-pane">
                  <AppErrorBoundary>
                    <MultiSelectComponent
                      id="bulk-promotion"
                      change={handleAddCategory}
                      removing={handleDeleteCategory}
                      value={selectedCategories.filter((el) => el.type === 7).map((el) => el.id)}
                      dataSource={allCategoriesListData.filter((el) => el.type === 3)}
                      mode="Box"
                      fields={catField}
                      placeholder="Select Occasions"
                      showDropDownIcon={true}
                    />
                  </AppErrorBoundary>
                </div>
              </div>
              <RestrictedSection page="edit_product" action="can_update_collections_categories">
                <div className="col-6 categories-field-box">
                  <label className="bold-title" htmlFor="bulk-collection">
                    Collection
                  </label>
                  <div className="control-pane">
                    <AppErrorBoundary>
                      <MultiSelectComponent
                        id="bulk-collection"
                        change={handleAddCategory}
                        removing={handleDeleteCategory}
                        value={selectedCategories.filter((el) => el.type === 9).map((el) => el.id)}
                        dataSource={allCategoriesListData.filter((el) => el.type === 9)}
                        mode="Box"
                        fields={catField}
                        placeholder="Select Collection"
                        showDropDownIcon={true}
                      />
                    </AppErrorBoundary>
                  </div>
                </div>
              </RestrictedSection>
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleBulkCategoriesSubmit}
            disabled={disableBtn || selectedCategories.length === 0}
          >
            Save
          </Button>
          <Button color="light" onClick={() => setIsBulkCategoryModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BulkCategoriesModal;
