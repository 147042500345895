/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// components
import RestrictedSection from 'components/shared/RestrictedSection';

// sub sub menu item (has children) with permission
const PermissionedSubSubMenu = ({ per, clicked, title, Icon, action }) => (
  <RestrictedSection page={per} action={action}>
    <a onClick={() => clicked()} className="pointer">
      <i className={`fa ${Icon} sidebar-icon`} />
      <span>{title}</span>
      <i className="fa fa-angle-right pull-right" />
    </a>
  </RestrictedSection>
);

export default PermissionedSubSubMenu;
