import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { CardBody, CardHeader } from 'reactstrap';
import RestrictedSection from 'components/shared/RestrictedSection';
// actions
import { setRequestGeneratedLink } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ProductTabs = ({ orderId, adjustLog, adjustData, orderData, generatedLinkData }) => {
  const [showGenerateBtn, setShowGenerateBtn] = useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (orderData.status === 0 && orderData.paymentSt === 0) ||
      (orderData.status === 2 && orderData.paymentMethod === 0)
    ) {
      setShowGenerateBtn(true);
    }
    if (generatedLinkData !== '') {
      setShowPaymentInfo(true);
    }
  }, []);

  const generatePaymentLink = async () => {
    try {
      const res = await ViewOrderService.generatePaymentLink(orderId);
      dispatch(setRequestGeneratedLink(orderId));
      if (res.data.isSuccess) {
        setShowPaymentInfo(true);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (e) {
      errorLogger('ProductTabs:generatePaymentLink', e);
      toast.error('Failed to generate payment link');
    }
  };

  return (
    <div className="col-sm-12">
      <RestrictedSection page="order_details" action="payment_link">
        <div className="row product-tabs-wrapper">
          <div className="col-12">
            <div className="card">
              <CardHeader>
                <h4>Payment Link</h4>
              </CardHeader>

              <CardBody className="recent-notification">
                {showGenerateBtn && (
                  <button onClick={generatePaymentLink} className="btn btn-success" type="button">
                    <i className="fa fa-cc-visa"></i> Generate Payment Link
                  </button>
                )}
                {showPaymentInfo && generatedLinkData && (
                  <div>
                    <h5
                      className="payment-link"
                      onClick={() => {
                        navigator.clipboard.writeText(generatedLinkData.paymentUrl);
                        toast.success('Copied');
                      }}
                    >
                      <strong>Payment Link:</strong>{' '}
                      <p className="generated-pay-link" target="_blank">
                        {generatedLinkData !== '' && generatedLinkData.paymentUrl}
                      </p>
                    </h5>
                    <h5 className="generated-date">
                      {' '}
                      <strong>Generated at:</strong>{' '}
                      {`${generatedLinkData.createdDate.slice(
                        0,
                        10
                      )}@${generatedLinkData.createdDate.slice(11, 19)} `}
                    </h5>
                  </div>
                )}
              </CardBody>
            </div>
          </div>
        </div>
      </RestrictedSection>
    </div>
  );
};

export default ProductTabs;
