export const localStorageKeys = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  OPS_ID: 'opsId',
  COUNTRY_ID: 'countryId',
  OPS_CENTER: 'opsCenter',
  IS_MUTED: 'isMuted',
  PERMISSIONS: 'permissions',
  CURRENCY: 'currency',
  NAV_BG_COLOR: 'navBgColor',
};

export const orderStatus = {
  0: 'Not_Confirmed',
  1: 'Delivered',
  2: 'Confirmed',
  3: 'Canceled',
  4: 'Under_Delivery',
  5: 'Refound',
  6: 'Pending_Cancellation',
  7: 'PreOrderPending',
};

export const orderCstepIcon = {
  1: 'https://cdn.floward.com/web/Files/attachment/ordered-637470803806403588.png',
  2: 'https://cdn.floward.com/web/Files/attachment/preparing-637470804521413792.png',
  3: 'https://cdn.floward.com/web/Files/attachment/delivering-637468244443154127.png',
  4: 'https://cdn.floward.com/web/Files/attachment/delivered-637470804169416023.png',
  5: 'https://cdn.floward.com/web/Files/attachment/picked-637468243940293727.png',
  6: 'https://cdn.floward.com/web/Files/attachment/collecting-address-637470804342571218.png',
  7: 'https://cdn.floward.com/web/Files/attachment/ready-637468244284382412.png',
  8: 'https://cdn.floward.com/web/Files/attachment/returned-637468245302753472.png',
  9: 'https://cdn.floward.com/web/Files/attachment/icons8-in_transit-16-637497660137696407.png',
};

export const flagIcon = {
  trueIcon:
    'https://cdn.floward.com/web/Files/attachment/icons8-in_transit-15-637497660015920932.png',
  falseIcon:
    'https://cdn.floward.com/web/Files/attachment/icons8-in_transit-16-637497660137696407.png',
};

export const orderTypes = [
  { name: 'Sales', value: 0 },
  { name: 'Corporate - Subscription', value: 1 },
  { name: 'Corporate - Event', value: 2 },
  { name: 'Corporate - OnDemand', value: 3 },
  { name: 'User - Subscription', value: 4 },
  { name: 'Celebrity', value: 5 },
];

export const paymentMethod = {
  0: 'Cash',
  1: 'Knet',
  2: 'Visa',
  3: 'Master',
  4: 'CreditCard',
  5: 'PayPal',
  6: 'Mada',
  7: 'FLWCredit',
  8: 'None',
  9: 'ApplePay',
  10: 'Benefit',
  11: 'KSA_STCPay',
};

// eslint-disable-next-line no-warning-comments
//TODO To be refactored into map for readibility
export const cStep = {
  // 0: "Add Items",
  1: 'Ordered',
  2: 'Preparing',
  3: 'Delivering',
  4: 'Delivered',
  5: 'Picked',
  6: 'Collecting Address',
  7: 'Ready',
  8: 'Returned',
  9: 'Failed Delivery',
};

export const cStepArray = [
  { id: 1, name: 'Ordered' },
  { id: 2, name: 'Preparing' },
  { id: 3, name: 'Delivering' },
  { id: 4, name: 'Delivered' },
  { id: 5, name: 'Picked' },
  { id: 6, name: 'Collecting Address' },
  { id: 7, name: 'Ready' },
  { id: 8, name: 'Returned' },
  { id: 9, name: 'Failed Delivery' },
  { id: 10, name: 'Looking for driver' },
];

export const productStatusList = [
  { id: 0, name: 'Draft' },
  { id: 1, name: 'Published' },
  { id: 2, name: 'Archived' },
];

export const productTypeList = [
  { id: 0, name: 'Product' },
  { id: 1, name: 'Designer' },
  { id: 2, name: 'Brands' },
  { id: 3, name: 'Subscriptions' },
  { id: 4, name: 'AdsOn' },
  { id: 5, name: 'Basket' },
  { id: 6, name: 'Custom Items' },
  { id: 7, name: 'Celebrity' },
  { id: 8, name: 'Product with basket' },
  { id: 9, name: 'Bundle' },
  { id: 10, name: 'Product with variants' },
  { id: 11, name: 'Variant' },
];

export const FeaturedOccasionColorList = [
  { colorHex: '', colorName: 'None (not selected)' },
  { colorHex: '#FCF4F2', colorName: 'Light Salmon ' },
  { colorHex: '#FEF7EB', colorName: 'Light Yellow' },
  { colorHex: '#F7F9F2', colorName: 'Light Green' },
  { colorHex: '#EBF2F5', colorName: 'Light Blue' },
  { colorHex: '#FFF4F6', colorName: 'Light Pink' },
  { colorHex: '#F5F6FF', colorName: 'Light Magenta' },
];

export const categoriesColorsList = [
  { colorHex: '', colorName: 'None (not selected)' },
  { colorHex: '#D1DFBB', colorName: 'Light green' },
  { colorHex: '#C6D6A6', colorName: 'Light green 2' },
  { colorHex: '#F8CC7C', colorName: 'Light yellow' },
  { colorHex: '#DDB873', colorName: 'Gold' },
  { colorHex: '#F1CFB4', colorName: 'Light orange' },
  { colorHex: '#EDC7B8', colorName: 'Light orange 2' },
  { colorHex: '#E9B8A5', colorName: 'Light orange 3' },
  { colorHex: '#FFB4C1', colorName: 'Rose' },
  { colorHex: '#BDBFFF', colorName: 'Light blue' },
  { colorHex: '#84B8C4', colorName: 'Teal' },
  { colorHex: '#7AA5BB', colorName: 'Aqua' },
  { colorHex: '#8D2F2D', colorName: 'red' },
];

export const categoriesTypesList = [
  { id: 0, name: 'Category' },
  { id: 1, name: 'Designers' },
  { id: 2, name: 'Occasion' },
  { id: 3, name: 'Campaign' },
  { id: 4, name: 'Brand' },
  { id: 5, name: 'Subscriptions' },
  { id: 6, name: 'Ads On' },
  { id: 7, name: 'Category Occasion' },
  { id: 8, name: 'Product Type' },
  { id: 9, name: 'Collection' },
];

export const paymentSt = {
  0: ' Not_Paid',
  1: 'Paid',
};

export const paymentStatusBtn = {
  0: 'Unpaid',
  1: 'Paid',
};

export const printStatusType = [
  { id: 0, name: 'Not-Printed', status: false },
  { id: 1, name: 'Printed', status: true },
];

export const priorityStatusType = [
  { id: 0, name: 'Normal' },
  { id: 1, name: 'High' },
];

export const driversSort = [
  { id: 4, name: 'None (not selected)' },
  { id: 0, name: 'Last Seen (Newest)' },
  { id: 1, name: 'Last Seen (Oldest)' },
  { id: 2, name: 'Created Date (Newest)' },
  { id: 3, name: 'Created Date (Oldest)' },
  { id: 5, name: 'Current Orders (Greater)' },
];

export const driversType = [
  { id: 0, name: 'Insource' },
  { id: 1, name: 'Outsource' },
  { id: 2, name: 'Freelancer' },
];

export const vehiclesTypeEnum = {
  0: 'Van',
  1: 'Jeep',
  2: 'Car',
  3: 'Truck',
};

export const userRoles = [
  { id: 0, name: 'Hub' },
  { id: 1, name: 'Picker' },
  { id: 2, name: 'Packer' },
  { id: 3, name: 'Florist' },
];

export const pickersType = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Insource' },
  { id: 2, name: 'Outsource' },
];

export const pickersStatus = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'Inactive' },
];

export const pickersSortBy = [
  { id: 0, name: 'None' },
  { id: 1, name: 'Created Date (Newest)' },
  { id: 2, name: 'Created Date (Oldest)' },
];

export const inventoryTypes = [
  { id: -1, name: 'All' },
  { id: 0, name: 'Uncategorized' },
  { id: 1, name: 'Ceramic_Vase' },
  { id: 2, name: 'Glass_Vase' },
  { id: 3, name: 'Basket' },
  { id: 4, name: 'Packaging' },
  { id: 5, name: 'Flower' },
  { id: 6, name: 'Accessory' },
  { id: 7, name: 'Brand' },
  { id: 8, name: 'Chocolate' },
  { id: 9, name: 'Balloon' },
  { id: 10, name: 'Perfumes' },
  { id: 11, name: 'Glass_Standard_Vase' },
  { id: 12, name: 'Wrapping' },
  { id: 13, name: 'Ribbons' },
  { id: 14, name: 'Dummy' },
  { id: 15, name: 'Tray' },
  { id: 16, name: 'Plants' },
];

export const sortingList = [
  { id: 0, name: 'Product' },
  { id: 1, name: 'Target' },
  { id: 2, name: 'Ready' },
  { id: 3, name: 'Ordered' },
  { id: 4, name: 'Dispatched' },
  { id: 5, name: 'To be sold' },
  { id: 6, name: 'In workshop ops' },
];

export const prevAddressHeader = ['Order', 'Recipient Name', 'Recipient Phone', 'Area', 'Address'];

export const countryCode = (opsId) => {
  switch (opsId) {
    case 1:
      return 'kw';
    case 2:
      return 'sa';
    case 3:
      return 'sa';
    case 4:
      return 'bh';
    case 5:
      return 'qa';
    case 6:
      return 'sa';
    case 7:
      return 'ae';
    case 8:
      return 'sa';
    case 9:
      return 'ae';
    case 10:
      return 'gb';
    case 11:
      return 'sa';
    case 12:
      return 'sa';
    case 13:
      return 'ae';
    case 14:
      return 'ae';
    case 15:
      return 'ae';
    case 16:
      return 'sa';
    case 17:
      return 'ae';
    case 19:
      return 'om';
    default:
      return 'kw';
  }
};

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const employeeGrades = [
  { title: 'C level', id: 0 },
  { title: 'Head / Director', id: 1 },
  { title: 'Managers', id: 2 },
  { title: 'Admin', id: 3 },
  { title: 'Operations', id: 4 },
];

export const brailleIcon =
  'https://cdn.floward.com/web/Files/attachment/png-transparent-braille-drawing-jorge-garces-borrero-departmental-library-alphabet-directional-signage-text-hand-logo-thumbnail-637827589478805537.png';

export const attributeTypesList = [
  {
    name: 'Options',
    id: 0,
  },
  {
    name: 'Text',
    id: 1,
  },
  {
    name: 'Label',
    id: 2,
  },
];

export const productionStatus = [
  { id: 0, label: 'Not Started', bgColor: '#FFF1DF', textColor: '#F4AA24' },
  { id: 1, label: 'Released', bgColor: '#C7F1FF', textColor: '#006782' },
  { id: 2, label: 'In Progress', bgColor: '#FEFFD3', textColor: '#AC9100' },
  { id: 3, label: 'In Progress', bgColor: '#FEFFD3', textColor: '#AC9100' },
  { id: 4, label: 'Stopped', bgColor: '#D64D4D', textColor: '#FFFFFF' },
  { id: 5, label: 'Done', bgColor: '#E2FEEA', textColor: '#689900' },
];

export const productionReasons = [
  { id: 0, name: 'Damaged PU' },
  { id: 1, name: 'Missing PU' },
  { id: 2, name: 'Transferred PU' },
  { id: 3, name: 'Disassemble PU' },
];
