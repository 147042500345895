import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardBody, CardHeader } from 'reactstrap';
// modals and components
import DriverModal from './modals/DriverModal';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import CustomSelect from 'components/shared/CustomSelect';
import RestrictedSection from 'components/shared/RestrictedSection';
import { driverTableColums } from './viewOrderCommons';
import { convertToDateAndTime, convertSecondsIntoTime } from 'constant/Helpers';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
// actions
import { setDriverData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';
import PreOrderWarning from './PreOrderWarning';

const Drivers = ({ driverData, orderId, orderCstep, orderStatus }) => {
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const dispatch = useDispatch();

  const disabledDeliveryStatus = [2, 3, 4, 7];

  const handleUpdateDriver = async (e, driverID, currentStatus) => {
    const dataBody = {
      status: e.itemData.id,
    };
    let res;
    try {
      res = await ViewOrderService.updateDriverStatus(dataBody, orderId, e.itemData.dataId);
      toast.success(res?.data?.message);
      setTimeout(() => {
        dispatch(setDriverData(orderId));
      }, 1500);
    } catch (err) {
      const clonedDriverData = cloneDeep(driverData);
      clonedDriverData.find((item) => item.driverID == driverID).status = currentStatus;
      dispatch({
        type: 'SET_DRIVER_DATA',
        payload: [],
      });
      dispatch({
        type: 'SET_DRIVER_DATA',
        payload: clonedDriverData,
      });
      toast.error(err?.response?.data?.message);
      errorLogger('Drivers:handleUpdateDriver', err);
    }
  };

  const isPreOrderPending = orderStatus === 7 || false;

  return (
    <div className="row drivers-wrapper">
      {isDriverModalOpen && (
        <AppErrorBoundary>
          <DriverModal
            isDriverModalOpen={isDriverModalOpen}
            setIsDriverModalOpen={setIsDriverModalOpen}
            orderId={orderId}
          />
        </AppErrorBoundary>
      )}
      <RestrictedSection page="order_details" action="drivers">
        <PreOrderWarning isPreOrderPending={isPreOrderPending} />
        <div className="col-12">
          <div className="card">
            <CardHeader className="card-header-border">
              <h5>Drivers</h5>
            </CardHeader>
            <CardBody className="recent-notification">
              <div className="table-responsive shopping-table text-center driver-section">
                <table className="table table-bordernone">
                  <thead>
                    <tr>
                      {driverTableColums.map((col, i) => (
                        <th key={i} scope="col">
                          {col}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {driverData.length > 0 && (
                    <tbody>
                      {driverData.map((data, i) => (
                        <tr key={i}>
                          <td>
                            <p>{data.name}</p> <p>{data.phone}</p>
                          </td>

                          {data.pickedDate && <td>{convertToDateAndTime(data.pickedDate)}</td>}
                          <td className="filter-select">
                            <CustomSelect
                              listData={[
                                { id: 0, name: 'Picked', dataId: data.id },
                                { id: 1, name: 'Delivering', dataId: data.id },
                                { id: 2, name: 'Delivered', dataId: data.id },
                                { id: 3, name: 'Canceled', dataId: data.id },
                                { id: 4, name: 'Canceled (Removed)', dataId: data.id },
                                { id: 6, name: 'Failed Delivery', dataId: data.id },
                                { id: 7, name: 'Unloaded', dataId: data.id },
                              ]}
                              handleChange={(e) =>
                                handleUpdateDriver(e, data.driverID, data.status)
                              }
                              placeholder="Select status"
                              textPropName="name"
                              valuePropName="id"
                              disabled={
                                disabledDeliveryStatus.includes(data.status) || orderCstep === 4
                              }
                              selectedValue={data.status}
                            />
                          </td>
                          <td>{data.endDate && convertToDateAndTime(data.endDate)}</td>
                          <td>
                            <p>
                              {data.differentSeconds &&
                                convertSecondsIntoTime(data.differentSeconds)}
                            </p>
                            {data.late && (
                              <button className={'btn generic-status btn-light-danger'}>
                                Late
                              </button>
                            )}
                          </td>
                          <td>{data.comment}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                <div className="col-12 add-driver-btn-wrapper">
                  {!isPreOrderPending ? (
                    <button
                      onClick={() => setIsDriverModalOpen((prev) => !prev)}
                      className="btn btn-success"
                      type="button"
                      disabled={orderCstep === 4}
                    >
                      <i className="fa fa-plus"></i> Add New
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </CardBody>
          </div>
        </div>
      </RestrictedSection>
    </div>
  );
};

export default Drivers;
