import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class AuthService {
  static SignInUser(email, password, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.signIn, ...extraParams),
      data: { email, password },
    });
  }

  static logOut() {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.logOut),
    });
  }

  static getUserPermissions(params, header) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.userPermissions),
      params,
      headers: { Authorization: `Bearer ${header}` },
    });
  }

  static getSliderImg(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.signInSlider, ...extraParams),
    });
  }

  static getMsLoginAuth(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.msLoginAuth, ...extraParams),
    });
  }
}
export default AuthService;
