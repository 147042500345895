import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import LiveProductsDataGrid from 'components/reports/products/liveProducts/LiveProductsDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import DJHPagination from 'components/shared/Pagination';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import ProductsReportService from 'services/reportsService/ProductsReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const LiveProductsPage = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [rowCount, setRowCount] = useState('');
  const [inputValues, setInputValues] = useState({
    catalogPage: 6,
    page: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await ProductsReportService.getLiveProductsReport(params);
      setData(res.data.data.data);
      setCurrentPage(res.data.data.currentPage);
      setPageSize(res.data.data.pageSize);
      setRowCount(res.data.data.rowCount);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Live Products Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: '/report/LiveProducts',
      search: queryString,
    });
    fetchData(inputValues);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);

    navigate({
      pathname: '/report/LiveProducts',
      search: queryString,
    });
    fetchData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  const handleExport = async () => {
    const updatedParams = { ...inputValues, page: -1 };
    try {
      const res = await ProductsReportService.exportLiveProductsReport(updatedParams);
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('Live Products Report Page Export Data Error:', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Live Products"
        catalogPage
        noDate
        hasExport
        handleExport={handleExport}
      />
      <LiveProductsDataGrid data={data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </div>
  );
};

export default LiveProductsPage;
