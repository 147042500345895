import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import RestrictedSection from 'components/shared/RestrictedSection';
import CreateProductGroupModal from '../modals/CreateProductGroupModal';
import CreateGroupItemModal from '../modals/CreateGroupItemModal';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { localStorageKeys } from 'constant/Enums';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { getProductGroupsById } from 'store/products/ProductsActions';
// api
import ProductsService from 'services/ProductsService';
// selector
import { getProductGrpupsData } from 'store/products/ProductsSelectors';

const ProductBasketSection = ({ productId, productOptionsById }) => {
  const [isAddProductGroupModalOpen, setIsAddProductGroupModalOpen] = useState(false);
  const [isEditProductGroupModalOpen, setIsEditProductGroupModalOpen] = useState(false);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [isEditItemModalOpen, setIsEditItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [disableAddGroupt, setDisableAddGroupt] = useState(false);
  const groups = useSelector((state) => getProductGrpupsData({ state }));
  const dispatch = useDispatch();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const deleteGroup = async (id) => {
    dispatch(setLoaderOpenTrue());
    try {
      await ProductsService.deleteProductGroup(productId, id);
      toast.success('Group deleted successfully');
      dispatch(setLoaderOpenFalse());
      dispatch(getProductGroupsById(productId));
    } catch (err) {
      toast.error('Failed to delete group!');
      dispatch(setLoaderOpenFalse());
    }
  };

  const deleteItem = async (id) => {
    dispatch(setLoaderOpenTrue());
    try {
      await ProductsService.deleteProductGroupItem(id);
      toast.success('Item deleted successfully');
      dispatch(setLoaderOpenFalse());
      dispatch(getProductGroupsById(productId));
    } catch (err) {
      toast.error('Failed to delete item!');
      dispatch(setLoaderOpenFalse());
    }
  };

  const addNewGroup = () => {
    if (productOptionsById?.type === 10) {
      if (groups.length > 0) {
        setDisableAddGroupt(true);
      } else {
        setIsAddProductGroupModalOpen((prev) => !prev);
      }
    } else {
      setIsAddProductGroupModalOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (productOptionsById?.type === 10 && groups.length > 0) {
      setDisableAddGroupt(true);
    } else {
      setDisableAddGroupt(false);
    }
  }, [groups, productOptionsById]);

  return (
    <AppErrorBoundary>
      <div>
        {isAddProductGroupModalOpen && (
          <CreateProductGroupModal
            isAddProductGroupModalOpen={isAddProductGroupModalOpen}
            setIsAddProductGroupModalOpen={setIsAddProductGroupModalOpen}
            productId={productId}
            productOptionsById={productOptionsById}
            type="add"
          />
        )}
        {isEditProductGroupModalOpen && (
          <CreateProductGroupModal
            isAddProductGroupModalOpen={isEditProductGroupModalOpen}
            setIsAddProductGroupModalOpen={setIsEditProductGroupModalOpen}
            productId={productId}
            type="edit"
            data={selectedGroup}
          />
        )}
        {isAddItemModalOpen && (
          <CreateGroupItemModal
            isAddItemModalOpen={isAddItemModalOpen}
            setIsAddItemModalOpen={setIsAddItemModalOpen}
            productId={productId}
            group={selectedGroup}
            productOptionsById={productOptionsById}
            type="add"
          />
        )}
        {isEditItemModalOpen && (
          <CreateGroupItemModal
            isAddItemModalOpen={isEditItemModalOpen}
            setIsAddItemModalOpen={setIsEditItemModalOpen}
            productId={productId}
            group={selectedGroup}
            type="edit"
            itemData={selectedItem}
          />
        )}
        <RestrictedSection page="edit_product" action="add_group_basket">
          <button
            onClick={() => addNewGroup()}
            type="button"
            className="btn btn-success new-product-group-btn"
            disabled={productOptionsById?.status === 1 || disableAddGroupt}
          >
            <i className="fa fa-plus"></i> New Group
          </button>
        </RestrictedSection>
        {groups.length > 0 &&
          groups.map(function (g, ind) {
            return (
              <div key={ind}>
                <div key={ind} className="product-group-container">
                  <div className="product-group-bg">
                    <p>{g.titleEn}</p>
                    <div className="product-group-actions">
                      <button
                        className="btn product-group-action btn-warning"
                        onClick={() => {
                          setSelectedGroup(g);
                          setIsEditProductGroupModalOpen((prev) => !prev);
                        }}
                        disabled={productOptionsById?.status === 1}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button
                        className="btn product-group-action btn-success"
                        onClick={() => {
                          setSelectedGroup(g);
                          setIsAddItemModalOpen((prev) => !prev);
                        }}
                        disabled={productOptionsById?.status === 1}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <button
                        className="btn product-group-action btn-danger"
                        onClick={() => deleteGroup(g.id)}
                        disabled={productOptionsById?.status === 1}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
                {g.productBasketItems.length > 0 &&
                  g.productBasketItems.map(function (item, itemInd) {
                    return (
                      <div key={itemInd} className="product-item-container">
                        <div className="product-item-bg">
                          <div
                            className="item-product-img"
                            style={{ backgroundImage: `URL(${item.coverImage})` }}
                          ></div>
                          <a
                            href={`/product/editProduct/${item.productId}/0`}
                            alt={item.productName}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.productName}
                          </a>
                          <p>
                            {parseFloat(item.price).toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                          </p>
                          <div className="product-group-actions">
                            <button
                              className="btn product-group-action btn-warning"
                              onClick={() => {
                                setSelectedGroup(g);
                                setSelectedItem(item);
                                setIsEditItemModalOpen((prev) => !prev);
                              }}
                              disabled={productOptionsById?.status === 1}
                            >
                              <i className="fa fa-edit"></i>
                            </button>
                            <button
                              className="btn product-group-action btn-danger"
                              onClick={() => deleteItem(item.id)}
                              disabled={productOptionsById?.status === 1}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
    </AppErrorBoundary>
  );
};

export default ProductBasketSection;
