import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';

const UserAddressesDataGrid = ({ data }) => {
  const toolbarOptions = ['Search'];
  const ref = useRef();

  const orderIdTemplate = (rowData) => (
    <a href={`/order/${rowData.orderId}`} target="_blank" rel="noreferrer">
      {rowData.orderId}
    </a>
  );

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          rowHeight={60}
          allowTextWrap={true}
          style={{ cursor: 'pointer' }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="OrderId"
              field="orderId"
              template={orderIdTemplate}
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Recipient Name"
              field="recipientName"
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Recipient Phone"
              field="recipientPhone"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Area" field="name" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Address"
              field="addressLineOne"
              width={150}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(UserAddressesDataGrid);
