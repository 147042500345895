import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
import SectionLoader from 'components/common/SectionLoader';

const BannersDataTable = React.lazy(() => import('components/banners/BannersDataTable'));

const BannersPage = (props) => {
  const [inputValues, setInputValues] = useState({
    checkStatus: '2',
    page: 1,
  });
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, [location.search]);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <BannersDataTable inputValues={inputValues} setInputValues={setInputValues} />
      </React.Suspense>
    </div>
  );
};

export default BannersPage;
