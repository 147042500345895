import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import SubAreasDataTable from 'components/delivery/areas/subAreas/SubAreasDataTable';
import { convertQueryParamsIntoObject } from 'constant/Helpers';

const SubAreasPage = () => {
  const [inputValues, setInputValues] = useState({
    page: 1,
  });
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, [location.search]);

  return (
    <div>
      <SubAreasDataTable inputValues={inputValues} setInputValues={setInputValues} />
    </div>
  );
};

export default SubAreasPage;
