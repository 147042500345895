import React, { useState } from 'react';
import DateRangePickerComponent from 'components/shared/DateRangePickerComponent';
import { dateFormat, convertToUnix } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
import { RefreshCw } from 'react-feather';

const StatementsFilter = ({ statementParams, setStatementParams, handleSubmit }) => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateRange = (item) => {
    const updatedParams = cloneDeep(statementParams);
    updatedParams.fromDate = convertToUnix(item.selection.startDate);
    updatedParams.toDate = convertToUnix(item.selection.endDate);
    setStatementParams(updatedParams);
    setDateRange(item.selection);
  };

  return (
    <div className="card">
      <div className="card-header p-18">
        <h5 className="sz-14">FILTER</h5>
      </div>
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group col-flex">
                <label className="bold-title" htmlFor="statement-range">
                  Date Range
                </label>
                <div className="normal-flex">
                  <input
                    id="statement-range"
                    className="form-control digits pointer bg-white"
                    value={`${dateFormat(dateRange.startDate)} - ${dateFormat(dateRange.endDate)}`}
                    onClick={() => setShowDatePicker((prev) => !prev)}
                    readOnly
                  />
                  <RefreshCw
                    style={{
                      position: 'absolute',
                      width: 20,
                      cursor: 'pointer',
                      right: 22,
                    }}
                    onClick={() => window.location.reload()}
                  />
                </div>

                {showDatePicker && (
                  <DateRangePickerComponent
                    setShowDatePicker={setShowDatePicker}
                    handleDateRange={handleDateRange}
                    dateRange={dateRange}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button onClick={handleSubmit} className="btn btn-primary mr-1" type="button">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StatementsFilter;
