export const getAddInventoryIsAddInventoryModal = ({ state }) =>
  state.addInventory.isAddInventoryModal;

export const getAddInventorySearch = ({ state }) => state.addInventory.search;

export const getAddInventoryBarcode = ({ state }) => state.addInventory.barcode;

export const getAddInventoryProduct = ({ state }) => state.addInventory.product;

export const getAddInventoryQuantity = ({ state }) => state.addInventory.quantity;

export const getAddInventorySearchInventoryList = ({ state }) =>
  state.addInventory.searchInventoryList;

export const getAddInventoryIsLoading = ({ state }) => state.addInventory.isLoading;

export const getAddInventoryIsSearching = ({ state }) => state.addInventory.isSearching;

export const isAddInventoryFormValid = ({ state }) => {
  const isBarcodeValid = getAddInventoryBarcode({ state }).valid;
  const isProductValid = getAddInventoryProduct({ state }).valid;
  const isQuantityValid = getAddInventoryQuantity({ state }).valid;

  return isBarcodeValid && isProductValid && isQuantityValid;
};
