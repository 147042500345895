import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import ProductTypeAttributeDataGrid from 'components/reports/products/productTypeAttribute/ProductTypeAttributeDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import ProductsReportService from 'services/reportsService/ProductsReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ProductTypeAttributePage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    datetype: 0,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await ProductsReportService.getProductTypeAttribute(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Product Type Attribute Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const updatedParams = cloneDeep(inputValues);
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: '/report/ProductTypeAttribute',
      search: queryString,
    });
    fetchData(updatedParams);
  };

  const handleExport = async () => {
    try {
      const updatedParams = cloneDeep(inputValues);
      const res = await ProductsReportService.exportGetProductTypeAttribute(updatedParams);
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('Product Type Attribute Report Page Export Data Error:', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        productType
        hasExport
        defaultDate
        handleExport={handleExport}
        title="Sales Product Type Attribute"
      />
      <ProductTypeAttributeDataGrid data={data} />
    </div>
  );
};

export default ProductTypeAttributePage;
