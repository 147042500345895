import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CelebrityForm from 'components/celebrities/CelebrityForm';
import PageTitle from 'components/shared/PageTitle';
// selectors
import { getSelectedCelebrity } from 'store/celebrities/CelebritiesSelectors';
// actions
import { getCelebrityById } from 'store/celebrities/CelebritiesActions';

const EditCelebrityPage = () => {
  const selectedCelebrityData = useSelector((state) => getSelectedCelebrity({ state }));
  const dispatch = useDispatch();
  const params = useParams();

  const fetchData = async () => {
    await dispatch(getCelebrityById(parseInt(params?.id, 10)));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <PageTitle title={`Edit ${selectedCelebrityData?.Name ?? ''} Celebrity`} />
      <div className="card">
        <CelebrityForm selectedCelebrity={selectedCelebrityData} type={'edit'} />
      </div>
    </>
  );
};

export default EditCelebrityPage;
