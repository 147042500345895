import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import CustomSelect from 'components/shared/CustomSelect';
// constants
import { convertObjectIntoQueryParams } from 'constant/Helpers';
// actions
import { setKeyValueList, setKeyValuesStatus } from 'store/keyValues/keyValueActions';
// selectors
import { getKeyValueStatus } from 'store/keyValues/keyValueSelectors';

const KeyValueFilter = () => {
  const optionList = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' },
  ];
  const status = useSelector((state) => getKeyValueStatus({ state }));
  const [filterStatus, setFilterStatus] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = () => {
    let params = { page: 1 };
    if (status !== 0) {
      params = { ...params, isactive: status === 1 };
    }
    const queryParams = params;
    const queryString = convertObjectIntoQueryParams(queryParams);
    navigate({
      pathname: location?.pathname,
      search: queryString,
    });
    dispatch(setKeyValueList(params));
  };

  const handleStatusChange = (e) => {
    dispatch(setKeyValuesStatus(e.itemData.id));
  };

  useEffect(() => {
    setFilterStatus(status);
  }, [status]);

  return (
    <div className="card">
      <div className="card-header key-value-card-header">
        <h4>Filter</h4>
      </div>
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="form-group filter-select">
                <label className="font-weight-bold" htmlFor="key-status">
                  Status
                </label>
                <CustomSelect
                  id="key-status"
                  listData={optionList}
                  handleChange={handleStatusChange}
                  placeholder="Select a status"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={filterStatus}
                />
              </div>
            </div>
            <div className="col-12 offset-md-5 col-md-2 filter-btn-div">
              <button onClick={() => handleSubmit()} className="btn btn-primary mr-1" type="button">
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default KeyValueFilter;
