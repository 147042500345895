import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// api
import PermissionService from 'services/PermissionService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const NotifficationsMessagesModal = ({
  isNotificationsModalOpen,
  setIsNotificationsModalOpen,
  isEditMessage,
  editedMessage,
  setIsEditMessage,
  setMessagesList,
}) => {
  const [key, setKey] = useState(isEditMessage ? editedMessage.key : '');
  const [locale, setLocale] = useState(isEditMessage ? editedMessage.locale : '');
  const [message, setMessage] = useState(isEditMessage ? editedMessage.message : '');
  const [target, setTarget] = useState(isEditMessage ? editedMessage.target : '');
  const [title, setTitle] = useState(isEditMessage ? editedMessage.title : '');
  const [isActive, setIsActive] = useState(isEditMessage ? editedMessage.isActive : false);
  const [disableBtn, setDisableBtn] = useState(false);

  const AddNewNotificationMessage = async () => {
    const dataBody = {
      Id: editedMessage.id,
      Key: key,
      Message: message,
      Locale: locale,
      target,
      Title: title,
      isActive,
    };
    setDisableBtn(true);
    if (!isEditMessage) {
      try {
        const res = await PermissionService.adminAddNotificationsMessages(dataBody);
        toast.success('New message added');
        setDisableBtn(false);
        setIsNotificationsModalOpen((prev) => !prev);
      } catch (err) {
        errorLogger('NotificationsMessagesModal:AddNewNotificationMessage', err);
        toast.error('Failed to add');
      }
    } else {
      try {
        const res = await PermissionService.adminUpdateNotificationsMessages(dataBody);
        toast.success('Message updated');
        setDisableBtn(false);
        setIsNotificationsModalOpen((prev) => !prev);
      } catch (err) {
        errorLogger('NotificationsMessagesModal:AddNewNotificationMessage', err);
        toast.error('Failed to update');
      }
    }
    setTimeout(async () => {
      const res = await PermissionService.adminGetNotificationsMessages();
      setMessagesList(res.data.data);
    }, 1500);
  };

  const handleLocaleChange = (e) => {
    setLocale(e.itemData.id);
  };

  const handleTargetChange = (e) => {
    setTarget(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isNotificationsModalOpen}
        toggle={() => {
          setIsNotificationsModalOpen((prev) => !prev);
          setIsEditMessage(false);
        }}
      >
        <ModalHeader toggle={() => setIsNotificationsModalOpen((prev) => !prev)}>
          {isEditMessage ? 'Edit Notifications Message' : 'Add Notifications Message'}
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Key
              </label>
              <input
                onChange={(e) => setKey(e.target.value)}
                className="form-control"
                id="message-text"
                value={key}
              ></input>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Message
              </label>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                className="form-control"
                id="message-text"
              ></textarea>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                title
              </label>
              <input
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className="form-control"
                id="message-text"
              ></input>
            </div>
            <div className="form-group filter-select">
              <label className="col-form-label" htmlFor="message-text">
                Locale
              </label>
              <CustomSelect
                listData={[
                  { id: 'ar', name: 'ar' },
                  { id: 'en-gb', name: 'en-gb' },
                  { id: 'en-us', name: 'en-us' },
                ]}
                handleChange={handleLocaleChange}
                placeholder="Select a locale"
                textPropName="name"
                valuePropName="id"
                selectedValue={locale}
              />
            </div>
            <div className="form-group filter-select">
              <label className="col-form-label" htmlFor="message-text">
                Target
              </label>
              <CustomSelect
                listData={[
                  { id: 0, name: 'Sender' },
                  { id: 1, name: 'Recipient' },
                  { id: 2, name: 'Hub' },
                ]}
                handleChange={handleTargetChange}
                placeholder="Select a target"
                textPropName="name"
                valuePropName="id"
                selectedValue={target}
              />
            </div>
            <div className="form-group">
              <label className="d-block bold-title" htmlFor="isActive">
                <input
                  className="checkbox_animated"
                  id="isActive"
                  type="checkbox"
                  checked={isActive}
                  onChange={(e) => setIsActive((prev) => !prev)}
                />
                Active
              </label>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={disableBtn} onClick={AddNewNotificationMessage} color="primary">
            Save
          </Button>
          <Button
            color="light"
            onClick={() => {
              setIsNotificationsModalOpen((prev) => !prev);
              setIsEditMessage(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NotifficationsMessagesModal;
