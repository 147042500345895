import { startOf, endOf, momentFormat } from 'constant/Helpers';

const startOfMonth = momentFormat('MM/DD/YYYY', startOf('month'));
const endOfMonth = momentFormat('MM/DD/YYYY', endOf('month'));

export const initialPayload = {
  orderstatus: 2,
  orderType: -1,
  paymentstatus: -1,
  orderId: 0,
  Channel: -1,
  searchkey: '',
  steps: -1,
  timeslot: -1,
  paymentMethod: -1,
  recipientKey: '',
  discount: -1,
  Resultdate: '',
  deliverydate: '',
  Agant: -1,
  AreaId: -1,
  ProductId: -1,
  PrintStatus: -1,
  CardPrintStatus: -1,
  OpsId: 1,
  voucherCode: '',
  page: 1,
  ProductKey: '',
  dispatchedStatus: 0,
  zone: -1,
};

export const filteInputs = [
  {
    label: 'OrderType',
    inputType: 'select',
    selections: [
      { id: '-1', name: 'Any' },
      { id: 0, name: 'Sales' },
      { id: 1, name: 'Corporate Subscription' },
      { id: 2, name: ' Corporate Event' },
      { id: 3, name: ' Corporate OnDemand' },
      { id: 4, name: ' User Subscription' },
      { id: 5, name: ' Celebrity' },
    ],
    name: 'orderType',
  },
  {
    label: 'PaymentStatus',
    inputType: 'select',
    selections: [
      { id: '-1', name: 'Any' },
      { id: 0, name: 'Not Paid' },
      { id: 1, name: 'Paid' },
      { id: 2, name: 'Cancelled' },
      { id: 3, name: 'Refund' },
    ],
    name: 'paymentstatus',
  },
  {
    label: 'PaymentMethod',
    inputType: 'select',
    selections: [
      { id: '-1', name: 'All' },
      { id: 0, name: 'Cash' },
      { id: 1, name: 'K-Net' },
      { id: 4, name: 'Credit Card' },
      { id: 5, name: 'PayPal' },
      { id: 6, name: 'Mada' },
      { id: 7, name: 'FLWCredit' },
      { id: 9, name: 'ApplePay' },
      { id: 11, name: 'Benefit' },
      { id: 12, name: 'KSA_STCPay' },
    ],
    name: 'paymentMethod',
  },
  {
    label: 'Discount',
    inputType: 'select',
    selections: [
      { id: '-1', name: 'Any' },
      { id: 0, name: 'Without Discount' },
      { id: 1, name: 'With Discount' },
    ],
    name: 'discount',
  },
  {
    label: 'Channel',
    inputType: 'select',
    selections: [
      { id: '-1', name: 'Any' },
      { id: 0, name: 'Web' },
      { id: 1, name: 'Agent' },
      { id: 2, name: 'iOS' },
      { id: 3, name: 'Android' },
      { id: 4, name: 'Carriage' },
      { id: 6, name: 'Machine' },
    ],
    name: 'Channel',
  },
  {
    label: 'AgentName',
    inputType: 'select',
    selections: [{ name: 'Any', id: '-1' }],
    name: 'Agant',
  },
  {
    label: 'AddressAvailability',
    inputType: 'select',
    selections: [
      { id: '-1', name: 'Any' },
      { id: 0, name: 'Available' },
      { id: 1, name: 'Unknowm' },
    ],
    name: 'Addressstatus',
  },
  {
    label: 'TimeSlot',
    inputType: 'select',
    selections: [{ name: 'Any', id: '-1' }],
    name: 'timeslot',
  },
  // {
  // label: 'CompletionDate',
  // placeHolder: 'Result Date',
  // inputType: 'date',
  // name: 'Resultdate',
  // value: `${startOfMonth} - ${endOfMonth}`,
  // },
  {
    label: 'DeliveryDate',
    placeHolder: 'Delivery Date',
    inputType: 'date',
    name: 'deliverydate',
    value: `${startOfMonth} - ${endOfMonth}`,
  },
  {
    label: 'MainArea',
    inputType: 'select',
    selections: [{ name: 'Any', id: '-1' }],
    name: 'AreaId',
  },
  {
    label: 'A6 print Status',
    inputType: 'select',
    selections: [
      { id: '-1', name: 'All' },
      { id: 1, name: 'Printed' },
      { id: 0, name: 'Not-Printed' },
    ],
    name: 'PrintStatus',
  },
  // {
  // label: 'CardPrintStatus',
  // inputType: 'select',
  // selections: [
  // { id: '-1', name: 'All' },
  // { id: 1, name: 'Printed' },
  // { id: 0, name: 'Not-Printed' },
  // ],
  // name: 'CardPrintStatus',
  // },
  {
    label: 'Dispatched Status',
    inputType: 'select',
    selections: [
      { id: 0, name: 'All' },
      { id: 1, name: 'Dispatched' },
      { id: 2, name: 'Not Dispatched' },
    ],
    name: 'dispatchedStatus',
  },
  {
    label: 'OrderCstep',
    inputType: 'select',
    selections: [
      { id: '-1', name: 'All' },
      { id: 0, name: 'Add Items' },
      { id: 1, name: 'Ordered' },
      { id: 2, name: 'Preparing' },
      { id: 3, name: 'Delivering' },
      { id: 4, name: 'Enjoy' },
      { id: 5, name: 'Picked' },
      { id: 6, name: 'Collecting Address' },
      { id: 7, name: 'Ready' },
      { id: 8, name: 'Returned' },
      { id: 9, name: 'Failed Delivery' },
      { id: 10, name: 'Looking for a driver' },
    ],
    name: 'steps',
  },
  {
    label: 'Voucher Code',
    placeHolder: 'Voucher code',
    value: '',
    name: 'voucherCode',
  },
  {
    label: 'SearchKey',
    placeHolder: 'Order#, email, phone, first name, last name',
    value: '',
    name: 'searchkey',
  },
  {
    label: 'ByProduct',
    placeHolder: 'Product SKU or Product name',
    value: '',
    name: 'ProductKey',
  },
  {
    label: 'ByRecipient',
    placeHolder: 'Recipient name or Phone number',
    value: '',
    name: 'recipientKey',
  },
  {
    label: 'Zone',
    inputType: 'select',
    selections: [{ id: '-1', name: 'All' }],
    name: 'zone',
  },
];
