import React, { useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
// components
import RankingTemplate from '../components/RankingTemplate';

const ProductRankingDataGrid = ({
  productData,
  productId,
  isValid,
  notValidFields,
  validateRanking,
  isAuthorized,
}) => {
  const ref = useRef();

  const opsTemplate = (rowData) => <span>{rowData.nickName}</span>;

  const RankTemplate = (rowData) => (
    <RankingTemplate
      rowData={rowData}
      productId={productId}
      isValid={isValid}
      notValidFields={notValidFields}
      validateRanking={validateRanking}
      isAuthorized={isAuthorized}
    />
  );

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={productData}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          allowTextWrap={true}
          allowSelection={false}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Ops"
              field="nickName"
              template={opsTemplate}
              textAlign="left"
              width={150}
            />
            <ColumnDirective headerText="Rank" template={RankTemplate} textAlign="left" />
          </ColumnsDirective>
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(ProductRankingDataGrid);
