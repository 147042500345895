import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// constants
import PageTitle from 'components/shared/PageTitle';
// selectors
import { getSliderImg } from 'store/app/AppSelectors';
import { getDisableLoginBtn } from 'store/auth/signIn/SignInSelector';
// actions
import { setSignInUser, setSliderImg } from 'store/auth/signIn/SignInAction';
// formik and yup
import * as yup from 'yup';
import { Formik } from 'formik';
// assets
import showSVG from '../assets/images/show.svg';
import hideSVG from '../assets/images/hide.svg';
import microsoftSVG from '../assets/images/microsoft.svg';

const formSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginPage = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [isShow, setShow] = useState(false);
  const [showMsLoginOnly, setShowMsLoginOnly] = useState(true);
  const dispatch = useDispatch();
  const slides = useSelector((state) => getSliderImg({ state }));
  const disableLoginBtn = useSelector((state) => getDisableLoginBtn({ state }));
  const isDevelopment = process.env.NODE_ENV === 'development';

  const handleLogIn = (email, password) => {
    dispatch(setSignInUser({ email, password }));
  };

  window.addEventListener(
    'resize',
    function (event) {
      setHeight(event.target.innerHeight);
    },
    true
  );

  useEffect(() => {
    dispatch(setSliderImg());
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('sessionId');
    if (sessionId) {
      dispatch(setSignInUser({ sessionId }));
    }
  }, []);

  const handleMSLogin = () => {
    window.location.href = `${process.env.REACT_APP_MS_AUTH}`;
  };

  return (
    <Fragment>
      <PageTitle title="Login" />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 login-col">
              <div className="authentication-box" style={{ height: height - 120 }}>
                <div className="text-center">
                  <img
                    src="https://cdn.floward.com/web/Files/attachment/floward-logo-01-637374032965010488.svg"
                    alt=""
                    className="w-40"
                  />
                </div>
                <div className="card mt-4">
                  <div className="card-body">
                    <Fragment>
                      <div className="text-center">
                        <h4 className="login-title">LOGIN</h4>
                        {!showMsLoginOnly && (
                          <h6 className="login-desc">Enter your Email and Password </h6>
                        )}
                      </div>
                      {!showMsLoginOnly && (
                        <Formik
                          initialValues={{ email: '', password: '' }}
                          validationSchema={formSchema}
                          onSubmit={(values, actions) => {
                            handleLogIn(values.email, values.password);
                          }}
                        >
                          {(props) => (
                            <form className="theme-form" onSubmit={props.handleSubmit}>
                              <div className="form-group">
                                <label
                                  className="col-form-label login-label pt-0"
                                  htmlFor="login_email"
                                >
                                  Email
                                </label>
                                <input
                                  id="login_email"
                                  className="form-control login-input"
                                  type="text"
                                  name="email"
                                  required={true}
                                  value={props.values.email}
                                  onChange={props.handleChange}
                                />
                              </div>
                              <div className="form-group password-form">
                                <label
                                  className="col-form-label login-label"
                                  htmlFor="login_password"
                                >
                                  Password
                                </label>
                                <input
                                  id="login_password"
                                  className="form-control login-input"
                                  type={isShow ? 'text' : 'password'}
                                  name="password"
                                  required={true}
                                  value={props.values.password}
                                  onChange={props.handleChange}
                                />
                                {isShow ? (
                                  <img
                                    className="showhide-icon"
                                    src={hideSVG}
                                    alt=""
                                    onClick={() => setShow(false)}
                                  />
                                ) : (
                                  <img
                                    className="showhide-icon"
                                    src={showSVG}
                                    alt=""
                                    onClick={() => setShow(true)}
                                  />
                                )}
                              </div>
                              <div className="form-group form-row mt-5 mb-0">
                                <button
                                  className="btn btn-success btn-block login-btn"
                                  type="submit"
                                  disabled={disableLoginBtn}
                                  onClick={() => props.handleSubmit}
                                >
                                  <i className="fa fa-user"></i> LOGIN
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      )}
                      <button
                        className="btn btn-block ms-login-btn"
                        disabled={disableLoginBtn}
                        onClick={() => handleMSLogin()}
                      >
                        <img className="ms-icon" src={microsoftSVG} alt="signIn with MS" /> Login
                        with Microsoft
                      </button>
                      {showMsLoginOnly && isDevelopment && (
                        <h5 className="login-email-title" onClick={() => setShowMsLoginOnly(false)}>
                          Or Login with your email
                        </h5>
                      )}
                    </Fragment>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 d-none d-sm-block p-0 login-slider">
              <div
                className="login-slide-img"
                style={{ backgroundImage: `url(${slides})`, height }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginPage;
