import { updateObject } from '../utility';

const initialState = {
  page: '',
  action: '',
  group: '',
  savedCC: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PERMISSION_PAGE_DATA': {
      return updateObject(state, {
        page: action.payload,
      });
    }

    case 'SET_PERMISSION_ACTION_DATA': {
      return updateObject(state, {
        action: action.payload,
      });
    }

    case 'SET_PERMISSION_GROUP_DATA': {
      return updateObject(state, {
        group: action.payload,
      });
    }

    case 'GET_USER_SAVED_CC': {
      return updateObject(state, { savedCC: action.payload });
    }

    default:
      return state;
  }
};

export default reducer;
