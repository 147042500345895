import React from 'react';
import PropTyps from 'prop-types';
import Pagination from 'react-js-pagination';

const DJHPagination = (props) => {
  const { itemsCount, pageSize, onPageChange, currentPage } = props;
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  return (
    <Pagination
      innerClass="pagination justify-content-center my-4"
      itemClass="page-item"
      linkClass="page-link"
      activeLinkClass="btn-primary"
      activePage={currentPage}
      itemsCountPerPage={pageSize}
      totalItemsCount={itemsCount}
      pageRangeDisplayed={8}
      onChange={(page) => onPageChange(page)}
    />
  );
};

DJHPagination.propTypes = {
  itemsCount: PropTyps.number.isRequired,
  pageSize: PropTyps.number.isRequired,
  onPageChange: PropTyps.func.isRequired,
  currentPage: PropTyps.number.isRequired,
};

export default DJHPagination;
