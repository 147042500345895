import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
} from '@syncfusion/ej2-react-grids';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const BrandsBasketItemsDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          // allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Image"
              template={(rowData) => (
                <span>
                  {rowData.image != null && (
                    <img
                      className="w-60p"
                      src={`https://cdn.floward.com/web/Files/thumPro/${rowData.image}`}
                      alt=""
                    />
                  )}
                </span>
              )}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product"
              field="productName"
              width={180}
              textAlign="Center"
            />
            <ColumnDirective headerText="SKU" field="sku" width={100} textAlign="Center" />
            <ColumnDirective headerText="Brands" field="brand" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Quantity Sold"
              field="totalQty"
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective
                  field="totalq"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                ></AggregateColumnDirective>
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page, Aggregate]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(BrandsBasketItemsDataGrid);
