import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// api
import ProductsService from 'services/ProductsService';
import { useDispatch } from 'react-redux';
import { setProductMediaById } from 'store/products/ProductsActions';

const DeleteProductImgModal = ({
  productId,
  imgId,
  isDeleteProductImgModalOpen,
  setIsDeleteProductImgModalOpen,
  setImagesList,
  imagesList,
  setExceededDimenstions,
}) => {
  const dispatch = useDispatch();

  const handleDeleteProductImg = async () => {
    try {
      await ProductsService.deleteProductMediaById(productId, imgId);
      const clonedImgList = [...imagesList];
      const updatedImgList = clonedImgList.filter((item) => item.id !== imgId);
      setImagesList(updatedImgList);
      setIsDeleteProductImgModalOpen((prev) => !prev);
      setExceededDimenstions([]);
      toast.success('Deleted successfully');
      dispatch(setProductMediaById(productId));
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Failed to delete product image');
      setIsDeleteProductImgModalOpen((prev) => !prev);
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isDeleteProductImgModalOpen}
        toggle={() => setIsDeleteProductImgModalOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setIsDeleteProductImgModalOpen((prev) => !prev)}>
          Delete Image
        </ModalHeader>
        <ModalBody>
          <p className="sz-20">Are you sure?</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleDeleteProductImg} color="danger">
            Delete
          </Button>
          <Button color="light" onClick={() => setIsDeleteProductImgModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteProductImgModal;
