import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import InventoryFilter from 'components/inventory/items/InventoryFilter';
import InventoryDataGrid from 'components/inventory/items/InventoryDataGrid';
import DJHPagination from 'components/shared/Pagination';
import InventoryItemsActions from 'components/inventory/items/InventoryItemsActions';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// actions
import { getAllItemsData } from 'store/inventory/InventoryActions';
// selectors
import {
  getAllInventoryData,
  getTotalInventoryPageSize,
  getTotalInventoryCurrentPage,
  getTotalInventoryRowCount,
  getInventoryParams,
} from 'store/inventory/InventorySelectors';

const InventoryAllItemsPage = () => {
  const inputValues = useSelector((state) => getInventoryParams({ state }));
  const inventoryData = useSelector((state) => getAllInventoryData({ state }));
  const pageSize = useSelector((state) => getTotalInventoryPageSize({ state }));
  const currentPage = useSelector((state) => getTotalInventoryCurrentPage({ state }));
  const rowCount = useSelector((state) => getTotalInventoryRowCount({ state }));
  const [qty, setQty] = useState('');
  const [clickedRow, setClickedRow] = useState('');
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [isAddQtyModalOpen, setIsAddQtyModalOpen] = useState(false);
  const [isEditItemQtyModalOpen, setIsEditItemQtyModalOpen] = useState(false);
  const [isUploadExcelModalOpen, setIsUploadExcelModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(getAllItemsData(queryObj));
    } else {
      dispatch(getAllItemsData({}));
    }
  }, []);

  const updateQueryParams = (inputValues) => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: '/inventory/allItems',
      search: queryString,
    });
  };

  const onPageChange = (i) => {
    updateQueryParams({ ...inputValues, page: i });
    dispatch(getAllItemsData({ ...inputValues, page: i }));
    document.documentElement.scrollTop = 0;
  };

  const handleSubmit = () => {
    const minqty = inputValues?.minimumQty || -1;
    if (qty === '') {
      updateQueryParams({ ...inputValues, qty: -1, minimumQty: minqty });
      dispatch(getAllItemsData({ ...inputValues, qty: -1, minimumQty: minqty }));
    } else {
      if (qty < 0 || isNaN(qty)) {
        toast.error('Please enter a correct quantity');
      } else {
        updateQueryParams({ ...inputValues, qty, minimumQty: minqty });
        dispatch(getAllItemsData({ ...inputValues, qty, minimumQty: minqty }));
        dispatch({
          type: 'INVENTORY_PARAMS',
          payload: { ...inputValues, qty, minimumQty: minqty },
        });
        // setInputValues((prev) => ({ ...prev, qty }));
      }
    }
  };

  return (
    <div className="quick-actions-table inventory-page">
      <InventoryItemsActions
        defaultParams={inputValues}
        clickedRow={clickedRow}
        setClickedRow={setClickedRow}
        isAddItemModalOpen={isAddItemModalOpen}
        setIsAddItemModalOpen={setIsAddItemModalOpen}
        isAddQtyModalOpen={isAddQtyModalOpen}
        setIsAddQtyModalOpen={setIsAddQtyModalOpen}
        isEditItemQtyModalOpen={isEditItemQtyModalOpen}
        setIsEditItemQtyModalOpen={setIsEditItemQtyModalOpen}
        isUploadExcelModalOpen={isUploadExcelModalOpen}
        setIsUploadExcelModalOpen={setIsUploadExcelModalOpen}
        isAllItemsPage={true}
      />
      <InventoryFilter
        inputValues={inputValues}
        // setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        setQty={setQty}
        qty={qty}
        isAllItemsPage={true}
      />
      <InventoryDataGrid
        data={inventoryData}
        setClickedRow={setClickedRow}
        setIsAddItemModalOpen={setIsAddItemModalOpen}
        setIsEditItemQtyModalOpen={setIsEditItemQtyModalOpen}
        isAllItemsPage={true}
      />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </div>
  );
};

export default InventoryAllItemsPage;
