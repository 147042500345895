import { updateObject } from '../utility';
// type
import {
  GET_TOTAL_CATEGORIES_DATA,
  SET_SELECTED_CATEGORY,
  GET_SUB_CATEGORIES,
  SET_SELECTED_SUB_CATEGORY,
  GET_CATEGORY_OPS,
} from './CategoriesActionTypes';

const initialState = {
  categoriesData: [],
  pageSize: '',
  currentPage: '',
  rowCount: '',
  selectedCategory: -1,
  subCategories: [],
  parentCategory: '',
  selectedSubCategory: {},
  categoryOps: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_CATEGORIES_DATA: {
      return updateObject(state, {
        categoriesData: action.payload,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        rowCount: action.payload.rowCount,
      });
    }
    case SET_SELECTED_CATEGORY: {
      return updateObject(state, {
        selectedCategory: action.payload,
      });
    }
    case GET_SUB_CATEGORIES: {
      return updateObject(state, {
        subCategories: action.payload.subCategory,
        parentCategory: action.payload.mainCategory,
      });
    }
    case SET_SELECTED_SUB_CATEGORY: {
      return updateObject(state, {
        selectedSubCategory: action.payload,
      });
    }
    case GET_CATEGORY_OPS: {
      return updateObject(state, {
        categoryOps: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
