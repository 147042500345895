import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import KeyValueService from 'services/KeyValueService';
import { setKeyValueList } from 'store/keyValues/keyValueActions';
import { handleImageFile } from 'constant/Helpers';

const AddNewKeyValueModal = ({
  isKeyValueModalOpen,
  setIsKeyValueModalOpen,
  clickedKeyValue,
  setClickedKeyValue,
}) => {
  const [title, setTitle] = useState(clickedKeyValue.Title ?? '');
  const [titleAr, setTitleAr] = useState(clickedKeyValue.TitleAr ?? '');
  const [descriptionEn, setDescriptionEn] = useState(clickedKeyValue.DescriptionEn ?? '');
  const [descriptionAr, setDescriptionAr] = useState(clickedKeyValue.DescriptionAr ?? '');
  const [sorder, setSorder] = useState(clickedKeyValue.Sorder ?? '');
  const [iconEn, setIconEn] = useState(clickedKeyValue.IconFullEn ?? '');
  const [iconAr, setIconAr] = useState(clickedKeyValue.IconFullAr ?? '');
  const [isActive, setIsActive] = useState(clickedKeyValue.isActive ?? true);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    let dataBody = {
      Title: title,
      TitleAr: titleAr,
      DescriptionEn: descriptionEn,
      DescriptionAr: descriptionAr,
      Sorder: sorder,
      iconEn,
      iconAr,
    };
    if (clickedKeyValue) {
      dataBody = {
        ...dataBody,
        isActive,
      };
    }
    const formData = new FormData();
    Object.keys(dataBody).forEach((key) => {
      formData.append(key, dataBody[key]);
    });
    setDisableBtn(true);

    try {
      if (clickedKeyValue) {
        await KeyValueService.updateKeyValue(formData, clickedKeyValue.Id);
        toast.success('Updated Successfully');
      } else {
        await KeyValueService.createKeyValue(formData);
        toast.success('Added Successfully');
      }
      dispatch(setKeyValueList({ page: 1 }));
      setIsKeyValueModalOpen((prev) => !prev);
      setClickedKeyValue('');
    } catch (e) {
      toast.error(e.response?.data?.message ?? 'Something went wrong');
      setIsKeyValueModalOpen((prev) => !prev);
    }
  };

  return (
    <div className="card-body btn-showcase" data-testid="AddNewKeyValueModalTestID">
      <Modal
        isOpen={isKeyValueModalOpen}
        toggle={() => {
          setIsKeyValueModalOpen((prev) => !prev);
          setClickedKeyValue('');
        }}
        className="m-w-700"
      >
        <ModalHeader
          toggle={() => {
            setIsKeyValueModalOpen((prev) => !prev);
            setClickedKeyValue('');
          }}
        >
          {clickedKeyValue ? 'Edit Key Value' : 'Add New Key Value'}
        </ModalHeader>
        <ModalBody>
          <form className="key-value-form row">
            <div className="form-group col-6">
              <label className="col-form-label font-weight-bold" htmlFor="Title">
                English Title
              </label>
              <input
                onChange={(e) => setTitle(e.target.value)}
                id="Title"
                className="form-control"
                type="text"
                placeholder="English Title"
                value={title}
                data-testid="EnglishTitleInputTestID"
              />
            </div>
            <div className="form-group col-6">
              <label className="col-form-label font-weight-bold" htmlFor="TitleAr">
                Arabic Title
              </label>
              <input
                onChange={(e) => setTitleAr(e.target.value)}
                id="TitleAr"
                className="form-control"
                type="text"
                placeholder="Arabic Title"
                value={titleAr}
              />
            </div>

            <div className="form-group col-6">
              <label className="col-form-label font-weight-bold" htmlFor="DescriptionEn">
                English Description
              </label>
              <textarea
                onChange={(e) => setDescriptionEn(e.target.value)}
                id="DescriptionEn"
                className="form-control"
                type="text"
                placeholder="English Description"
                value={descriptionEn}
                rows="5"
              />
            </div>
            <div className="form-group col-6">
              <label className="col-form-label font-weight-bold" htmlFor="DescriptionAr">
                Arabic Description
              </label>
              <textarea
                onChange={(e) => setDescriptionAr(e.target.value)}
                id="DescriptionAr"
                className="form-control"
                type="text"
                placeholder="Arabic Description"
                value={descriptionAr}
                rows="5"
              />
            </div>

            <div className="form-group col-6">
              <label className="font-weight-bold" htmlFor="iconEn">
                English Image
              </label>
              <div
                className="file-img"
                style={{
                  backgroundImage: `url(${handleImageFile(iconEn)})`,
                  height: iconEn === '' ? 0 : 150,
                  marginBottom: iconEn === '' ? 0 : 16,
                }}
              ></div>
              <div className="file-input">
                <input
                  type="file"
                  className="file"
                  id="iconEn"
                  onChange={(e) => {
                    setIconEn(e.target.files[0]);
                  }}
                />
                <label htmlFor="iconEn">
                  <p className="file-name">Select file</p>
                </label>
              </div>
            </div>
            <div className="form-group col-6">
              <label className="font-weight-bold" htmlFor="iconAr">
                Arabic Image
              </label>
              <div
                className="file-img"
                style={{
                  backgroundImage: `url(${handleImageFile(iconAr)})`,
                  height: iconAr === '' ? 0 : 150,
                  marginBottom: iconAr === '' ? 0 : 16,
                }}
              ></div>
              <div className="file-input">
                <input
                  type="file"
                  className="file"
                  id="iconAr"
                  onChange={(e) => setIconAr(e.target.files[0])}
                />
                <label htmlFor="iconAr">
                  <p className="file-name">Select file</p>
                </label>
              </div>
            </div>

            <div className="form-group col-6">
              <label className="col-form-label font-weight-bold" htmlFor="Sorder">
                Order
              </label>
              <input
                onChange={(e) => setSorder(e.target.value)}
                id="Sorder"
                className="form-control"
                type="number"
                value={sorder}
              />
            </div>
            {clickedKeyValue && (
              <div className="form-group col-6 align-self-end pb-2">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={isActive}
                  onChange={() => setIsActive((prev) => !prev)}
                />
                <span className="font-weight-bold">is Active</span>
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={disableBtn} onClick={handleSubmit} color="primary">
            Save
          </Button>
          <Button
            onClick={() => {
              setIsKeyValueModalOpen((prev) => !prev);
              setClickedKeyValue('');
            }}
            color="light"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddNewKeyValueModal;
