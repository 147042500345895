/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import WalletTransactionsDataGrid from 'components/reports/wallet/WalletTransactionsDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  isoStartFormat,
  isoEndFormat,
} from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// url
import { getWalletTransactionsPageUrl } from 'constant/AppUrls';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import WalletReportService from 'services/reportsService/WalletReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const WalletTransactionsPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: isoStartFormat(new Date()),
    toDate: isoEndFormat(new Date()),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await WalletReportService.getWalletTransactions(params);
      const updatedList =
        res.data === ''
          ? []
          : res.data.map((item, index) => ({
              ...item,
              serial: index + 1,
            }));
      setData(updatedList);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Wallet Transactions Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.fromDate = isoStartFormat(inputValues.fromDate);
    updatedParams.toDate = isoEndFormat(inputValues.toDate);
    setInputValues(updatedParams);
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getWalletTransactionsPageUrl(),
      search: queryString,
    });
    fetchData(updatedParams);
  };

  const handleExport = async () => {
    dispatch(setLoaderOpenTrue());
    const updatedParams = cloneDeep(inputValues);
    updatedParams.fromDate = isoStartFormat(inputValues.fromDate);
    updatedParams.toDate = isoEndFormat(inputValues.toDate);
    setInputValues(updatedParams);
    try {
      const res = await WalletReportService.exportWalletTransactions(updatedParams);
      toast.success(res?.data?.message ?? 'Wallet transactions report exported successfully');
      dispatch(setLoaderOpenFalse());
    } catch (error) {
      errorLogger('Wallet Transactions Report Page Export Data Error:', error);
      const json = JSON.parse(await error.response.data.text());
      toast.error(json?.message ?? 'Failed to export Wallet transactions report!!');
      dispatch(setLoaderOpenFalse());
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Wallet Transactions"
        timeDate
        hasExport
        handleExport={handleExport}
      />
      <WalletTransactionsDataGrid data={data} />
    </div>
  );
};

export default WalletTransactionsPage;
