import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageTitle from 'components/shared/PageTitle';
import AreaForm from './common/AreaForm';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// actions
import { updateArea } from 'store/delivery/deliveryAreas/areasActions';
// constants
import { localStorageKeys } from 'constant/Enums';
import { errorLogger } from 'datadog/DDUtils';

const EditArea = ({ selectedAreaData, type }) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [enName, setEnName] = useState('');
  const [arName, setArName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const validateForm = () => {
    if (enName === '' || arName === '') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  const handleEditArea = async () => {
    const data = {
      Name: enName,
      NameAr: arName,
      CountryId: getFromLocalStorage(localStorageKeys.OPS_CENTER).id,
      IsActive: isActive,
    };
    setDisableBtn(true);
    try {
      dispatch(updateArea(data, params?.id));
      navigate(-1);
    } catch (e) {
      errorLogger('EditArea:handleEditArea', e);
      setDisableBtn(false);
      toast.error('Failed to update area');
    }
  };

  useEffect(() => {
    validateForm();
  }, [enName, arName]);

  useEffect(() => {
    if (Object.keys(selectedAreaData).length > 0) {
      setEnName(selectedAreaData.Name);
      setArName(selectedAreaData.NameAr);
      setIsActive(selectedAreaData.IsActive);
    }
  }, [selectedAreaData]);

  return (
    <>
      <PageTitle title={`Area ${selectedAreaData.Name}`} />
      <div className="form-group col-12 card">
        <form className="card-body row">
          <AreaForm
            enName={enName}
            setEnName={setEnName}
            arName={arName}
            setArName={setArName}
            isActive={isActive}
            setIsActive={setIsActive}
            type={type}
          />
        </form>
        <div className="card-footer">
          <button
            disabled={disableBtn}
            onClick={handleEditArea}
            className="btn btn-primary"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default EditArea;
