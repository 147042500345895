import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from 'components/shared/CustomSelect';
import AddSizeModal from '../modals/AddSizeModal';
import ProductSizesDataGrid from '../productsDataGrid/ProductSizesDataGrid';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setProductSubscriptionType } from 'store/products/ProductsActions';
// selectors
import { getProductSizesData, getFetchProductSizeStatus } from 'store/products/ProductsSelectors';
// constants
import { localStorageKeys } from 'constant/Enums';

const ProductSubscriptionsSection = ({ productId, productOptionsById, productDetailsById }) => {
  const [type, setType] = useState(productDetailsById.subscriptionType);
  const [showDetails, setShowDetails] = useState(productDetailsById.subscriptionType === 1);
  const [isAddSizeModalOpen, setIsAddSizeModalOpen] = useState(false);
  const allSizesList = [
    { id: 0, name: 'Original' },
    { id: 1, name: 'Medium' },
    { id: 2, name: 'Large' },
  ];
  const [sizesList, setSizeList] = useState(allSizesList);
  const [modalType, setModalType] = useState('add');
  const countryId = getFromLocalStorage(localStorageKeys.COUNTRY_ID);
  const productSizesDataById = useSelector((state) => getProductSizesData({ state }));
  const fetchingSizes = useSelector((state) => getFetchProductSizeStatus({ state }));
  const dispatch = useDispatch();

  const handleTypeChange = (e) => {
    setType(e.itemData.id);
    if (e.itemData.id !== type) dispatch(setProductSubscriptionType(e.itemData.id, 5, productId));
    if (e.itemData.id === 1) {
      setShowDetails(true);
    }
  };

  useEffect(() => {
    if (Object.keys(productDetailsById).length > 0) {
      setType(productDetailsById.subscriptionType);
      setShowDetails(productDetailsById.subscriptionType === 1);
    }
  }, [productDetailsById]);

  useEffect(() => {
    if (productSizesDataById.length > 0) {
      let newList = sizesList;
      productSizesDataById.forEach(function (ps) {
        if (
          ps.price.filter(function (ll) {
            return ll.countryId === countryId;
          }).length > 0
        ) {
          newList = newList.filter(function (nl) {
            return nl.id !== ps.size.productSize;
          });
        }
      });
      setSizeList(newList);
    }
  }, [productSizesDataById]);

  return (
    <>
      {isAddSizeModalOpen && (
        <AddSizeModal
          isAddAttrModalOpen={isAddSizeModalOpen}
          setIsAddAttrModalOpen={setIsAddSizeModalOpen}
          productId={productId}
          allSizesList={allSizesList}
          sizesList={sizesList}
          type={modalType}
        />
      )}
      <div className="form-group col-12 m-t-30">
        <form className="row">
          <div className="col-md-6 col-xl-4">
            <label className="bold-title" htmlFor="subs-type">
              Type
            </label>
            <CustomSelect
              id="subs-type"
              listData={[
                { id: 0, name: 'Preset' },
                { id: 1, name: 'Custom' },
              ]}
              handleChange={handleTypeChange}
              placeholder="Select main category"
              textPropName="name"
              valuePropName="id"
              selectedValue={type}
              disabled={productOptionsById?.status === 1}
            />
          </div>
        </form>
      </div>
      {showDetails && (
        <div className="card attributes-card">
          {sizesList.length > 0 && (
            <div className="col-md-6 col-xl-4 add-attr-col">
              <button
                onClick={() => {
                  setIsAddSizeModalOpen(true);
                  setModalType('add');
                }}
                className="btn btn-success"
                type="button"
                disabled={productOptionsById?.status === 1}
              >
                <i className="fa fa-plus"></i> Add Size
              </button>
            </div>
          )}
          <div className="card-body">
            <div className="no-toolbar-table-style">
              {fetchingSizes ? (
                <SectionLoader height="180px" />
              ) : (
                <ProductSizesDataGrid
                  productSizesDataById={productSizesDataById}
                  setIsAddSizeModalOpen={setIsAddSizeModalOpen}
                  setModalType={setModalType}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductSubscriptionsSection;
