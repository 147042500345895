import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// selectors
import { getSelectedAreaData } from 'store/delivery/deliveryAreas/areasSelectors';
// action
import { getAreaById } from 'store/delivery/deliveryAreas/areasActions';
// components
import EditArea from 'components/delivery/areas/EditArea';
import SectionLoader from 'components/common/SectionLoader';

const EditAreaPage = () => {
  const selectedAreaData = useSelector((state) => getSelectedAreaData({ state }));
  const dispatch = useDispatch();
  const params = useParams();

  const fetchData = async () => {
    await dispatch(getAreaById(parseInt(params?.id, 10)));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {!selectedAreaData ? (
        <SectionLoader height="300px" />
      ) : (
        <EditArea selectedAreaData={selectedAreaData} type={'area'} />
      )}
    </div>
  );
};

export default EditAreaPage;
