import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
// action
import { setUpdateProductMarginById } from 'store/products/ProductsActions';
// selectors
import { getProductOptionsById } from 'store/products/ProductsSelectors';

const MarginInput = ({ rowData, inputRef }) => {
  const dispatch = useDispatch();
  const productOptionsById = useSelector((state) => getProductOptionsById({ state }));
  const [isDisabled, setIsDisabled] = useState(true);
  const updateMargin = debounce(async (data, productId, ind) => {
    if (data > 99.9999) {
      inputRef.current[ind].value = 99.999;
      dispatch(setUpdateProductMarginById(99.999, productId));
      setTimeout(() => {
        inputRef.current[ind].focus();
      }, 500);
    } else {
      dispatch(setUpdateProductMarginById(data, productId));
      setTimeout(() => {
        inputRef.current[ind].focus();
      }, 500);
    }
  }, 1000);

  useEffect(() => {
    if (productOptionsById?.status === 1) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [productOptionsById]);

  return (
    <input
      ref={(el) => (inputRef.current[rowData.index] = el)}
      className="form-control no-arrows-input"
      type="number"
      defaultValue={rowData.margin}
      max={99.999}
      onChange={(e) => updateMargin(e.target.value, rowData.productId, rowData.index)}
      disabled={isDisabled}
    />
  );
};

export default MarginInput;
