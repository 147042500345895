export const OPEN_ADD_INVENTORY_MODAL = '[ADD_INVENTORY] OPEN_ADD_INVENTORY_MODAL';

export const CLOSE_ADD_INVENTORY_MODAL = '[ADD_INVENTORY] CLOSE_ADD_INVENTORY_MODAL';

export const SET_ADD_INVENTORY_SEARCH_PROPERTIES =
  '[ADD_INVENTORY] SET_ADD_INVENTORY_SEARCH_PROPERTIES';

export const SET_ADD_INVENTORY_BARCODE_PROPERTIES =
  '[ADD_INVENTORY] SET_ADD_INVENTORY_BARCODE_PROPERTIES';

export const SET_ADD_INVENTORY_PRODUCT_PROPERTIES =
  '[ADD_INVENTORY] SET_ADD_INVENTORY_PRODUCT_PROPERTIES';

export const SET_ADD_INVENTORY_QUANTITY_PROPERTIES =
  '[ADD_INVENTORY] SET_ADD_INVENTORY_QUANTITY_PROPERTIES';

export const SET_ADD_INVENTORY_SEARCH_LIST = '[ADD_INVENTORY] SET_ADD_INVENTORY_SEARCH_LIST';

export const SET_ADD_INVENTORY_IS_LOADING_TRUE =
  '[ADD_INVENTORY] SET_ADD_INVENTORY_IS_LOADING_TRUE';

export const SET_ADD_INVENTORY_IS_LOADING_FALSE =
  '[ADD_INVENTORY] SET_ADD_INVENTORY_IS_LOADING_FALSE';

export const RESET_ADD_INVENTORY_FORM = '[ADD_INVENTORY] RESET_ADD_INVENTORY_FORM';

export const SET_ADD_INVENTORY_IS_SEARCHING_TRUE =
  '[ADD_INVENTORY] SET_ADD_INVENTORY_IS_SEARCHING_TRUE';

export const SET_ADD_INVENTORY_IS_SEARCHING_FALSE =
  '[ADD_INVENTORY] SET_ADD_INVENTORY_IS_SEARCHING_FALSE';
