import { apiUserService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class OpsService {
  static getOps(data) {
    return apiUserService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllOps),
      params: data,
    });
  }
}

export default OpsService;
