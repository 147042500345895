import { updateObject } from '../utility';

const initialState = {
  topicsList: [],
  orderTicketsList: [],
  ticketDetailsById: '',
  ticketCommentsList: [],
  ticketsList: [],
  currentPage: '',
  pageSize: '',
  rowCount: '',
  isTechTicketModalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOPICS_LIST_DATA': {
      return updateObject(state, {
        topicsList: action.payload,
      });
    }

    case 'SET_TICKETS_LIST_BY_ORDERID': {
      return updateObject(state, {
        orderTicketsList: action.payload,
      });
    }

    case 'SET_TICKET_DETAILS_DATA': {
      return updateObject(state, {
        ticketDetailsById: action.payload,
      });
    }

    case 'SET_TICKET_COMMENTS_LIST': {
      return updateObject(state, {
        ticketCommentsList: action.payload,
      });
    }

    case 'SET_TICKETS_PAGE_LIST': {
      return updateObject(state, {
        ticketsList: action.payload.data,
        currentPage: action.payload.currentPage,
        pageSize: action.payload.pageSize,
        rowCount: action.payload.rowCount,
      });
    }

    case 'TOGGLE_TECH_TICKET_MODAL_OPEN': {
      return updateObject(state, {
        isTechTicketModalOpen: !state.isTechTicketModalOpen,
      });
    }

    default:
      return state;
  }
};

export default reducer;
