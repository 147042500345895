import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CustomSelect from 'components/shared/CustomSelect';
// constant
import { inventoryTypes } from 'constant/Enums';
// actions
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';

const InventoryFilter = ({
  handleSubmit,
  inputValues,
  setQty,
  qty,
  isAllItemsPage,
  isAllCitiesPage,
}) => {
  const [accActive, setAccActive] = useState(true);
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[e.target.name] = e.target.value;
    dispatch({
      type: 'INVENTORY_PARAMS',
      payload: updatedParams,
    });
  };

  const handleSortBy = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.inventorySorting = e.itemData.id;
    dispatch({
      type: 'INVENTORY_PARAMS',
      payload: updatedParams,
    });
  };

  const handleIventoryType = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.type = e.itemData.id;
    dispatch({
      type: 'INVENTORY_PARAMS',
      payload: updatedParams,
    });
  };

  const handleSerialChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.serial = e.target.value === '' ? 0 : e.target.value;
    dispatch({
      type: 'INVENTORY_PARAMS',
      payload: updatedParams,
    });
  };

  const handleOpsCenterChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.opsId = e.itemData.id;
    dispatch({
      type: 'INVENTORY_PARAMS',
      payload: updatedParams,
    });
  };

  const submitForm = (e) => {
    if (e.isComposing || e.keyCode === 229) {
      return;
    }
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (isAllCitiesPage) {
      dispatch(setSignInSuccess());
    }
  }, []);

  return (
    <div className="card">
      <Accordion preExpanded={['a']} allowZeroExpanded>
        <AccordionItem uuid="a">
          <AccordionItemHeading onClick={() => setAccActive((prev) => !prev)}>
            <AccordionItemButton>
              <div className="card-header between-flex pointer p-18">
                <h4>Filter</h4>
                {accActive ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form onKeyDown={submitForm} className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-xl-3">
                    <div className="form-group">
                      <label className="bold-title" htmlFor="inventory-barcode">
                        Name or Barcode
                      </label>
                      <input
                        id="inventory-barcode"
                        className="form-control"
                        type="text"
                        placeholder="Search by product name, SKU"
                        value={isAllCitiesPage ? inputValues.searchKey : inputValues.sreackkey}
                        onChange={handleChange}
                        name={isAllCitiesPage ? 'searchKey' : 'sreackkey'}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3">
                    <div className="form-group">
                      <label className="bold-title" htmlFor="inventory-qty-less">
                        Qty less than
                      </label>
                      <input
                        id="inventory-qty-less"
                        className="form-control"
                        type="number"
                        placeholder="Search by quantity"
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        name="qty"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3">
                    <div className="form-group">
                      <label className="bold-title" htmlFor="inventory-qty-greater">
                        Qty greater than
                      </label>
                      <input
                        id="inventory-qty-greater"
                        className="form-control"
                        type="number"
                        placeholder="Search by quantity"
                        value={inputValues.minimumQty}
                        onChange={handleChange}
                        name="minimumQty"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3">
                    <div className="form-group filter-select">
                      <label className="bold-title" htmlFor="inventory-type">
                        Inventory type
                      </label>
                      <CustomSelect
                        id="inventory-type"
                        listData={inventoryTypes}
                        handleChange={handleIventoryType}
                        placeholder="Inventory Type"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={-1}
                      />
                    </div>
                  </div>
                  {!isAllItemsPage && (
                    <div className="col-md-6 col-xl-3">
                      <div className="form-group filter-select">
                        <label className="bold-title" htmlFor="inventory-sort">
                          Sort by
                        </label>
                        <CustomSelect
                          id="inventory-sort"
                          listData={[
                            { id: 0, name: 'Product' },
                            { id: 1, name: 'Qty (Highset)' },
                            { id: 3, name: 'Qty (Lowest)' },
                            { id: 2, name: 'Created Date (Newest)' },
                            { id: 4, name: 'Created Date (Oldest)' },
                          ]}
                          handleChange={handleSortBy}
                          placeholder="Sort by"
                          textPropName="name"
                          valuePropName="id"
                          selectedValue={0}
                        />
                      </div>
                    </div>
                  )}
                  {isAllItemsPage && (
                    <div className="col-md-6 col-xl-3">
                      <div className="form-group">
                        <label className="bold-title" htmlFor="inventory-serial">
                          Serial Number
                        </label>
                        <input
                          id="inventory-serial"
                          className="form-control no-arrows-input"
                          type="number"
                          placeholder="Search by serial number"
                          value={inputValues.serial === 0 ? '' : inputValues.serial}
                          onChange={handleSerialChange}
                          name="serial"
                        />
                      </div>
                    </div>
                  )}
                  {isAllCitiesPage && (
                    <div className="col-md-6 col-xl-3">
                      <div className="form-group filter-select">
                        <label className="bold-title" htmlFor="inventory-ops">
                          Ops Center
                        </label>
                        <CustomSelect
                          id="inventory-ops"
                          listData={[{ id: 0, nickName: 'All' }].concat(opsCenters)}
                          handleChange={handleOpsCenterChange}
                          placeholder="Select origin ops"
                          textPropName="nickName"
                          valuePropName="id"
                          selectedValue={inputValues.opsId}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-footer">
                <button className="btn btn-primary mr-1" type="button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default InventoryFilter;
