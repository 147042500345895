import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class BulkCreateOrderService {
  static getProductList(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.searchProduct, ...extraParams),
      params,
    });
  }

  static createBulkProduct(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.createBulkProduct, ...extraParams),
      data,
    });
  }
}
export default BulkCreateOrderService;
