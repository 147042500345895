import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
// components
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import RegisteredUsersDataGrid from 'components/reports/registeredUsers/RegisteredUsersDataGrid';
// constants
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  dateFormat,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';
import { getRegisteredUsersPageUrl } from 'constant/AppUrls';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import RegisteredUsersService from 'services/reportsService/RegisteredUsersService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const RegisteredUsersReportPage = () => {
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
  });
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await RegisteredUsersService.getRegisteredUsersReport(params);
      setRegisteredUsers(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Registered Users Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: getRegisteredUsersPageUrl(),
      search: queryString,
    });
    fetchData(inputValues);
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        defaultDate
        title="Registered Users"
      />
      <RegisteredUsersDataGrid data={registeredUsers} />
    </div>
  );
};

export default RegisteredUsersReportPage;
