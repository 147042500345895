import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import PageTitle from 'components/shared/PageTitle';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { HasPermission } from 'constant/Helpers';
// selector
import { getPermissionsList } from 'store/app/AppSelectors';

const AlertQtyItemsDataGrid = ({ data }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const activeTemplate = (rowData) => (
    <div>
      {rowData.productCount === -1 ? 'N/A' : rowData.productCount} <br></br>
    </div>
  );

  const nameTemplate = (rowData) => (
    <a
      href={
        HasPermission(permissionsList, 'inventory', 'edit_inventory') ||
        HasPermission(permissionsList, 'inventory', 'inventory_care')
          ? `/inventory/${rowData.inventoryId}/editInventory/${rowData.id}`
          : ''
      }
      className="primary-color pointer"
      target="_blank"
      rel="noreferrer"
    >
      {rowData.name}
    </a>
  );

  return (
    <div className="toolbar-table-style">
      <div className="control-pane">
        <PageTitle title="Inventory" />
        <div className="control-section">
          <GridComponent
            dataSource={data}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            allowSorting={true}
            ref={ref}
            toolbarClick={toolbarClick}
            allowTextWrap={true}
            showColumnChooser={true}
            excelQueryCellInfo={excelQueryCellInfo}
            pdfQueryCellInfo={excelQueryCellInfo}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText="Image"
                template={(rowData) => (
                  <img alt="" className="img-contain" src={rowData.parentImage} />
                )}
                customExcelTemplate={(rowData) => rowData.img}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="BarCode"
                field="barCode"
                customExcelTemplate={(rowData) => rowData.barCode}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Name"
                template={nameTemplate}
                customExcelTemplate={(rowData) => rowData.name}
                width={150}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Active Products"
                template={activeTemplate}
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Available QTY"
                field="qty"
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Alert QTY"
                field="alertQty"
                width={100}
                textAlign="Center"
              />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser, Sort]} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AlertQtyItemsDataGrid);
