import { setLoaderOpenTrue, setLoaderOpenFalse } from '../../app/AppActions';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
//api
import DeliveryService from 'services/DeliveryService';

export const getDriverRadius = () => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getDriverRadius();
    dispatch(setLoaderOpenFalse());
    return res;
  } catch (err) {
    errorLogger('DriverRadiusActions:getDriverRadius', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const setDriverRadius = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.setDriverRadius(params);
    dispatch(setLoaderOpenFalse());
    toast.success('The radius has updated successfully');
    return res;
  } catch (err) {
    errorLogger('DriverRadiusActions:setDriverRadius', err);
    toast.error(err?.response?.data?.message ?? 'Failed to update the radius');
    dispatch(setLoaderOpenFalse());
  }
};
