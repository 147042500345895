import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
} from '@syncfusion/ej2-react-grids';
import { toast } from 'react-toastify';
// actions
import { getGroupUsersById } from 'store/user/UserActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const GroupUsersDataGrid = ({ data, groupId }) => {
  const toolbarOptions = ['ExcelExport', 'Search'];
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef();

  const toolbarClick = async (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        ref.current.excelExport();
        break;
      default:
        break;
    }
  };

  const redirectUserPage = (row) => {
    window.open(`/admin/users/${row.id}`, '_blank');
  };

  const nameTemplate = (rowData) => (
    <span>
      {rowData.name} {rowData.lastName}
    </span>
  );

  const removeUserTemplate = (rowData) => (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleRemoveItem(rowData);
      }}
      className="btn btn-danger"
      disabled={disableBtn}
      type="button"
    >
      <i className="fa fa-trash"></i> Remove
    </button>
  );
  const handleRemoveItem = async (rowData) => {
    const bodyData = {
      GroupId: groupId,
    };
    setDisableBtn(true);
    try {
      await PermissionService.deleteUserGroup(bodyData, rowData?.id);
      await dispatch(getGroupUsersById(groupId));
      setDisableBtn(false);
      toast.success('User removed from this group');
    } catch (err) {
      setDisableBtn(false);
      errorLogger('GroupUsersDataGrid:handleRemoveItem', err);
      toast.error('Failed to remove user!');
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          toolbarClick={toolbarClick}
          ref={ref}
          rowHeight={60}
          rowSelected={(row) => {
            redirectUserPage(row.data);
          }}
          style={{ cursor: 'pointer' }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Name"
              template={nameTemplate}
              field="name"
              width={120}
              textAlign="Center"
            />
            <ColumnDirective headerText="Email" field="email" width={150} textAlign="Center" />
            <ColumnDirective headerText="Phone" field="phone" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Remove User"
              field="id"
              template={removeUserTemplate}
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(GroupUsersDataGrid);
