import React from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Calendar } from 'react-date-range';

const DatePickerComponent = ({ setShowDatePicker, date, handledate, eventDate }) => {
  return (
    <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
      <div className="seasonal-date-picker-modal">
        {eventDate ? (
          <Calendar
            date={date}
            minDate={new Date(new Date(eventDate).setDate(new Date(eventDate).getDate() - 1))}
            maxDate={new Date(new Date(eventDate).setDate(new Date(eventDate).getDate() + 1))}
            onChange={(item) => handledate(item)}
            direction={window.screen.width < 500 ? 'vertical' : 'horizontal'}
            moveRangeOnFirstSelection={false}
          />
        ) : (
          <Calendar
            date={date}
            onChange={(item) => handledate(item)}
            direction={window.screen.width < 500 ? 'vertical' : 'horizontal'}
            moveRangeOnFirstSelection={false}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DatePickerComponent;
