import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class QuickPrintingServices {
  static getQuickPrintingData(data) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.quickPrinting),
      params: data,
    });
  }
}
export default QuickPrintingServices;
