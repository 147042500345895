import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';

const AddAttributeModal = ({
  isAddAttrModalOpen,
  setIsAddAttrModalOpen,
  setAddProductAttributeById,
  setDeleteProductAttributeById,
  updatedAttributesArr,
  updatedAttributesArrById,
  productId,
  validateMC,
}) => {
  const attributesField = { groupBy: 'key', text: 'name', value: 'id' };
  const dispatch = useDispatch();

  let mulObj;

  const handleAddAttribute = (e) => {
    dispatch(setAddProductAttributeById(productId, e.itemData.id, e.itemData, validateMC));
  };

  const handleDeleteAttribute = (e) => {
    dispatch(setDeleteProductAttributeById(productId, e.itemData.id, e.itemData.attID, validateMC));
  };

  const created = (args) => {
    setTimeout(function () {
      mulObj.showPopup();
    }, 460);
  };

  const close = (args) => {
    args.cancel = true;
  };

  return (
    <Modal isOpen={isAddAttrModalOpen} toggle={() => setIsAddAttrModalOpen((prev) => !prev)}>
      <ModalHeader toggle={() => setIsAddAttrModalOpen((prev) => !prev)}>Add Attribute</ModalHeader>
      <ModalBody className="h-600p">
        <div className="control-pane">
          <div id="multisection" className="control-section filter-select">
            <div id="multidefault">
              <div className="control-styles">
                <div>
                  <AppErrorBoundary>
                    {isAddAttrModalOpen && (
                      <MultiSelectComponent
                        ref={(scope) => {
                          mulObj = scope;
                        }}
                        id="checkbox"
                        dataSource={updatedAttributesArr}
                        value={updatedAttributesArrById}
                        filterBarPlaceholder="Search attribute"
                        fields={attributesField}
                        placeholder="Select attribute"
                        mode="CheckBox"
                        // showSelectAll={true}
                        enableGroupCheckBox={true}
                        showDropDownIcon={true}
                        enableSelectionOrder={false}
                        popupHeight="550px"
                        select={(e) => handleAddAttribute(e)}
                        removed={(e) => handleDeleteAttribute(e)}
                        close={close}
                        created={created}
                        openOnClick="false"
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    )}
                  </AppErrorBoundary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => setIsAddAttrModalOpen((prev) => !prev)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddAttributeModal;
