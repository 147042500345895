import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
//components
import CoverageAreasActions from 'components/delivery/coverageAreas/CoverageAreasActions';
import CoverageAreasDataGrid from 'components/delivery/coverageAreas/CoverageAreasDataGrid';
import DJHPagination from 'components/shared/Pagination';
import CoverageAreaModal from 'components/delivery/areas/modals/CoverageAreaModal';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
//actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
//api
import DeliveryService from 'services/DeliveryService';

const CoverageAreasPage = () => {
  const [inputValues, setInputValues] = useState({
      page: 1,
    }),
    [isCoverageModalOpen, setIsCoverageModalOpen] = useState(false),
    [coverageAreasData, setCoverageAreasData] = useState([]),
    dispatch = useDispatch(),
    location = useLocation(),
    isFulfillmentCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER)?.centerType === 2;

  const fetchCoverageAreasList = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await DeliveryService.getCoverageAreasList(params);
      setCoverageAreasData(res?.data?.Data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchCoverageAreasList(queryObj);
    } else {
      fetchCoverageAreasList(inputValues);
    }
  }, []);

  const onPageChange = (i) => {
    setInputValues({ page: i });
    fetchCoverageAreasList({ page: i });
    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      {isCoverageModalOpen && (
        <CoverageAreaModal
          isModalOpen={isCoverageModalOpen}
          setIsModalOpen={setIsCoverageModalOpen}
          fetchCoverageAreasList={fetchCoverageAreasList}
        />
      )}
      {isFulfillmentCenter ? (
        <div className="quick-actions-table toolbar-table-style">
          <CoverageAreasActions setIsCoverageModalOpen={setIsCoverageModalOpen} />
          <CoverageAreasDataGrid
            data={coverageAreasData.Data}
            inputValues={inputValues}
            fetchCoverageAreasList={fetchCoverageAreasList}
          />
          <DJHPagination
            onPageChange={onPageChange}
            currentPage={+coverageAreasData?.CurrentPage}
            pageSize={+coverageAreasData?.PageSize}
            itemsCount={+coverageAreasData?.RowCount}
          />
        </div>
      ) : (
        <h1 className="sz-18 m-t-50 bold-title text-center">
          No coverage areas available for this ops
        </h1>
      )}
    </>
  );
};

export default CoverageAreasPage;
