import React, { useState, Fragment } from 'react';
// components
import PageTitle from 'components/shared/PageTitle';
import GenerateShorterLinkModal from 'components/shorterLink/GenerateShorterLinkModal';
import ShorterLinkDataGrid from 'components/shorterLink/ShorterLinkDataGrid';

const ShorterLinkPage = () => {
  const [isGenerateShorterLinkModalOpen, setIsGenerateShorterLinkModalOpen] = useState(false);

  return (
    <Fragment>
      <PageTitle title="Shorter-link" />
      <div className="card toolbar-table-style">
        <div className="card-header">
          <button
            onClick={() => setIsGenerateShorterLinkModalOpen((prev) => !prev)}
            className="btn btn-success"
          >
            <i className="fa fa-plus"></i> Generate Link
          </button>
        </div>

        <div className="recent-notification card-body">
          <ShorterLinkDataGrid />
        </div>
      </div>
      {isGenerateShorterLinkModalOpen && (
        <GenerateShorterLinkModal
          isGenerateShorterLinkModalOpen={isGenerateShorterLinkModalOpen}
          setIsGenerateShorterLinkModalOpen={setIsGenerateShorterLinkModalOpen}
        />
      )}
    </Fragment>
  );
};

export default ShorterLinkPage;
