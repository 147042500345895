export const getInvoicesDataList = ({ state }) => state.invoices.invoices.data;
export const getInvoicesPageSize = ({ state }) => state.invoices.invoices.pageSize;
export const getInvoicesCurrentPage = ({ state }) => state.invoices.invoices.currentPage;
export const getInvoicesItemsCount = ({ state }) => state.invoices.invoices.rowCount;

export const getCorporateInvoicesDataList = ({ state }) => state.invoices.corporateInvoices;
export const getSelectedInvoicesDataList = ({ state }) => state.invoices.selectedCorporateInvoice;

export const getConsolidatedInvoicesDataList = ({ state }) =>
  state.invoices.consolidatedInvoicesData;
export const getGeneratedConsolidatedInvoicesData = ({ state }) =>
  state.invoices.generatedConsolidatedInvoicesData;
