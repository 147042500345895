import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductLogsDataGrid from './ProductLogsDataGrid';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import PageTitle from 'components/shared/PageTitle';
import LogFilter from './LogFilter';
import { isoFormat } from 'constant/Helpers';
import DJHPagination from 'components/shared/Pagination';
// selectors
import { getProductLog, getSelectedProduct } from 'store/masterCatalog/MasterCatalogSelectors';
// actions
import { setProductLog } from 'store/masterCatalog/MasterCatalogActions';
// service
import MasterCatalogService from 'services/MasterCatalogService';

const ProductLogsDataTable = ({ toggleProductLog }) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    startDate: isoFormat(new Date()),
    endDate: isoFormat(new Date()),
    page: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const productLogData = useSelector((state) => getProductLog({ state }));
  const selectedProductID = useSelector((state) => getSelectedProduct({ state }));

  const handleSubmit = () => {
    dispatch(setProductLog(inputValues, selectedProductID));
  };

  const handleExport = async () => {
    setIsLoading(true);
    const params = {
      startDate: inputValues.startDate,
      endDate: inputValues.endDate,
    };
    try {
      const res = await MasterCatalogService.exportProductLog(params, selectedProductID);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Product log.xlsx');
      document.body.appendChild(link);
      link.click();
      toast.success('Downloaded');
      setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Failed to download');
      setIsLoading(false);
    }
  };

  const onPageChange = (i) => {
    const newParams = cloneDeep(inputValues);
    newParams.page = i;
    dispatch(setProductLog(newParams, selectedProductID));
  };

  useEffect(() => {
    if (toggleProductLog) {
      dispatch({
        type: 'SET_PRODUCT_LOG',
        payload: [],
      });
    }
  }, [toggleProductLog]);

  return (
    <div className="quick-actions-table toolbar-table-style">
      <PageTitle title="Master Catalog" />
      <h5>Product Log</h5>
      <LogFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        handleExport={handleExport}
      />
      <ProductLogsDataGrid
        data={productLogData.data}
        inputValues={inputValues}
        selectedProductID={selectedProductID}
        isLoading={isLoading}
      />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+productLogData.currentPage}
        pageSize={+productLogData.pageSize}
        itemsCount={+productLogData.rowCount}
      />
    </div>
  );
};

export default React.memo(ProductLogsDataTable);
