import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setViewOrderData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DeliveryChargeModal = ({
  isDeliveryChargeModalOpen,
  setIsDeliveryChargeModalOpen,
  orderId,
}) => {
  const [deliveryCharge, setDeliveryCharge] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const updateDeliveryCharge = async () => {
    if (deliveryCharge >= 0 && !isNaN(deliveryCharge)) {
      const dataBody = {
        value: deliveryCharge,
      };
      setIsLoaderOpen(true);
      setDisableBtn(true);
      try {
        await ViewOrderService.updateDeliveryCharge(dataBody, orderId);
        setTimeout(() => {
          setIsLoaderOpen(false);
          setDisableBtn(false);
          setIsDeliveryChargeModalOpen((prev) => !prev);
          dispatch(setViewOrderData(orderId));
          toast.success('Delivery charge updated successfully');
        }, 1500);
      } catch (e) {
        errorLogger('DeliveryChargeModal:updateDeliveryCharge', e);
        setIsDeliveryChargeModalOpen((prev) => !prev);
        toast.error('Failed to update delivery charge');
      }
    } else {
      toast.error('Please use a correct value');
    }
  };
  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isDeliveryChargeModalOpen}
        toggle={() => setIsDeliveryChargeModalOpen((prev) => !prev)}
      >
        <ModalBody>
          {isLoaderOpen ? (
            <SectionLoader height="150px" />
          ) : (
            <form>
              <div className="form-group col-12">
                <label className="col-form-label" htmlFor="message-text">
                  Delivery Charge
                </label>
                <input
                  onChange={(e) => setDeliveryCharge(e.target.value)}
                  className="form-control"
                  value={deliveryCharge}
                  id="message-text"
                ></input>
              </div>
            </form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={updateDeliveryCharge}
            color="primary"
            disabled={disableBtn || !deliveryCharge}
          >
            Save
          </Button>
          <Button onClick={() => setIsDeliveryChargeModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeliveryChargeModal;
