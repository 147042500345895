import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { setSelectedOrdersIds } from 'store/orderQuickPrinting/QuickPrintingActions';
// Helpers
import cloneDeep from 'lodash/cloneDeep';
import { dateFormatDDMM } from 'constant/Helpers';
// Components
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import OrderBlock from 'components/orders/quickActions/DataTableComponents/OrderBlock';

const QuickPrintingDataGrid = ({ data }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const toolbarOptions = ['ColumnChooser'];

  // Functions
  const checkAll = (e) => {
    const classNames = e.target.className;
    const isChecked = classNames.includes('is-checked');
    if (!isChecked) {
      ref.current.selectRowsByRange(0, 99);
      document.getElementsByClassName('selectAllcheckbox')[0].className =
        'selectAllcheckbox is-checked';
    } else {
      ref.current.clearSelection();
      document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
    }
  };

  const checkSelecting = (args) => {
    if (args.target) {
      if (
        args.target &&
        (args.target.className.indexOf('img-contain') > -1 ||
          args.target.className.indexOf('link') > -1 ||
          args.target.className.indexOf('btn-light-danger') > -1 ||
          args.target.className.indexOf('vip-order') > -1 ||
          args.target.className.indexOf('quick-action-see-more') > -1)
      ) {
        args.cancel = true;
      }
    }
  };

  const checkSelected = (args) => {
    if (
      args.target &&
      (args.target.className.indexOf('img-contain') > -1 ||
        args.target.className.indexOf('link') > -1 ||
        args.target.className.indexOf('btn-light-danger') > -1 ||
        args.target.className.indexOf('vip-order') > -1 ||
        args.target.className.indexOf('quick-action-see-more') > -1)
    ) {
      args.cancel = true;
    }
    const selectedrecords = cloneDeep(ref.current.getSelectedRecords());
    if (selectedrecords.length === data.length) {
      document.getElementsByClassName('selectAllcheckbox')[0].className =
        'selectAllcheckbox is-checked';
    } else {
      if (selectedrecords.length > 0) {
        document.getElementsByClassName('selectAllcheckbox')[0].className =
          'selectAllcheckbox some-check';
      } else {
        document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
      }
    }
    const selectedOrderIds = selectedrecords.map((record) => record.orderId);
    dispatch(setSelectedOrdersIds(selectedOrderIds));
  };

  // Templates
  const checkboxTemplate = () => {
    return (
      <div className="e-checkbox-wrapper e-css">
        <input className="e-checkselect" type="checkbox" />
        <span className="e-frame e-icons"></span>
        <span className="e-label"> </span>
      </div>
    );
  };

  const checkboxHeaderTemplate = () => {
    return <span className="selectAllcheckbox" onClick={checkAll}></span>;
  };

  const idTemplate = (rowData) => {
    return (
      <OrderBlock
        id={rowData?.orderId}
        detailsPrinted={rowData?.isPrinted}
        membershipType={rowData?.membershipType}
        isBraillePrint={rowData?.isBraillePrint}
        isQuickPrinting
      />
    );
  };

  const deliveryDateTemplate = (rowData) => {
    return <span>{dateFormatDDMM(rowData.deliveryDate)}</span>;
  };

  const mainAreaTemplate = (rowData) => {
    return <span>{rowData?.mainArea?.areaName}</span>;
  };

  const subAreaTemplate = (rowData) => {
    return <span>{rowData?.subArea?.areaName}</span>;
  };

  return (
    <div className="control-section">
      <GridComponent
        dataSource={data}
        toolbar={toolbarOptions}
        allowExcelExport={true}
        allowSorting={true}
        allowMultiSorting={true}
        ref={ref}
        allowTextWrap={true}
        showColumnChooser={true}
        selectionSettings={{ type: 'Multiple', enableSimpleMultiRowSelection: true }}
        rowSelecting={checkSelecting}
        rowDeselecting={checkSelecting}
        rowSelected={checkSelected}
        rowDeselected={checkSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            width="75"
            customAttributes={{ class: 'row-select-checkbox' }}
            template={checkboxTemplate}
            headerTemplate={checkboxHeaderTemplate}
            textAlign="Center"
            showInColumnChooser={false}
          />
          <ColumnDirective
            field="orderId"
            width={80}
            headerText="Order ID"
            textAlign="Center"
            template={idTemplate}
          />
          <ColumnDirective
            field="deliveryDate"
            width={80}
            headerText="Delivery Date"
            template={deliveryDateTemplate}
            textAlign="Center"
          />
          <ColumnDirective width={80} headerText="Time Slot" field="slotName" textAlign="Center" />
          <ColumnDirective
            field="mainArea.areaName"
            width={80}
            headerText="Main Area"
            template={mainAreaTemplate}
            textAlign="Center"
          />
          <ColumnDirective
            field="subArea.areaName"
            width={80}
            headerText="Subarea"
            template={subAreaTemplate}
            textAlign="Center"
          />
          <ColumnDirective
            field="deliveryZone"
            width={80}
            headerText="Delivery Zone"
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject services={[Toolbar, ColumnChooser, Sort]} />
      </GridComponent>
    </div>
  );
};

export default QuickPrintingDataGrid;
