import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CustomSelect from 'components/shared/CustomSelect';
// datagrid
import ProductMasterAvailabilityDataGrid from '../productsDataGrid/ProductMasterAvailabilityDataGrid';
// selectors
import { getValidatingState } from 'store/products/ProductsSelectors';

const ProductMasterAvailability = ({
  productMasterAvailabilityById,
  productId,
  isValid,
  notValidFields,
  validateMA,
}) => {
  const [accActive, setAccActive] = useState(true);
  const [clonedMasterAviData, setClonedMasterAviData] = useState([]);
  const [availabilityData, setAvailabiltyData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(-1);
  const [countries, setCountries] = useState([]);
  const isValidating = useSelector((state) => getValidatingState({ state }));
  const dispatch = useDispatch();

  const handleSearchPage = (e) => {
    setSelectedCountry(e.itemData.id);
  };

  useEffect(() => {
    if (clonedMasterAviData.length === 0) {
      setClonedMasterAviData(productMasterAvailabilityById);
      const newCountries = [];
      productMasterAvailabilityById.forEach((item, i) => {
        const newObj = {
          id: item.country.id,
          name: item.country.name,
        };
        newCountries.push(newObj);
      });
      setCountries(newCountries);
    }
  }, [productMasterAvailabilityById]);

  useEffect(() => {
    const availabilityData =
      selectedCountry && selectedCountry !== -1
        ? productMasterAvailabilityById.filter((item) => item.country.id === selectedCountry)
        : productMasterAvailabilityById;
    setAvailabiltyData(availabilityData);
  }, [productMasterAvailabilityById, selectedCountry]);

  return (
    <>
      <div className="form-group filter-select w-30 m-b-20">
        <label className="bold-title" htmlFor="master-availability-country">
          Country
        </label>
        <CustomSelect
          id="master-availability-country"
          listData={[{ id: -1, name: 'All' }].concat(countries)}
          handleChange={handleSearchPage}
          placeholder="Select a country"
          textPropName="name"
          valuePropName="id"
          selectedValue={selectedCountry}
        />
      </div>
      <div className="no-toolbar-table-style">
        {availabilityData.map((item, i) => (
          <Accordion
            key={i}
            allowZeroExpanded
            preExpanded={Array.from({ length: productMasterAvailabilityById.length }, (_, i) => i)}
          >
            <AccordionItem uuid={i}>
              <AccordionItemHeading onClick={() => setAccActive((prev) => !prev)}>
                <AccordionItemButton>
                  <div className="card-header between-flex pointer p-18 light-blue-bg">
                    <h5>{item.country.name}</h5>
                    {accActive ? (
                      <i className="fa fa-chevron-up"></i>
                    ) : (
                      <i className="fa fa-chevron-down"></i>
                    )}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {!isValidating && (
                  <ProductMasterAvailabilityDataGrid
                    productData={item.pricing}
                    productId={productId}
                    isValid={isValid}
                    notValidFields={notValidFields}
                    validateMA={validateMA}
                  />
                )}
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default ProductMasterAvailability;
