import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import { dateFormatDDMM } from 'constant/Helpers';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const CartProductDiscountDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Date"
              template={(rowData) => (
                <span>{rowData?.result_date && dateFormatDDMM(rowData?.result_date)}</span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData?.result_date && dateFormatDDMM(rowData?.result_date)
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Name"
              field="proName"
              width={150}
              customExcelTemplate={(rowData) => rowData?.proName}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Discount Price"
              field="discountPrice"
              width={100}
              customExcelTemplate={(rowData) => rowData?.discountPrice}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Original Price"
              field="productOriginalPrice"
              width={120}
              customExcelTemplate={(rowData) => rowData?.productOriginalPrice}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="QTY"
              field="qty"
              width={80}
              customExcelTemplate={(rowData) => rowData?.qty}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Web"
              field="web"
              width={80}
              customExcelTemplate={(rowData) => rowData?.web}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="IOS"
              field="ios"
              width={80}
              customExcelTemplate={(rowData) => rowData?.ios}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Android"
              field="android"
              width={80}
              customExcelTemplate={(rowData) => rowData?.android}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Carriage"
              field="carriage"
              width={80}
              customExcelTemplate={(rowData) => rowData?.carriage}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(CartProductDiscountDataGrid);
