import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import UserDefaultAddressDataGrid from './UserDefaultAddressDataGrid';
import PageTitle from 'components/shared/PageTitle';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DefaultAddressesPage = () => {
  const [searchKey, setSearchKey] = useState('');
  const [addressesList, setAddressesList] = useState([]);
  const dispatch = useDispatch();

  const fetchUsers = async () => {
    const params = {
      phone: searchKey,
    };
    dispatch(setLoaderOpenTrue());
    try {
      const res = await PermissionService.getUserAddressesList(params);
      setAddressesList(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('DefaultAddressesPage:fetchUserAddressesList', err);
      dispatch(setLoaderOpenFalse());
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchUsers();
  };

  return (
    <div className="no-toolbar-table-style">
      <PageTitle title="Users default Addresses" />
      <div className="card">
        <div className="card-header p-18">
          <h4>Recipient Default Addresses</h4>
        </div>
        <form onSubmit={(e) => handleSubmit(e)} className="form theme-form">
          <div className="card-body">
            <div className="form-group col-12 col-sm-6">
              <label htmlFor="search-recipient">Search Recipient</label>
              <input
                id="search-recipient"
                className="form-control"
                type="text"
                placeholder="By phone number"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
            </div>
          </div>
          <div className="card-footer ">
            <button type="submit" className="btn btn-primary mr-1">
              Search
            </button>
          </div>
        </form>
      </div>
      <UserDefaultAddressDataGrid data={addressesList} />
    </div>
  );
};

export default DefaultAddressesPage;
