/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { dateFormatDDMM } from 'constant/Helpers';
import { cStep } from 'constant/Enums';
// modals and components
import EditAddressModal from './modals/EditAddressModal';
import RecipientAddressListModal from './modals/RecipientAddressListModal';
import UserTotalOrdersList from './modals/UserTotalOrdersList';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import Tooltip from '@mui/material/Tooltip';
import RestrictedSection from 'components/shared/RestrictedSection';
// actions
import {
  setToggleAddressModalOpen,
  setShareSenderDetails,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getIsAddressModalOpen,
  getupdatedCstep,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// api
import DeliveryService from 'services/DeliveryService';
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const OrderInfo = ({ orderData, orderId, driverData }) => {
  const isAddressModalOpen = useSelector((state) => getIsAddressModalOpen({ state }));
  const updatedCstep = useSelector((state) => getupdatedCstep({ state }));
  const [isRxAddressModalOpen, setIsRxAddressModalOpen] = useState(false);
  const [isUserTotalOrdersOpen, setIsUserTotalOrdersOpen] = useState(false);
  const [sharedStatus, setSharedStatus] = useState(orderData.shareSender);
  const [rxTotalAddress, setRxTotalAddress] = useState('');
  const [recipientOrders, setRecipientOrders] = useState('');
  const [userOrders, setUserOrders] = useState('');
  const [defaultLat, setDefaultLat] = useState(orderData.ops.lat);
  const [defaultLng, setDefaultLng] = useState(orderData.ops.lng);
  const [driverOrdersList, setDriverOrdersList] = useState([]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    if (orderData.recipient) {
      const rxOrdersParams = {
        countryId: orderData && orderData.recipient && orderData.recipient.countryID,
      };
      try {
        const recipientOrders = await ViewOrderService.getRecipientOrders(
          rxOrdersParams,
          orderData.recipient.recipientPhone
        );
        setRecipientOrders(recipientOrders.data);
      } catch (e) {
        errorLogger('OrderInfo:fetchData:RecipientOrders', e);
        // console.log(e);
      }
      try {
        const userOrders = await ViewOrderService.getUserOrders(orderData.user.id);
        setUserOrders(userOrders.data);
      } catch (e) {
        errorLogger('OrderInfo:fetchData:UserDetails', e);
        // console.log(e);
      }
      const rxAddressParams = {
        countryId:
          orderData &&
          orderData.recipient &&
          orderData.recipient.countryID &&
          orderData.recipient.countryID,
        phone: orderData && orderData.recipient && orderData.recipient.recipientPhone,
      };
      try {
        const recipientTotalAddress = await ViewOrderService.getRecipientTotalAddress(
          rxAddressParams
        );
        setRxTotalAddress(recipientTotalAddress.data.data);
      } catch (e) {
        errorLogger('OrderInfo:fetchData:RecipientTotalAddress', e);
        // console.log(e);
      }
    }
  };

  const fetchDriversList = async () => {
    if (driverData.slice(-1).pop().tripId) {
      try {
        const res = await DeliveryService.getTripDriverOrdersList(
          driverData.slice(-1).pop().tripId
        );
        setDriverOrdersList(res.data);
      } catch (err) {
        errorLogger('OrderInfo:fetchDriversList', err);
        if (err.response?.data?.message) {
          toast.error(err.response.data.message);
        }
      }
    }
  };

  const handleTimeStamp = (date) => {
    const dateObject = new Date(date);
    const humanDateFormat = dateObject.toLocaleString();
    return humanDateFormat;
  };

  useEffect(() => {
    if (Object.keys(driverData).length > 0) {
      fetchDriversList();
    }
  }, [driverData]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setDefaultLat(orderData.ops.lat);
    setDefaultLng(orderData.ops.lng);
  }, []);

  const handleShareDetails = (id, status) => {
    try {
      dispatch(setShareSenderDetails(id, status, false));
      setSharedStatus((prev) => !prev);
    } catch (err) {
      // console.log(err);
    }
  };

  const getETA = (driverOrderList) => {
    if (
      driverOrderList.filter(function (driverOrder) {
        return parseInt(driverOrder.orderID, 10) === parseInt(orderId, 10);
      }).length > 0
    ) {
      return (
        <>
          <h6 className="eta-title">ETA:</h6>
          <div className="eta-wrapper">
            {driverOrderList.filter(function (driverOrder) {
              return parseInt(driverOrder.orderID, 10) === parseInt(orderId, 10);
            })[0].eta
              ? handleTimeStamp(
                  driverOrderList.filter(function (driverOrder) {
                    return parseInt(driverOrder.orderID, 10) === parseInt(orderId, 10);
                  })[0].eta
                )
              : 'Unknown'}
          </div>
        </>
      );
    } else {
      return null;
    }
  };
  return (
    <Fragment>
      {isAddressModalOpen && (
        <AppErrorBoundary>
          <EditAddressModal
            defaultLat={defaultLat}
            defaultLng={defaultLng}
            orderId={orderId}
            recipient={orderData.recipient}
            orderOps={orderData.opsID}
            premium={false}
            countryIso={orderData.ops.isoCode}
          />
        </AppErrorBoundary>
      )}
      {isRxAddressModalOpen && (
        <AppErrorBoundary>
          <RecipientAddressListModal
            countryID={orderData.recipient.countryID && orderData.recipient.countryID}
            phone={orderData.recipient.recipientPhone && orderData.recipient.recipientPhone}
            isRxAddressModalOpen={isRxAddressModalOpen}
            setIsRxAddressModalOpen={setIsRxAddressModalOpen}
            orderId={orderId}
          />
        </AppErrorBoundary>
      )}
      {isUserTotalOrdersOpen && (
        <AppErrorBoundary>
          <UserTotalOrdersList
            isUserTotalOrdersOpen={isUserTotalOrdersOpen}
            setIsUserTotalOrdersOpen={setIsUserTotalOrdersOpen}
            userId={orderData.user.id}
          />
        </AppErrorBoundary>
      )}
      {orderData !== '' && (
        <RestrictedSection page="order_details" action="user_info">
          <div className="card height-equal order-info-wrapper">
            <div className="card-body">
              <div className="task-list mb-3 row">
                <div className="col-sm-6 col-xl-4 sender-details-wrapper">
                  <h6>Sender Details</h6>
                  <ul>
                    <a
                      className="sender-name"
                      href={`/admin/users/${orderData.user.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li>
                        <i className="fa fa-caret-right mr-1"></i>
                        Name: <span>{orderData.user.name}</span>
                      </li>
                    </a>

                    <li
                      className="sender-phone"
                      onClick={() => {
                        navigator.clipboard.writeText(orderData.user.phone.replace(/\s/g, ''));
                        toast.success('Copied');
                      }}
                    >
                      <i className="fa fa-caret-right mr-1"></i>
                      Phone:{' '}
                      <span>
                        ({orderData.user.code}) {orderData.user.phone}
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-caret-right mr-1"></i>
                      Email: {orderData.user.email}
                    </li>
                    {userOrders && (
                      <li>
                        <i className="fa fa-caret-right mr-1"></i>
                        Today&apos;s Orders: {userOrders.todayOrders}
                      </li>
                    )}
                  </ul>
                  {userOrders && (
                    <button
                      disabled={userOrders.totalorders === 0}
                      className="btn btn-success total-orders-btn"
                      onClick={() => setIsUserTotalOrdersOpen((prev) => !prev)}
                    >
                      {`Total Orders: ${userOrders.totalorders}`}
                    </button>
                  )}
                  <button
                    onClick={() => handleShareDetails(orderId, sharedStatus)}
                    className={`btn ${
                      sharedStatus ? 'btn-success' : 'btn-danger'
                    } share-details-btn`}
                    disabled={orderData.cStep === 4}
                  >
                    {sharedStatus ? 'Share my details' : "Don't share my details"}
                  </button>
                  {orderData.membershipType === 3 && <div className="vip-order">VIP</div>}
                </div>
                <div className="col-sm-6 col-xl-4 receipient-details-wrapper">
                  <div>
                    <h6>Recipient Details</h6>
                    {orderData.cStep !== 4 && (
                      <RestrictedSection page="order_details" action="edit_address">
                        <Tooltip title="Edit">
                          <i
                            onClick={() => dispatch(setToggleAddressModalOpen())}
                            className="fa fa-edit"
                          ></i>
                        </Tooltip>
                      </RestrictedSection>
                    )}
                  </div>
                  {orderData.recipient !== null && (
                    <ul>
                      {orderData.recipient.recipentName && (
                        <li>
                          <i className="fa fa-caret-right mr-1"></i>
                          Name:{' '}
                          {orderData.recipient &&
                            orderData.recipient.recipentName &&
                            orderData.recipient.recipentName}
                        </li>
                      )}
                      {orderData.recipient.recipientPhone && (
                        <li
                          className="receipient-phone"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              orderData.recipient.recipientPhone.replace(/\s/g, '')
                            );
                            toast.success('Copied');
                          }}
                        >
                          <i className="fa fa-caret-right mr-1"></i>
                          Phone:{' '}
                          <span>
                            {' '}
                            ({orderData.recipient.code}){' '}
                            {orderData.recipient &&
                              orderData.recipient.recipientPhone &&
                              orderData.recipient.recipientPhone}
                          </span>
                        </li>
                      )}
                      {orderData.recipient.extraRecipientNumber &&
                        orderData?.recipient?.extraRecipientCountryCode && (
                          <li
                            className="receipient-phone"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                orderData.recipient.extraRecipientNumber.replace(/\s/g, '')
                              );
                              toast.success('Copied');
                            }}
                          >
                            <i className="fa fa-caret-right mr-1"></i>
                            Extra Phone:{' '}
                            <span>
                              {' '}
                              ({orderData?.recipient?.extraRecipientCountryCode}){' '}
                              {orderData?.recipient?.extraRecipientNumber}
                            </span>
                          </li>
                        )}
                      {orderData.recipient.area && (
                        <li>
                          <i className="fa fa-caret-right mr-1"></i>
                          Area:{' '}
                          <a
                            className="redirection-link"
                            href={`/delivery/areas/${orderData.recipient.areaid}/edit`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {orderData.recipient.area}
                          </a>
                        </li>
                      )}
                      {orderData.recipient.address && (
                        <li>
                          <i className="fa fa-caret-right mr-1"></i>
                          Address Line (Home):{' '}
                          {orderData.recipient &&
                            orderData.recipient.address &&
                            orderData.recipient.address}
                        </li>
                      )}
                      {orderData.recipient.postalCode && (
                        <li>
                          <i className="fa fa-caret-right mr-1"></i>
                          Postal Code:{' '}
                          {orderData.recipient &&
                            orderData.recipient.postalCode &&
                            orderData.recipient.postalCode}
                        </li>
                      )}
                      {orderData.leaveLocation && (
                        <li>
                          <i className="fa fa-caret-right mr-1"></i>
                          Leave Location: {orderData.leaveLocation}
                        </li>
                      )}
                      {orderData.recipient.extra && (
                        <li>
                          <i className="fa fa-caret-right mr-1"></i>
                          Extra Direction:{' '}
                          {orderData.recipient &&
                            orderData.recipient.extra &&
                            orderData.recipient.extra}
                        </li>
                      )}
                      {orderData.recipient && orderData.recipient.lat && orderData.recipient.lng && (
                        <a
                          className="view-on-map-link"
                          href={`https://www.google.com/maps/?q=${orderData.recipient.lat},${orderData.recipient.lng}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View on map <i className="fa fa-map-marker"></i>
                        </a>
                      )}
                      {recipientOrders && (
                        <li>
                          <i className="fa fa-caret-right mr-1"></i>
                          Today&apos;s Orders: {recipientOrders.todayOrders}
                        </li>
                      )}
                      {recipientOrders && (
                        <li>
                          <i className="fa fa-caret-right mr-1"></i>
                          Total Orders: {recipientOrders.totalorders}
                        </li>
                      )}
                    </ul>
                  )}
                  {rxTotalAddress !== '' && (
                    <button
                      disabled={rxTotalAddress === 1 || rxTotalAddress === 0}
                      className="btn btn-success total-addresses-btn"
                      onClick={() => setIsRxAddressModalOpen((prev) => !prev)}
                    >
                      {`Total Addresses: ${rxTotalAddress}`}
                    </button>
                  )}
                </div>
                <div className="col-sm-6 col-xl-4 team-note-wrapper">
                  {orderData.noteForTeam && (
                    <>
                      <h6>Note For Team:</h6>
                      <div className="order-team-note">{orderData.noteForTeam}</div>
                    </>
                  )}
                  {orderData.paymentDate && (
                    <>
                      <h6>Payment Date:</h6>
                      <div className="order-payment-date">
                        {dateFormatDDMM(orderData.paymentDate)}{' '}
                        {orderData.paymentDate.slice(11, 16)}
                      </div>
                      {orderData?.isPreOrder ? (
                        <>
                          <br />
                          <div className="order-badge">Pre Order</div>{' '}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                  {cStep[updatedCstep]
                    ? cStep[updatedCstep].toLocaleLowerCase() === 'delivering' ||
                      cStep[updatedCstep].toLocaleLowerCase() === 'picked'
                      ? getETA(driverOrdersList)
                      : null
                    : cStep[orderData.cStep]
                    ? cStep[orderData.cStep].toLocaleLowerCase() === 'delivering' ||
                      cStep[orderData.cStep].toLocaleLowerCase() === 'picked'
                      ? getETA(driverOrdersList)
                      : null
                    : null}
                  {orderData.mbsBookingReference && (
                    <>
                      <h6 className="order-mbs-title">Mbs Booking Reference:</h6>
                      <div className="order-mbs-value">{orderData.mbsBookingReference}</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </RestrictedSection>
      )}
    </Fragment>
  );
};

export default OrderInfo;
