import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class MerchandisingCategoriesService {
  static getMerchandisingCategoriesData(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getMerchandisingCategoriesData, ...extraParams),
      params,
    });
  }

  static addMerchandisingCategoriesData(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getMerchandisingCategoriesData, ...extraParams),
      data,
    });
  }
}
export default MerchandisingCategoriesService;
