import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Resize,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { dateFormatDDMM, timeFormatHHMM } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const OrderAmountDetailDataGrid = ({ data, params }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = async (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        ref.current.excelExport();
        break;
      default:
        break;
    }
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseInt(rowData.index, 10) + 1}</p>
      </div>
    );
  };

  const refundedAmountTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseFloat(rowData.refundedAmount).toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>
      </div>
    );
  };

  const orderTypeTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.orderType}</p>
      </div>
    );
  };

  const paymentVendorsTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.paymentVendors}</p>
      </div>
    );
  };

  const DeliveryDateTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>
          {dateFormatDDMM(rowData.resultDate)} {timeFormatHHMM(rowData.resultDate)}
        </p>
      </div>
    );
  };

  const paymentDateTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>
          {dateFormatDDMM(rowData.paymentDate)} {timeFormatHHMM(rowData.paymentDate)}
        </p>
      </div>
    );
  };

  const totalTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseFloat(rowData.total).toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>
      </div>
    );
  };

  const walletTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseFloat(rowData.wallet).toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>
      </div>
    );
  };

  const RemainBalanceTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseFloat(rowData.balance).toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>
      </div>
    );
  };

  const taxesTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseFloat(rowData.taxes).toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>
      </div>
    );
  };

  const discountTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseFloat(rowData.discount).toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>
      </div>
    );
  };

  const deliveryCostTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseFloat(rowData.deliveryCost).toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>
      </div>
    );
  };

  const grandtotalTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseFloat(rowData.total).toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>
      </div>
    );
  };

  const orderIdTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.orderId}</p>
      </div>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane m-b-30">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="#"
              template={idTemplate}
              field="orderId"
              width={80}
              customExcelTemplate={(rowData) => rowData.orderId}
            />
            <ColumnDirective
              headerText="Refunded Amount"
              template={refundedAmountTemplate}
              field="refundedAmount"
              width={80}
              customExcelTemplate={(rowData) => rowData.refundedAmount}
            />
            <ColumnDirective
              headerText="Order Status"
              template={orderTypeTemplate}
              field="orderType"
              width={80}
              customExcelTemplate={(rowData) => rowData.orderType}
            />
            <ColumnDirective
              headerText="Payment Vendor"
              template={paymentVendorsTemplate}
              field="paymentVendors"
              width={80}
              customExcelTemplate={(rowData) => rowData.paymentVendors}
            />
            <ColumnDirective
              headerText="Delivery Date"
              template={DeliveryDateTemplate}
              field="resultDate"
              width={80}
              customExcelTemplate={(rowData) => rowData.resultDate}
            />
            <ColumnDirective
              headerText="Payment Date"
              template={paymentDateTemplate}
              field="paymentDate"
              width={80}
              customExcelTemplate={(rowData) => rowData.paymentDate}
            />
            <ColumnDirective
              headerText="Net Amount"
              template={totalTemplate}
              field="total"
              width={80}
              customExcelTemplate={(rowData) => rowData.total}
            />
            <ColumnDirective
              headerText="Wallet"
              template={walletTemplate}
              field="wallet"
              width={80}
              customExcelTemplate={(rowData) => rowData.wallet}
            />
            <ColumnDirective
              headerText="Remain Balance"
              template={RemainBalanceTemplate}
              field="balance"
              width={80}
              customExcelTemplate={(rowData) => rowData.balance}
            />
            <ColumnDirective
              headerText="Taxes"
              template={taxesTemplate}
              field="taxes"
              width={80}
              customExcelTemplate={(rowData) => rowData.taxes}
            />
            <ColumnDirective
              headerText="Discount Amount"
              template={discountTemplate}
              field="discount"
              width={80}
              customExcelTemplate={(rowData) => rowData.discount}
            />
            <ColumnDirective
              headerText="Delivery Cost"
              template={deliveryCostTemplate}
              field="deliveryCost"
              width={80}
              customExcelTemplate={(rowData) => rowData.deliveryCost}
            />
            <ColumnDirective
              headerText="Grand Total"
              template={grandtotalTemplate}
              field="total"
              width={80}
              customExcelTemplate={(rowData) => rowData.total}
            />
            <ColumnDirective
              headerText="Order No"
              template={orderIdTemplate}
              field="orderId"
              width={80}
              customExcelTemplate={(rowData) => rowData.orderId}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page, Resize]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(OrderAmountDetailDataGrid);
