import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class MasterInventoryService {
  static getMasterInventoryList(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getMasterInventoryList),
      data,
    });
  }

  static getMasterInventoryById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getMasterInventoryById, ...extraParams),
    });
  }

  static updateMasterInventory(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getMasterInventoryById, ...extraParams),
      data,
    });
  }

  static getMasterInventoryChildren(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getMasterInventoryChildren, ...extraParams),
    });
  }
}

export default MasterInventoryService;
