import React from 'react';
import { convertUtcToLocalTime, exportToExcel } from 'constant/Helpers';
import MaterialDataGrid from 'components/shared/MaterialDataGrid';

const ticketStatus = {
  0: <i className="fa fa-unlock open-ticket-status-icon" />,
  1: <i className="fa fa-user assigned-ticket-status-icon" />,
  2: <i className="fa fa-lock closed-ticket-status-icon" />,
};

const ticketPriority = {
  0: {
    type: 'Normal',
    color: 'black',
  },
  1: {
    type: (
      <span>
        High <i className="fa fa-exclamation-circle" />
      </span>
    ),
    color: 'red',
  },
};

const TicketsDataGrid = ({ ticketsList }) => {
  //columns
  const columns = [
    {
      title: 'Case ID',
      field: 'caseNumber',
      render: (rowData) => (
        <a
          className="tickets-orderId"
          href={`/ticketing/ticket/${rowData?.id}`}
          target={'_blank'}
          rel="noreferrer"
        >
          {rowData.caseNumber ? rowData.caseNumber : 'N/A'}
        </a>
      ),
    },
    {
      title: 'Order ID',
      field: 'mapid',
      render: (rowData) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              rowData?.mapid ? `/order/${rowData.mapid}` : `/ticketing/ticket/${rowData?.id}`
            );
          }}
          className="tickets-orderId"
          target={'_blank'}
          rel="noreferrer"
        >
          {rowData.mapid ? rowData.mapid : 'N/A'}
        </div>
      ),
    },
    {
      title: 'Title',
      field: 'title',
    },
    {
      title: 'Topic',
      field: 'topicName',
    },
    {
      title: 'Opened By',
      field: 'openedByName',
      render: (rowData) => (
        <div>
          <p>{rowData.openedByName}</p>
          <p>{rowData.openedDate && convertUtcToLocalTime(rowData.openedDate)}</p>
        </div>
      ),
    },
    {
      title: 'Ticket Priority',
      field: 'ticketPriority',
      render: (rowData) => (
        <span style={{ color: ticketPriority[rowData.ticketPriority ?? 0]?.color }}>
          {ticketPriority[rowData.ticketPriority ?? 0]?.type}
        </span>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => {
        switch (rowData.status) {
          case 1:
            return (
              <div className="ticket-status-template">
                <p>{rowData.status != null && ticketStatus[rowData.status]}</p>
                <p>{rowData.assignedToName}</p>
                <p>{rowData.assignedDate && convertUtcToLocalTime(rowData.assignedDate)}</p>
              </div>
            );
          case 2:
            return (
              <div className="ticket-status-template">
                <p>{rowData.status != null && ticketStatus[rowData.status]}</p>
                <p>{rowData.closedByName}</p>
                <p>{rowData.closedDate && convertUtcToLocalTime(rowData.closedDate)}</p>
              </div>
            );
          default:
            return (
              <div className="ticket-status-template">
                <p>{rowData.status != null && ticketStatus[rowData.status]}</p>
                <p>{rowData.openedByName}</p>
                <p>{rowData.openedDate && convertUtcToLocalTime(rowData.openedDate)}</p>
              </div>
            );
        }
      },
    },
  ];

  //actions
  const actions = [
    {
      icon: () => (
        <div className="btn btn-secondary excel-export-btn">
          <i className="fa fa-file"></i> Excel Export
        </div>
      ),
      onClick: () => handleExcelExport(),
      tooltip: 'export',
      isFreeAction: true,
    },
  ];

  //options
  const options = {
    paging: false,
    showTitle: false,
    columnsButton: true,
    exportButton: true,
    sorting: true,
  };

  const onRowClick = (evt, row) => {
    window.open(`/ticketing/ticket/${row.id}`, '_blank');
  };

  const handleExcelExport = () => {
    let excelData = [];
    ticketsList.forEach((row) => {
      let excelRow = {};
      excelRow.orderID = row.mapid;
      excelRow.title = row.title;
      excelRow.topicName = row.topicName;
      excelRow.OpenedBy = row.openedByName;
      excelRow.priority = ticketPriority[row.ticketPriority ?? 0]?.type;
      excelData.push(excelRow);
    });
    exportToExcel(excelData, 'ticketing');
  };

  return (
    <MaterialDataGrid
      data={ticketsList}
      columns={columns}
      options={options}
      actions={actions}
      onRowClick={onRowClick}
    />
  );
};

export default React.memo(TicketsDataGrid);
