import React, { useState, useEffect } from 'react';
import DateRangePickerComponent from 'components/shared/DateRangePickerComponent';
import { dateFormat } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
import { RefreshCw } from 'react-feather';
import RestrictedSection from 'components/shared/RestrictedSection';

const DriversExpensesFilter = ({
  inputValues,
  setInputValues,
  handleSubmit,
  title,
  setIsAddDriverModalOpen,
  handleExport,
}) => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[e.target.name] = e.target.value;
    setInputValues(updatedParams);
  };

  const handleDateRange = (item) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.fromDate = dateFormat(item.selection.startDate);
    updatedParams.toDate = dateFormat(item.selection.endDate);
    setInputValues(updatedParams);
    setDateRange(item.selection);
  };

  useEffect(() => {
    setDateRange({
      startDate: new Date(inputValues.fromDate),
      endDate: new Date(inputValues.toDate),
      key: 'selection',
    });
  }, [inputValues]);

  return (
    <div className="card">
      <div className="card-header p-18">
        <h5 className="sz-14">{title}</h5>
      </div>
      <RestrictedSection page="drivers_expenses" action="add_driver_expenses">
        <div className="m-10">
          <button
            onClick={() => setIsAddDriverModalOpen((prev) => !prev)}
            className="btn btn-success"
          >
            <i className="fa fa-plus"></i> Add New
          </button>
        </div>
      </RestrictedSection>

      <div className="row card-body">
        <div className="col-12 col-md-4">
          <div className="form-group col-flex">
            <label className="bold-title" htmlFor="expense-range">
              Date Range
            </label>
            <div className="normal-flex">
              <input
                id="expense-range"
                className="form-control digits pointer white-bg"
                value={`${dateFormat(dateRange.startDate)} - ${dateFormat(dateRange.endDate)}`}
                onClick={() => setShowDatePicker((prev) => !prev)}
                readOnly
              />
              <RefreshCw
                className="refresh-driver-date-range"
                onClick={() => window.location.reload()}
              />
            </div>

            {showDatePicker && (
              <DateRangePickerComponent
                setShowDatePicker={setShowDatePicker}
                handleDateRange={handleDateRange}
                dateRange={dateRange}
              />
            )}
          </div>
        </div>
        <div className="col-12 col-md-4">
          <form className="form theme-form">
            <div className="form-group">
              <label className="bold-title" htmlFor="expense-search">
                Search Driver
              </label>
              <input
                id="expense-search"
                className="form-control"
                type="text"
                placeholder="search driver name or phone"
                value={inputValues.searchKey}
                onChange={handleChange}
                name="searchKey"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-between">
        <button onClick={handleSubmit} className="btn btn-primary mr-1" type="button">
          Search
        </button>
        <button onClick={handleExport} className="btn btn-secondary mr-1" type="button">
          Export
        </button>
      </div>
    </div>
  );
};

export default DriversExpensesFilter;
