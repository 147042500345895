import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
} from '@syncfusion/ej2-react-grids';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
// api
import KeyValueService from 'services/KeyValueService';
// actions
import { toggleStatus } from 'store/keyValues/keyValueActions';

const KeyValuesDataGrid = ({ data, handleClick }) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const idTemplate = (rowData) => <p>{parseInt(rowData.index, 10) + 1}</p>;
  const iconEnTemplate = (rowData) => (
    <img src={rowData.IconFullEn} alt="" className="icon-img-template" />
  );
  const iconArTemplate = (rowData) => (
    <img src={rowData.IconFullAr} alt="" className="icon-img-template" />
  );
  const editTemplate = (rowData) => (
    <div className="d-flex flex-column btns-column">
      <Tooltip title="Edit">
        <i onClick={() => handleClick(rowData)} className="fa fa-edit fa-2x" />
      </Tooltip>
    </div>
  );

  const statusTemplate = (rowData) => {
    return (
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={rowData.isActive}
            onChange={() => handletoggleStatus(rowData)}
          />
        }
        label={rowData.isActive ? 'Active' : 'Inactive'}
      />
    );
  };

  const handletoggleStatus = async (rowData) => {
    try {
      const data = { isActive: !rowData.isActive };
      await KeyValueService.updateKeyValueStatus(data, rowData.Id);
      toast.success('Key value status changed successfully!');
      dispatch(toggleStatus(rowData.Id));
    } catch (e) {
      toast.error('Failed to change key value status!');
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section key-value-grid">
        <GridComponent
          dataSource={data}
          allowSorting={true}
          ref={ref}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="#"
              field="id"
              width={40}
              textAlign="Center"
              template={idTemplate}
            />
            <ColumnDirective headerText="Title" field="Title" width={120} textAlign="Center" />
            <ColumnDirective
              headerText="Arabic Title"
              field="TitleAr"
              width={120}
              textAlign="Center"
            />
            <ColumnDirective headerText="Order" field="Sorder" width={50} textAlign="Center" />
            <ColumnDirective
              headerText="English Image"
              template={iconEnTemplate}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Arabic Image"
              template={iconArTemplate}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Status"
              field="isActive"
              template={statusTemplate}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Edit"
              template={editTemplate}
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default KeyValuesDataGrid;
