import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// modals
import GroupModal from './GroupModal';
import CopyGroupModal from './CopyGroupModal';
import RightSideModal from 'components/shared/RightSideModal';
import GroupUsersContent from './GroupUsersContent';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import PermissionService from 'services/PermissionService';
import PageTitle from 'components/shared/PageTitle';
import RestrictedSection from 'components/shared/RestrictedSection';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const AdminGroups = () => {
  const [groups, setGroups] = useState([]);
  const [clonedGroups, setClonedGroups] = useState([]);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [toGroup, setToGroup] = useState('');
  const [isCopyGroupOpen, setIsCopyGroupOpen] = useState(false);
  const [isGroupUsersModalOpen, setIsGroupUsersModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await PermissionService.getPermissionGroups();
      setGroups(res.data);
      setClonedGroups(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('AdminGroups:fetchGroupPermissions', err);
      dispatch(setLoaderOpenFalse());
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchGroup = (e) => {
    if (e.target.value) {
      const searchResult = clonedGroups.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setGroups(searchResult);
    } else {
      setGroups(clonedGroups);
    }
  };

  return (
    <Fragment>
      <PageTitle title="Admin" />
      {isGroupModalOpen && (
        <GroupModal
          isGroupModalOpen={isGroupModalOpen}
          setIsGroupModalOpen={setIsGroupModalOpen}
          setGroups={setGroups}
        />
      )}
      {isCopyGroupOpen && (
        <CopyGroupModal
          isCopyGroupOpen={isCopyGroupOpen}
          setIsCopyGroupOpen={setIsCopyGroupOpen}
          toGroup={toGroup}
          groups={groups}
        />
      )}
      {isGroupUsersModalOpen && (
        <RightSideModal
          isPaneOpen={isGroupUsersModalOpen}
          setIsPaneOpen={setIsGroupUsersModalOpen}
          direction="right"
          width="70%"
        >
          <GroupUsersContent toGroup={toGroup} />
        </RightSideModal>
      )}
      <div className="card">
        <div className="card-header between-flex">
          <div>
            <h6>Groups</h6>
            <RestrictedSection page="admin_groups" action="add_new_group">
              <button
                onClick={() => {
                  setIsGroupModalOpen((prev) => !prev);
                }}
                className="btn btn-success"
              >
                <i className="fa fa-plus"></i> Add New Group
              </button>
            </RestrictedSection>
          </div>
          <div className="form-group group-action-save">
            <input
              className="form-control"
              type="text"
              placeholder="Search group"
              onChange={handleSearchGroup}
            />
          </div>
        </div>
        <div className="recent-notification card-body">
          <div className="table-responsive text-center">
            <table className="table table-bordernone">
              <thead>
                <tr>
                  <th scope="col">Group</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {groups.map((group, i) => (
                  <tr key={i}>
                    <td>{group.name}</td>
                    <td>
                      <RestrictedSection page="admin_groups" action="edit_groups">
                        <button
                          onClick={() => {
                            dispatch({
                              type: 'SET_PERMISSION_GROUP_DATA',
                              payload: group,
                            });
                            navigate(`/admin/groups/${group.id}`);
                          }}
                          className="btn btn-primary m-r-15"
                        >
                          <i className="fa fa-edit"></i> Edit
                        </button>
                      </RestrictedSection>
                      <RestrictedSection page="admin_groups" action="view_users">
                        <button
                          onClick={() => {
                            setToGroup(group);
                            setIsGroupUsersModalOpen((prev) => !prev);
                          }}
                          className="btn btn-secondary m-r-15"
                        >
                          <i className="fa fa-user"></i> Users
                        </button>
                      </RestrictedSection>
                      <RestrictedSection page="admin_groups" action="copy_groups">
                        <button
                          onClick={() => {
                            setToGroup(group);
                            setIsCopyGroupOpen((prev) => !prev);
                          }}
                          className="btn btn-success"
                        >
                          <i className="fa fa-copy"></i> Copy
                        </button>
                      </RestrictedSection>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminGroups;
