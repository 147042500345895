import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// actions
import { getGenerateShorterLink } from 'store/shorterLink/ShorterLinkActions';
// selectors
import { generatedShorterLink } from 'store/shorterLink/ShorterLinkSelectors';

const GenerateShorterLinkModal = ({
  isGenerateShorterLinkModalOpen,
  setIsGenerateShorterLinkModalOpen,
}) => {
  const [url, setUrl] = useState('');
  const [customerToken, setCustomerToken] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const generatedLink = useSelector((state) => generatedShorterLink({ state }));
  const dispatch = useDispatch();

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handlecustomerTokenchange = (e) => {
    setCustomerToken(e.target.value);
  };

  const generateShorterLink = async () => {
    setDisableBtn(true);
    const bodyData = {
      originalUrl: url,
      customer_token: customerToken,
    };
    await dispatch(getGenerateShorterLink(bodyData));
    setDisableBtn(false);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isGenerateShorterLinkModalOpen}
        toggle={() => setIsGenerateShorterLinkModalOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setIsGenerateShorterLinkModalOpen((prev) => !prev)}>
          Generate shorter link
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Original Link
              </label>
              <textarea
                onChange={handleUrlChange}
                className="form-control"
                id="message-text"
                value={url}
              ></textarea>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Customer Token
              </label>
              <input
                onChange={handlecustomerTokenchange}
                value={customerToken}
                className="form-control"
                id="message-text"
              ></input>
            </div>
            {generatedLink && (
              <div className="shorter-link-wrapper">
                <span className="shorter-link">Shorter Link: </span>
                <a href={generatedLink} target="_blank" rel="noreferrer">
                  {generatedLink}
                </a>
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={disableBtn || !url} onClick={generateShorterLink}>
            Save
          </Button>
          <Button color="light" onClick={() => setIsGenerateShorterLinkModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GenerateShorterLinkModal;
