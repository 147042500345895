import React, { useState, useEffect } from 'react';
import SectionLoader from 'components/common/SectionLoader';
import { useDispatch, useSelector } from 'react-redux';
import DJHPagination from 'components/shared/Pagination';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
} from '@syncfusion/ej2-react-grids';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { dateFormat } from 'constant/Helpers';
// selectors
import { getUserActivities } from 'store/user/UserSelectors';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UserActivitiesContent = ({ userId }) => {
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    page: 1,
  });
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const activitiesData = useSelector((state) => getUserActivities({ state }));
  const dispatch = useDispatch();

  const fetchData = async (userId, params) => {
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getUserActivitiesById(params, userId);
      dispatch({
        type: 'SET_USER_ACTIVITIES',
        payload: res.data,
      });
      setIsLoaderOpen(false);
    } catch (err) {
      errorLogger('UserActivitiesContent:fetchUserActivitiesById', err);
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchData(userId, inputValues);
  }, []);

  const handleSubmit = async () => {
    fetchData(userId, inputValues);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    fetchData(updatedParams, inputValues);
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="card row checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          User activities <i className="fa fa-user"></i>
        </h4>
      </div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="card-body">
          <div className="control-pane">
            <div className="control-section saved-credicard-table toolbar-table-style">
              <PriceBreakDownFilter
                inputValues={inputValues}
                setInputValues={setInputValues}
                handleSubmit={handleSubmit}
                noTitle
                defaultDate
              />
              <GridComponent
                dataSource={activitiesData.data}
                allowPaging={false}
                allowTextWrap={true}
                rowHeight={50}
                showColumnChooser={false}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    headerText="Action"
                    field="remarks"
                    width={150}
                    textAlign="center"
                  />
                  <ColumnDirective
                    headerText="Created at"
                    template={(rowData) =>
                      `${rowData?.created_date?.slice(0, 10)}, ${rowData?.created_date?.slice(
                        11,
                        16
                      )}`
                    }
                    width={150}
                    textAlign="center"
                  />
                </ColumnsDirective>
                <Inject services={[Page]} />
              </GridComponent>
              <DJHPagination
                onPageChange={onPageChange}
                currentPage={+activitiesData.currentPage}
                pageSize={+activitiesData.pageSize}
                itemsCount={+activitiesData.rowCount}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserActivitiesContent;
