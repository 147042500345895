/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CardBody, Alert } from 'reactstrap';
import { AlertCircle } from 'react-feather';
import { toast } from 'react-toastify';
import RestrictedSection from 'components/shared/RestrictedSection';
import CustomSelect from 'components/shared/CustomSelect';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';
// constants
import { HasPermission } from 'constant/Helpers';
//selector
import { getPermissionsList } from 'store/app/AppSelectors';

const RefundModal = ({ isRefundModalOpen, setIsRefundModalOpen, orderData, orderId }) => {
  const [amount, setAmount] = useState(orderData.paymentdetails.grandTotal);
  const [toWallet, setToWallet] = useState('');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [refundList, setRefundList] = useState([]);
  const permissionsList = useSelector((state) => getPermissionsList({ state }));

  const handleRefundMethod = (e) => {
    setToWallet(e.itemData.id);
    setShowDisclaimer(true);
  };
  const handleRefund = async () => {
    const bodyData = {
      amount,
      towallet: toWallet,
    };
    setDisableBtn(true);
    try {
      const res = await ViewOrderService.refundAction(bodyData, orderId);
      setIsRefundModalOpen((prev) => !prev);
      setDisableBtn(false);
      toast.success(res.data.message);
    } catch (err) {
      errorLogger('RefundModal:handleRefund', err);
      setDisableBtn(false);
      toast.error(err.response.data.message);
      setIsRefundModalOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    const tempArr = [];
    if (HasPermission(permissionsList, 'order_details', 'wallet_refund')) {
      tempArr.push({ id: 'true', name: 'Wallet' });
    }
    if (HasPermission(permissionsList, 'order_details', 'bank_refund')) {
      tempArr.push({
        id: 'false',
        name: 'Bank Account',
      });
    }
    setRefundList(tempArr);
  }, []);

  const handleOpen = (e) => {
    if (orderData.paymentdetails.grandTotal === orderData.paymentdetails.paidFromWallet) {
      e.popup.element.querySelectorAll('.e-list-item#false')[0].classList.add('e-disabled');
      e.popup.element.querySelectorAll('.e-list-item#false')[0].classList.add('e-overlay');
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isRefundModalOpen} toggle={() => setIsRefundModalOpen((prev) => !prev)}>
        <ModalHeader>Refund</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Amount
              </label>
              <input
                onChange={(e) => setAmount(e.target.value)}
                className="form-control"
                value={amount}
                id="message-text"
              ></input>
            </div>
            <div className="form-group col-12 filter-select">
              <label htmlFor="refund">Refund to</label>
              <CustomSelect
                id="refund"
                listData={refundList}
                placeholder="Refund to"
                handleChange={handleRefundMethod}
                textPropName="name"
                valuePropName="id"
                selectedValue={toWallet}
                onOpen={handleOpen}
              />
            </div>
            {showDisclaimer && (
              <CardBody className="card-body col-12 disclaimer-message-wrapper">
                <Alert className="alert-dismissible" color="light dark">
                  <AlertCircle />
                  {amount <= orderData?.paymentdetails?.grandTotal && amount > 0 ? (
                    <Fragment>
                      <p>The refunded amount will be refunded as below:</p>
                      <p>
                        {toWallet === 'true'
                          ? amount
                          : toWallet === 'false' &&
                            amount <= orderData?.paymentdetails?.grandTotal &&
                            !(amount <= orderData?.paymentdetails?.paidFromWallet)
                          ? orderData?.paymentdetails?.paidFromWallet
                          : amount}
                        {orderData?.ops?.currencyISO} to Wallet
                      </p>
                      {toWallet === 'false' &&
                        amount <= orderData?.paymentdetails?.grandTotal &&
                        amount >= orderData?.paymentdetails?.paidFromWallet && (
                          <p>
                            {amount - orderData?.paymentdetails?.paidFromWallet}{' '}
                            {orderData?.ops?.currencyISO} to Bank
                          </p>
                        )}
                    </Fragment>
                  ) : (
                    <p className="disclaimer-warrning">Insufficient amount</p>
                  )}
                </Alert>
              </CardBody>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleRefund} disabled={!toWallet || disableBtn} color="primary">
            Save
          </Button>
          <Button onClick={() => setIsRefundModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RefundModal;
