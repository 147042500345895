import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setCheckoutLogsData } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getCheckoutLogsData } from 'store/viewOrder/ViewOrderSelectors';
// data grid
import CheckoutLogsDataGrid from './viewOrderDataGrids/CheckoutLogsDataGrid';

const CheckoutLogsDetails = ({ orderId, orderData }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const checkoutLogsData = useSelector((state) => getCheckoutLogsData({ state }));
  const dispatch = useDispatch();

  const fetchCheckoutLogsData = async (orderId) => {
    setIsLoaderOpen(true);
    await dispatch(setCheckoutLogsData(orderId));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchCheckoutLogsData(orderId);
  }, []);

  return (
    <div className="card row checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          Logs Details <i className="fa fa-info-circle"></i>
        </h4>
      </div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        checkoutLogsData.map((item, i) => (
          <div key={i} className="card-body">
            <CheckoutLogsDataGrid data={item} orderData={orderData} />
          </div>
        ))
      )}
    </div>
  );
};

export default CheckoutLogsDetails;
