import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import Chip from '@mui/material/Chip';
import CustomSelect from 'components/shared/CustomSelect';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// modals
import AddExcludedSlotsModal from '../modals/AddExcludedSlotsModal';
import RestrictedSection from 'components/shared/RestrictedSection';
// actions
import {
  setGetProductCategoriesById,
  setAddProductCategoryById,
  setDeleteProductCategoryById,
  setGetProductAttributeById,
  setUpdateProductMainCat,
  setGetProductExcludedSlotsById,
  setAddProductExcludedSlotById,
  setDeleteProductExcludedSlotById,
  setProductExcludedSlotsList,
} from 'store/products/ProductsActions';
// selectors
import {
  getProductCategoriesByIdData,
  getProductExcludedSlotsByIdData,
  // subcategories
  getEverydayOccasionsSubList,
  getEmotionsSubList,
  getSpecialOccasionsSubList,
  getFestivalsSubList,
} from 'store/products/ProductsSelectors';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';

const ProductCategoriesSection = ({
  productId,
  productOptionsById,
  allCategoriesListData,
  mainCategoriesData,
  productExcludedSlots,
  isValid,
  notValidFields,
  validateCategories,
}) => {
  const [mainCat, setMainCat] = useState('');
  const categoriesById = useSelector((state) => getProductCategoriesByIdData({ state }));
  const [updatedExcludedSlotsArr, setUpdatedExcludedSlotsArr] = useState([]);
  const [updatedExcludedSlotsArrById, setUpdatedExcludedSlotsArrById] = useState([]);
  const [isAddExcludedSlotsModalOpen, setIsAddExcludedSlotsModalOpen] = useState(false);
  const excludedSlotsById = useSelector((state) => getProductExcludedSlotsByIdData({ state }));
  // subCategoriesList
  const everydayOccasionsSubList = useSelector((state) => getEverydayOccasionsSubList({ state }));
  const emotionsSubList = useSelector((state) => getEmotionsSubList({ state }));
  const specialOccasionsSubList = useSelector((state) => getSpecialOccasionsSubList({ state }));
  const festivalsSubList = useSelector((state) => getFestivalsSubList({ state }));
  const dispatch = useDispatch();

  const catField = { text: 'name', value: 'id' };
  const slotsField = { text: 'slotName', value: 'slotId' };

  const fetchAllData = () => {
    dispatch(setGetProductCategoriesById(productId));
    dispatch(setGetProductAttributeById(productId));
    dispatch(setGetProductExcludedSlotsById(productId));
    dispatch(setProductExcludedSlotsList(productId));
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (Object.keys(productOptionsById).length > 0) {
      setMainCat(productOptionsById?.mainCategory ?? -1);
      fetchAllData();
    }
  }, [productOptionsById]);

  useEffect(() => {
    setUpdatedExcludedSlotsArr(productExcludedSlots);
  }, [productExcludedSlots]);

  useEffect(() => {
    setUpdatedExcludedSlotsArrById(excludedSlotsById.map((item) => item.slotId));
  }, [productExcludedSlots, excludedSlotsById]);

  const validateField = (field) => {
    validateCategories(false, { validate: true, field });
  };

  const handleAddcategory = async (e) => {
    dispatch(setAddProductCategoryById(productId, e?.itemData?.id, validateCategories));
  };

  const handleAddDefaultCategory = (e) => {
    if (!categoriesById.map((el) => el.id).includes(1)) {
      // 3190 is the id for :  default categories --> gifts
      // this used to prevent adding more than one default category to the product categories

      const giftsID = categoriesById.find((el) => el.id === 3190)?.id;
      if (giftsID) {
        dispatch(setDeleteProductCategoryById(productId, giftsID));
      }
      const validateDefaultCat = () => validateField('Default Category');
      dispatch(setAddProductCategoryById(productId, e?.itemData?.id, validateDefaultCat));
    }
  };

  const handleDeleteCategory = async (e) => {
    dispatch(setDeleteProductCategoryById(productId, e?.itemData?.id, validateCategories));
  };

  const handleMainCatChange = (e) => {
    setMainCat(e?.itemData?.id);
    const validateMainCat = () => validateField('Main Category');
    if (e?.itemData?.id !== mainCat)
      dispatch(setUpdateProductMainCat(e?.itemData?.id, 4, productId, validateMainCat));
  };

  const handleDeleteExcludedSlot = (id) => () => {
    dispatch(setDeleteProductExcludedSlotById(productId, id));
  };

  return (
    <>
      {isAddExcludedSlotsModalOpen && (
        <AddExcludedSlotsModal
          isAddExcludedSlotsModalOpen={isAddExcludedSlotsModalOpen}
          setIsAddExcludedSlotsModalOpen={setIsAddExcludedSlotsModalOpen}
          setAddProductExcludedSlotById={setAddProductExcludedSlotById}
          setDeleteProductExcludedSlotById={setDeleteProductExcludedSlotById}
          productExcludedSlots={updatedExcludedSlotsArr}
          excludedSlotsById={updatedExcludedSlotsArrById}
          productId={productId}
        />
      )}
      <div className="form-group col-12 m-t-30">
        <form className="row">
          {mainCategoriesData.length > 0 && (
            <div className="col-md-6 col-xl-4">
              <label className="bold-title" htmlFor="prod-main-cat">
                Main Category
              </label>
              <CustomSelect
                id="prod-main-cat"
                listData={mainCategoriesData}
                handleChange={handleMainCatChange}
                placeholder="Select main category"
                textPropName="nickname"
                valuePropName="id"
                selectedValue={mainCat}
                disabled={productOptionsById?.status === 1}
              />
            </div>
          )}
          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title" htmlFor="prod-cat-type">
              Types
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          id="prod-cat-type"
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el.type === 8).map((el) => el.id)}
                          dataSource={allCategoriesListData.filter((el) => el.type === 8)}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Type"
                          showDropDownIcon={true}
                          enabled={productOptionsById?.status !== 1}
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isValid && notValidFields?.includes('max range for types') && (
              <InlineErrorMessage
                message={'You can only add one Type here, please delete some items'}
              />
            )}
          </div>
          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title" htmlFor="prod-cat-addon">
              Add-ons
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          id="prod-cat-addon"
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el?.type === 6).map((el) => el.id)}
                          dataSource={allCategoriesListData.filter((el) => el?.type === 6)}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Add-on"
                          showDropDownIcon={true}
                          enabled={productOptionsById?.status !== 1}
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {mainCategoriesData.length > 0 && (
            <div className="col-md-6 col-xl-4">
              <label className="bold-title" htmlFor="prod-cat-default">
                Default Category
              </label>
              <CustomSelect
                id="prod-cat-default"
                listData={mainCategoriesData.filter((el) => el.id === 1 || el.id === 3190)}
                handleChange={handleAddDefaultCategory}
                placeholder="Select default category"
                textPropName="nickname"
                valuePropName="id"
                selectedValue={categoriesById.filter((el) => el.id === 1)?.[0]?.id}
                disabled={productOptionsById?.status === 1}
              />
            </div>
          )}

          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title" htmlFor="prod-cat-occasion">
              Everyday Occasions
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          id="prod-cat-occasion"
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el.type === 7).map((el) => el.id)}
                          dataSource={everydayOccasionsSubList}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Occasions"
                          showDropDownIcon={true}
                          enabled={productOptionsById?.status !== 1}
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isValid && notValidFields?.includes('max range for occasions') && (
              <InlineErrorMessage
                message={'You can only add up to 5 occasions here, please delete some items.'}
              />
            )}
          </div>

          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title" htmlFor="prod-cat-emotion">
              Emotions and Sentiments
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          id="prod-cat-emotion"
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el.type === 7).map((el) => el.id)}
                          dataSource={emotionsSubList}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Occasions"
                          showDropDownIcon={true}
                          enabled={productOptionsById?.status !== 1}
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title" htmlFor="prod-cat-special">
              Special Occasions
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          id="prod-cat-special"
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el.type === 7).map((el) => el.id)}
                          dataSource={specialOccasionsSubList}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Occasions"
                          showDropDownIcon={true}
                          enabled={productOptionsById?.status !== 1}
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title" htmlFor="prod-cat-festival">
              Festivals
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          id="prod-cat-festival"
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el.type === 7).map((el) => el.id)}
                          dataSource={festivalsSubList}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Occasions"
                          showDropDownIcon={true}
                          enabled={productOptionsById?.status !== 1}
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title" htmlFor="prod-cat-promotion">
              Promotions
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          id="prod-cat-promotion"
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el.type === 3).map((el) => el.id)}
                          dataSource={allCategoriesListData.filter((el) => el.type === 3)}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Promotions"
                          showDropDownIcon={true}
                          enabled={productOptionsById?.status !== 1}
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title">
              Designers{' '}
              {productOptionsById?.type !== 1 ? (
                <span className="type-warning-message">(Designer type only)</span>
              ) : (
                <span className="requird-asterisks ml-1">*</span>
              )}
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el.type === 1).map((el) => el.id)}
                          dataSource={allCategoriesListData.filter((el) => el.type === 1)}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Designer"
                          showDropDownIcon={true}
                          enabled={
                            productOptionsById?.status !== 1 && productOptionsById?.type === 1
                          }
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isValid && notValidFields?.includes('Designer') && (
              <InlineErrorMessage message={'Please add a designer value'} />
            )}
          </div>
          <div className="col-md-6 col-xl-4 categories-field-box">
            <label className="bold-title">
              Brands{' '}
              {productOptionsById?.type !== 2 ? (
                <span className="type-warning-message">(Brand type only)</span>
              ) : (
                <span className="requird-asterisks ml-1">*</span>
              )}
            </label>
            <div className="control-pane">
              <div id="multisection" className="control-section filter-select p-l-6">
                <div id="multidefault">
                  <div className="control-styles">
                    <div>
                      <AppErrorBoundary>
                        <MultiSelectComponent
                          select={(e) => handleAddcategory(e)}
                          removing={(e) => handleDeleteCategory(e)}
                          value={categoriesById.filter((el) => el.type === 4).map((el) => el.id)}
                          dataSource={allCategoriesListData.filter((el) => el.type === 4)}
                          mode="Box"
                          fields={catField}
                          placeholder="Select Brand"
                          showDropDownIcon={true}
                          enabled={
                            productOptionsById?.status !== 1 && productOptionsById?.type === 2
                          }
                        />
                      </AppErrorBoundary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isValid && notValidFields?.includes('Brand') && (
              <InlineErrorMessage message={'Please add a brand value'} />
            )}
          </div>
          <RestrictedSection page="edit_product" action="can_update_collections_categories">
            <div className="col-md-6 col-xl-4 categories-field-box">
              <label className="bold-title" htmlFor="prod-cat-collection">
                Collection
              </label>
              <div className="control-pane">
                <div id="multisection" className="control-section filter-select p-l-6">
                  <div id="multidefault">
                    <div className="control-styles">
                      <div>
                        <AppErrorBoundary>
                          <MultiSelectComponent
                            id="prod-cat-collection"
                            select={(e) => handleAddcategory(e)}
                            removing={(e) => handleDeleteCategory(e)}
                            value={categoriesById.filter((el) => el.type === 9).map((el) => el.id)}
                            dataSource={allCategoriesListData.filter((el) => el.type === 9)}
                            mode="Box"
                            fields={catField}
                            placeholder="Select Collection"
                            showDropDownIcon={true}
                            enabled={productOptionsById?.status !== 1}
                          />
                        </AppErrorBoundary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </RestrictedSection>
        </div>
      </div>

      <div className="card attributes-card">
        <div className="col-md-6 col-xl-4 add-attr-col">
          <button
            onClick={() => setIsAddExcludedSlotsModalOpen(true)}
            className="btn btn-success"
            type="button"
            disabled={productOptionsById?.status === 1}
          >
            <i className="fa fa-plus"></i> Add excluded slot
          </button>
        </div>
        <div className="card-body">
          <div className="col-flex m-10">
            {excludedSlotsById.length > 0 ? (
              <>
                <h6 className="m-r-8 no-wrap">Excluded slots</h6>
                <div className="attributes-container">
                  {excludedSlotsById.map((data, i) => (
                    <li key={i}>
                      <Chip
                        label={data.slotName}
                        onDelete={handleDeleteExcludedSlot(data.slotId)}
                        className="attr-chip"
                      />
                    </li>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductCategoriesSection);
