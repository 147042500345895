import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { errorLogger } from 'datadog/DDUtils';
// api
import MerchandisingCategoriesService from '../../services/MerchandisingCategoriesService';
// type
import { GET_TOTAL_MERCHANDISING_CATEGORIES_DATA } from './MerchandisingCategoriesActionTypes';

export const getMerchandisingCategories = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await MerchandisingCategoriesService.getMerchandisingCategoriesData(params);
    dispatch({
      type: GET_TOTAL_MERCHANDISING_CATEGORIES_DATA,
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('MerchandisingCategoriesActions:getMerchandisingCategories', err);
    toast.error('Failed to fetch categories data!!');
    dispatch({
      type: GET_TOTAL_MERCHANDISING_CATEGORIES_DATA,
      payload: [],
    });
    dispatch(setLoaderOpenFalse());
  }
};
