import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SearchUserForm from 'components/shared/SearchUserForm';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { setTicketDetailsData } from 'store/ticketing/TicketingActions';
// api
import PermissionService from 'services/PermissionService';
import TicketingService from 'services/TicketingService';
// constants
import { localStorageKeys } from 'constant/Enums';

const AssignModal = ({ isAssignModalOpen, setIsAssignModalOpen, ticketId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [assignedFirstName, setAssignedFirstName] = useState('');
  const [assignedLastName, setAssignedLastName] = useState('');
  const [assignedUserId, setAssignedUserId] = useState('');
  const [assignedEmail, setAssignedEmail] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const decodedToken = jwt_decode(getFromLocalStorage(localStorageKeys.ACCESS_TOKEN));
  const dispatch = useDispatch();

  const fetchData = debounce(async (keyword) => {
    const params = {
      searchkey: keyword,
    };
    setSearchKey(keyword);
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getSearchFlowardUsers(params);
      setSearchResult(res.data);
      setIsLoaderOpen(false);
    } catch (e) {
      errorLogger('AssignModal:fetchData', e);
      setIsLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    setAssignedFirstName('');
    setAssignedLastName('');
    setAssignedUserId('');
    if (keyword.length >= 3) {
      fetchData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleSelectUser = (user) => {
    setAssignedFirstName(user.name);
    setAssignedLastName(user.lastName);
    setAssignedUserId(user.id);
    setAssignedEmail(user.email);
    setShowTable(false);
  };

  const handleAssignUser = async () => {
    const dataBody = {
      TicketId: ticketId,
      AssignedTo: assignedUserId,
      AssignedToName: `${assignedFirstName} ${assignedLastName}`,
      AssignedToEmail: assignedEmail,
      AssignedByName: decodedToken.FullName,
      AssignedBy: decodedToken.Id,
      comment: '',
    };
    setDisableBtn(true);
    try {
      await TicketingService.handleAssignUserToThread(dataBody);
      setTimeout(() => {
        dispatch(setTicketDetailsData(ticketId));
        toast.success('Ticket assigned successfully');
        setDisableBtn(false);
        setIsAssignModalOpen((prev) => !prev);
      }, 1500);
    } catch (err) {
      toast.error('Failed to assign ticket!');
      setDisableBtn(false);
      setIsAssignModalOpen((prev) => !prev);
    }
  };

  return (
    <div>
      <Modal isOpen={isAssignModalOpen} toggle={() => setIsAssignModalOpen((prev) => !prev)}>
        <ModalHeader>Assign To</ModalHeader>
        <ModalBody>
          <SearchUserForm
            handleSearch={handleSearch}
            searchKey={searchKey}
            isLoaderOpen={isLoaderOpen}
            searchResult={searchResult}
            showTable={showTable}
            handleSelectUser={handleSelectUser}
            assignedFirstName={assignedFirstName}
            assignedLastName={assignedLastName}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!assignedFirstName || disableBtn}
            onClick={handleAssignUser}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={() => setIsAssignModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AssignModal;
