import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
// components
import PageTitle from 'components/shared/PageTitle';
import DriversExpensesDataGrid from 'components/delivery/driversExpenses/DriversExpensesDataGrid';
import DriversExpensesFilter from 'components/delivery/driversExpenses/DriversExpensesFilter';
import AddDriverModal from 'components/delivery/driversExpenses/AddDriverModal';
import DJHPagination from 'components/shared/Pagination';
// constants
import { getDriversExpensesPageUrl } from 'constant/AppUrls';
import { errorLogger } from 'datadog/DDUtils';
// helpers
import {
  dateFormat,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';
// actions
import { setDriversExpensesData } from 'store/delivery/driversExpenses/DriversExpensesActions';
// selectors
import { getDriversExpensesAllData } from 'store/delivery/driversExpenses/DriversExpensesSelectors';
// api
import DeliveryService from 'services/DeliveryService';

const DriversExpensesPage = () => {
  const driversExpensesAllData = useSelector((state) => getDriversExpensesAllData({ state }));
  const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    searchKey: '',
    page: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchDriversExpensesData = (params) => {
    dispatch(setDriversExpensesData(params));
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchDriversExpensesData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchDriversExpensesData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: getDriversExpensesPageUrl(),
      search: queryString,
    });
    dispatch(setDriversExpensesData(inputValues));
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getDriversExpensesPageUrl(),
      search: queryString,
    });
    fetchDriversExpensesData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  const handleExport = async () => {
    try {
      const res = await DeliveryService.exportDriversExpensesData({ ...inputValues, page: -1 });
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('DriversExpensesPage:handleExport', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Drivers Expenses" />
      {isAddDriverModalOpen && (
        <AddDriverModal
          isAddDriverModalOpen={isAddDriverModalOpen}
          setIsAddDriverModalOpen={setIsAddDriverModalOpen}
          inputValues={inputValues}
        />
      )}
      <DriversExpensesFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Drivers Expenses"
        setIsAddDriverModalOpen={setIsAddDriverModalOpen}
        handleExport={handleExport}
      />
      <DriversExpensesDataGrid data={driversExpensesAllData.data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+driversExpensesAllData.currentPage}
        pageSize={+driversExpensesAllData.pageSize}
        itemsCount={+driversExpensesAllData.rowCount}
      />
    </div>
  );
};

export default DriversExpensesPage;
