import React from 'react';
import SubCategoriesDataTable from './SubCategoriesDataTable';

const SubCategories = ({ categoryId }) => {
  return (
    <div>
      <SubCategoriesDataTable categoryId={categoryId} />
    </div>
  );
};

export default SubCategories;
