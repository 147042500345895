import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Resize,
  Sort,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const OrdersReportDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          allowSorting={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
          allowResizing={true}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="Date" field="date" width={120} textAlign="Center" />
            <ColumnDirective headerText="Web" field="webCount" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Web Sales"
              template={(rowData) => (
                <span>
                  {rowData.webTotal != null &&
                    rowData.webTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.webTotal != null &&
                rowData.webTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="iOS" field="iosCount" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="iOS Sales"
              template={(rowData) => (
                <span>
                  {rowData.iosTotal != null &&
                    rowData.iosTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.iosTotal != null &&
                rowData.iosTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Android"
              field="androidCount"
              width={90}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Android Sales"
              template={(rowData) => (
                <span>
                  {rowData.androidTotal != null &&
                    rowData.androidTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.androidTotal != null &&
                rowData.androidTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Agent" field="agentCount" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Agent Sales"
              template={(rowData) => (
                <span>
                  {rowData.agentTotal != null &&
                    rowData.agentTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.agentTotal != null &&
                rowData.agentTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Corporate"
              field="corpCount"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Corporate Sales"
              template={(rowData) => (
                <span>
                  {rowData.corpTotal != null &&
                    rowData.corpTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.corpTotal != null &&
                rowData.corpTotal.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Total" field="count" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Total Sales"
              template={(rowData) => (
                <span>
                  {rowData.total != null &&
                    rowData.total.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.total != null &&
                rowData.total.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Discount"
              template={(rowData) => (
                <span>
                  {rowData.discount != null &&
                    rowData.discount.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.discount != null &&
                rowData.discount.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Gross Profit"
              template={(rowData) => (
                <span>{`${
                  rowData.grossProfit != null && Number.isFinite(rowData.grossProfit)
                    ? rowData.grossProfit.toFixed(opsCenter?.country?.decimalNumber ?? 3)
                    : rowData.grossProfit != null && rowData.grossProfit
                }%`}</span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.grossProfit != null && Number.isFinite(rowData.grossProfit)
                  ? rowData.grossProfit.toFixed(opsCenter?.country?.decimalNumber ?? 3)
                  : rowData.grossProfit != null && rowData.grossProfit
              }
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page, Resize, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(OrdersReportDataGrid);
