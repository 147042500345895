/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import MapComponent from './MapComponent';
import PreviousAddress from './PreviousAddress';
import MapLinkModal from './MapLinkModal';
import SectionLoader from 'components/common/SectionLoader';
import { copyToClipboard } from 'constant/Helpers';
// actions
import {
  setToggleAddressModalOpen,
  setToggleMapLinkModalOpen,
  updateActionRecepientAddress,
  setPremiumTableData,
  setTableData,
} from 'store/orderQuickActions/dataTable/DataTableAction';
import { setViewOrderData } from 'store/viewOrder/ViewOrderActions';
// selectors
import {
  getIsAddressModalOpen,
  getIsMapLinkModalOpen,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// api
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const screenWidth = window.screen.width;

const EditAddressModal = ({
  recipient,
  orderId,
  defaultLat,
  defaultLng,
  isAction,
  orderOps,
  premium,
  countryIso,
}) => {
  const userCountryId = isAction
    ? recipient && recipient.country.id
      ? recipient && recipient.country.id
      : ''
    : recipient && recipient.countryID
    ? recipient.countryID
    : '';
  const isAddressModalOpen = useSelector((state) => getIsAddressModalOpen({ state }));
  const isMapLinkModalOpen = useSelector((state) => getIsMapLinkModalOpen({ state }));
  const [showMap, setShowMap] = useState(false);
  const [showPrevAddress, setShowPrevAddress] = useState(false);
  const [recipientName, setRecipientName] = useState(recipient?.recipentName || '');
  const [recipientPhone, setRecipientPhone] = useState(recipient?.recipientPhone || '');
  const [secondaryRecipientPhone, setSecondaryRecipientPhone] = useState(
    recipient?.extraRecipientNumber ?? ''
  );
  const [latValue, setLatValue] = useState(
    recipient && recipient.lat && recipient.lat !== 0 ? recipient.lat : defaultLat
  );
  const [lngValue, setLngValue] = useState(
    recipient && recipient.lng && recipient.lng !== 0 ? recipient.lng : defaultLng
  );
  const [updatedLat, setUpdatedLat] = useState(
    recipient && recipient.lat && recipient.lat !== 0 ? recipient.lat : ''
  );
  const [updatedLng, setUpdatedLng] = useState(
    recipient && recipient.lng && recipient.lng !== 0 ? recipient.lng : ''
  );
  const [areaList, setAreaList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [areaId, setAreaId] = useState(
    isAction
      ? recipient && recipient.areaId
        ? recipient.areaId.id
        : '-1'
      : recipient && recipient.areaid
      ? recipient.areaid
      : '-1'
  );
  const [countryId, setCountryId] = useState(userCountryId);
  const [secondaryCountryId, setSecondaryCountryId] = useState(
    recipient?.extraRecipientNumberCountry || userCountryId
  );
  const [addressOne, setAddressOne] = useState(recipient?.address || '');
  const [addressTwo, setAddressTwo] = useState(recipient?.extra || '');
  const [postalCode, setPostalCode] = useState(recipient?.postalCode || '');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showSecondaryPhone, setShowSecondaryPhone] = useState(secondaryRecipientPhone ?? false);
  const [houseImg, setHouseImg] = useState('');
  const [houseImgPath, setHouseImgPath] = useState('');
  const [secondaryCountryCode, setSecondaryCountryCode] = useState(
    recipient?.extraRecipientCountryCode || recipient?.country?.code || recipient?.code
  );
  const [prevAddressList, setPrevAddressList] = useState('');
  const dispatch = useDispatch();
  const [initialLocation, setInitialLocation] = useState({
    lat: updatedLat,
    lng: updatedLng,
    addressOne,
    areaId,
  });
  const [linkLocationUsed, setLinkLocationUsed] = useState(false);
  const fetchData = async () => {
    const params = {
      opsId: orderOps,
    };
    try {
      const res = await ViewOrderService.getAreasList(params);
      const countries = await ViewOrderService.getCountriesList();
      for (let j = 0; j < countries.data.length; j++) {
        countries.data[j].countryCode = countries.data[j].Code;
        countries.data[j].Code = '+' + countries.data[j].Code + ' ' + countries.data[j].Name;
      }
      setCountryList(countries.data);
      let allareas = res.data;
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].subarea.length > 0) {
          const subAreas = [res.data[i]].concat(res.data[i].subarea);
          for (let j = 0; j < subAreas.length; j++) {
            subAreas[j].mainArea = res.data[i].name;
          }
          allareas = allareas.concat(subAreas);
        } else {
          const subAreas = [res.data[i]];
          subAreas[0].mainArea = res.data[i].name;
          allareas = allareas.concat(subAreas);
        }
      }
      setAreaList(allareas);
    } catch (e) {
      errorLogger('EditAddressModal:fetchData', e);
    }
  };
  const fetchPreviousAddressData = async () => {
    try {
      const res = await ViewOrderService.getPrevAddressList(orderId);
      setPrevAddressList(res?.data?.data?.filter((address) => address.type !== 4) || []);
    } catch (err) {
      errorLogger('EditAddressModal:fetchPreviousAddressData', err);
      toast.error('Failed to get previous addresses!');
      setIsLoaderOpen(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchPreviousAddressData();
  }, []);

  useEffect(() => {
    setLatValue(defaultLat);
    setLngValue(defaultLng);
  }, [defaultLat]);

  const addHouseImg = async (e) => {
    const bodyFormData = new FormData();
    bodyFormData.append('image', e.target.files[0]);
    setHouseImg(window.URL.createObjectURL(e.target.files[0]));
    try {
      const res = await ViewOrderService.addHouseImage(bodyFormData);
      toast.success(res.data.message);
      setHouseImgPath(res.data.path);
    } catch (e) {
      errorLogger('EditAddressModal:addHouseImg', e);
      toast.error('Failed to uplod image');
    }
  };
  const handleSaveAddress = async () => {
    const bodyData = {
      recipientName,
      recipientPhone,
      AddressLine: addressOne,
      AddressLinetwo: addressTwo,
      areaID: areaId,
      lat: updatedLat,
      lng: updatedLng,
      type: areaId === -1 ? 4 : 0,
      countryId,
      PostalCode: postalCode,
      HouseImage: houseImgPath,
      extraRecipientNumber: secondaryRecipientPhone,
      extraRecipientNumberCountry: secondaryRecipientPhone ? +secondaryCountryId : 0,
      extraRecipientCountryCode: secondaryRecipientPhone ? +secondaryCountryCode : null,
    };
    setIsLoaderOpen(true);
    setDisableBtn(true);
    try {
      await ViewOrderService.updateAddress(bodyData, orderId);
      if (isAction) {
        const clonedRecepient = cloneDeep(recipient);
        clonedRecepient.recipentName = recipientName;
        clonedRecepient.recipientPhone = recipientPhone;
        clonedRecepient.address = addressOne;
        clonedRecepient.extra = addressTwo;
        clonedRecepient.lat = updatedLat;
        clonedRecepient.lng = updatedLng;
        if (clonedRecepient.areaId) {
          clonedRecepient.areaId.id = areaId;
        }
        // clonedRecepient.areaId && (clonedRecepient.areaId.id = areaId);
        clonedRecepient.country.id = countryId;
        dispatch(
          updateActionRecepientAddress({
            id: orderId,
            updatedData: clonedRecepient,
          })
        );
        setTimeout(() => {
          setDisableBtn(false);
          setIsLoaderOpen(false);
          dispatch(setToggleAddressModalOpen());
          toast.success('Recipient details updated successfully!');
          if (premium) {
            dispatch(setPremiumTableData(''));
          } else {
            dispatch(setTableData(''));
          }
        }, 1500);
      } else {
        setTimeout(() => {
          setDisableBtn(false);
          setIsLoaderOpen(false);
          dispatch(setToggleAddressModalOpen());
          dispatch(setViewOrderData(orderId));
        }, 1500);
      }
    } catch (e) {
      errorLogger('EditAddressModal:handleSaveAddress', e);
      setDisableBtn(false);
      setIsLoaderOpen(false);
      dispatch(setToggleAddressModalOpen());
      toast.error('Failed to update recipient details!');
    }
  };

  const handleAreaChange = (e) => {
    setAreaId(e.itemData.id);
  };

  const handleCountryChange = (e) => {
    setCountryId(e.itemData.Id);
  };

  const handleSecondaryCountryChange = (e) => {
    setSecondaryCountryId(e.itemData.Id);
    setSecondaryCountryCode(e.itemData.countryCode);
  };
  const handleCopyCoord = () => {
    copyToClipboard(
      updatedLat && updatedLng
        ? updatedLat + ',' + updatedLng
        : recipient
        ? recipient.lat + ',' + recipient.lng
        : ''
    );
  };

  return (
    <Modal
      isOpen={isAddressModalOpen}
      toggle={() => dispatch(setToggleAddressModalOpen())}
      className={`edit-address-modal ${showPrevAddress ? 'prev-address-modal' : ''}`}
    >
      {showMap ? (
        <MapComponent
          setShowMap={setShowMap}
          latValue={parseFloat(latValue)}
          lngValue={parseFloat(lngValue)}
          setAddressOne={setAddressOne}
          addressOne={addressOne}
          setAddressTwo={setAddressTwo}
          setAreaId={setAreaId}
          areaId={areaId}
          setUpdatedLat={setUpdatedLat}
          setUpdatedLng={setUpdatedLng}
          updatedLat={parseFloat(updatedLat)}
          updatedLng={parseFloat(updatedLng)}
          orderOps={orderOps}
          countryIso={countryIso}
          initialLocation={initialLocation}
          updateInitialLocation={setInitialLocation}
          linkLocationUsed={linkLocationUsed}
          setLinkLocationUsed={setLinkLocationUsed}
        />
      ) : showPrevAddress ? (
        <PreviousAddress
          orderId={orderId}
          setShowPrevAddress={setShowPrevAddress}
          recipient={recipient}
          isAction={isAction}
          premium={premium}
          addressList={prevAddressList}
        />
      ) : isMapLinkModalOpen ? (
        <MapLinkModal
          setUpdatedLat={setUpdatedLat}
          setUpdatedLng={setUpdatedLng}
          orderOps={orderOps}
          setShowMap={setShowMap}
          setLinkLocationUsed={setLinkLocationUsed}
        />
      ) : (
        <Fragment>
          <ModalHeader toggle={() => dispatch(setToggleAddressModalOpen())}>
            Edit Address
          </ModalHeader>
          {isLoaderOpen ? (
            <SectionLoader />
          ) : (
            <Fragment>
              <ModalBody>
                <div className="row modal-body-wrapper">
                  <div className="form-group col-7 p-r-0">
                    <div className="col-12 m-b-10">
                      <strong>Contact Details</strong>
                    </div>
                    <div className="form-group col-12">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Recipient Name
                      </label>
                      <input
                        onChange={(e) => setRecipientName(e.target.value)}
                        value={recipientName}
                        className="form-control"
                        type="text"
                      />
                    </div>
                    <div className="form-group col-12">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Phone Number
                      </label>
                      <div className="phone-wrapper">
                        <div className="filter-select">
                          <CustomSelect
                            id="address-country"
                            listData={countryList}
                            handleChange={handleCountryChange}
                            placeholder="Select an area"
                            textPropName="Code"
                            valuePropName="Id"
                            selectedValue={countryId}
                          />
                        </div>
                        <div>
                          <input
                            onChange={(e) => setRecipientPhone(e.target.value)}
                            value={recipientPhone}
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    {showSecondaryPhone ? (
                      <div className="form-group col-12">
                        <label className="col-form-label" htmlFor="recipient-name">
                          Secondary Phone Number
                        </label>
                        <div className="phone-wrapper">
                          <div className="filter-select">
                            <CustomSelect
                              id="address-sec-country"
                              listData={countryList}
                              handleChange={handleSecondaryCountryChange}
                              placeholder="Select an area"
                              textPropName="Code"
                              valuePropName="Id"
                              selectedValue={secondaryCountryId}
                            />
                          </div>
                          <div>
                            <input
                              onChange={(e) => setSecondaryRecipientPhone(e.target.value)}
                              value={secondaryRecipientPhone}
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        className="btn col-6 btn-view-more text-left"
                        onClick={() => setShowSecondaryPhone(true)}
                      >
                        <i className="fa fa-share-square-o col-1" />
                        Add secondary number
                      </button>
                    )}
                  </div>
                  {prevAddressList?.length > 0 && (
                    <div className="form-group col-5 p-l-0">
                      <div className="m-b-10 col-12">
                        <strong>Saved Address ({prevAddressList.length})</strong>
                      </div>
                      <div className="address-card">
                        {prevAddressList[0]?.addressLineOne}
                        <br />
                        {prevAddressList[0]?.name}
                        <br />
                      </div>
                      <button
                        onClick={() => setShowPrevAddress(true)}
                        className="btn col-12 btn-view-more text-right"
                      >
                        <i className="fa fa-map-marker p-r-5" />
                        Select from previous addresses
                      </button>
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="row" style={{ justifyContent: 'space-evenly' }}>
                  <div className="form-group col-12">
                    <strong className="col-6">Address (Required)</strong>
                    <button
                      onClick={() => dispatch(setToggleMapLinkModalOpen())}
                      className="btn col-6 btn-view-more text-right"
                    >
                      <i className="fa fa-map-marker p-r-5" />
                      Add map link
                    </button>
                  </div>
                  <div className="form-group col-12">
                    <button onClick={() => setShowMap(true)} className="btn map-btn col-12">
                      <i className="fa fa-map-marker p-r-5" />
                      Change Location
                    </button>
                    <img
                      src="https://cdn.zeplin.io/5f180e07111b776cefe98a2f/assets/750FBA1C-60B2-41C7-9BAA-29F2504B4C99.png"
                      alt="map"
                      className="col-12 map-img"
                    />
                  </div>
                  <div className="row col-12">
                    <div className="form-group col-6">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Lat (Required)
                      </label>
                      <input
                        value={updatedLat || (recipient && recipient.lat ? recipient.lat : '')}
                        onChange={(e) => setUpdatedLat(e.target.value)}
                        className="form-control"
                        type="text"
                        readOnly
                      />
                    </div>
                    <div className="form-group col-6">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Long (Required)
                      </label>
                      <input
                        value={updatedLng || (recipient && recipient.lng ? recipient.lng : '')}
                        onChange={(e) => setUpdatedLng(e.target.value)}
                        className="form-control"
                        type="text"
                        readOnly
                      />
                    </div>
                    <button
                      className="btn col-11 btn-view-more text-left m-l-15 m-b-10"
                      onClick={handleCopyCoord}
                    >
                      <i className="fa fa-clone p-r-5" />
                      Copy coords
                    </button>
                    <div className="col-6 form-group p-0">
                      <div className="col-12">
                        <strong>
                          <i className="fa fa-map-marker p-r-5" />
                          Address from Map
                        </strong>
                      </div>
                      <div className="col-12 filter-select">
                        <label className="col-form-label" htmlFor="address-area">
                          Area
                        </label>
                        <CustomSelect
                          id="address-area"
                          listData={areaList}
                          handleChange={handleAreaChange}
                          placeholder="Select an area"
                          textPropName="name"
                          valuePropName="id"
                          selectedValue={areaId}
                          groupBy={'mainArea'}
                        />
                      </div>
                      <div className="col-12">
                        <label className="col-form-label" htmlFor="recipient-name">
                          Address
                        </label>
                        <textarea
                          onChange={(e) => setAddressOne(e.target.value)}
                          value={addressOne}
                          className="form-control"
                          type="text"
                          // readOnly={(orderOps !== 10 && orderOps !== 21) ?? true}
                        />
                      </div>
                    </div>
                    <div className="col-6 form-group">
                      <div className="col-12">
                        <strong>
                          <i className="fa fa-address-book-o p-r-5" />
                          Address from Customer
                        </strong>
                      </div>
                      <div className="col-12">
                        <label className="col-form-label" htmlFor="recipient-name">
                          Additional Address details
                        </label>
                        <textarea
                          onChange={(e) => setAddressTwo(e.target.value)}
                          value={addressTwo}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  {(orderOps === 10 || orderOps === 21) && (
                    <div className="form-group col-12 p-l-30 p-r-35">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Postal code
                      </label>
                      <input
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  )}
                  <div
                    className={`col-12 house-photo-wrapper ${
                      screenWidth < 500 ? 'column-direction' : ''
                    }`}
                  >
                    <div className="form-group col-12 col-sm-9">
                      <label className="col-form-label pt-0" htmlFor="address-house">
                        House photo
                      </label>
                      <input
                        id="address-house"
                        onChange={(e) => addHouseImg(e)}
                        className="form-control"
                        type="file"
                        accept="image/*"
                      />
                    </div>
                    <div className="col-12 col-sm-3">
                      {(houseImg || (recipient && recipient?.houseImage)) && (
                        <img
                          alt="house"
                          id="image-preview"
                          src={houseImg || recipient.houseImage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </ModalFooter>
            </Fragment>
          )}

          <ModalFooter>
            <div className="col-12 row modal-footer-wrapper">
              <Button
                color="light"
                className="col-2 m-r-10"
                onClick={() => dispatch(setToggleAddressModalOpen())}
              >
                Cancel
              </Button>
              <Button
                disabled={disableBtn}
                className="col-9 m-r-10"
                onClick={handleSaveAddress}
                color="primary"
              >
                Save
              </Button>
            </div>
          </ModalFooter>
        </Fragment>
      )}
    </Modal>
  );
};

export default EditAddressModal;
