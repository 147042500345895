import { apiHubService, apiGatewayService, apiOrderService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { localStorageKeys } from 'constant/Enums';

class QuickActionService {
  static getOrderActionsData(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.orderAction, ...extraParams),
      data,
    });
  }

  static getPremiumQuickActionsData(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.orderAction, ...extraParams),
      data,
    });
  }

  static getTimeSlot(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.timeSlot, ...extraParams),
    });
  }

  static getUserAgent(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.userAgent, ...extraParams),
    });
  }

  static getFilterAreas(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.filterAreas, ...extraParams),
    });
  }

  static AddNote(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.AddNote, ...extraParams),
      data,
    });
  }

  static updateDetailsPrinted(...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.detailsPrinted, ...extraParams),
    });
  }

  static updateSharedDetails(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.shareSenderDetails, ...extraParams),
      data,
    });
  }

  static sendLocationLink(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.sendLocationLink, ...extraParams),
      params: data,
    });
  }

  static exportCm(data, ...extraParams) {
    return apiHubService({
      method: 'GET',
      url: setEndPoint(endPoints.exportCm, ...extraParams),
      params: data,
    });
  }

  static createNewOrder(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.createNewOrder, ...extraParams),
      data,
    });
  }

  static createNewPremiumOrder(data, ...extraParams) {
    return apiOrderService({
      method: 'POST',
      url: setEndPoint(endPoints.createNewPremiumOrder, ...extraParams),
      data,
    });
  }

  static getOrdersZones(data, ...extraParams) {
    return apiOrderService({
      method: 'GET',
      url: setEndPoint(endPoints.totalOrdersZone, ...extraParams),
      params: data,
    });
  }

  static updatePriority(data, ...extraParams) {
    return apiOrderService({
      method: 'POST',
      url: setEndPoint(endPoints.orderPriority, ...extraParams),
      data,
      headers: { Authorization: `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}` },
    });
  }

  static getCoordinates(data) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.coordinates),
      params: data,
    });
  }

  static getQuickOrdersData(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getQuickOrdersData, ...extraParams),
      data,
    });
  }

  static getExportQuickOrdersData(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.exportQuickOrders, ...extraParams),
      data,
    });
  }

  static getDuplicatedAndReplacedOrders(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getDuplicatedAndReplacedOrders, ...extraParams),
      params,
    });
  }

  static exportAllOrders(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportAllOrders, ...extraParams),
      params,
      responseType: 'blob',
    });
  }

  static getBulkActionsShipOnfleet(data, params, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getShipOnFleetBulkAction, ...extraParams),
      data,
      params,
    });
  }

  static setPriorityStatus(data) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.setOrdersPriority),
      data,
    });
  }

  static setPrintStatus(data) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.setOrdersPrintStatus),
      data,
    });
  }

  static makeZiwoCall(params, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.makeZiwoCall, ...extraParams),
      params,
    });
  }

  static getZones(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getZones, ...extraParams),
    });
  }
}
export default QuickActionService;
