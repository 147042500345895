/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import RedFlagIcon from 'components/shared/RedFlagIcon';

// sub menu item (has children) without permission
const SubMenuItem = ({
  clicked,
  Icon,
  title,
  isSubChild,
  ticketsList,
  alertQtyItems,
  isCustomIcon,
}) => (
  <a className={!isSubChild ? 'sidebar-header pointer' : 'pointer'} onClick={() => clicked()}>
    {Icon && !isSubChild && !isCustomIcon ? (
      <Icon className="light-grey-color" />
    ) : (
      <i className={`fa ${Icon} sidebar-icon`} />
    )}
    <span>{title}</span>
    <i className="fa fa-angle-right pull-right" />
    {ticketsList && ticketsList.some((item) => item.status !== 2) && title === 'Ticketing' && (
      <RedFlagIcon title={title} />
    )}
    {alertQtyItems && title === 'Inventory' && <RedFlagIcon title={title} />}
    {title === 'Premium Orders' && <i className="fa fa-star premium-star" />}
  </a>
);

export default SubMenuItem;
