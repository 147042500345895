/* eslint-disable indent */
import DashboardService from 'services/DashboardService';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';

const setTotalOrders = (opsId) => async (dispatch) => {
  try {
    const totalOrders = await DashboardService.getTotalOrders(opsId, 'TotalOrders');
    dispatch({
      type: 'DASHBOARD_TOTAL_ORDERS',
      payload: totalOrders.data,
    });
  } catch (e) {
    errorLogger('DashboardAction:setTotalOrders', e);
    // console.log(e);
  }
};

const setTodayOrders = (opsId) => async (dispatch) => {
  try {
    const todayOrders = await DashboardService.getTodayOrders(opsId, 'TodayTotalOrders');
    dispatch({
      type: 'DASHBOARD_TODAY_ORDERS',
      payload: todayOrders.data,
    });
  } catch (e) {
    errorLogger('DashboardAction:setTodayOrders', e);
    // console.log(e);
  }
};
const setTotalUsers = (opsId) => async (dispatch) => {
  try {
    const totalUsers = await DashboardService.getTotalUsers(opsId, 'TotalUsers');
    dispatch({
      type: 'DASHBOARD_TOTAL_USERS',
      payload: totalUsers.data,
    });
  } catch (e) {
    errorLogger('DashboardAction:setTotalUsers', e);
    // console.log(e);
  }
};
const setTodayRegisteredUsers = (opsId) => async (dispatch) => {
  try {
    const todayRegisteredUsers = await DashboardService.getTodayRegisteredUsers(
      opsId,
      'TodayRegisteredUsers'
    );
    dispatch({
      type: 'DASHBOARD_TODAY_REGISTERED_USERS',
      payload: todayRegisteredUsers.data,
    });
  } catch (e) {
    errorLogger('DashboardAction:setTodayRegisteredUsers', e);
    // console.log(e);
  }
};
const setRegisteredUsers = (opsId) => async (dispatch) => {
  try {
    const registeredUsers = await DashboardService.getRegisteredUsers(opsId, 'RegisteredUsers');
    dispatch({
      type: 'DASHBOARD_REGISTERED_USERS',
      payload: registeredUsers.data,
    });
  } catch (e) {
    errorLogger('DashboardAction:setRegisteredUsers', e);
    // console.log(e);
  }
};
const setSubscriptions = (opsId) => async (dispatch) => {
  try {
    const subscriptionBilling = await DashboardService.getSubscriptions(
      opsId,
      'SubscriptionBilling'
    );
    dispatch({
      type: 'DASHBOARD_SUBSCRIPTION_BILLING',
      payload: subscriptionBilling.data,
    });
  } catch (e) {
    errorLogger('DashboardAction:setSubscriptions', e);
    // console.log(e);
  }
};
const setTotalProducts = (opsId) => async (dispatch) => {
  try {
    const totalProducts = await DashboardService.getTotalProducts(opsId);
    dispatch({
      type: 'DASHBOARD_TOTAL_PRODUCTS',
      payload: totalProducts.data.totalProducts,
    });
    dispatch({
      type: 'DASHBOARD_LIVE_TOTAL_PRODUCTS',
      payload: totalProducts.data.liveProducts,
    });
  } catch (e) {
    errorLogger('DashboardAction:setTotalProducts', e);
    // console.log(e);
  }
};

const setCorporateOrders = () => async (dispatch) => {
  try {
    const corporateOrders = await DashboardService.getDashboardCorporateOrders();
    dispatch({
      type: 'DASHBOARD_CORPORATE_ORDERS',
      payload: corporateOrders.data,
    });
  } catch (e) {
    errorLogger('DashboardAction:setCorporateOrders', e);
    // console.log(e);
  }
};

export const setDashboardData = (opsId) => async (dispatch) => {
  const promisesArray = [];

  // dispatch(setLoaderOpenTrue());
  try {
    promisesArray.push(
      dispatch(setTotalOrders(opsId)),
      dispatch(setTodayOrders(opsId)),
      dispatch(setTotalUsers(opsId)),
      dispatch(setTodayRegisteredUsers(opsId)),
      dispatch(setRegisteredUsers(opsId)),
      dispatch(setSubscriptions(opsId)),
      dispatch(setTotalProducts(opsId)),
      dispatch(setCorporateOrders())
    );
    await Promise.all(promisesArray);
    // dispatch(setLoaderOpenFalse());
  } catch (e) {
    errorLogger('DashboardAction:setDashboardData', e);
    // dispatch(setLoaderOpenFalse());
  }
};

const setDashboardTimeSlotsList = (list) => ({
  type: 'SET_DASHBOARD_TIME_SLOTS_LIST',
  list,
});

const setDashboardPremiumTimeSlotsList = (list) => ({
  type: 'SET_DASHBOARD_PREMIUM_TIME_SLOTS_LIST',
  list,
});

export const getDashboardTimeSlots =
  ({ isPremium, deliveryDate }) =>
  async (dispatch) => {
    try {
      const res = await DashboardService.getDashboardSlotsWithDate({
        deliveryDate,
        line: isPremium ? 1 : 0,
      });
      const updatedList = res.data.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      if (isPremium) {
        dispatch(setDashboardPremiumTimeSlotsList(updatedList));
      } else {
        dispatch(setDashboardTimeSlotsList(updatedList));
      }
    } catch (err) {
      errorLogger('DashboardAction:getDashboardTimeSlots', err);
      // console.log(err);
    }
  };

export const getDashboardTimeSlotsPrintStatus = () => async (dispatch) => {
  try {
    const { data } = await DashboardService.getDashboardSlotsPrintStatus();
    dispatch({
      type: 'SET_DASHBOARD_TIME_SLOTS_PRINT_STATUS_LIST',
      payload: data,
    });
  } catch (err) {
    errorLogger('DashboardAction:getDashboardTimeSlotsPrintStatus', err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong with print status table');
  }
};
