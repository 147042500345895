import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import SalesPerformanceDataGrid from 'components/reports/inventory/SalesPerformanceDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// urls
import { getSalesPerformanceReportPageUrl } from 'constant/AppUrls';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { getInventorySuppliersList } from 'store/inventory/InventoryActions';
// selectors
import { getSuppliersList } from 'store/inventory/InventorySelectors';
// api
import InventoryReportService from 'services/reportsService/InventoryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SalesPerformanceReportPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: '',
    toDate: '',
    supplierid: null,
  });
  const suppliersList = useSelector((state) => getSuppliersList({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    if (
      params.supplierid === -1 ||
      params.supplierid === null ||
      params.supplierid === '-1' ||
      params.supplierid === 'null'
    ) {
      delete params.supplierid;
    }
    try {
      const res = await InventoryReportService.getSalesPerformanceReport(params);
      let updatedList = res.data.data;
      if (res.data.data && res.data.data.length > 0) {
        updatedList = res.data.data.map((item, index) => ({
          ...item,
          serial: index + 1,
        }));
      }
      setData(updatedList);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Sales Performance Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  const fetchSuppliersList = () => {
    dispatch(getInventorySuppliersList());
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
    fetchSuppliersList();
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: getSalesPerformanceReportPageUrl(),
      search: queryString,
    });
    fetchData(inputValues);
  };

  const getLinkURL = (type, item) => {
    let queryObj = {};
    if (window.location.search) queryObj = convertQueryParamsIntoObject(window.location.search);
    return `/report/salesPerformanceDetailsReport?fromDate=${queryObj.fromDate ?? ''}&toDate=${
      queryObj.toDate ?? ''
    }&status=${type}&inventoryItem=${item}`;
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        supplierType
        title="Inventory Sales Performance Report"
        suppliersList={suppliersList}
        defaultDate
      />
      <SalesPerformanceDataGrid data={data} getLinkURL={getLinkURL} />
    </div>
  );
};

export default SalesPerformanceReportPage;
