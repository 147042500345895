import React, { useState } from 'react';
// components
import SectionLoader from 'components/common/SectionLoader';
import { isoEndFormat, isoStartFormat } from 'constant/Helpers';
const ProductionDataTable = React.lazy(() =>
  import('components/productionSystem/ProductionDataTable')
);

const ProductionPage = (props) => {
  const [inputValues, setInputValues] = useState({
    searchKey: '',
    fromDate: '',
    endDate: '',
    sort: 1,
    page: 1,
  });

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <ProductionDataTable inputValues={inputValues} setInputValues={setInputValues} />
      </React.Suspense>
    </div>
  );
};

export default ProductionPage;
