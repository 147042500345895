import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
// actions
import { setToggleMapLinkModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import { getIsMapLinkModalOpen } from 'store/orderQuickActions/dataTable/DataTableSelector';
// service
import QuickActionService from 'services/QuickActionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const screenWidth = window.screen.width;

const MapLinkModal = ({
  orderOps,
  setShowMap,
  setUpdatedLat,
  setUpdatedLng,
  setLinkLocationUsed,
}) => {
  const isMapLinkModalOpen = useSelector((state) => getIsMapLinkModalOpen({ state }));
  const [link, setLink] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();

  const getCoordinates = async () => {
    if (link !== '') {
      setIsLoaderOpen(true);
      try {
        const res = await QuickActionService.getCoordinates({ url: link });
        if (res.data.lat) {
          setUpdatedLat(res.data.lat);
          setUpdatedLng(res.data.lon);
          setLinkLocationUsed(true);
          setShowMap(true);
          dispatch(setToggleMapLinkModalOpen());
          setIsLoaderOpen(false);
        }
      } catch (e) {
        errorLogger('MapLinkModal:getCoordinates', e);
        toast.error(e.response.data.message);
        setIsLoaderOpen(false);
      }
    } else {
      toast.error('link is required');
    }
  };

  return (
    <Modal
      isOpen={isMapLinkModalOpen}
      toggle={() => dispatch(setToggleMapLinkModalOpen())}
      className="map-link-modal-wrapper"
    >
      <Fragment>
        <ModalHeader toggle={() => dispatch(setToggleMapLinkModalOpen())}>
          From map link
        </ModalHeader>
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <Fragment>
            <ModalBody>
              <form>
                <div className="row form-content">
                  <div className="form-group col-12">
                    <label className="col-form-label" htmlFor="link">
                      Link
                    </label>
                    <input
                      onChange={(e) => setLink(e.target.value)}
                      value={link}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
              </form>
              <div
                className={`row map-actions-wrapper ${screenWidth < 500 ? 'column-direction' : ''}`}
              >
                <button
                  onClick={() => getCoordinates()}
                  className={`btn btn-primary ${screenWidth < 500 ? 'margin-bottom-btn' : ''}`}
                >
                  <i className="fa fa-map-marker"></i> Get
                </button>
              </div>
            </ModalBody>
          </Fragment>
        )}

        <ModalFooter>
          <div className="col-12 cancel-wrapper">
            <Button color="light" onClick={() => dispatch(setToggleMapLinkModalOpen())}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Fragment>
    </Modal>
  );
};

export default MapLinkModal;
