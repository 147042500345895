import cloneDeep from 'lodash/cloneDeep';
import { updateObject } from '../utility';

const initialState = {
  occasionsList: [],
  occasionsCardsList: [],
  selectedOccasionsCardsIds: [],
  occasionsFinalCardsList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OCCASIONS_LIST': {
      return updateObject(state, {
        occasionsList: action.payload,
      });
    }

    case 'GET_OCCASIONS_CARDS_LIST': {
      return updateObject(state, {
        occasionsCardsList: action.payload,
      });
    }

    case 'SET_SELECTED_OCCASIONS_CARDS_IDS': {
      return updateObject(state, {
        selectedOccasionsCardsIds: action.payload,
      });
    }
    case 'SET_CARD_PREDICTIONS': {
      const clonedOccasionsCardsList = cloneDeep(state.occasionsCardsList);
      const clonedOccasionsList = cloneDeep(state.occasionsList);
      const predictions = action.payload.data;
      predictions.forEach((item) => {
        item.occasionString = clonedOccasionsList.find(
          (el) => parseInt(el.id) === parseInt(item.id)
        ).nickname;
      });
      clonedOccasionsCardsList[action.payload.index].predictions = predictions;
      return updateObject(state, {
        occasionsCardsList: clonedOccasionsCardsList,
      });
    }

    case 'SET_FINAL_OCCASIONS_CARDS_LIST': {
      return updateObject(state, {
        occasionsFinalCardsList: action.payload ? action.payload : state.occasionsCardsList,
      });
    }
    default:
      return state;
  }
};

export default reducer;
