import { updateObject } from '../utility';

const initialState = {
  viewOrderDetails: '',
  orderInvoice: '',
  cartDetails: [],
  cardMessage: '',
  cutomerCall: '',
  inventoryData: '',
  actionsBox: '',
  driverData: [],
  adjustLog: '',
  adjustData: '',
  orderComments: '',
  generatedLinkData: '',
  updatedPaymentSt: '',
  premiumGiftData: '',
  // trip
  isDriverTripModalOpen: false,
  deliveryDriveryId: '',
  deliveryTripId: '',
  // checkout
  checkoutPaymentDetails: [],
  checkoutLogsData: [],
  // knet hostory
  knetHistoryData: [],
  // ziwoCalls
  ziwoCallsData: [],
  ziwoSecondaryCallsData: [],
  ziwoRecordedCall: '',
  orderInvoicesData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VIEW_ORDER_DETAILS': {
      return updateObject(state, {
        viewOrderDetails: action.payload,
      });
    }
    case 'UPDATE_VIEW_ORDER_DETAILS': {
      return updateObject(state, {
        viewOrderDetails: action.payload,
      });
    }
    case 'SET_ORDER_INVOICE': {
      return updateObject(state, {
        orderInvoice: action.payload,
      });
    }
    case 'SET_CART_DETAILS': {
      return updateObject(state, {
        cartDetails: action.payload,
      });
    }
    case 'SET_CARD_MESSAGE': {
      return updateObject(state, {
        cardMessage: action.payload,
      });
    }
    case 'SET_CUSTOMER_CALL': {
      return updateObject(state, {
        cutomerCall: action.payload,
      });
    }
    case 'SET_INVENTORY': {
      return updateObject(state, {
        inventoryData: action.payload,
      });
    }

    case 'SET_ACTIONS_BOX_DATA': {
      return updateObject(state, {
        actionsBox: action.payload,
      });
    }

    case 'SET_DRIVER_DATA': {
      return updateObject(state, {
        driverData: action.payload,
      });
    }

    case 'SET_ADJUST_LOG': {
      return updateObject(state, {
        adjustLog: action.payload,
      });
    }

    case 'SET_ADJUST_DATA': {
      return updateObject(state, {
        adjustData: action.payload,
      });
    }

    case 'SET_ORDER_COMMENTS': {
      return updateObject(state, {
        orderComments: action.payload,
      });
    }

    case 'SET_REQUEST_GENERATE_LINK': {
      return updateObject(state, {
        generatedLinkData: action.payload,
      });
    }

    case 'SET_PREMIUM_GIFT_DATA': {
      return updateObject(state, {
        premiumGiftData: action.payload,
      });
    }

    case 'SET_UPDATED_PAYMENT': {
      return updateObject(state, {
        updatedPaymentSt: action.payload,
      });
    }
    case 'TOGGLE_DRIVER_TRIP_MODAL_OPEN': {
      return updateObject(state, {
        isDriverTripModalOpen: !state.isDriverTripModalOpen,
        deliveryDriveryId: action.payload.tripId,
        deliveryTripId: action.payload.driverId,
      });
    }

    // checkout
    case 'SET_CHECKOUT_PAYMENT_DETAILS': {
      return updateObject(state, {
        checkoutPaymentDetails: action.payload,
      });
    }
    case 'SET_CHECKOUT_LOGS_DATA': {
      return updateObject(state, {
        checkoutLogsData: action.payload,
      });
    }

    // knet history
    case 'SET_KNET_ORDER_HISTORY': {
      return updateObject(state, {
        knetHistoryData: action.payload,
      });
    }

    // ziwo
    case 'SET_ZIWO_CALLS_DATA': {
      return updateObject(state, {
        ziwoCallsData: action.payload,
      });
    }

    case 'SET_SECONDARY_ZIWO_CALLS_DATA': {
      return updateObject(state, {
        ziwoSecondaryCallsData: action.payload,
      });
    }

    case 'SET_ZIWO_RECORDED_CALL': {
      return updateObject(state, {
        ziwoRecordedCall: action.payload,
      });
    }
    case 'SET_ORDER_INVOICES_DATA': {
      return updateObject(state, {
        orderInvoicesData: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
