import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// actions
import { setToggleDeleteCardModal } from 'store/orderQuickActions/dataTable/DataTableAction';
import { setCardMessage } from 'store/viewOrder/ViewOrderActions';
// selectors
import {
  getIsDeleteCardModalOpen,
  getCardData,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DeleteCardModal = ({ orderId }) => {
  const isDeleteCardModalOpen = useSelector((state) => getIsDeleteCardModalOpen({ state }));
  const cardData = useSelector((state) => getCardData({ state }));
  const dispatch = useDispatch();

  const handleDeleteCard = async () => {
    try {
      await ViewOrderService.getDeleteCard(cardData.id);
      dispatch(setToggleDeleteCardModal());
      dispatch(setCardMessage(orderId));
      toast.success('Card deleted successfully');
    } catch (e) {
      errorLogger('DeleteCardModal:handleDeleteCard', e);
      toast.error('Failed to delete a card');
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isDeleteCardModalOpen} toggle={() => dispatch(setToggleDeleteCardModal())}>
        <ModalHeader toggle={() => dispatch(setToggleDeleteCardModal())}>Delete Card</ModalHeader>
        <ModalBody>
          <p className="delete-card-confirmation-title">Are you sure?</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleDeleteCard} color="danger">
            Delete
          </Button>
          <Button color="light" onClick={() => dispatch(setToggleDeleteCardModal())}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteCardModal;
