import React, { useState, Fragment } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// api
import MasterCatalogService from 'services/MasterCatalogService';

const UploadRankingFileModal = ({ isUploadRankingModalOpen, setIsUploadRankingModalOpen }) => {
  const [excelFile, setExcelFile] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleUploadFile = async () => {
    setIsLoaderOpen(true);
    setDisableBtn(true);
    const bodyFormData = new FormData();
    bodyFormData.append('file', excelFile);
    try {
      const res = await MasterCatalogService.uploadRankingFile(bodyFormData);
      setIsUploadRankingModalOpen((prev) => !prev);
      toast.success(res?.data?.message ?? 'Uploaded');
    } catch (err) {
      errorLogger('UploadRankingFileModal:handleUploadFile', err);
      setIsLoaderOpen(false);
      setDisableBtn(false);
      toast.error(err?.response?.data?.message ?? 'Failed to upload');
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isUploadRankingModalOpen}
        toggle={() => setIsUploadRankingModalOpen((prev) => !prev)}
        className="m-w-600"
      >
        <ModalBody>
          {isLoaderOpen ? (
            <SectionLoader height="120px" />
          ) : (
            <Fragment>
              <div className="row">
                <div className="form-group col-12">
                  <label className="col-form-label pt-0" htmlFor="ranking-ops">
                    Ranking will be applied on current operation
                  </label>
                  <input
                    id="ranking-ops"
                    onChange={(e) => setExcelFile(e.target.files[0])}
                    className="form-control"
                    type="file"
                    accept=".csv"
                  />
                  <a
                    className="col-form-label mt-1 template-download-link"
                    href="https://cdn.floward.com/web/Files/attachment/ranking_file-637791419931284478.csv"
                    title="Download ranking template"
                  >
                    Download template
                  </a>
                </div>
              </div>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleUploadFile} color="primary" disabled={disableBtn || !excelFile}>
            Upload
          </Button>
          <Button color="light" onClick={() => setIsUploadRankingModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadRankingFileModal;
