import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Helpers
import { convertObjectIntoQueryParams } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// Actions
import { setPrintingTableData } from 'store/orderQuickPrinting/QuickPrintingActions';
// Selectors
import {
  getOrdersData,
  getCurrentPage,
  getPageSize,
  getRowCount,
  getQueryParams,
} from 'store/orderQuickPrinting/QuickPrintingSelectors';
// Components
import QuickPrintingDataGrid from './QuickPrintingDataGrid';
import DJHPagination from 'components/shared/Pagination';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import Paper from '@mui/material/Paper';

const QuickPrintingDataTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => getOrdersData({ state }));
  const currentPage = useSelector((state) => getCurrentPage({ state }));
  const pageSize = useSelector((state) => getPageSize({ state }));
  const rowCount = useSelector((state) => getRowCount({ state }));
  const inputValues = useSelector((state) => getQueryParams({ state }));

  const onPageChange = (page) => {
    dispatch(setPrintingTableData(page));
    const updatedParams = cloneDeep(inputValues);
    updatedParams.page = page;
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: '/orders/quickPrinting',
      search: queryString,
    });
  };

  return (
    <div className="quick-actions-table toolbar-table-style">
      <div className="control-pane">
        <AppErrorBoundary>
          <QuickPrintingDataGrid data={data} />
        </AppErrorBoundary>
        {+rowCount > +pageSize && (
          <AppErrorBoundary>
            <Paper className="p-1">
              <DJHPagination
                onPageChange={onPageChange}
                currentPage={+currentPage}
                pageSize={+pageSize}
                itemsCount={+rowCount}
              />
            </Paper>
          </AppErrorBoundary>
        )}
      </div>
    </div>
  );
};

export default QuickPrintingDataTable;
