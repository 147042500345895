import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

let amount = 0;
let percentage = 0;

const AddCreditModal = ({
  orderId,
  isAddCreditModalOpen,
  setIsAddCreditModalOpen,
  paymentdetails,
  opsId,
  currency,
}) => {
  const [selectReason, setSelectReason] = useState('');
  const [remarks, setRemarks] = useState('');
  const [amountFieldValue, setAmountFieldValue] = useState('');
  const [showAmountField, setShowAmountField] = useState(false);
  const [policyNote, setPolicyNote] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);

  const getMaxAmountToRefund = (percentage) => {
    const orderAmount = paymentdetails.grandTotal;
    return orderAmount * (percentage / 100);
  };

  const handleChange = (e) => {
    const reason = e.itemData.id;
    setSelectReason(e.itemData.id);

    if (reason >= 5 && reason <= 7) {
      setShowAmountField(false);
      if (opsId === 1 || opsId === 4) {
        amount = 3;
      } else {
        amount = 40;
      }
      setSelectReason(e.itemData.id);
      setPolicyNote(`As per the policy we will add ${amount}${currency} to the customer wallet`);
    } else {
      setShowAmountField(true);
      if (reason === 8) {
        percentage = 20;
      } else if (reason === 9) {
        percentage = 50;
      }
      amount = Math.round(getMaxAmountToRefund(percentage));
      setPolicyNote(`As per the policy the maximum credit ${percentage}% = ${amount}${currency}`);
    }
  };

  const sendAmountApi = async (dataBody, orderId) => {
    setDisableBtn(true);
    try {
      const res = await ViewOrderService.addCredit(dataBody, orderId);
      setIsAddCreditModalOpen((prev) => !prev);
      toast.success(res.data.message);
      setDisableBtn(false);
    } catch (error) {
      errorLogger('AddCreditModal:sendAmountApi', error);
      toast.error(error?.response?.data?.message);
      setIsAddCreditModalOpen((prev) => !prev);
      setDisableBtn(false);
    }
  };

  const AddCredit = () => {
    if (amountFieldValue > amount) {
      toast.error(`The maximum credit ${amount}${currency} `);
    } else {
      const dataBody = {
        type: selectReason,
        remarks,
        amount: selectReason <= 7 ? amount : amountFieldValue,
      };
      sendAmountApi(dataBody, orderId);
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddCreditModalOpen}
        toggle={() => setIsAddCreditModalOpen((prev) => !prev)}
        data-testid="AddCreditModalTestID"
      >
        <ModalHeader>Add Credit</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group col-12 filter-select">
              <label htmlFor="credit-reason">Reason</label>
              <CustomSelect
                id="credit-reason"
                listData={[
                  { id: 5, name: 'Late order' },
                  { id: 6, name: 'Card issue' },
                  { id: 7, name: 'Driver issue' },
                  { id: 8, name: 'Minor issues' },
                  { id: 9, name: 'Major issues' },
                ]}
                handleChange={handleChange}
                placeholder="Select a reason"
                textPropName="name"
                valuePropName="id"
              />
            </div>
            {showAmountField && (
              <div className="form-group col-12">
                <label className="col-form-label" htmlFor="message-text">
                  Amount ({currency})<span className="red-title">*</span>
                </label>
                <input
                  data-testid="AddCreditModalAmountTestID"
                  onChange={(e) => setAmountFieldValue(e.target.value)}
                  value={amountFieldValue}
                  className="form-control"
                  id="message-text"
                ></input>
              </div>
            )}

            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Remarks <span className="red-title">*</span>
              </label>
              <textarea
                data-testid="AddCreditModalRemarksTestID"
                onChange={(e) => setRemarks(e.target.value)}
                className="form-control"
                id="message-text"
                required
              ></textarea>
            </div>
          </form>
          {policyNote && (
            <div className="policy-note-wrapper">
              <h6>{policyNote}</h6>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={(showAmountField ? !remarks || !amountFieldValue : !remarks) || disableBtn}
            onClick={(e) => AddCredit(e)}
            color="primary"
            data-testid="AddCreditModalSaveTestID"
          >
            Add Balance
          </Button>
          <Button
            onClick={() => setIsAddCreditModalOpen((prev) => !prev)}
            color="light"
            data-testid="AddCreditModalCancelTestID"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddCreditModal;
