import React from 'react';
import { useParams } from 'react-router-dom';
// components
import InventoryCareSection from 'components/inventory/items/InventoryCareSection';
import InventoryItemSection from 'components/inventory/items/InventoryItemSection';
import RestrictedSection from 'components/shared/RestrictedSection';

const EditInventoryPage = () => {
  const params = useParams();

  return (
    <>
      <RestrictedSection page="inventory" action="edit_inventory">
        <InventoryItemSection itemId={params?.id} />
      </RestrictedSection>
      <RestrictedSection page="inventory" action="inventory_care">
        <InventoryCareSection invetoryId={params?.inventoryId} itemId={params?.id} />
      </RestrictedSection>
    </>
  );
};

export default EditInventoryPage;
