import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// selectors
import { getSelectedSubCategory } from 'store/categories/CategoriesSelectors';
// acttion
import { getSubCategory } from 'store/categories/CategoriesActions';
// components
import SectionLoader from 'components/common/SectionLoader';
const EditCategory = React.lazy(() => import('components/categories/EditCategory'));

const EditCategoryPage = () => {
  const subCategoryData = useSelector((state) => getSelectedSubCategory({ state }));
  const dispatch = useDispatch();
  const params = useParams();

  const fetchData = async () => {
    await dispatch(getSubCategory(parseInt(params?.id, 10)));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <EditCategory subCategoryData={subCategoryData} />
      </React.Suspense>
    </div>
  );
};

export default EditCategoryPage;
