import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// react strap
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// selectors
import {
  getAddInventoryIsAddInventoryModal,
  getAddInventoryIsLoading,
  isAddInventoryFormValid,
} from 'store/viewOrder/inventory/addInventory/selectors/AddInventorySelectors';
// actions
import {
  addSingleInventoryItem,
  closeAddInventoryModal,
} from 'store/viewOrder/inventory/addInventory/actions/AddInventoryActions';
// components
import AddInventoryForm from '../forms/AddInventoryForm';
import SectionLoader from 'components/common/SectionLoader';

const AddInventoryModal = ({ orderId, opsId }) => {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => getAddInventoryIsAddInventoryModal({ state }));
  const isFormValid = useSelector((state) => isAddInventoryFormValid({ state }));
  const isLoading = useSelector((state) => getAddInventoryIsLoading({ state }));

  const closeModal = () => {
    dispatch(closeAddInventoryModal());
  };

  const addItem = () => {
    dispatch(addSingleInventoryItem(orderId));
  };

  return (
    <div className="card-body btn-showcase">
      <Modal className="add-inventory-modal" isOpen={isActive} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Add Item</ModalHeader>
        <ModalBody>
          {isLoading ? <SectionLoader height={300} /> : <AddInventoryForm opsId={opsId} />}
        </ModalBody>
        <ModalFooter>
          <Button onClick={addItem} color="primary" disabled={!isFormValid || isLoading}>
            Save
          </Button>
          <Button color="light" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddInventoryModal;
