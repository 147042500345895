import React from 'react';
import { useDispatch } from 'react-redux';
// helpers
import { orderCstepIcon } from 'constant/Enums';
// material UI
import Tooltip from '@mui/material/Tooltip';
// actions
import { setChangeStatusModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';

const TableEditAction = ({ openActionModal, button, orderCstep, orderId, rowNote }) => {
  const dispatch = useDispatch();

  return (
    <div className="table-edit-action-wrapper">
      {button ? (
        <Tooltip title="Status">
          <img
            onClick={() =>
              dispatch(
                setChangeStatusModalOpen({
                  orderId,
                  orderCstep,
                })
              )
            }
            src={orderCstepIcon[orderCstep]}
            className="table-edit-action-icon"
            alt="Status"
          />
        </Tooltip>
      ) : (
        <Tooltip title="Add Note">
          <i
            onClick={() => dispatch(openActionModal(orderId, rowNote))}
            className="fa fa-edit edit-action-icon"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default TableEditAction;
