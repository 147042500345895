import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import DriverLogisticsDataGrid from 'components/reports/delivery/DriverLogisticsDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import DJHPagination from 'components/shared/Pagination';
// constants
import { getDriverLogisticsReportPageUrl } from 'constant/AppUrls';
// helpers
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import DeliveryReportService from 'services/reportsService/DeliveryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DriverLogisticsReportPage = () => {
  const [driversLogisticsData, setDriversLogisticsData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    searchKey: '',
    page: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await DeliveryReportService.getDriverLogistics(params);
      setDriversLogisticsData(res.data.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Driver Logistic Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: getDriverLogisticsReportPageUrl(),
      search: queryString,
    });
    fetchData(inputValues);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getDriverLogisticsReportPageUrl(),
      search: queryString,
    });
    fetchData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  const handleExport = async () => {
    try {
      const res = await DeliveryReportService.exportDriverLogistics({ ...inputValues, page: -1 });
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('Driver Logistic Report Page Export Data Error:', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Driver Logistics"
        hasExport
        handleExport={handleExport}
        defaultDate
        logisticsSearch
      />
      <DriverLogisticsDataGrid data={driversLogisticsData.data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+driversLogisticsData.currentPage}
        pageSize={+driversLogisticsData.pageSize}
        itemsCount={+driversLogisticsData.rowCount}
      />
    </div>
  );
};

export default DriverLogisticsReportPage;
