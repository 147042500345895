import {
  Home,
  Airplay,
  BarChart,
  Truck,
  Users,
  Tag,
  ThumbsUp,
  Settings,
  Clock,
  Star,
  Gift,
  Grid,
  Package,
  Image,
  Scissors,
  Percent,
  FileText,
  Link,
} from 'react-feather';
// urls
import {
  getHomePageUrl,
  getCreateOrderPageUrl,
  getOrderQuickActionsPageUrl,
  getOrderQuickPrintingPageUrl,
  getPremiumQuickActionsPageUrl,
  getDriversPageUrl,
  getAdminPagesUrl,
  getAdminGroupsUrl,
  getAdminUsersUrl,
  getPickersPageUrl,
  getPackersPageUrl,
  getFloristsPageUrl,
  getUserDefaultAddressesPageUrl,
  getUsersActivityPageUrl,
  getOrderedItemsPageUrl,
  getAdminMessageUrl,
  getOrdersZonesPageUrl,
  getSubscriptionsPage,
  getDeliverySlotsPage,
  getDeliverySlotsPremiumPage,
  // ticketing
  getTopicsPageUrl,
  getOpsTicketsPageUrl,
  getMyTicketsPage,
  getMyOpenedTicketsPage,
  getOpenedTicketsPage,
  getClosedTicketsPage,
  getMyClosedTicketsPage,
  getTechTicketsPageUrl,
  getOpenedTechTicketsPageUrl,
  getClosedTechTicketsPageUrl,
  getAdminNotificationsMessagesUrl,
  getTripDriversListPage,
  getQuickOrdersPageUrl,
  // reports
  getProductPriceBreakDown,
  getProductBreakDown,
  getCategoryBreakDown,
  getProductTypeSales,
  getProductTypeAttribute,
  getLiveProductsReport,
  getCartProductsDiscounts,
  getProductTypeBreakDown,
  getProductCategoryPercentage,
  getSalesReportPageUrl,
  getOrdersReportPageUrl,
  getFinancialOrdersPageUrl,
  getDailyTrackerOrdersPageUrl,
  getOrderAmountDetailReportPageUrl,
  getDesignerCommissionPageUrl,
  getGiftsDailySpendPageUrl,
  getDesignersDailyPerformancePageUrl,
  getDesignersProductPerformancePageUrl,
  getDesignersReportPageUrl,
  getOrderCancellationPageUrl,
  getBrandsReportPageUrl,
  getOrdersProductWisePage,
  getTopTenProductPage,
  getOldProductPage,
  getInventoryBalanceReportPageUrl,
  getInventorySupplierReportPageUrl,
  getInventoryStatementActualDeliveryReportPageUrl,
  getSalesPerformanceReportPageUrl,
  getRevenuesReportPageUrl,
  getDeferredRevenuesReportPageUrl,
  getWalletBalancesPageUrl,
  getWalletTransactionsPageUrl,
  getRegisteredUsersPageUrl,
  getHoursBreakdownPageUrl,
  getCouponDailySpendReportPageUrl,
  getStatementsReportPageURL,
  getFinanceInventoryStatementsPageUrl,
  getStatementsCancelledReportPageURL,
  getStatementsDamagedReportPageURL,
  getInventoryReportPageURL,
  getDriverPerformanceReportPageUrl,
  getDeliveryPerformanceReportPageUrl,
  getDriverLogisticsReportPageUrl,
  getDriverPerformanceDetailsReportPageUrl,
  getAreasBreakdownPageUrl,
  getFloristsPerformancePageUrl,
  // customer service
  getCustomerServicePageUrl,
  // inventory
  getInStockInventoryItemsPageUrl,
  getDiscontinuedInventoryItemsPageUrl,
  getOutofStockInventoryItemsPageUrl,
  getAllInventoryItemsPageUrl,
  getAllInventoryItemsAllOpsPageUrl,
  getInventorySuppliersPageUrl,
  getMasterInventoryPageURL,
  getAlertQtyInventoryItemsPageUrl,
  // occasions
  getOccasionsPageUrl,
  // late orders
  getLateOrdersPageUrl,
  // Delivery areas
  getDeliveryAreasPageUrl,
  getCoverageAreasPageUrl,
  // drivers expenses
  getDriversExpensesPageUrl,
  //driver radius
  getDriverRadiusPageUrl,
  // corporate reports
  getCorporateReportPageUrl,
  getCustomerCorporateReportPageUrl,
  // florist community
  // getFloristcommunityUnderReviewPageUrl,
  // getFloristcommunityAcceptedPageUrl,
  // getFloristcommunityRejectedPageUrl,
  // getFloristcommunityDeletedPageUrl,
  // getFloristTransactionsPageUrl,
  // postcode
  getCoveragePostcodePage,
  // vouchers
  getVouchersListPageUrl,
  // master catalog
  getActiveInstockPageUrl,
  getActiveOutstockPageUrl,
  getInactiveInstockPageUrl,
  getInactiveOutstockPageUrl,
  getDraftCatalogPageUrl,
  getArchiveCatalogPageUrl,
  getTotalCatalogPageUrl,
  getUncategorizedMerchanidsePageUrl,
  // duplaicted and replaced
  getDuplicatedAndReplacedPageUrl,
  // celebrities
  getCelebritiesPageUrl,
  // seasonal prep
  getSeasonalPrepPageUrl,
  // kpi
  getKpiPageUrl,
  // country slots
  getCountrySlotsPageUrl,
  // ranking products
  getRankingProductsPage,
  // customer loyality
  getCustomerLoyalityPageUrl,
  // key value
  getKeyValuesPageUrl,
  // invoices
  getInvoicesPageUrl,
  getCorporateInvoicesPageUrl,
  getConsolidatedInvoicesPageUrl,
  getGeneratedInvoicesPageUrl,
  // shorter link
  getShorterLinkPageUrl,
  // categories
  getMerchandisingCategoriesPage,
  // attributes
  getAttributesPageUrl,
  // generate payment link
  getGeneratePaymentLinkPageUrl,
  // production system
  getProductionPage,
  getFeaturedOccasionPageUrl,
  // user feedback
  getUserFeedbackPageUrl,
} from 'constant/AppUrls';

export const MENUITEMS = [
  {
    title: 'Dashboard',
    icon: Home,
    type: 'link',
    badgeType: 'primary',
    active: false,
    per: 'dashboard',
    path: getHomePageUrl(),
  },
  {
    title: 'KPI Summary',
    isCustomIcon: true,
    icon: 'fa-bar-chart',
    type: 'link',
    badgeType: 'primary',
    active: false,
    per: 'kpi',
    path: getKpiPageUrl(),
  },
  {
    title: 'Country slots',
    icon: Clock,
    type: 'link',
    badgeType: 'primary',
    active: false,
    per: 'country_slots',
    path: getCountrySlotsPageUrl(),
  },
  {
    title: 'Production System',
    isCustomIcon: true,
    icon: 'fa-cogs',
    type: 'sub',
    active: false,
    per: 'production_system',
    children: [
      {
        path: getProductionPage(),
        title: 'Production page',
        type: 'link',
        per: 'production_system',
        icon: 'fa-list-ul',
      },
    ],
  },
  {
    title: 'Sales Orders',
    icon: Airplay,
    type: 'sub',
    active: false,
    per: 'quick_actions',
    children: [
      {
        path: getCreateOrderPageUrl(),
        title: 'Create Bulk Order',
        type: 'link',
        per: 'quick_actions',
        icon: 'fa-cubes',
      },
      {
        path: getOrderQuickActionsPageUrl(),
        title: 'Quick Actions',
        type: 'link',
      },
      {
        path: getOrderQuickPrintingPageUrl(),
        title: 'Quick Printing',
        type: 'link',
        icon: 'fa-print',
        per: 'quick_actions',
      },
      {
        path: getOrderQuickActionsPageUrl(),
        title: 'Pre Orders',
        per: 'quick_actions',
        type: 'link',
        preOrders: true,
        icon: 'fa-rocket',
      },
      {
        path: getOrderQuickActionsPageUrl(),
        title: 'WDS',
        per: 'mbs_orders',
        type: 'link',
        mbsOrders: true,
        icon: 'fa-rocket',
      },
      {
        path: getQuickOrdersPageUrl(),
        title: 'Quick Orders',
        type: 'link',
        per: 'quick_orders',
        icon: 'fa-thumbs-up',
      },
      {
        title: 'Not Confirmed',
        type: 'link',
        per: 'not_confirmed',
        orderStatus: '0',
        icon: 'fa-spinner',
      },
      {
        title: 'Canceled',
        type: 'link',
        per: 'canceled',
        orderStatus: '3',
        icon: 'fa-ban',
      },
      {
        title: 'Pending Cancellation',
        type: 'link',
        per: 'pending_cancellation',
        orderStatus: '6',
        icon: 'fa-pause-circle',
      },
      {
        title: 'Duplicated & Replaced',
        type: 'link',
        per: 'duplicated_replaced',
        path: getDuplicatedAndReplacedPageUrl(),
        icon: 'fa-book',
      },
      {
        title: 'Ordered Items',
        type: 'link',
        per: 'ordered_items',
        path: getOrderedItemsPageUrl(),
        icon: 'fa-print',
      },
      {
        title: 'Orders Zones',
        type: 'link',
        per: 'orders_zones',
        path: getOrdersZonesPageUrl(),
        icon: 'fa-map-marker',
      },
      {
        title: 'Customer Service',
        icon: 'fa-headphones',
        type: 'link',
        per: 'customer_service',
        path: getCustomerServicePageUrl(),
      },
      {
        title: 'Generate Payment Link',
        icon: 'fa-credit-card-alt',
        type: 'link',
        per: 'generate_payment_link',
        path: getGeneratePaymentLinkPageUrl(),
      },
    ],
  },

  {
    title: 'Premium Orders',
    icon: Star,
    type: 'sub',
    active: false,
    per: 'quick_actions',
    children: [
      {
        path: getPremiumQuickActionsPageUrl(),
        title: 'Quick Actions',
        type: 'link',
        orderProdType: 1,
      },
      {
        title: 'Not Confirmed',
        type: 'link',
        per: 'not_confirmed',
        orderStatus: '0',
        orderProdType: 1,
        icon: 'fa-spinner',
      },
      {
        title: 'Canceled',
        type: 'link',
        per: 'canceled',
        orderStatus: '3',
        orderProdType: 1,
        icon: 'fa-ban',
      },
      {
        title: 'Pending Cancellation',
        type: 'link',
        per: 'pending_cancellation',
        orderStatus: '6',
        orderProdType: 1,
        icon: 'fa-pause-circle',
      },
    ],
  },

  {
    title: 'Slots',
    icon: Clock,
    type: 'sub',
    active: false,
    per: 'delivery_slots',
    children: [
      {
        path: getDeliverySlotsPage(),
        title: 'Classic',
        type: 'link',
        per: 'delivery_slots',
        icon: 'fa-calendar',
        prodLine: 0,
      },
      {
        path: getDeliverySlotsPremiumPage(),
        title: 'Premium line',
        type: 'link',
        per: 'delivery_slots',
        icon: 'fa-star',
        prodLine: 1,
      },
    ],
  },

  {
    title: 'Delivery',
    icon: Truck,
    type: 'sub',
    active: false,
    per: 'delivery',
    children: [
      {
        path: getDeliveryAreasPageUrl(),
        title: 'Areas',
        type: 'link',
        per: 'areas',
        icon: 'fa-map',
      },
      {
        path: getCoverageAreasPageUrl(),
        title: 'Coverage areas',
        type: 'link',
        per: 'coverage_areas',
        icon: 'fa-map',
      },
      {
        path: getDriversPageUrl(),
        title: 'Drivers',
        type: 'link',
        per: 'drivers',
        icon: 'fa-car',
      },
      {
        path: getTripDriversListPage(),
        title: 'Trip',
        type: 'link',
        per: 'trip',
        icon: 'fa-road',
      },
      {
        path: getCoveragePostcodePage(),
        title: 'Coverage Postcode',
        type: 'link',
        per: 'coverage_postcode',
        icon: 'fa-envelope',
      },
      {
        path: getDriversExpensesPageUrl(),
        title: 'Drivers Expenses',
        type: 'link',
        per: 'drivers_expenses',
        icon: 'fa-user',
      },
      {
        path: getDriverRadiusPageUrl(),
        title: 'Driver Radius',
        type: 'link',
        per: 'driver_radius',
        icon: 'fa-user',
      },
    ],
  },
  {
    title: 'Inventory',
    icon: Package,
    type: 'sub',
    active: false,
    per: 'inventory',
    children: [
      {
        path: getInStockInventoryItemsPageUrl(),
        title: 'Items',
        type: 'link',
        per: 'inventory',
        icon: 'fa-cubes',
        inventoryStatus: 0,
      },
      {
        path: getDiscontinuedInventoryItemsPageUrl(),
        title: 'Discontinued',
        type: 'link',
        per: 'inventory',
        icon: 'fa-ban',
        inventoryStatus: 2,
      },
      {
        path: getOutofStockInventoryItemsPageUrl(),
        title: 'Out of Stock',
        type: 'link',
        per: 'inventory',
        action: 'out_of_stock',
        icon: 'fa-ban',
        inventoryStatus: 1,
      },
      {
        path: getAllInventoryItemsPageUrl(),
        title: 'All Items',
        type: 'link',
        per: 'inventory',
        icon: 'fa-cubes',
      },
      {
        path: getAllInventoryItemsAllOpsPageUrl(),
        title: 'All Items All Ops',
        type: 'link',
        per: 'inventory',
        icon: 'fa-cubes',
      },
      {
        path: getInventorySuppliersPageUrl(),
        title: 'Suppliers',
        type: 'link',
        per: 'inventory',
        icon: 'fa-user',
      },
      {
        path: getMasterInventoryPageURL(),
        title: 'Master Inventory',
        type: 'link',
        per: 'master_inventory',
        icon: 'fa-cube',
      },
      {
        path: getAlertQtyInventoryItemsPageUrl(),
        title: 'Alerts',
        type: 'link',
        per: 'alert_qty_items',
        icon: 'fa-exclamation-circle',
      },
    ],
  },

  {
    title: 'Catalog',
    icon: Package,
    type: 'sub',
    active: false,
    per: 'master_catalog',
    children: [
      {
        path: getActiveInstockPageUrl(),
        title: 'Active Instock',
        type: 'link',
        per: 'master_catalog',
        icon: 'fa-cube',
      },
      {
        path: getActiveOutstockPageUrl(),
        title: 'Active Outstock',
        type: 'link',
        per: 'master_catalog',
        icon: 'fa-cube',
      },
      {
        path: getInactiveInstockPageUrl(),
        title: 'Inactive Instock',
        type: 'link',
        per: 'master_catalog',
        icon: 'fa-cube',
      },
      {
        path: getInactiveOutstockPageUrl(),
        title: 'Inactive Outstock',
        type: 'link',
        per: 'master_catalog',
        icon: 'fa-cube',
      },
      {
        path: getDraftCatalogPageUrl(),
        title: 'Draft',
        type: 'link',
        per: 'master_catalog',
        icon: 'fa-cube',
      },
      {
        path: getArchiveCatalogPageUrl(),
        title: 'Archive',
        type: 'link',
        per: 'master_catalog',
        icon: 'fa-cube',
      },
      {
        path: getTotalCatalogPageUrl(),
        title: 'Total Catalog',
        type: 'link',
        per: 'master_catalog',
        icon: 'fa-cube',
      },
      {
        path: getUncategorizedMerchanidsePageUrl(),
        title: 'Uncategorized Merch',
        type: 'link',
        per: 'master_catalog',
        icon: 'fa-cube',
      },
      {
        path: getRankingProductsPage(),
        title: 'Ranking Products',
        type: 'link',
        per: 'ranking_products',
        icon: 'fa-sort',
      },
    ],
  },

  {
    title: 'Categories',
    icon: Grid,
    type: 'sub',
    active: false,
    per: 'categories',
    children: [
      {
        path: getMerchandisingCategoriesPage(),
        title: 'Merchandising Categories',
        type: 'link',
        per: 'categories',
        icon: Grid,
      },
      {
        title: 'All',
        type: 'link',
        categoryType: '-1',
        per: 'categories',
        icon: Grid,
      },
      {
        title: 'Collections',
        type: 'link',
        categoryType: '9',
        per: 'categories',
        action: 'show_collections',
        icon: Grid,
      },
      {
        title: 'Main Categories',
        type: 'link',
        categoryType: '0',
        per: 'categories',
        icon: Grid,
      },
      {
        title: 'Promotions',
        type: 'link',
        categoryType: '3',
        per: 'categories',
        icon: Grid,
      },
      {
        title: 'Designers',
        type: 'link',
        categoryType: '1',
        per: 'categories',
        icon: Grid,
      },
      // {
      //   title: 'Occasion',
      //   type: 'link',
      //   categoryType: '2',
      //   per: 'categories',
      //   icon: Grid,
      // },

      {
        title: 'Brands',
        type: 'link',
        categoryType: '4',
        per: 'categories',
        icon: Grid,
      },
      // {
      //   title: 'Subscriptions',
      //   type: 'link',
      //   categoryType: '5',
      //   per: 'categories',
      //   icon: Grid,
      // },
      {
        title: 'Add-ons',
        type: 'link',
        categoryType: '6',
        per: 'categories',
        icon: Grid,
      },
      {
        title: 'Category Occasion',
        type: 'link',
        categoryType: '7',
        per: 'categories',
        icon: Grid,
      },
      {
        title: 'Product Type',
        type: 'link',
        categoryType: '8',
        per: 'categories',
        icon: Grid,
      },
      {
        title: 'Attributes',
        type: 'link',
        per: 'attributes',
        path: getAttributesPageUrl(),
      },
    ],
  },

  {
    title: 'Ticketing',
    icon: Tag,
    type: 'sub',
    active: false,
    per: 'ticketing',
    children: [
      {
        title: 'Configurations',
        type: 'sub',
        icon: 'fa-cog',
        children: [
          {
            path: getTopicsPageUrl(),
            title: 'Topics',
            type: 'link',
            icon: 'fa-book',
          },
        ],
      },
      {
        title: 'My Tickets',
        type: 'sub',
        icon: 'fa-user',
        children: [
          {
            path: getMyTicketsPage(),
            title: 'My Tickets',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getMyOpenedTicketsPage(),
            title: 'My Open Tickets',
            type: 'link',
            icon: 'fa-unlock',
          },
          {
            path: getMyClosedTicketsPage(),
            title: 'My Closed Tickets',
            type: 'link',
            icon: 'fa-lock',
          },
        ],
      },
      {
        title: 'Non-Tech Tickets',
        type: 'sub',
        icon: 'fa-globe',
        children: [
          {
            path: getOpsTicketsPageUrl(),
            title: 'Non-Tech Tickets',
            type: 'link',
            icon: 'fa-ticket',
          },
          {
            path: getOpenedTicketsPage(),
            title: 'Open Tickets',
            type: 'link',
            icon: 'fa-unlock',
          },
          {
            path: getClosedTicketsPage(),
            title: 'Closed Tickets',
            type: 'link',
            icon: 'fa-lock',
          },
        ],
      },

      {
        title: 'Tech Tickets',
        type: 'sub',
        icon: 'fa-laptop',
        per: 'tickets',
        action: 'tech-tickets',
        children: [
          {
            path: getTechTicketsPageUrl(),
            title: 'Tech Tickets',
            type: 'link',
            icon: 'fa-ticket',
          },
          {
            path: getOpenedTechTicketsPageUrl(),
            title: 'Tech Open Tickets',
            type: 'link',
            icon: 'fa-unlock',
          },
          {
            path: getClosedTechTicketsPageUrl(),
            title: 'Tech Closed Tickets',
            type: 'link',
            icon: 'fa-lock',
          },
        ],
      },
    ],
  },
  {
    title: 'Featured Occasions',
    icon: ThumbsUp,
    type: 'link',
    active: true,
    per: 'occasions',
    path: getFeaturedOccasionPageUrl(),
  },
  {
    title: 'Reports',
    icon: BarChart,
    type: 'sub',
    active: false,
    per: 'reports',
    children: [
      {
        title: 'Orders',
        type: 'sub',
        icon: 'fa-briefcase',
        per: 'reports',
        action: 'orders-reports',
        children: [
          {
            path: getSalesReportPageUrl(),
            title: 'Sales',
            type: 'link',
            icon: 'fa-dollar',
          },
          {
            path: getOrdersReportPageUrl(),
            title: 'Orders',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getFinancialOrdersPageUrl(),
            title: 'XD Orders',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getDailyTrackerOrdersPageUrl(),
            title: 'Daily Tracker DOD',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getOrderAmountDetailReportPageUrl(),
            title: 'Order Amount Details',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getOrderCancellationPageUrl(),
            title: 'Cancellation',
            type: 'link',
            icon: 'fa-ban',
          },
          {
            path: getSalesPerformanceReportPageUrl(),
            title: 'Sales Performance Report',
            type: 'link',
            icon: 'fa-percent',
          },
          {
            path: getRevenuesReportPageUrl(),
            title: 'Revenues report',
            type: 'link',
            icon: 'fa-percent',
          },
          {
            path: getDeferredRevenuesReportPageUrl(),
            title: 'Deferred Revenues report',
            type: 'link',
            icon: 'fa-percent',
          },
        ],
      },
      {
        title: 'Products',
        type: 'sub',
        icon: 'fa-shopping-cart',
        per: 'reports',
        action: 'products-reports',
        children: [
          {
            path: getProductBreakDown(),
            title: 'Product Breakdown',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getProductPriceBreakDown(),
            title: 'Price Breakdown',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getCategoryBreakDown(),
            title: 'Category Breakdown',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getProductTypeSales(),
            title: 'Product Type Sales',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getProductTypeAttribute(),
            title: 'Product Type Attribute',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getLiveProductsReport(),
            title: 'Live Products',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getCartProductsDiscounts(),
            title: 'Cart Products Discounts',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getProductTypeBreakDown(),
            title: 'Product Type Breakdown',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getProductCategoryPercentage(),
            title: 'Product Category Percentage',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getOrdersProductWisePage(),
            title: 'Product Wise',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getTopTenProductPage(),
            title: 'Top ten products',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
          {
            path: getOldProductPage(),
            title: 'Old products',
            type: 'link',
            icon: 'fa-shopping-cart',
          },
        ],
      },

      {
        title: 'Inventory',
        type: 'sub',
        icon: 'fa-archive',
        per: 'reports',
        action: 'inventory-reports',
        children: [
          {
            path: getInventorySupplierReportPageUrl(),
            title: 'Inventory Suppliers',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getInventoryStatementActualDeliveryReportPageUrl(),
            title: 'Inventory Statement Actual Delivery',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getInventoryBalanceReportPageUrl(),
            title: 'Inventory Balance',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getStatementsReportPageURL(),
            title: 'Statements',
            type: 'link',
            icon: 'fa-percent',
          },
          {
            path: getFinanceInventoryStatementsPageUrl(),
            title: 'Finance Inventory Statements',
            type: 'link',
            icon: 'fa-percent',
          },
          {
            path: getStatementsCancelledReportPageURL(),
            title: 'Statements (Cancelled Order)',
            type: 'link',
            icon: 'fa-percent',
          },
          {
            path: getStatementsDamagedReportPageURL(),
            title: 'Statements (Damaged)',
            type: 'link',
            icon: 'fa-percent',
          },
          {
            path: getInventoryReportPageURL(),
            title: 'Inventory Report',
            type: 'link',
            icon: 'fa-percent',
          },
        ],
      },

      {
        title: 'Designers',
        type: 'sub',
        icon: 'fa-star',
        per: 'reports',
        action: 'designers-reports',
        children: [
          {
            path: getGiftsDailySpendPageUrl(),
            title: 'Gifts Daily Spend',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getDesignerCommissionPageUrl(),
            title: 'Designers Daily Commission',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getDesignersDailyPerformancePageUrl(),
            title: 'Designers Daily Performance',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getDesignersProductPerformancePageUrl(),
            title: 'Designers Product Performance',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getDesignersReportPageUrl(),
            title: 'Designers',
            type: 'link',
            icon: 'fa-user',
          },
        ],
      },

      {
        title: 'Wallet',
        type: 'sub',
        icon: 'fa-briefcase',
        per: 'reports',
        action: 'wallet-reports',
        children: [
          {
            path: getWalletBalancesPageUrl(),
            title: 'Wallet Balances',
            type: 'link',
            icon: 'fa-user',
          },
          {
            path: getWalletTransactionsPageUrl(),
            title: 'Wallet Transactions',
            type: 'link',
            icon: 'fa-user',
          },
        ],
      },

      {
        title: 'Vouchers',
        type: 'sub',
        icon: 'fa-address-card',
        per: 'reports',
        action: 'vouchers-reports',
        children: [
          {
            path: getCouponDailySpendReportPageUrl(),
            title: 'All Coupon Daily Spend',
            type: 'link',
            icon: 'fa-user',
          },
          // {
          //   path: getVouchersReportPageUrl(),
          //   title: 'Vouchers',
          //   type: 'link',
          //   icon: 'fa-user',
          // },
        ],
      },

      {
        title: 'Delivery',
        type: 'sub',
        icon: 'fa-truck',
        per: 'reports',
        action: 'delivery-reports',
        children: [
          {
            path: getDriverPerformanceReportPageUrl(),
            title: 'Driver Performance',
            type: 'link',
            icon: 'fa-truck',
          },
          {
            path: getDeliveryPerformanceReportPageUrl(),
            title: 'Delivery Performance',
            type: 'link',
            icon: 'fa-truck',
          },
          {
            path: getDriverLogisticsReportPageUrl(),
            title: 'Drivers Logistics',
            type: 'link',
            icon: 'fa-circle-o',
          },
          {
            path: getDriverPerformanceDetailsReportPageUrl(),
            title: 'Drivers Performance Details',
            type: 'link',
            icon: 'fa-circle-o',
          },
        ],
      },
      {
        title: 'Corporate',
        isCustomIcon: true,
        icon: 'fa-building',
        type: 'sub',
        active: false,
        per: 'corporate',
        children: [
          {
            path: getCorporateReportPageUrl(),
            title: 'Corporate',
            type: 'link',
            per: 'corporate_report',
            icon: 'fa-building',
          },
          {
            path: getCustomerCorporateReportPageUrl(),
            title: 'Customer Corporate',
            type: 'link',
            per: 'customer_corporate',
            icon: 'fa-building',
          },
        ],
      },
      {
        title: 'Customer Loyality Points',
        type: 'link',
        icon: 'fa-user',
        per: 'customer_loyalty_points',
        path: getCustomerLoyalityPageUrl(),
      },

      {
        title: 'Registered Users',
        type: 'link',
        icon: 'fa-user',
        per: 'reports',
        path: getRegisteredUsersPageUrl(),
      },

      {
        title: 'Hours Breakdown',
        type: 'link',
        icon: 'fa-history',
        per: 'reports',
        path: getHoursBreakdownPageUrl(),
      },
      {
        title: 'Areas Breakdown',
        type: 'link',
        icon: 'fa-map-marker',
        per: 'reports',
        path: getAreasBreakdownPageUrl(),
      },

      {
        title: 'Brands',
        type: 'link',
        icon: 'fa-copyright',
        per: 'reports',
        path: getBrandsReportPageUrl(),
      },
      {
        title: 'Late Orders',
        type: 'link',
        per: 'late_orders',
        path: getLateOrdersPageUrl(),
        icon: 'fa-spinner',
      },
      {
        path: getFloristsPerformancePageUrl(),
        title: 'Florists Performance',
        per: 'reports',
        type: 'link',
        icon: 'fa-cut',
      },
    ],
  },
  {
    title: 'Invoices',
    icon: FileText,
    type: 'sub',
    active: false,
    per: 'invoices',
    children: [
      {
        title: 'Invoices',
        icon: 'fa-file',
        type: 'link',
        badgeType: 'primary',
        active: false,
        per: 'invoices',
        path: getInvoicesPageUrl(),
      },
      {
        title: 'Corporate Invoices',
        icon: 'fa-file',
        type: 'link',
        badgeType: 'primary',
        active: false,
        per: 'corporate_invoices',
        path: getCorporateInvoicesPageUrl(),
      },
      {
        title: 'Consolidated Invoices',
        icon: 'fa-file',
        type: 'link',
        badgeType: 'primary',
        active: false,
        per: 'consolidated_invoices',
        path: getConsolidatedInvoicesPageUrl(),
      },
      {
        title: 'Generated Cons Invoices',
        icon: 'fa-file',
        type: 'link',
        badgeType: 'primary',
        active: false,
        per: 'consolidated_invoices',
        path: getGeneratedInvoicesPageUrl(),
      },
    ],
  },

  {
    title: 'Subscription',
    icon: ThumbsUp,
    type: 'link',
    active: false,
    per: 'subscriptions',
    path: getSubscriptionsPage(),
  },
  {
    title: 'Banners',
    icon: Image,
    type: 'sub',
    active: false,
    per: 'banners',
    children: [
      {
        title: 'Web-Home',
        type: 'link',
        path: '/banners/0',
        per: 'banners',
        icon: 'fa-picture-o',
      },
      {
        title: 'App-Home',
        type: 'link',
        path: '/banners/1',
        per: 'banners',
        icon: 'fa-picture-o',
      },
      {
        title: 'App-Top-Home',
        type: 'link',
        path: '/banners/2',
        per: 'banners',
        icon: 'fa-picture-o',
      },
      {
        title: 'APP-Home-Center',
        type: 'link',
        path: '/banners/7',
        per: 'banners',
        icon: 'fa-picture-o',
      },
      {
        title: 'Web-Markting-Top',
        type: 'link',
        path: '/banners/3',
        per: 'banners',
        icon: 'fa-picture-o',
      },
      {
        title: 'Web-Markting-Down',
        type: 'link',
        path: '/banners/4',
        per: 'banners',
        icon: 'fa-picture-o',
      },
      {
        title: 'Web-Bundels',
        type: 'link',
        path: '/banners/5',
        per: 'banners',
        icon: 'fa-picture-o',
      },
      {
        title: 'Web-Two-colo',
        type: 'link',
        path: '/banners/6',
        per: 'banners',
        icon: 'fa-picture-o',
      },
      {
        title: 'Key Values',
        icon: 'fa-key',
        type: 'link',
        badgeType: 'primary',
        active: false,
        per: 'key_value',
        path: getKeyValuesPageUrl(),
      },
    ],
  },
  {
    title: 'Vouchers',
    icon: Percent,
    type: 'sub',
    active: false,
    per: 'vouchers',
    children: [
      {
        path: getVouchersListPageUrl(),
        title: 'List',
        type: 'link',
        per: 'vouchers',
        icon: 'fa-list',
      },
    ],
  },
  {
    title: 'Shorter link',
    icon: Link,
    type: 'link',
    badgeType: 'primary',
    active: false,
    per: 'shorter_link',
    path: getShorterLinkPageUrl(),
  },
  // {
  //   title: 'Florist Community',
  //   icon: Scissors,
  //   type: 'sub',
  //   active: false,
  //   per: 'florist_community',
  //   children: [
  //     {
  //       path: getFloristcommunityUnderReviewPageUrl(),
  //       title: 'Under review designs',
  //       type: 'link',
  //       per: 'florist_community',
  //       icon: 'fa-scissors',
  //     },
  //     {
  //       path: getFloristcommunityAcceptedPageUrl(),
  //       title: 'Accepted designs',
  //       type: 'link',
  //       per: 'florist_community',
  //       icon: 'fa-scissors',
  //     },
  //     {
  //       path: getFloristcommunityRejectedPageUrl(),
  //       title: 'Rejected designs',
  //       type: 'link',
  //       per: 'florist_community',
  //       icon: 'fa-scissors',
  //     },
  //     {
  //       path: getFloristcommunityDeletedPageUrl(),
  //       title: 'Deleted designs',
  //       type: 'link',
  //       per: 'florist_community',
  //       icon: 'fa-scissors',
  //     },
  //     {
  //       path: getFloristTransactionsPageUrl(),
  //       title: 'Transactions',
  //       type: 'link',
  //       per: 'florist_community',
  //       icon: 'fa-exchange',
  //     },
  //   ],
  // },

  {
    title: 'Celebrities',
    isCustomIcon: true,
    icon: 'fa-diamond',
    type: 'link',
    active: false,
    per: 'celebrities',
    path: getCelebritiesPageUrl(),
  },

  {
    title: 'Users',
    icon: Users,
    type: 'sub',
    active: false,
    per: 'users',
    children: [
      {
        path: getAdminUsersUrl(),
        title: 'Users',
        type: 'link',
        per: 'admin_users',
        icon: 'fa-user',
      },
      {
        path: getPickersPageUrl(),
        title: 'Pickers',
        type: 'link',
        per: 'admin_pickers',
        icon: 'fa-user',
      },
      {
        path: getPackersPageUrl(),
        title: 'Packers',
        type: 'link',
        per: 'admin_pickers',
        icon: 'fa-user',
      },
      {
        path: getFloristsPageUrl(),
        title: 'Florists',
        type: 'link',
        per: 'admin_florists',
        icon: 'fa-user',
      },
      {
        path: getUserDefaultAddressesPageUrl(),
        title: 'Default Addresses',
        type: 'link',
        per: 'users_addresses',
        icon: 'fa-user',
      },
      {
        path: getUsersActivityPageUrl(),
        title: 'Users Activity',
        type: 'link',
        per: 'users_activity',
        icon: 'fa-user',
      },
    ],
  },
  {
    title: 'Administration',
    icon: Settings,
    type: 'sub',
    active: false,
    per: 'administration',
    children: [
      {
        path: getAdminPagesUrl(),
        title: 'Pages',
        type: 'link',
        per: 'admin_pages',
        icon: 'fa-book',
      },
      {
        path: getAdminGroupsUrl(),
        title: 'Groups',
        type: 'link',
        per: 'admin_groups',
        icon: 'fa-users',
      },
      {
        path: getAdminMessageUrl(),
        title: 'Messages',
        type: 'link',
        per: 'admin_groups',
        icon: 'fa-envelope',
      },
      {
        path: getAdminNotificationsMessagesUrl(),
        title: 'Notifications Messages',
        type: 'link',
        per: 'admin_groups',
        icon: 'fa-bell',
      },
    ],
  },
  {
    title: 'Seasonal Preparations',
    isCustomIcon: true,
    icon: 'fa-birthday-cake',
    type: 'link',
    active: false,
    per: 'seasonal_prep',
    path: getSeasonalPrepPageUrl(),
  },

  {
    title: 'Occasions',
    icon: Gift,
    type: 'link',
    badgeType: 'primary',
    active: false,
    per: 'occasions',
    path: getOccasionsPageUrl(),
  },
  {
    title: 'User Feedback',
    type: 'link',
    icon: Users,
    per: 'user_feedback',
    path: getUserFeedbackPageUrl(),
  },
];
