import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CardBody, CardHeader } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { getOldOrderNotifications } from 'store/notifications/actions/NotificationsActions';
// selector
import { getOldOrderNotificationList } from 'store/notifications/selectors/NotificationsSelectors';

const OrderNotificationsBox = ({ orderId }) => {
  const orderOldNotifications = useSelector((state) => getOldOrderNotificationList({ state }));
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async () => {
    setIsLoaderOpen(true);
    await dispatch(getOldOrderNotifications(orderId));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   return () => {
  //     dispatch(unSubscripeOrderNotifications(orderId));
  //   };
  // }, []);

  return (
    <div className="row card order-notification-wrapper">
      <CardHeader className="card-header-border">
        <h5>
          Notifications <i className="fa fa-bell"></i>
        </h5>
      </CardHeader>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <Fragment>
          {orderOldNotifications.length > 0 && (
            <CardBody className="recent-notification">
              {orderOldNotifications.map((item, i) => (
                <div key={i} className="media">
                  {item.createdAt ? (
                    <h6>{`${item.createdAt.slice(0, 10)}@${item.createdAt.slice(11, 16)} `}</h6>
                  ) : (
                    ''
                  )}
                  <div className="media-body">
                    <span>{item.details}</span>
                    <p className="f-12">{item.createdByName}</p>
                  </div>
                </div>
              ))}
            </CardBody>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default OrderNotificationsBox;
