import React from 'react';
import SectionLoader from 'components/common/SectionLoader';
const EditBanner = React.lazy(() => import('components/banners/EditBanner'));

const EditBannerPage = (props) => {
  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <EditBanner />
      </React.Suspense>
    </div>
  );
};

export default EditBannerPage;
