import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class CelebritiesService {
  static getCelebritiesData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCelebrities, ...extraParams),
      params: data,
    });
  }

  static getCelebrityById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCelebrityById, ...extraParams),
    });
  }

  static editCelebrity(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getCelebrityById, ...extraParams),
      data,
    });
  }

  static deleteCelebrity(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateCelebrity, ...extraParams),
    });
  }

  static addCelebrity(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getCelebrities, ...extraParams),
      data,
    });
  }
}

export default CelebritiesService;
