import React from 'react';
import CelebrityForm from 'components/celebrities/CelebrityForm';
import PageTitle from 'components/shared/PageTitle';

const AddCelebrityPage = (props) => {
  return (
    <>
      <PageTitle title={'Add New Celebrity'} />
      <div className="card">
        <CelebrityForm selectedCelebrity={{}} type={'add'} />
      </div>
    </>
  );
};

export default AddCelebrityPage;
