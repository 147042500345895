import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { dateFormatDDMM, formatRecipient, isoFormat } from 'constant/Helpers';
import { orderTypes } from 'constant/Enums';
import BulkCreateOrderService from 'services/BulkCreateOrderService';
import { toast } from 'react-toastify';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ConfirmationModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  dataTable,
  selectedProduct,
  selectedUser,
  selectedTimeSlot,
  selectedDate,
  orderType,
  salesOfficer,
  salesOfficerList,
  timeSlotsData,
  clearAfterSuccess,
}) => {
  const [progressChunk, setPrgressChunk] = useState(0);
  const [loading, setLoading] = useState(false);

  const createOrder = async () => {
    const failedMsg = [];
    let haveSuccessChunck = false;
    const chunkSize = 10;
    let chunkProg = 0;
    let lastInd = 1;
    const chunks = dataTable
      .map((dT, ind) => {
        if (ind !== 0 && ind % chunkSize === 0) {
          lastInd = ind + 1;
          return dataTable.slice(ind - chunkSize + 1, ind + 1);
        } else {
          if (ind === dataTable.length - 1 && lastInd !== ind) {
            return dataTable.slice(lastInd, ind + 1);
          } else {
            return null;
          }
        }
      })
      .filter((dT) => dT);
    setLoading(true);
    for (const [index, c] of chunks.entries()) {
      const data = {
        RecipientDetailsPayload: formatRecipient(c),
        ProductId: selectedProduct.id,
        SenderId: selectedUser.userId,
        DeliveryDate: isoFormat(selectedDate),
        DeliveryTime: selectedTimeSlot,
        SalesPerson: salesOfficer,
        OrderType: orderType,
      };
      let count = 0;
      const maxTries = 2;
      let keepTrying = true;
      while (keepTrying) {
        try {
          await BulkCreateOrderService.createBulkProduct(data);
          chunkProg += c.length;
          setPrgressChunk(chunkProg);
          keepTrying = false;
          haveSuccessChunck = true;
          if (index + 1 === chunks.length) {
            setLoading(false);
            setShowConfirmationModal(false);
            clearAfterSuccess(failedMsg, haveSuccessChunck);
            window.scrollTo(0, 0);
          }
        } catch (e) {
          errorLogger('ConfirmationModal:createOrder', e);
          if (++count === maxTries) {
            keepTrying = false;
            const failMessage = `Data from line ${chunkProg + 2} to line ${
              chunkProg + c.length + 1
            } has failed after 2 tries!`;
            toast.error(failMessage);
            failedMsg.push(failMessage);
            chunkProg += c.length;
            setPrgressChunk(chunkProg);
            if (index + 1 === chunks.length) {
              setLoading(false);
              setShowConfirmationModal(false);
              clearAfterSuccess(failedMsg, haveSuccessChunck);
              window.scrollTo(0, 0);
            }
          }
        }
      }
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={showConfirmationModal}
        toggle={() => setShowConfirmationModal((prev) => !prev)}
        className="bulk-create-order-modal"
      >
        <ModalHeader>Confirm Order</ModalHeader>
        <ModalBody>
          {loading && (
            <div className="chunk-loader">
              <div className="chunk-loader-container">
                <div className="whirly-loader"></div>
              </div>
              <p>
                {progressChunk}/{dataTable.length - 1}
              </p>
              <div className="note-bg-container">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                <p>Please don&apos;t close or refresh this window until the process is finished.</p>
              </div>
            </div>
          )}
          <div className={loading ? 'chunk-loader-bg bulk-orders-list' : 'bulk-orders-list'}>
            <table className="w-100">
              <thead
                className="align-center"
                style={{ borderBottom: '1px solid black', textTransform: 'capitalize' }}
              >
                <tr>
                  {dataTable[0].map((head, headInd) => {
                    return (
                      <th style={{ height: 70 }} key={headInd}>
                        {head}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            </table>
            <div className="confirmation-table-scroll">
              <table className="m-b-0 w-100">
                <tbody className="align-center">
                  {dataTable.map((row, rowInd) => {
                    if (rowInd !== 0) {
                      return (
                        <tr style={{ height: 40 }} key={rowInd}>
                          {row.map((cell, cellInd) => {
                            return (
                              <td key={cellInd}>
                                {cellInd === row.length - 1 ? (
                                  <a
                                    className="primary-color text-underline"
                                    href={cell}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {cell}
                                  </a>
                                ) : (
                                  cell
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    } else {
                      return <tr key={rowInd}></tr>;
                    }
                  })}
                </tbody>
              </table>
            </div>
            <div className="m-b-30">
              Selected Product:
              <a
                href={`/product/editProduct/${selectedProduct.id}/0`}
                target="_blank"
                rel="noreferrer"
                className="m-l-10 primary-color bold-title"
              >
                {selectedProduct.name} {selectedProduct.sku}
              </a>
            </div>
            <div className="m-b-30">
              Selected Sender:
              <a
                href={`/admin/users/${selectedUser.userId}`}
                target="_blank"
                rel="noreferrer"
                className="m-l-10 primary-color bold-title"
              >
                {selectedUser.fName} {selectedUser.lName}
              </a>
            </div>
            <p>Selected Date: {dateFormatDDMM(selectedDate)}</p>
            <p>
              Selected Slot: {timeSlotsData.filter((td) => td.id === selectedTimeSlot)[0]?.name}
            </p>
            <p>Selected Order Type: {orderTypes.filter((ot) => ot.value === orderType)[0]?.name}</p>
            <p>
              Selected Sales Officer:{' '}
              {salesOfficerList.filter((so) => so.id === salesOfficer)[0]?.name}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={createOrder} color="primary" disabled={loading}>
            Confirm
          </Button>
          <Button color="light" onClick={() => setShowConfirmationModal((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
