import React from 'react';
import TrackTripBtn from './TrackTripBtn';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
// material UI
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Toolbar as MuiToolbar } from '@mui/material';

const TripDriversDataTable = ({ driversList }) => {
  const toolbarOptions = ['Search'];

  return (
    <Paper className="mui-table-search">
      <MuiToolbar>
        <Typography variant="h6">Drivers</Typography>
      </MuiToolbar>
      <div className="quick-actions-table mui-table-styling">
        <div className="control-pane">
          <div className="control-section">
            <GridComponent
              dataSource={driversList}
              allowTextWrap={true}
              allowSorting={true}
              allowSelection={false}
              enableHover={false}
              toolbar={toolbarOptions}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="name"
                  headerText="Driver"
                  headerTextAlign="Left"
                  width={50}
                />
                <ColumnDirective
                  field="phone"
                  headerText="Phone"
                  headerTextAlign="Left"
                  width={50}
                />
                <ColumnDirective
                  field="trip"
                  headerText="Trip"
                  headerTextAlign="Left"
                  template={(rowData) => (
                    <TrackTripBtn tripId={rowData.trip} driverId={rowData.driverId} />
                  )}
                  width={50}
                  customExcelTemplate={(rowData) => rowData.trip}
                />
              </ColumnsDirective>
              <Inject services={[Toolbar, Sort]} />
            </GridComponent>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default TripDriversDataTable;
