import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
import { errorLogger } from 'datadog/DDUtils';
// constants
import { handleImageFile } from 'constant/Helpers';
// actions
import { setInventoryCareDetailsById } from 'store/inventory/InventoryActions';
// selectors
import { getInventoryDetailsById } from 'store/inventory/InventorySelectors';
// api
import InventoryService from 'services/InventoryService';

const InventoryCareSection = ({ invetoryId }) => {
  const inventoryCareById = useSelector((state) => getInventoryDetailsById({ state }));
  const [disableBtn, setDisableBtn] = useState(false);
  const [nameAr, setNameAr] = useState(null);
  const [nameEn, setNameEn] = useState(null);
  const [descAr, setDescAr] = useState(null);
  const [descEn, setDescEn] = useState(null);
  const [howToCareEn, setHowToCareEn] = useState(null);
  const [howToCareAr, setHowToCareAr] = useState(null);
  const [fullImg, setFullImg] = useState(null);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(inventoryCareById).length > 0) {
      setNameAr(inventoryCareById.nameAr ? inventoryCareById.nameAr : null);
      setNameEn(inventoryCareById.nameEn ? inventoryCareById.nameEn : null);
      setDescAr(inventoryCareById.descriptionAr ? inventoryCareById.descriptionAr : null);
      setDescEn(inventoryCareById.descriptionEn ? inventoryCareById.descriptionEn : null);
      setHowToCareEn(inventoryCareById.howToCareEn ? inventoryCareById.howToCareEn : null);
      setHowToCareAr(inventoryCareById.howToCareAr ? inventoryCareById.howToCareAr : null);
      setFullImg(inventoryCareById.fullImage);
    }
  }, [inventoryCareById]);

  useEffect(() => {
    dispatch(setInventoryCareDetailsById(invetoryId));
  }, []);

  const getFormData = () => {
    const data = {
      inventoryId: invetoryId,
      nameAr,
      nameEn,
      descriptionAr: descAr,
      descriptionEn: descEn,
      howToCareAr,
      howToCareEn,
      image: fullImg,
    };
    const formData = new FormData();
    for (const key in data) {
      if (data[key] !== null) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  };

  const updateInventoryCare = async () => {
    const formData = getFormData();
    setDisableBtn(true);
    setIsLoaderOpen(true);
    try {
      const res = await InventoryService.updateInventoryCare(formData, invetoryId);
      toast.success(res?.data?.message ?? 'Updated Successfully');
      setIsLoaderOpen(false);
      setDisableBtn(false);
    } catch (err) {
      errorLogger('InventoryCareSection:updateInventoryCare', err);
      setDisableBtn(false);
      setIsLoaderOpen(false);
      toast.error(err?.response?.data?.message ?? 'Failed to update inventory care');
    }
  };

  return (
    <>
      <PageTitle title="Inventory | Edit Inventory" />
      <div className="form-group col-12 card">
        <div className="card-header p-12 sz-20">Edit Inventory Care</div>
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <form className="card-body row">
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="inventory-enName">
                Name English
              </label>
              <input
                id="inventory-enName"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="inventory-arName">
                Name Arabic
              </label>
              <input
                id="inventory-arName"
                value={nameAr}
                onChange={(e) => setNameAr(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="inventory-enDesc">
                Description English
              </label>
              <textarea
                id="inventory-enDesc"
                value={descEn}
                onChange={(e) => setDescEn(e.target.value)}
                className="form-control"
                type="text"
              ></textarea>
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="inventory-arDesc">
                Description Arabic
              </label>
              <textarea
                id="inventory-arDesc"
                value={descAr}
                onChange={(e) => setDescAr(e.target.value)}
                className="form-control"
                type="text"
              ></textarea>
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="inventory-enCare">
                how to care (En)
              </label>
              <textarea
                id="inventory-enCare"
                value={howToCareEn}
                onChange={(e) => setHowToCareEn(e.target.value)}
                className="form-control"
                type="text"
              ></textarea>
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="inventory-arCare">
                how to care (Ar)
              </label>
              <textarea
                id="inventory-arCare"
                value={howToCareAr}
                onChange={(e) => setHowToCareAr(e.target.value)}
                className="form-control"
                type="text"
              ></textarea>
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="inventory-image">
                Image
              </label>
              <div
                id="inventory-image"
                className="file-img"
                style={{
                  backgroundImage: `url(${handleImageFile(fullImg)})`,
                  height: fullImg === '' ? 0 : 150,
                }}
              ></div>
              <div className="file-input">
                <input
                  type="file"
                  className="file"
                  id="fullImg"
                  onChange={(e) => setFullImg(e.target.files[0])}
                />
                <label htmlFor="fullImg">
                  <p className="file-name">Select file</p>
                </label>
              </div>
            </div>
          </form>
        )}
        <div className="card-footer">
          <button
            disabled={disableBtn || !nameEn || !descEn || !fullImg}
            onClick={updateInventoryCare}
            className="btn btn-primary"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default InventoryCareSection;
