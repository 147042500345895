// modals
export const getIsNoteModalOpen = ({ state }) => state.quickActionsTable.isNoteModalOpen;

export const getIsNoteForTeamModalOpen = ({ state }) =>
  state.quickActionsTable.isNoteForTeamModalOpen;

export const getIsPriorityModalOpen = ({ state }) => state.quickActionsTable.isPriorityModalOpen;

export const getPriorityId = ({ state }) => state.quickActionsTable.priorityId;

export const getIsCardModalOpen = ({ state }) => state.quickActionsTable.isCardModalOpen;

export const getIsChangeStatusModalOpen = ({ state }) =>
  state.quickActionsTable.isChangeStatusModalOpen;

export const getIsActionBoxModalOpen = ({ state }) => state.quickActionsTable.isActionBoxModalOpen;

export const getActionAddressData = ({ state }) => state.quickActionsTable.addressDetails;

export const getIsCartModalOpen = ({ state }) => state.quickActionsTable.isCartModalOpen;

export const getQuickActionsTableRef = ({ state }) => state.quickActionsTable.quickActionsTableRef;

// status
export const getEditStatusNewValue = ({ state }) => state.quickActionsTable.editStatusValue;
export const getOrderId = ({ state }) => state.quickActionsTable.orderId;
export const getPaymentDate = ({ state }) => state.quickActionsTable.paymentDate;
export const getOrderRowNote = ({ state }) => state.quickActionsTable.rowNote;
export const getNoteForTeam = ({ state }) => state.quickActionsTable.noteForTeam;
export const getOrderCstep = ({ state }) => state.quickActionsTable.orderCstep;

// table and filter api
export const getTableData = ({ state }) => state.quickActionsTable.tableData;
export const getTimeSlotData = ({ state }) => state.quickActionsTable.timeSlot;
export const getUserAgentData = ({ state }) => state.quickActionsTable.userAgent;
export const getFilterAreas = ({ state }) => state.quickActionsTable.areas;

export const getOrderStatus = ({ state }) => state.quickActionsTable.orderStatus;

export const getOrderprodLine = ({ state }) => state.quickActionsTable.orderProdLine;

export const getupdatedCstep = ({ state }) => state.quickActionsTable.updatedCstep;

export const getLocationLinkIds = ({ state }) => state.quickActionsTable.locationLinkIds;

export const getSelectedOrdersId = ({ state }) => state.quickActionsTable.selectedOrdersIds;

// view order modals selectors
export const getIsDeleteCardModalOpen = ({ state }) =>
  state.quickActionsTable.isDeleteCardModalOpen;
export const getIsEditCardModalOpen = ({ state }) => state.quickActionsTable.isEditCardModalOpen;
export const getIsPreviewCardModalOpen = ({ state }) =>
  state.quickActionsTable.isPreviewCardModalOpen;
export const getCardData = ({ state }) => state.quickActionsTable.cardData;
export const getIsAddCallModalOpen = ({ state }) => state.quickActionsTable.isAddCallModalOpen;
export const getIsAddressModalOpen = ({ state }) => state.quickActionsTable.isAddressModalOpen;
export const getIsSupportTicketModalOpen = ({ state }) =>
  state.quickActionsTable.isSupportTicketModalOpen;
export const getIsMapLinkModalOpen = ({ state }) => state.quickActionsTable.isMapLinkModalOpen;

// quick actions data table
export const getQuickActionsTableParams = ({ state }) =>
  state.quickActionsTable.quickActionsTableParams;

export const getQuickActionsIsPrintedBtnDisabled = ({ state }) =>
  state.quickActionsTable.isPrintedBtnDisabled;

export const getQuickActionsIsShareDetailsBtnDisabled = ({ state }) =>
  state.quickActionsTable.isShareDetailsBtnDisabled;

export const getCurrentQuickActionsTablePage = ({ state }) =>
  state.quickActionsTable.currentQuickActionsTablePage;

export const getQuickActionsPageSize = ({ state }) => {
  const tableData = getTableData({ state });

  return tableData.pageSize ? tableData.pageSize : '15';
};

export const getQuickActionsTotalData = ({ state }) => {
  const tableData = getTableData({ state });

  return tableData.rowCount ? tableData.rowCount : '15';
};

// dupliacted and replaced
export const getDupliactedAndReplacedOrdersData = ({ state }) =>
  state.quickActionsTable.duplicatedReplacedOrdersData;

// zones filter
export const getZonesFilterData = ({ state }) => state.quickActionsTable.filterZones;
