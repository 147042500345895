import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import CustomSelect from 'components/shared/CustomSelect';

const CategoriesFilter = ({ inputValues, setInputValues, handleSubmit }) => {
  const [type, setType] = useState('-1');
  const handleTypeChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.type = e.itemData.id;
    setType(e.itemData.id);
    setInputValues(updatedParams);
  };
  useEffect(() => {
    setType(inputValues.type);
  }, [inputValues.type]);

  return (
    <div className="card">
      <div className="card-header p-18">
        <h4>Filter</h4>
      </div>
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="form-group filter-select">
                <label className="bold-title" htmlFor="category-type">
                  Category Type
                </label>
                <CustomSelect
                  id="category-type"
                  listData={[
                    { id: '-1', name: 'All' },
                    { id: '0', name: 'Main Categories' },
                    { id: '3', name: 'Promotions' },
                    { id: '1', name: 'Designers' },
                    { id: '4', name: 'Brands' },
                    // { id: '2', name: 'Occasion' },
                    // {id: "5", name: "Subscriptions"},
                    { id: '6', name: 'Add-ons' },
                    { id: '7', name: 'Category Occasion' },
                    { id: '8', name: 'Product Type' },
                  ]}
                  handleChange={handleTypeChange}
                  placeholder="Select a category type"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={type}
                />
              </div>
            </div>
            <div className="col-12 offset-md-5 col-md-2 filter-btn-div">
              <button
                onClick={() => handleSubmit(inputValues)}
                className="btn btn-primary mr-1"
                type="button"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CategoriesFilter;
