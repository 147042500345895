import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ActionBox from 'components/orders/viewOrder/ActionBox';
// actions
import { setActionBoxModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';

const ActionBoxModal = ({ orderId, paymentDate, isActionBoxModalOpen }) => {
  const dispatch = useDispatch();
  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isActionBoxModalOpen} toggle={() => dispatch(setActionBoxModalOpen())}>
        <ModalBody>
          <ActionBox orderId={orderId} paymentDate={paymentDate} />
        </ModalBody>
        <ModalFooter className="center-modal-footer">
          <Button color="light" onClick={() => dispatch(setActionBoxModalOpen())}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ActionBoxModal;
