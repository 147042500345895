import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class HoursAreaReportService {
  static getHoursBreakdownReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getHoursBreakdownReport, ...extraParams),
      params,
    });
  }

  static getAreasBreakdownReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getAreasBreakdownReport, ...extraParams),
      params,
    });
  }
}
export default HoursAreaReportService;
