import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
// components
import KeyValuesDataGrid from 'components/keyValues/KeyValuesDataGrid';
import AddNewKeyValueModal from 'components/keyValues/AddNewKeyValueModal';
import KeyValueFilter from 'components/keyValues/KeyValueFilter';
import KeyValuesPagination from './KeyValuesPagination';
import PageTitle from 'components/shared/PageTitle';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
// selectors
import { getKeyValueList } from 'store/keyValues/keyValueSelectors';
// actions
import { setKeyValueList, setKeyValuesStatus } from 'store/keyValues/keyValueActions';

const KeyValuesPage = () => {
  const keyValueList = useSelector((state) => getKeyValueList({ state }));
  const [isKeyValueModalOpen, setIsKeyValueModalOpen] = useState(false);
  const [clickedKeyValue, setClickedKeyValue] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();

  const fetchKeyValueList = async () => {
    dispatch(setKeyValueList({ page: 1 }));
  };

  const handleStatus = (isActive) => {
    if (isActive === 'true') {
      return 1;
    } else {
      if (isActive === 'false') {
        return 2;
      } else {
        return 0;
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      const newStatus = handleStatus(queryObj.isactive);
      const newPage = queryObj.page;
      let params = { page: newPage };
      if (newStatus !== 0) {
        params = { ...params, isactive: newStatus === 1 };
      }
      dispatch(setKeyValueList(params));
      dispatch(setKeyValuesStatus(newStatus));
    } else {
      fetchKeyValueList();
    }
  }, []);

  const handleClick = (obj) => {
    setClickedKeyValue(obj);
    setIsKeyValueModalOpen((prev) => !prev);
  };

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Key values" />
      {isKeyValueModalOpen && (
        <AddNewKeyValueModal
          isKeyValueModalOpen={isKeyValueModalOpen}
          setIsKeyValueModalOpen={setIsKeyValueModalOpen}
          clickedKeyValue={clickedKeyValue}
          setClickedKeyValue={setClickedKeyValue}
        />
      )}
      <div className="card">
        <div className="card-body row">
          <button
            className="btn btn-success add-key-button"
            onClick={() => setIsKeyValueModalOpen(true)}
          >
            <i className="fa fa-plus"></i> Add New Key Values
          </button>
        </div>
      </div>
      <KeyValueFilter />
      <KeyValuesDataGrid data={keyValueList} handleClick={handleClick} />
      <KeyValuesPagination />
    </div>
  );
};

export default KeyValuesPage;
