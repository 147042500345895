import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomSelect from 'components/shared/CustomSelect';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { toast } from 'react-toastify';
// Service
import ProductService from 'services/ProductsService';
// constants
import { handleImageFile } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
// selectors
import { getSelectedProductSize } from 'store/products/ProductsSelectors';
// actions
import { getProductSizes } from 'store/products/ProductsActions';

const AddSizeModal = ({
  isAddAttrModalOpen,
  setIsAddAttrModalOpen,
  productId,
  allSizesList,
  sizesList,
  type,
}) => {
  const [size, setSize] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [cost, setCost] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [margin, setMargin] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [image, setImage] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const opsId = getFromLocalStorage(localStorageKeys.OPS_ID);
  const currency = getFromLocalStorage(localStorageKeys.CURRENCY);
  const selectedData = useSelector((state) => getSelectedProductSize({ state }));
  const dispatch = useDispatch();

  const handleProductSizeChange = (e) => {
    setSize(e.itemData.id);
  };

  const checkValid = (height, width) => {
    if (height === 0 || width === 0) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  const handleSubmit = async () => {
    const body = {
      CurrencyISO: '',
      Price: price,
      DiscountPrice: discount,
      Cost: cost,
      Margin: margin,
      IsActive: isActive,
      Height: height,
      Width: width,
      Size: size,
      OpsId: type === 'edit' ? selectedData.opsId : opsId,
      Image: image,
    };

    const formData = new FormData();

    for (const key in body) {
      if (body[key] !== null) {
        formData.append(key, body[key]);
      }
    }
    if (type === 'edit') {
      try {
        const res = await ProductService.editProductSize(formData, selectedData.id);
        toast.success(res.data.message);
        setIsAddAttrModalOpen(false);
        dispatch(getProductSizes(productId));
      } catch (e) {
        toast.error('Failed to edit size');
      }
    } else {
      try {
        const res = await ProductService.addProductSize(formData, productId);
        toast.success(res.data.message);
        setIsAddAttrModalOpen(false);
        dispatch(getProductSizes(productId));
      } catch (e) {
        toast.error('Failed to add size');
      }
    }
  };

  useEffect(() => {
    checkValid(height, width);
  }, [height, width]);

  useEffect(() => {
    if (type === 'edit') {
      setSize(selectedData.size.productSize);
      setHeight(selectedData.height ?? 0);
      setWidth(selectedData.width ?? 0);
      setCost(selectedData.cost ?? 0);
      setPrice(selectedData.price ?? 0);
      setDiscount(selectedData.discountPrice ?? 0);
      setMargin(selectedData.margin ?? 0);
      setIsActive(selectedData.isActive ?? false);
      setImage(selectedData.fullImage ?? '');
    }
  }, [selectedData]);

  return (
    <Modal isOpen={isAddAttrModalOpen} toggle={() => setIsAddAttrModalOpen((prev) => !prev)}>
      <ModalHeader toggle={() => setIsAddAttrModalOpen((prev) => !prev)}>Add Size</ModalHeader>
      <ModalBody className="h-700p">
        <div className="form-group col-12 m-t-30">
          <form className="row">
            <div
              className={`form-group col-12 filter-select category-select ${
                type === 'edit' ? 'disabled-select' : ''
              }`}
            >
              <label className="bold-title" htmlFor="prod-size">
                Product Size
              </label>
              <CustomSelect
                id="prod-size"
                listData={type === 'edit' ? allSizesList : sizesList}
                handleChange={handleProductSizeChange}
                placeholder="Select product size"
                textPropName="name"
                valuePropName="id"
                selectedValue={size}
                disabled={type === 'edit'}
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-size-height">
                Height
              </label>
              <input
                id="prod-size-height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="form-control"
                type="number"
                step="0.001"
                min="0"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-size-width">
                Width
              </label>
              <input
                id="prod-size-width"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="form-control"
                type="number"
                step="0.001"
                min="0"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-size-cost">
                Cost
              </label>
              <input
                id="prod-size-cost"
                value={cost}
                onChange={(e) => setCost(e.target.value)}
                className="form-control"
                type="number"
                step="0.001"
                min="0"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-size-price">
                Price
              </label>
              <input
                id="prod-size-price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="form-control"
                type="number"
                step="0.001"
                min="0"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-size-discount">
                Discount Price
              </label>
              <input
                id="prod-size-discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                className="form-control"
                type="number"
                step="0.001"
                min="0"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-size-margin">
                Margin
              </label>
              <input
                id="prod-size-margin"
                value={margin}
                onChange={(e) => setMargin(e.target.value)}
                className="form-control"
                type="number"
                step="0.001"
                min="0"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-size-image">
                Image
              </label>
              <div
                id="prod-size-image"
                className="file-img"
                style={{
                  backgroundImage: `url(${handleImageFile(image)})`,
                  height: image === '' ? 0 : 150,
                }}
              ></div>
              <div className="file-input">
                <input
                  type="file"
                  className="file"
                  id="image"
                  onChange={(e) => setImage(e.target.files[0])}
                />
                <label htmlFor="image">
                  <p className="file-name m-0">Select file</p>
                </label>
              </div>
            </div>
            <div className="form-group col-12">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive((prev) => !prev)}
              />
              <span>Active</span>
            </div>
          </form>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => handleSubmit()} color="primary" disabled={disableBtn}>
          Save
        </Button>
        <Button color="light" onClick={() => setIsAddAttrModalOpen((prev) => !prev)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddSizeModal;
