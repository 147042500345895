import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setViewOrderData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';
// constant
import { prevAddressHeader } from 'constant/Enums';

const RecipientAddressListModal = ({
  countryID,
  phone,
  isRxAddressModalOpen,
  setIsRxAddressModalOpen,
  orderId,
}) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [addressesList, setAddressesList] = useState('');
  const dispatch = useDispatch();

  const fetchData = async (countryID, phone) => {
    const params = {
      countryID,
      phone,
    };
    try {
      const res = await ViewOrderService.getRecipientListAddress(params);
      setAddressesList(res.data.data);
    } catch (e) {
      errorLogger('RecipientAddressListModal:fetchData', e);
      toast.error('Failed to get recipient addresses!');
    }
  };

  useEffect(() => {
    fetchData(countryID, phone);
  }, []);

  const handleUpdateAddress = async (orderId, id, address) => {
    setIsLoaderOpen(true);
    try {
      await ViewOrderService.updateRecipientListAddress(orderId, id);
      setTimeout(() => {
        dispatch(setViewOrderData(orderId));
        setIsLoaderOpen(false);
        toast.success('Address updated successfully.');
        setIsRxAddressModalOpen((prev) => !prev);
      }, 1500);
    } catch (err) {
      errorLogger('RecipientAddressListModal:handleUpdateAddress', err);
      toast.error('Failed to update address!');
      setIsLoaderOpen(false);
      setIsRxAddressModalOpen((prev) => !prev);
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isRxAddressModalOpen}
        toggle={() => setIsRxAddressModalOpen((prev) => !prev)}
        className="recipient-address-modal-wrapper"
      >
        <ModalHeader>Addresses List</ModalHeader>
        <ModalBody>
          <div>
            {isLoaderOpen ? (
              <SectionLoader height="300px" />
            ) : (
              <div className="table-responsive shopping-table text-center">
                <table className="table table-bordernone">
                  <thead>
                    <tr>
                      {prevAddressHeader.map((col, i) => (
                        <th key={i} scope="col">
                          {col}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {addressesList !== '' && (
                    <tbody>
                      {addressesList.map((item, i) => (
                        <tr className="search-table-row" key={i}>
                          <td
                            onClick={() => {
                              window.open(`/order/${item.orderId}`, '_blank');
                            }}
                            className="order-id"
                          >
                            {item.orderId}
                          </td>
                          <td>{item.recipientName}</td>
                          <td>{item.recipientPhone}</td>
                          <td>{item.name}</td>
                          <td>{item.addressLineOne}</td>
                          <td>
                            <button
                              onClick={() =>
                                handleUpdateAddress(orderId, item.id, item.addressLineOne)
                              }
                              className="btn btn-primary"
                            >
                              Select
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 recipient-modal-footer">
            <Button onClick={() => setIsRxAddressModalOpen((prev) => !prev)} color="light">
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RecipientAddressListModal;
