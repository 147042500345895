import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// actions
import { setInventoryData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
import { toast } from 'react-toastify';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const InventoryModal = ({
  isInventoryModalOpen,
  setIsInventoryModalOpen,
  inventoryQty,
  setInventoryQty,
  inventoryBalance,
  inventoryItemId,
  orderId,
}) => {
  const dispatch = useDispatch();

  const handleEditInventory = async () => {
    const dataBody = {
      qty: inventoryQty,
    };

    if (inventoryQty <= inventoryBalance) {
      try {
        await ViewOrderService.editInventory(dataBody, inventoryItemId);
        dispatch(setInventoryData(orderId));
        setIsInventoryModalOpen((prev) => !prev);
      } catch (err) {
        errorLogger('InventoryModal:handleEditInventory', err);
      }
    } else {
      toast.error("Can't edit quantity");
    }
  };
  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isInventoryModalOpen} toggle={() => setIsInventoryModalOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsInventoryModalOpen((prev) => !prev)}>
          Edit Quantity
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Available Qty
              </label>
              <input
                disabled={true}
                className="form-control"
                id="message-text"
                value={inventoryBalance}
              ></input>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Qty
              </label>
              <input
                onChange={(e) => setInventoryQty(e.target.value)}
                value={inventoryQty}
                className="form-control"
                id="message-text"
              ></input>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleEditInventory} color="primary">
            Save
          </Button>
          <Button color="light" onClick={() => setIsInventoryModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InventoryModal;
