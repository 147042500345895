import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// Selectors
import { getMasterInventoryChildrenData } from 'store/masterInventory/MasterInventorySelectors';
// Components
import SubInventoryDataGrid from './SubInventoryDataGrid';

const SubInventories = () => {
  const subInventories = useSelector((state) => getMasterInventoryChildrenData({ state }));

  return (
    <div className="quick-actions-table inventory-page">
      <SubInventoryDataGrid data={subInventories} />
    </div>
  );
};

export default React.memo(SubInventories);
