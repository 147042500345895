import { updateObject } from '../utility';
// type
import { GET_TOTAL_MERCHANDISING_CATEGORIES_DATA } from './MerchandisingCategoriesActionTypes';

const initialState = {
  merchandisingCategoriesDatacategoriesData: [],
  pageSize: '',
  currentPage: '',
  rowCount: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_MERCHANDISING_CATEGORIES_DATA: {
      return updateObject(state, {
        merchandisingCategoriesData: action.payload,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        rowCount: action.payload.rowCount,
      });
    }

    default:
      return state;
  }
};

export default reducer;
