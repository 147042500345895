import React from 'react';
import PageTitle from 'components/shared/PageTitle';
import FeaturedOccasionInfo from './common/FeaturedOccasionInfo';

const NewFeaturedOccasions = () => {
  return (
    <>
      <PageTitle title={'Add new Featured Occasion'} />
      <div className="card">
        <FeaturedOccasionInfo type={'new'} />
      </div>
    </>
  );
};

export default NewFeaturedOccasions;
