import { updateObject } from '../utility';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  masterCatalogData: {},
  pageSize: '',
  currentPage: '',
  rowCount: '',
  selectedProduct: 0,
  productLog: {},
  selectedProductsItems: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOCAL_AVAILABILITY_LIST_DATA': {
      return updateObject(state, {
        masterCatalogData: action.payload,
      });
    }

    case 'SET_IS_SYNCED_PRODUCT_FALSE': {
      const productListData = cloneDeep(state.masterCatalogData);
      productListData.data.find((item) => item.id === action.payload).synced = false;
      return updateObject(state, {
        masterCatalogData: productListData,
      });
    }

    case 'SET_IS_SYNCED_PRODUCT_TRUE': {
      const productListData = cloneDeep(state.masterCatalogData);
      productListData.data.find((item) => item.id === action.payload).synced = true;
      return updateObject(state, {
        masterCatalogData: productListData,
      });
    }

    case 'SET_SELECTED_PRODUCT': {
      return updateObject(state, {
        selectedProduct: action.payload,
      });
    }

    case 'SET_PRODUCT_LOG': {
      return updateObject(state, {
        productLog: action.payload,
      });
    }

    case 'SET_SELECTED_PRODUCTS_ITEMS': {
      return updateObject(state, {
        selectedProductsItems: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
