import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
// Components
import CreateOrderModal from './modals/CreateOrderModal';
import RestrictedSection from 'components/shared/RestrictedSection';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// Constants
import {
  isoFormat,
  isoStartFormat,
  isoEndFormat,
  convertQueryParamsIntoObject,
} from 'constant/Helpers';
import { printStatusType, priorityStatusType } from 'constant/Enums';
// actions
import {
  setBulkActionsOnFleet,
  setBulkPriorityStatus,
  setBulkPrintStatus,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// Selectors
import {
  getLocationLinkIds,
  getSelectedOrdersId,
  getQuickActionsTableParams,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// Services
import QuickActionService from 'services/QuickActionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const TableActions = ({ premium, inputValues }) => {
  const locationLinkIds = useSelector((state) => getLocationLinkIds({ state }));
  const selectedOrdersIds = useSelector((state) => getSelectedOrdersId({ state }));
  const quickActionsParams = useSelector((state) => getQuickActionsTableParams({ state }));
  const [isAddOrderModalOpen, setIsAddOrderModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [showPrintStatus, setShowPrintStatus] = useState(false);
  const [showPriorityStatus, setShowPriorityStatus] = useState(false);
  const [disableZiwoCallBtn, setDisableZiwoCallBtn] = useState(false);

  const sendLocationLink = async () => {
    const params = {
      ids: locationLinkIds,
    };
    if (locationLinkIds) {
      try {
        const res = await QuickActionService.sendLocationLink(params);
        toast.success(res.data.message);
      } catch (err) {
        errorLogger('TableActions:sendLocationLink', err);
        toast.error(err.response.data.message);
      }
    } else {
      toast.error('Select one order');
    }
  };

  // hidden temporary
  // const handleExportCm = async () => {
  //   const params = {
  //     ids: locationLinkIds,
  //   };
  //   if (locationLinkIds) {
  //     try {
  //       const res = await QuickActionService.exportCm(params);
  //       toast.success(res.data.message);
  //     } catch (err) {
  //       toast.error(err.response.data.message);
  //     }
  //   } else {
  //     toast.error('Please select one order');
  //   }
  // };

  const getParameters = (data) => {
    let StartDeliveryDate, EndDeliveryDate;
    if (data.deliverydate === '') {
      StartDeliveryDate = isoStartFormat(new Date());
      EndDeliveryDate = isoEndFormat(new Date());
    } else {
      const dates = data.deliverydate.split('-');
      StartDeliveryDate = isoFormat(new Date(Date.parse(dates[0])));
      EndDeliveryDate = isoFormat(new Date(Date.parse(dates[1])));
    }

    const params = {
      ...data,
      StartDeliveryDate,
      EndDeliveryDate,
      orderType: +data.orderType === -1 ? null : data.orderType,
    };

    return params;
  };

  const handleDownload = async () => {
    setIsLoading(true);
    let params;

    if (location.search) {
      const queryParams = convertQueryParamsIntoObject(location.search);
      params = getParameters(queryParams);
    } else {
      params = getParameters(inputValues);
    }

    try {
      const res = await QuickActionService.exportAllOrders(params);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Alert QTY.xlsx');
      document.body.appendChild(link);
      link.click();
      toast.success('Downloaded');
      setIsLoading(false);
    } catch (err) {
      errorLogger('TableActions:handleDownload', err);
      toast.error(err?.response?.data?.message ?? 'Failed to download');
      setIsLoading(false);
    }
  };

  const handlePrintOrders = () => {
    if (locationLinkIds) {
      navigator.clipboard.writeText(locationLinkIds);
      toast.success('Copied');
    } else {
      toast.error('Select one order');
    }
  };

  const handleShipOnFleet = () => {
    if (selectedOrdersIds.length > 0) {
      dispatch(setBulkActionsOnFleet(selectedOrdersIds));
    } else {
      toast.error('Please select one order');
    }
  };

  const handlePriorityClick = async (status) => {
    if (selectedOrdersIds.length > 0) {
      dispatch(setBulkPriorityStatus(selectedOrdersIds, status));
    } else {
      toast.error('Please select at least one order');
    }
  };

  const handlePrintStatusClick = (status) => {
    if (selectedOrdersIds.length > 0) {
      dispatch(setBulkPrintStatus(selectedOrdersIds, status));
    } else {
      toast.error('Please select at least one order');
    }
  };
  const makeZiwoCall = async () => {
    if (selectedOrdersIds.length > 0) {
      const params = {
        ids: selectedOrdersIds.toString(),
      };
      setDisableZiwoCallBtn(true);
      try {
        const res = await QuickActionService.makeZiwoCall(params);
        setDisableZiwoCallBtn(false);
        toast.success(res.data?.message ?? 'Ziwo call was successful');
      } catch (err) {
        errorLogger('TableActions:makeZiwoCall', err);
        toast.error(err.response?.data?.message ?? 'Failed to make ziwo call');
        setDisableZiwoCallBtn(false);
      }
    } else {
      toast.error('Please select at least one order');
    }
  };
  return (
    <Fragment>
      {isAddOrderModalOpen && (
        <AppErrorBoundary>
          <CreateOrderModal
            isAddOrderModalOpen={isAddOrderModalOpen}
            setIsAddOrderModalOpen={setIsAddOrderModalOpen}
            premium={premium}
          />
        </AppErrorBoundary>
      )}
      <div className="card m-b-20">
        <div className="card-body btn-showcase action-btns-wrapper">
          <button onClick={handlePrintOrders} type="button" className="btn btn-primary ">
            <i className="fa fa-print"></i> Print
          </button>
          <button onClick={sendLocationLink} type="button" className="btn btn-success ">
            <i className="fa fa-whatsapp"></i> Send location link
          </button>
          {quickActionsParams.steps === '6' && (
            <button
              onClick={makeZiwoCall}
              type="button"
              disabled={disableZiwoCallBtn}
              className="btn btn-success "
            >
              <i className="fa fa-headphones"></i> Ziwo Call
            </button>
          )}
          {/* <RestrictedSection page="quick_actions" action="can_export">
            <button onClick={handleExportCm} type="button" className="btn btn-secondary">
              <i className="fa fa-file"></i> Export CM
            </button>
          </RestrictedSection> */}
          <RestrictedSection page="quick_actions" action="can_download">
            <button
              onClick={handleDownload}
              type="button"
              className="btn btn-secondary"
              disabled={isLoading}
            >
              <i className="fa fa-file-text"></i> Download
            </button>
          </RestrictedSection>
          <RestrictedSection
            page="quick_actions"
            action={premium ? 'create_premium_order' : 'create_order'}
          >
            <button
              onClick={() => setIsAddOrderModalOpen((prev) => !prev)}
              type="button"
              className={`btn btn-${premium ? 'warning' : 'success'}`}
            >
              <i className="fa fa-plus"></i> {premium ? 'Premium Order' : 'Create Order'}
            </button>
          </RestrictedSection>
          <RestrictedSection page="quick_actions" action="download_print_app">
            <ButtonGroup className="print-actions-dropdown" vertical>
              <UncontrolledDropdown>
                <DropdownToggle color="success" caret className="b-r-0">
                  <i className="fa fa-download"></i> Print App
                </DropdownToggle>
                <DropdownMenu className="print-actions-dropdown-menu">
                  <DropdownItem
                    className="print-dropdown-item"
                    onClick={() =>
                      (window.location.href =
                        'https://floward.s3.eu-west-2.amazonaws.com/print-app/flw-print-app-setup-latest.exe')
                    }
                  >
                    Windows Device
                  </DropdownItem>
                  <DropdownItem
                    className="print-dropdown-item"
                    onClick={() =>
                      (window.location.href =
                        'https://floward.s3.eu-west-2.amazonaws.com/print-app/flw-print-app-latest.dmg')
                    }
                  >
                    Mac Device with Intel Chip
                  </DropdownItem>
                  <DropdownItem
                    className="print-dropdown-item"
                    onClick={() =>
                      (window.location.href =
                        'https://floward.s3.eu-west-2.amazonaws.com/print-app/flw-print-app-arm64-latest.dmg')
                    }
                  >
                    Mac Device with Apple Chip
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
            <a
              className="print-app-download"
              href="https://floward.s3.eu-west-2.amazonaws.com/web/print-app-manual.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-file-text"></i> Print App Manual
            </a>
          </RestrictedSection>
          <RestrictedSection page="order_details" action="ship_onfleet">
            <ButtonGroup className="product-actions-dropdown" vertical>
              <UncontrolledDropdown>
                <DropdownToggle color="primary" caret className="b-r-0">
                  Bulk Actions
                </DropdownToggle>
                <DropdownMenu className="product-actions-dropdown-menu">
                  <DropdownItem onClick={handleShipOnFleet}>Ship onFleet</DropdownItem>
                  {showPrintStatus && (
                    <div className="horizontal-wrapper product-status-items">
                      {printStatusType.map((item) => (
                        <DropdownItem
                          className="status-item"
                          key={item.id}
                          onClick={() => {
                            handlePrintStatusClick(item.status);
                            setShowPrintStatus(false);
                          }}
                          onMouseEnter={() => setShowPrintStatus(true)}
                          onMouseLeave={() => setShowPrintStatus(false)}
                        >
                          {item.name}
                        </DropdownItem>
                      ))}
                    </div>
                  )}
                  <DropdownItem
                    className="product-dropdown-item"
                    onMouseEnter={() => setShowPrintStatus(true)}
                    onMouseLeave={() => setShowPrintStatus(false)}
                  >
                    <span>Print Status</span> <i className="fa fa-caret-right"></i>
                  </DropdownItem>

                  {showPriorityStatus && (
                    <div className="horizontal-wrapper product-status-items">
                      {priorityStatusType.map((item) => (
                        <DropdownItem
                          className="status-item"
                          key={item.id}
                          onClick={() => {
                            handlePriorityClick(item.id);
                            setShowPriorityStatus(false);
                          }}
                          onMouseEnter={() => setShowPriorityStatus(true)}
                          onMouseLeave={() => setShowPriorityStatus(false)}
                        >
                          {item.name}
                        </DropdownItem>
                      ))}
                    </div>
                  )}
                  <DropdownItem
                    className="product-dropdown-item"
                    onMouseEnter={() => setShowPriorityStatus(true)}
                    onMouseLeave={() => setShowPriorityStatus(false)}
                  >
                    <span>Priority Status</span> <i className="fa fa-caret-right"></i>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          </RestrictedSection>
        </div>
      </div>
    </Fragment>
  );
};

export default TableActions;
