import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SearchUserForm from 'components/shared/SearchUserForm';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { errorLogger } from 'datadog/DDUtils';
// api
import PermissionService from 'services/PermissionService';

const AddExistingUserModal = ({
  isExistingUserModalOpen,
  setIsExistingUserModalOpen,
  roleType,
  fetchData,
}) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [assignedFirstName, setAssignedFirstName] = useState('');
  const [assignedLastName, setAssignedLastName] = useState('');
  const [assignedUserId, setAssignedUserId] = useState('');
  const [assignedEmail, setAssignedEmail] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const fetchUsersData = debounce(async (keyword) => {
    const params = {
      searchkey: keyword,
    };
    setSearchKey(keyword);
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getSearchFlowardUsers(params);
      setSearchResult(res.data);
      setIsLoaderOpen(false);
    } catch (e) {
      errorLogger('AddExistingUserModal:fetchUsersData', e);
      setIsLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    setAssignedFirstName('');
    setAssignedLastName('');
    setAssignedUserId('');
    if (keyword.length >= 3) {
      fetchUsersData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleSelectUser = (user) => {
    setAssignedFirstName(user.name);
    setAssignedLastName(user.lastName);
    setAssignedUserId(user.id);
    setAssignedEmail(user.email);
    setShowTable(false);
  };

  const handleAddUser = async () => {
    const dataBody = {
      userId: assignedUserId,
      groupType: roleType,
    };
    setDisableBtn(true);
    try {
      await PermissionService.addExistingUser(dataBody);
      setTimeout(() => {
        toast.success('User Added successfully');
        setDisableBtn(false);
        fetchData();
        setIsExistingUserModalOpen((prev) => !prev);
      }, 1500);
    } catch (err) {
      toast.error(err?.response?.data?.Message ?? 'Failed to add user!');
      errorLogger('AddExistingUserModal:handleAddUser', err);
      setDisableBtn(false);
      setIsExistingUserModalOpen((prev) => !prev);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isExistingUserModalOpen}
        toggle={() => setIsExistingUserModalOpen((prev) => !prev)}
      >
        <ModalHeader>Add Existing User</ModalHeader>
        <ModalBody>
          <SearchUserForm
            handleSearch={handleSearch}
            searchKey={searchKey}
            isLoaderOpen={isLoaderOpen}
            searchResult={searchResult}
            showTable={showTable}
            handleSelectUser={handleSelectUser}
            assignedFirstName={assignedFirstName}
            assignedLastName={assignedLastName}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!assignedFirstName || disableBtn}
            onClick={handleAddUser}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={() => setIsExistingUserModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddExistingUserModal;
