import React, { useRef, useState } from 'react';
import { dateFormat } from 'constant/Helpers';

const deliveryStatus = {
  0: 'Picked',
  1: 'Delivering',
  2: 'Delivered',
  3: 'Canceled',
  4: 'Removed',
};

const TripRevealCard = ({ item }) => {
  const frontElement = useRef();
  const backElement = useRef();
  const [isFrontElement, setIsFrontElement] = useState(true);
  const [isBackElement, setIsBackElement] = useState(false);

  const showBackElement = () => {
    setIsFrontElement(false);
    setIsBackElement(true);
  };

  const showFrontElement = (e) => {
    setIsFrontElement(true);
    setIsBackElement(false);
  };

  const handleTimeStamp = (date) => {
    const dateObject = new Date(date);
    const humanDateFormat = dateObject.toLocaleString();
    return humanDateFormat;
  };

  return (
    <div className="control-pane">
      <div className="control-section card-control-section reveal_card_layout">
        <div className="e-card-resize-container">
          <div className="row">
            <div className="row card-layout m-b-15">
              <div className="col-12 required_element_of_card_revealed">
                <div className="e-card align-center">
                  <div
                    ref={frontElement}
                    className={`toggle_class_of_card_revealed card_revealed ${
                      isFrontElement ? 'e-reveal-show' : 'e-reveal-hide'
                    }`}
                  >
                    <div className="e-card-content">
                      <table className="trip-reveal-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="trip-table-title"> Order# </div>
                            </td>
                            <td>
                              <div
                                className="trip-order-id"
                                onClick={() => {
                                  window.open(`/order/${item.orderID}`, '_blank');
                                }}
                              >
                                {item.orderID}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="trip-table-title">Delivery Status</div>
                            </td>
                            <td>
                              <div className="trip-order-status">
                                {deliveryStatus[item.orderstatus]}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="e-card-actions">
                                <button
                                  onClick={showBackElement}
                                  className="e-btn e-outline e-primary"
                                >
                                  Show More
                                </button>
                              </div>
                            </td>
                            <td className="trip-more-details">
                              <div className="e-card-actions">
                                <button
                                  className="e-card-btn"
                                  title="Click to see more..."
                                  onClick={showBackElement}
                                >
                                  <i className="fa fa-bars sz-18"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    ref={backElement}
                    className={`toggle_class_name_of_card_reveal card_reveal ${
                      isBackElement ? 'e-reveal-show' : 'e-reveal-hide'
                    }`}
                  >
                    <div className="e-card-header">
                      <div className="e-card-header-caption">
                        <div className="e-card-header-title">More Details</div>
                      </div>
                      <div onClick={showFrontElement} title="Click to see back...">
                        <span className="e-icons e-collapse trip-collapse"></span>
                      </div>
                    </div>
                    <div className="e-card-content">
                      <table className="trip-reveal-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="trip-table-title"> Picked time </div>
                            </td>
                            <td>
                              <div className="trip-picked-date">
                                {item.pickedDate && handleTimeStamp(item.pickedDate)}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="trip-table-title">Delivery Date</div>
                            </td>
                            <td>
                              <div className="trip-delivery-date">
                                {item.deliveryDate && dateFormat(item.deliveryDate)}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="trip-table-title">Address or Postal code</div>
                            </td>
                            <td>
                              {item.address && (
                                <div className="left-txt">
                                  {item.address.postalCode
                                    ? item.address.postalCode
                                    : item.address.address}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="trip-table-title">ETA</div>
                            </td>
                            <td>
                              {item.address && (
                                <div className="left-txt">
                                  {item.eta ? handleTimeStamp(item.eta) : 'Unknown'}
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripRevealCard;
