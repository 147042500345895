import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
} from '@syncfusion/ej2-react-grids';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const OrderedItemsDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search'];

  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  // custom columns
  const imgGridTemplate = (rowData) => {
    return (
      <div className="image">
        <img className="ordered-item-img" alt={rowData.sku} src={rowData.image} />
      </div>
    );
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          pageSettings={{ pageSize: 20, pageSizes: true }}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective textAlign="Center" headerText="Image" template={imgGridTemplate} />
            <ColumnDirective textAlign="Center" headerText="SKU" field="sku" />
            <ColumnDirective textAlign="Center" headerText="Total Orders" field="totalorders" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(OrderedItemsDataGrid);
