import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import PageTitle from 'components/shared/PageTitle';
// actions
import { setFloristsDataList } from 'store/floristCommunity/FloristCommunityActions';
// selectors
import {
  getFloristCommunityList,
  getFloristCommunityListPageSize,
  getFloristCommunityListCurrentPage,
  getFloristCommunityListRowCount,
} from 'store/floristCommunity/FloristCommunitySelectors';
// components
import SectionLoader from 'components/common/SectionLoader';
const FloristCommunityDataGrid = React.lazy(() =>
  import('components/floristCommunity/FloristCommunityDataGrid')
);
const DJHPagination = React.lazy(() => import('components/shared/Pagination'));

const FloristCommunityPage = () => {
  const floristCommunityList = useSelector((state) => getFloristCommunityList({ state }));
  const pageSize = useSelector((state) => getFloristCommunityListPageSize({ state }));
  const currentPage = useSelector((state) => getFloristCommunityListCurrentPage({ state }));
  const rowCount = useSelector((state) => getFloristCommunityListRowCount({ state }));
  const dispatch = useDispatch();
  const location = useLocation();

  const getStatus = () => {
    let status = -1;
    if (location.pathname.indexOf('underreview') > -1) {
      status = 0;
    } else {
      if (location.pathname.indexOf('accepted') > -1) {
        status = 1;
      } else {
        if (location.pathname.indexOf('rejected') > -1) {
          status = 2;
        } else {
          if (location.pathname.indexOf('deleted') > -1) {
            status = 3;
          }
        }
      }
    }
    return status;
  };
  const fetchFloristsList = async () => {
    const status = getStatus();
    dispatch(setFloristsDataList({ page: 1, status }));
  };

  useEffect(() => {
    fetchFloristsList();
  }, []);

  const onPageChange = (i) => {
    const status = getStatus();
    dispatch(setFloristsDataList({ page: i, status }));
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Florist Community" />
      <React.Suspense fallback={<SectionLoader />}>
        <FloristCommunityDataGrid data={floristCommunityList} />
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+currentPage}
          pageSize={+pageSize}
          itemsCount={+rowCount}
        />
      </React.Suspense>
    </div>
  );
};

export default FloristCommunityPage;
