import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// modals and components
import AddCreditModal from './modals/AddCreditModal';
import ChangeOpsModal from './modals/ChangeOpsModal';
import RefundModal from './modals/RefundModal';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// api
import ViewOrderService from 'services/ViewOrderService';
import RestrictedSection from 'components/shared/RestrictedSection';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SecondaryActions = ({ orderData, cartData, orderId }) => {
  const [isAddCreditModalOpen, setIsAddCreditModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isChangeOpsModalOpen, setIsChangeOpsModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'SET_UPDATED_PAYMENT',
      payload: orderData.paymentSt,
    });
    dispatch({
      type: 'SET_UPDATED_ORDER_STATUS',
      payload: orderData.status,
    });
  }, []);

  const handleSendEmail = async () => {
    try {
      const res = await ViewOrderService.sendEmail(orderId);
      toast.success(res.data.message);
    } catch (err) {
      errorLogger('SecondaryActions:handleSendEmail', err);
      toast.error(err.response.data.message);
    }
  };

  const handleEarnPoints = async () => {
    try {
      const res = await ViewOrderService.earnPoints(orderId);
      toast.success(res.data);
    } catch (err) {
      errorLogger('SecondaryActions:handleEarnPoints', err);
      toast.error(err.response.data.message);
    }
  };

  return (
    <Fragment>
      {isAddCreditModalOpen && (
        <AddCreditModal
          orderId={orderId}
          isAddCreditModalOpen={isAddCreditModalOpen}
          setIsAddCreditModalOpen={setIsAddCreditModalOpen}
          paymentdetails={orderData.paymentdetails}
          opsId={orderData?.opsID}
          currency={orderData?.ops?.currencyISO}
        />
      )}
      {isChangeOpsModalOpen && (
        <AppErrorBoundary>
          <ChangeOpsModal
            orderId={orderId}
            cartData={cartData}
            isChangeOpsModalOpen={isChangeOpsModalOpen}
            setIsChangeOpsModalOpen={setIsChangeOpsModalOpen}
          />
        </AppErrorBoundary>
      )}
      {isRefundModalOpen && (
        <RefundModal
          isRefundModalOpen={isRefundModalOpen}
          setIsRefundModalOpen={setIsRefundModalOpen}
          orderData={orderData}
          orderId={orderId}
        />
      )}
      <div className="card">
        <div className="card-body btn-showcase">
          <RestrictedSection page="order_details" action="send_email">
            <button type="button" className="btn btn-success " onClick={handleSendEmail}>
              <i className="fa fa-envelope"></i> Send Email
            </button>
          </RestrictedSection>

          <RestrictedSection page="order_details" action="refund">
            <button
              type="button"
              className="btn btn-secondary "
              onClick={() => setIsRefundModalOpen((prev) => !prev)}
            >
              <i className="fa fa-dollar"></i> Refund
            </button>
          </RestrictedSection>

          <RestrictedSection page="order_details" action="add_credit">
            <button
              onClick={() => setIsAddCreditModalOpen((prev) => !prev)}
              type="button"
              className="btn btn-secondary "
            >
              <i className="fa fa-cc-visa"></i> Add Credit
            </button>
          </RestrictedSection>

          <RestrictedSection page="order_details" action="change_ops">
            <button
              onClick={() => setIsChangeOpsModalOpen((prev) => !prev)}
              type="button"
              className="btn btn-info"
              disabled={orderData.cStep === 4}
            >
              <i className="fa fa-globe"></i> Change Ops
            </button>
          </RestrictedSection>

          <RestrictedSection page="order_details" action="earn_points">
            <button onClick={handleEarnPoints} type="button" className="btn btn-success">
              <i className="fa fa-dollar"></i> Earn Points
            </button>
          </RestrictedSection>
        </div>
      </div>
    </Fragment>
  );
};

export default SecondaryActions;
