import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { setShareSenderDetails } from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import { getQuickActionsIsShareDetailsBtnDisabled } from 'store/orderQuickActions/dataTable/DataTableSelector';
// constants
import { copyToClipboard } from 'constant/Helpers';

const SenderRecipientBlock = ({
  name,
  phone,
  id,
  shareSender,
  isSender,
  premium,
  extra,
  orderCstep,
}) => {
  const dispatch = useDispatch();
  const isShareDetailsBtnDisabled = useSelector((state) =>
    getQuickActionsIsShareDetailsBtnDisabled({ state })
  );

  return (
    <div className="sender sender-recipient-block">
      <p>
        <span className="sec-title">Name: </span> {name}
      </p>
      <p className="sender-phone">
        <span className="sec-title">Phone: </span>{' '}
        {phone && (
          <span
            onClick={() => {
              copyToClipboard(phone);
            }}
            className="phone"
          >
            {phone}
          </span>
        )}
      </p>
      {extra && (
        <p>
          <span className="sec-title">Extra phone:</span>{' '}
          <span
            onClick={() => {
              copyToClipboard(extra);
            }}
            className="phone"
          >
            {extra}
          </span>
        </p>
      )}
      {isSender && (
        <button
          disabled={isShareDetailsBtnDisabled[id] || orderCstep === 4}
          onClick={() => dispatch(setShareSenderDetails(id, shareSender, !!premium))}
          className={`btn btn-sm ${shareSender ? 'btn-success' : 'btn-danger'} sender-btn`}
        >
          {shareSender ? 'Share details' : "Don't share details"}
        </button>
      )}
    </div>
  );
};

export default SenderRecipientBlock;
