import { apiHomeService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class KeyValueService {
  static getAllKeyValue(params, ...extraParams) {
    return apiHomeService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllOrCreateKeyValue, ...extraParams),
      params,
    });
  }

  static createKeyValue(data, ...extraParams) {
    return apiHomeService({
      method: 'POST',
      url: setEndPoint(endPoints.getAllOrCreateKeyValue, ...extraParams),
      data,
    });
  }

  static updateKeyValue(data, ...extraParams) {
    return apiHomeService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateKeyValue, ...extraParams),
      data,
    });
  }

  static updateKeyValueStatus(data, ...extraParams) {
    return apiHomeService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateKeyValueStatus, ...extraParams),
      data,
    });
  }
}

export default KeyValueService;
