import React from 'react';

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 footer-copyright">
            <p className="mb-0">
              Copyright {new Date().getFullYear()} ©{' '}
              <strong>
                <a className="primary-color" href="https://floward.com/">
                  Floward
                </a>
              </strong>{' '}
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
