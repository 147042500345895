import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class OrderedItemsService {
  static getOrderedItems(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.orderedItems, ...extraParams),
      params: data,
    });
  }
}
export default OrderedItemsService;
