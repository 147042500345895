import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// actions
import { setDeleteProductMasterInventoryItem } from 'store/products/ProductsActions';

const DeleteProductMasterInventoryModal = ({
  centeralId,
  isDeleteMasterInvModalOpen,
  setIsDeleteMasterInvModalOpen,
  validateMI,
}) => {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    await dispatch(setDeleteProductMasterInventoryItem(centeralId, validateMI));
    setIsDeleteMasterInvModalOpen((prev) => !prev);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isDeleteMasterInvModalOpen}
        toggle={() => setIsDeleteMasterInvModalOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setIsDeleteMasterInvModalOpen((prev) => !prev)}>
          Remove Item
        </ModalHeader>
        <ModalBody>
          <p className="sz-20">Are you sure?</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleDelete} color="danger">
            Delete
          </Button>
          <Button color="light" onClick={() => setIsDeleteMasterInvModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteProductMasterInventoryModal;
