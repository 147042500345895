import React from 'react';
import PageTitle from 'components/shared/PageTitle';
import FeaturedOccasionInfo from './common/FeaturedOccasionInfo';

const EditFeaturedOccasions = () => {
  return (
    <>
      <PageTitle title={'Edit Featured Occasion'} />
      <div className="card">
        <FeaturedOccasionInfo type={'edit'} />
      </div>
    </>
  );
};

export default EditFeaturedOccasions;
