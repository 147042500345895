import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
// components
import VouchersFilter from 'components/vouchers/VouchersFilter';
import VouchersDataGrid from 'components/vouchers/VouchersDataGrid';
import DJHPagination from 'components/shared/Pagination';
import PageTitle from 'components/shared/PageTitle';
// constants
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// url
import { getVouchersListPageUrl } from 'constant/AppUrls';
// actions
import { setAllCouponsList, setVoucherCategoryList } from 'store/vouchers/VouchersActions';
// selectors
import {
  getAllCouponsList,
  getAllCouponsCurrentPage,
  getAllCouponsPageSize,
  getAllCouponsPageRowCount,
  getVoucherCatergoriesList,
} from 'store/vouchers/VoucherSelectors';

const CouponDailySpendPage = () => {
  const [inputValues, setInputValues] = useState({
    code: '',
    catId: -1,
    page: 1,
  });
  const allCouponsListData = useSelector((state) => getAllCouponsList({ state }));
  const allCouponsCurrentPage = useSelector((state) => getAllCouponsCurrentPage({ state }));
  const allCouponsPageSize = useSelector((state) => getAllCouponsPageSize({ state }));
  const allCouponsRowCount = useSelector((state) => getAllCouponsPageRowCount({ state }));
  const voucherCategoriesList = useSelector((state) => getVoucherCatergoriesList({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(setAllCouponsList(queryObj));
    } else {
      dispatch(setAllCouponsList(inputValues));
    }
    dispatch(setVoucherCategoryList());
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: getVouchersListPageUrl(),
      search: queryString,
    });
    dispatch(setAllCouponsList(inputValues));
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);

    navigate({
      pathname: getVouchersListPageUrl(),
      search: queryString,
    });
    dispatch(setAllCouponsList(updatedParams));
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Coupons" />
      <VouchersFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        voucherCatList={voucherCategoriesList}
        title="Discount Vouchers"
      />
      <VouchersDataGrid data={allCouponsListData} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+allCouponsCurrentPage}
        pageSize={+allCouponsPageSize}
        itemsCount={+allCouponsRowCount}
      />
    </div>
  );
};

export default CouponDailySpendPage;
