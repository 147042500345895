import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class CouponReportService {
  static getAllCouponDailySpend(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getCouponDailySpend, ...extraParams),
      params,
    });
  }

  static getVoucherCategoryList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.voucherCategoryList, ...extraParams),
    });
  }

  static exportAllCouponDailySpendReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportAllCouponDailySpend, ...extraParams),
      params,
    });
  }
}
export default CouponReportService;
