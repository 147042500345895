import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
// Components
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
// Actions
import { setSelectedAreasIds } from 'store/coveredAreas/CoveredAreasActions';
// Selectors
import { getSelectedAreasIds } from 'store/coveredAreas/CoveredAreasSelectors';

const CoverageAreaModalDatagrid = ({ data, page }) => {
  const ref = useRef(),
    dispatch = useDispatch(),
    storedIds = useSelector((state) => getSelectedAreasIds({ state }));

  const rowSelected = () => {
    const selectedrecords = cloneDeep(ref.current?.getSelectedRecords());
    if (selectedrecords) {
      if (selectedrecords.length === data.length) {
        document.getElementsByClassName('selectAllcheckbox')[0].className =
          'selectAllcheckbox is-checked';
      } else {
        if (selectedrecords.length > 0) {
          document.getElementsByClassName('selectAllcheckbox')[0].className =
            'selectAllcheckbox some-check';
        } else {
          document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
        }
      }
      const selectedAreasIds = selectedrecords?.map((el) => el.Id);
      dispatch(setSelectedAreasIds({ page: page, areas: selectedAreasIds }));
    }
  };

  const checkAll = (e) => {
    const classNames = e.target.className;
    const isChecked = classNames.includes('is-checked');
    if (!isChecked) {
      ref.current.selectRowsByRange(0, data.length - 1);
      document.getElementsByClassName('selectAllcheckbox')[0].className =
        'selectAllcheckbox is-checked';
    } else {
      ref.current.clearSelection();
      document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
    }
  };

  const checkboxTemplate = () => {
    return (
      <label className="e-checkbox-wrapper e-css">
        <input className="e-checkselect" type="checkbox" />
        <span className="e-frame e-icons"></span>
      </label>
    );
  };

  const checkboxHeaderTemplate = () => {
    return <span className="selectAllcheckbox " onClick={checkAll}></span>;
  };

  useEffect(() => {
    let pageSelectedAreas = cloneDeep(storedIds);
    pageSelectedAreas = pageSelectedAreas.filter((pr) => pr.page === page);
    if (pageSelectedAreas) {
      if (pageSelectedAreas.length > 0) {
        let selectedAreas = pageSelectedAreas[0].areas;
        let dataSrc = ref.current.dataSource;
        let newSelectedRows = [];
        selectedAreas?.forEach((sp) => {
          dataSrc.forEach((row, index) => {
            if (row.Id === sp) {
              newSelectedRows.push(index);
            }
          });
        });
        setTimeout(() => {
          ref.current?.selectRows(newSelectedRows);
        }, 1500);
      }
    }
  }, [data]);

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          ref={ref}
          allowTextWrap={true}
          selectionSettings={{ type: 'Multiple', enableSimpleMultiRowSelection: true }}
          rowSelected={rowSelected}
          rowDeselected={rowSelected}
        >
          <ColumnsDirective>
            <ColumnDirective
              width="30"
              template={checkboxTemplate}
              headerTemplate={checkboxHeaderTemplate}
              textAlign="Center"
            />
            <ColumnDirective width="100" headerText="Area" textAlign="Center" field="Name" />
          </ColumnsDirective>
        </GridComponent>
      </div>
    </div>
  );
};

export default CoverageAreaModalDatagrid;
