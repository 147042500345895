import { updateObject } from '../utility';

const initialState = {
  areasList: [],
  selectedAreasIds: [],
  loading: false,
  isDisabled: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AREAS_LIST': {
      return updateObject(state, {
        areasList: action.payload,
      });
    }
    case 'SET_SELECTED_AREAS_IDS_LIST': {
      return updateObject(state, {
        selectedAreasIds: action.payload.data,
        isDisabled: action.payload.isDisabled,
      });
    }
    case 'SET_LOADING_TRUE': {
      return updateObject(state, {
        loading: true,
      });
    }
    case 'SET_LOADING_FALSE': {
      return updateObject(state, {
        loading: false,
      });
    }
    case 'SET_DISABLED_TRUE': {
      return updateObject(state, {
        isDisabled: true,
      });
    }
    case 'SET_DISABLED_FALSE': {
      return updateObject(state, {
        isDisabled: false,
      });
    }
    case 'CLEAR_SELECTED_IDS': {
      return updateObject(state, {
        selectedAreasIds: [],
      });
    }
    default:
      return state;
  }
};

export default reducer;
