import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import BrandsTotalOrdersDataGrid from 'components/reports/brands/BrandsTotalOrdersDataGrid';
import BrandsBasketItemsDataGrid from 'components/reports/brands/BrandsBasketItemsDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import BrandsReportService from 'services/reportsService/BrandsReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const BrandsReportPage = () => {
  const [data, setData] = useState([]);
  const [brandBasket, setBrandBasket] = useState([]);
  const [designersList, setDesignersList] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    brandId: 0,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchTotalBrands = async (params) => {
    try {
      const res = await BrandsReportService.getBrandsTotalOrders(params);
      setData(res.data);
    } catch (err) {
      errorLogger('Brands Report Page Fetch Total Orders Error:', err);
      toast.error('Failed to get data!!');
      setData([]);
    }
  };

  const fetchBasketOrders = async (params) => {
    try {
      const basketData = await BrandsReportService.getBrandsBasket(params);
      setBrandBasket(basketData.data.data);
    } catch (err) {
      errorLogger('Brands Report Page Fetch Basket Orders Error:', err);
      toast.error('Failed to get data!!');
      setBrandBasket([]);
    }
  };

  const fetchBrandsList = async () => {
    try {
      const designers = await BrandsReportService.getBrandsList();
      setDesignersList(designers.data.result);
    } catch (err) {
      errorLogger('Brands Report Page Fetch Brands List Error:', err);
      toast.error('Failed to get brands list!!');
    }
  };

  const fetchAllData = async () => {
    dispatch(setLoaderOpenTrue());
    try {
      const promisesArray = [];
      if (location.search) {
        const queryObj = convertQueryParamsIntoObject(location.search);
        promisesArray.push(fetchTotalBrands(queryObj), fetchBasketOrders(queryObj));
      } else {
        promisesArray.push(fetchTotalBrands(inputValues), fetchBasketOrders(inputValues));
      }
      await Promise.all(promisesArray);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Brands Report Page Fetch All Lists Error:', err);
      toast.error('Failed to get brands list!!');
      dispatch(setLoaderOpenFalse());
    }
  };

  useEffect(() => {
    fetchAllData();
    fetchBrandsList();
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: '/report/BrandsReport',
      search: queryString,
    });
    fetchAllData();
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Brands"
        brands
        defaultDate
        designersList={designersList}
      />
      <BrandsTotalOrdersDataGrid data={data} />

      <div className="card m-t-30 ">
        <div className="card-header p-10">
          <span className="sz-17">Basket Items</span>
        </div>
        <div className="card-body">
          <BrandsBasketItemsDataGrid data={brandBasket} />
        </div>
      </div>
    </div>
  );
};

export default BrandsReportPage;
