import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ModalFooter } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';
import SectionLoader from 'components/common/SectionLoader';
// actions
import {
  setToggleAddressModalOpen,
  updateActionRecepientAddress,
  setPremiumTableData,
  setTableData,
} from 'store/orderQuickActions/dataTable/DataTableAction';
import { setViewOrderData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';
// constant
import { prevAddressHeader } from 'constant/Enums';

const PreviousAddress = ({
  orderId,
  setShowPrevAddress,
  isAction,
  recipient,
  premium,
  addressList,
}) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();
  const closeModal = () => {
    setIsLoaderOpen(false);
    setShowPrevAddress(false);
    dispatch(setToggleAddressModalOpen());
  };

  const handlePrevAddress = async (orderId, id, address) => {
    setIsLoaderOpen(true);
    try {
      await ViewOrderService.updatePrevAddress(orderId, id);
      if (isAction) {
        const clonedRecepient = cloneDeep(recipient);
        clonedRecepient.address = address;
        dispatch(
          updateActionRecepientAddress({
            id: orderId,
            updatedData: clonedRecepient,
          })
        );
        setTimeout(() => {
          toast.success('Updated address successfully.');
          if (premium) {
            dispatch(setPremiumTableData(''));
          } else {
            dispatch(setTableData(''));
          }
          closeModal();
        }, 1500);
      } else {
        setTimeout(() => {
          dispatch(setViewOrderData(orderId));
          toast.success('Updated address successfully.');
          closeModal();
        }, 1500);
      }
    } catch (err) {
      errorLogger('PreviousAddress:handlePrevAddress', err);
      toast.error('Failed to update address!');
      closeModal();
    }
  };

  return (
    <div>
      {isLoaderOpen ? (
        <SectionLoader height="300px" />
      ) : (
        <div className="table-responsive shopping-table text-center prev-address-wrapper">
          <table className="table table-bordernone">
            <thead>
              <tr>
                {prevAddressHeader.map((col, i) => (
                  <th key={i} scope="col">
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            {addressList !== '' && (
              <tbody>
                {addressList?.map((item, i) => (
                  <tr className="search-table-row" key={i}>
                    <td
                      onClick={() => {
                        window.open(`/order/${item.orderId}`, '_blank');
                      }}
                      className="order-id"
                    >
                      {item.orderId}
                    </td>
                    <td>{item.recipientName}</td>
                    <td>{item.recipientPhone}</td>
                    <td>{item.name}</td>
                    <td>{item.addressLineOne}</td>
                    <td>
                      <button
                        onClick={() =>
                          handlePrevAddress(item.orderId, item.id, item.addressLineOne)
                        }
                        className="btn btn-primary"
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      )}

      <ModalFooter className="row prev-modal-footer-wrapper">
        <div className="col-12 actions-wrapper">
          <button className="btn btn-danger" onClick={() => setShowPrevAddress(false)}>
            Cancel
          </button>
        </div>
      </ModalFooter>
    </div>
  );
};

export default PreviousAddress;
