import { updateObject } from '../utility';

const initialState = {
  subscriptionsData: [],
  subscriptionId: '',
  isBillingDetailsModalOpen: false,
  isSubscriptionsDetailsModalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_SUBSCRIPTIONS_DATA': {
      return updateObject(state, {
        subscriptionsData: action.payload,
      });
    }
    case 'TOGGLE_BILLING_DETAILS_MODAL_OPEN': {
      return updateObject(state, {
        subscriptionId: action.payload,
        isBillingDetailsModalOpen: !state.isBillingDetailsModalOpen,
      });
    }

    case 'TOGGLE_SUBSCIPTIONS_DETAILS_MODAL_OPEN': {
      return updateObject(state, {
        subscriptionId: action.payload,
        isSubscriptionsDetailsModalOpen: !state.isSubscriptionsDetailsModalOpen,
      });
    }
    default:
      return state;
  }
};

export default reducer;
