import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RefreshCw } from 'react-feather';
import MaterialDataGrid from 'components/shared/MaterialDataGrid';
import ProductLogsDataTable from './ProductLogsDataTable';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { exportToExcel } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
// actions
import {
  setSyncProduct,
  setSelectedProduct,
  setSelectedProductsItems,
} from 'store/masterCatalog/MasterCatalogActions';
// modal
import RightSideModal from 'components/shared/RightSideModal';

const LocalAvilabilityDataGrid = ({ data, params, totalCatalog, isUncategorizedMerch }) => {
  const dispatch = useDispatch();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);
  const [toggleProductLog, setToggleProductLog] = useState(false);

  const handleSyncClick = (id, params) => {
    dispatch(setSyncProduct(id, params));
  };

  //columns
  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      render: (rowData) => (
        <a
          className="product-sku-link"
          href={`/product/editProduct/${rowData.id}/0`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {rowData.sku}
        </a>
      ),
    },
    { title: 'Product Type', field: 'typeString' },
    { title: 'Line', field: 'productionLine' },
    { title: 'Origin Ops', field: 'originOps' },
    { title: 'Product Definition', field: 'productDefinitionString' },
    { title: 'Product Status', field: 'productStatusString' },
    {
      field: 'image',
      title: 'Image',
      render: (rowData) => (
        <img alt={rowData.name} src={rowData.image} className="grid-template-img" />
      ),
    },
    {
      title: 'Name',
      field: 'name',
      render: (rowData) => (
        <a
          className="product-name-link"
          href={`/product/editProduct/${rowData.id}/0`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {rowData.name}
        </a>
      ),
    },
    {
      title: 'Price',
      field: 'price',
      hidden: isUncategorizedMerch ?? false,
      render: (rowData) => (
        <span>
          {rowData.currency}{' '}
          {rowData.price && rowData.price.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
        </span>
      ),
    },
    {
      title: 'Cost',
      render: (rowData) => (
        <span data-testid="costTemplateTestID">
          {rowData.currency}{' '}
          {rowData.cost && rowData.cost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
        </span>
      ),
      hidden: isUncategorizedMerch ?? false,
    },
    {
      title: 'Margin',
      render: (rowData) => (
        <span>
          {rowData.margin && rowData.margin.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%
        </span>
      ),
      hidden: isUncategorizedMerch ?? false,
    },
    { title: 'Order Count', field: 'orderCount', hidden: isUncategorizedMerch ?? false },
    {
      title: 'Available QTY',
      field: 'availableQTY',
      render: (rowData) => (
        <div>
          <span className="sz-16">{rowData.availableQty}</span>
          <p className={`sz-16 bold-title ${rowData.pricingActive ? 'green-color' : 'red-color'}`}>
            {rowData.pricingActive ? 'In' : 'Out'}
          </p>
        </div>
      ),
      hidden: isUncategorizedMerch ?? false,
    },
    { title: 'Views', field: 'views', hidden: isUncategorizedMerch ?? false },
    {
      title: 'Status',
      field: 'masterStatus',
      hidden: totalCatalog && !isUncategorizedMerch ? false : true,
    },
    {
      title: 'Sync',
      render: (rowData) =>
        rowData.synced ? (
          <RefreshCw
            style={{ width: 20, cursor: 'pointer', color: 'green' }}
            onClick={() => handleSyncClick(rowData.id, params)}
          />
        ) : (
          <span>
            <img
              alt="sync"
              src="https://cdn.floward.com/web/Files/attachment/main-637702266203428444.gif"
              className="syncbutton-img"
            />
          </span>
        ),
      hidden: isUncategorizedMerch ?? false,
    },
    {
      title: 'Log',
      render: (rowData) => (
        <div className="product-log">
          <i
            className="fa fa-book product-log-icon pointer"
            onClick={() => {
              dispatch(setSelectedProduct(rowData.id));
              setToggleProductLog(true);
            }}
          ></i>
        </div>
      ),
      hidden: isUncategorizedMerch ?? false,
    },
  ];

  //actions
  const actions = [
    {
      icon: () => (
        <div className="btn btn-secondary excel-export-btn">
          <i className="fa fa-file"></i> Excel Export
        </div>
      ),
      onClick: () => handleExcelExport(),
      tooltip: 'export',
      isFreeAction: true,
    },
  ];

  //options
  const options = {
    paging: false,
    showTitle: false,
    columnsButton: true,
    selection: true,
    exportButton: true,
    exportFileName: 'MasterCatalog',
    sorting: false,
  };

  const onSelectionChange = (selectedrecords) => {
    const productIds = selectedrecords.map((el) => el.id);
    dispatch(setSelectedProductsItems(productIds));
  };

  const handleExcelExport = () => {
    let excelData = [];
    data.forEach((row) => {
      let excelRow = {};
      excelRow.sku = row.sku;
      excelRow.productType = row.typeString;
      excelRow.productionLine = row.productionLine;
      excelRow.originOps = row.originOps;
      excelRow.productDefinition = row.productDefinitionString;
      excelRow.productStatus = row.productStatusString;
      excelRow.name = row.name;
      excelRow.price = row.price?.toFixed(opsCenter?.country?.decimalNumber ?? 3);
      excelRow.cost = row.cost?.toFixed(opsCenter?.country?.decimalNumber ?? 3);
      excelRow.margin = `${row.margin?.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`;
      excelRow.orderCount = row.orderCount;
      excelRow.availableQty = row.availableQty;
      excelRow.views = row.views;
      excelData.push(excelRow);
    });
    exportToExcel(excelData, 'catalogData');
  };

  return (
    <>
      {toggleProductLog && (
        <RightSideModal
          isPaneOpen={toggleProductLog}
          setIsPaneOpen={setToggleProductLog}
          direction="right"
          width="80%"
        >
          <ProductLogsDataTable toggleProductLog={toggleProductLog} />
        </RightSideModal>
      )}
      <MaterialDataGrid
        data={data}
        columns={columns}
        options={options}
        actions={actions}
        onSelectionChange={onSelectionChange}
        testId="LocalAvilabilityDataGridTestID"
      />
    </>
  );
};

export default LocalAvilabilityDataGrid;
