import React from 'react';
import ProductInventoryDataGrid from '../productsDataGrid/ProductInventoryDataGrid';

const ProductInventorySection = ({ productInventoryById }) => {
  return (
    <div className="no-toolbar-table-style">
      <ProductInventoryDataGrid productInventoryById={productInventoryById} />
    </div>
  );
};

export default ProductInventorySection;
