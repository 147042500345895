import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import { convertUtcToLocalTime, HasPermission } from 'constant/Helpers';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
import has from 'lodash/has';
import toString from 'lodash/toString';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';

const VouchersDataGrid = ({ data }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }
  };

  const codeTemplate = (rowData) =>
    HasPermission(permissionsList, 'vouchers', 'edit_voucher') ? (
      <a
        href={`/coupon/${rowData.couponId}/edit`}
        className="codeLink"
        target="_blank"
        rel="noreferrer"
      >
        {rowData.code}
      </a>
    ) : (
      <p>{rowData.code}</p>
    );

  const createdDateTemplate = (rowData) => (
    <span>{rowData.createdDate && convertUtcToLocalTime(rowData.createdDate)}</span>
  );

  const startDateTemplate = (rowData) => (
    <span>{rowData.startDate && convertUtcToLocalTime(rowData.startDate)}</span>
  );

  const expiredDate = (rowData) => (
    <span>{rowData.expireDate && convertUtcToLocalTime(rowData.expireDate)}</span>
  );

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Code"
              template={codeTemplate}
              width={120}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.code}
            />
            <ColumnDirective
              headerText="Created Date"
              template={createdDateTemplate}
              customExcelTemplate={(rowData) =>
                rowData.createdDate && convertUtcToLocalTime(rowData.createdDate)
              }
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Amount"
              field="amount"
              template={(rowData) => <span>{rowData.amount}%</span>}
              customExcelTemplate={(rowData) => rowData.amount}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Max Usage" field="maxTime" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Start Date"
              template={startDateTemplate}
              customExcelTemplate={(rowData) =>
                rowData.startDate && convertUtcToLocalTime(rowData.startDate)
              }
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Expired Date"
              template={expiredDate}
              customExcelTemplate={(rowData) =>
                rowData.expireDate && convertUtcToLocalTime(rowData.expireDate)
              }
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Time of Usage"
              field="timeOfUsage"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Comment" field="comment" width={150} textAlign="Center" />
            <ColumnDirective
              headerText="Category"
              field="catNameEn"
              width={180}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(VouchersDataGrid);
