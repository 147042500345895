/* eslint-disable indent */
import { updateObject } from '../utility';
import { getFromLocalStorage, addToLocalStorage } from 'localStorageService/LocalStorage';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import {
  START_SOCKET_CONNECTION,
  START_SOCKET_CONNECTION_FAIL,
  START_SOCKET_CONNECTION_SUCCESS,
  STOP_SOCKET_CONNECTION,
  STOP_SOCKET_CONNECTION_FAIL,
  STOP_SOCKET_CONNECTION_SUCCESS,
} from './AppActionTypes';
// constants
import { momentIsAfter } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const initialState = {
  isLoggedIn: getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)
    ? momentIsAfter(
        new Date(jwt_decode(getFromLocalStorage(localStorageKeys.ACCESS_TOKEN))?.exp * 1000),
        new Date()
      )
    : false,
  isLoaderOpen: false,
  isSectionLoaderOpen: false,
  decodedToken: '',
  permissions: getFromLocalStorage(localStorageKeys.PERMISSIONS),
  connectionStatus: '',
  sliderImg: '',
  countriesListData: [],
  isLoggedoutClicked: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_LOGGED_IN_TRUE': {
      return updateObject(state, { isLoggedIn: true });
    }
    case 'SET_IS_LOGGED_IN_FALSE': {
      localStorage.removeItem('ACCESS_TOKEN');
      localStorage.removeItem('opsId');
      localStorage.removeItem('countryId');
      localStorage.removeItem('permissions');
      localStorage.removeItem('opsCenter');
      localStorage.removeItem('isMuted');
      return updateObject(state, { isLoggedIn: false });
    }
    case 'SET_IS_LOGGED_OUT_CLICKED': {
      return updateObject(state, { isLoggedoutClicked: true });
    }
    case 'SET_LOADER_OPEN_TRUE': {
      return updateObject(state, { isLoaderOpen: true });
    }
    case 'SET_LOADER_OPEN_FALSE': {
      return updateObject(state, { isLoaderOpen: false });
    }

    case 'SET_SECTION_LOADER_OPEN_TRUE': {
      return updateObject(state, { isSectionLoaderOpen: true });
    }
    case 'SET_SECTION_LOADER_OPEN_FALSE': {
      return updateObject(state, { isSectionLoaderOpen: false });
    }
    case 'DECODED_TOKEN': {
      return updateObject(state, {
        decodedToken: jwt_decode(getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)),
      });
    }
    case 'SET_USER_PERMISSIONS': {
      addToLocalStorage(localStorageKeys.PERMISSIONS, action.payload);
      return updateObject(state, { permissions: action.payload });
    }
    case 'SET_SLIDER_IMG': {
      return updateObject(state, { sliderImg: action.payload });
    }

    case 'SET_COUNTRIES_LIST_DATA': {
      return updateObject(state, { countriesListData: action.payload });
    }

    // socketio
    case START_SOCKET_CONNECTION: {
      return updateObject(state, { connectionStatus: 'connecting' });
    }
    case START_SOCKET_CONNECTION_SUCCESS: {
      return updateObject(state, { connectionStatus: 'connected' });
    }
    case START_SOCKET_CONNECTION_FAIL: {
      return updateObject(state, { connectionStatus: 'connection failed' });
    }
    case STOP_SOCKET_CONNECTION: {
      return updateObject(state, { connectionStatus: 'disconnecting' });
    }
    case STOP_SOCKET_CONNECTION_SUCCESS: {
      return updateObject(state, { connectionStatus: 'disconnected' });
    }
    case STOP_SOCKET_CONNECTION_FAIL: {
      return updateObject(state, { connectionStatus: 'disconnection failed' });
    }
    default:
      return state;
  }
};

export default reducer;
