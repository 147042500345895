import React from 'react';
import SectionLoader from 'components/common/SectionLoader';
const NewBanner = React.lazy(() => import('components/banners/NewBanner'));

const NewBannerPage = () => {
  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <NewBanner />
      </React.Suspense>
    </div>
  );
};

export default NewBannerPage;
