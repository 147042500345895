import React from 'react';
import { Link } from 'react-router-dom';
import RedFlagIcon from 'components/shared/RedFlagIcon';

// menu item (has no children) without permission
const MenuItem = ({
  path,
  active,
  clicked,
  Icon,
  title,
  isCustomIcon,
  isChild,
  alertQtyItems,
  childOfChild,
  centerType,
}) => (
  <Link
    to={path}
    style={{
      display: title === 'Coverage areas' && centerType !== 2 ? 'none' : 'block',
    }}
    className={`menu-item-link no-wrap ${isChild ? 'child-menu-item-link' : 'sidebar-header'} ${
      active ? 'active' : ''
    } ${childOfChild ? 'child-menu-flex' : ''}`}
    onClick={() => clicked()}
  >
    {!isCustomIcon ? (
      <Icon className="light-grey-color" />
    ) : (
      <i className={`fa ${Icon} sidebar-icon ${childOfChild ? 'child-font-sz' : ''}`} />
    )}
    <span className={`${childOfChild ? 'child-of-child' : ''}`}>{title}</span>
    {alertQtyItems && title === 'Alerts' && <RedFlagIcon title={title} />}
  </Link>
);

export default MenuItem;
