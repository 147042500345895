import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setCheckoutPaymentDetails } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getCheckoutPaymentDetails } from 'store/viewOrder/ViewOrderSelectors';

const CheckoutPaymentDetails = ({ orderId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const checkoutPaymentData = useSelector((state) => getCheckoutPaymentDetails({ state }));
  const dispatch = useDispatch();

  const fetchCheckoutPaymentData = async (orderId) => {
    setIsLoaderOpen(true);
    await dispatch(setCheckoutPaymentDetails(orderId));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchCheckoutPaymentData(orderId);
  }, []);

  return (
    <div className="row card checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          Payment Details <i className="fa fa-cc-visa"></i>
        </h4>
      </div>

      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        checkoutPaymentData.map((item, i) => (
          <div key={i} className="recent-notification checkout-content card-body row">
            <div className="col-4">
              <span>Card holder:</span>
              <p>{item.customer.name}</p>
            </div>
            <div className="col-6 col-sm-4">
              <span>Issuing country:</span>
              <p>{item.source.issuerCountry}</p>
            </div>
            <div className="col-6 col-sm-4">
              <span>Issuing bank:</span>
              <p>{item.source.issuer}</p>
            </div>
            <div className="col-6 col-sm-4">
              <span>Card number:</span>
              <p>{`${item.source.bin}****${item.source.last4}`}</p>
            </div>
            <div className="col-6 col-sm-4">
              <span>CVV check:</span>
              <p>{item.source.cvvCheck}</p>
            </div>
            <div className="col-6 col-sm-4">
              <span>Card Expiry date:</span>
              <p>{`${item.source.expiryYear} / ${item.source.expiryMonth}`}</p>
            </div>
            <div className="col-6 col-sm-4">
              <span>AVS check:</span>
              <p>{item.source.avsCheck}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default CheckoutPaymentDetails;
