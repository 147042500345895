import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { CardHeader, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { dateFormat } from 'constant/Helpers';
// actions
import { setActionsBox } from 'store/viewOrder/ViewOrderActions';
import {
  setRequiredTableRowData,
  setToggleNoteModalOpen,
  setPremiumTableData,
  setTableData,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// api
import ViewOrderService from 'services/ViewOrderService';
import QuickActionService from 'services/QuickActionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const CommentBox = ({ orderId, isAction, orderNote, premium, paymentDate }) => {
  const [newComment, setNewComment] = useState(isAction ? orderNote : '');
  const isAfter20May = new Date(dateFormat(paymentDate)) > new Date('05/20/2022');
  const [note, setNote] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleNewComment = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('comment', `Note: ${newComment}`);
    setDisableBtn(true);
    try {
      if (isAction) {
        const dataBody = {
          value: note,
        };
        await QuickActionService.AddNote(dataBody, orderId);
        dispatch(
          setRequiredTableRowData({
            id: orderId,
            field: { name: 'note', value: note },
          })
        );
        setTimeout(() => {
          dispatch(setToggleNoteModalOpen());
          setDisableBtn(false);

          toast.success('Comment added successfully!');
          if (premium) {
            dispatch(setPremiumTableData(''));
          } else {
            dispatch(setTableData(''));
          }
        }, 1500);
      } else {
        await ViewOrderService.addNewComment(bodyFormData, orderId);
        setTimeout(() => {
          dispatch(setActionsBox(orderId, isAfter20May));
        }, 1500);
        setDisableBtn(false);
        setNewComment('');
        toast.success('Comment added successfully!');
      }
    } catch (e) {
      errorLogger('CommentBox:handleNewComment', e);
      if (isAction) {
        dispatch(setToggleNoteModalOpen());
        setDisableBtn(false);
      }
      setDisableBtn(false);
      toast.error('Failed to add new comment!');
    }
  };

  return (
    <div className="col-12 comment-box-wrapper">
      {!isAction && (
        <CardHeader className="card-header-border">
          <h5>
            Comment Box <i className="fa fa-comments"></i>
          </h5>
        </CardHeader>
      )}
      <Fragment>
        <CardBody className="recent-notification">
          <form className="theme-form e-commerce-form row">
            <div className="form-group col-12">
              {!isAction && <label htmlFor="add-comment">Add a comment</label>}
              <textarea
                id="add-comment"
                className="form-control"
                rows="2"
                placeholder="Comment"
                onChange={(e) => {
                  setNewComment(e.target.value);
                  setNote(e.target.value);
                }}
                value={newComment}
              ></textarea>
            </div>
            <div className="col-12 text-right">
              <button
                className="btn btn-primary "
                type="button"
                onClick={handleNewComment}
                disabled={!newComment || disableBtn}
              >
                Add
              </button>
            </div>
          </form>
        </CardBody>
      </Fragment>
    </div>
  );
};

export default CommentBox;
