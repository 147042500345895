import React from 'react';
import { toast } from 'react-toastify';
// api
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SalesOfficersContent = ({ orderData, orderId, salesOfficerList }) => {
  const handleSalesOfficerChange = async (e) => {
    try {
      await ViewOrderService.setSalesOfficer(orderId, e.itemData.id);
      toast.success('Sales Officer changed successfully!');
    } catch (err) {
      errorLogger('SalesOfficersContent:handleSalesOfficerChange', err);
      toast.error('Failed to change sales Officer');
    }
  };

  return (
    <div className="card row">
      <div className="card-body">
        <div className="row">
          <div className="form-group col-6 p-r-0 filter-select">
            <label htmlFor="sales-officer" className="sales-officers-title">
              Sales Officer
            </label>
            <CustomSelect
              id="sales-officer"
              listData={salesOfficerList}
              handleChange={handleSalesOfficerChange}
              placeholder="Select a sales officer"
              textPropName="name"
              valuePropName="id"
              selectedValue={orderData.salesOfficer ?? 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesOfficersContent;
