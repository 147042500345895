import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// components
import has from 'lodash/has';
import toString from 'lodash/toString';
import CustomSelect from 'components/shared/CustomSelect';
// constant
import { dateFormatDDMM } from 'constant/Helpers';
// actions
import { deleteCelebrity } from 'store/celebrities/CelebritiesActions';

const CelebritiesDataGrid = ({ data, inputValues }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const dispatch = useDispatch();
  const toolbarClick = (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        ref.current.excelExport();
        break;
      default:
        break;
    }
  };

  const deleteCel = (e, id) => {
    dispatch(deleteCelebrity(id, e.itemData.id, inputValues));
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <Link to={`/celebrity/${rowData.id}/edit`} className="total-txt link area-link">
          {parseInt(rowData.id, 10)}
        </Link>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <Link to={`/celebrity/${rowData.id}/edit`} className="total-txt link area-link">
        {rowData.name}
      </Link>
    );
  };

  const nameArTemplate = (rowData) => {
    return (
      <Link to={`/celebrity/${rowData.id}/edit`} className="total-txt link area-link">
        {rowData.nameAr}
      </Link>
    );
  };

  const birthTemplate = (rowData) => {
    return (
      <div className="dateDiv">
        <p className="dateRow">{rowData.birthDate && dateFormatDDMM(rowData.birthDate)}</p>
      </div>
    );
  };

  const iconTemplate = (rowData) => {
    return <img className="celebrity-icon" alt={rowData.name} src={rowData.productCoverImgEn} />;
  };

  const startDateTemplate = (rowData) => {
    return (
      <div className="dateDiv">
        <p className="dateRow">{rowData.startDate && dateFormatDDMM(rowData.startDate)}</p>
      </div>
    );
  };

  const endDateTemplate = (rowData) => {
    return (
      <div className="dateDiv">
        <p className="dateRow">{rowData.endDate && dateFormatDDMM(rowData.endDate)}</p>
      </div>
    );
  };

  const deleteTemplate = (rowData) => {
    return (
      <div className="form-group col-12 filter-select no-margin">
        <CustomSelect
          listData={[
            { id: true, name: 'Active' },
            { id: false, name: 'Inactive' },
          ]}
          handleChange={(e) => deleteCel(e, rowData.id)}
          placeholder="Select a Status"
          textPropName="name"
          valuePropName="id"
          selectedValue={rowData.isActive}
        />
      </div>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          allowTextWrap={true}
          showColumnChooser={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="Id"
              headerText="ID"
              template={idTemplate}
              width={60}
              customExcelTemplate={(rowData) => rowData.id}
            />
            <ColumnDirective
              field="Name"
              headerText="Name EN"
              template={nameTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.name}
            />
            <ColumnDirective
              field="NameAr"
              headerText="Name AR"
              template={nameArTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.nameAr}
            />
            <ColumnDirective
              field="BirthDate"
              headerText="Birth Date"
              template={birthTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.birthDate}
            />
            <ColumnDirective
              field="productCoverImgEn"
              headerText="Image"
              template={iconTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.icon}
              textAlign="Center"
            />
            <ColumnDirective
              field="StartDate"
              headerText="Start Date"
              template={startDateTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.startDate}
            />
            <ColumnDirective
              field="EndDate"
              headerText="End Date"
              template={endDateTemplate}
              width={140}
              customExcelTemplate={(rowData) => rowData.endDate}
            />
            <ColumnDirective textAlign="Center" template={deleteTemplate} width={100} />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(CelebritiesDataGrid);
