import React from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';

const CheckoutLogsDataGrid = ({ data, orderData }) => {
  const toolbarOptions = ['Search'];

  const handleTimeStamp = (rowData) => {
    const dateObject = new Date(rowData.processedOn);
    const humanDateFormat = dateObject.toLocaleString();
    return humanDateFormat;
  };

  const handleType = (rowData) => {
    if (rowData.approved) return <span className="green-title">{`${rowData.type} : Success`}</span>;
    else return <span className="red-title">{`${rowData.type} : Failed`}</span>;
  };
  const referenceNumberTemplate = (rowData) => (
    <span>
      {rowData?.processing?.retrievalReferenceNumber ??
        rowData?.processing?.acquirerReferenceNumber ??
        'N/A'}
    </span>
  );

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={false}
          toolbar={toolbarOptions}
          allowTextWrap={true}
          rowHeight={50}
          showColumnChooser={false}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="TimeStamp" template={handleTimeStamp} width={150} />
            <ColumnDirective headerText="Status" template={handleType} width={150} />
            <ColumnDirective
              headerText="Reference Number"
              template={referenceNumberTemplate}
              width={150}
            />
            <ColumnDirective headerText="Response Code" field="responseCode" width={80} />
            <ColumnDirective headerText="Response Summary" field="responseSummary" width={100} />
            <ColumnDirective
              headerText="Amount"
              template={(rowData) => (
                <span>{`${orderData.ops.currencyISO} ${(
                  rowData.amount /
                  (orderData.ops.decimalNumber ? Math.pow(10, orderData.ops.decimalNumber) : 1)
                ).toFixed(orderData.ops.decimalNumber && orderData.ops.decimalNumber)}`}</span>
              )}
              width={100}
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(CheckoutLogsDataGrid);
