import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
// actions
import { getOldNotifications } from 'store/notifications/actions/NotificationsActions';
// selectors
import {
  getOldNotificationsList,
  getNotificationsPageSize,
  getNotificationsCurrentPage,
  getNotificationsRowCount,
} from 'store/notifications/selectors/NotificationsSelectors';
// components
import SectionLoader from 'components/common/SectionLoader';
const NotificationsDataTable = React.lazy(() =>
  import('components/notifications/NotificationsDataTable')
);
const DJHPagination = React.lazy(() => import('components/shared/Pagination'));

const Notifications = () => {
  const notificationsList = useSelector((state) => getOldNotificationsList({ state }));
  const pageSize = useSelector((state) => getNotificationsPageSize({ state }));
  const currentPage = useSelector((state) => getNotificationsCurrentPage({ state }));
  const rowCount = useSelector((state) => getNotificationsRowCount({ state }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOldNotifications(1));
  }, []);

  const onPageChange = (i) => {
    dispatch(getOldNotifications(i));
    document.documentElement.scrollTop = 0;
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <PageTitle title="Notifications" />
        <React.Suspense fallback={<SectionLoader />}>
          <NotificationsDataTable notificationsList={notificationsList} />
          <DJHPagination
            onPageChange={onPageChange}
            currentPage={+currentPage}
            pageSize={+pageSize}
            itemsCount={+rowCount}
          />
        </React.Suspense>
      </div>
    </Fragment>
  );
};

export default Notifications;
