/* eslint-disable indent */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import PageTitle from 'components/shared/PageTitle';
import RightSideModal from 'components/shared/RightSideModal';
import StatementsContent from './StatementsContent';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { BarChart } from 'react-feather';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { convertObjectIntoQueryParams, HasPermission } from 'constant/Helpers';
import { localStorageKeys, inventoryTypes } from 'constant/Enums';
// selector
import { getPermissionsList } from 'store/app/AppSelectors';

const InventoryDataGrid = ({
  data,
  setClickedRow,
  setIsEditItemQtyModalOpen,
  isAllItemsPage,
  isAllCitiesPage,
}) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const [isStatmentModalOpen, setIsStatementModalOpen] = useState(false);
  const [statementItemId, setStatementItemId] = useState('');
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);
  const [statementParams, setStatementParams] = useState({ fromDate: 0, toDate: 0 });
  const ref = useRef();

  const masterCatalogParams = {
    catalogPage: 0,
    page: 1,
    opsId: getFromLocalStorage('opsId'),
    key: '',
    type: -1,
    stock: -1,
    productionline: 0,
    productdefination: -1,
    status: 1,
    qty: -1,
    categories: null,
    priceTo: '',
    originOps: 0,
    inventoryBarcode: '',
  };

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const imageTemplate = (rowData) => (
    <img
      alt=""
      className="img-contain"
      src={isAllCitiesPage ? rowData.parentFullImage : rowData.fullImage || rowData.img}
    />
  );

  const priceTemplate = (rowData) => (
    <span>{`${rowData.price.toFixed(opsCenter?.country?.decimalNumber ?? 3)} ${
      opsCenter?.country?.currency
    }`}</span>
  );

  const retailPriceTemplate = (rowData) => (
    <span>{`${rowData.retailPrice} ${opsCenter?.country?.currency}`}</span>
  );

  const qtyTemplate = (rowData) => (
    <div
      className={`inventory-item-qty ${rowData.qty === 0 ? 'red' : 'primary'}`}
      onClick={() => {
        setClickedRow(rowData);
        setIsEditItemQtyModalOpen((prev) => !prev);
      }}
    >
      {rowData.qty} <br></br>
    </div>
  );

  const alertQtyTemplate = (rowData) => (
    <div
      className={`inventory-item-qty ${
        rowData.qty <= rowData.alertQty ? 'red bold-title' : 'black normal-text'
      }`}
    >
      {rowData.alertQty} <br></br>
    </div>
  );

  const nameTemplate = (rowData) => (
    <a
      href={
        HasPermission(permissionsList, 'inventory', 'edit_inventory') ||
        HasPermission(permissionsList, 'inventory', 'inventory_care')
          ? `/inventory/${rowData.inventoryId}/editInventory/${
              isAllItemsPage ? rowData.invid : rowData.id
            }`
          : ''
      }
      className="primary-color pointer"
      target="_blank"
      rel="noreferrer"
    >
      {rowData.parentName || rowData.name}
    </a>
  );

  const typeTemplate = (rowData) => {
    const inType = inventoryTypes.filter(function (invType) {
      if (isAllCitiesPage) {
        return invType.id === rowData.parentType;
      } else {
        return invType.id === rowData.type;
      }
    });
    return <p>{inType.length > 0 ? inType[0].name : ''}</p>;
  };

  const statementsTemplate = (rowData) => (
    <BarChart
      className="primary-color pointer"
      onClick={() => {
        setStatementItemId(isAllItemsPage ? rowData.invid : rowData.id);
        setIsStatementModalOpen((prev) => !prev);
      }}
    />
  );

  const productTemplate = (rowData) => (
    <a
      href={`/masterCatalog/activeInstock${convertObjectIntoQueryParams({
        ...masterCatalogParams,
        inventoryBarcode: rowData.barCode,
      })}`}
      target="_blank"
      rel="noreferrer"
      className="primary-color pointer"
    >
      {rowData.numberofproduct}
    </a>
  );

  return (
    <div className="toolbar-table-style">
      {isStatmentModalOpen && (
        <RightSideModal
          isPaneOpen={isStatmentModalOpen}
          setIsPaneOpen={setIsStatementModalOpen}
          direction="right"
        >
          <StatementsContent
            statementItemId={statementItemId}
            statementParams={statementParams}
            setStatementParams={setStatementParams}
          />
        </RightSideModal>
      )}
      <div className="control-pane">
        <PageTitle title="Inventory" />
        <div className="control-section">
          <GridComponent
            dataSource={data}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            allowSorting={true}
            ref={ref}
            toolbarClick={toolbarClick}
            allowTextWrap={true}
            showColumnChooser={true}
            excelQueryCellInfo={excelQueryCellInfo}
            pdfQueryCellInfo={excelQueryCellInfo}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText="Image"
                template={imageTemplate}
                customExcelTemplate={(rowData) => rowData.fullImage || rowData.img}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="BarCode"
                field={isAllCitiesPage ? 'parentBarCode' : 'barCode'}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Name"
                template={nameTemplate}
                customExcelTemplate={(rowData) => rowData.name}
                width={150}
                textAlign="Center"
              />
              {isAllItemsPage && (
                <ColumnDirective
                  headerText="Serial Number"
                  field="itemSerial"
                  width={150}
                  textAlign="Center"
                />
              )}
              <ColumnDirective
                headerText="Price"
                customExcelTemplate={(rowData) =>
                  `${rowData.price.toFixed(opsCenter?.country?.decimalNumber ?? 3)} ${
                    opsCenter?.country?.currency
                  }`
                }
                template={priceTemplate}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Retail Price"
                customExcelTemplate={(rowData) => rowData.retailPrice}
                template={retailPriceTemplate}
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Available QTY"
                field="qty"
                template={qtyTemplate}
                customExcelTemplate={(rowData) => rowData.qty}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Alert QTY"
                field="alertQty"
                template={alertQtyTemplate}
                customExcelTemplate={(rowData) => rowData.alertQty}
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Status"
                field={isAllItemsPage ? 'statusString' : 'status'}
                width={120}
                textAlign="Center"
              />
              {isAllCitiesPage && (
                <ColumnDirective headerText="City" field="opsName" width={120} textAlign="Center" />
              )}
              <ColumnDirective
                headerText="Type"
                field="type"
                width={120}
                template={typeTemplate}
                textAlign="Center"
              />
              {!isAllItemsPage && (
                <ColumnDirective
                  headerText="#Product"
                  template={productTemplate}
                  field="numberofproduct"
                  customExcelTemplate={(rowData) => rowData.numberofproduct}
                  width={80}
                  textAlign="Center"
                />
              )}
              <ColumnDirective
                headerText="Statements"
                template={statementsTemplate}
                width={80}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Supplier"
                field="supplier"
                width={100}
                textAlign="Center"
              />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser, Sort]} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default React.memo(InventoryDataGrid);
