import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const ProductTopTenDataGrid = ({ data, title }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseInt(rowData.index, 10) + 1}</p>
      </div>
    );
  };

  const skuTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.sku}</p>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.name}</p>
      </div>
    );
  };

  const nameArTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.nameAr}</p>
      </div>
    );
  };

  const totalViewsTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.totalViews}</p>
      </div>
    );
  };

  const totalRevenuTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.totalRevenu}</p>
      </div>
    );
  };

  const totalSoldTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.totalSold}</p>
      </div>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }
  };

  return (
    <div className="control-pane">
      <div className="card-header p-18">
        <h5 className="sz-14">{title}</h5>
      </div>
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="#"
              template={idTemplate}
              width={50}
              customExcelTemplate={(rowData) => rowData.id}
            />
            <ColumnDirective
              field="sku"
              headerText="SKU"
              template={skuTemplate}
              width={90}
              customExcelTemplate={(rowData) => rowData.sku}
            />
            <ColumnDirective
              field="name"
              headerText="Name"
              template={nameTemplate}
              width={150}
              customExcelTemplate={(rowData) => rowData.name}
            />
            <ColumnDirective
              field="nameAr"
              headerText="Name Arabic"
              template={nameArTemplate}
              width={150}
              customExcelTemplate={(rowData) => rowData.nameAr}
            />
            <ColumnDirective
              field="totalViews"
              headerText="Total View"
              template={totalViewsTemplate}
              width={90}
              customExcelTemplate={(rowData) => rowData.totalViews}
            />
            <ColumnDirective
              field="totalRevenu"
              headerText="Total Revenue"
              template={totalRevenuTemplate}
              width={90}
              customExcelTemplate={(rowData) => rowData.totalRevenu}
            />
            <ColumnDirective
              field="totalSold"
              headerText="Total Sold"
              template={totalSoldTemplate}
              width={90}
              customExcelTemplate={(rowData) => rowData.totalSold}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(ProductTopTenDataGrid);
