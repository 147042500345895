import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import AddDeliverySlotsModal from './AddDeliverySlotsModal';
import RestrictedSection from 'components/shared/RestrictedSection';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject, dateFormat } from 'constant/Helpers';
import { errorLogger } from 'datadog/DDUtils';
import DeliverySlotsFilter from './DeliverySlotsFilter';
// actions
import { setAllDeliverySlots } from 'store/delivery/deliverySlots/slotsActions';
// selectors
import { getAllDeliverySlotsData } from 'store/delivery/deliverySlots/slotsSelectors';
// api
import DeliveryService from 'services/DeliveryService';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  CommandColumn,
  Edit,
} from '@syncfusion/ej2-react-grids';
// material UI
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

const typeString = {
  0: 'Delivery',
  1: 'PickUp',
};

const DeliverySlotsDataTable = () => {
  const deliverySlotsData = useSelector((state) => getAllDeliverySlotsData({ state }));
  const [isAddNewSlotModalOpen, setIsAddNewSlotModalOpen] = useState(false);
  const [inputValues, setInputValues] = useState({ line: 0, status: 0 });
  const [isEditing, setIsEditing] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [modifiedRow, setModifiedRow] = useState('');
  const dispatch = useDispatch();
  const editOptions = {
    allowEditing: true,
    mode: 'Normal',
    allowEditOnDblClick: false,
    allowScrolling: true,
  };
  const commandOptions = [
    { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
    { type: 'Save', buttonOption: { cssClass: 'e-flat', iconCss: 'e-check e-icons' } },
    { type: 'Cancel', buttonOption: { cssClass: 'e-flat', iconCss: 'e-close e-icons' } },
  ];
  const ref = useRef();
  const location = useLocation();

  const fetchData = (params) => {
    dispatch(setAllDeliverySlots(params));
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      handleTableTitle();
    }
  }, []);

  useEffect(() => {
    handleTableTitle();
  }, [location.search]);

  const handleRowClick = (args) => {
    if (
      args.target &&
      (args.target.className.indexOf('e-btn-icon') > -1 ||
        args.target.className.indexOf('e-icon-btn') > -1 ||
        args.target.className.indexOf('e-unboundcelldiv') > -1)
    ) {
      args.Cancel = true;
    } else {
      setModifiedRow(args.data);
      setIsAddNewSlotModalOpen((prev) => !prev);
    }
  };

  const checkSelection = (args) => {
    if (
      args.target &&
      (args.target.className.indexOf('e-btn-icon') > -1 ||
        args.target.className.indexOf('e-icon-btn') > -1 ||
        args.target.className.indexOf('e-unboundcelldiv') > -1)
    ) {
      args.Cancel = true;
    }
  };

  const handleTableTitle = () => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      if (queryObj.line === '0') setIsPremium(false);
      else setIsPremium(true);
    }
  };

  const gridActions = async (args) => {
    if (args.requestType === 'beginEdit') {
      const weekdays = [
        'saturday',
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
      ];
      ref.current.columns.forEach((column) => {
        if (weekdays.includes(column.field)) column.allowEditing = !args.rowData.isCustomizable;
      });
      setIsEditing(true);
    }
    if (args.requestType === 'cancel') {
      setIsEditing(false);
    }
    if (args.requestType === 'save' && isEditing) {
      args.cancel = true;
      try {
        const res = await DeliveryService.updateDeliverySlot(args.data, args.data.id);
        const dataUpdate = [...deliverySlotsData];
        const index = dataUpdate.findIndex((d) => d.id === args.data.id);
        dataUpdate[index] = args.data;
        dispatch({
          type: 'SET_DELIVERY_SLOTS_ALL_DATA',
          payload: dataUpdate,
        });
        setIsEditing(false);
        toast.success('Delivery slot updated');
      } catch (err) {
        errorLogger('DeliverySlotsDataTable:handleUpdateSlot', err);
        toast.error('Failed to update delivery slot');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setInputValues(convertQueryParamsIntoObject(location.search));
    }
  }, [location.search]);
  return (
    <div className="quick-actions-table">
      {isAddNewSlotModalOpen && (
        <AddDeliverySlotsModal
          isAddNewSlotModalOpen={isAddNewSlotModalOpen}
          setIsAddNewSlotModalOpen={setIsAddNewSlotModalOpen}
          params={inputValues}
          modifiedRow={modifiedRow}
          setModifiedRow={setModifiedRow}
        />
      )}
      <div className="card">
        <div className="card-header">
          <button
            onClick={() => setIsAddNewSlotModalOpen((prev) => !prev)}
            className="btn btn-success"
          >
            <i className="fa fa-plus"></i> Add New Slot
          </button>
        </div>
      </div>
      <RestrictedSection page="delivery_slots" action="archive_slots">
        <DeliverySlotsFilter
          handleSubmit={() => fetchData(inputValues)}
          inputValues={inputValues}
          setInputValues={setInputValues}
        />
      </RestrictedSection>
      <Paper>
        <Toolbar>
          <Typography variant="h6">
            {isPremium ? (
              <>
                Premium Delivery Slots <i className="fa fa-star sz-20 orange-color"></i>
              </>
            ) : (
              'Delivery Slots'
            )}
          </Typography>
        </Toolbar>
        <div className="quick-actions-table mui-table-styling mui-table-slots">
          <div className="control-pane">
            <div className="control-section">
              <GridComponent
                ref={ref}
                dataSource={deliverySlotsData}
                allowTextWrap={true}
                allowSorting={true}
                rowSelecting={checkSelection}
                rowSelected={handleRowClick}
                editSettings={editOptions}
                actionBegin={gridActions}
              >
                <ColumnsDirective>
                  <ColumnDirective headerText="Actions" width="40" commands={commandOptions} />
                  <ColumnDirective
                    field="name"
                    headerText="Time (From:To)"
                    headerTextAlign="Left"
                    width={80}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="maxNumbeOfOrders"
                    headerText="Max Orders"
                    headerTextAlign="Left"
                    width={50}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="fromTime"
                    headerText="Accept Orders (From:To)"
                    template={(rowData) => (
                      <p>
                        {rowData.fromTime && rowData.fromTime} : {rowData.toTime && rowData.toTime}
                      </p>
                    )}
                    headerTextAlign="Left"
                    customExcelTemplate={(rowData) =>
                      rowData.fromTime &&
                      rowData.fromTime + ' : ' + rowData.toTime &&
                      rowData.toTime
                    }
                    width={80}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="deliveryFromTime"
                    headerText="Delivery (From:To)"
                    template={(rowData) => (
                      <p>
                        {rowData.deliveryFromTime && rowData.deliveryFromTime} :{' '}
                        {rowData.deliveryToTime && rowData.deliveryToTime}
                      </p>
                    )}
                    headerTextAlign="Left"
                    customExcelTemplate={(rowData) =>
                      rowData.deliveryFromTime &&
                      rowData.deliveryFromTime + ' : ' + rowData.deliveryToTime &&
                      rowData.deliveryToTime
                    }
                    width={80}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="showAtDate"
                    headerText="Delivery Dates(From:To)"
                    template={(rowData) => (
                      <p className="align-center">
                        {rowData?.showAtDate &&
                          `${dateFormat(rowData.showAtDate)} - ${dateFormat(rowData.hideAtDate)}`}
                      </p>
                    )}
                    headerTextAlign="Left"
                    customExcelTemplate={(rowData) =>
                      rowData?.showAtDate &&
                      `${dateFormat(rowData.showAtDate)} - ${dateFormat(rowData.hideAtDate)}`
                    }
                    width={80}
                    allowEditing={false}
                  />

                  <ColumnDirective
                    field="deliveryCharges"
                    headerText="Delivery Charges"
                    template={(rowData) => (
                      <p className="align-center">
                        {rowData.deliveryCharges ? rowData.deliveryCharges : '0'}
                      </p>
                    )}
                    headerTextAlign="Left"
                    customExcelTemplate={(rowData) =>
                      rowData.deliveryCharges ? rowData.deliveryCharges : '0'
                    }
                    width={100}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="type"
                    headerText="Type"
                    template={(rowData) => <p>{typeString[rowData.type]}</p>}
                    headerTextAlign="Left"
                    customExcelTemplate={(rowData) => typeString[rowData.type]}
                    width={70}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="isActive"
                    headerText="Active"
                    headerTextAlign="Center"
                    textAlign="Center"
                    type={'Boolean'}
                    editType={'booleanEdit'}
                    template={(rowData) => (
                      <p className="tes-mui-icon">{rowData.isActive ? 'check' : 'remove'}</p>
                    )}
                    width={60}
                  />

                  <ColumnDirective
                    field="saturday"
                    headerText="Sat"
                    headerTextAlign="Center"
                    textAlign="Center"
                    type={'Boolean'}
                    editType={'booleanEdit'}
                    template={(rowData) => (
                      <p className="tes-mui-icon">{rowData.saturday ? 'check' : 'remove'}</p>
                    )}
                    width={45}
                  />
                  <ColumnDirective
                    field="sunday"
                    headerText="Sun"
                    headerTextAlign="Center"
                    textAlign="Center"
                    type={'Boolean'}
                    editType={'booleanEdit'}
                    template={(rowData) => (
                      <p className="tes-mui-icon">{rowData.sunday ? 'check' : 'remove'}</p>
                    )}
                    width={45}
                  />
                  <ColumnDirective
                    field="monday"
                    headerText="Mon"
                    headerTextAlign="Center"
                    textAlign="Center"
                    type={'Boolean'}
                    editType={'booleanEdit'}
                    template={(rowData) => (
                      <p className="tes-mui-icon">{rowData.monday ? 'check' : 'remove'}</p>
                    )}
                    width={45}
                  />
                  <ColumnDirective
                    field="tuesday"
                    headerText="Tues"
                    headerTextAlign="Center"
                    textAlign="Center"
                    type={'Boolean'}
                    editType={'booleanEdit'}
                    template={(rowData) => (
                      <p className="tes-mui-icon">{rowData.tuesday ? 'check' : 'remove'}</p>
                    )}
                    width={45}
                  />
                  <ColumnDirective
                    field="wednesday"
                    headerText="Wed"
                    headerTextAlign="Center"
                    textAlign="Center"
                    type={'Boolean'}
                    editType={'booleanEdit'}
                    template={(rowData) => (
                      <p className="tes-mui-icon">{rowData.wednesday ? 'check' : 'remove'}</p>
                    )}
                    width={45}
                  />
                  <ColumnDirective
                    field="thursday"
                    headerText="Thu"
                    headerTextAlign="Center"
                    textAlign="Center"
                    type={'Boolean'}
                    editType={'booleanEdit'}
                    template={(rowData) => (
                      <p className="tes-mui-icon">{rowData.thursday ? 'check' : 'remove'}</p>
                    )}
                    width={45}
                  />
                  <ColumnDirective
                    field="friday"
                    headerText="Fri"
                    headerTextAlign="Center"
                    textAlign="Center"
                    type={'Boolean'}
                    editType={'booleanEdit'}
                    template={(rowData) => (
                      <p className="tes-mui-icon">{rowData.friday ? 'check' : 'remove'}</p>
                    )}
                    width={45}
                  />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, CommandColumn]} />
              </GridComponent>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default DeliverySlotsDataTable;
