import React from 'react';
import PageTitle from 'components/shared/PageTitle';
import OrderedItemsDataTable from './OrderedItemsDataTable';

const OrderedItemsPage = () => {
  return (
    <div>
      <PageTitle title="Ordered Items" />
      <OrderedItemsDataTable />
    </div>
  );
};

export default OrderedItemsPage;
