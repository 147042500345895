import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { errorLogger } from 'datadog/DDUtils';
// api
import TicketingService from '../../services/TicketingService';

export const setTopicListData = (params) => async (dispatch) => {
  try {
    const res = await TicketingService.getTopicsList(params);
    dispatch({
      type: 'SET_TOPICS_LIST_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('TicketingActions:setTopicListData', err);
    toast.error('Failed to get topics');
  }
};

export const setTicketsListByOrderId = (orderId) => async (dispatch) => {
  try {
    const res = await TicketingService.getTicketsListByOrderId(orderId);
    dispatch({
      type: 'SET_TICKETS_LIST_BY_ORDERID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('TicketingActions:setTicketsListByOrderId', err);
    toast.error('Failed to get order tickets');
  }
};

export const setTicketDetailsData = (ticketId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await TicketingService.getTicketById(ticketId);
    dispatch({
      type: 'SET_TICKET_DETAILS_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('TicketingActions:setTicketDetailsData', err);
    dispatch(setLoaderOpenFalse());
    toast.error('Failed to get ticket details');
  }
};

export const setTicketCommentsList = (ticketId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());

  try {
    const res = await TicketingService.getTicketCommentsList(ticketId);
    dispatch({
      type: 'SET_TICKET_COMMENTS_LIST',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('TicketingActions:setTicketCommentsList', err);
    dispatch(setLoaderOpenFalse());
    toast.error('Failed to get ticket comments');
  }
};

export const setTicketsPageList = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await TicketingService.getTicketsList(params);
    dispatch({
      type: 'SET_TICKETS_PAGE_LIST',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('TicketingActions:setTicketsPageList', err);
    dispatch(setLoaderOpenFalse());
    toast.error('Failed to get tickets!');
  }
};

export const setToggleTechTicketModal = () => ({
  type: 'TOGGLE_TECH_TICKET_MODAL_OPEN',
});
