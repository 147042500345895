import React from 'react';
import CustomSelect from 'components/shared/CustomSelect';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// constants
import { handleImageFile } from 'constant/Helpers';
import { attributeTypesList } from 'constant/Enums';

const CommonInfo = ({ type, dataObj, updateDataObj }) => {
  return (
    <div className="row">
      {type === 'child' && (
        <div className="form-group col-12">
          <label className="font-weight-bold" htmlFor="partent-attr">
            Parent Attribute
          </label>
          <input
            id="partent-attr"
            value={dataObj.attributeName}
            disabled
            className="form-control"
            type="text"
          />
        </div>
      )}
      <div className="form-group col-6">
        <label className="font-weight-bold" htmlFor="attr-enName">
          Display name English
        </label>
        <input
          id="attr-enName"
          value={dataObj.enName}
          onChange={(e) => updateDataObj('enName', e.target.value)}
          className="form-control"
          type="text"
          data-testid="EnglishNameInputTestId"
        />
      </div>
      <div className="form-group col-6">
        <label className="font-weight-bold" htmlFor="attr-arName">
          Display name Arabic
        </label>
        <input
          id="attr-arName"
          value={dataObj.arName}
          onChange={(e) => updateDataObj('arName', e.target.value)}
          className="form-control"
          type="text"
          data-testid="ArabicNameInputTestId"
        />
      </div>
      <div className="form-group col-6">
        <label className="font-weight-bold" htmlFor="attr-enDesc">
          Description English
        </label>
        <input
          id="attr-enDesc"
          value={dataObj.enDesc}
          onChange={(e) => updateDataObj('enDesc', e.target.value)}
          className="form-control"
          type="text"
          data-testid="EnglishDescInputTestId"
        />
      </div>
      <div className="form-group col-6">
        <label className="font-weight-bold" htmlFor="attr-arDesc">
          Description Arabic
        </label>
        <input
          id="attr-arDesc"
          value={dataObj.arDesc}
          onChange={(e) => updateDataObj('arDesc', e.target.value)}
          className="form-control"
          type="text"
          data-testid="ArabicDescInputTestId"
        />
      </div>
      {type === 'parent' && (
        <div className="form-group col-6">
          <label className="font-weight-bold" htmlFor="attr-key">
            Key
          </label>
          <input
            id="attr-key"
            value={dataObj.attKey}
            onChange={(e) => updateDataObj('attKey', e.target.value)}
            className="form-control"
            type="text"
            data-testid="AttKeyInputTestId"
          />
        </div>
      )}
      {type === 'parent' && (
        <div className="form-group col-6 filter-select category-select">
          <label className="font-weight-bold" htmlFor="attr-type">
            Type
          </label>
          <AppErrorBoundary>
            <CustomSelect
              id="attr-type"
              listData={attributeTypesList}
              handleChange={(e) => updateDataObj('attType', e.itemData.id)}
              placeholder="Select a color"
              textPropName="name"
              valuePropName="id"
              selectedValue={dataObj.attType}
            />
          </AppErrorBoundary>
        </div>
      )}
      {type === 'child' && (
        <div className="form-group col-6">
          <label className="font-weight-bold" htmlFor="attr-val">
            Value
          </label>
          <input
            id="attr-val"
            value={dataObj.attValue}
            onChange={(e) => updateDataObj('attValue', e.target.value)}
            className="form-control"
            type="text"
            data-testid="AttributeValueInputTestId"
          />
        </div>
      )}
      {type === 'child' && (
        <div className="form-group col-6">
          <label className="font-weight-bold" htmlFor="attr-extra-val">
            Extra Value
          </label>
          <input
            id="attr-extra-val"
            value={dataObj.attExtraVal}
            onChange={(e) => updateDataObj('attExtraVal', e.target.value)}
            className="form-control"
            type="text"
            data-testid="AttributeExtraValueInputTestId"
          />
        </div>
      )}
      {type === 'child' && (
        <div className="form-group col-12 col-md-3">
          <label className="font-weight-bold" htmlFor="cenImgFilev">
            Image EN
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="enImgFile"
              onChange={(e) => updateDataObj('enImgFile', e.target.files[0])}
              data-testid="ImageEnInputTestId"
            />
            <label htmlFor="enImgFile">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            data-testid="ImageArDivTestId"
            style={{ backgroundImage: `url(${handleImageFile(dataObj.enImgFile)})` }}
          ></div>
        </div>
      )}
      {type === 'child' && (
        <div className="form-group col-12 col-md-3">
          <label className="font-weight-bold" htmlFor="arImgFile">
            Image AR
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="arImgFile"
              onChange={(e) => updateDataObj('arImgFile', e.target.files[0])}
              data-testid="ImageArInputTestId"
            />
            <label htmlFor="arImgFile">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            data-testid="ImageArDivTestId"
            style={{ backgroundImage: `url(${handleImageFile(dataObj.arImgFile)})` }}
          ></div>
        </div>
      )}
      {type === 'child' && (
        <div className="form-group col-12 col-md-3">
          <label className="font-weight-bold" htmlFor="enAppFile">
            App Image EN
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="enAppFile"
              onChange={(e) => updateDataObj('enAppImgFile', e.target.files[0])}
              data-testid="AppImageEnInputTestId"
            />
            <label htmlFor="enAppFile">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            data-testid="ImageArDivTestId"
            style={{ backgroundImage: `url(${handleImageFile(dataObj.enAppImgFile)})` }}
          ></div>
        </div>
      )}
      {type === 'child' && (
        <div className="form-group col-12 col-md-3">
          <label className="font-weight-bold" htmlFor="arAppFile">
            App Image AR
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="arAppFile"
              onChange={(e) => updateDataObj('arAppImgFile', e.target.files[0])}
              data-testid="AppImageArInputTestId"
            />
            <label htmlFor="arAppFile">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            data-testid="ImageArDivTestId"
            style={{ backgroundImage: `url(${handleImageFile(dataObj.arAppImgFile)})` }}
          ></div>
        </div>
      )}
      <div className="form-group col-12 ">
        <input
          className="checkbox_animated"
          type="checkbox"
          checked={dataObj.isActive}
          onChange={(e) => updateDataObj('isActive', !dataObj.isActive)}
          data-testid="IsActiveCheckboxInputTestId"
        />
        <span className="font-weight-bold">Active</span>
      </div>
    </div>
  );
};

export default CommonInfo;
