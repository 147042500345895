import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import SalesPerformanceDetailsDataGrid from 'components/reports/inventory/SalesPerformanceDetailsDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// urls
import { getSalesPerformanceDetailsReportPageUrl } from 'constant/AppUrls';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import InventoryReportService from 'services/reportsService/InventoryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SalesPerformanceDetailsReportPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: '',
    toDate: '',
    inventoryItem: null,
    status: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await InventoryReportService.getSalesPerformanceDetailsReport(params);
      const updatedList = res.data.data.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      setData(updatedList);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Sales Performance Details Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: getSalesPerformanceDetailsReportPageUrl(),
      search: queryString,
    });
    fetchData(inputValues);
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        SalesInventoryItem
        inventoryStatus
        title="Inventory Sales Performance Details Report"
        defaultDate
      />
      <SalesPerformanceDetailsDataGrid data={data} />
    </div>
  );
};

export default SalesPerformanceDetailsReportPage;
