import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
import MessageModal from './MessageModal';
import { toast } from 'react-toastify';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const target = {
  0: 'Sender',
  1: 'Recipient',
  2: 'Hub',
};

const AdminMessage = () => {
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messagesList, setMessagesList] = useState([]);
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [editedMessage, setEditedMessage] = useState('');
  const dispatch = useDispatch();

  const fetchMessagesList = async () => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await PermissionService.adminGetMessagesList();
      setMessagesList(res.data.data);
    } catch (err) {
      errorLogger('AdminMessage:fetchMessagesList', err);
      toast.error(err?.response?.data?.message ?? 'Failed to get messages');
    }
    dispatch(setLoaderOpenFalse());
  };

  useEffect(() => {
    fetchMessagesList();
  }, []);

  return (
    <Fragment>
      {isMessageModalOpen && (
        <MessageModal
          isMessageModalOpen={isMessageModalOpen}
          setIsMessageModalOpen={setIsMessageModalOpen}
          isEditMessage={isEditMessage}
          editedMessage={editedMessage}
          setIsEditMessage={setIsEditMessage}
          setMessagesList={setMessagesList}
        />
      )}
      <PageTitle title="Admin" />
      <div className="card">
        <div className="card-header">
          <button
            onClick={() => {
              setIsMessageModalOpen((prev) => !prev);
              setIsEditMessage(false);
            }}
            className="btn btn-success"
          >
            <i className="fa fa-plus"></i> Add Message
          </button>
        </div>

        <div className="recent-notification card-body">
          <div className="table-responsive text-center">
            <table className="table table-bordernone">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Messsage</th>
                  <th scope="col">Key</th>
                  <th scope="col">Locale</th>
                  <th scope="col">Target</th>
                  <th></th>
                </tr>
              </thead>
              {messagesList.length > 0 && (
                <tbody>
                  {messagesList.map((message, i) => (
                    <tr key={i}>
                      <td>{message.id}</td>
                      <td>{message.message}</td>
                      <td>{message.key}</td>
                      <td>{message.locale}</td>
                      <td>{target[message.target]}</td>
                      <td>
                        <button
                          onClick={() => {
                            setIsMessageModalOpen((prev) => !prev);
                            setIsEditMessage(true);
                            setEditedMessage(message);
                          }}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminMessage;
