/* eslint-disable indent */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RestrictedSection from 'components/shared/RestrictedSection';
// constant
import { brailleIcon } from 'constant/Enums';
// actions
import { setOrderDetailsPrinted } from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getQuickActionsIsPrintedBtnDisabled,
  getTableData,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// material UI
import Tooltip from '@mui/material/Tooltip';

const OrderBlock = ({
  id,
  detailsPrinted,
  membershipType,
  premium,
  isBraillePrint,
  giftPickUp,
  isQuickActions,
  isQuickPrinting,
}) => {
  const dispatch = useDispatch();
  const tableData = useSelector((state) => getTableData({ state }));
  const isPrintedBtnDisabled = useSelector((state) =>
    getQuickActionsIsPrintedBtnDisabled({ state })
  );

  return (
    <div className="d-flex flex-column align-items-center">
      <div>
        <RestrictedSection page="quick_actions" action="view_priority">
          {Array.isArray(tableData.data)
            ? tableData?.data?.filter(function (d) {
                return d.id === id;
              })[0]?.orderPriority === 1 && (
                <Tooltip title="Hight priority">
                  <i className="fa fa-exclamation-circle priority-flag" aria-hidden="true"></i>
                </Tooltip>
              )
            : null}
        </RestrictedSection>
        <a href={`/order/${id}`} target="_blank" rel="noopener noreferrer" className="link">
          {id}
        </a>
      </div>

      <button
        disabled={isPrintedBtnDisabled[id]}
        onClick={() =>
          dispatch(
            setOrderDetailsPrinted(
              id,
              detailsPrinted,
              premium ?? false,
              isQuickActions,
              isQuickPrinting
            )
          )
        }
        className={`btn generic-status btn-pointer ${
          detailsPrinted ? `btn-light-success` : `btn-light-danger`
        }`}
      >
        {detailsPrinted ? 'Printed' : 'Not Printed'}
      </button>
      {giftPickUp && <div className="vip-order btn-purple">Gift pick-up</div>}
      {isBraillePrint && (
        <Tooltip title="Braille">
          <img src={brailleIcon} alt="Action-box" className="braille-icon" />
        </Tooltip>
      )}
      {membershipType === 3 && <div className="vip-order">VIP</div>}
    </div>
  );
};

export default OrderBlock;
