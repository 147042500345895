import React from 'react';
import SectionLoader from 'components/common/SectionLoader';

const CountrySlotsDataTable = React.lazy(() =>
  import('components/countrySlots/CountrySlotsDataTable')
);

const CountrySlotsPage = () => {
  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <CountrySlotsDataTable />
      </React.Suspense>
    </div>
  );
};

export default CountrySlotsPage;
