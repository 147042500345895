import React from 'react';
import { useParams } from 'react-router-dom';
// components
import SectionLoader from 'components/common/SectionLoader';
const SubCategoriesDataTable = React.lazy(() =>
  import('components/categories/subcategories/SubCategoriesDataTable')
);

const SecondSubCategoriesPage = () => {
  const params = useParams();

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <SubCategoriesDataTable categoryId={params?.id} allowSubLevel={false} />
      </React.Suspense>
    </div>
  );
};

export default SecondSubCategoriesPage;
