import { updateObject } from '../utility';

const initialState = {
  invoices: [],
  corporateInvoices: [],
  selectedCorporateInvoice: [],
  consolidatedInvoicesData: [],
  generatedConsolidatedInvoicesData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_INVOICES_DATA': {
      return updateObject(state, {
        invoices: action.payload,
      });
    }
    case 'GET_CORPORATE_INVOICES_DATA': {
      return updateObject(state, {
        corporateInvoices: action.payload,
      });
    }
    case 'SET_SELECTED_CORPORATE_INVOICES': {
      return updateObject(state, {
        selectedCorporateInvoice: action.payload,
      });
    }
    case 'GET_CONSOLIDATED_INVOICES_DATA': {
      return updateObject(state, {
        consolidatedInvoicesData: action.payload,
      });
    }
    case 'GET_GENERATED_CONSOLIDATED_INVOICES': {
      return updateObject(state, {
        generatedConsolidatedInvoicesData: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
