import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Group,
} from '@syncfusion/ej2-react-grids';
import { dateFormatDDMM } from 'constant/Helpers';

const WalletTransactionsDataGrid = ({ data }) => {
  const ref = useRef();
  const groupOptions = { columns: ['country'] };

  const orderIdTemplate = (rowData) => <span>{rowData.mapId <= 0 ? 'N/A' : rowData.mapId}</span>;

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          ref={ref}
          allowTextWrap={true}
          allowGrouping={true}
          groupSettings={groupOptions}
        >
          <ColumnsDirective>
            <ColumnDirective field="country" headerText="Country" width="120" />
            <ColumnDirective
              field="country"
              headerText="Country"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="mapId"
              template={orderIdTemplate}
              headerText="Order ID"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Credit"
              field="credit"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Debit"
              field="debit"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Balance"
              field="balance"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Created by"
              field="createdUser"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Date"
              template={(rowData) =>
                rowData.transactionDate && dateFormatDDMM(rowData.transactionDate)
              }
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Remarks"
              field="remarks"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Group]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(WalletTransactionsDataGrid);
