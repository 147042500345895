import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// components
import ZiwoCallsSharedComponent from './ZiwoCallsSharedComponent';
// actions
import { setSecondaryZiwoCallsData } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getZiwoSecondaryCallsData } from 'store/viewOrder/ViewOrderSelectors';

const SecondaryRecepientCalls = ({ senderNumber, secondaryRecipientNumber }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const ziwoSecondaryCallsData = useSelector((state) => getZiwoSecondaryCallsData({ state }));
  const dispatch = useDispatch();

  const fetchIncomingCalls = async () => {
    if (senderNumber) {
      const dataBody = {
        senderNumber,
        recipientNumber: secondaryRecipientNumber ?? null,
      };
      setIsLoaderOpen(true);
      await dispatch(setSecondaryZiwoCallsData(dataBody));
      setIsLoaderOpen(false);
    } else {
      toast.error('Sender number is incorrect');
    }
  };

  useEffect(() => {
    fetchIncomingCalls();
  }, []);

  return (
    <>
      <ZiwoCallsSharedComponent
        ziwoCallsData={ziwoSecondaryCallsData}
        isLoaderOpen={isLoaderOpen}
        title="Ziwo (Extra Numbers)"
      />
    </>
  );
};

export default SecondaryRecepientCalls;
