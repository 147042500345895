import { apiGenerateShorterLink } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class ShorterLinkService {
  static getGenerateShorterLink(data, ...extraParams) {
    return apiGenerateShorterLink({
      method: 'POST',
      url: setEndPoint(endPoints.getGenerateShorterLink, ...extraParams),
      data,
    });
  }

  static getShorterLinksListData(params, ...extraParams) {
    return apiGenerateShorterLink({
      method: 'GET',
      url: setEndPoint(endPoints.getGenerateShorterLink, ...extraParams),
      params,
    });
  }
}
export default ShorterLinkService;
