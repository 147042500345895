import React, { useState, Fragment } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// api
import InventoryService from 'services/InventoryService';

const UploadExcelFile = ({ isUploadExcelModalOpen, setIsUploadExcelModalOpen }) => {
  const [file, setFile] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleUploadFile = async () => {
    setIsLoaderOpen(true);
    setDisableBtn(true);
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      const res = await InventoryService.uploadInventoryItem(bodyFormData);
      setIsLoaderOpen(false);
      setDisableBtn(false);
      setIsUploadExcelModalOpen((prev) => !prev);
      toast.success(res?.data?.message ?? 'Uploaded');
    } catch (err) {
      errorLogger('UploadExcelFile:handleUploadFile', err);
      setIsLoaderOpen(false);
      setDisableBtn(false);
      setIsUploadExcelModalOpen((prev) => !prev);
      toast.error(err?.response?.data?.message ?? 'Failed to upload');
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isUploadExcelModalOpen}
        toggle={() => setIsUploadExcelModalOpen((prev) => !prev)}
        className="m-w-600"
      >
        <ModalBody>
          {isLoaderOpen ? (
            <SectionLoader height="120px" />
          ) : (
            <Fragment>
              <div className="row">
                <div className="form-group col-12">
                  <label className="col-form-label pt-0" htmlFor="excel-file">
                    Upload Excel
                  </label>
                  <input
                    id="excel-file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="form-control"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                  <a
                    className="col-form-label mt-1 template-download-link"
                    href="https://cdn.floward.com/web/Files/attachment/inventoryformat-637744550071838808.xlsx"
                    title="Download inventory template"
                  >
                    Download template
                  </a>
                </div>
              </div>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleUploadFile} color="primary" disabled={disableBtn || !file}>
            Import
          </Button>
          <Button color="light" onClick={() => setIsUploadExcelModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadExcelFile;
