import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// components
import SectionLoader from 'components/common/SectionLoader';
import UserAddressesDataGrid from '../userAddresses/UserAddressesDataGrid';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UserAddressesModal = ({ isUserAddressesModalOpen, setIsUserAddressesModalOpen, userId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [addressesList, setAddressesList] = useState('');

  const fetchAddressesList = async () => {
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getUserLastAddresses(userId);
      setIsLoaderOpen(false);
      setAddressesList(res.data);
    } catch (err) {
      setIsLoaderOpen(false);
      errorLogger('UserAddressesModal:fetchAddressesList', err);
      toast.error('Failed to get user addresses!');
    }
  };

  useEffect(() => {
    fetchAddressesList();
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isUserAddressesModalOpen}
        toggle={() => setIsUserAddressesModalOpen((prev) => !prev)}
        className="user-address-modal"
      >
        <ModalBody>
          <div>
            {isLoaderOpen ? (
              <SectionLoader height="300px" />
            ) : (
              <div className="table-responsive shopping-table text-center">
                {addressesList && <UserAddressesDataGrid data={addressesList} />}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 align-center">
            <Button onClick={() => setIsUserAddressesModalOpen((prev) => !prev)} color="light">
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserAddressesModal;
