import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class KpiService {
  static getSalesPerformanceByCountryData(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getSalesPerformanceByCountry, ...extraParams),
      params,
    });
  }

  static getSalesPerformanceData(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getSalesPerformance, ...extraParams),
      params,
    });
  }

  static getSalesPerformanceByChannelData(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getSalesPerformanceByChannel, ...extraParams),
      params,
    });
  }
}
export default KpiService;
