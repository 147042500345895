import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { calcPercentage } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const AreasBreakDownDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const percentageCountTemplate = (rowData) => {
    return (
      <p className="total-txt">{rowData.count > 0 ? calcPercentage(data, rowData.count) : 0}</p>
    );
  };

  const percentageTotalTemplate = (rowData) => {
    return (
      <p className="total-txt">
        {rowData.count > 0 ? calcPercentage(data, rowData.grandTotal) : 0}
      </p>
    );
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="Area" field="name" width={120} textAlign="Center" />
            <ColumnDirective
              headerText="Total Orders"
              field="count"
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="%"
              width={100}
              template={percentageCountTemplate}
              customExcelTemplate={(rowData) =>
                rowData.count > 0 ? calcPercentage(data, rowData.count) : 0
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Total Revenue USDT"
              template={(rowData) => (
                <span>
                  {rowData.revenuUSD &&
                    rowData.revenuUSD.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                  %
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.revenuUSD &&
                rowData.revenuUSD.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Total Revenue Local"
              field="grandTotal"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="%"
              width={100}
              template={percentageTotalTemplate}
              customExcelTemplate={(rowData) =>
                rowData.count > 0 ? calcPercentage(data, rowData.grandTotal) : 0
              }
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(AreasBreakDownDataGrid);
