import { toast } from 'react-toastify';
import DeliveryService from 'services/DeliveryService';
import { errorLogger } from 'datadog/DDUtils';

export const setDriversData = (data) => async (dispatch) => {
  dispatch({
    type: 'SET_DRIVERS_DATA',
    payload: data,
  });
};

export const setAllItemsAllOpsExport = (params) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_IS_EXPORTING',
      payload: true,
    });
    const res = await DeliveryService.exportDriversData({ ...params, page: -1 });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Drivers.xlsx');
    document.body.appendChild(link);
    link.click();
    dispatch({
      type: 'SET_IS_EXPORTING',
      payload: false,
    });
    toast.success(res?.data?.message ?? 'Drivers exported successfully');
  } catch (err) {
    errorLogger('driversActions:setAllItemsAllOpsExport', err);
    dispatch({
      type: 'SET_IS_EXPORTING',
      payload: false,
    });
    toast.error('Failed to download drivers.');
  }
};
