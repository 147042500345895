import React, { Fragment } from 'react';

export const departments = [
  { name: 'Customers', value: 1 },
  { name: 'CEO', value: 2 },
  { name: 'Finance & HR', value: 3 },
  { name: 'Marketing', value: 4 },
  { name: 'Operations', value: 5 },
  { name: 'Technology', value: 6 },
  { name: 'Sales', value: 7 },
];

export const userOrdersColumns = [
  {
    name: 'Order',
    selector: 'order',
    sortable: true,
    cell: (row) => (
      <div className="align-left">
        <a className="user-order-id" href={`/order/${row.id}`} target="_blank" rel="noreferrer">
          {row.id}
        </a>
      </div>
    ),
    width: '100px',
  },
  {
    name: 'Ops',
    selector: 'nickName',
    sortable: true,
    cell: (row) => (
      <div className="normal-flex align-left">
        <p>{row.ops.nickName}</p>
      </div>
    ),
    center: true,
  },
  {
    name: 'Payment Date',
    selector: 'paymentDate',
    sortable: true,
    cell: (row) => (
      <div className="align-left">
        {row.paymentDate !== null && (
          <Fragment>
            <p>{row.paymentDate.slice(0, 10)}</p>
            <p>{row.paymentDate.slice(11, 16)}</p>
          </Fragment>
        )}
      </div>
    ),
  },
  {
    name: 'Delivery Date',
    selector: 'paymentDate',
    sortable: true,
    cell: (row) => (
      <div className="align-left">
        {row.deliveryDate !== null && (
          <Fragment>
            <p>{row.deliveryDate.slice(0, 10)}</p>
            <p>{row.deliveryDate.slice(11, 16)}</p>
          </Fragment>
        )}
      </div>
    ),
  },
  {
    name: 'Payment Status',
    selector: 'paymentString',
    sortable: true,
    left: true,
  },
  {
    name: 'Current Step',
    selector: 'cStepString',
    sortable: true,
    left: true,
  },
  {
    name: 'Order Status',
    selector: 'statusString',
    sortable: true,
    left: true,
  },
];

export const userAddressesColumns = [
  {
    name: 'Order',
    selector: 'order',
    sortable: true,
    cell: (row) => (
      <div className="align-left user-order-id">
        <a href={`/order/${row.id}`} target="_blank" rel="noreferrer">
          {row.id}
        </a>
      </div>
    ),
    width: '100px',
  },
  {
    name: 'Recipient Name',
    selector: 'recipientName',
    center: true,
  },
  {
    name: 'Recipient Phone',
    selector: 'recipientPhone',
    sortable: true,
  },
  {
    name: 'Area',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Address',
    selector: 'addressLineOne',
    sortable: true,
    cell: (row) => (
      <div className="align-left">
        <p>{row.addressLineOne}</p>
      </div>
    ),
    width: '400px',
  },
];
