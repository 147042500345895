import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import SubInventories from './SubInventories';
// constants
import { dateFormatDDMM, timeFormatHHMM, HasPermission } from 'constant/Helpers';
import { inventoryTypes } from 'constant/Enums';
// modal
import RightSideModal from 'components/shared/RightSideModal';
// actions
import { getMasterInventoryChildren } from 'store/masterInventory/MasterInventoryActions';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';

const MasterInventoryDataGrid = ({ data }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const toolbarOptions = ['Search'];
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const ref = useRef();
  const dispatch = useDispatch();

  const getInventoryChildren = (id) => {
    dispatch(getMasterInventoryChildren(id));
  };

  const nameTemplate = (rowData) => (
    <a
      href={
        HasPermission(permissionsList, 'edit_master_inventory', 'view')
          ? `/master-inventory/${rowData.id}/edit/`
          : ''
      }
      className="primary-color pointer"
      target="_blank"
      rel="noopener noreferrer"
    >
      {rowData.name}
    </a>
  );

  const typeTemplate = (rowData) => (
    <p>
      {
        inventoryTypes.filter(function (it) {
          return it.id === rowData.type;
        })[0]?.name
      }
    </p>
  );

  const subInventoriesTemplate = (rowData) => {
    return (
      <p
        className="cat-icon-cell"
        onClick={() => {
          getInventoryChildren(rowData.id);
          setIsSideModalOpen(true);
        }}
      >
        <i className="fa fa-align-justify"></i>
      </p>
    );
  };

  const dateTemplate = (rowData) => (
    <div className="dateDiv">
      <p className="dateRow">{rowData.createdDate && dateFormatDDMM(rowData.createdDate)}</p>
      <p>{rowData.createdDate && timeFormatHHMM(rowData.createdDate)}</p>
    </div>
  );

  return (
    <>
      {isSideModalOpen && (
        <RightSideModal
          isPaneOpen={isSideModalOpen}
          setIsPaneOpen={setIsSideModalOpen}
          direction="right"
          width="80%"
        >
          <SubInventories />
        </RightSideModal>
      )}
      <div className="control-pane">
        <div className="control-section">
          <GridComponent
            dataSource={data}
            toolbar={toolbarOptions}
            ref={ref}
            showColumnChooser={true}
            rowHeight={60}
            allowTextWrap={true}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText="Image"
                template={(rowData) => <img alt="" className="img-contain" src={rowData.img} />}
                customExcelTemplate={(rowData) => rowData.img}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Barcode"
                field="barCode"
                customExcelTemplate={(rowData) => rowData.barCode}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Name"
                template={nameTemplate}
                customExcelTemplate={(rowData) => rowData.name}
                width={150}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Type"
                template={typeTemplate}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Sub inventories"
                template={subInventoriesTemplate}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Created at"
                template={dateTemplate}
                width={120}
                textAlign="Center"
              />
            </ColumnsDirective>
            <Inject services={[Toolbar]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
};

export default React.memo(MasterInventoryDataGrid);
