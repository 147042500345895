import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllItemsAllOpsExport } from 'store/delivery/drivers/driversActions';
import { getIsExporting } from 'store/delivery/drivers/driversSelector';

const DriversActions = ({ setIsDriverModalOpen, setClickedDriver, inputValues }) => {
  const dispatch = useDispatch();
  const isExporting = useSelector((state) => getIsExporting({ state }));

  const handleExport = () => {
    dispatch(setAllItemsAllOpsExport(inputValues));
  };

  return (
    <>
      <div className="card">
        <div className="card-body row new-driver">
          <div className="btn-showcase">
            <button
              className="btn btn-success"
              onClick={() => {
                setClickedDriver('');
                setIsDriverModalOpen((prev) => !prev);
              }}
            >
              <i className="fa fa-plus"></i> Add New
            </button>
            <button className="btn btn-secondary" onClick={handleExport} disabled={isExporting}>
              {isExporting ? (
                'Downloading..'
              ) : (
                <>
                  <i className="fa fa-download"></i> Download
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriversActions;
