import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// actions
import { setPremiumGiftData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DeleteGiftModal = ({ isDeletegiftModalOpen, setIsDeleteGiftModalOpen, orderId }) => {
  const dispatch = useDispatch();

  const handleDeleteCard = async () => {
    try {
      await ViewOrderService.deletePremiumGift(orderId);
      dispatch(setPremiumGiftData(orderId));
      setIsDeleteGiftModalOpen((prev) => !prev);
      toast.success('Gift deleted successfully');
    } catch (e) {
      errorLogger('DeleteGiftModal:handleDeleteCard', e);
      setIsDeleteGiftModalOpen((prev) => !prev);
      toast.error('Failed to delete a gift');
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isDeletegiftModalOpen}
        toggle={() => setIsDeleteGiftModalOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setIsDeleteGiftModalOpen((prev) => !prev)}>
          Delete Gift
        </ModalHeader>
        <ModalBody>
          <p className="delete-card-confirmation-title">Are you sure?</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleDeleteCard} color="danger">
            Delete
          </Button>
          <Button color="light" onClick={() => setIsDeleteGiftModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteGiftModal;
