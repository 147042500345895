/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  DetailRow,
  Page,
  Sort,
} from '@syncfusion/ej2-react-grids';
// material UI
import Tooltip from '@mui/material/Tooltip';
// constant
import { dateFormat, HasPermission } from 'constant/Helpers';
// acrions
import { setSelectedSize } from 'store/products/ProductsActions';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';

const ProductSizesDataGrid = ({ productSizesDataById, setIsAddSizeModalOpen, setModalType }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const [availabilityArr, setAvailabilityArr] = useState([]);
  const dispatch = useDispatch();

  const updatedArr = [];

  const imgTemplate = (rowData) => (
    <img alt={rowData.name} src={rowData.fullImage} className="w-70p" />
  );

  const dateTemplate = (rowData) => <p>{dateFormat(rowData.size.createdOn)}</p>;

  const actionTemplate = (rowData) => (
    <div className="d-flex flex-column btns-column">
      <Tooltip title="Edit">
        <i
          onClick={() => {
            setIsAddSizeModalOpen(true);
            setModalType('edit');
            dispatch(setSelectedSize(rowData));
          }}
          className="fa fa-edit icon"
        />
      </Tooltip>
    </div>
  );

  const basicColumns = [
    { field: 'fullImage', headerText: 'Image', textAlign: 'Center', template: imgTemplate },
    { field: 'countryName', headerText: 'Country', textAlign: 'Center' },
    { field: 'opsName', headerText: 'Ops', textAlign: 'Center' },
    { field: 'height', headerText: 'Height', textAlign: 'Center' },
    { field: 'width', headerText: 'Width', textAlign: 'Center' },
    { field: 'cost', headerText: 'Cost', textAlign: 'Center' },
    { field: 'price', headerText: 'Price', textAlign: 'Center' },
    { field: 'discountPrice', headerText: 'Discount Price', textAlign: 'Center' },
    { field: 'margin', headerText: 'Margin', textAlign: 'Center' },
  ];

  useEffect(() => {
    productSizesDataById.forEach((item) => {
      updatedArr.push(...item.price);
    });
    setAvailabilityArr(updatedArr);
  }, [productSizesDataById]);

  const childGrid = {
    dataSource: availabilityArr,
    queryString: 'size.productSize',
    allowSorting: true,
    sortSettings: {
      columns: [
        {
          field: 'opsName',
          direction: 'Ascending',
        },
      ],
    },
    rowHeight: 60,
    textAlign: 'Center',
    columns: HasPermission(permissionsList, 'edit_product', 'edit_product_size')
      ? basicColumns.concat([
          { headerText: 'Actions', textAlign: 'Center', template: actionTemplate },
        ])
      : basicColumns,
    load() {
      this.parentDetails.parentKeyFieldValue = this.parentDetails.parentRowData.size.productSize;
    },
  };

  return (
    <>
      {availabilityArr.length > 0 && (
        <div className="control-pane">
          <div className="control-section">
            <GridComponent
              dataSource={productSizesDataById}
              allowTextWrap={true}
              childGrid={childGrid}
              allowSorting={true}
            >
              <ColumnsDirective>
                <ColumnDirective
                  headerText="Size"
                  field="size.productSizeString"
                  textAlign="Center"
                  width={100}
                />
                <ColumnDirective
                  headerText="Created at"
                  field="size.createdOn"
                  textAlign="Center"
                  template={dateTemplate}
                  width={100}
                />
              </ColumnsDirective>
              <Inject services={[DetailRow, Page, Sort]} />
            </GridComponent>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(ProductSizesDataGrid);
