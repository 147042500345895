export const SET_REQUIRED_PARAMS_FOR_QUICK_ACTIONS_TABLE =
  '[QUICK_ACTIONS] SET_REQUIRED_PARAMS_FOR_QUICK_ACTIONS_TABLE';

export const SET_QUICK_ACTIONS_TABLE_REF = '[QUICK_ACTIONS] SET_QUICK_ACTIONS_TABLE_REF';

export const TOGGLE_QUICK_ACTIONS_IS_PRINTED_BTN_DISABLED =
  '[QUICK_ACTIONS] TOGGLE_QUICK_ACTIONS_IS_PRINTED_BTN_DISABLED';

export const TOGGLE_QUICK_ACTIONS_IS_SHARE_DETAILS_BTN_DISABLED =
  '[QUICK_ACTIONS] TOGGLE_QUICK_ACTIONS_IS_SHARE_DETAILS_BTN_DISABLED';

export const SET_CURRENT_QUICK_ACTIONS_TABLE_PAGE =
  '[QUICK_ACTIONS] SET_CURRENT_QUICK_ACTIONS_TABLE_PAGE';
