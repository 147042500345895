import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// constants
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { localStorageKeys } from 'constant/Enums';
// actions
import { getConsolidatedInvoicesData } from 'store/invoices/InvoicesActions';
// Selectors
import { getSelectedInvoicesDataList } from 'store/invoices/InvoicesSelectors';
// Services
import InvoicesService from 'services/InvoicesService';

const CorporateInvoicesBtns = ({ consolidated, userId }) => {
  const selectedInvoices = useSelector((state) => getSelectedInvoicesDataList({ state }));
  const [buttonDisableState, setButtonDisableState] = useState(false);
  const [poInput, setPoInput] = useState('');
  const countryId = getFromLocalStorage(localStorageKeys.COUNTRY_ID);
  const dispatch = useDispatch();

  const generateInvoice = async () => {
    const body = {
      invoiceOrder: selectedInvoices,
      CountryId: countryId,
      Link: '',
    };
    const params = { generated: false, po: poInput ? poInput : undefined };

    try {
      if (selectedInvoices.length === 0) {
        throw new Error('No invoices selected');
      } else if (selectedInvoices.length > 200) {
        throw new Error('Please choose less than 200 invoices');
      }
      setButtonDisableState(true);
      if (consolidated) {
        const res = await InvoicesService.generateConsolidatedInvoice(
          {
            ...body,
            userId,
            ConsolidatedId: null,
          },
          params
        );
        window.open(res?.data?.invoices?.link);
        setTimeout(() => {
          const queryObj = { userid: userId, countryid: countryId };
          dispatch(getConsolidatedInvoicesData(queryObj));
        }, 1500);
      } else {
        const res = await InvoicesService.generateInvoice(body);
        if (res?.data?.updatedInvoices?.link) {
          window.open(res?.data?.updatedInvoices?.link);
        }
      }
      toast.success('Invoice generated');
      setButtonDisableState(false);
      setPoInput('');
    } catch (e) {
      toast.error(e.message);
      setButtonDisableState(false);
    }
  };
  const handlePoInput = (e) => {
    setPoInput(e.target.value);
  };
  return (
    <div className="d-flex justify-content-end">
      {countryId == 2 && consolidated ? (
        <>
          <label className="mb-4 mt-4 p-2 f-16 f-w-700" htmlFor="po-nb">
            PO # :
          </label>
          <input
            id="po-nb"
            className="mb-4 mt-4 b-r-4 p-2"
            type="text"
            value={poInput}
            onChange={handlePoInput}
          />
        </>
      ) : null}
      <button
        className="btn btn-primary p-2 m-4"
        onClick={generateInvoice}
        disabled={buttonDisableState}
      >
        Generate Invoice
      </button>
    </div>
  );
};

export default CorporateInvoicesBtns;
