import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constant
import { dateFormat } from 'constant/Helpers';

const InventoryStatementActualDeliveryDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  // custom columns

  const orderIdTemplate = (rowData) => (
    <a
      className="primary-color"
      href={`/order/${rowData.orderId}`}
      target="_blank"
      rel="noreferrer"
    >
      {rowData.orderId}
    </a>
  );

  const deliveredDate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{dateFormat(rowData.deliveredDate)}</p>
      </div>
    );
  };

  const itemIDTemplate = (rowData) => {
    return <p className="total-txt">{rowData.itemId}</p>;
  };

  const priceTemplate = (rowData) => {
    return <p className="total-txt">{rowData.price ?? 0}</p>;
  };

  const totalTemplate = (rowData) => {
    return <p className="total-txt">{rowData.qty * (rowData.price ?? 0)}</p>;
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
          allowSorting={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Order#"
              field="orderId"
              width={50}
              template={orderIdTemplate}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Order status"
              field="orderStatus"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Actual delivery date"
              field="deliveryDate"
              template={deliveredDate}
              customExcelTemplate={(rowData) => dateFormat(rowData.deliveredDate)}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Country"
              field="countryName"
              width={90}
              textAlign="Center"
            />
            <ColumnDirective headerText="City" field="cityName" width={90} textAlign="Center" />
            <ColumnDirective
              headerText="Item#"
              field="itemId"
              width={90}
              template={itemIDTemplate}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Item Name"
              field="itemName"
              width={190}
              textAlign="Center"
            />
            <ColumnDirective headerText="Qty" field="qty" width={60} textAlign="Center" />
            <ColumnDirective
              headerText="Up"
              template={priceTemplate}
              customExcelTemplate={(rowData) => rowData.price ?? 0}
              width={90}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Total (QTY*UP)"
              width={90}
              template={totalTemplate}
              customExcelTemplate={(rowData) => rowData.qty * (rowData.price ?? 0)}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(InventoryStatementActualDeliveryDataGrid);
