// constants
import { updateObject } from '../../utility';
// lodash
import cloneDeep from 'lodash/cloneDeep';
// action types
import {
  SET_SEND_TO_CHANNEL,
  FETCH_NEW_NOTIFICATIONS,
  FETCH_NEW_NOTIFICATIONS_SUCCESS,
  FETCH_NEW_NOTIFICATIONS_FAIL,
  SAVE_RECEIVED_NEW_NOTIFICATIONS,
  SAVE_RECEIVED_NEW_ORDER_NOTIFICATIONS,
  SAVE_RECEIVED_OLD_NOTIFICATIONS,
  SAVE_RECEIVED_OLD_ORDER_NOTIFICATIONS,
} from '../NotificationsActionTypes';

const initialState = {
  oldNotifications: [],
  newNotifications: [],
  orderOldNotifications: [],
  orderNewNotifications: [],
  newNotificationsLength: 0,
  notificationStatus: '',
  isFetching: false,
  pageSize: '',
  currentPage: '',
  rowCount: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEND_TO_CHANNEL: {
      return state;
    }

    // all notifications
    case FETCH_NEW_NOTIFICATIONS: {
      // console.log('Receiving');
      return updateObject(state, { notificationStatus: 'Receiving' });
    }
    case FETCH_NEW_NOTIFICATIONS_SUCCESS: {
      return updateObject(state, { notificationStatus: 'Received' });
    }
    case FETCH_NEW_NOTIFICATIONS_FAIL: {
      // console.log(action.error);
      return updateObject(state, { notificationStatus: 'Failed' });
    }

    case SAVE_RECEIVED_OLD_NOTIFICATIONS: {
      return updateObject(state, {
        oldNotifications: [...state.newNotifications, ...action.payload.data],
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        rowCount: action.payload.rowCount,
      });
    }

    case SAVE_RECEIVED_NEW_NOTIFICATIONS: {
      const oldNotifications = cloneDeep(state.oldNotifications);
      const newNotifications = cloneDeep(state.newNotifications);
      oldNotifications.unshift(action.notifications);
      newNotifications.unshift(action.notifications);
      return updateObject(state, {
        oldNotifications,
        newNotifications,
        newNotificationsLength: state.newNotificationsLength + 1,
      });
    }

    // order notifications
    case SAVE_RECEIVED_OLD_ORDER_NOTIFICATIONS: {
      return updateObject(state, {
        orderOldNotifications: [...state.orderNewNotifications, ...action.payload],
      });
    }

    case SAVE_RECEIVED_NEW_ORDER_NOTIFICATIONS: {
      const orderOldNotifications = cloneDeep(state.orderOldNotifications);
      const orderNewNotifications = cloneDeep(state.orderNewNotifications);
      orderOldNotifications.unshift(action.notifications);
      orderNewNotifications.unshift(action.notifications);
      return updateObject(state, { orderOldNotifications, orderNewNotifications });
    }
    default:
      return state;
  }
};

export default reducer;
