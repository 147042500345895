import React, { useEffect, useState } from 'react';
// components
import SectionLoader from 'components/common/SectionLoader';
import PageTitle from 'components/shared/PageTitle';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// selectors
import { getUserFeedbackList } from 'store/userFeedback/userFeedbackSelectors';
// actions
import { setUserFeedbackList } from 'store/userFeedback/userFeedbackActions';

import { useDispatch, useSelector } from 'react-redux';
const UserFeedbackDataGrid = React.lazy(() =>
  import('components/userFeedback/UserFeedbackDataGrid')
);
const AddNewUserFeedbackModal = React.lazy(() =>
  import('components/userFeedback/modals/AddNewUserFeedback')
);
const UserFeedbackPage = () => {
  const userFeedbackList = useSelector((state) => getUserFeedbackList({ state }));
  const [isUserFeedbackModalOpen, setIsUserFeedbackModalOpen] = useState(false);
  const [clickedUserFeedback, setClickedUserFeedback] = useState('');
  const dispatch = useDispatch();

  const handleClick = (rowData) => {
    setClickedUserFeedback(rowData);
    setIsUserFeedbackModalOpen((prev) => !prev);
  };

  const fetchUserFeedbackList = async () => {
    dispatch(setUserFeedbackList());
  };

  useEffect(() => {
    fetchUserFeedbackList();
  }, []);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        {isUserFeedbackModalOpen && (
          <AppErrorBoundary>
            <AddNewUserFeedbackModal
              isUserFeedbackModalOpen={isUserFeedbackModalOpen}
              setIsUserFeedbackModalOpen={setIsUserFeedbackModalOpen}
              clickedUserFeedback={clickedUserFeedback}
              setClickedUserFeedback={setClickedUserFeedback}
            />
          </AppErrorBoundary>
        )}
        <div className="card">
          <div className="card-body row">
            <button
              className="btn btn-success add-key-button"
              onClick={() => setIsUserFeedbackModalOpen(true)}
            >
              <i className="fa fa-plus"></i> Add New Reason
            </button>
          </div>
        </div>
        <PageTitle title="user feedback" />
        <AppErrorBoundary>
          <UserFeedbackDataGrid data={userFeedbackList} handleClick={handleClick} />
        </AppErrorBoundary>
      </React.Suspense>
    </div>
  );
};

export default UserFeedbackPage;
