import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
//selectors
import { getSelectedAreasIds } from './CoveredAreasSelectors';
//api
import DeliveryService from 'services/DeliveryService';

export const getCoveredAreasList = (page) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_LOADING_TRUE',
    });
    const res = await DeliveryService.getCoveredAreasListData(page);
    dispatch({
      type: 'SET_AREAS_LIST',
      payload: res.data.Data,
    });
    dispatch({
      type: 'SET_LOADING_FALSE',
    });
  } catch (err) {
    dispatch({
      type: 'SET_LOADING_FALSE',
    });
    errorLogger('CoveredAreasActions:getCoveredAreasList', err);
    toast.error('Failed to get coverage areas list');
  }
};

export const setSelectedAreasIds = (data) => async (dispatch, getState) => {
  const state = getState();
  let oldSA = getSelectedAreasIds({ state });
  let isExist = false,
    isDisabled = true;

  oldSA.forEach(function (sp) {
    if (sp.page === data.page) {
      isExist = true;
      sp.areas = data.areas;
    }
    if (sp.areas.length > 0) {
      isDisabled = false;
    }
  });

  if (!isExist) {
    oldSA.push(data);
    if (data.areas.length > 0) {
      isDisabled = false;
    }
  }

  dispatch({
    type: 'SET_SELECTED_AREAS_IDS_LIST',
    payload: { data: oldSA, isDisabled: isDisabled },
  });
};

export const setCoveredAreas = (areaIds) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_DISABLED_TRUE',
    });
    const res = await DeliveryService.setCoveredAreas(areaIds);
    dispatch({
      type: 'SET_DISABLED_FALSE',
    });
    dispatch({
      type: 'CLEAR_SELECTED_IDS',
    });
    toast.success(res?.data?.Message ?? 'Area/s added successfully');
    return res;
  } catch (err) {
    dispatch({
      type: 'SET_DISABLED_FALSE',
    });
    errorLogger('CoveredAreasActions:setCoveredAreas', err);
    toast.error(err?.response?.data?.message ?? 'Failed to add the new area/s');
  }
};

export const clearSelectedIds = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_SELECTED_IDS',
  });
};
