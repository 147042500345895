import { toast } from 'react-toastify';
import ViewOrderService from 'services/ViewOrderService';
import { errorLogger } from 'datadog/DDUtils';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// selectors
import { getDecodedToken } from 'store/app/AppSelectors';

export const setToggleDriverTripModal = ({ tripId, driverId }) => ({
  type: 'TOGGLE_DRIVER_TRIP_MODAL_OPEN',
  payload: { tripId, driverId },
});

export const setViewOrderData = (orderId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await ViewOrderService.getViewOrderDetails(orderId);
    dispatch({
      type: 'SET_VIEW_ORDER_DETAILS',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (e) {
    errorLogger('ViewOrderActions:setViewOrderData', e);
    dispatch(setLoaderOpenFalse());
  }
};

export const updateViewOrderData = (data) => ({
  type: 'UPDATE_VIEW_ORDER_DETAILS',
  payload: data,
});

export const setOrderInvoice = (orderId) => async (dispatch) => {
  // dispatch(setLoaderOpenTrue());
  try {
    const res = await ViewOrderService.getOrderInvoice(orderId);
    dispatch({
      type: 'SET_ORDER_INVOICE',
      payload: res.data,
    });
    // dispatch(setLoaderOpenFalse());
  } catch (e) {
    errorLogger('ViewOrderActions:setOrderInvoice', e);
    // dispatch(setLoaderOpenFalse());
  }
};

export const setCartDetails = (orderId) => async (dispatch, getState) => {
  const state = getState();
  const userId = getDecodedToken({ state }).Id;
  // dispatch(setLoaderOpenTrue());
  try {
    const res = await ViewOrderService.getCartDetails(userId, orderId);
    dispatch({
      type: 'SET_CART_DETAILS',
      payload: res.data,
    });
    // dispatch(setLoaderOpenFalse());
  } catch (e) {
    errorLogger('ViewOrderActions:setCartDetails', e);
    dispatch(setLoaderOpenFalse());
  }
};
export const setCardMessage = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getCardMessage(orderId);
    dispatch({
      type: 'SET_CARD_MESSAGE',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setCardMessage', e);
    // console.log(e);
  }
};

export const setCustomerCall = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getCustomerCalls(orderId);
    dispatch({
      type: 'SET_CUSTOMER_CALL',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setCustomerCall', e);
    // console.log(e);
  }
};

export const setInventoryData = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getInventory(orderId);
    dispatch({
      type: 'SET_INVENTORY',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setInventoryData', e);
    // console.log(e);
  }
};

export const setActionsBox = (orderId, isAfter20May) => async (dispatch) => {
  try {
    let res;
    if (isAfter20May) res = await ViewOrderService.getActionsBoxLogsData(orderId);
    else res = await ViewOrderService.getActionsBox(orderId);
    dispatch({
      type: 'SET_ACTIONS_BOX_DATA',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setActionsBox', e);
    // console.log(e);
  }
};

export const setDriverData = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getDriverData(orderId);
    dispatch({
      type: 'SET_DRIVER_DATA',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setDriverData', e);
    // console.log(e);
  }
};

// export const setAdjustLog = (orderId) => async (dispatch) => {
//   try {
//     const res = await ViewOrderService.getAdjustLog(orderId);
//     dispatch({
//       type: 'SET_ADJUST_LOG',
//       payload: res.data,
//     });
//   } catch (e) {
//     console.log(e);
//   }
// };

// export const setAdjustData = (orderId) => async (dispatch) => {
//   try {
//     const res = await ViewOrderService.getAdjustData(orderId);
//     dispatch({
//       type: 'SET_ADJUST_DATA',
//       payload: res.data,
//     });
//   } catch (e) {
//     console.log(e);
//   }
// };

export const setOrderComments = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getComments(orderId);
    dispatch({
      type: 'SET_ORDER_COMMENTS',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setOrderComments', e);
    // console.log(e);
  }
};

export const setRequestGeneratedLink = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.requestGeneratedLink(orderId);
    dispatch({
      type: 'SET_REQUEST_GENERATE_LINK',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setRequestGeneratedLink', e);
    // console.log(e);
  }
};

export const setPremiumGiftData = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getGiftByOrderId(orderId);
    dispatch({
      type: 'SET_PREMIUM_GIFT_DATA',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setPremiumGiftData', e);
    // console.log(e);
  }
};

// checkout
export const setCheckoutPaymentDetails = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getCheckoutPaymentDetails(orderId);
    dispatch({
      type: 'SET_CHECKOUT_PAYMENT_DETAILS',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ViewOrderActions:setCheckoutPaymentDetails', err);
    toast.error(err?.response?.data?.message);
  }
};

export const setCheckoutLogsData = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getCheckoutLogsDetails(orderId);
    dispatch({
      type: 'SET_CHECKOUT_LOGS_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ViewOrderActions:setCheckoutLogsData', err);
    toast.error(err?.response?.data?.message);
  }
};

// knet history
export const setKnetOrderHistory = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getKnetOrderHistory(orderId);
    dispatch({
      type: 'SET_KNET_ORDER_HISTORY',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ViewOrderActions:setKnetOrderHistory', err);
    toast.error(err?.response?.data?.message);
  }
};

// ziwo calls
export const setZiwoCallsDetails = (dataBody) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getZiwoCallsLogs(dataBody);
    dispatch({
      type: 'SET_ZIWO_CALLS_DATA',
      payload: res.data.data,
    });
  } catch (err) {
    errorLogger('ViewOrderActions:setZiwoCallsDetails', err);
    toast.error(err?.response?.data?.message);
  }
};

export const setSecondaryZiwoCallsData = (dataBody) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getZiwoCallsLogs(dataBody);
    dispatch({
      type: 'SET_SECONDARY_ZIWO_CALLS_DATA',
      payload: res.data.data,
    });
  } catch (err) {
    errorLogger('ViewOrderActions:setSecondaryZiwoCallsData', err);
    toast.error(err?.response?.data?.message);
  }
};

export const setZiwoRecordedCall = (id) => async (dispatch) => {
  const params = {
    callId: id,
  };
  try {
    const res = await ViewOrderService.getZiwoCallRecording(params);

    const reader = new FileReader();
    reader.readAsDataURL(res.data);
    const convertedToB64 = await new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    dispatch({
      type: 'SET_ZIWO_RECORDED_CALL',
      payload: convertedToB64,
    });
  } catch (err) {
    errorLogger('ViewOrderActions:setZiwoRecordedCall', err);
    toast.error(err?.response?.data?.message);
  }
};

// catch user
export const setUserAudit = (orderId) => async () => {
  try {
    await ViewOrderService.getUserAudit(orderId);
  } catch (err) {
    errorLogger('ViewOrderActions:setUserAudit', err);
    // console.log(err);
  }
};

export const setOrderInvoicesData = (orderId) => async (dispatch) => {
  try {
    const res = await ViewOrderService.getOrderInvoicesData(orderId);
    dispatch({
      type: 'SET_ORDER_INVOICES_DATA',
      payload: res.data.invoice.data,
    });
  } catch (e) {
    errorLogger('ViewOrderActions:setOrderInvoicesData', e);
    toast.error(e?.response.data?.message ?? 'Failed to get invoices data');
  }
};

// ship onFleet
export const setShipOnFleet = (orderId) => async () => {
  const params = {
    providers: 0,
  };
  try {
    await ViewOrderService.getShipOnFleet(params, orderId);
    toast.success('Shipped onFleet successfully');
  } catch (err) {
    errorLogger('ViewOrderActions:setShipOnFleet', err);
    toast.error(err?.response?.data?.message ?? 'Failed to ship onFleet');
  }
};

export const setViewOrderDetails = (orderId) => async (dispatch) => {
  const promisesArray = [];
  try {
    promisesArray.push(
      dispatch(setViewOrderData(orderId)),
      dispatch(setOrderInvoice(orderId)),
      dispatch(setCartDetails(orderId)),
      // dispatch(setCustomerCall(orderId)),
      dispatch(setInventoryData(orderId)),
      dispatch(setDriverData(orderId)),
      dispatch(setRequestGeneratedLink(orderId)),
      dispatch(setPremiumGiftData(orderId)),
      dispatch(setUserAudit(orderId))
      // dispatch(setCardMessage(orderId)),
      // dispatch(setActionsBox(orderId)),
      // dispatch(setAdjustLog(orderId)),
      // dispatch(setOrderComments(orderId)),
    );
    await Promise.all(promisesArray);
  } catch (e) {
    errorLogger('ViewOrderActions:setViewOrderDetails', e);
    toast.error('Sorry, No data to be viewed for this order');
  }
};
