import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import UserActivityDataGrid from './UsersActivityDataGrid';
import PageTitle from 'components/shared/PageTitle';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import DJHPagination from 'components/shared/Pagination';
// helpers
import {
  dateFormat,
  downloadFile,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UsersActivityPage = () => {
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    searchKey: '',
    page: 1,
  });
  const [userActivityData, setUserActivityData] = useState({
    data: [],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchUserActivity = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await PermissionService.getUsersActivity(params);
      setUserActivityData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      dispatch(setLoaderOpenFalse());
      errorLogger('UsersActivityPage:fetchUserActivity', err);
      toast.error(err?.response?.data?.message ?? 'Something went wrong');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
      fetchUserActivity(queryObj);
    } else {
      fetchUserActivity(inputValues);
    }
  }, []);

  const updateUserActivity = (params) => {
    const queryString = convertObjectIntoQueryParams(params);
    navigate(queryString);
    fetchUserActivity(params);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    updateUserActivity(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUserActivity(inputValues);
  };

  const handleExport = async () => {
    const params = {
      fromDate: inputValues.fromDate,
      toDate: inputValues.toDate,
      searchKey: inputValues.searchKey,
    };
    try {
      const res = await PermissionService.exportUsersActivity(params);
      downloadFile(res.data, 'User Activity.xlsx');
      toast.success('Exported successfully');
    } catch (err) {
      errorLogger('UsersActivityPage:handleExport', err);
      const json = JSON.parse(await err.response.data.text());
      toast.error(json?.message ?? 'Failed to export user activity');
    }
  };

  return (
    <div className="no-toolbar-table-style">
      <PageTitle title="Users Activity" />
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Users Activity"
        UserActivity
        defaultDate
        hasExport
        handleExport={handleExport}
      />
      <UserActivityDataGrid data={userActivityData.data} />
      {+userActivityData.rowCount > +userActivityData.pageSize && (
        <Paper className="quick-actions-pagination m-b-30">
          <DJHPagination
            onPageChange={onPageChange}
            currentPage={+userActivityData.currentPage}
            pageSize={+userActivityData.pageSize}
            itemsCount={+userActivityData.rowCount}
          />
        </Paper>
      )}
    </div>
  );
};

export default UsersActivityPage;
