import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ColumnChooser,
  Resize,
} from '@syncfusion/ej2-react-grids';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { localStorageKeys, inventoryTypes } from 'constant/Enums';

const LiveProductsDataGrid = ({ data }) => {
  const toolbarOptions = ['ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const productImgTemp = (rowData) => {
    return (
      <div className="image">
        <img alt={rowData.product.sku} className="w-60p" src={rowData.product.image} />
      </div>
    );
  };

  const itemImageTemp = (rowData) => {
    return (
      <div className="image">
        <img alt={rowData.product.sku} className="w-60p" src={rowData.inventory.image} />
      </div>
    );
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={false}
          toolbar={toolbarOptions}
          ref={ref}
          showColumnChooser={true}
          allowTextWrap={true}
          rowHeight={80}
          allowResizing={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Product Image"
              template={productImgTemp}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product SKU"
              field="product.sku"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Type"
              field="product.typeString"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Available QTY"
              field="product.availableQTY"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Price"
              field="product.price"
              template={(rowData) => (
                <span>{rowData.product.price.toFixed(opsCenter?.country?.decimalNumber ?? 3)}</span>
              )}
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Cost"
              field="product.cost"
              template={(rowData) => (
                <span>{rowData.product.cost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}</span>
              )}
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Item Image"
              template={itemImageTemp}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="BarCode"
              field="inventory.barCode"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="QTY" field="inventory.qty" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Required QTY"
              field="inventory.requiredQty"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Cost"
              field="inventory.price"
              template={(rowData) => (
                <span>
                  {rowData.inventory.price.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Cost"
              template={(rowData) => (
                <span>
                  {rowData.inventory.price &&
                    rowData.inventory.requiredQty &&
                    (rowData.inventory.price * rowData.inventory.requiredQty).toFixed(
                      opsCenter?.country?.decimalNumber ?? 3
                    )}
                </span>
              )}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Margin"
              field="inventory.margin"
              template={(rowData) => (
                <span>
                  {rowData.inventory.margin.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Total Price"
              template={(rowData) => (
                <span>
                  {rowData.inventory.price != null &&
                    rowData.inventory.margin != null &&
                    rowData.inventory.requiredQty != null &&
                    `${(
                      (rowData.inventory.price / (1 - rowData.inventory.margin / 100)) *
                      rowData.inventory.requiredQty
                    ).toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Status"
              field="product.productStatusString"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Type"
              field="inventory.type"
              template={(rowData) => (
                <span>
                  {inventoryTypes.filter(function (invType) {
                    return invType.id === rowData.inventory.type;
                  })[0]?.name ?? ''}
                </span>
              )}
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ColumnChooser, Resize]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(LiveProductsDataGrid);
