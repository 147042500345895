import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material UI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// react strap
import { Button } from 'reactstrap';
// selectors
import { getAddInventorySearchInventoryList } from 'store/viewOrder/inventory/addInventory/selectors/AddInventorySelectors';
// actions
import {
  setAddInventoryBarcodeProperties,
  setAddInventorySearchList,
  setAddInventorySearchProperties,
} from 'store/viewOrder/inventory/addInventory/actions/AddInventoryActions';

const AddInventorySearchResultsList = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => getAddInventorySearchInventoryList({ state }));

  const selectItemHandler = (selectedItem) => {
    dispatch(setAddInventoryBarcodeProperties(selectedItem));
    dispatch(setAddInventorySearchList([]));
    dispatch(setAddInventorySearchProperties(''));
  };

  return (
    <TableContainer component={Paper} className="add-inventory-table-wrapper">
      <Table className="add-inventory-cart-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Barcode</TableCell>
            <TableCell align="left">Available Qty</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((el) => (
            <TableRow key={el.id}>
              <TableCell component="th" scope="row">
                <img src={el.fullImage} alt={el.product ? el.product.name : ''} />
              </TableCell>
              <TableCell align="left">{el.name}</TableCell>
              <TableCell align="left">{el.barCode}</TableCell>
              <TableCell align="left">{el.qty}</TableCell>
              <TableCell align="left">
                <Button variant="contained" onClick={() => selectItemHandler(el.barCode)}>
                  Select
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddInventorySearchResultsList;
