import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleImageURL, handleImageFile } from 'constant/Helpers';
import { toast } from 'react-toastify';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// service
import CelebritiesService from 'services/CelebritiesService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const CelebrityForm = ({ selectedCelebrity, type }) => {
  const [enName, setEnName] = useState('');
  const [arName, setArName] = useState('');
  const [enDesc, setEnDesc] = useState('');
  const [arDesc, setArDesc] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [icon, setIcon] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [coverImgEn, setCoverImgEn] = useState('');
  const [coverImgAr, setCoverImgAr] = useState('');
  const [appCoverImgEn, setAppCoverImgEn] = useState('');
  const [appCoverImgAr, setAppCoverImgAr] = useState('');
  const [coverMobImgEn, setCoverMobImgEn] = useState('');
  const [coverMobImgAr, setCoverMobImgAr] = useState('');
  const [productCoverImgEn, setProductCoverImgEn] = useState('');
  const [productCoverImgAr, setProductCoverImgAr] = useState('');
  const [webProductCoverImgEn, setWebProductCoverImgEn] = useState('');
  const [webProductCoverImgAr, setWebProductCoverImgAr] = useState('');
  const [isValid, setValid] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateForm = () => {
    if (enName === '') {
      setValid(false);
    } else {
      setValid(true);
    }
  };

  const handleSubmit = async () => {
    const data = {
      Name: enName,
      NameAr: arName,
      BirthDate: birthDate,
      Description: enDesc,
      DescriptionAr: arDesc,
      CoverImgAr: coverImgAr,
      CoverImgEn: coverImgEn,
      AppCoverImgAr: appCoverImgAr,
      AppCoverImgEn: appCoverImgEn,
      Icon: icon,
      CoverMobImgAr: coverMobImgAr,
      CoverMobImgEn: coverMobImgEn,
      ProductCoverImgAr: productCoverImgAr,
      ProductCoverImgEn: productCoverImgEn,
      WebProductCoverImgAr: webProductCoverImgAr,
      WebProductCoverImgEn: webProductCoverImgEn,
      StartDate: startDate,
      EndDate: endDate,
    };
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    setValid(false);
    dispatch(setLoaderOpenTrue());
    if (type !== 'add') {
      try {
        const res = await CelebritiesService.editCelebrity(formData, selectedCelebrity.id);
        dispatch(setLoaderOpenFalse());
        setValid(true);
        toast.success(res?.data?.message ?? 'Edited celebrity successfully!');
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      } catch (err) {
        errorLogger('CelebrityForm:handleSubmit', err);
        toast.error(err?.response?.data?.message ?? 'Failed to edit celebrity data!');
        dispatch(setLoaderOpenFalse());
        setValid(true);
      }
    } else {
      try {
        const res = await CelebritiesService.addCelebrity(formData);
        dispatch(setLoaderOpenFalse());
        setValid(true);
        toast.success(res?.data?.message ?? 'Added celebrity successfully!');
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      } catch (err) {
        errorLogger('CelebrityForm:handleSubmit', err);
        toast.error(err?.response?.data?.message ?? 'Failed to add celebrity data!');
        dispatch(setLoaderOpenFalse());
        setValid(true);
      }
    }
  };

  useEffect(() => {
    if (type !== 'add' && Object.keys(selectedCelebrity).length > 0) {
      setEnName(selectedCelebrity?.name ?? '');
      setArName(selectedCelebrity?.nameAr ?? '');
      setEnDesc(selectedCelebrity?.description ?? '');
      setArDesc(selectedCelebrity?.descriptionAr ?? '');
      setBirthDate(
        selectedCelebrity?.birthDate?.slice(0, selectedCelebrity?.birthDate.indexOf('T')) ?? ''
      );
      setIcon(handleImageURL(selectedCelebrity?.icon));
      setStartDate(
        selectedCelebrity?.startDate?.slice(0, selectedCelebrity?.startDate.indexOf('T')) ?? ''
      );
      setEndDate(
        selectedCelebrity?.endDate?.slice(0, selectedCelebrity?.endDate.indexOf('T')) ?? ''
      );
      setCoverImgEn(handleImageURL(selectedCelebrity?.coverImgEn));
      setCoverImgAr(handleImageURL(selectedCelebrity?.coverImgAr));
      setAppCoverImgEn(handleImageURL(selectedCelebrity?.appCoverImgEn));
      setAppCoverImgAr(handleImageURL(selectedCelebrity?.appCoverImgAr));
      setCoverMobImgEn(handleImageURL(selectedCelebrity?.coverMobImgEn));
      setCoverMobImgAr(handleImageURL(selectedCelebrity?.coverMobImgAr));
      setProductCoverImgEn(handleImageURL(selectedCelebrity?.productCoverImgEn));
      setProductCoverImgAr(handleImageURL(selectedCelebrity?.productCoverImgAr));
      setWebProductCoverImgEn(handleImageURL(selectedCelebrity?.webProductCoverImgEn));
      setWebProductCoverImgAr(handleImageURL(selectedCelebrity?.webProductCoverImgAr));
    }
  }, [selectedCelebrity]);

  useEffect(() => {
    validateForm();
  }, [enName]);

  return (
    <>
      <div className="card-body row">
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="celebrity-enName">
            Name in English
          </label>
          <input
            id="celebrity-enName"
            value={enName}
            onChange={(e) => setEnName(e.target.value)}
            className="form-control"
            type="text"
          />
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="celebrity-arName">
            Name in Arabic
          </label>
          <input
            id="celebrity-arName"
            value={arName}
            onChange={(e) => setArName(e.target.value)}
            className="form-control"
            type="text"
          />
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="celebrity-enDesc">
            English Description
          </label>
          <textarea
            id="celebrity-enDesc"
            onChange={(e) => setEnDesc(e.target.value)}
            className="form-control"
            value={enDesc}
          ></textarea>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="celebrity-arDesc">
            Arabic Description
          </label>
          <textarea
            id="celebrity-arDesc"
            onChange={(e) => setArDesc(e.target.value)}
            className="form-control"
            value={arDesc}
          ></textarea>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="celebrity-sDate">
            Start Date
          </label>
          <input
            id="celebrity-sDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="form-control"
            type="date"
          />
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="celebrity-eDate">
            End Date
          </label>
          <input
            id="celebrity-eDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="form-control"
            type="date"
          />
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="celebrity-bDate">
            Birth Date
          </label>
          <input
            id="celebrity-bDate"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            className="form-control"
            type="date"
          />
        </div>
        {/* <div className="form-group col-6"></div>
        <div className="form-group col-6">
          <label className="bold-title">Icon</label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="icon"
              onChange={(e) => setIcon(e.target.files[0])}
            />
            <label htmlFor="icon">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img file-icon-img"
            style={{ backgroundImage: `url(${handleImageFile(icon)})` }}
          ></div>
        </div> */}
        <div className="form-group col-6"></div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="coverImgEn">
            Cover EN
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="coverImgEn"
              onChange={(e) => setCoverImgEn(e.target.files[0])}
            />
            <label htmlFor="coverImgEn">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(coverImgEn)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="coverImgAr">
            Cover AR
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="coverImgAr"
              onChange={(e) => setCoverImgAr(e.target.files[0])}
            />
            <label htmlFor="coverImgAr">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(coverImgAr)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="appCoverImgEn">
            App cover EN
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="appCoverImgEn"
              onChange={(e) => setAppCoverImgEn(e.target.files[0])}
            />
            <label htmlFor="appCoverImgEn">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(appCoverImgEn)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="appCoverImgAr">
            App cover AR
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="appCoverImgAr"
              onChange={(e) => setAppCoverImgAr(e.target.files[0])}
            />
            <label htmlFor="appCoverImgAr">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(appCoverImgAr)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="coverMobImgEn">
            Cover Mobile EN
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="coverMobImgEn"
              onChange={(e) => setCoverMobImgEn(e.target.files[0])}
            />
            <label htmlFor="coverMobImgEn">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(coverMobImgEn)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="coverMobImgAr">
            Cover Mobile AR
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="coverMobImgAr"
              onChange={(e) => setCoverMobImgAr(e.target.files[0])}
            />
            <label htmlFor="coverMobImgAr">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(coverMobImgAr)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="productCoverImgEn">
            Product Cover EN
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="productCoverImgEn"
              onChange={(e) => setProductCoverImgEn(e.target.files[0])}
            />
            <label htmlFor="productCoverImgEn">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(productCoverImgEn)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="productCoverImgAr">
            Product Cover AR
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="productCoverImgAr"
              onChange={(e) => setProductCoverImgAr(e.target.files[0])}
            />
            <label htmlFor="productCoverImgAr">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(productCoverImgAr)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="webProductCoverImgEn">
            Web Product Cover En
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="webProductCoverImgEn"
              onChange={(e) => setWebProductCoverImgEn(e.target.files[0])}
            />
            <label htmlFor="webProductCoverImgEn">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(webProductCoverImgEn)})` }}
          ></div>
        </div>
        <div className="form-group col-6">
          <label className="bold-title" htmlFor="webProductCoverImgAr">
            Web Product Cover Ar
          </label>
          <div className="file-input">
            <input
              type="file"
              className="file"
              id="webProductCoverImgAr"
              onChange={(e) => setWebProductCoverImgAr(e.target.files[0])}
            />
            <label htmlFor="webProductCoverImgAr">
              <p className="file-name">Select file</p>
            </label>
          </div>
          <div
            className="file-img"
            style={{ backgroundImage: `url(${handleImageFile(webProductCoverImgAr)})` }}
          ></div>
        </div>
      </div>
      <div className="card-footer">
        <button
          disabled={!isValid}
          onClick={handleSubmit}
          className="btn btn-primary"
          type="button"
        >
          Save
        </button>
      </div>
    </>
  );
};

export default CelebrityForm;
