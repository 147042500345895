import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import RestrictedSection from 'components/shared/RestrictedSection';
import { toast } from 'react-toastify';
// actions
import { setUserGroupsById } from 'store/user/UserActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const GroupsSection = ({ setIsGroupModalOpen, userId, userGroups }) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteGroup = async (id) => {
    const bodyData = {
      GroupId: id,
    };
    setDisableBtn(true);
    try {
      await PermissionService.deleteUserGroup(bodyData, userId);
      dispatch(setUserGroupsById(userId));
      setDisableBtn(false);
      toast.success("User's group deleted successfully!!");
    } catch (err) {
      setDisableBtn(false);
      errorLogger('GroupsSection:handleDeleteGroup', err);
      toast.error("Failed to delete user's group!");
    }
  };

  return (
    <RestrictedSection page="admin_user_info" action="add_group">
      <div className="card">
        <div className="card-header p-15">
          <button onClick={() => setIsGroupModalOpen((prev) => !prev)} className="btn btn-success">
            <i className="fa fa-plus"></i> Add Group
          </button>
        </div>
        <div className="recent-notification card-body">
          <div className="table-responsive shopping-table text-center">
            <table className="table table-bordernone">
              <thead>
                <tr>
                  <th scope="col">Group</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              {userGroups && (
                <tbody>
                  {userGroups.map((group, i) => (
                    <tr key={i}>
                      <td>{group.name}</td>
                      <td>
                        <button
                          onClick={() => handleDeleteGroup(group.groupId)}
                          className="btn btn-danger"
                          disabled={disableBtn}
                        >
                          <i className="fa fa-trash"></i> Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </RestrictedSection>
  );
};

export default GroupsSection;
