import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
// components
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import toString from 'lodash/toString';
// actions
import { setSelectedSubCategory } from 'store/categories/CategoriesActions';

const SubCategoriesDataGrid = ({ data, allowSubLevel = false }) => {
  const toolbarOptions = ['ExcelExport', 'Search'];
  const grid = useRef(GridComponent);
  const dispatch = useDispatch();
  const toolbarClick = (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        grid.current.excelExport();
        break;
      default:
        break;
    }
  };

  const goEditsubCat = async (id, subid) => {
    const selectedCat = cloneDeep(data).filter(function (sc) {
      return sc.id === subid;
    })[0];
    await dispatch(setSelectedSubCategory(selectedCat));
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <Link
          to={`/categories/${rowData.id}/edit`}
          onClick={() => goEditsubCat(rowData.parentId, rowData.id)}
          className="total-txt link sub-categoryid-link"
        >
          {parseInt(rowData.id, 10)}
        </Link>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <Link
        to={`/categories/${rowData.id}/edit`}
        onClick={() => goEditsubCat(rowData.parentId, rowData.id)}
        className="total-txt link sub-categoryid-link"
      >
        {rowData.nickname}
      </Link>
    );
  };

  const subcategoryTemplate = (rowData) => {
    return (
      <Link to={`/categories/${rowData.id}/sublv2`} className="cat-icon-cell">
        <i className="fa fa-align-justify"></i>
      </Link>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${
          rowData.isActive ? 'btn-light-success' : 'btn-light-danger'
        }`}
      >
        {rowData.isActive ? 'Active' : 'Inactive'}
      </button>
    );
  };

  const orderIdTemplate = (rowData) => {
    return <p className="total-txt">{rowData.orderId}</p>;
  };

  // const productsTemplate = (rowData) => {
  //     return <a className="cat-icon-cell"><i className="fa fa-tags"></i></a>
  // }

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section toolbar-table-style">
        <GridComponent
          ref={grid}
          dataSource={data}
          toolbar={toolbarOptions}
          allowPaging={true}
          allowExcelExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          toolbarClick={toolbarClick}
          allowTextWrap={true}
          pageSettings={{ pageSize: 10 }}
          allowSorting={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="ID"
              template={idTemplate}
              width={90}
              customExcelTemplate={(rowData) => rowData.id}
            />
            <ColumnDirective
              field="nickname"
              headerText="Name"
              template={nameTemplate}
              width={150}
              customExcelTemplate={(rowData) => rowData.nickname}
            />
            {allowSubLevel ? (
              <ColumnDirective
                field="subcat"
                headerText="Sub Cat"
                template={subcategoryTemplate}
                width={100}
              />
            ) : (
              ''
            )}
            <ColumnDirective
              field="isActive"
              headerText="Status"
              template={statusTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.isActive}
              customAttributes={{ class: 'category-status' }}
            />
            <ColumnDirective
              field="orderId"
              headerText="Order ID"
              template={orderIdTemplate}
              width={80}
            />
            {/* <ColumnDirective field="products" headerText="Products" template={productsTemplate} width={80} /> */}
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(SubCategoriesDataGrid);
