import InlineErrorMessage from 'components/shared/InlineErrorMessage';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { setUpdateProductRankingStatus } from 'store/products/ProductsActions';
import {
  getProductMasterAvailabilityById,
  getProductOptionsById,
} from 'store/products/ProductsSelectors';

const RankingTemplate = ({
  rowData,
  productId,
  isValid,
  notValidFields,
  validateRanking,
  isAuthorized,
}) => {
  const productOptionsById = useSelector((state) => getProductOptionsById({ state }));
  const productMAData = useSelector((state) => getProductMasterAvailabilityById({ state }));

  const [rank, setRank] = useState(rowData.rank ?? '');
  const [isCustomizable, setIsCustomizable] = useState(rowData.customizable);
  const [isPickupandPackage, setIsPickupAndPackage] = useState(rowData.isPickAndPack);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const saveRankingRow = async () => {
    const dataBody = {
      OpsId: rowData.opsId,
      Rank: rank,
      Customizable: isCustomizable,
      IsPickAndPack: isPickupandPackage,
    };
    setDisableBtn(true);
    await dispatch(setUpdateProductRankingStatus(dataBody, productId, validateRanking));
    setDisableBtn(false);
  };
  const handleCustomizableChange = () => {
    if (!isPickupandPackage) setIsCustomizable((prev) => !prev);
  };
  const handlePickupandPackageChange = () => {
    if (!isCustomizable) setIsPickupAndPackage((prev) => !prev);
  };

  const allMAData = productMAData?.reduce((prev, curr) => [...prev, ...curr.pricing], []);
  // check that the product type is not Basket(5) or Variant(11)
  const isRequired =
    allMAData?.find((el) => el?.opsId === rowData?.opsId)?.active &&
    productOptionsById?.type !== 5 &&
    productOptionsById?.type !== 11;

  return (
    <div className="form-group" style={{ padding: 15 }}>
      <form className="row">
        <div className="form-group col-4">
          <label className="bold-title" htmlFor="rank">
            Rank
            {isRequired && <span className="requird-asterisks ml-1">*</span>}
          </label>
          <input
            id="rank"
            value={rank}
            className="form-control"
            onChange={(e) => setRank(e.target.value)}
            type="text"
            disabled={!isAuthorized}
          />
          {!isValid && notValidFields?.includes(`rank for ${rowData.nickName}`) && (
            <InlineErrorMessage message={'Please add "Rank"'} />
          )}
        </div>
        <div className="col-12 row">
          <div className="form-group mx-3">
            <input
              className="checkbox_animated"
              type="checkbox"
              checked={isCustomizable ?? false}
              onChange={handleCustomizableChange}
              disabled={!isAuthorized}
            />
            <span className="bold-title">Customizable</span>
          </div>
          <div className="form-group mx-3">
            <input
              className="checkbox_animated"
              type="checkbox"
              checked={isPickupandPackage ?? false}
              onChange={handlePickupandPackageChange}
              disabled={!isAuthorized || rowData?.countryId !== 1}
            />
            <span className="bold-title">Pickup and package</span>
          </div>
        </div>
      </form>
      <div className="row">
        <div className="form-group col-12 col-md-3 availability-row-button">
          <button
            onClick={saveRankingRow}
            className="btn btn-success m-b-0"
            type="button"
            disabled={!isAuthorized || disableBtn || productOptionsById?.status === 1}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RankingTemplate);
