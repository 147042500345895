import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// components
import SectionLoader from 'components/common/SectionLoader';
import UserPointsDataGrid from './UserPointsDataGrid';
// actions
import { setUserPointsHistoryById } from 'store/user/UserActions';
// selectors
import { getUserPointsHistoryById } from 'store/user/UserSelectors';

const UserCardsDetails = ({ userId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const userPointsHistory = useSelector((state) => getUserPointsHistoryById({ state }));
  const dispatch = useDispatch();

  const fetchUserPointsList = async () => {
    setIsLoaderOpen(true);
    await dispatch(setUserPointsHistoryById(userId));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchUserPointsList();
  }, []);

  return (
    <div className="card row checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          Points History <i className="fa fa-info-circle"></i>
        </h4>
      </div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="card-body no-toolbar-table-style">
          <UserPointsDataGrid data={userPointsHistory} />
        </div>
      )}
    </div>
  );
};

export default UserCardsDetails;
