import { endPoints } from './apiService/EndPoints';
import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';

class UserFeedbackService {
  static getAllUserFeedbackReasons(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllUserFeedbackReasons, ...extraParams),
      params,
    });
  }
  static createUserFeedbackReason(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.createUserFeedbackReason, ...extraParams),
      data,
    });
  }
  static updateUserFeedbackReason(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateUserFeedbackReason, ...extraParams),
      data,
    });
  }
}
export default UserFeedbackService;
