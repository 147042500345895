import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { isoFormat } from 'constant/Helpers';
// api
import WalletReportService from 'services/reportsService/WalletReportService';
// components
import SectionLoader from 'components/common/SectionLoader';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const CustomerLoyalityPointsFilter = React.lazy(() =>
  import('components/reports/customerLoyalityPoints/CustomerLoyalityPointsFilter')
);

const CustomerLoyalityPage = () => {
  const [inputValues, setInputValues] = useState({
    startDate: isoFormat(new Date()),
    endDate: isoFormat(new Date()),
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const res = await WalletReportService.exportCustomerLoyalityReport(inputValues);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
      toast.success('Downloaded');
      setIsLoading(false);
    } catch (err) {
      errorLogger('Customer Loyality Report Store Export Data Error:', err);
      toast.error(err?.response?.data?.message ?? 'Failed to download');
      setIsLoading(false);
    }
  };

  return (
    <div className="toolbar-table-style">
      <React.Suspense fallback={<SectionLoader />}>
        <CustomerLoyalityPointsFilter
          inputValues={inputValues}
          setInputValues={setInputValues}
          handleExport={handleExport}
          title="Customer Loyalty Points"
          isLoading={isLoading}
        />
      </React.Suspense>
    </div>
  );
};

export default CustomerLoyalityPage;
