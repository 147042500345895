import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Resize,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { dateFormatDDMM } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
import has from 'lodash/has';
import toString from 'lodash/toString';

const DailySpendDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const totalSalesTemplate = (rowData) => {
    return <p>{rowData?.totalSales.toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>;
  };

  const totalSalesUsdTemplate = (rowData) => {
    return <p>{rowData?.totalSalesUsd.toFixed(opsCenter?.country?.decimalNumber ?? 2)}</p>;
  };

  const orderdateTemplate = (rowData) => {
    return <p>{dateFormatDDMM(rowData?.orderdate)}</p>;
  };

  const queryCellInfo = (args) => {
    let newVal = parseInt('0', 10).toFixed(opsCenter?.country?.decimalNumber ?? 2);
    if (args.column.field === 'totalSales') {
      newVal = args.data.totalSales.toFixed(opsCenter?.country?.decimalNumber ?? 2);
      args.data.totalSales = args.cell.innerText = newVal;
    } else if (args.column.field === 'totalSalesUsd') {
      newVal = args.data.totalSalesUsd.toFixed(opsCenter?.country?.decimalNumber ?? 2);
      args.data.totalSalesUsd = args.cell.innerText = newVal;
    } else if (args.column.field === 'orderDate') {
      newVal = dateFormatDDMM(args.data.orderDate);
      args.data.orderDate = args.cell.innerText = newVal;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane m-b-30">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          allowSorting={true}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
          // queryCellInfo={queryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Date"
              field="orderDate"
              width={120}
              textAlign="Center"
              template={orderdateTemplate}
              customExcelTemplate={(rowData) => dateFormatDDMM(rowData?.orderdate)}
            />
            <ColumnDirective headerText="Ops" field="opsId" width={80} textAlign="Center" />
            <ColumnDirective headerText="Country" field="country" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Commission"
              field="totalSales"
              width={120}
              textAlign="Center"
              template={totalSalesTemplate}
              customExcelTemplate={(rowData) =>
                rowData?.totalSales.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
            />
            <ColumnDirective
              headerText="Commission USD"
              field="totalSalesUsd"
              width={120}
              textAlign="Center"
              template={totalSalesUsdTemplate}
              customExcelTemplate={(rowData) =>
                rowData?.totalSalesUsd.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page, Resize, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DailySpendDataGrid);
