import React, { useState, useEffect } from 'react';
import { formatLogValues } from 'constant/Helpers';
import { CardBody } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';

const ProductLogsDataGrid = ({ data }) => {
  const [showExtend, setShowExtend] = useState([]);

  useEffect(() => {
    const newArr = [];
    for (let i = 0; i < data?.length; i++) {
      newArr.push(false);
    }
    setShowExtend(newArr);
  }, [data]);

  const logDetails = (rowData) => {
    return (
      <div className="log-changes-container">
        <table className="product-logs-table">
          <tbody>
            <tr>
              <th></th>
              <th>Old values</th>
              <th>New values</th>
            </tr>
            {rowData?.map(function (fd, ind) {
              return (
                <tr key={ind}>
                  <th>{fd.field_name}</th>
                  <td>{formatLogValues(fd.field_name, fd.old_value)}</td>
                  <td>{formatLogValues(fd.field_name, fd.new_value)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const toggleData = (ind) => {
    const oldArr = cloneDeep(showExtend);
    oldArr[ind] = !oldArr[ind];
    setShowExtend(oldArr);
  };

  return (
    <>
      {data !== '' &&
        data?.map((action, i) => (
          <div className="card action-box-wrapper" key={i}>
            <CardBody className="recent-notification product-log-notification">
              <div key={i} className="media">
                {action.created_date ? (
                  <h6>{`${action.created_date.slice(0, 10)}@${action.created_date.slice(
                    11,
                    16
                  )} `}</h6>
                ) : (
                  ''
                )}
                <div className="media-body">
                  <div className="space-btwn-div">
                    <span>{action.remarks} </span>
                    <span className="show-more-link" onClick={() => toggleData(i)}>
                      {showExtend[i] ? 'Show less' : 'Show more'}
                    </span>
                  </div>
                  <p className="f-12">
                    {action.user_name}{' '}
                    <a className="primary-color" href={`mailto:${action.email}`}>
                      ({action.email})
                    </a>{' '}
                    - {action.user_operation}
                  </p>
                  {showExtend[i] ? logDetails(action.affected_changes) : null}
                </div>
              </div>
            </CardBody>
          </div>
        ))}
    </>
  );
};

export default React.memo(ProductLogsDataGrid);
