import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { copyToClipboard } from 'constant/Helpers';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const LateDriversDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  // custom columns
  const idTemplate = (rowData) => (
    <a
      className="d-flex flex-column btns-column"
      href={`/order/${rowData.orderId}`}
      target="_blank"
      rel="noreferrer"
    >
      {parseInt(rowData.orderId, 10)}
    </a>
  );

  const driverTemplate = (rowData) => {
    return (
      <div className="sender">
        <p>
          <span className="sec-title">Name: </span> {rowData.driverName}
        </p>
        <p className="m-b-0">
          <span className="sec-title">Phone: </span>{' '}
          {rowData.driverPhone && (
            <span
              onClick={() => {
                copyToClipboard(rowData.driverPhone);
              }}
              className="phone"
            >
              {rowData.driverPhone}
            </span>
          )}
        </p>
      </div>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="toolbar-table-style">
      <div className="control-pane">
        <div className="card-header p-18">
          <h5 className="sz-14">Late Orders Details</h5>
        </div>
        <div className="control-section">
          <GridComponent
            dataSource={data}
            allowPaging={true}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            excelQueryCellInfo={excelQueryCellInfo}
            pdfQueryCellInfo={excelQueryCellInfo}
            ref={ref}
            toolbarClick={toolbarClick}
            showColumnChooser={true}
            rowHeight={60}
            allowTextWrap={true}
            pageSettings={{ pageSizes: true, pageSize: 20 }}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText="Order ID"
                textAlign="Center"
                width={100}
                template={idTemplate}
                customExcelTemplate={(rowData) => rowData.orderId}
              />
              <ColumnDirective
                headerText="Driver"
                textAlign="Center"
                width={100}
                template={driverTemplate}
                customExcelTemplate={(rowData) => rowData.driverName}
              />
              <ColumnDirective headerText="Status" textAlign="Center" width={100} field="status" />
              <ColumnDirective headerText="Reason" textAlign="Center" width={100} field="reason" />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LateDriversDataGrid);
