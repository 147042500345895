import React from 'react';

const ActiveStatusBadge = ({ isActive, label = 'Active' }) => {
  return (
    <div className={`status-badge ${isActive ? 'active' : 'inactive'}`}>
      {isActive ? (
        <span className="status-badge-icon active-circle" />
      ) : (
        <span className="status-badge-icon in-active-circle" />
      )}
      {label}
    </div>
  );
};

export default ActiveStatusBadge;
