import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import SectionLoader from 'components/common/SectionLoader';
import SubAttributesDataGrid from './SubAttributesDataGrid';
import AttributesActions from '../AttributesActions';
import AttributesFilter from '../AttributesFilter';
import DJHPagination from 'components/shared/Pagination';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// selectors
import { getSubAttributesData } from 'store/attributes/AttributesSelectors';
// actions
import { getSubAttributes } from 'store/attributes/AttributesActions';

const SubAttributesDataTable = ({ inputValues, setInputValues }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [parentName, setParentName] = useState('');
  const subAttributesData = useSelector((state) => getSubAttributesData({ state }));
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSubmit = (getParams) => {
    const queryParams = getParams ?? inputValues;
    const queryString = convertObjectIntoQueryParams(queryParams);
    navigate({
      pathname: `/attributes/${params?.id}/sub`,
      search: queryString,
    });
  };

  const fetchSubAttributes = async (getParams) => {
    setIsLoaderOpen(true);
    await dispatch(getSubAttributes(getParams, params?.id));
    setIsLoaderOpen(false);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    dispatch(fetchSubAttributes(updatedParams));
    document.documentElement.scrollTop = 0;
  };

  const fetchSubData = async () => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setIsLoaderOpen(true);
      await fetchSubAttributes(queryObj);
      setIsLoaderOpen(false);
    } else {
      setIsLoaderOpen(true);
      await fetchSubAttributes(inputValues);
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchSubData();
  }, [location.search]);

  useEffect(() => {
    if (subAttributesData?.data?.length > 0) {
      setParentName(subAttributesData.data[0].attributeName);
    }
  }, [subAttributesData]);

  return (
    <>
      <AttributesActions type="child" inputValues={inputValues} parentName={parentName} />
      <AttributesFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
      />
      <div className="card checkout-card">
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <div className="card-body">
            <SubAttributesDataGrid data={subAttributesData?.data} />
            <DJHPagination
              onPageChange={onPageChange}
              currentPage={+subAttributesData?.currentPage}
              pageSize={+subAttributesData?.pageSize}
              itemsCount={+subAttributesData?.rowCount}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SubAttributesDataTable;
