import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { errorLogger } from 'datadog/DDUtils';
// api
import DeliveryService from 'services/DeliveryService';

export const getAllAreas = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getAreas(params);
    dispatch({
      type: 'SET_DELIVERY_AREAS',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('areasAction:getAllAreas', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const getAreaById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getAreaById(id);
    dispatch({
      type: 'SET_SELECTED_AREA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('areasAction:getAreaById', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const updateArea = (data, id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.updateAreaById(data, id);
    dispatch({
      type: 'UPDATE_SELECTED_AREA',
      payload: data,
    });
    dispatch(setLoaderOpenFalse());
    toast.success(res.data.message ?? 'Area is updated successfuly!');
  } catch (err) {
    errorLogger('areasAction:updateArea', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const getSubAreas = (params, id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getSubAreas(params, id);
    dispatch({
      type: 'SET_SUB_AREAS',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('areasAction:getSubAreas', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const getSubAreaById = (id, subid) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getSubAreaById(id, subid);
    dispatch({
      type: 'SET_SELECTED_SUBAREA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('areasAction:getSubAreaById', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const updateSubArea = (data, id, subid) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.updateSubAreaById(data, id, subid);
    dispatch({
      type: 'UPDATE_SELECTED_SUBAREA',
      payload: data,
    });
    dispatch(setLoaderOpenFalse());
    toast.success(res.data.message ?? 'Sub-Area is updated successfuly!');
  } catch (err) {
    errorLogger('areasAction:updateSubArea', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const getMainAreas = () => async (dispatch) => {
  const params = {
    page: -1,
  };
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getAreas(params);
    dispatch({
      type: 'SET_MAIN_AREAS',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('areasAction:getMainAreas', err);
    dispatch(setLoaderOpenFalse());
  }
};
