import { updateObject } from '../utility';

const initialState = {
  masterInventoryData: [],
  pageSize: '',
  currentPage: '',
  rowCount: '',
  selectedMasterInventory: {},
  masterInventoryChildren: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_MASTER_INVENTORY_DATA': {
      return updateObject(state, {
        masterInventoryData: action.payload.data,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        rowCount: action.payload.rowCount,
      });
    }
    case 'GET_MASTER_INVENTORY_BY_ID_DATA': {
      return updateObject(state, {
        selectedMasterInventory: action.payload,
      });
    }
    case 'GET_MASTER_INVENTORY_CHILDREN': {
      return updateObject(state, {
        masterInventoryChildren: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
