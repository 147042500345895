import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
  Sort,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { Link } from 'react-router-dom';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const DriverPerformanceDataGrid = ({ data, params }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const aggregatesArrayVal = [
    { name: 'picked', status: 0 },
    { name: 'delivering', status: 1 },
    { name: 'delivered', status: 2 },
    { name: 'returned', status: 3 },
  ];

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseInt(rowData.index, 10) + 1}</p>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return <p className="total-txt">{rowData.name}</p>;
  };

  const typeTemplate = (rowData) => {
    return <p className="total-txt">{rowData.type}</p>;
  };

  const phoneTemplate = (rowData) => {
    return <p className="total-txt">{rowData.phone}</p>;
  };

  const companyTemplate = (rowData) => {
    return <p className="total-txt">{rowData.company}</p>;
  };

  const pickedTemplate = (rowData) => {
    return (
      <Link
        target={'_blank'}
        to={`/report/DriverPerformanceOrdersReport?fromDate=${params.fromDate}&toDate=${params.toDate}&id=${rowData.id}&status=0&page=1`}
        className="total-txt link"
      >
        {rowData.picked}
      </Link>
    );
  };

  const deliveringTemplate = (rowData) => {
    return (
      <Link
        target={'_blank'}
        to={`/report/DriverPerformanceOrdersReport?fromDate=${params.fromDate}&toDate=${params.toDate}&id=${rowData.id}&status=1&page=1`}
        className="total-txt link"
      >
        {rowData.delivering}
      </Link>
    );
  };

  const deliveredTemplate = (rowData) => {
    return (
      <Link
        target={'_blank'}
        to={`/report/DriverPerformanceOrdersReport?fromDate=${params.fromDate}&toDate=${params.toDate}&id=${rowData.id}&status=2&page=1`}
        className="total-txt link"
      >
        {rowData.delivered}
      </Link>
    );
  };

  const returnedTemplate = (rowData) => {
    return (
      <Link
        target={'_blank'}
        to={`/report/DriverPerformanceOrdersReport?fromDate=${params.fromDate}&toDate=${params.toDate}&id=${rowData.id}&status=3&page=1`}
        className="total-txt link"
      >
        {rowData.returned}
      </Link>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
          allowSorting={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="#"
              field="id"
              width={100}
              template={idTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.id}
            />
            <ColumnDirective
              headerText="Name"
              field="name"
              width={100}
              template={nameTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.name}
            />
            <ColumnDirective
              headerText="Type"
              field="type"
              width={100}
              template={typeTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.type}
            />
            <ColumnDirective
              headerText="Phone"
              field="phone"
              width={100}
              template={phoneTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.phone}
            />
            <ColumnDirective
              headerText="Company"
              field="company"
              width={100}
              template={companyTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.company}
            />
            <ColumnDirective
              headerText="Picked"
              field="picked"
              width={100}
              template={pickedTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.picked}
            />
            <ColumnDirective
              headerText="Delivering"
              field="delivering"
              width={100}
              template={deliveringTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.delivering}
            />
            <ColumnDirective
              headerText="Delivered"
              field="delivered"
              width={100}
              template={deliveredTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.delivered}
            />
            <ColumnDirective
              headerText="Returned"
              field="returned"
              width={100}
              template={returnedTemplate}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.returned}
            />
          </ColumnsDirective>
          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                {aggregatesArrayVal.map((item, i) => (
                  <AggregateColumnDirective
                    key={i}
                    field={item.name}
                    type="Sum"
                    footerTemplate={(props) => (
                      <Link
                        target={'_blank'}
                        to={`/report/DriverPerformanceOrdersReport?fromDate=${params.fromDate}&toDate=${params.toDate}&id=0&status=${item.status}&page=1`}
                        className="bold-title black-color"
                      >
                        {props.Sum}
                      </Link>
                    )}
                  ></AggregateColumnDirective>
                ))}
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>
          <Inject
            services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser, Aggregate, Sort]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DriverPerformanceDataGrid);
