import React from 'react';
import { useDispatch } from 'react-redux';
// material UI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// actions
import { setAddInventoryProductProperties } from 'store/viewOrder/inventory/addInventory/actions/AddInventoryActions';

const AddInventoryCartList = ({ cartList }) => {
  const dispatch = useDispatch();

  const productHandler = ({ target: { value } }) => {
    dispatch(setAddInventoryProductProperties(value));
  };

  return (
    <TableContainer component={Paper} className="add-inventory-table-wrapper">
      <Table className="add-inventory-cart-table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="left">Item</TableCell>
            <TableCell align="left">Photo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartList.map((el) => (
            <TableRow key={el.id}>
              <TableCell component="th" scope="row">
                <div className="d-flex align-items-center radio-wrapper">
                  <input
                    type="radio"
                    onChange={productHandler}
                    id={el.product.id}
                    name="product"
                    value={el.product.id}
                  />
                  <label htmlFor={el.product.id}>{el.product.id}</label>
                </div>
              </TableCell>
              <TableCell align="left">
                {el.product ? el.product.name : ''} - {el.product ? el.product.sku : ''}
              </TableCell>
              <TableCell align="left">
                <img
                  src={el.product ? el.product.image : ''}
                  alt={el.product ? el.product.name : ''}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddInventoryCartList;
