import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
// api
import ProductsService from 'services/ProductsService';
// action
import { updateInventoryAfterQty } from 'store/products/ProductsActions';
// selectors
import { getProductOptionsById } from 'store/products/ProductsSelectors';

const RequiredQty = ({ rowData }) => {
  const dispatch = useDispatch();
  const productOptionsById = useSelector((state) => getProductOptionsById({ state }));
  const [isDisabled, setIsDisabled] = useState(true);
  const handleUpdateMasterInventoryVal = debounce(async (id, val) => {
    if (val < 1) {
      toast.error('invalid input');
    } else {
      try {
        await ProductsService.editProductMasterInventoryQty(id, val);
        dispatch(updateInventoryAfterQty(val, id));
        toast.success('Updated');
      } catch (err) {
        toast.error(err?.response?.data?.message ?? 'Failed to update');
      }
    }
  }, 1000);

  useEffect(() => {
    if (productOptionsById?.status === 1) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [productOptionsById]);

  return (
    <input
      max={250}
      min={1}
      defaultValue={rowData.requiredQty}
      onChange={(e) => dispatch(handleUpdateMasterInventoryVal(rowData.centralId, e.target.value))}
      className="form-control align-center"
      type="number"
      disabled={isDisabled}
    />
  );
};

export default RequiredQty;
