import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

const DataTableComponent = ({ columns, data, clickable, handleClick, header }) => {
  const tableData = {
    columns,
    data,
  };

  return (
    <div className="card data-table">
      <div className="card-header p-18">
        <h4>{header}</h4>
      </div>
      <div className="card-body">
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            striped={true}
            defaultSortFieldId="1"
            defaultSortAsc={true}
            highlightOnHover
            onRowClicked={(row) => (clickable ? handleClick(row) : '')}
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};

export default DataTableComponent;
