import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class EditItemsService {
  static gertOrderDetails(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getEditItemsOrderDetails, ...extraParams),
    });
  }

  static getOrderDetailsPriceInfo(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getEditItemsPriceInfo, ...extraParams),
      params: data,
    });
  }

  static searchForItem(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.editItemsSearchItem, ...extraParams),
      params: data,
    });
  }

  static searchForPremiumItem(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.editItemsSearchPremiumItem, ...extraParams),
      params: data,
    });
  }

  static updateItemStatus(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateItemStatus, ...extraParams),
      data,
    });
  }

  static selectItem(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.selectEditOrderItem, ...extraParams),
      data,
    });
  }

  static addCustomItem(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addCustomItem, ...extraParams),
      data,
    });
  }
}

export default EditItemsService;
