import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
// components
import DJHPagination from 'components/shared/Pagination';
// selectors
import {
  getKeyValuePageSize,
  getKeyValueListcurrentPage,
  getKeyValueListRowCount,
  getKeyValueStatus,
} from 'store/keyValues/keyValueSelectors';
// actions
import { setKeyValueList } from 'store/keyValues/keyValueActions';
// constants
import { convertObjectIntoQueryParams } from 'constant/Helpers';

const KeyValuesPagination = () => {
  const pageSize = useSelector((state) => getKeyValuePageSize({ state }));
  const currentPage = useSelector((state) => getKeyValueListcurrentPage({ state }));
  const rowCount = useSelector((state) => getKeyValueListRowCount({ state }));
  const status = useSelector((state) => getKeyValueStatus({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onPageChange = (i) => {
    let params = { page: i };
    if (status !== 0) {
      params = { ...params, isactive: status === 1 };
    }
    const queryParams = params;
    const queryString = convertObjectIntoQueryParams(queryParams);
    navigate({
      pathname: location?.pathname,
      search: queryString,
    });
    dispatch(setKeyValueList(params));
    document.documentElement.scrollTop = 0;
  };

  return (
    <DJHPagination
      onPageChange={onPageChange}
      currentPage={+currentPage}
      pageSize={+pageSize}
      itemsCount={+rowCount}
    />
  );
};

export default KeyValuesPagination;
