import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import DataTableComponent from 'components/shared/DataTableComponent';
import { columns } from 'components/orders/viewOrder/viewOrderCommons';
import SectionLoader from 'components/common/SectionLoader';
// api
import ViewOrderService from 'services/ViewOrderService';
// datdog
import { errorLogger } from 'datadog/DDUtils';

const RecepientTodayOrdersModal = ({
  isRecepientOrdersModalOpen,
  setIsRecepientOrdersModalOpen,
  rowData,
}) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [recepientOrdersList, setRecepientOrdersList] = useState([]);

  const fetchData = async () => {
    const params = {
      phone: rowData.recipient.recipientPhone,
      countryId: rowData.recipient.country.id,
    };
    setIsLoaderOpen(true);
    try {
      const res = await ViewOrderService.getRecepientTodayOrders(params);
      setIsLoaderOpen(false);
      setRecepientOrdersList(res.data);
    } catch (e) {
      errorLogger('RecepientTodayOrdersModal:fetchData', e);
      setIsLoaderOpen(false);
      toast.error('Failed to get Recepient orders!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isRecepientOrdersModalOpen}
        toggle={() => setIsRecepientOrdersModalOpen((prev) => !prev)}
        className="recepient-order-modal"
      >
        <ModalBody>
          <div>
            {isLoaderOpen ? (
              <SectionLoader height="400px" />
            ) : (
              <div className="table-responsive shopping-table text-center">
                {recepientOrdersList && (
                  <DataTableComponent
                    data={recepientOrdersList}
                    columns={columns}
                    header="Recepient Orders List"
                  />
                )}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12">
            <Button onClick={() => setIsRecepientOrdersModalOpen((prev) => !prev)} color="light">
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RecepientTodayOrdersModal;
