import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class DashboardService {
  static getTotalOrders(opsId, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardTotalOrder, ...extraParams),
      headers: { opsId },
    });
  }

  static getTodayOrders(opsId, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardTodayTotalOrders, ...extraParams),
      headers: { opsId },
    });
  }

  static getTotalUsers(opsId, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardTotalUsers, ...extraParams),
      headers: { opsId },
    });
  }

  static getTodayRegisteredUsers(opsId, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardTodayRegisteredUsers, ...extraParams),
      headers: { opsId },
    });
  }

  static getRegisteredUsers(opsId, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardRegisteredUsers, ...extraParams),
      headers: { opsId },
    });
  }

  static getSubscriptions(opsId, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.order, ...extraParams),
      headers: { opsId },
    });
  }

  static getTotalProducts(opsId, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.product, ...extraParams),
      headers: { opsId },
    });
  }

  static getDashboardSlots(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardTimeSlots, ...extraParams),
      params: data,
    });
  }

  static getDashboardSlotsWithDate(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardTimeSlotsWithDate, ...extraParams),
      params: data,
    });
  }

  static getDashboardSlotsPrintStatus(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardTimeSlotsPrintStatus, ...extraParams),
      params: data,
    });
  }

  static getDashboardCorporateOrders(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.dashboardCorporateOrders, ...extraParams),
    });
  }
}

export default DashboardService;
