import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { toast } from 'react-toastify';
// api
import PermissionService from 'services/PermissionService';

const UserDefaultAddressDataGrid = ({ setData, data }) => {
  const toolbarOptions = ['Search'];
  const ref = useRef();

  const handleUpdateDefaultAddress = async (val, id) => {
    const defaultAddressData = {
      DefaultAddress: val,
    };
    try {
      await PermissionService.updateDefaultAddress(defaultAddressData, id);
      toast.success('Updated successfully');
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Failed to update');
    }
  };

  const orderIdTemplate = (rowData) => (
    <a href={`/order/${rowData?.orderId}`} target="_blank" rel="noreferrer">
      {rowData?.orderId}
    </a>
  );

  const defaultAddressTemplate = (rowData) => (
    <input
      className="checkbox_animated ticket-topic-checkbox"
      type="checkbox"
      defaultChecked={rowData?.recipientDefaultAddress}
      onChange={(e) => handleUpdateDefaultAddress(e.target.checked, rowData?.id)}
    />
  );

  return (
    <div className="control-pane ">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          rowHeight={60}
          allowTextWrap={true}
          style={{ cursor: 'pointer' }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Order ID"
              template={orderIdTemplate}
              width={80}
              textAlign="Center"
            />
            <ColumnDirective headerText="Area" field="areaName" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Address one"
              field="addressLineOne"
              width={140}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Address two"
              field="addressLineTwo"
              width={140}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Default Address"
              template={defaultAddressTemplate}
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(UserDefaultAddressDataGrid);
