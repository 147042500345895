export const getViewOrderDetails = ({ state }) => state.viewOrder.viewOrderDetails;

export const getOrderInvoice = ({ state }) => state.viewOrder.orderInvoice;

export const getOrderCartDetails = ({ state }) => state.viewOrder.cartDetails;

export const getOrderCardMessage = ({ state }) => state.viewOrder.cardMessage;

export const getCustomerCall = ({ state }) => state.viewOrder.cutomerCall;

export const getInventoryData = ({ state }) => state.viewOrder.inventoryData;

export const getActionsBoxData = ({ state }) => state.viewOrder.actionsBox;

export const getDriverData = ({ state }) => state.viewOrder.driverData;

export const getAdjustLog = ({ state }) => state.viewOrder.adjustLog;

export const getAdjustData = ({ state }) => state.viewOrder.adjustData;

export const getOrderComments = ({ state }) => state.viewOrder.orderComments;

export const getGeneratedLinkData = ({ state }) => state.viewOrder.generatedLinkData;

export const getUpdatedPaymentSt = ({ state }) => state.viewOrder.updatedPaymentSt;

export const getPremiumGiftData = ({ state }) => state.viewOrder.premiumGiftData;

// trip
export const getIsDriverTripModalOpen = ({ state }) => state.viewOrder.isDriverTripModalOpen;
export const getDeliveryDriverTripId = ({ state }) => state.viewOrder.deliveryDriveryId;
export const getDeliveryDriverId = ({ state }) => state.viewOrder.deliveryTripId;

// checkout
export const getCheckoutPaymentDetails = ({ state }) => state.viewOrder.checkoutPaymentDetails;
export const getCheckoutLogsData = ({ state }) => state.viewOrder.checkoutLogsData;

// knet history
export const getKnetOrderHistoryData = ({ state }) => state.viewOrder.knetHistoryData;

// ziwo calls
export const getZiwoCallsData = ({ state }) => state.viewOrder.ziwoCallsData;
export const getZiwoSecondaryCallsData = ({ state }) => state.viewOrder.ziwoSecondaryCallsData;
export const getZiwoRecordedCall = ({ state }) => state.viewOrder.ziwoRecordedCall;

// invoices data
export const getOrderInvoicesData = ({ state }) => state.viewOrder.orderInvoicesData;
