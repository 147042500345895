import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, editUser } from 'store/user/UserActions';
import { getUserInfoDetails } from 'store/user/UserSelectors';
import ResetPasswordModal from './pickers/ResetPasswordModal';
import UserInfoInputs from './components/userInfo/UserInfoInputs';
import cloneDeep from 'lodash/cloneDeep';
import RestrictedSection from 'components/shared/RestrictedSection';
import { toast } from 'react-toastify';

const UserEditPage = () => {
  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    contractType: -1,
    roles: [],
    isActive: false,
    countryId: -1,
    capacity: -1,
    opsId: '',
  });
  const [userId, setUserId] = useState(-1);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showResetButton, setShowResetButton] = useState(false);
  const [showCapacity, setShowCapacity] = useState(false);
  const userDetails = useSelector((state) => getUserInfoDetails({ state }));
  const dispatch = useDispatch();
  const params = useParams();

  const onSave = async () => {
    setIsDisabled(true);
    const values = cloneDeep(inputValues);
    if (values.capacity < 1 || values.capacity > 1000) {
      toast.error('Capacity should be between 1 and 1000');
      setIsDisabled(false);
      return;
    }
    const { roles, isActive, ...updatedValues } = values;
    const responseStatus = await dispatch(editUser(updatedValues, userDetails?.userInfo?.userId));
    setIsDisabled(false);
    if (updatedValues.contractType === 1 && showResetButton && responseStatus === 204) {
      setShowResetButton(false);
    } else if (updatedValues.contractType === 2 && !showResetButton && responseStatus === 204) {
      setShowResetButton(true);
    }
  };

  const validateInputs = () => {
    if (
      inputValues.firstName &&
      inputValues.lastName &&
      inputValues.email &&
      inputValues.phoneNumber &&
      inputValues.contractType &&
      inputValues.countryId
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    if (Object.keys(userDetails).length > 0) {
      setInputValues({
        ...inputValues,
        firstName: userDetails?.userInfo?.firstName,
        lastName: userDetails?.userInfo?.lastName,
        email: userDetails?.userInfo?.email,
        phoneNumber: userDetails?.userInfo?.phone,
        contractType: userDetails?.userInfo?.contractType,
        isActive: userDetails?.userInfo?.isActive,
        countryId: userDetails?.userInfo?.countryId,
        capacity: userDetails?.userInfo?.capacity,
        roles: userDetails?.userRoles,
        opsId: userDetails?.userInfo?.opsId,
      });
      setUserId(userDetails?.userInfo?.userId);
      if (userDetails.userRoles.filter((role) => role.name === 'Picker').length > 0) {
        setShowCapacity(true);
      }
    }
    if (userDetails?.userInfo?.contractType === 2) {
      setShowResetButton(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (params?.id) {
      dispatch(getUserDetails(params.id));
    }
  }, []);

  useEffect(() => {
    // fetch temp password
    validateInputs();
  }, [inputValues]);

  const onModalToggle = () => {
    setIsResetModalOpen((prev) => !prev);
  };

  return (
    <>
      <div className="card">
        <UserInfoInputs
          inputValues={inputValues}
          setInputValues={setInputValues}
          userId={userId}
          showCapacity={showCapacity}
          setShowCapacity={setShowCapacity}
          isModal={false}
        />
        <div className="form-group">
          <div className="card-footer pb-0">
            <button
              className="btn btn-primary mr-3"
              type="button"
              onClick={onSave}
              disabled={isDisabled}
            >
              Save
            </button>
            <RestrictedSection page="admin_edit_picker" action="reset_picker_password">
              {showResetButton && (
                <button className="btn btn-outline-primary" type="button" onClick={onModalToggle}>
                  Reset password
                </button>
              )}
            </RestrictedSection>
          </div>
        </div>
      </div>
      {isResetModalOpen && (
        <ResetPasswordModal
          isResetModalOpen={isResetModalOpen}
          onToggle={onModalToggle}
          userId={params?.id}
        />
      )}
    </>
  );
};

export default UserEditPage;
