import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import InventoryFinanceStatementDataGrid from 'components/reports/inventory/InventoryFinanceStatementDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
import { getFinanceInventoryStatementsPageUrl } from 'constant/AppUrls';
import DJHPagination from 'components/shared/Pagination';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import InventoryReportService from 'services/reportsService/InventoryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const FinanceInventoryStatementPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    type: -1,
    transtype: -1,
    isdeliverydate: 0,
    page: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await InventoryReportService.getFinanceInventoryStatementsReport(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Finance Inventory Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  const exportFile = async () => {
    try {
      const res = await InventoryReportService.exportFinanceInventoryStatementsReport({
        ...inputValues,
        page: null,
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
      toast.success('Downloaded');
    } catch (err) {
      errorLogger('Finance Inventory Report Page Export Data Error:', err);
      toast.error(err?.response?.data?.message ?? 'Failed to download');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const updatedParams = cloneDeep(inputValues);
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getFinanceInventoryStatementsPageUrl(),
      search: queryString,
    });
    fetchData(updatedParams);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getFinanceInventoryStatementsPageUrl(),
      search: queryString,
    });
    fetchData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Statements"
        statementType
        dateType
        defaultDate
        hasExport
        handleExport={exportFile}
      />
      <InventoryFinanceStatementDataGrid data={data.data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+data.currentPage}
        pageSize={+data.pageSize}
        itemsCount={+data.rowCount}
      />
    </div>
  );
};

export default FinanceInventoryStatementPage;
