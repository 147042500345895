import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const AdminAddPage = () => {
  const [pageName, setPageName] = useState('');
  const [pageKey, setPageKey] = useState('');
  const navigate = useNavigate();

  const handleAddNewPage = async () => {
    const bodyData = {
      Name: pageName,
      Key: pageKey,
    };
    try {
      await PermissionService.createPermissionPage(bodyData);
      navigate('/admin/pages');
    } catch (err) {
      errorLogger('AdminAddPage:handleAddNewPage', err);
      toast.error(err?.response?.data?.message ?? 'Failed to add new page');
    }
  };
  return (
    <div className="card col-12 col-md-6">
      <div className="card-body btn-showcase">
        <form>
          <div className="form-group col-12">
            <label className="col-form-label" htmlFor="message-text">
              Name
            </label>
            <input
              onChange={(e) => setPageName(e.target.value)}
              className="form-control"
              id="message-text"
            ></input>
          </div>
          <div className="form-group col-12">
            <label className="col-form-label" htmlFor="message-text">
              Key
            </label>
            <input
              onChange={(e) => setPageKey(e.target.value)}
              className="form-control"
              id="message-text"
            ></input>
          </div>
        </form>
        <div className="align-center">
          <button
            onClick={handleAddNewPage}
            className="btn btn-primary"
            color="primary"
            disabled={!pageName || !pageKey}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminAddPage;
