import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const ProductBreakDownDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const imgGridTemplate = (rowData) => {
    return (
      <div className="image">
        <img
          alt={rowData.name}
          className="w-60p"
          src={`https://cdn.floward.com/web/Files/thumPro/${rowData.image}`}
        />
      </div>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          // allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          allowSorting={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="SKU"
              field="skU"
              width={100}
              customExcelTemplate={(rowData) => rowData.skU}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Name"
              field="name"
              width={150}
              customExcelTemplate={(rowData) => rowData.name}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Image"
              template={imgGridTemplate}
              width={100}
              customExcelTemplate={(rowData) =>
                `https://cdn.floward.com/web/Files/thumPro/${rowData.image}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Price"
              field="price"
              width={80}
              customExcelTemplate={(rowData) => rowData.price}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Gross Margin%"
              template={(rowData) => (
                <span>
                  {rowData.crossMargin != null &&
                    `${rowData.crossMargin.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.crossMargin != null &&
                `${rowData.crossMargin.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Designed By"
              field="designedBy"
              width={150}
              customExcelTemplate={(rowData) => rowData.designedBy}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Brand"
              field="brand"
              width={100}
              customExcelTemplate={(rowData) => rowData.brand}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Views"
              field="toTalProductViews"
              width={100}
              customExcelTemplate={(rowData) => rowData.toTalProductViews}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="No. of orders"
              field="totalOrdersCount"
              width={100}
              customExcelTemplate={(rowData) => rowData.totalOrdersCount}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Conversion"
              template={(rowData) => (
                <span>
                  {rowData.conversion != null &&
                    `${rowData.conversion.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.conversion != null &&
                `${rowData.conversion.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Revenue in Data Range"
              field="revenueInDataRange"
              width={120}
              customExcelTemplate={(rowData) => rowData.revenueInDataRange}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Revenue per view"
              template={(rowData) => (
                <span>
                  {rowData.revenuePerView != null &&
                    `${rowData.revenuePerView.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`}
                </span>
              )}
              width={150}
              customExcelTemplate={(rowData) =>
                rowData.revenuePerView != null &&
                `${rowData.revenuePerView.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`
              }
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(ProductBreakDownDataGrid);
