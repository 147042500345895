import socketIOClient from 'socket.io-client';
class SocketClient {
  constructor() {
    this.socket = {};
  }

  connect() {
    this.socket = socketIOClient.connect('https://socket.floward.com/', {
      transports: ['websocket'],
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: 10,
      agent: false, // [2] Please don't set this to true
      upgrade: false,
      rejectUnauthorized: false,
    });
    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => resolve());
      this.socket.on('connect_error', (error) => reject(error));
    });
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.disconnect(() => {
        this.socket = null;
        resolve();
      });
    });
  }

  emit(event, data) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject(new Error('No socket connection.'));

      return this.socket.emit(event, data, (response) => {
        // Response is the optional callback that you can use with socket.io in every request. See 1 above.
        if (response.error) {
          // console.error(response.error);
          return reject(response.error);
        }

        return resolve();
      });
    });
  }

  on(event, fun) {
    // No promise is needed here, but we're expecting one in the middleware.
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject(new Error('No socket connection.'));

      this.socket.on(event, fun);
      resolve();
    });
  }
}

export default SocketClient;
