/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import DateRangePickerComponent from 'components/shared/DateRangePickerComponent';
import { dateFormat, convertQueryParamsIntoObject } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
import { RefreshCw } from 'react-feather';
import CustomSelect from 'components/shared/CustomSelect';

const QuickOrdersFilter = ({
  inputValues,
  setInputValues,
  handleSubmit,
  countriesList,
  handleExport,
  isRevenue,
}) => {
  const [deliveryDateRange, setDeliveryDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [resultDateRange, setReultDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [showDeliveryDatePicker, setShowDeliveryDatePicker] = useState(false);
  const [showResultDatePicker, setShowResultDatePicker] = useState(false);
  const [initDelvVal, setInitDelvVal] = useState(true);
  const [initResVal, setInitResVal] = useState(true);
  const location = useLocation();

  const handleChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[e.target.name] = e.target?.value;
    setInputValues(updatedParams);
  };

  const handleOrderType = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.orderType = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handleOrderStatus = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.orderstatus = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handlePaymentStatus = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.paymentstatus = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handlePaymentMethod = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.paymentMethod = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handleDeliveryDate = (item) => {
    setInitDelvVal(false);
    const updatedParams = cloneDeep(inputValues);
    if (isRevenue) {
      updatedParams.deliveredDate = `${dateFormat(item.selection.startDate)}-${dateFormat(
        item.selection.endDate
      )}`;
    } else {
      updatedParams.deliverydate = `${dateFormat(item.selection.startDate)}-${dateFormat(
        item.selection.endDate
      )}`;
    }
    setInputValues(updatedParams);
    setDeliveryDateRange(item.selection);
  };

  const handleResultDate = (item) => {
    setInitResVal(false);
    const updatedParams = cloneDeep(inputValues);
    updatedParams.Resultdate = `${dateFormat(item.selection.startDate)}-${dateFormat(
      item.selection.endDate
    )}`;
    setInputValues(updatedParams);
    setReultDateRange(item.selection);
  };

  const handleCountryChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.country = e.itemData?.id;
    setInputValues(updatedParams);
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      if (isRevenue) {
        if (queryObj.deliveredDate !== 'null') {
          setInitDelvVal(false);
          const newDate = queryObj.deliveredDate;
          const newDeliveryDateRange = {
            startDate: new Date(
              `${newDate.slice(6, 10)}-${newDate.slice(0, 2)}-${newDate.slice(3, 5)}`
            ),
            endDate: new Date(
              `${newDate.slice(17, 21)}-${newDate.slice(11, 13)}-${newDate.slice(14, 16)}`
            ),
            key: 'selection',
          };
          setDeliveryDateRange(newDeliveryDateRange);
        }
      } else {
        if (queryObj.deliverydate !== 'null') {
          setInitDelvVal(false);
          const newDate = queryObj.deliverydate;
          const newDeliveryDateRange = {
            startDate: new Date(
              `${newDate.slice(6, 10)}-${newDate.slice(0, 2)}-${newDate.slice(3, 5)}`
            ),
            endDate: new Date(
              `${newDate.slice(17, 21)}-${newDate.slice(11, 13)}-${newDate.slice(14, 16)}`
            ),
            key: 'selection',
          };
          setDeliveryDateRange(newDeliveryDateRange);
        }
      }
      if (queryObj.Resultdate !== 'null') {
        setInitResVal(false);
        const newDate = queryObj.Resultdate;
        const newResultDateRange = {
          startDate: new Date(
            `${newDate.slice(6, 10)}-${newDate.slice(0, 2)}-${newDate.slice(3, 5)}`
          ),
          endDate: new Date(
            `${newDate.slice(17, 21)}-${newDate.slice(11, 13)}-${newDate.slice(14, 16)}`
          ),
          key: 'selection',
        };
        setReultDateRange(newResultDateRange);
      }
    }
  }, []);

  return (
    <div className="card">
      <div className="card-header p-18">
        <h5 className="sz-14">Quick Orders</h5>
      </div>
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group filter-select">
                <label className="bold-title" htmlFor="quickOrder-type">
                  Order Type
                </label>
                <CustomSelect
                  id="quickOrder-type"
                  listData={[
                    { id: -1, name: 'All' },
                    { id: 1, name: 'Individual' },
                    { id: 2, name: 'Corporate' },
                    { id: 4, name: 'User Subscriptions' },
                    { id: 5, name: 'Celebrity' },
                  ]}
                  handleChange={handleOrderType}
                  placeholder="Select order type"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={inputValues.orderType}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group filter-select">
                <label className="title-bold" htmlFor="quickOrder-status">
                  Order Status
                </label>
                <CustomSelect
                  id="quickOrder-status"
                  listData={[
                    { id: 2, name: 'Confirmed' },
                    { id: 3, name: 'Canceled' },
                    { id: 6, name: 'Pending Cancellation' },
                  ]}
                  handleChange={handleOrderStatus}
                  placeholder="Select order status"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={inputValues.orderstatus}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group filter-select">
                <label className="title-bold" htmlFor="quickOrder-payment">
                  Payment Status
                </label>
                <CustomSelect
                  id="quickOrder-payment"
                  listData={[
                    { id: -1, name: 'All' },
                    { id: 1, name: 'Paid' },
                    { id: 0, name: 'Not Paid' },
                  ]}
                  handleChange={handlePaymentStatus}
                  placeholder="Select payment status"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={inputValues.paymentstatus}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group filter-select">
                <label className="title-bold" htmlFor="quickOrder-method">
                  Payment Method
                </label>
                <CustomSelect
                  id="quickOrder-method"
                  listData={[
                    { id: -1, name: 'All' },
                    { id: 0, name: 'Cash' },
                    { id: 1, name: 'K-Net' },
                    { id: 4, name: 'Credit Card' },
                    { id: 5, name: 'PayPal' },
                    { id: 6, name: 'Mada' },
                    { id: 7, name: 'FLWCredit' },
                    { id: 9, name: 'ApplePay' },
                    { id: 11, name: 'Benefit' },
                    { id: 12, name: 'KSA_STCPay' },
                  ]}
                  handleChange={handlePaymentMethod}
                  placeholder="Select payment method"
                  textPropName="name"
                  valuePropName="id"
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group col-flex">
                <label className="bold-title">
                  {isRevenue ? 'Actual Delivered Date' : 'Delivery Date'}
                </label>
                <div className="normal-flex">
                  <input
                    className="form-control digits pointer white-bg"
                    value={
                      !initDelvVal
                        ? `${dateFormat(deliveryDateRange.startDate)} - ${dateFormat(
                            deliveryDateRange.endDate
                          )}`
                        : ''
                    }
                    onClick={() => setShowDeliveryDatePicker((prev) => !prev)}
                    readOnly
                  />
                  <RefreshCw
                    style={{
                      position: 'absolute',
                      width: 20,
                      cursor: 'pointer',
                      right: 22,
                    }}
                    onClick={() => window.location.reload()}
                  />
                </div>

                {showDeliveryDatePicker && (
                  <DateRangePickerComponent
                    idval="delivery-date"
                    setShowDatePicker={setShowDeliveryDatePicker}
                    handleDateRange={handleDeliveryDate}
                    dateRange={deliveryDateRange}
                    setInit={setInitDelvVal}
                    setVal={setDeliveryDateRange}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group col-flex">
                <label className="bold-title" htmlFor="quickOrder-result">
                  Result Date
                </label>
                <div className="normal-flex">
                  <input
                    id="quickOrder-result"
                    className="form-control digits white-bg pointer"
                    value={
                      !initResVal
                        ? `${dateFormat(resultDateRange.startDate)} - ${dateFormat(
                            resultDateRange.endDate
                          )}`
                        : ''
                    }
                    onClick={() => setShowResultDatePicker((prev) => !prev)}
                    readOnly
                  />
                  <RefreshCw
                    style={{
                      position: 'absolute',
                      width: 20,
                      cursor: 'pointer',
                      right: 22,
                    }}
                    onClick={() => window.location.reload()}
                  />
                </div>

                {showResultDatePicker && (
                  <DateRangePickerComponent
                    setShowDatePicker={setShowResultDatePicker}
                    handleDateRange={handleResultDate}
                    dateRange={resultDateRange}
                    setInit={setInitResVal}
                    setVal={setReultDateRange}
                  />
                )}
              </div>
            </div>
            {countriesList.length > 0 && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="quickOrder-country">
                    Country
                  </label>
                  <CustomSelect
                    id="quickOrder-country"
                    listData={[{ id: 0, name: 'All' }].concat(countriesList)}
                    handleChange={handleCountryChange}
                    placeholder="Select a country"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={inputValues.country}
                  />
                </div>
              </div>
            )}
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label className="bold-title" htmlFor="quickOrder-searchkey">
                  Search Key
                </label>
                <input
                  id="quickOrder-searchkey"
                  className="form-control white-bg pointer"
                  value={inputValues.searchkey}
                  name={'searchkey'}
                  onChange={handleChange}
                  placeholder="Order#, email, phone, first name, last name"
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label className="bold-title" htmlFor="quickOrder-product">
                  Product
                </label>
                <input
                  id="quickOrder-product"
                  className="form-control white-bg pointer"
                  value={inputValues.ProductKey}
                  name={'ProductKey'}
                  onChange={handleChange}
                  placeholder="Product SKU or Product name"
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label className="bold-title" htmlFor="quickOrder-recipient">
                  Recipient Key
                </label>
                <input
                  id="quickOrder-recipient"
                  className="form-control white-bg pointer"
                  value={inputValues.recipientKey}
                  name={'recipientKey'}
                  onChange={handleChange}
                  placeholder="Recipient name or phone"
                />
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-between">
            <button onClick={handleSubmit} className="btn btn-primary mr-1" type="button">
              Search
            </button>
            <button onClick={handleExport} className="btn btn-secondary mr-1" type="button">
              Export
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuickOrdersFilter;
