import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MapComponent from './MapComponent';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
import DatePicker from 'react-datepicker';
import { convertToUnix } from 'constant/Helpers';
// actions
import { setPremiumGiftData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const screenWidth = window.screen.width;

const AddNewGiftModal = ({ isAddGiftModalOpen, setIsAddGiftModalOpen, orderId, orderData }) => {
  const [showMap, setShowMap] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(orderData.deliveryDate));
  const [description, setDescription] = useState('');
  const [latValue, setLatValue] = useState(parseFloat(orderData.ops.lat));
  const [lngValue, setLngValue] = useState(parseFloat(orderData.ops.lng));
  const [updatedLat, setUpdatedLat] = useState('');
  const [updatedLng, setUpdatedLng] = useState('');
  const [areaList, setAreaList] = useState([]);
  const [areaId, setAreaId] = useState('-1');
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [giftAttchs, setGiftAttachs] = useState('');
  const dispatch = useDispatch();

  const fetchData = async () => {
    const params = {
      opsId: orderData.opsID,
    };
    try {
      const res = await ViewOrderService.getAreasList(params);
      let allareas = res.data;
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].subarea.length > 0) {
          allareas = allareas.concat(res.data[i].subarea);
        }
      }
      setAreaList(allareas);
    } catch (err) {
      errorLogger('AddNewGiftModal:fetchData', err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeDate = async (date) => {
    setSelectedDate(date);
  };

  const handleSaveNewGift = async () => {
    setIsLoaderOpen(true);
    setDisableBtn(true);

    const bodyData = {
      AddressDetails: addressOne,
      ItemsDescription: description,
      AreaId: areaId,
      lat: updatedLat,
      lng: updatedLng,
      CollectionDate: convertToUnix(selectedDate),
      file: giftAttchs,
    };

    const formData = new FormData();

    Object.entries(bodyData).forEach((el) => {
      if (el[0] === 'file') {
        Array.from(el[1]).forEach((file, i) => {
          formData.append(`file${i + 1}`, file);
        });
      } else {
        formData.append(el[0], el[1]);
      }
    });

    try {
      await ViewOrderService.addPremiumGift(formData, orderId);
      setTimeout(() => {
        setDisableBtn(false);
        setIsLoaderOpen(false);
        setIsAddGiftModalOpen((prev) => !prev);
        dispatch(setPremiumGiftData(orderId));
        setGiftAttachs('');
        setDescription('');
        setAreaId('-1');
        setAddressOne('');
        setUpdatedLat('');
        setUpdatedLng('');
      }, 1500);
    } catch (err) {
      errorLogger('AddNewGiftModal:handleSaveNewGift', err);
      setDisableBtn(false);
      setIsLoaderOpen(false);
      setIsAddGiftModalOpen((prev) => !prev);
      toast.error(err.response?.data?.message);
    }
  };

  const handleAreaChange = (e) => {
    setAreaId(e.itemData.id);
  };

  return (
    <Modal
      isOpen={isAddGiftModalOpen}
      toggle={() => setIsAddGiftModalOpen((prev) => !prev)}
      className="add-new-gift-modal"
    >
      {showMap ? (
        <MapComponent
          setShowMap={setShowMap}
          latValue={parseFloat(latValue)}
          lngValue={parseFloat(lngValue)}
          setAddressOne={setAddressOne}
          addressOne={addressOne}
          setAddressTwo={setAddressTwo}
          setAreaId={setAreaId}
          setUpdatedLat={setUpdatedLat}
          setUpdatedLng={setUpdatedLng}
          updatedLat={parseFloat(updatedLat)}
          updatedLng={parseFloat(updatedLng)}
          orderOps={orderData.opsID}
        />
      ) : (
        <Fragment>
          <ModalHeader toggle={() => setIsAddGiftModalOpen((prev) => !prev)}>Add Gift</ModalHeader>
          {isLoaderOpen ? (
            <SectionLoader />
          ) : (
            <Fragment>
              <ModalBody>
                <form>
                  <div className="row gift-form">
                    <div className="form-group col-12 col-sm-6">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Gift Description
                      </label>
                      <textarea
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        className="form-control"
                        type="text"
                      ></textarea>
                    </div>
                    <div className="form-group col-12 col-sm-6">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Address
                      </label>
                      <textarea
                        onChange={(e) => setAddressOne(e.target.value)}
                        value={addressOne}
                        className="form-control"
                        type="text"
                      />
                    </div>
                    <div className="form-group col-6 filter-select">
                      <label htmlFor="gift-area">Area</label>
                      <CustomSelect
                        id="gift-area"
                        listData={areaList}
                        handleChange={handleAreaChange}
                        placeholder="Select area"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={areaId}
                      />
                    </div>
                    <div className="form-group col-6">
                      <label className="col-form-label" htmlFor="message-text">
                        Collecting Date
                      </label>
                      <DatePicker
                        className="form-control digits"
                        placeholderText="Selected delivery date"
                        selected={selectedDate}
                        onChange={handleChangeDate}
                        dateFormat="dd/MM/yyyy"
                      />
                    </div>
                    <div
                      className={`form-group col-12 gift-photo-wrapper ${
                        window.screen.width < 500 ? 'column-direction' : 'row-direction'
                      }`}
                    >
                      <div className="form-group col-12 col-sm-9">
                        <label className="col-form-label pt-0" htmlFor="gift-photo">
                          Gift photo
                        </label>
                        <input
                          id="gift-photo"
                          onChange={(e) => setGiftAttachs(e.target.files)}
                          className="form-control"
                          type="file"
                          accept="image/*"
                          multiple={true}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-12 text-align-center">
                    <button
                      onClick={() => setShowMap(true)}
                      className={`btn btn-primary ${screenWidth < 500 ? 'drop-btn-small' : ''}`}
                    >
                      <i className="fa fa-map-marker"></i> drop
                    </button>
                  </div>
                  <div className="form-group col-12 col-sm-6">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Latitide
                    </label>
                    <input
                      value={updatedLat || ''}
                      onChange={(e) => setUpdatedLat(e.target.value)}
                      className="form-control"
                      type="text"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-12 col-sm-6">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Longitude
                    </label>
                    <input
                      value={updatedLng || ''}
                      onChange={(e) => setUpdatedLng(e.target.value)}
                      className="form-control"
                      type="text"
                      readOnly
                    />
                  </div>
                  {/* {orderOps == 10 && (
                    <div className="form-group col-12">
                      <label className="col-form-label" htmlFor="recipient-name">
                        Postal code
                      </label>
                      <input
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  )} */}
                </div>
              </ModalBody>
            </Fragment>
          )}

          <ModalFooter>
            <div className="col-12 modal-footer-wrapper">
              <Button
                disabled={disableBtn || !addressOne}
                onClick={handleSaveNewGift}
                color="primary"
              >
                Save
              </Button>
              <Button color="light" onClick={() => setIsAddGiftModalOpen((prev) => !prev)}>
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Fragment>
      )}
    </Modal>
  );
};

export default AddNewGiftModal;
