/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Media,
  Button,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import debounce from 'lodash/debounce';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import RestrictedSection from 'components/shared/RestrictedSection';
// actions
import {
  setEditItemsOrderDetails,
  setEditItemsPriceInfo,
  setToggleQtyModalOpen,
  setToggleCustomItemModalOpen,
} from 'store/editItems/EditItemsActions';
// selectors
import {
  getEditItemsOrderDetails,
  getOrderDetailsPriceInfo,
  getIsEditItemsQtyModalOpen,
  getIsCustomItemModalOpen,
} from 'store/editItems/EditItemsSelector';
import { getViewOrderDetails } from 'store/viewOrder/ViewOrderSelectors';
// api
import EditItemsService from 'services/EditItemsService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

// modals
const EditItemsQtyModal = React.lazy(() =>
  import('components/orders/viewOrder/modals/editItems/EditItemsQtyModal')
);
const EditItemsCustomItemModal = React.lazy(() =>
  import('components/orders/viewOrder/modals/editItems/EditItemsCustomItemModal')
);

const OrderColumsTable = ['Product', 'Product name', 'Qty', 'Price', 'Subtotal', 'Status'];

const status = {
  0: 'Added',
  1: 'Confirmed',
  2: 'Deleted',
};

const statusArr = [
  { name: 'Added', value: 0 },
  { name: 'Confirmed', value: 1 },
  { name: 'Deleted', value: 2 },
];

const EditItemsPage = () => {
  const orderDetails = useSelector((state) => getEditItemsOrderDetails({ state }));
  const priceInfo = useSelector((state) => getOrderDetailsPriceInfo({ state }));
  const orderData = useSelector((state) => getViewOrderDetails({ state }));
  const isEditQtyModalOpen = useSelector((state) => getIsEditItemsQtyModalOpen({ state }));
  const isCuatomItemModalOpen = useSelector((state) => getIsCustomItemModalOpen({ state }));
  const [searchResult, setSearchResult] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [searchKey, setSearchKey] = useState('');
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isSearchLoaderOpen, setIsSearchLoaderOpen] = useState(false);
  const [cartId, setCartId] = useState('');
  const [disableBtn, setDisabledBtn] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const fetchOrderDetailsData = async () => {
    const newParams = {
      orderid: params?.id,
    };
    setIsLoaderOpen(true);
    await dispatch(setEditItemsOrderDetails(params?.id));
    await dispatch(setEditItemsPriceInfo(newParams));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchOrderDetailsData();
  }, []);

  const fetchSearchData = debounce(async (keyword, opsId) => {
    const apiParams = {
      searchkey: keyword,
      opsId,
    };
    setSearchKey(keyword);
    setIsSearchLoaderOpen(true);
    try {
      if (priceInfo.productionLine) {
        const res = await EditItemsService.searchForPremiumItem(apiParams);
        setSearchResult(res.data);
      } else {
        const res = await EditItemsService.searchForItem(apiParams);
        setSearchResult(res.data);
      }
      setIsSearchLoaderOpen(false);
    } catch (err) {
      errorLogger('EditItemsPage:fetchSearchData', err);
      toast.error(err?.response?.data?.message);
      setIsSearchLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    if (keyword.length >= 3) {
      fetchSearchData(keyword, priceInfo.opsID);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleUpdateStatus = async (e, id) => {
    const dataBody = {
      status: e.target.value,
    };
    setCartId(id);
    setDisabledBtn(true);
    try {
      const res = await EditItemsService.updateItemStatus(dataBody, id);
      if (res.data) {
        const newParams = {
          orderid: params?.id,
        };
        setTimeout(async () => {
          await dispatch(setEditItemsOrderDetails(params?.id));
          await dispatch(setEditItemsPriceInfo(newParams));
          setDisabledBtn(false);
        }, 1500);
        toast.success('Status updated successfully!');
      } else {
        toast.error('Failed to update item status');
        setDisabledBtn(false);
      }
    } catch (err) {
      errorLogger('EditItemsPage:handleUpdateStatus', err);
      setDisabledBtn(false);
      toast.error(err.response.data.message);
    }
  };

  const switchDropDownItems = (cart) => {
    switch (cart.status) {
      case 0:
        return statusArr
          .filter((item) => item.value !== 0)
          .map((el, i) => (
            <DropdownItem key={i} value={el.value}>
              {el.name}
            </DropdownItem>
          ));
      case 1:
        return statusArr
          .filter((item) => item.value !== 1)
          .map((el, i) => (
            <DropdownItem key={i} value={el.value}>
              {el.name}
            </DropdownItem>
          ));
      case 2:
        return statusArr
          .filter((item) => item.value !== 2)
          .map((el, i) => (
            <DropdownItem key={i} value={el.value}>
              {el.name}
            </DropdownItem>
          ));
      default:
        return '';
    }
  };

  return (
    <Fragment>
      {isEditQtyModalOpen && (
        <React.Suspense fallback={<SectionLoader />}>
          <EditItemsQtyModal orderId={params?.id} />
        </React.Suspense>
      )}
      {isCuatomItemModalOpen && (
        <React.Suspense fallback={<SectionLoader />}>
          <EditItemsCustomItemModal orderId={params?.id} />
        </React.Suspense>
      )}
      <div className="topBar">
        <h5 className="primary-color">
          Edit Order Items #
          <Link to={`/order/${params?.id}`} className="primary-color text-underline ">
            {params?.id}
          </Link>{' '}
          {priceInfo.productionLine === 1 && (
            <i className="fa fa-star orange-color sz-22 m-l-5"></i>
          )}
        </h5>
        <RestrictedSection page="edit_items" action="add_custom_item">
          <Button onClick={() => dispatch(setToggleCustomItemModalOpen())} color="success">
            <i className="fa fa-plus"></i> Add Custom Item
          </Button>
        </RestrictedSection>
      </div>

      <div className="form-group card col-12">
        <div className="card-body">
          <form>
            <label className="sz-17" htmlFor="add_items">
              Add Items
            </label>
            <input
              id="add_items"
              className="form-control"
              type="text"
              placeholder="item name, description"
              onChange={(e) => handleSearch(e.target.value)}
              defaultValue={searchKey}
            />
          </form>
          {isSearchLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <Fragment>
              {searchResult.length > 0 && (
                <div
                  style={{ height: showTable ? 'auto' : '', maxHeight: 350, textAlign: 'left' }}
                  className="table-responsive shopping-table"
                >
                  {showTable && (
                    <table className="table table-bordernone create-order-table m-t-40">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Item</th>
                          <th>Available Qty</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResult.map((item, i) => (
                          <tr
                            className="search-table-row pointer"
                            onClick={() =>
                              dispatch(setToggleQtyModalOpen(item.id, item.availableQTY))
                            }
                            key={i}
                          >
                            <td>
                              <img
                                src={item.image}
                                alt="#"
                                className="img-thumbnail img-fluid img-90"
                              />
                            </td>
                            <td>{item.name}</td>
                            <td>{item.availableQTY}</td>
                            <td>{item.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>

      <div className="card col-12 m-t-25">
        <div className="height-equal">
          {isLoaderOpen ? (
            <SectionLoader />
          ) : (
            <div className="card-body">
              {orderDetails !== '' && (
                <div className="order-history table-responsive">
                  <table className="col-12 table table-bordernone">
                    <thead>
                      <tr>
                        {OrderColumsTable.map((element, i) => (
                          <th scope="col" key={i}>
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {orderDetails && (
                      <tbody>
                        {orderDetails.map((cart, i) => (
                          <Fragment key={i}>
                            <tr>
                              <td className="pointer">
                                <Media
                                  src={cart.image}
                                  alt="#"
                                  className="img-thumbnail img-fluid img-60"
                                  onClick={() => {
                                    setIsImgOpen(true);
                                    setImageSrc(cart.image);
                                  }}
                                />
                              </td>
                              <td>
                                <div className="product-name">
                                  <div className="bold-title">{cart.proName}</div>
                                  <a href="#" className="text-underline red-color">
                                    {cart.productSKU}
                                  </a>
                                </div>
                              </td>
                              <td>{cart.qty}</td>
                              <td>{cart.price}</td>
                              <td>{cart.qty * cart.price}</td>

                              <td>
                                <ButtonGroup vertical>
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      disabled={
                                        orderData.status === 2 || cartId === cart.id
                                          ? disableBtn
                                          : null
                                      }
                                      color="primary"
                                      caret
                                      className="b-r-0"
                                    >
                                      {status[cart.status]}
                                    </DropdownToggle>
                                    <DropdownMenu
                                      value={cart.status}
                                      onClick={(e) => handleUpdateStatus(e, cart.id)}
                                    >
                                      {switchDropDownItems(cart)}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </ButtonGroup>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="1"></td>
                              <td className="row " colSpan="4">
                                {cart.basketItems.map((item, i) => (
                                  <div
                                    key={i}
                                    className=" col-sm-6 col-md-4 col-lg-2 small-slick m-0-auto normal-flex"
                                  >
                                    <img
                                      src={item.product.image}
                                      alt=""
                                      className="m-r-8 w-60p h-60p"
                                    />
                                    <div className="align-left">
                                      <a className="text-underline black-color" href="#">
                                        {item.product.name}
                                      </a>
                                      {/* <p>Qty: {item.qty}</p> */}
                                    </div>
                                  </div>
                                ))}
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                      </tbody>
                    )}

                    {priceInfo && (
                      <tfoot className="top-white-border">
                        <tr>
                          <td colSpan="4"></td>
                          <td>Sub Total</td>
                          <td>{priceInfo.paymentdetails.subTotal}</td>
                        </tr>

                        <tr>
                          <td colSpan="4"></td>
                          <td>Delivery</td>
                          <td>{priceInfo.paymentdetails.deliveryCost}</td>
                        </tr>
                        <tr>
                          <td colSpan="4"></td>
                          <td>Grand Total</td>
                          <td>{priceInfo.paymentdetails.grandTotal}</td>
                        </tr>
                      </tfoot>
                    )}
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
        {isImgOpen && <Lightbox onCloseRequest={() => setIsImgOpen(false)} mainSrc={imageSrc} />}
      </div>
    </Fragment>
  );
};

export default EditItemsPage;
