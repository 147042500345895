import { updateObject } from '../utility';

const initialState = {
  salesPerformanceByCountryData: {},
  salesPerformanceData: {},
  salesPerformanceByChannelData: {},
  isKpiLoaderOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SALES_PERFORMANCE_BY_COUNTRY_DATA': {
      return updateObject(state, {
        salesPerformanceByCountryData: action.payload.data,
      });
    }
    case 'GET_SALES_PERFORMANCE_DATA': {
      return updateObject(state, {
        salesPerformanceData: action.payload.data,
      });
    }
    case 'GET_SALES_PERFORMANCE_BY_CHANNEL_DATA': {
      return updateObject(state, {
        salesPerformanceByChannelData: action.payload.data,
      });
    }
    case 'SET_KPI_LOADER_OPEN_TRUE': {
      return updateObject(state, {
        isKpiLoaderOpen: true,
      });
    }
    case 'SET_KPI_LOADER_OPEN_FALSE': {
      return updateObject(state, {
        isKpiLoaderOpen: false,
      });
    }
    default:
      return state;
  }
};

export default reducer;
