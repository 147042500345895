import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const DesignersProductPerformanceDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ''
      );
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Country"
              field="country"
              width={100}
              customExcelTemplate={(rowData) => rowData.country}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="OpsID"
              field="opsid"
              width={80}
              customExcelTemplate={(rowData) => rowData.opsid}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="SKU"
              field="sku"
              width={80}
              customExcelTemplate={(rowData) => rowData.sku}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Name"
              field="product"
              width={150}
              customExcelTemplate={(rowData) => rowData.product}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Designed By"
              field="desginer"
              width={120}
              customExcelTemplate={(rowData) => rowData.desginer}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Views"
              field="viewsCount"
              width={80}
              customExcelTemplate={(rowData) => rowData.viewsCount}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Quantity Sold"
              field="totalq"
              width={100}
              customExcelTemplate={(rowData) => rowData.totalq}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Sales (Local currency)"
              template={(rowData) => (
                <span>
                  {rowData.totalSales != null &&
                    rowData.totalSales.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.totalSales != null &&
                rowData.totalSales.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Sales (in USD)"
              template={(rowData) => (
                <span>
                  {rowData.totalSalesUsd != null &&
                    rowData.totalSalesUsd.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.totalSalesUsd != null &&
                rowData.totalSalesUsd.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Commission (Local currency)"
              template={(rowData) => (
                <span>
                  {rowData.totalSales != null &&
                    (rowData.totalSales * 0.2).toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.totalSales != null &&
                (rowData.totalSales * 0.2).toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Commission (in USD)"
              template={(rowData) => (
                <span>
                  {rowData.totalSalesUsd != null &&
                    (rowData.totalSalesUsd * 0.2).toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.totalSalesUsd != null &&
                (rowData.totalSalesUsd * 0.2).toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DesignersProductPerformanceDataGrid);
