/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogOut } from 'react-feather';
// selectors
import { getDecodedToken } from 'store/app/AppSelectors';
// actions
import { setIsLoggedInFalse, logoutApi } from 'store/app/AppActions';

const UserMenu = () => {
  const decodedToken = useSelector((state) => getDecodedToken({ state }));
  const dispatch = useDispatch();
  const handleSignOut = async () => {
    await dispatch(logoutApi());
    dispatch(setIsLoggedInFalse());
  };
  return (
    <Fragment>
      <li className="onhover-dropdown m-l-10 no-border-r">
        <div className="media align-items-center justify-content-center">
          <span className="header-link">{decodedToken.Name}</span>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover top-30">
          <li>
            <a onClick={handleSignOut}>
              <LogOut /> {'Log out'}
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
