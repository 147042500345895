import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// api
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const CustomerMessageModal = ({ isMessageModalOpen, setIsMessageModalOpen, orderId }) => {
  const [cutomerCases, setCustomerCases] = useState([]);
  const [customerMessage, setCustomerMessage] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);

  const fetchCustomerCases = async () => {
    try {
      const res = await ViewOrderService.getCustomerCommunicationCases();
      setCustomerCases(res.data);
    } catch (err) {
      errorLogger('CustomerMessageModal:fetchCustomerCases', err);
      // console.log(err);
    }
  };

  useEffect(() => {
    fetchCustomerCases();
  }, []);

  const handleCustomerMessage = async () => {
    const dataBody = {
      type: customerMessage,
    };
    setDisableBtn(true);
    try {
      await ViewOrderService.sendCustomerCommunicationMessage(dataBody, orderId);
      setIsMessageModalOpen((prev) => !prev);
      setDisableBtn(false);
      toast.success('Sent');
    } catch (err) {
      errorLogger('CustomerMessageModal:handleCustomerMessage', err);
      setIsMessageModalOpen((prev) => !prev);
      setDisableBtn(false);
      toast.error(err.response.data.message);
    }
  };

  const handleCaseChange = (e) => {
    setCustomerMessage(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isMessageModalOpen} toggle={() => setIsMessageModalOpen((prev) => !prev)}>
        <ModalHeader>Send a message</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group col-12 filter-select">
              <CustomSelect
                listData={cutomerCases}
                handleChange={handleCaseChange}
                placeholder="Select a case"
                textPropName="title"
                valuePropName="id"
                selectedValue={customerMessage}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleCustomerMessage}
            color="primary"
            disabled={!customerMessage || disableBtn}
          >
            Save
          </Button>
          <Button color="light" onClick={() => setIsMessageModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomerMessageModal;
