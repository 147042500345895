import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CommentBox from 'components/orders/viewOrder/CommentBox';
// actions
import { setToggleNoteModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getIsNoteModalOpen,
  getOrderId,
} from 'store/orderQuickActions/dataTable/DataTableSelector';

const WriteNoteModal = ({ isAction, orderNote, premium }) => {
  const isNoteModalOpen = useSelector((state) => getIsNoteModalOpen({ state }));
  const orderId = useSelector((state) => getOrderId({ state }));
  const dispatch = useDispatch();

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isNoteModalOpen} toggle={() => dispatch(setToggleNoteModalOpen())}>
        <ModalHeader toggle={() => dispatch(setToggleNoteModalOpen())}>Add Note</ModalHeader>
        <ModalBody>
          <CommentBox
            orderId={orderId}
            isAction={isAction}
            orderNote={orderNote}
            premium={premium}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default WriteNoteModal;
