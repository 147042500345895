import React from 'react';

const LoadingIcon = () => (
  <div className="snippet" data-title=".dot-pulse">
    <div className="stage">
      <div className="dot-pulse"></div>
    </div>
  </div>
);

export default LoadingIcon;
