import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Components
import PageTitle from 'components/shared/PageTitle';
import DriverRadiusForm from 'components/delivery/driverRadius/DriverRadiusForm';
// Actions
import { getDriverRadius } from 'store/delivery/driverRadius/DriverRadiusActions';

const DriverRadiusPage = () => {
  const [inputValues, setInputValues] = useState({
      customerRadius: '',
      workshopRadius: '',
    }),
    [opsName, setOpsName] = useState(''),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDriverRadius()).then((result) => {
      setOpsName(result.data.OpsName || '');
      setInputValues({
        customerRadius: result.data.CustomerRadius || '',
        workshopRadius: result.data.HubRadius || '',
      });
    });
  }, []);

  return (
    <>
      <PageTitle title="Driver Radius" />
      <DriverRadiusForm inputValues={inputValues} setInputValues={setInputValues} title={opsName} />
    </>
  );
};

export default DriverRadiusPage;
