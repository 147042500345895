import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProductTypeBreakDownDataGrid from 'components/reports/products/productTypeBreakdown/ProductTypeBreakDownDataGrid';
import DefinitionFilterComponent from 'components/reports/products/productTypeBreakdown/DefinitionFilterComponent';
import { toast } from 'react-toastify';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import ProductsReportService from 'services/reportsService/ProductsReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ProductTypeBreakDownPage = () => {
  const [data, setData] = useState([]);
  const [definition, setDefinition] = useState(0);
  const dispatch = useDispatch();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await ProductsReportService.getProductTypeBreakDown(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Product Type Breakdown Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    fetchData({ ProductDefinition: definition });
  }, []);

  const handleSubmit = () => {
    fetchData({ ProductDefinition: definition });
  };

  return (
    <div className="toolbar-table-style">
      <DefinitionFilterComponent
        definition={definition}
        setDefinition={setDefinition}
        handleSubmit={handleSubmit}
      />
      <ProductTypeBreakDownDataGrid data={data} />
    </div>
  );
};

export default ProductTypeBreakDownPage;
