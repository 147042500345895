import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';

const AddExcludedSlotsModal = ({
  isAddExcludedSlotsModalOpen,
  setIsAddExcludedSlotsModalOpen,
  setAddProductExcludedSlotById,
  setDeleteProductExcludedSlotById,
  productExcludedSlots,
  excludedSlotsById,
  productId,
}) => {
  const fields = { text: 'slotName', value: 'slotId' };
  const dispatch = useDispatch();

  let mulObj;

  const handleAddAttribute = (e) => {
    dispatch(setAddProductExcludedSlotById(productId, e.itemData.slotId, e.itemData));
  };

  const handleDeleteAttribute = (e) => {
    dispatch(setDeleteProductExcludedSlotById(productId, e.itemData.slotId));
  };

  const created = (args) => {
    setTimeout(function () {
      mulObj.showPopup();
    }, 460);
  };

  const close = (args) => {
    args.cancel = true;
  };

  return (
    <Modal
      isOpen={isAddExcludedSlotsModalOpen}
      toggle={() => setIsAddExcludedSlotsModalOpen((prev) => !prev)}
    >
      <ModalHeader toggle={() => setIsAddExcludedSlotsModalOpen((prev) => !prev)}>
        Add excluded slot
      </ModalHeader>
      <ModalBody className="h-600p">
        <div className="control-pane">
          <div id="multisection" className="control-section filter-select">
            <div id="multidefault">
              <div className="control-styles">
                <div>
                  <AppErrorBoundary>
                    {isAddExcludedSlotsModalOpen && (
                      <MultiSelectComponent
                        ref={(scope) => {
                          mulObj = scope;
                        }}
                        id="checkbox"
                        dataSource={productExcludedSlots}
                        value={excludedSlotsById}
                        filterBarPlaceholder="Search excluded slot"
                        fields={fields}
                        placeholder="Select excluded slot"
                        mode="CheckBox"
                        enableSelectionOrder={false}
                        popupHeight="550px"
                        select={(e) => handleAddAttribute(e)}
                        removed={(e) => handleDeleteAttribute(e)}
                        close={close}
                        created={created}
                        openOnClick="false"
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    )}
                  </AppErrorBoundary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => setIsAddExcludedSlotsModalOpen((prev) => !prev)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddExcludedSlotsModal;
