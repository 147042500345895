export const getFloristCommunityList = ({ state }) => state.floristCommunity.floristsCommunityList;
export const getFloristCommunityListPageSize = ({ state }) => state.floristCommunity.pageSize;
export const getFloristCommunityListCurrentPage = ({ state }) => state.floristCommunity.currentPage;
export const getFloristCommunityListRowCount = ({ state }) => state.floristCommunity.rowCount;
export const getFloristDesignData = ({ state }) => state.floristCommunity.floristsCommunityDesign;
export const getFloristUserData = ({ state }) => state.floristCommunity.floristsCommunityUser;
export const getFloristActionedData = ({ state }) =>
  state.floristCommunity.floristsCommunityActioned;
export const getFloristCommunityTransactionList = ({ state }) =>
  state.floristCommunity.floristsCommunityTransactionList;
export const getFloristCommunityTransactionListPageSize = ({ state }) =>
  state.floristCommunity.transactionPageSize;
export const getFloristCommunityTransactionListCurrentPage = ({ state }) =>
  state.floristCommunity.transactionCurrentPage;
export const getFloristCommunityTransactionListRowCount = ({ state }) =>
  state.floristCommunity.transactionRowCount;
export const getFloristCommunityTransactionData = ({ state }) =>
  state.floristCommunity.floristsCommunityTransaction;
export const getFloristCommunityTransactionComments = ({ state }) =>
  state.floristCommunity.floristsCommunityComments;
