import React, { useRef, useState } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import RemoveUserTemplate from './RemoveUserTemplate';
import { toast } from 'react-toastify';
//components
import has from 'lodash/has';
import toString from 'lodash/toString';
//api
import DeliveryService from 'services/DeliveryService';

const CoverageAreasDataGrid = ({ data, inputValues, fetchCoverageAreasList }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'],
    [disableBtn, setDisableBtn] = useState(false),
    ref = useRef(),
    toolbarClick = (args) => {
      switch (args.item.text) {
        case 'Excel Export':
          ref.current.excelExport();
          break;
        default:
          break;
      }
    };

  //custom columns
  const idTemplate = (rowData) => (
    <span className="total-txt link area-link">{parseInt(rowData.Id, 10)}</span>
  );

  const nameTemplate = (rowData) => (
    <span className="total-txt link area-link">{rowData.Name}</span>
  );

  const nameArTemplate = (rowData) => (
    <span className="total-txt link area-link">{rowData.NameAr}</span>
  );

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const removeUserTemplate = (rowData) => {
    return (
      <RemoveUserTemplate
        handleRemoveArea={handleRemoveArea}
        rowData={rowData}
        inputValues={inputValues}
      />
    );
  };

  const handleRemoveArea = async (rowData, params, setIsDisabled) => {
    setIsDisabled(true);
    try {
      await DeliveryService.deleteCoverageArea(rowData.Id);
      setTimeout(() => {
        fetchCoverageAreasList(params);
      }, 1500);
    } catch (err) {
      setIsDisabled(false);
      toast.error(err?.response?.data?.message ?? 'Failed to delete area');
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          allowTextWrap={true}
          showColumnChooser={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="Id"
              headerText="ID"
              template={idTemplate}
              width={60}
              customExcelTemplate={(rowData) => rowData.Id}
            />
            <ColumnDirective
              field="Name"
              headerText="Name EN"
              template={nameTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.Name}
            />
            <ColumnDirective
              field="NameAr"
              headerText="Name AR"
              template={nameArTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.NameAr}
            />
            <ColumnDirective template={removeUserTemplate} width={100} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(CoverageAreasDataGrid);
