import React from 'react';

const RedFlagIcon = ({ title }) => (
  <img
    src="https://thumbs.dreamstime.com/b/rec-record-button-trendy-flat-style-vector-icon-symbol-your-web-site-design-logo-app-ui-189659004.jpg"
    style={{ width: 23, alignSelf: 'baseline' }}
    alt={title}
  />
);

export default RedFlagIcon;
