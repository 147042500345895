/* eslint-disable indent */
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

const TicketsFilter = ({ inputValues, setInputValues, handleSubmit }) => {
  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      const updatedParams = cloneDeep(inputValues);
      updatedParams[name] = value;
      updatedParams.page = 1;
      setInputValues(updatedParams);
    };

  const submitForm = (e) => {
    if (e.isComposing || e.keyCode === 229) {
      return;
    }
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="card ticket-filter-wrapper">
      <div className="card-header ticket-filter-header">
        <h4>Filter</h4>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        onKeyDown={submitForm}
        className="form theme-form"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="form-group">
                <label className="ticket-search-label" htmlFor="tickets-searchkey">
                  Search Key
                </label>
                <input
                  id="tickets-searchkey"
                  name="searchkey"
                  onChange={handleChange('searchkey')}
                  className="form-control digits"
                  placeholder="OrderId, Title, Topic, Opened by, Assigned to"
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button
              onClick={() => handleSubmit(inputValues)}
              className="btn btn-primary mr-1"
              type="button"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TicketsFilter;
