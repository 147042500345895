import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorFallback = ({ error }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
      {/* <p>Stack:</p>
      <pre>{error?.stack}</pre> */}
    </div>
  );
};

const AppErrorBoundary = ({ children }) => {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
};

AppErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default AppErrorBoundary;
