import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/shared/PageTitle';
import { getDriversPageUrl } from 'constant/AppUrls';
import DriversDataGrid from './DriversDataGrid';
import DriversActions from './DriversActions';
import DriversFilter from './DriversFilter';
import DJHPagination from 'components/shared/Pagination';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import { errorLogger } from 'datadog/DDUtils';
import { toast } from 'react-toastify';
// modals
import AddDriverModal from './modals/AddDriverModal';
// services
import DeliveryService from 'services/DeliveryService';
// actions
import { setDriversData } from 'store/delivery/drivers/driversActions';
// selectors
import { getAllDriversData } from 'store/delivery/drivers/driversSelector';

const DriversPage = () => {
  const driversData = useSelector((state) => getAllDriversData({ state }));
  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const [clickedDriver, setClickedDriver] = useState('');
  const defaultParams = {
    page: 1,
    sortby: 4,
    type: -1,
    vehicleType: -1,
    searchkey: '',
    companykey: '',
  };
  const [inputValues, setInputValues] = useState(defaultParams);
  const [isLoaderOpen, setIsloaderOpen] = useState(false);
  const [vehiclesType, setVehiclesType] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const fetchDriversData = async (params) => {
    setIsloaderOpen(true);
    try {
      const res = await DeliveryService.getDriversList(params);
      dispatch(setDriversData(res.data));
      setIsloaderOpen(false);
    } catch (e) {
      errorLogger('DriversPage:fetchDriversData', e);
      setIsloaderOpen(false);
    }
  };

  useEffect(() => {
    fetchDriverVehicleTypes();
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues({
        ...queryObj,
        page: +queryObj.page,
        sortby: +queryObj.sortby,
        type: +queryObj.type,
        vehicleType: +queryObj.vehicleType,
      });
      fetchDriversData(queryObj);
    } else {
      fetchDriversData(defaultParams);
    }
  }, []);

  const updateDriversData = (params) => {
    const queryString = convertObjectIntoQueryParams(params);
    navigate({
      pathname: getDriversPageUrl(),
      search: queryString,
    });
    fetchDriversData(params);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    updateDriversData(inputValues);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    updateDriversData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  const handleClick = (obj) => {
    setClickedDriver(obj);
    setIsDriverModalOpen((prev) => !prev);
  };

  const fetchDriverVehicleTypes = async () => {
    try {
      const res = await DeliveryService.getDriversVehiclesTypes();
      setVehiclesType(res.data);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Drivers" />
      <DriversActions
        setIsDriverModalOpen={setIsDriverModalOpen}
        setClickedDriver={setClickedDriver}
        inputValues={inputValues}
      />
      <DriversFilter
        handleSubmitForm={handleSubmitForm}
        inputValues={inputValues}
        setInputValues={setInputValues}
        vehiclesType={vehiclesType}
      />
      <DriversDataGrid
        data={driversData.data}
        handleClick={handleClick}
        isLoaderOpen={isLoaderOpen}
      />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+driversData.currentPage}
        pageSize={+driversData.pageSize}
        itemsCount={+driversData.rowCount}
      />
      {isDriverModalOpen && (
        <AddDriverModal
          isDriverModalOpen={isDriverModalOpen}
          setIsDriverModalOpen={setIsDriverModalOpen}
          clickedDriver={clickedDriver}
          defaultParams={defaultParams}
          fetchDriversData={fetchDriversData}
          vehiclesType={vehiclesType}
        />
      )}
    </div>
  );
};

export default DriversPage;
