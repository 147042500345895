import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StatementsFilter from './StatementsFilter';
import StatementsDataGrid from './StatementsDataGrid';
// actions
import { setInventoryStatements } from 'store/inventory/InventoryActions';
// selectors
import { getInventoryStatements } from 'store/inventory/InventorySelectors';

const StatementsContent = ({ statementParams, setStatementParams, statementItemId }) => {
  const statementData = useSelector((state) => getInventoryStatements({ state }));
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();

  const fetchStatementData = async (params, id) => {
    setIsLoaderOpen(true);
    await dispatch(setInventoryStatements(params, id));
    setIsLoaderOpen(false);
  };
  useEffect(() => {
    fetchStatementData(statementParams, statementItemId);
  }, []);

  useEffect(() => {
    // clean up
    return () => {
      setStatementParams({
        fromDate: 0,
        toDate: 0,
      });
    };
  }, []);

  const handleSubmit = async () => {
    fetchStatementData(statementParams, statementItemId);
  };
  return (
    <Fragment>
      <StatementsFilter
        statementParams={statementParams}
        setStatementParams={setStatementParams}
        handleSubmit={handleSubmit}
      />
      <StatementsDataGrid statementData={statementData} isLoaderOpen={isLoaderOpen} />
    </Fragment>
  );
};

export default StatementsContent;
