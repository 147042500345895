import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CustomSelect from 'components/shared/CustomSelect';
import ProductRankingDataGrid from '../productsDataGrid/ProductRankingDataGrid';
// selectors
import { getValidatingState } from 'store/products/ProductsSelectors';
import { HasPermission } from 'constant/Helpers';
import { getPermissionsList } from 'store/app/AppSelectors';

const ProductRankingSection = ({
  productRankingById,
  productId,
  isValid,
  notValidFields,
  validateRanking,
}) => {
  const [accActive, setAccActive] = useState(true);
  const [clonedRankingData, setClonedRankingData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(-1);
  const [countries, setCountries] = useState([]);
  const [countriesDatalist, setCountriesDatalist] = useState([]);
  const isValidating = useSelector((state) => getValidatingState({ state }));

  const handleSearchPage = (e) => {
    setSelectedCountry(e.itemData.countryId);
    filterData(e.itemData.countryId);
  };

  useEffect(() => {
    if (clonedRankingData.length === 0) {
      setClonedRankingData(productRankingById);
      const newCountries = [
        ...new Map(productRankingById.map((item) => [item.countryId, item])).values(),
      ];
      setCountries(newCountries);
      setCountriesDatalist(newCountries);
    }
  }, [productRankingById]);

  const filterData = (selectedCountryId) => {
    if (selectedCountryId > -1) {
      setCountriesDatalist(countries.filter((c) => c.countryId === selectedCountryId));
    } else {
      setCountriesDatalist(countries);
    }
  };

  const permissionsList = useSelector((state) => getPermissionsList({ state }));

  const isAuthorized = HasPermission(permissionsList, 'edit_product', 'edit_ranking');

  return (
    <>
      <div className="form-group filter-select w-30 m-b-20">
        <label className="bold-title" htmlFor="ranking-country">
          Country
        </label>
        <CustomSelect
          id="ranking-country"
          listData={[{ countryId: -1, countryName: 'All' }].concat(countries)}
          handleChange={handleSearchPage}
          placeholder="Select a country"
          textPropName="countryName"
          valuePropName="countryId"
          selectedValue={selectedCountry}
        />
      </div>
      <div className="no-toolbar-table-style">
        {countriesDatalist.map((item, i) => (
          <Accordion
            key={i}
            allowZeroExpanded
            preExpanded={Array.from({ length: productRankingById.length }, (_, i) => i)}
          >
            <AccordionItem uuid={i}>
              <AccordionItemHeading onClick={() => setAccActive((prev) => !prev)}>
                <AccordionItemButton>
                  <div className="card-header between-flex pointer p-18 light-blue-bg">
                    <h5>{item.countryName}</h5>
                    {accActive ? (
                      <i className="fa fa-chevron-up"></i>
                    ) : (
                      <i className="fa fa-chevron-down"></i>
                    )}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {!isValidating && (
                  <ProductRankingDataGrid
                    productData={productRankingById?.filter((p) => p.countryId === item.countryId)}
                    productId={productId}
                    isValid={isValid}
                    notValidFields={notValidFields}
                    validateRanking={validateRanking}
                    isAuthorized={isAuthorized}
                  />
                )}
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        ))}
      </div>
    </>
  );
};

export default ProductRankingSection;
