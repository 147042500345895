import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const DesignersReportDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Image"
              template={(rowData) => (
                <span>
                  {rowData.image != null && (
                    <img
                      alt={rowData.product}
                      className="w-60p"
                      src={`https://cdn.floward.com/web/Files/thumPro/${rowData.image}`}
                    />
                  )}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                `https://cdn.floward.com/web/Files/thumPro/${rowData.image}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product"
              field="product"
              width={120}
              customExcelTemplate={(rowData) => rowData.product}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Designed by"
              field="desginer"
              width={120}
              customExcelTemplate={(rowData) => rowData.desginer}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Views"
              field="viewsCount"
              width={100}
              customExcelTemplate={(rowData) => rowData.viewsCount}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Quantity Sold"
              field="totalq"
              width={100}
              customExcelTemplate={(rowData) => rowData.totalq}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Sales"
              template={(rowData) => (
                <span>
                  {rowData.totalSales != null &&
                    rowData.totalSales.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.totalSales != null &&
                rowData.totalSales.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Commission"
              template={(rowData) => (
                <span>
                  {rowData.totalSales != null &&
                    (rowData.totalSales * 0.2).toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.totalSales != null &&
                (rowData.totalSales * 0.2).toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
              textAlign="Center"
            />
            {/* <ColumnDirective headerText="Analytic" field="" width={100} /> */}
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DesignersReportDataGrid);
