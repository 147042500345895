import React from 'react';

const KnetHistoryModal = ({ header, DataGridComponent }) => {
  return (
    <div className="card row checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          {header} <i className="fa fa-info-circle"></i>
        </h4>
      </div>
      <div className="card-body">
        <div className="quick-actions-table no-toolbar-table-style knet-table d-flex justify-content-center">
          {DataGridComponent}
        </div>
      </div>
    </div>
  );
};

export default KnetHistoryModal;
