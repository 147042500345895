import React from 'react';
import { useDispatch } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
// actions
import { rankProductsByType } from 'store/rankingProducts/RankingProductsActions';

const RankingProductsPage = ({ history, location }) => {
  const dispatch = useDispatch();

  const rankByType = (type) => {
    dispatch(rankProductsByType(type));
  };

  return (
    <div className="no-toolbar-table-style">
      <PageTitle title="Users" />
      <div className="card">
        <div className="card-header p-18">
          <h4>Ranking Products</h4>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <div className="col-md-12">
              <p>
                Top Sellers 30/30, sorted by total revenue in the last 30 days <br />
                Other products = Sort by total rvenue in the last 30 days, THEN sort by total
                revenue over lifetime.
              </p>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-primary mr-1" type="button" onClick={() => rankByType(2)}>
            Rank by revenue 30 days
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <div className="col-md-12">
              <p>New products = New products sorted by created date (most recent at the top).</p>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-primary mr-1" type="button" onClick={() => rankByType(3)}>
            Rank by new created products
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <div className="col-md-12">
              <p>Rank products by margin (Price-Cost).</p>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-primary mr-1" type="button" onClick={() => rankByType(4)}>
            Rank by margin
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <div className="col-md-12">
              <p>
                Top Sellers 30/30, sorted by total revenue in the last 30 days <br />
                New products = New products sorted by created date (most recent at the top). <br />
                Other products = Sort by total rvenue in the last 30 days, THEN sort by total
                revenue over lifetime.
              </p>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-primary mr-1" type="button" onClick={() => rankByType(1)}>
            Rank by top sellers 30/30
          </button>
        </div>
      </div>
    </div>
  );
};

export default RankingProductsPage;
