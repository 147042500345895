import { updateObject } from '../utility';

const initialState = {
  editItemsOrderDetails: '',
  priceInfo: '',
  isQtyModalOpen: false,
  itemId: '',
  itemQty: '',
  isCustomItemModalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EDIT_ITEMS_ORDER_DETAILS': {
      return updateObject(state, {
        editItemsOrderDetails: action.payload,
      });
    }

    case 'SET_EDIT_ITEMS_PRICE_INFO': {
      return updateObject(state, {
        priceInfo: action.payload,
      });
    }

    case 'TOGGLE_EDIT_ITEM_QTY_MODAL_OPEN': {
      return updateObject(state, {
        isQtyModalOpen: !state.isQtyModalOpen,
        itemId: action.payload.id,
        itemQty: action.payload.qty,
      });
    }

    case 'TOGGLE_CUSTOM_ITEM_MODAL_OPEN': {
      return updateObject(state, {
        isCustomItemModalOpen: !state.isCustomItemModalOpen,
      });
    }
    default:
      return state;
  }
};

export default reducer;
