import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class SalesReportService {
  static getSalesReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.salesReport, ...extraParams),
      params,
    });
  }
}
export default SalesReportService;
