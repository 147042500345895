import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import DataTableComponent from 'components/shared/DataTableComponent';
import { columns } from '../viewOrderCommons';
import SectionLoader from 'components/common/SectionLoader';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UserTotalOrdersList = ({ isUserTotalOrdersOpen, setIsUserTotalOrdersOpen, userId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [userOrdersList, setUserOrdersList] = useState('');

  const fetchData = async () => {
    const params = {
      userId,
    };
    setIsLoaderOpen(true);
    try {
      const res = await ViewOrderService.getUserTotalOrdersList(params);
      setIsLoaderOpen(false);
      setUserOrdersList(res.data.data);
    } catch (e) {
      errorLogger('UserTotalOrdersList:addSingleInventoryItem', e);
      setIsLoaderOpen(false);
      toast.error('Failed to get user orders!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isUserTotalOrdersOpen}
        toggle={() => setIsUserTotalOrdersOpen((prev) => !prev)}
        className="user-total-orders-modal"
      >
        <ModalBody>
          <div>
            {isLoaderOpen ? (
              <SectionLoader height="400px" />
            ) : (
              <div className="table-responsive shopping-table text-center">
                {userOrdersList && (
                  <DataTableComponent
                    data={userOrdersList}
                    columns={columns}
                    header="User Orders List"
                  />
                )}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 cancel-wrapper">
            <Button onClick={() => setIsUserTotalOrdersOpen((prev) => !prev)} color="light">
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserTotalOrdersList;
