import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
// Components
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UserInfoInputs from 'components/administration/users/components/userInfo/UserInfoInputs';
import { toast } from 'react-toastify';
// Actions
import { getUsersListData, createNewUser } from 'store/user/UserActions';
// Utility
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const CreateUserModal = ({
  isModalOpen,
  onToggle,
  title,
  userGroupType,
  togglePasswordModal,
  setTempPassword,
  pageInputValues,
  showCapacity,
}) => {
  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryId: getFromLocalStorage(localStorageKeys.COUNTRY_ID),
    contractType: -1,
    groupType: userGroupType || -1,
    capacity: 100,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  const onSave = async () => {
    setIsDisabled(true);
    if (inputValues.capacity < 1 || inputValues.capacity > 1000) {
      toast.error('Capacity should be between 1 and 1000');
      return;
    }
    const res = await dispatch(createNewUser(inputValues));
    setIsDisabled(false);
    if (res?.status === 200) {
      onToggle();
      if (res?.data?.tempPassword) {
        setTempPassword(res?.data?.tempPassword);
        togglePasswordModal();
      }
      setTimeout(() => {
        if (location.search) {
          const queryObj = convertQueryParamsIntoObject(location.search);
          dispatch(getUsersListData(queryObj));
        } else {
          dispatch(getUsersListData(pageInputValues));
        }
      }, 2000);
    }
  };

  const validateInputs = () => {
    if (
      inputValues.firstName &&
      inputValues.lastName &&
      inputValues.email &&
      inputValues.phoneNumber &&
      inputValues.contractType !== -1
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    validateInputs();
  }, [inputValues]);

  return (
    <Modal size="lg" isOpen={isModalOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>{title}</ModalHeader>
      <ModalBody>
        <UserInfoInputs
          inputValues={inputValues}
          setInputValues={setInputValues}
          isModal
          showCapacity={showCapacity}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSave} disabled={isDisabled}>
          Save
        </Button>
        <Button color="light" onClick={onToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateUserModal;
