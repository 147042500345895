import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import InventorySuppliersDataGrid from 'components/reports/inventory/InventorySuppliersDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// urls
import { getInventorySupplierReportPageUrl } from 'constant/AppUrls';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { getInventorySuppliersList } from 'store/inventory/InventoryActions';
// selectors
import { getSuppliersList } from 'store/inventory/InventorySelectors';
// api
import InventoryReportService from 'services/reportsService/InventoryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const InventorySuppliersPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: '',
    toDate: '',
    datetype: 0,
    supplierid: -1,
  });
  const suppliersList = useSelector((state) => getSuppliersList({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await InventoryReportService.getInventorySuppliersReport(params);
      const updatedList = res.data.data.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      setData(updatedList);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Inventory Suppliers Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  const fetchSuppliersList = () => {
    dispatch(getInventorySuppliersList());
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
    fetchSuppliersList();
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: getInventorySupplierReportPageUrl(),
      search: queryString,
    });
    fetchData(inputValues);
  };

  const handleExport = async () => {
    try {
      const res = await InventoryReportService.exportInventorySuppliersReport(inputValues);
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('Inventory Suppliers Report Page Export Data Error:', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        productType
        supplierType
        title="Inventory Suppliers Report"
        suppliersList={suppliersList}
        // hasExport
        handleExport={handleExport}
        defaultDate
      />
      <InventorySuppliersDataGrid data={data} />
    </div>
  );
};

export default InventorySuppliersPage;
