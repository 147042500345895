import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class OccasionsService {
  static getOccasionsList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.occasionsList, ...extraParams),
    });
  }

  static getOccasionsCardsList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.occcasionsCardslist, ...extraParams),
    });
  }

  static saveChangedCardOccasion(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.savedCardOccasion, ...extraParams),
      data,
    });
  }

  static getOccasionsPredictions(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getOccasionsPredictions, ...extraParams),
      data,
    });
  }
}
export default OccasionsService;
