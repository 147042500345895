import React from 'react';

const CoverageAreasActions = ({ setIsCoverageModalOpen }) => {
  return (
    <div className="card">
      <div className="card-body btn-showcase">
        <button
          type="button"
          className="btn btn-success"
          onClick={() => setIsCoverageModalOpen(true)}
        >
          <i className="fa fa-plus"></i> Add Coverage Area
        </button>
      </div>
    </div>
  );
};

export default CoverageAreasActions;
