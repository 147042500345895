import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// actions
import { setToggleEditCardModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';
import { setCardMessage } from 'store/viewOrder/ViewOrderActions';
// selectors
import {
  getIsEditCardModalOpen,
  getCardData,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const EditCardModal = ({ orderId }) => {
  const cardData = useSelector((state) => getCardData({ state }));
  const [messageFrom, setMesssageFrom] = useState(cardData ? cardData.messageFrom : '');
  const [messageTo, setMesssageTo] = useState(cardData ? cardData.messageTo : '');
  const [messageText, setMesssageText] = useState(cardData ? cardData.messageText : '');
  const [messageLink, setMesssageLink] = useState(cardData ? cardData.feelingLink : '');
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const isCardModalOpen = useSelector((state) => getIsEditCardModalOpen({ state }));
  const dispatch = useDispatch();

  const handleCardSubmit = async () => {
    const dataBody = {
      messageFrom,
      messageTo,
      messageText,
      feelingLink: messageLink,
    };
    setDisableSaveBtn(true);
    try {
      if (cardData) {
        await ViewOrderService.getEditCard(dataBody, cardData.id);
        setDisableSaveBtn(false);
        dispatch(setCardMessage(orderId));
        dispatch(setToggleEditCardModalOpen());
        toast.success('Card edited successfully');
      } else {
        await ViewOrderService.getAddCard(dataBody, orderId, 'addcard');
        setDisableSaveBtn(false);
        dispatch(setCardMessage(orderId));
        dispatch(setToggleEditCardModalOpen());
        toast.success('Card added successfully');
      }
    } catch (e) {
      errorLogger('EditCardModal:handleCardSubmit', e);
      dispatch(setToggleEditCardModalOpen());
      toast.error('Failed to add a new card');
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isCardModalOpen} toggle={() => dispatch(setToggleEditCardModalOpen())}>
        <ModalHeader toggle={() => dispatch(setToggleEditCardModalOpen())}>
          Card Details
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="recipient-name">
                From
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="sender"
                value={messageFrom}
                onChange={(e) => setMesssageFrom(e.target.value)}
              />
              <label className="col-form-label" htmlFor="recipient-name">
                To
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="recipient"
                value={messageTo}
                onChange={(e) => setMesssageTo(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Message
              </label>
              <textarea
                className="form-control"
                id="message-text"
                placeholder="Message"
                value={messageText}
                onChange={(e) => setMesssageText(e.target.value)}
              ></textarea>
              <label className="col-form-label" htmlFor="message-text">
                Link
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Link"
                value={messageLink}
                onChange={(e) => setMesssageLink(e.target.value)}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleCardSubmit()} color="primary" disabled={disableSaveBtn}>
            Save
          </Button>
          <Button color="light" onClick={() => dispatch(setToggleEditCardModalOpen())}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditCardModal;
