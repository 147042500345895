import React from 'react';

const AreaForm = ({
  zone,
  setZone,
  deliveryCharge,
  setDeliveryCharge,
  mapKey,
  setMapKey,
  isActive,
  setIsActive,
}) => {
  return (
    <>
      <div className="form-group col-6">
        <label className="bold-title" htmlFor="subarea-zone">
          Zone
        </label>
        <input
          id="subarea-zone"
          value={zone}
          onChange={(e) => setZone(e.target.value)}
          className="form-control"
          type="number"
        />
      </div>
      <div className="form-group col-6">
        <label className="bold-title" htmlFor="subarea-delv-charge">
          Delivery Charge
        </label>
        <input
          id="subarea-delv-charge"
          value={deliveryCharge}
          onChange={(e) => setDeliveryCharge(e.target.value)}
          className="form-control"
          type="text"
        />
      </div>
      <div className="form-group col-12">
        <label className="bold-title" htmlFor="subarea-searchkey">
          Map Search Key or names (separate them with ,)
        </label>
        <input
          id="subarea-searchkey"
          value={mapKey}
          onChange={(e) => setMapKey(e.target.value)}
          className="form-control"
          type="text"
        />
      </div>
      <div className="form-group col-12 ">
        <input
          className="checkbox_animated"
          type="checkbox"
          checked={isActive}
          onChange={(e) => setIsActive((prev) => !prev)}
        />
        <span className="bold-title">Active</span>
      </div>
    </>
  );
};

export default AreaForm;
