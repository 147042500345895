import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import CustomSelect from 'components/shared/CustomSelect';
import VoucherLimitCategories from './VoucherLimitCategories';
import RestrictedSection from 'components/shared/RestrictedSection';
// constants
import { convertToDateAndTime, convertObjectIntoQueryParams } from 'constant/Helpers';
import { initialPayload } from 'constant/filteInputs';
// actions
import {
  setVoucherCategoryList,
  setCouponDetailsById,
  setVoucherLimitCategories,
} from 'store/vouchers/VouchersActions';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// selectors
import {
  getVoucherCatergoriesList,
  getCouponDetailsById,
  getVoucherLimitCategories,
} from 'store/vouchers/VoucherSelectors';
// api
import VouchersService from 'services/VouchersService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const EditCouponSection = ({ couponId }) => {
  const couponDetailsById = useSelector((state) => getCouponDetailsById({ state }));
  const voucherCategoriesList = useSelector((state) => getVoucherCatergoriesList({ state }));
  const voucherLimitCategories = useSelector((state) => getVoucherLimitCategories({ state }));
  const [couponCode, setCouponCode] = useState('');
  const [discountType, setDiscountType] = useState(0);
  const [discountAmount, setDiscountAmount] = useState('');
  const [minOrderSubtotal, SetMinOrderSubTotal] = useState(0);
  const [catId, setCatId] = useState('');
  const [opsAvailability, setOpsAvailability] = useState('');
  const [totalUsage, setTotalUsage] = useState('');
  const [usagePerUser, setUsagePerUser] = useState(1);
  const [maxDiscount, setMaxDiscount] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reason, setReason] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isCatVoucher, setIsCatVoucher] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = {
    0: 'NotUsed',
    1: 'Used',
  };

  const getLimitCategories = () => {
    dispatch(setVoucherLimitCategories(couponId));
  };

  useEffect(() => {
    if (Object.keys(couponDetailsById).length > 0) {
      setCouponCode(couponDetailsById.code);
      setDiscountType(couponDetailsById.type);
      setDiscountAmount(couponDetailsById.amount);
      SetMinOrderSubTotal(couponDetailsById.minmumAmountToOrder);
      setCatId(couponDetailsById.catId);
      setOpsAvailability(couponDetailsById.opsAvailability);
      setTotalUsage(couponDetailsById.maxTime);
      setUsagePerUser(couponDetailsById.timeOfUsePerUser);
      setMaxDiscount(couponDetailsById.maximumDiscount);
      setStartDate(new Date(couponDetailsById.startDate));
      setEndDate(new Date(couponDetailsById.expiredDate));
      setReason(couponDetailsById.comment);
      setIsActive(couponDetailsById.isActive);
      setIsCatVoucher(couponDetailsById.categoryDiscount);
      setIsExpired(couponDetailsById.isExpired);
    }
  }, [couponDetailsById]);

  useEffect(() => {
    if (couponId) {
      dispatch(setCouponDetailsById(couponId));
      getLimitCategories();
    }
  }, []);

  useEffect(() => {
    dispatch(setVoucherCategoryList());
  }, []);

  const handleDiscountTypeChange = (e) => {
    if (e.itemData.id === 1) {
      setOpsAvailability(1);
    }
    setDiscountType(e.itemData.id);
  };

  const handleVoucherCategory = (e) => {
    setCatId(e.itemData.id);
  };

  const handleOpsAvailability = (e) => {
    setOpsAvailability(e.itemData.id);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleAddNewItem = async () => {
    const bodyData = {
      Code: couponCode,
      Amount: discountAmount,
      UsedUser: null,
      UsedDate: couponDetailsById ? couponDetailsById.createdDate : new Date(),
      ExpiredDate: endDate,
      StartDate: startDate,
      Status: couponDetailsById ? couponDetailsById.status : 0,
      IsActive: isActive,
      CategoryDiscount: isCatVoucher,
      IsExpired: isExpired,
      MaxTime: totalUsage,
      Type: discountType,
      Comment: reason,
      catID: catId,
      opsAvailability,
      TimeOfUsePerUser: usagePerUser,
      MinmumAmountToOrder: minOrderSubtotal,
      MaximumDiscount: maxDiscount,
    };
    dispatch(setLoaderOpenTrue());
    try {
      if (couponId) {
        const res = await VouchersService.updateCouponDetails(bodyData, couponId);
        toast.success(res?.data?.message ?? 'Coupon has been updated successfully');
      } else {
        const res = await VouchersService.createNewCoupon(bodyData);
        toast.success(res?.data?.message ?? 'Coupon has been added successfully');
      }
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('EditCouponSection:handleAddNewItem', err);
      toast.error(err?.response?.data?.message ?? 'Failed to add coupon');
      dispatch(setLoaderOpenFalse());
    }
  };

  const handleAutoGenerate = async () => {
    try {
      const res = await VouchersService.generateCouponCode();
      toast.success(res?.data?.message ?? 'Coupon code has been created');
      setCouponCode(res.data);
    } catch (err) {
      errorLogger('EditCouponSection:handleAutoGenerate', err);
      toast.error('Failed to generate coupon code');
    }
  };

  const handleToOrdersList = () => {
    const inputValues = { ...initialPayload, voucherCode: couponCode };
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: '/orders/quickactions',
      search: queryString,
    });
  };

  const onOpen = (e) => {
    if (discountType === 1) {
      e.popup.element.querySelectorAll('.e-list-item')[0].classList.add('e-disabled');
    } else {
      e.popup.element.querySelectorAll('.e-list-item')[0].classList.remove('e-disabled');
    }
  };

  return (
    <div className="form-group col-12 card coupoun-section">
      {couponId && (
        <div className="form-group col-sm-3">
          <button
            onClick={handleToOrdersList}
            className="btn btn-secondary orders-list-btn"
            type="button"
          >
            <i className="fa fa-file"></i> Coupon Orders List
          </button>
        </div>
      )}
      <div className="card-header coupon-title">{couponId ? 'Edit Coupon' : 'Create Coupon'}</div>
      <div className="card-body row">
        <div className="form-group col-sm-4 col-12">
          <label className="bold-title">
            Coupon Code |{' '}
            <span className="generate-code" onClick={handleAutoGenerate}>
              Auto Create
            </span>
          </label>
          <input
            onChange={(e) => setCouponCode(e.target.value)}
            value={couponCode}
            className="form-control"
            type="text"
          />
        </div>
        <div className="col-sm-4 col-12">
          <div className="form-group filter-select">
            <label className="bold-title" htmlFor="coupon-discount">
              Discount Type
            </label>
            <CustomSelect
              id="coupon-discount"
              listData={[
                { id: 0, name: 'Percentage' },
                { id: 1, name: 'Fixed Amount' },
              ]}
              handleChange={handleDiscountTypeChange}
              placeholder="Select a date type"
              textPropName="name"
              valuePropName="id"
              selectedValue={discountType}
            />
          </div>
        </div>
        <div className="form-group col-sm-4 col-12">
          <label className="bold-title" htmlFor="coupon-amount">
            Discount Amount
          </label>
          <input
            id="coupon-amount"
            onChange={(e) => setDiscountAmount(e.target.value)}
            value={discountAmount}
            className="form-control"
            type="number"
          />
        </div>
        <div className="form-group col-sm-4 col-12">
          <label className="bold-title" htmlFor="coupon-min">
            Minimum order subtotal
          </label>
          <input
            id="coupon-min"
            onChange={(e) => SetMinOrderSubTotal(e.target.value)}
            value={minOrderSubtotal}
            className="form-control"
            type="number"
          />
        </div>
        <div className="form-group col-sm-4 col-12 filter-select">
          <label className="bold-title" htmlFor="coupon-category">
            Category
          </label>
          {voucherCategoriesList.length > 0 && (
            <CustomSelect
              id="coupon-category"
              listData={[{ id: -1, name: 'All' }].concat(voucherCategoriesList)}
              handleChange={handleVoucherCategory}
              placeholder="Select voucher category"
              textPropName="name"
              valuePropName="id"
              selectedValue={catId}
            />
          )}
        </div>
        <div className="col-sm-4 col-12">
          <div className="form-group filter-select">
            <label className="bold-title" htmlFor="coupon-ops">
              Ops Availability
            </label>
            <CustomSelect
              id="coupon-ops"
              listData={[
                { id: 0, name: 'All Countries' },
                { id: 1, name: 'Current Ops Country' },
                { id: 2, name: 'Current Ops Only' },
              ]}
              handleChange={handleOpsAvailability}
              placeholder="Select Ops"
              textPropName="name"
              valuePropName="id"
              selectedValue={opsAvailability}
              onOpen={onOpen}
            />
          </div>
        </div>
        <div className="form-group col-sm-4 col-12">
          <label className="bold-title" htmlFor="coupon-usage">
            Total of Usage
          </label>
          <input
            id="coupon-usage"
            onChange={(e) => setTotalUsage(e.target.value)}
            value={totalUsage}
            className="form-control"
            type="number"
          />
        </div>
        <div className="form-group col-sm-4 col-12">
          <label className="bold-title" htmlFor="coupon-user">
            Usage per user
          </label>
          <input
            id="coupon-user"
            onChange={(e) => setUsagePerUser(e.target.value)}
            value={usagePerUser}
            className="form-control"
            type="number"
          />
        </div>
        <div className="form-group col-sm-4 col-12">
          <label className="bold-title" htmlFor="coupon-max">
            Maximum discount in $
          </label>
          <input
            id="coupon-max"
            onChange={(e) => setMaxDiscount(e.target.value)}
            value={maxDiscount}
            className="form-control"
            type="number"
          />
        </div>
        <div className="form-group col-sm-4 col-12">
          <label className="col-form-label bold-title" htmlFor="message-text">
            Start Date
          </label>
          <DatePicker
            className="form-control digits"
            placeholderText="Selected start date"
            selected={startDate}
            onChange={handleStartDate}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="form-group col-sm-4 col-12">
          <label className="col-form-label bold-title" htmlFor="message-text">
            End Date
          </label>
          <DatePicker
            className="form-control digits"
            placeholderText="Selected end date"
            selected={endDate}
            onChange={handleEndDate}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div className="form-group col-sm-4 col-12">
          <label className="bold-title" htmlFor="coupon-reason">
            Reason(comment)
          </label>
          <textarea
            id="coupon-reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="form-control"
            type="text"
          ></textarea>
        </div>
        <div className="col-md-12 form-group bold-title">
          <div className="col-md-4 form-group">
            <input
              onChange={() => setIsActive((prev) => !prev)}
              className="m-r-5"
              type="checkbox"
              checked={isActive}
            ></input>
            <label className="col-form-label" htmlFor="recipient-name">
              Active
            </label>
          </div>
          <div className="col-md-4 form-group">
            <input
              onChange={() => setIsExpired((prev) => !prev)}
              className="m-r-5"
              type="checkbox"
              checked={isExpired}
            ></input>
            <label className="col-form-label" htmlFor="recipient-name">
              Expired
            </label>
          </div>
        </div>
        <div className="form-group col-12">
          <label className="bold-title" htmlFor="coupon-status">
            Status
          </label>
          <input
            id="coupon-status"
            value={couponId ? status[couponDetailsById.status] : ''}
            className="form-control"
            type="text"
            readOnly
          />
        </div>

        <div className="form-group col-12">
          <label className="bold-title" htmlFor="coupon-created">
            Created Date
          </label>
          <input
            id="coupon-created"
            value={
              couponId
                ? convertToDateAndTime(couponDetailsById.createdDate)
                : convertToDateAndTime(new Date())
            }
            className="form-control"
            type="text"
            readOnly
          />
        </div>
      </div>
      {couponId && (
        <VoucherLimitCategories
          voucherLimitCategories={voucherLimitCategories}
          couponId={couponId}
          setIsCatVoucher={setIsCatVoucher}
          isCatVoucher={isCatVoucher}
          getLimitCategories={getLimitCategories}
        />
      )}
      <RestrictedSection page="vouchers" action="save_voucher_btn">
        <div className="card-footer  d-flex justify-content-between">
          <button onClick={handleAddNewItem} className="btn btn-primary" type="button">
            Save
          </button>
        </div>
      </RestrictedSection>
    </div>
  );
};

export default EditCouponSection;
