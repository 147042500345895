import { updateObject } from '../utility';

const initialState = {
  designersListData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DESIGNERS_LIST_DATA': {
      return updateObject(state, {
        designersListData: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
