import { updateObject } from '../utility';

const initialState = {
  seasonalCategories: {},
  seasonalData: [],
  seasonalProducts: {},
  fetchingSeasonalProducts: true,
  selectedSeasonalProducts: [],
  fetchingSeasonal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SEASONAL_CATEGORIES': {
      return updateObject(state, {
        seasonalCategories: action.payload,
      });
    }
    case 'GET_SEASONAL_DATA': {
      return updateObject(state, {
        seasonalData: action.payload,
      });
    }
    case 'GET_SEASONAL_PRODUCTS': {
      return updateObject(state, {
        seasonalProducts: action.payload,
      });
    }
    case 'FETCHING_SEASONAL_PRODUCTS': {
      return updateObject(state, {
        fetchingSeasonalProducts: action.payload,
      });
    }
    case 'SET_SELECTED_SEASONAL_PRODUCTS': {
      return updateObject(state, {
        selectedSeasonalProducts: action.payload,
      });
    }
    case 'FETCHING_SEASONAL': {
      return updateObject(state, {
        fetchingSeasonal: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
