import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
// actions
import { setIsNoteForTeamOpen } from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getIsNoteForTeamModalOpen,
  getNoteForTeam,
} from 'store/orderQuickActions/dataTable/DataTableSelector';

const NoteForTeamModal = () => {
  const isNoteForTeamModalOpen = useSelector((state) => getIsNoteForTeamModalOpen({ state }));
  const noteForTeam = useSelector((state) => getNoteForTeam({ state }));
  const dispatch = useDispatch();

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isNoteForTeamModalOpen} toggle={() => dispatch(setIsNoteForTeamOpen())}>
        <ModalHeader toggle={() => dispatch(setIsNoteForTeamOpen())}>Note for team</ModalHeader>
        <ModalBody>
          <div className="form-group col-12">
            <textarea
              disabled={true}
              value={noteForTeam}
              className="form-control bigger-textarea"
              id="message-text"
            ></textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => dispatch(setIsNoteForTeamOpen())} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NoteForTeamModal;
