import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import CommonInfo from './CommonInfo';
import SectionLoader from 'components/common/SectionLoader';
import PageTitle from 'components/shared/PageTitle';
import { errorLogger } from 'datadog/DDUtils';
// service
import AttributesService from 'services/AttributesService';
// actions
import { getAttributeByID } from 'store/attributes/AttributesActions';
// selectors
import { getAttributeByIdData } from 'store/attributes/AttributesSelectors';

const EditAttribute = () => {
  const [type, setType] = useState('parent');
  const [disableBtn, setDisableBtn] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [dataObj, setDataObj] = useState({
    enName: '',
    arName: '',
    enDesc: '',
    arDesc: '',
    attKey: '',
    attType: '',
    attValue: '',
    attExtraVal: '',
    enImgFile: '',
    arImgFile: '',
    enAppImgFile: '',
    arAppImgFile: '',
    isActive: false,
    attributeId: -1,
    attributeName: '',
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const attributeData = useSelector((state) => getAttributeByIdData({ state }));

  const validateForm = () => {
    if (type === 'parent') {
      if (dataObj.enName === '' || dataObj.attType === '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    } else {
      if (dataObj.enName === '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    }
  };

  const handleEditAttribute = async () => {
    let data = {
      Name: dataObj.enName,
      NameAr: dataObj.arName,
      Key: dataObj.attKey,
      AltEn: dataObj.enDesc,
      AltAr: dataObj.arDesc,
      Type: dataObj.attType,
      IsActive: dataObj.isActive,
    };
    let formData;
    if (type === 'child') {
      data = {
        AttributeId: params?.id,
        Name: dataObj.enName,
        NameAr: dataObj.arName,
        Description: dataObj.enDesc,
        DescriptionAr: dataObj.arDesc,
        Value: dataObj.attValue,
        IsActive: dataObj.isActive,
        Extravalue: dataObj.attExtraVal,
        ImgEn: dataObj.enImgFile,
        ImgAr: dataObj.arImgFile,
        appImgEn: dataObj.enAppImgFile,
        AppImgAr: dataObj.arAppImgFile,
      };
      formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
    }
    setDisableBtn(true);
    try {
      let res = {};
      res = await AttributesService.editAttributeData(
        type === 'child' ? formData : data,
        type,
        params?.id
      );
      toast.success(res.data.message ?? 'Created attribute successfuly!');
      setDisableBtn(false);
    } catch (e) {
      errorLogger('EditAttribute:handleEditAttribute', e);
      setDisableBtn(false);
      toast.error('Failed to create new attribute');
    }
  };

  const updateDataObj = (name, value) => {
    setDataObj({ ...dataObj, [name]: value });
  };

  const getAttributeDataByID = async () => {
    if (location.pathname.indexOf('/sub') > -1) {
      setType('child');
      await dispatch(getAttributeByID('child', params?.id));
    } else {
      await dispatch(getAttributeByID('parent', params?.id));
    }
  };

  useEffect(() => {
    validateForm();
  }, [dataObj.enName, dataObj.attType]);

  useEffect(() => {
    setIsLoaderOpen(true);
    getAttributeDataByID();
    setIsLoaderOpen(false);
  }, []);

  useEffect(() => {
    if (location.pathname.indexOf('/sub') > -1) {
      setDataObj({
        enName: attributeData.name,
        arName: attributeData.nameAr,
        enDesc: attributeData.description,
        arDesc: attributeData.descriptionAr,
        attKey: '',
        attType: '',
        attValue: attributeData.value,
        attExtraVal: attributeData.extraValue,
        enImgFile: attributeData.imgEnFull,
        arImgFile: attributeData.imgArFull,
        enAppImgFile: attributeData.appImgEnFull,
        arAppImgFile: attributeData.appImgArFull,
        isActive: attributeData.isActive,
        attributeId: attributeData.attributeId,
        attributeName: attributeData.attributeName,
      });
    } else {
      setDataObj({
        enName: attributeData.name,
        arName: attributeData.nameAr,
        enDesc: attributeData.altEn,
        arDesc: attributeData.altAr,
        attKey: attributeData.key,
        attType: attributeData.type,
        attValue: '',
        attExtraVal: '',
        enImgFile: '',
        arImgFile: '',
        enAppImgFile: '',
        arAppImgFile: '',
        isActive: attributeData.isActive,
      });
    }
  }, [attributeData]);

  return (
    <>
      <PageTitle title={`${type === 'parent' ? 'Attribute' : 'Sub Attribute'} ${dataObj.enName}`} />
      <div className="card">
        <div className="card-body">
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <CommonInfo type={type} dataObj={dataObj} updateDataObj={updateDataObj} />
          )}
        </div>
        <div className="card-footer">
          <Button
            onClick={handleEditAttribute}
            color="primary"
            disabled={disableBtn}
            data-testid="CreateButtonTestId"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditAttribute;
