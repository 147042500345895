import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const WalletBalancesDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }

    if (
      args.column.field === 'debit' ||
      args.column.field === 'credit' ||
      args.column.field === 'balance'
    ) {
      args.value = (+args.value).toFixed(opsCenter?.country?.decimalNumber ?? 2);
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }

    if (
      args.column.field === 'debit' ||
      args.column.field === 'credit' ||
      args.column.field === 'balance'
    ) {
      args.value = (+args.value).toFixed(opsCenter?.country?.decimalNumber ?? 2);
    }
  };

  const userIdTemplate = (rowData) => (
    <a
      className="primary-color"
      href={`/admin/users/${rowData.userId}`}
      target="_blank"
      rel="noreferrer"
    >
      {rowData.userId}
    </a>
  );

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="#" field="serial" width={60} textAlign="Center" />
            <ColumnDirective
              headerText="Customer"
              field="touser"
              customExcelTemplate={(rowData) => rowData.touser}
              width={140}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="User ID"
              template={userIdTemplate}
              customExcelTemplate={(rowData) => rowData.userId}
              field="userID"
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="TransactionDate"
              field="transactionDateString"
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Debit"
              field="debit"
              template={(rowData) => (
                <span>
                  {rowData.debit != null &&
                    rowData.debit.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              customExcelTemplate={(rowData) => rowData.debit}
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Credit"
              field="credit"
              template={(rowData) => (
                <span>
                  {rowData.credit != null &&
                    rowData.credit.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              customExcelTemplate={(rowData) => rowData.credit}
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Balance"
              field="balance"
              template={(rowData) => (
                <span>
                  {rowData.balance != null &&
                    rowData.balance.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              customExcelTemplate={(rowData) => rowData.balance}
              width={80}
              textAlign="Center"
            />
            <ColumnDirective headerText="Type" field="type" width={100} textAlign="Center" />
            <ColumnDirective headerText="Remarks" field="remarks" width={200} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(WalletBalancesDataGrid);
