export const getTopicsListData = ({ state }) => state.ticketing.topicsList;

export const getOrderTicketsList = ({ state }) => state.ticketing.orderTicketsList;

export const getTicketDetailsByid = ({ state }) => state.ticketing.ticketDetailsById;

export const getTicketCommentsList = ({ state }) => state.ticketing.ticketCommentsList;

export const getTicketsPageList = ({ state }) => state.ticketing.ticketsList;
export const getTicketsCurrentPage = ({ state }) => state.ticketing.currentPage;
export const getTicketsPageSize = ({ state }) => state.ticketing.pageSize;
export const getTicketsPageRowCount = ({ state }) => state.ticketing.rowCount;

export const getIsTechTicketModalOpen = ({ state }) => state.ticketing.isTechTicketModalOpen;
