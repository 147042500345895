import React from 'react';
// components
import RestrictedSection from 'components/shared/RestrictedSection';
import MenuItem from './MenuItem';

// menu item (has no children) with permission
const PermissionedMenuItem = ({
  per,
  action,
  path,
  active,
  clicked,
  Icon,
  title,
  isCustomIcon,
  isChild,
  alertQtyItems,
  centerType,
}) => (
  <RestrictedSection page={per} action={action ? action : 'view'}>
    <MenuItem
      path={path}
      active={active}
      clicked={clicked}
      Icon={Icon}
      title={title}
      isCustomIcon={isCustomIcon}
      isChild={isChild}
      alertQtyItems={alertQtyItems}
      centerType={centerType}
    />
  </RestrictedSection>
);

export default PermissionedMenuItem;
