import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
// api
import RankingProductsService from '../../services/RankingProductsService';

export const rankProductsByType = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await RankingProductsService.rankByType(params);
    dispatch({
      type: 'RANK_PRODUCTS',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
    toast.success(res.data.message ?? 'Failed to rank products!!');
  } catch (err) {
    toast.error(err.response.data.message ?? 'Failed to rank products!!');
    dispatch(setLoaderOpenFalse());
  }
};
