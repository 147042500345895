import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import CustomSelect from 'components/shared/CustomSelect';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// constants
import { pickersType } from 'constant/Enums';
// Selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
import { getAllUserRoles } from 'store/user/UserSelectors';
// Actions
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
import { getAllRoles, addUserRole, deleteUserRole, changeUserStatus } from 'store/user/UserActions';
// Service
import ViewOrderService from 'services/ViewOrderService';

const UserInfoInputs = ({
  isModal,
  userId,
  inputValues,
  setInputValues,
  showCapacity,
  setShowCapacity,
}) => {
  const roleField = { text: 'name', value: 'id' };
  const dispatch = useDispatch();
  const allUserRoles = useSelector((state) => getAllUserRoles({ state }));
  const [countryList, setCountryList] = useState([]);
  const opsCenters = useSelector((state) => getOpsCenters({ state }));

  const handleChange = (e, name, isCheckbox) => {
    setInputValues({ ...inputValues, [name]: !isCheckbox ? e.target.value : e.target.checked });
  };

  const handleCountryChange = (e) => {
    setInputValues({ ...inputValues, countryId: e.itemData.Id });
  };

  const handleTypeChange = (e) => {
    setInputValues({ ...inputValues, contractType: +e.itemData.id });
  };

  const handleRoleAddition = (e) => {
    const pickerRoleId = allUserRoles?.filter((role) => role.name === 'Picker')[0]?.id;

    const role = {
      groupId: e.itemData.id,
      name: e.itemData.name,
    };
    setInputValues({
      ...inputValues,
      roles: [...inputValues.roles, role],
    });
    dispatch(addUserRole(userId, e.itemData.id));

    if (role.groupId === pickerRoleId) {
      setShowCapacity(true);
    }
  };

  const handleRoleDeletion = (e) => {
    const pickerRoleId = allUserRoles?.filter((role) => role.name === 'Picker')?.[0]?.id;
    const filteredRoles = inputValues?.roles?.filter((role) => role.groupId !== e.itemData.id);

    if (inputValues.roles.length > 1) {
      setInputValues({
        ...inputValues,
        roles: filteredRoles,
      });
      dispatch(deleteUserRole(userId, e.itemData.id));
      if (filteredRoles?.filter((role) => role.groupId === pickerRoleId).length === 0) {
        setShowCapacity(false);
      }
    } else {
      e.cancel = true;
      toast.error('Last role cannot be deleted. Please disable the account instead.');
    }
  };

  const handleStatusChange = (e) => {
    handleChange(e, 'isActive', true);
    dispatch(changeUserStatus(userId, { isActive: e.target.checked }));
  };

  const fetchCountryList = async () => {
    const countries = await ViewOrderService.getCountriesList();
    for (let i = 0; i < countries.data.length; i++) {
      countries.data[i].Code = '+' + countries.data[i].Code + ' ' + countries.data[i].Name;
    }
    setCountryList(countries.data);
  };

  const handleChangeOps = (e) => {
    setInputValues({ ...inputValues, opsId: e.value });
  };

  useEffect(() => {
    dispatch(setSignInSuccess());
    fetchCountryList();
    if (!isModal) {
      dispatch(getAllRoles());
    }
  }, []);

  return (
    <div className="card-body">
      <div className="row">
        <div className={`form-group col-12 ${isModal ? 'col-md-6' : 'col-md-4'}`}>
          <label className="bold-title" htmlFor="firstName">
            First Name
          </label>
          <input
            value={inputValues.firstName}
            onChange={(e) => handleChange(e, 'firstName')}
            className="form-control"
            type="text"
            id="firstName"
          />
        </div>
        <div className={`form-group col-12 ${isModal ? 'col-md-6' : 'col-md-4'}`}>
          <label className="bold-title" htmlFor="lastName">
            Last Name
          </label>
          <input
            value={inputValues.lastName}
            onChange={(e) => handleChange(e, 'lastName')}
            className="form-control"
            type="text"
            id="lastName"
          />
        </div>
        <div className={`form-group col-12 ${isModal ? 'col-md-6' : 'col-md-4'}`}>
          <label className="bold-title" htmlFor="email">
            Email
          </label>
          <input
            value={inputValues.email}
            onChange={(e) => handleChange(e, 'email')}
            className="form-control"
            type="email"
            id="email"
          />
        </div>
        <div className={`form-group col-12 filter-select ${isModal ? 'col-md-3' : 'col-md-2'}`}>
          <label className="bold-title" htmlFor="phone">
            Country
          </label>
          {inputValues?.countryId !== -1 && countryList.length && (
            <CustomSelect
              listData={countryList}
              handleChange={handleCountryChange}
              placeholder="Select an area"
              textPropName="Code"
              valuePropName="Id"
              selectedValue={inputValues?.countryId}
            />
          )}
        </div>
        <div className={`form-group col-12 ${isModal ? 'col-md-3' : 'col-md-2'}`}>
          <label className="bold-title" htmlFor="phone">
            Phone Number
          </label>
          <input
            value={inputValues.phoneNumber}
            onChange={(e) => handleChange(e, 'phoneNumber')}
            className="form-control"
            type="tel"
            id="phone"
          />
        </div>
        <div className={`form-group col-12  filter-select ${isModal ? 'col-md-6' : 'col-md-4'}`}>
          <label className="bold-title" htmlFor="type">
            Type
          </label>
          <CustomSelect
            id="type"
            listData={pickersType.filter((type) => type.id !== 0)}
            handleChange={handleTypeChange}
            placeholder="Not Selected"
            textPropName="name"
            valuePropName="id"
            selectedValue={inputValues.contractType}
          />
        </div>
        {showCapacity && (
          <div className={`form-group col-12  filter-select ${isModal ? 'col-md-6' : 'col-md-4'}`}>
            <label className="bold-title" htmlFor="capacity">
              Capacity
            </label>
            <input
              value={inputValues.capacity}
              onChange={(e) => handleChange(e, 'capacity')}
              className="form-control"
              type="number"
              id="capacity"
              min={1}
              max={1000}
            />
          </div>
        )}
        {!isModal && (
          <div className={`form-group col-12 ${isModal ? 'col-md-6' : 'col-md-4'}`}>
            <label htmlFor="roles" className="font-weight-bold">
              Roles
            </label>
            <AppErrorBoundary>
              <MultiSelectComponent
                delayUpdate={true}
                select={(e) => handleRoleAddition(e)}
                removing={(e) => handleRoleDeletion(e)}
                id="roles"
                name="roles"
                placeholder="Select roles"
                mode="Box"
                dataSource={allUserRoles}
                changeOnBlur={false}
                showDropDownIcon={true}
                value={inputValues.roles.map((role) => role.groupId)}
                fields={roleField}
              />
            </AppErrorBoundary>
          </div>
        )}
        <div className="form-group col-12 col-md-3 filter-select">
          <label className="bold-title" htmlFor="user-points">
            User Ops
          </label>
          <CustomSelect
            listData={opsCenters}
            handleChange={handleChangeOps}
            placeholder="Select an ops"
            textPropName="nickName"
            valuePropName="id"
            selectedValue={inputValues.opsId}
          />
        </div>
      </div>
      {!isModal && (
        <div className="row">
          <div className="form-group col-6 align-self-end pb-2">
            <input
              id="isActive"
              className="checkbox_animated"
              type="checkbox"
              checked={inputValues.isActive}
              onChange={(e) => {
                handleStatusChange(e);
              }}
            />
            <label htmlFor="isActive" className="font-weight-bold">
              Is Active
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInfoInputs;
