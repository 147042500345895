import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import OldProductsDataGrid from 'components/reports/products/oldProducts/OldProductsDataGrid';
import DJHPagination from 'components/shared/Pagination';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import ProductsReportService from 'services/reportsService/ProductsReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const OldProductsPage = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [rowCount, setRowCount] = useState('');
  const [inputValues, setInputValues] = useState({
    page: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await ProductsReportService.getOldProductsReport(params);
      setData(res.data.data);
      setCurrentPage(res.data.currentPage);
      setPageSize(res.data.pageSize);
      setRowCount(res.data.rowCount);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Old Products Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const onPageChange = (i) => {
    const updatedParams = { page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);

    navigate({
      pathname: '/report/oldProducts',
      search: queryString,
    });
    fetchData({ page: i });
    document.documentElement.scrollTop = 0;
  };

  return (
    <div>
      <div className="card m-t-30">
        <div className="card-header d-flex justify-content-between p-10">
          <span className="sz-17">Old Products</span>
        </div>
        <div className="card-body toolbar-table-style p-6">
          <OldProductsDataGrid data={data} />
        </div>
      </div>
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </div>
  );
};

export default OldProductsPage;
