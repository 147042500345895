import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// components
import DataTableComponent from 'components/shared/DataTableComponent';
import { userOrdersColumns } from './UsersCommons';
import SectionLoader from 'components/common/SectionLoader';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UserOrdersModal = ({ isUserTotalOrdersOpen, setIsUserTotalOrdersOpen, userId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [userOrdersList, setUserOrdersList] = useState('');

  const fetchData = async () => {
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getUserLastOrders(userId);
      setIsLoaderOpen(false);
      setUserOrdersList(res.data);
    } catch (err) {
      setIsLoaderOpen(false);
      errorLogger('UserOrdersModal:fetchUserOrders', err);
      toast.error('Failed to get user orders!');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isUserTotalOrdersOpen}
        toggle={() => setIsUserTotalOrdersOpen((prev) => !prev)}
        className="user-orders-modal"
      >
        <ModalBody>
          <div>
            {isLoaderOpen ? (
              <SectionLoader height="400px" />
            ) : (
              <div className="table-responsive shopping-table text-center">
                {userOrdersList && (
                  <DataTableComponent
                    data={userOrdersList}
                    columns={userOrdersColumns}
                    header="User Orders List"
                  />
                )}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 align-center">
            <Button onClick={() => setIsUserTotalOrdersOpen((prev) => !prev)} color="light">
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserOrdersModal;
