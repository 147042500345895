import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/shared/PageTitle';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const AdminPage = () => {
  const [pages, setPages] = useState([]);
  const [clonedPages, setClonedPages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await PermissionService.getPermissionPages();
      dispatch(setLoaderOpenFalse());
      setPages(res.data);
      setClonedPages(res.data);
    } catch (err) {
      dispatch(setLoaderOpenFalse());
      errorLogger('AdminPage:fetchPremissionspages', err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchPage = (e) => {
    if (e.target.value) {
      const searchResult = clonedPages.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setPages(searchResult);
    } else {
      setPages(clonedPages);
    }
  };

  return (
    <>
      <PageTitle title="Admin" />
      <div className="card">
        <div className="card-header between-flex">
          <div>
            <h6>Pages</h6>
            <button onClick={() => navigate('/admin/pages/add')} className="btn btn-success">
              <i className="fa fa-plus"></i> Add New Page
            </button>
          </div>
          <div className="form-group group-action-save">
            <input
              className="form-control"
              type="text"
              placeholder="Search Page"
              onChange={handleSearchPage}
            />
          </div>
        </div>

        <div className="recent-notification card-body">
          <div className="table-responsive text-center">
            <table className="table table-bordernone">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Key</th>
                  <th></th>
                </tr>
              </thead>
              {pages.length > 0 && (
                <tbody>
                  {pages.map((page, i) => (
                    <tr key={i}>
                      <td>{page.name}</td>
                      <td>{page.key}</td>
                      <td>
                        <button
                          onClick={() => {
                            dispatch({
                              type: 'SET_PERMISSION_PAGE_DATA',
                              payload: page,
                            });
                            navigate(`/admin/pages/${page.id}`);
                          }}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
