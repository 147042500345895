export const getUserPageDataById = ({ state }) => state.user.userDataById;
export const getUserWalletBalanceById = ({ state }) => state.user.userWalletBalanceById;
export const getUserGroupsById = ({ state }) => state.user.userGroupsListById;
export const getUserRecentTransactionsById = ({ state }) => state.user.userRecentTransactionsById;
export const getUserPointsById = ({ state }) => state.user.userPointsById;
export const getUserPointsHistoryById = ({ state }) => state.user.userPointsHistoryById;
export const getUserActivities = ({ state }) => state.user.userActivitiesById;
export const getGroupUsersList = ({ state }) => state.user.groupUsersList;
export const getUserInfoDetails = ({ state }) => state.user.userDetails;
export const getAllUserRoles = ({ state }) => state.user.userRoles;
export const getUsersList = ({ state }) => state.user.pickersList;
