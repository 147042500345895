import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
// components
import SectionLoader from 'components/common/SectionLoader';
const DuplicatedAndReplacedDataTable = React.lazy(() =>
  import('components/duplicatedAndReplacedOrders/DuplicatedAndReplacedDataTable')
);

const DuplicatedAndReplacedOrdersPage = () => {
  const [inputValues, setInputValues] = useState({
    orderId: 0,
    orderChangetype: -1,
    page: 1,
  });
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'GET_DUPLICATED_REPLACED_ORDERS_DATA',
        payload: {},
      });
    };
  }, []);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <DuplicatedAndReplacedDataTable inputValues={inputValues} setInputValues={setInputValues} />
      </React.Suspense>
    </div>
  );
};

export default DuplicatedAndReplacedOrdersPage;
