import React, { useState, useEffect } from 'react';
import DateRangePickerComponent from 'components/shared/DateRangePickerComponent';
import DatePickerComponent from 'components/shared/DatePickerComponent';
import cloneDeep from 'lodash/cloneDeep';
import { RefreshCw } from 'react-feather';
import CustomSelect from 'components/shared/CustomSelect';
// constants
import {
  dateFormat,
  isoFormat,
  isoStartFormat,
  isoEndFormat,
  convertToUnix,
} from 'constant/Helpers';
import { inventoryTypes } from 'constant/Enums';

const PriceBreakDownFilter = ({
  inputValues,
  setInputValues,
  handleSubmit,
  productType,
  defaultDate,
  isoDate,
  country,
  countriesList,
  withoutOps,
  title,
  designers,
  company,
  orderStatus,
  status,
  inventoryStatus,
  brands,
  statementType,
  dateType,
  sortType,
  InventoryItem,
  SalesInventoryItem,
  designersList,
  supplierType,
  suppliersList,
  hasExport,
  handleExport,
  voucherCategory,
  voucherCode,
  voucherCatList,
  kpi,
  handleKpiDateRangeChange,
  logisticsSearch,
  customer,
  salesOfficer,
  customerList,
  salesOfficerList,
  catalogPage,
  noDate,
  invoicesSearch,
  timeDate,
  noTitle,
  UserActivity,
  singleDate,
}) => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [singleDateVal, setSingleDateVal] = useState(new Date());
  const [sortVal, setSortVal] = useState(1);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSingleDatePicker, setShowSingleDatePicker] = useState(false);

  const handleChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[e.target.name] = e.target.value;
    setInputValues(updatedParams);
  };

  const handleSingleDateRange = (item) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.fromDate = isoEndFormat(item);
    setInputValues(updatedParams);
    setSingleDateVal(item);
    setShowSingleDatePicker(false);
  };

  const handleDateRange = (item) => {
    if (kpi) {
      if (item.selection.startDate === item.selection.endDate) {
        setDateRange(item.selection);
      } else {
        handleKpiDateRangeChange({
          fromDate: dateFormat(item.selection.startDate),
          toDate: dateFormat(item.selection.endDate),
        });
        setShowDatePicker(false);
        setDateRange(item.selection);
      }
    } else {
      const updatedParams = cloneDeep(inputValues);
      if (defaultDate) {
        updatedParams.fromDate = dateFormat(item.selection.startDate);
        updatedParams.toDate = dateFormat(item.selection.endDate);
      } else {
        if (isoDate) {
          updatedParams.fromDate = isoFormat(item.selection.startDate);
          updatedParams.toDate = isoFormat(item.selection.endDate);
        } else {
          if (timeDate) {
            updatedParams.fromDate = isoStartFormat(item.selection.startDate);
            updatedParams.toDate = isoEndFormat(item.selection.endDate);
          } else {
            updatedParams.fromDate = convertToUnix(item.selection.startDate);
            updatedParams.toDate = convertToUnix(item.selection.endDate);
          }
        }
      }
      setInputValues(updatedParams);
      setDateRange(item.selection);
    }
  };

  const handleDateTypeChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.datetype = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleVoucherCategory = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.catid = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleCountryChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.countryId = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleDesignerChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    if (designers) {
      updatedParams.designer = e.itemData.id;
    } else {
      updatedParams.brandId = e.itemData.id;
    }
    setInputValues(updatedParams);
  };

  const handleSuppliers = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.supplierid = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleStatusChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.status = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleOrderStatusChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.type = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleStatementsTypeChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.type = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleStatementsDateTypeChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.isdeliverydate = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleSortTypeChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.sort = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleItemChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.item = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleCustomerChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.userId = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleSalesOfficerChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.salesofficer = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleCatalogPageChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.catalogPage = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleCountryList = (withoutOps) => {
    if (withoutOps) {
      return [{ id: 0, name: 'All' }].concat(countriesList);
    } else {
      return [
        { id: 0, name: 'All' },
        { id: -1, name: 'Current Ops' },
      ].concat(countriesList);
    }
  };

  useEffect(() => {
    if (defaultDate || isoDate || timeDate) {
      setDateRange({
        startDate: new Date(inputValues.fromDate),
        endDate: new Date(inputValues.toDate),
        key: 'selection',
      });
    } else {
      setDateRange({
        startDate: new Date(inputValues.fromDate * 1000),
        endDate: new Date(inputValues.toDate * 1000),
        key: 'selection',
      });
    }
    if (sortType) {
      setSortVal(inputValues.sort);
    }
    if (inputValues.fromDate === '' && inputValues.fromDate === '') {
      setDateRange({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      });
    }
    if (singleDate) {
      setSingleDateVal(new Date(inputValues.fromDate));
    }
  }, [inputValues]);

  return (
    <div className={!kpi ? 'card' : ''}>
      {!kpi && !noTitle && (
        <div className="card-header p-18">
          <h5 className="sz-14">{title || 'FILTER'}</h5>
        </div>
      )}

      <form className="form theme-form">
        <div className={!kpi ? 'card-body' : ''}>
          <div className="row" style={{ justifyContent: kpi ? 'flex-end' : '' }}>
            {!noDate && (
              <div className="col-12 col-md-4">
                <div className="form-group col-flex">
                  <label
                    htmlFor="priceFilter-range"
                    style={{ fontWeight: 'bold', textAlign: kpi ? 'right' : '' }}
                  >
                    Date Range
                  </label>
                  <div className="normal-flex">
                    <input
                      id="priceFilter-range"
                      className="form-control digits white-bg pointer"
                      value={`${dateFormat(dateRange.startDate)} - ${dateFormat(
                        dateRange.endDate
                      )}`}
                      onClick={() => setShowDatePicker((prev) => !prev)}
                      readOnly
                    />
                    <RefreshCw
                      style={{
                        position: 'absolute',
                        width: 20,
                        cursor: 'pointer',
                        right: 22,
                      }}
                      onClick={() => window.location.reload()}
                    />
                  </div>

                  {showDatePicker && (
                    <DateRangePickerComponent
                      setShowDatePicker={setShowDatePicker}
                      handleDateRange={handleDateRange}
                      dateRange={dateRange}
                      kpi={kpi}
                    />
                  )}
                </div>
              </div>
            )}
            {singleDate && (
              <div className="col-4">
                <div className="form-group filter-select">
                  <label className="font-weight-bold" htmlFor="seasonal-date">
                    Up to Date
                  </label>
                  <div className="seasonal-filter-date">
                    <input
                      id="seasonal-date"
                      className="form-control digits"
                      value={`${dateFormat(singleDateVal)}`}
                      onClick={() => setShowSingleDatePicker((prev) => !prev)}
                      readOnly
                    />
                  </div>
                  {showSingleDatePicker && (
                    <DatePickerComponent
                      setShowDatePicker={setShowSingleDatePicker}
                      handledate={handleSingleDateRange}
                      date={singleDateVal}
                    />
                  )}
                </div>
              </div>
            )}
            {productType && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-type">
                    Date Type
                  </label>
                  <CustomSelect
                    id="priceFilter-type"
                    listData={[
                      { id: 0, name: 'Result Date' },
                      { id: 1, name: 'Delivery Date' },
                    ]}
                    handleChange={handleDateTypeChange}
                    placeholder="Select a date type"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={0}
                  />
                </div>
              </div>
            )}
            {supplierType && (
              <div className="form-group col-md-4 filter-select">
                <label className="bold-title" htmlFor="priceFilter-supplier">
                  Select Supplier
                </label>
                <CustomSelect
                  id="priceFilter-supplier"
                  listData={[{ id: -1, companyName: 'All' }].concat(suppliersList)}
                  handleChange={handleSuppliers}
                  placeholder="Select Supplier"
                  textPropName="companyName"
                  valuePropName="id"
                />
              </div>
            )}
            {voucherCategory && (
              <div className="form-group col-md-4 filter-select">
                <label className="bold-title" htmlFor="priceFilter-voucherCat">
                  Voucher Category
                </label>
                <CustomSelect
                  id="priceFilter-voucherCat"
                  listData={[{ id: -1, name: 'All' }].concat(voucherCatList)}
                  handleChange={handleVoucherCategory}
                  placeholder="Select voucher category"
                  textPropName="name"
                  valuePropName="id"
                />
              </div>
            )}
            {voucherCode && (
              <div className="form-group col-md-4">
                <div className="form-group">
                  <label className="bold-title" htmlFor="priceFilter-voucherCode">
                    Voucher Code
                  </label>
                  <input
                    id="priceFilter-voucherCode"
                    className="form-control"
                    type="text"
                    placeholder="Voucher Code"
                    value={inputValues.voucherCode}
                    onChange={handleChange}
                    name="voucherCode"
                  />
                </div>
              </div>
            )}
            {invoicesSearch && (
              <div className="form-group col-md-4">
                <div className="form-group">
                  <label className="font-weight-bold" htmlFor="priceFilter-orderId">
                    Search by Order ID
                  </label>
                  <input
                    id="priceFilter-orderId"
                    className="form-control"
                    type="text"
                    placeholder="Order ID"
                    value={inputValues.orderId === '0' ? '' : inputValues.orderId}
                    onChange={handleChange}
                    name="orderId"
                  />
                </div>
              </div>
            )}
            {logisticsSearch && (
              <div className="form-group col-md-4">
                <div className="form-group">
                  <label className="bold-title" htmlFor="priceFilter-driver">
                    Search Driver
                  </label>
                  <input
                    id="priceFilter-driver"
                    className="form-control"
                    type="text"
                    placeholder="search driver name or phone"
                    value={inputValues.searchKey}
                    onChange={handleChange}
                    name="searchKey"
                  />
                </div>
              </div>
            )}
            {country && countriesList.length > 0 && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-country">
                    Country
                  </label>
                  <CustomSelect
                    id="priceFilter-country"
                    listData={handleCountryList(withoutOps)}
                    handleChange={handleCountryChange}
                    placeholder="Select a country"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={inputValues.countryId}
                  />
                </div>
              </div>
            )}
            {(designers || brands) && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title">
                    {designers ? 'Select Designer' : 'Select Brand'}{' '}
                  </label>
                  <CustomSelect
                    listData={[{ id: 0, name: 'All' }].concat(designersList)}
                    handleChange={handleDesignerChange}
                    placeholder={designers ? 'Select Designer' : 'Select Brand'}
                    textPropName="name"
                    valuePropName="id"
                  />
                </div>
              </div>
            )}
            {company && (
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="bold-title" htmlFor="priceFilter-cName">
                    Company Name
                  </label>
                  <input
                    id="priceFilter-cName"
                    className="form-control white-bg pointer"
                    value={inputValues.companyName}
                    name={'companyName'}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            {(status || orderStatus) && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title">{orderStatus ? 'Order Status' : 'Status'}</label>
                  <CustomSelect
                    listData={[
                      { id: -1, name: 'All' },
                      { id: 2, name: orderStatus ? 'Confirmed' : 'Delivered' },
                      { id: 3, name: 'Canceled' },
                    ]}
                    handleChange={orderStatus ? handleOrderStatusChange : handleStatusChange}
                    placeholder="Select a status"
                    textPropName="name"
                    valuePropName="id"
                  />
                </div>
              </div>
            )}
            {inventoryStatus && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-status">
                    Status
                  </label>
                  <CustomSelect
                    id="priceFilter-status"
                    listData={[
                      { id: '0', name: 'Not Confirmed' },
                      { id: '1', name: 'Delivered' },
                      { id: '2', name: 'Confirmed' },
                      { id: '3', name: 'Canceled' },
                      { id: '4', name: 'Under Delivery' },
                      { id: '5', name: 'Refound' },
                      { id: '6', name: 'Pending Cancellation' },
                    ]}
                    handleChange={handleStatusChange}
                    placeholder="Select a status"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={inputValues.status}
                  />
                </div>
              </div>
            )}
            {statementType && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-statement">
                    Statement Type
                  </label>
                  <CustomSelect
                    id="priceFilter-statement"
                    listData={[
                      { id: -1, name: 'All' },
                      { id: 0, name: 'In' },
                      { id: 1, name: 'Out' },
                      { id: 2, name: 'Damaged' },
                    ]}
                    handleChange={handleStatementsTypeChange}
                    placeholder="Select a statement type"
                    textPropName="name"
                    valuePropName="id"
                  />
                </div>
              </div>
            )}
            {dateType && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-date">
                    Date Type
                  </label>
                  <CustomSelect
                    id="priceFilter-date"
                    listData={[
                      { id: 0, name: 'Result Date' },
                      { id: 1, name: 'Delivery Date' },
                    ]}
                    handleChange={handleStatementsDateTypeChange}
                    placeholder="Select a date type"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={0}
                  />
                </div>
              </div>
            )}
            {sortType && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-sort">
                    Sort Type
                  </label>
                  <CustomSelect
                    id="priceFilter-sort"
                    listData={[
                      { id: 1, name: 'Total Views' },
                      { id: 2, name: 'Total Revenue' },
                      { id: 3, name: 'Total Sold' },
                    ]}
                    handleChange={handleSortTypeChange}
                    placeholder="Select a sort type"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={sortVal}
                  />
                </div>
              </div>
            )}
            {InventoryItem && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-inventory">
                    Inventory Item
                  </label>
                  <CustomSelect
                    id="priceFilter-inventory"
                    listData={inventoryTypes}
                    handleChange={handleItemChange}
                    placeholder="Select an inventory item"
                    textPropName="name"
                    valuePropName="id"
                  />
                </div>
              </div>
            )}
            {SalesInventoryItem && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-salesInv">
                    Inventory Item
                  </label>
                  <input
                    id="priceFilter-salesInv"
                    className="form-control"
                    type="number"
                    readOnly
                    placeholder="Inventory Item ID"
                    value={inputValues.inventoryItem}
                    name="inventoryItem"
                  />
                </div>
              </div>
            )}
            {customer && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-customer">
                    Customer
                  </label>
                  <CustomSelect
                    id="priceFilter-customer"
                    listData={[{ id: -1, name: 'All' }].concat(customerList)}
                    handleChange={handleCustomerChange}
                    placeholder="Select a customer"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={0}
                  />
                </div>
              </div>
            )}
            {salesOfficer && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-officer">
                    Sales Officer
                  </label>
                  <CustomSelect
                    id="priceFilter-officer"
                    listData={[{ id: -1, name: 'All' }].concat(salesOfficerList)}
                    handleChange={handleSalesOfficerChange}
                    placeholder="Select a sales officer"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={0}
                  />
                </div>
              </div>
            )}
            {catalogPage && (
              <div className="col-12 col-md-4">
                <div className="form-group filter-select">
                  <label className="bold-title" htmlFor="priceFilter-catalog">
                    Catalog Page
                  </label>
                  <CustomSelect
                    id="priceFilter-catalog"
                    listData={[
                      { id: 0, name: 'Active In Stock' },
                      { id: 1, name: 'Active Out Stock' },
                      { id: 2, name: 'Inactive In Stock' },
                      { id: 3, name: 'Inactive Out Stock' },
                      { id: 4, name: 'Draft' },
                      { id: 5, name: 'Archived' },
                      { id: 6, name: 'All Master Product' },
                    ]}
                    handleChange={handleCatalogPageChange}
                    placeholder="Select a catalog page"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={inputValues.catalogPage}
                  />
                </div>
              </div>
            )}
            {UserActivity && (
              <div className="form-group col-md-4">
                <div className="form-group">
                  <label className="bold-title" htmlFor="priceFilter-user">
                    Search User
                  </label>
                  <input
                    id="priceFilter-user"
                    className="form-control"
                    type="text"
                    placeholder="search user by first name, last name, or email"
                    value={inputValues.searchKey}
                    onChange={handleChange}
                    name="searchKey"
                  />
                </div>
              </div>
            )}
          </div>
          {!kpi && (
            <div className="card-footer d-flex">
              <button onClick={handleSubmit} className="btn btn-primary mr-3" type="button">
                Search
              </button>
              {hasExport && (
                <button onClick={handleExport} className="btn btn-secondary mr-1" type="button">
                  <i className="fa fa-external-link mr-2" aria-hidden="true"></i>
                  Export
                </button>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default PriceBreakDownFilter;
