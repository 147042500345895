import { updateObject } from '../utility';

const initialState = {
  inventoryData: [],
  pageSize: '',
  currentPage: '',
  rowCount: '',
  suppliersList: [],
  inventoryStatements: [],
  inventoryParams: {
    type: '-1',
    sreackkey: '',
    searchKey: '',
    qty: -1,
    minimumQty: -1,
    status: '0',
    page: '1',
    inventorySorting: '0',
    serial: 0,
    opsId: 0,
  },
  inventorySuppliersData: [],
  iventoryCareDetailsById: {},
  inventoryItemDetailsById: {},
  alertQtyInventoryItems: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_INVENTORY_DATA': {
      return updateObject(state, {
        inventoryData: action.payload.data,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        rowCount: action.payload.rowCount,
      });
    }
    case 'INVENTORY_PARAMS': {
      return updateObject(state, {
        inventoryParams: action.payload,
      });
    }

    case 'GET_INVENTORY_SUPPLIERS_LIST': {
      return updateObject(state, {
        suppliersList: action.payload,
      });
    }

    case 'GET_INVENTORY_STATEMENT_LIST': {
      return updateObject(state, {
        inventoryStatements: action.payload,
      });
    }

    case 'GET_INVENTORY_SUPPLIERS_DATA': {
      return updateObject(state, {
        inventorySuppliersData: action.payload,
      });
    }

    case 'GET_INVENTORY_CARE_DETAILS_BY_ID': {
      return updateObject(state, {
        iventoryCareDetailsById: action.payload,
      });
    }

    case 'GET_INVENTORY_ITEM_DETAILS_BY_ID': {
      return updateObject(state, {
        inventoryItemDetailsById: action.payload,
      });
    }

    case 'GET_ALERT_QTY_INVENTORY_ITEMS': {
      return updateObject(state, {
        alertQtyInventoryItems: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
