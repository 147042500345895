import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
} from '@syncfusion/ej2-react-grids';
// Helpers
import { dateFormatDDMM, HasPermission } from 'constant/Helpers';
// Services
import InvoicesService from 'services/InvoicesService';
// datadog
import { errorLogger } from 'datadog/DDUtils';
import { toast } from 'react-toastify';
// actions
import { getGeneratedConsolidatedInvoices } from 'store/invoices/InvoicesActions';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';

const GeneratedConsolidatedDataGrid = ({ data, userId, countryId }) => {
  const ref = useRef();
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const hasPermission = HasPermission(permissionsList, 'consolidated_invoices', 'lock_invoice');
  const dispatch = useDispatch();
  const queryObj = { userid: userId || -1, countryid: countryId };

  const invoiceDateTemplate = (rowData) => {
    return <span>{dateFormatDDMM(rowData.invoiceDate)}</span>;
  };

  const generateBtnTemplate = (rowData) => (
    <button className="btn btn-success" onClick={() => handleGenerateInvoice(rowData.invoiceId)}>
      <i className="fa fa-file"></i> Generate
    </button>
  );
  const lockBtnTemplate = (rowData) => {
    return (
      <button className="btn btn-success" onClick={() => handleLockInvoice(rowData)}>
        {rowData?.isLocked ? (
          <>
            <i className="fa fa-unlock"></i> unlock
          </>
        ) : (
          <>
            <i className="fa fa-lock"></i> lock
          </>
        )}
      </button>
    );
  };
  const handleLockInvoice = async (rowData) => {
    try {
      await InvoicesService.lockConsolidatedInvoice(rowData?.invoiceId, !rowData?.isLocked);
      await dispatch(getGeneratedConsolidatedInvoices(queryObj));
      toast.success(`Invoice is ${!rowData?.isLocked ? 'locked' : 'unlocked'} successfuly`);
    } catch (err) {
      toast.error('Failed to update invoice!');
      errorLogger('GeneratedConsolidatedDataGrid:handleLockInvoice', err);
    }
  };

  const deleteInvoice = (rowData) => {
    return (
      <button
        className="btn btn-info"
        disabled={rowData?.isLocked}
        onClick={() => handleDeleteInvoice(rowData?.invoiceId)}
      >
        Delete
      </button>
    );
  };
  const handleDeleteInvoice = async (invoiceId) => {
    try {
      await InvoicesService.deleteConsolidatedInvoice(invoiceId);
      await dispatch(getGeneratedConsolidatedInvoices(queryObj));
      toast.success(`Invoice is deleted successfuly`);
    } catch (err) {
      toast.error('Failed to delete invoice!');
      errorLogger('GeneratedConsolidatedDataGrid:handleLockInvoice', err);
    }
  };
  const handleGenerateInvoice = async (invoiceId) => {
    const body = {
      invoiceOrder: null,
      CountryId: countryId,
      Link: '',
      userId: userId || -1,
      ConsolidatedId: invoiceId,
    };
    const params = { generated: true };
    const res = await InvoicesService.generateConsolidatedInvoice(body, params);
    window.open(res?.data?.invoices?.link);
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowSorting={true}
          ref={ref}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="invoiceId"
              headerText="Invoice ID"
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="serialNumber"
              headerText="Serial No."
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="createdUser"
              headerText="Created By"
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="customerName"
              headerText="Customer"
              width={110}
              textAlign="Center"
            />
            <ColumnDirective field="email" headerText="Email" width={110} textAlign="Center" />
            <ColumnDirective
              field="invoiceDate"
              headerText="delivery"
              template={invoiceDateTemplate}
              width={110}
              textAlign="Center"
            />
            {countryId === 2 ? (
              <ColumnDirective
                headerText="Delete"
                template={deleteInvoice}
                width={110}
                textAlign="Center"
              />
            ) : null}
            {countryId === 2 && hasPermission ? (
              <ColumnDirective
                field="isLock"
                headerText="Lock"
                template={lockBtnTemplate}
                width={110}
                textAlign="Center"
              />
            ) : null}

            <ColumnDirective
              headerText=""
              template={generateBtnTemplate}
              width={110}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(GeneratedConsolidatedDataGrid);
