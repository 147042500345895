import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomSelect from 'components/shared/CustomSelect';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// constants
import { convertQueryParamsIntoObject, handleImageFile } from 'constant/Helpers';
import { categoriesColorsList, categoriesTypesList } from 'constant/Enums';
// selectors
import { getParentCategoryData } from 'store/categories/CategoriesSelectors';
// service
import CategoriesService from 'services/CategoriesService';
// actions
import { getCategoriesData } from 'store/categories/CategoriesActions';

const CreateCategoryModal = ({
  isAddCategoryModalOpen,
  setIsAddCategoryModalOpen,
  type,
  inputValues,
}) => {
  const parentCategoryData = useSelector((state) => getParentCategoryData({ state }));
  const [disableBtn, setDisableBtn] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [enName, setEnName] = useState('');
  const [arName, setArName] = useState('');
  const [enHeadDesc, setEnHeadDesc] = useState('');
  const [arHeadDesc, setArHeadDesc] = useState('');
  const [nickName, setNickName] = useState('');
  const [slug, setSlug] = useState('');
  const [subscriptionDays, setSubscriptionDays] = useState(0);
  const [frequency, setFrequency] = useState(0);
  const [color, setColor] = useState({ colorName: '', colorHex: '' });
  const [txtColor, setTxtColor] = useState({ colorName: '', colorHex: '' });
  const [enImgFile, setEnImgFile] = useState('');
  const [arImgFile, setArImgFile] = useState('');
  const [enBannerFile, setEnBannerFile] = useState('');
  const [arBannerFile, setArBannerFile] = useState('');
  const [enAppImgFile, setEnAppImgFile] = useState('');
  const [arAppImgFile, setArAppImgFile] = useState('');
  const [enMobBannerFile, setEnMobBannerFile] = useState('');
  const [arMobBannerFile, setArMobBannerFile] = useState('');
  const [enCoverImgFile, setEnCoverImgFile] = useState('');
  const [arCoverImgFile, setArCoverImgFile] = useState('');
  const [descImgFile, setDescImgFile] = useState('');
  const [overlayImageFile, setOverlayImageFile] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [catType, setCatType] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();

  const handleTypeChange = (e) => {
    setCatType(e.itemData.id);
  };
  const handleColorChange = (e) => {
    setColor(e.itemData.colorHex);
  };
  const handleTxtColorChange = (e) => {
    setTxtColor(e.itemData.colorHex);
  };
  const validateForm = () => {
    if (type === 'category') {
      if (enName === '' || nickName === '' || catType === '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    } else {
      if (enName === '' || nickName === '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    }
  };

  const handleCreateCategory = async () => {
    const data = {
      Nickname: nickName,
      Name: enName,
      NameArabic: arName,
      HeadDescriptionEn: enHeadDesc,
      HeadDescriptionAr: arHeadDesc,
      BackgroundColor: color,
      Description: '',
      DescriptionAr: '',
      OrderId: 1,
      Color: color,
      Img: enImgFile,
      ImgAR: arImgFile,
      AppImageEn: enAppImgFile,
      AppImageAr: arAppImgFile,
      CatBannerEn: enBannerFile,
      CatBannerAr: arBannerFile,
      BannerMobileEn: enMobBannerFile,
      BannerMobileAr: arMobBannerFile,
      CoverImageEn: enCoverImgFile,
      CoverImageAr: arCoverImgFile,
      DescriptionImage: descImgFile,
      OverlayImage: overlayImageFile,
      Status: isActive ? 1 : 0,
      ParentID: type === 'category' ? 0 : parentCategoryData.id,
      IsActive: isActive,
      Key: slug,
      Type: type === 'category' ? catType : parentCategoryData.type,
      SubsciptionDayS: subscriptionDays,
      Frequency: frequency,
      TextColor: txtColor,
    };
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    setDisableBtn(true);
    try {
      let res = {};
      res = await CategoriesService.createNewCategory(formData);
      dispatch(getCategoriesData(inputValues));
      setIsLoaderOpen(true);
      setIsAddCategoryModalOpen((prev) => !prev);
      toast.success(res.data.message ?? 'Created category successfuly!');
    } catch (e) {
      setDisableBtn(false);
      setIsLoaderOpen(false);
      setIsAddCategoryModalOpen((prev) => !prev);
      toast.error('Failed to create new category');
    }
  };

  useEffect(() => {
    validateForm();
  }, [nickName, enName, catType]);

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      if (queryObj?.type) setCatType(parseInt(queryObj?.type, 10));
    }
  }, []);

  const colorTemplate = (rowData) => (
    <>
      <div
        style={{ backgroundColor: rowData.colorHex, width: rowData.colorHex ? '' : 0 }}
        className="colorTemplateDisplay"
      ></div>
      <span className="colorName">{rowData.colorName}</span>
    </>
  );

  const selectedColorValueTemplate = (data) => (
    <div className="selectedColorValueTemplate">
      <div
        style={{
          backgroundColor: data.colorHex,
          width: data.colorHex ? '' : 0,
        }}
        className="selectedColorDisplay"
      ></div>
      <div>{data.colorName}</div>
    </div>
  );

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddCategoryModalOpen}
        toggle={() => setIsAddCategoryModalOpen((prev) => !prev)}
        className="m-w-900"
      >
        <ModalHeader>Create {type === 'category' ? 'Category' : 'Sub Category'}</ModalHeader>
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <ModalBody>
            <div className="form-group col-12 card">
              <form className="card-body row">
                {type === 'subCategory' && (
                  <div className="form-group col-12">
                    <label className="font-weight-bold" htmlFor="main-category">
                      Main Category
                    </label>
                    <input
                      id="main-category"
                      value={parentCategoryData.name}
                      disabled
                      className="form-control"
                      type="text"
                    />
                  </div>
                )}
                <div className="form-group col-6">
                  <label className="font-weight-bold" htmlFor="category-enName">
                    Display name English
                  </label>
                  <input
                    id="category-enName"
                    value={enName}
                    onChange={(e) => setEnName(e.target.value)}
                    className="form-control"
                    type="text"
                    data-testid="EnglishNameInputTestId"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="font-weight-bold" htmlFor="category-arName">
                    Display name Arabic
                  </label>
                  <input
                    id="category-arName"
                    value={arName}
                    onChange={(e) => setArName(e.target.value)}
                    className="form-control"
                    type="text"
                    data-testid="ArabicNameInputTestId"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="font-weight-bold" htmlFor="category-enDesc">
                    Head Description English
                  </label>
                  <input
                    id="category-enDesc"
                    value={enHeadDesc}
                    onChange={(e) => setEnHeadDesc(e.target.value)}
                    className="form-control"
                    type="text"
                    data-testid="EnglishHeadDescInputTestId"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="font-weight-bold" htmlFor="category-arDesc">
                    Head Description Arabic
                  </label>
                  <input
                    id="category-arDesc"
                    value={arHeadDesc}
                    onChange={(e) => setArHeadDesc(e.target.value)}
                    className="form-control"
                    type="text"
                    data-testid="ArabicHeadDescInputTestId"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="font-weight-bold" htmlFor="category-nickname">
                    Nickname
                  </label>
                  <input
                    id="category-nickname"
                    value={nickName}
                    onChange={(e) => setNickName(e.target.value)}
                    className="form-control"
                    type="text"
                    data-testid="NicknameInputTestId"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="font-weight-bold" htmlFor="category-slug">
                    Category Slug
                  </label>
                  <input
                    id="category-slug"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    className="form-control"
                    type="text"
                    data-testid="CategorySlugInputTestId"
                  />
                </div>
                {type === 'subCategory' && (
                  <div className="form-group col-6">
                    <label className="font-weight-bold" htmlFor="category-days">
                      Subscriptions days
                    </label>
                    <input
                      id="category-days"
                      value={subscriptionDays}
                      onChange={(e) => setSubscriptionDays(e.target.value)}
                      className="form-control"
                      type="number"
                      data-testid="SubscriptionDaysInputTestId"
                    />
                  </div>
                )}
                {type === 'subCategory' && (
                  <div className="form-group col-6">
                    <label className="font-weight-bold" htmlFor="category-freq">
                      Frequency (number of weeks)
                    </label>
                    <input
                      id="category-freq"
                      value={frequency}
                      onChange={(e) => setFrequency(e.target.value)}
                      className="form-control"
                      type="number"
                      data-testid="FrequencyInputTestId"
                    />
                  </div>
                )}
                {type === 'category' && (
                  <div className="form-group col-4 filter-select category-select">
                    <label className="font-weight-bold" htmlFor="category-type">
                      Type
                    </label>
                    <CustomSelect
                      id="category-type"
                      listData={categoriesTypesList}
                      handleChange={handleTypeChange}
                      placeholder="Select a category type"
                      textPropName="name"
                      valuePropName="id"
                      selectedValue={catType}
                    />
                  </div>
                )}
                <div className="form-group col-4 filter-select category-select">
                  <label className="font-weight-bold" htmlFor="category-bg">
                    Background Color
                  </label>
                  <AppErrorBoundary>
                    <CustomSelect
                      id="category-bg"
                      listData={categoriesColorsList}
                      handleChange={handleColorChange}
                      allowFiltering={false}
                      placeholder="Select a color"
                      textPropName="colorName"
                      valuePropName="colorHex"
                      selectedValue={color}
                      template={colorTemplate}
                      valueTemplate={selectedColorValueTemplate}
                      delayUpdate="true"
                    />
                  </AppErrorBoundary>
                </div>
                <div className="form-group col-4 filter-select category-select ">
                  <label className="font-weight-bold" htmlFor="category-txt">
                    Text Color
                  </label>
                  <AppErrorBoundary>
                    <CustomSelect
                      id="category-txt"
                      listData={categoriesColorsList}
                      handleChange={handleTxtColorChange}
                      placeholder="Select a color"
                      textPropName="colorName"
                      valuePropName="colorHex"
                      selectedValue={txtColor}
                      valueTemplate={selectedColorValueTemplate}
                      allowFiltering={false}
                      delayUpdate="true"
                      template={colorTemplate}
                    />
                  </AppErrorBoundary>
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="enImgFile">
                    Image EN
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="enImgFile"
                      onChange={(e) => setEnImgFile(e.target.files[0])}
                      data-testid="ImageEnInputTestId"
                    />
                    <label htmlFor="enImgFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {enImgFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(enImgFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="arImgFile">
                    Image AR
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="arImgFile"
                      onChange={(e) => setArImgFile(e.target.files[0])}
                      data-testid="ImageArInputTestId"
                    />
                    <label htmlFor="arImgFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {arImgFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(arImgFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="enBannerFile">
                    Banner EN
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="enBannerFile"
                      onChange={(e) => setEnBannerFile(e.target.files[0])}
                      data-testid="BannerImageEnInputTestId"
                    />
                    <label htmlFor="enBannerFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {enBannerFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(enBannerFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="arBannerFile">
                    Banner AR
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="arBannerFile"
                      onChange={(e) => setArBannerFile(e.target.files[0])}
                      data-testid="BannerImageArInputTestId"
                    />
                    <label htmlFor="arBannerFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {arBannerFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(arBannerFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="enAppFile">
                    App Image EN
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="enAppFile"
                      onChange={(e) => setEnAppImgFile(e.target.files[0])}
                      data-testid="AppImageEnInputTestId"
                    />
                    <label htmlFor="enAppFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {enAppImgFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(enAppImgFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="arAppFile">
                    App Image AR
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="arAppFile"
                      onChange={(e) => setArAppImgFile(e.target.files[0])}
                      data-testid="AppImageArInputTestId"
                    />
                    <label htmlFor="arAppFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {arAppImgFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(arAppImgFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="enMobBannerFile">
                    Banner Mobile EN
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="enMobBannerFile"
                      onChange={(e) => setEnMobBannerFile(e.target.files[0])}
                      data-testid="BannerMobileEnInputTestId"
                    />
                    <label htmlFor="enMobBannerFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {enMobBannerFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(enMobBannerFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="arMobBannerFile">
                    Banner Mobile AR
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="arMobBannerFile"
                      onChange={(e) => setArMobBannerFile(e.target.files[0])}
                      data-testid="BannerMobileArInputTestId"
                    />
                    <label htmlFor="arMobBannerFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {arMobBannerFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(arMobBannerFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="enCoverFile">
                    Cover Image EN
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="enCoverFile"
                      onChange={(e) => setEnCoverImgFile(e.target.files[0])}
                      data-testid="CoverImageEnInputTestId"
                    />
                    <label htmlFor="enCoverFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {enCoverImgFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(enCoverImgFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="arCoverFile">
                    Cover Image AR
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="arCoverFile"
                      onChange={(e) => setArCoverImgFile(e.target.files[0])}
                      data-testid="CoverImageArInputTestId"
                    />
                    <label htmlFor="arCoverFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {arCoverImgFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(arCoverImgFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="descFile">
                    Description Image
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="descFile"
                      onChange={(e) => setDescImgFile(e.target.files[0])}
                      data-testid="DescriptionImageInputTestId"
                    />
                    <label htmlFor="descFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {descImgFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(descImgFile)} />
                  )}
                </div>
                <div className="form-group col-12 col-md-4">
                  <label className="font-weight-bold" htmlFor="overlayImageFile">
                    Overlay Image
                  </label>
                  <div className="file-input">
                    <input
                      type="file"
                      className="file"
                      id="overlayImageFile"
                      onChange={(e) => setOverlayImageFile(e.target.files[0])}
                      data-testid="OverlayImageInputTestId"
                    />
                    <label htmlFor="overlayImageFile">
                      <p className="file-name">Select file</p>
                    </label>
                  </div>
                  {overlayImageFile !== '' && (
                    <img className="file-img" alt={'img'} src={handleImageFile(overlayImageFile)} />
                  )}
                </div>
                <div className="form-group col-12 ">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={isActive}
                    onChange={(e) => setIsActive((prev) => !prev)}
                    data-testid="IsActiveCheckboxInputTestId"
                  />
                  <span className="font-weight-bold">Active</span>
                </div>
              </form>
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            onClick={handleCreateCategory}
            color="primary"
            disabled={disableBtn}
            data-testid="CreateButtonTestId"
          >
            Create {type === 'category' ? 'category' : 'sub category'}
          </Button>
          <Button color="light" onClick={() => setIsAddCategoryModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateCategoryModal;
