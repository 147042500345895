import React, { useRef, useState } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
// components
import has from 'lodash/has';
import toString from 'lodash/toString';

const AreasDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const toolbarClick = (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        ref.current.excelExport();
        break;
      default:
        break;
    }
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <Link to={`/delivery/areas/${rowData.Id}/edit`} className="total-txt link area-link">
          {parseInt(rowData.Id, 10)}
        </Link>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <Link to={`/delivery/areas/${rowData.Id}/edit`} className="total-txt link area-link">
        {rowData.Name}
      </Link>
    );
  };

  const nameArTemplate = (rowData) => {
    return (
      <Link to={`/delivery/areas/${rowData.Id}/edit`} className="total-txt link area-link">
        {rowData.NameAr}
      </Link>
    );
  };

  const subareaTemplate = (rowData) => {
    return (
      <Link to={`/delivery/areas/${rowData.Id}/sub`} className="cat-icon-cell">
        <i className="fa fa-align-justify"></i>
      </Link>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${
          rowData.IsActive ? 'btn-light-success' : 'btn-light-danger'
        }`}
      >
        {rowData.IsActive ? 'Active' : 'Inactive'}
      </button>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          allowTextWrap={true}
          showColumnChooser={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="Id"
              headerText="ID"
              template={idTemplate}
              width={60}
              customExcelTemplate={(rowData) => rowData.Id}
            />
            <ColumnDirective
              field="Name"
              headerText="Name EN"
              template={nameTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.Name}
            />
            <ColumnDirective
              field="NameAr"
              headerText="Name AR"
              template={nameArTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.NameAr}
            />
            <ColumnDirective headerText="Sub Areas" template={subareaTemplate} width={100} />
            <ColumnDirective
              field="IsActive"
              headerText="Status"
              template={statusTemplate}
              width={100}
              customExcelTemplate={(rowData) => rowData.IsActive}
              customAttributes={{ class: 'area-status' }}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(AreasDataGrid);
