import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import DesignersReportDataGrid from 'components/reports/designers/DesignersReportDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { setDesignerslistData } from 'store/reports/ReportsActions';
// selectors
import { getDesignersListData } from 'store/reports/ReportsSelectors';
// api
import DesignersReportService from 'services/reportsService/DesignersReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DesignersReportPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    designer: 0,
  });
  const designersList = useSelector((state) => getDesignersListData({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await DesignersReportService.getDesignersReport(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Designers Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
    dispatch(setDesignerslistData());
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: '/report/DesignersReport',
      search: queryString,
    });
    fetchData(inputValues);
  };

  const handleExport = async () => {
    try {
      const res = await DesignersReportService.exportGetDesignersReport(inputValues);
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('Designers Report Page Export Data Error:', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Designers Report"
        designers
        designersList={designersList}
        hasExport
        handleExport={handleExport}
        defaultDate
      />
      <DesignersReportDataGrid data={data} />
    </div>
  );
};

export default DesignersReportPage;
