import React, { useState } from 'react';
import { dateFormat } from 'constant/Helpers';
// components
import SectionLoader from 'components/common/SectionLoader';
const SeasonalPrepDataTable = React.lazy(() =>
  import('components/seasonalPrep/SeasonalPrepDataTable')
);

const SeasonalPrepPage = (props) => {
  const [inputValues, setInputValues] = useState({
    sku: '',
    seasonalDate: dateFormat(new Date()),
    page: 1,
    sort: 0,
  });

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <SeasonalPrepDataTable inputValues={inputValues} setInputValues={setInputValues} />
      </React.Suspense>
    </div>
  );
};

export default SeasonalPrepPage;
