import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class FloristCommunityService {
  static getFloristCommunityList(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFloristCommunityDesigns, ...extraParams),
      params,
    });
  }

  static getFloristCommunityDesignByid(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFloristCommunityDesignById, ...extraParams),
    });
  }

  static getFloristCommunityUserByid(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFloristCommunityUser, ...extraParams),
    });
  }

  static approveFloristDesign(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.approveFloristDesign, ...extraParams),
      data,
    });
  }

  static rejectFloristDesign(...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.rejectFloristDesign, ...extraParams),
    });
  }

  static getFloristCommunityProduct(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFloristCommunityProduct, ...extraParams),
      params,
    });
  }

  static getFloristCommunityTransactionList(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFloristCommunityTransactionList, ...extraParams),
      params,
    });
  }

  static getTransactionById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFloristCommunityTransactionById, ...extraParams),
    });
  }

  static assignTransaction(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.assignFloristCommunityTransaction, ...extraParams),
      data,
    });
  }

  static changePaymentStatus(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.changeFloristCommunityTransactionStatus, ...extraParams),
      data,
    });
  }

  static getTransactionComments(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.floristCommunityTransactionComment, ...extraParams),
    });
  }

  static postTransactionComment(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.floristCommunityTransactionComment, ...extraParams),
      data,
    });
  }
}
export default FloristCommunityService;
