import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ApplyCopounModal = ({ isApplyCopounModalOpen, setIsApplyCopounModalOpen, orderId }) => {
  const [code, setCode] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleAddCupoun = async () => {
    const dataBody = {
      code,
    };
    setIsLoaderOpen(true);
    setDisableBtn(true);
    try {
      await ViewOrderService.applyCopoun(dataBody, orderId);
      setIsLoaderOpen(false);
      setDisableBtn(false);
      setIsApplyCopounModalOpen((prev) => !prev);
      toast.success('Copoun applied');
    } catch (err) {
      errorLogger('ApplyCopounModal:handleAddCupoun', err);
      setIsApplyCopounModalOpen((prev) => !prev);
      toast.error(err.response.data.message);
    }
  };
  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isApplyCopounModalOpen}
        toggle={() => setIsApplyCopounModalOpen((prev) => !prev)}
      >
        <ModalBody>
          {isLoaderOpen ? (
            <SectionLoader height="150px" />
          ) : (
            <form>
              <div className="form-group col-12">
                <label className="col-form-label" htmlFor="message-text">
                  Coupon Code
                </label>
                <input
                  onChange={(e) => setCode(e.target.value)}
                  className="form-control"
                  value={code}
                  id="message-text"
                ></input>
              </div>
            </form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleAddCupoun} color="primary" disabled={disableBtn || !code}>
            Save
          </Button>
          <Button onClick={() => setIsApplyCopounModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ApplyCopounModal;
