import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
import CustomSelect from 'components/shared/CustomSelect';
// actions
import { getInventoryData } from 'store/inventory/InventoryActions';
// api
import InventoryService from 'services/InventoryService';

const EditItemQtyModal = ({
  isEditItemQtyModalOpen,
  setIsEditItemQtyModalOpen,
  defaultParams,
  clickedRow,
}) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [qty, setQty] = useState(1);
  const [isDamaged, setIsDamaged] = useState(0);
  const dispatch = useDispatch();

  const handleAddQty = async () => {
    setDisableBtn(true);

    const data = {
      barcode: clickedRow.barCode,
      qty,
      id: clickedRow.inventoryId,
      isDamaged,
    };
    try {
      await InventoryService.addInventoryItemQty(data);

      setTimeout(() => {
        dispatch(getInventoryData(defaultParams));
        setDisableBtn(false);
        setIsEditItemQtyModalOpen((prev) => !prev);
        toast.success('Updated');
      }, 2000);
    } catch (err) {
      errorLogger('EditItemQtyModal:handleAddQty', err);
      setDisableBtn(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleIsDamagedChange = (e) => {
    setIsDamaged(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isEditItemQtyModalOpen}
        toggle={() => setIsEditItemQtyModalOpen((prev) => !prev)}
        className="m-w-600"
      >
        <ModalBody>
          <Fragment>
            <div className="row">
              <div className="col-12 modal-header add-qty-title">
                <h6 className="primary-color">Edit inventory Qty</h6>
              </div>
              <div className="form-group col-12 col-sm-6 filter-select">
                <label className="col-form-label" htmlFor="recipient-name">
                  Add / Remove
                </label>
                <CustomSelect
                  id="recipient-name"
                  listData={[
                    { id: 0, name: 'Add' },
                    { id: 1, name: 'Remove' },
                  ]}
                  handleChange={handleIsDamagedChange}
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={isDamaged}
                />
              </div>
              <div className="form-group col-12 col-sm-6">
                <label className="col-form-label" htmlFor="recipient-name">
                  Qty
                </label>
                <input
                  onChange={(e) => setQty(e.target.value)}
                  value={qty}
                  className="form-control"
                  type="number"
                />
              </div>
            </div>
          </Fragment>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleAddQty} color="primary" disabled={disableBtn}>
            Save
          </Button>
          <Button color="light" onClick={() => setIsEditItemQtyModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditItemQtyModal;
