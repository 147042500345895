import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import DJHPagination from 'components/shared/Pagination';
import InventoryReportDataGrid from 'components/reports/inventory/InventoryReportDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  dateFormat,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import InventoryReportService from 'services/reportsService/InventoryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const InventoryReportPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    item: -1,
    page: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await InventoryReportService.getInventoryReport(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Inventory Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  const exportFile = async () => {
    try {
      const res = await InventoryReportService.exportInventoryReport(inputValues);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'InventoryReport.xlsx');
      document.body.appendChild(link);
      link.click();
      toast.success(res?.data?.message || 'Downloaded');
    } catch (err) {
      errorLogger('Inventory Report Page Export Data Error:', err);
      toast.error('Failed to export report data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async (e, params, i) => {
    const queryParams = params ?? inputValues;
    if (!i) {
      queryParams.page = 1;
    }
    const queryString = convertObjectIntoQueryParams(queryParams);
    navigate({
      pathname: '/report/InventoryReport',
      search: queryString,
    });
    fetchData(queryParams);
  };

  const onPageChange = async (i) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.page = i;
    setInputValues(updatedParams);
    handleSubmit(null, updatedParams, i);
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Inventory Report"
        InventoryItem
        defaultDate
        hasExport
        handleExport={exportFile}
      />
      <InventoryReportDataGrid data={data.data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+data?.currentPage}
        pageSize={+data?.pageSize}
        itemsCount={+data?.rowCount}
      />
    </div>
  );
};

export default InventoryReportPage;
