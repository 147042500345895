import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { dateFormatDDMM } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const InventorySuppliersDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="#" field="serial" width={60} textAlign="Center" />
            <ColumnDirective
              headerText="Date"
              template={(rowData) => rowData.date && dateFormatDDMM(rowData.date)}
              customExcelTemplate={(rowData) => rowData.date && dateFormatDDMM(rowData.date)}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Image"
              template={(rowData) => (
                <img alt={rowData.supplier} className="w-60p" src={rowData.img} />
              )}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Supplier Name"
              field="supplier"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Name" field="name" width={120} textAlign="Center" />
            <ColumnDirective headerText="Barcode" field="barcode" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Cost"
              template={(rowData) => (
                <span>
                  {rowData.price != null &&
                    rowData.price.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.price != null &&
                rowData.price.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Quantity Sold"
              field="qty"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Type" field="type" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Total"
              template={(rowData) => (
                <span>
                  {rowData.total != null &&
                    rowData.total.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.total != null &&
                rowData.total.toFixed(opsCenter?.country?.decimalNumber ?? 3)
              }
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(InventorySuppliersDataGrid);
