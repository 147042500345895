import { apiGatewayService, apiDriverService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';
class DeliveryService {
  // drivers
  static getDriversList(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.driversList, ...extraParams),
      params: data,
    });
  }

  static addDriverData(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addDriverData, ...extraParams),
      data,
    });
  }

  static updateDriverData(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateDriver, ...extraParams),
      data,
    });
  }

  static exportDriversData(data) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportDriversData),
      params: data,
      responseType: 'blob',
    });
  }

  static getDriversVehiclesTypes(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getDriversVehiclesTypes, ...extraParams),
      params: data,
    });
  }

  // slots
  static getDeliverySlots(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllDeliverySlots, ...extraParams),
      params: data,
    });
  }

  static addNewDeliverySlot(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addNewDeliverySlot, ...extraParams),
      data,
    });
  }

  static updateDeliverySlot(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateDeliveySlot, ...extraParams),
      data,
    });
  }

  // trip
  static getTripDriversList(params, ...extraParams) {
    return apiDriverService({
      method: 'GET',
      url: setEndPoint(endPoints.getTripDriversList, ...extraParams),
      params,
    });
  }

  static getTripDriverOrdersList(...extraParams) {
    return apiDriverService({
      method: 'GET',
      url: setEndPoint(endPoints.getTripDriverOrdersList, ...extraParams),
    });
  }

  static sortOrdersList(data, ...extraParams) {
    return apiDriverService({
      method: 'PUT',
      url: setEndPoint(endPoints.sortDriverOrdersList, ...extraParams),
      data,
    });
  }

  // areas
  static getAreas(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAreas, ...extraParams),
      params,
    });
  }

  static addArea(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getAreas, ...extraParams),
      data,
    });
  }

  static getAreaById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAreaById, ...extraParams),
    });
  }

  static updateAreaById(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getAreaById, ...extraParams),
      data,
    });
  }

  static getSubAreas(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getSubAreas, ...extraParams),
      params,
    });
  }

  static getSubAreaById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getSubAreaById, ...extraParams),
    });
  }

  static addSubArea(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getSubAreas, ...extraParams),
      data,
    });
  }

  static updateSubAreaById(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getSubAreaById, ...extraParams),
      data,
    });
  }

  // postcode

  static getAllPostCodes(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getPostCodes, ...extraParams),
      params,
    });
  }

  static addPostCode(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getPostCodes, ...extraParams),
      data,
    });
  }

  static deletPostCode(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.getPostCodesById, ...extraParams),
    });
  }

  static getPostCodeById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getPostCodesById, ...extraParams),
    });
  }

  static updatePostCodeById(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getPostCodesById, ...extraParams),
      data,
    });
  }

  // drivers expenses
  static getDriversExpensesData(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getDriverExpensesData, ...extraParams),
      params,
    });
  }

  static updateDriversExpensesStatus(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateDriverExpensesStatus, ...extraParams),
    });
  }

  static AddNewDriversExpenses(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addNewDriverExpenses, ...extraParams),
      data,
    });
  }

  static exportDriversExpensesData(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportDriverExpensesData, ...extraParams),
      params,
    });
  }

  // Driver radius
  static getDriverRadius() {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.driverRadius),
    });
  }
  static setDriverRadius(params) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.driverRadius),
      data: params,
    });
  }

  //coverage list
  static getCoverageAreasList(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.coverageAreasList, ...extraParams),
      params,
    });
  }

  static deleteCoverageArea(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deleteCoverageArea, ...extraParams),
    });
  }

  static getCoveredAreasListData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCoveredAreasList, ...extraParams),
    });
  }

  static setCoveredAreas(params) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.setCoveredAreas),
      data: { areaIds: params },
    });
  }
}
export default DeliveryService;
