import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { dateFormatDDMM } from 'constant/Helpers';
import { toast } from 'react-toastify';
// api
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ChangeOpsModal = ({ orderId, isChangeOpsModalOpen, setIsChangeOpsModalOpen }) => {
  const [opsList, setOpsList] = useState([]);
  const [selectedOps, setSelectedOps] = useState('');
  const [disableDate, setDisableDate] = useState(true);
  const [selectedDate, setSelectedDate] = useState('');
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);

  const getOpsList = async (orderId) => {
    try {
      const res = await ViewOrderService.getOpsList(orderId);
      setOpsList(res.data);
    } catch (err) {
      errorLogger('ChangeOpsModal:getOpsList', err);
    }
  };

  useEffect(() => {
    getOpsList(orderId);
  }, []);

  const handleChangeOps = async (e) => {
    setSelectedOps(e.itemData.id);
    setDisableDate(false);
  };

  const handleChangeDate = async (date) => {
    const params = {
      date: dateFormatDDMM(date),
      proId: -1,
      fulfillmenttype: 0,
      opsid: selectedOps,
    };
    try {
      const res = await ViewOrderService.getDeliveryTimeSlot(params);
      setTimeSlotsData(res.data.avilableSlots);
    } catch (e) {
      errorLogger('ChangeOpsModal:handleChangeDate', e);
      // console.log(e);
    }
    setSelectedDate(date);
  };

  const handleSubmit = async () => {
    setDisableBtn(true);
    const params = {
      opsid: selectedOps,
      deliverydate: dateFormatDDMM(selectedDate),
      deliverytime: selectedTimeSlot,
    };
    try {
      await ViewOrderService.getChangeOps(params, orderId);
      toast.success('Ops changed successfully!');
      setDisableBtn(false);
      setIsChangeOpsModalOpen((prev) => !prev);
      setDisableBtn(false);
    } catch (err) {
      errorLogger('ChangeOpsModal:handleSubmit', err);
      setDisableBtn(false);
      setIsChangeOpsModalOpen((prev) => !prev);
      setDisableBtn(false);
      toast.error(err?.response?.data?.message ?? 'Failed to change Ops!');
    }
  };

  const handleTimeSlotChange = (e) => {
    setSelectedTimeSlot(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isChangeOpsModalOpen} toggle={() => setIsChangeOpsModalOpen((prev) => !prev)}>
        <ModalHeader>Change order Ops</ModalHeader>
        <ModalBody>
          <form>
            <div className="row">
              {opsList.length > 0 && (
                <div className="form-group col-12 filter-select">
                  <label className="col-form-label" htmlFor="message-text">
                    Select destination Ops
                  </label>
                  <CustomSelect
                    listData={opsList}
                    handleChange={handleChangeOps}
                    placeholder="Select an ops"
                    textPropName="nickName"
                    valuePropName="id"
                  />
                </div>
              )}
              <div className="form-group col-6">
                <DatePicker
                  className="form-control digits"
                  placeholderText="Selected a delivery date"
                  selected={selectedDate}
                  onChange={handleChangeDate}
                  disabled={disableDate}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="form-group col-6 filter-select">
                <CustomSelect
                  listData={timeSlotsData}
                  handleChange={handleTimeSlotChange}
                  placeholder="Select delivery time"
                  textPropName="name"
                  valuePropName="id"
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={selectedTimeSlot === '' || disableBtn}
            onClick={handleSubmit}
            color="primary"
          >
            Change
          </Button>
          <Button onClick={() => setIsChangeOpsModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ChangeOpsModal;
