import React, { useRef } from 'react';
import { dateFormatDDMM } from 'constant/Helpers';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
} from '@syncfusion/ej2-react-grids';

const InvoicesDataGrid = ({ data }) => {
  const ref = useRef();
  const invoiceTypeValue = {
    0: 'TaxInvoice',
    1: 'CreditNote',
    2: 'UserWallet',
  };

  const countryTemplate = (rowData) => (
    <span>
      {rowData.country}/{rowData.currency}
    </span>
  );

  const createdAtTemplate = (rowData) => <span>{dateFormatDDMM(rowData.createdAt)}</span>;

  const invoiceDateTemplate = (rowData) => <span>{dateFormatDDMM(rowData.invoiceDate)}</span>;

  const downInvoiceTemplate = (rowData) => (
    <button
      disabled={!rowData.filePath}
      className="btn btn-success"
      onClick={() => window.open(rowData.filePath, 'blank')}
    >
      <i className="fa fa-download"></i> Download
    </button>
  );

  const viewInvoiceTemplate = (rowData) => (
    <button
      disabled={!rowData.link || !rowData.orderId}
      className="btn btn-info"
      onClick={() => window.open(rowData.link, 'blank')}
    >
      View
    </button>
  );

  const invoiceStatementTemplate = (rowData) => (
    <span data-testid="invoiceStatementTestId">
      {invoiceTypeValue[rowData.invoiceStatmentType]}
    </span>
  );

  return (
    <div className="control-pane">
      <div className="control-section key-value-grid">
        <GridComponent
          dataSource={data}
          allowSorting={true}
          ref={ref}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective field="orderId" headerText="Order ID" width={110} textAlign="Center" />
            <ColumnDirective
              field="serialNumberDisplay"
              headerText="Serial Number Display"
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="country"
              headerText="Country"
              template={countryTemplate}
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="createdAt"
              headerText="Created at"
              template={createdAtTemplate}
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="invoiceDate"
              headerText="Invoice Date"
              template={invoiceDateTemplate}
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="invoiceStatmentType"
              headerText="Invoice Statement Type"
              template={invoiceStatementTemplate}
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="link"
              headerText="View"
              template={viewInvoiceTemplate}
              width={110}
              textAlign="Center"
            />
            <ColumnDirective
              field="link"
              headerText="Download"
              template={downInvoiceTemplate}
              width={110}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default InvoicesDataGrid;
