import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import RestrictedSection from 'components/shared/RestrictedSection';
import CustomSelect from 'components/shared/CustomSelect';
import { errorLogger } from 'datadog/DDUtils';
// constants
import { handleImageFile, HasPermission } from 'constant/Helpers';
import { inventoryTypes } from 'constant/Enums';
// actions
import { getMasterInventoryById } from 'store/masterInventory/MasterInventoryActions';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';
import { getSelectedMasterInventoryData } from 'store/masterInventory/MasterInventorySelectors';
// api
import MasterInventoryService from 'services/MasterInventoryService';

const MasterInventoryItemSection = ({ parentId }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const inventoryItemById = useSelector((state) => getSelectedMasterInventoryData({ state }));
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [name, setName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [inventoryType, setInventoryType] = useState('');
  const [itemImage, setItemImage] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(inventoryItemById).length > 0) {
      setName(inventoryItemById.name);
      setBarcode(inventoryItemById.barCode);
      setInventoryType(inventoryItemById.type);
      setItemImage(inventoryItemById.fullImage);
    }
  }, [inventoryItemById]);

  useEffect(() => {
    dispatch(getMasterInventoryById(parentId));
  }, []);

  const getFormData = () => {
    const data = {
      Name: name,
      BarCode: barcode,
      Type: inventoryType,
      Image: itemImage,
    };
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  };

  const handleAddNewItem = async () => {
    const formData = getFormData();
    setDisableBtn(true);
    setIsLoaderOpen(true);
    try {
      const res = await MasterInventoryService.updateMasterInventory(formData, parentId);
      toast.success(res?.data?.message ?? 'Updated Successfully');
      setIsLoaderOpen(false);
      setDisableBtn(false);
    } catch (e) {
      errorLogger('MasterInventoryItemSection:handleAddNewItem', e);
      setDisableBtn(false);
      setIsLoaderOpen(false);
      toast.error('Failed to update inventory care');
    }
  };

  return (
    <div className="form-group col-12 card">
      <div className="card-header p-12 sz-20">Edit Master Inventory Item</div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <Fragment>
          <div className="card-body row">
            <RestrictedSection page="inventory" action="edit_inventory">
              <div className="form-group col-6">
                <label className="bold-title" htmlFor="invItem-name">
                  Name
                </label>
                <input
                  id="invItem-name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="form-control"
                  type="text"
                  disabled={!HasPermission(permissionsList, 'master_inventory', 'edit_master_name')}
                />
              </div>
            </RestrictedSection>
            <RestrictedSection page="inventory" action="edit_inventory">
              <div className="form-group col-6">
                <label className="bold-title" htmlFor="invItem-barcode">
                  Barcode
                </label>
                <input
                  id="invItem-barcode"
                  onChange={(e) => setBarcode(e.target.value)}
                  value={barcode}
                  className="form-control"
                  type="text"
                  readOnly
                />
              </div>
            </RestrictedSection>
            <RestrictedSection page="inventory" action="edit_inventory">
              <div className="form-group col-6 filter-select">
                <label className="bold-title" htmlFor="invItem-type">
                  Inventory Type
                </label>
                <CustomSelect
                  id="invItem-type"
                  listData={inventoryTypes.slice(1)}
                  handleChange={(e) => setInventoryType(e.itemData.id)}
                  placeholder="Select Inventory type"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={inventoryType}
                />
              </div>
            </RestrictedSection>
            <RestrictedSection page="inventory" action="edit_inventory">
              <div className="form-group col-6">
                <label className="bold-title" htmlFor="itemImg">
                  Image
                </label>
                <div
                  className="file-img"
                  style={{
                    backgroundImage: `url(${handleImageFile(itemImage)})`,
                    height: itemImage === '' ? 0 : 150,
                  }}
                ></div>
                <div className="file-input">
                  <input
                    type="file"
                    className="file"
                    id="itemImg"
                    onChange={(e) => setItemImage(e.target.files[0])}
                  />
                  <label htmlFor="itemImg">
                    <p className="file-name">Select file</p>
                  </label>
                </div>
              </div>
            </RestrictedSection>
          </div>
        </Fragment>
      )}
      <div className="card-footer  d-flex justify-content-between">
        <button
          disabled={!name || !barcode || disableBtn}
          onClick={handleAddNewItem}
          className="btn btn-primary"
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default MasterInventoryItemSection;
