import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import PageTitle from 'components/shared/PageTitle';
import MasterInventoryActions from 'components/inventory/master/MasterInventoryActions';
import MasterInventoryDataGrid from 'components/masterInventory/MasterInventoryDataGrid';
import DJHPagination from 'components/shared/Pagination';
import MasterInventoryFilter from 'components/masterInventory/MasterInventoryFilter';
// constants
import { convertQueryParamsIntoObject } from 'constant/Helpers';
// actions
import { getMasterInventoryList } from 'store/masterInventory/MasterInventoryActions';
// selectors
import {
  getAllMasterInventoryData,
  getMasterInventoryPageSize,
  getMasterInventoryCurrentPage,
  getMasterInventoryRowCount,
} from 'store/masterInventory/MasterInventorySelectors';

const MasterInventoryPage = () => {
  const [inputValues, setInputValues] = useState({
    key: '',
    type: null,
    sortby: null,
    page: 1,
  });
  const masterInventoryData = useSelector((state) => getAllMasterInventoryData({ state }));
  const currentPage = useSelector((state) => getMasterInventoryCurrentPage({ state }));
  const pageSize = useSelector((state) => getMasterInventoryPageSize({ state }));
  const rowCount = useSelector((state) => getMasterInventoryRowCount({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchMasterInventory = async (params) => {
    dispatch(getMasterInventoryList(params));
  };
  const handleSubmit = async (params) => {
    const queryParams = params ?? inputValues;
    const queryString =
      '?' +
      Object.keys(queryParams)
        .map((key) => {
          const filteredEncodedstring =
            queryParams[key] && typeof queryParams[key] === 'string'
              ? queryParams[key].replace(/[\\]*[:]*["]*[?]*[<]*[>]*[|]*[\t]*/gi, '')
              : queryParams[key];
          if (queryParams[key]) {
            return `${key}=${filteredEncodedstring}`;
          } else {
            return null;
          }
        })
        .filter((q) => q !== null)
        .join('&');

    navigate({
      pathname: '/master-inventory',
      search: queryString,
    });
    fetchMasterInventory(queryParams);
  };

  const onPageChange = (i) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.page = i;
    setInputValues(updatedParams);
    handleSubmit(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchMasterInventory(queryObj);
    } else {
      fetchMasterInventory(inputValues);
    }
  }, []);

  return (
    <div className="no-toolbar-table-style">
      <PageTitle title="Master inventory" />
      <MasterInventoryActions defaultParams={inputValues} />
      <MasterInventoryFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
      />
      <MasterInventoryDataGrid data={masterInventoryData} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </div>
  );
};

export default MasterInventoryPage;
