import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class UserService {
  static getUsersList(params) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUsersList),
      params,
    });
  }

  static createUser(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.createUser),
      data,
    });
  }

  static editUser(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.editUser, ...extraParams),
      data,
    });
  }

  static getUserDetails(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserDetails, ...extraParams),
    });
  }

  static getUserRoles(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserRoles, ...extraParams),
    });
  }

  static getAllRoles() {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllRoles),
    });
  }

  static addUserRole(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateRole, ...extraParams),
    });
  }

  static deleteUserRole(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deleteRole, ...extraParams),
    });
  }

  static changeUserStatus(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.changeUserStatus, ...extraParams),
      data,
    });
  }

  static resetUserPassword(...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.resetUserPassword, ...extraParams),
    });
  }
}

export default UserService;
