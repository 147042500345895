import {
  apiHubService,
  apiLogService,
  apiOrderService,
  apiHomeService,
  apiGatewayService,
} from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class ViewOrderService {
  static getViewOrderDetails(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.orderDetails, ...extraParams),
    });
  }

  static getOrderInvoice(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.orderInvoice, ...extraParams),
    });
  }

  static getCartDetails(userId, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.cartDetails, ...extraParams),
      headers: { flwuser: userId },
    });
  }

  static getCardMessage(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.cardMessage, ...extraParams),
    });
  }

  static getEditCard(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.cardMessage, ...extraParams),
      data,
    });
  }

  static getAddCard(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.cardMessage, ...extraParams),
      data,
    });
  }

  static getDeleteCard(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.cardMessage, ...extraParams),
    });
  }

  static getCustomerCalls(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.customerCalls, ...extraParams),
    });
  }

  static addNewCustomerCall(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.customerCalls, ...extraParams),
      data,
    });
  }

  static getInventory(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.inventoryTrans, ...extraParams),
    });
  }

  static editInventory(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.editInventory, ...extraParams),
      data,
    });
  }

  static getActionsBox(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.actionsBox, ...extraParams),
    });
  }

  static getActionsBoxLogsData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getActionBoxLogs, ...extraParams),
    });
  }

  static getDriverData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.driver, ...extraParams),
    });
  }

  static getSearchDriver(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.searchDriver, ...extraParams),
    });
  }

  static validateOrderBeforeAssign(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.validateBeforeAssign, ...extraParams),
    });
  }

  static addOrderToDriver(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addDriver, ...extraParams),
      data,
    });
  }

  static updateDriverStatus(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.updateDriverStatus, ...extraParams),
      data,
    });
  }

  static getAdjustLog(...extraParams) {
    return apiLogService({
      method: 'GET',
      url: setEndPoint(endPoints.adjustLog, ...extraParams),
    });
  }

  // static getAdjustData(...extraParams) {
  // return apiHubService({
  // method: 'GET',
  // url: setEndPoint(endPoints.adjustData, ...extraParams),
  // });
  // }

  static getDeliveryTimeSlot(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.deliveryTimeSlot, ...extraParams),
      params: data,
    });
  }

  static getDeliveryPremiumTimeSlot(data, ...extraParams) {
    return apiOrderService({
      method: 'GET',
      url: setEndPoint(endPoints.deliveryPremiumTimeSlot, ...extraParams),
      params: data,
    });
  }

  static getTimeSlotReason(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.timeSlotReason, ...extraParams),
    });
  }

  static updateDeliveryDate(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.updateDate, ...extraParams),
      data,
    });
  }

  static markAsPaid(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.markPaid, ...extraParams),
      data,
    });
  }

  static orderType(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.orderType, ...extraParams),
      data,
    });
  }

  static updateCstep(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.updateCstep, ...extraParams),
      data,
    });
  }

  static generatePaymentLink(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.generatePaymentLink, ...extraParams),
    });
  }

  static requestGeneratedLink(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getLinkRequest, ...extraParams),
    });
  }

  static getComments(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getComments, ...extraParams),
    });
  }

  static addNewComment(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getComments, ...extraParams),
      data,
    });
  }

  static getAddress(data, ...extraParams) {
    return apiOrderService({
      method: 'GET',
      url: setEndPoint(endPoints.getAddress, ...extraParams),
      params: data,
    });
  }

  static getAreasList(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAreasList, ...extraParams),
      params: data,
    });
  }

  static getCountriesList(...extraParams) {
    return apiHomeService({
      method: 'GET',
      url: setEndPoint(endPoints.getCountriesList, ...extraParams),
    });
  }

  static addCredit(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addCredit, ...extraParams),
      data,
    });
  }

  static getOpsList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getOpsList, ...extraParams),
    });
  }

  static getCheckOps(data, ...extraParams) {
    return apiHubService({
      method: 'GET',
      url: setEndPoint(endPoints.checkOps, ...extraParams),
      params: data,
    });
  }

  static getChangeOps(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.changeOps, ...extraParams),
      params: data,
    });
  }

  static sendEmail(...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.sendEmail, ...extraParams),
    });
  }

  static updateAddress(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.updateAddress, ...extraParams),
      data,
    });
  }

  static addExtraShippingInfo(data, ...extraParams) {
    return apiOrderService({
      method: 'POST',
      url: setEndPoint(endPoints.addExtraShippingInfo, ...extraParams),
      data,
    });
  }

  static updateOrderStatus(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.updateOrderStatus, ...extraParams),
      data,
    });
  }

  static refundAction(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.refund, ...extraParams),
      data,
    });
  }

  static earnPoints(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.earnPoints, ...extraParams),
    });
  }

  static getDriverOrders(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getDriverOrders, ...extraParams),
    });
  }

  static getRecipientOrders(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getRecipientOrders, ...extraParams),
      params: data,
    });
  }

  static getUserOrders(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUsersOrders, ...extraParams),
    });
  }

  static getPrevAddressList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getPrevAddressList, ...extraParams),
    });
  }

  static updatePrevAddress(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updatePrevAddress, ...extraParams),
    });
  }

  static getRecipientTotalAddress(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getRecipientTotalAddress, ...extraParams),
      params: data,
    });
  }

  static getRecipientListAddress(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getRecipientListAddress, ...extraParams),
      params: data,
    });
  }

  static updateRecipientListAddress(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateRecipientListAddress, ...extraParams),
    });
  }

  static getUserTotalOrdersList(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserTotalOrdersList, ...extraParams),
      params: data,
    });
  }

  static getRecepientTodayOrders(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getRecepientTodayOrders, ...extraParams),
      params: data,
    });
  }

  static getAddInventorySearchList(params) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAddInventorySearchList),
      params,
    });
  }

  static addInventoryItem(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addOrderDetailsInventoryItem),
      data,
    });
  }

  static addHouseImage(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addHouseImage),
      data,
    });
  }

  static duplicateOrder(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.duplicateOrder, ...extraParams),
      data,
    });
  }

  static updateDeliveryCharge(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateDeliveryCharge, ...extraParams),
      data,
    });
  }

  static applyCopoun(data, ...extraParams) {
    return apiOrderService({
      method: 'POST',
      url: setEndPoint(endPoints.applyCopoun, ...extraParams),
      data,
    });
  }

  static getCustomerCommunicationCases(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCustomerCommunicationCases, ...extraParams),
    });
  }

  static sendCustomerCommunicationMessage(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.sendCustomerCommunicationMessage, ...extraParams),
      data,
    });
  }

  // premium collecting gift
  static getGiftByOrderId(...extraParams) {
    return apiOrderService({
      method: 'GET',
      url: setEndPoint(endPoints.getPremiumGiftByOrderId, ...extraParams),
    });
  }

  static addPremiumGift(data, ...extraParams) {
    return apiOrderService({
      method: 'POST',
      url: setEndPoint(endPoints.addPremiumGift, ...extraParams),
      data,
    });
  }

  static deletePremiumGift(...extraParams) {
    return apiOrderService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deletePremiumGift, ...extraParams),
    });
  }

  // checkout
  static getCheckoutPaymentDetails(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCheckoutPaymentDetails, ...extraParams),
    });
  }

  static getCheckoutLogsDetails(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCheckoutLogsDetails, ...extraParams),
    });
  }

  // favorite flowers
  static getFavoriteFlowersData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFavFlowersData, ...extraParams),
    });
  }

  // ziwo calls
  static getZiwoCallsLogs(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getZiwoCallsLogs, ...extraParams),
      data,
    });
  }

  static getZiwoCallRecording(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getZiwoCallRecording, ...extraParams),
      params: data,
      responseType: 'blob',
    });
  }

  // serach order
  static getSearchOrder(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.searchOrder, ...extraParams),
      params: data,
    });
  }

  // knet order history
  static getKnetOrderHistory(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.orderKnetHistory, ...extraParams),
    });
  }

  // sales officer
  static setSalesOfficer(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.setSalesOfficer, ...extraParams),
    });
  }

  // change cost
  static editOrderCost(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.editOrderCost, ...extraParams),
      data,
    });
  }

  // user audit
  static getUserAudit(...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getUserAudit, ...extraParams),
    });
  }

  // Order Invoices
  static getOrderInvoicesData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.orderInvoices, ...extraParams),
    });
  }

  // ship on fleet
  static getShipOnFleet(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getShipOnFleet, ...extraParams),
      params: data,
    });
  }

  // nw delivery
  static getSwapToNwDelivery(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getSwapToNwDelivery, ...extraParams),
      data,
    });
  }
}
export default ViewOrderService;
