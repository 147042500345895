import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
} from '@syncfusion/ej2-react-grids';
import SectionLoader from 'components/common/SectionLoader';
// Constants
import { dateFormatDDMM } from 'constant/Helpers';
import { vehiclesTypeEnum } from 'constant/Enums';

const DriversDataGrid = ({ data, handleClick, isLoaderOpen }) => {
  const ref = useRef();

  // custom columns
  const dateGridTemplate = (rowData) => {
    return <p>{rowData.lastSeen && dateFormatDDMM(rowData.lastSeen)}</p>;
  };

  const createdDateTemplate = (rowData) => {
    return <p>{rowData.createdDate && dateFormatDDMM(rowData.createdDate)}</p>;
  };

  const vehicleTypeTemplate = (rowData) => <span>{vehiclesTypeEnum[rowData.vehicleType]}</span>;

  return (
    <div className="control-pane">
      <div className="control-section">
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <GridComponent
            dataSource={data}
            allowExcelExport={true}
            allowPdfExport={true}
            allowGrouping={true}
            ref={ref}
            rowHeight={70}
            rowSelected={(row) => {
              handleClick(row.data);
            }}
            className="pointer"
          >
            <ColumnsDirective>
              <ColumnDirective headerText="Name" field="name" width={150} textAlign="Center" />
              <ColumnDirective headerText="Phone" field="phone" width={120} textAlign="Center" />
              <ColumnDirective headerText="Email" field="email" width={150} textAlign="Center" />
              <ColumnDirective
                headerText="User Type"
                field="typeString"
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Company"
                field="company"
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Vehicle Type"
                template={vehicleTypeTemplate}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Petrol QR Code"
                field="petrolQRCode"
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Last seen"
                template={dateGridTemplate}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Created Date"
                template={createdDateTemplate}
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Current Orders"
                field="currentOrders"
                width={120}
                textAlign="Center"
              />
            </ColumnsDirective>
            <Inject />
          </GridComponent>
        )}
      </div>
    </div>
  );
};

export default DriversDataGrid;
