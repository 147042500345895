import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// api
import KpiService from 'services/KpiService';

export const getSalesPerformanceByCountry = (params) => async (dispatch) => {
  try {
    const res = await KpiService.getSalesPerformanceByCountryData(params);
    dispatch({
      type: 'GET_SALES_PERFORMANCE_BY_COUNTRY_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('KpiActions:getSalesPerformanceByCountry', err);
    toast.error(
      err?.response?.data?.message ?? 'Failed to fetch sales performance by country data!!'
    );
  }
};

export const getSalesPerformance = (params) => async (dispatch) => {
  try {
    const res = await KpiService.getSalesPerformanceData(params);
    dispatch({
      type: 'GET_SALES_PERFORMANCE_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('KpiActions:getSalesPerformance', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch sales performance data!!');
  }
};

export const getSalesPerformanceByChannel = (params) => async (dispatch) => {
  try {
    const res = await KpiService.getSalesPerformanceByChannelData(params);
    dispatch({
      type: 'GET_SALES_PERFORMANCE_BY_CHANNEL_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('KpiActions:getSalesPerformanceByChannel', err);
    toast.error(
      err?.response?.data?.message ?? 'Failed to fetch sales performance by channels data!!'
    );
  }
};

export const getAllSalesPerformance = (params) => async (dispatch) => {
  const promisesArray = [];
  dispatch({
    type: 'SET_KPI_LOADER_OPEN_TRUE',
  });
  try {
    promisesArray.push(
      dispatch(getSalesPerformanceByCountry(params)),
      dispatch(getSalesPerformance(params)),
      dispatch(getSalesPerformanceByChannel(params))
    );
    await Promise.all(promisesArray);
    dispatch({
      type: 'SET_KPI_LOADER_OPEN_FALSE',
    });
  } catch (e) {
    errorLogger('KpiActions:getAllSalesPerformance', e);
    dispatch({
      type: 'SET_KPI_LOADER_OPEN_FALSE',
    });
  }
};
