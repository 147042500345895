import { updateObject } from '../utility';

const initialState = {
  customerList: [],
  salesOfficerList: [],
  corporateReport: [],
  customerCorporateReport: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CUSTOMER_DATA': {
      return updateObject(state, {
        customerList: action.payload,
      });
    }
    case 'GET_SALESOFFICER_DATA': {
      return updateObject(state, {
        salesOfficerList: action.payload,
      });
    }
    case 'GET_CORPORATE_REPORT': {
      return updateObject(state, {
        corporateReport: action.payload,
      });
    }
    case 'GET_CUSTOMER_CORPORATE_REPORT': {
      return updateObject(state, {
        customerCorporateReport: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
