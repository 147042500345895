import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class LateOrdersService {
  static getLateOrdersList(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.lateOrdersBalanceList, ...extraParams),
      params: data,
    });
  }

  static approveLateOrderBalance(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.approveLateOrderBalance, ...extraParams),
      data,
    });
  }
}

export default LateOrdersService;
