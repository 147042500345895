import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import { Modal, ModalHeader, ModalBody, ModalFooter, Media } from 'reactstrap';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// Actions
import { setViewOrderData, setInventoryData } from 'store/viewOrder/ViewOrderActions';
// Services
import ViewOrderService from 'services/ViewOrderService';

const OrderStatusModal = ({ modalStatus, setIsOrderStatusModalOpen, inventoryData, orderId }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  let dataSource = [];

  if (inventoryData) {
    dataSource = inventoryData.filter((item) => {
      if (item.type === 1) return item;
      else return null;
    });
  }

  const checkSelecting = (args) => {
    if (args.target) {
      if (args.target.localName.indexOf('img') > -1 || args.target.className.indexOf('link') > -1) {
        args.cancel = true;
      }
    }
  };

  const checkSelected = (args) => {
    if (
      args.target &&
      (args.target.localName.indexOf('img') > -1 || args.target.className.indexOf('link') > -1)
    ) {
      args.Cancel = true;
    }
    const selectedrecords = cloneDeep(ref.current?.getSelectedRecords());
    if (selectedrecords) {
      if (selectedrecords.length === inventoryData.length) {
        document.getElementsByClassName('selectAllcheckbox')[0].className =
          'selectAllcheckbox is-checked';
      } else {
        if (selectedrecords.length > 0) {
          document.getElementsByClassName('selectAllcheckbox')[0].className =
            'selectAllcheckbox some-check';
        } else {
          document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
        }
      }
    }
  };

  const checkAll = (e) => {
    const classNames = e.target.className;
    const isChecked = classNames.includes('is-checked');
    if (!isChecked) {
      ref.current.selectRowsByRange(0, inventoryData.length - 1);
      document.getElementsByClassName('selectAllcheckbox')[0].className =
        'selectAllcheckbox is-checked';
    } else {
      ref.current.clearSelection();
      document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
    }
  };

  // custom columns
  const checkboxTemplate = () => {
    return (
      <label className="e-checkbox-wrapper e-css">
        <input className="e-checkselect" type="checkbox" />
        <span className="e-frame e-icons"></span>
        <span className="e-label"> </span>
      </label>
    );
  };

  const checkboxHeaderTemplate = () => {
    return <span className="selectAllcheckbox" onClick={checkAll}></span>;
  };

  const imageTemplate = (rowData) => {
    return (
      <div className="deliveryContainer">
        <Media className="img-contain" src={rowData.item.fullImage} />
      </div>
    );
  };

  // Handler Functions
  const handleCreation = () => {
    ref.current.selectRowsByRange(0, inventoryData.length - 1);
    document.getElementsByClassName('selectAllcheckbox')[0].className =
      'selectAllcheckbox is-checked';
  };

  const handleClick = async () => {
    const selectedRecords = ref.current.getSelectedRecords();
    const selectedRecordsIds = selectedRecords.map((item) => item.itemId);
    const ItemIds = inventoryData
      .filter((item) => !selectedRecordsIds.includes(item.itemId))
      .map((item) => item.itemId);
    const dataBody = {
      value: 3,
      ItemIds,
    };
    try {
      setIsLoading(true);
      await ViewOrderService.updateOrderStatus(dataBody, orderId);
      setIsOrderStatusModalOpen(false);
      setTimeout(() => {
        dispatch(setViewOrderData(orderId));
        dispatch(setInventoryData(orderId));
        toast.success('Order status updated successfully!');
      }, 1500);
      setIsLoading(false);
    } catch (err) {
      errorLogger('OrderStatusModal:handleClick', err);
      setIsLoading(false);
      toast.error(err.response.data.message);
    }
  };

  return (
    <Modal isOpen={modalStatus} toggle={() => setIsOrderStatusModalOpen((prev) => !prev)} size="lg">
      <ModalHeader>Order Status (Out from stock)</ModalHeader>
      <ModalBody>
        <GridComponent
          dataSource={dataSource}
          ref={ref}
          dataBound={handleCreation}
          allowTextWrap={true}
          selectionSettings={{ type: 'Multiple', enableSimpleMultiRowSelection: true }}
          rowSelecting={checkSelecting}
          rowSelected={checkSelected}
          rowDeselected={checkSelected}
        >
          <ColumnsDirective>
            <ColumnDirective
              width="75"
              customAttributes={{ class: 'row-select-checkbox' }}
              template={checkboxTemplate}
              headerTemplate={checkboxHeaderTemplate}
              textAlign="Center"
            />
            <ColumnDirective
              textAlign="Center"
              headerText="Image"
              template={imageTemplate}
              width={100}
            />
            <ColumnDirective
              width={100}
              headerText="Barcode"
              field="item.barCode"
              textAlign="Center"
            />
            <ColumnDirective width={100} headerText="Name" field="item.name" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[ColumnChooser]} />
        </GridComponent>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" onClick={handleClick} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Add back to inventory'}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default OrderStatusModal;
