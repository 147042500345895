import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TicketSvg from './TicketSvg';
import { toast } from 'react-toastify';
import AssignModal from './AssignModal';
import RestrictedSection from 'components/shared/RestrictedSection';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import jwtDecode from 'jwt-decode';
import CloseNoteModal from './CloseNoteModal';
import UploadImgsModal from './UploadImgsModal';
import { Media } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import { convertUtcToLocalTime } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
import TicketCommentsList from './TicketCommentsList';
//material ui
import Tooltip from '@mui/material/Tooltip';
import { errorLogger } from 'datadog/DDUtils';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
// actions
import { setTicketDetailsData, setTicketCommentsList } from 'store/ticketing/TicketingActions';
// selectors
import { getTicketDetailsByid, getTicketCommentsList } from 'store/ticketing/TicketingSelectors';
// api
import TicketingService from 'services/TicketingService';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const TicketCommentHeader = ({ ticketId }) => {
  const ticketDetails = useSelector((state) => getTicketDetailsByid({ state }));
  const commentsList = useSelector((state) => getTicketCommentsList({ state }));
  const [newComment, setNewComment] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isCloseNoteModalOpen, setIsCloseNoteModalOpen] = useState(false);
  const [isUploadImgsModalOpen, setIsUploadImgsModalOpen] = useState(false);
  const decodedToken = jwtDecode(getFromLocalStorage(localStorageKeys.ACCESS_TOKEN));
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(setTicketDetailsData(ticketId));
  }, []);

  const handleAssignToMe = async () => {
    const dataBody = {
      TicketId: ticketId,
      AssignedTo: decodedToken.Id,
      AssignedToName: decodedToken.FullName,
      AssignedToEmail: decodedToken.Email,
      AssignedByName: decodedToken.FullName,
      AssignedBy: decodedToken.Id,
      comment: '',
    };
    try {
      await TicketingService.handleAssignUserToThread(dataBody);
      setTimeout(() => {
        dispatch(setTicketDetailsData(ticketId));
        toast.success('Ticket assigned successfully');
      }, 1500);
      handleClose();
    } catch (err) {
      errorLogger('TicketCommentHeader:handleAssignToMe', err);
      toast.error(err?.response?.data?.message ?? 'Failed to assign ticket!');
    }
  };

  const handleNewComment = async () => {
    const dataBody = {
      TicketId: ticketId,
      FromId: decodedToken.Id,
      FromName: decodedToken.FullName,
      FromEmail: decodedToken.Email,
      Comment: newComment,
      close: false,
    };
    setDisableBtn(true);
    try {
      await TicketingService.addNewTicketComment(dataBody);
      setTimeout(() => {
        dispatch(setTicketCommentsList(ticketId));
        setDisableBtn(true);
        toast.success('New comment added successfully');
        setNewComment('');
      }, 1500);
    } catch (err) {
      errorLogger('TicketCommentHeader:handleNewComment', err);
      setDisableBtn(true);
      toast.error('Failed to add new comment!');
    }
  };

  useEffect(() => {
    if (newComment !== '') {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [newComment]);

  const handleRedirect = () => {
    window.open(`/order/${ticketDetails.mapid}`, '_blank');
  };

  const redirectToUserPage = (name, id) => (
    <span className="ticket-user-name" onClick={() => window.open(`/admin/users/${id}`, '_blank')}>
      {name}
    </span>
  );

  return (
    <Fragment>
      {isAssignModalOpen && (
        <AssignModal
          isAssignModalOpen={isAssignModalOpen}
          setIsAssignModalOpen={setIsAssignModalOpen}
          ticketId={ticketId}
        />
      )}
      {isCloseNoteModalOpen && (
        <CloseNoteModal
          ticketId={ticketId}
          isCloseNoteModalOpen={isCloseNoteModalOpen}
          setIsCloseNoteModalOpen={setIsCloseNoteModalOpen}
        />
      )}
      {isUploadImgsModalOpen && (
        <UploadImgsModal
          isUploadImgsModalOpen={isUploadImgsModalOpen}
          setIsUploadImgsModalOpen={setIsUploadImgsModalOpen}
          ticketId={ticketId}
        />
      )}
      <div className="col-xl-12 xl-60">
        {ticketDetails?.caseNumber ? (
          <h6 className="comment-header-title">Case Id: {ticketDetails?.caseNumber}</h6>
        ) : null}
        <div className="card">
          <div className="job-search">
            <div className="card-body">
              <div className="media">
                <TicketSvg />
                <div className="media-body">
                  <div className="comment-header-wrapper">
                    <h6 className="comment-header-title">
                      {ticketDetails.ticketPriority === 1 && (
                        <Tooltip title="VIP">
                          <i className="fa fa-exclamation-circle red-color m-r-4"></i>
                        </Tooltip>
                      )}
                      {ticketDetails.title}
                    </h6>
                    {ticketDetails.mapid && (
                      <h6 onClick={handleRedirect} className="comment-header-orderId">
                        order# {ticketDetails.mapid} |
                      </h6>
                    )}
                    {ticketDetails.department === 1 && (
                      <h6 className="comment-header-tech-type"> Tech Ticket</h6>
                    )}
                  </div>
                  {ticketDetails && ticketDetails.openedByName && (
                    <p className="m-0">
                      By: {redirectToUserPage(ticketDetails.openedByName, ticketDetails.openedBy)},{' '}
                      {ticketDetails.opsId && ticketDetails.department === 1 && (
                        <span className="m-l-0">{ticketDetails.opsName},</span>
                      )}{' '}
                      Date: {convertUtcToLocalTime(ticketDetails.openedDate)}
                      <br></br>
                      {ticketDetails.assignedToName && (
                        <span className="ticket-assign-to">
                          (Assigned to:{' '}
                          {redirectToUserPage(
                            ticketDetails.assignedToName,
                            ticketDetails.assignedTo
                          )}
                          , Date:{' '}
                          {ticketDetails.assignedDate &&
                            convertUtcToLocalTime(ticketDetails.assignedDate)}
                          )
                        </span>
                      )}
                    </p>
                  )}
                  {ticketDetails && ticketDetails.topicName && (
                    <p className="m-0">
                      {ticketDetails.topicName && (
                        <span className="ticket-topic-name">
                          (Topic: {ticketDetails.topicName})
                        </span>
                      )}
                    </p>
                  )}
                </div>
                <Fragment>
                  <RestrictedSection
                    page="tickets"
                    action={ticketDetails.department === 1 ? 'assign_tech_ticket' : 'assign_ticket'}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      disabled={ticketDetails.status === 2}
                      onClick={handleClick}
                      endIcon={<i className="fa fa-user"></i>}
                    >
                      {ticketDetails.assignedTo ? 'Re-Assign' : 'Assign'}
                    </Button>
                    <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      <MenuItem
                        disabled={String(ticketDetails.assignedTo) === String(decodedToken.Id)}
                        onClick={handleAssignToMe}
                      >
                        Assign to me
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          setIsAssignModalOpen((prev) => !prev);
                          handleClose();
                        }}
                      >
                        Assign to others
                      </MenuItem>
                    </StyledMenu>
                  </RestrictedSection>
                  {String(ticketDetails.assignedTo) === String(decodedToken.Id) && (
                    <RestrictedSection
                      page="tickets"
                      action={ticketDetails.department === 1 ? 'close_tech_thread' : 'close_thread'}
                    >
                      <button
                        onClick={() => setIsCloseNoteModalOpen((prev) => !prev)}
                        disabled={ticketDetails.status === 2}
                        className="btn btn-danger close-ticket-btn"
                        type="button"
                      >
                        CLOSE <i className="fa fa-lock"></i>
                      </button>
                    </RestrictedSection>
                  )}
                </Fragment>
              </div>
              <div className="ticket-description">
                <p className="sz-15 m-b-15">{ticketDetails.description}</p>
                <button
                  onClick={() => setIsUploadImgsModalOpen((prev) => !prev)}
                  className="btn btn-dark ticket-attachment-btn"
                  type="button"
                >
                  <i className="fa fa-paperclip sz-20"></i>
                </button>
              </div>
              {ticketDetails.hasAttachment && (
                <div className="ticket-attachment-wrapper">
                  {ticketDetails.ticketAttachments.map((item, i) => (
                    <Media
                      onClick={() => {
                        setIsImgOpen(true);
                        setImageSrc(item.fileFullUrl);
                      }}
                      key={i}
                      src={item.fileFullUrl}
                      className="ticket-attachment-images"
                    />
                  ))}
                </div>
              )}
              <div className="ticket-comments-wrapper">
                {ticketDetails && ticketDetails.status !== 2 ? (
                  <div className="comments-box col-12 p-l-0">
                    <div className="media">
                      <div className="media-body">
                        <form className="form-group text-box ticket-comments-box">
                          <textarea
                            className="form-control input-txt-bx ticket-comment-area"
                            type="text"
                            name="message-to-send"
                            placeholder="Write a comment"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                          ></textarea>
                          <div className="input-group-append">
                            <button
                              onClick={handleNewComment}
                              disabled={disableBtn || !newComment}
                              className="btn btn-primary ticket-comment-post-btn"
                              type="button"
                            >
                              Post
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h6 className="closed-ticket-title">
                      <i className="fa fa-lock"></i> Closed Thread
                    </h6>
                    {ticketDetails.closedByName && (
                      <p className="closed-ticket-by">
                        (By:{' '}
                        {redirectToUserPage(ticketDetails.closedByName, ticketDetails.closedBy)},
                        Date: {''}
                        {ticketDetails.closedDate &&
                          convertUtcToLocalTime(ticketDetails.closedDate)}
                        )
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="like-comment mt-4 comments-counter">
                <ul className="list-inline">
                  <li className="list-inline-item ml-2">
                    <label className="m-0" htmlFor="comment">
                      <span className="comment-icon">
                        <i className="fa fa-comment"></i>
                      </span>
                      Comment
                    </label>
                    <span className="ml-2 counter">
                      {commentsList.length > 0 && commentsList.length}
                    </span>
                  </li>
                </ul>
              </div>
              <TicketCommentsList ticketId={ticketId} redirectToUserPage={redirectToUserPage} />
            </div>
          </div>
        </div>
        {isImgOpen && <Lightbox onCloseRequest={() => setIsImgOpen(false)} mainSrc={imageSrc} />}
      </div>
    </Fragment>
  );
};

export default TicketCommentHeader;
