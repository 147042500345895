import { updateObject } from '../utility';

const initialState = {
  celebritiesData: [],
  selectedCelebrity: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CELEBRITIES_DATA': {
      return updateObject(state, {
        celebritiesData: action.payload,
      });
    }
    case 'GET_CELEBRITY_BY_ID': {
      return updateObject(state, {
        selectedCelebrity: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
