import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// actions
import {
  setCurrentQuickActionsTablePage,
  setPremiumTableData,
  setRequiredParamsForQuickActionsTable,
  setLocationLinkIds,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getCurrentQuickActionsTablePage,
  getQuickActionsPageSize,
  getQuickActionsTotalData,
  getQuickActionsTableParams,
  getTableData,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// components
import DJHPagination from 'components/shared/Pagination';
import QuickActionsDataGrid from './QuickActionsDataGrid';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// constants
import { getPremiumQuickActionsPageUrl } from 'constant/AppUrls';
import { convertObjectIntoQueryParams } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';

const PremiumQuickActionsDataTable = () => {
  const dispatch = useDispatch();
  const currentQuickActionsTablePage = useSelector((state) =>
    getCurrentQuickActionsTablePage({ state })
  );
  const params = useSelector((state) => getQuickActionsTableParams({ state }));
  const pageSize = useSelector((state) => getQuickActionsPageSize({ state }));
  const totalDataNum = useSelector((state) => getQuickActionsTotalData({ state }));
  const data = useSelector((state) => getTableData({ state }));
  const navigate = useNavigate();

  const onPageChange = (i) => {
    dispatch(setCurrentQuickActionsTablePage(i));
    const newParams = cloneDeep(params);
    newParams.page = i;
    dispatch(setRequiredParamsForQuickActionsTable(newParams));
    const queryParams = newParams;
    const queryString = convertObjectIntoQueryParams(queryParams);

    navigate({
      pathname: getPremiumQuickActionsPageUrl(),
      search: queryString,
    });
    dispatch(setPremiumTableData(params.searchkey));
    document.documentElement.scrollTop = 0;
    const classNames = document.getElementsByClassName('selectAllcheckbox')?.[0]?.className || '';
    const isChecked = classNames.includes('is-checked');
    if (isChecked) {
      document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
      dispatch(setLocationLinkIds(''));
    }
  };

  return (
    <div className="quick-actions-table toolbar-table-style">
      <AppErrorBoundary>
        <QuickActionsDataGrid data={data.data} premium />
      </AppErrorBoundary>
      {totalDataNum > pageSize && (
        <AppErrorBoundary>
          <DJHPagination
            onPageChange={onPageChange}
            currentPage={currentQuickActionsTablePage}
            pageSize={pageSize}
            itemsCount={totalDataNum}
          />
        </AppErrorBoundary>
      )}
    </div>
  );
};

export default PremiumQuickActionsDataTable;
