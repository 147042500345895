import { updateObject } from '../utility';
// type
import { GET_BANNERS_DATA } from './BannerActionTypes';

const initialState = {
  bannersData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNERS_DATA: {
      return updateObject(state, {
        bannersData: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
