import PermissionService from '../../services/PermissionService';
import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const getUserSavedCC = (userId) => async (dispatch) => {
  try {
    const savedCC = await PermissionService.getUserSavedCC(userId);
    dispatch({
      type: 'GET_USER_SAVED_CC',
      payload: savedCC.data,
    });
  } catch (err) {
    errorLogger('PermissionsActions:getUserSavedCC', err);
    toast.error('Loading saved credit cards have failed');
  }
};

export const deleteUserSavedCC = (cardId, newdata) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    await PermissionService.deleteUserSavedCC(cardId);
    dispatch({
      type: 'GET_USER_SAVED_CC',
      payload: newdata,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('PermissionsActions:deleteUserSavedCC', err);
    toast.error('Deleting credit card has failed');
    dispatch(setLoaderOpenFalse());
  }
};
