/* eslint-disable indent */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// material UI
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import { makeStyles } from '@mui/styles';
// constants
import { objectWithoutKey } from 'constant/Helpers';
import ErrorMessages from 'constant/ErrorMessages';

const useStyles = makeStyles({
  inputContainer: {
    width: '100%',
    marginBottom: 8,
  },
  inputField: {
    marginTop: 0,
  },
  fullWidth: {
    width: '100%',
  },
  inlineRadioGroup: {
    '& div': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  errorMessage: {
    margin: '0 0 10px',
    color: 'red',
    fontSize: 16,
    marginBottom: 0,
  },
});

const Input = ({
  invalid,
  touched,
  elementType,
  elementClasses,
  elementConfig,
  value,
  changed,
  focused,
  blured,
  keyupHandler,
  keydownHandler,
  label,
  required,
  InputLabelProps, // for HTML attributes
  extraInputProps, // for material UI to show text or icon in an input field
  inlineRadio,
  noInputContainer,
  disabledCheckbox,
  disabled,
  errorsArray,
}) => {
  const classes = useStyles();
  const errors = {
    isRequiredError: ErrorMessages.getRequiredErrorMessage(),
    isNumError: ErrorMessages.getNumErrorMessage(),
    isGreaterThanZeroError: ErrorMessages.getGreaterThanZeroErrorMessage(),
  };
  let inputElement = null;

  switch (elementType) {
    case 'input':
      inputElement = (
        <TextField
          className={`${classes.inputField} ${elementClasses || ''} input`}
          fullWidth
          inputProps={{ ...elementConfig }}
          InputProps={{ ...extraInputProps }}
          error={invalid && touched}
          label={label}
          value={value}
          onChange={changed}
          onFocus={focused || null}
          onBlur={blured || null}
          onKeyUp={keyupHandler}
          onKeyDown={keydownHandler}
          margin="normal"
          InputLabelProps={InputLabelProps || {}}
          required={required}
          disabled={disabled}
          variant="standard"
        />
      );
      break;
    case 'file_input':
      inputElement = (
        <TextField
          className={`${classes.inputField} ${elementClasses || ''} input`}
          fullWidth
          inputProps={{ ...elementConfig }}
          InputProps={{ ...extraInputProps }}
          error={invalid && touched}
          label={label}
          onChange={changed}
          onFocus={focused || null}
          onBlur={blured || null}
          InputLabelProps={InputLabelProps || {}}
          required={required}
          disabled={disabled}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <TextField
          className={`${classes.inputField} ${elementClasses || ''} input`}
          fullWidth
          inputProps={{ ...elementConfig }}
          error={invalid && touched}
          label={label}
          multiline
          margin="normal"
          value={value}
          onChange={changed}
          onFocus={focused || null}
          onBlur={blured || null}
          InputLabelProps={InputLabelProps || {}}
          required={required}
          disabled={disabled}
        />
      );
      break;
    case 'checkbox':
      inputElement = (
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabledCheckbox || null}
              {...elementConfig}
              checked={value}
              onChange={changed}
              color="primary"
            />
          }
          label={label}
        />
      );
      break;
    case 'radio_group':
      inputElement = (
        <FormControl
          className={`${classes.fullWidth} ${inlineRadio ? classes.inlineRadioGroup : ''} input`}
          component="fieldset"
        >
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            className={`${classes.inputField} ${elementClasses || ''}`}
            {...objectWithoutKey(elementConfig, 'options')}
            value={value}
            onChange={changed}
          >
            {elementConfig.options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.displayValue}
                disabled={option.disabled}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
      break;
    case 'select':
      inputElement = (
        <FormControl
          className={classes.fullWidth}
          required={required}
          error={invalid && touched}
          disabled={disabled}
        >
          <InputLabel shrink htmlFor={elementConfig.id}>
            {label}
          </InputLabel>
          <Select
            value={value}
            onChange={changed}
            onFocus={focused || null}
            onBlur={blured || null}
            displayEmpty
            {...objectWithoutKey(elementConfig, 'options')}
            className={`${classes.inputField} ${elementClasses || ''} input`}
            inputProps={{
              id: elementConfig.id,
            }}
          >
            {elementConfig.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.displayValue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      break;
    case 'select-grouped':
      inputElement = (
        <FormControl
          className={classes.fullWidth}
          required={required}
          error={invalid && touched}
          disabled={disabled}
        >
          <InputLabel shrink htmlFor={elementConfig.id}>
            {label}
          </InputLabel>
          <Select
            value={value}
            onChange={changed}
            onFocus={focused || null}
            onBlur={blured || null}
            displayEmpty
            {...objectWithoutKey(elementConfig, 'options')}
            className={`${classes.inputField} ${elementClasses || ''} input`}
            inputProps={{
              id: elementConfig.id,
            }}
            defaultValue={elementConfig.defaultValue ? elementConfig.defaultValue : null}
          >
            {elementConfig.defaultValue ? (
              <MenuItem {...objectWithoutKey(elementConfig.defaultValue, 'displayValue')}>
                <em>{elementConfig.defaultValue.displayValue}</em>
              </MenuItem>
            ) : null}
            {elementConfig.groups
              ? elementConfig.groups.map((group, groupId) => [
                  <ListSubheader key={groupId}>{group.label}</ListSubheader>,
                  ...group.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.displayValue}
                    </MenuItem>
                  )),
                ])
              : elementConfig.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.displayValue}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      );
      break;
    default:
      inputElement = (
        <TextField
          className={`${classes.inputField} ${elementClasses || ''} input`}
          fullWidth
          inputProps={{ ...elementConfig }}
          InputProps={{ ...extraInputProps }}
          error={invalid && touched}
          label={label}
          value={value}
          onChange={changed}
          onFocus={focused || null}
          onBlur={blured || null}
          margin="normal"
          InputLabelProps={InputLabelProps || {}}
          required={required}
          disabled={disabled}
        />
      );
  }
  return (
    <div className={!noInputContainer ? classes.inputContainer : ''}>
      {inputElement}
      {errorsArray &&
        errorsArray.length > 0 &&
        errorsArray.map((el, i) => (
          <Fragment key={i}>
            {el.value && (
              <p className={classes.errorMessage}>
                {el.args ? errors[el.key](el.args) : errors[el.key]}
              </p>
            )}
          </Fragment>
        ))}
    </div>
  );
};

Input.prototype = {
  invalid: PropTypes.bool,
  touched: PropTypes.bool,
  elementType: PropTypes.string,
  elementConfig: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changed: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  InputLabelProps: PropTypes.object,
};

export default Input;
