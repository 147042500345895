import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
// Actions
import { setDriverRadius } from 'store/delivery/driverRadius/DriverRadiusActions';

const DriverRadiusForm = ({ inputValues, setInputValues, title }) => {
  const [showCustomerErrMessage, setShowCustomerErrMessage] = useState(false),
    [showWorkshopErrMessage, setShowWorkshopErrMessage] = useState(false),
    [isDisabled, setIsDisabled] = useState(true),
    dispatch = useDispatch();

  const handleChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[e.target.name] = e.target.value;
    setInputValues(updatedParams);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowCustomerErrMessage(false);
    setShowWorkshopErrMessage(false);
    let errorToastMess = 'Incorrect distance';
    let isInCustomerRadius =
      inputValues['customerRadius'] >= 100 && inputValues['customerRadius'] <= 400;
    let isInWorkshopRadius =
      inputValues['workshopRadius'] >= 100 && inputValues['workshopRadius'] <= 1000;

    if (!isInCustomerRadius && !isInWorkshopRadius) {
      setShowCustomerErrMessage(true);
      setShowWorkshopErrMessage(true);
      toast.error(errorToastMess);
    } else if (!isInCustomerRadius) {
      setShowCustomerErrMessage(true);
      toast.error(errorToastMess);
    } else if (!isInWorkshopRadius) {
      setShowWorkshopErrMessage(true);
      toast.error(errorToastMess);
    } else {
      const payload = {
        customerRadius: inputValues['customerRadius'],
        hubRadius: inputValues['workshopRadius'],
      };
      dispatch(setDriverRadius(payload));
    }
  };

  useEffect(() => {
    if (inputValues['customerRadius'] !== '' && inputValues['workshopRadius'] !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  });

  return (
    <div className="card">
      <div className="card-header p-18">
        <h5 className="sz-14">{title}</h5>
      </div>
      <form className="form theme-form" onSubmit={handleSubmit}>
        <div className="row card-body">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>
                <span className="bold-title">Customer Radius in meters</span>{' '}
                <span className={`red-color ${!showCustomerErrMessage ? 'hide-display' : ''}`}>
                  {' '}
                  *Min 100 - Max 400
                </span>
              </label>
              <input
                className="form-control m-b-10"
                type="number"
                value={inputValues['customerRadius']}
                onChange={handleChange}
                name="customerRadius"
              />
              <span>
                A zone to the driver to be able to change the order status when he is in that zone.
              </span>
              <p>*Minimum 100 - Maximum 400</p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>
                <span className="bold-title">Workshop Radius in meters</span>{' '}
                <span className={`red-color ${!showWorkshopErrMessage ? 'hide-display' : ''}`}>
                  {' '}
                  *Min 100 - Max 1000
                </span>
              </label>
              <input
                className="form-control m-b-10"
                type="number"
                value={inputValues['workshopRadius']}
                onChange={handleChange}
                name="workshopRadius"
              />
              <span>
                An area for the driver to be able to set his online status when he is in that area.
              </span>
              <p>*Minimum 100 - Maximum 1000</p>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <button className="btn btn-primary mr-1" type="submit" disabled={isDisabled}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default DriverRadiusForm;
