import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import RestrictedSection from 'components/shared/RestrictedSection';
import CustomSelect from 'components/shared/CustomSelect';
import { errorLogger } from 'datadog/DDUtils';
// constants
import { handleImageFile, HasPermission } from 'constant/Helpers';
import { inventoryTypes } from 'constant/Enums';
// actions
import {
  getInventorySuppliersList,
  setCopyToBasketItem,
  setInventoryItemDetailsById,
} from 'store/inventory/InventoryActions';
// selectors
import { getSuppliersList, getInventoryItemDetailsById } from 'store/inventory/InventorySelectors';
import { getPermissionsList } from 'store/app/AppSelectors';
// api
import InventoryService from 'services/InventoryService';

const InventoryItemSection = ({ itemId }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const inventoryItemById = useSelector((state) => getInventoryItemDetailsById({ state }));
  const suppliersList = useSelector((state) => getSuppliersList({ state }));
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [name, setName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [price, setPrice] = useState('');
  const [retailPrice, setRetailPrice] = useState('');
  const [alertQty, setAlertQty] = useState(0);
  const [inventoryType, setInventoryType] = useState('');
  const [status, setStatus] = useState('');
  const [supplier, setSupplier] = useState('');
  const [itemImage, setItemImage] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(inventoryItemById).length > 0) {
      setName(inventoryItemById.name);
      setBarcode(inventoryItemById.barCode);
      setPrice(inventoryItemById.price);
      setRetailPrice(inventoryItemById.retailPrice);
      setAlertQty(inventoryItemById.alertQty);
      setInventoryType(inventoryItemById.inventoryType);
      setStatus(inventoryItemById.status);
      setSupplier(inventoryItemById.supplierId);
      setItemImage(inventoryItemById.parentImage);
    }
  }, [inventoryItemById]);

  useEffect(() => {
    dispatch(setInventoryItemDetailsById(itemId));
  }, []);

  useEffect(() => {
    dispatch(getInventorySuppliersList());
  }, []);

  const getFormData = () => {
    const data = {
      Name: name,
      Barcode: barcode,
      Price: price,
      RetailPrice: retailPrice,
      Type: inventoryType,
      Img: itemImage,
      SupplierId: supplier,
      status,
      InventroyId: inventoryItemById.inventoryId,
      ItemId: inventoryItemById.id,
      alertQty,
    };
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    return formData;
  };

  const handleAddNewItem = async () => {
    if (price >= 0 && alertQty >= 0) {
      const formData = getFormData();
      setDisableBtn(true);
      setIsLoaderOpen(true);
      try {
        const res = await InventoryService.editInventoryItem(formData, inventoryItemById.id);
        toast.success(res?.data?.message ?? 'Updated Successfully');
        setIsLoaderOpen(false);
        setDisableBtn(false);
      } catch (err) {
        errorLogger('InventoryItemSection:handleAddNewItem', err);
        setDisableBtn(false);
        setIsLoaderOpen(false);
        toast.error(err?.response?.data?.message ?? 'Failed to update inventory');
      }
    } else {
      toast.error('Invalid input value');
    }
  };

  const handleCopyToBasketItem = (id) => {
    dispatch(setCopyToBasketItem(id));
  };

  return (
    <div className="form-group col-12 card">
      <div className="card-header p-12 sz-20">Edit Inventory Item</div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <Fragment>
          <div className="card-body row">
            <RestrictedSection page="inventory" action="edit_inventory">
              <div className="form-group col-6">
                <label className="bold-title" htmlFor="item-name">
                  Name
                </label>
                <input
                  id="item-name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="form-control"
                  type="text"
                  readOnly
                />
              </div>
            </RestrictedSection>
            <RestrictedSection page="inventory" action="edit_inventory">
              <div className="form-group col-6">
                <label className="bold-title" htmlFor="item-barcode">
                  Barcode
                </label>
                <input
                  id="item-barcode"
                  onChange={(e) => setBarcode(e.target.value)}
                  value={barcode}
                  className="form-control"
                  type="text"
                  readOnly
                />
              </div>
            </RestrictedSection>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="item-price">
                Price
              </label>
              <input
                id="item-price"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
                className="form-control"
                type="number"
                min="0"
                disabled={!HasPermission(permissionsList, 'inventory', 'edit_inventory_price')}
              />
            </div>
            {[7, 8, 10].includes(inventoryType) && (
              <div className="form-group col-6">
                <label className="bold-title" htmlFor="item-retail-price">
                  Retail Price
                </label>
                <input
                  id="item-retail-price"
                  onChange={(e) => setRetailPrice(e.target.value)}
                  value={retailPrice}
                  className="form-control"
                  type="number"
                  min="0"
                  disabled={!HasPermission(permissionsList, 'inventory', 'edit_inventory_price')}
                />
              </div>
            )}
            <RestrictedSection page="inventory" action="edit_alert_qty">
              <div className="form-group col-6">
                <label className="bold-title" htmlFor="item-qty">
                  Alert Qty
                </label>
                <input
                  id="item-qty"
                  onChange={(e) => setAlertQty(e.target.value)}
                  value={alertQty}
                  className="form-control"
                  type="number"
                  min="0"
                />
              </div>
            </RestrictedSection>
            <RestrictedSection page="inventory" action="edit_inventory">
              <div className="form-group col-md-6 filter-select">
                <label className="bold-title" htmlFor="item-name">
                  Inventory Type
                </label>
                <CustomSelect
                  id="item-name"
                  listData={inventoryTypes.slice(1)}
                  handleChange={(e) => setInventoryType(e.itemData.id)}
                  placeholder="Select Inventory type"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={inventoryType}
                />
              </div>
            </RestrictedSection>
            <div className="col-12 col-md-6">
              <div className="form-group filter-select">
                <label className="bold-title" htmlFor="item-status">
                  Status
                </label>
                <CustomSelect
                  id="item-status"
                  listData={[
                    { id: 0, name: 'In_Stock' },
                    { id: 2, name: 'Discontinued' },
                  ]}
                  handleChange={(e) => setStatus(e.itemData.id)}
                  placeholder="Select a status"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={status}
                />
              </div>
            </div>
            {suppliersList.length > 0 && (
              <div className="form-group col-md-6 filter-select">
                <label className="bold-title" htmlFor="item-supplier">
                  Select Supplier
                </label>
                <CustomSelect
                  id="item-supplier"
                  listData={[{ id: 0, companyName: '' }].concat(suppliersList)}
                  handleChange={(e) => setSupplier(e.itemData.id)}
                  placeholder="Select Supplier"
                  textPropName="companyName"
                  valuePropName="id"
                  selectedValue={supplier}
                />
              </div>
            )}
            <RestrictedSection page="inventory" action="edit_inventory">
              <div className="form-group col-6">
                <label className="bold-title" htmlFor="item-image">
                  Image
                </label>
                <div
                  id="item-image"
                  className="file-img"
                  style={{
                    backgroundImage: `url(${handleImageFile(itemImage)})`,
                    height: itemImage === '' ? 0 : 150,
                  }}
                ></div>
                <div className="file-input">
                  <input
                    type="file"
                    className="file"
                    id="itemImg"
                    onChange={(e) => setItemImage(e.target.files[0])}
                  />
                  <label htmlFor="itemImg">
                    <p className="file-name">Select file</p>
                  </label>
                </div>
              </div>
            </RestrictedSection>
          </div>
        </Fragment>
      )}
      <div className="card-footer  d-flex justify-content-between">
        <button
          disabled={!name || !barcode || disableBtn}
          onClick={handleAddNewItem}
          className="btn btn-primary"
          type="button"
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => handleCopyToBasketItem(inventoryItemById.id)}
        >
          <i className="fa fa-file"></i> Copy to basket
        </button>
      </div>
    </div>
  );
};

export default InventoryItemSection;
