import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  Aggregate,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const ProductTypeAttributeDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  let flag = false;

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const dataBound = () => {
    flag = true;
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          dataBound={dataBound}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Type"
              field="name"
              width={120}
              customExcelTemplate={(rowData) => rowData?.name}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Sales"
              template={(rowData) => (
                <span>
                  {rowData?.total != null &&
                    `${rowData?.total.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData?.total != null &&
                `${rowData?.total.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Cost"
              template={(rowData) => (
                <span>
                  {rowData?.cost != null &&
                    `${rowData?.cost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData?.cost != null &&
                `${rowData?.cost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Margin%"
              template={(rowData) => (
                <span>
                  {rowData?.margin != null &&
                    `${rowData?.margin.toFixed(opsCenter?.country?.decimalNumber ?? 3)}%`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData?.margin != null &&
                `${rowData?.margin.toFixed(opsCenter?.country?.decimalNumber ?? 3)}%`
              }
              textAlign="Center"
            />
          </ColumnsDirective>

          {/* <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective field="cost" type="Sum" footerTemplate={footerSum}>
                  {' '}
                </AggregateColumnDirective>
              </AggregateColumnsDirective>
            </AggregateDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective field="total" type="Sum" footerTemplate={footerSum}>
                  {' '}
                </AggregateColumnDirective>
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective> */}

          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, Aggregate]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(ProductTypeAttributeDataGrid);
