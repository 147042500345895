import { toast } from 'react-toastify';
// api
import DesignersReportService from '../../services/reportsService/DesignersReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const setDesignerslistData = () => async (dispatch) => {
  try {
    const res = await DesignersReportService.getDesignersList();
    dispatch({
      type: 'SET_DESIGNERS_LIST_DATA',
      payload: res.data.result,
    });
  } catch (err) {
    errorLogger('Designers Report Store Fetch Data Error:', err);
    toast.error('Failed to get designers list!!');
  }
};
