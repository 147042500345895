/* eslint-disable indent */
import { toast } from 'react-toastify';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse, setCountriesListData } from '../app/AppActions';
// api
import PermissionService from 'services/PermissionService';
import UserService from 'services/UserService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const setUserPageMainData = (userId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await PermissionService.getUserInfoById(userId);
    dispatch({
      type: 'SET_USER_DATA_BY_ID',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('UserActions:setUserPageMainData', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get user data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setUserWalletBalanceById = (userId) => async (dispatch) => {
  try {
    const res = await PermissionService.getUserBalancebyId(userId);
    dispatch({
      type: 'SET_USER_WALLET_BALANCE_BY_ID',
      payload: res.data.balance,
    });
  } catch (err) {
    errorLogger('UserActions:setUserWalletBalanceById', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get user wallet balance!!');
  }
};

export const setUserGroupsById = (userId) => async (dispatch) => {
  try {
    const res = await PermissionService.getUserGroups(userId);
    dispatch({
      type: 'SET_USER_GROUPS_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('UserActions:setUserGroupsById', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get user groups!!');
  }
};

export const setUserRecentTransactionsById =
  ({ userId }) =>
  async (dispatch) => {
    try {
      const res = await PermissionService.getUserLatestTransactions({ userId });
      dispatch({
        type: 'SET_USER_RECENT_TRANSACTIONS_BY_ID',
        payload: res.data,
      });
    } catch (err) {
      errorLogger('UserActions:setUserRecentTransactionsById', err);
      toast.error(err?.response?.data?.message ?? 'Failed to get user transactions!!');
    }
  };

export const setUserPointsById = (userId) => async (dispatch) => {
  try {
    const res = await PermissionService.getUserPointsbyId(userId);
    dispatch({
      type: 'SET_USER_POINTS_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('UserActions:setUserPointsById', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get user points!!');
  }
};

export const setUserPageAllData = (userId) => async (dispatch) => {
  const promisesArray = [];
  try {
    promisesArray.push(
      dispatch(setUserPageMainData(userId)),
      dispatch(setUserWalletBalanceById(userId)),
      dispatch(setUserGroupsById(userId)),
      dispatch(setCountriesListData()),
      dispatch(setUserRecentTransactionsById({ userId })),
      dispatch(setUserPointsById(userId))
    );
    await Promise.all(promisesArray);
  } catch (err) {
    errorLogger('UserActions:setUserPageAllData', err);
    toast.error('Failed to get user data');
  }
};

export const setUserPointsHistoryById = (userId) => async (dispatch) => {
  try {
    const res = await PermissionService.getUserPointsHistorybyId(userId);
    dispatch({
      type: 'SET_USER_POINTS_HISTORY',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('UserActions:setUserPointsHistoryById', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get user points history!!');
  }
};

export const setUserActivitiesById = (userId) => async (dispatch) => {
  try {
    const res = await PermissionService.getUserActivitiesById(userId);
    dispatch({
      type: 'SET_USER_ACTIVITIES',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('UserActions:setUserActivitiesById', err);
  }
};

export const getGroupUsersById = (groupId) => async (dispatch) => {
  try {
    const res = await PermissionService.getGroupUsersList(groupId);
    dispatch({
      type: 'SET_GROUP_USERS_LIST',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('UserActions:getGroupUsersById', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get users list');
  }
};

// pickers & packers
export const getAllRoles = () => async (dispatch) => {
  try {
    const res = await UserService.getAllRoles();
    dispatch({
      type: 'GET_USER_ROLES',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('UserActions:getAllRoles', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get users details.');
  }
};

export const addUserRole = (userId, roleId) => async () => {
  try {
    await UserService.addUserRole(userId, roleId);
  } catch (err) {
    errorLogger('UserActions:addUserRole', err);
    toast.error(err?.response?.data?.message ?? 'Failed to add role.');
  }
};
export const deleteUserRole = (userId, roleId) => async () => {
  try {
    await UserService.deleteUserRole(userId, roleId);
  } catch (err) {
    errorLogger('UserActions:deleteUserRole', err);
    toast.error(err?.response?.data?.message ?? 'Failed to delete role.');
  }
};

export const changeUserStatus = (userId, isUserActive) => async () => {
  try {
    await UserService.changeUserStatus(isUserActive, userId);
  } catch (err) {
    errorLogger('UserActions:changeUserStatus', err);
    toast.error(err?.response?.data?.message ?? 'Failed to change status.');
  }
};

export const getUserDetails = (userId) => async (dispatch) => {
  try {
    const [res, roles] = await Promise.allSettled([
      UserService.getUserDetails(userId),
      UserService.getUserRoles(userId),
    ]);

    dispatch({
      type: 'SET_USER_DETAILS',
      payload: {
        userInfo: res.status === 'fulfilled' ? res.value.data : {},
        userRoles: roles.status === 'fulfilled' ? roles.value.data : [],
      },
    });
  } catch (err) {
    errorLogger('UserActions:getUserDetails', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get users details.');
  }
};

export const getUsersListData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await UserService.getUsersList(params);
    dispatch({
      type: 'SET_PICKERS_USERS_LIST',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('UserActions:getUsersListData', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get users list');
    dispatch(setLoaderOpenFalse());
  }
};

export const createNewUser = (data) => async () => {
  try {
    const res = await UserService.createUser(data);
    toast.success(`User ${data.firstName} ${data.lastName} created successfully.`);
    return res;
  } catch (err) {
    errorLogger('UserActions:createNewUser', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get users list');
  }
};

export const editUser = (data, userId) => async () => {
  try {
    const res = await UserService.editUser(data, userId);
    toast.success(`User ${data.firstName} ${data.lastName} updated successfully.`);
    return res.status;
  } catch (err) {
    errorLogger('UserActions:editUser', err);
    toast.error(err?.response?.data?.message ?? 'Failed to update user.');
  }
};
