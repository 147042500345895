import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';
class PermissionService {
  // pages
  static getPermissionPages(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.permissionPages, ...extraParams),
    });
  }

  static getPermissionPageById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.permissionPageById, ...extraParams),
    });
  }

  static createPermissionPage(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.permissionPages, ...extraParams),
      data,
    });
  }

  static updatePermissionPage(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.permissionPages, ...extraParams),
      data,
    });
  }

  // pages actions
  static createPageAction(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.permissionPageActions, ...extraParams),
      data,
    });
  }

  static updatePageAction(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.permissionPageActions, ...extraParams),
      data,
    });
  }

  // groups
  static getPermissionGroups(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.permissionGroups, ...extraParams),
    });
  }

  static createNewGroup(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.permissionGroups, ...extraParams),
      data,
    });
  }

  static UpdateGroup(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.permissionGroups, ...extraParams),
      data,
    });
  }

  static copyGroup(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.permissionsCopyGroup, ...extraParams),
      data,
    });
  }

  // groups actions
  static getGroupActions(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.permissionsGroupActions, ...extraParams),
    });
  }

  static UpdateGroupActions(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.permissionsGroupActions, ...extraParams),
      data,
    });
  }

  static getGroupUsersList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getGroupUsersList, ...extraParams),
    });
  }

  // users
  static getSearchUsers(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.permissionSearchUsers, ...extraParams),
      params,
    });
  }

  static getUserInfoById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserInfoById, ...extraParams),
    });
  }

  static getUserBalancebyId(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserBalance, ...extraParams),
    });
  }

  static getUserPointsbyId(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserPointsById, ...extraParams),
    });
  }

  static getUserPointsHistorybyId(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserPointsHistoryById, ...extraParams),
    });
  }

  static addUserToGroup(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addUserToGroup, ...extraParams),
      data,
    });
  }

  static getUserGroups(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.addUserToGroup, ...extraParams),
    });
  }

  static deleteUserGroup(data, ...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.addUserToGroup, ...extraParams),
      data,
    });
  }

  static requestOtp(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.requestOtp, ...extraParams),
    });
  }

  static userTempPassword(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.userTempPassword, ...extraParams),
    });
  }

  static saveUserDetails(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.saveUserDetails, ...extraParams),
      data,
    });
  }

  static getUserSavedCC(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.userSavedCC, ...extraParams),
    });
  }

  static deleteUserSavedCC(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.userSavedCC, ...extraParams),
    });
  }

  static clearUserWallet(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.clearWallet, ...extraParams),
      data,
    });
  }

  static addUserBalance(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addUserBalance, ...extraParams),
      data,
    });
  }

  static getSearchFlowardUsers(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.searchForFlowardUsers, ...extraParams),
      params,
    });
  }

  static getUserLastOrders(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserLastOrders, ...extraParams),
    });
  }

  static getUserLastAddresses(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserLastAddresses, ...extraParams),
    });
  }

  static getUserTransactions(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUsersTransactions, ...extraParams),
      params: data,
    });
  }

  static getUserLatestTransactions(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserLatestTransactions, ...extraParams),
      params: data,
    });
  }

  // messages
  static adminAddNewMessage(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.adminMessage, ...extraParams),
      data,
    });
  }

  static adminGetMessagesList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.adminMessage, ...extraParams),
    });
  }

  static adminUpdateMessage(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.adminMessage, ...extraParams),
      data,
    });
  }

  // notifications messages
  static adminGetNotificationsMessages(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAdminNotificationsMessages, ...extraParams),
    });
  }

  static adminAddNotificationsMessages(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getAdminNotificationsMessages, ...extraParams),
      data,
    });
  }

  static adminUpdateNotificationsMessages(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getAdminNotificationsMessages, ...extraParams),
      data,
    });
  }

  // user profile
  static getUserProfileInfo(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserProfileInfo, ...extraParams),
    });
  }

  static addUserProfileInfo(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getUserProfileInfo, ...extraParams),
      data,
    });
  }

  static updateUserCorporate(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateUserCorporate, ...extraParams),
      data,
    });
  }

  static getUserActivitiesById(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserActivitiesById, ...extraParams),
      params,
    });
  }

  // user default addresses
  static getUserAddressesList(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserAddressesList, ...extraParams),
      params,
    });
  }

  static updateDefaultAddress(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateDefaultAddress, ...extraParams),
      data,
    });
  }

  // user activity
  static getUsersActivity(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUsersActivity, ...extraParams),
      params,
    });
  }

  static exportUsersActivity(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportUsersActivity, ...extraParams),
      params,
      responseType: 'blob',
    });
  }

  static addExistingUser(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addExistingUser),
      data,
    });
  }

  static removeUserRole(data) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.removeUserRole),
      data,
    });
  }
}
export default PermissionService;
