import { DDConfig } from './DDConfig';
import { datadogLogs } from '@datadog/browser-logs';

const isEnabled = DDConfig.hub.enabled;
const clientToken = DDConfig.hub.clientToken || '';

export const traceClientLogs = () => {
  if (isEnabled && clientToken) {
    datadogLogs.init({
      clientToken,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['error'],
      sampleRate: 100,
      service: 'HUB',
      env: DDConfig.hub.env,
    });
    datadogLogs.setLoggerGlobalContext({
      service: 'HUB',
      env: DDConfig.hub.env,
    });
  }
};

export const errorLogger = (msg, ctx) => {
  try {
    if (isEnabled && clientToken && window.DD_LOGS) {
      window.DD_LOGS.logger.error(`${msg} - ${ctx?.response?.data?.message || ''}`, {
        responseInfo: ctx?.response?.data || '',
        ctx,
      });
      // eslint-disable-next-line no-console
      console.error(msg, { responseInfo: ctx?.response?.data || '', ctx });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Logger not found : errorLogger: ', msg, ctx, error);
  }
};
