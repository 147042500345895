import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { dateFormatDDMM, dateFormat } from 'constant/Helpers';
// actions
import { setViewOrderData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const EditDateModal = ({
  orderId,
  isEditDateModalOpen,
  setIsEditDateModalOpen,
  deliveryDate,
  isDuplicateOrder,
  setIsDuplicateOrder,
  orderData,
  isReplaceOrder,
  setIsReplaceOrder,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date(deliveryDate));
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(-1);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [reasonsList, setReasonsList] = useState([]);
  const [reason, setReason] = useState('');
  const dispatch = useDispatch();
  const ref = useRef();

  const fetchTimeSlotReason = async () => {
    try {
      const res = await ViewOrderService.getTimeSlotReason();
      setReasonsList(res.data);
    } catch (err) {
      errorLogger('EditDateModal:fetchTimeSlotReason', err);
    }
  };

  const fetchTimeSlots = async (date) => {
    const params = {
      date: dateFormatDDMM(date),
      proId: -1,
      fulfillmenttype: 0,
      opsid: orderData.opsID,
      lines: orderData.productionLine,
      nwDelivery: orderData?.nwDelivery,
    };
    try {
      const res = await ViewOrderService.getDeliveryTimeSlot(params);
      setTimeSlotsData(res.data.avilableSlots);
    } catch (e) {
      errorLogger('EditDateModal:fetchTimeSlots', e);
      // console.log(e);
    }
  };

  useEffect(() => {
    if (!isDuplicateOrder && !isReplaceOrder) {
      fetchTimeSlotReason();
    }
    fetchTimeSlots(selectedDate);
  }, []);

  // component cleanup
  useEffect(() => {
    return () => {
      setIsDuplicateOrder(false);
      setIsReplaceOrder(false);
    };
  }, []);

  const handleChangeDate = async (date) => {
    fetchTimeSlots(date);
    setSelectedDate(date);
  };

  const handleSubmit = async () => {
    setDisableSaveBtn(true);
    try {
      if (isDuplicateOrder || isReplaceOrder) {
        const dataBody = {
          deliveryDate: dateFormat(selectedDate),
          deliveryTime: selectedTimeSlot,
          isreplaced: !!isReplaceOrder,
        };
        const res = await ViewOrderService.duplicateOrder(dataBody, orderId);
        toast.success(isDuplicateOrder ? 'Order has been duplicated' : 'Order has been replaced');
        setTimeout(() => {
          window.location.href = `/order/${res.data}`;
        }, 2000);
      } else {
        const dataBody = {
          deliveryTime: selectedTimeSlot,
          selectedDate: dateFormatDDMM(selectedDate),
          deliveryreason: reason,
        };
        const res = await ViewOrderService.updateDeliveryDate(dataBody, orderId);
        setTimeout(() => {
          setIsEditDateModalOpen((prev) => !prev);
          setIsDuplicateOrder(false);
          dispatch(setViewOrderData(orderId));
          setDisableSaveBtn(false);
          toast.success(res.data);
        }, 1500);
      }
    } catch (err) {
      errorLogger('EditDateModal:fetchTimeSlots', err);
      toast.error(err?.response?.data?.message);
      setIsEditDateModalOpen((prev) => !prev);
      setIsDuplicateOrder(false);
      setDisableSaveBtn(false);
    }
  };

  const handleTimeChange = (e) => {
    setSelectedTimeSlot(e.itemData.id);
  };

  const handleReasonChange = (e) => {
    setReason(e.itemData.id);
  };

  const timeSlotStatus = {
    0: 'Available',
    1: 'Busy',
    2: 'Not Available',
  };

  const onChangeFallBack = (data) => {
    ref.current.value = data.id;
    ref.current.text = data.name;
    setSelectedTimeSlot(data.id);
    ref.current.hidePopup();
  };

  const slotTemplate = (data) => {
    return (
      <span className="date-slot-span" id={data.id} onClick={() => onChangeFallBack(data)}>
        <span id={data.id}>{data.name}</span>
        <span id={data.id} className="ml-2">
          ({timeSlotStatus[data.status] ?? ''})
        </span>
      </span>
    );
  };

  const handleModalHeader = (isDuplicateOrder, isReplaceOrder) => {
    if (isDuplicateOrder) {
      return 'Duplicate Order';
    } else {
      if (isReplaceOrder) {
        return 'Replace Order';
      } else {
        return 'Select delivery date and time';
      }
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isEditDateModalOpen}
        toggle={() => {
          setIsEditDateModalOpen((prev) => !prev);
          setIsDuplicateOrder(false);
        }}
      >
        <ModalHeader> {handleModalHeader(isDuplicateOrder, isReplaceOrder)}</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Date
              </label>
              <DatePicker
                className="form-control digits"
                placeholderText="Selected delivery date"
                selected={selectedDate}
                onChange={handleChangeDate}
                dateFormat="dd/MM/yyyy"
              />
            </div>

            {isEditDateModalOpen && timeSlotsData.length > 0 && (
              <div className="form-group col-12 filter-select date-slot-change">
                <label htmlFor="date-time">Time</label>
                <AppErrorBoundary>
                  <CustomSelect
                    id="date-time"
                    forwardedRef={ref}
                    listData={timeSlotsData}
                    handleChange={handleTimeChange}
                    placeholder="Select a delivery time"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={selectedTimeSlot}
                    template={slotTemplate}
                    delayUpdate={true}
                  />
                </AppErrorBoundary>
              </div>
            )}
            {isEditDateModalOpen && !isDuplicateOrder && !isReplaceOrder && reasonsList.length > 0 && (
              <div className="form-group col-12 filter-select">
                <label htmlFor="date-reason">Reason</label>
                <AppErrorBoundary>
                  <CustomSelect
                    id="date-reason"
                    listData={reasonsList}
                    handleChange={handleReasonChange}
                    placeholder="Select a reason"
                    textPropName="title"
                    valuePropName="id"
                    selectedValue={reason}
                  />
                </AppErrorBoundary>
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              isDuplicateOrder || isReplaceOrder
                ? selectedTimeSlot === '' || disableSaveBtn
                : selectedTimeSlot === '' || disableSaveBtn || !reason
            }
            onClick={handleSubmit}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setIsEditDateModalOpen((prev) => !prev);
              setIsDuplicateOrder(false);
            }}
            color="light"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditDateModal;
