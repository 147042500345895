import React from 'react';
import SectionLoader from 'components/common/SectionLoader';
const SearchUserForm = ({
  handleSearch,
  searchKey,
  isLoaderOpen,
  searchResult,
  showTable,
  handleSelectUser,
  assignedFirstName,
  assignedLastName,
}) => {
  return (
    <form>
      <div className="form-group col-12">
        <input
          className="form-control assign-ticket-search"
          type="text"
          placeholder="Phone, name, email address"
          onChange={(e) => handleSearch(e.target.value)}
          defaultValue={searchKey}
        />

        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <>
            {searchResult.length > 0 && (
              <div
                style={{ height: showTable ? 'auto' : '', maxHeight: 220 }}
                className="table-responsive shopping-table text-center"
              >
                {showTable ? (
                  <table className="table table-bordernone create-order-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResult.map((item, i) => (
                        <tr
                          className="search-table-row assign-results-list"
                          onClick={() => handleSelectUser(item)}
                          key={i}
                        >
                          <td>{item.name}</td>
                          <td>{item.phone}</td>
                          <td>{item.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h5>{`${assignedFirstName} ${assignedLastName}`}</h5>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </form>
  );
};

export default SearchUserForm;
