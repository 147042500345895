import React from 'react';

const PreOrderWarning = ({
  isPreOrderPending = false,
  message = 'Note: This section will be available once pre order status is confirmed automatically',
}) => {
  return (
    <>
      {isPreOrderPending ? (
        <div className="col-12">
          <div className="card p-2 alert-warning">{message}</div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default PreOrderWarning;
