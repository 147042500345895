import { updateObject } from '../../utility';

const initialState = {
  calenderData: [],
  calenderDateRange: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CALENDER_DATA': {
      return updateObject(state, {
        calenderData: action.payload,
      });
    }
    case 'SET_CALENDER_DATE_RANGE': {
      return updateObject(state, {
        calenderDateRange: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
