import React, { useRef, useState } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { calcPercentage } from 'constant/Helpers';
import RightSideModal from 'components/shared/RightSideModal';
import LateOrdersDetailsDataGrid from './LateOrdersDetailsDataGrid';
import { toast } from 'react-toastify';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// api
import DeliveryReportService from 'services/reportsService/DeliveryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const LateOrdersDataGrid = ({ data, inputValues }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const [showLateOrderDetailsModule, setShowLateOrderDetailsModule] = useState(false);
  const [lateOrdersData, setLateOrdersData] = useState({});
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const showLateOrderDetails = async () => {
    setShowLateOrderDetailsModule(true);
    try {
      const res = await DeliveryReportService.getLateOrderDetails(inputValues);
      setLateOrdersData(res.data);
    } catch (err) {
      errorLogger('Late Orders Report Page Fetch Data Error:', err);
      toast.error('Failed to get data');
    }
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseInt(rowData.index, 10) + 1}</p>
      </div>
    );
  };

  const typeTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${rowData.late ? 'btn-light-success' : 'btn-light-danger'}`}
        onClick={rowData.late ? () => showLateOrderDetails() : null}
      >
        {rowData.late ? 'True' : 'False'}
      </button>
    );
  };

  const countTemplate = (rowData) => {
    return <p className="total-txt">{rowData.count}</p>;
  };

  const percentageTemplate = (rowData) => {
    return (
      <p className="total-txt">{rowData.count > 0 ? calcPercentage(data, rowData.count) : 0}</p>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <>
      {showLateOrderDetailsModule && (
        <RightSideModal
          isPaneOpen={showLateOrderDetailsModule}
          setIsPaneOpen={setShowLateOrderDetailsModule}
          direction="right"
        >
          <LateOrdersDetailsDataGrid data={lateOrdersData} />
        </RightSideModal>
      )}
      <div className="control-pane">
        <div className="card-header p-18">
          <h5 className="sz-14">Late Orders</h5>
        </div>
        <div className="control-section">
          <GridComponent
            dataSource={data}
            allowPaging={true}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            excelQueryCellInfo={excelQueryCellInfo}
            pdfQueryCellInfo={excelQueryCellInfo}
            ref={ref}
            toolbarClick={toolbarClick}
            showColumnChooser={true}
            rowHeight={60}
            allowTextWrap={true}
            pageSettings={{ pageSizes: true, pageSize: 20 }}
          >
            <ColumnsDirective>
              <ColumnDirective headerText="#" width={100} template={idTemplate} />
              <ColumnDirective
                headerText="Type"
                field="late"
                width={100}
                template={typeTemplate}
                customExcelTemplate={(rowData) => rowData.late}
                customAttributes={{ class: 'btn-div-container' }}
              />
              <ColumnDirective
                headerText="Count"
                field="count"
                width={100}
                template={countTemplate}
                customExcelTemplate={(rowData) => rowData.count}
              />
              <ColumnDirective
                headerText="Percentage"
                width={100}
                template={percentageTemplate}
                customExcelTemplate={(rowData) =>
                  rowData.count > 0 ? calcPercentage(data, rowData.count) : 0
                }
              />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
};

export default React.memo(LateOrdersDataGrid);
