import React from 'react';
import { useSelector } from 'react-redux';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { localStorageKeys } from 'constant/Enums';
// selectors
import { getUserRecentTransactionsById } from 'store/user/UserSelectors';

const TransactionsSection = () => {
  const transactionsList = useSelector((state) => getUserRecentTransactionsById({ state }));
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  return (
    <div className="card">
      <div className="card-header baseline-flex p-12">
        <span className="user-transactions-title">Recent Transactions</span>{' '}
        <i className="fa fa-exchange"></i>
      </div>
      <div className="recent-notification card-body">
        <div className="table-responsive shopping-table ">
          <table className="table table-bordernone">
            <thead>
              <tr>
                <th scope="col">Country</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>

            {transactionsList.length > 0 && (
              <tbody>
                {transactionsList.map((item, i) => (
                  <tr key={i}>
                    <td>{item.country}</td>
                    <td>
                      {item.balance && item.balance.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransactionsSection;
