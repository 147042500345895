import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class FloristsReportService {
  static getFloristsPerformanceReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getFloristsPerformanceReport, ...extraParams),
      params,
    });
  }
}
export default FloristsReportService;
