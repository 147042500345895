export const getTotalAttributesData = ({ state }) => state.attributes.attributesData;

export const getTotalAttributesPageSize = ({ state }) => state.attributes.pageSize;

export const getTotalAttributesCurrentPage = ({ state }) => state.attributes.currentPage;

export const getTotalAttributesRowCount = ({ state }) => state.attributes.rowCount;

export const getSubAttributesData = ({ state }) => state.attributes.subAttributes;

export const getAttributeByIdData = ({ state }) => state.attributes.attributeById;
