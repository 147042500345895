/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ activeTab, type, handleTabValue, children, validTabs }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(type === 'product' ? '0' : 0);

  useEffect(() => {
    if (type === 'product') {
      setCurrentActiveTab(activeTab.toString());
    } else {
      if (activeTab !== 0 && activeTab < children.length) {
        setCurrentActiveTab(activeTab);
      }
    }
  }, []);

  const onChangeTab = (index) => {
    setCurrentActiveTab(index);
    if (type === 'product') {
      handleTabValue(index);
    }
  };

  return (
    <div className="tabs">
      <div className="labels-wrapper">
        {children.map((el, i) => {
          if (type === 'product') {
            return (
              <div
                className={'tab-label' + (currentActiveTab === el.key ? ' active-tab-label' : '')}
                key={el.key}
                onClick={() => onChangeTab(el.key)}
              >
                {el.props.label}
                {el?.props?.label &&
                  validTabs[el.props.label] &&
                  !validTabs[el.props.label]?.isValid && (
                    <i className="fa fa-circle requird-asterisks red-dot" aria-hidden="true" />
                  )}
              </div>
            );
          } else {
            return (
              <div
                className={'tab-label' + (currentActiveTab === i ? ' active-tab-label' : '')}
                key={i}
                onClick={() => onChangeTab(i)}
              >
                {el.props.label}
              </div>
            );
          }
        })}
      </div>
      <div className="tabs-content-wrapper">
        {children.map((el, i) => {
          if (type === 'product') {
            return (
              <div
                className={
                  'tab-content' + (currentActiveTab === el.key ? ' active-tab-content' : '')
                }
                key={el.key}
              >
                {el.props.children}
              </div>
            );
          } else {
            return (
              <div
                className={'tab-content' + (currentActiveTab === i ? ' active-tab-content' : '')}
                key={i}
              >
                {el.props.children}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
};

export default Tabs;
