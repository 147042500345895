import { updateObject } from '../utility';

const initialState = {
  ordersData: {},
  currentPage: '',
  pageSize: '',
  rowCount: '',
  selectedOrderIds: [],
  queryParams: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_QUICK_PRINTING_DATA': {
      return updateObject(state, {
        ordersData: action.payload.data,
        currentPage: action.payload.currentPage,
        pageSize: action.payload.pageSize,
        rowCount: action.payload.rowCount,
      });
    }
    case 'SET_SELECTED_ORDERS_IDS': {
      return updateObject(state, {
        selectedOrderIds: action.payload,
      });
    }
    case 'SET_PRINTING_TABLE_PARAMS': {
      return updateObject(state, {
        queryParams: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
