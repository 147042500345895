import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// actions
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
// api
import PermissionService from 'services/PermissionService';
import CustomSelect from 'components/shared/CustomSelect';
// constants
import { localStorageKeys, userRoles } from 'constant/Enums';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const GroupModal = ({ isGroupModalOpen, setIsGroupModalOpen, setGroups }) => {
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const userOps = getFromLocalStorage(localStorageKeys.OPS_ID);
  const userCountry = getFromLocalStorage(localStorageKeys.COUNTRY_ID);
  const [groupName, setGroupName] = useState('');
  const [opsId, setOpsId] = useState(userOps);
  const [groupType, setGroupType] = useState(0);
  const [isCountryLevel, setIsCountryLevel] = useState(false);
  const [countryId, setCountryId] = useState(userCountry);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSignInSuccess());
  }, []);

  useEffect(() => {
    const countriesData = [];
    opsCenters.forEach(function (o) {
      if (
        countriesData.filter(function (c) {
          return c.id === o.country.id;
        }).length === 0
      ) {
        countriesData.push(o.country);
      }
    });
    setCountries(countriesData);
  }, [opsCenters]);

  const handleAddnewGroup = async () => {
    const bodyData = {
      Name: groupName,
      opsId: isCountryLevel ? countryId : opsId,
      accessType: isCountryLevel ? 1 : 0,
      GroupType: groupType,
    };
    try {
      await PermissionService.createNewGroup(bodyData);
      const res = await PermissionService.getPermissionGroups();
      setGroups(res.data);
      setIsGroupModalOpen((prev) => !prev);
    } catch (err) {
      errorLogger('GroupModal:handleAddnewGroup', err);
      setIsGroupModalOpen((prev) => !prev);
    }
  };

  const handleOpsChange = (e) => {
    setOpsId(e.itemData.id);
  };

  const handleCountryChange = (e) => {
    setCountryId(e.itemData.id);
  };

  const handleGroupTypeChange = (e) => {
    setGroupType(+e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isGroupModalOpen} toggle={() => setIsGroupModalOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsGroupModalOpen((prev) => !prev)}>Add New Group</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Name
              </label>
              <input
                onChange={(e) => setGroupName(e.target.value)}
                className="form-control"
                id="message-text"
              ></input>
            </div>
            <div className="row">
              <div className="form-group col-6  filter-select">
                <label className="d-block" htmlFor="groupType">
                  Group Type
                </label>
                <CustomSelect
                  id="groupType"
                  listData={userRoles}
                  handleChange={handleGroupTypeChange}
                  placeholder="Select a group type"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={groupType}
                />
              </div>
              <div className="form-group col-6">
                <label className="d-block" htmlFor="accessType">
                  Access Type
                </label>
                <label className="d-block" htmlFor="accessType">
                  <input
                    className="checkbox_animated"
                    id="accessType"
                    type="checkbox"
                    checked={isCountryLevel}
                    onChange={(e) => setIsCountryLevel((prev) => !prev)}
                  />
                  Country Level
                </label>
              </div>
            </div>
            {isCountryLevel ? (
              <div className="form-group filter-select">
                <label className="col-form-label" htmlFor="message-text">
                  Countries
                </label>
                {opsCenters !== '' && (
                  <CustomSelect
                    listData={countries}
                    handleChange={handleCountryChange}
                    placeholder="Select a country"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={countryId}
                  />
                )}
              </div>
            ) : (
              <div className="form-group filter-select">
                <label className="col-form-label" htmlFor="message-text">
                  Ops
                </label>
                {opsCenters !== '' && (
                  <CustomSelect
                    listData={[{ id: -1, nickName: 'Central' }].concat(opsCenters)}
                    handleChange={handleOpsChange}
                    placeholder="Select an ops"
                    textPropName="nickName"
                    valuePropName="id"
                    selectedValue={opsId}
                  />
                )}
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleAddnewGroup} disabled={!groupName} color="primary">
            Save
          </Button>
          <Button color="secondary" onClick={() => setIsGroupModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GroupModal;
