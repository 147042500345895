import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Components
import { Button } from 'reactstrap';
// Actions
import { setCoveredAreas, clearSelectedIds } from 'store/coveredAreas/CoveredAreasActions';
//  Selectors
import { getIsDisabled, getSelectedAreasIds } from 'store/coveredAreas/CoveredAreasSelectors';

const CoverageAreaFooter = ({ setIsModalOpen, fetchCoverageAreasList }) => {
  const isDisabled = useSelector((state) => getIsDisabled({ state })),
    selectedIds = useSelector((state) => getSelectedAreasIds({ state })),
    dispatch = useDispatch();

  const addCoverageAreas = async () => {
    const ids = selectedIds.flatMap((page) => page.areas);
    const res = await dispatch(setCoveredAreas(ids));
    if (res.status === 200) {
      setIsModalOpen(false);
      setTimeout(() => {
        fetchCoverageAreasList({ page: 1 });
      }, 1500);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    dispatch(clearSelectedIds());
  };

  return (
    <div className="d-flex justify-content-center">
      <Button color="primary mr-4" onClick={addCoverageAreas} disabled={isDisabled}>
        Add Coverage Areas
      </Button>
      <Button color="secondary" onClick={handleModalClose}>
        Cancel
      </Button>
    </div>
  );
};

export default CoverageAreaFooter;
