import { toast } from 'react-toastify';
// api
import ShorterLinkService from 'services/ShorterLinkService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const getShorterLinksData = (params) => async (dispatch) => {
  try {
    const res = await ShorterLinkService.getShorterLinksListData(params);
    dispatch({
      type: 'GET_SHORTER_LINKS_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ShorterLinkActions:getShorterLinksData', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get shorter links data!!');
  }
};

export const getGenerateShorterLink = (data) => async (dispatch) => {
  try {
    const res = await ShorterLinkService.getGenerateShorterLink(data);
    dispatch({
      type: 'GENERATED_SHORTER_LINK',
      payload: res.data.link,
    });
    toast.success('Shorter link generated');
    setTimeout(() => {
      dispatch(getShorterLinksData({ page: 1 }));
    }, 1500);
  } catch (err) {
    errorLogger('ShorterLinkActions:getGenerateShorterLink', err);
    toast.error(err?.response?.data?.message ?? 'Failed to generate shorter link');
  }
};
