import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
// middlewares
import thunk from 'redux-thunk';
import { socketMiddleware } from './middleWares/SocketMiddleWare';

const isDevelopment = process.env.NODE_ENV === 'development';
const middlewares = [];

// log redux data in development mode only
// if (isDevelopment) {
// const { logger } = require('redux-logger');
// middlewares.push(logger);
// }

/* Create a Redux store that holds the app state. */
const configureStore = (socketClient) => {
  const apply = applyMiddleware(thunk, socketMiddleware(socketClient), ...middlewares);

  return createStore(
    rootReducer,
    /* preloadedState, */
    // use redux dev tool in development only
    isDevelopment ? composeWithDevTools(apply) : apply
  );
};

export default configureStore;
