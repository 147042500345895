import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const TempPasswordModal = ({ isTempPasswordModalOpen, setIsTempPasswordModalOpen, userId }) => {
  const [tempPasswordValue, setTempPasswordValue] = useState('');

  const fetchData = async () => {
    try {
      const res = await PermissionService.userTempPassword(userId);
      setTempPasswordValue(res.data.temp);
      toast.success(res.data.message);
    } catch (err) {
      errorLogger('TempPasswordModal:generateTempPassword', err);
      toast.error(err?.response?.data?.message ?? 'Failed to generate temp password');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isTempPasswordModalOpen}
        toggle={() => setIsTempPasswordModalOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setIsTempPasswordModalOpen((prev) => !prev)}>
          Temporary Password
        </ModalHeader>
        <ModalBody>
          <form>
            <div
              onClick={() => {
                navigator.clipboard.writeText(tempPasswordValue);
                toast.success('Copied');
              }}
              className="form-group "
            >
              <label htmlFor="temp-pass">Temporary password has been generated</label>
              <input
                id="temp-pass"
                className="form-control bold-title"
                value={tempPasswordValue}
                disabled={true}
                type="text"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => setIsTempPasswordModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TempPasswordModal;
