import React from 'react';
import { useSelector } from 'react-redux';
// components
import ProductDetailsSection from 'components/products/components/ProductDetailsSection';
import ProductOptionsSection from 'components/products/components/ProductOptionsSection';
import AvailabilityRowTemplate from 'components/products/components/AvailabilityRowTemplate';
import ProductMasterInventorySection from 'components/products/components/ProductMasterInventorySection';
import ProductInventorySection from 'components/products/components/ProductInventorySection';
import ProductMediaSection from 'components/products/components/ProductMediaSection';
import ProductMasterAvailability from 'components/products/components/ProductMasterAvailability';
import ProductBasketSection from 'components/products/components/ProductBasketSection';
import ProductExcludeDaySection from 'components/products/components/ProductExcludeDaySection';
import ProductCategoriesSection from 'components/products/components/ProductCategoriesSection';
import ProductMCSection from 'components/products/components/ProductMCSection';
import ProductSubscriptionsSection from 'components/products/components/ProductSubscriptionsSection';
import ProductRankingSection from 'components/products/components/ProductRankingSection';
// selectors
import {
  getProductDetailsById,
  getProductAvailabilityById,
  getProductInventoryById,
  getProductMediaById,
  getProductMasterAvailabilityById,
  getProductOptionsById,
  getProductAllCategoriesData,
  getProductMasterInventoryById,
  getProductMainCategoriesData,
  getProductExcludedSlotsData,
  getProductAttributesFilterData,
  getProductMCData,
  getProductRanking,
} from 'store/products/ProductsSelectors';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';

const ProductTabContent = ({
  id,
  productId,
  isCustomizable,
  setIsCustomizable,
  validTabs,
  validateFields,
  validation,
  getMCAttributesFieldsData,
}) => {
  const productDetailsById = useSelector((state) => getProductDetailsById({ state }));
  const productRankingById = useSelector((state) => getProductRanking({ state }));
  const productOptionsById = useSelector((state) => getProductOptionsById({ state }));
  const productAvailabilityById = useSelector((state) => getProductAvailabilityById({ state }));
  const productInventoryById = useSelector((state) => getProductInventoryById({ state }));
  const productMediaById = useSelector((state) => getProductMediaById({ state }));
  const productMasterAvailabilityById = useSelector((state) =>
    getProductMasterAvailabilityById({ state })
  );
  const productMasterInventoryById = useSelector((state) =>
    getProductMasterInventoryById({ state })
  );
  const allCategoriesListData = useSelector((state) => getProductAllCategoriesData({ state }));
  const mainCategoriesData = useSelector((state) => getProductMainCategoriesData({ state }));
  const productExcludedSlots = useSelector((state) => getProductExcludedSlotsData({ state }));
  const productAttributesData = useSelector((state) => getProductAttributesFilterData({ state }));
  const productMCData = useSelector((state) => getProductMCData({ state }));

  switch (id) {
    case 0:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductDetailsSection
                productId={productId}
                productDetailsById={productDetailsById}
                productOptionsById={productOptionsById}
                isCustomizable={isCustomizable}
                setIsCustomizable={setIsCustomizable}
                isValid={validTabs?.Details?.isValid}
                notValidFields={validTabs?.Details?.notValidFields}
                validateDetailsTab={validation?.details}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 1:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductOptionsSection
                productId={productId}
                productOptionsById={productOptionsById}
                validateFields={validateFields}
                isValid={validTabs?.Options?.isValid}
                notValidFields={validTabs?.Options?.notValidFields}
                validateOptions={validation?.options}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 2:
      return (
        <div className="row">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductMediaSection
                productId={productId}
                productMediaById={productMediaById}
                productOptionsById={productOptionsById}
                validateMedia={validation?.media}
                isValid={validTabs?.Media?.isValid}
                notValidFields={validTabs?.Media?.notValidFields}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <AvailabilityRowTemplate
                productId={productId}
                availabilityData={productAvailabilityById}
                productOptionsById={productOptionsById}
                validateLocalAvailability={validation?.localAvailability}
                isValid={validTabs['Local Availability']?.isValid}
                notValidFields={validTabs['Local Availability']?.notValidFields}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 4:
      return (
        <div className="row">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductCategoriesSection
                productId={productId}
                productOptionsById={productOptionsById}
                allCategoriesListData={allCategoriesListData}
                productExcludedSlots={productExcludedSlots}
                mainCategoriesData={mainCategoriesData}
                isValid={validTabs?.Categories?.isValid}
                notValidFields={validTabs?.Categories?.notValidFields}
                validateCategories={validation?.categories}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 5:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductInventorySection productInventoryById={productInventoryById} />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 6:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductBasketSection productId={productId} productOptionsById={productOptionsById} />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 7:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductExcludeDaySection productId={productId} />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 8:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductMasterAvailability
                productId={productId}
                productMasterAvailabilityById={productMasterAvailabilityById}
                isValid={validTabs['Master Availability']?.isValid}
                notValidFields={validTabs['Master Availability']?.notValidFields}
                validateMA={validation?.ma}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 9:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductMasterInventorySection
                productId={productId}
                productMasterInventoryDataById={productMasterInventoryById}
                productOptionsById={productOptionsById}
                isValid={validTabs['Master Inventory']?.isValid}
                notValidFields={validTabs['Master Inventory']?.notValidFields}
                validateMI={validation?.mi}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 10:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductSubscriptionsSection
                productId={productId}
                productOptionsById={productOptionsById}
                productDetailsById={productDetailsById}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 11:
      return (
        <div className="row">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductMCSection
                productId={productId}
                productOptionsById={productOptionsById}
                productMCData={productMCData}
                productAttributesData={productAttributesData}
                isValid={validTabs?.MC?.isValid}
                notValidFields={validTabs?.MC?.notValidFields}
                validateMC={validation?.mc}
                getMCAttributesFieldsData={getMCAttributesFieldsData}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    case 12:
      return (
        <div className="row p-t-20">
          <div className="col-12">
            <AppErrorBoundary>
              <ProductRankingSection
                productId={productId}
                productRankingById={productRankingById}
                isValid={validTabs?.Ranking?.isValid}
                notValidFields={validTabs?.Ranking?.notValidFields}
                validateRanking={validation?.ranking}
              />
            </AppErrorBoundary>
          </div>
        </div>
      );
    default:
      break;
  }
};

export default ProductTabContent;
