import React, { useState } from 'react';
import LocalAvailabilityDataTable from 'components/masterCatalog/localAvailability/LocalAvailabilityDataTable';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';

const InactiveInstockPage = ({ location, history }) => {
  const [inputValues, setInputValues] = useState({
    catalogPage: 2,
    page: 1,
    opsId: getFromLocalStorage('opsId'),
    key: '',
    type: -1,
    stock: -1,
    productionline: 0,
    productdefination: -1,
    status: 1,
    qty: -1,
    categories: '',
    priceTo: '',
    originOps: 0,
    inventoryBarcode: '',
  });

  return (
    <div className="toolbar-table-style">
      <LocalAvailabilityDataTable
        inputValues={inputValues}
        setInputValues={setInputValues}
        location={location}
        history={history}
        title="Inactive-Instock"
      />
    </div>
  );
};

export default InactiveInstockPage;
