export const getAllMasterInventoryData = ({ state }) => state.masterInventory.masterInventoryData;

export const getMasterInventoryPageSize = ({ state }) => state.masterInventory.pageSize;
export const getMasterInventoryCurrentPage = ({ state }) => state.masterInventory.currentPage;
export const getMasterInventoryRowCount = ({ state }) => state.masterInventory.rowCount;

export const getSelectedMasterInventoryData = ({ state }) =>
  state.masterInventory.selectedMasterInventory;

export const getMasterInventoryChildrenData = ({ state }) =>
  state.masterInventory.masterInventoryChildren;
