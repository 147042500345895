import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
import DJHPagination from 'components/shared/Pagination';
import CoverageAreaModalDatagrid from './CoverageAreaModalDatagrid';
import CoverageAreaFooter from './CoverageAreaFooter';
// Actions
import { getCoveredAreasList } from 'store/coveredAreas/CoveredAreasActions';
// Selectors
import { getCoveredAreasListData, getLoadingState } from 'store/coveredAreas/CoveredAreasSelectors';

const CoverageAreaModal = ({ isModalOpen, setIsModalOpen, fetchCoverageAreasList }) => {
  const dispatch = useDispatch(),
    data = useSelector((state) => getCoveredAreasListData({ state })),
    isLoading = useSelector((state) => getLoadingState({ state }));

  useEffect(() => {
    dispatch(getCoveredAreasList(1));
  }, []);

  const onPageChange = (i) => {
    dispatch(getCoveredAreasList(i));
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal isOpen={isModalOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Add coverage area</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <SectionLoader />
        ) : (
          <CoverageAreaModalDatagrid data={data?.Data} page={data?.CurrentPage} />
        )}
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+data.CurrentPage}
          pageSize={+data.PageSize}
          itemsCount={+data.RowCount}
        />
        <CoverageAreaFooter
          setIsModalOpen={setIsModalOpen}
          fetchCoverageAreasList={fetchCoverageAreasList}
        />
      </ModalBody>
    </Modal>
  );
};

export default CoverageAreaModal;
