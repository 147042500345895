import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Resize,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { dateFormatDDMM } from 'constant/Helpers';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const DailySpendDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const queryCellInfo = (args) => {
    if (args.column.field === 'date') {
      const newVal = dateFormatDDMM(args.data.date);
      args.data.date = args.cell.innerText = newVal;
    }
  };

  return (
    <div className="control-pane m-b-30">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          allowSorting={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
          queryCellInfo={queryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="Ops" field="opsId" width={80} textAlign="Center" />
            <ColumnDirective headerText="Date" field="date" width={120} textAlign="Center" />
            <ColumnDirective
              headerText="New Buyers"
              field="newUsers"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Returned Buyers"
              field="returnedUsers"
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page, Resize, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DailySpendDataGrid);
