import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ColumnChooser,
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import MarginInput from '../components/MarginInput';
// constants
import { localStorageKeys } from 'constant/Enums';
// actions
import { setCostQtySum } from 'store/products/ProductsActions';
// selector
import { getProductOptionsById } from 'store/products/ProductsSelectors';

const ProductInventoryDataGrid = ({ productInventoryById }) => {
  const toolbarOptions = ['ColumnChooser', 'Search'];
  const productOptionsById = useSelector((state) => getProductOptionsById({ state }));
  const ref = useRef();
  const inputRef = useRef([]);
  const dispatch = useDispatch();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const imgTemplate = (rowData) => <img alt={rowData.name} src={rowData.image} className="w-70p" />;

  const costQtyTemplate = (rowData) => <span>{rowData.cost * rowData.requiredQty}</span>;

  const costQtySumTemplate = (rowData) => {
    let costQty = 0;
    rowData.result?.forEach((r) => {
      costQty += r.cost * r.requiredQty;
    });
    dispatch(setCostQtySum(costQty));
    return costQty && costQty.toFixed(opsCenter?.country?.decimalNumber ?? 2);
  };
  const marginTemplate = (rowData) => <MarginInput rowData={rowData} inputRef={inputRef} />;

  const finalPriceTemplate = (rowData) => (
    <span>
      {((rowData.cost * rowData.requiredQty) / (1 - rowData.margin / 100)).toFixed(
        opsCenter?.country?.decimalNumber ?? 3
      )}
    </span>
  );

  const totalPriceSumTemplate = (rowData) => {
    let totalPrice = 0;
    rowData?.result?.forEach((r) => {
      totalPrice += (r.cost * r.requiredQty) / (1 - r.margin / 100);
    });
    return totalPrice.toFixed(opsCenter?.country?.decimalNumber ?? 3);
  };
  return (
    <div className="control-pane">
      <div className="control-section">
        {productOptionsById.status !== undefined && (
          <GridComponent
            dataSource={productInventoryById}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            ref={ref}
            allowTextWrap={true}
            showColumnChooser={true}
            allowSorting={true}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText="Image"
                field="user"
                template={imgTemplate}
                textAlign="Center"
                width={120}
              />
              <ColumnDirective headerText="Name" field="name" textAlign="Center" width={100} />
              <ColumnDirective
                headerText="Barcode"
                field="barcode"
                textAlign="Center"
                width={100}
              />
              <ColumnDirective
                headerText="Available Qty"
                field="availableQty"
                textAlign="Center"
                width={100}
              />
              <ColumnDirective headerText="Cost" field="cost" textAlign="Center" width={80} />
              <ColumnDirective
                headerText="Required Qty"
                field="requiredQty"
                textAlign="Center"
                width={100}
              />
              <ColumnDirective
                headerText="Cost*Qty"
                template={costQtyTemplate}
                textAlign="Center"
                width={100}
              />
              <ColumnDirective
                headerText="Margin"
                field="margin"
                template={marginTemplate}
                textAlign="Center"
                width={100}
              />
              <ColumnDirective
                headerText="Total Price"
                field="totalPrice"
                template={finalPriceTemplate}
                textAlign="Center"
                width={100}
              />
            </ColumnsDirective>
            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field="cost"
                    type="Sum"
                    footerTemplate={(props) => (
                      <span className="font-weight-bold text-body">
                        {(+props.Sum).toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                      </span>
                    )}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    customAggregate={costQtySumTemplate}
                    type="Custom"
                    footerTemplate={(props) => (
                      <span className="font-weight-bold text-body">{props.Custom}</span>
                    )}
                  ></AggregateColumnDirective>
                  <AggregateColumnDirective
                    customAggregate={totalPriceSumTemplate}
                    field="totalPrice"
                    type="Custom"
                    footerTemplate={(props) => (
                      <span className="font-weight-bold text-body">{props.Custom}</span>
                    )}
                  ></AggregateColumnDirective>
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject services={[Page, Toolbar, ColumnChooser, Aggregate, Sort]} />
          </GridComponent>
        )}
      </div>
    </div>
  );
};

export default React.memo(ProductInventoryDataGrid);
