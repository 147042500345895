import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import OrdersReportDataGrid from 'components/reports/orders/OrdersReportDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  isoFormat,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import OrdersReportService from 'services/reportsService/OrdersReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const OrdersReportPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: isoFormat(new Date()),
    toDate: isoFormat(new Date()),
    datetype: 0,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await OrdersReportService.getOrdersReport(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Orders Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: '/report/OrdersReport',
      search: queryString,
    });
    fetchData(inputValues);
  };

  const handleExport = async () => {
    try {
      const res = await OrdersReportService.exportOrdersReport(inputValues);
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('Orders Report Page Export Data Error:', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        isoDate
        productType
        title="Orders Report"
        hasExport
        handleExport={handleExport}
      />
      <OrdersReportDataGrid data={data} inputValues={inputValues} />
    </div>
  );
};

export default OrdersReportPage;
