import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router';
import { errorLogger } from 'datadog/DDUtils';
// Constants
import { dateFormat, convertQueryParamsIntoObject } from 'constant/Helpers';
import { driversType } from 'constant/Enums';
// api
import DeliveryService from 'services/DeliveryService';
import CustomSelect from 'components/shared/CustomSelect';
const AddDriverModal = ({
  isDriverModalOpen,
  setIsDriverModalOpen,
  clickedDriver,
  defaultParams,
  fetchDriversData,
  vehiclesType,
}) => {
  const [name, setName] = useState(clickedDriver && clickedDriver.name ? clickedDriver.name : '');
  const [phone, setPhone] = useState(
    clickedDriver && clickedDriver.phone ? clickedDriver.phone : ''
  );
  const [password, setPassword] = useState(clickedDriver?.password ?? '');
  const [userType, setUserType] = useState(clickedDriver.type ?? null);
  const [vehicleType, setVehicleType] = useState(clickedDriver.vehicleType ?? null);
  const [email, setEmail] = useState(clickedDriver?.email || '');
  const [company, setCompany] = useState(
    clickedDriver && clickedDriver.company ? clickedDriver.company : ''
  );
  const [carNumber, setCarNumber] = useState(
    clickedDriver && clickedDriver.carNumber ? clickedDriver.carNumber : ''
  );
  const [petrolCode, setPetrolCode] = useState(
    clickedDriver && clickedDriver.petrolQRCode ? clickedDriver.petrolQRCode : ''
  );
  const [image, setImage] = useState(clickedDriver?.image ?? '');
  const [active, setActive] = useState(
    clickedDriver && clickedDriver.status ? clickedDriver.status : false
  );
  const [createdDate, setCreatedDate] = useState(
    clickedDriver && clickedDriver.createdDate ? new Date(clickedDriver.createdDate) : ''
  );
  const [endDate, setEndDate] = useState(
    clickedDriver && clickedDriver.endDate ? new Date(clickedDriver.endDate) : ''
  );
  const [reason, setReason] = useState(
    clickedDriver && clickedDriver.reasonForLeave ? clickedDriver.reasonForLeave : ''
  );
  const [disableBtn, setDisableBtn] = useState(false);
  const location = useLocation();
  const handleSubmit = async () => {
    if (userType === 0 && !/@floward.com$/.test(email)) {
      toast.error('Email should end with @floward.com');
      return;
    }
    let data = {
      Name: name,
      Password: password,
      CarNumber: carNumber,
      Phone: phone,
      Company: company,
      Status: active,
      Type: userType,
      vehicleType,
      Email: userType === 0 ? email : '',
      PetrolQRCode: petrolCode,
      Image: image,
      CreatedDate: createdDate ? dateFormat(createdDate) : '',
      EndDate: endDate ? dateFormat(endDate) : '',
      ReasonForLeave: reason,
      Id: clickedDriver ? clickedDriver.id : '',
    };
    let formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    setDisableBtn(true);
    try {
      if (clickedDriver) {
        await DeliveryService.updateDriverData(formData, clickedDriver.id);
        toast.success('Driver data updated successfully!');
      } else {
        await DeliveryService.addDriverData(formData);
        toast.success('Driver data added successfully!');
      }
      setTimeout(() => {
        if (location.search) {
          const queryObj = convertQueryParamsIntoObject(location.search);
          fetchDriversData(queryObj);
        } else {
          fetchDriversData(defaultParams);
        }
      }, 2000);
      setIsDriverModalOpen((prev) => !prev);
      setDisableBtn(false);
    } catch (e) {
      errorLogger('AddDriverModal:handleSubmit', e);
      toast.error(e?.response?.data?.message ?? 'Failed to add driver data!');
      setIsDriverModalOpen((prev) => !prev);
      setDisableBtn(false);
    }
  };
  const handleUserTypeChange = (e) => {
    setUserType(e.itemData.id);
  };
  const handleVehicleTypeChange = (e) => {
    setVehicleType(e.itemData.vehicleType);
  };

  const validateForm = (_name, _phone, _userType, _vehicleType, _email) => {
    if (
      _name === '' ||
      _phone === '' ||
      _userType === null ||
      (_userType === 0 && _email === '') ||
      _vehicleType === null
    ) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  useEffect(() => {
    validateForm(name, phone, userType, vehicleType, email);
  }, [name, phone, userType, vehicleType, email]);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isDriverModalOpen}
        toggle={() => setIsDriverModalOpen((prev) => !prev)}
        className="m-w-1000"
      >
        <ModalHeader toggle={() => setIsDriverModalOpen((prev) => !prev)}>Driver Data</ModalHeader>
        <ModalBody>
          <form className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="driver-name" className="col-form-label bold-title">
                Name <span className="red-color"> *</span>
              </label>
              <input
                id="driver-name"
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                type="text"
                value={name}
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="driver-phone" className="col-form-label bold-title">
                Phone Number <span className="red-color"> *</span>
              </label>
              <input
                id="driver-phone"
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                type="number"
                value={phone}
              />
            </div>
            <div className="col-md-6 form-group filter-select">
              <label htmlFor="driver-type" className="col-form-label bold-title">
                User Type <span className="red-color"> *</span>
              </label>
              <CustomSelect
                id="driver-type"
                listData={driversType}
                handleChange={handleUserTypeChange}
                placeholder="Select a type"
                textPropName="name"
                valuePropName="id"
                selectedValue={userType}
              />
            </div>
            <div className="col-md-6 form-group filter-select">
              <label htmlFor="vehicle-type" className="col-form-label bold-title">
                Vehicle Type <span className="red-color"> *</span>
              </label>
              <CustomSelect
                id="vehicle-type"
                listData={vehiclesType}
                handleChange={handleVehicleTypeChange}
                placeholder="Select a type"
                textPropName="vehicleName"
                valuePropName="vehicleType"
                selectedValue={vehicleType}
              />
            </div>
            {userType === 0 && (
              <div className="col-md-6 form-group">
                <label htmlFor="driver-email" className="col-form-label bold-title">
                  Email <span className="red-color"> *</span>
                </label>
                <input
                  id="driver-email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  type="text"
                  placeholder="Email"
                  value={email}
                />
              </div>
            )}
            <div className="col-md-6 form-group">
              <label htmlFor="driver-pass" className="col-form-label bold-title">
                Password
              </label>
              <input
                id="driver-pass"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                type="text"
                placeholder="password"
                value={password}
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="driver-company" className="col-form-label bold-title">
                Company Name
              </label>
              <input
                id="driver-company"
                onChange={(e) => setCompany(e.target.value)}
                className="form-control"
                type="text"
                value={company}
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="driver-car" className="col-form-label bold-title">
                Car Number
              </label>
              <input
                id="driver-car"
                onChange={(e) => setCarNumber(e.target.value)}
                className="form-control"
                type="text"
                value={carNumber}
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="driver-petrol" className="col-form-label bold-title">
                Petrol QR Code
              </label>
              <input
                id="driver-petrol"
                onChange={(e) => setPetrolCode(e.target.value)}
                className="form-control"
                type="text"
                value={petrolCode}
              />
            </div>
            <div className="col-md-6 form-group">
              <label className="col-form-label bold-title" htmlFor="driver-sdate">
                Start Date
              </label>
              <DatePicker
                id="driver-sdate"
                className="form-control digits"
                placeholderText="Selected start date"
                selected={createdDate}
                onChange={(date) => setCreatedDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            {clickedDriver && (
              <div className="col-md-6 form-group">
                <label className="col-form-label bold-title" htmlFor="driver-edate">
                  End Date
                </label>
                <DatePicker
                  id="driver-edate"
                  className="form-control digits"
                  placeholderText="Selected created date"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            )}
            <div className="col-md-6 form-group">
              <label htmlFor="driver-photo" className="col-form-label pt-0 bold-title">
                Upload driver photo:
              </label>
              <input
                id="driver-photo"
                onChange={(e) => setImage(e.target.files[0])}
                className="form-control"
                type="file"
                accept="image/*"
              />
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <img style={{ width: 100 }} id="image-preview" src={image} alt="" />
            </div>
            {clickedDriver && (
              <div className="col-12 form-group">
                <label className="col-form-label bold-title" htmlFor="driver-reason">
                  Reason for leave
                </label>
                <textarea
                  id="driver-reason"
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                  className="form-control"
                  type="text"
                />
              </div>
            )}
            {clickedDriver && (
              <div className="col-md-6 form-group">
                <input
                  className="checkbox_animated"
                  onChange={() => setActive((prev) => !prev)}
                  type="checkbox"
                  checked={active}
                ></input>
                <span className="col-form-label">Active</span>
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={disableBtn} onClick={handleSubmit} color="primary">
            Save
          </Button>
          <Button onClick={() => setIsDriverModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddDriverModal;
