import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { setDriversExpensesData } from 'store/delivery/driversExpenses/DriversExpensesActions';
// api
import ViewOrderService from 'services/ViewOrderService';
import DeliveryService from 'services/DeliveryService';

const AddDriverModal = ({ setIsAddDriverModalOpen, isAddDriverModalOpen, inputValues }) => {
  const [searchData, setSearchData] = useState('');
  const [driverId, setdriverId] = useState('');
  const [cost, setCost] = useState('');
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState(0);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const dispatch = useDispatch();

  const delayedHandleChange = debounce(async (keyword) => {
    try {
      const res = await ViewOrderService.getSearchDriver(keyword);
      setSearchData(res.data);
    } catch (e) {
      errorLogger('AddDriverModal:delayedHandleChange', e);
      toast.error('No results match this keyword');
    }
  }, 1000);

  const handleSearch = async (keyword) => {
    if (keyword) {
      delayedHandleChange(keyword);
    }
  };

  const selectDriver = (e, id) => {
    for (let i = 0; i <= searchData.length - 1; i++) {
      document.getElementById('check' + i).checked = false;
    }
    document.getElementById('check' + id).checked = true;
    setdriverId(e.target.value);
  };

  const handleAddDriver = async () => {
    const dataBody = {
      status,
      cost,
      note: comment,
    };
    setDisableSaveBtn(true);
    try {
      await DeliveryService.AddNewDriversExpenses(dataBody, driverId);
      setTimeout(() => {
        dispatch(setDriversExpensesData(inputValues));
        setDisableSaveBtn(false);
        setIsAddDriverModalOpen((prev) => !prev);
        toast.success('Driver Expenses has been added');
      }, 2000);
    } catch (err) {
      errorLogger('AddDriverModal:handleAddDriver', err);
      setDisableSaveBtn(false);
      setIsAddDriverModalOpen((prev) => !prev);
      toast.error(err?.response?.data?.message ?? 'Failed to add new driver!');
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isAddDriverModalOpen} toggle={() => setIsAddDriverModalOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsAddDriverModalOpen((prev) => !prev)}>
          Drivers Expenses
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label bold-title" htmlFor="message-text">
                Search for Drivers
              </label>
              <input
                onChange={(e) => handleSearch(e.target.value)}
                defaultValue=""
                className="form-control"
                id="message-text"
                placeholder="Name or phone number"
              ></input>

              <div className="ProfileCard u-cf">
                <div className="table-responsive shopping-table ">
                  <table className="table table-bordernone border-collapse-unset">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchData.length > 0 &&
                        searchData.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.name}</td>
                              <td>{item.phone}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  value={item.id}
                                  id={`check${i}`}
                                  onClick={(e) => selectDriver(e, i)}
                                ></input>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="form-group filter-select">
              <label className="bold-title" htmlFor="expense-status">
                Status
              </label>
              <CustomSelect
                id="expense-status"
                listData={[
                  { id: 0, name: 'Confirmed' },
                  { id: 1, name: 'Cancelled' },
                ]}
                handleChange={handleStatusChange}
                placeholder="Select status"
                textPropName="name"
                valuePropName="id"
                selectedValue={status}
              />
            </div>
            <div className="form-group">
              <label className="col-form-label bold-title" htmlFor="message-text">
                Cost
              </label>
              <input
                onChange={(e) => setCost(e.target.value)}
                value={cost}
                className="form-control"
                id="message-text"
                type="number"
              ></input>
            </div>
            <div className="form-group">
              <label className="col-form-label bold-title" htmlFor="message-text">
                Comment
              </label>
              <input
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                className="form-control"
                id="message-text"
              ></input>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={disableSaveBtn || !driverId || !cost}
            onClick={handleAddDriver}
            color="primary"
          >
            Save
          </Button>
          <Button color="light" onClick={() => setIsAddDriverModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddDriverModal;
