import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
// api
import MasterInventoryService from '../../services/MasterInventoryService';

export const getMasterInventoryList = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await MasterInventoryService.getMasterInventoryList(params);
    dispatch({
      type: 'GET_ALL_MASTER_INVENTORY_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    toast.error(err?.response?.data?.message ?? 'Failed to fetch inventory data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getMasterInventoryById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await MasterInventoryService.getMasterInventoryById(id);
    dispatch({
      type: 'GET_MASTER_INVENTORY_BY_ID_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    toast.error(err?.response?.data?.message ?? 'Failed to fetch inventory data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getMasterInventoryChildren = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await MasterInventoryService.getMasterInventoryChildren(params);
    dispatch({
      type: 'GET_MASTER_INVENTORY_CHILDREN',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    toast.error(err?.response?.data?.message ?? 'Failed to fetch inventory children!!');
    dispatch(setLoaderOpenFalse());
  }
};
