import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
// api
import ViewOrderService from 'services/ViewOrderService';
// datdog
import { errorLogger } from 'datadog/DDUtils';

const FavoriteFlowersModal = ({ isFavFlowersModalOpen, setIsFavFlowersModalOpen, cartId }) => {
  const [favFlowersData, setFavFlowersData] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);

  const fetchFavFlowers = async (id) => {
    setIsLoaderOpen(true);
    try {
      const res = await ViewOrderService.getFavoriteFlowersData(id);
      setFavFlowersData(res.data);
      setIsLoaderOpen(false);
    } catch (err) {
      errorLogger('FavoriteFlowersModal:fetchFavFlowers', err);
      toast.error('Failed to load favorite flowers');
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchFavFlowers(cartId);
  }, []);

  return (
    <div className="card-body btn-showcase favorite-flowers-modal">
      <Modal
        data-testid="showFavFlowersTestID"
        isOpen={isFavFlowersModalOpen}
        toggle={() => setIsFavFlowersModalOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setIsFavFlowersModalOpen((prev) => !prev)}>
          Favorite Flowers
        </ModalHeader>
        <ModalBody className="modal-body-wrapper">
          {isLoaderOpen ? (
            <SectionLoader />
          ) : (
            <table className="col-12">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {favFlowersData.map((item, i) => (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>
                      <img src={item.fullImage} alt="" className="fav-flower-img" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid="FavFlowersModalCancelTestID"
            color="light"
            onClick={() => setIsFavFlowersModalOpen((prev) => !prev)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FavoriteFlowersModal;
