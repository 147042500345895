import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { errorLogger } from 'datadog/DDUtils';
// api
import MasterCatalogService from 'services/MasterCatalogService';

export const setLocalAvailabilityList = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await MasterCatalogService.getLocalAvailabilityList(params);
    dispatch({
      type: 'LOCAL_AVAILABILITY_LIST_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    dispatch({
      type: 'LOCAL_AVAILABILITY_LIST_DATA',
      payload: {},
    });
    errorLogger('MasterCatalogActions:setLocalAvailabilityList', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get master catalog data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setSyncProduct = (id) => async (dispatch) => {
  dispatch(setIsProductSyncedFalse(id));
  try {
    await MasterCatalogService.getSyncMasterCaltalogProduct(id);
    const interval = setInterval(async () => {
      const res = await MasterCatalogService.getIndividualProductData(id);
      if (res.data.synced) {
        dispatch(setIsProductSyncedTrue(id));
        clearInterval(interval);
      }
    }, 3000);
  } catch (err) {
    errorLogger('MasterCatalogActions:setSyncProduct', err);
    toast.error(err?.response?.data?.message ?? 'Failed to sync product data!!');
  }
};

export const setIsProductSyncedFalse = (id) => ({
  type: 'SET_IS_SYNCED_PRODUCT_FALSE',
  payload: id,
});

export const setIsProductSyncedTrue = (id) => ({
  type: 'SET_IS_SYNCED_PRODUCT_TRUE',
  payload: id,
});

export const setSelectedProduct = (id) => ({
  type: 'SET_SELECTED_PRODUCT',
  payload: id,
});

export const setProductLog = (params, id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await MasterCatalogService.getProductLog(params, id);
    dispatch({
      type: 'SET_PRODUCT_LOG',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('MasterCatalogActions:setProductLog', err);
    toast.error('Failed to get product log data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setSelectedProductsItems = (params) => {
  return {
    type: 'SET_SELECTED_PRODUCTS_ITEMS',
    payload: params,
  };
};

export const setExportMasterCatalog = async (params) => {
  try {
    const res = await MasterCatalogService.getExportMasterCatalog({ ...params, page: -1 });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Catalog Items.xlsx');
    document.body.appendChild(link);
    link.click();
    toast.success(res?.data?.message ?? 'Catalog items exported successfully');
  } catch (err) {
    errorLogger('MasterCatalogActions:setExportMasterCatalog', err);
    toast.error('Failed to export excel file!!');
  }
};

export const setUncategorizedMerchandise = (params) => async (dispatch) => {
  try {
    const res = await MasterCatalogService.getUncategorizedMerch(params);
    dispatch({
      type: 'LOCAL_AVAILABILITY_LIST_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    dispatch({
      type: 'LOCAL_AVAILABILITY_LIST_DATA',
      payload: {},
    });
    errorLogger('MasterCatalogActions:setUncategorizedMerchandise', err);
    toast.error(err?.response?.data?.message ?? 'Failed to get uncategorized merchandise data');
    dispatch(setLoaderOpenFalse());
  }
};

export const setExportUncategorizedMerch = async (params) => {
  try {
    const res = await MasterCatalogService.getExportUncategorizedMerch({ ...params, page: -1 });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Uncategorized Merchandise.xlsx');
    document.body.appendChild(link);
    link.click();
    toast.success(res?.data?.message ?? 'Uncategorized merchandise exported successfully');
  } catch (err) {
    errorLogger('MasterCatalogActions:setExportUncategorizedMerch', err);
    toast.error('Failed to export excel file');
  }
};
