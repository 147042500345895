import React from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { XCircle } from 'react-feather';

const RightSideModal = ({ isPaneOpen, setIsPaneOpen, direction, children, width }) => {
  return (
    <SlidingPane
      className="slide-pane-style"
      hideHeader={true}
      isOpen={isPaneOpen}
      from={direction}
      onRequestClose={() => {
        setIsPaneOpen((prev) => !prev);
      }}
      width={width ?? '80%'}
    >
      <XCircle className="close-slide-pane" onClick={() => setIsPaneOpen(false)} />
      {children}
    </SlidingPane>
  );
};

export default RightSideModal;
