import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import CustomSelect from 'components/shared/CustomSelect';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// constant
import { HasPermission } from 'constant/Helpers';
import { productStatusList, productTypeList } from 'constant/Enums';
// actions
import {
  setUpdateProductOptionsStatus,
  setUpdateProductOptionsProductDef,
  setUpdateProductOptionsProductLine,
  setUpdateProductOptionsType,
  updateProductLabelByID,
  addProductLabel,
  deleteProductLabelByID,
} from 'store/products/ProductsActions';
// selectors
import {
  fetchProductLabels,
  getProductLabelById,
  getIsFetchingLabel,
} from 'store/products/ProductsSelectors';
import { getPermissionsList } from 'store/app/AppSelectors';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';

const ProductOptionsSection = ({
  productId,
  productOptionsById,
  validateFields,
  isValid,
  notValidFields,
  validateOptions,
}) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const [status, setStatus] = useState('');
  const [definition, setDefinition] = useState(-1);
  const [prodLine, setProdLine] = useState('');
  const [prodType, setProdType] = useState('');
  const fetchingLabel = useSelector((state) => getIsFetchingLabel({ state }));
  const labels = useSelector((state) => fetchProductLabels({ state }));
  const productLabel = useSelector((state) => getProductLabelById({ state }));
  const [prodLabel, setProdLabel] = useState([]);
  const [remountStatus, setRemountStatus] = useState(1);

  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    if (Object.keys(productOptionsById).length > 0) {
      setStatus(productOptionsById?.status ?? '');
      setDefinition(productOptionsById?.productDefinition ?? -1);
      setProdLine(productOptionsById?.productionLine ?? '');
      setProdType(productOptionsById?.type ?? '');
    }
  }, [productOptionsById]);

  const handleStatusChange = async (e) => {
    if (e.itemData.id !== status) {
      if (e?.itemData?.id === 1) {
        if (!(await validateFields())) {
          setRemountStatus(remountStatus + 1);
          return;
        }
      }
      setStatus(e.itemData.id);
      dispatch(setUpdateProductOptionsStatus(e.itemData.id, 1, productId));
    }
  };

  const handleProductDefinitionChange = (e) => {
    setDefinition(e.itemData.id);
    if (e.itemData.id !== definition) {
      dispatch(setUpdateProductOptionsProductDef(e.itemData.id, 0, productId));
    }
  };

  const handleProductionLineChange = (e) => {
    setProdLine(e.itemData.id);
    if (e.itemData.id !== prodLine) {
      dispatch(setUpdateProductOptionsProductLine(e.itemData.id, 2, productId));
    }
  };

  const handleProductTypeChange = async (e) => {
    setProdType(e.itemData.id);
    if (e.itemData.id !== prodType) {
      dispatch(
        setUpdateProductOptionsType(e.itemData.id, 3, productId, validateOptions, validateFields)
      );
    }
  };

  const handleAddProductLabelChange = (e) => {
    e.cancel = true;
    setProdLabel([e.itemData]);
    if (productLabel.length > 0) {
      dispatch(updateProductLabelByID(e.itemData, productId, productLabel[0].id));
    } else {
      dispatch(addProductLabel(e.itemData, productId));
    }
  };

  const handleDeleteProductLabelChange = (e) => {
    setProdLabel([]);
    dispatch(deleteProductLabelByID(productLabel[0].id));
  };

  const labelTemplate = (item) => {
    return (
      <div>
        <span>
          {item.title} {item.titleAr}
        </span>
      </div>
    );
  };

  const checkDisable = (statusVal) => {
    if (statusVal === 2) {
      return !HasPermission(permissionsList, 'edit_product', 'can_unarchive');
    } else if (status === 1) {
      return !HasPermission(permissionsList, 'edit_product', 'can_unpublish');
    } else if (status === 0) {
      const hasPermission =
        HasPermission(permissionsList, 'edit_product', 'can_publish') ||
        HasPermission(permissionsList, 'edit_product', 'can_archive');
      return !hasPermission;
    }
  };

  const checkIsOptionAllowed = (statusVal, currentStatus) => {
    switch (statusVal) {
      case 0: {
        const hasPermission =
          currentStatus === 1
            ? HasPermission(permissionsList, 'edit_product', 'can_unpublish')
            : HasPermission(permissionsList, 'edit_product', 'can_unarchive');
        return hasPermission;
      }
      case 1: {
        const hasPermission =
          currentStatus === 2 || currentStatus === 0
            ? HasPermission(permissionsList, 'edit_product', 'can_publish')
            : HasPermission(permissionsList, 'edit_product', 'can_unpublish');
        return hasPermission;
      }
      case 2: {
        const hasPermission =
          currentStatus === 1 || currentStatus === 0
            ? HasPermission(permissionsList, 'edit_product', 'can_archive')
            : HasPermission(permissionsList, 'edit_product', 'can_unarchive');
        return hasPermission;
      }
      default:
        return false;
    }
  };

  const formattedProductStatusList = productStatusList.reduce((acc, itm) => {
    if (itm.id === status) {
      return acc.concat(itm);
    }
    if (checkIsOptionAllowed(itm.id, status)) {
      return acc.concat(itm);
    }
    return acc;
  }, []);

  useEffect(() => {
    setProdLabel(productLabel);
  }, [productLabel]);

  return (
    <>
      <div className="form-group col-12">
        <form className="row">
          <div className="form-group col-12 col-md-3">
            <div className="form-group filter-select">
              <label className="bold-title" htmlFor="product-status">
                Status
                <span className="requird-asterisks ml-1">*</span>
              </label>
              <CustomSelect
                key={remountStatus}
                id="product-status"
                listData={formattedProductStatusList}
                handleChange={(e) => handleStatusChange(e)}
                placeholder="Select a product status"
                textPropName="name"
                valuePropName="id"
                selectedValue={status}
                disabled={checkDisable(status)}
              />
            </div>
          </div>
          <div className="form-group col-12 col-md-3">
            <div className="form-group filter-select">
              <label className="bold-title" htmlFor="product-definition">
                Product Definition
              </label>
              <CustomSelect
                id="product-definition"
                listData={[
                  { id: -1, name: 'Any' },
                  { id: 0, name: 'undefined' },
                  { id: 1, name: 'central' },
                  { id: 2, name: 'defined' },
                ]}
                handleChange={handleProductDefinitionChange}
                placeholder="Select a product definition"
                textPropName="name"
                valuePropName="id"
                selectedValue={definition}
                disabled={status === 1}
              />
            </div>
          </div>
          <div className="form-group col-12 col-md-3">
            <div className="form-group filter-select">
              <label className="bold-title" htmlFor="product-production-line">
                Production Line
              </label>
              <CustomSelect
                id="product-production-line"
                listData={[
                  { id: 0, name: 'Classic' },
                  { id: 1, name: 'Premium' },
                ]}
                handleChange={handleProductionLineChange}
                placeholder="Select a product definition"
                textPropName="name"
                valuePropName="id"
                selectedValue={prodLine}
                disabled={status === 1}
              />
            </div>
          </div>
          <div className="form-group col-12 col-md-3">
            <div className="form-group filter-select">
              <label className="bold-title" htmlFor="product-type">
                Product Type
                <span className="requird-asterisks ml-1">*</span>
              </label>
              <CustomSelect
                id="product-type"
                listData={productTypeList}
                handleChange={handleProductTypeChange}
                placeholder="Select a product type"
                textPropName="name"
                valuePropName="id"
                selectedValue={prodType}
                disabled={status === 1}
              />
              {!isValid && notValidFields?.includes('Type') && (
                <InlineErrorMessage message={'Please add "Type"'} />
              )}
            </div>
          </div>
          <div className="form-group col-12 col-md-3">
            <div className="form-group filter-select">
              <label className="bold-title" htmlFor="product-labels">
                Label
              </label>
              <div className="control-pane">
                <div id="multisection" className="control-section filter-select p-l-6">
                  <div id="multidefault">
                    <div className="control-styles">
                      <div>
                        <AppErrorBoundary>
                          {!fetchingLabel && (
                            <MultiSelectComponent
                              id="product-labels"
                              ref={ref}
                              select={(e) => handleAddProductLabelChange(e)}
                              removed={(e) => handleDeleteProductLabelChange(e)}
                              value={prodLabel.map((el) => el.title)}
                              dataSource={labels}
                              mode="Default"
                              fields={{ text: 'title', value: 'title' }}
                              placeholder="Select a label"
                              showDropDownIcon={true}
                              itemTemplate={labelTemplate}
                              enabled={!(status === 1)}
                            />
                          )}
                        </AppErrorBoundary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default React.memo(ProductOptionsSection);
