import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
// api
import FloristCommunityService from '../../services/FloristCommunityService';
// selectors
import { getFloristCommunityTransactionComments } from './FloristCommunitySelectors';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const setFloristsDataList = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await FloristCommunityService.getFloristCommunityList(params);
    dispatch({
      type: 'GET_FLORIST_COMMUNITY_LIST',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Florist Community Store Fetch Data Error:', err);
    toast.error('Failed to fetch florist community list!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setSelectedFloristDesign = (data) => async (dispatch) => {
  dispatch({
    type: 'SET_FLORIST_COMMUNITY_DESIGN',
    payload: data,
  });
};

export const getFloristDesignById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await FloristCommunityService.getFloristCommunityDesignByid(id);
    dispatch({
      type: 'GET_FLORIST_COMMUNITY_DESIGN',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Florist Community Store Fetch Design Data By ID Error:', err);
    toast.error('Failed to fetch florist community design!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getFloristUserById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await FloristCommunityService.getFloristCommunityUserByid(id);
    dispatch({
      type: 'GET_FLORIST_COMMUNITY_USER',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Florist Community Store Fetch User Data By ID Error:', err);
    toast.error('Failed to fetch florist community user!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getFloristActionedById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await FloristCommunityService.getFloristCommunityUserByid(id);
    dispatch({
      type: 'GET_FLORIST_COMMUNITY_ACTIONED',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Florist Community Store Fetch Actioned User Data By ID Error:', err);
    toast.error('Failed to fetch florist community user!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setFloristsTransactionDataList = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await FloristCommunityService.getFloristCommunityTransactionList(params);
    dispatch({
      type: 'GET_FLORIST_COMMUNITY_TRANSACTIONS',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Florist Community Store Fetch Transaction List Data Error:', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch florist community transactions!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setFloristsTransactionDetailsData = (transactionId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await FloristCommunityService.getTransactionById(transactionId);
    dispatch({
      type: 'SET_FLORIST_COMMUNITY_TRANSACTIONS_DETAILS_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Florist Community Store Fetch Transaction Data By ID Error:', err);
    dispatch(setLoaderOpenFalse());
    toast.error('Failed to get ticket details');
  }
};

export const getFloristsTransactionComments = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await FloristCommunityService.getTransactionComments(id);
    dispatch({
      type: 'GET_FLORIST_COMMUNITY_COMMENTS',
      payload: res.data.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Florist Community Store Fetch Transaction Comments Data Error:', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch florist community comments!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setFloristsTransactionComments =
  (data, transactionId) => async (dispatch, getState) => {
    dispatch(setLoaderOpenTrue());
    const state = getState();
    const oldComments = getFloristCommunityTransactionComments({ state });
    try {
      const res = await FloristCommunityService.postTransactionComment(data, transactionId);
      dispatch({
        type: 'SET_FLORIST_COMMUNITY_COMMENTS',
        payload: oldComments.concat(data),
      });
      dispatch(setLoaderOpenFalse());
      dispatch(getFloristsTransactionComments());
      toast.success(res?.data?.message ?? 'Posting comment was successfully');
    } catch (err) {
      errorLogger('Florist Community Store Post Transaction Comment Error:', err);
      toast.error(err?.response?.data?.message ?? 'Failed to post comment!!');
      dispatch(setLoaderOpenFalse());
    }
  };
