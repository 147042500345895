import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const RequestOtpModal = ({ isOtpModalOpen, setIsOtpModalOpen, userId }) => {
  const [otpValue, setOtpValue] = useState('');

  const fetchData = async () => {
    try {
      const res = await PermissionService.requestOtp(userId);
      setOtpValue(res.data.otp);
      toast.success(res.data.message);
    } catch (err) {
      errorLogger('RequestOtpModal:fetchOtpData', err);
      toast.error(err?.response?.data?.message ?? 'Failed to get OTP');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isOtpModalOpen} toggle={() => setIsOtpModalOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsOtpModalOpen((prev) => !prev)}>OTP</ModalHeader>
        <ModalBody>
          <form>
            <div
              onClick={() => {
                navigator.clipboard.writeText(otpValue);
                toast.success('Copied');
              }}
              className="form-group "
            >
              <label htmlFor="opt-inp">OTP has been generated</label>
              <input
                id="opt-inp"
                className="form-control bold-title"
                value={otpValue}
                disabled={true}
                type="text"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => setIsOtpModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RequestOtpModal;
