import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { errorLogger } from 'datadog/DDUtils';
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { toast } from 'react-toastify';
import RestrictedSection from 'components/shared/RestrictedSection';
// modals
import CreateProductModal from '../modals/CreateProductModal';
import UploadRankingFileModal from '../modals/UploadRankingFileModal';
import BulkCategoriesModal from '../modals/BulkCategoriesModal';
import BulkMerchandisingCategoriesModal from '../modals/BulkMerchandisingCategoriesModal';
import {
  setUncategorizedMerchandise,
  setLocalAvailabilityList,
} from 'store/masterCatalog/MasterCatalogActions';
// selectors
import {
  getSelectedProductsItems,
  getLocalAvailabilityData,
} from 'store/masterCatalog/MasterCatalogSelectors';
// api
import MasterCatalogService from 'services/MasterCatalogService';
// constants
import { productStatusList, productTypeList } from 'constant/Enums';

const CatalogActions = ({
  inputValues,
  totalCatalog,
  handleExportCatalog,
  isUncategorizedMerch,
}) => {
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isUploadRankingModalOpen, setIsUploadRankingModalOpen] = useState(false);
  const [isBulkCategoryModalOpen, setIsBulkCategoryModalOpen] = useState(false);
  const [isBulkMCModalOpen, setIsBulkMCModalOpen] = useState(false);
  const [isShowStatusItems, setIsShowStatusItems] = useState(false);
  const [isShowTypeItems, setIsShowTypeItems] = useState(false);
  const [enableBulk, setEnableBulk] = useState(true);
  const selectedProducts = useSelector((state) => getSelectedProductsItems({ state }));
  const { data } = useSelector((state) => getLocalAvailabilityData({ state }));
  const dispatch = useDispatch();

  useEffect(() => {
    let isAllDraft = true;
    selectedProducts.forEach((selectedProduct) => {
      const product = data.find((product) => product.id === selectedProduct);
      if (product?.productStatus !== 0) {
        isAllDraft = false;
      }
    });
    setEnableBulk(isAllDraft);
  }, [selectedProducts]);

  const handleStatusClick = async (id) => {
    if (selectedProducts.length > 0) {
      const body = { ProductsIds: selectedProducts, Status: id };
      try {
        const res = await MasterCatalogService.bulkStatusUpdate(body);
        toast.success(res?.data?.message ?? 'Merchandising categories were updated successfully');
        if (isUncategorizedMerch) {
          setTimeout(() => {
            dispatch(setUncategorizedMerchandise(inputValues));
          }, 1500);
        } else {
          setTimeout(() => {
            dispatch(setLocalAvailabilityList(inputValues));
          }, 1500);
        }
      } catch (err) {
        errorLogger('CatalogActions:handleStatusClick', err);
        toast.error(err?.response?.data?.message ?? 'Something went wrong');
      }
    } else {
      toast.error('Please select product');
    }
    setIsShowStatusItems(false);
  };

  const handleTypeClick = async (id) => {
    if (selectedProducts.length > 0) {
      const body = { prodIds: selectedProducts, type: id };
      try {
        const res = await MasterCatalogService.bulkTypeUpdate(body);
        toast.success(res?.data?.message ?? 'Merchandising categories were updated successfully');
        if (isUncategorizedMerch) {
          setTimeout(() => {
            dispatch(setUncategorizedMerchandise(inputValues));
          }, 1500);
        } else {
          setTimeout(() => {
            dispatch(setLocalAvailabilityList(inputValues));
          }, 1500);
        }
      } catch (err) {
        errorLogger('CatalogActions:handleTypeClick', err);
        toast.error(err?.response?.data?.message ?? 'Something went wrong');
      }
    } else {
      toast.error('Please select product');
    }
    setIsShowTypeItems(false);
  };

  return (
    <>
      {isAddProductModalOpen && (
        <CreateProductModal
          isAddProductModalOpen={isAddProductModalOpen}
          setIsAddProductModalOpen={setIsAddProductModalOpen}
          inputValues={inputValues}
        />
      )}
      {isUploadRankingModalOpen && (
        <UploadRankingFileModal
          isUploadRankingModalOpen={isUploadRankingModalOpen}
          setIsUploadRankingModalOpen={setIsUploadRankingModalOpen}
        />
      )}
      {isBulkCategoryModalOpen && (
        <BulkCategoriesModal
          isBulkCategoryModalOpen={isBulkCategoryModalOpen}
          setIsBulkCategoryModalOpen={setIsBulkCategoryModalOpen}
        />
      )}
      {isBulkMCModalOpen && (
        <BulkMerchandisingCategoriesModal
          isBulkMCModalOpen={isBulkMCModalOpen}
          setIsBulkMCModalOpen={setIsBulkMCModalOpen}
          inputValues={inputValues}
          isUncategorizedMerch
        />
      )}
      <div className="card">
        <div className="card-body btn-showcase">
          {!totalCatalog && (
            <>
              <RestrictedSection page="master_catalog" action="create_master_catalog">
                <button
                  onClick={() => setIsAddProductModalOpen((prev) => !prev)}
                  type="button"
                  className="btn btn-success"
                >
                  <i className="fa fa-plus"></i> Create Product
                </button>
              </RestrictedSection>
              <RestrictedSection page="master_catalog" action="upload_ranking_file">
                <button
                  onClick={() => setIsUploadRankingModalOpen((prev) => !prev)}
                  type="button"
                  className="btn btn-success"
                >
                  <i className="fa fa-file"></i> Upload Ranking file
                </button>
              </RestrictedSection>
            </>
          )}
          <RestrictedSection page="master_catalog" action="master_catalog_bulk_action">
            <ButtonGroup className="product-actions-dropdown">
              <UncontrolledDropdown>
                <DropdownToggle color="primary" caret className="b-r-0">
                  Bulk Actions
                </DropdownToggle>
                <DropdownMenu className="product-actions-dropdown-menu">
                  {isShowStatusItems && (
                    <div className="horizontal-wrapper product-status-items">
                      {productStatusList.map((item) => (
                        <DropdownItem
                          className="status-item"
                          key={item.id}
                          onClick={() => handleStatusClick(item.id)}
                          onMouseEnter={() => setIsShowStatusItems(true)}
                          onMouseLeave={() => setIsShowStatusItems(false)}
                        >
                          {item.name}{' '}
                        </DropdownItem>
                      ))}
                    </div>
                  )}
                  <DropdownItem
                    className="product-dropdown-item"
                    onMouseEnter={() => setIsShowStatusItems(true)}
                    onMouseLeave={() => setIsShowStatusItems(false)}
                  >
                    <span>Status</span> <i className="fa fa-caret-right"></i>
                  </DropdownItem>

                  {isShowTypeItems && (
                    <div className="horizontal-wrapper product-status-items">
                      {productTypeList.map((item) => (
                        <DropdownItem
                          className="status-item"
                          key={item.id}
                          onClick={() => handleTypeClick(item.id)}
                          onMouseEnter={() => setIsShowTypeItems(true)}
                          onMouseLeave={() => setIsShowTypeItems(false)}
                        >
                          {item.name}{' '}
                        </DropdownItem>
                      ))}
                    </div>
                  )}
                  <DropdownItem
                    className="product-dropdown-item"
                    onMouseEnter={() => setIsShowTypeItems(true)}
                    onMouseLeave={() => setIsShowTypeItems(false)}
                    disabled={!enableBulk}
                  >
                    <span>Type</span> <i className="fa fa-caret-right"></i>
                  </DropdownItem>
                  <DropdownItem
                    className="product-dropdown-item"
                    onClick={() =>
                      selectedProducts.length > 0
                        ? setIsBulkCategoryModalOpen((prev) => !prev)
                        : toast.error('Please select product')
                    }
                    disabled={!enableBulk}
                  >
                    <span>Categories and Occasions</span>
                  </DropdownItem>
                  <DropdownItem
                    className="product-dropdown-item"
                    onClick={() =>
                      selectedProducts.length > 0
                        ? setIsBulkMCModalOpen((prev) => !prev)
                        : toast.error('Please select product')
                    }
                    disabled={!enableBulk}
                  >
                    <span>Merchandising Categories</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ButtonGroup>
          </RestrictedSection>
          <RestrictedSection page="master_catalog" action="download_catalog">
            <button type="button" className="btn btn-secondary" onClick={handleExportCatalog}>
              <i className="fa fa-download"></i> Export
            </button>
          </RestrictedSection>
        </div>
      </div>
    </>
  );
};

export default CatalogActions;
