import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Resize,
  Sort,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { dateFormatDDMM } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const DailySpendDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane m-b-30">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          allowSorting={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Date"
              template={(rowData) => rowData.orderdate && dateFormatDDMM(rowData.orderdate)}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.orderdate && dateFormatDDMM(rowData.orderdate)
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="OpsID"
              field="opsid"
              width={80}
              customExcelTemplate={(rowData) => rowData.opsid}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Country"
              field="country"
              width={100}
              customExcelTemplate={(rowData) => rowData.country}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Order"
              field="ordercount"
              width={80}
              customExcelTemplate={(rowData) => rowData.ordercount}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Orders (Local currency)"
              field="ordertotalSales"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Orders (in USD)"
              field="ordertotalSalesUsd"
              template={(rowData) => (
                <span>
                  {rowData.ordertotalSalesUsd != null &&
                    `${rowData.ordertotalSalesUsd.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`}
                </span>
              )}
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page, Resize, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DailySpendDataGrid);
