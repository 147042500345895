import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import { getFromLocalStorage, addToLocalStorage, token } from './localStorageService/LocalStorage';
// components
import Header from './components/common/header-component/header';
import Sidebar from './components/common/sidebar-component/sidebar';
import RightSidebar from './components/common/right-sidebar';
import { ToastContainer } from 'react-toastify';
import Loader from './components/common/loader';
import { registerationRoutes, restrictedRoutes, privateRoutes } from './routing/RoutesConfig';
import RestrictedRoute from './routing/RestrictedRoute';
import RegistrationRoute from './routing/RegistrationRoute';
import PrivateRoute from './routing/PrivateRoute';
import Footer from './components/common/footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// selectors
import { getIsLoggedIn } from './store/app/AppSelectors';
// urls
import { getHomePageUrl, getLoginPageUrl } from './constant/AppUrls';
// constants
import { localStorageKeys } from './constant/Enums';
// api
import AuthService from './services/AuthService';
import AppErrorBoundary from './components/errorBoundary/AppErrorBoundary';
// DataDog
import { traceClientLogs } from './datadog/DDUtils';

traceClientLogs();

const theme = createTheme();

function RequiredAuth({ isLoggedIn, homePath, redirectTo }) {
  return isLoggedIn ? <Navigate to={homePath} /> : <Navigate to={redirectTo} />;
}

const App = () => {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));
  const opsId = getFromLocalStorage(localStorageKeys.OPS_ID);
  const countryId = getFromLocalStorage(localStorageKeys.COUNTRY_ID);
  const dispatch = useDispatch();

  const fetchPermissions = async (token) => {
    const params = {
      opsid: opsId,
      countryId,
    };
    const userPermissions = await AuthService.getUserPermissions(params, token);
    dispatch({
      type: 'SET_USER_PERMISSIONS',
      payload: userPermissions.data,
    });
    addToLocalStorage(localStorageKeys.PERMISSIONS, userPermissions.data);
  };

  useEffect(() => {
    if (localStorage.getItem(localStorageKeys.ACCESS_TOKEN)) {
      fetchPermissions(token);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch({
        type: 'DECODED_TOKEN',
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const source = urlParams.get('source');
    const hubSource = urlParams.get('hub-source');
    if (source && isLoggedIn) {
      window.location.href = `${source}?token=${getFromLocalStorage(
        localStorageKeys.ACCESS_TOKEN
      )}&opsId=${opsId}`;
    }
    if (hubSource && isLoggedIn) {
      window.location.href = hubSource;
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App" data-testid="AppTestID">
          <div className="page-wrapper page-body">
            <div className="page-body-wrapper">
              {isLoggedIn && (
                <>
                  <AppErrorBoundary>
                    <Header />
                  </AppErrorBoundary>
                  <AppErrorBoundary>
                    <RightSidebar />
                  </AppErrorBoundary>
                  <AppErrorBoundary>
                    <Sidebar />
                  </AppErrorBoundary>
                </>
              )}
              <div className={isLoggedIn ? 'page-body p-t-40' : 'p-t-40'}>
                <Loader />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <RequiredAuth
                        isLoggedIn={isLoggedIn}
                        homePath={getHomePageUrl()}
                        redirectTo={getLoginPageUrl()}
                      />
                    }
                  />
                  {registerationRoutes.map((route, i) => (
                    <Route
                      key={i}
                      path={route.path}
                      element={
                        <RegistrationRoute path={route.path} component={route.component} key={i} />
                      }
                    />
                  ))}
                  {privateRoutes.map((route, i) => (
                    <Route
                      key={i}
                      path={route.path}
                      element={
                        <PrivateRoute
                          path={route.path}
                          component={route.component}
                          exact={route.exact}
                          key={i}
                        />
                      }
                    />
                  ))}
                  {restrictedRoutes.map((route, i) => (
                    <Route
                      key={i}
                      path={route.path}
                      element={
                        <RestrictedRoute
                          path={route.path}
                          component={route.component}
                          key={i}
                          exact={route.exact}
                          page={route.permissions}
                        />
                      }
                    />
                  ))}
                </Routes>
                <div className="p-t-40 m-t-10">{isLoggedIn && <Footer />}</div>
              </div>
            </div>
          </div>
          <ToastContainer theme="colored" icon={false} />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
