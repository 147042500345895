import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Sort,
  Aggregate,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { dateFormatDDMM } from 'constant/Helpers';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const CorporateReportDataGrid = ({ data, type }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const currencyFormat = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

  // custom columns
  const dateTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column">
        <p>{dateFormatDDMM(rowData.date)}</p>
      </div>
    );
  };

  const subscriptionCostTemplate = (rowData) => {
    return <p>{currencyFormat(rowData.subscriptionCost)}</p>;
  };

  const subscriptionSalesTemplate = (rowData) => {
    return <p>{currencyFormat(rowData.subscriptionSales)}</p>;
  };

  const eventCostTemplate = (rowData) => {
    return <p>{currencyFormat(rowData.eventCost)}</p>;
  };

  const eventSalesTemplate = (rowData) => {
    return <p>{currencyFormat(rowData.eventSales)}</p>;
  };

  const demandCostTemplate = (rowData) => {
    return <p>{currencyFormat(rowData.demandCost)}</p>;
  };

  const demandSalesTemplate = (rowData) => {
    return <p>{currencyFormat(rowData.demandSales)}</p>;
  };

  const totalCostTemplate = (rowData) => {
    return <p>{currencyFormat(rowData.totalCost)}</p>;
  };

  const totalSalesTemplate = (rowData) => {
    return <p>{currencyFormat(rowData.totalSales)}</p>;
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
          allowSorting={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="#" field="serial" width={50} textAlign="Center" />
            {type === 'customer' ? (
              <ColumnDirective headerText="Name" field="userName" width={100} textAlign="Center" />
            ) : (
              <ColumnDirective
                headerText="Date"
                field="date"
                width={100}
                template={dateTemplate}
                customExcelTemplate={(rowData) => rowData.date}
                textAlign="Center"
              />
            )}
            <ColumnDirective
              headerText="Subsc."
              field="subscriptionTotal"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Subsc. Cost"
              field="subscriptionCost"
              width={100}
              textAlign="Center"
              template={subscriptionCostTemplate}
            />
            <ColumnDirective
              headerText="Subsc. Sales"
              field="subscriptionSales"
              width={100}
              textAlign="Center"
              template={subscriptionSalesTemplate}
            />
            <ColumnDirective headerText="Event" field="eventTotal" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Event Cost"
              field="eventCost"
              width={100}
              textAlign="Center"
              template={eventCostTemplate}
            />
            <ColumnDirective
              headerText="Event Sales"
              field="eventSales"
              width={100}
              textAlign="Center"
              template={eventSalesTemplate}
            />
            <ColumnDirective
              headerText="On-demand"
              field="demandTotal"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="On-demand Cost"
              field="demandCost"
              width={100}
              textAlign="Center"
              template={demandCostTemplate}
            />
            <ColumnDirective
              headerText="On-demand Sales"
              field="demandSales"
              width={100}
              textAlign="Center"
              template={demandSalesTemplate}
            />
            <ColumnDirective headerText="Total" field="count" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Total Cost"
              field="totalCost"
              width={100}
              textAlign="Center"
              template={totalCostTemplate}
            />
            <ColumnDirective
              headerText="Total Sales"
              field="totalSales"
              width={100}
              textAlign="Center"
              template={totalSalesTemplate}
            />
          </ColumnsDirective>
          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective
                  field="subscriptionTotal"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="subscriptionCost"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="subscriptionSales"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="eventTotal"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="eventCost"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="eventSales"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="demandTotal"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="demandCost"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="demandSales"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="count"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="totalCost"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
                <AggregateColumnDirective
                  field="totalSales"
                  type="Sum"
                  footerTemplate={(props) => <span>{props.Sum}</span>}
                />
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Sort, Aggregate]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(CorporateReportDataGrid);
