import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import AreasDataTable from 'components/delivery/areas/AreasDataTable';
import { convertQueryParamsIntoObject } from 'constant/Helpers';

const AreasPage = () => {
  const [inputValues, setInputValues] = useState({
    page: 1,
  });
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, [location.search]);

  return (
    <div>
      <AreasDataTable inputValues={inputValues} setInputValues={setInputValues} />
    </div>
  );
};

export default AreasPage;
