import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
import DatePicker from 'react-datepicker';
import { dateFormat } from 'constant/Helpers';
// actions
import { setUserWalletBalanceById, setUserRecentTransactionsById } from 'store/user/UserActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const AddBalanceModal = ({ isAddBalanceModalOpen, setIsAddBalanceModalOpen, userId }) => {
  const [amount, setAmount] = useState(1);
  const [remarks, setRemarks] = useState('');
  const [type, setType] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const dispatch = useDispatch();

  const handleAddBalance = async () => {
    const params = {
      userId,
      amount,
      type,
      expiryDate: selectedDate ? dateFormat(selectedDate) : null,
      remarks,
    };
    setDisableBtn(true);
    try {
      await PermissionService.addUserBalance(params);
      setTimeout(async () => {
        try {
          dispatch(setUserWalletBalanceById(userId));
          dispatch(setUserRecentTransactionsById({ userId }));
        } catch (err) {
          errorLogger('AddBalanceModal:handleAddBalance', err);
          toast.error('Failed to get User balance!');
        }
        setDisableBtn(false);
        setIsAddBalanceModalOpen((prev) => !prev);
        toast.success('Balance added');
      }, 1500);
    } catch (err) {
      setDisableBtn(false);
      setIsAddBalanceModalOpen((prev) => !prev);
      errorLogger('AddBalanceModal:handleAddBalance', err);
      toast.error(err?.response?.data?.message ?? 'Failed to add balance to user');
    }
  };

  const handleTypeChange = (e) => {
    setType(e.itemData.Id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddBalanceModalOpen}
        toggle={() => setIsAddBalanceModalOpen((prev) => !prev)}
        className="add-balance-modal"
      >
        <ModalHeader toggle={() => setIsAddBalanceModalOpen((prev) => !prev)}>
          Add Balance
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="form-group col-sm-6 filter-select">
              <label className="bold-title" htmlFor="balance-type">
                Type
              </label>
              <CustomSelect
                id="balance-type"
                listData={[
                  { Name: 'Promotion', Id: 3 },
                  { Name: 'Customer Satisfication', Id: 4 },
                  { Name: 'Designer Credit', Id: 12 },
                ]}
                handleChange={handleTypeChange}
                placeholder="Select type"
                textPropName="Name"
                valuePropName="Id"
              />
            </div>
            <div className="form-group col-sm-6">
              <label className="bold-title" htmlFor="balance-amount">
                Amount
              </label>
              <input
                id="balance-amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="balance-exp-date">
                Expirey Date (Not Required)
              </label>
              <DatePicker
                id="balance-exp-date"
                className="form-control digits"
                placeholderText="Selected delivery date"
                selected={selectedDate}
                onChange={(data) => setSelectedDate(data)}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
              />
            </div>
            <div className="form-group col-12">
              <label className="bold-title" htmlFor="balance-remark">
                Remarks
              </label>
              <textarea
                id="balance-remark"
                onChange={(e) => setRemarks(e.target.value)}
                value={remarks}
                className="form-control"
                type="text"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!amount || type === '' || !remarks || disableBtn}
            onClick={handleAddBalance}
          >
            Save
          </Button>
          <Button color="light" onClick={() => setIsAddBalanceModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddBalanceModal;
