import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class VouchersService {
  static getAllCoupons(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllCopouns, ...extraParams),
      params,
    });
  }

  static getVoucherCategoryList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getVouchersCatList, ...extraParams),
    });
  }

  static getCouponDetailsById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCouponById, ...extraParams),
    });
  }

  static updateCouponDetails(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getCouponById, ...extraParams),
      data,
    });
  }

  static createNewCoupon(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getCreateNewCoupon, ...extraParams),
      data,
    });
  }

  static generateCouponCode(...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.generateCouponCode, ...extraParams),
    });
  }

  static getVoucherLimitCategories(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getVoucherLimitCat, ...extraParams),
    });
  }

  static removeVoucherLimitCat(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.getRemoveVoucherLimitCat, ...extraParams),
    });
  }

  static searchVoucherLimitCat(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.searchVoucherLimitCat, ...extraParams),
      params,
    });
  }

  static createVoucherLimitedCat(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.createVoucherLimitedCat, ...extraParams),
      data,
    });
  }
}
export default VouchersService;
