/* eslint-disable indent */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import CustomSelect from 'components/shared/CustomSelect';
import { errorLogger } from 'datadog/DDUtils';
// api
import MerchandisingCategoriesService from 'services/MerchandisingCategoriesService';
import MasterCatalogService from 'services/MasterCatalogService';
// selectors
import { getSelectedProductsItems } from 'store/masterCatalog/MasterCatalogSelectors';
// actions
import { setUncategorizedMerchandise } from 'store/masterCatalog/MasterCatalogActions';

const BulkMerchandisingCategoriesModal = ({
  isBulkMCModalOpen,
  setIsBulkMCModalOpen,
  isUncategorizedMerch,
  inputValues,
}) => {
  const selectedProducts = useSelector((state) => getSelectedProductsItems({ state }));
  const [disableBtn, setDisableBtn] = useState(false);
  const [categories, setCategories] = useState([
    { mcLevel: 0, data: [] },
    { mcLevel: 1, data: [] },
    { mcLevel: 2, data: [] },
    { mcLevel: 3, data: [] },
    { mcLevel: 4, data: [] },
  ]);
  const [selectedCategories, setSelectedCategories] = useState([
    { mcLevel: 0, cat: 0 },
    { mcLevel: 1, cat: 0 },
    { mcLevel: 2, cat: 0 },
    { mcLevel: 3, cat: 0 },
    { mcLevel: 4, cat: 0 },
  ]);
  const ref0 = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const dispatch = useDispatch();

  const handleMainCatChange = (e, mcLevel) => {
    if (e.itemData) {
      const oldCat = cloneDeep(selectedCategories);
      const newCat = oldCat.map((mc) => {
        if (mc.mcLevel === mcLevel) {
          mc.cat = e.itemData.id;
        }
        if (mc.mcLevel > mcLevel) {
          mc.cat = 0;
        }
        return mc;
      });
      const oldCats = cloneDeep(categories);
      const newCats = oldCats.map((mc) => {
        if (mc.mcLevel > mcLevel) {
          mc.data = [];
        }
        return mc;
      });
      setCategories(newCats);
      setSelectedCategories(newCat);
      if (mcLevel < 4) {
        fetchData(mcLevel + 1, newCats, newCat, e.itemData.id);
      }
    }
  };

  const handleGetMC = (val, mcLevel, categoriesOptions, selectedCats) => {
    const oldCat = cloneDeep(selectedCats);
    const newCat = oldCat.map((mc) => {
      if (mc.mcLevel === mcLevel) {
        mc.cat = val;
      }
      if (mc.mcLevel > mcLevel) {
        mc.cat = 0;
      }
      return mc;
    });
    const oldCats = cloneDeep(categoriesOptions);
    const newCats = oldCats.map((mc) => {
      if (mc.mcLevel > mcLevel) {
        mc.data = [];
      }
      return mc;
    });
    setCategories(newCats);
    setSelectedCategories(newCat);
    if (mcLevel < 4) {
      fetchData(mcLevel + 1, newCats, newCat, val);
    }
  };

  const fetchData = async (mcLevel, categoriesData, selectedCats, parentId) => {
    const params = parentId ? { parentId, page: -1 } : { page: -1 };
    setDisableBtn(true);
    try {
      const res = await MerchandisingCategoriesService.getMerchandisingCategoriesData(params);
      const oldCat = cloneDeep(categoriesData);
      const newCat = oldCat.map((mc) => {
        if (mc.mcLevel === mcLevel) {
          mc.data = res.data;
        }
        return mc;
      });
      setCategories(newCat);
      let val = 0;
      switch (mcLevel) {
        case 0:
          val = selectedCats.filter((mc) => {
            return mc.mcLevel === 0;
          })[0].cat;
          break;
        case 1:
          val = selectedCats.filter((mc) => {
            return mc.mcLevel === 1;
          })[0].cat;
          break;
        case 2:
          val = selectedCats.filter((mc) => {
            return mc.mcLevel === 2;
          })[0].cat;
          break;
        case 3:
          val = selectedCats.filter((mc) => {
            return mc.mcLevel === 3;
          })[0].cat;
          break;
        case 4:
          val = selectedCats.filter((mc) => {
            return mc.mcLevel === 4;
          })[0].cat;
          break;
        default:
          val = selectedCats.filter((mc) => {
            return mc.mcLevel === 0;
          })[0].cat;
          break;
      }

      if (val) handleGetMC(val, mcLevel, newCat, selectedCats);
      setDisableBtn(false);
    } catch (err) {
      toast.error('Failed to fetch categories data!!');
      setDisableBtn(false);
    }
  };

  const handleClear = async (level) => {
    const oldCat = cloneDeep(selectedCategories);
    const newCat = oldCat.map((mc) => {
      if (mc.mcLevel >= level) {
        mc.cat = 0;
      }
      return mc;
    });
    await setSelectedCategories(newCat);
    const oldCats = cloneDeep(categories);
    const newCats = oldCats.map((mc) => {
      if (mc.mcLevel > level) {
        mc.data = [];
      }
      return mc;
    });
    await setCategories(newCats);
    if (level === 1) {
      ref1.current.clear();
    } else {
      if (level === 2) {
        ref2.current.clear();
      } else {
        if (level === 3) {
          ref3.current.clear();
        } else {
          if (level === 4) {
            ref4.current.clear();
          } else {
            ref0.current.clear();
          }
        }
      }
    }
  };

  const handleSave = async () => {
    setDisableBtn(true);
    const data = {
      prodIds: selectedProducts,
      McLevels: {
        MC0:
          selectedCategories.filter((mc) => {
            return mc.mcLevel === 0;
          })[0].cat === 0
            ? null
            : selectedCategories.filter((mc) => {
                return mc.mcLevel === 0;
              })[0].cat,
        MC1:
          selectedCategories.filter((mc) => {
            return mc.mcLevel === 1;
          })[0].cat === 0
            ? null
            : selectedCategories.filter((mc) => {
                return mc.mcLevel === 1;
              })[0].cat,
        MC2:
          selectedCategories.filter((mc) => {
            return mc.mcLevel === 2;
          })[0].cat === 0
            ? null
            : selectedCategories.filter((mc) => {
                return mc.mcLevel === 2;
              })[0].cat,
        MC3:
          selectedCategories.filter((mc) => {
            return mc.mcLevel === 3;
          })[0].cat === 0
            ? null
            : selectedCategories.filter((mc) => {
                return mc.mcLevel === 3;
              })[0].cat,
        MC4:
          selectedCategories.filter((mc) => {
            return mc.mcLevel === 4;
          })[0].cat === 0
            ? null
            : selectedCategories.filter((mc) => {
                return mc.mcLevel === 4;
              })[0].cat,
      },
    };
    try {
      const res = await MasterCatalogService.bulkMCUpdate(data);
      toast.success(res?.data?.message ?? 'Merchandising categories were updated successfully');
      setDisableBtn(false);
      setIsBulkMCModalOpen(false);
      if (isUncategorizedMerch) {
        setTimeout(() => {
          dispatch(setUncategorizedMerchandise(inputValues));
        }, 1500);
      }
    } catch (err) {
      errorLogger('BulkMerchandisingCategoriesModal:handleSave', err);
      toast.error(err?.response?.data?.message ?? 'Something went wrong');
      setDisableBtn(false);
    }
  };

  useEffect(() => {
    fetchData(0, categories, selectedCategories);
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isBulkMCModalOpen}
        toggle={() => setIsBulkMCModalOpen((prev) => !prev)}
        className="m-w-900"
      >
        <ModalHeader>Add Merchandising Categories</ModalHeader>
        <ModalBody>
          <div className="form-group col-12">
            <form className="row">
              {categories.filter((mc) => {
                return mc.mcLevel === 0;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4 m-b-15">
                  <label className="bold-title" htmlFor="merchBulk-lvl0">
                    Merchandising Category Level 0
                  </label>
                  <CustomSelect
                    id="merchBulk-lvl0"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 0;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 0)}
                    placeholder="Select merchandising category level 0"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 0;
                      })[0].cat
                    }
                    disabled={disableBtn}
                    showClearButton={!disableBtn}
                    handleClear={() => handleClear(0)}
                    forwardedRef={ref0}
                  />
                </div>
              )}
              {categories.filter((mc) => {
                return mc.mcLevel === 1;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="merchBulk-lvl1">
                    Merchandising Category Level 1
                  </label>
                  <CustomSelect
                    id="merchBulk-lvl1"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 1;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 1)}
                    placeholder="Select merchandising category level 1"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 1;
                      })[0].cat
                    }
                    disabled={disableBtn}
                    showClearButton={!disableBtn}
                    handleClear={() => handleClear(1)}
                    forwardedRef={ref1}
                  />
                </div>
              )}
              {categories.filter((mc) => {
                return mc.mcLevel === 2;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="merchBulk-lvl2">
                    Merchandising Category Level 2
                  </label>
                  <CustomSelect
                    id="merchBulk-lvl2"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 2;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 2)}
                    placeholder="Select merchandising category level 2"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 2;
                      })[0].cat
                    }
                    disabled={disableBtn}
                    showClearButton={!disableBtn}
                    handleClear={() => handleClear(2)}
                    forwardedRef={ref2}
                  />
                </div>
              )}
              {categories.filter((mc) => {
                return mc.mcLevel === 3;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="merchBulk-lvl3">
                    Merchandising Category Level 3
                  </label>
                  <CustomSelect
                    id="merchBulk-lvl3"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 3;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 3)}
                    placeholder="Select merchandising category level 3"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 3;
                      })[0].cat
                    }
                    disabled={disableBtn}
                    showClearButton={!disableBtn}
                    handleClear={() => handleClear(3)}
                    forwardedRef={ref3}
                  />
                </div>
              )}
              {categories.filter((mc) => {
                return mc.mcLevel === 4;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="merchBulk-lvl4">
                    Merchandising Category Level 4
                  </label>
                  <CustomSelect
                    id="merchBulk-lvl4"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 4;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 4)}
                    placeholder="Select merchandising category level 4"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 4;
                      })[0].cat
                    }
                    disabled={disableBtn}
                    showClearButton={!disableBtn}
                    handleClear={() => handleClear(4)}
                    forwardedRef={ref4}
                  />
                </div>
              )}
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-primary" onClick={handleSave}>
            Save
          </Button>
          <Button color="light" onClick={() => setIsBulkMCModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BulkMerchandisingCategoriesModal;
