import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getFromLocalStorage, addToLocalStorage } from 'localStorageService/LocalStorage';
// actions
import { setSignInSuccess, setUserPermiossions } from 'store/auth/signIn/SignInAction';
// selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
// constants
import { localStorageKeys } from 'constant/Enums';

const OpsModal = ({ isOpsModalOpen, setIsOpsModalOpen }) => {
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const currentOps = getFromLocalStorage(localStorageKeys.OPS_ID);
  const updatedCenters = opsCenters.filter((ops) => ops.id !== currentOps);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSignInSuccess());
  }, []);

  const handleOpsDashboard = (ops) => {
    addToLocalStorage(localStorageKeys.OPS_CENTER, ops);
    addToLocalStorage(localStorageKeys.OPS_ID, ops.id);
    addToLocalStorage(localStorageKeys.NAV_BG_COLOR, `#${ops.color}`);
    addToLocalStorage(localStorageKeys.COUNTRY_ID, ops.countryID);
    dispatch(setUserPermiossions(ops.id, ops.countryID));
    setIsOpsModalOpen((prev) => !prev);
    window.location.reload();
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        className="m-w-800"
        isOpen={isOpsModalOpen}
        toggle={(prev) => setIsOpsModalOpen(!prev)}
      >
        <ModalHeader toggle={(prev) => setIsOpsModalOpen(!prev)}>Change HUB Ops center</ModalHeader>
        <ModalBody>
          <Fragment>
            <div className="row">
              <ul className="col-sm-12">
                <Fragment>
                  <p className="ops-modal-title">
                    <span>
                      {' '}
                      <i className="fa fa-globe"></i> Global
                    </span>
                  </p>
                  {updatedCenters
                    .filter(
                      (ops) =>
                        ops.countryID !== 2 &&
                        ops.countryID !== 6 &&
                        ops.countryID !== 8 &&
                        ops.countryID !== 13 &&
                        ops.countryID !== 3
                    )
                    .map((ops, i) => (
                      <li
                        key={i}
                        onClick={() => handleOpsDashboard(ops)}
                        className="col-4 col-sm-2 ops-selector"
                      >
                        <img className="ops-img radius-5" src={ops.iconFullPath} alt="" />
                        <p className="no-wrap">{ops.nickName}</p>
                      </li>
                    ))}
                </Fragment>
              </ul>
              <ul className="col-sm-12">
                <Fragment>
                  <p className="ops-modal-title">
                    <span>
                      <i className="flag-icon flag-icon-sa"></i> Saudi Arabia
                    </span>
                  </p>
                  {updatedCenters
                    .filter((ops) => ops.countryID === 2)
                    .map((ops, i) => (
                      <li
                        key={i}
                        onClick={() => handleOpsDashboard(ops)}
                        className="col-4 col-sm-2 ops-selector"
                      >
                        <img className="ops-img radius-5" src={ops.iconFullPath} alt="" />
                        <p className="no-wrap">{ops.nickName}</p>
                      </li>
                    ))}
                </Fragment>
              </ul>
              <ul className="col-sm-12">
                <Fragment>
                  <p className="ops-modal-title">
                    <span>
                      <i className="flag-icon flag-icon-ae"></i> UAE
                    </span>
                  </p>
                  {updatedCenters
                    .filter((ops) => ops.countryID === 6)
                    .map((ops, i) => (
                      <li
                        key={i}
                        onClick={() => handleOpsDashboard(ops)}
                        className="col-4 col-sm-2 ops-selector"
                      >
                        <img className="ops-img radius-5" src={ops.iconFullPath} alt="" />
                        <p className="no-wrap">{ops.nickName}</p>
                      </li>
                    ))}
                </Fragment>
              </ul>
              <ul className="col-sm-12">
                <Fragment>
                  <p className="ops-modal-title">
                    <span>
                      <i className="flag-icon flag-icon-gb"></i> UK
                    </span>
                  </p>
                  {updatedCenters
                    .filter((ops) => ops.countryID === 8)
                    .map((ops, i) => (
                      <li
                        key={i}
                        onClick={() => handleOpsDashboard(ops)}
                        className="col-4 col-sm-2 ops-selector"
                      >
                        <img className="ops-img radius-5" src={ops.iconFullPath} alt="" />
                        <p className="no-wrap">{ops.nickName}</p>
                      </li>
                    ))}
                </Fragment>
              </ul>
              <ul className="col-sm-12">
                <Fragment>
                  <p className="ops-modal-title">
                    <span>
                      <i className="flag-icon flag-icon-om"></i> Oman
                    </span>
                  </p>
                  {updatedCenters
                    .filter((ops) => ops.countryID === 3)
                    .map((ops, i) => (
                      <li
                        key={i}
                        onClick={() => handleOpsDashboard(ops)}
                        className="col-4 col-sm-2 ops-selector"
                      >
                        <img className="ops-img radius-5" src={ops.iconFullPath} alt="" />
                        <p className="no-wrap">{ops.nickName}</p>
                      </li>
                    ))}
                </Fragment>
              </ul>
              <ul className="col-sm-12">
                <Fragment>
                  <p className="ops-modal-title">
                    <span>
                      <i className="flag-icon flag-icon-jo"></i> Jordan
                    </span>
                  </p>
                  {updatedCenters
                    .filter((ops) => ops.countryID === 13)
                    .map((ops, i) => (
                      <li
                        key={i}
                        onClick={() => handleOpsDashboard(ops)}
                        className="col-4 col-sm-2 ops-selector"
                      >
                        <img className="ops-img radius-5" src={ops.iconFullPath} alt="" />
                        <p className="no-wrap">{ops.nickName}</p>
                      </li>
                    ))}
                </Fragment>
              </ul>
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OpsModal;
