import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
import { errorLogger } from 'datadog/DDUtils';
// actions
import {
  getInventorySuppliersList,
  getInventoryData,
  setCopyToBasketItem,
} from 'store/inventory/InventoryActions';
// selectors
import { getSuppliersList } from 'store/inventory/InventorySelectors';
// api
import InventoryService from 'services/InventoryService';
import RestrictedSection from 'components/shared/RestrictedSection';
// constant
import { inventoryTypes } from 'constant/Enums';

const AddNewItemModal = ({
  isAddItemModalOpen,
  setIsAddItemModalOpen,
  defaultParams,
  clickedRow,
}) => {
  const suppliersList = useSelector((state) => getSuppliersList({ state }));
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [name, setName] = useState(clickedRow ? clickedRow.name : '');
  const [barcode, setBarcode] = useState(clickedRow ? clickedRow.barCode : '');
  const [price, setPrice] = useState(clickedRow ? clickedRow.price : 0);
  const [inventoryType, setInventoryType] = useState(clickedRow ? clickedRow.inventoryType : 0);
  const [status, setStatus] = useState(
    clickedRow ? clickedRow.inventorystatus : defaultParams.status
  );
  const [supplier, setSupplier] = useState(clickedRow ? clickedRow.supplierId : 0);
  const [itemImage, setItemImage] = useState(clickedRow ? clickedRow.img : '');
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleStatusChange = (e) => {
    setStatus(e.itemData.id);
  };

  const handleSupplierChange = (e) => {
    setSupplier(e.itemData.id);
  };

  useEffect(() => {
    dispatch(getInventorySuppliersList());
  }, []);

  const handleAddNewItem = async () => {
    setIsLoaderOpen(true);
    setDisableBtn(true);
    const data = {
      Name: name,
      Barcode: barcode,
      Price: price,
      Type: inventoryType,
      Img: itemImage,
      SupplierId: supplier,
      status,
      InventroyId: clickedRow ? clickedRow.inventoryId : 0,
      ItemId: clickedRow ? clickedRow.id : 0,
    };
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    try {
      if (clickedRow) {
        await InventoryService.editInventoryItem(formData);
      } else {
        await InventoryService.addInventoryItem(formData);
      }
      setTimeout(() => {
        dispatch(getInventoryData(defaultParams));
        setIsLoaderOpen(false);
        setDisableBtn(false);
        setIsAddItemModalOpen((prev) => !prev);
        toast.success('Updated');
      }, 2000);
    } catch (err) {
      errorLogger('AddNewItemModal:handleAddNewItem', err);
      setIsLoaderOpen(false);
      setDisableBtn(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleCopyToBasketItem = (id) => {
    dispatch(setCopyToBasketItem(id));
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddItemModalOpen}
        toggle={() => setIsAddItemModalOpen((prev) => !prev)}
        className="m-w-600"
      >
        <ModalBody>
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <Fragment>
              <div className="row">
                <div className="col-12 add-new-item-modal-title">
                  <h5 className="primary-color align-center">
                    {clickedRow ? 'Edit Item' : 'Add Inventory'}
                  </h5>
                </div>
                <RestrictedSection page="inventory" action={clickedRow ? 'edit_inventory' : 'view'}>
                  <div className="form-group col-12">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Name
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </RestrictedSection>
                <RestrictedSection page="inventory" action={clickedRow ? 'edit_inventory' : 'view'}>
                  <div className="form-group col-12">
                    <label className="col-form-label" htmlFor="recipient-name">
                      Barcode
                    </label>
                    <input
                      onChange={(e) => setBarcode(e.target.value)}
                      value={barcode}
                      className="form-control"
                      type="text"
                    />
                  </div>
                </RestrictedSection>
                <div className="form-group col-12">
                  <label className="col-form-label" htmlFor="recipient-name">
                    Price
                  </label>
                  <input
                    onChange={(e) => setPrice(e.target.value)}
                    value={price}
                    className="form-control"
                    type="number"
                  />
                </div>
                <RestrictedSection page="inventory" action={clickedRow ? 'edit_inventory' : 'view'}>
                  <div className="form-group col-12 filter-select">
                    <label htmlFor="inventory-type">Inventory Type</label>
                    <CustomSelect
                      id="inventory-type"
                      listData={inventoryTypes.slice(1)}
                      handleChange={(e) => setInventoryType(e.itemData.id)}
                      placeholder="Inventory Type"
                      textPropName="name"
                      valuePropName="id"
                      selectedValue={inventoryType}
                    />
                  </div>
                </RestrictedSection>
                <div className="form-group col-12 filter-select">
                  <label htmlFor="inventory-status">Status</label>
                  <CustomSelect
                    id="inventory-status"
                    listData={[
                      { id: 0, name: 'In_Stock' },
                      { id: 2, name: 'Discontinued' },
                    ]}
                    handleChange={handleStatusChange}
                    placeholder="Select a status"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={status}
                  />
                </div>
                <div className="form-group col-12 filter-select">
                  <label htmlFor="inventory-supplier">Supplier</label>
                  <CustomSelect
                    id="inventory-supplier"
                    listData={suppliersList}
                    handleChange={handleSupplierChange}
                    placeholder="Select a supplier"
                    textPropName="companyName"
                    valuePropName="id"
                    selectedValue={supplier}
                  />
                </div>
                <RestrictedSection page="inventory" action={clickedRow ? 'edit_inventory' : 'view'}>
                  <div className="form-group col-12">
                    <label className="col-form-label pt-0" htmlFor="inventory-image">
                      Image
                    </label>
                    <input
                      id="inventory-image"
                      onChange={(e) => setItemImage(e.target.files[0])}
                      className="form-control"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                </RestrictedSection>
                {/* {clickedRow && (
                  <div className="form-group col-12">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleCopyToBasketItem(clickedRow.id)}
                    >
                      <i className="fa fa-file"></i> Copy to basket
                    </button>
                  </div>
                )} */}
              </div>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleAddNewItem}
            color="primary"
            disabled={!name || !barcode || disableBtn}
          >
            Save
          </Button>
          <Button color="light" onClick={() => setIsAddItemModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddNewItemModal;
