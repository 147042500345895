import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';
//FO => featured occasions

//get FO based on isActive value {isActive:true || falase}
class FeaturedOccasionService {
  static getFeaturedOccasionsData(data) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.featuredOccasions.getAll),
      params: data,
    });
  }
  //get FO by Id
  static getFeaturedOccasionsById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.featuredOccasions.actionById, ...extraParams),
    });
  }
  //sort FO by list of array [FO1, FO2]
  static sortFeaturedOccasions(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.featuredOccasions.sort, ...extraParams),
      data,
    });
  }
  // change FO status by {occasionId,isActive}
  // maximum active FO is two
  static changeFeaturedOccasionsStatus(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.featuredOccasions.status, ...extraParams),
      data,
    });
  }
  //get active collection products for FO add & edit
  static getActiveCollection(data) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.featuredOccasions.activeCollections),
      params: data,
    });
  }
  //create Fo
  static createFeaturedOccasion(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.featuredOccasions.create, ...extraParams),
      data,
    });
  }
  // update FO
  static editFeaturedOccasion(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.featuredOccasions.actionById, ...extraParams),
      data,
    });
  }
}
export default FeaturedOccasionService;
