import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
// api
import VouchersService from 'services/VouchersService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const setAllCouponsList = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  const newParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== ''));
  try {
    const res = await VouchersService.getAllCoupons(newParams);
    dispatch({
      type: 'SET_ALL_COUPONS_LIST_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    dispatch(setLoaderOpenFalse());
    errorLogger('VouchersActions:setAllCouponsList', err);
    toast.error('Failed to get coupons list');
  }
};

export const setVoucherCategoryList = () => async (dispatch) => {
  try {
    const res = await VouchersService.getVoucherCategoryList();
    dispatch({
      type: 'SET_VOUCHERS_CATEGORIES_LIST',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('VouchersActions:setVoucherCategoryList', err);
    toast.error('Failed to fetch voucher category list!!');
  }
};

export const setCouponDetailsById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await VouchersService.getCouponDetailsById(id);
    dispatch({
      type: 'SET_COUPON_DETAILS_BY_ID',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    dispatch(setLoaderOpenFalse());
    errorLogger('VouchersActions:setCouponDetailsById', err);
    toast.error('Failed to get coupon details');
  }
};

export const setVoucherLimitCategories = (id) => async (dispatch) => {
  try {
    const res = await VouchersService.getVoucherLimitCategories(id);
    dispatch({
      type: 'SET_VOUCHER_LIMIT_CATEGORIES',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('VouchersActions:setVoucherLimitCategories', err);
    toast.error('Failed to get coupon details');
  }
};
