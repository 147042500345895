import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// components
import SectionLoader from 'components/common/SectionLoader';
import WalletTransactionsDataGrid from './WalletTransactionsDataGrid';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const WalletTransactionsContent = ({ userId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [transactionsList, setTransactionsList] = useState('');

  const fetchWalletTransactionsData = async () => {
    const params = {
      userid: userId,
    };
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getUserTransactions(params);
      const modifiedData = res.data.reduce((accum, currentVal) => {
        return [...accum, ...currentVal.data];
      }, []);
      setTransactionsList(modifiedData);
      setIsLoaderOpen(false);
    } catch (err) {
      setIsLoaderOpen(false);
      errorLogger('WalletTransactionsContent:fetchWalletTransactionsData', err);
      toast.error(err?.response?.data?.message ?? 'Failed to get user transactions!');
    }
  };

  useEffect(() => {
    fetchWalletTransactionsData();
  }, []);
  return (
    <div className="card row checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          Wallet Transactions <i className="fa fa-info-circle"></i>
        </h4>
      </div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="card-body">
          <WalletTransactionsDataGrid data={transactionsList} />
        </div>
      )}
    </div>
  );
};

export default WalletTransactionsContent;
