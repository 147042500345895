import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
} from '@syncfusion/ej2-react-grids';
import CustomSelect from 'components/shared/CustomSelect';
import { dateFormatDDMM } from 'constant/Helpers';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// actions
import { setChangeDriverExpensesStatus } from 'store/delivery/driversExpenses/DriversExpensesActions';

const DriversExpensesDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'Search'];
  const ref = useRef();
  const dispatch = useDispatch();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const handleStatusChange = (rowData) => {
    dispatch(setChangeDriverExpensesStatus(rowData));
  };

  const createdDateTemplate = (rowData) => <span>{dateFormatDDMM(rowData.createdDate)}</span>;

  const statusTemplate = (rowData) => (
    <div className="filter-select">
      <CustomSelect
        listData={[
          { id: 0, name: 'Confirmed' },
          { id: 1, name: 'Cancelled' },
        ]}
        handleChange={() => handleStatusChange(rowData)}
        placeholder="Select status"
        textPropName="name"
        valuePropName="id"
        disabled={rowData.status === 1}
        selectedValue={rowData.status}
      />
    </div>
  );

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          rowHeight={60}
          allowTextWrap={true}
          className="pointer"
        >
          <ColumnsDirective>
            <ColumnDirective headerText="Name" field="driverName" width={120} textAlign="Center" />
            <ColumnDirective
              headerText="Created Date"
              field="createdDate"
              template={createdDateTemplate}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective headerText="Cost" field="cost" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Agent Name"
              field="agantName"
              width={140}
              textAlign="Center"
            />
            <ColumnDirective headerText="Comment" field="note" width={140} textAlign="Center" />
            <ColumnDirective
              headerText="Status"
              template={statusTemplate}
              width={150}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DriversExpensesDataGrid);
