import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { Link } from 'react-router-dom';

const DriverPerformanceDetailsDataGrid = ({ data, params }) => {
  const toolbarOptions = ['ColumnChooser', 'Search'];
  const ref = useRef();

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseInt(rowData.index, 10) + 1}</p>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return <p className="total-txt">{rowData.name}</p>;
  };

  const typeTemplate = (rowData) => {
    return <p className="total-txt">{rowData.type}</p>;
  };

  const phoneTemplate = (rowData) => {
    return <p className="total-txt">{rowData.phone}</p>;
  };

  const companyTemplate = (rowData) => {
    return <p className="total-txt">{rowData.company}</p>;
  };

  const deliveredTemplate = (rowData) => {
    return (
      <Link
        target={'_blank'}
        to={`/report/DriverPerformanceOrdersReport?fromDate=${params.fromDate}&toDate=${params.toDate}&id=${rowData.id}&status=2&page=1`}
        className="total-txt link"
      >
        {rowData.delivered}
      </Link>
    );
  };

  const returnedTemplate = (rowData) => {
    return (
      <Link
        target={'_blank'}
        to={`/report/DriverPerformanceOrdersReport?fromDate=${params.fromDate}&toDate=${params.toDate}&id=${rowData.id}&status=3&page=1`}
        className="total-txt link"
      >
        {rowData.returned}
      </Link>
    );
  };

  const totalTemplate = (rowData) => {
    return <p className="total-txt">{rowData.total}</p>;
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="#"
              field="id"
              width={100}
              template={idTemplate}
              customExcelTemplate={(rowData) => rowData.id}
            />
            <ColumnDirective
              headerText="Name"
              field="name"
              width={100}
              template={nameTemplate}
              customExcelTemplate={(rowData) => rowData.name}
            />
            <ColumnDirective
              headerText="Type"
              field="type"
              width={100}
              template={typeTemplate}
              customExcelTemplate={(rowData) => rowData.type}
            />
            <ColumnDirective
              headerText="Phone"
              field="phone"
              width={100}
              template={phoneTemplate}
              customExcelTemplate={(rowData) => rowData.phone}
            />
            <ColumnDirective
              headerText="Company"
              field="company"
              width={100}
              template={companyTemplate}
              customExcelTemplate={(rowData) => rowData.company}
            />
            <ColumnDirective
              headerText="Delivered"
              field="delivered"
              width={100}
              template={deliveredTemplate}
              customExcelTemplate={(rowData) => rowData.delivered}
            />
            <ColumnDirective
              headerText="Returned"
              field="returned"
              width={100}
              template={returnedTemplate}
              customExcelTemplate={(rowData) => rowData.returned}
            />
            <ColumnDirective
              headerText="Total"
              field="total"
              width={100}
              template={totalTemplate}
              customExcelTemplate={(rowData) => rowData.total}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DriverPerformanceDetailsDataGrid);
