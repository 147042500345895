import { updateObject } from '../../utility';

const initialState = {
  deliveryAreasData: [],
  selectedArea: {},
  subAreasData: [],
  selectedSubArea: {},
  mainAreas: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DELIVERY_AREAS': {
      return updateObject(state, {
        deliveryAreasData: action.payload,
      });
    }

    case 'SET_SELECTED_AREA': {
      return updateObject(state, {
        selectedArea: action.payload,
      });
    }

    case 'UPDATE_SELECTED_AREA': {
      return updateObject(state, {
        selectedArea: action.payload,
      });
    }

    case 'SET_SUB_AREAS': {
      return updateObject(state, {
        subAreasData: action.payload,
      });
    }

    case 'SET_SELECTED_SUBAREA': {
      return updateObject(state, {
        selectedSubArea: action.payload,
      });
    }

    case 'UPDATE_SELECTED_SUBAREA': {
      return updateObject(state, {
        selectedSubArea: action.payload,
      });
    }

    case 'SET_MAIN_AREAS': {
      return updateObject(state, {
        mainAreas: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
