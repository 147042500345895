import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// selectors
import { getSelectedPostCodeData } from 'store/delivery/deliveryPostCodes/postcodeSelectors';
// action
import { getPostCodeById } from 'store/delivery/deliveryPostCodes/postcodeActions';
// services
import ViewOrderService from 'services/ViewOrderService';
// localstorage
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { getAreas } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
// components
import SectionLoader from 'components/common/SectionLoader';
const EditPostcode = React.lazy(() => import('components/delivery/coveragePostcode/EditPostcode'));

const EditCoveragePostCodePage = () => {
  const selectedPostCodeData = useSelector((state) => getSelectedPostCodeData({ state }));
  const [areaList, setAreaList] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();

  const fetchData = async () => {
    await dispatch(getPostCodeById(parseInt(params?.id, 10)));
  };

  const fetchAreas = async () => {
    const newParams = {
      opsId: getFromLocalStorage(localStorageKeys.OPS_ID),
    };
    const res = await ViewOrderService.getAreasList(newParams);
    const allareas = getAreas(res);
    setAreaList(allareas);
  };

  useEffect(() => {
    fetchAreas();
  }, []);

  useEffect(() => {
    if (areaList.length > 0) fetchData();
  }, [areaList]);

  return (
    <div>
      {areaList.length === 0 ? (
        <SectionLoader height="300px" />
      ) : (
        <React.Suspense fallback={<SectionLoader />}>
          <EditPostcode selectedPostCodeData={selectedPostCodeData} areaList={areaList} />
        </React.Suspense>
      )}
    </div>
  );
};

export default EditCoveragePostCodePage;
