import React from 'react';
// Components
import CorporateInvoicesBtns from './CorporateInvoicesBtns';
import CorporateInvoicesDataGrid from './CorporateInvoicesDataGrid';

const CorporateInvoicesDataTable = ({ data, consolidated, userId }) => {
  return (
    <div className="control-pane">
      <CorporateInvoicesBtns consolidated={consolidated} userId={userId} />
      <CorporateInvoicesDataGrid data={data} />
    </div>
  );
};

export default CorporateInvoicesDataTable;
