import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import InventoryStatementActualDeliveryDataGrid from 'components/reports/inventory/InventoryStatementActualDeliveryDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
import { getInventoryStatementActualDeliveryReportPageUrl } from 'constant/AppUrls';
import DJHPagination from 'components/shared/Pagination';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// api
import InventoryReportService from 'services/reportsService/InventoryReportService';
// selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const InventoryStatementActualDeliveryPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    countryId: -1,
    page: 1,
  });
  const [countriesList, setCountriesList] = useState([]);
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await InventoryReportService.getInventoryStatementActualDeliveryReport(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Inventory Statement Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  const exportFile = async () => {
    try {
      const res = await InventoryReportService.exportInventoryStatementActualDeliveryReport({
        ...inputValues,
        page: -1,
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
      toast.success('Downloaded');
    } catch (err) {
      errorLogger('Inventory Statement Report Page Download Data Error:', err);
      toast.error(err?.response?.data?.message ?? 'Failed to download');
    }
  };

  const handleSubmit = async () => {
    const updatedParams = cloneDeep(inputValues);
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getInventoryStatementActualDeliveryReportPageUrl(),
      search: queryString,
    });
    fetchData(updatedParams);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getInventoryStatementActualDeliveryReportPageUrl(),
      search: queryString,
    });
    fetchData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  const fetchCountriesList = () => {
    try {
      dispatch(setSignInSuccess());
    } catch (err) {
      errorLogger('Inventory Statement Report Page Fetch Country List Error:', err);
      toast.error('Failed to get coutries list!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
    fetchCountriesList();
  }, []);

  useEffect(() => {
    const countriesData = [];
    opsCenters.forEach((ops) => {
      if (
        countriesData.filter((country) => {
          return country.id === ops.country.id;
        }).length === 0
      ) {
        countriesData.push(ops.country);
      }
    });
    setCountriesList(countriesData);
  }, [opsCenters]);

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Inventory Statement Actual delivery"
        defaultDate
        countriesList={countriesList}
        country
        withoutOps
        hasExport
        handleExport={exportFile}
      />
      <InventoryStatementActualDeliveryDataGrid data={data.data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+data.currentPage}
        pageSize={+data.pageSize}
        itemsCount={+data.rowCount}
      />
    </div>
  );
};

export default InventoryStatementActualDeliveryPage;
