import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextField from '@mui/material/TextField';
import { dateFormat } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
import { errorLogger } from 'datadog/DDUtils';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import 'react-times/css/classic/default.css';
import { toast } from 'react-toastify';
// actions
import { setAllDeliverySlots } from 'store/delivery/deliverySlots/slotsActions';
// api
import DeliveryService from 'services/DeliveryService';
import CustomSelect from 'components/shared/CustomSelect';
// Components
import DateRangePickerComponent from 'components/shared/DateRangePickerComponent';

const AddDeliverySlotsModal = ({
  isAddNewSlotModalOpen,
  setIsAddNewSlotModalOpen,
  modifiedRow,
  setModifiedRow,
  params,
}) => {
  const [name, setName] = useState(modifiedRow ? modifiedRow.name : '');
  const [maxOrders, setMaxOrders] = useState(modifiedRow ? modifiedRow.maxNumbeOfOrders : '');
  const [expressDeliveryDuration, setExpressDeliveryDuration] = useState(
    modifiedRow ? modifiedRow.expressDeliveryDuration : ''
  );
  const [type, setType] = useState(modifiedRow ? modifiedRow.type : 0);
  const [fromTime, setFromTime] = useState(modifiedRow ? modifiedRow.fromTime : '00:00');
  const [toTime, setToTime] = useState(modifiedRow ? modifiedRow.toTime : '00:00');
  const [deliveryFrom, setDeliveryFrom] = useState(
    modifiedRow ? modifiedRow.deliveryFromTime : '00:00'
  );
  const [deliveryTo, setDeliveryTo] = useState(modifiedRow ? modifiedRow.deliveryToTime : '00:00');
  const [deliveryCharges, setDeliveryCharges] = useState(
    modifiedRow ? modifiedRow.deliveryCharges ?? 0 : 0
  );

  const [isExpressDelivery, setIsExpressDelivery] = useState(modifiedRow?.expressDelivery ?? false);
  const [isArchive, setIsArchive] = useState(modifiedRow?.status ?? false);
  const [disableBtn, setDisablebtn] = useState(false);
  const opsId = getFromLocalStorage(localStorageKeys.OPS_ID);
  const [isSpecificDate, setIsSpecificDate] = useState(modifiedRow?.isCustomizable ?? false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    endDate: modifiedRow?.hideAtDate ? new Date(modifiedRow?.hideAtDate) : new Date(),
    startDate: modifiedRow?.showAtDate ? new Date(modifiedRow?.showAtDate) : new Date(),
    key: 'selection',
  });
  const dispatch = useDispatch();
  const handleAddNewSlot = async () => {
    let expressDuration;
    if (expressDeliveryDuration && expressDeliveryDuration < 1) {
      toast.error('Express delivery duration must be greater than 0');
      return;
    }
    if (isExpressDelivery) {
      expressDuration = expressDeliveryDuration === '' ? null : expressDeliveryDuration;
    } else {
      expressDuration = null;
    }
    const dataBody = {
      Name: name,
      FromTime: fromTime,
      ToTime: toTime,
      Saturday: modifiedRow?.saturday ?? false,
      Sunday: modifiedRow?.sunday ?? false,
      Monday: modifiedRow?.monday ?? false,
      Tuesday: modifiedRow?.tuesday ?? false,
      Wednesday: modifiedRow?.wednesday ?? false,
      Thursday: modifiedRow?.thursday ?? false,
      Friday: modifiedRow?.friday ?? false,
      IsActive: modifiedRow?.isActive ?? false,
      OrderID: modifiedRow?.orderId ?? 3,
      OpsID: opsId,
      DeliveryFromTime: deliveryFrom,
      DeliveryToTime: deliveryTo,
      MaxNumbeOfOrders: maxOrders,
      ExpressDeliveryDuration: expressDuration,
      Type: type,
      Line: params.line,
      deliveryCharges,
      expressDelivery: isExpressDelivery,
      status: isArchive,
      isCustomizable: isSpecificDate,
    };
    let sentParams = { ...dataBody };
    if (isSpecificDate) {
      sentParams = {
        ...dataBody,
        showAtDate: dateFormat(dateRange?.startDate),
        hideAtDate: dateFormat(dateRange?.endDate),
      };
    }
    setDisablebtn(true);
    try {
      if (modifiedRow) {
        await DeliveryService.updateDeliverySlot(sentParams, modifiedRow.id);
      } else {
        await DeliveryService.addNewDeliverySlot(sentParams);
      }
      setTimeout(() => {
        dispatch(setAllDeliverySlots({ line: params.line, status: params.status }));
        setDisablebtn(false);
        setIsAddNewSlotModalOpen((prev) => !prev);
        toast.success(modifiedRow ? 'Slot updated successfully' : 'New Slot Added successfully');
      }, 2000);
    } catch (err) {
      if (modifiedRow) {
        errorLogger('AddDeliverySlotsModal:handleUpdateSlot', err);
      } else {
        errorLogger('AddDeliverySlotsModal:handleAddNewSlot', err);
      }
      setDisablebtn(false);
      setIsAddNewSlotModalOpen((prev) => !prev);
    }
    setModifiedRow('');
  };

  const handleTypeChange = (e) => {
    setType(e.itemData.id);
  };

  const handleDateRange = (item) => {
    setDateRange(item.selection);
  };
  const onClickSpecificDates = () => {
    setIsSpecificDate((prev) => !prev);
    setShowDatePicker(!isSpecificDate);
    setIsExpressDelivery(false);
    setExpressDeliveryDuration(null);
  };
  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddNewSlotModalOpen}
        toggle={() => {
          setIsAddNewSlotModalOpen((prev) => !prev);
          setModifiedRow('');
        }}
        className="m-w-700"
      >
        <ModalHeader>{modifiedRow ? 'Edit Slot' : 'New Slot'}</ModalHeader>
        <ModalBody>
          <form className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="v">Name</label>
                <input
                  id="delvslot-name"
                  onChange={(e) => setName(e.target.value)}
                  className="form-control digits"
                  placeholder="Name"
                  value={name}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="delvslot-max-orders">Maximum orders (0 means unlimited)</label>
                <input
                  id="delvslot-max-orders"
                  onChange={(e) => setMaxOrders(e.target.value)}
                  className="form-control digits"
                  placeholder="Maximum orders"
                  value={maxOrders}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <TextField
                  id="time"
                  label="Show at:"
                  type="time"
                  defaultValue={fromTime}
                  className="w-70 m-t-20"
                  onChange={(e) => setFromTime(e.target.value)}
                  variant="standard"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <TextField
                  id="time"
                  label="Close at:"
                  type="time"
                  defaultValue={toTime}
                  onChange={(e) => setToTime(e.target.value)}
                  className="w-70 m-t-20"
                  variant="standard"
                />
              </div>
            </div>
            <div className="col-12 py-3">
              <div className="form-group">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={isSpecificDate}
                  onChange={onClickSpecificDates}
                />
                <span className="bold-title">Show on specific dates only</span>
              </div>
            </div>

            {isSpecificDate && (
              <div className="col-12 mb-4">
                <div className="form-group">
                  <div className="normal-flex">
                    <input
                      id="expense-range"
                      className="form-control digits pointer white-bg"
                      value={`${dateFormat(dateRange.startDate)} - ${dateFormat(
                        dateRange.endDate
                      )}`}
                      onClick={() => setShowDatePicker((prev) => !prev)}
                      readOnly
                    />
                  </div>

                  {showDatePicker ? (
                    <DateRangePickerComponent
                      minDate={new Date()}
                      setShowDatePicker={setShowDatePicker}
                      handleDateRange={handleDateRange}
                      dateRange={dateRange}
                    />
                  ) : null}
                </div>
              </div>
            )}

            <div className="col-12 col-md-6">
              <div className="form-group">
                <TextField
                  id="time"
                  label="Delivery at:"
                  type="time"
                  defaultValue={deliveryFrom}
                  onChange={(e) => setDeliveryFrom(e.target.value)}
                  className="w-70"
                  variant="standard"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <TextField
                  id="time"
                  label="Delivery to:"
                  type="time"
                  defaultValue={deliveryTo}
                  onChange={(e) => setDeliveryTo(e.target.value)}
                  className="w-70"
                  variant="standard"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group filter-select">
                <label htmlFor="delvslot-max-orders">
                  Delivery Charges (override the delivery charges leave it 0 to ignore this option)
                </label>
                <input
                  id="ç"
                  onChange={(e) => setDeliveryCharges(e.target.value)}
                  className="form-control digits"
                  placeholder="Delivery Charges"
                  value={deliveryCharges}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group filter-select">
                <label htmlFor="delvslot-type">Type</label>
                <CustomSelect
                  id="delvslot-type"
                  listData={[
                    { id: 0, name: 'Delivery' },
                    { id: 1, name: 'Picked' },
                  ]}
                  handleChange={handleTypeChange}
                  placeholder="Select a type"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={type}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={isExpressDelivery}
                  onChange={() => setIsExpressDelivery((prev) => !prev)}
                  disabled={isSpecificDate}
                />
                <span className="bold-title">Express delivery?</span>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={isArchive}
                  onChange={() => setIsArchive((prev) => !prev)}
                />
                <span className="bold-title">Archive</span>
              </div>
            </div>
            {isExpressDelivery && (
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="delvslot-duration">Express Delivery Duration</label>
                  <input
                    id="delvslot-duration"
                    type="number"
                    onChange={(e) => setExpressDeliveryDuration(e.target.value)}
                    className="form-control digits"
                    placeholder="Express Delivery Duration"
                    value={expressDeliveryDuration === null ? '' : expressDeliveryDuration}
                    min={1}
                  />
                </div>
              </div>
            )}
            {isArchive ? (
              <div className="col-12">
                <p style={{ color: 'red' }}>
                  * By archiving slot you will no longer be able to retrieve or see the slot in the
                  list
                </p>
              </div>
            ) : null}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={disableBtn || maxOrders === ''}
            onClick={handleAddNewSlot}
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setIsAddNewSlotModalOpen((prev) => !prev);
              setModifiedRow('');
            }}
            color="light"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddDeliverySlotsModal;
