// pages
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import CreateOrderPage from '../components/orders/quickActions/CreateOrderPage';
import OrdersQuickActionsPage from '../components/orders/quickActions/OrdersQuickActionsPage';
import OrdersQuickPrintingPage from 'pages/quickPrinting/OrdersQuickPrintingPage';
import PremiumOrdersQuickActionsPage from '../components/orders/quickActions/PremiumOrdersQuickActionsPage';
import OrderDetailsPage from '../components/orders/viewOrder/OrderDetailsPage';
import OrderedItemsPage from '../components/orders/orderedItems/OrderedItemsPage';
import DriversPage from '../components/delivery/drivers/DriversPage';
import EditItemsPage from '../pages/EditItemsPage';
import Notifications from '../pages/Notifications';
import OrderNotifications from '../pages/OrderNotifications';
import OrdersZonesPage from '../components/orders/ordersZones/OrdersZonesPage';
// products
import EditProductPage from '../pages/products/EditProductPage';
import SubscriptionsPage from '../pages/SubscriptionsPage';
import DeliverySlotsPage from '../components/delivery/deliverySlots/DeliverySlotsPage';
import QuickOrdersPage from '../pages/QuickOrdersPage';
// tickting
import TopicsPage from '../components/ticketing/topics/TopicsPage';
import OpsTicketsPage from '../pages/ticketing/opsTicketsList/OpsTicketsPage';
import TicketComments from '../components/ticketing/opsTicketsList/TicketComments';
import MyTicketsPage from '../pages/ticketing/opsTicketsList/MyTicketsPage';
import OpenedTicketsPage from '../pages/ticketing/opsTicketsList/OpenedTicketsPage';
import ClosedTicketsPage from '../pages/ticketing/opsTicketsList/ClosedTicketsPage';
import MyOpenTicketsPage from '../pages/ticketing/opsTicketsList/MyOpenTicketsPage';
import MyClosedTicketsPage from '../pages/ticketing/opsTicketsList/MyClosedTicketsPage';
import TechTicketsPage from '../pages/ticketing/techTicketsList/TechTicketsPage';
import OpenedTechTicketsPage from '../pages/ticketing/techTicketsList/OpenedTechTicketsPage';
import ClosedTechTicketsPage from '../pages/ticketing/techTicketsList/ClosedTechTicketsPage';
// trip
import TripDriversListPage from '../components/delivery/trip/TripDriversListPage';
// admin
import AdminPage from '../components/administration/pages/AdminPage';
import AdminAddPage from '../components/administration/pages/AdminAddPage';
import AdminEditPage from '../components/administration/pages/AdminEditPage';
import AdminGroups from '../components/administration/groups/AdminGroups';
import AdminEditGroup from '../components/administration/groups/AdminEditGroup';
import SearchUsers from '../components/administration/users/usersList/SearchUsers';
import DefaultAddressesPage from '../components/administration/users/userDefaultAddresses/DefaultAddressesPage';
import UsersActivityPage from 'components/administration/users/usersActivity/UsersActivityPage';
import UserInfoPage from '../components/administration/users/UserInfoPage';
import PickersPage from '../pages/users/PickersPage';
import PackersPage from '../pages/users/PackersPage';
import FloristsPage from '../pages/users/FloristsPage';
import UserEditPage from '../components/administration/users/UserEditPage';
import AdminMessage from '../components/administration/message/AdminMessage';
import NotificationsMessages from '../components/administration/notificationsMessages/NotificationsMessages';
// reports
import PriceBreakDownPage from '../pages/reports/products/PriceBreakDownPage';
import ProductBreakDownPage from '../pages/reports/products/ProductBreakDownPage';
import CategoryBreakDownPage from '../pages/reports/products/CategoryBreakDownPage';
import ProductTypeSalesPage from '../pages/reports/products/ProductTypeSalesPage';
import ProductTypeAttributePage from '../pages/reports/products/ProductTypeAttributePage';
import LiveProductsPage from '../pages/reports/products/LiveProductsPage';
import CartProductDiscountPage from '../pages/reports/products/CartProductDiscountPage';
import ProductTypeBreakDownPage from '../pages/reports/products/ProductTypeBreakDownPage';
import ProductCategoryPercentagePage from '../pages/reports/products/ProductCategoryPercentagePage';
import SalesReportPage from '../pages/reports/sales/SalesReportPage';
import OrdersReportPage from '../pages/reports/orders/OrdersReportPage';
import FinancialOrdersReportPage from '../pages/reports/orders/FinancialOrdersReportPage';
import DailyTrackerReportPage from '../pages/reports/orders/DailyTrackerReportPage';
import OrderAmountDetailReportPage from '../pages/reports/orders/OrderAmountDetailReportPage';
import RevenuesReportPage from '../pages/reports/orders/RevenuesReportPage';
import DeferredRevenuesReportPage from '../pages/reports/orders/DeferredRevenuesReportPage';
import DesignerCommissionReportPage from '../pages/reports/designers/DesignerCommissionReportPage';
import GiftsDailySpendReportPage from '../pages/reports/designers/GiftsDailySpendReportPage';
import DesignersDailyPerformancePage from '../pages/reports/designers/DesignersDailyPerformancePage';
import DesignersProductPerformancePage from '../pages/reports/designers/DesignersProductPerformancePage';
import DesignersReportPage from '../pages/reports/designers/DesignersReportPage';
import OrderCancellationReportPage from '../pages/reports/orders/OrderCancellationReportPage';
import BrandsReportPage from '../pages/reports/brands/BrandsReportPage';
import ProductWisePage from '../pages/reports/products/ProductWisePage';
import TopTenProductsPage from '../pages/reports/products/TopTenProductsPage';
import OldProductsPage from '../pages/reports/products/OldProductsPage';
import InventorySuppliersPage from '../pages/reports/inventory/InventorySuppliersPage';
import InventoryStatementActualDeliveryPage from '../pages/reports/inventory/InventoryStatementActualDeliveryPage';
import InventoryBalancePage from '../pages/reports/inventory/InventoryBalancePage';
import WalletBalancesPage from '../pages/reports/wallets/WalletBalancesPage';
import WalletTransactionsPage from '../pages/reports/wallets/WalletTransactionsPage';
// import VouchersReportPage from '../pages/reports/vouchers/VouchersReportPage';
import CouponDailySpendPage from '../pages/reports/vouchers/CouponDailySpendPage';
import DriverPerformanceReportPage from '../pages/reports/delivery/DriverPerformanceReportPage';
import DeliveryPerformanceReportPage from '../pages/reports/delivery/DeliveryPerformanceReportPage';
import DriverLogisticsReportPage from '../pages/reports/delivery/DriverLogisticsReportPage';
import DriverPerformanceDetailsReportPage from '../pages/reports/delivery/DriverPerformanceDetailsReportPage';
import DriverOrdersReportPage from '../pages/reports/delivery/DriverOrdersReportPage';
import RegisteredUsersReportPage from '../pages/reports/registeredUsers/RegisteredUsersReportPage';
import HourseBreakDownPage from '../pages/reports/hourseBreakDown/HourseBreakDownPage';
import AreasBreakDownPage from '../pages/reports/areasBreakDown/AreasBreakDownPage';
import FloristPerformanceReportPage from '../pages/reports/florists/FloristPerformanceReportPage';
import SalesPerformanceReportPage from '../pages/reports/inventory/SalesPerformanceReportPage';
import SalesPerformanceDetailsReportPage from '../pages/reports/inventory/SalesPerformanceDetailsReportPage';
// inventory
import InventoryItemsPage from '../pages/inventory/InventoryItemsPage';
import InventoryAllItemsPage from '../pages/inventory/InventoryAllItemsPage';
import InventoryAllItemsOpsPage from '../pages/inventory/InventoryAllItemsAllOpsPage';
import InventorySuppliers from '../pages/inventory/InventorySuppliersPage';
import AlertQtyItemsPage from '../pages/inventory/AlertQtyItemsPage';
import EditInventoryPage from '../pages/inventory/EditInventoryPage';
import StatementsReportPage from '../pages/reports/inventory/StatementsReportPage';
import FinanceInventoryStatementPage from '../pages/reports/inventory/FinanceInventoryStatementPage';
import StatementsCancelledReportPage from '../pages/reports/inventory/StatementsCancelledReportPage';
import StatementsDamagedReportPage from '../pages/reports/inventory/StatementsDamagedReportPage';
import InventoryReportPage from '../pages/reports/inventory/InventoryReportPage';
// master-inventory
import MasterInventoryPage from '../pages/inventory/MasterInventoryPage';
import EditMasterInventoryPage from '../pages/inventory/EditMasterInventoryPage';
// customer service
import CustomerServicePage from '../pages/CustomerServicePage';
// Generate Payment link
import GeneratePaymentLinkPage from '../pages/GeneratePaymentLinkPage';
// occasions
import OccasionsPage from '../pages/OccasionsPage';
// categories
import CategoriesPage from '../pages/categories/CategoriesPage';
import MerchandisingCategoriesPage from '../pages/categories/MerchandisingCategoriesPage';
import SubCategoriesPage from '../pages/SubCategoriesPage';
import SecondSubCategoriesPage from '../pages/SecondSubCategoriesPage';
import EditCategoryPage from '../pages/EditCategoryPage';
// late orders
import LateOrdersPage from '../pages/LateOrdersPage';
// Delivery areas
import AreasPage from '../pages/deliveryAreas/AreasPage';
import SubAreasPage from '../pages/deliveryAreas/SubAreasPage';
import EditAreaPage from '../pages/deliveryAreas/EditAreaPage';
import EditSubAreaPage from '../pages/deliveryAreas/EditSubAreaPage';
import DriverRadiusPage from '../pages/delivery/DriverRadiusPage';
import CoverageAreasPage from '../pages/deliveryAreas/CoverageAreasPage';
// drivers expenses
import DriversExpensesPage from '../pages/delivery/DriversExpensesPage';
// Banners
import BannersPage from '../pages/BannersPage';
import NewBannerPage from '../pages/NewBannerPage';
import EditBannerPage from '../pages/EditBannerPage';
// florist community
import FloristCommunityPage from '../pages/FloristCommunityPage';
import FloristDesignEditPage from '../pages/FloristDesignEditPage';
import FloristTransactionsPage from '../pages/FloristTransactionsPage';
// Delivery postcode
import CoveragePostcodePage from '../pages/CoveragePostcodePage';
import EditCoveragePostCodePage from '../pages/EditCoveragePostCodePage';
// vouchers
import AllCouponPage from '../pages/vouchers/AllCouponPage';
import EditCouponPage from '../pages/vouchers/EditCouponPage';
import AddCouponPage from '../pages/vouchers/AddCouponPage';
// master catalog
import ActiveInstockPage from '../pages/masterCatalog/ActiveInstockPage';
import ActiveOutstockPage from '../pages/masterCatalog/ActiveOutstockPage';
import InactiveInstockPage from '../pages/masterCatalog/InactiveInstockPage';
import InactiveOutstockPage from '../pages/masterCatalog/InactiveOutstockPage';
import DraftCatalogPage from '../pages/masterCatalog/DraftCatalogPage';
import ArchiveCatalogPage from '../pages/masterCatalog/ArchiveCatalogPage';
import TotalCatalogPage from 'pages/masterCatalog/TotalCatalogPage';
import UncategorizedMerchandise from 'pages/masterCatalog/UncategorizedMerchandise';
// duplicated and replaced
import DuplicatedAndReplacedOrdersPage from '../pages/DuplicatedAndReplacedOrdersPage';
// celebrities
import CelebritiesPage from '../pages/celebrities/CelebritiesPage';
import AddCelebrityPage from '../pages/celebrities/AddCelebrityPage';
import EditCelebrityPage from '../pages/celebrities/EditCelebrityPage';
// kpi
import KpiSummaryPage from '../pages/KpiSummaryPage';
// corporate reports
import CorporateReportPage from '../pages/reports/corporate/CorporateReportPage';
import CustomerCorporateReportPage from '../pages/reports/corporate/CustomerCorporateReportPage';
// ranking products
import RankingProductsPage from '../pages/RankingProductsPage';
// country slots
import CountrySlotsPage from '../pages/CountrySlotsPage';
// customer loyality page
import CustomerLoyalityPage from '../pages/CustomerLoyalityPage';
// key value page
import KeyValuesPage from '../pages/keyValues/KeyValuesPage';
// seasonal-prep
import SeasonalPrepPage from '../pages/SeasonalPrepPage';
// invoices
import InvoicesPage from '../pages/invoices/InvoicesPage';
import CorporateInvoicesPage from 'pages/invoices/CorporateInvoicesPage';
import ConsolidatedInvoicesPage from 'pages/invoices/ConsolidatedInvoicesPage';
import GeneratedConsolidatedInvoicesPage from 'pages/invoices/GeneratedConsolidatedInvoicesPage';
// shorer link
import ShorterLinkPage from '../pages/shorterLink/ShorterLinkPage';
// attributes
import AttributesPage from 'pages/attributes/AttributesPage';
import EditAttribute from 'components/attributes/EditAttribute';
import SubAttributes from 'components/attributes/subAttributes/SubAttributes';
// Production system
import ProductionPage from 'pages/productionSystem/ProductionPage';

// urls
import {
  getHomePageUrl,
  getCreateOrderPageUrl,
  getOrderQuickActionsPageUrl,
  getOrderQuickPrintingPageUrl,
  getPremiumQuickActionsPageUrl,
  getLoginPageUrl,
  getOrderDetailsPageUrl,
  getOrderedItemsPageUrl,
  getDriversPageUrl,
  getAdminPagesUrl,
  getAdminAddPageUrl,
  getAdminEditPageUrl,
  getAdminGroupsUrl,
  getAdminEditGroupUrl,
  getAdminUsersUrl,
  getUserDefaultAddressesPageUrl,
  getUsersActivityPageUrl,
  getPickersPageUrl,
  getPackersPageUrl,
  getFloristsPageUrl,
  getAdminUserInfo,
  getEditUserPage,
  getAdminNotificationsMessagesUrl,
  getEditItemsPageUrl,
  getAdminMessageUrl,
  getNotificationsPageUrl,
  getOrderNotificationsPageUrl,
  getOrdersZonesPageUrl,
  // products
  getEditProductPageUrl,
  getSubscriptionsPage,
  getDeliverySlotsPageUrl,
  getQuickOrdersPageUrl,
  // ticketing
  getTopicsPageUrl,
  getOpsTicketsPageUrl,
  getTicketCommentsPageUrl,
  getMyTicketsPage,
  getOpenedTicketsPage,
  getClosedTicketsPage,
  getMyOpenedTicketsPage,
  getMyClosedTicketsPage,
  getTechTicketsPageUrl,
  getOpenedTechTicketsPageUrl,
  getClosedTechTicketsPageUrl,
  // trip
  getTripDriversListPage,
  // reports
  // product
  getProductPriceBreakDown,
  getProductBreakDown,
  getCategoryBreakDown,
  getProductTypeSales,
  getProductTypeAttribute,
  getLiveProductsReport,
  getCartProductsDiscounts,
  getProductTypeBreakDown,
  getProductCategoryPercentage,
  getOrdersProductWisePage,
  getTopTenProductPage,
  getOldProductPage,
  // sales
  getSalesReportPageUrl,
  // orders
  getOrdersReportPageUrl,
  getFinancialOrdersPageUrl,
  getDailyTrackerOrdersPageUrl,
  getOrderAmountDetailReportPageUrl,
  getRevenuesReportPageUrl,
  getDeferredRevenuesReportPageUrl,
  // inventory report
  getInventorySupplierReportPageUrl,
  getInventoryStatementActualDeliveryReportPageUrl,
  getInventoryBalanceReportPageUrl,
  getSalesPerformanceReportPageUrl,
  getSalesPerformanceDetailsReportPageUrl,
  // designers
  getDesignerCommissionPageUrl,
  getGiftsDailySpendPageUrl,
  getDesignersDailyPerformancePageUrl,
  getDesignersProductPerformancePageUrl,
  getDesignersReportPageUrl,
  getOrderCancellationPageUrl,
  // florists
  getFloristsPerformancePageUrl,
  // wallet
  getWalletBalancesPageUrl,
  getWalletTransactionsPageUrl,
  getCustomerLoyalityPageUrl,
  // vouvhers
  // getVouchersReportPageUrl,
  getCouponDailySpendReportPageUrl,
  // delivery
  getDriverPerformanceReportPageUrl,
  getDeliveryPerformanceReportPageUrl,
  getDriverLogisticsReportPageUrl,
  getDriverPerformanceDetailsReportPageUrl,
  getDriverOrdersReportPageUrl,
  // brands
  getBrandsReportPageUrl,
  // registered users
  getRegisteredUsersPageUrl,
  // hours & areas breakdown
  getHoursBreakdownPageUrl,
  getAreasBreakdownPageUrl,
  // customer service
  getCustomerServicePageUrl,
  // Payment Link
  getGeneratePaymentLinkPageUrl,
  // inventory
  getInventoryItemsPageUrl,
  getAllInventoryItemsPageUrl,
  getAllInventoryItemsAllOpsPageUrl,
  getInventorySuppliersPageUrl,
  getAlertQtyInventoryItemsPageUrl,
  getStatementsReportPageURL,
  getFinanceInventoryStatementsPageUrl,
  getEditInventoryPageUrl,
  getStatementsCancelledReportPageURL,
  getStatementsDamagedReportPageURL,
  getInventoryReportPageURL,
  // master-inventory
  getMasterInventoryPageURL,
  getEditMasterInventoryPageURL,
  // occasions
  getOccasionsPageUrl,
  // categories
  getCategoriesPage,
  getMerchandisingCategoriesPage,
  getSubMerchandisingCategoriesPage,
  // getEditMerchandisingCategoriesPage,
  getEditCategoryPage,
  getSubCategoriesPage,
  getSecondSubCategoriesPage,
  // late orders
  getLateOrdersPageUrl,
  // Delivery areas
  getDeliveryAreasPageUrl,
  getDeliverySubAreasPageUrl,
  getEditDeliveryAreasPageUrl,
  getEditSubAreaPageUrl,
  getDriverRadiusPageUrl,
  getCoverageAreasPageUrl,
  // banner
  getBannersPageURL,
  getNewBannerPageURL,
  getEditBannerPageURL,
  // florist community
  getFloristcommunityUnderReviewPageUrl,
  getFloristcommunityAcceptedPageUrl,
  getFloristcommunityRejectedPageUrl,
  getFloristcommunityDeletedPageUrl,
  getFloristDesignEditPageUrl,
  getFloristTransactionsPageUrl,
  getFloristTransactionEditPageUrl,
  // Delivery postcode
  getCoveragePostcodePage,
  getEditCoveragePostcodePage,
  // drivers expenses
  getDriversExpensesPageUrl,
  // vouchers
  getVouchersListPageUrl,
  getEditCouponPage,
  getAddCouponPage,
  // master catalog
  getActiveInstockPageUrl,
  getActiveOutstockPageUrl,
  getInactiveInstockPageUrl,
  getInactiveOutstockPageUrl,
  getDraftCatalogPageUrl,
  getArchiveCatalogPageUrl,
  getTotalCatalogPageUrl,
  getUncategorizedMerchanidsePageUrl,
  // duplcated and replaced
  getDuplicatedAndReplacedPageUrl,
  // celebrities
  getCelebritiesPageUrl,
  getAddCelebrityPageUrl,
  getEditCelebrityPageUrl,
  // kpi
  getKpiPageUrl,
  // corporate reports
  getCorporateReportPageUrl,
  getCustomerCorporateReportPageUrl,
  // ranking products
  getRankingProductsPage,
  // country slots
  getCountrySlotsPageUrl,
  // key values
  getKeyValuesPageUrl,
  // seasonal prep
  getSeasonalPrepPageUrl,
  // invoices
  getInvoicesPageUrl,
  getCorporateInvoicesPageUrl,
  getConsolidatedInvoicesPageUrl,
  getGeneratedInvoicesPageUrl,
  // shorter link
  getShorterLinkPageUrl,
  // attributes
  getAttributesPageUrl,
  getSubAttributesPage,
  getEditAttributesPage,
  getEditSubAttributesPage,
  // production system
  getProductionPage,
  getFeaturedOccasionPageUrl,
  newFeaturedOccasionPageUrl,
  editFeaturedOccasionPageUrl,
  getUserFeedbackPageUrl,
} from '../constant/AppUrls';
import FloristTransactionEditPage from '../pages/FloristTransactionEditPage';
import FeaturedOccasionsPage from 'pages/featuredOccasions/FeaturedOccasions';
import NewFeaturedOccasions from 'components/featuredOccasions/NewFeaturedOccasions';
import EditFeaturedOccasions from 'components/featuredOccasions/EditFeaturedOccasions';
import UserFeedbackPage from 'pages/userFeedback/UserFeedbackPage';

export const registerationRoutes = [{ path: getLoginPageUrl(), component: LoginPage }];

export const privateRoutes = [];

export const restrictedRoutes = [
  {
    path: getHomePageUrl(),
    component: HomePage,
    permissions: 'dashboard',
    exact: true,
  },
  {
    path: getCreateOrderPageUrl(),
    component: CreateOrderPage,
    permissions: 'create_bulk_action',
  },
  {
    path: getOrderQuickActionsPageUrl(),
    component: OrdersQuickActionsPage,
    permissions: 'quick_actions',
  },
  {
    path: getOrderQuickPrintingPageUrl(),
    component: OrdersQuickPrintingPage,
    permissions: 'quick_actions',
  },
  {
    path: getPremiumQuickActionsPageUrl(),
    component: PremiumOrdersQuickActionsPage,
    permissions: 'quick_actions',
  },
  {
    path: getOrderDetailsPageUrl(),
    component: OrderDetailsPage,
    permissions: 'dashboard',
  },
  {
    path: getOrderedItemsPageUrl(),
    component: OrderedItemsPage,
    permissions: 'ordered_items',
  },
  {
    path: getQuickOrdersPageUrl(),
    component: QuickOrdersPage,
    permissions: 'quick_orders',
  },
  {
    path: getDriversPageUrl(),
    component: DriversPage,
    permissions: 'drivers',
  },
  // administration
  {
    path: getAdminPagesUrl(),
    component: AdminPage,
    exact: true,
    permissions: 'admin_pages',
  },
  {
    path: getAdminAddPageUrl(),
    component: AdminAddPage,
    permissions: 'admin_pages',
  },
  {
    path: getAdminEditPageUrl(),
    component: AdminEditPage,
    permissions: 'admin_pages',
  },
  {
    path: getAdminGroupsUrl(),
    component: AdminGroups,
    exact: true,
    permissions: 'admin_groups',
  },
  {
    path: getAdminEditGroupUrl(),
    component: AdminEditGroup,
    permissions: 'admin_groups',
  },
  {
    path: getAdminUsersUrl(),
    component: SearchUsers,
    exact: true,
    permissions: 'admin_users',
  },
  {
    path: getUserDefaultAddressesPageUrl(),
    component: DefaultAddressesPage,
    exact: true,
    permissions: 'users_addresses',
  },
  {
    path: getUsersActivityPageUrl(),
    component: UsersActivityPage,
    exact: true,
    permissions: 'users_activity',
  },
  {
    path: getPickersPageUrl(),
    component: PickersPage,
    exact: true,
    permissions: 'admin_pickers',
  },
  {
    path: getPackersPageUrl(),
    component: PackersPage,
    exact: true,
    permissions: 'admin_pickers',
  },
  {
    path: getFloristsPageUrl(),
    component: FloristsPage,
    exact: true,
    permissions: 'admin_florists',
  },
  {
    path: getAdminUserInfo(),
    component: UserInfoPage,
    permissions: 'admin_user_info',
  },
  {
    path: getEditUserPage(),
    component: UserEditPage,
    permissions: 'admin_edit_picker',
  },
  { path: getAdminMessageUrl(), component: AdminMessage, permissions: 'admin_messages' },
  {
    path: getAdminNotificationsMessagesUrl(),
    component: NotificationsMessages,
    permissions: 'admin_notifications',
  },
  // edit items page
  { path: getEditItemsPageUrl(), component: EditItemsPage, permissions: 'edit_items' },
  // notifications
  {
    path: getNotificationsPageUrl(),
    component: Notifications,
    exact: true,
    permissions: 'notifications',
  },
  {
    path: getOrderNotificationsPageUrl(),
    component: OrderNotifications,
    permissions: 'notifications',
  },
  { path: getOrdersZonesPageUrl(), component: OrdersZonesPage, permissions: 'orders_zones' },
  // products
  { path: getEditProductPageUrl(), component: EditProductPage, permissions: 'edit_product' },

  { path: getSubscriptionsPage(), component: SubscriptionsPage, permissions: 'subscriptions' },
  { path: getDeliverySlotsPageUrl(), component: DeliverySlotsPage, permissions: 'delivery_slots' },
  { path: getTopicsPageUrl(), component: TopicsPage, permissions: 'topics' },
  // tickets
  { path: getOpsTicketsPageUrl(), component: OpsTicketsPage, permissions: 'tickets' },
  { path: getMyTicketsPage(), component: MyTicketsPage, permissions: 'tickets' },
  { path: getTicketCommentsPageUrl(), component: TicketComments, permissions: 'tickets' },
  { path: getOpenedTicketsPage(), component: OpenedTicketsPage, permissions: 'tickets' },
  { path: getClosedTicketsPage(), component: ClosedTicketsPage, permissions: 'tickets' },
  { path: getMyOpenedTicketsPage(), component: MyOpenTicketsPage, permissions: 'tickets' },
  { path: getMyClosedTicketsPage(), component: MyClosedTicketsPage, permissions: 'tickets' },
  { path: getTechTicketsPageUrl(), component: TechTicketsPage, permissions: 'tickets' },
  { path: getOpenedTechTicketsPageUrl(), component: OpenedTechTicketsPage, permissions: 'tickets' },
  { path: getClosedTechTicketsPageUrl(), component: ClosedTechTicketsPage, permissions: 'tickets' },
  //featured occasion
  {
    path: getFeaturedOccasionPageUrl(),
    component: FeaturedOccasionsPage,
    permissions: 'occasions',
  },
  { path: newFeaturedOccasionPageUrl(), component: NewFeaturedOccasions, permissions: 'occasions' },
  {
    path: editFeaturedOccasionPageUrl(),
    component: EditFeaturedOccasions,
    permissions: 'occasions',
  },

  // trip
  { path: getTripDriversListPage(), component: TripDriversListPage, permissions: 'trip' },
  // Delivery postcode
  {
    path: getEditCoveragePostcodePage(),
    component: EditCoveragePostCodePage,
    permissions: 'coverage_postcode',
  },
  {
    path: getCoveragePostcodePage(),
    component: CoveragePostcodePage,
    permissions: 'coverage_postcode',
  },
  // drivers expenses
  {
    path: getDriversExpensesPageUrl(),
    component: DriversExpensesPage,
    permissions: 'drivers_expenses',
  },
  // reports-product (permission: reports)
  { path: getProductPriceBreakDown(), component: PriceBreakDownPage, permissions: 'reports' },
  { path: getProductBreakDown(), component: ProductBreakDownPage, permissions: 'reports' },
  { path: getCategoryBreakDown(), component: CategoryBreakDownPage, permissions: 'reports' },
  { path: getProductTypeSales(), component: ProductTypeSalesPage, permissions: 'reports' },
  { path: getProductTypeAttribute(), component: ProductTypeAttributePage, permissions: 'reports' },
  { path: getLiveProductsReport(), component: LiveProductsPage, permissions: 'reports' },
  { path: getCartProductsDiscounts(), component: CartProductDiscountPage, permissions: 'reports' },
  { path: getProductTypeBreakDown(), component: ProductTypeBreakDownPage, permissions: 'reports' },
  {
    path: getProductCategoryPercentage(),
    component: ProductCategoryPercentagePage,
    permissions: 'reports',
  },
  {
    path: getOrdersProductWisePage(),
    component: ProductWisePage,
    permissions: 'reports',
  },
  {
    path: getTopTenProductPage(),
    component: TopTenProductsPage,
    permissions: 'reports',
  },
  {
    path: getOldProductPage(),
    component: OldProductsPage,
    permissions: 'reports',
  },
  // reports-sales
  { path: getSalesReportPageUrl(), component: SalesReportPage, permissions: 'reports' },
  // reports-orders
  { path: getOrdersReportPageUrl(), component: OrdersReportPage, permissions: 'reports' },
  {
    path: getFinancialOrdersPageUrl(),
    component: FinancialOrdersReportPage,
    permissions: 'reports',
  },
  {
    path: getDailyTrackerOrdersPageUrl(),
    component: DailyTrackerReportPage,
    permissions: 'reports',
  },
  {
    path: getOrderAmountDetailReportPageUrl(),
    component: OrderAmountDetailReportPage,
    permissions: 'reports',
  },
  {
    path: getRevenuesReportPageUrl(),
    component: RevenuesReportPage,
    permissions: 'reports',
  },
  {
    path: getDeferredRevenuesReportPageUrl(),
    component: DeferredRevenuesReportPage,
    permissions: 'reports',
  },

  // report-inventory
  {
    path: getInventorySupplierReportPageUrl(),
    component: InventorySuppliersPage,
    permissions: 'reports',
  },
  {
    path: getInventoryStatementActualDeliveryReportPageUrl(),
    component: InventoryStatementActualDeliveryPage,
    permissions: 'reports',
  },
  {
    path: getInventoryBalanceReportPageUrl(),
    component: InventoryBalancePage,
    permissions: 'reports',
  },
  { path: getStatementsReportPageURL(), component: StatementsReportPage, permissions: 'reports' },
  {
    path: getFinanceInventoryStatementsPageUrl(),
    component: FinanceInventoryStatementPage,
    permissions: 'reports',
  },
  {
    path: getStatementsCancelledReportPageURL(),
    component: StatementsCancelledReportPage,
    permissions: 'reports',
  },
  {
    path: getStatementsDamagedReportPageURL(),
    component: StatementsDamagedReportPage,
    permissions: 'reports',
  },
  { path: getInventoryReportPageURL(), component: InventoryReportPage, permissions: 'reports' },
  {
    path: getSalesPerformanceReportPageUrl(),
    component: SalesPerformanceReportPage,
    permissions: 'reports',
  },
  {
    path: getSalesPerformanceDetailsReportPageUrl(),
    component: SalesPerformanceDetailsReportPage,
    permissions: 'reports',
  },
  // reports-designers (permissions: reports)
  {
    path: getDesignerCommissionPageUrl(),
    component: DesignerCommissionReportPage,
    permissions: 'reports',
  },
  {
    path: getGiftsDailySpendPageUrl(),
    component: GiftsDailySpendReportPage,
    permissions: 'reports',
  },
  {
    path: getDesignersDailyPerformancePageUrl(),
    component: DesignersDailyPerformancePage,
    permissions: 'reports',
  },
  {
    path: getDesignersProductPerformancePageUrl(),
    component: DesignersProductPerformancePage,
    permissions: 'reports',
  },
  { path: getDesignersReportPageUrl(), component: DesignersReportPage, permissions: 'reports' },
  {
    path: getOrderCancellationPageUrl(),
    component: OrderCancellationReportPage,
    permissions: 'reports',
  },
  // reports-wallet
  {
    path: getWalletBalancesPageUrl(),
    component: WalletBalancesPage,
    permissions: 'reports',
  },
  {
    path: getWalletTransactionsPageUrl(),
    component: WalletTransactionsPage,
    permissions: 'reports',
  },
  // reports-vouchers
  // {
  // path: getVouchersReportPageUrl(),
  // component: VouchersReportPage,
  // permissions: 'reports',
  // },
  {
    path: getCouponDailySpendReportPageUrl(),
    component: CouponDailySpendPage,
    permissions: 'reports',
  },
  // reports-delivery (permissions: reports)
  {
    path: getDriverPerformanceReportPageUrl(),
    component: DriverPerformanceReportPage,
    permissions: 'reports',
  },
  {
    path: getDeliveryPerformanceReportPageUrl(),
    component: DeliveryPerformanceReportPage,
    permissions: 'reports',
  },
  {
    path: getDriverLogisticsReportPageUrl(),
    component: DriverLogisticsReportPage,
    permissions: 'reports',
  },
  {
    path: getDriverPerformanceDetailsReportPageUrl(),
    component: DriverPerformanceDetailsReportPage,
    permissions: 'reports',
  },
  {
    path: getDriverOrdersReportPageUrl(),
    component: DriverOrdersReportPage,
    permissions: 'reports',
  },
  // reports-brands (permissions: reports)
  { path: getBrandsReportPageUrl(), component: BrandsReportPage, permissions: 'reports' },
  // report-registered users
  {
    path: getRegisteredUsersPageUrl(),
    component: RegisteredUsersReportPage,
    permissions: 'reports',
  },
  // report-hours & areas breakdown (permissions: reports)
  {
    path: getHoursBreakdownPageUrl(),
    component: HourseBreakDownPage,
    permissions: 'reports',
  },
  {
    path: getAreasBreakdownPageUrl(),
    component: AreasBreakDownPage,
    permissions: 'reports',
  },
  {
    path: getFloristsPerformancePageUrl(),
    component: FloristPerformanceReportPage,
    permissions: 'reports',
  },
  // customer service
  {
    path: getCustomerServicePageUrl(),
    component: CustomerServicePage,
    permissions: 'customer_service',
  },
  // Generate Payment Link
  {
    path: getGeneratePaymentLinkPageUrl(),
    component: GeneratePaymentLinkPage,
    permissions: 'generate_payment_link',
  },
  // inventory
  { path: getInventoryItemsPageUrl(), component: InventoryItemsPage, permissions: 'inventory' },
  {
    path: getAllInventoryItemsPageUrl(),
    component: InventoryAllItemsPage,
    permissions: 'inventory',
  },
  {
    path: getAllInventoryItemsAllOpsPageUrl(),
    component: InventoryAllItemsOpsPage,
    permissions: 'inventory',
  },
  {
    path: getInventorySuppliersPageUrl(),
    component: InventorySuppliers,
    permissions: 'inventory',
  },
  { path: getEditInventoryPageUrl(), component: EditInventoryPage, permissions: 'inventory' },
  {
    path: getAlertQtyInventoryItemsPageUrl(),
    component: AlertQtyItemsPage,
    permissions: 'alert_qty_items',
  },
  // master-inventory
  {
    path: getEditMasterInventoryPageURL(),
    component: EditMasterInventoryPage,
    permissions: 'edit_master_inventory',
  },
  {
    path: getMasterInventoryPageURL(),
    component: MasterInventoryPage,
    permissions: 'master_inventory',
  },
  // occcaions
  { path: getOccasionsPageUrl(), component: OccasionsPage, permissions: 'occasions' },
  // categories
  { path: getSubCategoriesPage(), component: SubCategoriesPage, permissions: 'categories' },
  {
    path: getSecondSubCategoriesPage(),
    component: SecondSubCategoriesPage,
    permissions: 'categories',
  },
  {
    path: getSubMerchandisingCategoriesPage(),
    component: MerchandisingCategoriesPage,
    permissions: 'categories',
  },
  {
    path: getMerchandisingCategoriesPage(),
    component: MerchandisingCategoriesPage,
    permissions: 'categories',
  },
  { path: getEditCategoryPage(), component: EditCategoryPage, permissions: 'categories' },
  { path: getCategoriesPage(), component: CategoriesPage, permissions: 'categories' },
  // late orders (permissions: late_orders)
  { path: getLateOrdersPageUrl(), component: LateOrdersPage, permissions: 'late_orders' },
  // Delivery areas
  { path: getEditSubAreaPageUrl(), component: EditSubAreaPage, permissions: 'areas' },
  { path: getDeliverySubAreasPageUrl(), component: SubAreasPage, permissions: 'areas' },
  { path: getEditDeliveryAreasPageUrl(), component: EditAreaPage, permissions: 'areas' },
  { path: getDeliveryAreasPageUrl(), component: AreasPage, permissions: 'areas' },
  { path: getDriverRadiusPageUrl(), component: DriverRadiusPage, permissions: 'driver_radius' },
  //coverage areas
  { path: getCoverageAreasPageUrl(), component: CoverageAreasPage, permissions: 'coverage_areas' },
  // banners
  { path: getNewBannerPageURL(), component: NewBannerPage, permissions: 'banners' },
  { path: getEditBannerPageURL(), component: EditBannerPage, permissions: 'banners' },
  { path: getBannersPageURL(), component: BannersPage, permissions: 'banners' },
  // florist community
  {
    path: getFloristDesignEditPageUrl(),
    component: FloristDesignEditPage,
    permissions: 'florist_community',
  },
  {
    path: getFloristcommunityUnderReviewPageUrl(),
    component: FloristCommunityPage,
    permissions: 'florist_community',
  },
  {
    path: getFloristcommunityAcceptedPageUrl(),
    component: FloristCommunityPage,
    permissions: 'florist_community',
  },
  {
    path: getFloristcommunityRejectedPageUrl(),
    component: FloristCommunityPage,
    permissions: 'florist_community',
  },
  {
    path: getFloristcommunityDeletedPageUrl(),
    component: FloristCommunityPage,
    permissions: 'florist_community',
  },
  {
    path: getFloristTransactionsPageUrl(),
    component: FloristTransactionsPage,
    permissions: 'florist_community',
  },
  {
    path: getFloristTransactionEditPageUrl(),
    component: FloristTransactionEditPage,
    permissions: 'florist_community',
  },
  // vouchers
  { path: getVouchersListPageUrl(), component: AllCouponPage, permissions: 'vouchers' },
  { path: getEditCouponPage(), component: EditCouponPage, permissions: 'vouchers' },
  { path: getAddCouponPage(), component: AddCouponPage, permissions: 'vouchers' },
  // master catalog
  { path: getActiveInstockPageUrl(), component: ActiveInstockPage, permissions: 'master_catalog' },
  {
    path: getActiveOutstockPageUrl(),
    component: ActiveOutstockPage,
    permissions: 'master_catalog',
  },
  {
    path: getInactiveInstockPageUrl(),
    component: InactiveInstockPage,
    permissions: 'master_catalog',
  },
  {
    path: getInactiveOutstockPageUrl(),
    component: InactiveOutstockPage,
    permissions: 'master_catalog',
  },
  { path: getDraftCatalogPageUrl(), component: DraftCatalogPage, permissions: 'master_catalog' },
  {
    path: getArchiveCatalogPageUrl(),
    component: ArchiveCatalogPage,
    permissions: 'master_catalog',
  },
  {
    path: getTotalCatalogPageUrl(),
    component: TotalCatalogPage,
    permissions: 'master_catalog',
  },
  {
    path: getUncategorizedMerchanidsePageUrl(),
    component: UncategorizedMerchandise,
    permissions: 'master_catalog',
  },
  // duplicated and replaced
  {
    path: getDuplicatedAndReplacedPageUrl(),
    component: DuplicatedAndReplacedOrdersPage,
    permissions: 'duplicated_replaced',
  },
  // celebrities
  { path: getEditCelebrityPageUrl(), component: EditCelebrityPage, permissions: 'celebrities' },
  { path: getAddCelebrityPageUrl(), component: AddCelebrityPage, permissions: 'celebrities' },
  { path: getCelebritiesPageUrl(), component: CelebritiesPage, permissions: 'celebrities' },
  // kpi
  { path: getKpiPageUrl(), component: KpiSummaryPage, permissions: 'kpi' },
  // corporate reports (permissions: corporate_report)
  {
    path: getCorporateReportPageUrl(),
    component: CorporateReportPage,
    permissions: 'reports',
  },
  {
    path: getCustomerCorporateReportPageUrl(),
    component: CustomerCorporateReportPage,
    permissions: 'reports',
  },
  // ranking products
  {
    path: getRankingProductsPage(),
    component: RankingProductsPage,
    permissions: 'ranking_products',
  },
  // country slots
  { path: getCountrySlotsPageUrl(), component: CountrySlotsPage, permissions: 'country_slots' },
  // customer loyality
  {
    path: getCustomerLoyalityPageUrl(),
    component: CustomerLoyalityPage,
    permissions: 'customer_loyalty_points',
  },
  // key value
  {
    path: getKeyValuesPageUrl(),
    component: KeyValuesPage,
    permissions: 'key_value',
  },
  // seasonal prep
  { path: getSeasonalPrepPageUrl(), component: SeasonalPrepPage, permissions: 'seasonal_prep' },
  // Invoices
  {
    path: getInvoicesPageUrl(),
    component: InvoicesPage,
    permissions: 'invoices',
  },
  {
    path: getCorporateInvoicesPageUrl(),
    component: CorporateInvoicesPage,
    permissions: 'corporate_invoices',
  },
  {
    path: getConsolidatedInvoicesPageUrl(),
    component: ConsolidatedInvoicesPage,
    permissions: 'consolidated_invoices',
  },
  {
    path: getGeneratedInvoicesPageUrl(),
    component: GeneratedConsolidatedInvoicesPage,
    permissions: 'consolidated_invoices',
  },
  // shorter link page
  {
    path: getShorterLinkPageUrl(),
    component: ShorterLinkPage,
    permissions: 'shorter_link',
  },
  // attributes
  {
    path: getSubAttributesPage(),
    component: SubAttributes,
    permissions: 'attributes',
  },
  {
    path: getAttributesPageUrl(),
    component: AttributesPage,
    permissions: 'attributes',
  },
  {
    path: getEditAttributesPage(),
    component: EditAttribute,
    permissions: 'attributes',
  },
  {
    path: getEditSubAttributesPage(),
    component: EditAttribute,
    permissions: 'attributes',
  },
  // production system
  { path: getProductionPage(), component: ProductionPage, permissions: 'production_system' },
  // user feedback
  { path: getUserFeedbackPageUrl(), component: UserFeedbackPage, permissions: 'user_feedback' },
];
