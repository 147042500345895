import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomSelect from 'components/shared/CustomSelect';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// selectors
import { getAllMainAreasData } from 'store/delivery/deliveryAreas/areasSelectors';
// actions
import { getMainAreas } from 'store/delivery/deliveryAreas/areasActions';
// constants
import { localStorageKeys } from 'constant/Enums';

const AreaForm = ({
  mainArea,
  handleMainAreaChange,
  enName,
  setEnName,
  arName,
  setArName,
  isActive,
  setIsActive,
  type,
}) => {
  const mainAreaList = useSelector((state) => getAllMainAreasData({ state }));
  const dispatch = useDispatch();

  const handleCountryChange = (e) => {
    // console.log('disabled');
  };

  useEffect(() => {
    if (type !== 'area') {
      dispatch(getMainAreas());
    }
  }, []);

  return (
    <>
      <div
        className={`form-group ${type !== 'area' ? 'col-6' : 'col-12'} filter-select area-select`}
      >
        <label className="bold-title" htmlFor="area-country">
          Country
        </label>
        <CustomSelect
          id="area-country"
          listData={[
            {
              countryID: getFromLocalStorage(localStorageKeys.OPS_CENTER).id,
              nickName: getFromLocalStorage(localStorageKeys.OPS_CENTER).nickName,
            },
          ]}
          handleChange={handleCountryChange}
          placeholder="Select a country"
          textPropName="nickName"
          valuePropName="countryID"
          selectedValue={getFromLocalStorage(localStorageKeys.OPS_CENTER).id}
          disabled={true}
        />
      </div>
      {type !== 'area' && (
        <div className="form-group col-6 filter-select area-select">
          <label className="bold-title" htmlFor="main-area">
            Main Area
          </label>
          <CustomSelect
            id="main-area"
            listData={mainAreaList.Data}
            handleChange={handleMainAreaChange}
            placeholder="Select a country"
            textPropName="Name"
            valuePropName="Id"
            selectedValue={mainArea}
          />
        </div>
      )}
      <div className="form-group col-6">
        <label className="bold-title" htmlFor="area-enName">
          Name English
        </label>
        <input
          id="area-enName"
          value={enName}
          onChange={(e) => setEnName(e.target.value)}
          className="form-control"
          type="text"
        />
      </div>
      <div className="form-group col-6">
        <label className="bold-title" htmlFor="area-arName">
          Name Arabic
        </label>
        <input
          id="area-arName"
          value={arName}
          onChange={(e) => setArName(e.target.value)}
          className="form-control"
          type="text"
        />
      </div>
      {type === 'area' && (
        <div className="form-group col-12 ">
          <input
            className="checkbox_animated"
            type="checkbox"
            checked={isActive}
            onChange={(e) => setIsActive((prev) => !prev)}
          />
          <span className="bold-title">Active</span>
        </div>
      )}
    </>
  );
};

export default AreaForm;
