import React, { Fragment, useState, useEffect } from 'react';
import { ModalFooter } from 'reactstrap';
// react google maps
import { withGoogleMap, GoogleMap, withScriptjs } from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';
import { toast } from 'react-toastify';
import Geocode from 'react-geocode';
// assets
import marker from 'assets/images/marker.png';
import mapCustomStyle from 'constant/mapCustomStyle.json';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const GoogleMapsAPI = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const AsyncMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      google={props.google}
      ref={props.onMapMounted}
      defaultZoom={18}
      defaultCenter={{ lat: props.latValue, lng: props.lngValue }}
      disableDefaultUI={false}
      onDragEnd={props.handleDrag}
      options={{
        styles: mapCustomStyle,
      }}
    >
      <Autocomplete
        className="map-auto-complete"
        onPlaceSelected={props.onPlaceSelected}
        options={{
          types: ['geocode'],
          componentRestrictions: { country: props.countryIso },
        }}
        onChange={(e) => props.setText(e.target.value)}
        value={props.text}
      />
    </GoogleMap>
  ))
);

const MapComponent = ({
  google,
  setShowMap,
  latValue,
  lngValue,
  setAddressOne,
  addressOne,
  setAreaId,
  areaId,
  updatedLat,
  updatedLng,
  setUpdatedLat,
  setUpdatedLng,
  orderOps,
  countryIso,
  initialLocation,
  updateInitialLocation,
  linkLocationUsed,
  setLinkLocationUsed,
}) => {
  const [text, setText] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [disableBtn, setDisablebtn] = useState(false);
  const refs = {};
  Geocode.setApiKey(GoogleMapsAPI);

  const onMapMounted = (ref) => {
    if (ref) {
      refs.map = ref;
    }
  };

  const defaultParams = {
    lat: initialLocation?.lat ? initialLocation.lat : updatedLat || latValue,
    lng: initialLocation?.lng ? initialLocation.lng : updatedLng || lngValue,
    opsId: orderOps,
  };

  const fetchData = async (params) => {
    setDisablebtn(true);
    try {
      const res = await ViewOrderService.getAddress(params);
      setNewAddress(res.data);
      setAddressOne(res.data.addressLineOne);
      setAreaId(res.data.area.id);
      setDisablebtn(false);
      return res.data;
    } catch (err) {
      errorLogger('MapComponent:fetchData', err);
      toast.error(err.response && err.response.data && err.response.data.message);
      setDisablebtn(false);
      setNewAddress('');
      setAddressOne('');
      return '';
    }
  };

  useEffect(() => {
    const params = linkLocationUsed
      ? { lat: updatedLat, lng: updatedLng, opsId: orderOps }
      : defaultParams;
    fetchData(params);
  }, []);

  const handleDrag = async () => {
    const center = refs.map.getCenter();
    const newLat = center.lat();
    const newLng = center.lng();
    setUpdatedLat(newLat);
    setUpdatedLng(newLng);
  };

  const onPlaceSelected = (place, inputRef) => {
    const inputTextVal = inputRef.value;
    if (place && place.geometry) {
      const newLat = place.geometry.location.lat();
      const newLng = place.geometry.location.lng();
      setLatLng(newLat, newLng);
      setUpdatedLat(newLat);
      setUpdatedLng(newLng);
      const params = {
        lat: newLat,
        lng: newLng,
        opsId: orderOps,
      };
      fetchData(params);
      setText('');
    } else {
      if (
        inputTextVal.match(
          /^([-+]\?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]\?)([\d]{1,3})((\.)(\d+))\?)$/g
        )
      ) {
        const latlongArray = inputTextVal.split(',');
        const newLat = parseFloat(latlongArray[0]);
        const newLng = parseFloat(latlongArray[1]);
        setLatLng(newLat, newLng);
        setUpdatedLat(newLat);
        setUpdatedLng(newLng);
        const params = {
          lat: newLat,
          lng: newLng,
          opsId: orderOps,
        };
        fetchData(params);
      } else {
        Geocode.fromAddress(inputTextVal).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            setLatLng(lat, lng);
            setUpdatedLat(lat);
            setUpdatedLng(lng);
            const params = {
              lat,
              lng,
              opsId: orderOps,
            };
            fetchData(params);
          },
          (error) => {
            errorLogger('MapComponent onPlaceSelected:', error);
            toast.error('Please use a sufficient address format');
          }
        );
      }
    }
  };

  const handleSaveLocation = () => {
    const params = {
      lat: updatedLat || latValue,
      lng: updatedLng || lngValue,
      opsId: orderOps,
    };
    fetchData(params).then((addressData) => {
      if (addressData !== '') {
        if (updateInitialLocation) {
          updateInitialLocation({
            lat: updatedLat || latValue,
            lng: updatedLng || lngValue,
            addressOne: addressData.addressLineOne || '',
            areaId: addressData.area.id || -1,
          });
        }
        setShowMap(false);
      }
    });
  };

  const handleCancelLocation = () => {
    if (initialLocation) {
      setUpdatedLat(initialLocation.lat || '');
      setUpdatedLng(initialLocation.lng || '');
      setAddressOne(initialLocation.addressOne || '');
      setAreaId(initialLocation.areaId || -1);
    }
    if (linkLocationUsed) {
      setLinkLocationUsed(false);
    }
    setShowMap(false);
  };
  const setLatLng = (lat, lng) => {
    refs.map.panTo({ lat, lng });
  };

  return (
    <Fragment>
      {latValue && lngValue && (
        <div className="map-modal-content-wrapper">
          <Fragment>
            <AsyncMap
              latValue={updatedLat || latValue}
              lngValue={updatedLng || lngValue}
              handleDrag={handleDrag}
              onPlaceSelected={onPlaceSelected}
              google={google}
              onMapMounted={onMapMounted}
              orderOps={orderOps}
              setText={setText}
              countryIso={countryIso}
              text={text}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
              loadingElement={<div className="loading-elem" />}
              containerElement={<div className="container-elem" />}
              mapElement={<div className="map-elem" />}
            />
            <img alt="marker" src={marker} className="marker-style-center" />

            <ModalFooter className="row">
              <div className="col-12">
                <h5 className="address-title">
                  {newAddress ? (
                    <Fragment>
                      <strong>{`${newAddress.area.name}: `}</strong>{' '}
                      {`${newAddress.addressLineOne}`}
                    </Fragment>
                  ) : (
                    addressOne || <p>Area not available!</p>
                  )}
                </h5>
              </div>
              <div className="col-12 map-actions-wrapper">
                <button
                  disabled={disableBtn}
                  onClick={handleSaveLocation}
                  className="btn btn-success"
                >
                  Check Availability
                </button>
                <button className="btn btn-light" onClick={handleCancelLocation}>
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </Fragment>
        </div>
      )}
    </Fragment>
  );
};

export default MapComponent;
