import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Media } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
// actions
import { setIsCartModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';

const ProductImage = ({ rowData }) => {
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const dispatch = useDispatch();

  return (
    <Fragment>
      {rowData.carts.length > 0 && (
        <Fragment>
          <Media
            className="img-contain"
            src={rowData.carts[0].image}
            onClick={() => {
              setIsImgOpen(true);
              setImageSrc(rowData.carts.length > 0 && rowData.carts[0].image);
            }}
          />
          <p
            className="quick-action-see-more"
            onClick={() => dispatch(setIsCartModalOpen(rowData.id))}
          >
            {rowData.carts.length > 1 && `and ${rowData.carts.length - 1} more`}
          </p>
        </Fragment>
      )}

      {isImgOpen && <Lightbox onCloseRequest={() => setIsImgOpen(false)} mainSrc={imageSrc} />}
    </Fragment>
  );
};

export default ProductImage;
