// Configure your environment
export const APP_CONFIG = {
  hub: process.env.REACT_APP_HUB,
  user: process.env.REACT_APP_USER,
  home: process.env.REACT_APP_HOME,
  logs: process.env.REACT_APP_LOGS,
  order: process.env.REACT_APP_ORDER,
  gateway: process.env.REACT_APP_GATEWAY,
  product: process.env.REACT_APP_PRODUCT,
  catalog: process.env.REACT_APP_CATALOG,
  driver: process.env.REACT_APP_DRIVER,
  fleet: process.env.REACT_APP_FLEET,
  portal: process.env.REACT_APP_PORTAL,
};
