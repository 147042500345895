import React, { useState, useEffect } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { DateRangePicker } from 'react-date-range';

const DateRangePickerComponent = ({
  setShowDatePicker,
  dateRange,
  handleDateRange,
  kpi,
  setInit,
  setVal,
  idval,
  minDate,
}) => {
  const handleLeftAmount = () => {
    if (window.screen.width < 500) {
      return 0;
    } else {
      if (setInit) {
        return '5%';
      } else {
        return '9%';
      }
    }
  };

  const [isRight, setIsRight] = useState(false);

  useEffect(() => {
    setIsRight(
      (
        (document.getElementById(idval ?? 'date-range-picker')?.getBoundingClientRect().left *
          100) /
        window.innerWidth
      ).toFixed(0) > 44
    );
  }, [idval]);

  return (
    <ClickAwayListener onClickAway={() => setShowDatePicker(false)}>
      <div
        id={idval ?? 'date-range-picker'}
        onClickAway={() => setShowDatePicker(false)}
        style={{
          position: 'absolute',
          top: 75,
          zIndex: 3000,
          border: '1px solid #eee',
          right: kpi || isRight ? 15 : '',
        }}
      >
        <DateRangePicker
          ranges={[dateRange]}
          months={2}
          onChange={(item) => handleDateRange(item)}
          direction={window.screen.width < 500 ? 'vertical' : 'horizontal'}
          moveRangeOnFirstSelection={false}
          minDate={minDate}
        />
        {!kpi && (
          <button
            style={{
              position: 'absolute',
              left: handleLeftAmount(),
              bottom: window.screen.width < 500 ? 0 : 10,
              padding: 6,
              fontSize: 13,
            }}
            onClick={() => setShowDatePicker(false)}
            className="btn btn-success mr-1"
            type="button"
          >
            Apply
          </button>
        )}
        {setInit && (
          <button
            style={{
              position: 'absolute',
              left: window.screen.width < 500 ? 0 : '15%',
              bottom: window.screen.width < 500 ? 0 : 10,
              padding: 6,
              fontSize: 13,
            }}
            onClick={() => {
              setInit(true);
              setVal({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
              });
            }}
            className="btn btn-light mr-1"
            type="button"
          >
            Clear
          </button>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DateRangePickerComponent;
