import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { copyToClipboard } from 'constant/Helpers';
import SectionLoader from 'components/common/SectionLoader';
// api
import UserService from 'services/UserService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ResetPasswordModal = ({ isResetModalOpen, onToggle, defaultPassword, userId }) => {
  const [tempPasswordValue, setTempPasswordValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = async () => {
    setIsLoading(true);
    try {
      const res = await UserService.resetUserPassword(userId);
      setTempPasswordValue(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      errorLogger('ResetPasswordModal:resetPassword', err);
      toast.error(err?.response?.data?.message ?? 'Failed to create password');
    }
  };

  useEffect(() => {
    if (defaultPassword) {
      setTempPasswordValue(defaultPassword);
    } else {
      resetPassword(userId);
    }
  }, []);

  // cleanup
  useEffect(() => {
    return () => {
      setTempPasswordValue('');
    };
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isResetModalOpen} toggle={onToggle}>
        <ModalHeader toggle={onToggle}>Temporary Password</ModalHeader>
        <ModalBody>
          {isLoading ? (
            <SectionLoader height="100px" />
          ) : (
            <form>
              <div onClick={() => copyToClipboard(tempPasswordValue)} className="form-group ">
                <label htmlFor="picker-temp-pass">The user has been added</label>
                <div className="temp-password-wrapper">
                  <input
                    id="picker-temp-pass"
                    className="form-control bold-title"
                    value={tempPasswordValue}
                    disabled={true}
                    type="text"
                  />
                  <i className="fa fa-copy temp-password-icon"></i>
                </div>
                <p>Copy the password and send it to the user</p>
              </div>
            </form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ResetPasswordModal;
