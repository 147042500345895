import React, { useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
} from '@syncfusion/ej2-react-grids';
// components
import has from 'lodash/has';
import toString from 'lodash/toString';
import CreateCategoryModal from '../modals/CreateCategoryModal';

const MerchandisingCategoriesDataGrid = ({
  data,
  inputValues,
  isModalOpen,
  setIsModalOpen,
  setSelectedCat,
  setIsEdit,
}) => {
  const params = useParams();
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const ref = useRef();
  const toolbarClick = (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        ref.current.excelExport();
        break;
      default:
        break;
    }
  };

  const handleEdit = (data) => {
    setIsModalOpen(!isModalOpen);
    setSelectedCat(data);
    setIsEdit(true);
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p
          onClick={() => {
            handleEdit(rowData);
          }}
          className="total-txt link categoryid-link"
        >
          {parseInt(rowData.id, 10)}
        </p>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <p
        onClick={() => {
          handleEdit(rowData);
        }}
        className="total-txt link categoryid-link"
      >
        {rowData.titleEn}
      </p>
    );
  };

  const subcategoryTemplate = (rowData) => {
    return (
      <Link
        to={`/merchandising-categories/${rowData.mcLevel + 1}/${rowData.id}/sub`}
        className="cat-icon-cell"
      >
        <i className="fa fa-align-justify"></i>
      </Link>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${
          rowData.isActive ? 'btn-light-success' : 'btn-light-danger'
        }`}
      >
        {rowData.isActive ? 'Active' : 'Inactive'}
      </button>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <>
      {isAddCategoryModalOpen && (
        <CreateCategoryModal
          isAddCategoryModalOpen={isAddCategoryModalOpen}
          setIsAddCategoryModalOpen={setIsAddCategoryModalOpen}
          type={'category'}
          isEdit={true}
          inputValues={inputValues}
        />
      )}
      <div className="control-pane">
        <div className="control-section">
          <GridComponent
            dataSource={data}
            allowExcelExport={true}
            excelQueryCellInfo={excelQueryCellInfo}
            ref={ref}
            toolbarClick={toolbarClick}
            allowTextWrap={true}
            showColumnChooser={true}
            allowSorting={true}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="id"
                headerText="ID"
                alignText="center"
                template={idTemplate}
                width={100}
                customExcelTemplate={(rowData) => rowData.id}
              />
              <ColumnDirective
                field="titleEn"
                alignText="center"
                headerText="Name"
                template={nameTemplate}
                width={100}
                customExcelTemplate={(rowData) => rowData.nictitleEnkname}
              />
              {params.level === '4' ? null : (
                <ColumnDirective
                  field="subcat"
                  alignText="center"
                  headerText="Sub Cat"
                  template={subcategoryTemplate}
                  width={100}
                />
              )}
              <ColumnDirective
                field="isActive"
                alignText="center"
                headerText="Status"
                template={statusTemplate}
                width={100}
                customExcelTemplate={(rowData) => rowData.isActive}
                customAttributes={{ class: 'category-status' }}
              />
            </ColumnsDirective>
            <Inject services={[Sort]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
};

export default React.memo(MerchandisingCategoriesDataGrid);
