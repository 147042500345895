import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// components
import ZiwoCallsSharedComponent from './ZiwoCallsSharedComponent';
// actions
import { setZiwoCallsDetails } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getZiwoCallsData } from 'store/viewOrder/ViewOrderSelectors';

const PrimaryRecepientCalls = ({ recepientNumber, senderNumber }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const ziwoCallsData = useSelector((state) => getZiwoCallsData({ state }));
  const dispatch = useDispatch();

  const fetchIncomingCalls = async () => {
    if (senderNumber) {
      const dataBody = {
        senderNumber,
        recipientNumber: recepientNumber ?? null,
      };
      setIsLoaderOpen(true);
      await dispatch(setZiwoCallsDetails(dataBody));
      setIsLoaderOpen(false);
    } else {
      toast.error('Sender number is incorrect');
    }
  };

  useEffect(() => {
    fetchIncomingCalls();
  }, []);

  return (
    <>
      <ZiwoCallsSharedComponent
        ziwoCallsData={ziwoCallsData}
        isLoaderOpen={isLoaderOpen}
        title="Ziwo Calls"
      />
    </>
  );
};

export default PrimaryRecepientCalls;
