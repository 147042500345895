import InvoicesService from './../../services/InvoicesService';
import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { errorLogger } from 'datadog/DDUtils';

export const getInvoicesData = (params, countryId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  const newParams = { ...params, countryId };
  try {
    const res = await InvoicesService.getInvoicesData(newParams);
    dispatch({
      type: 'GET_INVOICES_DATA',
      payload: res.data.invoice.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (e) {
    errorLogger('InvoicesActions:getInvoicesData', e);
    toast.error('Failed to fetch invoices data');
    dispatch(setLoaderOpenFalse());
  }
};

export const getCorporateInvoicesData = (params, countryId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  const newParams = { ...params, countryId };
  try {
    const res = await InvoicesService.getCorporateInvoicesData(newParams);
    dispatch({
      type: 'GET_CORPORATE_INVOICES_DATA',
      payload: res.data.invoices,
    });
    dispatch(setLoaderOpenFalse());
  } catch (e) {
    errorLogger('InvoicesActions:getCorporateInvoicesData', e);
    toast.error('Failed to fetch invoices data');
    dispatch(setLoaderOpenFalse());
  }
};

export const setSelectedCorporateInvoices = (params) => {
  return {
    type: 'SET_SELECTED_CORPORATE_INVOICES',
    payload: params,
  };
};

export const getConsolidatedInvoicesData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InvoicesService.getConsolidatedInvoicesData(params);
    dispatch({
      type: 'GET_CONSOLIDATED_INVOICES_DATA',
      payload: res.data.invoices,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InvoicesActions:getConsolidatedInvoicesData', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch invoices data');
    dispatch(setLoaderOpenFalse());
  }
};

export const getGeneratedConsolidatedInvoices = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InvoicesService.getGeneratedConsolidatedInvoices(params);
    dispatch({
      type: 'GET_GENERATED_CONSOLIDATED_INVOICES',
      payload: res.data.invoices,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InvoicesActions:getGeneratedConsolidatedInvoices', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch invoices data');
    dispatch(setLoaderOpenFalse());
  }
};
