import React, { useState } from 'react';
// components
import SectionLoader from 'components/common/SectionLoader';
const SubscriptionsDataTable = React.lazy(() =>
  import('components/subscriptions/SubscriptionsDataTable')
);

const SubscriptionsPage = () => {
  const [inputValues, setInputValues] = useState({
    Searchkey: '',
    fromdate: '',
    todate: '',
  });
  return (
    <div className="toolbar-table-style">
      <React.Suspense fallback={<SectionLoader />}>
        <SubscriptionsDataTable inputValues={inputValues} setInputValues={setInputValues} />
      </React.Suspense>
    </div>
  );
};

export default SubscriptionsPage;
