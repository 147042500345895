export const addInventoryFormConfig = {
  searchConf: {
    label: 'Search for item',
    elementType: 'input',
    elementConfig: {
      type: 'text',
      id: 'addInventorySearch',
    },
  },
  barcodeConf: {
    label: 'or Barcode',
    elementType: 'input',
    elementConfig: {
      type: 'text',
      id: 'addInventoryBarcode',
    },
  },
  quantityConf: {
    label: 'Qty',
    elementType: 'input',
    elementConfig: {
      type: 'text',
      id: 'addInventoryQty',
    },
  },
};
