import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RestrictedSection from 'components/shared/RestrictedSection';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
import DatePicker from 'react-datepicker';
// constant
import { HasPermission, dateStartFormat, dateEndFormat } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';
import { getProductOptionsById, getProductRankingForOps } from 'store/products/ProductsSelectors';
// api
import ProductsService from 'services/ProductsService';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';
import ActiveStatusBadge from 'components/shared/ActiveStatusBadge';
import { setProductMasterAvailabilityById } from 'store/products/ProductsActions';

const PREORDER_NOT_SUPPORTED_TYPES = [3, 4, 5, 6, 7, 8];

const AvailabilityRowTemplate = ({
  availabilityData,
  productId,
  master,
  validateLocalAvailability,
  validateMA,
  isValid,
  notValidFields,
  country,
}) => {
  const dispatch = useDispatch();
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const productOptionsById = useSelector((state) => getProductOptionsById({ state }));
  const productRankingById = useSelector((state) =>
    getProductRankingForOps({ state, opsId: getFromLocalStorage(localStorageKeys.OPS_ID) })
  );
  const [price, setPrice] = useState(0);
  const [cost, setCost] = useState(0);
  const [activePreOrder, setActivePreOrder] = useState(false);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [orderId, setOrderId] = useState('');
  const [availableQty, setAvailableQty] = useState(0);
  const [suggestedPrice, setSuggestedPrice] = useState('');
  const [vat, setVat] = useState(0);
  const [margin, setMargin] = useState(0);
  const [minPriceWithVat, setMinPriceWithVat] = useState(0);
  const [displayPrice, setDisplayPrice] = useState(0);
  const [producedUnits, setProducedUnits] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isOnlyLondon, setIsOnlyLondon] = useState(false);
  const [isSuperExpress, setIsSuperExpress] = useState(false);
  const [isEnableExDelivery, setIsEnableExpDelivery] = useState(false);
  const [isTaxFree, setIsTaxFree] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  // Pre Order Managment Related
  const [seasonalCollectionSpecificDates, setSeasonalCollectionSpecificDates] = useState(false);
  const [seasonalCollectionAnyDates, setSeasonalCollectionAnyDates] = useState(false);
  const [enablePreOrder, setEnablePreOrder] = useState(false);
  const [preOrderStartAt, setPreOrderStartAt] = useState(null);
  const [preOrderEndAt, setPreOrderEndAt] = useState(null);
  const [preOrderDeliveryStartAt, setPreOrderDeliveryStartAt] = useState(null);
  const [preOrderDeliveryEndAt, setPreOrderDeliveryEndAt] = useState(null);
  const [preOrderCapacity, setPreOrderCapacity] = useState(0);
  const [preOrderCurrentOrders, setPreOrderCurrentOrders] = useState(0);
  const [validationPreOrderStartAt, setValidationPreOrderStartAt] = useState({
    isValid: true,
    message: '',
  });
  const [validationPreOrderEndAt, setValidationPreOrderEndAt] = useState({
    isValid: true,
    message: '',
  });
  const [validationPreOrderDeliveryStartAt, setValidationPreOrderDeliveryStartAt] = useState({
    isValid: true,
    message: '',
  });
  const [validationPreOrderDeliveryEndAt, setValidationPreOrderDeliveryEndAt] = useState({
    isValid: true,
    message: '',
  });
  const [validationPreOrderCapacity, setValidationPreOrderCapacity] = useState({
    isValid: true,
    message: '',
  });

  const updatePreOrderValidations = (
    enable_Pre_Order,
    pre_Order_StartAt,
    pre_Order_EndAt,
    pre_Order_DeliveryStartAt,
    pre_Order_DeliveryEndAt,
    pre_Order_Capacity
  ) => {
    // no need to run validation if not pre order item
    if (!enable_Pre_Order) return;
    const formattedPreOrderStartAt = pre_Order_StartAt ? dateStartFormat(pre_Order_StartAt) : null;
    const formattedPreOrderEndAt = pre_Order_EndAt ? dateStartFormat(pre_Order_EndAt, '02') : null;
    const formattedPreOrderDeliveryStartAt = pre_Order_DeliveryStartAt
      ? dateStartFormat(pre_Order_DeliveryStartAt)
      : null;
    const formattedPreOrderDeliveryEndAt = pre_Order_DeliveryEndAt
      ? dateStartFormat(pre_Order_DeliveryEndAt, '02')
      : null;
    if (!formattedPreOrderStartAt) {
      setValidationPreOrderStartAt({ isValid: false, message: 'Required Field' });
    } else {
      if (formattedPreOrderStartAt < formattedPreOrderEndAt) {
        setValidationPreOrderStartAt({ isValid: true, message: '' });
      } else {
        setValidationPreOrderStartAt({
          isValid: false,
          message: 'Start date should be before end date',
        });
      }
    }

    if (!formattedPreOrderEndAt) {
      setValidationPreOrderEndAt({ isValid: false, message: 'Required Field' });
    } else {
      if (
        formattedPreOrderEndAt < formattedPreOrderDeliveryStartAt &&
        formattedPreOrderEndAt > formattedPreOrderStartAt
      ) {
        setValidationPreOrderEndAt({ isValid: true, message: '' });
      } else if (formattedPreOrderEndAt >= formattedPreOrderDeliveryStartAt) {
        setValidationPreOrderEndAt({
          isValid: false,
          message: 'Pre order end date should be before Delivery start date',
        });
      } else if (formattedPreOrderEndAt <= formattedPreOrderStartAt) {
        setValidationPreOrderEndAt({
          isValid: false,
          message: 'Pre order end date should be after start date',
        });
      } else {
        setValidationPreOrderEndAt({ isValid: false, message: '' });
      }
    }
    if (!formattedPreOrderDeliveryStartAt) {
      setValidationPreOrderDeliveryStartAt({ isValid: false, message: 'Required Field' });
    } else {
      if (
        formattedPreOrderDeliveryStartAt > formattedPreOrderEndAt &&
        formattedPreOrderDeliveryStartAt < formattedPreOrderDeliveryEndAt
      ) {
        setValidationPreOrderDeliveryStartAt({ isValid: true, message: '' });
      } else if (formattedPreOrderDeliveryStartAt <= formattedPreOrderEndAt) {
        setValidationPreOrderDeliveryStartAt({
          isValid: false,
          message: 'Delivery start date should be after Pre order end date',
        });
      } else if (formattedPreOrderDeliveryStartAt >= formattedPreOrderDeliveryEndAt) {
        setValidationPreOrderDeliveryStartAt({
          isValid: false,
          message: 'Delivery start date should be before delivery end date',
        });
      } else {
        setValidationPreOrderDeliveryStartAt({ isValid: false, message: '' });
      }
    }
    if (!formattedPreOrderDeliveryEndAt) {
      setValidationPreOrderDeliveryEndAt({ isValid: false, message: 'Required Field' });
    } else {
      if (formattedPreOrderDeliveryEndAt > formattedPreOrderDeliveryStartAt) {
        setValidationPreOrderDeliveryEndAt({ isValid: true, message: '' });
      } else {
        setValidationPreOrderDeliveryEndAt({
          isValid: false,
          message: 'Delivery end date should be after Delivery start date',
        });
      }
    }
    if (pre_Order_Capacity && pre_Order_Capacity > 0) {
      setValidationPreOrderCapacity({ isValid: true, message: '' });
    } else {
      setValidationPreOrderCapacity({ isValid: false, message: 'Should be greater than 0' });
    }
  };

  useEffect(() => {
    updatePreOrderValidations(
      enablePreOrder,
      preOrderStartAt,
      preOrderEndAt,
      preOrderDeliveryStartAt,
      preOrderDeliveryEndAt,
      preOrderCapacity
    );
  }, [
    enablePreOrder,
    preOrderStartAt,
    preOrderEndAt,
    preOrderDeliveryStartAt,
    preOrderDeliveryEndAt,
    preOrderCapacity,
  ]);
  useEffect(() => {
    if (Object.keys(availabilityData).length > 0) {
      setPrice(availabilityData?.price ?? 0);
      setCost(availabilityData?.cost ?? 0);
      setDiscountPrice(availabilityData?.discountedPrice ?? 0);
      setOrderId(availabilityData?.orderId ?? '');
      setAvailableQty(availabilityData?.availableQty ?? 0);
      setSuggestedPrice(availabilityData?.suggestedPrice ?? '');
      setVat((master ? availabilityData?.ops?.vat : availabilityData?.vat) ?? 0);
      setMargin(availabilityData?.margin ?? 0);
      setMinPriceWithVat(availabilityData?.minPriceWithVat ?? 0);
      setDisplayPrice(availabilityData?.displayPrice ?? 0);
      setProducedUnits(availabilityData?.producedUnitQty ?? 0);
      setIsActive(availabilityData?.active ?? false);
      setIsOnlyLondon(availabilityData?.nwOnlyLondon ?? false);
      setIsSuperExpress(availabilityData?.superExpressDelivery ?? false);
      setIsEnableExpDelivery(availabilityData?.enableExpressDelivery ?? false);
      setIsTaxFree(availabilityData?.taxFree ?? false);
      // Pre Orders Related
      setActivePreOrder(availabilityData?.activePreOrder ?? false);
      setEnablePreOrder(availabilityData?.enablePreOrder ?? false);
      setPreOrderCapacity(availabilityData?.preOrderCapacity ?? 0);
      setPreOrderCurrentOrders(availabilityData?.preOrderCurrentOrders ?? 0);
      setPreOrderStartAt(
        availabilityData?.preOrderStartAt ? new Date(availabilityData?.preOrderStartAt) : null
      );
      setPreOrderEndAt(
        availabilityData?.preOrderEndAt ? new Date(availabilityData?.preOrderEndAt) : null
      );
      setPreOrderDeliveryStartAt(
        availabilityData?.preOrderDeliveryStartAt
          ? new Date(availabilityData?.preOrderDeliveryStartAt)
          : null
      );
      setPreOrderDeliveryEndAt(
        availabilityData?.preOrderDeliveryEndAt
          ? new Date(availabilityData?.preOrderDeliveryEndAt)
          : null
      );
      setSeasonalCollectionSpecificDates(
        availabilityData?.seasonalCollectionSpecificDates || false
      );
      setSeasonalCollectionAnyDates(availabilityData?.seasonalCollectionAnyDates || false);
    }
  }, [availabilityData]);

  const handlePreOrderDatesChange = (date, setField) => {
    setField(date);
  };

  const saveAvailabilityRow = async () => {
    let dataBody = {
      opsId: master ? availabilityData?.opsId : getFromLocalStorage(localStorageKeys.OPS_ID),
      price,
      cost,
      discountedPrice: discountPrice ?? 0,
      orderId: orderId && orderId !== '' ? orderId : 0,
      availableQty: availableQty ?? 0,
      displayPrice,
      suggestedPrice,
      active: isActive,
      nwOnlyLondon: isOnlyLondon,
      superExpressDelivery: isSuperExpress,
      enableExpressDelivery: isEnableExDelivery,
      enablePreOrder,
      preOrderStartAt: preOrderStartAt ? dateStartFormat(preOrderStartAt) : null,
      preOrderEndAt: preOrderEndAt ? dateEndFormat(preOrderEndAt) : null,
      preOrderDeliveryStartAt: preOrderDeliveryStartAt
        ? dateStartFormat(preOrderDeliveryStartAt)
        : null,
      preOrderDeliveryEndAt: preOrderDeliveryEndAt ? dateEndFormat(preOrderDeliveryEndAt) : null,
      preOrderCapacity,
      seasonalCollectionSpecificDates,
      seasonalCollectionAnyDates,
    };
    if (HasPermission(permissionsList, 'edit_product', 'edit_tax_free')) {
      dataBody = {
        ...dataBody,
        taxFree: isTaxFree,
      };
    }
    setDisableBtn(true);
    setIsLoaderOpen(true);
    try {
      if (master) {
        await ProductsService.updateOpsMasterAvailabilityById(dataBody, productId);
      } else {
        await ProductsService.updateProductAvailability(dataBody, productId);
      }
      toast.success('Updated Successfully');
      setIsLoaderOpen(false);
      setDisableBtn(false);
      if (validateLocalAvailability) {
        dispatch({
          type: 'PRODUCT_AVAILABILITY_BY_ID',
          payload: dataBody,
        });
        validateLocalAvailability(true, dataBody);
      }

      if (validateMA) {
        dispatch(setProductMasterAvailabilityById(productId, validateMA));
      }
    } catch (err) {
      setDisableBtn(false);
      setIsLoaderOpen(false);
      toast.error(err?.response?.data?.message ?? 'Failed to update product availability');
    }
  };

  const handleAnyDateCheckbox = (e) => {
    setSeasonalCollectionAnyDates((prev) => !prev);
    if (e.currentTarget.checked && seasonalCollectionSpecificDates) {
      setSeasonalCollectionSpecificDates(false);
    }
  };
  const handleSpecificDateCheckbox = (e) => {
    setSeasonalCollectionSpecificDates((prev) => !prev);
    if (e.currentTarget.checked && seasonalCollectionAnyDates) {
      setSeasonalCollectionAnyDates(false);
    }
  };

  const calcPrice = (val, isTFree) => {
    if (isTFree) {
      setPrice(val);
    } else {
      setPrice(val / (1 + vat / 100));
    }
  };

  const handlePriceChange = (e) => {
    setDisplayPrice(e.target.value);
    calcPrice(e.target.value, isTaxFree);
  };

  const handleTaxFreeChange = () => {
    setIsTaxFree((prev) => {
      calcPrice(displayPrice, !prev);
      return !prev;
    });
  };

  const productType = productOptionsById?.type || 0;
  const isPreOrderAvailable =
    !PREORDER_NOT_SUPPORTED_TYPES.includes(productType) && !productRankingById?.customizable;
  const isPreOrderValid =
    enablePreOrder && isPreOrderAvailable
      ? validationPreOrderCapacity?.isValid &&
        validationPreOrderDeliveryEndAt?.isValid &&
        validationPreOrderDeliveryStartAt?.isValid &&
        validationPreOrderEndAt?.isValid &&
        validationPreOrderStartAt?.isValid
      : true;
  const isRequired = isActive && productOptionsById?.type !== 5 && productOptionsById?.type !== 11;

  return (
    <div className="form-group">
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <>
          <form className="row">
            <div className="form-group col-12 col-md-4">
              <label htmlFor="product-price" className="bold-title">
                Price
              </label>
              <input
                id="product-price"
                value={price}
                className="form-control"
                type="text"
                readOnly
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label htmlFor="product-discount" className="bold-title">
                Discount Price
              </label>
              <input
                id="product-discount"
                value={discountPrice}
                onChange={(e) => setDiscountPrice(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-12 col-md-3">
              <label htmlFor="product-qty" className="bold-title">
                Available QTY
              </label>
              <input
                id="product-qty"
                value={availableQty}
                className="form-control"
                type="text"
                readOnly
              />
            </div>
            <div className="form-group col-12 col-md-3">
              <label htmlFor="product-cost" className="bold-title">
                Cost
              </label>
              <input id="product-cost" value={cost} className="form-control" type="text" readOnly />
            </div>
            <div className="form-group col-12 col-md-3">
              <label htmlFor="product-min-price" className="bold-title">
                Minimum price (policy price)
              </label>
              <input
                id="product-min-price"
                value={suggestedPrice}
                className="form-control"
                type="text"
                readOnly
              />
            </div>
            <div className="form-group col-12 col-md-3">
              <label htmlFor="product-vat" className="bold-title">
                VAT %
              </label>
              <input id="product-vat" value={vat} className="form-control" type="text" readOnly />
            </div>
            <div className="form-group col-12 col-md-3">
              <label htmlFor="product-margin" className="bold-title">
                Margin
              </label>
              <input
                id="product-margin"
                value={margin}
                className="form-control"
                type="text"
                readOnly
              />
            </div>
            <div className="form-group col-12 col-md-3">
              <label htmlFor="product-min-vat" className="bold-title">
                Minimum price with VAT
              </label>
              <input
                id="product-min-vat"
                value={minPriceWithVat}
                className="form-control"
                type="text"
                readOnly
              />
            </div>
            <div className="form-group col-12 col-md-3">
              <label htmlFor="product-final-price" className="bold-title">
                Final Price with VAT
                {isRequired && <span className="requird-asterisks ml-1">*</span>}
              </label>
              <input
                id="product-final-price"
                value={displayPrice}
                onChange={handlePriceChange}
                className="form-control"
                type="number"
                disabled={!HasPermission(permissionsList, 'edit_product', 'edit_final_price')}
              />
              {!isValid && notValidFields?.includes('final price with vat') && (
                <InlineErrorMessage message={'Please add "Final Price with VAT"'} />
              )}

              {!isValid && notValidFields?.includes(`final price with vat for ${country}`) && (
                <InlineErrorMessage message={`Please add "Final Price with VAT" for ${country}`} />
              )}
            </div>
            <div className="form-group col-12 col-md-3">
              <label htmlFor="produced-units" className="bold-title">
                Available Produced Units
              </label>
              <input
                id="produced-units"
                value={producedUnits}
                className="form-control"
                type="number"
                readOnly
              />
            </div>
          </form>
          {isPreOrderAvailable ? (
            <RestrictedSection page="edit_product" action="edit_pre_order_item">
              <hr />
              <div className="row">
                <div className="form-group col-3">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={enablePreOrder}
                    onChange={() => setEnablePreOrder((prev) => !prev)}
                    disabled={productOptionsById?.status === 1}
                  />
                  <span className="bold-title">Pre-Order Active</span>
                </div>
                {enablePreOrder ? (
                  <div className="form-group col-3">
                    {enablePreOrder && activePreOrder ? (
                      <ActiveStatusBadge isActive label="Active" />
                    ) : (
                      <ActiveStatusBadge isActive={false} label="Not Active" />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {enablePreOrder ? (
                <div className="row">
                  <div className="form-group col-sm-3 col-12">
                    <label className="col-form-label bold-title" htmlFor="message-text">
                      Pre-Order Start on
                    </label>
                    <DatePicker
                      className="form-control digits"
                      placeholderText="Pre-Order Start on"
                      selected={preOrderStartAt}
                      onChange={(date) => handlePreOrderDatesChange(date, setPreOrderStartAt)}
                      dateFormat="dd-MM-yyyy"
                      disabled={productOptionsById?.status === 1}
                      minDate={Date.now()}
                    />
                    {validationPreOrderStartAt.message ? (
                      <InlineErrorMessage message={validationPreOrderStartAt.message} />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-group col-sm-3 col-12">
                    <label className="col-form-label bold-title" htmlFor="message-text">
                      Pre-Order Ends on
                    </label>
                    <DatePicker
                      className="form-control digits"
                      placeholderText="Pre-Order Ends on"
                      selected={preOrderEndAt}
                      onChange={(date) => handlePreOrderDatesChange(date, setPreOrderEndAt)}
                      dateFormat="dd-MM-yyyy"
                      disabled={productOptionsById?.status === 1}
                      minDate={Date.now()}
                    />
                    {validationPreOrderEndAt.message ? (
                      <InlineErrorMessage message={validationPreOrderEndAt.message} />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-group col-sm-3 col-12">
                    <label className="col-form-label bold-title" htmlFor="message-text">
                      Delivery Start on
                    </label>
                    <DatePicker
                      className="form-control digits"
                      placeholderText="Delivery Start on"
                      selected={preOrderDeliveryStartAt}
                      onChange={(date) =>
                        handlePreOrderDatesChange(date, setPreOrderDeliveryStartAt)
                      }
                      dateFormat="dd-MM-yyyy"
                      disabled={productOptionsById?.status === 1}
                      minDate={Date.now()}
                    />
                    {validationPreOrderDeliveryStartAt.message ? (
                      <InlineErrorMessage message={validationPreOrderDeliveryStartAt.message} />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-group col-sm-3 col-12">
                    <label className="col-form-label bold-title" htmlFor="message-text">
                      Delivery Ends on
                    </label>
                    <DatePicker
                      className="form-control digits"
                      placeholderText="Delivery Ends on"
                      selected={preOrderDeliveryEndAt}
                      onChange={(date) => handlePreOrderDatesChange(date, setPreOrderDeliveryEndAt)}
                      dateFormat="dd-MM-yyyy"
                      disabled={productOptionsById?.status === 1}
                      minDate={Date.now()}
                    />
                    {validationPreOrderDeliveryEndAt.message ? (
                      <InlineErrorMessage message={validationPreOrderDeliveryEndAt.message} />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-group col-sm-3 col-12">
                    <label className="bold-title" htmlFor="coupon-amount">
                      Pre-Order Capacity
                    </label>
                    <input
                      id="preorder-capacity"
                      onChange={(e) => setPreOrderCapacity(e.target.value)}
                      value={preOrderCapacity}
                      className="form-control"
                      type="number"
                      disabled={productOptionsById?.status === 1}
                    />
                    {validationPreOrderCapacity.message ? (
                      <InlineErrorMessage message={validationPreOrderCapacity.message} />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-group col-sm-3 col-12">
                    <label className="bold-title" htmlFor="coupon-amount">
                      Current Pre-orders
                    </label>
                    <input
                      id="preorder-current-order"
                      value={preOrderCurrentOrders}
                      className="form-control"
                      type="number"
                      readOnly
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <hr />
            </RestrictedSection>
          ) : (
            ''
          )}
          <div className="row availability-row-checkbox">
            <div className="form-group col-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive((prev) => !prev)}
              />
              <span className="bold-title">Active</span>
            </div>
            <RestrictedSection page="edit_product" action="availability_only_london">
              <div className="form-group col-3">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={isOnlyLondon}
                  onChange={(e) => setIsOnlyLondon((prev) => !prev)}
                />
                <span className="bold-title">London Only</span>
              </div>
            </RestrictedSection>
            <div className="form-group col-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={isSuperExpress}
                onChange={(e) => setIsSuperExpress((prev) => !prev)}
              />
              <span className="bold-title">Super express delivery (15min delivery)</span>
            </div>
            <div className="form-group col-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={isEnableExDelivery}
                onChange={() => setIsEnableExpDelivery((prev) => !prev)}
              />
              <span className="bold-title">Enable express delivery</span>
            </div>
            <RestrictedSection page="edit_product" action="edit_tax_free">
              <div className="form-group col-3">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={isTaxFree}
                  onChange={handleTaxFreeChange}
                />
                <span className="bold-title">Tax Free</span>
              </div>
            </RestrictedSection>
            <RestrictedSection page="edit_product" action="edit_seasonal_collection_delivery_dates">
              <div className="form-group col-3">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={seasonalCollectionAnyDates}
                  onChange={handleAnyDateCheckbox}
                />
                <span className="bold-title">Seasonal Collection - Any Delivery Dates</span>
              </div>
              <div className="form-group col-3">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={seasonalCollectionSpecificDates}
                  onChange={handleSpecificDateCheckbox}
                />
                <span className="bold-title">Seasonal Collection - Specific Delivery Dates</span>
              </div>
            </RestrictedSection>
          </div>
        </>
      )}

      <div className="row">
        <div className="form-group col-12 col-md-3 availability-row-button">
          <button
            onClick={() => saveAvailabilityRow()}
            className="btn btn-success m-b-0"
            type="button"
            disabled={!isPreOrderValid || disableBtn || productOptionsById?.status === 1}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AvailabilityRowTemplate);
