import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { toast } from 'react-toastify';
import { dateFormatDDMM } from 'constant/Helpers';
// api
import DeliveryReportService from 'services/reportsService/DeliveryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DriverOrdersDataGrid = ({ data, params, id }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = async (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        try {
          const res = await DeliveryReportService.exportDriverOrders(params, id);
          toast.success(res?.data?.message);
        } catch (err) {
          errorLogger('Driver Orders Report Page Export Data Error:', err);
          toast.error('Failed to export excel file!!');
        }
        break;
      default:
        break;
    }
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.id}</p>
      </div>
    );
  };

  const orderIDTemplate = (rowData) => {
    return <p className="total-txt">{rowData.orderID}</p>;
  };

  const nameTemplate = (rowData) => {
    return <p className="total-txt">{rowData.name}</p>;
  };

  const phoneTemplate = (rowData) => {
    return <p className="total-txt">{rowData.phone}</p>;
  };

  const companyTemplate = (rowData) => {
    return <p className="total-txt">{rowData.company}</p>;
  };

  const startDateTemplate = (rowData) => {
    return <p className="total-txt">{dateFormatDDMM(rowData.startDate)}</p>;
  };

  const endDateTemplate = (rowData) => {
    return <p className="total-txt">{rowData.endDate}</p>;
  };

  const statusStringTemplate = (rowData) => {
    return <p className="total-txt">{rowData.statusString}</p>;
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="#"
              field="id"
              width={60}
              template={idTemplate}
              customExcelTemplate={(rowData) => rowData.id}
            />
            <ColumnDirective
              headerText="Order Number"
              field="orderID"
              width={100}
              template={orderIDTemplate}
              customExcelTemplate={(rowData) => rowData.orderID}
            />
            <ColumnDirective
              headerText="Driver Name"
              field="name"
              width={100}
              template={nameTemplate}
              customExcelTemplate={(rowData) => rowData.name}
            />
            <ColumnDirective
              headerText="Company"
              field="company"
              width={100}
              template={companyTemplate}
              customExcelTemplate={(rowData) => rowData.company}
            />
            <ColumnDirective
              headerText="Phone"
              field="phone"
              width={100}
              template={phoneTemplate}
              customExcelTemplate={(rowData) => rowData.phone}
            />
            <ColumnDirective
              headerText="Start Delivering"
              field="startDate"
              width={100}
              template={startDateTemplate}
              customExcelTemplate={(rowData) => rowData.startDate}
            />
            <ColumnDirective
              headerText="End Delivering"
              field="endDate"
              width={120}
              template={endDateTemplate}
              customExcelTemplate={(rowData) => rowData.endDate}
            />
            <ColumnDirective
              headerText="Delivering Status"
              field="statusString"
              width={100}
              template={statusStringTemplate}
              customExcelTemplate={(rowData) => rowData.statusString}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(DriverOrdersDataGrid);
