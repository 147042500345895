import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DeleteProductImgModal from '../modals/DeleteProductImgModal';
import { ReactSortable } from 'react-sortablejs';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
// constants
import { handleImageFile, compareSort } from 'constant/Helpers';
// actions
import { setProductMediaById } from 'store/products/ProductsActions';
// api
import ProductsService from 'services/ProductsService';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';

const ProductMediaSection = ({
  productMediaById,
  productId,
  productOptionsById,
  isValid,
  notValidFields,
  validateMedia,
}) => {
  const [coverImg, setCoverImg] = useState('');
  const [imagesList, setImagesList] = useState([]);
  const [imageId, setImageId] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isDeleteProductImgModalOpen, setIsDeleteProductImgModalOpen] = useState(false);
  const [errImageMessage, setErrImageMessage] = useState(false);
  const [imgTypeErr, setImgTypeErr] = useState({ 0: false, 1: false });
  const [errCoverMessage, setErrCovereMessage] = useState(false);
  const [exceededDimenstions, setExceededDimenstions] = useState([]);
  const dispatch = useDispatch();
  const maxWidth = 1000;
  const maxHeight = 1000;

  useEffect(() => {
    if (Object.keys(productMediaById).length > 0) {
      setCoverImg(productMediaById.coverImage);
      setImagesList(productMediaById.images);
    }
  }, [productMediaById]);

  const handleUploadImg = async (e, type) => {
    setIsLoaderOpen(true);
    const data = {
      Image: e.target.files,
      imageType: type,
    };
    const formData = new FormData();
    Object.entries(data).forEach((el) => {
      if (el[0] === 'Image') {
        Array.from(el[1]).forEach((file, i) => {
          formData.append(`Image${i + 1}`, file);
        });
      } else {
        formData.append(el[0], el[1]);
      }
    });
    try {
      await ProductsService.updateProductMediaById(formData, productId);
      setTimeout(() => {
        dispatch(setProductMediaById(productId, validateMedia));
        toast.success('Image uploaded successfully');
        setIsLoaderOpen(false);
      }, 1500);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Failed to upload image!');
      setIsLoaderOpen(false);
    }
  };

  const toggleDeleteImg = (e, id) => {
    e.stopPropagation();
    setImageId(id);
    setIsDeleteProductImgModalOpen(true);
  };

  const handleReorder = async (newList) => {
    if (
      newList.filter(function (de) {
        return de.chosen;
      }).length === 0 &&
      compareSort(newList, imagesList)
    ) {
      const dataBody = {
        Ids: newList.map((item) => item.id),
      };
      try {
        await ProductsService.reorderProductImages(dataBody);
        toast.success('Updated');
      } catch (err) {
        toast.error(err?.response?.data?.message ?? 'Failed to reorder images');
      }
    }
  };

  const handleOpenImgNewTab = (path) => {
    window.open(path, '_blank');
  };

  const validateDimensions = (dimenstionsArray) => {
    return dimenstionsArray.every((el) => el.height <= maxHeight && el.width <= maxWidth);
  };

  const validateExtension = (images) => {
    const allowedExtensions = ['image/png', 'image/jpg', 'image/jpeg'];
    return images.every((img) => allowedExtensions.indexOf(img.type) > -1);
  };

  // file reader
  const readImageFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // Initiate the JavaScript Image object.
        const image = new Image();
        // Set the Base64 string return from FileReader as source.
        image.src = e.target.result;
        // Validate the File Height and Width.
        image.onload = function () {
          const height = this.height;
          const width = this.width;
          resolve({ height, width });
        };
      };
    });
  };

  // triggered on browsing images for the carousel from the file input
  const onSelectImage = async (e, type) => {
    e.persist();
    setImgTypeErr({ ...imgTypeErr, [type]: false });

    const inputImages = [];
    inputImages.push(...e.target.files);

    const mediaValidity = validateMedia(true, inputImages.length);
    if (
      mediaValidity &&
      !mediaValidity?.isValid &&
      mediaValidity?.notValidFields.includes('max range for images')
    )
      return;

    if (!validateExtension(inputImages)) {
      setImgTypeErr({ ...imgTypeErr, [type]: true });
      return;
    }
    // loop through images and check validation
    const dimenstionsArray = inputImages.map(async (file, i) => {
      // readFile here
      const imageDimensions = await readImageFile(file);
      const { width, height } = imageDimensions;
      return { width, height, name: file.name };
    });
    const resolvedDimenstions = await Promise.all(dimenstionsArray);

    if (validateDimensions(resolvedDimenstions)) {
      setErrImageMessage(false);
      setErrCovereMessage(false);
      setExceededDimenstions([]);
      handleUploadImg(e, type);
    } else {
      const exceededDimenstionsArr = resolvedDimenstions.filter(
        (el) => el.height > maxHeight && el.width > maxWidth
      );
      if (type === 0) {
        setErrCovereMessage(true);
      } else {
        setExceededDimenstions(exceededDimenstionsArr);
        setErrImageMessage(true);
      }
    }
  };

  const isBasketOrAddon = productOptionsById?.type === 4 || productOptionsById?.type === 5;

  return (
    <div className="form-group col-12 card">
      <DeleteProductImgModal
        isDeleteProductImgModalOpen={isDeleteProductImgModalOpen}
        setIsDeleteProductImgModalOpen={setIsDeleteProductImgModalOpen}
        productId={productId}
        imgId={imageId}
        setImagesList={setImagesList}
        imagesList={imagesList}
        setExceededDimenstions={setExceededDimenstions}
      />
      <div className="card-body row content-display">
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <Fragment>
            <div className="form-group col-12 m-b-50">
              <label className="bold-title">
                Upload Cover Image
                <span className="requird-asterisks ml-1">*</span>{' '}
                <span className={`red-color ${!errCoverMessage ? 'hide-display' : ''}`}>
                  {' '}
                  (Max {maxWidth}*{maxHeight}px)
                </span>
              </label>
              <div
                className="file-img pointer"
                style={{
                  backgroundImage: `url(${handleImageFile(coverImg)})`,
                  height: coverImg === '' ? 0 : 150,
                  width: '12%',
                }}
                onClick={() => handleOpenImgNewTab(coverImg)}
              ></div>
              <div className="file-input w-20">
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => onSelectImage(e, 0)}
                  disabled={productOptionsById?.status === 1}
                />
              </div>
              {imgTypeErr[0] && (
                <p className="exceeded-dim-message">
                  <i className="fa fa-exclamation-triangle m-r-5"></i>
                  Please only upload PNG, JPG, and JPEG images
                </p>
              )}
              {!isValid && notValidFields.includes('Cover Image') && (
                <InlineErrorMessage message={'Please add "Cover Image" to publish the product'} />
              )}
            </div>
            <div className="form-group col-12">
              <label className="bold-title">
                Upload Images
                {!isBasketOrAddon && <span className="requird-asterisks ml-1">*</span>}
                <span className={`red-color ${!errImageMessage ? 'hide-display' : ''}`}>
                  {' '}
                  (Max {maxWidth}*{maxHeight}px)
                </span>
              </label>
              <Fragment>
                <div>
                  <ReactSortable
                    className="row normal-flex"
                    list={imagesList}
                    setList={(newList) => {
                      if (productOptionsById?.status !== 1) {
                        handleReorder(newList);
                        setImagesList(newList);
                      }
                    }}
                  >
                    {imagesList.map((item, i) => (
                      <div
                        key={i}
                        className="file-img col-sm-2 col-4 pointer"
                        style={{
                          backgroundImage: `url(${handleImageFile(item.fullPath)})`,
                          height: coverImg === '' ? 0 : 150,
                        }}
                        onClick={() => handleOpenImgNewTab(item.fullPath)}
                      >
                        <i
                          onClick={(e) => toggleDeleteImg(e, item.id)}
                          className="fa fa-times-circle media-img-icon"
                          style={{ display: productOptionsById?.status === 1 ? 'none' : '' }}
                        ></i>
                      </div>
                    ))}
                  </ReactSortable>
                </div>
                <div className="file-input w-20">
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => onSelectImage(e, 1)}
                    disabled={productOptionsById?.status === 1}
                    multiple={true}
                  />
                </div>
                {imgTypeErr[1] && (
                  <p className="exceeded-dim-message">
                    <i className="fa fa-exclamation-triangle m-r-5"></i>
                    Please only upload PNG, JPG, and JPEG images
                  </p>
                )}
                {exceededDimenstions.length > 0 && (
                  <p className="exceeded-dim-message">
                    <i className="fa fa-exclamation-triangle m-r-5"></i>
                    The following images exceeded max limit
                  </p>
                )}
                {exceededDimenstions.map((item, i) => (
                  <p key={i} className="exceeded-img-name">
                    <i className="fa fa-circle bult-icon"></i>
                    {item.name}
                  </p>
                ))}
              </Fragment>
              {!isBasketOrAddon && !isValid && notValidFields.includes('min range for images') && (
                <InlineErrorMessage
                  message={'Please add at least 2 “Images“ to publish the product'}
                />
              )}
              {!isBasketOrAddon && !isValid && notValidFields.includes('max range for images') && (
                <InlineErrorMessage
                  message={
                    'You can only add up to 6 images here, please delete some items to add new'
                  }
                />
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ProductMediaSection;
