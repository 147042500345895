import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import CustomSelect from 'components/shared/CustomSelect';
// actions
import { setToggleTechTicketModal, setTopicListData } from 'store/ticketing/TicketingActions';
// selectors
import { getDecodedToken } from 'store/app/AppSelectors';
import { getTopicsListData } from 'store/ticketing/TicketingSelectors';
// api
import TicketingService from 'services/TicketingService';

const TechTicketModal = ({ isTechTicketModalOpen }) => {
  const [topic, setTopic] = useState('');
  const [topicTitle, setTopicTitle] = useState('');
  const [title, setTitle] = useState('');
  const [orderId, setOrderId] = useState('');
  const [description, setDescription] = useState('');
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const decodedToken = useSelector((state) => getDecodedToken({ state }));
  const topicsList = useSelector((state) => getTopicsListData({ state }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTopicListData({ departments: 1, status: true }));
  }, []);

  const handleCreateTicket = async () => {
    const dataBody = {
      TopicId: topic.id,
      TopicName: topic.title,
      Title: title,
      Mapid: orderId,
      Description: description,
      OpenedBy: decodedToken.Id,
      OpenedByName: decodedToken.FullName,
      Department: 1,
      OpsName: decodedToken.OpsName,
      opsId: decodedToken.OpsId,
      OpenedByEmail: decodedToken.Email,
    };
    setDisableSaveBtn(true);
    try {
      const res = await TicketingService.createTechTicket(dataBody);
      setDisableSaveBtn(true);
      dispatch(setToggleTechTicketModal());
      toast.success(res?.data?.message ?? 'New ticket created');
    } catch (err) {
      setDisableSaveBtn(false);
      dispatch(setToggleTechTicketModal());
      toast.error(err?.response?.data?.message ?? 'Failed to create new ticket');
    }
  };

  const handleTopicChange = (e) => {
    const topics = cloneDeep(topicsList);
    const selectedTopic = topics.filter(function (t) {
      return t.id === e.itemData.id;
    })[0];
    const formatedTopic = JSON.stringify(selectedTopic);
    setTopic(JSON.parse(formatedTopic));
    setTopicTitle(formatedTopic);
    setTitle(e.itemData.title);
  };

  const handleOrderIdChange = (e) => {
    if (e.target.value) {
      if (!isNaN(parseInt(e.target.value, 10))) {
        setOrderId(parseInt(e.target.value, 10));
      }
    } else setOrderId(e.target.value);
  };

  const validateForm = () => {
    if (topic === '' || title === '' || description === '') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [topic, title, description]);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isTechTicketModalOpen}
        toggle={() => dispatch(setToggleTechTicketModal())}
        className="m-w-600"
      >
        <ModalHeader>Create Tech Ticket</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group col-12 filter-select">
              <label htmlFor="tech-ticket-topic">Topic</label>
              <CustomSelect
                id="tech-ticket-topic"
                listData={topicsList}
                handleChange={handleTopicChange}
                placeholder="Select a topic"
                textPropName="title"
                valuePropName="id"
                selectedValue={topicTitle}
              />
            </div>
            {topic && (
              <div className="form-group col-12">
                <label className="col-form-label" htmlFor="message-text">
                  Actions To Take
                </label>
                <textarea
                  value={topic.actionsToTake}
                  className="form-control"
                  id="message-text"
                  rows={5}
                  readOnly
                ></textarea>
              </div>
            )}
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Title
              </label>
              <input
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
                value={title}
                id="message-text"
              ></input>
            </div>
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Order Id (Optional)
              </label>
              <input
                onChange={handleOrderIdChange}
                className="form-control"
                value={orderId}
                id="message-text"
              ></input>
            </div>
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Description
              </label>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
                className="form-control"
                value={description}
                id="message-text"
              ></textarea>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={disableSaveBtn || disableBtn}
            onClick={handleCreateTicket}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={() => dispatch(setToggleTechTicketModal())} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TechTicketModal;
