import React from 'react';
import { useDispatch } from 'react-redux';
// material UI
import Tooltip from '@mui/material/Tooltip';
// actions
import {
  setActionBoxModalOpen,
  setCardMessageModalOpen,
  setIsCartModalOpen,
  setIsNoteForTeamOpen,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// modals
import TableEditAction from '../modals/TableEditAction';
import OrderPriority from './OrderPriority';

const QuickActionsDataTableActions = ({
  cStepString,
  cStep,
  id,
  noteForTeam,
  priorityID,
  status,
  paymentDate,
  isPreOrder,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center flex-column quick-actions-wrapper">
      {isPreOrder ? <span className="btn priority-btn btn-light-warning mb-2">Pre Order</span> : ''}
      {status !== 1 && status !== 3 && (
        <div className="order-priority-contianer">
          <OrderPriority orderId={id} priorityId={priorityID} isQuickAction={true} />
        </div>
      )}
      <div className="d-flex align-items-center quick-actions-wrapper">
        <TableEditAction CstepString={cStepString} orderCstep={cStep} orderId={id} button />
        <Tooltip title="Card">
          <img
            onClick={() => dispatch(setCardMessageModalOpen(id))}
            src="https://cdn.floward.com/web/Files/attachment/message-card-637374051145867535.svg"
            alt="Card"
          />
        </Tooltip>
        <Tooltip title="Action-box">
          <img
            onClick={() => dispatch(setActionBoxModalOpen(id, paymentDate))}
            src="https://cdn.floward.com/web/Files/attachment/action-box-637468283181932112.png"
            alt="Action-box"
          />
        </Tooltip>
        <Tooltip title="Cart">
          <img
            onClick={() => dispatch(setIsCartModalOpen(id))}
            src="https://cdn.floward.com/web/Files/attachment/126083-01-637471684725450075.png"
            alt="Cart"
          />
        </Tooltip>
        {noteForTeam && (
          <Tooltip title="Note for team">
            <img
              className="note-team-img"
              onClick={() => dispatch(setIsNoteForTeamOpen(noteForTeam))}
              src="https://is3-ssl.mzstatic.com/image/thumb/Purple124/v4/83/6c/d3/836cd372-2a19-1882-3b68-b5fcc66ba8e7/source/256x256bb.jpg"
              alt="Note for team"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default QuickActionsDataTableActions;
