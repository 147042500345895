import React, { Fragment, useState } from 'react';
import RecepientTodayOrdersModal from './modals/RecepientTodayOrdersModal';

const RecepientTodayOrdersBtn = ({ rowData }) => {
  const [isRecepientOrdersModalOpen, setIsRecepientOrdersModalOpen] = useState(false);
  return (
    <Fragment>
      <button
        className="btn btn-danger today-order-btn"
        onClick={() => setIsRecepientOrdersModalOpen((prev) => !prev)}
      >
        {`Today's Orders: ${rowData.recipientTodayOrders}`}
      </button>
      {isRecepientOrdersModalOpen && (
        <RecepientTodayOrdersModal
          isRecepientOrdersModalOpen={isRecepientOrdersModalOpen}
          setIsRecepientOrdersModalOpen={setIsRecepientOrdersModalOpen}
          rowData={rowData}
        />
      )}
    </Fragment>
  );
};

export default RecepientTodayOrdersBtn;
