import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material UI
import Paper from '@mui/material/Paper';
// actions
import {
  setCurrentQuickActionsTablePage,
  setTableData,
  setRequiredParamsForQuickActionsTable,
  setLocationLinkIds,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getCurrentQuickActionsTablePage,
  getQuickActionsPageSize,
  getQuickActionsTotalData,
  getQuickActionsTableParams,
  getTableData,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// components
import DJHPagination from 'components/shared/Pagination';
import QuickActionsDataGrid from './QuickActionsDataGrid';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// constants
import { getOrderQuickActionsPageUrl } from 'constant/AppUrls';
import { convertObjectIntoQueryParams } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';

const QuickActionsDataTable = () => {
  const dispatch = useDispatch();
  const currentQuickActionsTablePage = useSelector((state) =>
    getCurrentQuickActionsTablePage({ state })
  );
  const params = useSelector((state) => getQuickActionsTableParams({ state }));
  const pageSize = useSelector((state) => getQuickActionsPageSize({ state }));
  const totalDataNum = useSelector((state) => getQuickActionsTotalData({ state }));
  const data = useSelector((state) => getTableData({ state }));
  const navigate = useNavigate();

  const onPageChange = (i) => {
    dispatch(setCurrentQuickActionsTablePage(i));
    const newParams = cloneDeep(params);
    newParams.page = i;
    dispatch(setRequiredParamsForQuickActionsTable(newParams));
    const queryParams = newParams;
    const queryString = convertObjectIntoQueryParams(queryParams);

    navigate({
      pathname: getOrderQuickActionsPageUrl(),
      search: queryString,
    });
    dispatch(setTableData(params.searchkey));
    document.documentElement.scrollTop = 0;
    const classNames = document.getElementsByClassName('selectAllcheckbox')?.[0]?.className || '';
    const isChecked = classNames.includes('is-checked');
    if (isChecked) {
      document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
      dispatch(setLocationLinkIds(''));
    }
  };

  return (
    <div className="quick-actions-table toolbar-table-style">
      <div className="control-pane">
        <AppErrorBoundary>
          <QuickActionsDataGrid data={data.data} premium={false} />
        </AppErrorBoundary>
        {totalDataNum > pageSize && (
          <AppErrorBoundary>
            <Paper className="quick-actions-pagination">
              <DJHPagination
                onPageChange={onPageChange}
                currentPage={currentQuickActionsTablePage}
                pageSize={pageSize}
                itemsCount={totalDataNum}
              />
            </Paper>
          </AppErrorBoundary>
        )}
      </div>
    </div>
  );
};

export default QuickActionsDataTable;
