import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// components
import SectionLoader from 'components/common/SectionLoader';
import UserCardsDataGrid from './UserCardsDataGrid';
// actions
import { getUserSavedCC } from 'store/permissions/PermissionsActions';
// selectors
import { getUserSavedCCData } from 'store/permissions/PermissionsSelector';

const UserCardsDetails = ({ userId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const creditcardsData = useSelector((state) => getUserSavedCCData({ state }));
  const dispatch = useDispatch();

  const fetchSavedCC = async () => {
    setIsLoaderOpen(true);
    await dispatch(getUserSavedCC(userId));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchSavedCC();
  }, []);

  return (
    <div className="card row checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          Associated cards <i className="fa fa-info-circle"></i>
        </h4>
      </div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="card-body">
          <UserCardsDataGrid data={creditcardsData} />
        </div>
      )}
    </div>
  );
};

export default UserCardsDetails;
