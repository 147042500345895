import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatDDMM, timeFormatHHMM, exportToExcel, HasPermission } from 'constant/Helpers';
import { localStorageKeys, flagIcon } from 'constant/Enums';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import MaterialDataGrid from 'components/shared/MaterialDataGrid';
// components
import OrderBlock from './DataTableComponents/OrderBlock';
import SenderRecipientBlock from './DataTableComponents/SenderRecipientBlock';
import RecepientTodayOrdersBtn from './RecepientTodayOrdersBtn';
import RecipientAddress from './DataTableComponents/RecipientAddress';
import ProductImage from './DataTableComponents/ProductImage';
import QuickActionsDataTableActions from './DataTableComponents/QuickActionsDataTableActions';
// constants
import TableEditAction from './modals/TableEditAction';
// actions
import {
  setToggleNoteModalOpen,
  setLocationLinkIds,
  setSelectedOrdersIds,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';

const QuickActionsDataGrid = ({ data, premium }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const countryId = getFromLocalStorage(localStorageKeys.OPS_CENTER).countryID;
  const dispatch = useDispatch();

  //columns
  const columns = [
    {
      title: 'Order',
      field: 'id',
      render: (rowData) => (
        <OrderBlock
          id={rowData.id}
          detailsPrinted={rowData.detailsPrinted}
          membershipType={rowData.membershipType}
          premium={premium}
          isBraillePrint={rowData.isBraillePrint}
          //case of pickup and package cart contain 1 item only
          giftPickUp={
            rowData.carts[0]?.isAvailableForPickUpAndPackage
              ? rowData.carts[0].isAvailableForPickUpAndPackage
              : false
          }
          isQuickActions
        />
      ),
    },
    {
      title: 'Ops',
      render: (rowData) => (
        <div className="opsContainer">
          <img alt={rowData.ops?.nickName ?? ''} src={rowData.ops?.iconFullPath} />
          <p>{rowData.ops?.nickName}</p>
          {rowData.nwDelivery && (
            <div className="vip-order" style={{ marginTop: 0, backgroundColor: '#022069' }}>
              NW
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Date',
      render: (rowData) => (
        <div className="dateDiv">
          <p className="dateRow">{rowData.paymentDate && dateFormatDDMM(rowData.paymentDate)}</p>
          <p>{rowData.paymentDate && timeFormatHHMM(rowData.paymentDate)}</p>
        </div>
      ),
    },
    {
      title: 'Grand Total',
      field: 'grandTotal',
      render: (rowData) => (
        <p className="total-txt">{rowData.grandTotal ? rowData.grandTotal : ''}</p>
      ),
    },
    {
      title: 'Sender',
      render: (rowData) => (
        <SenderRecipientBlock
          id={rowData.id}
          name={rowData.user ? rowData.user.name : ''}
          phone={rowData.user ? rowData.user.phone : ''}
          shareSender={rowData.shareSender}
          isSender
          premium={premium}
          orderCstep={rowData.cStep}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        (rowData.user?.name + '' + rowData.user?.phone)?.toLowerCase().indexOf(term) != -1,
    },
    {
      title: 'Recipient',
      render: (rowData) => (
        <>
          <SenderRecipientBlock
            name={rowData.recipient ? rowData.recipient.recipentName : ''}
            phone={rowData.recipient ? rowData.recipient.recipientPhone : ''}
            extra={rowData.recipient ? rowData.recipient.extraRecipientNumber : ''}
            premium={premium}
          />
          {rowData.recipientTodayOrders >= 2 && <RecepientTodayOrdersBtn rowData={rowData} />}
        </>
      ),
      customFilterAndSearch: (term, rowData) =>
        (rowData.recipient?.recipentName + '' + rowData.recipient?.recipientPhone)
          ?.toLowerCase()
          .indexOf(term) != -1,
    },
    {
      title: 'Address',
      render: (rowData) => (
        <RecipientAddress
          id={rowData.id}
          opsID={rowData.opsID}
          recipient={rowData.recipient}
          opsInfo={rowData.ops}
          orderCstep={rowData.cStep}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        (rowData.recipient?.address + '' + rowData.recipient?.extra + '' + rowData.recipient?.area)
          ?.toLowerCase()
          .indexOf(term) != -1,
      cellStyle: {
        width: '12%',
      },
    },
    {
      title: 'Delivery Date',
      render: (rowData) => (
        <div className="deliveryContainer">
          <p className="dateRow">{rowData.deliveryDate && dateFormatDDMM(rowData.deliveryDate)}</p>
          <p className="dateRow">{rowData.deliveryTimeName}</p>
          {rowData.carts && <ProductImage rowData={rowData} />}
        </div>
      ),
    },
    {
      title: 'Actions',
      render: (rowData) => (
        <QuickActionsDataTableActions
          id={rowData.id}
          priorityID={rowData.orderPriority}
          status={rowData.status}
          cStep={rowData.cStep}
          cStepString={rowData.cStepString}
          noteForTeam={rowData.noteForTeam}
          paymentDate={rowData.paymentDate}
          isPreOrder={rowData?.isPreOrder}
        />
      ),
    },
    {
      title: 'OnFleet',
      render: (rowData) => (
        <div className="d-flex  justify-content-center onfleet-template">
          {rowData.taskId ? (
            <img alt="onFleet" src={flagIcon.trueIcon} />
          ) : (
            <img alt="not-OnFleet" src={flagIcon.falseIcon} />
          )}
        </div>
      ),
      hidden: countryId === 8 ? false : true,
    },
    {
      title: 'Note',
      render: (rowData) => (
        <div className="d-flex flex-column justify-content-center text-center">
          <TableEditAction
            orderId={rowData.id}
            openActionModal={setToggleNoteModalOpen}
            rowNote={rowData.note}
          />
          {rowData.note && <p className="actions-note">{rowData.note}</p>}
        </div>
      ),
      customFilterAndSearch: (term, rowData) => rowData.note?.toLowerCase().indexOf(term) != -1,
    },
  ];

  //actions
  const actions = [
    {
      icon: () => (
        <div className="btn btn-secondary excel-export-btn">
          <i className="fa fa-file"></i> Excel Export
        </div>
      ),
      onClick: () => handleExcelExport(),
      tooltip: 'export',
      isFreeAction: true,
      style: {
        display: HasPermission(permissionsList, 'quick_actions', 'can_export') ? 'block' : 'none',
      },
    },
  ];

  //options
  const options = {
    paging: false,
    showTitle: false,
    columnsButton: true,
    selection: true,
    sorting: false,
  };

  const onSelectionChange = (selectedrecords) => {
    const ids = selectedrecords.map((el) => el.id).join(',');
    const ordersIds = selectedrecords.map((el) => el.id);
    dispatch(setLocationLinkIds(ids));
    dispatch(setSelectedOrdersIds(ordersIds));
  };

  const handleExcelExport = () => {
    let excelData = [];
    data.forEach((row) => {
      let excelRow = {};
      excelRow.orderId = row.id;
      excelRow.ops = row.ops?.nickName;
      excelRow.date = row.paymentDate && dateFormatDDMM(row.paymentDate);
      excelRow.grandTotal = row.grandTotal;
      excelRow.sender = row.user?.name;
      excelRow.recipient = row.recipient?.recipentName;
      excelRow.address = row.recipient?.address;
      excelRow.deliveryDate = row.deliveryDate && dateFormatDDMM(row.deliveryDate);
      excelData.push(excelRow);
    });
    exportToExcel(excelData, 'ordersList');
  };

  return (
    <div className="control-section">
      <MaterialDataGrid
        data={data}
        columns={columns}
        options={options}
        actions={actions}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};

export default QuickActionsDataGrid;
