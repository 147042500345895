import React, { useState, Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import debounce from 'lodash/debounce';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
// api
import VouchersService from 'services/VouchersService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const AddLimitedCategoryModal = ({
  isAddCatModalOpen,
  setIsAddCatModalOpen,
  couponId,
  getLimitCategories,
}) => {
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const fetchData = debounce(async (keyword) => {
    const params = {
      catName: keyword,
    };
    setSearchKey(keyword);
    setIsLoaderOpen(true);
    try {
      const res = await VouchersService.searchVoucherLimitCat(params, couponId);
      setSearchResult(res.data);
      setIsLoaderOpen(false);
    } catch (e) {
      errorLogger('AddLimitedCategoryModal:fetchData', e);
      setIsLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    if (keyword.length >= 3) {
      fetchData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleSelectedCat = async (cat) => {
    const bodyData = {
      CatID: cat.id,
      VoucherID: couponId,
    };
    try {
      const res = await VouchersService.createVoucherLimitedCat(bodyData);
      setIsAddCatModalOpen(false);
      getLimitCategories();
      toast.success(res?.data?.message ?? 'Voucher Categories has been added successfully');
    } catch (err) {
      errorLogger('AddLimitedCategoryModal:handleSelectedCat', err);
      toast.error(err?.response?.data?.message ?? "Voucher Categories can't be added");
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddCatModalOpen}
        toggle={() => setIsAddCatModalOpen((prev) => !prev)}
        className="m-w-600 "
      >
        <ModalHeader
          isOpen={isAddCatModalOpen}
          toggle={() => setIsAddCatModalOpen((prev) => !prev)}
        >
          Add Category
        </ModalHeader>
        <ModalBody>
          <div className="form-group col-12">
            <form>
              <label htmlFor="category-search">Search Category</label>
              <input
                id="category-search"
                className="form-control"
                type="text"
                placeholder="Type category name"
                onChange={(e) => handleSearch(e.target.value)}
                defaultValue={searchKey}
              />
            </form>
          </div>
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <Fragment>
              {searchResult.length > 0 && (
                <div
                  style={{ height: showTable ? 'auto' : '', maxHeight: 220 }}
                  className="table-responsive shopping-table text-center"
                >
                  {showTable && (
                    <table className="table table-bordernone create-order-table">
                      <thead>
                        <tr>
                          <th>Name English</th>
                          <th>Name Arabic</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResult.map((item, i) => (
                          <tr
                            className="search-table-row pointer"
                            onClick={() => handleSelectedCat(item)}
                            key={i}
                          >
                            <td>{item.name}</td>
                            <td>{item.nameArabic}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </Fragment>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddLimitedCategoryModal;
