import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
// api
import KeyValueService from '../../services/KeyValueService';
// selector
import { getKeyValueListcurrentPage, getKeyValueStatus } from './keyValueSelectors';

export const setKeyValueList = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await KeyValueService.getAllKeyValue(params);
    dispatch({
      type: 'GET_KEY_VALUES_LIST',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    toast.error('Failed to fetch key values list!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const toggleStatus = (id) => async (dispatch, getState) => {
  const state = getState();
  const page = getKeyValueListcurrentPage({ state });
  const isActive = getKeyValueStatus({ state });
  let params = { page };
  if (isActive !== 0) {
    params = { ...params, isactive: isActive === 1 };
  }
  dispatch({
    type: 'TOGGLE_KEY_VALUES_STATUS',
    payload: id,
  });
  dispatch(setKeyValueList(params));
};

export const setKeyValuesStatus = (id) => (dispatch) => {
  dispatch({
    type: 'SET_KEY_VALUES_STATUS',
    payload: id,
  });
};
