/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader, CardBody, Card } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import RestrictedSection from 'components/shared/RestrictedSection';
import { convertUtcToLocalTime } from 'constant/Helpers';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
// actions
import { setTicketsListByOrderId } from 'store/ticketing/TicketingActions';
// api
import TicketingService from 'services/TicketingService';
// selectors
import { getOrderTicketsList } from 'store/ticketing/TicketingSelectors';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ticketStatus = {
  0: 'Opened',
  1: 'Assigned',
  2: 'Closed',
};

const TicketsListBox = ({ orderId, setToggleSupportTicketModal }) => {
  const ticketsList = useSelector((state) => getOrderTicketsList({ state }));
  const [commentList, setCommentList] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isShowingComment, setIsShowingComment] = useState(false);
  const [clickedId, setClickedId] = useState(0);
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async (orderId) => {
    setIsLoaderOpen(true);
    await dispatch(setTicketsListByOrderId(orderId));
    setIsLoaderOpen(false);
  };

  const showComments = async (item) => {
    setIsShowingComment(true);
    setClickedId(item.id);
    try {
      const res = await TicketingService.getTicketCommentsList(item.id);
      commentList.forEach(function (c) {
        if (c.id === item.id) {
          c.comments = res.data;
          c.isOpen = true;
        }
      });
      setIsShowingComment(false);
    } catch (err) {
      errorLogger('TicketsListBox:showComments', err);
      setIsShowingComment(false);
      toast.error('Failed to get ticket comments');
    }
  };
  const hideComments = async (id) => {
    setToggle(!toggle);
    commentList.forEach(function (c) {
      if (c.id === id) {
        c.isOpen = false;
      }
    });
  };
  useEffect(() => {
    fetchData(orderId);
  }, []);

  useEffect(() => {
    if (ticketsList.length > 0) {
      ticketsList.forEach(function (c) {
        c.isOpen = false;
      });
      setCommentList(ticketsList);
    }
  }, [ticketsList]);

  const redirectToTicketPage = (item) => {
    window.open(`/ticketing/ticket/${item.id}`, '_blank');
  };

  // custom columns
  const commentTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.comment}</p>
      </div>
    );
  };

  const byTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{rowData.commentFromName}</p>
      </div>
    );
  };

  const dateTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{convertUtcToLocalTime(rowData.createdDate)}</p>
      </div>
    );
  };

  const handleTicketStatus = (status) => {
    if (status === 2) {
      return 'ticket-gray-status';
    } else {
      if (status === 0) {
        return 'ticket-red-status';
      } else {
        return 'ticket-green-status';
      }
    }
  };

  return (
    <div>
      <div className="row card ticket-list-box-wrapper">
        <CardHeader className="card-header-border">
          <RestrictedSection page="order_details" action="create_ticket">
            <div
              onClick={() => dispatch(setToggleSupportTicketModal())}
              className="recent-notification customer-call"
            >
              <i className="fa fa-ticket"></i>
              <span className="ticket-title">Create Ticket</span>
            </div>
          </RestrictedSection>
        </CardHeader>

        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <Fragment>
            {ticketsList.length > 0 && (
              <div className="ticket-list-wrapper">
                {ticketsList.map((item, i) => (
                  <div key={i}>
                    <Card className="ticket-list-card">
                      <CardBody className="recent-notification">
                        <div className="card ecommerce-widget">
                          <div
                            onClick={() => redirectToTicketPage(item)}
                            className="card-body support-ticket-font"
                          >
                            <div className="row">
                              <div className="col-12">
                                <div className="ticket-item-wrapper">
                                  <span className="ticket-item-title">{item.title}</span>
                                  <span>({item.topicName})</span>
                                </div>
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 ticket-item-status-wrapper">
                            <span className="ticket-item-openedby">By: {item.openedByName}</span>
                            <span className={handleTicketStatus(item.status)}>
                              {ticketStatus[item.status]}
                            </span>
                            <span className="ticket-item-open-date">
                              {convertUtcToLocalTime(item.openedDate)}
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <a
                      className="show-comments-link"
                      onClick={() => (item.isOpen ? hideComments(item.id) : showComments(item))}
                    >
                      {item.isOpen ? 'Hide' : 'Show'} comments
                    </a>
                    {isShowingComment && item.id === clickedId ? (
                      <SectionLoader height="80px" />
                    ) : (
                      item.comments &&
                      item.isOpen && (
                        <div className="quick-actions-table no-toolbar-table-style comments-table">
                          <GridComponent dataSource={item.comments} allowTextWrap={true}>
                            <ColumnsDirective>
                              <ColumnDirective
                                field="comment"
                                headerText="Comment"
                                template={commentTemplate}
                                width={100}
                              />
                              <ColumnDirective
                                field="commentFromName"
                                headerText="By"
                                template={byTemplate}
                                width={100}
                              />
                              <ColumnDirective
                                field="createdDate"
                                headerText="Added at"
                                template={dateTemplate}
                                width={100}
                              />
                            </ColumnsDirective>
                          </GridComponent>
                        </div>
                      )
                    )}
                  </div>
                ))}
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default TicketsListBox;
