import { updateObject } from '../../utility';

const initialState = {
  opsCenters: [],
  disableLoginBtn: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SIGN_IN_SUCCESS': {
      return updateObject(state, {
        opsCenters: action.payload,
      });
    }
    case 'SET_DISABLE_LOGIN_BTN_TRUE': {
      return updateObject(state, {
        disableLoginBtn: true,
      });
    }
    case 'SET_DISABLE_LOGIN_BTN_FALSE': {
      return updateObject(state, {
        disableLoginBtn: false,
      });
    }
    default:
      return state;
  }
};

export default reducer;
