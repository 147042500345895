// import React from "react";
import { useSelector } from 'react-redux';
// constant
import { HasPermission } from 'constant/Helpers';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';

const RestrictedSection = ({ action, page, children }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  if (HasPermission(permissionsList, page, action)) return children;
  return null;
};

export default RestrictedSection;
