import { updateObject } from '../utility';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  keyValueList: [],
  pageSize: '',
  currentPage: '',
  rowCount: '',
  keyValueStatus: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_KEY_VALUES_LIST': {
      return updateObject(state, {
        keyValueList: action.payload.Data,
        pageSize: action.payload.PageSize,
        currentPage: action.payload.CurrentPage,
        rowCount: action.payload.RowCount,
      });
    }
    case 'TOGGLE_KEY_VALUES_STATUS': {
      const updatedKeyValueList = cloneDeep(state.keyValueList);
      for (let i = 0; i < updatedKeyValueList.length; i++) {
        if (updatedKeyValueList[i].Id === action.payload) {
          updatedKeyValueList[i].isActive = !updatedKeyValueList[i].isActive;
          break;
        }
      }
      return updateObject(state, {
        keyValueList: updatedKeyValueList,
      });
    }
    case 'SET_KEY_VALUES_STATUS': {
      return updateObject(state, {
        keyValueStatus: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
