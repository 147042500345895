import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import { dateFormatDDMM } from 'constant/Helpers';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const QuickOrdersDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const paymentStatusString = {
    0: 'Not_Paid',
    1: 'Paid',
    2: 'Canceled',
    3: 'Refound',
  };

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const customerTemplate = (rowData) => (
    <a
      href={`admin/users/${rowData.customerId}`}
      target="_blank"
      className="customerDiv"
      rel="noreferrer"
    >
      <p className="quick-orders-user">UserName: {rowData.customerName}</p>
      <p className="quick-orders-user">Phone: {rowData.customerPhone}</p>
      <p className="quick-orders-user">Email: {rowData.customerEmail}</p>
    </a>
  );

  const recipientTemplate = (rowData) => (
    <div className="customerDiv">
      <p>Recipient Name: {rowData.address && rowData.address.recipientName}</p>
      <p>Recipient Phone: {rowData.address && rowData.address.recipientPhone}</p>
    </div>
  );

  const paymentStatusTemplate = (rowData) => (
    <span>{paymentStatusString[rowData.paymentStatus]}</span>
  );

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={false}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          allowTextWrap={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Order"
              template={(rowData) => (
                <a
                  className="primary-color"
                  href={`/order/${rowData.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {rowData.id}
                </a>
              )}
              customExcelTemplate={(rowData) => rowData.id}
              field="id"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="City" field="nickName" width={80} textAlign="Center" />
            <ColumnDirective headerText="Amount" field="grandTotal" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Discount"
              field="discountAmount"
              width={80}
              textAlign="Center"
            />
            <ColumnDirective headerText="VAT" field="vat" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="Delivery Cost"
              field="deliveryCost"
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Payment Date"
              template={(rowData) => (
                <span>{rowData.paymentDate && dateFormatDDMM(rowData.paymentDate)}</span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.paymentDate && dateFormatDDMM(rowData.paymentDate)
              }
              field="paymentDate"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Customer"
              template={customerTemplate}
              customExcelTemplate={(rowData) => rowData.customerName}
              field="customerName"
              width={180}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Recipient"
              template={recipientTemplate}
              customExcelTemplate={(rowData) => rowData.address && rowData.address.recipientName}
              width={180}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Delivery Date"
              template={(rowData) => (
                <span>{rowData.deliveryDate && dateFormatDDMM(rowData.deliveryDate)}</span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.deliveryDate && dateFormatDDMM(rowData.deliveryDate)
              }
              field="deliveryDate"
              width={100}
              textAlign="Center"
            />

            <ColumnDirective
              headerText="Payment Status"
              field="paymentStatus"
              template={paymentStatusTemplate}
              width={120}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(QuickOrdersDataGrid);
