import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// selectors
import { getIsLoggedIn } from '../store/app/AppSelectors';
// constants
import { getHomePageUrl } from '../constant/AppUrls';
import AppErrorBoundary from '../components/errorBoundary/AppErrorBoundary';

const RegistrationRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));

  if (!isLoggedIn) {
    return (
      <AppErrorBoundary>
        <Component {...rest} />
      </AppErrorBoundary>
    );
  }
  return <Navigate to={{ pathname: getHomePageUrl(), state: { from: rest.location } }} />;
};

export default RegistrationRoute;
