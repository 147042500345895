import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const CategoryBreakDownDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Type"
              field="protypesting"
              width={120}
              customExcelTemplate={(rowData) => rowData.protypesting}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Orders"
              field="totalOrdersCount"
              width={120}
              customExcelTemplate={(rowData) => rowData.totalOrdersCount}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Order on total Orders%"
              template={(rowData) => (
                <p>
                  {rowData.ordersofTotalOrders != null &&
                    `${rowData.ordersofTotalOrders.toFixed(
                      opsCenter?.country?.decimalNumber ?? 2
                    )}%`}
                </p>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.ordersofTotalOrders != null &&
                `${rowData.ordersofTotalOrders.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`
              }
              textAlign="Center"
            />

            <ColumnDirective
              headerText="Revenue (Sum Price*QTY)"
              template={(rowData) => (
                <p>
                  {rowData.totalRevenueUSD != null &&
                    `${rowData.revenue.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`}
                </p>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.totalRevenueUSD != null &&
                `${rowData.revenue.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`
              }
              textAlign="Center"
            />

            <ColumnDirective
              headerText="Revenue Per Total Revenue %"
              template={(rowData) => (
                <p>
                  {rowData.renveueoftotalrenvenue != null &&
                    `${rowData.renveueoftotalrenvenue.toFixed(
                      opsCenter?.country?.decimalNumber ?? 2
                    )}%`}
                </p>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.renveueoftotalrenvenue != null &&
                `${rowData.renveueoftotalrenvenue.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`
              }
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(CategoryBreakDownDataGrid);
