import React, { useState, useEffect } from 'react';
import DateRangePickerComponent from 'components/shared/DateRangePickerComponent';
import { dateFormat, isoFormat } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';

const LogFilter = ({ inputValues, setInputValues, handleSubmit, handleExport, isLoading }) => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateRange = (item) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.startDate = isoFormat(item.selection.startDate);
    updatedParams.endDate = isoFormat(item.selection.endDate);
    setInputValues(updatedParams);
    setDateRange(item.selection);
  };

  useEffect(() => {
    setDateRange({
      startDate: new Date(inputValues.startDate),
      endDate: new Date(inputValues.endDate),
      key: 'selection',
    });
    if (inputValues.startDate === '' && inputValues.startDate === '') {
      setDateRange({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      });
    }
  }, [inputValues]);

  return (
    <div className="card">
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <div className="form-group col-flex ">
                <label className="bold-title" htmlFor="log-filter-status">
                  Date Range
                </label>
                <div className="normal-flex">
                  <input
                    id="log-filter-status"
                    className="form-control digits bg-white pointer"
                    value={`${dateFormat(dateRange.startDate)} - ${dateFormat(dateRange.endDate)}`}
                    onClick={() => setShowDatePicker((prev) => !prev)}
                    readOnly
                  />
                </div>
                {showDatePicker && (
                  <DateRangePickerComponent
                    setShowDatePicker={setShowDatePicker}
                    handleDateRange={handleDateRange}
                    dateRange={dateRange}
                  />
                )}
              </div>
            </div>
            <div className="col-6 search-btn-container">
              {/* <button
                onClick={handleExport}
                className="btn btn-secondary mr-1"
                type="button"
                disabled={isLoading}
              >
                Export
              </button> */}
              <button onClick={handleSubmit} className="btn btn-primary mr-1" type="button">
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LogFilter;
