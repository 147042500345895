import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  convertQueryObjToValues,
} from 'constant/Helpers';
import { getQuickOrdersPageUrl } from 'constant/AppUrls';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
// api
import QuickActionService from 'services/QuickActionService';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// components
import SectionLoader from 'components/common/SectionLoader';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const QuickOrdersDataGrid = React.lazy(() => import('components/quickOrders/QuickOrdersDataGrid'));
const QuickOrdersFilter = React.lazy(() => import('components/quickOrders/QuickOrdersFilter'));
const DJHPagination = React.lazy(() => import('components/shared/Pagination'));

const QuickOrdersPage = () => {
  const [data, setData] = useState([]);
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const [currentPage, setCurrentPage] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [rowCount, setRowCount] = useState('');
  const [inputValues, setInputValues] = useState({
    orderstatus: 2,
    orderType: -1,
    paymentstatus: -1,
    orderId: 0,
    Channel: -1,
    searchkey: '',
    steps: -1,
    timeslot: -1,
    paymentMethod: -1,
    recipientKey: '',
    Resultdate: null,
    deliverydate: null,
    ProductId: -1,
    OpsId: getFromLocalStorage('opsId'),
    page: 1,
    ProductKey: '',
    country: 0,
  });
  const [countriesList, setCountriesList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const countriesData = [];
    opsCenters.forEach((ops) => {
      if (
        countriesData.filter((country) => {
          return country.id === ops.country.id;
        }).length === 0
      ) {
        countriesData.push(ops.country);
      }
    });
    setCountriesList(countriesData);
  }, [opsCenters]);

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await QuickActionService.getQuickOrdersData(params);
      setData(res.data.data);
      setCurrentPage(res.data.currentPage);
      setPageSize(res.data.pageSize);
      setRowCount(res.data.rowCount);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('QuickOrdersPage:fetchData', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  const fetchCountriesList = () => {
    try {
      dispatch(setSignInSuccess());
    } catch (err) {
      errorLogger('QuickOrdersPage:fetchCountriesList', err);
      toast.error('Failed to get coutries list!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      const newValue = convertQueryObjToValues(queryObj, inputValues);
      fetchData(newValue);
      setInputValues(newValue);
    } else {
      fetchData(inputValues);
    }
    fetchCountriesList();
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: getQuickOrdersPageUrl(),
      search: queryString,
    });
    fetchData(inputValues);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);

    navigate({
      pathname: getQuickOrdersPageUrl(),
      search: queryString,
    });
    fetchData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  const handleExport = async () => {
    try {
      const res = await QuickActionService.getExportQuickOrdersData({ ...inputValues, page: -1 });
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('QuickOrdersPage:handleExport', err);
      toast.error(err?.response?.data?.message ?? 'Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <React.Suspense fallback={<SectionLoader />}>
        <QuickOrdersFilter
          inputValues={inputValues}
          setInputValues={setInputValues}
          handleSubmit={handleSubmit}
          countriesList={countriesList}
          handleExport={handleExport}
        />
        <QuickOrdersDataGrid data={data} inputValues={inputValues} />
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+currentPage}
          pageSize={+pageSize}
          itemsCount={+rowCount}
        />
      </React.Suspense>
    </div>
  );
};

export default QuickOrdersPage;
