import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserMenu from './userMenu';
import SearchHeader from './searchHeader';
import Notification from './notification';
import { Link } from 'react-router-dom';
import { AlignLeft, HelpCircle, MoreHorizontal, Bell } from 'react-feather';
import { translate } from 'react-switch-lang';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import RestrictedSection from 'components/shared/RestrictedSection';
// modals
import TechTicketModal from './TechTicketModal';
import OpsModal from './OpsModal';
// actions
import { setToggleTechTicketModal } from 'store/ticketing/TicketingActions';
// selectors
import { getnewNotificationsLength } from 'store/notifications/selectors/NotificationsSelectors';
import { getIsTechTicketModalOpen } from 'store/ticketing/TicketingSelectors';
// constants
import { localStorageKeys } from 'constant/Enums';

const HELP_CENTER_URL = 'https://help.floward.io/';

const Header = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const [isOpsModalOpen, setIsOpsModalOpen] = useState(false);
  const newNotificationsLength = useSelector((state) => getnewNotificationsLength({ state }));
  const isTechTicketModalOpen = useSelector((state) => getIsTechTicketModalOpen({ state }));
  const dispatch = useDispatch();

  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  useEffect(() => {
    if (window.screen.width < 500) {
      setSidebar(true);
      document.querySelector('.page-main-header').classList.add('open');
      document.querySelector('.page-sidebar').classList.add('open');
    } else {
      setSidebar(false);
      document.querySelector('.page-main-header').classList.remove('open');
      document.querySelector('.page-sidebar').classList.remove('open');
    }
  }, []);

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector('.page-main-header').classList.remove('open');
      document.querySelector('.page-sidebar').classList.remove('open');
    } else {
      setSidebar(!sidebar);
      document.querySelector('.page-main-header').classList.add('open');
      document.querySelector('.page-sidebar').classList.add('open');
    }
  };

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector('.right-sidebar').classList.add('show');
    } else {
      setRightSidebar(!rightSidebar);
      document.querySelector('.right-sidebar').classList.remove('show');
    }
  }

  // full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <Fragment>
      {isOpsModalOpen && (
        <OpsModal isOpsModalOpen={isOpsModalOpen} setIsOpsModalOpen={setIsOpsModalOpen} />
      )}
      {isTechTicketModalOpen && <TechTicketModal isTechTicketModalOpen={isTechTicketModalOpen} />}
      <div
        className="page-main-header"
        style={{
          backgroundColor: localStorage.getItem(localStorageKeys.NAV_BG_COLOR)
            ? getFromLocalStorage(localStorageKeys.NAV_BG_COLOR)
            : '#53a6d6',
        }}
      >
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to="/home">
                <img
                  className="img-fluid"
                  src="https://cdn.floward.com/web/Files/attachment/floward-logo-01-637374032965010488.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch" htmlFor="switch-sidebar">
                <div className="pointer" onClick={() => openCloseSidebar()}>
                  <AlignLeft color="white" />
                </div>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li>
                <SearchHeader />
              </li>
              <li>
                <RestrictedSection page="tickets" action="create_tech_ticket">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => dispatch(setToggleTechTicketModal())}
                  >
                    <i className="fa fa-ticket"></i> Tech Ticket
                  </button>
                </RestrictedSection>
              </li>
              <li className="maximize-icon">
                <a
                  className="text-dark d-flex"
                  href={HELP_CENTER_URL}
                  target="_blank"
                  rel="noreferrer"
                  title="Help Center"
                >
                  <HelpCircle color="white" />
                </a>
              </li>
              <RestrictedSection page="notifications" action="notifications">
                <li className="onhover-dropdown notification-icon baseline-flex">
                  <Notification />
                  <Bell color="white" />
                  {newNotificationsLength > 0 && (
                    <span className="badge badge-pill badge-danger pull-right notification-badge">
                      {newNotificationsLength}
                    </span>
                  )}
                </li>
              </RestrictedSection>
              <li className="onhover-dropdown one-side-border">
                <div
                  className="d-flex align-items-center"
                  onClick={() => setIsOpsModalOpen((prev) => !prev)}
                >
                  <p className="ops-city">{opsCenter.nickName} </p>
                  <img alt="ops-icon" className="header-ops-img" src={opsCenter.iconFullPath} />
                </div>
              </li>
              <UserMenu />
            </ul>
            <div
              className="d-lg-none mobile-toggle pull-right"
              onClick={() => setHeaderbar(!headerbar)}
            >
              <MoreHorizontal color="white" />
            </div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1" />
                  <polygon points="12 15 17 21 7 21 12 15" />
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName" />
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">
              {
                'Your search turned up 0 results. This most likely means the backend is down, yikes!'
              }
            </div>
          </script>
        </div>
      </div>
    </Fragment>
  );
};
export default translate(Header);
