import React from 'react';
import { useParams } from 'react-router-dom';
import TicketCommentHeader from './TicketCommentHeader';

const TicketComments = () => {
  const params = useParams();

  return <TicketCommentHeader ticketId={params?.id} />;
};

export default TicketComments;
