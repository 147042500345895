import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import SubAreasDataGrid from './SubAreasDataGrid';
import AreasActions from '../AreasActions';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// pagination
import DJHPagination from 'components/shared/Pagination';
// actions
import { getSubAreas } from 'store/delivery/deliveryAreas/areasActions';
// selectors
import { getSubAreasData } from 'store/delivery/deliveryAreas/areasSelectors';

const SubAreasDataTable = ({ inputValues, setInputValues }) => {
  const subareasData = useSelector((state) => getSubAreasData({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const handleSubmit = async (submitParams) => {
    const queryParams = submitParams ?? inputValues;
    const queryString = convertObjectIntoQueryParams(queryParams);

    navigate({
      pathname: location?.pathname,
      search: queryString,
    });
    dispatch(getSubAreas(queryParams, params?.id));
  };

  const onPageChange = async (i) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.page = i;
    setInputValues(updatedParams);
    handleSubmit(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(getSubAreas(queryObj, params?.id));
    } else {
      dispatch(getSubAreas(inputValues, params?.id));
    }
  }, [params?.id]);

  return (
    <div className="quick-actions-table toolbar-table-style">
      <AreasActions type="subarea" inputValues={inputValues} />
      <SubAreasDataGrid data={subareasData.Data} inputValues={inputValues} />
      {+subareasData.RowCount > +subareasData.PageSize && (
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+subareasData.CurrentPage}
          pageSize={+subareasData.PageSize}
          itemsCount={+subareasData.RowCount}
        />
      )}
    </div>
  );
};

export default SubAreasDataTable;
