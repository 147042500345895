import OccasionsService from '../../services/OccasionsService';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
// acctions
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
// selectors
import { getOcccasionsCardsList, getSelectedOcccasionsCardsIds } from './OccasionsSelectors';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const setOccasionsList = () => async (dispatch) => {
  try {
    const res = await OccasionsService.getOccasionsList();
    const updatedOccasionsList = res.data.sort((a, b) => a.nickname.localeCompare(b.nickname));
    dispatch({
      type: 'GET_OCCASIONS_LIST',
      payload: updatedOccasionsList,
    });
  } catch (err) {
    errorLogger('Occasion Store Fetch Data Error:', err);
    toast.error('Failed to fetch occasions list');
  }
};

const setSingleCardPredictions = (dataBody, index) => async (dispatch) => {
  try {
    const res = await OccasionsService.getOccasionsPredictions(dataBody);
    dispatch({
      type: 'SET_CARD_PREDICTIONS',
      payload: { data: res.data, index },
    });
  } catch (err) {
    errorLogger('Occasion Store Fetch Prediction Data Error:', err);
    // console.log(err);
  }
};

export const setOccasionsListData = () => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  dispatch(setOccasionsList());
  try {
    const res = await OccasionsService.getOccasionsCardsList();
    const updatedList = res.data
      .sort((a, b) => a.messageText.localeCompare(b.messageText))
      .map((item) => ({
        ...item,
        success: false,
        occasionId: '',
      }));
    dispatch({
      type: 'GET_OCCASIONS_CARDS_LIST',
      payload: updatedList,
    });

    const promisesArray = [];
    let dataBody = {};
    updatedList.forEach(async (item, index) => {
      dataBody = {
        query: item.messageText,
      };
      promisesArray.push(dispatch(setSingleCardPredictions(dataBody, index)));
    });
    await Promise.all(promisesArray);

    dispatch({
      type: 'SET_FINAL_OCCASIONS_CARDS_LIST',
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Occasion Store Fetch List of Cards Data Error:', err);
    toast.error('Failed to fetch list of cards');
    dispatch(setLoaderOpenFalse());
  }
};

export const setSelectedOccasionsCardsIds = (ids) => ({
  type: 'SET_SELECTED_OCCASIONS_CARDS_IDS',
  payload: ids,
});

export const setSavingCardOccasion = (cardId, categoryId) => async (dispatch, getState) => {
  const state = getState();
  const clonedCardsList = cloneDeep(getOcccasionsCardsList({ state }));
  const selectedCardsIds = getSelectedOcccasionsCardsIds({ state });
  const dataBody = {
    ids: cardId || selectedCardsIds,
  };
  if (!cardId && selectedCardsIds.length < 1) {
    toast.error('Please select a card');
  } else {
    try {
      await OccasionsService.saveChangedCardOccasion(dataBody, categoryId);
      clonedCardsList.forEach((el) => {
        if (cardId ? cardId.includes(el.id) : selectedCardsIds.includes(el.id)) {
          el.success = true;
          el.occasionId = categoryId;
        }
      });
      dispatch({
        type: 'SET_FINAL_OCCASIONS_CARDS_LIST',
        payload: clonedCardsList,
      });
    } catch (err) {
      errorLogger('Occasion Store Update Data Error:', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update occasion');
    }
    dispatch({
      type: 'SET_SELECTED_OCCASIONS_CARDS_IDS',
      payload: [],
    });
  }
};
