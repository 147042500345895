import React from 'react';
import MaterialTable from 'material-table';

const MaterialDataGrid = ({
  data,
  columns,
  actions,
  options,
  onRowClick,
  onSelectionChange,
  testId = 'MaterialTableTestId',
}) => {
  return (
    <div className="material-table-wrapper" data-testid={testId}>
      <MaterialTable
        data={data}
        columns={columns}
        options={options}
        actions={actions}
        onRowClick={(evt, row) => onRowClick(evt, row)}
        onSelectionChange={(rows) => onSelectionChange(rows)}
      />
    </div>
  );
};

export default MaterialDataGrid;
