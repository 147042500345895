import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { errorLogger } from 'datadog/DDUtils';
// api
import CategoriesService from '../../services/CategoriesService';
// type
import {
  GET_TOTAL_CATEGORIES_DATA,
  SET_SELECTED_CATEGORY,
  GET_SUB_CATEGORIES,
  GET_SELECTED_SUB_CATEGORY,
  SET_SELECTED_SUB_CATEGORY,
  GET_CATEGORY_OPS,
} from './CategoriesActionTypes';

export const getCategoriesData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await CategoriesService.getCategoriesData(params);
    dispatch({
      type: GET_TOTAL_CATEGORIES_DATA,
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('CategoriesActions:getCategoriesData', err);
    toast.error('Failed to fetch categories data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setSelectedCategory = (catId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    dispatch({
      type: SET_SELECTED_CATEGORY,
      payload: catId,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('CategoriesActions:setSelectedCategory', err);
    toast.error('Failed to set parent category id!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getSubCategories = (catId) => async (dispatch) => {
  try {
    const res = await CategoriesService.getSubCategoriesData(catId);
    dispatch({
      type: GET_SUB_CATEGORIES,
      payload: res.data,
    });
  } catch (err) {
    errorLogger('CategoriesActions:getSubCategories', err);
    toast.error('Failed to fetch sub-categories data!!');
  }
};

export const getSubCategory = (catId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await CategoriesService.getCategoryMainData(catId);
    dispatch({
      type: GET_SELECTED_SUB_CATEGORY,
    });
    dispatch(setSelectedSubCategory(res.data));
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('CategoriesActions:getSubCategory', err);
    toast.error('Failed to set parent category!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setSelectedSubCategory = (cat) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    dispatch({
      type: SET_SELECTED_SUB_CATEGORY,
      payload: cat,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('CategoriesActions:setSelectedSubCategory', err);
    toast.error('Failed to set parent category!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getCategoryOps = (catId) => async (dispatch) => {
  try {
    const res = await CategoriesService.getCategoryOpsData(catId);
    dispatch({
      type: GET_CATEGORY_OPS,
      payload: res.data,
    });
  } catch (err) {
    errorLogger('CategoriesActions:getCategoryOps', err);
    toast.error('Failed to fetch category ops data!!');
  }
};
