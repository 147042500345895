import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  Aggregate,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const ProductTypeSalesDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Date"
              template={(rowData) => <span>{rowData.date}</span>}
              width={120}
              customExcelTemplate={(rowData) => rowData.date}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Hand bouquets"
              template={(rowData) => (
                <span>
                  {rowData.hand != null &&
                    `${rowData.hand.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.hand != null &&
                `${rowData.hand.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Bouquets"
              template={(rowData) => (
                <span>
                  {rowData.bouqutes != null &&
                    `${rowData.bouqutes.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.bouqutes != null &&
                `${rowData.bouqutes.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Brand"
              template={(rowData) => (
                <span>
                  {rowData.brand != null &&
                    `${rowData.brand.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.brand != null &&
                `${rowData.brand.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Designer"
              template={(rowData) => (
                <span>
                  {rowData.designer != null &&
                    `${rowData.designer.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.designer != null &&
                `${rowData.designer.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Hand bouquets Cost"
              template={(rowData) => (
                <span>
                  {rowData.handcost != null &&
                    `${rowData.handcost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.handcost != null &&
                `${rowData.handcost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Bouquets Cost"
              template={(rowData) => (
                <span>
                  {rowData.bouqutescost != null &&
                    `${rowData.bouqutescost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.bouqutescost != null &&
                `${rowData.bouqutescost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Brand Cost"
              template={(rowData) => (
                <span>
                  {rowData.barndcost != null &&
                    `${rowData.barndcost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.barndcost != null &&
                `${rowData.barndcost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Designer Cost"
              template={(rowData) => (
                <span>
                  {rowData.designercost != null &&
                    `${rowData.designercost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.designercost != null &&
                `${rowData.designercost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Cost"
              field="cost"
              template={(rowData) => (
                <span>
                  {rowData.cost != null &&
                    `${rowData.cost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.cost != null &&
                `${rowData.cost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Sales"
              field="total"
              template={(rowData) => (
                <span>
                  {rowData.total != null &&
                    `${rowData.total.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`}
                </span>
              )}
              width={120}
              customExcelTemplate={(rowData) =>
                rowData.total != null &&
                `${rowData.total.toFixed(opsCenter?.country?.decimalNumber ?? 3)}`
              }
              textAlign="Center"
            />
          </ColumnsDirective>

          {/* <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective field="cost" type="Sum" footerTemplate={footerSum}>
                  {' '}
                </AggregateColumnDirective>
              </AggregateColumnsDirective>
            </AggregateDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective field="total" type="Sum" footerTemplate={footerSum}>
                  {' '}
                </AggregateColumnDirective>
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective> */}

          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, Aggregate, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(ProductTypeSalesDataGrid);
