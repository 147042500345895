import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { errorLogger } from 'datadog/DDUtils';
// constants
import { localStorageKeys, days } from 'constant/Enums';
// selectors
import {
  getProductAttributesByIdData,
  getProductMasterInventoryById,
  getProductExcludedSlotsByIdData,
  getProductInventoryById,
} from './ProductsSelectors';
// api
import ProductsService from 'services/ProductsService';
import MasterCatalogService from 'services/MasterCatalogService';
import CategoriesService from 'services/CategoriesService';
import { getProductOptionsById, getProductRanking } from 'store/products/ProductsSelectors';

export const setProductCategoriesFilterData = () => async (dispatch) => {
  try {
    const res = await ProductsService.getProductCategoriesFilter();
    dispatch({
      type: 'GET_PRODUCT_CATEGORIES_FILTER',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductCategoriesFilterData', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product categories filter');
  }
};

export const setProductMainCategories = () => async (dispatch) => {
  try {
    const res = await ProductsService.getProductMainCategories();
    dispatch({
      type: 'GET_PRODUCT_MAIN_CATEGORIES',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductMainCategories', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product main categories');
  }
};

export const setProductCategoriesData = () => async (dispatch) => {
  try {
    const res = await ProductsService.getProductCategories();
    dispatch({
      type: 'GET_PRODUCT_ALL_CATEGORIES_DATA',
      payload: res.data.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductCategoriesData', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product categories');
  }
};

export const setProductExcludedSlotsList = () => async (dispatch) => {
  try {
    const res = await ProductsService.getProductExcludedSlots();
    dispatch({
      type: 'GET_PRODUCT_EXCLUDED_SLOTS_DATA',
      payload: res.data.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductExcludedSlotsList', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product excluded slots');
  }
};

export const setProductAttributesData = () => async (dispatch) => {
  try {
    const res = await ProductsService.getProductAttributesFilter();
    dispatch({
      type: 'GET_PRODUCT_ATTRIBUTES_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductAttributesData', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product categories filter');
  }
};

// products Data by id

// product details
export const setProductDetailsById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductDetailsById(id);
    dispatch({
      type: 'PRODUCT_DETAILS_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductDetailsById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product details');
  }
};

// product ranking
export const setProductRankingById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductRankingById(id);
    dispatch({
      type: 'PRODUCT_RANKING_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductRankingById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product ranking');
  }
};

// product options
export const setProductOptionsById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductOptionsById(id);
    dispatch({
      type: 'PRODUCT_OPTIONS_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductOptionsById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product options');
  }
};

export const setUpdateProductRankingStatus =
  (data, productId, validateRanking) => async (dispatch, getState) => {
    const state = getState();
    const productRankingById = getProductRanking({ state });
    const newp = productRankingById.map((p) => {
      if (p.opsId === data.OpsId) {
        p.rank = Number(data.Rank);
        p.customizable = data.Customizable;
        p.isPickAndPack = data.IsPickAndPack;
      }
      return p;
    });
    try {
      await ProductsService.updateProductRankingById(data, productId);
      toast.success('Rank updated');
      dispatch({
        type: 'PRODUCT_RANKING_BY_ID',
        payload: newp,
      });

      if (validateRanking) {
        validateRanking(newp, true);
      }
    } catch (err) {
      errorLogger('ProductsActions:setUpdateProductRankingStatus', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update rank');
    }
  };

export const setUpdateProductOptionsStatus =
  (value, type, productId) => async (dispatch, getState) => {
    const state = getState();
    const productOptionsById = getProductOptionsById({ state });
    const dataBody = {
      Status: value,
      OptionType: type,
    };
    try {
      await ProductsService.updateProductOptionsById(dataBody, productId);
      toast.success('Status updated');
      dispatch({
        type: 'PRODUCT_OPTIONS_BY_ID',
        payload: { ...productOptionsById, status: value },
      });
    } catch (err) {
      errorLogger('ProductsActions:setUpdateProductOptionsStatus', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update status');
    }
  };

export const setUpdateProductOptionsProductDef =
  (value, type, productId) => async (dispatch, getState) => {
    const state = getState();
    const productOptionsById = getProductOptionsById({ state });
    const dataBody = {
      ProductDefinition: value,
      OptionType: type,
    };
    try {
      await ProductsService.updateProductOptionsById(dataBody, productId);
      toast.success('Product definion updated');
      dispatch({
        type: 'PRODUCT_OPTIONS_BY_ID',
        payload: { ...productOptionsById, productDefinition: value },
      });
    } catch (err) {
      errorLogger('ProductsActions:setUpdateProductOptionsProductDef', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update status');
    }
  };

export const setUpdateProductOptionsProductLine =
  (value, type, productId) => async (dispatch, getState) => {
    const state = getState();
    const productOptionsById = getProductOptionsById({ state });
    const dataBody = {
      ProductionLine: value,
      OptionType: type,
    };
    try {
      await ProductsService.updateProductOptionsById(dataBody, productId);
      toast.success('Product line updated');
      dispatch({
        type: 'PRODUCT_OPTIONS_BY_ID',
        payload: { ...productOptionsById, productionLine: value },
      });
    } catch (err) {
      errorLogger('ProductsActions:setUpdateProductOptionsProductLine', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update production line');
    }
  };

export const setUpdateProductOptionsType =
  (value, type, productId, validateOptions, validateFields) => async (dispatch, getState) => {
    const state = getState();
    const productOptionsById = getProductOptionsById({ state });
    const dataBody = {
      Type: value,
      OptionType: type,
    };
    try {
      await ProductsService.updateProductOptionsById(dataBody, productId);
      toast.success('Product type updated');
      dispatch({
        type: 'PRODUCT_OPTIONS_BY_ID',
        payload: { ...productOptionsById, type: value },
      });
      if (validateFields) {
        await validateFields({ ...productOptionsById, type: value });
      } else if (validateOptions) {
        validateOptions(true, { ...productOptionsById, type: value });
      }
    } catch (err) {
      errorLogger('ProductsActions:setUpdateProductOptionsType', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update product type');
    }
  };

export const setUpdateProductMainCat =
  (value, type, productId, validateCategories) => async (dispatch, getState) => {
    const state = getState();
    const productOptionsById = getProductOptionsById({ state });
    const dataBody = {
      MainCategory: value,
      OptionType: type,
    };
    try {
      await ProductsService.updateProductOptionsById(dataBody, productId);
      toast.success('Main category updated');
      dispatch({
        type: 'PRODUCT_OPTIONS_BY_ID',
        payload: { ...productOptionsById, mainCategory: value },
      });
      if (validateCategories) {
        validateCategories();
      }
    } catch (err) {
      errorLogger('ProductsActions:setUpdateProductMainCat', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update main category');
    }
  };

export const getLabelList = () => async (dispatch) => {
  try {
    const res = await ProductsService.getLabelList();
    dispatch({
      type: 'PRODUCT_LABEL_LIST',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:getLabelList', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to update status');
  }
};

export const getProductLabel = (id) => async (dispatch) => {
  dispatch({
    type: 'FETCHING_PRODUCT_LABEL',
    payload: true,
  });
  try {
    const res = await ProductsService.getLabelByProductID({ productId: id });
    dispatch({
      type: 'SET_PRODUCT_LABEL',
      payload: res.data,
    });
    dispatch({
      type: 'FETCHING_PRODUCT_LABEL',
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: 'FETCHING_PRODUCT_LABEL',
      payload: false,
    });
    errorLogger('ProductsActions:getProductLabel', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to update status');
  }
};

export const updateProductLabelByID = (label, productId, id) => async (dispatch) => {
  const newObj = {
    ...label,
    productId,
  };
  try {
    const res = await ProductsService.updateProductLabel(newObj, id);
    dispatch({
      type: 'UPDATE_PRODUCT_LABEL',
      payload: [{ ...newObj, id }],
    });
    toast.success(res.data.message ?? 'Updating label was successfull');
  } catch (err) {
    errorLogger('ProductsActions:updateProductLabelByID', err);
    toast.error(err?.response?.data?.message ?? 'Failed to update label');
  }
};

export const addProductLabel = (label, productId) => async (dispatch) => {
  const newObj = {
    ...label,
    productId,
  };
  try {
    const res = await ProductsService.addProductLabel(newObj);
    dispatch({
      type: 'ADD_PRODUCT_LABEL',
      payload: [res.data],
    });
    toast.success(res.data.message ?? 'Adding label was successfull');
  } catch (err) {
    errorLogger('ProductsActions:addProductLabel', err);
    toast.error(err?.response?.data?.message ?? 'Failed to add label');
  }
};

export const deleteProductLabelByID = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.deleteProductLabel(id);
    dispatch({
      type: 'DELETE_PRODUCT_LABEL',
      payload: [],
    });
    toast.success(res.data.message ?? 'Deleting label was successfull');
  } catch (err) {
    errorLogger('ProductsActions:deleteProductLabelByID', err);
    toast.error(err?.response?.data?.message ?? 'Failed to delete label');
  }
};

// product availability
export const setProductAvailabilityById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductAvailabilityById(id);
    dispatch({
      type: 'PRODUCT_AVAILABILITY_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductAvailabilityById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product availability');
  }
};

// product inventory
export const setProductInventoryById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductInventoryById(id);
    dispatch({
      type: 'PRODUCT_INVENTORY_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setProductInventoryById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product inventory');
  }
};

export const setUpdateProductMarginById = (data, productId) => async (dispatch, getState) => {
  const state = getState();
  const clonedProductInventoryById = cloneDeep(getProductInventoryById({ state }));
  const body = { margin: data };
  try {
    await ProductsService.updateProductMargin(body, productId);
    clonedProductInventoryById.forEach((item) => {
      if (item.productId === productId) {
        item.margin = data;
      }
    });
    dispatch({
      type: 'PRODUCT_INVENTORY_BY_ID',
      payload: clonedProductInventoryById,
    });
    toast.success('Margin updated');
  } catch (err) {
    errorLogger('ProductsActions:setUpdateProductMarginById', err);
    toast.error(err?.response?.data?.message ?? 'Failed to update margin');
  }
};

// product media
export const setProductMediaById = (id, validateMedia) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductMediaById(id);
    dispatch({
      type: 'PRODUCT_MEDIA_BY_ID',
      payload: res.data,
    });
    if (validateMedia) {
      validateMedia(true, null, res.data);
    }
  } catch (err) {
    errorLogger('ProductsActions:setProductMediaById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product media');
  }
};

// product master availability
export const setProductMasterAvailabilityById = (id, validateMA) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductMasterAvailabilityById(id);
    dispatch({
      type: 'PRODUCT_MASTER_AVAILABILITY_BY_ID',
      payload: res.data,
    });
    if (validateMA) {
      await validateMA(res.data, true);
    }
  } catch (err) {
    errorLogger('ProductsActions:setProductMasterAvailabilityById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product master availability');
  }
};

// product groups
export const getProductGroupsById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductGroupsById(id);
    dispatch({
      type: 'PRODUCT_GROUPS_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:getProductGroupsById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product groups');
  }
};

// product excluded days
export const getProductExcludedDaysById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductExcludedDaysById(id);
    const data = [
      {
        Sunday: { isExcluded: false, id: 0 },
        Monday: { isExcluded: false, id: 0 },
        Tuesday: { isExcluded: false, id: 0 },
        Wednesday: { isExcluded: false, id: 0 },
        Thursday: { isExcluded: false, id: 0 },
        Friday: { isExcluded: false, id: 0 },
        Saturday: { isExcluded: false, id: 0 },
      },
    ];
    res.data.forEach(function (eD) {
      data[0][days[eD.dayOfWeek]].isExcluded = true;
      data[0][days[eD.dayOfWeek]].id = eD.id;
    });
    dispatch({
      type: 'PRODUCT_EXCLUDED_DAYS_BY_ID',
      payload: data,
    });
  } catch (err) {
    errorLogger('ProductsActions:getProductExcludedDaysById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product excluded days');
  }
};

// product master inventory
export const setProductMasterInventoryById = (id, validateMI) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductMasterInventoryById(id);
    dispatch({
      type: 'PRODUCT_MASTER_INVENTORY_BY_ID',
      payload: res.data,
    });
    if (validateMI) {
      validateMI(res.data, true);
    }
  } catch (err) {
    errorLogger('ProductsActions:setProductMasterInventoryById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch product master inventory');
  }
};

export const setDeleteProductMasterInventoryItem =
  (id, validateMI) => async (dispatch, getState) => {
    const state = getState();
    const productMasterInventoryData = getProductMasterInventoryById({ state });

    try {
      await ProductsService.deleteProductMasterInventoryItem(id);
      const clonedProductMasterInventoryData = productMasterInventoryData.filter(
        (item) => item.centralId !== id
      );
      dispatch({
        type: 'PRODUCT_MASTER_INVENTORY_BY_ID',
        payload: clonedProductMasterInventoryData,
      });
      toast.success('Removed');
      if (validateMI) {
        validateMI(clonedProductMasterInventoryData, true);
      }
    } catch (err) {
      errorLogger('ProductsActions:setDeleteProductMasterInventoryItem', err);
      toast.error(err?.response?.data?.message ?? 'Failed remove master inventory item');
    }
  };

// product categoriers
// categories
export const setGetProductCategoriesById = (id, validation) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductCategoriesById(id);
    if (validation) {
      return res.data.data;
    } else {
      dispatch({
        type: 'GET_PRODUCT_CATEGORIES_BY_ID',
        payload: res.data.data,
      });
    }
  } catch (err) {
    errorLogger('ProductsActions:setGetProductCategoriesById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch categories for this product');
  }
};

export const setAddProductCategoryById =
  (productId, catId, validateCategories) => async (dispatch) => {
    try {
      await ProductsService.addProductCategoryById(productId, catId);
      toast.success('Added');
      const res = await ProductsService.getProductCategoriesById(productId);

      dispatch({
        type: 'GET_PRODUCT_CATEGORIES_BY_ID',
        payload: res.data.data,
      });
      if (validateCategories) {
        validateCategories(true, null, res.data.data);
      }
    } catch (err) {
      errorLogger('ProductsActions:setAddProductCategoryById', err);
      toast.error(err?.response?.data?.message ?? 'Failed to add category');
    }
  };

export const setDeleteProductCategoryById =
  (productId, mapId, validateCategories) => async (dispatch) => {
    try {
      await ProductsService.deleteProductCategoryById(productId, mapId);
      toast.success('Removed');

      const res = await ProductsService.getProductCategoriesById(productId);

      dispatch({
        type: 'GET_PRODUCT_CATEGORIES_BY_ID',
        payload: res.data.data,
      });
      if (validateCategories) {
        validateCategories(true, null, res.data.data);
      }
    } catch (err) {
      errorLogger('ProductsActions:setDeleteProductCategoryById', err);
      toast.error(err?.response?.data?.message ?? 'Failed to remove product category');
    }
  };

// MC
export const setGetProductMCById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductMCById(id);
    dispatch({
      type: 'GET_PRODUCT_MC_BY_ID',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setGetProductMCById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch categories for this product');
  }
};

export const addProductMCById = (data, id) => async (dispatch) => {
  try {
    await ProductsService.addProductMCById(data, id);
    dispatch({
      type: 'GET_PRODUCT_MC_BY_ID',
      payload: data,
    });
  } catch (err) {
    errorLogger('ProductsActions:addProductMCById', err);
  }
};

// excluded slots
export const setGetProductExcludedSlotsById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductExcludedSlotsById(id);
    dispatch({
      type: 'GET_PRODUCT_EXCLUDED_SLOTS_BY_ID',
      payload: res.data.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setGetProductExcludedSlotsById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch excluded slots for this product');
  }
};

export const setAddProductExcludedSlotById =
  (productId, slotId, slot) => async (dispatch, getState) => {
    const state = getState();
    const productExcludedSlotsByIdData = getProductExcludedSlotsByIdData({ state });
    const clonedProductExcludedSlotsById = cloneDeep(productExcludedSlotsByIdData);
    const clonedAttrValues = [...clonedProductExcludedSlotsById, slot];
    dispatch({
      type: 'GET_PRODUCT_EXCLUDED_SLOTS_BY_ID',
      payload: clonedAttrValues,
    });
    try {
      await ProductsService.addProductExcludedSlotById(productId, slotId);
      toast.success('Added');
    } catch (err) {
      errorLogger('ProductsActions:setAddProductExcludedSlotById', err);
      toast.error(err?.response?.data?.message ?? 'Failed to exclude slot');
    }
  };

export const setDeleteProductExcludedSlotById =
  (productId, slotId) => async (dispatch, getState) => {
    const state = getState();
    const productExcludedSlotsByIdData = getProductExcludedSlotsByIdData({ state });
    const clonedProductExcludedSlotsById = cloneDeep(productExcludedSlotsByIdData);
    const clonedAttrValues = clonedProductExcludedSlotsById.filter((el) => el.slotId !== slotId);
    dispatch({
      type: 'GET_PRODUCT_EXCLUDED_SLOTS_BY_ID',
      payload: clonedAttrValues,
    });
    try {
      await ProductsService.deleteProductExcludedSlotById(productId, slotId);
      toast.success('Removed');
    } catch (err) {
      errorLogger('ProductsActions:setDeleteProductExcludedSlotById', err);
      toast.error(err?.response?.data?.message ?? 'Failed to remove slot');
    }
  };

// attributes
export const setGetProductAttributeById = (id) => async (dispatch) => {
  try {
    const res = await ProductsService.getProductAttributesById(id);
    dispatch({
      type: 'GET_PRODUCT_ATTRIBUTES_BY_ID',
      payload: res.data.data,
    });
  } catch (err) {
    errorLogger('ProductsActions:setGetProductAttributeById', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch attributes for this product');
  }
};

export const setAddProductAttributeById =
  (productId, id, selectedAttr, validateMC) => async (dispatch, getState) => {
    const state = getState();
    const productAttributesByIdData = getProductAttributesByIdData({ state });
    const clonedProductAttributesById = cloneDeep(productAttributesByIdData);

    clonedProductAttributesById.forEach((item) => {
      if (item.id === selectedAttr.attID) {
        const clonedAttrValues = [
          ...item.productValueAtrributes,
          { ...selectedAttr, attId: item.id },
        ];
        item.productValueAtrributes = clonedAttrValues;
      }
    });

    dispatch({
      type: 'GET_PRODUCT_ATTRIBUTES_BY_ID',
      payload: clonedProductAttributesById,
    });

    try {
      await ProductsService.addProductAttributeById(productId, id);
      toast.success('Added');
      if (validateMC) {
        validateMC(null, true, clonedProductAttributesById);
      }
    } catch (err) {
      errorLogger('ProductsActions:setAddProductAttributeById', err);
      toast.error(err?.response?.data?.message ?? 'Failed to add attribute');
    }
  };

export const setDeleteProductAttributeById =
  (productId, id, attId, validateMC) => async (dispatch, getState) => {
    const state = getState();
    const productAttributesByIdData = getProductAttributesByIdData({ state });
    const clonedProductAttributesById = cloneDeep(productAttributesByIdData);

    clonedProductAttributesById.forEach((item) => {
      if (item.id === attId) {
        const tempArr = cloneDeep(item.productValueAtrributes);
        const clonedAttrValues = tempArr.filter((el) => el.id !== id);
        item.productValueAtrributes = clonedAttrValues;
      }
    });
    dispatch({
      type: 'GET_PRODUCT_ATTRIBUTES_BY_ID',
      payload: clonedProductAttributesById,
    });

    try {
      await ProductsService.deleteProductAttributeById(productId, id);

      toast.success('Removed');
      if (validateMC) {
        validateMC(null, true, clonedProductAttributesById);
      }
    } catch (err) {
      errorLogger('ProductsActions:setDeleteProductAttributeById', err);
      toast.error(err?.response?.data?.message ?? 'Failed to remove attribute');
    }
  };

// subscription

const isFetchingSizes = (data) => async (dispatch) => {
  dispatch({
    type: data ? 'FETCHING_PRODUCT_SIZES' : 'FETCHED_PRODUCT_SIZES',
    payload: data,
  });
};

export const getProductSizes = (productId) => async (dispatch) => {
  dispatch(isFetchingSizes(true));
  try {
    const res = await ProductsService.getProductSizes(productId);
    dispatch({
      type: 'GET_PRODUCT_SIZES',
      payload: res.data,
    });
    dispatch(isFetchingSizes(false));
  } catch (err) {
    errorLogger('ProductsActions:getProductSizes', err);
    // toast.error(err?.response?.data?.message ?? 'Failed to fetch sizes for this product');
    dispatch(isFetchingSizes(false));
  }
};

export const setProductSubscriptionType =
  (value, type, productId) => async (dispatch, getState) => {
    const state = getState();
    const productOptionsById = getProductOptionsById({ state });
    const dataBody = {
      SubscriptionType: value,
      OptionType: type,
    };
    try {
      await ProductsService.updateProductOptionsById(dataBody, productId);
      toast.success('Subscription Type updated');
      dispatch({
        type: 'PRODUCT_OPTIONS_BY_ID',
        payload: { ...productOptionsById, subscriptionType: value },
      });
    } catch (err) {
      errorLogger('ProductsActions:setProductSubscriptionType', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update subscription type');
    }
  };

// get sub categories
export const getEverydaySubCategories = () => async (dispatch) => {
  try {
    const res = await CategoriesService.getSubCategoriesData(6417);
    dispatch({
      type: 'SET_EVERYDAY_SUB_CATEGORIES',
      payload: res.data.subCategory,
    });
  } catch (err) {
    errorLogger('ProductsActions:getEverydaySubCategories', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch sub-categories data!!');
  }
};

export const getEmotionsSubCategories = () => async (dispatch) => {
  try {
    const res = await CategoriesService.getSubCategoriesData(6665);
    dispatch({
      type: 'SET_EMOTIONS_SUB_CATEGORIES',
      payload: res.data.subCategory,
    });
  } catch (err) {
    errorLogger('ProductsActions:getEmotionsSubCategories', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch sub-categories data!!');
  }
};

export const getSpecialOccasionsSubCategories = () => async (dispatch) => {
  try {
    const res = await CategoriesService.getSubCategoriesData(6666);
    dispatch({
      type: 'SET_SPECIAL_OCCASIONS_SUB_CATEGORIES',
      payload: res.data.subCategory,
    });
  } catch (err) {
    errorLogger('ProductsActions:getSpecialOccasionsSubCategories', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch sub-categories data!!');
  }
};

export const getFestivalsSubCategories = () => async (dispatch) => {
  try {
    const res = await CategoriesService.getSubCategoriesData(6664);
    dispatch({
      type: 'SET_FESTIVALS_SUB_CATEGORIES',
      payload: res.data.subCategory,
    });
  } catch (err) {
    errorLogger('ProductsActions:getFestivalsSubCategories', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch sub-categories data!!');
  }
};

export const setAllProductsDataById = (productId) => async (dispatch) => {
  const promisesArray = [];
  try {
    promisesArray.push(
      dispatch(setProductCategoriesFilterData()),
      dispatch(setProductCategoriesData()),
      dispatch(setProductMainCategories()),
      // dispatch(setProductExcludedSlotsList()),
      dispatch(setProductAttributesData()),
      dispatch(setProductDetailsById(productId)),
      dispatch(setProductRankingById(productId)),
      dispatch(setProductAvailabilityById(productId)),
      dispatch(setProductInventoryById(productId)),
      dispatch(setProductMediaById(productId)),
      dispatch(setProductMasterAvailabilityById(productId)),
      dispatch(setProductOptionsById(productId)),
      dispatch(setProductMasterInventoryById(productId)),
      dispatch(getProductGroupsById(productId)),
      dispatch(getProductExcludedDaysById(productId)),
      dispatch(getProductSizes(productId)),
      dispatch(getLabelList()),
      dispatch(getProductLabel(productId)),
      dispatch(setGetProductMCById(productId)),
      // subcategories
      dispatch(getEverydaySubCategories()),
      dispatch(getEmotionsSubCategories()),
      dispatch(getSpecialOccasionsSubCategories()),
      dispatch(getFestivalsSubCategories())
    );
    await Promise.all(promisesArray);
  } catch (e) {
    errorLogger('ProductsActions:setAllProductsDataById', e);
    toast.error('Sorry, No data to be viewed for this product');
  }
};

// sync product
export const setSyncEditProduct = (id) => async (dispatch) => {
  dispatch(setIsEditProductSyncedFalse());
  try {
    await MasterCatalogService.getSyncMasterCaltalogProduct(id);
    const interval = setInterval(async () => {
      const res = await MasterCatalogService.getIndividualProductData(id);
      if (res.data.synced) {
        dispatch(setIsEditProductSyncedTrue());
        clearInterval(interval);
      }
    }, 3000);
  } catch (err) {
    errorLogger('ProductsActions:setSyncEditProduct', err);
    toast.error(err?.response?.data?.message ?? 'Failed to sync product data!!');
  }
};

export const setIsEditProductSyncedFalse = () => ({
  type: 'SET_IS_EDIT_PRODUCT_SYNCED_FALSE',
});

export const setIsEditProductSyncedTrue = () => ({
  type: 'SET_IS_EDIT_PRODUCT_SYNCED_TRUE',
});

export const setSelectedSize = (data) => async (dispatch) => {
  dispatch({
    type: 'SET_SELECTED_PRODUCT_SIZE',
    payload: data,
  });
};

export const setDuplicateProduct = (id) => async () => {
  const dataBody = {
    ProdId: id,
  };
  try {
    const res = await ProductsService.getDuplicateProduct(dataBody);
    window.open(`/product/editProduct/${res.data.id}/0?isDuplicated=true`, '_blank');
  } catch (err) {
    errorLogger('ProductsActions:setDuplicateProduct', err);
    toast.error(err?.response?.data?.message ?? 'Failed to duplicate this product');
  }
};

export const setCostQtySum = (sum) => (dispatch) => {
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);
  dispatch({
    type: 'SET_COST_QTY_SUM',
    payload: sum && +sum.toFixed(opsCenter?.country?.decimalNumber ?? 2),
  });
};

export const updateInventoryAfterQty = (value, id) => (dispatch, getState) => {
  const state = getState();
  const productMasterInventoryById = getProductMasterInventoryById({ state });
  productMasterInventoryById.map(function (pInv) {
    if (pInv.centralId === id) {
      pInv.requiredQty = Number(value);
    }
    return pInv;
  });
  try {
    dispatch({
      type: 'PRODUCT_MASTER_INVENTORY_BY_ID',
      payload: productMasterInventoryById,
    });
  } catch (err) {
    errorLogger('ProductsActions:updateInventoryAfterQty', err);
  }
};

export const validatingFields = () => (dispatch) => {
  dispatch({
    type: 'VALIDATING_PRODUCT',
  });
};

export const finishedValidating = () => (dispatch) => {
  dispatch({
    type: 'FINISHED_VALIDATING_PRODUCT',
  });
};
