import React from 'react';
// components
import RestrictedSection from 'components/shared/RestrictedSection';
import SubMenuItem from './SubMenuItem';

// sub menu item (has children) with permission
const PermissionedSubMenuItem = ({
  per,
  clicked,
  title,
  Icon,
  ticketsList,
  alertQtyItems,
  isCustomIcon,
}) => (
  <RestrictedSection page={per} action="view">
    <SubMenuItem
      clicked={clicked}
      title={title}
      Icon={Icon}
      ticketsList={ticketsList}
      alertQtyItems={alertQtyItems}
      isCustomIcon={isCustomIcon}
    />
  </RestrictedSection>
);

export default PermissionedSubMenuItem;
