import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import QrCodeGenerator from 'components/shared/QrCodeGenerator';
// actions
import { setTogglePreviewCardModal } from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getIsPreviewCardModalOpen,
  getCardData,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
// svg icons
import FlowardIcon from 'assets/images/FlowardIcon';
import GiftIcon from 'assets/images/GiftIcon';

const PreviewCardModal = () => {
  const cardData = useSelector((state) => getCardData({ state }));
  const isCardModalOpen = useSelector((state) => getIsPreviewCardModalOpen({ state }));
  const dispatch = useDispatch();

  return (
    <div className="card-body btn-showcase">
      <Modal
        className="edit-card-message-container"
        isOpen={isCardModalOpen}
        toggle={() => dispatch(setTogglePreviewCardModal())}
      >
        <ModalBody className="edit-card-message-content preview-wrapper">
          <FlowardIcon />
          <p dir="auto">{cardData?.messageTo}</p>
          <p dir="auto" className="message-content">
            {cardData?.messageText}
          </p>
          <p dir="auto">{cardData?.messageFrom}</p>
          {cardData?.feelingLink !== '' && (
            <div className="qr-code-wrapper">
              <QrCodeGenerator value={cardData.feelingLink} size={80} />
            </div>
          )}
          <GiftIcon />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PreviewCardModal;
