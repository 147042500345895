import React from 'react';
import CustomSelect from 'components/shared/CustomSelect';

const DefinitionFilterComponent = ({ definition, setDefinition, handleSubmit }) => {
  const handleProductDefinitionChange = (e) => {
    setDefinition(e.itemData.id);
  };

  return (
    <div className="card">
      <div className="card-header p-18">
        <h4>Filter</h4>
      </div>
      <form className="form theme-form">
        <div className="card-body">
          <div className="row normal-flex">
            <div className="col-12 col-md-6">
              <div className="form-group filter-select">
                <label className="bold-title" htmlFor="production-def">
                  Product Definition
                </label>
                <CustomSelect
                  id="production-def"
                  listData={[
                    { id: 1, name: 'Central Products' },
                    { id: 2, name: 'Uncentral Products' },
                    { id: 0, name: 'Undefined Products' },
                  ]}
                  handleChange={handleProductDefinitionChange}
                  placeholder="Select a product definition"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={definition}
                />
              </div>
            </div>
            <button onClick={handleSubmit} className="btn btn-primary mr-1 m-t-7" type="button">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DefinitionFilterComponent;
