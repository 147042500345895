import React from 'react';
import RestrictedSection from 'components/shared/RestrictedSection';

const MasterCategoriesAction = ({ type, isModalOpen, setIsModalOpen, setIsEdit }) => {
  return (
    <div className="card">
      <div className="card-body btn-showcase">
        <RestrictedSection
          page="categories"
          action={type === 'category' ? 'create_category' : 'create_subcategory'}
        >
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              setIsEdit(false);
              setIsModalOpen(!isModalOpen);
            }}
          >
            <i className="fa fa-plus"></i> Create Category
          </button>
        </RestrictedSection>
      </div>
    </div>
  );
};

export default MasterCategoriesAction;
