import { cloneDeep } from 'lodash';
// API
import QuickPrintingServices from 'services/QuickPrintingServices';
// Actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// datadog
import { errorLogger } from 'datadog/DDUtils';
// Selectors
import { getQueryParams } from './QuickPrintingSelectors';

export const setSelectedOrdersIds = (ordersIds) => ({
  type: 'SET_SELECTED_ORDERS_IDS',
  payload: ordersIds,
});

export const setPrintingTableParams = (params) => ({
  type: 'SET_PRINTING_TABLE_PARAMS',
  payload: params,
});

export const setPrintingTableData = (page) => async (dispatch, getState) => {
  const state = getState();
  let queryParams = getQueryParams({ state });
  if (page) {
    queryParams.page = page;
  }

  const clonedParams = cloneDeep(queryParams);
  const keys = Object.keys(clonedParams);
  keys.forEach((key) => {
    if (key === 'printed' && clonedParams[key] !== '-1') {
      clonedParams[key] = queryParams[key] === '1' || false;
    }

    if (clonedParams[key] === '-1') {
      delete clonedParams[key];
    }
  });

  try {
    dispatch(setLoaderOpenTrue());
    const res = await QuickPrintingServices.getQuickPrintingData(clonedParams);
    dispatch({
      type: 'SET_QUICK_PRINTING_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (error) {
    errorLogger('QuickPrintingActions:setPrintingTableData', error);
    dispatch(setLoaderOpenFalse());
  }
};
