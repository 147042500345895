/* eslint-disable no-nested-ternary */
import React, { Fragment, useState } from 'react';
// components
import SectionLoader from 'components/common/SectionLoader';
import ZiwoCallsDataTable from './ZiwoCallsDataTable';
// modal
import ZiwoRecordedCallModal from '../modals/ZiwoRecordedCallModal';

const ZiwoCallsSharedComponent = ({ ziwoCallsData, isLoaderOpen, title }) => {
  const [isZiwoRecordedCallModalOpen, setIsZiwoRecordedCallModalOpen] = useState(false);
  const [ziwoCallId, setZiwoCallId] = useState('');

  return (
    <Fragment>
      <div className="card row checkout-card ziwo-card-wrapper">
        <div className="card-header-border card-header checkout-card-header">
          <h5>{title}</h5>
          <i className="fa fa-headphones"></i>
        </div>
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : ziwoCallsData.length > 0 ? (
          <div className="card-body">
            <div className="table-responsive topper-lists">
              <table className="table table-bordernone">
                <tbody>
                  {ziwoCallsData.map((item, i) => (
                    <ZiwoCallsDataTable
                      key={i}
                      item={item}
                      setZiwoCallId={setZiwoCallId}
                      setIsZiwoRecordedCallModalOpen={setIsZiwoRecordedCallModalOpen}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p className="no-result-title">No Results</p>
        )}
      </div>
      {isZiwoRecordedCallModalOpen && (
        <ZiwoRecordedCallModal
          isZiwoRecordedCallModalOpen={isZiwoRecordedCallModalOpen}
          setIsZiwoRecordedCallModalOpen={setIsZiwoRecordedCallModalOpen}
          ziwoCallId={ziwoCallId}
        />
      )}
    </Fragment>
  );
};

export default ZiwoCallsSharedComponent;
