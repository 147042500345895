import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UserCorporateContent = ({ userId }) => {
  const [vat, setVat] = useState('');
  const [customerRef, setCustomerRef] = useState('');
  const [nationalAddress, setNationalAddress] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const fetchUserCorporateData = async (userId) => {
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getUserProfileInfo(userId);
      setVat(res.data.vat);
      setCustomerRef(res.data.cr);
      setNationalAddress(res.data.nationalAddress);
      setCorporateName(res?.data?.corporateName || '');
      setIsLoaderOpen(false);
    } catch (err) {
      errorLogger('UserCorporateContent:fetchUserCorporateData', err);
      toast.error(err?.response?.data?.message ?? 'Failed to get user corporate info!');
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchUserCorporateData(userId);
  }, []);

  const updateUserCorporate = async () => {
    setIsLoaderOpen(true);
    setDisableBtn(true);
    const dataBody = {
      Vat: vat,
      Cr: customerRef,
      NationalAddress: nationalAddress,
      CorporateName: corporateName,
    };
    try {
      const res = await PermissionService.updateUserCorporate(dataBody, userId);
      setIsLoaderOpen(false);
      toast.success(res?.data?.message ?? 'Profile Updated Successfully');
      setDisableBtn(false);
    } catch (err) {
      errorLogger('UserCorporateContent:updateUserCorporate', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update user corporate!');
      setIsLoaderOpen(false);
      setDisableBtn(false);
    }
  };

  return (
    <div className="card row checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          User Corporate <i className="fa fa-user"></i>
        </h4>
      </div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="card-body">
          <div className="row">
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="user-corporate-vat">
                VAT
              </label>
              <input
                id="user-corporate-vat"
                value={vat}
                onChange={(e) => setVat(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="user-corporate-refno">
                Customer Reference NO.
              </label>
              <input
                id="user-corporate-refno"
                value={customerRef}
                onChange={(e) => setCustomerRef(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="user-corporate-address">
                National Address
              </label>
              <input
                id="user-corporate-address"
                value={nationalAddress}
                onChange={(e) => setNationalAddress(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="user-corporate-name">
                Corporate Name
              </label>
              <input
                id="user-corporate-name"
                value={corporateName}
                onChange={(e) => setCorporateName(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
          </div>
        </div>
      )}
      <div className="form-group col-12">
        <Button color="primary" disabled={disableBtn} onClick={updateUserCorporate}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default UserCorporateContent;
