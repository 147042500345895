import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
import CommonInfo from '../CommonInfo';
// service
import AttributesService from 'services/AttributesService';
// actions
import { getAttributesData } from 'store/attributes/AttributesActions';

const CreateAttributeModal = ({
  isAddAttributeModalOpen,
  setIsAddAttributeModalOpen,
  type,
  inputValues,
  parentName,
}) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const params = useParams();
  const [dataObj, setDataObj] = useState({
    enName: '',
    arName: '',
    enDesc: '',
    arDesc: '',
    attKey: '',
    attType: 1,
    attValue: '',
    attExtraVal: '',
    enImgFile: '',
    arImgFile: '',
    enAppImgFile: '',
    arAppImgFile: '',
    isActive: false,
    attributeId: params?.id,
    attributeName: parentName,
  });
  const dispatch = useDispatch();

  const validateForm = () => {
    if (type === 'parent') {
      if (dataObj.enName === '' || dataObj.attType === '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    } else {
      if (dataObj.enName === '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    }
  };

  const handleCreateAttribute = async () => {
    let data = {
      Name: dataObj.enName,
      NameAr: dataObj.arName,
      Key: dataObj.attKey,
      AltEn: dataObj.enDesc,
      AltAr: dataObj.arDesc,
      Type: dataObj.attType,
      IsActive: dataObj.isActive,
    };
    let formData;
    if (type === 'child') {
      data = {
        AttributeId: params?.id,
        Name: dataObj.enName,
        NameAr: dataObj.arName,
        Description: dataObj.enDesc,
        DescriptionAr: dataObj.arDesc,
        Value: dataObj.attValue,
        IsActive: dataObj.isActive,
        Extravalue: dataObj.attExtraVal,
        ImgEn: dataObj.enImgFile,
        ImgAr: dataObj.arImgFile,
        appImgEn: dataObj.enAppImgFile,
        AppImgAr: dataObj.arAppImgFile,
      };
      formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
    }
    setDisableBtn(true);
    setIsLoaderOpen(true);
    try {
      let res = {};
      res = await AttributesService.createNewAttribute(type === 'child' ? formData : data, type);
      dispatch(getAttributesData(inputValues));
      setDisableBtn(false);
      setIsLoaderOpen(false);
      setIsAddAttributeModalOpen((prev) => !prev);
      toast.success(res.data.message ?? 'Created attribute successfuly!');
    } catch (e) {
      setDisableBtn(false);
      setIsLoaderOpen(false);
      setIsAddAttributeModalOpen((prev) => !prev);
      toast.error('Failed to create new attribute');
    }
  };

  const updateDataObj = (name, value) => {
    setDataObj({ ...dataObj, [name]: value });
  };

  useEffect(() => {
    validateForm();
  }, [dataObj.enName, dataObj.attType]);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddAttributeModalOpen}
        toggle={() => setIsAddAttributeModalOpen((prev) => !prev)}
        className="m-w-900"
      >
        <ModalHeader>Create {type === 'parent' ? 'Attribute' : 'Sub Attribute'}</ModalHeader>
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <ModalBody>
            <div className="form-group col-12 card">
              <div className="card-body">
                <CommonInfo type={type} dataObj={dataObj} updateDataObj={updateDataObj} />
              </div>
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            onClick={handleCreateAttribute}
            color="primary"
            disabled={disableBtn}
            data-testid="CreateButtonTestId"
          >
            Create {type === 'parent' ? 'attribute' : 'sub attribute'}
          </Button>
          <Button color="light" onClick={() => setIsAddAttributeModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateAttributeModal;
