import React from 'react';

const GiftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Group_1912"
    width={36.868}
    height={32.821}
    viewBox="0 0 36.868 32.821"
  >
    <defs>
      <style>{'.prefix__cls-1{fill:#005671}'}</style>
    </defs>
    <path
      id="prefix__Path_5836"
      d="M121.229 332.818a5.441 5.441 0 001.82 3.268l.113.093a5.407 5.407 0 001.348.808l.018.007.071.029c.052.02.1.04.157.059.169.061.335.111.5.154l.025.006q.24.059.488.1h.023c.075.011.149.022.224.029l.116.01.136.011h.075c.081 0 .163.006.245.006h.074a5.727 5.727 0 01.093-.724h-.187c-.079 0-.158 0-.236-.007l-.087-.007-.084-.007-.055-.005h-.037a4.685 4.685 0 01-.629-.116l-.073-.019-.1-.029-.022-.006-.016-.006-.115-.036-.121-.042-.1-.039-.027-.011-.02-.008-.1-.04c-.044-.019-.086-.037-.129-.058l-.05-.024c-.095-.046-.188-.094-.28-.145s-.175-.1-.259-.157h-.008a4.506 4.506 0 01-.474-.355l-.021-.018a4.7 4.7 0 01-1.465-2.308 4.683 4.683 0 011.545.755 4.76 4.76 0 01.758.706c.048.056.094.113.14.17l.053.071.076.1.07.1.049.074c.027.041.052.083.077.124l.033.054c.028.048.054.1.08.144l.02.038c.028.053.055.107.081.161l.011.023c.028.059.054.118.08.179v.006c.027.064.053.128.076.193l.124.039.115.033.071.018a4.464 4.464 0 00.508.1 5.433 5.433 0 00-.443-1.109l-.014-.024q-.064-.12-.133-.235l-.04-.062a3.383 3.383 0 00-.21-.311c-.03-.04-.058-.083-.088-.122q-.092-.12-.191-.235a5.436 5.436 0 00-3.268-1.82l-.488-.077.042.268z"
      className="prefix__cls-1"
      transform="translate(-114.403 -332.329)"
    />
    <path
      id="prefix__Path_5837"
      d="M130.6 342.622a5.836 5.836 0 01-.093.724h.188c.079 0 .158 0 .236.007l.087.007.084.007.056.005h.037a4.691 4.691 0 01.629.116l.073.019.1.029.023.006.016.006.115.036.121.042.1.039.028.011.02.009.1.04.129.057.05.024a5.248 5.248 0 01.538.302h.008a4.57 4.57 0 01.473.355l.022.018a4.692 4.692 0 011.465 2.308 4.674 4.674 0 01-1.545-.755h-.005a4.785 4.785 0 01-.758-.706 5.743 5.743 0 01-.14-.17l-.053-.071-.075-.1-.07-.1-.049-.073-.077-.124-.032-.054c-.028-.047-.054-.1-.08-.144l-.02-.038a4.843 4.843 0 01-.081-.161l-.01-.023a3.605 3.605 0 01-.08-.179v-.006l-.076-.193-.124-.039-.115-.033-.071-.018a4.472 4.472 0 00-.509-.1 5.471 5.471 0 00.443 1.109l.014.024c.043.08.087.158.134.235.012.021.027.041.039.062a3.383 3.383 0 00.21.311c.03.04.058.082.089.122.062.08.125.158.191.235a5.44 5.44 0 003.268 1.82l.489.077-.042-.269-.034-.22a5.438 5.438 0 00-1.82-3.268l-.112-.093a5.408 5.408 0 00-1.348-.808l-.018-.007-.071-.029-.157-.059a5.696 5.696 0 00-.5-.154l-.025-.006a6.459 6.459 0 00-.487-.1h-.023c-.075-.011-.15-.022-.225-.029l-.116-.01-.136-.011h-.075c-.081 0-.163-.006-.244-.006z"
      className="prefix__cls-1"
      transform="translate(-118.469 -336.802)"
    />
    <path
      id="prefix__Path_5838"
      d="M131.57 337.786h.037l.088.008c.074.008.153.018.24.031h.024c.069.011.139.024.207.037v-.123c0-.079 0-.158.007-.236 0-.029 0-.058.007-.087l.007-.084c0-.031 0-.063.008-.093a4.786 4.786 0 01.117-.629l.019-.073c.011-.042.023-.084.036-.125l.042-.132.02-.058.021-.062.05-.131c.017-.043.032-.078.049-.116l.057-.129.024-.05a4.7 4.7 0 012.987-2.507 4.7 4.7 0 01-2.9 3.155l-.04.124c-.013.041-.022.076-.033.115l-.018.071a4.576 4.576 0 00-.1.509 5.458 5.458 0 002.1-1.119 5.438 5.438 0 001.82-3.268l.077-.489-.489.077a5.44 5.44 0 00-4.17 3.281l-.037.089c-.02.052-.04.1-.059.157-.06.169-.111.335-.154.5l-.006.025a5.4 5.4 0 00-.152.988l-.005.075c0 .084-.006.168-.006.253l.088.007z"
      className="prefix__cls-1"
      transform="translate(-118.876 -332.329)"
    />
    <path
      id="prefix__Path_5839"
      d="M124.91 345.441a5.43 5.43 0 00.9-1.46l.037-.089c.02-.052.04-.1.059-.157.06-.169.111-.335.154-.5l.006-.025a5.452 5.452 0 00.152-.988v-.075c0-.084.006-.168.006-.253l-.088-.007h-.076l-.088-.008a5.917 5.917 0 01-.24-.031h-.024c-.07-.011-.139-.024-.208-.037v.123c0 .079 0 .158-.007.236 0 .029 0 .058-.007.087l-.007.084c0 .031-.005.063-.008.093a4.778 4.778 0 01-.116.629l-.019.074a2.566 2.566 0 01-.036.125l-.042.132-.02.058-.022.062-.05.131-.048.116-.058.129-.024.05a4.708 4.708 0 01-2.987 2.507 4.7 4.7 0 012.9-3.155l.039-.124.033-.115.018-.071a4.468 4.468 0 00.1-.509 5.461 5.461 0 00-2.1 1.119 5.438 5.438 0 00-1.82 3.268l-.076.489.488-.077a5.438 5.438 0 003.279-1.831z"
      className="prefix__cls-1"
      transform="translate(-114.403 -336.445)"
    />
    <path
      id="prefix__Path_5840"
      d="M145.727 364.811c-.012 0-1.822.285-3.153.436-2.324.263-2.939-.093-3.1-.259a5.746 5.746 0 01-.606-.832c-.681-1.057-1.614-2.5-4.9-3.528-4.453-1.388-12.329-6.174-12.407-6.222l-.217-.133-.172.188a2.269 2.269 0 00-.236.319 2.7 2.7 0 00-.881-1.076l-1.327-.954-1.01 9.791 3.024 4.293-3.079-1.211-3.191-3.362-.027-.025a2.688 2.688 0 00-2.236-.569l-1.835.357 4.9 5.461-2.208-.417a2.706 2.706 0 00-2.111.486l-1.746 1.3 7.658 2.1.028.007 1.662.139a2.928 2.928 0 00-.794 1.269l-.135.438 7.378-.61c.542.065 6.546.782 8.778.84l.907.02c2.364.049 5.937.123 7.178.663a3.888 3.888 0 001.557.311 5.588 5.588 0 002.462-.62l.189-.1v-8.574zm-24.54-7.391a3.213 3.213 0 00.293.266c1.546 1.237 5.745 4.607 6.084 4.878a2.783 2.783 0 01-.024 3.756l-4.216-2.272-2.138-2.987zm-4.178 12.923l-6.4-1.753.725-.539a2.084 2.084 0 011.626-.374l2.525.477.464.074.222.248 3.889 1.645 1.411.6-2.109-.176zm1.541 1.762a2.3 2.3 0 01.824-.851l.132-.08 3.794.318.438.185zm26.87.844a4.294 4.294 0 01-3.3.2c-1.352-.587-4.854-.66-7.411-.713l-.9-.02c-1.545-.04-7.111-.648-7.111-.648l-.46-.028-1.314-.11-1.436-.663-.206-.1-1.63-.747-3.74-1.581-1.372-.581-.277-.308-4.686-5.227.764-.149a2.069 2.069 0 011.709.427l3.273 3.448 4.581 1.8.771.715.816.756.816.757a1.208 1.208 0 00.821.39l.223.023.65.067.957.1.064-.616-1.163-.12-.65-.067h-.04a.588.588 0 01-.412-.2l-.17-.157-.815-.756-2.44-2.264-2.96-4.2.877-8.5.459.33a2.084 2.084 0 01.865 1.688v5.365l2.338 3.266 4.754 2.562.163-.163a3.453 3.453 0 00.189-4.79l-.037-.036c-.045-.036-4.5-3.613-6.106-4.9a1.6 1.6 0 01-.677-1.1 1.693 1.693 0 01.3-1.019c.606.363 2.288 1.361 4.308 2.458a49.107 49.107 0 008 3.681c3.067.956 3.895 2.24 4.559 3.272a6.236 6.236 0 00.676.922c.493.522 1.676.669 3.615.449.942-.107 2.4-.274 2.867-.371z"
      className="prefix__cls-1"
      transform="translate(-109.214 -341.205)"
    />
  </svg>
);

export default GiftIcon;
