export const reportsEndPoints = {
  // products
  priceBreakDown: 'order/hub/reports/ProductPrice/Breakdown',
  productBreakDown: 'order/hub/reports/Product/Breakdown',
  CategoryBreakDown: 'order/hub/reports/Category/Breakdown',
  productTypeSales: 'order/hub/reports/productType/sales',
  ProductTypeAttribute: 'order/hub/reports/ProductType/Attribute',
  exportProductTypeAttribute: 'order/hub/reports/ProductType/Attribute/export',
  liveProductsReport: 'catalog/hub/product/live',
  exportLiveProductsReport: 'catalog/hub/product/export/live',
  cartProductDiscount: 'order/hub/reports/cart/productdiscount',
  productTypeBreakDown: 'order/hub/reports/ProductType/Breakdown',
  productCategoryPercentage: 'order/hub/reports/ProductCategory/breackdown',
  ordersProductWise: 'order/hub/reports/productwise',
  topTenProductsByType: 'order/hub/reports/Top/Products',
  oldProducts: 'catalog/hub/product/history',
  exportOldProducts: 'catalog/hub/product/history/export',
  // sales
  salesReport: 'order/hub/reports/sales',
  // orders
  ordersReport: 'order/hub/reports/method/Orders',
  exportOrdersReport: 'order/hub/reports/method/Orders/export',
  financialOrdersReport: 'order/hub/reports/orders/useranlytic',
  exportFinancialOrdersReport: 'order/hub/reports/orders/useranlytic/export',
  orderCancellation: 'order/hub/reports/Total/CancelledOrders',
  orderAmountDetails: 'order/hub/reports/orders/details/amount/city',
  exportOrderAmountDetails: 'order/hub/reports/orders/details/amount/city/export',
  revenuesData: '/orders/revenues-report',
  exportRevenuesData: '/orders/revenues-report/export',
  defferedRevenuesData: '/orders/deferred-revenues',
  exportDefferedRevenuesData: '/orders/deferred-revenues/export',
  // daily tracker
  dailyTrackerAllMethod: 'order/hub/reports/all/method/Orders',
  giftsDailySpend: 'order/hub/reports/designers/ordersBreakdown',
  exportGiftsDailySpend: 'order/hub/reports/designers/ordersBreakdown/export',
  designersDailyCommisions: 'order/hub/reports/designer/commission',
  exportDesignersDailyCommisions: 'order/hub/reports/designer/commission/export',
  acquisitionPerformanceReport: 'order/hub/reports/flowardsales/users',
  // designers
  designersDailyPerformance: 'order/hub/reports/designers/dailyperformance',
  getDesignersList: 'order/hub/reports/Designer',
  designersProductPerformance: 'order/hub/reports/designers/productperformance',
  exportDesignersProductPerformance: 'order/hub/reports/designers/productperformance/export',
  getDesignersReport: 'order/hub/reports/designers/details',
  exportGetDesignersReport: 'order/hub/reports/designers/details/export',
  // inventory report
  getInventorySuppliers: 'order/hub/reports/inventory/suppliers',
  exportInventorySuppliers: 'order/hub/reports/Inventory/Suppliers/export',
  getInventoryBalance: 'order/hub/reports/Inventory/Balance',
  statements: 'order/hub/reports/statment',
  financeInventoryStatement: 'order/hub/reports/inventory/statement',
  exportStatements: 'order/hub/reports/statment/export',
  inventoryStatementActualDelivery: 'order/hub/reports/inventory/statement/actual-delivery',
  exportInventoryStatementActualDelivery:
    'order/hub/reports/inventory/statement/actual-delivery/export',
  exportFinanceInventoryStatements: 'order/hub/reports/inventory/statement/export',
  inventoryReport: 'order/hub/reports/inventory/details',
  exportInventoryReport: 'order/hub/reports/Inventory/Details/export',
  salesPerformanceReport: 'order/hub/reports/sales/performance',
  salesPerformanceDetails: 'order/hub/reports/sales/performance/details',
  // delivery
  getDriverPerformanceReport: 'order/hub/driver/DriversPerformance',
  exportDriverPerformanceReport: 'order/hub/driver/DriversPerformance/export',
  getDeliveryPerformanceReport: 'order/hub/driver/Delivery/Performance',
  exportDeliveryPerformanceReport: 'order/hub/driver/Delivery/Performance/export',
  getDeliveryBreakdownReport: 'order/hub/driver/Late/Delivery/Performance',
  getDeliveryLateOrdersDetails: 'order/hub/driver/late/orders',
  getDriverLogisticsReport: 'orders/reports/drivers-expenses',
  exportDriverLogisticsReport: 'orders/reports/drivers-expenses/export',
  getDriverPerformanceDetailsReport: 'order/hub/driver/DriversPerformanceDetails',
  exportDriverPerformanceDetailsReport: 'order/hub/driver/export/DriversPerformanceDetails',
  getDriverOrders: 'order/hub/driver/{0}/orders/list',
  exportDriverOrders: 'order/hub/driver/{0}/export/orders/list',
  // brands
  getTotalBrandsOrdersReport: 'order/hub/reports/brand/details',
  getBrandBasket: 'order/hub/reports/brand-basket',
  getBrandsList: 'order/hub/reports/brands',
  // florists
  getFloristsPerformanceReport: 'order/hub/reports/florist/orders',
  // wallet
  getWalletBalance: 'order/hub/reports/walletbalance',
  exportWalletBalances: 'order/hub/reports/walletbalance/export',
  getWalletTransactions: 'order/hub/reports/wallet/transaction',
  exportWalletTransactions: 'order/hub/reports/wallet/transaction/export',
  exportCustomerLoyalityReport: 'loyalty/hub/reports/loyalty-customer/export',
  // coupon
  getCouponDailySpend: 'order/hub/reports/All/Coupon',
  voucherCategoryList: 'order/hub/reports/Voucher/Category',
  exportAllCouponDailySpend: 'order/hub/reports/All/Coupon/export',
  // registered users
  getReisteredUsersReport: 'order/hub/reports/users/breakdown',
  // hours & areas breakdown
  getHoursBreakdownReport: 'order/hub/reports/hours/breakdown',
  getAreasBreakdownReport: 'order/hub/reports/orders/area',
  // corporate
  getCustomerList: 'user/hub/users/corporate',
  getSalesOfficerList: 'user/hub/users/salesofficer',
  getCorporateReport: 'orders/reports/corporate',
  getCustomerCorporateReport: 'orders/reports/corporate/Customer',
};
