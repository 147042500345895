import React from 'react';
import { useDispatch } from 'react-redux';
import { setMasterInventoryExport } from 'store/inventory/InventoryActions';

const MasterInventoryActions = ({ defaultParams }) => {
  const dispatch = useDispatch();

  const handleExport = async () => {
    dispatch(setMasterInventoryExport(defaultParams));
  };

  return (
    <div className="no-toolbar-table-style">
      <div className="card">
        <div className="card-body btn-showcase">
          <button type="button" className="btn btn-secondary" onClick={handleExport}>
            <i className="fa fa-file"></i> Export
          </button>
        </div>
      </div>
    </div>
  );
};

export default MasterInventoryActions;
