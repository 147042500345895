import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import debounce from 'lodash/debounce';
import DatePicker from 'react-datepicker';
import SectionLoader from 'components/common/SectionLoader';
import CustomSelect from 'components/shared/CustomSelect';
// helpers
import { dateFormatDDMM } from 'constant/Helpers';
import { localStorageKeys, orderTypes } from 'constant/Enums';
import { toast } from 'react-toastify';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// api
import PermissionService from 'services/PermissionService';
import ViewOrderService from 'services/ViewOrderService';
import QuickActionService from 'services/QuickActionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const CreateOrderModal = ({ isAddOrderModalOpen, setIsAddOrderModalOpen, premium }) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [orderType, setOrderType] = useState(0);
  const [countryList, setCountryList] = useState('');
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [isNationwide, setIsNationwide] = useState(false);
  const navigate = useNavigate();
  const opsId = getFromLocalStorage(localStorageKeys.OPS_ID);

  const fetchCountryList = async () => {
    try {
      const res = await ViewOrderService.getCountriesList();
      setCountryList(res.data);
    } catch (e) {
      errorLogger('CreateOrderModal:fetchCountryList', e);
      // console.log(e);
    }
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchData = debounce(async (keyword) => {
    const params = {
      searchkey: keyword,
      page: 1,
    };
    setSearchKey(keyword);
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getSearchUsers(params);
      setSearchResult(res.data.data);
      setIsLoaderOpen(false);
    } catch (e) {
      errorLogger('CreateOrderModal:fetchData', e);
      setIsLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    if (keyword.length >= 3) {
      fetchData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleSelectUser = (user) => {
    setEmail(user.email);
    setFirstName(user.name);
    setLastName(user.lastName);
    setCountry(user.countryID);
    setPhone(user.phone);
    setShowTable(false);
  };

  const handleChangeDate = async (date) => {
    const params = {
      date: dateFormatDDMM(date),
      proId: -1,
      fulfillmenttype: 0,
      opsid: opsId,
      nwDelivery: isNationwide,
    };
    try {
      if (premium) {
        const res = await ViewOrderService.getDeliveryPremiumTimeSlot(params);
        setTimeSlotsData(res.data.avilableSlots);
      } else {
        delete params.proId;
        const res = await ViewOrderService.getDeliveryTimeSlot(params);
        setTimeSlotsData(res.data.avilableSlots);
      }
    } catch (e) {
      errorLogger('CreateOrderModal:handleChangeDate', e);
      toast.error('No available slots');
    }
    setSelectedDate(date);
  };

  const handleCreateOrder = async () => {
    const dataBody = {
      email,
      fName: firstName,
      lName: lastName,
      countryID: country,
      phone,
      orderType,
      channel: 1,
      deliverdate: dateFormatDDMM(selectedDate),
      deliverytime: selectedTimeSlot,
      nwDelivery: isNationwide,
    };
    setDisableBtn(true);
    try {
      if (premium) {
        const res = await QuickActionService.createNewPremiumOrder(dataBody);
        setDisableBtn(true);
        setIsLoaderOpen(true);
        setTimeout(() => {
          navigate(`/order/${res.data.id}`);
          toast.success(res.data.message);
        }, 2500);
      } else {
        const res = await QuickActionService.createNewOrder(dataBody);
        setDisableBtn(true);
        setIsLoaderOpen(true);
        setTimeout(() => {
          navigate(`/order/${res.data.id}`);
          toast.success(res.data.message);
        }, 2500);
      }
    } catch (e) {
      errorLogger('CreateOrderModal:handleCreateOrder', e);
      setDisableBtn(false);
      setIsLoaderOpen(false);
      setIsAddOrderModalOpen((prev) => !prev);
      toast.error('Failed to create new order');
    }
  };

  const handleModalTitle = () => {
    if (!premium) return <ModalHeader>Create Order</ModalHeader>;
    else {
      return (
        <ModalHeader>
          Premium Order <i className="fa fa-star premium-order-star"></i>
        </ModalHeader>
      );
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e.itemData.Id);
  };

  const handleOrderTypeChange = (e) => {
    setOrderType(e.itemData.value);
  };

  const handleTimeSlotChange = (e) => {
    setSelectedTimeSlot(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddOrderModalOpen}
        toggle={() => setIsAddOrderModalOpen((prev) => !prev)}
        className="create-order-modal"
      >
        {handleModalTitle()}
        <ModalBody>
          <div className="form-group col-12">
            <form>
              <label htmlFor="select-customer">Search Customer</label>
              <input
                id="select-customer"
                className="form-control"
                type="text"
                placeholder="Customer Phone, email address"
                onChange={(e) => handleSearch(e.target.value)}
                defaultValue={searchKey}
              />
            </form>
          </div>
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <Fragment>
              {searchResult.length > 0 && (
                <div
                  className={`table-responsive shopping-table text-center ${
                    showTable ? 'auto-height-table' : ''
                  }`}
                >
                  {showTable && (
                    <table className="table table-bordernone create-order-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResult.map((item, i) => (
                          <tr
                            className="search-table-row"
                            onClick={() => handleSelectUser(item)}
                            key={i}
                          >
                            <td>{item.name}</td>
                            <td>{item.phone}</td>
                            <td>{item.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-12 customer-info-header">
                  <h6>Customer Information</h6>
                </div>
                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="recipient-name">
                    E-mail
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="form-control"
                    type="text"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="recipient-name">
                    Phone
                  </label>
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    className="form-control"
                    type="text"
                  />
                </div>

                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="recipient-name">
                    First Name
                  </label>
                  <input
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    className="form-control"
                    type="text"
                  />
                </div>
                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="recipient-name">
                    Last Name
                  </label>
                  <input
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    className="form-control"
                    type="text"
                  />
                </div>
                <div className="form-group col-6 filter-select">
                  <label htmlFor="select-country">Country</label>
                  {countryList !== '' && (
                    <CustomSelect
                      id="select-country"
                      listData={countryList}
                      handleChange={handleCountryChange}
                      placeholder="Select a country"
                      textPropName="Name"
                      valuePropName="Id"
                      selectedValue={country}
                    />
                  )}
                </div>
                <div className="form-group col-6 filter-select">
                  <label htmlFor="select-order-type">Order Type</label>
                  <CustomSelect
                    id="select-order-type"
                    listData={orderTypes}
                    handleChange={handleOrderTypeChange}
                    placeholder="Select an order type"
                    textPropName="name"
                    valuePropName="value"
                    selectedValue={orderType}
                  />
                </div>
                {opsId === 10 && (
                  <div className="form-group col-12">
                    <input
                      className="checkbox_animated"
                      type="checkbox"
                      checked={isNationwide}
                      onChange={(e) => setIsNationwide((prev) => !prev)}
                    />
                    <span className="bold-title">Nantionwide Delivery?</span>
                  </div>
                )}
                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="message-text">
                    Delivery Date
                  </label>
                  <DatePicker
                    className="form-control digits"
                    placeholderText="Selected delivery date"
                    selected={selectedDate}
                    onChange={handleChangeDate}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                </div>
                {timeSlotsData.length > 0 && (
                  <div className="form-group col-6 filter-select delivery-time-select">
                    <label htmlFor="select-delivery-time">Delivery Time</label>
                    <CustomSelect
                      id="select-delivery-time"
                      listData={timeSlotsData}
                      handleChange={handleTimeSlotChange}
                      placeholder="Select an delivery time"
                      textPropName="name"
                      valuePropName="id"
                      selectedValue={selectedTimeSlot}
                    />
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleCreateOrder}
            color="primary"
            disabled={
              !email ||
              !firstName ||
              !lastName ||
              !country ||
              !phone ||
              !selectedDate ||
              !selectedTimeSlot ||
              disableBtn
            }
          >
            Create order
          </Button>
          <Button color="light" onClick={() => setIsAddOrderModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateOrderModal;
