import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
// constants
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import LateOrdersService from 'services/LateOrdersService';
// components
import SectionLoader from 'components/common/SectionLoader';
const LateOrdersFilter = React.lazy(() => import('components/lateOrders/LateOrdersFilter'));
const DJHPagination = React.lazy(() => import('components/shared/Pagination'));
const LateOrdersDataGrid = React.lazy(() => import('components/lateOrders/LateOrdersDataGrid'));

const LateOrdersPage = () => {
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    page: 1,
  });
  const [lateOrdersList, setLateOrdersList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await LateOrdersService.getLateOrdersList(params);
      setLateOrdersList(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      dispatch(setLoaderOpenFalse());

      toast.error('Failed to get late orders data');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: '/orders/lateOrders',
      search: queryString,
    });
    fetchData(inputValues);
  };

  const onPageChange = async (i) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.page = i;
    setInputValues(updatedParams);
    handleSubmit(updatedParams);
    document.documentElement.scrollTop = 0;
  };
  return (
    <div className="no-toolbar-table-style">
      <React.Suspense fallback={<SectionLoader />}>
        <LateOrdersFilter
          inputValues={inputValues}
          setInputValues={setInputValues}
          handleSubmit={handleSubmit}
        />
        <LateOrdersDataGrid
          lateOrdersList={lateOrdersList.data}
          setLateOrdersList={setLateOrdersList}
          inputValues={inputValues}
        />
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+lateOrdersList.currentPage}
          pageSize={+lateOrdersList.pageSize}
          itemsCount={+lateOrdersList.rowCount}
        />
      </React.Suspense>
    </div>
  );
};

export default LateOrdersPage;
