import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CustomSelect from 'components/shared/CustomSelect';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
import { errorLogger } from 'datadog/DDUtils';
// service
import MasterCatalogService from 'services/MasterCatalogService';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';

const CreateProductModal = ({ isAddProductModalOpen, setIsAddProductModalOpen }) => {
  const [isValid, setIsValid] = useState(true);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [enName, setEnName] = useState('');
  const [arName, setArName] = useState('');
  const [productionLine, setProductionLine] = useState('');
  const [type, setType] = useState('');
  const [errors, setErrors] = useState({
    name: false,
    nameAr: false,
    line: false,
    type: false,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (field, value) => {
    let formErrors = errors;
    if (field === 'enName') {
      setEnName(value);
      formErrors.name = !(value || value?.trim() !== '');
    } else if (field === 'arName') {
      setArName(value);
      formErrors.nameAr = !(value || value?.trim() !== '');
    }
    setErrors(formErrors);
  };

  const handleTypeChange = (e) => {
    setType(e?.itemData?.id);
    setErrors({ ...errors, type: false });
  };

  const handleProductionLineChange = (e) => {
    setProductionLine(e?.itemData?.id);
    setErrors({ ...errors, line: false });
  };

  const validateForm = () => {
    let formErrors = errors;
    if (!enName || enName?.trim() === '') {
      formErrors.name = true;
    }
    if (!arName || arName?.trim() === '') {
      formErrors.nameAr = true;
    }
    if ((!productionLine && productionLine !== 0) || productionLine === '') {
      formErrors.line = true;
    }
    if ((!type && type !== 0) || type === '') {
      formErrors.type = true;
    }
    setErrors(formErrors);
  };

  const handleCreateCategory = async () => {
    const data = {
      Name: enName,
      NameAr: arName,
      Type: type,
      ProductionLines: productionLine,
    };
    validateForm();
    let isFormValid = true;
    Object.keys(errors).forEach((key) => {
      if (errors[key]) isFormValid = false;
    });

    if (!isFormValid) {
      setIsValid(false);
    } else {
      setIsValid(true);
      try {
        let res = {};
        res = await MasterCatalogService.createNewProduct(data);
        navigate(`/product/editProduct/${res.data}/0`);
        setIsAddProductModalOpen((prev) => !prev);
        toast.success('Created product successfully!');
      } catch (e) {
        errorLogger('CreateProductModal:handleCreateCategory', e);
        setDisableBtn(false);
        setIsLoaderOpen(false);
        setIsAddProductModalOpen((prev) => !prev);
        toast.error('Failed to create new product');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      if (queryObj?.type) setType(parseInt(queryObj?.type, 10));
    }
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddProductModalOpen}
        toggle={() => setIsAddProductModalOpen((prev) => !prev)}
        className="m-w-900"
      >
        <ModalHeader>Create Product</ModalHeader>
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <ModalBody>
            <div className="form-group col-12 card">
              <form className="card-body row">
                <div className="form-group col-6">
                  <label className="bold-title" htmlFor="newprod-enName">
                    Display name English<span className="requird-asterisks">*</span>
                  </label>
                  <input
                    id="newprod-enName"
                    value={enName}
                    onChange={(e) => handleChange('enName', e.target.value)}
                    className="form-control"
                    type="text"
                    required
                  />
                  {!isValid && errors?.name && (
                    <InlineErrorMessage message={'Please add “name English“ to proceed'} />
                  )}
                </div>
                <div className="form-group col-6">
                  <label className="bold-title" htmlFor="newprod-arName">
                    Display name Arabic<span className="requird-asterisks">*</span>
                  </label>
                  <input
                    id="newprod-arName"
                    value={arName}
                    onChange={(e) => handleChange('arName', e.target.value)}
                    className="form-control"
                    type="text"
                    required
                  />
                  {!isValid && errors?.nameAr && (
                    <InlineErrorMessage message={'Please add “name Arabic“ to proceed'} />
                  )}
                </div>
                <div className="form-group col-12 filter-select category-select">
                  <label className="bold-title" htmlFor="newprod-line">
                    Production Line<span className="requird-asterisks">*</span>
                  </label>
                  <CustomSelect
                    id="newprod-line"
                    listData={[
                      { id: 0, name: 'Classic Line' },
                      { id: 1, name: 'Premium Line' },
                    ]}
                    handleChange={handleProductionLineChange}
                    placeholder="Select a production line"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={productionLine}
                  />
                  {!isValid && errors?.line && (
                    <InlineErrorMessage message={'Please add “Production Line“ to proceed'} />
                  )}
                </div>
                <div className="form-group col-12 filter-select category-select">
                  <label className="bold-title" htmlFor="newprod-type">
                    Type<span className="requird-asterisks">*</span>
                  </label>
                  <CustomSelect
                    id="newprod-type"
                    listData={[
                      { id: 0, name: 'Product' },
                      { id: 1, name: 'Designer' },
                      { id: 2, name: 'Brands' },
                      { id: 3, name: 'Subscriptions' },
                      { id: 4, name: 'AdsOn' },
                      { id: 5, name: 'Basket' },
                      { id: 6, name: 'Custom Items' },
                      { id: 7, name: 'Celebrity' },
                      { id: 8, name: 'Product with Basket' },
                      { id: 9, name: 'Bundle' },
                      { id: 10, name: 'Product with variants' },
                      { id: 11, name: 'Variant' },
                    ]}
                    handleChange={handleTypeChange}
                    placeholder="Select a type"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={type}
                  />
                  {!isValid && errors?.type && (
                    <InlineErrorMessage message={'Please add “Type“ to proceed'} />
                  )}
                </div>
              </form>
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button onClick={handleCreateCategory} color="primary">
            Save
          </Button>
          <Button color="light" onClick={() => setIsAddProductModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateProductModal;
