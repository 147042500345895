import React from 'react';
import { useDispatch } from 'react-redux';
import CartDetails from '../../viewOrder/CartDetails';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
// actions
import { setIsCartModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';

const CartModal = ({ isAction, orderId, isCartModalOpen }) => {
  const dispatch = useDispatch();
  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isCartModalOpen}
        toggle={() => dispatch(setIsCartModalOpen())}
        className="cart-modal"
      >
        <ModalBody>
          <CartDetails isAction={isAction} orderId={orderId} isCartModalOpen={isCartModalOpen} />
        </ModalBody>
        <ModalFooter className="center-modal-footer">
          <Button color="light" onClick={() => dispatch(setIsCartModalOpen())}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CartModal;
