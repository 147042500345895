import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// Components
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import PageTitle from 'components/shared/PageTitle';
import CorporateInvoicesDataTable from 'components/invoices/CorporateInvoicesDataTable';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
//  Actions
import { getCorporateInvoicesData } from 'store/invoices/InvoicesActions';
// Selectors
import { getCorporateInvoicesDataList } from 'store/invoices/InvoicesSelectors';
// Helpers
import {
  isoStartFormat,
  isoEndFormat,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';

const CorporateInvoicesPage = () => {
  const [inputValues, setInputValues] = useState({
    fromDate: isoStartFormat(new Date()),
    toDate: isoEndFormat(new Date()),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const countryId = getFromLocalStorage('countryId');
  const data = useSelector((state) => getCorporateInvoicesDataList({ state }));

  useEffect(() => {
    if (location.search) {
      const queryParams = convertQueryParamsIntoObject(location.search);
      setInputValues({
        fromDate: queryParams.fromDate,
        toDate: queryParams.toDate,
      });
      dispatch(getCorporateInvoicesData(queryParams, countryId));
    } else {
      dispatch(getCorporateInvoicesData(inputValues, countryId));
    }
  }, []);

  const handleChange = (e) => {
    setInputValues({ ...inputValues, orderId: e.input.value });
  };

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: '/corporate-invoices',
      search: queryString,
    });
    dispatch(getCorporateInvoicesData(inputValues, countryId));
  };

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Invoices" />
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        title="Corporate Invoice Filter"
        timeDate
      />
      <AppErrorBoundary>
        <CorporateInvoicesDataTable data={data} />
      </AppErrorBoundary>
    </div>
  );
};

export default CorporateInvoicesPage;
