import React, { useState } from 'react';
import SubAttributesDataTable from './SubAttributesDataTable';

const SubAttributes = () => {
  const [inputValues, setInputValues] = useState({
    searchKey: '',
  });

  return (
    <div>
      <SubAttributesDataTable inputValues={inputValues} setInputValues={setInputValues} />
    </div>
  );
};

export default SubAttributes;
