import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { dateFormat } from 'constant/Helpers';
import DateRangePickerComponent from 'components/shared/DateRangePickerComponent';
import { RefreshCw } from 'react-feather';
// styles
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import CustomSelect from 'components/shared/CustomSelect';

const OrderedItemsFilter = ({ timeSlots, inputValues, setInputValues, handleSubmit }) => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleDateRange = (item) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.Deliverydate =
      dateFormat(item.selection.startDate) + '-' + dateFormat(item.selection.endDate);
    setInputValues(updatedParams);
    setDateRange(item.selection);
  };

  return (
    <div className="card ordered-items-filter-wrapper">
      <div className="card-header">
        <h4>Filter</h4>
      </div>
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group range-time">
                <label htmlFor="ordered-items-range">Start - End time</label>
                <div className="range-input-wrapper">
                  <input
                    id="ordered-items-range"
                    className="form-control digits"
                    value={`${dateFormat(dateRange.startDate)} - ${dateFormat(dateRange.endDate)}`}
                    onClick={() => setShowDatePicker((prev) => !prev)}
                    readOnly
                  />
                  <RefreshCw className="range-icon" onClick={() => window.location.reload()} />
                </div>

                {showDatePicker && (
                  <DateRangePickerComponent
                    setShowDatePicker={setShowDatePicker}
                    handleDateRange={handleDateRange}
                    dateRange={dateRange}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group filter-select">
                <label className="ordered-items-delivery-title" htmlFor="ordered-items-delvTime">
                  Delivery Time
                </label>
                {timeSlots.length > 0 && (
                  <CustomSelect
                    id="ordered-items-delvTime"
                    listData={timeSlots.map(function (t) {
                      const activetxt = t.isActive ? '(Active)' : '(Not Active)';
                      if (t.name?.slice(-7) === 'Active)') {
                        // do nothing
                      } else {
                        const oldname = t.name ?? '';
                        t.name = oldname + ' ' + activetxt;
                      }
                      return t;
                    })}
                    handleChange={(e) => {
                      setInputValues({ ...inputValues, TimeSlot: e.itemData.id });
                    }}
                    placeholder="Select a delivery time"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={inputValues.TimeSlot}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group filter-select">
                <label htmlFor="ordered-items-status">Print Status</label>
                <CustomSelect
                  id="ordered-items-status"
                  listData={[
                    { id: -1, name: 'Any' },
                    { id: 0, name: 'Not Printed' },
                    { id: 1, name: 'Printed' },
                  ]}
                  handleChange={(e) => {
                    setInputValues({ ...inputValues, PrintStatus: e.itemData.id });
                  }}
                  placeholder="Select a print status"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={inputValues.PrintStatus}
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button onClick={handleSubmit} className="btn btn-primary mr-1" type="button">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrderedItemsFilter;
