// used to check if the given field is required
export const validateRequired = (inputValue) => {
  return inputValue.trim() === '';
};

// used to check if number
export const validateNumber = (inputValue) => {
  let isError = false;
  if (inputValue.trim().length === 0) {
    isError = false;
  } else if (!/^[0-9]*$/.test(inputValue.trim())) {
    isError = true;
  }
  return isError;
};

// check if number greater than 0
export const validateGreaterThanZero = (inputValue) => {
  let isError = false;
  if (inputValue.trim().length === 0) {
    isError = false;
  } else if (!/^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/.test(inputValue.trim())) {
    isError = true;
  }
  return isError;
};
