import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import PageTitle from 'components/shared/PageTitle';
import TicketsDataGrid from 'components/ticketing/opsTicketsList/TicketsDataGrid';
import TicketsFilter from 'components/ticketing/opsTicketsList/TicketsFilter';
import DJHPagination from 'components/shared/Pagination';
import { getOpenedTicketsPage } from 'constant/AppUrls';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
// actions
import { setTicketsPageList } from 'store/ticketing/TicketingActions';
// selectors
import {
  getTicketsPageList,
  getTicketsCurrentPage,
  getTicketsPageSize,
  getTicketsPageRowCount,
} from 'store/ticketing/TicketingSelectors';

const OpenedTicketsPage = () => {
  const [inputValues, setInputValues] = useState({
    searchkey: '',
    department: 0,
    status: 0,
    page: 1,
    opsId: getFromLocalStorage(localStorageKeys.OPS_ID),
  });
  const ticketsList = useSelector((state) => getTicketsPageList({ state }));
  const currentPage = useSelector((state) => getTicketsCurrentPage({ state }));
  const pageSize = useSelector((state) => getTicketsPageSize({ state }));
  const rowCount = useSelector((state) => getTicketsPageRowCount({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchTicketsList = async (params) => {
    dispatch(setTicketsPageList(params));
  };

  const handleSubmit = async (params) => {
    const queryParams = params ?? inputValues;
    const queryString = convertObjectIntoQueryParams(queryParams);

    navigate({
      pathname: getOpenedTicketsPage(),
      search: queryString,
    });
    fetchTicketsList(queryParams);
  };

  const onPageChange = (i) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.page = i;
    setInputValues(updatedParams);
    handleSubmit(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(setTicketsPageList(queryObj));
    } else {
      dispatch(setTicketsPageList(inputValues));
    }
  }, []);

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Ops Opened Tickets" />
      <TicketsFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
      />
      <TicketsDataGrid ticketsList={ticketsList} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </div>
  );
};

export default OpenedTicketsPage;
