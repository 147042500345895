export const getAllInventoryData = ({ state }) => state.inventory.inventoryData;

export const getTotalInventoryPageSize = ({ state }) => state.inventory.pageSize;
export const getTotalInventoryCurrentPage = ({ state }) => state.inventory.currentPage;
export const getTotalInventoryRowCount = ({ state }) => state.inventory.rowCount;

export const getSuppliersList = ({ state }) => state.inventory.suppliersList;

export const getInventoryStatements = ({ state }) => state.inventory.inventoryStatements;

export const getInventoryParams = ({ state }) => state.inventory.inventoryParams;

export const getInventorySuppliersData = ({ state }) => state.inventory.inventorySuppliersData;

export const getInventoryDetailsById = ({ state }) => state.inventory.iventoryCareDetailsById;

export const getInventoryItemDetailsById = ({ state }) => state.inventory.inventoryItemDetailsById;

export const getAlertQtyInventoryItems = ({ state }) => state.inventory.alertQtyInventoryItems;
