import { updateObject } from '../utility';
// type
import {
  GET_ACTIVE_FEATURED_OCCASIONS_DATA,
  GET_INACTIVE_FEATURED_OCCASIONS_DATA,
} from './FeaturedOccasionsActionTypes';

const initialState = {
  activeFeaturedOccasionsData: [],
  inactiveFeaturedOccasionsData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_FEATURED_OCCASIONS_DATA: {
      return updateObject(state, {
        activeFeaturedOccasionsData: action.payload,
      });
    }
    case GET_INACTIVE_FEATURED_OCCASIONS_DATA: {
      return updateObject(state, {
        inactiveFeaturedOccasionsData: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
