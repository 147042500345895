import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageTitle from 'components/shared/PageTitle';
import AreaForm from '../common/AreaForm';
import SubAreaForm from './SubAreaForm';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// actions
import { updateSubArea } from 'store/delivery/deliveryAreas/areasActions';
// constants
import { localStorageKeys } from 'constant/Enums';
import { errorLogger } from 'datadog/DDUtils';

const EditSubArea = ({ selectedSubAreaData, type }) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const params = useParams();
  const [mainArea, setMainArea] = useState(parseInt(params?.id, 10));
  const [enName, setEnName] = useState('');
  const [arName, setArName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [zone, setZone] = useState('');
  const [deliveryCharge, setDeliveryCharge] = useState('');
  const [mapKey, setMapKey] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMainAreaChange = (e) => {
    setMainArea(e.itemData.Id);
  };

  const validateForm = () => {
    if (enName === '' || arName === '' || mapKey === '' || deliveryCharge === '' || zone === '') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  const handleEditArea = async () => {
    const data = {
      ParentId: mainArea,
      WorkShopId: null,
      MapNames: mapKey,
      DeliveryCost: deliveryCharge,
      Zone: zone,
      Name: enName,
      NameAr: arName,
      CountryId: getFromLocalStorage(localStorageKeys.OPS_CENTER).id,
      IsActive: isActive,
      Type: 0,
    };
    setDisableBtn(true);
    try {
      dispatch(updateSubArea(data, params?.id, params?.subId));
      navigate(-1);
    } catch (e) {
      errorLogger('EditSubArea:handleEditArea', e);
      setDisableBtn(false);
      toast.error('Failed to update sub-area');
    }
  };

  useEffect(() => {
    validateForm();
  }, [enName, arName, mapKey, deliveryCharge, zone]);

  useEffect(() => {
    if (Object.keys(selectedSubAreaData).length > 0) {
      setEnName(selectedSubAreaData.Name);
      setArName(selectedSubAreaData.NameAr);
      setZone(selectedSubAreaData.Zone);
      setDeliveryCharge(selectedSubAreaData.DeliveryCost);
      setMapKey(selectedSubAreaData.MapNames);
      setMainArea(selectedSubAreaData.ParentId);
      setIsActive(selectedSubAreaData.IsActive);
    }
  }, [selectedSubAreaData]);

  useEffect(() => {
    setMainArea(parseInt(params?.id, 10));
  }, []);

  return (
    <>
      <PageTitle title={`Area ${selectedSubAreaData.Name}`} />
      <div className="form-group col-12 card">
        <form className="card-body row">
          <AreaForm
            mainArea={mainArea}
            handleMainAreaChange={handleMainAreaChange}
            enName={enName}
            setEnName={setEnName}
            arName={arName}
            setArName={setArName}
            isActive={isActive}
            setIsActive={setIsActive}
            type={type}
          />
          <SubAreaForm
            zone={zone}
            setZone={setZone}
            deliveryCharge={deliveryCharge}
            setDeliveryCharge={setDeliveryCharge}
            mapKey={mapKey}
            setMapKey={setMapKey}
            isActive={isActive}
            setIsActive={setIsActive}
          />
        </form>
        <div className="card-footer">
          <button
            disabled={disableBtn}
            onClick={handleEditArea}
            className="btn btn-primary"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default EditSubArea;
