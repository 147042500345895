import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
import { HasPermission } from 'constant/Helpers';
import { cStep, cStepArray } from 'constant/Enums';
// actions
import {
  setChangeStatusModalClose,
  setRequiredTableRowData,
} from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getIsChangeStatusModalOpen,
  getOrderId,
  getOrderCstep,
  getupdatedCstep,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
import { getPermissionsList } from 'store/app/AppSelectors';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ChangeStatusModal = ({ order }) => {
  const orderId = useSelector((state) => getOrderId({ state }));
  const orderCstep = useSelector((state) => getOrderCstep({ state }));
  const isChangeStatusModalOpen = useSelector((state) => getIsChangeStatusModalOpen({ state }));
  const updatedCstep = useSelector((state) => getupdatedCstep({ state }));
  const [newCstepValue, setNewCstepValue] = useState(updatedCstep || orderCstep);
  const [newCstepName, setNewCstepName] = useState(updatedCstep || orderCstep);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();
  const permissionsList = useSelector((state) => getPermissionsList({ state }));

  const handleNewStatus = async (newCStepValue, orderId) => {
    const dataBody = {
      status: newCStepValue,
    };
    setDisableBtn(true);
    try {
      if (
        order?.opsID !== 10 &&
        order?.opsID !== 21 &&
        (order?.type === 0 || order?.type === 4) &&
        [cStep[1], cStep[2], cStep[3], cStep[4], cStep[7]].includes(cStep[newCStepValue]) &&
        !order?.recipient?.lat &&
        !HasPermission(permissionsList, 'order_details', 'force_change_order_status')
      ) {
        toast.error(
          'This order cannot be confirmed without coordinates on the map. Please update the map with a pin to proceed.'
        );
        return;
      }
      const res = await ViewOrderService.updateCstep(dataBody, orderId);
      dispatch({
        type: 'SET_UPDATED_CSTEP',
        payload: newCStepValue,
      });
      dispatch(
        setRequiredTableRowData({
          id: orderId,
          field: { name: 'cStep', value: newCStepValue },
        })
      );
      setDisableBtn(false);
      dispatch(setChangeStatusModalClose());
      toast.success(`Order status changed to ${newCstepName}`);
    } catch (err) {
      errorLogger('ChangeStatusModal:handleNewStatus', err);
      setDisableBtn(false);
      dispatch(setChangeStatusModalClose());
      if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      }
    }
  };

  const handleStatusChange = (e) => {
    setNewCstepValue(e.itemData.id);
    setNewCstepName(e.itemData.name);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isChangeStatusModalOpen} toggle={() => dispatch(setChangeStatusModalClose())}>
        <ModalHeader>Edit Status</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group col-12 filter-select">
              <CustomSelect
                listData={cStepArray}
                handleChange={handleStatusChange}
                placeholder="Select a status"
                textPropName="name"
                valuePropName="id"
                selectedValue={newCstepValue}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => handleNewStatus(newCstepValue, orderId)}
            color="primary"
            disabled={disableBtn}
          >
            Save
          </Button>
          <Button color="light" onClick={() => dispatch(setChangeStatusModalClose())}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ChangeStatusModal;
