import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { setTopicListData } from 'store/ticketing/TicketingActions';
// api
import TicketingService from 'services/TicketingService';

const AddTopicModal = ({
  isAddTopicModalOpen,
  setIsAddTopicModalOpen,
  isEditMessage,
  editedMessage,
  setIsEditMessage,
}) => {
  const [title, setTitle] = useState(isEditMessage ? editedMessage.title : '');
  const [titleAr, setTitleAr] = useState(isEditMessage ? editedMessage.titleAr : '');
  const [actionToTake, setActionToTake] = useState(
    isEditMessage ? editedMessage.actionsToTake : ''
  );
  const [description, setDescription] = useState(isEditMessage ? editedMessage.description : '');
  const [descriptionAr, setDescriptionAr] = useState(
    isEditMessage ? editedMessage.descriptionAr : ''
  );
  const [department, setDepartment] = useState(isEditMessage ? editedMessage.department : 0);
  const [showForCustomer, setShowForCustomer] = useState(
    isEditMessage ? editedMessage.showForCustomer : false
  );
  const [isActive, setIsActive] = useState(isEditMessage ? editedMessage.status : true);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleAddNewTopic = async () => {
    const dataBody = {
      Id: editedMessage.id,
      Description: description,
      ActionsToTake: actionToTake,
      Title: title,
      TitleAr: titleAr,
      DescriptionAr: descriptionAr,
      ShowForCustomer: showForCustomer,
      Department: department,
      status: isActive,
    };
    setDisableBtn(true);
    if (!isEditMessage) {
      try {
        await TicketingService.addTicketingTopic(dataBody);
        toast.success('New topic added successfully');
        setDisableBtn(false);
        setIsAddTopicModalOpen((prev) => !prev);
      } catch (err) {
        errorLogger('AddTopicModal:handleAddNewTopic', err);
        toast.error('Failed to add new topic');
      }
    } else {
      try {
        await TicketingService.updateTicketingTopic(dataBody);
        toast.success('Topic updated successfully');
        setDisableBtn(false);
        setIsAddTopicModalOpen((prev) => !prev);
      } catch (err) {
        errorLogger('AddTopicModal:handleAddNewTopic', err);
        toast.error('Failed to update topic');
      }
    }
    setTimeout(async () => {
      dispatch(setTopicListData());
    }, 1500);
  };

  const handleDepartmentChange = (e) => {
    setDepartment(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddTopicModalOpen}
        toggle={() => {
          setIsAddTopicModalOpen((prev) => !prev);
          setIsEditMessage(false);
        }}
        className="m-w-600"
      >
        <ModalHeader toggle={() => setIsAddTopicModalOpen((prev) => !prev)}>
          {isEditMessage ? 'Edit Topic' : 'Add Topic'}
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="row">
              <div className="form-group col-6">
                <label className="col-form-label" htmlFor="message-text">
                  Title
                </label>
                <input
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control"
                  id="message-text"
                  value={title}
                ></input>
              </div>
              <div className="form-group col-6">
                <label className="col-form-label" htmlFor="message-text">
                  Title(AR)
                </label>
                <input
                  onChange={(e) => setTitleAr(e.target.value)}
                  className="form-control"
                  id="message-text"
                  value={titleAr}
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12 filter-select">
                <label className="col-form-label" htmlFor="message-text">
                  Department
                </label>
                <CustomSelect
                  listData={[
                    { id: 0, name: 'Non-Tech' },
                    { id: 1, name: 'Tech' },
                    // {id: 2, name: "Operations"},
                  ]}
                  handleChange={handleDepartmentChange}
                  placeholder="Select department"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={department}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                ActionsToTake
              </label>
              <textarea
                onChange={(e) => setActionToTake(e.target.value)}
                className="form-control"
                id="message-text"
                value={actionToTake}
                rows={2}
              ></textarea>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Description
              </label>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
                className="form-control"
                id="message-text"
                value={description}
                rows={2}
              ></textarea>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Description(AR)
              </label>
              <textarea
                onChange={(e) => setDescriptionAr(e.target.value)}
                className="form-control"
                id="message-text"
                value={descriptionAr}
                rows={2}
              ></textarea>
            </div>
            <div className="row">
              <div className="form-group col-6">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={showForCustomer}
                  onChange={() => setShowForCustomer((prev) => !prev)}
                />
                <span className="topic-show-for-customer">Show for customer?</span>
              </div>
              <div className="form-group col-6">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={isActive}
                  onChange={() => setIsActive((prev) => !prev)}
                />
                <span className="topic-show-for-customer">Active</span>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleAddNewTopic}
            disabled={disableBtn || !title || !actionToTake || !description}
            color="primary"
          >
            Save
          </Button>
          <Button
            color="light"
            onClick={() => {
              setIsAddTopicModalOpen((prev) => !prev);
              setIsEditMessage(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddTopicModal;
