import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { dateFormatDDMM } from 'constant/Helpers';

const SalesPerformanceDetailsDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="#" field="serial" width={60} textAlign="Center" />
            <ColumnDirective headerText="Order ID" field="orderId" width={120} textAlign="Center" />
            <ColumnDirective headerText="Barcode" field="barcode" width={120} textAlign="Center" />
            <ColumnDirective headerText="Price" field="price" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Supplier"
              field="supplierName"
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Total Sales Price"
              field="totalSalesPrice"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Total Cost"
              field="totalCost"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Delivery Date"
              template={(rowData) => rowData.deliveryDate && dateFormatDDMM(rowData.deliveryDate)}
              customExcelTemplate={(rowData) =>
                rowData.deliveryDate && dateFormatDDMM(rowData.deliveryDate)
              }
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Order Status"
              field="orderStatus"
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(SalesPerformanceDetailsDataGrid);
