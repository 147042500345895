import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// api
import PermissionService from 'services/PermissionService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const CopyGroupModal = ({ isCopyGroupOpen, setIsCopyGroupOpen, toGroup, groups }) => {
  const [fromGroup, setFromGroup] = useState('');

  const handleCopyGroup = async () => {
    const dataBody = {
      FromGroupID: fromGroup,
      Actions: [],
    };
    try {
      await PermissionService.copyGroup(dataBody, toGroup.id);
      toast.success('Group copied successfully!');
      setIsCopyGroupOpen((prev) => !prev);
    } catch (err) {
      errorLogger('CopyGroupModal:handleCopyGroup', err);
      toast.error('Failed to copy group!');
      setIsCopyGroupOpen((prev) => !prev);
    }
  };

  const handleFromGroupChange = (e) => {
    setFromGroup(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isCopyGroupOpen} toggle={() => setIsCopyGroupOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsCopyGroupOpen((prev) => !prev)}>
          Copy from group
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group filter-select">
              {groups !== '' && (
                <CustomSelect
                  listData={groups}
                  handleChange={handleFromGroupChange}
                  placeholder="Select group"
                  textPropName="name"
                  valuePropName="id"
                />
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={!fromGroup} onClick={handleCopyGroup} color="primary">
            Save
          </Button>
          <Button color="light" onClick={() => setIsCopyGroupOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CopyGroupModal;
