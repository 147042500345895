import React, { useState } from 'react';
import CreateCategoryModal from './modals/CreateCategoryModal';
import RestrictedSection from 'components/shared/RestrictedSection';
const CategoriesActions = ({ type, inputValues }) => {
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);

  return (
    <>
      {isAddCategoryModalOpen && (
        <CreateCategoryModal
          isAddCategoryModalOpen={isAddCategoryModalOpen}
          setIsAddCategoryModalOpen={setIsAddCategoryModalOpen}
          type={type}
          inputValues={inputValues}
        />
      )}
      <div className="card">
        <div className="card-body btn-showcase">
          <RestrictedSection
            page="categories"
            action={type === 'category' ? 'create_category' : 'create_subcategory'}
          >
            <button
              onClick={() => setIsAddCategoryModalOpen((prev) => !prev)}
              type="button"
              className="btn btn-success"
            >
              <i className="fa fa-plus"></i> Create{' '}
              {type === 'category' ? 'Category' : 'Sub Category'}
            </button>
          </RestrictedSection>
        </div>
      </div>
    </>
  );
};

export default CategoriesActions;
