import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { history, convertObjectIntoQueryParams } from 'constant/Helpers';
import { errorLogger } from 'datadog/DDUtils';
// api
import DeliveryService from 'services/DeliveryService';

export const setAllDeliverySlots = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getDeliverySlots(params);
    const queryString = convertObjectIntoQueryParams(params);

    history.push({
      pathname: '/delivery/deliveryslots',
      search: queryString,
    });
    dispatch({
      type: 'SET_DELIVERY_SLOTS_ALL_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('slotActions:setAllDeliverySlots', err);
    dispatch(setLoaderOpenFalse());
  }
};
