import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { getProductGroupsById } from 'store/products/ProductsActions';
// api
import ProductsService from 'services/ProductsService';

const CreateProductGroupModal = ({
  isAddProductGroupModalOpen,
  setIsAddProductGroupModalOpen,
  productOptionsById,
  productId,
  type,
  data,
}) => {
  const [titleAr, setTitleAr] = useState(null);
  const [titleEn, setTitleEn] = useState(null);
  const [maxSelection, setMaxSelection] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [disabledMax, setDisabledMax] = useState(false);
  const [disabledOrderId, setDisabledOrderId] = useState(false);
  const dispatch = useDispatch();

  const handleSubmitGroup = async () => {
    const sendData = {
      titleAr,
      titleEn,
      maxQty: parseInt(maxSelection, 10),
      orderId: parseInt(orderId, 10),
      productId: parseInt(productId, 10),
    };
    setDisableBtn(true);
    dispatch(setLoaderOpenTrue());
    if (type === 'edit') {
      try {
        await ProductsService.editProductGroup(sendData, productId, data.id);
        setDisableBtn(false);
        toast.success('Group updated successfully');
        dispatch(setLoaderOpenFalse());
        dispatch(getProductGroupsById(productId));
        setIsAddProductGroupModalOpen(false);
      } catch (err) {
        setDisableBtn(false);
        toast.error('Failed to update group!');
        dispatch(setLoaderOpenFalse());
      }
    } else {
      try {
        await ProductsService.addProductGroup(sendData, productId);
        setDisableBtn(false);
        toast.success('Group added successfully');
        dispatch(setLoaderOpenFalse());
        dispatch(getProductGroupsById(productId));
        setIsAddProductGroupModalOpen(false);
      } catch (err) {
        setDisableBtn(false);
        toast.error('Failed to add group!');
        dispatch(setLoaderOpenFalse());
      }
    }
  };

  useEffect(() => {
    if (type === 'edit') {
      setTitleAr(data.titleAr);
      setTitleEn(data.titleEn);
      setMaxSelection(data.maxQty);
      setOrderId(data.orderId);
    }
  }, []);

  useEffect(() => {
    if (productOptionsById?.type === 10) {
      setMaxSelection(1);
      setDisabledMax(true);
      setOrderId(1);
      setDisabledOrderId(true);
    }
  }, [productOptionsById]);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddProductGroupModalOpen}
        toggle={() => setIsAddProductGroupModalOpen((prev) => !prev)}
      >
        <ModalHeader>{type === 'edit' ? `Edit ${data.titleEn} Group` : 'New Group'}</ModalHeader>
        <ModalBody>
          <form className="card-body row">
            <div className="form-group col-12">
              <label className="bold-title" htmlFor="group-enName">
                Title English
              </label>
              <input
                id="group-enName"
                value={titleEn}
                onChange={(e) => setTitleEn(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-12">
              <label className="bold-title" htmlFor="group-arName">
                Title Arabic
              </label>
              <input
                id="group-arName"
                value={titleAr}
                onChange={(e) => setTitleAr(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="group-max">
                Maximum Selection
              </label>
              <input
                id="group-max"
                value={maxSelection}
                onChange={(e) => setMaxSelection(e.target.value)}
                className="form-control"
                type="number"
                disabled={disabledMax}
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="group-order">
                Order ID
              </label>
              <input
                id="group-order"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
                className="form-control"
                type="number"
                disabled={disabledOrderId}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleSubmitGroup()} color="primary" disabled={disableBtn}>
            Save
          </Button>
          <Button color="light" onClick={() => setIsAddProductGroupModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateProductGroupModal;
