import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// selectors
import { getPermissionActionData } from 'store/permissions/PermissionsSelector';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ActionsModal = ({ isActionsModalOpen, setIsActionsmodalOpen, page, setPageActions }) => {
  const action = useSelector((state) => getPermissionActionData({ state }));

  const [actionName, setActionName] = useState(action.name ? action.name : '');
  const [actionKey, setActionKey] = useState(action.key ? action.key : '');

  const handleAddAction = async () => {
    const bodyData = {
      Id: action.id,
      PageId: page.id,
      Name: actionName,
      Key: actionKey,
    };
    if (action) {
      try {
        await PermissionService.updatePageAction(bodyData);
        const res = await PermissionService.getPermissionPageById(page.id);
        setPageActions(res.data.actions);
        setIsActionsmodalOpen((prev) => !prev);
      } catch (err) {
        errorLogger('ActionsModal:handleAddAction', err);
        setIsActionsmodalOpen((prev) => !prev);
      }
    } else {
      try {
        await PermissionService.createPageAction(bodyData);
        const res = await PermissionService.getPermissionPageById(page.id);
        setPageActions(res.data.actions);
        setIsActionsmodalOpen((prev) => !prev);
      } catch (err) {
        errorLogger('ActionsModal:handleAddAction', err);
        setIsActionsmodalOpen((prev) => !prev);
      }
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isActionsModalOpen} toggle={() => setIsActionsmodalOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsActionsmodalOpen((prev) => !prev)}>
          {action ? 'Edit Action' : 'Add New Action'}
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Name
              </label>
              <input
                onChange={(e) => setActionName(e.target.value)}
                className="form-control"
                id="message-text"
                value={actionName}
              ></input>
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Key
              </label>
              <input
                onChange={(e) => setActionKey(e.target.value)}
                className="form-control"
                id="message-text"
                value={actionKey}
              ></input>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={!actionName || !actionKey} onClick={handleAddAction} color="primary">
            Save
          </Button>
          <Button color="light" onClick={() => setIsActionsmodalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ActionsModal;
