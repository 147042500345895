import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class InvoicesService {
  static getInvoicesData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllInvoices, ...extraParams),
      params: data,
    });
  }

  static getCorporateInvoicesData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCorporateInvoices, ...extraParams),
      params: data,
    });
  }

  static generateInvoice(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getCorporateInvoices, ...extraParams),
      data,
    });
  }

  static getConsolidatedInvoicesData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getConsolidatedInvoices, ...extraParams),
      params: data,
    });
  }

  static generateConsolidatedInvoice(data, params, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getConsolidatedInvoices, ...extraParams),
      data,
      params,
    });
  }

  static getGeneratedConsolidatedInvoices(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getGeneratedConsolidatedInvoices, ...extraParams),
      params: data,
    });
  }

  static lockConsolidatedInvoice(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.lockConsolidatedInvoice, ...extraParams),
    });
  }

  static deleteConsolidatedInvoice(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deleteConsolidatedInvoice, ...extraParams),
    });
  }
}

export default InvoicesService;
