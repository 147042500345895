import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
import SectionLoader from 'components/common/SectionLoader';
// components
const CoveragePostcodeDataTable = React.lazy(() =>
  import('components/delivery/coveragePostcode/CoveragePostcodeDataTable')
);

const CoveragePostcodePage = () => {
  const [inputValues, setInputValues] = useState({
    page: 1,
  });
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, [location.search]);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <CoveragePostcodeDataTable inputValues={inputValues} setInputValues={setInputValues} />
      </React.Suspense>
    </div>
  );
};

export default CoveragePostcodePage;
