import React from 'react';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Toolbar,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { convertUtcToLocalTime } from 'constant/Helpers';

const UserPointsDataGrid = ({ data }) => {
  const toolbarOptions = ['ColumnChooser', 'Search'];

  const referenceIdTemplate = (rowData) => <span>{rowData.referenceId ?? 'N/A'}</span>;

  const createdAtTemplate = (rowData) => (
    <span>{rowData.createdAt && convertUtcToLocalTime(rowData.createdAt)}</span>
  );

  const expiryDateTemplate = (rowData) => (
    <span>{rowData.expiryDate ? convertUtcToLocalTime(rowData.expiryDate) : 'N/A'}</span>
  );

  return (
    <div className="control-pane">
      <div className="control-section saved-credicard-table">
        <GridComponent
          dataSource={data}
          allowPaging={false}
          toolbar={toolbarOptions}
          allowTextWrap={true}
          rowHeight={50}
          showColumnChooser={true}
          allowSorting={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Reference ID"
              template={referenceIdTemplate}
              field="referenceId"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Created at"
              template={createdAtTemplate}
              field="createdAt"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Credit" field="credit" width={100} textAlign="Center" />
            <ColumnDirective headerText="Debit" field="debit" width={100} textAlign="Center" />
            <ColumnDirective headerText="Balance" field="balance" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Expiry date"
              template={expiryDateTemplate}
              field="expiryDate"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective headerText="Remarks" field="remarks" width={100} textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Toolbar, ColumnChooser, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(UserPointsDataGrid);
