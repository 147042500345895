import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// prime react styles
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// ** Import custom components for redux **
import { store } from './store';
import App from './App';
import './index.scss';
import AppErrorBoundary from './components/errorBoundary/AppErrorBoundary';

const Root = () => {
  return (
    <AppErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </AppErrorBoundary>
  );
};

const root = document.getElementById('root');
ReactDOM.render(<Root />, root);
