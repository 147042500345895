import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const LateBreakdownDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseInt(rowData.index, 10) + 1}</p>
      </div>
    );
  };

  const firstMinuteTemplate = (rowData) => {
    return <p className="total-txt">{rowData.firstMinute}</p>;
  };

  const elevenMinuteTemplate = (rowData) => {
    return <p className="total-txt">{rowData.elevenMinute}</p>;
  };

  const thirtyMinuteTemplate = (rowData) => {
    return <p className="total-txt">{rowData.thirtyMinute}</p>;
  };

  const sixtyMinuteTemplate = (rowData) => {
    return <p className="total-txt">{rowData.sixtyMinute}</p>;
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="card-header p-18">
        <h5 className="sz-14">Late Orders BreakDown</h5>
      </div>
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="#" width={100} template={idTemplate} />
            <ColumnDirective
              headerText="1-10 Minute"
              field="firstMinute"
              width={100}
              template={firstMinuteTemplate}
              customExcelTemplate={(rowData) => rowData.firstMinute}
            />
            <ColumnDirective
              headerText="11-30 Minute"
              field="elevenMinute"
              width={100}
              template={elevenMinuteTemplate}
              customExcelTemplate={(rowData) => rowData.elevenMinute}
            />
            <ColumnDirective
              headerText="31-60 Minute"
              field="thirtyMinute"
              width={100}
              template={thirtyMinuteTemplate}
              customExcelTemplate={(rowData) => rowData.thirtyMinute}
            />
            <ColumnDirective
              headerText=">60 Minute"
              field="sixtyMinute"
              width={100}
              template={sixtyMinuteTemplate}
              customExcelTemplate={(rowData) => rowData.sixtyMinute}
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(LateBreakdownDataGrid);
