import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { history, convertObjectIntoQueryParams } from 'constant/Helpers';
import { errorLogger } from 'datadog/DDUtils';
// api
import InventoryService from 'services/InventoryService';

export const getInventoryData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InventoryService.getAllInventoryData(params);
    const queryString = convertObjectIntoQueryParams(params);

    history.push({
      pathname: '/inventory/items',
      search: queryString,
    });
    dispatch({
      type: 'GET_ALL_INVENTORY_DATA',
      payload: res.data,
    });

    dispatch({
      type: 'INVENTORY_PARAMS',
      payload: params,
    });

    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InventoryActions:getInventoryData', err);
    toast.error('Failed to fetch inventory data!!');
    dispatch(setLoaderOpenFalse());
  }
};
export const getAllItemsData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InventoryService.getAllItemsInventoryData(params);
    const queryString = convertObjectIntoQueryParams(params);

    history.push({
      pathname: '/inventory/allItems',
      search: queryString,
    });
    dispatch({
      type: 'GET_ALL_INVENTORY_DATA',
      payload: res.data,
    });

    dispatch({
      type: 'INVENTORY_PARAMS',
      payload: params,
    });

    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InventoryActions:getAllItemsData', err);
    toast.error('Failed to fetch inventory data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getAllItemsAllOpsData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InventoryService.getAllItemsAllOpsInventoryData(params);
    const queryString = convertObjectIntoQueryParams(params);

    history.push({
      pathname: '/inventory/allItemsAllOps',
      search: queryString,
    });
    dispatch({
      type: 'GET_ALL_INVENTORY_DATA',
      payload: res.data,
    });

    dispatch({
      type: 'INVENTORY_PARAMS',
      payload: params,
    });

    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InventoryActions:getAllItemsAllOpsData', err);
    toast.error('Failed to fetch inventory data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getInventorySuppliersList = () => async (dispatch) => {
  try {
    const res = await InventoryService.getSuppliersList();
    dispatch({
      type: 'GET_INVENTORY_SUPPLIERS_LIST',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('InventoryActions:getInventorySuppliersList', err);
    toast.error('Failed to fetch suppliers list!!');
  }
};

export const setInventoryStatements = (params, id) => async (dispatch) => {
  try {
    const res = await InventoryService.getInventoryStatement(params, id);
    dispatch({
      type: 'GET_INVENTORY_STATEMENT_LIST',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('InventoryActions:setInventoryStatements', err);
    toast.error('Failed to get statement data!!');
  }
};

export const setInventorySuppliersData = () => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InventoryService.getInventorySuppliers();
    dispatch({
      type: 'GET_INVENTORY_SUPPLIERS_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InventoryActions:setInventorySuppliersData', err);
    toast.error('Failed to get suppliers data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setExportInventory = (params) => async () => {
  try {
    const res = await InventoryService.exportInventoryItems({ ...params, page: -1 });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Inventory Items.xlsx');
    document.body.appendChild(link);
    link.click();
    toast.success(res?.data?.message ?? 'Inventory exported successfully');
  } catch (err) {
    errorLogger('InventoryActions:setExportInventory', err);
    toast.error('Failed to export excel file!!');
  }
};

export const setAllItemsExport = (params) => async () => {
  try {
    const res = await InventoryService.exportAllInventoryItems({ ...params, page: -1 });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Inventory Items.xlsx');
    document.body.appendChild(link);
    link.click();
    toast.success(res?.data?.message ?? 'Inventory exported successfully');
  } catch (err) {
    errorLogger('InventoryActions:setAllItemsExport', err);
    toast.error('Failed to export excel file!!');
  }
};

export const setAllItemsAllOpsExport = (params) => async () => {
  try {
    const res = await InventoryService.exportAllInventoryAllOpsItems({ ...params, page: -1 });
    toast.success(res?.data?.message ?? 'Inventory exported successfully');
  } catch (err) {
    errorLogger('InventoryActions:setAllItemsAllOpsExport', err);
    toast.error('Failed to download excel file!!');
  }
};

export const setMasterInventoryExport = (params) => async () => {
  try {
    const res = await InventoryService.exportMasterInventoryData({ ...params });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'master-inventory.xlsx');
    document.body.appendChild(link);
    link.click();
    toast.success('Downloaded');
  } catch (err) {
    errorLogger('InventoryActions:setMasterInventoryExport', err);
    toast.error(err?.response?.data?.message ?? 'Failed to download');
  }
};

export const setCopyToBasketItem = (id) => async () => {
  try {
    await InventoryService.copyToBasketItem(id);
    toast.success('Success');
  } catch (err) {
    errorLogger('InventoryActions:setCopyToBasketItem', err);
    toast.error('Failed to copy to basket');
  }
};

export const setInventoryCareDetailsById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InventoryService.getInvetoryCareById(id);
    dispatch({
      type: 'GET_INVENTORY_CARE_DETAILS_BY_ID',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InventoryActions:setInventoryCareDetailsById', err);
    toast.error(err?.response?.data?.message ?? 'Error while retrieving inventory care details');
    dispatch(setLoaderOpenFalse());
  }
};

export const setInventoryItemDetailsById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InventoryService.getInventoryItemDetailsbyId(id);
    dispatch({
      type: 'GET_INVENTORY_ITEM_DETAILS_BY_ID',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InventoryActions:setInventoryItemDetailsById', err);
    toast.error(err?.response?.data?.message ?? 'Error while retrieving inventory item details');
    dispatch(setLoaderOpenFalse());
  }
};

export const setAlertQtyInventoryItems = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await InventoryService.getAlertQtyInventoryItems(params);
    dispatch({
      type: 'GET_ALERT_QTY_INVENTORY_ITEMS',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('InventoryActions:setAlertQtyInventoryItems', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch the alert qty items');
    dispatch(setLoaderOpenFalse());
  }
};
