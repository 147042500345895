import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import MasterCategoriesAction from './MerchandisingCategoriesAction';
import MerchandisingCategoriesDataGrid from './MerchandisingCategoriesDataGrid';
import MerchandisingCategoriesModal from '../modals/MerchandisingCategoriesModal';
// pagination
import DJHPagination from 'components/shared/Pagination';
// actions
import { getMerchandisingCategories } from 'store/merchandisingCategories/MerchandisingCategoriesActions';
// selectors
import { getMerchandisingCategoriesData } from 'store/merchandisingCategories/MerchandisingCategoriesSelectors';

const MasterCategoriesDataTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCat, setSelectedCat] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const categoriesData = useSelector((state) => getMerchandisingCategoriesData({ state }));
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    fetchData();
  }, [params]);

  const fetchData = (i) => {
    if (location.search) {
      dispatch(
        getMerchandisingCategories(
          params.id ? { parentId: params.id, page: i ?? 1 } : { page: i ?? 1 }
        )
      );
    } else {
      dispatch(
        getMerchandisingCategories(
          params.id ? { parentId: params.id, page: i ?? 1 } : { page: i ?? 1 }
        )
      );
    }
  };

  const onPageChange = async (i) => {
    fetchData(i);
    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      {isModalOpen && (
        <MerchandisingCategoriesModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          data={selectedCat}
          isEdit={isEdit}
        />
      )}
      <div className="quick-actions-table toolbar-table-style">
        <MasterCategoriesAction
          type={params.id ? 'subcategory' : 'category'}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setIsEdit={setIsEdit}
        />
        <MerchandisingCategoriesDataGrid
          data={categoriesData?.data}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setSelectedCat={setSelectedCat}
          setIsEdit={setIsEdit}
        />
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+categoriesData?.currentPage}
          pageSize={+categoriesData?.pageSize}
          itemsCount={+categoriesData?.rowCount}
        />
      </div>
    </>
  );
};

export default MasterCategoriesDataTable;
