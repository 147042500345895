import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CustomSelect from 'components/shared/CustomSelect';
import { driversType, driversSort } from 'constant/Enums';

const DriversFilter = ({ handleSubmitForm, inputValues, setInputValues, vehiclesType }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const handleChange = (e, name) => {
    setInputValues({ ...inputValues, [name]: e.target.value });
  };

  const handleSortChange = (e) => {
    setInputValues({ ...inputValues, sortby: e.itemData.id });
  };

  const handleTypeChange = (e) => {
    setInputValues({ ...inputValues, type: e.itemData.id });
  };

  const handleVehicleTypeChange = (e) => {
    setInputValues({ ...inputValues, vehicleType: e.itemData.vehicleType });
  };
  return (
    <div className="card ">
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading onClick={() => setFilterOpen((prev) => !prev)}>
            <AccordionItemButton>
              <div className="card-header filter-accordion-card">
                <h4>Filter</h4>
                {filterOpen ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form id="driversFilter" onSubmit={handleSubmitForm} className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-xl-3">
                    <label className="bold-title" htmlFor="driver-name">
                      Driver
                    </label>
                    <div className="form-group">
                      <input
                        id="driver-name"
                        className="form-control"
                        type="text"
                        placeholder="Search by driver name, phone or email"
                        defaultValue={inputValues.searchkey}
                        onChange={(e) => handleChange(e, 'searchkey')}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3">
                    <label className="bold-title" htmlFor="driver-company">
                      Company
                    </label>
                    <div className="form-group">
                      <input
                        id="driver-company"
                        className="form-control"
                        type="text"
                        placeholder="Search by Company name"
                        defaultValue={inputValues.companykey}
                        onChange={(e) => handleChange(e, 'companykey')}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3 filter-select">
                    <label className="bold-title" htmlFor="driver-sort">
                      Sort by
                    </label>
                    <div className="form-group">
                      <CustomSelect
                        id="driver-sort"
                        listData={driversSort}
                        handleChange={handleSortChange}
                        placeholder="Sort By"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={inputValues.sortby}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3 filter-select">
                    <label className="bold-title" htmlFor="driver-type">
                      User Type
                    </label>
                    <div className="form-group">
                      <CustomSelect
                        id="driver-type"
                        listData={[{ id: -1, name: 'All' }].concat(driversType)}
                        handleChange={handleTypeChange}
                        placeholder="Select a type"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={inputValues.type}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3 filter-select">
                    <label className="bold-title" htmlFor="driver-vehicle">
                      Vehicle Type
                    </label>
                    {vehiclesType.length > 0 && (
                      <div className="form-group">
                        <CustomSelect
                          id="driver-vehicle"
                          listData={[{ vehicleType: -1, vehicleName: 'All' }].concat(vehiclesType)}
                          handleChange={handleVehicleTypeChange}
                          placeholder="Select vehicle"
                          textPropName="vehicleName"
                          valuePropName="vehicleType"
                          selectedValue={inputValues.vehicleType}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary mr-1 -mr-5" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default DriversFilter;
