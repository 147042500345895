import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

const AttributesFilter = ({ inputValues, setInputValues, handleSubmit }) => {
  const [searchKey, setSearchKey] = useState('');
  const handleSearchKeychange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.searchKey = e.target.value;
    setSearchKey(e.target.value);
    setInputValues(updatedParams);
  };
  useEffect(() => {
    setSearchKey(inputValues.searchKey);
  }, [inputValues.searchKey]);

  return (
    <div className="card">
      <div className="card-header p-18">
        <h4>Filter</h4>
      </div>
      <form className="form theme-form" onSubmit={(e) => e.preventDefault()}>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="form-group filter-select">
                <label className="bold-title" htmlFor="search-attr">
                  Search Attribute
                </label>
                <input
                  id="search-attr"
                  className="form-control"
                  type="text"
                  placeholder="attribute name"
                  value={searchKey}
                  onChange={handleSearchKeychange}
                />
              </div>
            </div>
            <div className="col-12 offset-md-5 col-md-2 filter-btn-div">
              <button
                onClick={() => handleSubmit(inputValues)}
                className="btn btn-primary mr-1"
                type="button"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AttributesFilter;
