import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import moment from 'moment';
import * as XLSX from 'xlsx';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const history = createBrowserHistory();

export const formatDateForInput = (date) => format(date, 'yyyy-MM-dd');

export const formatDateMDYDashes = (date) => moment(date).format('MM-DD-YYYY');

export const formatDateYMDDashes = (date) => moment(date).format('YYYY-MM-DD');

export const isoFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + 'T00:00:00';
  return newDate;
};

export const isoStartFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + 'T00:00:00';
  return newDate;
};

export const isoEndFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + 'T23:59:59';
  return newDate;
};

export const dateStartFormat = (dateFormat, starthr = '00', startMin = '00') => {
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + `T${starthr}:${startMin}:00`;
  return newDate;
};

export const dateEndFormat = (dateFormat) => {
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + 'T23:59:59';
  return newDate;
};

export const dateFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = mm + '/' + dd + '/' + yyyy;
  return newDate;
};

export const dateFormatWithoutZone = (date) => {
  let oldDate = date;
  if (oldDate.indexOf('Z') > -1) {
    oldDate = oldDate.slice(0, oldDate.length - 1);
  }
  const dateFormat = new Date(oldDate);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = mm + '/' + dd + '/' + yyyy;
  return newDate;
};

export const dateFormatDDMM = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = dd + '/' + mm + '/' + yyyy;
  return newDate;
};

export const timeFormatHHMM = (date) => {
  const dateFormat = new Date(date);
  const hh = String(dateFormat.getHours()).padStart(2, '0');
  const mm = String(dateFormat.getMinutes()).padStart(2, '0');
  const newTime = hh + ':' + mm;
  return newTime;
};

export const timeFormatUTCHHMM = (date) => {
  if (date) {
    const dateFormat = new Date(date);
    const hh = String(dateFormat.getUTCHours()).padStart(2, '0');
    const mm = String(dateFormat.getUTCMinutes()).padStart(2, '0');
    const newTime = hh + ':' + mm;
    return newTime;
  } else {
    const dateFormat = new Date();
    const hh = String(dateFormat.getHours()).padStart(2, '0');
    const mm = String(dateFormat.getMinutes()).padStart(2, '0');
    const newTime = hh + ':' + mm;
    return newTime;
  }
};

export const timeFormatHHMM12 = (date) => {
  let sufix = 'AM';
  const dateFormat = new Date(date);
  let hh = dateFormat.getUTCHours();
  if (hh > 11) {
    sufix = 'PM';
  }
  if (hh > 12) {
    hh = hh - 12;
  }
  hh = String(hh).padStart(2, '0');
  const mm = String(dateFormat.getUTCMinutes()).padStart(2, '0');
  const newTime = hh + ':' + mm + ' ' + sufix;
  return newTime;
};

export const convertQueryParamsIntoObject = (params) =>
  JSON.parse(
    '{"' + decodeURIComponent(params.substring(1).replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
  );

export const convertObjectIntoQueryParams = (object) =>
  '?' +
  Object.keys(object)
    .map((key) => {
      const filteredEncodedstring =
        object[key] && typeof object[key] === 'string'
          ? object[key].replace(/[\\]*["]*[?]*[<]*[>]*[|]*[\t]*/gi, '')
          : object[key];
      return `${key}=${encodeURIComponent(filteredEncodedstring)}`;
    })
    .join('&');

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  toast.success('Copied');
};

export const objectWithoutKey = (object, key) => {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

export const convertSecondsIntoTime = (secs) => {
  const hours = Math.floor(secs / (60 * 60));

  const DIVISOR_FOR_MINUTES = secs % (60 * 60);
  const minutes = Math.floor(DIVISOR_FOR_MINUTES / 60);

  const DIVISOR_FOR_SECONDS = DIVISOR_FOR_MINUTES % 60;
  const seconds = Math.ceil(DIVISOR_FOR_SECONDS);

  const obj = `${hours <= 9 ? `0${hours}` : hours}:${minutes <= 9 ? `0${minutes}` : minutes}:${
    seconds <= 9 ? `0${seconds}` : seconds
  }`;

  return obj;
};

export const convertToDateAndTime = (date) => {
  const dateObject = new Date(date);
  const humanDateFormat = dateObject.toLocaleString();
  return humanDateFormat;
};

export const handleImageURL = (url) => {
  return url?.includes('https://') ? url : 'https://cdnv2.floward.com/web/Files/cat_images/' + url;
};

export const handleImageFile = (img) => {
  return img?.name ? URL.createObjectURL(img) : img;
};

export const calcPercentage = (data, val) => {
  let tot = 0;
  for (let i = 0; i < data?.length ?? 0; i++) {
    tot += data[i].count;
  }
  return data?.length ? Math.round((val * 100) / tot) : 0;
};

export const convertUtcToLocalTime = (utcDate) => {
  return moment(moment.utc(utcDate).toDate()).local().format('DD-MM-YYYY HH:mm');
};

export const momentFormat = (format, date) => {
  if (date) {
    return moment(date).format(format);
  } else {
    if (format) {
      return moment().format(format);
    } else {
      return moment().format('DD MMM');
    }
  }
};

export const startOf = (type, date) => {
  if (date) {
    return moment(date).startOf(type);
  } else {
    return moment().startOf(type);
  }
};

export const endOf = (type, date) => {
  if (date) {
    return moment(date).endOf(type);
  } else {
    return moment().endOf(type);
  }
};

export const momentIsAfter = (date1, date2) => {
  return moment(date1).isAfter(date2);
};

export const getMoment = () => {
  return moment;
};

export const getAreas = (res) => {
  let allareas = res.data;
  for (let i = 0; i < res.data.length; i++) {
    if (res.data[i].subarea.length > 0) {
      const subAreas = [res.data[i]].concat(res.data[i].subarea);
      for (let j = 0; j < subAreas.length; j++) {
        subAreas[j].mainArea = res.data[i].name;
      }
      allareas = allareas.concat(subAreas);
    } else {
      const subAreas = [res.data[i]];
      subAreas[0].mainArea = res.data[i].name;
      allareas = allareas.concat(subAreas);
    }
  }
  return allareas;
};

export const startOfMonth = moment().startOf('month').format('MM/DD/YYYY');

export const endOfMonth = moment().endOf('month').format('MM/DD/YYYY');

export const fromNow = (date) => moment(date).fromNow();

export const momentDate = (date, format) => {
  if (format) {
    return moment(date, format);
  } else {
    return moment(date);
  }
};

export const getSubCategories = (data) => {
  let newData = [];
  data.forEach((d) => {
    if (d.subCategories.length > 0) {
      newData = newData.concat(d.subCategories);
    }
  });
  return newData;
};

export const formatLogValues = (type, val) => {
  if (
    type === 'cost' ||
    type === 'costUsd' ||
    type === 'discountedPrice' ||
    type === 'margin' ||
    type === 'oldPrice' ||
    type === 'price' ||
    type === 'priceUsd' ||
    type === 'suggestedPrice' ||
    type === 'productViews'
  ) {
    return new Intl.NumberFormat('en-US').format(val);
  } else {
    if (type === 'createdAt' || type === 'priceEditedAt') {
      return dateFormatDDMM(val);
    } else {
      return val;
    }
  }
};

export const compareSort = (arr1, arr2) => {
  let isDifferent = false;
  if (arr1.length > 0) {
    arr1.forEach((a, ind) => {
      if (a.id !== arr2[ind].id) {
        isDifferent = true;
      }
    });
    return isDifferent;
  } else {
    return false;
  }
};

export const copyPageUrl = async () => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(window.location.href);
    return;
  }
  try {
    await navigator.clipboard.writeText(window.location.href);
    toast.success('URL copied');
  } catch {
    toast.error('Failed to copy');
  }
};

export const handleCopyText = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success('Copied');
  } catch (e) {
    toast.error('Copy failed');
  }
};

const fallbackCopyTextToClipboard = async (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
    toast.success('successful');
  } catch (err) {
    toast.error('Failed to copy');
  }
  document.body.removeChild(textArea);
};

export const HasPermission = (permissionsList, page, action) => {
  return permissionsList.some((item) => item.pageKey === page && item.actionKey === action);
};

export const convertToUnix = (date) => {
  if (date) {
    return moment(new Date(date)).utc(true).unix();
  } else {
    return moment(new Date()).unix();
  }
};

export const convertFromUnix = (date) => {
  return moment(new Date(date * 1000))
    .utc(true)
    .unix();
};

export async function downloadImage(imageSrc, index) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = `Image ${index}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function downloadFile(fileSrc, fileName) {
  const url = window.URL.createObjectURL(new Blob([fileSrc]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export function convertQueryObjToValues(obj, initObj) {
  Object.keys(obj).forEach((key) => {
    if (typeof initObj[key] === 'number') {
      obj[key] = Number(obj[key]);
    }
    if (typeof initObj[key] === 'object' && obj[key] === 'null') {
      obj[key] = null;
    }
  });
  return obj;
}

export function excelToJSON(excelFile, setData, setIsValidList, checkValid, setGoNext, setLoading) {
  // var name = excelFile.name;
  const reader = new FileReader();
  setLoading(true);
  reader.onload = (evt) => {
    // evt = on_file_select event
    /* Parse data */
    const bstr = evt.target.result;
    import('xlsx')
      .then(({ read, utils }) => {
        const wb = read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
          defval: '',
          blankrows: false,
        });
        /* Update state */
        const newList = [];
        data.forEach((row) => {
          const rowArr = [];
          row.forEach(() => {
            rowArr.push(true);
          });
          newList.push(rowArr);
        });
        setIsValidList(newList);
        setData(data);
        setGoNext(true);
        checkValid(newList, data);
        setLoading(false);
      })
      .catch((error) =>
        errorLogger(
          'An error occurred while loading the module (Excel to JSON) with the followin error: ',
          error
        )
      );
  };
  reader.readAsBinaryString(excelFile);
}

export const formatRecipient = (data) => {
  const formattedData = data.map((d) => {
    return {
      RecipientName: d[0],
      RecipientPhone: d[1],
      DeliveryCost: parseFloat(d[2]),
      MessageTo: d[3],
      MessageFrom: d[4],
      Message: d[5],
      FeelingLink: d[6],
    };
  });
  return formattedData;
};

export const formatProductionData = (data) => {
  const formattedData = data.map((d) => {
    return {
      ProductSku: d[0],
      ProductName: d[1],
      Target: parseInt(d[2]),
      BatchSize: parseInt(d[3]),
      TriggerStock: parseInt(d[4]),
      SafetyStock: parseInt(d[5]),
      ProductionStartDate: d[6],
      ProductionEndDate: d[6].replace('2022', '2023'),
      IsManual: d[7]?.toLowerCase() === 'yes',
    };
  });
  return formattedData;
};

export const isRowValid = (row) => {
  let isValid = true;
  const hasError = (element) => element === false;
  isValid = !row?.some(hasError);
  return isValid;
};

export function getProductTabs(productOptionsById) {
  return [
    { nickName: 'Details', id: 0 },
    { nickName: 'Options', id: 1 },
    { nickName: 'Media', id: 2 },
    { nickName: 'Local Availability', id: 3 },
    { nickName: 'Categories', id: 4 },
    { nickName: 'MC', id: 11 },
    { nickName: 'Inventory', id: 5 },
    {
      nickName: productOptionsById?.type === 10 ? 'Variant' : 'Basket',
      id: 6,
    },
    { nickName: 'Exclude Day', id: 7 },
    { nickName: 'Master Availability', id: 8 },
    { nickName: 'Master Inventory', id: 9 },
    { nickName: 'Subscriptions', id: 10 },
    { nickName: 'Ranking', id: 12 },
  ];
}

export const customToolbarItemTemplate = (text, className) => {
  return `<button class="${className}">
      ${text}
    </button>`;
};

export const exportToExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
  //buffer
  let buf = XLSX.write(workbook, { booktype: 'xlsx', type: 'buffer' });
  //binary string
  XLSX.write(workbook, { booktype: 'xlsx', type: 'binary' });
  //download
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
