import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PageTitle from 'components/shared/PageTitle';
import OrdersZonesDataTable from './OrdersZonesDataTable';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { dateFormat } from 'constant/Helpers';
// api
import QuickActionService from 'services/QuickActionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const OrdersZonesPage = () => {
  const [ordersZones, setOrdersZones] = useState([]);
  const dispatch = useDispatch();

  const fetchOrdersZonesData = async () => {
    dispatch(setLoaderOpenTrue());
    const params = {
      deliveryDate: dateFormat(new Date()),
    };
    try {
      const res = await QuickActionService.getOrdersZones(params);
      setOrdersZones(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('OrdersZonesPage:fetchOrdersZonesData', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get orders zones!');
    }
  };

  useEffect(() => {
    fetchOrdersZonesData();
  }, []);

  return (
    <Fragment>
      <div className="container-fluid">
        <PageTitle title="Orders Zones" />
        <OrdersZonesDataTable ordersZones={ordersZones} />
      </div>
    </Fragment>
  );
};

export default OrdersZonesPage;
