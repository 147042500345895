import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
import AddTopicModal from './AddTopicModal';
import CustomSelect from 'components/shared/CustomSelect';
// actions
import { setTopicListData } from 'store/ticketing/TicketingActions';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// selectors
import { getTopicsListData } from 'store/ticketing/TicketingSelectors';

const TopicsPage = () => {
  const [isAddTopicModalOpen, setIsAddTopicModalOpen] = useState(false);
  const [editedMessage, setEditedMessage] = useState('');
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [inputValues, setInputValues] = useState({
    departments: 0,
  });
  const [department, setDepartment] = useState(0);
  const topicsList = useSelector((state) => getTopicsListData({ state }));
  const dispatch = useDispatch();
  const departmentValue = {
    0: 'Non-Tech',
    1: 'Tech',
    // 2: "Operations"
  };

  const fetchTopics = async (params) => {
    dispatch(setLoaderOpenTrue());
    await dispatch(setTopicListData(params));
    dispatch(setLoaderOpenFalse());
  };

  useEffect(() => {
    fetchTopics(inputValues);
  }, []);

  const handleDepartmentChange = (e) => {
    fetchTopics({
      departments: e.itemData.id,
    });
    setDepartment(e.itemData.id);
  };

  return (
    <Fragment>
      {isAddTopicModalOpen && (
        <AddTopicModal
          isAddTopicModalOpen={isAddTopicModalOpen}
          setIsAddTopicModalOpen={setIsAddTopicModalOpen}
          isEditMessage={isEditMessage}
          editedMessage={editedMessage}
          setIsEditMessage={setIsEditMessage}
        />
      )}
      <PageTitle title="Topics" />
      <div className="card topics-wrapper">
        <div className="card-header topic-header">
          <div>
            <button
              onClick={() => {
                setIsAddTopicModalOpen((prev) => !prev);
                setIsEditMessage(false);
              }}
              className="btn btn-success"
            >
              <i className="fa fa-plus"></i> Add Topic
            </button>
          </div>
          <div className="form-group filter-select col-6">
            <CustomSelect
              listData={[
                { id: 0, name: 'Non-Tech' },
                { id: 1, name: 'Tech' },
                // { id: 2, name: 'Operations' },
              ]}
              handleChange={handleDepartmentChange}
              placeholder="Select Department"
              textPropName="name"
              valuePropName="id"
              selectedValue={department}
            />
          </div>
        </div>
        <div className="recent-notification card-body">
          <div className="table-responsive text-center">
            <table className="table table-bordernone">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Department</th>
                  <th scope="col">ActionsToTake</th>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                  <th scope="col" className="no-wrap">
                    Show for customer
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              {topicsList.length > 0 && (
                <tbody>
                  {topicsList.map((item, i) => (
                    <tr key={i}>
                      <td>{item.title}</td>
                      <td>{departmentValue[item.department]}</td>
                      <td>{item.actionsToTake}</td>
                      <td>{item.description}</td>
                      <td>
                        <div
                          className={`btn generic-status ${
                            item?.status ? 'btn-light-success' : 'btn-light-danger'
                          }`}
                        >
                          {item?.status ? 'Active' : 'Inactive'}
                        </div>
                      </td>
                      <td>
                        <input
                          className="checkbox_animated ticket-topic-checkbox"
                          type="checkbox"
                          checked={item.showForCustomer == null ? false : item.showForCustomer}
                          readOnly
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setIsAddTopicModalOpen((prev) => !prev);
                            setIsEditMessage(true);
                            setEditedMessage(item);
                          }}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TopicsPage;
