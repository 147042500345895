import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
// actions
import { setFloristsTransactionDataList } from 'store/floristCommunity/FloristCommunityActions';
// selectors
import {
  getFloristCommunityTransactionList,
  getFloristCommunityTransactionListPageSize,
  getFloristCommunityTransactionListCurrentPage,
  getFloristCommunityTransactionListRowCount,
} from 'store/floristCommunity/FloristCommunitySelectors';
// components
import SectionLoader from 'components/common/SectionLoader';
const FloristTransactionDataGrid = React.lazy(() =>
  import('components/floristCommunity/FloristTransactionDataGrid')
);
const DJHPagination = React.lazy(() => import('components/shared/Pagination'));

const FloristTransactionsPage = ({ location }) => {
  const floristCommunityList = useSelector((state) =>
    getFloristCommunityTransactionList({ state })
  );
  const pageSize = useSelector((state) => getFloristCommunityTransactionListPageSize({ state }));
  const currentPage = useSelector((state) =>
    getFloristCommunityTransactionListCurrentPage({ state })
  );
  const rowCount = useSelector((state) => getFloristCommunityTransactionListRowCount({ state }));
  const dispatch = useDispatch();

  const fetchFloristsList = async () => {
    dispatch(setFloristsTransactionDataList({ page: 1 }));
  };

  useEffect(() => {
    fetchFloristsList();
  }, []);

  const onPageChange = (i) => {
    dispatch(setFloristsTransactionDataList({ page: i }));
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Florist Community transactions" />
      <React.Suspense fallback={<SectionLoader />}>
        <FloristTransactionDataGrid data={floristCommunityList} />
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+currentPage}
          pageSize={+pageSize}
          itemsCount={+rowCount}
        />
      </React.Suspense>
    </div>
  );
};

export default FloristTransactionsPage;
