import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import debounce from 'lodash/debounce';
import SectionLoader from 'components/common/SectionLoader';
// api
import BulkCreateOrderService from 'services/BulkCreateOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ProductModal = ({ isProductModalOpen, setIsProductModalOpen, setSelectedProduct }) => {
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [product, setProduct] = useState('');

  const fetchData = debounce(async (keyword) => {
    const params = {
      searchkey: keyword,
    };
    setSearchKey(keyword);
    setIsLoaderOpen(true);
    try {
      const res = await BulkCreateOrderService.getProductList(params);
      setSearchResult(res.data);
      setIsLoaderOpen(false);
    } catch (e) {
      errorLogger('ProductModal:fetchData', e);
      setIsLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    if (keyword.length >= 3) {
      fetchData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleSelectProduct = (prod) => {
    setProduct(prod);
    setShowTable(false);
  };

  const saveProduct = () => {
    setSelectedProduct(product);
    setIsProductModalOpen(false);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isProductModalOpen}
        toggle={() => setIsProductModalOpen((prev) => !prev)}
        className="create-order-modal"
      >
        <ModalHeader>Select Product</ModalHeader>
        <ModalBody>
          <div className="form-group col-12">
            <form onSubmit={(e) => e.preventDefault()}>
              <label htmlFor="product-search">Search product</label>
              <input
                id="product-search"
                className="form-control"
                type="text"
                placeholder="product name"
                onChange={(e) => handleSearch(e.target.value)}
                defaultValue={searchKey}
              />
            </form>
          </div>
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <>
              {searchResult.length > 0 && (
                <div
                  className={`table-responsive shopping-table text-center ${
                    showTable ? 'auto-height-table' : ''
                  }`}
                >
                  {showTable && (
                    <table className="table table-bordernone create-order-table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Image</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResult.map((item, i) => (
                          <tr
                            className="search-table-row"
                            onClick={() => handleSelectProduct(item)}
                            key={i}
                          >
                            <td>{item.id}</td>
                            <td>
                              <img
                                width={50}
                                height={50}
                                src={item.image}
                                alt={item.name}
                                className="img-fluid rounded product-img"
                              />
                            </td>
                            <td>{item.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              {product && (
                <div className="row">
                  <div className="col-12 customer-info-header">
                    <h6>Product Information</h6>
                  </div>
                  <div className="selected card py-2 px-4 product-card" key={product.id}>
                    <div className="row px-2">
                      <div className="col-3 col-md-3">
                        <img
                          src={product.image}
                          alt={product.name}
                          className="img-fluid rounded product-img"
                        />
                      </div>
                      <div className="col-9 col-md-9 between-flex">
                        <h2 className="h6">{product.name}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={saveProduct} color="primary" disabled={!product || isLoaderOpen}>
            Save
          </Button>
          <Button color="light" onClick={() => setIsProductModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProductModal;
