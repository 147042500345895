import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CustomSelect from 'components/shared/CustomSelect';
import { pickersType, pickersSortBy, pickersStatus } from 'constant/Enums';

const UsersFilter = ({ handleSubmitForm, inputValues, setInputValues, title }) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const handleChange = (e, name) => {
    setInputValues({ ...inputValues, [name]: e.target.value });
  };

  const handleSortChange = (e) => {
    setInputValues({ ...inputValues, sortBy: e.itemData.id });
  };

  const handleStatusChange = (e) => {
    setInputValues({ ...inputValues, status: e.itemData.id });
  };

  const handleTypeChange = (e) => {
    setInputValues({ ...inputValues, userContractType: e.itemData.id });
  };

  return (
    <div className="card ">
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading onClick={() => setFilterOpen((prev) => !prev)}>
            <AccordionItemButton>
              <div className="card-header filter-accordion-card">
                <h4>Filter</h4>
                {filterOpen ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form id="driversFilter" onSubmit={handleSubmitForm} className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-xl-3">
                    <label className="bold-title">{title}</label>
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by first name, last name or phone number "
                        defaultValue={inputValues.searchKey}
                        onChange={(e) => handleChange(e, 'searchKey')}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3 filter-select">
                    <label className="bold-title" htmlFor="picker-sort">
                      Sort by
                    </label>
                    <div className="form-group">
                      <CustomSelect
                        id="picker-sort"
                        listData={pickersSortBy}
                        handleChange={handleSortChange}
                        placeholder="Sort By"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={inputValues.sortBy}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3 filter-select">
                    <label className="bold-title" htmlFor="picker-status">
                      Status
                    </label>
                    <div className="form-group">
                      <CustomSelect
                        id="picker-status"
                        listData={pickersStatus}
                        handleChange={handleStatusChange}
                        placeholder="Select a status"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={inputValues.status}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-3 filter-select">
                    <label className="bold-title" htmlFor="picker-type">
                      Type
                    </label>
                    <div className="form-group">
                      <CustomSelect
                        id="picker-type"
                        listData={pickersType}
                        handleChange={handleTypeChange}
                        placeholder="Select a type"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={inputValues.userContractType}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary mr-1 -mr-5" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default UsersFilter;
