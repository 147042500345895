// default app reducers
import { combineReducers } from 'redux';
import Customizer from '../redux/customizer/reducer';
// custom reducers
import app from './app/AppReducer';
import dashboard from './dashboard/DashboardReducer';
import calender from './dashboard/calender/CalenderReducer';
import signinForm from './auth/signIn/SignInReducer';
import quickActionsTable from './orderQuickActions/dataTable/DataTableReducer';
import quickPrinting from './orderQuickPrinting/QuickPrintingReducer';
import viewOrder from './viewOrder/ViewOrderReducer';
import permission from './permissions/PermissionsReducer';
import addInventory from './viewOrder/inventory/addInventory/reducers/AddInventoryReducer';
import editItems from './editItems/EditItemsReducer';
import notifications from './notifications/reducers/NotificationsReducer';
import products from './products/ProductsReducer';
import subscriptions from './subscriptions/SubscriptionsReducer';
import deliverySlots from './delivery/deliverySlots/slotsReducer';
import ticketing from './ticketing/TicketingReducer';
import customerService from './customerService/CustomerServiceReducer';
// user
import user from './user/UserReducer';
// inventory
import inventory from './inventory/InventoryReducer';
// master-inventory
import masterInventory from './masterInventory/MasterInventoryReducer';
// occasions
import occasions from './occasions/OccasionsReducer';
// categories
import categories from './categories/CategoriesReducer';
// Delivery areas
import deliveryAreas from './delivery/deliveryAreas/areasReducer';
// Delivery drivers
import drivers from './delivery/drivers/driversReducer';
// banners
import banners from './banners/BannerReducer';
// florist community
import floristCommunity from './floristCommunity/FloristCommunityReducer';
// Delivery postcodes
import deliveryPostCodes from './delivery/deliveryPostCodes/postcodeReducer';
// Drivers expenses
import driversExpenses from './delivery/driversExpenses/DriversExpensesReducer';
// vouchers
import vouchers from './vouchers/VouchersReducer';
// master catalog
import masterCatalog from './masterCatalog/MasterCatalogReducer';
// celebrities
import celebrities from './celebrities/CelebritiesReducer';
// kpi
import kpi from './kpi/KpiReducer';
// corporate
import corporate from './corporate/CorporateReducer';
// rankingProducts
import rankingProducts from './rankingProducts/RankingProductsReducer';
// country slots
import countrySlots from './countrySlots/CountrySlotsReducer';
// reports
import reports from './reports/ReportsReducer';
// key value
import keyValue from './keyValues/keyValueReducer';
// seasonal prep
import seasonal from './seasonal/SeasonalReducer';
// Invoices
import invoices from './invoices/InvoicesReducer';
// shorter links
import shorterLinks from './shorterLink/ShorterLinkReducer';
// merchandising
import merchandising from './merchandisingCategories/MerchandisingCategoriesReducer';
// attributes
import attributes from './attributes/AttributesReducer';
// Covered Areas
import coveredAreas from './coveredAreas/CoveredAreasReducer';
// Production System
import productionSystem from './productionSystem/productionSystemReducer';
// Featured Occasions
import featuredOccasions from './featuredOccassions/FeaturedOccasionsReducer';
// user feedback
import userFeedback from './userFeedback/userFeedbackReducer';

const reducers = combineReducers({
  Customizer,
  app,
  dashboard,
  calender,
  signinForm,
  quickActionsTable,
  quickPrinting,
  viewOrder,
  user,
  permission,
  addInventory,
  editItems,
  notifications,
  products,
  subscriptions,
  deliverySlots,
  ticketing,
  customerService,
  inventory,
  occasions,
  categories,
  deliveryAreas,
  driversExpenses,
  banners,
  deliveryPostCodes,
  floristCommunity,
  vouchers,
  masterInventory,
  masterCatalog,
  kpi,
  corporate,
  celebrities,
  rankingProducts,
  countrySlots,
  reports,
  keyValue,
  seasonal,
  invoices,
  shorterLinks,
  drivers,
  merchandising,
  attributes,
  coveredAreas,
  productionSystem,
  featuredOccasions,
  userFeedback,
});

export default reducers;
