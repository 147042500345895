import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
// modals
import AddLimitedCategoryModal from './AddLimitedCategoryModal';
// api
import VouchersService from 'services/VouchersService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const VoucherLimitCategories = ({
  voucherLimitCategories,
  couponId,
  setIsCatVoucher,
  isCatVoucher,
  getLimitCategories,
}) => {
  const [isAddCatModalOpen, setIsAddCatModalOpen] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();

  const handleRemoveItem = async (id) => {
    setIsLoaderOpen(true);
    try {
      const res = await VouchersService.removeVoucherLimitCat(id);
      const newVoucherLimitCat = voucherLimitCategories.filter((el) => el.id !== id);
      dispatch({
        type: 'SET_VOUCHER_LIMIT_CATEGORIES',
        payload: newVoucherLimitCat,
      });
      setIsLoaderOpen(false);
      toast.success(res?.data?.message ?? 'Voucher Category has been deleted successfully');
    } catch (err) {
      errorLogger('VoucherLimitCategories:handleRemoveItem', err);
      toast.error(err?.response?.data?.message ?? "Voucher Category doesn't exist");
      setIsLoaderOpen(false);
    }
  };

  return (
    <div>
      {isAddCatModalOpen && (
        <AddLimitedCategoryModal
          isAddCatModalOpen={isAddCatModalOpen}
          setIsAddCatModalOpen={setIsAddCatModalOpen}
          couponId={couponId}
          getLimitCategories={getLimitCategories}
        />
      )}
      <div className="col-md-12 form-group bold-title">
        <div className="col-md-4 form-group">
          <input
            onChange={() => setIsCatVoucher((prev) => !prev)}
            className="m-r-5"
            type="checkbox"
            checked={isCatVoucher}
          ></input>
          <label className="col-form-label" htmlFor="recipient-name">
            Category voucher
          </label>
        </div>
      </div>
      {isCatVoucher && (
        <>
          <div className="limited-cat">
            <span>Voucher Limited Categories</span> |{' '}
            <span
              onClick={() => setIsAddCatModalOpen((prev) => !prev)}
              className="generate-code add-more"
            >
              Add More
            </span>
          </div>
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <>
              {voucherLimitCategories.length > 0 && (
                <div className="table-responsive shopping-table text-center">
                  <table className="table table-bordernone create-order-table">
                    <thead>
                      <tr>
                        <th>Name English</th>
                        <th>Name Arabic</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {voucherLimitCategories.map((item, i) => (
                        <tr className="search-table-row" key={i}>
                          <td>{item.nameEn}</td>
                          <td>{item.nameAr}</td>
                          <td>
                            <i
                              className="fa fa-trash inventory-supp-trash"
                              onClick={() => handleRemoveItem(item.id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VoucherLimitCategories;
