export const getProductAllCategoriesData = ({ state }) => state.products.productAllCategoriesData;
export const getProductMainCategoriesData = ({ state }) => state.products.productMainCategoriesData;
export const getProductExcludedSlotsData = ({ state }) => state.products.productExcludedSlots;
export const getProductExcludedSlotsByIdData = ({ state }) =>
  state.products.productExcludedSlotsById;
export const getProductCategoriesByIdData = ({ state }) => state.products.productCategoriesById;
export const getProductAttributesFilterData = ({ state }) =>
  state.products.productArrtibutesFilterData;
export const getProductAttributesByIdData = ({ state }) => state.products.productAttributesById;
export const getProductMCData = ({ state }) => state.products.productMCById;
export const getTotalProductsPageSize = ({ state }) => state.products.pageSize;
export const getTotalProductsCurrentPage = ({ state }) => state.products.currentPage;
export const getTotalProductsRowCount = ({ state }) => state.products.rowCount;
export const getProductCategories = ({ state }) => state.products.productCategories;
export const getProductDetailsById = ({ state }) => state.products.productDetailsById;
export const getProductOptionsById = ({ state }) => state.products.productOptionsById;
export const getProductAvailabilityById = ({ state }) => state.products.productAvailabilityById;
export const getProductInventoryById = ({ state }) => state.products.productInventoryById;
export const getProductMediaById = ({ state }) => state.products.productMediaById;
export const getProductMasterAvailabilityById = ({ state }) =>
  state.products.productMasterAvailabilityById;
export const getProductMasterInventoryById = ({ state }) =>
  state.products.productMasterInventoryById;
export const getProductGrpupsData = ({ state }) => state.products.productGroups;
export const getProductExcludedDaysData = ({ state }) => state.products.productExcludedDays;
export const getProductSizesData = ({ state }) => state.products.productSizes;
export const getSelectedProductSize = ({ state }) => state.products.selectedProductSize;
export const getFetchProductSizeStatus = ({ state }) => state.products.isFetchingProductSizes;
export const fetchProductLabels = ({ state }) => state.products.productLabels;
export const getProductLabelById = ({ state }) => state.products.selectedProductLabel;
export const getIsFetchingLabel = ({ state }) => state.products.isFetchingLabel;
// subcategories
export const getEverydayOccasionsSubList = ({ state }) => state.products.everydayOccasionsSubList;
export const getEmotionsSubList = ({ state }) => state.products.emotionsSubList;
export const getSpecialOccasionsSubList = ({ state }) => state.products.specialOccasionsSubList;
export const getFestivalsSubList = ({ state }) => state.products.festivalsSubList;
// sync product
export const getIsSyncedProduct = ({ state }) => state.products.isSyncedProduct;
// costQtySum
export const getCostQtySum = ({ state }) => state.products.costQtySum;
// ranking
export const getProductRanking = ({ state }) => state.products.productRanking;
export const getProductRankingForOps = ({ state, opsId }) =>
  state?.products?.productRanking?.find((itm) => itm?.opsId === opsId) || {};
export const getValidatingState = ({ state }) => state.products.isValidating;
