import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { at, cloneDeep } from 'lodash';
import MerchandisingCategoriesService from 'services/MerchandisingCategoriesService';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { getMerchandisingCategories } from 'store/merchandisingCategories/MerchandisingCategoriesActions';
import { MultiSelectComponent, Inject, CheckBoxSelection } from '@syncfusion/ej2-react-dropdowns';
import ProductsService from 'services/ProductsService';
import { HasPermission } from 'constant/Helpers';
import { getPermissionsList } from 'store/app/AppSelectors';

const MerchandisingCategoriesModal = ({ data, isEdit, isModalOpen, setIsModalOpen }) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [attrData, setAttrData] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState(
    isEdit ? data?.mcAttributes || [] : []
  );

  const permissionsList = useSelector((state) => getPermissionsList({ state }));

  const isAuthorized = HasPermission(permissionsList, 'categories', 'mc_family');

  const [inputFields, setInputFields] = useState({
    MerchandisingKey: '',
    Slug: '',
    TitleEn: '',
    TitleAr: '',
    DescriptionEn: '',
    DescriptionAr: '',
    OrderId: 0,
    IsActive: false,
    IsFamily: false,
    familyName: '',
    familyNameAr: '',
  });
  const [isValid, setIsValid] = useState(false);

  const setValue = (name, val) => {
    const clonedVal = cloneDeep(inputFields);
    clonedVal[name] = val;
    setInputFields(clonedVal);
    handleValidation(clonedVal);
  };

  const handleSubmit = async () => {
    const submitData = {
      ...inputFields,
      ParentId: params.id,
      McLevel: params.level,
      Key: inputFields.MerchandisingKey,
      mcAttributes: selectedAttributes,
    };
    try {
      if (isEdit) {
        const editData = {
          id: data.id,
          ...submitData,
        };
        const res = await MerchandisingCategoriesService.addMerchandisingCategoriesData(editData);
        toast.success(res.data.message ?? 'Category added successfully');
      } else {
        const res = await MerchandisingCategoriesService.addMerchandisingCategoriesData(submitData);
        toast.success(res.data.message ?? 'Category updated successfully');
      }
      setIsModalOpen(false);
      if (location.search) {
        dispatch(getMerchandisingCategories(params.id ? { parentId: params.id } : null));
      } else {
        dispatch(getMerchandisingCategories(params.id ? { parentId: params.id } : null));
      }
    } catch (err) {
      errorLogger('MerchandisingCategoriesModal:handleSubmit', err);
    }
  };

  const handleValidation = (data) => {
    if (data.TitleEn === '' || data.Slug === '' || data.MerchandisingKey === '') {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setInputFields({
        MerchandisingKey: data.merchandisingKey ?? '',
        Slug: data.slug ?? '',
        TitleEn: data.titleEn ?? '',
        TitleAr: data.titleAr ?? '',
        DescriptionEn: data.descriptionEn ?? '',
        DescriptionAr: data.descriptionAr ?? '',
        OrderId: data.orderId ?? 0,
        IsActive: data.isActive ?? false,
        IsFamily: data.isFamily ?? false,
        familyName: data.familyName ?? '',
        familyNameAr: data.familyNameAr ?? '',
      });
      handleValidation({
        MerchandisingKey: data.merchandisingKey ?? '',
        Slug: data.slug ?? '',
        TitleEn: data.titleEn ?? '',
        TitleAr: data.titleAr ?? '',
        DescriptionEn: data.descriptionEn ?? '',
        DescriptionAr: data.descriptionAr ?? '',
        OrderId: data.orderId ?? 0,
        IsActive: data.isActive ?? false,
        IsFamily: data.isFamily ?? false,
        familyName: data.familyName ?? '',
        familyNameAr: data.familyNameAr ?? '',
      });
    }
  }, [data]);

  const getAttrData = async () => {
    try {
      const { data } = await ProductsService.getProductAttributesFilter();
      setAttrData(
        data?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            isMandatory: false,
          };
        })
      );
    } catch (error) {
      errorLogger('MerchandisingCategoriesModal:getAttributeData', error);
    }
  };

  const attrField = { text: 'name', value: 'id' };

  useEffect(() => {
    getAttrData();
  }, []);

  const removeAttr = (id) => setSelectedAttributes(selectedAttributes.filter((el) => el.id !== id));
  const addAttr = (newAttr) => setSelectedAttributes([...selectedAttributes, newAttr]);

  const setIsAttributeMandatory = (id, isMandatory) => {
    setSelectedAttributes(
      selectedAttributes?.map((item) => {
        if (item?.id === id) {
          return { ...item, isMandatory };
        }
        return item;
      })
    );
  };

  const setAttributeFilterable = (id, isFilterable) => {
    setSelectedAttributes(
      selectedAttributes?.map((item) => {
        if (item?.id === id) {
          return { ...item, isFilterable };
        }
        return item;
      })
    );
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen((prev) => !prev)}
        className="m-w-1000"
      >
        <ModalHeader>Merchandising Category</ModalHeader>
        <ModalBody>
          <div className="form-group col-12 card">
            <form action="#" className="card-body-row">
              <div className="mc-card-body card-body row">
                <div className="form-group col-4">
                  <label htmlFor="titleEn" className="font-weight-bold">
                    English Title *
                  </label>
                  <input
                    type="text"
                    id="titleEn"
                    value={inputFields.TitleEn}
                    onChange={(e) => setValue('TitleEn', e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="titleAr" className="font-weight-bold">
                    Arabic Title
                  </label>
                  <input
                    type="text"
                    id="titleAr"
                    value={inputFields.TitleAr}
                    onChange={(e) => setValue('TitleAr', e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="descriptionEn" className="font-weight-bold">
                    English Description
                  </label>
                  <input
                    type="text"
                    id="descriptionEn"
                    value={inputFields.DescriptionEn}
                    onChange={(e) => setValue('DescriptionEn', e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="descriptionAr" className="font-weight-bold">
                    Arabic Description
                  </label>
                  <input
                    type="text"
                    id="descriptionAr"
                    value={inputFields.DescriptionAr}
                    onChange={(e) => setValue('DescriptionAr', e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="slug" className="font-weight-bold">
                    Slug *
                  </label>
                  <input
                    type="text"
                    id="slug"
                    value={inputFields.Slug}
                    onChange={(e) => setValue('Slug', e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="merchandisingKey" className="font-weight-bold">
                    Key *
                  </label>
                  <input
                    type="text"
                    id="merchandisingKey"
                    value={inputFields.MerchandisingKey}
                    onChange={(e) => setValue('MerchandisingKey', e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="orderId" className="font-weight-bold">
                    Order Id
                  </label>
                  <input
                    type="number"
                    min={0}
                    id="orderId"
                    value={inputFields.OrderId}
                    onChange={(e) => setValue('OrderId', +e.target.value)}
                    className="form-control"
                  />
                </div>
                <div className="col-8 mc-checkbox-wrapper">
                  <div className="mc-isActive col-4">
                    <input
                      type="checkbox"
                      checked={inputFields.IsActive}
                      className="checkbox_animated"
                      onChange={(e) => setValue('IsActive', e.target.checked)}
                    />
                    <label htmlFor="checkbox" className="font-weight-bold">
                      Active
                    </label>
                  </div>
                  {isAuthorized && (
                    <div>
                      <input
                        type="checkbox"
                        checked={inputFields.IsFamily}
                        className="checkbox_animated"
                        onChange={(e) => setValue('IsFamily', e.target.checked)}
                      />
                      <label htmlFor="checkbox" className="font-weight-bold">
                        Set as Product Family
                      </label>
                    </div>
                  )}
                </div>
                {isAuthorized && (
                  <>
                    <div className="form-group col-4">
                      <div>
                        <label htmlFor="family-name" className="font-weight-bold">
                          Family Name
                        </label>
                        <input
                          id="family-name"
                          type="text"
                          className="form-control"
                          value={inputFields.familyName}
                          onChange={(e) => setValue('familyName', e.target.value)}
                          disabled={!inputFields.IsFamily}
                        />
                      </div>
                    </div>
                    <div className="form-group col-4">
                      <div>
                        <label htmlFor="family-nameAr" className="font-weight-bold">
                          Arabic Family Name
                        </label>
                        <input
                          id="family-nameAr"
                          type="text"
                          className="form-control"
                          value={inputFields.familyNameAr}
                          onChange={(e) => setValue('familyNameAr', e.target.value)}
                          disabled={!inputFields.IsFamily}
                        />
                      </div>
                    </div>

                    <div className="form-control  mc-attributes">
                      <div className="form-group col-7">
                        <label htmlFor="attrCheckbox" className="font-weight-bold">
                          Attributes
                        </label>
                        <MultiSelectComponent
                          id="attrCheckbox"
                          dataSource={attrData}
                          value={selectedAttributes?.map((el) => el?.id)}
                          filterBarPlaceholder="Search attribute"
                          fields={attrField}
                          placeholder="Select attribute"
                          mode="CheckBox"
                          showDropDownIcon={true}
                          enableSelectionOrder={false}
                          popupHeight="550px"
                          select={(e) => addAttr(e?.itemData)}
                          removed={(e) => removeAttr(e?.itemData?.id)}
                          openOnClick="false"
                        >
                          <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                      </div>

                      <div className="form-group  mandatory-attr">
                        {selectedAttributes?.length > 0 && (
                          <div className="mandatory-item">
                            <span className="attr-name">Attribute</span>
                            <span>Mandatory</span>
                            <span>Filter</span>
                          </div>
                        )}
                        {selectedAttributes?.map((attr) => (
                          <div key={attr?.id} className="mandatory-item">
                            <div className="attr-item">
                              <span>{attr?.name}</span>
                              <i className="icon-close" onClick={() => removeAttr(attr?.id)}></i>
                            </div>
                            <input
                              id={`${attr?.id}-mandatory`}
                              type="checkbox"
                              className="checkbox_animated"
                              onChange={(e) => setIsAttributeMandatory(attr?.id, e.target.checked)}
                              checked={attr?.isMandatory}
                            />
                            <input
                              id={`${attr?.id}-filterable`}
                              type="checkbox"
                              className="checkbox_animated"
                              onChange={(e) => setAttributeFilterable(attr?.id, e.target.checked)}
                              checked={attr?.isFilterable}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={!isValid} onClick={() => handleSubmit()}>
            {isEdit ? 'Update' : 'Create'}
          </Button>
          <Button color="light" onClick={() => setIsModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MerchandisingCategoriesModal;
