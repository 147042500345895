/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
// constants
import { dateFormatDDMM, HasPermission } from 'constant/Helpers';
import { cStep, paymentStatusBtn } from 'constant/Enums';
// components
import RestrictedSection from 'components/shared/RestrictedSection';
import CheckoutContent from './CheckoutContent';
import OrderPriority from 'components/orders/quickActions/DataTableComponents/OrderPriority';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
// dataGrid
import KnetHistoryDataGrid from './viewOrderDataGrids/KnetHistoryDataGrid';
import OrderInvoicesDataGrid from './viewOrderDataGrids/OrderInvoicesDataGrid';
// modals
import EditDateModal from './modals/EditDateModal';
import EditOrderCostModal from './modals/EditOrderCostModal';
import ChangeStatusModal from 'components/orders/quickActions/modals/ChangeStatusModal';
import OrderStatusModal from './modals/OrderStatusModal';
import KnetHistoryContent from './modals/KnetHistoryContent';
// import DriverOrdersModal from './modals/DriverOrdersModal';
import TripDriversListModal from 'components/delivery/trip/TripDriversListModal';
import SwapUkOpsModal from './modals/SwapUkOpsModal';
// actions
import {
  setChangeStatusModalOpen,
  setOrderDetailsPrinted,
  setToggleSupportTicketModal,
} from 'store/orderQuickActions/dataTable/DataTableAction';
import {
  setViewOrderData,
  setToggleDriverTripModal,
  setShipOnFleet,
} from 'store/viewOrder/ViewOrderActions';
// selectors
import { getPermissionsList } from 'store/app/AppSelectors';
import {
  getIsChangeStatusModalOpen,
  getupdatedCstep,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
import { getUpdatedPaymentSt, getIsDriverTripModalOpen } from 'store/viewOrder/ViewOrderSelectors';
// api
import ViewOrderService from 'services/ViewOrderService';
import QuickActionService from 'services/QuickActionService';
import RightSideModal from 'components/shared/RightSideModal';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const OrderdetailsActions = ({
  orderData,
  invoiceData,
  cartData,
  orderId,
  driverData,
  inventoryData,
}) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const [isEditDateModalOpen, setIsEditDateModalOpen] = useState(false);
  const [isKnetHistoryModalOpen, setIsKnetHistoryModalOpen] = useState(false);
  const [isOrderCostModalOpen, setIsOrderCostModalOpen] = useState(false);
  const [isOrderStatusModal, setIsOrderStatusModal] = useState(false);
  const isDriverTripModalOpen = useSelector((state) => getIsDriverTripModalOpen({ state }));
  const updatedCstep = useSelector((state) => getupdatedCstep({ state }));
  const updatedPaymentSt = useSelector((state) => getUpdatedPaymentSt({ state }));
  const isChangeStatusModalOpen = useSelector((state) => getIsChangeStatusModalOpen({ state }));
  const [isDetailsPrinted, setIsDetailsPrinted] = useState(orderData.detailsPrinted);
  const [disableSendlocationBtn, setDisableSendLocation] = useState(false);
  const [isDuplicateOrder, setIsDuplicateOrder] = useState(false);
  const [isReplaceOrder, setIsReplaceOrder] = useState(false);
  const dispatch = useDispatch();
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [isOrderInvoicesModelOpen, setIsOrderInvoicesModelOpen] = useState(false);
  const [isSwapUkOpsModalOpen, setIsSwapOpsModalOpen] = useState(false);
  const [disableMakeZiwoCallBtn, setDisableMakeZiwoCall] = useState(false);

  const orderStatus = [
    { status: 'Not_Confirmed', value: 0 },
    { status: 'Confirmed', value: 2 },
    { status: 'Cancel', value: 3 },
    { status: 'Pending_Cancellation', value: 6 },
  ];

  const orderStatusString = {
    0: 'Not_Confirmed',
    2: 'Confirmed',
    3: 'Cancelled',
    6: 'Pending_Cancellation',
    7: 'PreOrderPending',
  };

  let isQuickActions;

  useEffect(() => {
    dispatch({
      type: 'SET_UPDATED_PAYMENT',
      payload: orderData.paymentSt,
    });
    dispatch({
      type: 'SET_UPDATED_ORDER_STATUS',
      payload: orderData.status,
    });
  }, []);

  const handleSubmitValue = async () => {
    if (orderData.status === 0) {
      toast.error('Order is NOT confirmed');
    } else {
      let bodyData;
      try {
        if (updatedPaymentSt === 0) {
          bodyData = {
            value: 1,
          };
          await ViewOrderService.markAsPaid(bodyData, orderId);
          dispatch({
            type: 'SET_UPDATED_PAYMENT',
            payload: 1,
          });
          toast.success('Payment status changed successfully');
        } else {
          bodyData = {
            value: 0,
          };
          await ViewOrderService.markAsPaid(bodyData, orderId);
          dispatch({
            type: 'SET_UPDATED_PAYMENT',
            payload: 0,
          });
          toast.success('Payment status changed successfully');
        }
      } catch (err) {
        errorLogger('OrderdetailsActions:handleSubmitValue', err);
        toast.success('Something went wrong');
      }
    }
  };

  const handleUpdateOrderStatus = async (e) => {
    if (+e.target.value === 3) {
      setIsOrderStatusModal(true);
    } else {
      const dataBody = {
        value: e.target.value,
      };
      try {
        await ViewOrderService.updateOrderStatus(dataBody, orderId);
        setTimeout(() => {
          dispatch(setViewOrderData(orderId));
          toast.success('Order status updated successfully!');
          if (dataBody.value === 6) {
            dispatch(setToggleSupportTicketModal());
          }
        }, 1500);
      } catch (err) {
        errorLogger('OrderdetailsActions:handleUpdateOrderStatus', err);
        toast.error(err.response.data.message);
      }
    }
  };

  const handleDetailsPrinted = () => {
    try {
      dispatch(
        setOrderDetailsPrinted(orderId, orderData.detailsPrinted, false, (isQuickActions = false))
      );
      setIsDetailsPrinted((prev) => !prev);
    } catch (err) {
      // console.log(err);
    }
  };

  const sendLocationLink = async () => {
    const params = {
      ids: orderId,
    };
    setDisableSendLocation(true);
    try {
      const res = await QuickActionService.sendLocationLink(params);
      setDisableSendLocation(false);
      toast.success(res.data.message);
    } catch (err) {
      errorLogger('OrderdetailsActions:sendLocationLink', err);
      toast.error(err.response.data.message);
      setDisableSendLocation(false);
    }
  };

  const makeZiwoCall = async () => {
    const params = {
      ids: orderId,
    };
    setDisableMakeZiwoCall(true);
    try {
      const res = await QuickActionService.makeZiwoCall(params);
      setDisableMakeZiwoCall(false);
      toast.success(res.data?.message ?? 'Ziwo call was successful');
    } catch (err) {
      errorLogger('OrderdetailsActions:makeZiwoCall', err);
      toast.error(err.response?.data?.message ?? 'Failed to make ziwo call');
      setDisableMakeZiwoCall(false);
    }
  };

  const handleDuplicateOrder = () => {
    setIsEditDateModalOpen((prev) => !prev);
    setIsDuplicateOrder(true);
  };

  const handleReplaceOrder = () => {
    setIsEditDateModalOpen((prev) => !prev);
    setIsReplaceOrder(true);
  };

  const handleShipOnFleet = () => {
    dispatch(setShipOnFleet(orderId));
  };

  return (
    <Fragment>
      {isEditDateModalOpen && (
        <AppErrorBoundary>
          <EditDateModal
            isEditDateModalOpen={isEditDateModalOpen}
            setIsEditDateModalOpen={setIsEditDateModalOpen}
            cartData={cartData}
            orderId={orderId}
            deliveryDate={orderData.deliveryDate}
            isDuplicateOrder={isDuplicateOrder}
            setIsDuplicateOrder={setIsDuplicateOrder}
            setIsReplaceOrder={setIsReplaceOrder}
            isReplaceOrder={isReplaceOrder}
            orderData={orderData}
          />
        </AppErrorBoundary>
      )}
      {isOrderStatusModal && (
        <OrderStatusModal
          inventoryData={inventoryData}
          setIsOrderStatusModalOpen={setIsOrderStatusModal}
          modalStatus={isOrderStatusModal}
          orderId={orderId}
        />
      )}
      {isChangeStatusModalOpen && <ChangeStatusModal order={orderData} />}
      {isKnetHistoryModalOpen && (
        <AppErrorBoundary>
          <RightSideModal
            isPaneOpen={isKnetHistoryModalOpen}
            setIsPaneOpen={setIsKnetHistoryModalOpen}
            direction="right"
          >
            <KnetHistoryContent
              DataGridComponent={<KnetHistoryDataGrid orderId={orderId} />}
              header="Knet transactions' history"
            />
          </RightSideModal>
        </AppErrorBoundary>
      )}
      {isOrderCostModalOpen && (
        <EditOrderCostModal
          orderId={orderId}
          isOrderCostModalOpen={isOrderCostModalOpen}
          setIsOrderCostModalOpen={setIsOrderCostModalOpen}
          orderData={orderData}
        />
      )}
      {isDriverTripModalOpen && <TripDriversListModal />}
      {isSideModalOpen && (
        <AppErrorBoundary>
          <RightSideModal
            isPaneOpen={isSideModalOpen}
            setIsPaneOpen={setIsSideModalOpen}
            direction="right"
          >
            <CheckoutContent orderId={orderId} orderData={orderData} />
          </RightSideModal>
        </AppErrorBoundary>
      )}
      {isOrderInvoicesModelOpen && (
        <AppErrorBoundary>
          <RightSideModal
            isPaneOpen={isOrderInvoicesModelOpen}
            setIsPaneOpen={setIsOrderInvoicesModelOpen}
            direction="right"
            width={'80%'}
          >
            <KnetHistoryContent
              DataGridComponent={<OrderInvoicesDataGrid orderId={orderId} />}
              header="Order Invoices List"
            />
          </RightSideModal>
        </AppErrorBoundary>
      )}
      {isSwapUkOpsModalOpen && (
        <SwapUkOpsModal
          orderId={orderId}
          isSwapUkOpsModalOpen={isSwapUkOpsModalOpen}
          setIsSwapOpsModalOpen={setIsSwapOpsModalOpen}
          isNwDelivery={orderData?.nwDelivery}
          isPremium={orderData?.productionLine}
          opsId={orderData?.opsID}
        />
      )}
      <div className="card">
        <div className="card-body btn-showcase order-details-actions">
          <div>
            <RestrictedSection page="order_details" action="delivery_date">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setIsEditDateModalOpen((prev) => !prev)}
                disabled={orderData.cStep === 4}
              >
                <i className="fa fa-calendar"></i> {dateFormatDDMM(orderData.deliveryDate)}{' '}
                {orderData.deliveryTimeName}
              </button>
            </RestrictedSection>

            <RestrictedSection page="order_details" action="payment_status">
              <button
                type="button"
                className={`btn ${updatedPaymentSt === 0 ? 'btn-danger' : 'btn-success'} `}
                onClick={handleSubmitValue}
                // disabled={orderData.cStep === 4}
              >
                <i className="fa fa-dollar"></i> {paymentStatusBtn[updatedPaymentSt]}
              </button>
            </RestrictedSection>
            <RestrictedSection page="order_details" action="cstep_status">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  dispatch(
                    setChangeStatusModalOpen({
                      orderId,
                      orderCstep: orderData.cStep,
                    })
                  );
                }}
                disabled={orderData.cStep === 4}
              >
                <i className="fa fa-shopping-cart"></i>{' '}
                {cStep[updatedCstep] ? cStep[updatedCstep] : cStep[orderData.cStep]}
              </button>
            </RestrictedSection>
            <RestrictedSection page="order_details" action="order_status">
              <ButtonGroup className="order-status-dropdown" vertical>
                <UncontrolledDropdown>
                  <DropdownToggle
                    disabled={
                      orderData.status === 3 ||
                      (orderData.cStep === 4 &&
                        orderData.status !== 6 &&
                        !HasPermission(permissionsList, 'order_details', 'force_cancel'))
                    }
                    color="primary"
                    caret
                    className="b-r-0"
                  >
                    <i className="fa fa-shopping-cart"></i> {orderStatusString[orderData.status]}
                  </DropdownToggle>
                  <DropdownMenu value={orderData.status} onClick={handleUpdateOrderStatus}>
                    {orderData.status === 2 || orderData.status === 6 || orderData.status === 7 ? (
                      orderStatus
                        .filter((order) => order.value !== orderData.status && order.value !== 0)
                        .map((item, i) => (
                          <RestrictedSection
                            key={i}
                            page="order_details"
                            action={item.value === 3 ? 'cancel_order' : 'view'}
                          >
                            {orderData.isPreOrder && item.value == 2 ? (
                              ''
                            ) : (
                              <DropdownItem value={item.value}>{item.status}</DropdownItem>
                            )}
                          </RestrictedSection>
                        ))
                    ) : orderData.status === 3 ? (
                      ''
                    ) : orderData.status === 0 ? (
                      <DropdownItem value="2">Confirmed</DropdownItem>
                    ) : (
                      ''
                    )}
                    {orderData?.isPreOrder &&
                    !orderData?.isPreOrderConfirmed &&
                    orderData.status === 6 ? (
                      <DropdownItem value="7">PreOrderPending</DropdownItem>
                    ) : (
                      ''
                    )}
                    {orderData.isPreOrder &&
                    orderData?.isPreOrderConfirmed &&
                    orderData.status === 6 ? (
                      <DropdownItem value="2">Confirmed</DropdownItem>
                    ) : (
                      ''
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            </RestrictedSection>
            <RestrictedSection page="order_details" action="duplicate_order">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleDuplicateOrder}
                disabled={orderData.orderChanged != null}
              >
                <i className="fa fa-book"></i> Duplicate
              </button>
            </RestrictedSection>
            <RestrictedSection page="order_details" action="duplicate_order">
              <button
                type="button"
                className="btn btn-info"
                onClick={handleReplaceOrder}
                disabled={orderData.orderChanged != null}
              >
                <i className="fa fa-book"></i> Replace
              </button>
            </RestrictedSection>
            <button
              onClick={sendLocationLink}
              type="button"
              className="btn btn-success "
              disabled={disableSendlocationBtn || orderData.cStep === 4}
            >
              <i className="fa fa-whatsapp"></i> Send location link
            </button>
            <button
              onClick={makeZiwoCall}
              type="button"
              className="btn btn-success "
              disabled={disableMakeZiwoCallBtn || orderData.cStep === 4}
            >
              <i className="fa fa-headphones"></i> Ziwo Call
            </button>
            <RestrictedSection page="order_details" action="checkout">
              <button
                onClick={() => setIsSideModalOpen((prev) => !prev)}
                type="button"
                className="btn btn-info"
              >
                <i className="fa fa-cc-visa"></i> Checkout Details
              </button>
            </RestrictedSection>
            {orderData.paymentMethod === 1 && (
              <RestrictedSection page="order_details" action="knet_history">
                <button
                  onClick={() => setIsKnetHistoryModalOpen((prev) => !prev)}
                  type="button"
                  className="btn btn-info"
                >
                  <i className="fa fa-credit-card-alt"></i> Knet transactions
                </button>
              </RestrictedSection>
            )}
            <RestrictedSection page="order_details" action="edit_order_cost">
              <button
                onClick={() => setIsOrderCostModalOpen((prev) => !prev)}
                type="button"
                className="btn btn-success"
                disabled={orderData.cStep === 4}
              >
                <i className="fa fa-dollar"></i> Change order cost
              </button>
            </RestrictedSection>
            {invoiceData.invoice?.filePath ? (
              <RestrictedSection page="order_details" action="invoice">
                <button
                  onClick={() => {
                    window.open(invoiceData.invoice.filePath, '_blank');
                  }}
                  type="button"
                  className="btn btn-info"
                >
                  <i className="fa fa-download"></i> Download Invoice
                </button>
              </RestrictedSection>
            ) : null}
            {invoiceData.invoice?.link && orderData.status === 2 && (
              <RestrictedSection page="order_details" action="invoice">
                <button
                  onClick={() => {
                    window.open(invoiceData.invoice.link, '_blank');
                  }}
                  type="button"
                  className="btn btn-info"
                >
                  <i className="fa fa-file-text"></i> View Invoice
                </button>
              </RestrictedSection>
            )}
            {orderData.status === 2 && (
              <RestrictedSection page="order_details" action="order_invoices">
                <button
                  onClick={() => setIsOrderInvoicesModelOpen((prev) => !prev)}
                  type="button"
                  className="btn btn-success"
                >
                  <i className="fa fa-file-text"></i> Order Invoices
                </button>
              </RestrictedSection>
            )}
            <RestrictedSection page="order_details" action="ship_onfleet">
              <ButtonGroup className="order-status-dropdown" vertical>
                <UncontrolledDropdown>
                  <DropdownToggle
                    disabled={orderData.cStep === 4 || orderData.status === 7}
                    color="info"
                    caret
                    className="b-r-0"
                  >
                    Logistics
                  </DropdownToggle>
                  <DropdownMenu onClick={handleShipOnFleet}>
                    <DropdownItem>Ship onFleet</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            </RestrictedSection>
            {orderData.opsID === 10 && (
              <RestrictedSection page="order_details" action="nationwide_delivery">
                <button
                  onClick={() => setIsSwapOpsModalOpen((prev) => !prev)}
                  type="button"
                  className="btn btn-success"
                >
                  <i className="fa fa-calendar"></i>{' '}
                  {orderData.nwDelivery ? 'Swap to London' : 'Swap to Nationwide'}
                </button>
              </RestrictedSection>
            )}
            <RestrictedSection page="order_details" action="edit_priority">
              {orderData.status !== 1 && orderData.status !== 3 && (
                <OrderPriority
                  orderId={orderData.id}
                  priorityId={orderData.orderPriority}
                  orderCstep={orderData.cStep}
                />
              )}
            </RestrictedSection>
          </div>
          <div className="order-actions-icons-wrapper">
            <img
              alt="payment"
              className="payment-icon"
              src={orderData.paymentdetails.paymentIcon}
            />
            <img alt="channel" className="channel-icon" src={orderData.channelIcon} />
            <button
              onClick={handleDetailsPrinted}
              className={`btn ${
                isDetailsPrinted ? 'btn-success' : 'btn-danger'
              } printed-status-btn`}
              disabled={orderData.cStep === 4}
            >
              {isDetailsPrinted ? 'Printed' : 'Not Printed'}
            </button>

            {driverData.length !== 0 && (
              <div className="order-action-driver-wrapper">
                <p
                  className="order-action-driver-name"
                  onClick={() => {
                    dispatch(
                      setToggleDriverTripModal({
                        tripId: driverData.slice(-1).pop().tripId,
                        driverId: driverData.slice(-1).pop().driverID,
                      })
                    );
                  }}
                >
                  {driverData.slice(-1).pop().name}
                </p>
                <p
                  className="order-action-driver-phone"
                  onClick={() => {
                    navigator.clipboard.writeText(driverData.slice(-1).pop().phone);
                    toast.success('Copied');
                  }}
                >
                  {driverData.slice(-1).pop().phone}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderdetailsActions;
