import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  Page,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { dateFormatDDMM } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const ProductWiseDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="inventory-table">
      <div className="control-pane">
        <div className="control-section">
          <GridComponent
            dataSource={data}
            allowPaging={true}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            allowPdfExport={true}
            excelQueryCellInfo={excelQueryCellInfo}
            pdfQueryCellInfo={excelQueryCellInfo}
            ref={ref}
            toolbarClick={toolbarClick}
            showColumnChooser={true}
            allowTextWrap={true}
            rowHeight={80}
            pageSettings={{ pageSizes: true, pageSize: 20 }}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText="Order#"
                field="order.id"
                template={(rowData) => <span>{rowData.order.id}</span>}
                customExcelTemplate={(rowData) => rowData.order.id}
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="City"
                field="order.nickName"
                template={(rowData) => <span>{rowData.order.nickName}</span>}
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Image"
                template={(rowData) => (
                  <img
                    alt={rowData.productSKU}
                    src={rowData.cart.productImageFullPath}
                    className="w-60p"
                  />
                )}
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Delivery Date"
                template={(rowData) =>
                  rowData.order.deliveryDate && dateFormatDDMM(rowData.order.deliveryDate)
                }
                customExcelTemplate={(rowData) =>
                  rowData.order.deliveryDate && dateFormatDDMM(rowData.order.deliveryDate)
                }
                width={120}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Total"
                template={(rowData) => <span>{rowData.order.grandTotal}</span>}
                customExcelTemplate={(rowData) => rowData.order.grandTotal}
                width={80}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Product SKU"
                field="cart.productSKU"
                template={(rowData) => <span>{rowData.cart.productSKU}</span>}
                customExcelTemplate={(rowData) => rowData.cart.productSKU}
                width={100}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Qty"
                template={(rowData) => <span>{rowData.cart.qty}</span>}
                customExcelTemplate={(rowData) => rowData.cart.qty}
                width={80}
                textAlign="Center"
              />

              <ColumnDirective
                headerText="Price"
                template={(rowData) => <span>{rowData.cart.price}</span>}
                customExcelTemplate={(rowData) => rowData.cart.price}
                width={80}
                textAlign="Center"
              />
              <ColumnDirective
                headerText="Cost"
                template={(rowData) => (
                  <span>
                    {rowData.cart.cost &&
                      rowData.cart.cost.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                  </span>
                )}
                customExcelTemplate={(rowData) =>
                  rowData.cart.cost &&
                  rowData.cart.cost.toFixed(opsCenter?.country?.decimalNumber ?? 3)
                }
                width={80}
                textAlign="Center"
              />

              <ColumnDirective
                headerText="Margin"
                template={(rowData) => (
                  <span>
                    {rowData.cart.margin &&
                      rowData.cart.margin.toFixed(opsCenter?.country?.decimalNumber ?? 3)}
                  </span>
                )}
                customExcelTemplate={(rowData) =>
                  rowData.cart.margin &&
                  rowData.cart.margin.toFixed(opsCenter?.country?.decimalNumber ?? 3)
                }
                width={100}
                textAlign="Center"
              />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProductWiseDataGrid);
