import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import FinancialOrdersDataGrid from 'components/reports/orders/FinancialOrdersDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import DJHPagination from 'components/shared/Pagination';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  convertToUnix,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
// api
import OrdersReportService from 'services/reportsService/OrdersReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const FinancialOrdersReportPage = () => {
  const [data, setData] = useState([]);
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const [currentPage, setCurrentPage] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [rowCount, setRowCount] = useState('');
  const [inputValues, setInputValues] = useState({
    fromDate: convertToUnix(),
    toDate: convertToUnix(),
    countryId: -1,
    page: 1,
  });
  const [countriesList, setCountriesList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const countriesData = [];
    opsCenters.forEach((ops) => {
      if (
        countriesData.filter((country) => {
          return country.id === ops.country.id;
        }).length === 0
      ) {
        countriesData.push(ops.country);
      }
    });
    setCountriesList(countriesData);
  }, [opsCenters]);

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await OrdersReportService.getFinancialOrdersReport(params);
      setData(res.data.data);
      setCurrentPage(res.data.currentPage);
      setPageSize(res.data.pageSize);
      setRowCount(res.data.rowCount);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Financial Orders Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  const fetchCountriesList = () => {
    try {
      dispatch(setSignInSuccess());
    } catch (err) {
      errorLogger('Financial Orders Report Page Fetch Countries List Error:', err);
      toast.error('Failed to get coutries list!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
    fetchCountriesList();
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: '/report/FinanicalOrdersReport',
      search: queryString,
    });
    fetchData(inputValues);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: '/report/FinanicalOrdersReport',
      search: queryString,
    });
    fetchData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        country
        countriesList={countriesList}
        title="Finanical Orders"
      />
      <FinancialOrdersDataGrid data={data} inputValues={inputValues} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </div>
  );
};

export default FinancialOrdersReportPage;
