import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
// import CustomSelect from 'components/shared/CustomSelect';
import LoadingIcon from 'components/common/LoadingIcon';
import RestrictedSection from 'components/shared/RestrictedSection';
import { errorLogger } from 'datadog/DDUtils';
// api
import QuickActionService from 'services/QuickActionService';
// actions
import {
  updateOrderPriority,
  updatePriority,
} from 'store/orderQuickActions/dataTable/DataTableAction';
import { updateViewOrderData } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getViewOrderDetails } from 'store/viewOrder/ViewOrderSelectors';

const OrderPriority = ({ orderId, priorityId, isDisable, isQuickAction, orderCstep }) => {
  const [priority, setPriority] = useState(priorityId);
  const [isLoading, setIsLoading] = useState(false);
  const orderData = useSelector((state) => getViewOrderDetails({ state }));
  const dispatch = useDispatch();

  const handleChange = async () => {
    const data = {
      OrderId: orderId,
      OrderPriority: priority === 1 ? 0 : 1,
    };
    setIsLoading(true);
    try {
      const res = await QuickActionService.updatePriority(data);
      setPriority(priority === 1 ? 0 : 1);
      dispatch(updatePriority(priority === 1 ? 0 : 1));
      if (isQuickAction) {
        dispatch(updateOrderPriority(orderId, priority === 1 ? 0 : 1));
      } else {
        const newOrder = cloneDeep(orderData);
        newOrder.orderPriority = priority === 1 ? 0 : 1;
        dispatch(updateViewOrderData(newOrder));
      }
      setIsLoading(false);
      toast.success(res.data.message);
    } catch (err) {
      errorLogger('OrderPriority:handleChange', err);
      setIsLoading(false);
      toast.error('Changing order priority has failed');
    }
  };

  const handlePriorityClass = (isQuickAction, priority) => {
    if (isQuickAction) {
      if (priority === 1) {
        return 'btn-light-danger';
      } else {
        return 'btn-light-warning';
      }
    } else {
      if (priority === 1) {
        return 'btn-danger';
      } else {
        return 'btn-warning';
      }
    }
  };

  return (
    <div
      className={`form-group col-12 ${
        isQuickAction ? 'text-center' : 'vieworder-priority'
      } filter-select priority-select priority-${priority}`}
    >
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <RestrictedSection page="quick_actions" action="edit_priority">
          {/* <CustomSelect
                        listData={[
                            {name: 'high', value: 1, iconCss: 'priority-double-up'},
                            // {name: 'medium', value: 2, iconCss: 'priority-up'},
                            {name: 'normal', value: 0, iconCss: 'priority-med'},
                            // {name: 'low', value: 3, iconCss: 'priority-low'},
                        ]}
                        handleChange={handleChange}
                        placeholder={"Select a priority"}
                        textPropName={"name"}
                        valuePropName={"value"}
                        iconCss={"iconCss"}
                        selectedValue={priority}
                        disabled={isDisable}
                    /> */}
          <button
            disabled={isDisable || orderCstep === 4}
            onClick={() => handleChange()}
            className={`btn priority-btn ${handlePriorityClass(isQuickAction, priority)}`}
          >
            {priority === 1 ? 'High' : 'Normal'}
          </button>
        </RestrictedSection>
      )}
    </div>
  );
};

export default OrderPriority;
