import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
// actions
import { setDriverData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DriverModal = ({ setIsDriverModalOpen, isDriverModalOpen, orderId }) => {
  const [searchData, setSearchData] = useState('');
  const [driverId, setdriverId] = useState('');
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const dispatch = useDispatch();

  const delayedHandleChange = debounce(async (keyword) => {
    try {
      const res = await ViewOrderService.getSearchDriver(keyword);
      setSearchData(res.data);
    } catch (e) {
      errorLogger('DriverModal:delayedHandleChange', e);
      toast.error('No results match this keyword');
    }
  }, 1000);

  const handleSearch = async (keyword) => {
    if (keyword) {
      delayedHandleChange(keyword);
    }
  };

  const selectDriver = (e, id) => {
    for (let i = 0; i <= searchData.length - 1; i++) {
      document.getElementById('check' + i).checked = false;
    }
    document.getElementById('check' + id).checked = true;
    setdriverId(e.target.value);
  };

  const handleAddDriver = async () => {
    setDisableSaveBtn(true);
    try {
      const res = await ViewOrderService.validateOrderBeforeAssign(orderId);
      if (res?.data) {
        const dataBody = res?.data;
        await ViewOrderService.addOrderToDriver(dataBody, driverId);
        setTimeout(() => {
          dispatch(setDriverData(orderId));
          setDisableSaveBtn(false);
          setIsDriverModalOpen((prev) => !prev);
          toast.success('Order assigned');
        }, 2000);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      errorLogger('DriverModal:handleAddDriver', err);
      setIsDriverModalOpen((prev) => !prev);
      setDisableSaveBtn(false);
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isDriverModalOpen} toggle={() => setIsDriverModalOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsDriverModalOpen((prev) => !prev)}>Drivers</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Search for Drivers
              </label>
              <input
                onChange={(e) => handleSearch(e.target.value)}
                defaultValue=""
                className="form-control"
                id="message-text"
                placeholder="Name or phone number"
              ></input>

              <div className="ProfileCard u-cf">
                <div className="table-responsive shopping-table ">
                  <table className="table table-bordernone driver-modal-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchData.length > 0 &&
                        searchData.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.name}</td>
                              <td>{item.phone}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  value={item.id}
                                  id={`check${i}`}
                                  onClick={(e) => selectDriver(e, i)}
                                ></input>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={disableSaveBtn} onClick={handleAddDriver} color="primary">
            Save
          </Button>
          <Button color="light" onClick={() => setIsDriverModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DriverModal;
