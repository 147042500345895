import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { setInventorySuppliersData } from 'store/inventory/InventoryActions';
// api
import InventoryService from 'services/InventoryService';

const AddSupplierModal = ({
  isAddSupplierModalOpen,
  setIsAddSupplierModalOpen,
  clickedSupplier,
}) => {
  const [companyName, setCompanyName] = useState(
    clickedSupplier && clickedSupplier.companyName ? clickedSupplier.companyName : ''
  );
  const [phone, setPhone] = useState(
    clickedSupplier && clickedSupplier.phone ? clickedSupplier.phone : ''
  );
  const [email, setEmail] = useState(
    clickedSupplier && clickedSupplier.email ? clickedSupplier.email : ''
  );
  const [address, setAddress] = useState(
    clickedSupplier && clickedSupplier.address ? clickedSupplier.address : ''
  );
  const [active, setActive] = useState(
    clickedSupplier && clickedSupplier.isActive ? clickedSupplier.isActive : false
  );
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const dataBody = {
      CompanyName: companyName,
      Phone: phone,
      Email: email,
      Address: address,
      IsActive: active,
    };
    setDisableBtn(true);
    try {
      if (clickedSupplier) {
        await InventoryService.updateInventorySupplier(dataBody, clickedSupplier.id);
        toast.success('supplier data updated successfully!');
      } else {
        await InventoryService.addInventorySupplier(dataBody);
        toast.success('supplier data added successfully!');
      }
      setTimeout(() => {
        dispatch(setInventorySuppliersData());
        setIsAddSupplierModalOpen((prev) => !prev);
      }, 1500);
    } catch (e) {
      errorLogger('AddSupplierModal:handleSubmit', e);
      toast.error('Failed to add inventory supplier!');
      setIsAddSupplierModalOpen((prev) => !prev);
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAddSupplierModalOpen}
        toggle={() => setIsAddSupplierModalOpen((prev) => !prev)}
        className="m-w-600"
      >
        <ModalHeader toggle={() => setIsAddSupplierModalOpen((prev) => !prev)}>
          {clickedSupplier ? 'Edit Supplier' : 'Assign New Supplier'}
        </ModalHeader>
        <ModalBody>
          <form className="row">
            <div className="col-md-6 form-group">
              <label className="col-form-label" htmlFor="recipient-name">
                Company Name
              </label>
              <input
                onChange={(e) => setCompanyName(e.target.value)}
                className="form-control"
                type="text"
                placeholder="company name"
                value={companyName}
              />
            </div>
            <div className="col-md-6 form-group">
              <label className="col-form-label" htmlFor="recipient-name">
                Phone
              </label>
              <input
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                type="text"
                placeholder="phone"
                value={phone}
              />
            </div>
            <div className="col-md-6 form-group">
              <label className="col-form-label" htmlFor="recipient-name">
                Email
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                type="text"
                placeholder="email"
                value={email}
              />
            </div>

            <div className="col-md-6 form-group">
              <label className="col-form-label" htmlFor="recipient-name">
                Address
              </label>
              <input
                onChange={(e) => setAddress(e.target.value)}
                className="form-control"
                type="text"
                placeholder="address"
                value={address}
              />
            </div>
            <div className="col-md-6 form-group">
              <input
                onChange={() => setActive((prev) => !prev)}
                className="m-r-5"
                type="checkbox"
                checked={active}
              ></input>
              <label className="col-form-label" htmlFor="recipient-name">
                Active
              </label>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!companyName || !phone || !email || !address || disableBtn}
            onClick={handleSubmit}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={() => setIsAddSupplierModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddSupplierModal;
