import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CustomSelect from 'components/shared/CustomSelect';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
// actions
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';

const CatalogFilter = ({
  handleSubmit,
  inputValues,
  setInputValues,
  setQty,
  qty,
  categoriesFilterList,
  isUncategorizedMerch,
}) => {
  const [accActive, setAccActive] = useState(false);
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const newOpsCenters = opsCenters.filter((el) => el.accountType === 0);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setSignInSuccess());
  }, []);

  const submitForm = (e) => {
    if (e.isComposing || e.keyCode === 229) {
      return;
    }
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const handleChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[e.target.name] = e.target?.value;
    setInputValues(updatedParams);
  };

  const handleProductionLineChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.productionline = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handleProductDefinitionChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.productdefination = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handleTypeChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.type = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handleStatusChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.status = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handleStockChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.stock = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handleOriginOpsChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.originOps = e.itemData?.id;
    setInputValues(updatedParams);
  };

  const handleCategoriesChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.categories = e.value?.join(',');
    setInputValues(updatedParams);
  };

  const fields = { text: 'name', value: 'id' };

  return (
    <div className="card">
      <Accordion preExpanded={location.search ? ['a'] : []} allowZeroExpanded>
        <AccordionItem uuid="a">
          <AccordionItemHeading onClick={() => setAccActive((prev) => !prev)}>
            <AccordionItemButton>
              <div className="card-header between-flex pointer p-18">
                <h4>Filter</h4>
                {accActive ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form onKeyDown={submitForm} className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-xl-3">
                    <div className="form-group">
                      <label className="bold-title" htmlFor="catalog-name">
                        Product name, SKU
                      </label>
                      <input
                        id="catalog-name"
                        className="form-control"
                        type="text"
                        placeholder="Search by product name, SKU"
                        value={inputValues.key}
                        onChange={handleChange}
                        name="key"
                      />
                    </div>
                  </div>
                  {!isUncategorizedMerch && (
                    <>
                      <div className="col-md-6 col-xl-3">
                        <div className="form-group">
                          <label className="bold-title" htmlFor="catalog-qty-less">
                            Qty less than
                          </label>
                          <input
                            id="catalog-qty-less"
                            className="form-control"
                            type="number"
                            placeholder="Search by quantity"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                            name="qty"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3">
                        <div className="form-group">
                          <label className="bold-title" htmlFor="catalog-price-less">
                            Price less than
                          </label>
                          <input
                            id="catalog-price-less"
                            className="form-control"
                            type="number"
                            placeholder="Search by price"
                            value={inputValues.priceTo}
                            onChange={handleChange}
                            name="priceTo"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-md-6 col-xl-3">
                    <div className="form-group filter-select">
                      <label className="bold-title" htmlFor="catalog-production-line">
                        Production Line
                      </label>
                      <CustomSelect
                        id="catalog-production-line"
                        listData={[
                          { id: 0, name: 'Classic' },
                          { id: 1, name: 'Premium' },
                        ]}
                        handleChange={handleProductionLineChange}
                        placeholder="Select production line"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={inputValues.productionline}
                      />
                    </div>
                  </div>
                  {!isUncategorizedMerch && (
                    <>
                      <div className="col-md-6 col-xl-3">
                        <div className="form-group filter-select">
                          <label className="bold-title" htmlFor="catalog-defination">
                            Product Definition
                          </label>
                          <CustomSelect
                            id="catalog-defination"
                            listData={[
                              { id: -1, name: 'Any' },
                              { id: 0, name: 'Undefined' },
                              { id: 1, name: 'Central' },
                              { id: 2, name: 'Uncentral' },
                            ]}
                            handleChange={handleProductDefinitionChange}
                            placeholder="Select a product definition"
                            textPropName="name"
                            valuePropName="id"
                            selectedValue={inputValues.productdefination}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3">
                        <div className="form-group filter-select">
                          <label className="bold-title" htmlFor="catalog-type">
                            Product Type
                          </label>
                          <CustomSelect
                            id="catalog-type"
                            listData={[
                              { id: 0, name: 'Product' },
                              { id: 1, name: 'Designer' },
                              { id: 2, name: 'Brands' },
                              { id: 3, name: 'Subscriptions' },
                              { id: 4, name: 'AdsOn' },
                              { id: 5, name: 'Basket' },
                              { id: 6, name: 'Custom Items' },
                              { id: 7, name: 'Celebrity' },
                              { id: 8, name: 'Product with basket' },
                              { id: 9, name: 'Bundle' },
                              { id: 10, name: 'Product with variants' },
                              { id: 11, name: 'Variant' },
                            ]}
                            handleChange={handleTypeChange}
                            placeholder="Select a product type"
                            textPropName="name"
                            valuePropName="id"
                            selectedValue={inputValues.type}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-xl-3">
                        <div className="form-group filter-select">
                          <label className="bold-title" htmlFor="catalog-status">
                            Status
                          </label>
                          <CustomSelect
                            id="catalog-status"
                            listData={[
                              { id: 0, name: 'Draft' },
                              { id: 1, name: 'Published' },
                              { id: 2, name: 'Archieved' },
                            ]}
                            handleChange={handleStatusChange}
                            placeholder="Select a product status"
                            textPropName="name"
                            valuePropName="id"
                            selectedValue={inputValues.status}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-xl-3">
                        <div className="form-group filter-select">
                          <label className="bold-title" htmlFor="catalog-stock">
                            Stock
                          </label>
                          <CustomSelect
                            id="catalog-stock"
                            listData={[
                              { id: -1, name: 'All' },
                              { id: 1, name: 'InStock' },
                              { id: 0, name: 'OutStock' },
                            ]}
                            handleChange={handleStockChange}
                            placeholder="Select a stock"
                            textPropName="name"
                            valuePropName="id"
                            selectedValue={inputValues.stock}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-xl-3">
                        <div className="form-group filter-select">
                          <label className="bold-title" htmlFor="catalog-origin">
                            Origin Ops
                          </label>
                          <CustomSelect
                            id="catalog-origin"
                            listData={newOpsCenters}
                            handleChange={handleOriginOpsChange}
                            placeholder="Select origin ops"
                            textPropName="nickName"
                            valuePropName="id"
                            selectedValue={inputValues.originOps}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3">
                        <div className="form-group">
                          <label className="bold-title" htmlFor="catalog-barcode">
                            Inventory Barcode
                          </label>
                          <input
                            id="catalog-barcode"
                            className="form-control"
                            type="text"
                            placeholder="Search by inventory barcode"
                            value={inputValues.inventoryBarcode}
                            onChange={handleChange}
                            name="inventoryBarcode"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3">
                        <label className="bold-title" htmlFor="defaultelement">
                          Categories
                        </label>
                        <div className="">
                          <div id="multisection" className="control-section filter-select p-l-6">
                            <div id="multidefault">
                              <div className="control-styles">
                                <div>
                                  <AppErrorBoundary>
                                    {categoriesFilterList.length > 0 && (
                                      <MultiSelectComponent
                                        change={(e) => handleCategoriesChange(e)}
                                        id="defaultelement"
                                        dataSource={categoriesFilterList}
                                        mode="Box"
                                        fields={fields}
                                        placeholder="Select categories"
                                        showDropDownIcon={true}
                                      />
                                    )}
                                  </AppErrorBoundary>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary mr-1" type="button" onClick={handleSubmit}>
                    Submit
                  </button>
                  <input className="btn btn-light" type="reset" value="Cancel" />
                </div>
              </div>
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CatalogFilter;
