// lodash
import debounce from 'lodash/debounce';
// toast
import { toast } from 'react-toastify';
// store
import { store } from 'store';
// services
import ViewOrderService from 'services/ViewOrderService';
// selectors
import {
  getAddInventoryBarcode,
  getAddInventoryProduct,
  getAddInventoryQuantity,
  getAddInventorySearch,
} from '../selectors/AddInventorySelectors';
// action types
import {
  CLOSE_ADD_INVENTORY_MODAL,
  OPEN_ADD_INVENTORY_MODAL,
  RESET_ADD_INVENTORY_FORM,
  SET_ADD_INVENTORY_BARCODE_PROPERTIES,
  SET_ADD_INVENTORY_IS_LOADING_FALSE,
  SET_ADD_INVENTORY_IS_LOADING_TRUE,
  SET_ADD_INVENTORY_IS_SEARCHING_FALSE,
  SET_ADD_INVENTORY_IS_SEARCHING_TRUE,
  SET_ADD_INVENTORY_PRODUCT_PROPERTIES,
  SET_ADD_INVENTORY_QUANTITY_PROPERTIES,
  SET_ADD_INVENTORY_SEARCH_LIST,
  SET_ADD_INVENTORY_SEARCH_PROPERTIES,
} from '../AddInventoryActionTypes';
// actions
import { setCartDetails } from 'store/viewOrder/ViewOrderActions';
import { errorLogger } from 'datadog/DDUtils';

export const openAddInventoryModal = () => ({ type: OPEN_ADD_INVENTORY_MODAL });

export const closeAddInventoryModal = () => (dispatch) => {
  dispatch({ type: CLOSE_ADD_INVENTORY_MODAL });
  dispatch(resetAddInventoryForm());
};

const setAddInventoryIdLoadingTrue = () => ({ type: SET_ADD_INVENTORY_IS_LOADING_TRUE });

const setAddInventoryIdLoadingFalse = () => ({ type: SET_ADD_INVENTORY_IS_LOADING_FALSE });

export const setAddInventorySearchList = (list) => ({
  type: SET_ADD_INVENTORY_SEARCH_LIST,
  list,
});

const setAddInventoryIsSearchingTrue = () => ({ type: SET_ADD_INVENTORY_IS_SEARCHING_TRUE });

const setAddInventoryIsSearchingFalse = () => ({ type: SET_ADD_INVENTORY_IS_SEARCHING_FALSE });

export const searchForInventoryItems = debounce(async (opsId) => {
  try {
    const state = store.getState();
    const params = {
      key: getAddInventorySearch({ state }).value,
      opsId,
    };
    const res = await ViewOrderService.getAddInventorySearchList(params);
    store.dispatch(setAddInventorySearchList(res.data));
    store.dispatch(setAddInventoryIsSearchingFalse());
  } catch (err) {
    errorLogger('AddInventoryActions:searchForInventoryItems', err);
    toast.error(err.response.data.message);
    store.dispatch(setAddInventoryIsSearchingFalse());
  }
}, 400);

export const setAddInventorySearchProperties = (value, opsId) => (dispatch) => {
  dispatch({
    type: SET_ADD_INVENTORY_SEARCH_PROPERTIES,
    value,
  });

  if (value.length > 1) {
    dispatch(setAddInventoryIsSearchingTrue());
    searchForInventoryItems(opsId);
  } else {
    dispatch(setAddInventorySearchList([]));
    dispatch(setAddInventoryIsSearchingFalse());
  }
};

export const setAddInventoryBarcodeProperties = (value) => ({
  type: SET_ADD_INVENTORY_BARCODE_PROPERTIES,
  value,
});

export const setAddInventoryProductProperties = (value) => ({
  type: SET_ADD_INVENTORY_PRODUCT_PROPERTIES,
  value,
});

export const setAddInventoryQuantityProperties = (value) => ({
  type: SET_ADD_INVENTORY_QUANTITY_PROPERTIES,
  value,
});

export const resetAddInventoryForm = () => ({
  type: RESET_ADD_INVENTORY_FORM,
});

export const addSingleInventoryItem = (orderID) => async (dispatch, getState) => {
  const state = getState();
  const barcode = getAddInventoryBarcode({ state }).value;
  const ProId = +getAddInventoryProduct({ state }).value;
  const qty = +getAddInventoryQuantity({ state }).value;
  const data = { barcode, ProId, orderID, qty };

  dispatch(setAddInventoryIdLoadingTrue());
  try {
    await ViewOrderService.addInventoryItem(data);
    setTimeout(() => {
      dispatch(setCartDetails(orderID));
      dispatch(setAddInventoryIdLoadingFalse());
      dispatch(closeAddInventoryModal());
      toast.success('Added item successfully.');
    }, 1500);
  } catch (err) {
    errorLogger('AddInventoryActions:addSingleInventoryItem', err);
    dispatch(setAddInventoryIdLoadingFalse());
    toast.error(err.response.data.message);
  }
};
