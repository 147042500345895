export const getAllCouponsList = ({ state }) => state.vouchers.couponsListData;
export const getAllCouponsCurrentPage = ({ state }) => state.vouchers.currentPage;
export const getAllCouponsPageSize = ({ state }) => state.vouchers.pageSize;
export const getAllCouponsPageRowCount = ({ state }) => state.vouchers.rowCount;

export const getVoucherCatergoriesList = ({ state }) => state.vouchers.voucherCatList;

export const getCouponDetailsById = ({ state }) => state.vouchers.couponDetails;

export const getVoucherLimitCategories = ({ state }) => state.vouchers.voucherLimitCat;
