import React, { useState } from 'react';

import { CardBody, CardHeader } from 'reactstrap';
import { Media } from 'reactstrap';
import Lightbox from 'react-image-lightbox';

const PickupDetails = ({ pickupDetails }) => {
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const handleTimeStamp = (unixTime) => {
    const milliseconds = unixTime * 1000;
    const dateObject = new Date(milliseconds);
    const humanDateFormat = dateObject.toLocaleString();
    const date = humanDateFormat.split(',')[0];
    return date;
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="card premium-gift-wrapper">
          <CardHeader className="card-header-border">
            <h5>Gift Pick Up Details</h5>
          </CardHeader>
          <CardBody className="recent-notification">
            <div className="table-responsive shopping-table text-center">
              <table className="table table-bordernone">
                <thead>
                  <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Image</th>
                    <th className="collection-time-th" scope="col">
                      Collecting Time
                    </th>
                    <th scope="col">Address</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                {pickupDetails !== '' && (
                  <tbody>
                    <tr>
                      <td>{pickupDetails.itemsDescription}</td>
                      <td className="attachments-wrapper">
                        {pickupDetails.attachments?.map((item, i) => (
                          <Media
                            key={i}
                            className="img-thumbnail img-fluid img-90"
                            src={item.fullImage}
                            onClick={() => {
                              setIsImgOpen(true);
                              setImageSrc(item.fullImage);
                            }}
                          />
                        ))}
                      </td>
                      <td>
                        {pickupDetails.collectionDate &&
                          handleTimeStamp(pickupDetails.collectionDate)}
                        <br />
                        {pickupDetails.pickupSlotName}
                      </td>
                      <td>
                        {pickupDetails.areaName}
                        <br />
                        {pickupDetails.addressDetails}
                        <br />
                        {pickupDetails.lat && pickupDetails.lng && (
                          <a
                            className="view-on-map-link"
                            href={`https://www.google.com/maps/?q=${pickupDetails.lat},${pickupDetails.lng}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            view on map <i className="fa fa-map-marker"></i>
                          </a>
                        )}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </CardBody>
        </div>
      </div>
      {isImgOpen && <Lightbox onCloseRequest={() => setIsImgOpen(false)} mainSrc={imageSrc} />}
    </div>
  );
};

export default PickupDetails;
