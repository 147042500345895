import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const EditOrderCostModal = ({
  orderId,
  isOrderCostModalOpen,
  setIsOrderCostModalOpen,
  orderData,
}) => {
  const [cost, setCost] = useState(parseFloat(orderData.cost, 10) ?? 0);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleEditCost = async () => {
    const dataBody = {
      cost,
    };
    setIsLoaderOpen(true);
    setDisableBtn(true);
    try {
      await ViewOrderService.editOrderCost(dataBody, orderId);
      dispatch({
        type: 'UPDATE_VIEW_ORDER_DETAILS',
        payload: { ...orderData, cost },
      });
      setIsLoaderOpen(false);
      setDisableBtn(false);
      setIsOrderCostModalOpen((prev) => !prev);
      toast.success('Order cost has changed successfully');
    } catch (err) {
      errorLogger('EditOrderCostModal:handleEditCost', err);
      setIsLoaderOpen(false);
      setDisableBtn(false);
      toast.error(err.response.data.message);
    }
  };
  return (
    <div className="card-body btn-showcase">
      <Modal
        data-testid="EditOrderCostModalTestID"
        isOpen={isOrderCostModalOpen}
        toggle={() => setIsOrderCostModalOpen((prev) => !prev)}
      >
        <ModalBody>
          {isLoaderOpen ? (
            <SectionLoader height="150px" />
          ) : (
            <form>
              <div className="form-group col-12">
                <label className="col-form-label" htmlFor="message-text">
                  Cost
                </label>
                <input
                  data-testid="EditCostModalCostValueTestId"
                  onChange={(e) => setCost(parseFloat(e.target.value, 10))}
                  className="form-control"
                  value={cost}
                  type="number"
                  id="message-text"
                ></input>
              </div>
            </form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleEditCost}
            color="primary"
            disabled={disableBtn || !cost}
            data-testid="EditCostModalSaveBtnTestID"
          >
            Save
          </Button>
          <Button
            onClick={() => setIsOrderCostModalOpen((prev) => !prev)}
            color="light"
            data-testid="EditCostModalCancelTestID"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditOrderCostModal;
