import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
// actions
import { setUserWalletBalanceById, setUserRecentTransactionsById } from 'store/user/UserActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const ClearWalletModal = ({ isClearWalletModalOpen, setIsClearWalletModalOpen, userId }) => {
  const [amount, setAmount] = useState('');
  const [remarks, setRemarks] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleClearWallet = async () => {
    const params = {
      amount,
      remarks,
    };
    setDisableBtn(true);
    try {
      await PermissionService.clearUserWallet(params, userId);
      setTimeout(async () => {
        dispatch(setUserWalletBalanceById(userId));
        dispatch(setUserRecentTransactionsById({ userId }));
        setDisableBtn(false);
        setIsClearWalletModalOpen((prev) => !prev);
        toast.success('Wallet updated');
      }, 1500);
    } catch (err) {
      setDisableBtn(false);
      setIsClearWalletModalOpen((prev) => !prev);
      errorLogger('ClearWalletModal:handleClearWallet', err);
      toast.error('Failed to update wallet!');
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isClearWalletModalOpen}
        toggle={() => setIsClearWalletModalOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setIsClearWalletModalOpen((prev) => !prev)}>
          Clear Wallet
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group ">
              <label htmlFor="wallet-amount">Amount</label>
              <input
                id="wallet-amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>

            <div className="form-group ">
              <label htmlFor="wallet-remarks">Remarks</label>
              <textarea
                id="wallet-remarks"
                onChange={(e) => setRemarks(e.target.value)}
                value={remarks}
                className="form-control"
                type="text"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!amount || !remarks || disableBtn}
            onClick={handleClearWallet}
          >
            Save
          </Button>
          <Button color="light" onClick={() => setIsClearWalletModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ClearWalletModal;
