import { updateObject } from '../../utility';

const initialState = {
  deliveryPostCodesData: [],
  selectedPostCode: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DELIVERY_POSTCODES': {
      return updateObject(state, {
        deliveryPostCodesData: action.payload,
      });
    }

    case 'SET_SELECTED_POSTCODE': {
      return updateObject(state, {
        selectedPostCode: action.payload,
      });
    }

    case 'UPDATE_SELECTED_POSTCODE': {
      return updateObject(state, {
        selectedPostCode: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
