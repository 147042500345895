import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
import SubCategoriesDataGrid from './SubCategoriesDataGrid';
import CategoriesActions from '../CategoriesActions';
// selectors
import { getSubCategoriesData } from 'store/categories/CategoriesSelectors';
// actions
import { getSubCategories } from 'store/categories/CategoriesActions';

const SubCategoriesDataTable = ({ categoryId, allowSubLevel = false }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const subCategoriesData = useSelector((state) => getSubCategoriesData({ state }));
  const dispatch = useDispatch();

  const fetchSubCategories = async (catId) => {
    setIsLoaderOpen(true);
    await dispatch(getSubCategories(catId));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchSubCategories(categoryId);
  }, [categoryId]);
  return (
    <>
      <CategoriesActions type="subCategory" />
      <div className="card checkout-card">
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <div className="card-body">
            <SubCategoriesDataGrid data={subCategoriesData} allowSubLevel={allowSubLevel} />
          </div>
        )}
      </div>
    </>
  );
};

export default SubCategoriesDataTable;
