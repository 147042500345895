import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { ReactSortable } from 'react-sortablejs';
import SectionLoader from 'components/common/SectionLoader';
import TripRevealCard from './TripRevealCard';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { setToggleDriverTripModal } from 'store/viewOrder/ViewOrderActions';
// selectors
import {
  getIsDriverTripModalOpen,
  getDeliveryDriverTripId,
  getDeliveryDriverId,
} from 'store/viewOrder/ViewOrderSelectors';
// api
import DeliveryService from 'services/DeliveryService';

const TripDriversListModal = () => {
  const [driverOrdersList, setDriverOrdersList] = useState([]);
  const isDriverTripModalOpen = useSelector((state) => getIsDriverTripModalOpen({ state }));
  const tripId = useSelector((state) => getDeliveryDriverTripId({ state }));
  const driverId = useSelector((state) => getDeliveryDriverId({ state }));
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const dispatch = useDispatch();

  const fetchDriversList = async () => {
    setIsLoaderOpen(true);
    try {
      const res = await DeliveryService.getTripDriverOrdersList(tripId);
      setDriverOrdersList(res.data);
      setIsLoaderOpen(false);
    } catch (err) {
      errorLogger('TripDriversListModal:fetchDriversList', err);
      if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      }
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchDriversList();
  }, []);

  const handleReorder = async () => {
    setIsLoaderOpen(true);
    setDisableSaveBtn(true);
    const dataBody = {
      MapIds: driverOrdersList.map((item) => item.id),
    };
    try {
      const res = await DeliveryService.sortOrdersList(dataBody, driverId);
      setIsLoaderOpen(false);
      setDisableSaveBtn(false);
      dispatch(setToggleDriverTripModal({ tripId: '', driverId: '' }));
      toast.success(res.data.message);
    } catch (err) {
      errorLogger('TripDriversListModal:handleReorder', err);
      if (err.response?.data?.message) {
        toast.error(err.response.data.message);
      }
      setIsLoaderOpen(false);
      setDisableSaveBtn(false);
      dispatch(setToggleDriverTripModal({ tripId: '', driverId: '' }));
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isDriverTripModalOpen}
        toggle={() => dispatch(setToggleDriverTripModal({ tripId: '', driverId: '' }))}
        className="m-w-600"
      >
        <ModalHeader
          toggle={() => dispatch(setToggleDriverTripModal({ tripId: '', driverId: '' }))}
        >
          Orders Queue
        </ModalHeader>
        <ModalBody>
          {isLoaderOpen ? (
            <SectionLoader />
          ) : (
            <ReactSortable list={driverOrdersList} setList={setDriverOrdersList}>
              {driverOrdersList.length > 0 &&
                driverOrdersList.map((item, i) => <TripRevealCard key={i} item={item} />)}
            </ReactSortable>
          )}
        </ModalBody>
        <ModalFooter>
          <Button disabled={disableSaveBtn} onClick={handleReorder} color="primary">
            Save
          </Button>
          <Button
            color="light"
            onClick={() => dispatch(setToggleDriverTripModal({ tripId: '', driverId: '' }))}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TripDriversListModal;
