import React, { useRef, useState } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// components
import has from 'lodash/has';
import toString from 'lodash/toString';
import SubCategories from './subcategories/SubCategories';
import CreateCategoryModal from './modals/CreateCategoryModal';
// modal
import RightSideModal from 'components/shared/RightSideModal';
// selector
import { getSelectedCategory } from 'store/categories/CategoriesSelectors';
// actions
import { setSelectedCategory, setSelectedSubCategory } from 'store/categories/CategoriesActions';

const CategoriesDataGrid = ({ data, inputValues, pageSize, rowCount, pageCount }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'];
  const dispatch = useDispatch();
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const selectedCategory = useSelector((state) => getSelectedCategory({ state }));
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const ref = useRef();
  const toolbarClick = (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        ref.current.excelExport();
        break;
      default:
        break;
    }
  };

  const goEditCat = async (rowData, id) => {
    await dispatch(setSelectedSubCategory(rowData));
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <Link
          to={`/categories/${rowData.id}/edit`}
          onClick={() => goEditCat(rowData, rowData.id)}
          className="total-txt link categoryid-link"
        >
          {parseInt(rowData.id, 10)}
        </Link>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <Link
        to={`/categories/${rowData.id}/edit`}
        onClick={() => goEditCat(rowData, rowData.id)}
        className="total-txt link categoryid-link"
      >
        {rowData.nickname}
      </Link>
    );
  };

  const subcategoryTemplate = (rowData) => {
    return (
      <Link to={`/categories/${rowData.id}/sub`} className="cat-icon-cell">
        <i className="fa fa-align-justify"></i>
      </Link>
    );
  };

  // const productsTemplate = (rowData) => {
  //     return <a className="cat-icon-cell"><i className="fa fa-tags"></i></a>
  // }

  const statusTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${
          rowData.isActive ? 'btn-light-success' : 'btn-light-danger'
        }`}
      >
        {rowData.isActive ? 'Active' : 'Inactive'}
      </button>
    );
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const openSubCategories = (catId) => {
    dispatch(setSelectedCategory(catId));
    setIsSideModalOpen(true);
  };

  return (
    <>
      {isAddCategoryModalOpen && (
        <CreateCategoryModal
          isAddCategoryModalOpen={isAddCategoryModalOpen}
          setIsAddCategoryModalOpen={setIsAddCategoryModalOpen}
          type={'category'}
          isEdit={true}
          inputValues={inputValues}
        />
      )}
      {isSideModalOpen && (
        <RightSideModal
          isPaneOpen={isSideModalOpen}
          setIsPaneOpen={setIsSideModalOpen}
          direction="right"
          width="90%"
        >
          <SubCategories categoryId={selectedCategory} />
        </RightSideModal>
      )}
      <div className="control-pane">
        <div className="control-section">
          <GridComponent
            dataSource={data}
            toolbar={toolbarOptions}
            allowExcelExport={true}
            excelQueryCellInfo={excelQueryCellInfo}
            ref={ref}
            toolbarClick={toolbarClick}
            allowTextWrap={true}
            showColumnChooser={true}
            allowSorting={true}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="id"
                headerText="ID"
                template={idTemplate}
                width={100}
                customExcelTemplate={(rowData) => rowData.id}
              />
              <ColumnDirective
                field="nickname"
                headerText="Name"
                template={nameTemplate}
                width={100}
                customExcelTemplate={(rowData) => rowData.nickname}
              />
              <ColumnDirective
                field="subcat"
                headerText="Sub Cat"
                template={subcategoryTemplate}
                width={100}
              />
              {/* <ColumnDirective field="products" headerText="Products" template={productsTemplate} width={100} /> */}
              <ColumnDirective
                field="isActive"
                headerText="Status"
                template={statusTemplate}
                width={100}
                customExcelTemplate={(rowData) => rowData.isActive}
                customAttributes={{ class: 'category-status' }}
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Sort]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
};

export default React.memo(CategoriesDataGrid);
