import React from 'react';
import { useParams } from 'react-router-dom';
// components
import PageTitle from 'components/shared/PageTitle';
import EditCouponSection from 'components/vouchers/EditCouponSection';

const EditCouponPage = () => {
  const params = useParams();

  return (
    <>
      <PageTitle title="Edit Coupon" />
      <EditCouponSection couponId={params?.id} />
    </>
  );
};

export default EditCouponPage;
