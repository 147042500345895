import React, { useEffect, useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
// components
import AvailabilityRowTemplate from '../components/AvailabilityRowTemplate';

const ProductMasterAvailabilityDataGrid = ({
  productData,
  productId,
  isValid,
  notValidFields,
  validateMA,
}) => {
  const ref = useRef();

  const opsTemplate = (rowData) => <span>{rowData.ops.nickName}</span>;

  const availabilityTemplate = (rowData) => (
    <AvailabilityRowTemplate
      availabilityData={rowData}
      productId={productId}
      isValid={isValid}
      validateMA={validateMA}
      notValidFields={notValidFields}
      country={rowData.ops.nickName}
      master
    />
  );

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={productData}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          allowTextWrap={true}
          allowSelection={false}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Ops"
              field="ops.nickName"
              template={opsTemplate}
              textAlign="Center"
              width={150}
            />
            <ColumnDirective
              headerText="Availability"
              template={availabilityTemplate}
              textAlign="Center"
            />
          </ColumnsDirective>
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(ProductMasterAvailabilityDataGrid);
