// fetch new notifications
export const FETCH_NEW_NOTIFICATIONS = '[Notifications] FETCH_NEW_NOTIFICATIONS';
export const FETCH_NEW_NOTIFICATIONS_SUCCESS = '[Notifications] FETCH_NEW_NOTIFICATIONS_SUCCESS';
export const FETCH_NEW_NOTIFICATIONS_FAIL = '[Notifications] FETCH_NEW_NOTIFICATIONS_FAIL';

// fetch order notifications
export const FETCH_NEW_ORDER_NOTIFICATIONS = '[Notifications] FETCH_NEW_ORDER_NOTIFICATIONS';
export const FETCH_NEW_ORDER_NOTIFICATIONS_SUCCESS =
  '[Notifications] FETCH_NEW_ORDER_NOTIFICATIONS_SUCCESS';
export const FETCH_NEW_ORDER_NOTIFICATIONS_FAIL =
  '[Notifications] FETCH_NEW_ORDER_NOTIFICATIONS_FAIL';

// save old notifications
export const SAVE_RECEIVED_OLD_NOTIFICATIONS = '[Notifications] SAVE_RECEIVED_OLD_NOTIFICATIONS';

// save order old notifications
export const SAVE_RECEIVED_OLD_ORDER_NOTIFICATIONS =
  '[Notifications] SAVE_RECEIVED_OLD_ORDER_NOTIFICATIONS';

// save new notifications
export const SAVE_RECEIVED_NEW_NOTIFICATIONS = '[Notifications] SAVE_RECEIVED_NEW_NOTIFICATIONS';

// save order notifications
export const SAVE_RECEIVED_NEW_ORDER_NOTIFICATIONS =
  '[Notifications] SAVE_RECEIVED_NEW_ORDER_NOTIFICATIONS';

export const SET_IS_FETCHING_OLD_NOTIFICATIONS_TRUE =
  '[Notifications] SET_IS_FETCHING_OLD_NOTIFICATIONS_TRUE';

export const SET_IS_FETCHING_OLD_NOTIFICATIONS_FALSE =
  '[Notifications] SET_IS_FETCHING_OLD_NOTIFICATIONS_FALSE';

// emit
export const SET_SEND_TO_CHANNEL = '[Notifications] SET_SEND_TO_CHANNEL';
