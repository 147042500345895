import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TripDriversDataTable from './TripDriversDataTable';
import DJHPagination from 'components/shared/Pagination';
import TripDriversListModal from './TripDriversListModal';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// selector
import { getIsDriverTripModalOpen } from 'store/viewOrder/ViewOrderSelectors';
// api
import DeliveryService from 'services/DeliveryService';

const TripDriversListPage = () => {
  const isDriverTripModalOpen = useSelector((state) => getIsDriverTripModalOpen({ state }));
  const [driversList, setDriversList] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [rowCount, setRowCount] = useState('');
  const dispatch = useDispatch();

  const fetchDriversList = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await DeliveryService.getTripDriversList(params);
      setDriversList(res.data.data);
      setCurrentPage(res.data.currentPage);
      setPageSize(res.data.pageSize);
      setRowCount(res.data.rowCount);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('TripDriversListPage:fetchDriversList', err);
      toast.error('Failed to get drivers list');
      dispatch(setLoaderOpenFalse());
    }
  };

  useEffect(() => {
    fetchDriversList({ page: 1 });
  }, []);

  const onPageChange = (i) => {
    fetchDriversList({ page: i });
  };

  return (
    <Fragment>
      {isDriverTripModalOpen && <TripDriversListModal />}
      <TripDriversDataTable driversList={driversList} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </Fragment>
  );
};

export default TripDriversListPage;
