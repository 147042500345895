import React from 'react';
import SectionLoader from 'components/common/SectionLoader';
const GeneratePaymentLink = React.lazy(() =>
  import('components/generatePayment/GeneratePaymentLink')
);

const GeneratePaymentLinkPage = () => {
  return (
    <React.Suspense fallback={<SectionLoader />}>
      <GeneratePaymentLink />
    </React.Suspense>
  );
};

export default GeneratePaymentLinkPage;
