import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// api
import DeliveryService from 'services/DeliveryService';

export const getAllPostCodes = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getAllPostCodes(params);
    dispatch({
      type: 'SET_DELIVERY_POSTCODES',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('areasAction:getAllPostCodes', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const getPostCodeById = (id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getPostCodeById(id);
    dispatch({
      type: 'SET_SELECTED_POSTCODE',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('areasAction:getPostCodeById', err);
    dispatch(setLoaderOpenFalse());
  }
};

export const updatePostcode = (data, id) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.updatePostCodeById(data, id);
    dispatch({
      type: 'UPDATE_SELECTED_POSTCODE',
      payload: data,
    });
    dispatch(setLoaderOpenFalse());
    toast.success(res.data.message ?? 'Postcode is updated successfuly!');
  } catch (err) {
    errorLogger('postcodeActions:updatePostcode', err);
    dispatch(setLoaderOpenFalse());
  }
};
