import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// material UI
import Tooltip from '@mui/material/Tooltip';
// react strap
import { CardBody, CardHeader } from 'reactstrap';
// accordion
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
// selectors
import { getAddInventoryIsAddInventoryModal } from 'store/viewOrder/inventory/addInventory/selectors/AddInventorySelectors';
// actions
import { openAddInventoryModal } from 'store/viewOrder/inventory/addInventory/actions/AddInventoryActions';
// components
import { inventoryTableColums } from './viewOrderCommons';
import RestrictedSection from 'components/shared/RestrictedSection';
// modals
import InventoryModal from './modals/InventoryModal';
import AddInventoryModal from './modals/AddInventoryModal';
import PreOrderWarning from './PreOrderWarning';

const Inventory = ({ inventoryData, orderId, opsId, orderCstep, orderStatus }) => {
  const dispatch = useDispatch();
  const [isInventoryModalOpen, setIsInventoryModalOpen] = useState(false);
  const [inventoryQty, setInventoryQty] = useState('');
  const [inventoryBalance, setInventoryBalance] = useState();
  const [inventoryItemId, setInventoryItemId] = useState('');
  const [accActive, setAccActive] = useState(false);
  const isAddInventoryModalActive = useSelector((state) =>
    getAddInventoryIsAddInventoryModal({ state })
  );

  const addInventoryHandler = (e) => {
    e.stopPropagation();
    dispatch(openAddInventoryModal());
  };

  const isPreOrderPending = orderStatus === 7 || false;

  return (
    <div className="row inventory-wrapper">
      {isInventoryModalOpen && (
        <InventoryModal
          isInventoryModalOpen={isInventoryModalOpen}
          setIsInventoryModalOpen={setIsInventoryModalOpen}
          inventoryQty={inventoryQty}
          setInventoryQty={setInventoryQty}
          inventoryBalance={inventoryBalance}
          inventoryItemId={inventoryItemId}
          orderId={orderId}
        />
      )}
      {isAddInventoryModalActive && <AddInventoryModal orderId={orderId} opsId={opsId} />}
      <RestrictedSection page="order_details" action="inventory">
        <PreOrderWarning isPreOrderPending={isPreOrderPending} />
        <div className="col-12" data-testid="TestId__InventoryTransactions">
          <div className="card">
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading
                  data-testid="TestId__AccordionHeading"
                  onClick={() => setAccActive((prev) => !prev)}
                >
                  <AccordionItemButton>
                    <CardHeader className="card-header-border inventory-header-wrapper">
                      <h5 className="header">Inventory Transactions</h5>
                      <span className="flex-fill" />
                      {!isPreOrderPending && orderCstep !== 4 && (
                        <Tooltip title="Add Inventory" placement="top">
                          <i
                            className="fa fa-plus-square add-inventory"
                            onClick={addInventoryHandler}
                            data-testid="TestId__AddInventory"
                          />
                        </Tooltip>
                      )}
                      {orderCstep === 4 ? (
                        <RestrictedSection
                          page="order_details"
                          action="add_inventory_for_delivered"
                        >
                          <Tooltip title="Add Inventory" placement="top">
                            <i
                              className="fa fa-plus-square add-inventory"
                              onClick={addInventoryHandler}
                              data-testid="TestId__AddInventory"
                            />
                          </Tooltip>
                        </RestrictedSection>
                      ) : null}
                      {accActive ? (
                        <i className="fa fa-chevron-up" />
                      ) : (
                        <i className="fa fa-chevron-down" />
                      )}
                    </CardHeader>
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                  <CardBody className="recent-notification">
                    <div className="table-responsive shopping-table text-center">
                      <table className="table table-bordernone">
                        <thead>
                          <tr>
                            {inventoryTableColums.map((col, i) => (
                              <th key={i} scope="col" data-testid="TestId__tableHeader">
                                {col}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        {inventoryData !== '' && (
                          <tbody>
                            {inventoryData.map((item, i) => (
                              <tr key={i} data-testid="TestId__tableDataRow">
                                <td>
                                  <img
                                    alt={item.item.name}
                                    className="img-fluid img-60"
                                    src={item.item.fullImage}
                                  />
                                </td>
                                <td>{item.item.barCode}</td>
                                <td>
                                  <Link
                                    to={`/inventory/${item.item.inventoryId}/editInventory/${item.item.id}`}
                                    target="_blank"
                                    className="redirection-link"
                                  >
                                    {item.item.name}
                                  </Link>
                                </td>
                                <td
                                  className="inventory-qnty"
                                  data-testid="TestId__InventoryQuantity"
                                  onClick={(e) => {
                                    setIsInventoryModalOpen((prev) => !prev);
                                    setInventoryBalance(item.balance);
                                    setInventoryItemId(item.id);
                                    setInventoryQty(item.qty);
                                  }}
                                >
                                  {item.qty}
                                </td>
                                <td>{item.details}</td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </CardBody>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </RestrictedSection>
    </div>
  );
};

export default Inventory;
