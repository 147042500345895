import React from 'react';

const FlowardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Group_1911"
    width={33.141}
    height={32.969}
    viewBox="0 0 33.141 32.969"
  >
    <defs>
      <style>{'.prefix__cls-1{fill:#005671}'}</style>
    </defs>
    <path
      id="prefix__Path_5819"
      d="M123.6 29.879a16.576 16.576 0 00-9.943 5.509q-.3.347-.582.711c-.093.12-.179.246-.269.37s-.181.242-.267.366a15.65 15.65 0 00-.371.575c-.04.062-.082.124-.12.187q-.213.351-.407.711c-.013.024-.028.047-.041.072a16.651 16.651 0 00-1.349 3.357 13.539 13.539 0 001.548-.293l.216-.055c.118-.032.236-.065.349-.1s.251-.076.377-.119c.073-.2.15-.391.233-.584l.006-.018c.078-.183.159-.362.244-.541l.031-.068c.08-.164.161-.327.246-.487l.062-.115a13.078 13.078 0 01.578-.976c.048-.074.1-.148.149-.222.07-.1.139-.208.213-.31s.152-.2.228-.307c.056-.072.108-.144.162-.215.138-.175.279-.347.425-.515a14.487 14.487 0 012.305-2.137l.016-.012a14.291 14.291 0 014.7-2.286 14.2 14.2 0 01-4.457 6.986c-.022.018-.045.035-.065.053a14.156 14.156 0 01-1.441 1.074l-.024.015q-.385.249-.789.475t-.85.439l-.152.074c-.13.059-.261.118-.394.173q-.144.062-.291.12l-.062.025-.085.032c-.1.041-.208.081-.314.119-.122.045-.245.086-.368.128s-.233.074-.35.11l-.048.016-.069.019-.314.088-.222.057a14.458 14.458 0 01-1.914.353c-.038 0-.076.006-.114.01-.057 0-.113.011-.17.015l-.255.022c-.088.008-.177.017-.264.022-.24.013-.481.018-.72.021h-.091c-.16 0-.32-.007-.48-.013a17.563 17.563 0 01.284 2.19h.224q.373 0 .743-.018l.228-.015c.138-.008.276-.022.415-.034.118-.01.238-.017.355-.03.228-.024.456-.055.682-.088l.069-.008c.5-.076 1-.176 1.484-.3l.075-.018a17.093 17.093 0 002.006-.646l.215-.09.055-.022a16.554 16.554 0 004.1-2.446c.114-.093.23-.185.342-.282a16.434 16.434 0 005.539-9.891l.105-.668.127-.811z"
      className="prefix__cls-1"
      transform="translate(-91.944 -29.647)"
    />
    <path
      id="prefix__Path_5820"
      d="M96.245 59.3a16.576 16.576 0 009.943-5.509c.2-.232.394-.469.582-.711.092-.12.179-.246.269-.37s.181-.241.267-.366c.128-.188.25-.381.371-.575.039-.062.081-.122.12-.185.142-.234.278-.473.407-.713l.04-.072a16.483 16.483 0 001.349-3.357 13.519 13.519 0 00-1.547.293l-.216.056c-.119.032-.236.064-.35.1s-.25.078-.376.119c-.073.2-.152.391-.233.585l-.007.017c-.078.183-.158.364-.242.541l-.032.068c-.08.165-.161.327-.246.487l-.062.115c-.079.147-.16.291-.244.434l-.1.165c-.076.127-.154.253-.234.377-.048.075-.1.148-.15.223-.069.1-.139.207-.212.309s-.151.2-.229.307l-.162.216a16.98 16.98 0 01-.424.514 14.451 14.451 0 01-2.307 2.138l-.015.011A14.273 14.273 0 0197.5 56.8a14.206 14.206 0 014.458-6.986l.065-.053a14.021 14.021 0 011.441-1.073l.024-.015a14.227 14.227 0 011.639-.915l.152-.073q.195-.091.393-.175c.1-.041.194-.081.292-.12l.062-.025.084-.032a10.723 10.723 0 01.684-.246l.35-.112.048-.016.068-.018c.105-.032.21-.061.315-.088l.222-.057a14.272 14.272 0 011.914-.354c.038 0 .076-.006.114-.01.056 0 .113-.011.168-.015l.256-.022c.088-.008.176-.017.264-.022.24-.013.48-.018.719-.021h.091c.161 0 .321.006.481.012a17.345 17.345 0 01-.284-2.19h-.224c-.248 0-.5.006-.744.018l-.228.015c-.139.008-.276.022-.415.034-.119.011-.238.018-.355.03-.229.024-.457.055-.684.088l-.069.008c-.5.076-1 .176-1.482.3l-.076.018a17.295 17.295 0 00-2.004.646l-.215.088-.055.023a16.491 16.491 0 00-4.1 2.445c-.115.093-.23.187-.343.282a16.432 16.432 0 00-5.538 9.892l-.105.667-.127.811z"
      className="prefix__cls-1"
      transform="translate(-94.759 -26.566)"
    />
    <path
      id="prefix__Path_5821"
      d="M100.53 41.016a16.631 16.631 0 006.383 3.388 13.671 13.671 0 00-.295-1.539l-.056-.213c-.033-.119-.065-.235-.1-.349s-.078-.248-.12-.375a14.269 14.269 0 01-8.837-9.549 14.283 14.283 0 019.088 7.589l.074.15c.059.13.118.259.175.39s.1.234.147.353.1.263.15.4l.067.189.062.176a15.621 15.621 0 01.235.778l.058.223a14.324 14.324 0 01.355 1.9c.01.093.017.189.024.282l.022.255c.008.087.017.176.022.263.013.238.019.478.021.715v.091c0 .093-.006.187-.008.281.211-.041.421-.08.631-.113l.074-.008c.265-.04.505-.07.731-.095a5.76 5.76 0 01.267-.023l.113-.01.119-.01c.088-.008.178-.016.267-.022q0-.384-.018-.767l-.015-.228a16.332 16.332 0 00-.462-2.989l-.018-.075a16.824 16.824 0 00-.649-1.994l-.112-.269a16.512 16.512 0 00-12.686-9.931l-1.486-.232.233 1.479a16.433 16.433 0 005.538 9.891"
      className="prefix__cls-1"
      transform="translate(-94.759 -29.647)"
    />
    <path
      id="prefix__Path_5822"
      d="M119.032 48.411a16.639 16.639 0 00-6.383-3.388 13.414 13.414 0 00.3 1.539l.055.215c.033.118.065.235.1.348s.078.25.12.375a14.269 14.269 0 018.837 9.549 14.273 14.273 0 01-9.088-7.589l-.074-.15c-.059-.128-.118-.259-.175-.39s-.1-.234-.147-.353-.1-.263-.15-.4l-.067-.19-.062-.175c-.045-.133-.086-.267-.127-.4s-.074-.251-.108-.378L112 46.8a14.283 14.283 0 01-.355-1.9c-.01-.093-.017-.188-.024-.282l-.022-.255c-.008-.087-.017-.175-.022-.263a17.115 17.115 0 01-.021-.715v-.091c0-.093.006-.187.008-.28-.211.04-.421.079-.632.11-.025 0-.05.007-.074.011-.265.039-.505.069-.731.095l-.267.023-.113.008-.119.011c-.088.008-.178.015-.267.021q0 .385.018.767l.015.228a16.334 16.334 0 00.462 2.989l.018.075c.13.505.285 1.01.468 1.521.058.159.119.316.181.473l.112.269a16.512 16.512 0 0012.686 9.931l1.486.232-.237-1.478a16.432 16.432 0 00-5.538-9.892"
      className="prefix__cls-1"
      transform="translate(-91.662 -26.812)"
    />
  </svg>
);

export default FlowardIcon;
