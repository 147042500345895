import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import WalletBalancesDataGrid from 'components/reports/wallet/WalletBalancesDataGrid';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  isoEndFormat,
  isoStartFormat,
} from 'constant/Helpers';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
// url
import { getWalletBalancesPageUrl } from 'constant/AppUrls';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import WalletReportService from 'services/reportsService/WalletReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const WalletBalancesPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: isoEndFormat(new Date()),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await WalletReportService.getWalletBalances(params);
      const updatedList = res.data.map((item, index) => ({
        ...item,
        serial: index + 1,
      }));
      setData(updatedList);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Wallet Balance Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to fetch data!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    navigate({
      pathname: getWalletBalancesPageUrl(),
      search: convertObjectIntoQueryParams(inputValues),
    });
    fetchData(inputValues);
  };

  const handleExport = async () => {
    dispatch(setLoaderOpenTrue());
    const updatedParams = cloneDeep(inputValues);
    updatedParams.fromDate = isoStartFormat(inputValues.fromDate);
    // updatedParams.toDate = isoEndFormat(inputValues.toDate);
    setInputValues(updatedParams);
    try {
      const res = await WalletReportService.exportWalletBalances(updatedParams);
      toast.success(res?.data?.message ?? 'Wallet Balances report exported successfully');
      dispatch(setLoaderOpenFalse());
    } catch (error) {
      errorLogger('Wallet Balances Report Page Export Data Error:', error);
      const json = JSON.parse(await error.response.data.text());
      toast.error(json?.message ?? 'Failed to export Wallet Balances report!!');
      dispatch(setLoaderOpenFalse());
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Wallet Balances"
        singleDate
        noDate
        hasExport
        handleExport={handleExport}
      />
      <WalletBalancesDataGrid data={data} />
    </div>
  );
};

export default WalletBalancesPage;
