/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader, CardBody } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
import { convertUtcToLocalTime, dateFormat } from 'constant/Helpers';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import cloneDeep from 'lodash/cloneDeep';
// actions
import { setActionsBox } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getActionsBoxData } from 'store/viewOrder/ViewOrderSelectors';

const ActionBox = ({ orderId, paymentDate }) => {
  const actionBoxData = useSelector((state) => getActionsBoxData({ state }));
  const isAfter20May = new Date(dateFormat(paymentDate)) > new Date('05/20/2022');
  const [showChanges, setShowChanges] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async () => {
    setIsLoaderOpen(true);
    await dispatch(setActionsBox(orderId, isAfter20May));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const newArr = [];
    for (let i = 0; i < actionBoxData?.length; i++) {
      newArr.push(false);
    }
    setShowChanges(newArr);
  }, [actionBoxData]);

  const toggleData = (index) => {
    const oldArr = cloneDeep(showChanges);
    oldArr[index] = !oldArr[index];
    setShowChanges(oldArr);
  };

  return (
    <div data-testid="ActionBoxTestID">
      <div className="row card action-box-wrapper">
        <CardHeader className="card-header-border action-box-header">
          <h5>
            Actions Box <i className="fa fa-briefcase"></i>
          </h5>
        </CardHeader>
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : isAfter20May ? (
          <>
            {actionBoxData !== '' && (
              <CardBody className="recent-notification" data-testid="ActionBoxAfter20MayTestID">
                {actionBoxData.map((item, i) => (
                  <>
                    <div key={i} className="media">
                      {item.created_date && (
                        <h6>
                          {item?.created_date?.slice(0, 10)}, {item?.created_date?.slice(11, 16)}
                        </h6>
                      )}
                      <div className="media-body">
                        <span>{item.description}</span>
                        {item.attachment && (
                          <a
                            href={`https://floward.imgix.net/web/Files/attachment/${item.attachment}`}
                            className="d-block"
                            target="_blank"
                            rel="noreferrer"
                            download
                          >
                            Attachment
                          </a>
                        )}
                        <p className="f-12">{item.user_name}</p>
                      </div>
                      {item.affected_changes && item.affected_changes.length > 0 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          data-testid="ActionBoxShowMoreTestID"
                          className="show-comments-link"
                          style={{ padding: 0 }}
                          onClick={() => toggleData(i)}
                        >
                          {showChanges[i] ? 'Hide changes' : 'Show changes'}
                        </a>
                      )}
                    </div>
                    {showChanges[i] && (
                      <div
                        data-testid="ActionBoxChangesTableTestID"
                        className="quick-actions-table no-toolbar-table-style comments-table"
                      >
                        <GridComponent dataSource={item.affected_changes} allowTextWrap={true}>
                          <ColumnsDirective>
                            <ColumnDirective
                              field="field_name"
                              headerText="Field"
                              textAlign="Center"
                            />
                            <ColumnDirective
                              field="old_value"
                              headerText="Old values"
                              textAlign="Center"
                            />
                            <ColumnDirective
                              field="new_value"
                              headerText="New values"
                              textAlign="Center"
                            />
                          </ColumnsDirective>
                        </GridComponent>
                      </div>
                    )}
                  </>
                ))}
              </CardBody>
            )}
          </>
        ) : (
          <>
            {actionBoxData !== '' && (
              <CardBody className="recent-notification" data-testid="ActionBoxBefore20MayTestID">
                {actionBoxData.map((item, i) => (
                  <div key={i} className="media">
                    {item.createdDate && <h6>{convertUtcToLocalTime(item.createdDate)}</h6>}
                    <div className="media-body">
                      <span>{item.description}</span>
                      <p className="f-12">{item.fullname}</p>
                    </div>
                  </div>
                ))}
              </CardBody>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ActionBox;
