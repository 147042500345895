export const GET_TOTAL_CATEGORIES_DATA = '[CATEGORIES] GET_TOTAL_CATEGORIES_DATA';

export const SET_SELECTED_CATEGORY = '[CATEGORIES] SET_SELECTED_CATEGORY';

export const GET_SUB_CATEGORIES = '[CATEGORIES] GET_SUB_CATEGORIES';

export const GET_SELECTED_SUB_CATEGORY = '[CATEGORIES] GET_SELECTED_SUB_CATEGORY';

export const SET_SELECTED_SUB_CATEGORY = '[CATEGORIES] SET_SELECTED_SUB_CATEGORY';

export const GET_CATEGORY_OPS = '[CATEGORIES] GET_CATEGORY_OPS';
