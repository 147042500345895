import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
// api
import UserFeedbackService from '../../services/UserFeedbackService';

export const setUserFeedbackList = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await UserFeedbackService.getAllUserFeedbackReasons(params);
    dispatch({
      type: 'GET_USER_FEEDBACK_LIST',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    toast.error('Failed to fetch user feedback reasons list!!');
    errorLogger('AddNewUserFeedbackModal:FetchUserFeedbackList', err);
    dispatch(setLoaderOpenFalse());
  }
};
