import { updateObject } from '../utility';

const initialState = {
  rankedProducts: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RANK_PRODUCTS': {
      return updateObject(state, {
        rankedProducts: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
