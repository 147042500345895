import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
} from '@syncfusion/ej2-react-grids';
// Actions
import { setSelectedCorporateInvoices } from 'store/invoices/InvoicesActions';
// Helpers
import { dateFormatDDMM } from 'constant/Helpers';

const CorporateInvoicesDataGrid = ({ data }) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const checkAll = (e) => {
    const classNames = e.target.className;
    const isChecked = classNames.includes('is-checked');
    if (!isChecked) {
      ref.current.selectRowsByRange(0, 199);
      document.getElementsByClassName('selectAllcheckbox')[0].className =
        'selectAllcheckbox is-checked';
    } else {
      ref.current.clearSelection();
      document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
    }
  };

  const checkboxTemplate = () => {
    return (
      <div className="e-checkbox-wrapper e-css">
        <input className="e-checkselect" type="checkbox" />
        <span className="e-frame e-icons"></span>
        <span className="e-label"> </span>
      </div>
    );
  };

  const rowSelected = () => {
    const selectedrecords = cloneDeep(ref.current.getSelectedRecords());
    if (selectedrecords.length === data.length) {
      document.getElementsByClassName('selectAllcheckbox')[0].className =
        'selectAllcheckbox is-checked';
    } else {
      if (selectedrecords.length > 0) {
        document.getElementsByClassName('selectAllcheckbox')[0].className =
          'selectAllcheckbox some-check';
      } else {
        document.getElementsByClassName('selectAllcheckbox')[0].className = 'selectAllcheckbox';
      }
    }
    dispatch(setSelectedCorporateInvoices(selectedrecords));
  };

  const checkboxHeaderTemplate = () => {
    return <span className="selectAllcheckbox" onClick={checkAll}></span>;
  };

  const deliveryTemplate = (rowData) => {
    return <span>{dateFormatDDMM(rowData.deliveryDate)}</span>;
  };

  return (
    <div className="control-section key-value-grid">
      <GridComponent
        dataSource={data}
        allowSorting={true}
        ref={ref}
        rowHeight={60}
        allowTextWrap={true}
        selectionSettings={{ type: 'Multiple', enableSimpleMultiRowSelection: true }}
        rowSelected={rowSelected}
        rowDeselected={rowSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            width="75"
            customAttributes={{ class: 'row-select-checkbox' }}
            template={checkboxTemplate}
            headerTemplate={checkboxHeaderTemplate}
          />
          <ColumnDirective field="orderId" headerText="Order ID" width={110} textAlign="Center" />
          <ColumnDirective
            field="invoiceId"
            headerText="Invoice ID"
            width={110}
            textAlign="Center"
          />
          <ColumnDirective field="discount" headerText="Discounts" width={110} textAlign="Center" />
          <ColumnDirective
            field="delivery"
            headerText="delivery"
            template={deliveryTemplate}
            width={110}
            textAlign="Center"
          />
          <ColumnDirective
            field="vatValue"
            headerText="Tax Amount"
            width={110}
            textAlign="Center"
          />
          <ColumnDirective
            field="subTotal"
            headerText="Total (Excluding VAT)"
            width={110}
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject services={[Sort]} />
      </GridComponent>
    </div>
  );
};

export default CorporateInvoicesDataGrid;
