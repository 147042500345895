import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
import DatePicker from 'react-datepicker';
import SectionLoader from 'components/common/SectionLoader';
import RestrictedSection from 'components/shared/RestrictedSection';
// constants
import { HasPermission } from 'constant/Helpers';
import { employeeGrades } from 'constant/Enums';
// api
import PermissionService from 'services/PermissionService';
// actions
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// selectors
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
import { getPermissionsList } from 'store/app/AppSelectors';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UserProfileContent = ({ userId, setIsUserProfileModalOpen }) => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const [isDesigner, setIsDesigner] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [monthlyCredit, setMonthlyCredit] = useState('');
  const [designerCommission, setDesignerCommission] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [currency, setCurrency] = useState(null);
  const [currencyString, setCurrencyString] = useState('');
  const [employeeGrade, setEmployeeGrade] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const [countriesList, setCountriesList] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();

  const fetchUserProfileData = async (userId) => {
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getUserProfileInfo(userId);
      setIsDesigner(res.data.isDesigner);
      setIsEmployee(res.data.isEmployee);
      setDesignerCommission(res.data.designerCommission);
      setMonthlyCredit(res.data.monthlyCredit);
      setCurrency(res.data.creditCountry);
      setEmployeeGrade(res.data.employeeGrade);
      setSelectedDate(new Date(res.data.onboardedAt));
      setIsLoaderOpen(false);
    } catch (err) {
      errorLogger('UserProfileContent:fetchUserProfileData', err);
      toast.error(err?.response?.data?.message ?? 'Failed to get user profile info!');
      setIsLoaderOpen(false);
    }
  };

  const fetchCountriesList = () => {
    try {
      dispatch(setSignInSuccess());
    } catch (err) {
      errorLogger('UserProfileContent:fetchCountriesList', err);
      toast.error('Failed to get coutries list!!');
    }
  };

  useEffect(() => {
    fetchUserProfileData(userId);
    fetchCountriesList();
  }, []);

  useEffect(() => {
    if (countriesList.length > 0 && currency) {
      setCurrencyString(countriesList.find((item) => item.id === currency).currencyISO);
    }
  }, [countriesList, currency]);

  useEffect(() => {
    const countriesData = [];
    opsCenters.forEach((ops) => {
      if (
        countriesData.filter((country) => {
          return country.id === ops.country.id;
        }).length === 0
      ) {
        countriesData.push(ops.country);
      }
    });
    setCountriesList(countriesData);
  }, [opsCenters]);

  const handleAddUserProfile = async () => {
    setIsLoaderOpen(true);
    setDisableBtn(true);
    const dataBody = {
      IsDesigner: isDesigner,
      isEmployee,
      DesignerCommission: isDesigner ? designerCommission : 0,
      MonthlyCredit: monthlyCredit,
      CreditCountry: currency,
      OnboardedAt: selectedDate,
      employeeGrade,
    };
    try {
      const res = await PermissionService.addUserProfileInfo(dataBody, userId);
      setIsLoaderOpen(false);
      toast.success(res?.data?.message ?? 'User Profile updated');
      setDisableBtn(false);
      setIsUserProfileModalOpen((prev) => !prev);
    } catch (err) {
      errorLogger('UserProfileContent:handleAddUserProfile', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update user profile info!');
      setIsLoaderOpen(false);
      setDisableBtn(false);
    }
  };

  const handleIsDesignerChange = () => {
    if (isDesigner || (!isDesigner && !isEmployee)) {
      setIsDesigner((prev) => !prev);
    } else {
      setIsDesigner((prev) => !prev);
      setIsEmployee((prev) => !prev);
    }
  };

  const handleIsEmployeeChange = () => {
    if (isEmployee || (!isEmployee && !isDesigner)) {
      setIsEmployee((prev) => !prev);
    } else {
      setIsEmployee((prev) => !prev);
      setIsDesigner((prev) => !prev);
    }
  };

  return (
    <div className="card row checkout-card">
      <div className="card-header-border card-header checkout-card-header">
        <h4>
          User Profile <i className="fa fa-user"></i>
        </h4>
      </div>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="card-body">
          <div className="form-group row m-b-30 p-l-0">
            <div className="form-group col-6">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={isDesigner}
                onChange={handleIsDesignerChange}
              />
              <span className="bold-title">Is Designer?</span>
            </div>
            <RestrictedSection page="admin_user_info" action="is_employee_access">
              <div className="form-group col-6">
                <input
                  className="checkbox_animated"
                  type="checkbox"
                  checked={isEmployee}
                  onChange={handleIsEmployeeChange}
                />
                <span className="bold-title">Is Employee?</span>
              </div>
            </RestrictedSection>
          </div>

          {(isDesigner ||
            (isEmployee &&
              HasPermission(permissionsList, 'admin_user_info', 'is_employee_access'))) && (
            <div className="row">
              <div className="form-group filter-select col-12">
                <label className="bold-title">
                  {isDesigner ? 'Designer Commission %' : 'Employee Grade'}
                </label>
                {isDesigner ? (
                  <input
                    value={designerCommission}
                    onChange={(e) => setDesignerCommission(e.target.value)}
                    className="form-control"
                    type="text"
                  />
                ) : (
                  <CustomSelect
                    listData={employeeGrades}
                    handleChange={(e) => setEmployeeGrade(e.itemData.id)}
                    placeholder="Select employee grade"
                    textPropName="title"
                    valuePropName="id"
                    selectedValue={employeeGrade}
                  />
                )}
              </div>
              <div className="form-group col-6">
                <label className="bold-title">
                  Monthly Credit {currencyString && `(${currencyString})`}
                </label>
                <input
                  value={monthlyCredit}
                  onChange={(e) => setMonthlyCredit(e.target.value)}
                  className="form-control"
                  type="text"
                />
              </div>
              {countriesList.length > 0 && (
                <div className="col-6">
                  <div className="form-group filter-select">
                    <label className="bold-title" htmlFor="select-currency">
                      Currency
                    </label>
                    <CustomSelect
                      id="select-currency"
                      listData={countriesList}
                      handleChange={(e) => setCurrency(e.itemData.id)}
                      placeholder="Select currency"
                      textPropName="currencyISO"
                      valuePropName="id"
                      selectedValue={currency}
                    />
                  </div>
                </div>
              )}
              <div className="form-group col-12">
                <label className="bold-title" htmlFor="onboard-date">
                  On boarded at
                </label>
                <DatePicker
                  id="onboard-date"
                  className="form-control digits"
                  placeholderText="On board date"
                  selected={selectedDate}
                  onChange={(data) => setSelectedDate(data)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="form-group col-12">
        <Button color="primary" disabled={disableBtn} onClick={handleAddUserProfile}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default UserProfileContent;
