import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import PageTitle from 'components/shared/PageTitle';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject, getProductTabs } from 'constant/Helpers';
// components
import ProductTabContent from './ProductTabContent';
import Tabs from 'components/shared/Tabs';
import RestrictedSection from 'components/shared/RestrictedSection';
import ProductLogsDataTable from 'components/masterCatalog/localAvailability/ProductLogsDataTable';
// actions
import {
  setAllProductsDataById,
  setSyncEditProduct,
  setDuplicateProduct,
  validatingFields,
  finishedValidating,
} from 'store/products/ProductsActions';
// actions
import { setSelectedProduct } from 'store/masterCatalog/MasterCatalogActions';
// selectors
import {
  getProductDetailsById,
  getIsSyncedProduct,
  getProductOptionsById,
  getProductMediaById,
  getProductAvailabilityById,
  getProductCategoriesByIdData,
  getProductMCData,
  getProductAttributesByIdData,
  getProductMasterAvailabilityById,
  getProductMasterInventoryById,
  getProductRanking,
  getEverydayOccasionsSubList,
} from 'store/products/ProductsSelectors';
import { getPermissionsList } from 'store/app/AppSelectors';
// modal
import RightSideModal from 'components/shared/RightSideModal';
import {
  validateDetailsTab,
  validateLocalAvailability,
  validateMediaTab,
  validateCategoriesTab,
  validateOptionsTab,
  validateMCTab,
  validateMasterAvailabilityTab,
  validateMasterInventoryTab,
  validateRankingTab,
} from 'constant/Validations';
const EditProductPage = () => {
  const params = useParams();
  const [BasicTab, setBasicTab] = useState(params?.tabVal);
  const [toggleProductLog, setToggleProductLog] = useState(false);
  const productDetailsById = useSelector((state) => getProductDetailsById({ state }));
  const isProductSynced = useSelector((state) => getIsSyncedProduct({ state }));
  const productOptionsById = useSelector((state) => getProductOptionsById({ state }));
  const productMediaById = useSelector((state) => getProductMediaById({ state }));
  const productCatsById = useSelector((state) => getProductCategoriesByIdData({ state }));
  const productLocalAvailabilityById = useSelector((state) =>
    getProductAvailabilityById({ state })
  );
  const productMCData = useSelector((state) => getProductMCData({ state }));
  const productAttrData = useSelector((state) => getProductAttributesByIdData({ state }));
  const productMAData = useSelector((state) => getProductMasterAvailabilityById({ state }));
  const productMIData = useSelector((state) => getProductMasterInventoryById({ state }));
  const productRanking = useSelector((state) => getProductRanking({ state }));
  const everydayOccasionsSubList = useSelector((state) => getEverydayOccasionsSubList({ state }));
  const permissionsList = useSelector((state) => getPermissionsList({ state }));

  const [isCustomizable, setIsCustomizable] = useState(false);
  const tabsData = getProductTabs(productOptionsById);
  const [fetchingTabs, setFetchingTabs] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [fields, setFields] = useState([]);

  const getMCAttributesFieldsData = (data) => {
    setFields(data);
  };

  const [validTabs, setValidTabs] = useState({
    Details: { isValid: true, notValidFields: [] },
    Options: { isValid: true, notValidFields: [] },
    Media: { isValid: true, notValidFields: [] },
    'Local Availability': { isValid: true, notValidFields: [] },
    Categories: { isValid: true, notValidFields: [] },
    MC: { isValid: true, notValidFields: [] },
    Inventory: { isValid: true, notValidFields: [] },
    'Exclude Day': { isValid: true, notValidFields: [] },
    'Master Availability': { isValid: true, notValidFields: [] },
    'Master Inventory': { isValid: true, notValidFields: [] },
    Ranking: { isValid: true, notValidFields: [] },
  });

  const validateDetails = async (productDetails, isDetailsOnly, options) => {
    const detailsValidity = await validateDetailsTab(
      productDetails,
      options?.type || productOptionsById?.type
    );
    if (isDetailsOnly) {
      setValidTabs({ ...validTabs, Details: detailsValidity });
    } else {
      return detailsValidity;
    }
  };

  const validateAvailability = async (isLocalAvailabilityOnly, productLocalAvailability) => {
    const localAvailabilityValidity = await validateLocalAvailability(
      productLocalAvailability,
      productOptionsById
    );
    if (isLocalAvailabilityOnly) {
      setValidTabs({ ...validTabs, 'Local Availability': localAvailabilityValidity });
    } else {
      return localAvailabilityValidity;
    }
  };

  const validateOptions = async (isOptionsOnly, productOptions) => {
    const optionsValidity = await validateOptionsTab(productOptions);
    if (isOptionsOnly) {
      setValidTabs({ ...validTabs, Options: optionsValidity });
    } else {
      return optionsValidity;
    }
  };

  const validateMedia = (isMediaOnly, maxRange, productMedia, options) => {
    if (maxRange) {
      const validateMaxRange = { isValid: true, notValidFields: [] };
      if (maxRange + productMediaById.images.length > 6) {
        validateMaxRange.isValid = false;
        validateMaxRange.notValidFields.push('max range for images');
        setValidTabs({
          ...validTabs,
          Media: validateMaxRange,
        });
        return validateMaxRange;
      }
      return;
    }
    const mediaValidity = validateMediaTab(productMedia, options?.type || productOptionsById?.type);
    if (isMediaOnly) {
      setValidTabs({ ...validTabs, Media: mediaValidity });
    } else {
      return mediaValidity;
    }
  };

  const validateCategories = async (
    isCategoriesOnly,
    validateField,
    productCategories,
    productOptions
  ) => {
    if (validateField) {
      const { isValid, notValidFields } = validTabs.Categories;
      if (validateField.validate) {
        setValidTabs({
          ...validTabs,
          Categories: {
            isValid,
            notValidFields: notValidFields.filter((el) => el !== validateField.field),
          },
        });
      } else {
        setValidTabs({
          ...validTabs,
          Categories: {
            isValid: false,
            notValidFields: [...new Set([...notValidFields, validateField.field])],
          },
        });
      }

      return;
    }
    const categoriesValidity = await validateCategoriesTab(
      productCategories,
      productOptions || productOptionsById,
      everydayOccasionsSubList
    );

    if (isCategoriesOnly) {
      setValidTabs({ ...validTabs, Categories: categoriesValidity });
    } else {
      return categoriesValidity;
    }
  };

  const validateMC = async (mcData, isMCOnly, attrData) => {
    const mcValidity = await validateMCTab(
      mcData || productMCData,
      attrData || productAttrData,
      permissionsList,
      fields
    );
    if (isMCOnly) {
      setValidTabs({ ...validTabs, MC: mcValidity });
    } else {
      return mcValidity;
    }
  };

  const validateMA = async (maData, isMAOnly) => {
    const maValidity = await validateMasterAvailabilityTab(maData, productOptionsById);
    if (isMAOnly) {
      dispatch(validatingFields());
      setValidTabs({ ...validTabs, 'Master Availability': maValidity });
      dispatch(finishedValidating());
    } else {
      return maValidity;
    }
  };

  const validateMI = async (miData, isMIOnly) => {
    const miValidity = await validateMasterInventoryTab(miData);
    if (isMIOnly) {
      setValidTabs({ ...validTabs, 'Master Inventory': miValidity });
    } else {
      return miValidity;
    }
  };

  const validateRanking = async (rankingData, isRankingOnly) => {
    const rankingValidity = await validateRankingTab(
      rankingData,
      productMAData,
      productOptionsById,
      permissionsList
    );
    if (isRankingOnly) {
      dispatch(validatingFields());
      setValidTabs({ ...validTabs, Ranking: rankingValidity });
      dispatch(finishedValidating());
    } else {
      return rankingValidity;
    }
  };

  const validatFields = async (options) => {
    dispatch(validatingFields());
    const tabsValidity = {};
    //validate details page
    const detailsValidity = await validateDetails(productDetailsById, false, options);
    tabsValidity['Details'] = detailsValidity;

    //validate media page
    const mediaValidity = validateMedia(false, null, productMediaById, options);
    tabsValidity['Media'] = mediaValidity;

    // validate local availability tab
    const localAvailabilityValidity = await validateAvailability(
      false,
      productLocalAvailabilityById,
      productMAData
    );
    tabsValidity['Local Availability'] = localAvailabilityValidity;
    //validate categories tab
    const categoriesValidity = await validateCategories(
      false,
      null,
      productCatsById,
      options || productOptionsById
    );
    tabsValidity['Categories'] = categoriesValidity;

    //validate options tab
    const optionsValidity = await validateOptions(false, productOptionsById);
    tabsValidity['Options'] = optionsValidity;

    //validate MC tab
    const mcValidity = await validateMC(productMCData, false, productAttrData);
    tabsValidity['MC'] = mcValidity;

    //validate Master Availability tab
    const maValidity = await validateMA(productMAData);
    tabsValidity['Master Availability'] = maValidity;

    //validate master inventory tab
    const miValidity = await validateMI(productMIData);
    tabsValidity['Master Inventory'] = miValidity;

    //validate ranking tab
    const rankingValidity = await validateRanking(productRanking);
    tabsValidity['Ranking'] = rankingValidity;

    setValidTabs(tabsValidity);

    const isValidToSubmit = Object.values(tabsValidity).every((tab) => tab.isValid) || false;
    const errors = Object.values(tabsValidity).reduce(
      (prev, curr) => prev.concat(curr.notValidFields),
      []
    );
    setValidationErrors(errors);
    dispatch(finishedValidating());
    return isValidToSubmit;
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(setAllProductsDataById(params?.id));
  }, []);

  const handleTabValue = (tabVal) => {
    setBasicTab(tabVal);
    navigate(`/product/editProduct/${params?.id}/${tabVal}`);
  };

  const handleSyncProduct = (id) => {
    dispatch(setSyncEditProduct(id));
  };

  const handleDuplicateProduct = (id) => {
    dispatch(setDuplicateProduct(id));
  };

  useEffect(() => {
    setFetchingTabs(true);
    setIsCustomizable(productDetailsById.customizable);
  }, [productDetailsById, productOptionsById]);

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      if (queryObj.isDuplicated) {
        toast.success('Duplicated');
        navigate(`/product/editProduct/${params?.id}/0`);
      }
    }
  }, []);

  return (
    <>
      {toggleProductLog && (
        <RightSideModal
          isPaneOpen={toggleProductLog}
          setIsPaneOpen={setToggleProductLog}
          direction="right"
          width="80%"
        >
          <ProductLogsDataTable toggleProductLog={toggleProductLog} />
        </RightSideModal>
      )}
      <div className="product-page">
        <PageTitle
          title={
            productDetailsById?.sku
              ? `${productDetailsById.sku} | Edit Product`
              : 'Products | Edit Product'
          }
        />
        <div className="card">
          <div className="card-body sz-20 p-15">
            Edit Product | {productDetailsById.sku}
            <div className="product-actions-wrapper">
              <button
                type="button"
                className="btn btn-warning m-r-10"
                onClick={() => {
                  dispatch(setSelectedProduct(params?.id));
                  setToggleProductLog(true);
                }}
              >
                <i className="fa fa-book"></i> Log
              </button>
              <RestrictedSection page="edit_product" action="duplicate_product">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => handleDuplicateProduct(params?.id)}
                >
                  <i className="fa fa-files-o"></i> Duplicate
                </button>
              </RestrictedSection>
              {isProductSynced ? (
                <button
                  onClick={() => handleSyncProduct(params?.id)}
                  className="btn btn-secondary sync-product-btn"
                >
                  <i className="fa fa-refresh"></i>
                  Sync
                </button>
              ) : (
                <span className="sz-15">
                  <img
                    alt="sync"
                    src="https://cdn.floward.com/web/Files/attachment/main-637702266203428444.gif"
                    className="w-30p h-30p"
                  />
                  Syncing...
                </span>
              )}
            </div>
          </div>
        </div>
        {productOptionsById.status === 1 && (
          <div className="published-caution">
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> You can&apos;t do
            changes to published product, change the status to &quot;Draft&quot; to do any changes
          </div>
        )}
        {validationErrors?.length > 3 && (
          <div className="published-caution">
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please add all the
            missing fields
          </div>
        )}
        {validationErrors?.length <= 3 && validationErrors?.length > 0 && (
          <div className="published-caution">
            <div className="submission-wrapper">
              <div className="warrning-icon">
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </div>
              <div className="submission-data">
                <span className="submission-header">Submission Failed</span>
                <div className="submission-body">
                  {validationErrors?.map((item, index) => (
                    <span key={index}>{`Please enter a valid "${item}"`}</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card">
          <div className="card-body">
            {fetchingTabs ? (
              <Tabs
                activeTab={parseInt(BasicTab, 10)}
                type="product"
                handleTabValue={handleTabValue}
                validTabs={validTabs}
              >
                {tabsData
                  .filter(
                    (item) => item?.id !== 6 || isCustomizable || productOptionsById?.type === 10
                  )
                  .filter((item) => item?.id !== 5 || productDetailsById?.trackInventory)
                  .filter((item) => item?.id !== 10 || productOptionsById?.type === 3)
                  .map((tab, i) => (
                    <div label={tab.nickName} key={tab.id}>
                      <ProductTabContent
                        id={tab.id}
                        productId={params?.id}
                        isCustomizable={isCustomizable}
                        setIsCustomizable={setIsCustomizable}
                        validTabs={validTabs}
                        validateFields={validatFields}
                        getMCAttributesFieldsData={getMCAttributesFieldsData}
                        validation={{
                          details: validateDetails,
                          localAvailability: validateAvailability,
                          media: validateMedia,
                          categories: validateCategories,
                          options: validateOptions,
                          mc: validateMC,
                          ma: validateMA,
                          mi: validateMI,
                          ranking: validateRanking,
                        }}
                      />
                    </div>
                  ))}
              </Tabs>
            ) : (
              <SectionLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProductPage;
