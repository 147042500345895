import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import CelebritiesDataGrid from './CelebritiesDataGrid';
import CelebritiesActions from './CelebritiesActions';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// pagination
import DJHPagination from 'components/shared/Pagination';
// actions
import { setCelebritiesData } from 'store/celebrities/CelebritiesActions';
// selectors
import { getCelebritiesData } from 'store/celebrities/CelebritiesSelectors';

const CelebritiesDataTable = ({ inputValues, setInputValues }) => {
  const celebritiesData = useSelector((state) => getCelebritiesData({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (params) => {
    const queryParams = params ?? inputValues;
    const queryString = convertObjectIntoQueryParams(queryParams);

    navigate({
      pathname: '/celebrities',
      search: queryString,
    });
    dispatch(setCelebritiesData(queryParams));
  };

  const onPageChange = async (i) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.page = i;
    setInputValues(updatedParams);
    handleSubmit(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(setCelebritiesData(queryObj));
    } else {
      dispatch(setCelebritiesData(inputValues));
    }
  }, []);

  return (
    <div className="quick-actions-table toolbar-table-style">
      <CelebritiesActions />
      <CelebritiesDataGrid data={celebritiesData.data} inputValues={inputValues} />
      {+celebritiesData.rowCount > +celebritiesData.pageSize && (
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+celebritiesData.currentPage}
          pageSize={+celebritiesData.pageSize}
          itemsCount={+celebritiesData.rowCount}
        />
      )}
    </div>
  );
};

export default CelebritiesDataTable;
