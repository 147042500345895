import React from 'react';
// Components
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import SectionLoader from 'components/common/SectionLoader';
const QuickPrintingFilter = React.lazy(() =>
  import('components/orders/quickPrinting/QuickPrintingFilter')
);
import QuickPrintingDataTable from 'components/orders/quickPrinting/QuickPrintingDataTable';
const QuickPrintingActions = React.lazy(() =>
  import('components/orders/quickPrinting/QuickPrintingActions')
);

const OrdersQuickPrintingPage = () => {
  return (
    <>
      <React.Suspense fallback={<SectionLoader />}>
        <AppErrorBoundary>
          <QuickPrintingActions />
        </AppErrorBoundary>
      </React.Suspense>
      <React.Suspense fallback={<SectionLoader />}>
        <AppErrorBoundary>
          <QuickPrintingFilter />
        </AppErrorBoundary>
      </React.Suspense>
      <React.Suspense fallback={<SectionLoader />}>
        <AppErrorBoundary>
          <QuickPrintingDataTable />
        </AppErrorBoundary>
      </React.Suspense>
    </>
  );
};

export default OrdersQuickPrintingPage;
