/* eslint-disable indent */
import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { setPrintingTableData } from 'store/orderQuickPrinting/QuickPrintingActions';
// lodash
import cloneDeep from 'lodash/cloneDeep';
// api
import QuickActionService from 'services/QuickActionService';
// action types
import {
  SET_CURRENT_QUICK_ACTIONS_TABLE_PAGE,
  SET_QUICK_ACTIONS_TABLE_REF,
  SET_REQUIRED_PARAMS_FOR_QUICK_ACTIONS_TABLE,
  TOGGLE_QUICK_ACTIONS_IS_PRINTED_BTN_DISABLED,
  TOGGLE_QUICK_ACTIONS_IS_SHARE_DETAILS_BTN_DISABLED,
} from './DataTableActionTypes';
// selectors
import {
  getCurrentQuickActionsTablePage,
  getQuickActionsTableParams,
  getQuickActionsTableRef,
} from './DataTableSelector';
// constants
import { getOrderQuickActionsPageUrl, getPremiumQuickActionsPageUrl } from 'constant/AppUrls';
import { convertObjectIntoQueryParams, history } from 'constant/Helpers';
import { errorLogger } from 'datadog/DDUtils';

export const forceReloadQuickActionsTable = (state, message) => {
  const tableRef = getQuickActionsTableRef({ state });

  if (tableRef && tableRef.current) {
    tableRef.current.onQueryChange();
  }
  if (message) {
    toast.success(message);
  }
};

const toggleQuickActionsIsShareDetailsBtnDisabled = (id) => ({
  type: TOGGLE_QUICK_ACTIONS_IS_SHARE_DETAILS_BTN_DISABLED,
  id,
});

export const setShareSenderDetails = (id, shared, premium) => async (dispatch) => {
  let bodyData;
  if (shared) {
    bodyData = {
      share: false,
    };
  } else {
    bodyData = {
      share: true,
    };
  }
  dispatch(toggleQuickActionsIsShareDetailsBtnDisabled(id));
  try {
    await QuickActionService.updateSharedDetails(bodyData, id);
    dispatch(
      setRequiredTableRowData({
        id,
        field: { name: 'shareSender', value: !shared },
      })
    );
    setTimeout(() => {
      if (premium) {
        dispatch(setPremiumTableData(''));
      } else {
        dispatch(setTableData(''));
      }
      dispatch(toggleQuickActionsIsShareDetailsBtnDisabled(id));
    }, 1500);
  } catch (err) {
    errorLogger('DataTableAction:setShareSenderDetails', err);
    dispatch(toggleQuickActionsIsShareDetailsBtnDisabled(id));
    toast.error('Failed to share recipient details!');
  }
};

const toggleQuickActionsIsPrintedBtnDisabled = (id) => ({
  type: TOGGLE_QUICK_ACTIONS_IS_PRINTED_BTN_DISABLED,
  id,
});

export const setOrderDetailsPrinted =
  (id, printed, premium, isQuickActions, isQuickPrinting) => async (dispatch) => {
    let status;
    if (printed) {
      status = 0;
    } else {
      status = 1;
    }
    dispatch(toggleQuickActionsIsPrintedBtnDisabled(id));
    try {
      await QuickActionService.updateDetailsPrinted(id, status);
      dispatch(
        setRequiredTableRowData({
          id,
          field: { name: 'detailsPrinted', value: !printed },
        })
      );
      if (isQuickActions) {
        setTimeout(() => {
          // forceReloadQuickActionsTable(state, 'Print details updated');
          if (premium) {
            dispatch(setPremiumTableData(''));
          } else {
            dispatch(setTableData(''));
          }
          dispatch(toggleQuickActionsIsPrintedBtnDisabled(id));
        }, 1500);
      }

      if (isQuickPrinting) {
        setTimeout(() => {
          dispatch(setPrintingTableData());
          dispatch(toggleQuickActionsIsPrintedBtnDisabled(id));
        }, 1500);
      }
    } catch (err) {
      errorLogger('DataTableAction:setOrderDetailsPrinted', err);
      dispatch(toggleQuickActionsIsPrintedBtnDisabled(id));
      toast.error('Failed to update print details!');
    }
  };

export const setToggleNoteModalOpen = (orderId, rowNote) => ({
  type: 'TOGGLE_NOTE_MODAL_OPEN',
  payload: { orderId, rowNote },
});

export const setIsNoteForTeamOpen = (noteForTeam) => ({
  type: 'TOGGLE_NOTE_FOR_TEAM_MODAL_OPEN',
  payload: noteForTeam,
});

export const setTogglePriorityModalOpen = (orderId, priorityId) => ({
  type: 'TOGGLE_PRIORITY_MODAL_OPEN',
  payload: { orderId, priorityId },
});

export const updatePriority = (priorityId) => ({
  type: 'UPDATE_PRIORITY',
  payload: { priorityId },
});

export const setCardMessageModalOpen = (orderId) => ({
  type: 'TOGGLE_CARD_MODAL_OPEN',
  payload: orderId,
});

export const setToggleAddressModalOpen = (orderId, recipient, isAction, orderOps, opsInfo) => ({
  type: 'TOGGLE_ADDRESS_MODAL_OPEN',
  payload: { orderId, recipient, isAction, orderOps, opsInfo },
});

export const setToggleMapLinkModalOpen = (orderId, recipient, isAction, orderOps, opsInfo) => ({
  type: 'TOGGLE_MAP_LINK_MODAL_OPEN',
  payload: { orderId, recipient, isAction, orderOps, opsInfo },
});

export const setChangeStatusModalOpen = (val) => {
  return {
    type: 'SET_CHANGE_STATUS_MODAL_OPEN',
    payload: val,
  };
};

export const setChangeStatusModalClose = () => ({
  type: 'SET_CHANGE_STATUS_MODAL_CLOSE',
});

export const setActionBoxModalOpen = (orderId, paymentDate) => ({
  type: 'SET_TOGGLE_ACTION_BOX_MODAL',
  payload: { orderId, paymentDate },
});

// view order modal actions
export const setToggleDeleteCardModal = (data) => ({
  type: 'TOGGLE_DELETE_CARD_OPEN',
  payload: data,
});

export const setToggleEditCardModalOpen = (data) => ({
  type: 'TOGGLE_EDIT_CARD_MODAL_OPEN',
  payload: data,
});

export const setTogglePreviewCardModal = (data) => ({
  type: 'TOGGLE_PREVIEW_CARD_MODAL',
  payload: data,
});

export const setToggleAddCallModal = () => ({
  type: 'TOGGLE_ADD_CALL_MODAL_OPEN',
});

export const setLocationLinkIds = (ids) => ({
  type: 'SET_LOCATION_LINK_IDS',
  payload: ids,
});

export const setSelectedOrdersIds = (ordersIds) => ({
  type: 'SET_SELECTED_ORDERS_IDS',
  payload: ordersIds,
});

export const setIsCartModalOpen = (orderId) => ({
  type: 'SET_IS_CART_MODAL_OPEN',
  payload: orderId,
});

export const setToggleSupportTicketModal = () => ({
  type: 'TOGGLE_SUPPORT_TICKET_MODAL_OPEN',
});

// data table & filter

export const setRequiredParamsForQuickActionsTable = (params) => ({
  type: SET_REQUIRED_PARAMS_FOR_QUICK_ACTIONS_TABLE,
  params,
});

export const setQuickActionsTableRef = ({ ref }) => ({
  type: SET_QUICK_ACTIONS_TABLE_REF,
  ref,
});

const searchQuickActionsDataTable = (quickActionsData, searchKey) => {
  if (searchKey) {
    const filteredData = quickActionsData.filter((el) => {
      const id = `${el.id}`;
      const query = searchKey.toLowerCase();
      const printed = el.detailsPrinted ? 'printed' : 'not';
      const shareDetails = el.shareSender ? 'share details' : "don't";
      if (id.includes(searchKey)) {
        return el;
      }
      if (printed.includes(query)) {
        return el;
      }
      if (el.ops && el.ops.nickName.toLowerCase().includes(query)) {
        return el;
      }
      if (el.user && el.user.name && el.user.name.toLowerCase().includes(query)) {
        return el;
      }
      if (el.user && el.user.phone && el.user.phone.includes(query)) {
        return el;
      }
      if (shareDetails.includes(query)) {
        return el;
      }
      if (
        el.recipient &&
        el.recipient.recipentName &&
        el.recipient.recipentName.toLowerCase().includes(query)
      ) {
        return el;
      }
      if (
        el.recipient &&
        el.recipient.recipientPhone &&
        el.recipient.recipientPhone.includes(query)
      ) {
        return el;
      }
      if (
        el.recipient &&
        el.recipient.address &&
        el.recipient.address.toLowerCase().includes(query)
      ) {
        return el;
      }
      if (el.recipient && el.recipient.extra && el.recipient.extra.toLowerCase().includes(query)) {
        return el;
      }
      if (el.note && el.note.toLowerCase().includes(query)) {
        return el;
      }
      return null;
    });
    return filteredData.length > 0 ? filteredData : [];
  }
  return quickActionsData;
};

export const setCurrentQuickActionsTablePage = (pageNum) => ({
  type: SET_CURRENT_QUICK_ACTIONS_TABLE_PAGE,
  pageNum,
});

export const setTableData = (query) => async (dispatch, getState) => {
  const state = getState();
  const search = query ?? '';
  const pageNum = getCurrentQuickActionsTablePage({ state });
  const params = getQuickActionsTableParams({ state });
  const clonedParams = cloneDeep(params);
  clonedParams.page = `${pageNum}`;
  dispatch(setLoaderOpenTrue());
  try {
    const res = await QuickActionService.getOrderActionsData(clonedParams);
    dispatch({
      type: 'SET_TABLE_DATA',
      payload: res.data,
    });
    const data = {
      data: searchQuickActionsDataTable(res.data.data, search),
      page: pageNum - 1,
      totalCount: res.data.rowCount,
    };
    dispatch(setLoaderOpenFalse());
    return data;
  } catch (e) {
    errorLogger('DataTableAction:setTableData', e);
    toast.error('Something went wrong with quick actions');
    dispatch(setLoaderOpenFalse());
    // return new Promise.reject(e);
  }
};

export const setPremiumTableData = (query) => async (dispatch, getState) => {
  const state = getState();
  const search = query ?? '';
  const pageNum = getCurrentQuickActionsTablePage({ state });
  const params = getQuickActionsTableParams({ state });
  const clonedParams = cloneDeep(params);
  clonedParams.page = `${pageNum}`;
  dispatch(setLoaderOpenTrue());
  try {
    const res = await QuickActionService.getPremiumQuickActionsData({ ...clonedParams, line: 1 });
    dispatch({
      type: 'SET_TABLE_DATA',
      payload: res.data,
    });
    const data = {
      data: searchQuickActionsDataTable(res.data.data, search),
      page: pageNum - 1,
      totalCount: res.data.rowCount,
    };
    dispatch(setLoaderOpenFalse());
    return data;
  } catch (e) {
    errorLogger('DataTableAction:setPremiumTableData', e);
    toast.error('Something went wrong with quick actions');
    dispatch(setLoaderOpenFalse());
    // eslint-disable-next-line new-cap
    return new Promise.reject(e);
  }
};

export const filterQuickActions = (filtersObject, navigate, premium) => (dispatch) => {
  if (navigate) {
    const queryString = convertObjectIntoQueryParams(filtersObject);

    history.push({
      pathname: premium ? getPremiumQuickActionsPageUrl() : getOrderQuickActionsPageUrl(),
      search: queryString,
    });
  }
  dispatch(setCurrentQuickActionsTablePage(1));
  dispatch(setRequiredParamsForQuickActionsTable(filtersObject));
  // forceReloadQuickActionsTable(state);
  if (premium) {
    dispatch(setPremiumTableData(''));
  } else {
    dispatch(setTableData(''));
  }
};

export const setRequiredTableRowData =
  ({ id, field }) =>
  (dispatch) => {
    dispatch({
      type: 'SET_REQUIRED_TABLE_ROW_DATA',
      id,
      field,
    });
  };

export const updateActionRecepientAddress =
  ({ id, updatedData }) =>
  (dispatch) => {
    dispatch({
      type: 'UPDATE_ACTION_RECEPIENT_ADDRESS',
      id,
      updatedData,
    });
  };

export const updateOrderPriority = (orderId, priorityId) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ORDER_PRIORITY',
    orderId,
    priorityId,
  });
};

export const setTimeSlotData = () => async (dispatch) => {
  try {
    const res = await QuickActionService.getTimeSlot();
    dispatch({
      type: 'SET_TIME_SLOT_DATA',
      payload: res.data.data,
    });
  } catch (e) {
    errorLogger('DataTableAction:setTimeSlotData', e);
    // console.log(e);
  }
};

export const setUserAgentData = () => async (dispatch) => {
  try {
    const res = await QuickActionService.getUserAgent();
    dispatch({
      type: 'SET_USER_AGENT_DATA',
      payload: res.data.data,
    });
  } catch (e) {
    errorLogger('DataTableAction:setUserAgentData', e);
    // console.log(e);
  }
};

export const setFilterAreas = () => async (dispatch) => {
  try {
    const res = await QuickActionService.getFilterAreas();
    dispatch({
      type: 'SET_FILTER_AREAS',
      payload: res.data,
    });
  } catch (e) {
    errorLogger('DataTableAction:setFilterAreas', e);
    // console.log(e);
  }
};

export const setOrderActionsData = () => async (dispatch) => {
  const promisesArray = [];
  // dispatch(setLoaderOpenTrue());
  try {
    promisesArray.push(
      dispatch(setTimeSlotData()),
      dispatch(setUserAgentData()),
      dispatch(setFilterAreas())
    );
    await Promise.all(promisesArray);
    // dispatch(setLoaderOpenFalse());
  } catch (e) {
    errorLogger('DataTableAction:setOrderActionsData', e);
    // dispatch(setLoaderOpenFalse());
    toast.error('Sorry, No data to be viewed');
  }
};

export const setDupliactedAndReplacedOrdersData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await QuickActionService.getDuplicatedAndReplacedOrders(params);
    dispatch({
      type: 'GET_DUPLICATED_REPLACED_ORDERS_DATA',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('DataTableAction:setDupliactedAndReplacedOrdersData', err);
    toast.error('Failed to get orders data!');
    dispatch(setLoaderOpenFalse());
  }
};

// ship onFleet
export const setBulkActionsOnFleet = (orderIds) => async (dispatch) => {
  const bodyData = {
    orderIds,
  };
  const params = {
    providers: 0,
  };
  try {
    await QuickActionService.getBulkActionsShipOnfleet(bodyData, params);
    setTimeout(() => {
      dispatch(setTableData(''));
      toast.success('Shipped to onFleet successfully');
    }, 1500);
  } catch (err) {
    errorLogger('DataTableAction:setBulkActionsOnFleet', err);
    toast.error(err?.response?.data?.message ?? 'Failed to ship onFleet');
  }
};

export const setBulkPriorityStatus = (ids, status) => async (dispatch) => {
  const body = { OrdersId: ids, OrderPriority: status };
  try {
    const res = await QuickActionService.setPriorityStatus(body);
    toast.success(res?.data?.message ?? 'Order priority updated');
    setTimeout(() => {
      dispatch(setTableData(''));
    }, 1500);
  } catch (err) {
    errorLogger('DataTableAction:setBulkPriorityStatus', err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
};

export const setBulkPrintStatus = (ids, status) => async (dispatch) => {
  const body = { OrdersId: ids, Printed: status };
  try {
    const res = await QuickActionService.setPrintStatus(body);
    toast.success(res?.data?.message ?? 'Order print status updated');
    setTimeout(() => {
      dispatch(setTableData(''));
    }, 1500);
  } catch (err) {
    errorLogger('DataTableAction:setBulkPrintStatus', err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
};

export const getZones = () => async (dispatch) => {
  try {
    const res = await QuickActionService.getZones();
    dispatch({
      type: 'GET_ZONES_FILTER_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('DataTableAction:getZones', err);
  }
};
