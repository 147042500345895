import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import DailyTrackerDataGrid from 'components/reports/orders/DailyTrackerDataGrid';
import DailySpendDataGrid from 'components/reports/orders/DailySpendDataGrid';
import DesignersDailyCommisionsDataGrid from 'components/reports/orders/DesignersDailyCommisionsDataGrid';
import AcquisitionPerformanceDataGrid from 'components/reports/orders/AcquisitionPerformanceDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import OrdersReportService from 'services/reportsService/OrdersReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DailyTrackerReport = () => {
  const [dailyTrackerData, setDailyTrackerData] = useState([]);
  const [dailySpendData, setDailySpendData] = useState([]);
  const [designersCommission, setDesignerCommission] = useState([]);
  const [acquisitionData, setAcquisitionData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    datetype: 0,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchDailyTrackerData = async (params) => {
    try {
      const res = await OrdersReportService.getDailyTrackerReport(params);
      setDailyTrackerData(res.data);
    } catch (err) {
      errorLogger('Daily Tracker Report Page Fetch Daily Tracker Data Error:', err);
      toast.error('Failed to daily tracker data!!');
    }
  };

  const fetchDailySpendData = async (params) => {
    try {
      const res = await OrdersReportService.getDailySpendReport(params);
      setDailySpendData(res.data);
    } catch (err) {
      errorLogger('Daily Tracker Report Page Fetch Daily Spend Data Error:', err);
      toast.error('Failed to get daily spend data!!');
    }
  };

  const fetchDesignerDailyCommisionData = async (params) => {
    try {
      const res = await OrdersReportService.getDesignersDailyCommisions(params);
      setDesignerCommission(res.data);
    } catch (err) {
      errorLogger('Daily Tracker Report Page Fetch Designer Daily Commision Data Error:', err);
      toast.error('Failed to daily commision data!!');
    }
  };

  const fetchAcquisitionPerformanceReport = async (params) => {
    try {
      const res = await OrdersReportService.getAcquisitionPerformanceReport(params);
      setAcquisitionData(res.data);
    } catch (err) {
      errorLogger('Daily Tracker Report Page Fetch Acquisition Performance Data Error:', err);
      toast.error('Failed to get performance data!!');
    }
  };

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    const promisesArray = [];
    try {
      promisesArray.push(
        fetchDailyTrackerData(params),
        fetchDailySpendData(params),
        fetchDesignerDailyCommisionData(params),
        fetchAcquisitionPerformanceReport(params)
      );
      await Promise.all(promisesArray);
      dispatch(setLoaderOpenFalse());
    } catch (e) {
      errorLogger('Daily Tracker Report Page Fetch All Data Error:', e);
      dispatch(setLoaderOpenFalse());
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: '/report/FlowardSalesReport',
      search: queryString,
    });
    fetchData(inputValues);
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        productType
        defaultDate
        title="Daily Tracker"
      />
      <DailyTrackerDataGrid data={dailyTrackerData} />
      <DailySpendDataGrid data={dailySpendData} />
      <DesignersDailyCommisionsDataGrid data={designersCommission} />
      <AcquisitionPerformanceDataGrid data={acquisitionData} />
    </div>
  );
};

export default DailyTrackerReport;
