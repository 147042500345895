import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { dateFormatDDMM } from 'constant/Helpers';
import { toast } from 'react-toastify';
// api
import OrdersReportService from 'services/reportsService/OrdersReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const FinancialOrdersDataGrid = ({ data, inputValues }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = async (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        try {
          const res = await OrdersReportService.exportFinancialOrdersReport({
            ...inputValues,
            page: -1,
          });
          toast.success(res?.data?.message);
        } catch (err) {
          errorLogger('Financial Orders Report Page Export Data Error:', err);
          toast.error('Failed to export excel file!!');
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={false}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowSorting={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Result Date"
              template={(rowData) => (
                <span>{rowData.resultDate != null && dateFormatDDMM(rowData.resultDate)}</span>
              )}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective headerText="City" field="city" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Name"
              template={(rowData) => (
                <a
                  className="primary-color"
                  href={`/admin/users/${rowData.userId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {rowData.name}
                </a>
              )}
              width={150}
              textAlign="Center"
            />
            <ColumnDirective headerText="Email" field="email" width={150} textAlign="Center" />
            <ColumnDirective
              headerText="Order#"
              template={(rowData) => (
                <a
                  className="primary-color"
                  href={`/order/${rowData.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {rowData.id}
                </a>
              )}
              field="id"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Wallet"
              field="paidFromWallet"
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Grand Total"
              field="grandTotal"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Payment Through"
              template={(rowData) => (
                <span>
                  {rowData.paymentMethod} ({rowData.paymentVendor})
                </span>
              )}
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, ColumnChooser, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(FinancialOrdersDataGrid);
