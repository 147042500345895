import axios from 'axios';
import { setBaseUrl } from './SetBaseUrl';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { store } from 'store';
import { getDecodedToken } from 'store/app/AppSelectors';
import { toast } from 'react-toastify';
// actions
import { setIsLoggedInFalse } from 'store/app/AppActions';
// constants
import { localStorageKeys } from 'constant/Enums';

const base = setBaseUrl();
// home
export const apiHomeService = axios.create({
  baseURL: base.home,
});
// hub
export const apiHubService = axios.create({
  baseURL: base.hub,
});
export const apiProdHubService = axios.create({
  baseURL: 'https://hubapi.floward.io/api',
});
// user
export const apiUserService = axios.create({
  baseURL: base.user,
  headers: { Authorization: `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}` },
});
// log
export const apiLogService = axios.create({
  baseURL: base.logs,
});
// order
export const apiOrderService = axios.create({
  baseURL: base.order,
});
// gateway
export const apiGatewayService = axios.create({
  baseURL: base.gateway,
  headers: { Authorization: `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}` },
});
export const apiProdGatewayService = axios.create({
  baseURL: 'https://api2.prod.floward.io',
  headers: { Authorization: `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}` },
});
// product
export const apiProductService = axios.create({
  baseURL: base.product,
  headers: { Authorization: `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}` },
});
// catalog
export const apiCatalogService = axios.create({
  baseURL: base.catalog,
  headers: { Authorization: `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}` },
});

// DRIVER
export const apiDriverService = axios.create({
  baseURL: base.driver,
  headers: { Authorization: `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}` },
});

// generate shorter link
export const apiGenerateShorterLink = axios.create({
  baseURL: 'https://flrd.cc/api',
  headers: {
    Authorization: `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}`,
    'x-account-id': process.env.REACT_APP_XACCOUNT_ID,
  },
});

const requestInterceptor = (config) => {
  config.headers.flwuser = getDecodedToken({ state: store.getState() }).Id;
  config.headers.opsId = getFromLocalStorage(localStorageKeys.OPS_ID);
  config.headers.OpsCountryID = getFromLocalStorage(localStorageKeys.COUNTRY_ID);
  config.headers.Authorization = `Bearer ${getFromLocalStorage(localStorageKeys.ACCESS_TOKEN)}`;
  config.headers.lang = 1;
  return config;
};

const requestInterceptorError = (error) => {
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  response.headers['X-Robots-Tag'] = 'noindex';
  return response;
};

const responseInterceptorError = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      toast.error('401 Unauthorized');
      store.dispatch(setIsLoggedInFalse());
    }
  }

  return Promise.reject(error);
};

// request
apiHomeService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiHubService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiUserService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiLogService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiOrderService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiGatewayService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiProdGatewayService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiProductService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiCatalogService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiDriverService.interceptors.request.use(requestInterceptor, requestInterceptorError);
apiProdHubService.interceptors.request.use(requestInterceptor, requestInterceptorError);

// response
apiHomeService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiHubService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiUserService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiLogService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiOrderService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiGatewayService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiProdGatewayService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiProductService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiCatalogService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiDriverService.interceptors.response.use(responseInterceptor, responseInterceptorError);
apiProdHubService.interceptors.response.use(responseInterceptor, responseInterceptorError);
