import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { toast } from 'react-toastify';
// actions
import { setInventorySuppliersData } from 'store/inventory/InventoryActions';
// api
import InventoryService from 'services/InventoryService';

const InventorySuppliersDataGrid = ({ data, handleClick }) => {
  const toolbarOptions = ['Search'];
  const ref = useRef();
  const dispatch = useDispatch();

  const removeTemplate = (rowData) => (
    <i className="fa fa-trash inventory-supp-trash" onClick={() => handleRemoveItem(rowData)} />
  );

  const handleRemoveItem = async (rowData) => {
    try {
      await InventoryService.deletInventorySupplier(rowData.id);
      toast.success('Supplier removed successfully!');
      setTimeout(() => {
        dispatch(setInventorySuppliersData());
      }, 1500);
    } catch (e) {
      toast.error('Failed to remove inventory supplier!');
    }
  };

  const companyNameTemplate = (rowData) => (
    <span onClick={() => handleClick(rowData)} className="supp-company-name">
      {rowData.companyName}
    </span>
  );

  const statusTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${
          rowData.isActive ? 'btn-light-success' : 'btn-light-danger'
        }`}
      >
        {rowData.isActive ? 'Active' : 'Inactive'}
      </button>
    );
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          ref={ref}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Company Name"
              field="companyName"
              template={companyNameTemplate}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective headerText="Phone" field="phone" width={100} textAlign="Center" />
            <ColumnDirective headerText="Email" field="email" width={120} textAlign="Center" />
            <ColumnDirective headerText="Address" field="address" width={120} textAlign="Center" />
            <ColumnDirective
              field="isActive"
              headerText="Status"
              template={statusTemplate}
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(InventorySuppliersDataGrid);
