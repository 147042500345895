import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import UsersDataGrid from './UsersDataGrid';
import PageTitle from 'components/shared/PageTitle';
import DJHPagination from 'components/shared/Pagination';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SearchUsers = () => {
  const [searchKey, setSearchKey] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setcurrentPage] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [rowCount, setRowCount] = useState('');
  const dispatch = useDispatch();

  const fetchUsers = async (i) => {
    const params = {
      searchkey: searchKey,
      page: i ?? 1,
    };
    dispatch(setLoaderOpenTrue());
    try {
      const res = await PermissionService.getSearchUsers(params);
      setData(res.data.data);
      setcurrentPage(res.data.currentPage);
      setPageSize(res.data.pageSize);
      setRowCount(res.data.rowCount);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('SearchUsers:fetchSearchUsersList', err);
      dispatch(setLoaderOpenFalse());
    }
  };

  const handleSearch = async () => {
    fetchUsers();
  };

  const onPageChange = async (i) => {
    fetchUsers(i);
    document.documentElement.scrollTop = 0;
  };

  const submitForm = (e) => {
    if (e.isComposing || e.keyCode === 229) {
      return;
    }
    if (e.key === 'Enter') {
      fetchUsers();
    }
  };
  return (
    <div className="no-toolbar-table-style">
      <PageTitle title="Users" />
      <div className="card">
        <div className="card-header p-18">
          <h4>Users</h4>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          onKeyDown={submitForm}
          className="form theme-form"
        >
          <div className="card-body">
            <div className="form-group col-12 col-sm-6">
              <label htmlFor="search-user">Search User</label>
              <input
                id="search-user"
                className="form-control"
                type="text"
                placeholder="Phone, email, first name, last name"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
            </div>
          </div>
          <div className="card-footer ">
            <button onClick={handleSearch} className="btn btn-primary mr-1" type="button">
              Search
            </button>
          </div>
        </form>
      </div>
      <UsersDataGrid data={data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </div>
  );
};

export default SearchUsers;
