import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Media } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import SectionLoader from 'components/common/SectionLoader';
import RestrictedSection from 'components/shared/RestrictedSection';
// modals
import DeliveryChargeModal from './modals/DeliveryChargeModal';
import ApplyCopounModal from './modals/ApplyCopounModal';
import FavoriteFlowersModal from './modals/FavoriteFlowersModal';
// actions
import { setCartDetails } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getOrderCartDetails } from 'store/viewOrder/ViewOrderSelectors';

const CartDetails = ({
  carts,
  subTotal,
  delivery,
  discount,
  vat,
  flwWallet,
  total,
  currency,
  isAction,
  orderId,
  id,
  vatPercentage,
  orderData,
  paidAmount,
}) => {
  const cartData = useSelector((state) => getOrderCartDetails({ state }));
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const dispatch = useDispatch();
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isDeliveryChargeModalOpen, setIsDeliveryChargeModalOpen] = useState(false);
  const [isApplyCopounModalOpen, setIsApplyCopounModalOpen] = useState(false);
  const [isFavFlowersModalOpen, setIsFavFlowersModalOpen] = useState(false);
  const [clickedCartId, setClickedCartId] = useState('');

  const quickActionscartColumns = [
    'Product',
    'Product name',
    'Cake message',
    'Qty',
    'Price',
    'Subtotal',
  ];
  const cartColumns = [
    'Product',
    'Product name',
    'Cake message',
    'Qty',
    'Price',
    'Subtotal',
    'Favorite flowers',
  ];

  const orderTotalPrice = [
    { title: 'Subtotal', sum: subTotal, currency },
    { title: 'Delivery charges', sum: delivery, currency },
    {
      title: `Discount ${orderData && orderData.couponCode ? `(${orderData.couponCode})` : ''}`,
      sum: discount,
      currency,
    },
    { title: `VAT (${vatPercentage}%)`, sum: vat, currency },
    { title: 'FLW Wallet', sum: flwWallet, currency },
    { title: 'Cost', sum: orderData && orderData.cost ? orderData.cost : '', currency },
    { title: 'Total', sum: total, currency },
    { title: 'Paid amount', sum: paidAmount, currency },
  ];

  const fetchData = async () => {
    if (isAction) {
      setIsLoaderOpen(true);
      await dispatch(setCartDetails(orderId));
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="card height-equal cart-details-wrapper">
      {isDeliveryChargeModalOpen && !isAction && (
        <DeliveryChargeModal
          isDeliveryChargeModalOpen={isDeliveryChargeModalOpen}
          setIsDeliveryChargeModalOpen={setIsDeliveryChargeModalOpen}
          orderId={id}
        />
      )}
      {isApplyCopounModalOpen && !isAction && (
        <ApplyCopounModal
          isApplyCopounModalOpen={isApplyCopounModalOpen}
          setIsApplyCopounModalOpen={setIsApplyCopounModalOpen}
          orderId={id}
        />
      )}
      {isFavFlowersModalOpen && (
        <FavoriteFlowersModal
          isFavFlowersModalOpen={isFavFlowersModalOpen}
          setIsFavFlowersModalOpen={setIsFavFlowersModalOpen}
          cartId={clickedCartId}
        />
      )}
      <div className="row">
        <div className="col-12">
          <div className="height-equal">
            {!isAction && (
              <Fragment>
                {orderData.status === 0 && (
                  <div className="card-header">
                    <RestrictedSection page="order_details" action="edit_items">
                      <button
                        onClick={() => {
                          window.open(`/editInvoice/${id}`, '_blank');
                        }}
                        className="btn btn-primary cart-details-btn"
                      >
                        <i className="fa fa-edit"></i> Edit items
                      </button>
                    </RestrictedSection>
                    <RestrictedSection page="order_details" action="delivery_charge">
                      <button
                        onClick={() => setIsDeliveryChargeModalOpen((prev) => !prev)}
                        className="btn btn-secondary cart-details-btn"
                      >
                        <i className="fa fa-car"></i> Delivery Charge
                      </button>
                    </RestrictedSection>
                    <RestrictedSection page="order_details" action="apply_copoun">
                      <button
                        onClick={() => setIsApplyCopounModalOpen((prev) => !prev)}
                        className="btn btn-success cart-details-btn"
                      >
                        <i className="fa fa-money-file-alt"></i> Apply Coupon
                      </button>
                    </RestrictedSection>
                  </div>
                )}
              </Fragment>
            )}
            <div className="card-body">
              <div className="order-history table-responsive">
                <table className="col-12 table table-bordernone">
                  <thead>
                    {isAction ? (
                      <tr>
                        {quickActionscartColumns.map((element, i) => (
                          <th scope="col" key={i}>
                            {element}
                          </th>
                        ))}
                      </tr>
                    ) : (
                      <tr>
                        {cartColumns.map((element, i) => {
                          if (orderData.type === 4 || element !== 'Favorite flowers') {
                            return (
                              <th scope="col" key={i}>
                                {element}
                              </th>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                      </tr>
                    )}
                  </thead>
                  {isAction ? (
                    <Fragment>
                      {isLoaderOpen && window.screen.width > 500 ? (
                        <tbody className="modal-loader-wrapper">
                          <tr>
                            <td>
                              <SectionLoader />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <Fragment>
                          {cartData !== '' && (
                            <tbody>
                              {cartData.map((cart, i) => (
                                <Fragment key={i}>
                                  <tr>
                                    <td>
                                      <img className="img-fluid img-90" src={cart.image} alt="#" />
                                    </td>
                                    <td>
                                      <div className="product-name">
                                        <div>{cart.proName}</div>
                                        <a
                                          alt={cart.productSKU}
                                          href={`/product/editProduct/${cart.proId}/0`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {cart.productSKU}
                                        </a>
                                      </div>
                                    </td>
                                    <td>{cart.cakeMessage ? cart.cakeMessage : '-'}</td>
                                    <td
                                      className={`${
                                        cart.qty > 1 ? 'cart-items-qty' : 'cart-item-qty'
                                      }`}
                                    >
                                      {cart.qty}
                                    </td>
                                    <td>{cart.price}</td>
                                    <td>{cart.qty * cart.price}</td>
                                  </tr>
                                  <tr>
                                    <td colSpan="1"></td>
                                    <td className="row " colSpan="4">
                                      {cart.basketItems.map((item, i) => (
                                        <div
                                          key={i}
                                          className="col-sm-6 col-md-4 col-lg-2 small-slick basket-item-wrapper"
                                        >
                                          <img src={item.product.image} alt="" />
                                          <div className="sub-product-name">
                                            <a
                                              alt={item.product.name}
                                              href={`/product/editProduct/${item.product.id}/0`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {item.product.name}
                                            </a>
                                            <p>Qty: {item.qty}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </td>
                                  </tr>
                                </Fragment>
                              ))}
                            </tbody>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <tbody>
                      {carts.map((cart, i) => (
                        <Fragment key={i}>
                          <tr>
                            <td className="img-cursor">
                              <Media
                                src={cart.image}
                                alt="#"
                                className="img-thumbnail img-fluid img-60"
                                onClick={() => {
                                  setIsImgOpen(true);
                                  setImageSrc(cart.image);
                                }}
                              />
                            </td>
                            <td>
                              <div className="product-name">
                                <div>{cart.proName}</div>
                                <a
                                  alt={cart.productSKU}
                                  href={`/product/editProduct/${cart.proId}/0`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {cart.productSKU}
                                </a>
                              </div>
                            </td>
                            <td>{cart.cakeMessage ? cart.cakeMessage : '-'}</td>
                            <td className={`${cart.qty > 1 ? 'cart-items-qty' : 'cart-item-qty'}`}>
                              {cart.qty}
                            </td>
                            <td>{cart.price}</td>
                            <td>{cart.qty * cart.price}</td>
                            {orderData.type === 4 && (
                              <td>
                                <span
                                  onClick={() => {
                                    setClickedCartId(cart.id);
                                    setIsFavFlowersModalOpen((prev) => !prev);
                                  }}
                                  className="show-fav-flowers"
                                >
                                  Show
                                </span>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td colSpan="1"></td>
                            <td className="row " colSpan="4">
                              {cart.basketItems.map((item, i) => (
                                <div
                                  key={i}
                                  className=" col-sm-6 col-md-4 col-lg-2 small-slick basket-item-wrapper"
                                >
                                  <img src={item.product.image} alt="" />
                                  <div className="sub-product-name">
                                    <a
                                      alt={item.product.name}
                                      href={`/product/editProduct/${item.product.id}/0`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.product.name}
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                  )}
                  {!isAction && (
                    <tfoot>
                      {orderTotalPrice.map((item, i) => (
                        <tr key={i}>
                          <td colSpan="4"></td>
                          <td className="align-left">{item.title}</td>
                          <td>
                            {item.sum} {item.currency}
                          </td>
                        </tr>
                      ))}
                    </tfoot>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isImgOpen && <Lightbox onCloseRequest={() => setIsImgOpen(false)} mainSrc={imageSrc} />}
    </div>
  );
};

export default CartDetails;
