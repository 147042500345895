import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import SalesReportDataGrid from 'components/reports/sales/SalesReportDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  convertToUnix,
  convertFromUnix,
} from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import SalesReportService from 'services/reportsService/SalesReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SalesReportPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: convertToUnix(),
    toDate: convertToUnix(),
    datetype: 0,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await SalesReportService.getSalesReport(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Sales Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.fromDate = convertFromUnix(inputValues.fromDate);
    updatedParams.toDate = convertFromUnix(inputValues.toDate);
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: '/report/SalesReport',
      search: queryString,
    });
    fetchData(updatedParams);
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        productType
        title="Sales Report"
      />
      <SalesReportDataGrid data={data} />
    </div>
  );
};

export default SalesReportPage;
