import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import CategoriesDataGrid from './CategoriesDataGrid';
import CategoriesFilter from './CategoriesFilter';
import CategoriesActions from './CategoriesActions';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// actions
import { getCategoriesData } from 'store/categories/CategoriesActions';
// selectors
import { getTotalCategoriesData } from 'store/categories/CategoriesSelectors';

const CategoriesDataTable = ({ inputValues, setInputValues }) => {
  const categoriesData = useSelector((state) => getTotalCategoriesData({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (params) => {
    const queryParams = params ?? inputValues;
    const queryString = convertObjectIntoQueryParams(queryParams);

    navigate({
      pathname: '/categories',
      search: queryString,
    });
    dispatch(getCategoriesData(queryParams));
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(getCategoriesData(queryObj));
    } else {
      if (!categoriesData) {
        dispatch(getCategoriesData(inputValues));
      }
    }
  }, []);

  return (
    <div className="quick-actions-table toolbar-table-style">
      <CategoriesActions type="category" inputValues={inputValues} />
      <CategoriesFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
      />
      <CategoriesDataGrid data={categoriesData} inputValues={inputValues} />
    </div>
  );
};

export default CategoriesDataTable;
