import React from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
} from '@syncfusion/ej2-react-grids';
// material UI
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

const OrdersZonesDataTable = ({ ordersZones }) => {
  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6">Orders Zones</Typography>
      </Toolbar>
      <div className="quick-actions-table mui-table-styling">
        <div className="control-pane">
          <div className="control-section">
            <GridComponent
              dataSource={ordersZones}
              allowTextWrap={true}
              allowSorting={true}
              allowSelection={false}
              enableHover={false}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="count"
                  headerText="No. of orders"
                  headerTextAlign="Left"
                  width={50}
                />
                <ColumnDirective
                  field="deliveryZone"
                  headerText="Zones"
                  headerTextAlign="Left"
                  width={50}
                />
                <ColumnDirective
                  field="deliveryTimeName"
                  headerText="Delivery Time"
                  headerTextAlign="Left"
                  width={50}
                />
              </ColumnsDirective>
              <Inject services={[Sort]} />
            </GridComponent>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default OrdersZonesDataTable;
