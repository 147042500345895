import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ActionsModal from './ActionsModal';
import { toast } from 'react-toastify';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const AdminEditPage = () => {
  const [page, setPage] = useState({});
  const [pageName, setPageName] = useState(page.name ? page.name : '');
  const [pageKey, setPageKey] = useState(page.key ? page.key : '');
  const [pageActions, setPageActions] = useState(page.actions);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [isActionsModalOpen, setIsActionsmodalOpen] = useState(false);

  const fetchData = async () => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await PermissionService.getPermissionPageById(params?.id);
      setPage(res.data);
      setPageName(res.data.name);
      setPageKey(res.data.key);
      setPageActions(res.data.actions);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('AdminEditPage:getPageById', err);
      dispatch(setLoaderOpenFalse());
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdatePage = async () => {
    const bodyData = {
      Id: page.id,
      Name: pageName,
      Key: pageKey,
    };
    try {
      await PermissionService.updatePermissionPage(bodyData);
      navigate('/admin/pages');
    } catch (err) {
      errorLogger('AdminEditPage:handleUpdatePage', err);
      toast.error(err?.response?.data?.message ?? 'Failed to update page');
    }
  };

  return (
    <>
      {isActionsModalOpen && (
        <ActionsModal
          isActionsModalOpen={isActionsModalOpen}
          setIsActionsmodalOpen={setIsActionsmodalOpen}
          page={page}
          setPageActions={setPageActions}
        />
      )}
      <div className="card col-12 col-md-6">
        <div className="card-header">
          <h6>Update Page</h6>
        </div>
        <div className="card-body btn-showcase">
          <form>
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Name
              </label>
              <input
                onChange={(e) => setPageName(e.target.value)}
                className="form-control"
                id="message-text"
                value={pageName}
              ></input>
            </div>
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Key
              </label>
              <input
                onChange={(e) => setPageKey(e.target.value)}
                className="form-control"
                id="message-text"
                value={pageKey}
              ></input>
            </div>
          </form>
          <div className="align-center">
            <button onClick={handleUpdatePage} className="btn btn-primary">
              Update
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h6>Page Actions</h6>
          <button
            onClick={() => {
              dispatch({
                type: 'SET_PERMISSION_ACTION_DATA',
                payload: '',
              });
              setIsActionsmodalOpen((prev) => !prev);
            }}
            className="btn btn-success"
          >
            <i className="fa fa-plus"></i> Add New Action
          </button>
        </div>
        <div className="recent-notification card-body">
          <div className="table-responsive text-center">
            <table className="table table-bordernone">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Key</th>
                  <th></th>
                </tr>
              </thead>
              {pageActions && (
                <tbody>
                  {pageActions.map((action, i) => (
                    <tr key={i}>
                      <td>{action.name}</td>
                      <td>{action.key}</td>
                      <td>
                        <button
                          onClick={() => {
                            dispatch({
                              type: 'SET_PERMISSION_ACTION_DATA',
                              payload: action,
                            });
                            setIsActionsmodalOpen((prev) => !prev);
                          }}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-edit"></i> Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminEditPage;
