import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { addToLocalStorage, token } from 'localStorageService/LocalStorage';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// selectors
import { getPermissionGroupData } from 'store/permissions/PermissionsSelector';
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
// api
import PermissionService from 'services/PermissionService';
import AuthService from 'services/AuthService';
import CustomSelect from 'components/shared/CustomSelect';
// constants
import { localStorageKeys, userRoles } from 'constant/Enums';
// datadog
import { errorLogger } from 'datadog/DDUtils';
import RestrictedSection from 'components/shared/RestrictedSection';

const AdminEditGroup = () => {
  const [accActive, setAccActive] = useState(false);
  const opsCenters = useSelector((state) => getOpsCenters({ state }));
  const group = useSelector((state) => getPermissionGroupData({ state }));
  const [opsId, setOpsId] = useState(group.accessType ? -1 : group.opsId);
  const [groupType, setGroupType] = useState(group.groupType || 0);
  const [newGroupName, setNewGroupName] = useState(group.name ? group.name : '');
  const [checkedActions, setCheckedActions] = useState([]);
  const [isCountryLevel, setIsCountryLevel] = useState(!!group.accessType);
  const [countryId, setCountryId] = useState(group.accessType ? group.opsId : -1);
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [pageData, setPageData] = useState([]);
  const [clonedPages, setClonedPages] = useState([]);

  const fetchData = async () => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await PermissionService.getGroupActions(params?.id);
      setPageData(res.data);
      setClonedPages(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('AdminEditGroup:fetchGroupActions', err);
      dispatch(setLoaderOpenFalse());
    }
  };

  useEffect(() => {
    dispatch(setSignInSuccess());
    fetchData();
  }, []);

  useEffect(() => {
    const countriesData = [];
    opsCenters.forEach((o) => {
      if (
        countriesData.filter(function (c) {
          return c.id === o.country.id;
        }).length === 0
      ) {
        countriesData.push(o.country);
      }
    });
    setCountries(countriesData);
  }, [opsCenters]);

  useEffect(() => {
    pageData.forEach((page) => {
      page.actions.forEach((action) => {
        if (action.checked) {
          checkedActions.push(action.id);
        }
      });
    });
  }, [pageData]);

  const handleUpdateGroup = async () => {
    const bodyData = {
      Id: params?.id,
      Name: newGroupName,
      opsId: isCountryLevel ? countryId : opsId,
      accessType: isCountryLevel ? 1 : 0,
      GroupType: groupType,
    };
    try {
      await PermissionService.UpdateGroup(bodyData);
      toast.success('Group updated successfully!');
      navigate('/admin/groups');
    } catch (err) {
      errorLogger('AdminEditGroup:handleUpdateGroup', err);
      toast.error('Failed to update group!');
    }
  };

  const handleCheckedAction = (e, id) => {
    if (checkedActions.includes(parseInt(e.target.value, 10))) {
      pageData.forEach((page) => {
        page.actions.forEach((action) => {
          if (action.id === id) {
            action.checked = false;
          }
        });
      });
      const checkedItems = checkedActions.filter((el) => el !== parseInt(e.target.value, 10));
      setCheckedActions(checkedItems);
    } else {
      pageData.forEach((page) => {
        page.actions.forEach((action) => {
          if (action.id === id) {
            action.checked = true;
          }
        });
      });
      checkedActions.push(parseInt(e.target.value, 10));
    }
  };

  const handleUpdateGroupActions = async () => {
    const bodyData = {
      Actions: checkedActions,
    };
    dispatch(setLoaderOpenTrue());
    try {
      await PermissionService.UpdateGroupActions(bodyData, params?.id);
      toast.success('New Actions Updated!');
      dispatch(setLoaderOpenFalse());
      setTimeout(async () => {
        const newParams = {
          opsid: opsId,
          countryId,
        };
        const userPermissions = await AuthService.getUserPermissions(newParams, token);
        addToLocalStorage(localStorageKeys.PERMISSIONS, userPermissions.data);
      }, 2000);
    } catch (err) {
      errorLogger('AdminEditGroup:handleUpdateGroupActions', err);
      toast.error('Failed to update actions!');
      dispatch(setLoaderOpenFalse());
    }
  };

  const handleOpsChange = (e) => {
    setOpsId(e.itemData.id);
  };

  const handleCountryChange = (e) => {
    setCountryId(e.itemData.id);
  };

  const handleSearchPage = (e) => {
    if (e.target.value) {
      const searchResult = clonedPages.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setPageData(searchResult);
    } else {
      setPageData(clonedPages);
    }
  };

  const handleGroupTypeChange = (e) => {
    setGroupType(+e.itemData.id);
  };

  return (
    <Fragment>
      <div className="card col-12 col-md-6">
        <div className="card-header">
          <h6>Update Group</h6>
        </div>
        <div className="card-body btn-showcase">
          <form>
            <div className="row">
              <div className="form-group col-12">
                <label className="col-form-label" htmlFor="message-text">
                  Name
                </label>
                <input
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                  className="form-control"
                  id="message-text"
                ></input>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-6 filter-select">
                <label className="d-block" htmlFor="groupType">
                  Group Type
                </label>
                <CustomSelect
                  id="groupType"
                  listData={userRoles}
                  handleChange={handleGroupTypeChange}
                  placeholder="Select a group"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={groupType}
                />
              </div>
              <div className="form-group col-6">
                <label className="d-block" htmlFor="accessType">
                  Access Type
                </label>
                <label className="d-block" htmlFor="accessType">
                  <input
                    className="checkbox_animated"
                    id="accessType"
                    type="checkbox"
                    checked={isCountryLevel}
                    onChange={(e) => setIsCountryLevel((prev) => !prev)}
                  />
                  Country Level
                </label>
              </div>
            </div>
            <div className="row">
              {isCountryLevel ? (
                <div className="form-group col-12 filter-select">
                  <label className="col-form-label" htmlFor="message-text">
                    Countries
                  </label>
                  {opsCenters !== '' && (
                    <CustomSelect
                      listData={[{ id: -1, name: 'Select a country' }].concat(countries)}
                      handleChange={handleCountryChange}
                      placeholder="Select a country"
                      textPropName="name"
                      valuePropName="id"
                      selectedValue={countryId}
                    />
                  )}
                </div>
              ) : (
                <div className="form-group col-12 filter-select">
                  <label className="col-form-label" htmlFor="message-text">
                    Ops
                  </label>
                  {opsCenters !== '' && (
                    <CustomSelect
                      listData={[{ id: -1, nickName: 'Central' }].concat(opsCenters)}
                      handleChange={handleOpsChange}
                      placeholder="Select an ops"
                      textPropName="nickName"
                      valuePropName="id"
                      selectedValue={opsId}
                    />
                  )}
                </div>
              )}
            </div>
          </form>
          <div>
            <RestrictedSection page="admin_groups" action="update_group_details">
              <button
                onClick={handleUpdateGroup}
                className="btn btn-primary"
                disabled={!newGroupName}
              >
                Update
              </button>
            </RestrictedSection>
          </div>
        </div>
      </div>

      <div className="card col-12">
        <div className="card-header between-flex">
          <div>
            <h6>Group Actions</h6>
          </div>
          <div className="form-group group-action-save">
            <input
              className="form-control"
              type="text"
              placeholder="Search Page"
              onChange={handleSearchPage}
            />
          </div>
          <RestrictedSection page="admin_groups" action="update_group_actions">
            <button onClick={handleUpdateGroupActions} className="btn btn-primary">
              Save
            </button>
          </RestrictedSection>
        </div>
        <div className="col-12 card-body">
          {pageData.map((page, i) => (
            <Accordion key={i} allowZeroExpanded>
              <AccordionItem>
                <div className="col-4 card group-accordion">
                  <AccordionItemHeading onClick={() => setAccActive((prev) => !prev)}>
                    <AccordionItemButton>
                      <div className="card-header">
                        <h6 className="mb-0">{page.name}</h6>
                        {accActive ? (
                          <i className="fa fa-chevron-up"></i>
                        ) : (
                          <i className="fa fa-chevron-down"></i>
                        )}
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="card-body animate-chk">
                      {page.actions.map((action, i) => (
                        <label key={action.id} className="d-block">
                          <input
                            className="checkbox_animated"
                            id={`check${i}`}
                            type="checkbox"
                            value={action.id}
                            defaultChecked={action.checked}
                            onChange={(e) => handleCheckedAction(e, action.id)}
                          />
                          {action.name}
                        </label>
                      ))}
                    </div>
                  </AccordionItemPanel>
                </div>
              </AccordionItem>
            </Accordion>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default AdminEditGroup;
