import { updateObject } from '../../utility';
// lodash
import cloneDeep from 'lodash/cloneDeep';
// action types
import {
  SET_CURRENT_QUICK_ACTIONS_TABLE_PAGE,
  SET_QUICK_ACTIONS_TABLE_REF,
  SET_REQUIRED_PARAMS_FOR_QUICK_ACTIONS_TABLE,
  TOGGLE_QUICK_ACTIONS_IS_PRINTED_BTN_DISABLED,
  TOGGLE_QUICK_ACTIONS_IS_SHARE_DETAILS_BTN_DISABLED,
} from './DataTableActionTypes';

const initialState = {
  isNoteModalOpen: false,
  isNoteForTeamModalOpen: false,
  isCardModalOpen: false,
  isAddressModalOpen: false,
  isMapLinkModalOpen: false,
  isActionBoxModalOpen: false,
  addressDetails: '',
  mapLinkDetails: '',
  isChangeStatusModalOpen: false,
  isCartModalOpen: false,
  orderId: '',
  paymentDate: '',
  rowNote: '',
  noteForTeam: '',
  orderCstep: '',
  tableData: {},
  timeSlot: [],
  userAgent: [],
  areas: [],
  // view order modals
  isEditCardModalOpen: false,
  isDeleteCardModalOpen: false,
  cardData: '',
  isAddCallModalOpen: '',
  isSupportTicketModalOpen: '',
  // qyick actions data table
  orderStatus: 2,
  orderProdLine: 0,
  updatedCstep: '',
  locationLinkIds: '',
  selectedOrdersIds: [],
  quickActionsTableParams: {},
  quickActionsTableRef: null,
  isPrintedBtnDisabled: {},
  isShareDetailsBtnDisabled: {},
  currentQuickActionsTablePage: 1,
  isPriorityModalOpen: false,
  priorityId: '',
  duplicatedReplacedOrdersData: {},
  isPreviewCardModalOpen: false,
  filterZones: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_NOTE_MODAL_OPEN': {
      return updateObject(state, {
        isNoteModalOpen: !state.isNoteModalOpen,
        orderId: action.payload.orderId,
        rowNote: action.payload.rowNote,
      });
    }

    case 'TOGGLE_NOTE_FOR_TEAM_MODAL_OPEN': {
      return updateObject(state, {
        isNoteForTeamModalOpen: !state.isNoteForTeamModalOpen,
        noteForTeam: action.payload,
      });
    }

    case 'TOGGLE_PRIORITY_MODAL_OPEN': {
      return updateObject(state, {
        isPriorityModalOpen: !state.isPriorityModalOpen,
        orderId: action.payload.orderId,
        priorityId: action.payload.priorityId,
      });
    }

    case 'UPDATE_PRIORITY': {
      return updateObject(state, {
        priorityId: action.payload.priorityId,
      });
    }

    case 'TOGGLE_CARD_MODAL_OPEN': {
      return updateObject(state, {
        isCardModalOpen: !state.isCardModalOpen,
        orderId: action.payload,
      });
    }

    case 'SET_TOGGLE_ACTION_BOX_MODAL': {
      return updateObject(state, {
        isActionBoxModalOpen: !state.isActionBoxModalOpen,
        orderId: action.payload.orderId,
        paymentDate: action.payload.paymentDate,
      });
    }

    case 'TOGGLE_ADDRESS_MODAL_OPEN': {
      return updateObject(state, {
        isAddressModalOpen: !state.isAddressModalOpen,
        addressDetails: action.payload,
      });
    }

    case 'TOGGLE_MAP_LINK_MODAL_OPEN': {
      return updateObject(state, {
        isMapLinkModalOpen: !state.isMapLinkModalOpen,
        mapLinkDetails: action.payload,
      });
    }

    case 'SET_CHANGE_STATUS_MODAL_OPEN': {
      return updateObject(state, {
        isChangeStatusModalOpen: true,
        orderId: action.payload.orderId,
        orderCstep: action.payload.orderCstep,
      });
    }
    case 'SET_CHANGE_STATUS_MODAL_CLOSE': {
      return updateObject(state, {
        isChangeStatusModalOpen: false,
      });
    }

    case 'SET_TABLE_DATA': {
      const isPrintedBtnDisabled = {};
      const isShareDetailsBtnDisabled = {};
      if (action.payload) {
        action.payload.data.forEach((el) => {
          isPrintedBtnDisabled[el.id] = false;
          isShareDetailsBtnDisabled[el.id] = false;
        });
      }
      return updateObject(state, {
        tableData: action.payload,
        isPrintedBtnDisabled,
        isShareDetailsBtnDisabled,
      });
    }

    case 'SET_REQUIRED_TABLE_ROW_DATA': {
      const tableData = cloneDeep(state.tableData);
      const {
        id,
        field: { name, value },
      } = action;
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].id === id) {
          tableData[i][name] = value;
          break;
        }
      }
      return updateObject(state, { tableData });
    }

    case 'UPDATE_ACTION_RECEPIENT_ADDRESS': {
      const tableData = cloneDeep(state.tableData);
      const { id, updatedData } = action;
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].id === id) {
          tableData[i].recipient = updatedData;
          break;
        }
      }
      return updateObject(state, { tableData });
    }

    case 'UPDATE_ORDER_PRIORITY': {
      const tableData = cloneDeep(state.tableData);
      const { orderId, priorityId } = action;
      for (let i = 0; i < tableData.data.length; i++) {
        if (tableData.data[i].id === orderId) {
          tableData.data[i].orderPriority = priorityId;
          break;
        }
      }
      return updateObject(state, { tableData });
    }

    case 'SET_TIME_SLOT_DATA': {
      return updateObject(state, {
        timeSlot: action.payload,
      });
    }

    case 'SET_USER_AGENT_DATA': {
      return updateObject(state, {
        userAgent: action.payload,
      });
    }

    case 'SET_FILTER_AREAS': {
      return updateObject(state, {
        areas: action.payload,
      });
    }

    case 'SET_ORDER_STATUS': {
      return updateObject(state, {
        orderStatus: action.payload,
      });
    }

    case 'SET_ORDER_PROD_LINE': {
      return updateObject(state, {
        orderProdLine: action.payload,
      });
    }

    case 'SET_UPDATED_CSTEP': {
      return updateObject(state, {
        updatedCstep: action.payload,
      });
    }

    case 'SET_LOCATION_LINK_IDS': {
      return updateObject(state, {
        locationLinkIds: action.payload,
      });
    }

    case 'SET_SELECTED_ORDERS_IDS': {
      return updateObject(state, {
        selectedOrdersIds: action.payload,
      });
    }

    case 'SET_IS_CART_MODAL_OPEN': {
      return updateObject(state, {
        isCartModalOpen: !state.isCartModalOpen,
        orderId: action.payload,
      });
    }

    // view Order modals reducers

    case 'TOGGLE_EDIT_CARD_MODAL_OPEN': {
      return updateObject(state, {
        isEditCardModalOpen: !state.isEditCardModalOpen,
        cardData: action.payload,
      });
    }

    case 'TOGGLE_PREVIEW_CARD_MODAL': {
      return updateObject(state, {
        isPreviewCardModalOpen: !state.isPreviewCardModalOpen,
        cardData: action.payload,
      });
    }

    case 'TOGGLE_DELETE_CARD_OPEN': {
      return updateObject(state, {
        isDeleteCardModalOpen: !state.isDeleteCardModalOpen,
        cardData: action.payload,
      });
    }

    case 'TOGGLE_ADD_CALL_MODAL_OPEN': {
      return updateObject(state, {
        isAddCallModalOpen: !state.isAddCallModalOpen,
      });
    }

    case 'TOGGLE_SUPPORT_TICKET_MODAL_OPEN': {
      return updateObject(state, {
        isSupportTicketModalOpen: !state.isSupportTicketModalOpen,
      });
    }

    case 'GET_ZONES_FILTER_DATA': {
      return updateObject(state, {
        filterZones: action.payload,
      });
    }

    // data table
    case SET_QUICK_ACTIONS_TABLE_REF: {
      return updateObject(state, { quickActionsTableRef: action.ref });
    }
    case SET_REQUIRED_PARAMS_FOR_QUICK_ACTIONS_TABLE: {
      return updateObject(state, { quickActionsTableParams: action.params });
    }
    case TOGGLE_QUICK_ACTIONS_IS_PRINTED_BTN_DISABLED: {
      const isPrintedBtnDisabled = cloneDeep(state.isPrintedBtnDisabled);
      isPrintedBtnDisabled[action.id] = !isPrintedBtnDisabled[action.id];
      return updateObject(state, { isPrintedBtnDisabled });
    }
    case TOGGLE_QUICK_ACTIONS_IS_SHARE_DETAILS_BTN_DISABLED: {
      const isShareDetailsBtnDisabled = cloneDeep(state.isShareDetailsBtnDisabled);
      isShareDetailsBtnDisabled[action.id] = !isShareDetailsBtnDisabled[action.id];
      return updateObject(state, { isShareDetailsBtnDisabled });
    }
    case SET_CURRENT_QUICK_ACTIONS_TABLE_PAGE: {
      return updateObject(state, { currentQuickActionsTablePage: action.pageNum });
    }

    case 'GET_DUPLICATED_REPLACED_ORDERS_DATA': {
      return updateObject(state, {
        duplicatedReplacedOrdersData: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
