import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// urls
import { getKpiPageUrl } from 'constant/AppUrls';
// actions
import { getAllSalesPerformance } from 'store/kpi/KpiActions';
// selectors
import {
  getSalesPerformanceByCountryData,
  getSalesPerformanceData,
  getSalesPerformanceByChannelData,
} from 'store/kpi/KpiSelectors';
// components
import SectionLoader from 'components/common/SectionLoader';
const SalesPerformanceByCountryDataGrid = React.lazy(() =>
  import('components/kpi/SalesPerformanceByCountryDataGrid')
);
const SalesPerformanceDataGrid = React.lazy(() =>
  import('components/kpi/SalesPerformanceDataGrid')
);
const SalesPerformanceByChannelDataGrid = React.lazy(() =>
  import('components/kpi/SalesPerformanceByChannelDataGrid')
);
const PriceBreakDownFilter = React.lazy(() =>
  import('components/reports/products/priceBreakDown/PriceBreakDownFilter')
);

const KpiSummaryPage = () => {
  const [inputValues, setInputValues] = useState({
    fromDate: '',
    toDate: '',
  });
  const salesPerformanceByCountry = useSelector((state) =>
    getSalesPerformanceByCountryData({ state })
  );
  const salesPerformance = useSelector((state) => getSalesPerformanceData({ state }));
  const salesPerformanceByChannel = useSelector((state) =>
    getSalesPerformanceByChannelData({ state })
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(getAllSalesPerformance(params));
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleKpiDateRangeChange = (params) => {
    const queryString = convertObjectIntoQueryParams(params);
    navigate({
      pathname: getKpiPageUrl(),
      search: queryString,
    });
    fetchData(params);
  };

  return (
    <div className="toolbar-table-style">
      <React.Suspense fallback={<SectionLoader />}>
        <PriceBreakDownFilter
          inputValues={inputValues}
          setInputValues={setInputValues}
          title=""
          defaultDate
          kpi={true}
          handleKpiDateRangeChange={handleKpiDateRangeChange}
        />
        <SalesPerformanceByCountryDataGrid data={salesPerformanceByCountry} />
        <SalesPerformanceDataGrid data={salesPerformance} />
        <SalesPerformanceByChannelDataGrid data={salesPerformanceByChannel} />
      </React.Suspense>
    </div>
  );
};

export default KpiSummaryPage;
