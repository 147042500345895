import React, { useEffect, useState, Fragment } from 'react';
// modals
import DeleteProductMasterInventoryModal from '../modals/DeleteProductMasterInventoryModal';
import AddNewInvenoryMasterModal from '../modals/AddNewInvenoryMasterModal';
import RequiredQty from '../components/RequiredQty';
// dataGrid
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  DetailRow,
  Page,
  Sort,
} from '@syncfusion/ej2-react-grids';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';

const ProductMasterInventoryDataGrid = ({
  productId,
  productMasterInventoryDataById,
  productOptionsById,
  isValid,
  notValidFields,
  validateMI,
}) => {
  const [availabilityArr, setAvailabilityArr] = useState([]);
  const [isDeleteMasterInvModalOpen, setIsDeleteMasterInvModalOpen] = useState(false);
  const [isAddMasterInvModalOpen, setIsAddMasterInvModalOpen] = useState(false);
  const [centeralId, setCenteralId] = useState('');

  const updatedArr = [];

  const imgTemplate = (rowData) => (
    <img alt={rowData.name} src={rowData.fullImage} className="w-70p" />
  );

  useEffect(() => {
    productMasterInventoryDataById.forEach((item) => {
      updatedArr.push(...item.availability);
    });
    setAvailabilityArr(updatedArr);
  }, [productMasterInventoryDataById]);

  const childGrid = {
    dataSource: availabilityArr,
    queryString: 'centralId',
    allowSorting: true,
    rowHeight: 60,
    textAlign: 'Center',
    columns: [
      { field: 'countryName', headerText: 'Country', textAlign: 'Center' },
      { field: 'nickName', headerText: 'Ops', textAlign: 'Center' },
      { field: 'qty', headerText: 'Available Qty', textAlign: 'Center' },
    ],
  };

  const requiredQtyTemplate = (rowData) => <RequiredQty rowData={rowData} />;

  const removeTemplate = (rowData) => (
    <button
      className="btn product-group-action btn-danger"
      onClick={() => {
        setCenteralId(rowData.centralId);
        setIsDeleteMasterInvModalOpen((prev) => !prev);
      }}
    >
      <i className="fa fa-trash"></i>
    </button>
  );
  return (
    <Fragment>
      <button
        onClick={() => setIsAddMasterInvModalOpen((prev) => !prev)}
        type="button"
        className="btn btn-success new-product-group-btn m-b-20"
        disabled={productOptionsById?.status === 1}
      >
        <i className="fa fa-plus"></i> Add New
      </button>
      {!isValid && notValidFields?.includes('min range for master inventory') && (
        <InlineErrorMessage
          message={'Please add at least 1 “Inventory Item“ to publish the product'}
        />
      )}
      {!isValid && notValidFields?.includes('max range for master inventory') && (
        <InlineErrorMessage
          message={'You can only add up to 20 inventory items here, please delete some items.'}
        />
      )}
      {availabilityArr.length > 0 && (
        <div className="control-pane">
          <div className="control-section">
            <GridComponent
              dataSource={productMasterInventoryDataById}
              allowTextWrap={true}
              childGrid={childGrid}
              allowSorting={true}
              allowSelection={false}
            >
              <ColumnsDirective>
                <ColumnDirective
                  headerText="Image"
                  field="fullImage"
                  template={imgTemplate}
                  textAlign="Center"
                  width={120}
                />
                <ColumnDirective headerText="Name" field="name" textAlign="Center" width={100} />
                <ColumnDirective
                  headerText="Barcode"
                  field="barCode"
                  textAlign="Center"
                  width={100}
                />
                <ColumnDirective
                  headerText="Required Qty"
                  field="requiredQty"
                  template={requiredQtyTemplate}
                  textAlign="Center"
                  width={100}
                />
                {productOptionsById?.status !== 1 && (
                  <ColumnDirective
                    headerText="Remove"
                    template={removeTemplate}
                    textAlign="Center"
                    width={100}
                  />
                )}
              </ColumnsDirective>
              <Inject services={[DetailRow, Page, Sort]} />
            </GridComponent>
          </div>
        </div>
      )}
      {isDeleteMasterInvModalOpen && (
        <DeleteProductMasterInventoryModal
          centeralId={centeralId}
          isDeleteMasterInvModalOpen={isDeleteMasterInvModalOpen}
          setIsDeleteMasterInvModalOpen={setIsDeleteMasterInvModalOpen}
          validateMI={validateMI}
        />
      )}
      {isAddMasterInvModalOpen && (
        <AddNewInvenoryMasterModal
          isAddMasterInvModalOpen={isAddMasterInvModalOpen}
          setIsAddMasterInvModalOpen={setIsAddMasterInvModalOpen}
          productId={productId}
          validateMI={validateMI}
        />
      )}
    </Fragment>
  );
};

export default React.memo(ProductMasterInventoryDataGrid);
