import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';

const AttributesDataGrid = ({ data }) => {
  const toolbarOptions = ['ColumnChooser', 'Search'];
  const ref = useRef();

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <Link to={`/attributes/${rowData.id}/edit`} className="total-txt link categoryid-link">
          {parseInt(rowData.id, 10)}
        </Link>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <Link to={`/attributes/${rowData.id}/edit`} className="total-txt link categoryid-link">
        {rowData.name}
      </Link>
    );
  };

  const subAttributeTemplate = (rowData) => {
    return (
      <Link to={`/attributes/${rowData.id}/sub`} className="cat-icon-cell">
        <i className="fa fa-align-justify"></i>
      </Link>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <button
        className={`btn generic-status ${
          rowData.isActive ? 'btn-light-success' : 'btn-light-danger'
        }`}
      >
        {rowData.isActive ? 'Active' : 'Inactive'}
      </button>
    );
  };

  return (
    <>
      <div className="control-pane">
        <div className="control-section">
          <GridComponent
            dataSource={data}
            toolbar={toolbarOptions}
            ref={ref}
            allowTextWrap={true}
            showColumnChooser={true}
            allowSorting={true}
          >
            <ColumnsDirective>
              <ColumnDirective field="id" headerText="ID" template={idTemplate} width={100} />
              <ColumnDirective field="name" headerText="Name" template={nameTemplate} width={100} />
              <ColumnDirective
                field="subattribut"
                headerText="Sub Attribute"
                template={subAttributeTemplate}
                width={100}
              />
              <ColumnDirective
                field="isActive"
                headerText="Status"
                template={statusTemplate}
                width={100}
                customAttributes={{ class: 'category-status' }}
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, ColumnChooser, Sort]} />
          </GridComponent>
        </div>
      </div>
    </>
  );
};

export default React.memo(AttributesDataGrid);
