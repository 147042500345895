import React, { useState } from 'react';
import AreaModal from './modals/AreaModal';
import RestrictedSection from 'components/shared/RestrictedSection';

const AreasActions = ({ type, inputValues }) => {
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);

  return (
    <>
      {isAreaModalOpen && (
        <AreaModal
          isAreaModalOpen={isAreaModalOpen}
          setIsAreaModalOpen={setIsAreaModalOpen}
          type={type}
          inputValues={inputValues}
        />
      )}
      <div className="card">
        <div className="card-body btn-showcase">
          <RestrictedSection
            page="areas"
            action={type === 'area' ? 'create_area' : 'create_subarea'}
          >
            <button
              onClick={() => setIsAreaModalOpen((prev) => !prev)}
              type="button"
              className="btn btn-success"
            >
              <i className="fa fa-plus"></i> Create {type === 'area' ? 'Area' : 'Sub Area'}
            </button>
          </RestrictedSection>
        </div>
      </div>
    </>
  );
};

export default AreasActions;
