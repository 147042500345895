// connection status
export const getNotificationsStatus = ({ state }) => state.notifications.notificationStatus;
// all notifications
export const getOldNotificationsList = ({ state }) => state.notifications.oldNotifications;
export const getNewNotificationList = ({ state }) => state.notifications.newNotifications;
export const getnewNotificationsLength = ({ state }) => state.notifications.newNotificationsLength;
// notifications pagination
export const getNotificationsPageSize = ({ state }) => state.notifications.pageSize;
export const getNotificationsCurrentPage = ({ state }) => state.notifications.currentPage;
export const getNotificationsRowCount = ({ state }) => state.notifications.rowCount;
// order notifications
export const getOldOrderNotificationList = ({ state }) => state.notifications.orderOldNotifications;
