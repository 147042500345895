import React, { useState } from 'react';
import { MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import { useDispatch } from 'react-redux';
import {
  setAddProductAttributeById,
  setDeleteProductAttributeById,
} from 'store/products/ProductsActions';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';

const MCAttributes = ({
  fields,
  attrData,
  selectedAttrData,
  productId,
  validateMC,
  isValid,
  notValidFields,
  isAuthorized,
  productOptions,
}) => {
  const dispatch = useDispatch();
  const [remount, setRemount] = useState(1);

  const handleAddAttribute = (e) => {
    dispatch(setAddProductAttributeById(productId, e?.itemData.id, e?.itemData, validateMC));
  };

  const handleDeleteAttribute = (e) => {
    dispatch(
      setDeleteProductAttributeById(productId, e?.itemData?.id, e?.itemData?.attID, validateMC)
    );

    setTimeout(() => {
      const filterValues = document.getElementById('checkbox')?.ej2_instances[0]?.value;
      if (filterValues?.length === 1) {
        setRemount(remount + 1);
      }
    }, 0);
  };

  const getValue = (fieldID) => {
    return selectedAttrData?.filter(
      (id) => attrData?.find((el) => el?.id === id)?.attID === fieldID
    );
  };

  return (
    <div className="card-body">
      {fields?.map((field) => (
        <div key={field?.id} className="col-md-6 col-xl-8 categories-field-box">
          <label className="bold-title" htmlFor="prod-cat-addon">
            {field?.name}
            {field?.isMandatory && <span className="requird-asterisks ml-1">*</span>}
          </label>
          <div className="control-pane">
            <div className="control-section filter-select p-l-6">
              <div>
                <div className="control-styles">
                  <div>
                    <AppErrorBoundary>
                      <MultiSelectComponent
                        id="checkbox"
                        key={`${field?.name}-${remount}`}
                        dataSource={attrData?.filter((el) => el?.attID === field?.id)}
                        value={getValue(field?.id)}
                        filterBarPlaceholder={`Search ${field?.name}`}
                        fields={{ text: 'name', value: 'id' }}
                        placeholder={`Select ${field?.name}`}
                        mode="CheckBox"
                        showDropDownIcon={true}
                        enableSelectionOrder={false}
                        popupHeight="300px"
                        select={handleAddAttribute}
                        removed={handleDeleteAttribute}
                        openOnClick="false"
                        disabled={!isAuthorized || productOptions?.status === 1}
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    </AppErrorBoundary>
                    {!isValid && notValidFields?.includes(field?.name) && (
                      <InlineErrorMessage message={`Please add "${field?.name}"`} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MCAttributes;
