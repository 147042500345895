/* eslint-disable indent */
// store
import { store } from 'store';
// action types
import {
  FETCH_NEW_NOTIFICATIONS,
  FETCH_NEW_NOTIFICATIONS_SUCCESS,
  FETCH_NEW_NOTIFICATIONS_FAIL,
  FETCH_NEW_ORDER_NOTIFICATIONS,
  FETCH_NEW_ORDER_NOTIFICATIONS_SUCCESS,
  FETCH_NEW_ORDER_NOTIFICATIONS_FAIL,
  SAVE_RECEIVED_OLD_ORDER_NOTIFICATIONS,
  SAVE_RECEIVED_NEW_NOTIFICATIONS,
  SAVE_RECEIVED_NEW_ORDER_NOTIFICATIONS,
  SAVE_RECEIVED_OLD_NOTIFICATIONS,
  SET_SEND_TO_CHANNEL,
} from '../NotificationsActionTypes';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import NotificationsService from 'services/NotificationsService';
// constants
import { localStorageKeys } from 'constant/Enums';

const handShake = 'B8FnsNNsmrH5lBT0kvqdV8DHYXLUIBQA==@#';
const channelid = 'ch-2';
const opsId = getFromLocalStorage(localStorageKeys.OPS_ID);

const access = { channelid, _handshake: handShake };

export const setSendToChannel = () => ({
  type: 'socket',
  types: [SET_SEND_TO_CHANNEL],
  promise: (socket) => socket.emit('uconnect', access),
});

// All notifications
export const fetchNewNotifications = () => ({
  type: 'socket',
  types: [FETCH_NEW_NOTIFICATIONS, FETCH_NEW_NOTIFICATIONS_SUCCESS, FETCH_NEW_NOTIFICATIONS_FAIL],
  promise: (socket) =>
    socket.on(`orders_log_${opsId}`, (notifications) => {
      store.dispatch(saveReceivedNotifications({ notifications }));
    }),
});

export const getOldNotifications = (i) => async (dispatch) => {
  const params = {
    page: i,
    opsid: opsId,
  };
  dispatch(setLoaderOpenTrue());
  try {
    const res = await NotificationsService.getOldNotifications(params);
    dispatch({
      type: SAVE_RECEIVED_OLD_NOTIFICATIONS,
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    dispatch(setLoaderOpenFalse());
  }
};

const saveReceivedNotifications =
  ({ notifications }) =>
  (dispatch) => {
    dispatch({
      type: SAVE_RECEIVED_NEW_NOTIFICATIONS,
      notifications,
    });
  };

// Order Notifications
export const getOldOrderNotifications = (orderId) => async (dispatch) => {
  try {
    const res = await NotificationsService.getOrderOldNotifications(orderId);
    dispatch({
      type: SAVE_RECEIVED_OLD_ORDER_NOTIFICATIONS,
      payload: res.data,
    });
  } catch (err) {
    // console.log(err);
  }
};

export const fetchOrderNewNotifications = (orderId) => ({
  type: 'socket',
  types: [
    FETCH_NEW_ORDER_NOTIFICATIONS,
    FETCH_NEW_ORDER_NOTIFICATIONS_SUCCESS,
    FETCH_NEW_ORDER_NOTIFICATIONS_FAIL,
  ],
  promise: (socket) =>
    socket.on(`orders_log_${orderId}`, (notifications) => {
      store.dispatch(saveReceivedOrderNotifications({ notifications }));
    }),
});

const saveReceivedOrderNotifications = ({ notifications }) => ({
  type: SAVE_RECEIVED_NEW_ORDER_NOTIFICATIONS,
  notifications,
});

// unsubscripe order notifications
// export const unSubscripeOrderNotifications = (orderId) => ({
// type: 'socket',
// promise: (socket) => {
// console.log('unsubscribed');
// socket.off(`orders_log_${orderId}`);
// },
// });
