import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// actions
import { setOccasionsListData } from 'store/occasions/OccasionsActions';
// selectors
import { getOcccasionsList, getOcccasionsCardsList } from 'store/occasions/OccasionsSelectors';
// components
import SectionLoader from 'components/common/SectionLoader';
const OccasionsDataGrid = React.lazy(() => import('components/occasions/OccasionsDataGrid'));

const OccasionsPage = () => {
  const occasionsList = useSelector((state) => getOcccasionsList({ state }));
  const occasionsCardsList = useSelector((state) => getOcccasionsCardsList({ state }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOccasionsListData());
  }, []);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <OccasionsDataGrid occasionsList={occasionsList} data={occasionsCardsList} />
      </React.Suspense>
    </div>
  );
};

export default OccasionsPage;
