export const getTotalCategoriesData = ({ state }) => state.categories.categoriesData;

export const getTotalCategoriesPageSize = ({ state }) => state.categories.pageSize;

export const getTotalCategoriesCurrentPage = ({ state }) => state.categories.currentPage;

export const getTotalCategoriesRowCount = ({ state }) => state.categories.rowCount;

export const getSelectedCategory = ({ state }) => state.categories.selectedCategory;

export const getSubCategoriesData = ({ state }) => state.categories.subCategories;

export const getParentCategoryData = ({ state }) => state.categories.parentCategory;

export const getSelectedSubCategory = ({ state }) => state.categories.selectedSubCategory;

export const getCategoryOpsData = ({ state }) => state.categories.categoryOps;
