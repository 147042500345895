import { HasPermission } from './Helpers';

export const validateDetailsTab = async (productDetails, productType) => {
  let detailsValidity = { isValid: true, notValidFields: [] };
  const isBasketOrAddon = productType === 5 || productType === 4;
  if (!productDetails.name || productDetails.name === '') {
    detailsValidity.isValid = false;
    detailsValidity.notValidFields.push('name');
  }
  if (!productDetails.nameAr || productDetails.nameAr === '') {
    detailsValidity.isValid = false;
    detailsValidity.notValidFields.push('name arabic');
  }
  if (
    !isBasketOrAddon &&
    (!productDetails.description || productDetails.description.trim() === '')
  ) {
    detailsValidity.isValid = false;
    detailsValidity.notValidFields.push('description');
  }
  if (
    !isBasketOrAddon &&
    (!productDetails.descriptionAr || productDetails.descriptionAr.trim() === '')
  ) {
    detailsValidity.isValid = false;
    detailsValidity.notValidFields.push('description arabic');
  }
  if (
    !isBasketOrAddon &&
    (!productDetails.shortDescription || productDetails.shortDescription.trim() === '')
  ) {
    detailsValidity.isValid = false;
    detailsValidity.notValidFields.push('short description');
  }
  if (
    !isBasketOrAddon &&
    (!productDetails.shortDescriptionAr || productDetails.shortDescriptionAr.trim() === '')
  ) {
    detailsValidity.isValid = false;
    detailsValidity.notValidFields.push('short Description arabic');
  }

  return detailsValidity;
};

export const validateMediaTab = (productMedia, productType) => {
  let mediaValidity = { isValid: true, notValidFields: [] };
  const isBasketOrAddon = productType === 5 || productType === 4;

  if (
    !productMedia.coverImage ||
    productMedia.coverImage === '' ||
    productMedia.coverImage.includes('image_placeholder')
  ) {
    mediaValidity.isValid = false;
    mediaValidity.notValidFields.push('Cover Image');
  }

  if (!isBasketOrAddon && (!productMedia.images || productMedia.images.length < 2)) {
    mediaValidity.isValid = false;
    mediaValidity.notValidFields.push('min range for images');
  }

  if (!isBasketOrAddon && (!productMedia.images || productMedia.images.length > 6)) {
    mediaValidity.isValid = false;
    mediaValidity.notValidFields.push('max range for images');
  }

  return mediaValidity;
};

export const validateLocalAvailability = async (productLocalAvailability, productOptions) => {
  const localAvailabilityValidity = { isValid: true, notValidFields: [] };
  // check that the product type is not Basket(5) or Variant(11)
  if (
    (!productLocalAvailability || productLocalAvailability?.displayPrice <= 0) &&
    productLocalAvailability?.active &&
    productOptions.type !== 5 &&
    productOptions.type !== 11
  ) {
    localAvailabilityValidity.isValid = false;
    localAvailabilityValidity.notValidFields.push('final price with vat');
  }

  return localAvailabilityValidity;
};

export const validateCategoriesTab = async (
  prodcutCategories,
  productOptions,
  everydayOccasionsSubList
) => {
  const categoriesValidity = { isValid: true, notValidFields: [] };
  const isDesigner = productOptions?.type === 1;
  const isBrand = productOptions?.type === 2;
  if (prodcutCategories.filter((el) => el.type === 8).length > 1) {
    categoriesValidity.isValid = false;
    categoriesValidity.notValidFields.push('max range for types');
  }

  if (
    prodcutCategories.filter(
      (el) =>
        el.type === 7 &&
        everydayOccasionsSubList.filter((item) => item.catKey === el.key).length > 0
    ).length > 5
  ) {
    categoriesValidity.isValid = false;
    categoriesValidity.notValidFields.push('max range for occasions');
  }

  if (isDesigner && prodcutCategories.filter((el) => el.type === 1).length < 1) {
    categoriesValidity.isValid = false;
    categoriesValidity.notValidFields.push('Designer');
  }

  if (isBrand && prodcutCategories.filter((el) => el.type === 4).length < 1) {
    categoriesValidity.isValid = false;
    categoriesValidity.notValidFields.push('Brand');
  }

  return categoriesValidity;
};

export const validateOptionsTab = async (productOptions) => {
  const optionsValidity = { isValid: true, notValidFields: [] };
  if (productOptions.type === null) {
    optionsValidity.isValid = false;
    optionsValidity.notValidFields.push('Type');
  }

  return optionsValidity;
};

export const validateMCTab = async (mcData, attrData, permissionsList, fields) => {
  const mcValidity = { isValid: true, notValidFields: [] };
  if (!HasPermission(permissionsList, 'edit_product', 'edit_mc')) {
    return mcValidity;
  }
  if (!mcData.mC0 && !mcData.MC0) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('Merchandising Category Level 0');
  }
  if (!mcData.mC1 && !mcData.MC1) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('Merchandising Category Level 1');
  }
  if (mcData.mC2 === '' || mcData.MC2 === '') {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('Merchandising Category Level 2');
  }
  if (mcData.mC3 === '' || mcData.MC3 === '') {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('Merchandising Category Level 3');
  }
  if (mcData.mC4 === '' || mcData.MC4 === '') {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('Merchandising Category Level 4');
  }
  const attr = attrData.reduce((prev, curr) => prev.concat(curr.productValueAtrributes), []);

  if (attr.length < 2) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('min range for attributes');
  }
  if (attr.length > 30) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('max range for attributes');
  }

  const bundleType = attrData?.find((el) => el?.key === 'bundleType');
  if (bundleType?.productValueAtrributes?.length > 1) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('max range for bundle types');
  }

  if (fields) {
    fields?.map((field) => {
      const fieldValues = attrData?.find((el) => el?.key === field?.key)?.productValueAtrributes;
      if (field.isMandatory && fieldValues?.length < 1) {
        mcValidity.isValid = false;
        mcValidity.notValidFields.push(field?.name);
      }
    });
  }

  const flowerProductType = attrData?.find((el) => el?.key === 'product-type');
  if (flowerProductType?.productValueAtrributes?.length > 1) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('max range for "Flower Product Type"');
  }

  const packagingSize = attrData?.find((el) => el?.key === 'packaging-size');
  if (packagingSize?.productValueAtrributes?.length > 1) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('max range for "Packaging Size"');
  }

  const flowerPackaging = attrData?.find((el) => el?.key === 'packaging');
  if (flowerPackaging?.productValueAtrributes?.length > 1) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('max range for "Flower Packaging"');
  }

  const checkMaxRange = attrData?.find((item) => item?.productValueAtrributes?.length > 5);
  if (checkMaxRange) {
    mcValidity.isValid = false;
    mcValidity.notValidFields.push('Max range of items per attribute');
  }

  return mcValidity;
};

export const validateMasterAvailabilityTab = async (maData, productOptions) => {
  const maValidity = { isValid: true, notValidFields: [] };
  maData?.forEach((item) => {
    item?.pricing.forEach((el) => {
      // check that the product type is not Basket(5) or Variant(11)
      if (
        (!el.displayPrice || Number(el?.displayPrice) <= 0) &&
        el?.active &&
        productOptions.type !== 5 &&
        productOptions.type !== 11
      ) {
        maValidity.isValid = false;
        maValidity.notValidFields.push(`final price with vat for ${el?.ops?.nickName}`);
      }
    });
  });

  return maValidity;
};

export const validateMasterInventoryTab = async (miData) => {
  const miValidity = { isValid: true, notValidFields: [] };
  if (miData.length < 1) {
    miValidity.isValid = false;
    miValidity.notValidFields.push('min range for master inventory');
  }
  if (miData.length > 20) {
    miValidity.isValid = false;
    miValidity.notValidFields.push('max range for master inventory');
  }
  return miValidity;
};

export const validateRankingTab = async (rankingData, maData, productOptions, permissionsList) => {
  const rankingValidity = { isValid: true, notValidFields: [] };
  if (!HasPermission(permissionsList, 'edit_product', 'edit_ranking')) {
    return rankingValidity;
  }
  const allMA = maData.reduce((prev, curr) => [...prev, ...curr.pricing], []);
  rankingData?.forEach((item) => {
    const isActive = allMA.find((el) => el?.opsId === item?.opsId)?.active;
    // check that the product type is not Basket(5) or Variant(11)
    if (!item.rank && isActive && productOptions.type !== 5 && productOptions.type !== 11) {
      rankingValidity.isValid = false;
      rankingValidity.notValidFields.push(`rank for ${item?.nickName}`);
    }
  });
  return rankingValidity;
};
