import React from 'react';
import CheckoutPaymentDetails from './CheckoutPaymentDetails';
import CheckoutLogsDetails from './CheckoutLogsDetails';

const CheckoutContent = ({ orderId, orderData }) => {
  return (
    <div>
      <CheckoutLogsDetails orderId={orderId} orderData={orderData} />
      <CheckoutPaymentDetails orderId={orderId} />
    </div>
  );
};

export default CheckoutContent;
