/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';
// api
import ProductsService from 'services/ProductsService';

const ProductDetailsSection = ({
  productId,
  productDetailsById,
  productOptionsById,
  setIsCustomizable,
  isValid,
  notValidFields,
  validateDetailsTab,
}) => {
  const [nameEn, setNameEn] = useState('');
  const [nameAr, setNameAr] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [basketMin, setBasketMin] = useState('');
  const [basketMax, setBasketMax] = useState('');
  const [messageEn, setMessageEn] = useState('');
  const [messageAr, setMessageAr] = useState('');
  const [deliveryDaysDuration, setDeliveryDaysDuration] = useState(0);
  const [cakeMessageLength, setCakeMessageLength] = useState('');
  const [cakeMessageEn, setCakeMessageEn] = useState('');
  const [cakeMessageAr, setCakeMessageAr] = useState('');
  const [shortDescriptioncEn, setShortDescriptionEn] = useState('');
  const [shortDescriptioncAr, setShortDescriptionAr] = useState('');
  const [descriptioncEn, setdescriptionEn] = useState('');
  const [descriptioncAr, setdescriptionAr] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [trackInventory, setTrackInventory] = useState(false);
  const [customizable, setCustomizable] = useState(false);
  const [motherDayDiscount, setMotherDayDiscount] = useState(false);
  const [notAvailableForDisccount, setNotAvailableForDiscount] = useState(false);
  const [pickupAvailable, setPickupAvailable] = useState(false);
  const [hidePrice, setHidePrice] = useState(false);
  const [showInValentineDay, setShowInValentineDay] = useState(false);
  const [showInMotherDay, setShowInMotherDay] = useState(false);
  const [expressDelivery, setExpressDelivery] = useState(false);
  const [motherDayGeneral, setMotherDayGeneral] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(productDetailsById).length > 0) {
      setNameEn(productDetailsById.name ? productDetailsById.name : '');
      setNameAr(productDetailsById.nameAr ? productDetailsById.nameAr : '');
      setWidth(productDetailsById.width ?? 0);
      setHeight(productDetailsById.height ?? 0);
      setWeight(productDetailsById.weight ?? 0);
      setBasketMin(productDetailsById.basketMinQty ?? '');
      setBasketMax(productDetailsById.basketMaxQty ?? '');
      setMessageEn(productDetailsById.messageEn ? productDetailsById.messageEn : '');
      setMessageAr(productDetailsById.messageAr ? productDetailsById.messageAr : '');
      setDeliveryDaysDuration(productDetailsById.deliveryDaysDuration ?? 0);
      setCakeMessageLength(productDetailsById.cakeMessageLength ?? '');
      setCakeMessageEn(productDetailsById.cakeMessageTitleEn ?? '');
      setCakeMessageAr(productDetailsById.cakeMessageTitleAr ?? '');
      setShortDescriptionEn(
        productDetailsById.shortDescription ? productDetailsById.shortDescription : ''
      );
      setShortDescriptionAr(
        productDetailsById.shortDescriptionAr ? productDetailsById.shortDescriptionAr : ''
      );
      setdescriptionEn(productDetailsById.description ? productDetailsById.description : '');
      setdescriptionAr(productDetailsById.descriptionAr ? productDetailsById.descriptionAr : '');
      setIsActive(productDetailsById.inStock ? productDetailsById.inStock : false);
      setTrackInventory(
        productDetailsById.trackInventory ? productDetailsById.trackInventory : false
      );
      setCustomizable(productDetailsById.customizable ? productDetailsById.customizable : false);
      setMotherDayDiscount(
        productDetailsById.mothersDayDiscount ? productDetailsById.mothersDayDiscount : false
      );
      setNotAvailableForDiscount(
        productDetailsById.notAvailableForDiscount
          ? productDetailsById.notAvailableForDiscount
          : false
      );
      setPickupAvailable(
        productDetailsById.pickupAvailable ? productDetailsById.pickupAvailable : false
      );
      setHidePrice(productDetailsById.hidePrice ? productDetailsById.hidePrice : false);
      setShowInValentineDay(
        productDetailsById.valentinesDayRestricted
          ? productDetailsById.valentinesDayRestricted
          : false
      );
      setShowInMotherDay(
        productDetailsById.mothersDayRestricted ? productDetailsById.mothersDayRestricted : false
      );
      setExpressDelivery(
        productDetailsById.expressDelivery ? productDetailsById.expressDelivery : false
      );
      setMotherDayGeneral(
        productDetailsById.mothersDayGeneralRestricted
          ? productDetailsById.mothersDayGeneralRestricted
          : false
      );
    }
  }, [productDetailsById]);

  const updateProductDetails = async () => {
    const dataBody = {
      name: nameEn,
      nameAR: nameAr,
      width: width ?? 0,
      height: height ?? 0,
      weight: weight ?? 0,
      basketMinQty: basketMin,
      basketMaxQty: basketMax,
      messageEn,
      messageAr,
      deliveryDaysDuration: deliveryDaysDuration ?? 0,
      cakeMessageLength,
      cakeMessageTitleEn: cakeMessageEn,
      cakeMessageTitleAr: cakeMessageAr,
      shortDescription: shortDescriptioncEn,
      shortDescriptionAr: shortDescriptioncAr,
      description: descriptioncEn,
      descriptionAr: descriptioncAr,
      inStock: isActive,
      trackInventory,
      customizable,
      mothersDayDiscount: motherDayDiscount,
      notAvailableForDiscount: notAvailableForDisccount,
      pickupAvailable,
      hidePrice,
      valentinesDayRestricted: showInValentineDay,
      mothersDayRestricted: showInMotherDay,
      expressDelivery,
      mothersDayGeneralRestricted: motherDayGeneral,
    };
    setDisableBtn(true);
    setIsLoaderOpen(true);
    try {
      const res = await ProductsService.updateProductDetails(dataBody, productId);
      toast.success(res?.data?.message ?? 'Updated Successfully');
      setDisableBtn(false);
      setIsCustomizable(customizable);
      setIsLoaderOpen(false);
      dispatch({
        type: 'PRODUCT_DETAILS_BY_ID',
        payload: { ...productDetailsById, ...dataBody },
      });
      validateDetailsTab({ ...productDetailsById, ...dataBody }, true);
    } catch (err) {
      setDisableBtn(false);
      setIsLoaderOpen(false);
      toast.error(err?.response?.data?.message ?? 'Failed to update product details');
    }
  };

  const isBasketOrAddon = productOptionsById?.type === 4 || productOptionsById?.type === 5;

  return (
    <>
      <div className="form-group col-12">
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <form className="row">
            <div className="form-group col-12 col-md-6">
              <label className="bold-title" htmlFor="prod-det-enName">
                Name English
                <span className="requird-asterisks ml-1">*</span>
              </label>
              <input
                id="prod-det-enName"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
                className="form-control"
                type="text"
              />
              {!isValid && notValidFields.includes('name') && (
                <InlineErrorMessage message={'Please add "Name English"'} />
              )}
            </div>
            <div className="form-group col-12 col-md-6">
              <label className="bold-title" htmlFor="prod-det-arName">
                Name Arabic
                <span className="requird-asterisks ml-1">*</span>
              </label>
              <input
                id="prod-det-arName"
                value={nameAr}
                onChange={(e) => setNameAr(e.target.value)}
                className="form-control"
                type="text"
              />
              {!isValid && notValidFields.includes('name arabic') && (
                <InlineErrorMessage message={'Please add "Name Arabic"'} />
              )}
            </div>
            <div className="form-group col-12 col-md-2">
              <label className="bold-title" htmlFor="prod-det-width">
                Width
              </label>
              <input
                id="prod-det-width"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
            <div className="form-group col-12 col-md-2">
              <label className="bold-title" htmlFor="prod-det-height">
                Height
              </label>
              <input
                id="prod-det-height"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
            <div className="form-group col-12 col-md-2">
              <label className="bold-title" htmlFor="prod-det-weight">
                Weight
              </label>
              <input
                id="prod-det-weight"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
            <div className="form-group col-12 col-md-2">
              <label className="bold-title" htmlFor="prod-det-minBasket">
                Basket Min
              </label>
              <input
                id="prod-det-minBasket"
                value={basketMin}
                onChange={(e) => setBasketMin(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
            <div className="form-group col-12 col-md-2">
              <label className="bold-title" htmlFor="prod-det-maxBasket">
                Basket Max
              </label>
              <input
                id="prod-det-maxBasket"
                value={basketMax}
                onChange={(e) => setBasketMax(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label className="bold-title" htmlFor="prod-det-enMsg">
                Add a message to the Product English
              </label>
              <input
                id="prod-det-enMsg"
                value={messageEn}
                onChange={(e) => setMessageEn(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label className="bold-title" htmlFor="prod-det-arMsg">
                Add a message to the Product Arabic
              </label>
              <input
                id="prod-det-arMsg"
                value={messageAr}
                onChange={(e) => setMessageAr(e.target.value)}
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label className="bold-title" htmlFor="prod-det-duration">
                Delivery days duration
              </label>
              <input
                id="prod-det-duration"
                value={deliveryDaysDuration}
                onChange={(e) => setDeliveryDaysDuration(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label className="bold-title" htmlFor="prod-det-maxLength">
                Cake Message Max length
              </label>
              <input
                id="prod-det-maxLength"
                value={cakeMessageLength}
                onChange={(e) => setCakeMessageLength(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label className="bold-title" htmlFor="prod-det-enCake">
                English Cake Message
              </label>
              <input
                id="prod-det-enCake"
                value={cakeMessageEn}
                onChange={(e) => setCakeMessageEn(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label className="bold-title" htmlFor="prod-det-arCake">
                Arabic Cake Message
              </label>
              <input
                id="prod-det-arCake"
                value={cakeMessageAr}
                onChange={(e) => setCakeMessageAr(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-det-enShort">
                Short Description English
                {!isBasketOrAddon && <span className="requird-asterisks ml-1">*</span>}
              </label>
              <textarea
                id="prod-det-enShort"
                value={shortDescriptioncEn}
                onChange={(e) => setShortDescriptionEn(e.target.value)}
                className="form-control"
                type="text"
              ></textarea>
              {!isBasketOrAddon && !isValid && notValidFields?.includes('short description') && (
                <InlineErrorMessage message={'Please add "Short Description English"'} />
              )}
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="prod-det-arShort">
                Short Description Arabic
                {!isBasketOrAddon && <span className="requird-asterisks ml-1">*</span>}
              </label>
              <textarea
                id="prod-det-arShort"
                value={shortDescriptioncAr}
                onChange={(e) => setShortDescriptionAr(e.target.value)}
                className="form-control"
                type="text"
              ></textarea>
              {!isBasketOrAddon &&
                !isValid &&
                notValidFields?.includes('short Description arabic') && (
                  <InlineErrorMessage message={'Please add "Short Description Arabic"'} />
                )}
            </div>
            <div className="form-group col-12">
              <label className="bold-title" htmlFor="prod-det-enDesc">
                Description English
                {!isBasketOrAddon && <span className="requird-asterisks ml-1">*</span>}
              </label>
              <CKEditor
                id="prod-det-enDesc"
                editor={ClassicEditor}
                data={descriptioncEn}
                onChange={(e, editor) => {
                  const data = editor.getData();
                  setdescriptionEn(data);
                }}
              />
              {!isBasketOrAddon && !isValid && notValidFields?.includes('description') && (
                <InlineErrorMessage message={'Please add "Description English"'} />
              )}
            </div>
            <div className="form-group col-12">
              <label className="bold-title" htmlFor="prod-det-arDesc">
                Description Arabic
                {!isBasketOrAddon && <span className="requird-asterisks ml-1">*</span>}
              </label>
              <CKEditor
                id="prod-det-arDesc"
                editor={ClassicEditor}
                data={descriptioncAr}
                onChange={(e, editor) => {
                  const data = editor.getData();
                  setdescriptionAr(data);
                }}
              />
              {!isBasketOrAddon && !isValid && notValidFields?.includes('description arabic') && (
                <InlineErrorMessage message={'Please add "Description Arabic"'} />
              )}
            </div>
            <div className="form-group col-12 col-md-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive((prev) => !prev)}
              />
              <span>Ready for sale</span>
            </div>
            {/* <div className="form-group col-12 col-md-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={expressDelivery}
                onChange={(e) => setExpressDelivery((prev) => !prev)}
              />
              <span>Enable express delivery slot</span>
            </div> */}
            <div className="form-group col-12 col-md-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={notAvailableForDisccount}
                onChange={(e) => setNotAvailableForDiscount((prev) => !prev)}
              />
              <span>Exclude discount codes from this item</span>
            </div>
            <div className="form-group col-12 col-md-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={customizable}
                onChange={(e) => setCustomizable((prev) => !prev)}
              />
              <span>Enable Customisable Gift Basket Options</span>
            </div>
            <div className="form-group col-12 col-md-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={pickupAvailable}
                onChange={(e) => setPickupAvailable((prev) => !prev)}
              />
              <span>Available For Pickup</span>
            </div>
            <div className="form-group col-12 col-md-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={motherDayDiscount}
                onChange={(e) => setMotherDayDiscount((prev) => !prev)}
              />
              <span>Mother&apos;s Day Discount</span>
            </div>
            <div className="form-group col-12 col-md-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={trackInventory}
                // onChange={() => setTrackInventory((prev) => !prev)}
                disabled={true}
              />
              <span>Track Inventory</span>
            </div>
            {/* <div className="form-group col-12 col-md-3">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={showInValentineDay}
                onChange={(e) => setShowInValentineDay((prev) => !prev)}
              />
              <span>Show Only In Valentine&apos;s Day</span>
            </div> */}
          </form>
        )}
        <div className="card-footer">
          <button
            disabled={disableBtn || productOptionsById?.status === 1}
            onClick={updateProductDetails}
            className="btn btn-primary"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductDetailsSection);
