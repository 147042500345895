import { apiGatewayService, apiHubService, apiCatalogService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class InventoryService {
  static getAllInventoryData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllInventoryData, ...extraParams),
      params: data,
    });
  }

  static getAllItemsInventoryData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllItemsInventoryData, ...extraParams),
      params: data,
    });
  }

  static getAllItemsAllOpsInventoryData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllItemsAllOpsInventoryData, ...extraParams),
      params: data,
    });
  }

  static getSuppliersList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getInventorySuppliersList, ...extraParams),
    });
  }

  static addInventoryItem(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addInventoryItem, ...extraParams),
      data,
    });
  }

  static editInventoryItem(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.editInvenoryItem, ...extraParams),
      data,
    });
  }

  static uploadInventoryItem(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.uploadInvenoryFile, ...extraParams),
      data,
    });
  }

  static addInventoryItemQty(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.addInventoryQty, ...extraParams),
      data,
    });
  }

  static getInventoryStatement(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getInventoryStatements, ...extraParams),
      params: data,
    });
  }

  static getInventorySuppliers(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getInventorySuppliers, ...extraParams),
    });
  }

  static addInventorySupplier(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getInventorySuppliers, ...extraParams),
      data,
    });
  }

  static updateInventorySupplier(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateInventorySupplier, ...extraParams),
      data,
    });
  }

  static deletInventorySupplier(...extraParams) {
    return apiHubService({
      method: 'DELETE',
      url: setEndPoint(endPoints.updateInventorySupplier, ...extraParams),
      data: {},
    });
  }

  static exportInventoryItems(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportInventoryItems, ...extraParams),
      params,
      responseType: 'blob',
    });
  }

  static exportAllInventoryItems(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportAllInventoryItems, ...extraParams),
      params,
      responseType: 'blob',
    });
  }

  static exportAllInventoryAllOpsItems(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportAllInventoryAllOpsItems, ...extraParams),
      params,
      responseType: 'blob',
    });
  }

  static copyToBasketItem(...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.copyToBasket, ...extraParams),
    });
  }

  static getInvetoryCareById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getInventoryCareById, ...extraParams),
    });
  }

  static addInventoryCare(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addInventoryCareById, ...extraParams),
      data,
    });
  }

  static updateInventoryCare(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getInventoryCareById, ...extraParams),
      data,
    });
  }

  static getInventoryItemDetailsbyId(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getInventoryItemDetailsById, ...extraParams),
    });
  }

  static getAlertQtyInventoryItems(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAlertQtyInventoryItems, ...extraParams),
      params,
    });
  }

  static exportAlertQtyInventoryData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportAlertQtyInventoryData, ...extraParams),
      responseType: 'blob',
    });
  }

  static getAlertQtyStatus(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAlertQtyStatus, ...extraParams),
    });
  }

  static exportMasterInventoryData(params) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportMasterInventoryData),
      params,
      responseType: 'blob',
    });
  }
}
export default InventoryService;
