import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import SectionLoader from 'components/common/SectionLoader';
import AttributesDataGrid from './AttributesDataGrid';
import AttributesFilter from './AttributesFilter';
import AttributesActions from './AttributesActions';
import DJHPagination from 'components/shared/Pagination';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
// actions
import { getAttributesData } from 'store/attributes/AttributesActions';
// selectors
import { getTotalAttributesData } from 'store/attributes/AttributesSelectors';

const AttributesDataTable = ({ inputValues, setInputValues }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const attributesData = useSelector((state) => getTotalAttributesData({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (params) => {
    const queryParams = params ?? inputValues;
    const queryString = convertObjectIntoQueryParams(queryParams);
    navigate({
      pathname: '/attributes',
      search: queryString,
    });
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    dispatch(getAttributesData(updatedParams));
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setIsLoaderOpen(true);
      dispatch(getAttributesData(queryObj));
      setIsLoaderOpen(false);
    } else {
      setIsLoaderOpen(true);
      dispatch(getAttributesData(inputValues));
      setIsLoaderOpen(false);
    }
  }, [location.search]);

  return (
    <div className="quick-actions-table toolbar-table-style">
      <AttributesActions type="parent" inputValues={inputValues} parentName={''} />
      <AttributesFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
      />
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <>
          <AttributesDataGrid data={attributesData?.data} />
          <DJHPagination
            onPageChange={onPageChange}
            currentPage={+attributesData?.currentPage}
            pageSize={+attributesData?.pageSize}
            itemsCount={+attributesData?.rowCount}
          />
        </>
      )}
    </div>
  );
};

export default AttributesDataTable;
