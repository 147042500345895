import { updateObject } from '../utility';

const initialState = {
  productionData: [],
  productionProducts: {},
  fetchingProductionProducts: true,
  selectedProductionProducts: [],
  fetchingProduction: false,
  selectedProductForEdit: {},
  viewJobData: [],
  isActionModalOpen: false,
  isCTADisabled: true,
  actionData: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCTION_PRODUCTS': {
      return updateObject(state, {
        productionProducts: action.payload,
      });
    }
    case 'FETCHING_PRODUCTION_PRODUCTS': {
      return updateObject(state, {
        fetchingProductionProducts: action.payload,
      });
    }
    case 'SET_SELECTED_PRODUCTION_PRODUCTS': {
      return updateObject(state, {
        selectedProductionProducts: action.payload,
      });
    }
    case 'GET_PRODUCTION_DATA': {
      return updateObject(state, {
        productionData: action.payload,
      });
    }
    case 'FETCHING_PRODUCTION': {
      return updateObject(state, {
        fetchingProduction: action.payload,
      });
    }
    case 'SET_SELECTED_PRODUCT_FOR_EDIT': {
      return updateObject(state, {
        selectedProductForEdit: action.payload,
      });
    }
    case 'SET_VIEW_JOB_DATA': {
      return updateObject(state, {
        viewJobData: action.payload,
      });
    }
    case 'SET_IS_ACTION_MODAL_OPEN': {
      return updateObject(state, {
        isActionModalOpen: action.payload,
      });
    }
    case 'SET_IS_CTA_DISABLED': {
      return updateObject(state, {
        isCTADisabled: action.payload,
      });
    }
    case 'SET_ACTION_DATA': {
      return updateObject(state, {
        actionData: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
