import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import debounce from 'lodash/debounce';
import SectionLoader from 'components/common/SectionLoader';
// api
import PermissionService from 'services/PermissionService';
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SenderModal = ({ isSenderModalOpen, setIsSenderModalOpen, setSelectedUser }) => {
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [countryList, setCountryList] = useState('');
  const [userId, setUserID] = useState('');

  const fetchCountryList = async () => {
    try {
      const res = await ViewOrderService.getCountriesList();
      setCountryList(res.data);
    } catch (e) {
      errorLogger('SenderModal:fetchCountryList', e);
      // console.log(e);
    }
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchData = debounce(async (keyword) => {
    const params = {
      searchkey: keyword,
      page: 1,
    };
    setSearchKey(keyword);
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getSearchUsers(params);
      setSearchResult(res.data.data);
      setIsLoaderOpen(false);
    } catch (e) {
      errorLogger('SenderModal:fetchData', e);
      setIsLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    if (keyword.length >= 3) {
      fetchData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleSelectUser = (user) => {
    setEmail(user.email);
    setFirstName(user.name);
    setLastName(user.lastName);
    setCountry(user.countryID);
    setPhone(user.phone);
    setUserID(user.id);
    setShowTable(false);
  };

  const handleCountryChange = (e) => {
    setCountry(e.itemData.Id);
  };

  const saveUser = () => {
    const userData = {
      email,
      fName: firstName,
      lName: lastName,
      countryID: country,
      phone,
      userId,
    };
    setSelectedUser(userData);
    setIsSenderModalOpen(false);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isSenderModalOpen}
        toggle={() => setIsSenderModalOpen((prev) => !prev)}
        className="create-order-modal"
      >
        <ModalHeader>Select Sender</ModalHeader>
        <ModalBody>
          <div className="form-group col-12">
            <form onSubmit={(e) => e.preventDefault()}>
              <label htmlFor="customer-search">Search Customer</label>
              <input
                id="customer-search"
                className="form-control"
                type="text"
                placeholder="Customer Phone, email address"
                onChange={(e) => handleSearch(e.target.value)}
                defaultValue={searchKey}
              />
            </form>
          </div>
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <>
              {searchResult.length > 0 && (
                <div
                  className={`table-responsive shopping-table text-center ${
                    showTable ? 'auto-height-table' : ''
                  }`}
                >
                  {showTable && (
                    <table className="table table-bordernone create-order-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResult.map((item, i) => (
                          <tr
                            className="search-table-row"
                            onClick={() => handleSelectUser(item)}
                            key={i}
                          >
                            <td>{item.name}</td>
                            <td>{item.phone}</td>
                            <td>{item.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-12 customer-info-header">
                  <h6>Customer Information</h6>
                </div>
                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="recipient-name">
                    E-mail
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="form-control"
                    type="text"
                    readOnly
                  />
                </div>
                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="recipient-name">
                    Phone
                  </label>
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    className="form-control"
                    type="text"
                    readOnly
                  />
                </div>

                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="recipient-name">
                    First Name
                  </label>
                  <input
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    className="form-control"
                    type="text"
                    readOnly
                  />
                </div>
                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="recipient-name">
                    Last Name
                  </label>
                  <input
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    className="form-control"
                    type="text"
                    readOnly
                  />
                </div>
                <div className="form-group col-6 filter-select">
                  <label htmlFor="sender-country">Country</label>
                  {countryList !== '' && (
                    <CustomSelect
                      id="sender-country"
                      listData={countryList}
                      handleChange={handleCountryChange}
                      placeholder="Select a country"
                      textPropName="Name"
                      valuePropName="Id"
                      selectedValue={country}
                      disabled
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={saveUser}
            color="primary"
            disabled={!email || !firstName || !lastName || !country || !phone || isLoaderOpen}
          >
            Save
          </Button>
          <Button color="light" onClick={() => setIsSenderModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SenderModal;
