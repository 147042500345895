import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import SectionLoader from 'components/common/SectionLoader';
import { errorLogger } from 'datadog/DDUtils';
// actions
import { setTicketDetailsData } from 'store/ticketing/TicketingActions';
// api
import TicketingService from 'services/TicketingService';

const UploadImgsModal = ({ isUploadImgsModalOpen, setIsUploadImgsModalOpen, ticketId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (files) => {
    setIsLoaderOpen(true);

    const bodyFormData = new FormData();
    files.forEach((el, i) => {
      bodyFormData.append(`file${i + 1}`, el.file);
    });

    try {
      await TicketingService.uploadTicketAttachments(bodyFormData, ticketId);
      setTimeout(() => {
        dispatch(setTicketDetailsData(ticketId));
        setIsLoaderOpen(false);
        setIsUploadImgsModalOpen((prev) => !prev);
        toast.success('Attchments uploaded successfully');
      }, 1500);
    } catch (err) {
      errorLogger('UploadImgsModal:handleSubmit', err);
      setIsLoaderOpen(false);
      setIsUploadImgsModalOpen((prev) => !prev);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isUploadImgsModalOpen}
        toggle={() => setIsUploadImgsModalOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setIsUploadImgsModalOpen((prev) => !prev)}>
          Attach Photos
        </ModalHeader>
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <ModalBody>
            <div className="dz-message needsclick">
              <Dropzone onSubmit={handleSubmit} accept="image/*" />
            </div>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
};

export default UploadImgsModal;
