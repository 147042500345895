import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditCardModal from './modals/EditCardModal';
import DeleteCardModal from './modals/DeleteCardModal';
import PreviewCardModal from './modals/PreviewCardModal';
import { CardBody } from 'reactstrap';
// constants
import { cardTableColums } from './viewOrderCommons';
import { flagIcon, brailleIcon } from 'constant/Enums';
// actions
import {
  setToggleEditCardModalOpen,
  setToggleDeleteCardModal,
  setTogglePreviewCardModal,
} from 'store/orderQuickActions/dataTable/DataTableAction';
import { setCardMessage } from 'store/viewOrder/ViewOrderActions';
// selectos
import {
  getIsEditCardModalOpen,
  getIsDeleteCardModalOpen,
  getIsPreviewCardModalOpen,
} from 'store/orderQuickActions/dataTable/DataTableSelector';

const CardMessage = ({ orderId, cardMessage, orderCstep }) => {
  const isEditCardModalOpen = useSelector((state) => getIsEditCardModalOpen({ state }));
  const isDeleteCardModalOpen = useSelector((state) => getIsDeleteCardModalOpen({ state }));
  const isPreviewCardModalOpen = useSelector((state) => getIsPreviewCardModalOpen({ state }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCardMessage(orderId));
  }, []);

  return (
    <div className="row card-message-wrapper">
      {isEditCardModalOpen && <EditCardModal orderId={orderId} />}
      {isPreviewCardModalOpen && <PreviewCardModal orderId={orderId} />}
      {isDeleteCardModalOpen && <DeleteCardModal orderId={orderId} />}
      <div className="col-12 ">
        <div className="card">
          <CardBody className="recent-notification">
            <div className="table-responsive shopping-table text-center">
              <table className="table table-bordernone">
                <thead>
                  <tr>
                    {cardTableColums.map((col, i) => (
                      <th className="card-col" key={i} scope="col">
                        {col}
                      </th>
                    ))}
                  </tr>
                </thead>
                {cardMessage !== '' && (
                  <tbody>
                    {cardMessage.map((data, i) => (
                      <Fragment key={i}>
                        <tr>
                          <td>{data.messageFrom}</td>
                          <td>{data.messageTo}</td>
                          <td>{data.messageText}</td>
                          <td>
                            <a href={data.feelingLink}>{data.feelingLink}</a>
                          </td>
                          <td>
                            {data.printed ? (
                              <img alt="printed" className="printed-icon" src={flagIcon.trueIcon} />
                            ) : (
                              <img
                                alt="not-printed"
                                className="not-printed-icon"
                                src={flagIcon.falseIcon}
                              />
                            )}
                          </td>
                          <td>
                            {data.isBraillePrint ? (
                              <img
                                alt="isBraillePrint"
                                className="printed-icon"
                                src={brailleIcon}
                              />
                            ) : (
                              <img
                                alt="not-isBraillePrint"
                                className="not-printed-icon"
                                src={flagIcon.falseIcon}
                              />
                            )}
                          </td>
                          <td>
                            <i
                              onClick={() => dispatch(setTogglePreviewCardModal(data))}
                              className="fa fa-eye card-msg-icon"
                            />
                          </td>
                          <td>
                            {orderCstep !== 4 && (
                              <i
                                onClick={() => dispatch(setToggleEditCardModalOpen(data))}
                                className="fa fa-edit card-msg-icon"
                              />
                            )}
                          </td>
                          <td>
                            {orderCstep !== 4 && (
                              <i
                                className="fa fa-trash inventory-supp-trash card-msg-del-icon"
                                onClick={() => dispatch(setToggleDeleteCardModal(data))}
                              />
                            )}
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                )}
                <tfoot>
                  <tr>
                    <td colSpan={6}>
                      <button
                        onClick={() => dispatch(setToggleEditCardModalOpen())}
                        className="btn btn-success"
                        disabled={orderCstep === 4}
                      >
                        <i className="fa fa-plus"></i> Add New Card
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default CardMessage;
