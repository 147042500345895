import { apiGatewayService, apiCatalogService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class ProductsService {
  static getProductCategoriesFilter(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductCategoriesFilter, ...extraParams),
    });
  }

  static getProductCategories(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductCategories, ...extraParams),
    });
  }

  static getProductExcludedSlots(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductExcludedSlots, ...extraParams),
    });
  }

  // main categories
  static getProductMainCategories(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductMainCategories, ...extraParams),
    });
  }

  static getProductAttributesFilter(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductAttributesFilter, ...extraParams),
    });
  }

  static getProductDetailsById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductDetailsById, ...extraParams),
    });
  }

  static updateProductDetails(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getProductDetailsById, ...extraParams),
      data,
    });
  }

  // product options by id
  static getProductOptionsById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductOptionsById, ...extraParams),
    });
  }

  static updateProductOptionsById(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateProductOptionsById, ...extraParams),
      data,
    });
  }

  // product ranking by id
  static getProductRankingById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductRankingById, ...extraParams),
    });
  }

  static updateProductRankingById(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getProductRankingById, ...extraParams),
      data,
    });
  }

  static getLabelList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getLabelList, ...extraParams),
    });
  }

  static getLabelByProductID(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductLabel, ...extraParams),
      params,
    });
  }

  static updateProductLabel(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateProductLabel, ...extraParams),
      data,
    });
  }

  static addProductLabel(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getProductLabel, ...extraParams),
      data,
    });
  }

  static deleteProductLabel(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.updateProductLabel, ...extraParams),
    });
  }

  // product availability by id
  static getProductAvailabilityById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductAvailabilityById, ...extraParams),
    });
  }

  // update  availability by id
  static updateProductAvailability(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateProductAvailability, ...extraParams),
      data,
    });
  }

  // product inventory by id
  static getProductInventoryById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductInventoryById, ...extraParams),
    });
  }

  static updateProductMargin(data, ...extraParams) {
    return apiCatalogService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateProductMargin, ...extraParams),
      data,
    });
  }

  // product media by id
  static getProductMediaById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductMediaById, ...extraParams),
    });
  }

  // update product media by id
  static updateProductMediaById(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.updateProductMediaById, ...extraParams),
      data,
    });
  }

  // reorder product images
  static reorderProductImages(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.reorderProductImages, ...extraParams),
      data,
    });
  }

  // delete product media by id
  static deleteProductMediaById(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deleteProductMediaById, ...extraParams),
    });
  }

  // product master availability by id
  static getProductMasterAvailabilityById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.updateProductAvailability, ...extraParams),
    });
  }

  static updateOpsMasterAvailabilityById(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateOpsMasterAvailability, ...extraParams),
      data,
    });
  }

  static getProductGroupsById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductGroupsById, ...extraParams),
    });
  }

  // product master inventory
  static getProductMasterInventoryById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductMasterInventoryById, ...extraParams),
    });
  }

  static deleteProductMasterInventoryItem(...extraParams) {
    return apiCatalogService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deleteProductMasterInventoryItem, ...extraParams),
    });
  }

  static editProductMasterInventoryQty(...extraParams) {
    return apiCatalogService({
      method: 'PUT',
      url: setEndPoint(endPoints.editProductMasterInventoryQty, ...extraParams),
    });
  }

  static searchProductMasterInventoryItem(params, ...extraParams) {
    return apiCatalogService({
      method: 'GET',
      url: setEndPoint(endPoints.searchProductMasterInventoryItem, ...extraParams),
      params,
    });
  }

  static addNewProductMasterInventoryItem(data, ...extraParams) {
    return apiCatalogService({
      method: 'PUT',
      url: setEndPoint(endPoints.addNewProductMasterInventoryItem, ...extraParams),
      data,
    });
  }

  // product excluded days
  static getProductExcludedDaysById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductExcludedDaysById, ...extraParams),
    });
  }

  static addExcludedDay(...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.excludeDay, ...extraParams),
    });
  }

  static deleteExcludedDay(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.excludeDay, ...extraParams),
    });
  }

  // product group
  static addProductGroup(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addGroupBasket, ...extraParams),
      data,
    });
  }

  static editProductGroup(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.editGroupBasket, ...extraParams),
      data,
    });
  }

  static deleteProductGroup(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.editGroupBasket, ...extraParams),
    });
  }

  // product categories section

  // categories
  static getProductCategoriesById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductCategoriesById, ...extraParams),
    });
  }

  static addProductCategoryById(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.addProductCategoryById, ...extraParams),
    });
  }

  static deleteProductCategoryById(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deleteProductCategoryById, ...extraParams),
    });
  }

  // MC
  static getProductMCById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductMCById, ...extraParams),
    });
  }

  static addProductMCById(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getProductMCById, ...extraParams),
      data,
    });
  }

  // excluded slots
  static getProductExcludedSlotsById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductExcludedSlotsById, ...extraParams),
    });
  }

  static addProductExcludedSlotById(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateProductExcludedSlotsById, ...extraParams),
    });
  }

  static deleteProductExcludedSlotById(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.updateProductExcludedSlotsById, ...extraParams),
    });
  }

  // attributes
  static getProductAttributesById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductAttributesById, ...extraParams),
    });
  }

  static addProductAttributeById(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.addProductAttributeById, ...extraParams),
    });
  }

  static deleteProductAttributeById(...extraParams) {
    return apiGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deleteProductAttributeById, ...extraParams),
    });
  }

  // product basket
  static addProductGroupItem(data, ...extraParams) {
    return apiCatalogService({
      method: 'POST',
      url: setEndPoint(endPoints.addGroupBasketItem, ...extraParams),
      data,
    });
  }

  static editProductGroupItem(data, ...extraParams) {
    return apiCatalogService({
      method: 'PUT',
      url: setEndPoint(endPoints.editGroupBasketItem, ...extraParams),
      data,
    });
  }

  static deleteProductGroupItem(...extraParams) {
    return apiCatalogService({
      method: 'DELETE',
      url: setEndPoint(endPoints.addGroupBasketItem, ...extraParams),
    });
  }

  static searchBasketProduct(params, ...extraParams) {
    return apiCatalogService({
      method: 'GET',
      url: setEndPoint(endPoints.searchBasketProduct, ...extraParams),
      params,
    });
  }

  static getProductSizes(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductSizes, ...extraParams),
    });
  }

  static addProductSize(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addProductSize, ...extraParams),
      data,
    });
  }

  static editProductSize(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getProductSizes, ...extraParams),
      data,
    });
  }

  static getDuplicateProduct(data, ...extraParams) {
    return apiCatalogService({
      method: 'POST',
      url: setEndPoint(endPoints.getDuplicateProduct, ...extraParams),
      data,
    });
  }

  static getAttributesRelatedToMC(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints?.getMCAttributes, ...extraParams),
    });
  }
}
export default ProductsService;
