import { toast } from 'react-toastify';
// actions types
import {
  START_SOCKET_CONNECTION,
  START_SOCKET_CONNECTION_FAIL,
  START_SOCKET_CONNECTION_SUCCESS,
  STOP_SOCKET_CONNECTION,
  STOP_SOCKET_CONNECTION_FAIL,
  STOP_SOCKET_CONNECTION_SUCCESS,
} from './AppActionTypes';
// api
import ViewOrderService from '../../services/ViewOrderService';
import AuthService from './../../services/AuthService';

export const setIsLoggedInTrue = () => ({ type: 'SET_IS_LOGGED_IN_TRUE' });
export const setIsLoggedInFalse = () => (dispatch) => {
  dispatch({ type: 'SET_IS_LOGGED_IN_FALSE' });
};

export const logoutApi = () => async (dispatch) => {
  try {
    await AuthService.logOut();
  } catch (error) {
    toast.error(error?.response?.data?.message ?? 'Something went wrong');
  }
  dispatch({ type: 'SET_IS_LOGGED_OUT_CLICKED' });
};

export const setLoaderOpenTrue = () => ({ type: 'SET_LOADER_OPEN_TRUE' });
export const setLoaderOpenFalse = () => ({ type: 'SET_LOADER_OPEN_FALSE' });

export const setCountriesListData = () => async (dispatch) => {
  try {
    const res = await ViewOrderService.getCountriesList();
    dispatch({
      type: 'SET_COUNTRIES_LIST_DATA',
      payload: res.data,
    });
  } catch (err) {
    toast.error(err?.response?.data?.message ?? 'Failed to get countries list!!');
  }
};

// socket io
export const startSocketConnection = () => ({
  type: 'socket',
  types: [START_SOCKET_CONNECTION, START_SOCKET_CONNECTION_SUCCESS, START_SOCKET_CONNECTION_FAIL],
  promise: (socket) => socket.connect(),
});

export const stopSocketConnection = () => ({
  type: 'socket',
  types: [STOP_SOCKET_CONNECTION, STOP_SOCKET_CONNECTION_SUCCESS, STOP_SOCKET_CONNECTION_FAIL],
  promise: (socket) => socket.disconnect(),
});
