/* eslint-disable indent */
import AuthService from 'services/AuthService';
import OpsService from 'services/OpsService';
import { toast } from 'react-toastify';
import { addToLocalStorage } from 'localStorageService/LocalStorage';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse, setIsLoggedInTrue } from 'store/app/AppActions';
import { history } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const getOpsData = async () => {
  try {
    const res = await OpsService.getOps({ allops: true });
    return res.data;
  } catch (error) {
    errorLogger('SignInAction:getOpsData', err);
    return [];
  }
};

export const setUserPermiossions = (opsId, countryId) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const params = {
      opsid: opsId,
      countryId,
    };
    const userPermissions = await AuthService.getUserPermissions(params);
    dispatch({
      type: 'SET_USER_PERMISSIONS',
      payload: userPermissions.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('SignInAction:setUserPermiossions', err);
    toast.error('Permissions failed');
    dispatch(setLoaderOpenFalse());
  }
};

export const setSignInUser =
  ({ email, password, sessionId }) =>
  async (dispatch) => {
    dispatch(setDisableLoginBtnTrue());
    let res;
    try {
      if (sessionId) {
        res = await AuthService.getMsLoginAuth(sessionId);
      } else {
        res = await AuthService.SignInUser(email, password, 'Login');
      }
      addToLocalStorage(localStorageKeys.ACCESS_TOKEN, res.data.token);
      addToLocalStorage(localStorageKeys.OPS_ID, res.data.operationCenter.id);
      addToLocalStorage(localStorageKeys.NAV_BG_COLOR, `#${res.data.operationCenter.color}`);
      addToLocalStorage(localStorageKeys.COUNTRY_ID, res.data.operationCenter.countryID);
      await dispatch(
        setUserPermiossions(res.data.operationCenter.id, res.data.operationCenter.countryID)
      );
      addToLocalStorage(localStorageKeys.OPS_CENTER, res.data.operationCenter);
      addToLocalStorage(localStorageKeys.IS_MUTED, false);

      if (!res?.data?.operationCenter?.country) {
        const opsData = await getOpsData();
        const currentOps = opsData.find((ops) => ops.id === res.data.operationCenter.id);

        if (currentOps?.id) {
          addToLocalStorage(localStorageKeys.OPS_CENTER, currentOps);
        }
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const source = urlParams.get('source');
      const hubSource = urlParams.get('hub-source');
      if (source) {
        window.location.href = `${source}?token=${res.data.token}&opsId=${res?.data?.operationCenter?.id}`;
      } else {
        if (hubSource) {
          window.location.href = hubSource;
        } else {
          dispatch(setIsLoggedInTrue());
          toast.success('Logged in successfully.');
          history.push('/home');
        }
      }
      dispatch(setDisableLoginBtnFalse());
    } catch (err) {
      dispatch(setDisableLoginBtnFalse());
      if (email || password) {
        toast.error('Invalid username or password.');
      } else {
        toast.error('Failed to sign in this user.');
      }
      errorLogger('SignInAction:setSignInUser', err);
    }
  };

export const setSignInSuccess = () => async (dispatch) => {
  try {
    const res = await OpsService.getOps({ allops: true });
    dispatch({
      type: 'SIGN_IN_SUCCESS',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('SignInAction:setSignInSuccess', err);
  }
};

export const setSliderImg = () => async (dispatch) => {
  try {
    const sliderImg = await AuthService.getSliderImg();
    dispatch({
      type: 'SET_SLIDER_IMG',
      payload: sliderImg.data.coverImageFullPath,
    });
  } catch (err) {
    errorLogger('SignInAction:setSliderImg', err);
  }
};

export const setDisableLoginBtnTrue = () => ({ type: 'SET_DISABLE_LOGIN_BTN_TRUE' });
export const setDisableLoginBtnFalse = () => ({ type: 'SET_DISABLE_LOGIN_BTN_FALSE' });
