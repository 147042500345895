import React, { useState, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
import debounce from 'lodash/debounce';
// api
import PermissionService from 'services/PermissionService';

const SearchUserModal = ({
  isSearchUserModalOpen,
  setIsSearchUserModalOpen,
  setUserName,
  setUserId,
  setUserEmail,
}) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showTable, setShowTable] = useState(true);

  const fetchData = debounce(async (keyword) => {
    const params = {
      searchkey: keyword,
      page: 1,
    };
    setSearchKey(keyword);
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getSearchUsers(params);
      setSearchResult(res.data.data);
      setIsLoaderOpen(false);
    } catch (e) {
      setIsLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    setUserName('');
    setUserId('');
    setUserEmail('');
    if (keyword.length >= 3) {
      fetchData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleSelectUser = (user) => {
    setUserName(`${user.name} ${user.lastName}`);
    setUserId(user.id);
    setUserEmail(user.email);
    setIsSearchUserModalOpen(false);
  };

  return (
    <div>
      <Modal
        isOpen={isSearchUserModalOpen}
        toggle={() => setIsSearchUserModalOpen((prev) => !prev)}
        style={{ maxWidth: 600 }}
      >
        <ModalHeader>Search User</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group col-12">
              <input
                className="form-control assign-ticket-search"
                type="text"
                placeholder="Phone, name, email address"
                onChange={(e) => handleSearch(e.target.value)}
                defaultValue={searchKey}
              />

              {isLoaderOpen ? (
                <SectionLoader height="180px" />
              ) : (
                <Fragment>
                  {searchResult.length > 0 ? (
                    <div
                      style={{ height: showTable ? 'auto' : '', maxHeight: 220 }}
                      className="table-responsive shopping-table text-center"
                    >
                      {showTable && (
                        <table className="table table-bordernone create-order-table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {searchResult.map((item) => (
                              <tr
                                className="search-table-row assign-results-list"
                                onClick={() => handleSelectUser(item)}
                                key={item.email}
                              >
                                <td>{item.name}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  ) : (
                    <p style={{ textAlign: 'center' }}>No results match</p>
                  )}
                </Fragment>
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setIsSearchUserModalOpen((prev) => !prev)} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SearchUserModal;
