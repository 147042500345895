import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
// actions
import { setToggleDriverTripModal } from 'store/viewOrder/ViewOrderActions';

const TrackTripBtn = ({ tripId, driverId }) => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <button
        onClick={() => dispatch(setToggleDriverTripModal({ tripId, driverId }))}
        type="button"
        className="btn btn-primary"
      >
        Orders
      </button>
    </Fragment>
  );
};

export default TrackTripBtn;
