import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { toast } from 'react-toastify';
// api
import CorporateService from '../../services/reportsService/CorporateService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

export const setCustomerList = () => async (dispatch) => {
  try {
    const res = await CorporateService.getCustomerListData();
    dispatch({
      type: 'GET_CUSTOMER_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('Corporate Report Page Fetch Customer List Error:', err);
    toast.error('Failed to get customer list!');
  }
};

export const setSalesOfficerList = () => async (dispatch) => {
  try {
    const res = await CorporateService.getSalesOfficerListData();
    dispatch({
      type: 'GET_SALESOFFICER_DATA',
      payload: res.data,
    });
  } catch (err) {
    errorLogger('Corporate Report Page Fetch Officer List Error:', err);
    toast.error('Failed to get sales officer list!');
  }
};

export const setCorporateReport = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await CorporateService.getCorporateReportData(params);
    dispatch({
      type: 'GET_CORPORATE_REPORT',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Corporate Report Page Fetch Data Error:', err);
    toast.error('Failed to get corporate report!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setCustomerCorporateReport = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await CorporateService.getCustomerCorporateReportData(params);
    dispatch({
      type: 'GET_CUSTOMER_CORPORATE_REPORT',
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('Customer Corporate Report Page Fetch Data Error:', err);
    toast.error('Failed to get sales customer corporate report!');
    dispatch(setLoaderOpenFalse());
  }
};
