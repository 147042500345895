import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { dateFormatDDMM } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const CouponDailySpendDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="#"
              field="serial"
              width={60}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.serial}
            />
            <ColumnDirective
              headerText="Date"
              template={(rowData) => (
                <span>
                  {rowData.ord_processed_date && dateFormatDDMM(rowData.ord_processed_date)}
                </span>
              )}
              customExcelTemplate={(rowData) =>
                rowData.ord_processed_date && dateFormatDDMM(rowData.ord_processed_date)
              }
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="OpsID"
              field="ops_id"
              width={80}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.ops_id}
            />
            <ColumnDirective
              headerText="Country"
              field="city_name"
              width={80}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.city_name}
            />
            <ColumnDirective
              headerText="Voucher Code"
              field="coupon_code"
              width={120}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.coupon_code}
            />
            <ColumnDirective
              headerText="Voucher Category"
              field="voucher_category"
              width={100}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.voucher_category}
            />
            <ColumnDirective
              headerText="Total Used"
              field="total_vouchers"
              width={100}
              textAlign="Center"
              customExcelTemplate={(rowData) => rowData.total_vouchers}
            />
            <ColumnDirective
              headerText="Total Amount"
              field="total_discount"
              template={(rowData) => (
                <span>
                  {rowData.total_discount != null &&
                    rowData.total_discount.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              customExcelTemplate={(rowData) => rowData.total_discount}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Total Amount USD"
              field="total_discount_usd"
              template={(rowData) => (
                <span>
                  {rowData.total_discount_usd != null &&
                    rowData.total_discount_usd.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                </span>
              )}
              customExcelTemplate={(rowData) => rowData.total_discount_usd}
              width={100}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(CouponDailySpendDataGrid);
