import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class DeliveryReportService {
  static getDriverPerformance(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getDriverPerformanceReport, ...extraParams),
      params,
    });
  }

  static exportDriverPerformance(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportDriverPerformanceReport, ...extraParams),
      params,
    });
  }

  static getDeliveryPerformance(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getDeliveryPerformanceReport, ...extraParams),
      params,
    });
  }

  static exportDeliveryPerformance(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportDeliveryPerformanceReport, ...extraParams),
      params,
    });
  }

  static getDeliveryBreakdown(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getDeliveryBreakdownReport, ...extraParams),
      params,
    });
  }

  static getDriverLogistics(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getDriverLogisticsReport, ...extraParams),
      params,
    });
  }

  static exportDriverLogistics(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportDriverLogisticsReport, ...extraParams),
      params,
    });
  }

  static getDriverPerformanceDetails(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getDriverPerformanceDetailsReport, ...extraParams),
      params,
    });
  }

  static exportDriverPerformanceDetails(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportDriverPerformanceDetailsReport, ...extraParams),
      params,
    });
  }

  static getDriverOrders(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getDriverOrders, ...extraParams),
      params,
    });
  }

  static exportDriverOrders(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportDriverOrders, ...extraParams),
      params,
    });
  }

  static getLateOrderDetails(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getDeliveryLateOrdersDetails, ...extraParams),
      params,
    });
  }
}
export default DeliveryReportService;
