/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from 'store';
import notify3 from 'assets/audio/notify3.mp3';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getFromLocalStorage, addToLocalStorage } from 'localStorageService/LocalStorage';
// selectors
import { getNewNotificationList } from 'store/notifications/selectors/NotificationsSelectors';
// constants
import { fromNow } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const mutedStatus = getFromLocalStorage(localStorageKeys.IS_MUTED);

const Notifications = () => {
  const newNotificationsList = useSelector((state) => getNewNotificationList({ state }));
  const [isMuted, setIsMuted] = useState(mutedStatus);
  const audio = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (newNotificationsList.length > 0 && !getFromLocalStorage(localStorageKeys.IS_MUTED)) {
      audio.current.muted = false;
      audio.current.play();
      setTimeout(() => {
        audio.current.muted = true;
      }, 2000);
    }
  }, [newNotificationsList]);

  const handleChangeMute = () => {
    setIsMuted((prev) => !prev);
    addToLocalStorage(localStorageKeys.IS_MUTED, !getFromLocalStorage(localStorageKeys.IS_MUTED));
  };

  return (
    <div>
      <ul className="notification-dropdown onhover-show-div p-0 notifications-list">
        <li className={`between-flex ${window.screen.width < 500 ? 'hide-display' : ''}`}>
          <span>Notifications</span>
          <FormControlLabel
            control={
              <Switch
                checked={isMuted || false}
                onChange={handleChangeMute}
                name="checkedB"
                color="primary"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Mute"
          />
        </li>
        {newNotificationsList.length > 0 && window.screen.width > 500 && (
          <Fragment>
            <a
              className="black-color"
              href={`/order/${newNotificationsList[0].orderId}`}
              target="_blank"
              rel="noreferrer"
            >
              <li>
                <p className="m-0">
                  <span className="primary-color bold-title">
                    {newNotificationsList[0].orderId}
                  </span>{' '}
                  {newNotificationsList[0].details}{' '}
                  <span className="primary-color">
                    {fromNow(newNotificationsList[0].createdAt)}
                  </span>
                </p>
              </li>
            </a>

            {newNotificationsList[1] && (
              <a
                className="black-color"
                href={`/order/${newNotificationsList[1].orderId}`}
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <p className="m-0">
                    <span className="primary-color bold-title">
                      {newNotificationsList[1].orderId}
                    </span>{' '}
                    {newNotificationsList[1].details}{' '}
                    <span className="primary-color">
                      {fromNow(newNotificationsList[1].createdAt)}
                    </span>
                  </p>
                </li>
              </a>
            )}
            {newNotificationsList[2] && (
              <a
                className="black-color"
                href={`/order/${newNotificationsList[2].orderId}`}
                target="_blank"
                rel="noreferrer"
              >
                <li>
                  <p className="m-0">
                    <span className="primary-color bold-title">
                      {newNotificationsList[2].orderId}
                    </span>{' '}
                    {newNotificationsList[2].details}{' '}
                    <span className="primary-color">
                      {fromNow(newNotificationsList[2].createdAt)}
                    </span>
                  </p>
                </li>
              </a>
            )}
          </Fragment>
        )}
        <li>
          <p
            onClick={() => {
              const state = store.getState();
              state.notifications.newNotificationsLength = 0;
              navigate('/notifications');
            }}
            className="m-0 bold-title primary-color align-center"
          >
            View all notifications
          </p>
        </li>
      </ul>
      <audio
        className="hide-display"
        ref={audio}
        src={notify3}
        muted={isMuted}
        autoPlay=""
        controls
      ></audio>
    </div>
  );
};

export default Notifications;
