import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Sort,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const StatementsDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  // custom columns
  const idTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column btns-column">
        <p>{parseInt(rowData.index, 10) + 1}</p>
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return <p className="total-txt">{rowData.item.name}</p>;
  };

  const barCodeTemplate = (rowData) => {
    return <p className="total-txt">{rowData.item.barCode}</p>;
  };

  const handleTypeTitle = (type) => {
    switch (type) {
      case 0:
        return 'In';
      case 1:
        return 'Out';
      default:
        return 'Damaged';
    }
  };

  const typeTemplate = (rowData) => {
    return <p className="total-txt">{handleTypeTitle(rowData.type)}</p>;
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  const pdfQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data)).replace(
        /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/gi,
        ' '
      );
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          // allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={pdfQueryCellInfo}
          allowSorting={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="#"
              field="itemId"
              width={50}
              template={idTemplate}
              customExcelTemplate={(rowData) => rowData.itemId}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Item"
              field="item.name"
              width={190}
              template={nameTemplate}
              customExcelTemplate={(rowData) => rowData.item.name}
              disableHtmlEncode={true}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Item Type"
              field="parentType"
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Barcode"
              field="item.barCode"
              width={91}
              template={barCodeTemplate}
              customExcelTemplate={(rowData) => rowData.item.barCode}
              textAlign="Center"
            />
            <ColumnDirective headerText="Qty" field="qty" width={61} textAlign="Center" />
            <ColumnDirective headerText="Unit Price" field="price" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="Statement Type"
              field="type"
              width={130}
              template={typeTemplate}
              customExcelTemplate={(rowData) => rowData.type}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(StatementsDataGrid);
