import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
// actions
import { setUserGroupsById } from 'store/user/UserActions';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UserGroupsModal = ({ isGroupModalOpen, setIsGroupModalOpen, userId }) => {
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState('');
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async () => {
    const res = await PermissionService.getPermissionGroups();
    setGroups(res.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmitGroup = async () => {
    const bodyData = {
      GroupId: groupId,
    };
    setDisableSaveBtn(true);
    try {
      await PermissionService.addUserToGroup(bodyData, userId);
      setTimeout(() => {
        dispatch(setUserGroupsById(userId));
        setDisableSaveBtn(false);
        toast.success('New group added successfully');
        setIsGroupModalOpen((prev) => !prev);
      }, 1500);
    } catch (err) {
      setDisableSaveBtn(false);
      setIsGroupModalOpen((prev) => !prev);
      errorLogger('UserGroupsModal:handleSubmitGroup', err);
      toast.error('Failed to add user group!');
    }
  };

  const handleGroupChange = (e) => {
    setGroupId(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isGroupModalOpen} toggle={() => setIsGroupModalOpen((prev) => !prev)}>
        <ModalHeader toggle={() => setIsGroupModalOpen((prev) => !prev)}>Add New Group</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group ">
              <label className="col-form-label" htmlFor="message-text">
                Groups
              </label>
              <CustomSelect
                listData={groups}
                handleChange={handleGroupChange}
                placeholder="Select a group"
                textPropName="name"
                valuePropName="id"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={disableSaveBtn} onClick={handleSubmitGroup} color="primary">
            Save
          </Button>
          <Button color="light" onClick={() => setIsGroupModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserGroupsModal;
