import React, { Fragment } from 'react';
import { toast } from 'react-toastify';

export const inventoryTableColums = ['Image', 'Barcode', 'Name', 'Quantity', 'Details'];

export const inventoryTableData = [
  {
    image: 'https://cdn.floward.com/web/files/inventory/637116600407754692.jpg',
    barcode: 555,
    name: 'Rose-Purple',
    quantity: 8,
    details: 'Out from stock',
  },
  {
    image: 'https://cdn.floward.com/web/files/inventory/637116600407754692.jpg',
    barcode: 555,
    name: 'Rose-Purple',
    quantity: 8,
    details: 'Out from stock',
  },
  {
    image: 'https://cdn.floward.com/web/files/inventory/637116600407754692.jpg',
    barcode: 555,
    name: 'Rose-Purple',
    quantity: 8,
    details: 'Out from stock',
  },
  {
    image: 'https://cdn.floward.com/web/files/inventory/637116600407754692.jpg',
    barcode: 555,
    name: 'Rose-Purple',
    quantity: 8,
    details: 'Out from stock',
  },
];

export const driverTableColums = [
  'Driver',
  'PickedDate',
  'OrderStatus',
  'End Date',
  'Time Diff.',
  'Comment',
];

export const driverTableData = [
  {
    name: 'Hossam',
    phone: 97028660,
    pickedDate: '12/11/2020',
    status: 'Delivered',
    comment: 'Done',
  },
];

export const cardTableColums = [
  'Messsage from',
  'Message to',
  'Message text',
  'Feeling link',
  'Print status',
  'Braille',
  'Preview',
  'Edit',
  'Delete',
];

export const cusomerCallTableColums = [
  'OrderId',
  'Called Person',
  'Call Time',
  'Call Status',
  'UserName',
  'Remark',
];

export const adjustTableColums = [
  'Type',
  'campaign_name',
  'idfa',
  'country',
  'city',
  'click_time',
  'tracker_name',
  'first_tracker_name',
  'last_tracker_name',
  'is_reattributed',
  'installed_at',
  'conversion_duration',
];

export const columns = [
  {
    name: 'Order',
    selector: 'order',
    sortable: true,
    cell: (row) => (
      <div className="columns-order-id">
        <a href={`/order/${row.id}`} target="_blank" rel="noopener noreferrer">
          {row.id}
        </a>
      </div>
    ),
    width: '100px',
  },
  {
    name: 'Ops',
    selector: 'nickName',
    sortable: true,
    cell: (row) => (
      <div className="columns-order-name">
        <p>{row.ops.nickName}</p>
      </div>
    ),
    center: true,
  },
  {
    name: 'Payment Date',
    selector: 'paymentDate',
    sortable: true,
    cell: (row) => (
      <div className="columns-order-paydate">
        {row.paymentDate !== null && (
          <Fragment>
            <p>{row.paymentDate.slice(0, 10)}</p>
            <p>{row.paymentDate.slice(11, 16)}</p>
          </Fragment>
        )}
      </div>
    ),
  },

  {
    name: 'Sender',
    selector: 'sender',
    sortable: true,
    cell: (row) => (
      <div className="columns-order-sender">
        <p>
          <span>Name: </span> {row.user ? row.user.name : ''}
        </p>
        <p>
          <span>Phone: </span>{' '}
          <span
            onClick={() => {
              navigator.clipboard.writeText(row.user && row.user.phone.replace(/\s/g, ''));
              toast.success('Copied');
            }}
            className="sender-phone"
          >
            {row.user ? row.user.phone : ''}
          </span>
        </p>
      </div>
    ),
  },
  {
    name: 'Recipient',
    selector: 'recipient',
    sortable: true,
    cell: (row) => (
      <div className="columns-order-recipient">
        <p>
          <span>Name: </span> {row.recipient ? row.recipient.recipentName : ''}
        </p>
        <p>
          <span>Phone: </span>
          <span
            onClick={() => {
              navigator.clipboard.writeText(
                row.recipient && row.recipient.recipientPhone.replace(/\s/g, '')
              );
              toast.success('Copied');
            }}
            className="sender-phone"
          >
            {' '}
            {row.recipient ? row.recipient.recipientPhone : ''}
          </span>
        </p>
      </div>
    ),
  },
  {
    name: 'Address',
    selector: 'recipient',
    sortable: true,
    cell: (row) => (
      <div className="columns-order-address">
        <p>{row.recipient ? row.recipient.address : ''}</p>
        <p>{row.recipient ? row.recipient.extra : ''}</p>
      </div>
    ),
    left: true,
    width: '200px',
  },
];
