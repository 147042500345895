import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import SectionLoader from 'components/common/SectionLoader';
// helpers
import { dateFormatDDMM } from 'constant/Helpers';
import { toast } from 'react-toastify';
// actions
import { setViewOrderData } from 'store/viewOrder/ViewOrderActions';
// api
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SwapUkOpsModal = ({
  isSwapUkOpsModalOpen,
  setIsSwapOpsModalOpen,
  isNwDelivery,
  isPremium,
  orderId,
  opsId,
}) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const dispatch = useDispatch();

  const handleChangeDate = async (date) => {
    const params = {
      date: dateFormatDDMM(date),
      proId: -1,
      fulfillmenttype: 0,
      opsid: opsId,
      nwDelivery: isNwDelivery ? false : true,
    };
    try {
      if (isPremium) {
        const res = await ViewOrderService.getDeliveryPremiumTimeSlot(params);
        setTimeSlotsData(res.data.avilableSlots);
      } else {
        delete params.proId;
        const res = await ViewOrderService.getDeliveryTimeSlot(params);
        setTimeSlotsData(res.data.avilableSlots);
      }
    } catch (e) {
      errorLogger('SwapUkOpsModal:handleChangeDate', e);
      toast.error('No available slots');
    }
    setSelectedDate(date);
  };

  const handleTimeSlotChange = (e) => {
    setSelectedTimeSlot(e.itemData.id);
  };

  const handleSubmitNwDeliveryDate = async () => {
    setIsLoaderOpen(true);
    setDisableBtn(true);
    const bodyData = {
      deliveryDate: dateFormatDDMM(selectedDate),
      deliverySlot: selectedTimeSlot,
      nwDelivery: isNwDelivery ? false : true,
    };
    try {
      const res = await ViewOrderService.getSwapToNwDelivery(bodyData, orderId);
      setTimeout(() => {
        dispatch(setViewOrderData(orderId));
      }, 1500);
      setIsLoaderOpen(false);
      setDisableBtn(false);
      setIsSwapOpsModalOpen((prev) => !prev);
      toast.success(res?.data?.message ?? 'updated');
    } catch (err) {
      errorLogger('SwapUkOpsModal:handleSubmitNwDeliveryDate', err);
      toast.error(err?.response?.data?.message ?? 'failed to swap to Nationwide');
      setIsLoaderOpen(false);
      setDisableBtn(false);
    }
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isSwapUkOpsModalOpen}
        toggle={() => setIsSwapOpsModalOpen((prev) => !prev)}
        className="create-order-modal"
      >
        <ModalHeader>{isNwDelivery ? 'Swap to London' : 'Swap to Nationwide'}</ModalHeader>
        <ModalBody>
          {isLoaderOpen ? (
            <SectionLoader height="180px" />
          ) : (
            <Fragment>
              <div className="row">
                <div className="form-group col-6">
                  <label className="col-form-label" htmlFor="message-text">
                    Delivery Date
                  </label>
                  <DatePicker
                    className="form-control digits"
                    placeholderText="Selected delivery date"
                    selected={selectedDate}
                    onChange={handleChangeDate}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                </div>
                {timeSlotsData.length > 0 && (
                  <div className="form-group col-6 filter-select delivery-time-select">
                    <label htmlFor="uk-swap-time">Delivery Time</label>
                    <CustomSelect
                      id="uk-swap-time"
                      listData={timeSlotsData}
                      handleChange={handleTimeSlotChange}
                      placeholder="Select an delivery time"
                      textPropName="name"
                      valuePropName="id"
                      selectedValue={selectedTimeSlot}
                    />
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSubmitNwDeliveryDate}
            color="primary"
            disabled={!selectedDate || !selectedTimeSlot || disableBtn}
          >
            Submit
          </Button>
          <Button color="light" onClick={() => setIsSwapOpsModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SwapUkOpsModal;
