import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const HoursBreakDownDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Time"
              template={(rowData) => `${rowData?.cTime} - ${rowData?.cTime2}`}
              width={120}
              textAlign="Center"
              customExcelTemplate={(rowData) => `${rowData?.cTime} - ${rowData?.cTime2}`}
            />
            <ColumnDirective headerText="Web" field="web" width={80} textAlign="Center" />
            <ColumnDirective
              headerText="%"
              template={(rowData) => (
                <span>
                  {rowData?.webBreakdown &&
                    rowData?.webBreakdown.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                  %
                </span>
              )}
              width={100}
              textAlign="Center"
              customExcelTemplate={(rowData) =>
                rowData?.webBreakdown &&
                rowData?.webBreakdown.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
            />
            <ColumnDirective headerText="IOS" field="ios" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="%"
              template={(rowData) => (
                <span>
                  {rowData?.iosBreakdown &&
                    rowData?.iosBreakdown.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                  %
                </span>
              )}
              width={100}
              textAlign="Center"
              customExcelTemplate={(rowData) =>
                rowData?.iosBreakdown &&
                rowData?.iosBreakdown.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
            />
            <ColumnDirective headerText="Android" field="android" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="%"
              template={(rowData) => (
                <span>
                  {rowData?.androidBreakdown &&
                    rowData?.androidBreakdown.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                  %
                </span>
              )}
              textAlign="Center"
              width={100}
              customExcelTemplate={(rowData) =>
                rowData?.androidBreakdown &&
                rowData?.androidBreakdown.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
            />
            <ColumnDirective headerText="Agent" field="agent" width={100} textAlign="Center" />
            <ColumnDirective
              headerText="%"
              template={(rowData) => (
                <span>
                  {rowData?.agentBreakdown &&
                    rowData?.agentBreakdown.toFixed(opsCenter?.country?.decimalNumber ?? 2)}
                  %
                </span>
              )}
              width={100}
              textAlign="Center"
              customExcelTemplate={(rowData) =>
                rowData?.agentBreakdown &&
                rowData?.agentBreakdown.toFixed(opsCenter?.country?.decimalNumber ?? 2)
              }
            />
            <ColumnDirective headerText="Total" field="total" width={100} textAlign="Center" xw />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Page]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(HoursBreakDownDataGrid);
