import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// actions
import { getOldOrderNotifications } from 'store/notifications/actions/NotificationsActions';
// selectors
import { getOldOrderNotificationList } from 'store/notifications/selectors/NotificationsSelectors';
// components
import SectionLoader from 'components/common/SectionLoader';
const OrderNotificationsComponent = React.lazy(() =>
  import('components/notifications/OrderNotificationsComponent')
);

const OrderNotifications = () => {
  const orderNotificationsList = useSelector((state) => getOldOrderNotificationList({ state }));
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getOldOrderNotifications(params?.id));
  }, []);

  return (
    <div className="container-fluid">
      <h4>Order # {params?.id}</h4>
      <div className="row">
        {orderNotificationsList.map((item, i) => (
          <React.Suspense key={i} fallback={<SectionLoader />}>
            <OrderNotificationsComponent key={i} item={item} />
          </React.Suspense>
        ))}
      </div>
    </div>
  );
};

export default OrderNotifications;
