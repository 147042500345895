import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
import { errorLogger } from 'datadog/DDUtils';
// api
import AttributesService from 'services/AttributesService';
// type
import {
  GET_TOTAL_ATTRIBUTES_DATA,
  GET_SUB_ATTRIBUTES,
  GET_ATTRIBUTE_BY_ID,
} from './AttributesActionTypes';

export const getAttributesData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await AttributesService.getAttributesData(params);
    dispatch({
      type: GET_TOTAL_ATTRIBUTES_DATA,
      payload: res.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('AttributesActions:getAttributesData', err);
    toast.error('Failed to fetch attributes data!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const getSubAttributes = (params, id) => async (dispatch) => {
  try {
    const res = await AttributesService.getSubAttributesData(params, id);
    dispatch({
      type: GET_SUB_ATTRIBUTES,
      payload: res.data,
    });
  } catch (err) {
    errorLogger('AttributesActions:getSubAttributes', err);
    toast.error('Failed to fetch sub-attributes data!!');
  }
};

export const getAttributeByID = (type, id) => async (dispatch) => {
  try {
    const res = await AttributesService.getAttributeByIdData(type, id);
    dispatch({
      type: GET_ATTRIBUTE_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    errorLogger('AttributesActions:getAttributeByID', err);
    toast.error('Failed to fetch attribute ops data!!');
  }
};
