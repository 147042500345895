import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class TicketingService {
  static getTopicsList(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getTicketingTopicsList, ...extraParams),
      params,
    });
  }

  static addTicketingTopic(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.getTicketingTopicsList, ...extraParams),
      data,
    });
  }

  static updateTicketingTopic(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getTicketingTopicsList, ...extraParams),
      data,
    });
  }

  static createNewTicket(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.createNewTicket, ...extraParams),
      data,
    });
  }

  static createTechTicket(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.createTechTicket, ...extraParams),
      data,
    });
  }

  static getTicketsList(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getTicketsList, ...extraParams),
      params: data,
    });
  }

  static getTicketsListByOrderId(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getTicketsListByOrderId, ...extraParams),
    });
  }

  static getTicketById(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getTicketById, ...extraParams),
    });
  }

  static addNewTicketComment(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addNewTicketComment, ...extraParams),
      data,
    });
  }

  static getTicketCommentsList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getTicketCommentsList, ...extraParams),
    });
  }

  static handleCloseThread(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.closeThread, ...extraParams),
      data,
    });
  }

  static handleAssignUserToThread(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.assignUserToThread, ...extraParams),
      data,
    });
  }

  static uploadTicketAttachments(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.uploadAttachments, ...extraParams),
      data,
    });
  }
}
export default TicketingService;
