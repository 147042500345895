import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import CustomSelect from 'components/shared/CustomSelect';
// constants
import { inventoryTypes } from 'constant/Enums';

const MasterInventoryFilter = ({ handleSubmit, inputValues, setInputValues }) => {
  const [accActive, setAccActive] = useState(true);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[e.target.name] = e.target.value;
    setInputValues(updatedParams);
  };

  const handleSortBy = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.sortby = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleIventoryType = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.type = e.itemData.id;
    setInputValues(updatedParams);
  };

  const submitForm = (e) => {
    if (e.isComposing || e.keyCode === 229) {
      return;
    }
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="card">
      <Accordion preExpanded={['a']} allowZeroExpanded>
        <AccordionItem uuid="a">
          <AccordionItemHeading onClick={() => setAccActive((prev) => !prev)}>
            <AccordionItemButton>
              <div className="card-header between-flex p-18 pointer">
                <h4>Filter</h4>
                {accActive ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form onKeyDown={submitForm} className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label className="bold-title" htmlFor="masterInv-name">
                        Name or Barcode
                      </label>
                      <input
                        id="masterInv-name"
                        className="form-control"
                        type="text"
                        placeholder="Search by product name, SKU"
                        value={inputValues.key}
                        onChange={handleChange}
                        name="key"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group filter-select">
                      <label className="bold-title" htmlFor="masterInv-type">
                        Inventory type
                      </label>
                      <CustomSelect
                        id="masterInv-type"
                        listData={inventoryTypes.slice(1)}
                        handleChange={handleIventoryType}
                        placeholder="Inventory Type"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={-1}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group filter-select">
                      <label className="bold-title" htmlFor="masterInv-sort">
                        Sort by
                      </label>
                      <CustomSelect
                        id="masterInv-sort"
                        listData={[
                          { id: 1, name: 'Created Date (Newest)' },
                          { id: 0, name: 'Created Date (Oldest)' },
                        ]}
                        handleChange={handleSortBy}
                        placeholder="Sort by"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={-1}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-primary mr-1"
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default MasterInventoryFilter;
