import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import CustomSelect from 'components/shared/CustomSelect';
import RestrictedSection from 'components/shared/RestrictedSection';

const VouchersFilter = ({ inputValues, setInputValues, handleSubmit, voucherCatList, title }) => {
  const handleChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams[e.target.name] = e.target.value;
    setInputValues(updatedParams);
  };

  const handleVoucherCategory = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.catId = e.itemData.id;
    setInputValues(updatedParams);
  };

  const handleOpenCreateCoupon = () => {
    window.open('coupon/add', '_blank');
  };

  return (
    <div className="card">
      <div className="card-header p-18">
        <h5 className="sz-14">{title}</h5>
      </div>
      <div className="col-12 m-t-10">
        <RestrictedSection page="vouchers" action="add_voucher">
          <button onClick={handleOpenCreateCoupon} className="btn btn-success">
            <i className="fa fa-plus"></i> New
          </button>
        </RestrictedSection>
      </div>
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="form-group col-md-4">
              <div className="form-group">
                <label className="bold-title" htmlFor="voucher-code">
                  Voucher Code
                </label>
                <input
                  id="voucher-code"
                  className="form-control"
                  type="text"
                  placeholder="Voucher Code"
                  value={inputValues.code}
                  onChange={handleChange}
                  name="code"
                />
              </div>
            </div>
            {voucherCatList.length > 0 && (
              <div className="form-group col-md-4 filter-select">
                <label className="bold-title" htmlFor="voucher-category">
                  Voucher Category
                </label>
                <CustomSelect
                  id="voucher-category"
                  listData={[{ id: -1, name: 'All' }].concat(voucherCatList)}
                  handleChange={handleVoucherCategory}
                  placeholder="Select voucher category"
                  textPropName="name"
                  valuePropName="id"
                />
              </div>
            )}
          </div>
          <div className="card-footer d-flex justify-content-between">
            <button onClick={handleSubmit} className="btn btn-primary mr-1" type="button">
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VouchersFilter;
