import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import { convertToDateAndTime } from 'constant/Helpers';
import SectionLoader from 'components/common/SectionLoader';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';

const StatementsDataGrid = ({ statementData, isLoaderOpen }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="toolbar-table-style">
      <div className="control-pane">
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <div className="control-section">
            <GridComponent
              dataSource={statementData}
              toolbar={toolbarOptions}
              allowExcelExport={true}
              allowPdfExport={true}
              ref={ref}
              toolbarClick={toolbarClick}
              allowTextWrap={true}
              showColumnChooser={true}
              excelQueryCellInfo={excelQueryCellInfo}
              pdfQueryCellInfo={excelQueryCellInfo}
            >
              <ColumnsDirective>
                <ColumnDirective
                  headerText="Barcode"
                  field="barCode"
                  width={120}
                  textAlign="Center"
                />
                <ColumnDirective
                  headerText="Date"
                  template={(rowData) =>
                    rowData.createdDate && convertToDateAndTime(rowData.createdDate)
                  }
                  customExcelTemplate={(rowData) => convertToDateAndTime(rowData.createdDate)}
                  width={170}
                  textAlign="Center"
                />
                <ColumnDirective headerText="In" field="inStock" width={80} textAlign="Center" />
                <ColumnDirective headerText="Out" field="outStock" width={80} textAlign="Center" />
                <ColumnDirective
                  headerText="Damaged"
                  field="outStockDamaged"
                  width={120}
                  textAlign="Center"
                />
                <ColumnDirective
                  headerText="Available QTY"
                  field="balance"
                  customExcelTemplate={(rowData) => rowData.qty}
                  width={100}
                  textAlign="Center"
                />
                <ColumnDirective
                  headerText="Comment"
                  field="details"
                  width={120}
                  textAlign="Center"
                />
                <ColumnDirective headerText="User" field="user" width={120} textAlign="Center" />
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
            </GridComponent>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(StatementsDataGrid);
