import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// selectors
import {
  getAddInventoryBarcode,
  getAddInventoryIsSearching,
  getAddInventoryQuantity,
  getAddInventorySearch,
  getAddInventorySearchInventoryList,
} from 'store/viewOrder/inventory/addInventory/selectors/AddInventorySelectors';
import { getOrderCartDetails } from 'store/viewOrder/ViewOrderSelectors';
// actions
import {
  setAddInventoryBarcodeProperties,
  setAddInventoryQuantityProperties,
  setAddInventorySearchProperties,
} from 'store/viewOrder/inventory/addInventory/actions/AddInventoryActions';
// form configuration
import { addInventoryFormConfig } from 'constant/formsConfig/AddInventoryFormConfig';
// components
import Input from 'components/common/Input';
import AddInventoryCartList from '../AddInventoryCartList';
import AddInventorySearchResultsList from '../AddInventorySearchResultsList';
import LoadingIcon from 'components/common/LoadingIcon';

const AddInventoryForm = ({ opsId }) => {
  const dispatch = useDispatch();
  const { searchConf, barcodeConf, quantityConf } = addInventoryFormConfig;
  const search = useSelector((state) => getAddInventorySearch({ state }));
  const barcode = useSelector((state) => getAddInventoryBarcode({ state }));
  const quantity = useSelector((state) => getAddInventoryQuantity({ state }));
  const cartDetails = useSelector((state) => getOrderCartDetails({ state }));
  const list = useSelector((state) => getAddInventorySearchInventoryList({ state }));
  const isSearching = useSelector((state) => getAddInventoryIsSearching({ state }));

  const searchHandler = ({ target: { value } }) => {
    dispatch(setAddInventorySearchProperties(value, opsId));
  };

  const barcodeHandler = ({ target: { value } }) => {
    dispatch(setAddInventoryBarcodeProperties(value));
  };

  const quantityHandler = ({ target: { value } }) => {
    dispatch(setAddInventoryQuantityProperties(value));
  };

  return (
    <form>
      <Input
        elementType={searchConf.elementType}
        elementConfig={searchConf.elementConfig}
        value={search.value}
        changed={searchHandler}
        label={searchConf.label}
      />
      {list.length > 0 && <AddInventorySearchResultsList />}
      {list.length === 0 && search.value.length > 1 && !isSearching && (
        <p className="error-message">No results match your search criteria.</p>
      )}
      {isSearching && <LoadingIcon />}
      <Input
        elementType={barcodeConf.elementType}
        elementConfig={barcodeConf.elementConfig}
        value={barcode.value}
        changed={barcodeHandler}
        invalid={!barcode.valid}
        touched={barcode.touched}
        required={true}
        label={barcodeConf.label}
        errorsArray={[
          {
            key: 'isRequiredError',
            value: barcode.displayErrors.isRequiredError,
          },
        ]}
      />
      {cartDetails !== '' && cartDetails.cart && cartDetails.cart.length > 0 && (
        <AddInventoryCartList cartList={cartDetails.cart} />
      )}
      <Input
        elementType={quantityConf.elementType}
        elementConfig={quantityConf.elementConfig}
        value={quantity.value}
        changed={quantityHandler}
        invalid={!quantity.valid}
        touched={quantity.touched}
        required={true}
        label={quantityConf.label}
        errorsArray={[
          {
            key: 'isRequiredError',
            value: quantity.displayErrors.isRequiredError,
          },
          {
            key: 'isNumError',
            value: quantity.displayErrors.isNumError,
          },
          {
            key: 'isGreaterThanZeroError',
            value: quantity.displayErrors.isGreaterThanZeroError,
          },
        ]}
      />
    </form>
  );
};

export default AddInventoryForm;
