import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import SectionLoader from 'components/common/SectionLoader';
import CustomSelect from 'components/shared/CustomSelect';
// actions
import { setToggleSupportTicketModal } from 'store/orderQuickActions/dataTable/DataTableAction';
import { setTopicListData, setTicketsListByOrderId } from 'store/ticketing/TicketingActions';
// selectors
import { getDecodedToken } from 'store/app/AppSelectors';
import { getTopicsListData } from 'store/ticketing/TicketingSelectors';
// api
import TicketingService from 'services/TicketingService';
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const SupportTicketModal = ({ orderId, isSupportTicketModalOpen, orderData }) => {
  const [topic, setTopic] = useState('');
  const [topicTitle, setTopicTitle] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [assignedFirstName, setAssignedFirstName] = useState('');
  const [assignedLastName, setAssignedLastName] = useState('');
  const [assignedUserId, setAssignedUserId] = useState('');
  const [assignedEmail, setAssignedEmail] = useState('');
  const [department, setDepartment] = useState(0);
  const [showTable, setShowTable] = useState(true);
  const decodedToken = useSelector((state) => getDecodedToken({ state }));
  const topicsList = useSelector((state) => getTopicsListData({ state }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTopicListData({ departments: 0, status: true }));
  }, []);

  const fetchData = debounce(async (keyword) => {
    const params = {
      searchkey: keyword,
    };
    setSearchKey(keyword);
    setIsLoaderOpen(true);
    try {
      const res = await PermissionService.getSearchFlowardUsers(params);
      setSearchResult(res.data);
      setIsLoaderOpen(false);
    } catch (e) {
      errorLogger('SupportTicketModal:fetchData', e);
      setIsLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    setAssignedFirstName('');
    setAssignedLastName('');
    setAssignedUserId('');
    if (keyword.length >= 3) {
      fetchData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  const handleSelectUser = (user) => {
    setAssignedFirstName(user.name);
    setAssignedLastName(user.lastName);
    setAssignedUserId(user.id);
    setAssignedEmail(user.email);
    setShowTable(false);
  };

  const handleCreateTicket = async () => {
    const dataBody = {
      TopicId: topic.id,
      TopicName: topic.title,
      Title: title,
      Description: description,
      Mapid: orderId,
      Department: department,
      AssignedTo: assignedUserId,
      AssignedToName: `${assignedFirstName} ${assignedLastName}`,
      AssignedToEmail: assignedEmail,
      OpenedBy: decodedToken.Id,
      OpenedByName: decodedToken.FullName,
      OpsName: orderData.ops.nickName,
      opsId: orderData.opsID,
      ticketPriority: orderData.orderPriority,
      OpenedByEmail: decodedToken.Email,
    };
    setDisableSaveBtn(true);
    try {
      const res = await TicketingService.createNewTicket(dataBody);
      setTimeout(async () => {
        await dispatch(setTicketsListByOrderId(orderId));
        setDisableSaveBtn(true);
        dispatch(setToggleSupportTicketModal());
        toast.success(res?.data?.message ?? 'New ticket created');
      }, 2000);
    } catch (err) {
      errorLogger('SupportTicketModal:handleCreateTicket', err);
      setDisableSaveBtn(false);
      dispatch(setToggleSupportTicketModal());
      toast.error(err?.response?.data?.message ?? 'Failed to create new ticket');
    }
  };

  const handleTopicChange = (e) => {
    const topics = cloneDeep(topicsList);
    const selectedTopic = topics.filter(function (t) {
      return t.id === e.itemData.id;
    })[0];
    const formatedTopic = JSON.stringify(selectedTopic);
    setTopic(JSON.parse(formatedTopic));
    setTopicTitle(formatedTopic);
    setTitle(e.itemData.title);
  };

  const handleDepartmentChange = (e) => {
    dispatch(setTopicListData({ departments: e.itemData.id, status: true }));
    setDepartment(e.itemData.id);
  };

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isSupportTicketModalOpen}
        toggle={() => dispatch(setToggleSupportTicketModal())}
        className="support-ticket-modal-wrapper"
      >
        <ModalHeader>Create Ticket</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group col-12 filter-select">
              <label htmlFor="ticket-department">Department</label>
              <CustomSelect
                id="ticket-department"
                listData={[
                  { id: 0, name: 'Non-Tech Ticket' },
                  { id: 1, name: 'Tech-Ticket' },
                ]}
                handleChange={handleDepartmentChange}
                placeholder="Select department"
                textPropName="name"
                valuePropName="id"
                selectedValue={department}
              />
            </div>
            <div className="form-group col-12 filter-select">
              <label htmlFor="ticket-topic">Topic</label>
              <CustomSelect
                id="ticket-topic"
                listData={topicsList}
                handleChange={handleTopicChange}
                placeholder="Select a topic"
                textPropName="title"
                valuePropName="id"
                selectedValue={topicTitle}
              />
            </div>
            {topic && (
              <div className="form-group col-12">
                <label className="col-form-label" htmlFor="message-text">
                  Actions To Take
                </label>
                <textarea
                  value={topic.actionsToTake}
                  className="form-control"
                  id="message-text"
                  rows={5}
                  readOnly
                ></textarea>
              </div>
            )}
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Title
              </label>
              <input
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
                value={title}
                id="message-text"
              ></input>
            </div>
            <div className="form-group col-12">
              <label className="col-form-label" htmlFor="message-text">
                Description
              </label>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
                className="form-control"
                value={description}
                id="message-text"
              ></textarea>
            </div>
            {department !== 1 && (
              <div className="form-group col-12">
                <label htmlFor="ticket-assign">Assigned to (Optional)</label>
                <input
                  id="ticket-assign"
                  className="form-control search-ticket"
                  type="text"
                  placeholder="Phone, name, email address"
                  onChange={(e) => handleSearch(e.target.value)}
                  defaultValue={searchKey}
                />

                {isLoaderOpen ? (
                  <SectionLoader height="180px" />
                ) : (
                  <Fragment>
                    {searchResult.length > 0 && (
                      <div
                        className={`table-responsive shopping-table text-center ticket-search-result-table ${
                          showTable ? 'show-ticket-table' : ''
                        }`}
                      >
                        {showTable ? (
                          <table className="table table-bordernone create-order-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              {searchResult.map((item, i) => (
                                <tr
                                  className="search-table-row"
                                  onClick={() => handleSelectUser(item)}
                                  key={i}
                                >
                                  <td>{item.name}</td>
                                  <td>{item.phone}</td>
                                  <td>{item.email}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <h5>{`${assignedFirstName} ${assignedLastName}`}</h5>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={disableSaveBtn || !topic || !title || !description}
            onClick={handleCreateTicket}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={() => dispatch(setToggleSupportTicketModal())} color="light">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SupportTicketModal;
