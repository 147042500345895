import React from 'react';
import ProductMasterInventoryDataGrid from '../productsDataGrid/ProductMasterInventoryDataGrid';

const ProductMasterInventorySection = ({
  productId,
  productMasterInventoryDataById,
  productOptionsById,
  isValid,
  notValidFields,
  validateMI,
}) => {
  return (
    <div className="no-toolbar-table-style">
      <ProductMasterInventoryDataGrid
        productMasterInventoryDataById={productMasterInventoryDataById}
        productOptionsById={productOptionsById}
        productId={productId}
        isValid={isValid}
        notValidFields={notValidFields}
        validateMI={validateMI}
      />
    </div>
  );
};

export default ProductMasterInventorySection;
