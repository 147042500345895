import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class AttributesService {
  static getAttributesData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getAttributesData, ...extraParams),
      params: data,
    });
  }

  static getSubAttributesData(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getSubAttributesData, ...extraParams),
      params,
    });
  }

  static createNewAttribute(data, type, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(
        type === 'child' ? endPoints.createNewSubAttribute : endPoints.createNewAttribute,
        ...extraParams
      ),
      data,
    });
  }

  static getAttributeByIdData(type, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(
        type === 'child' ? endPoints.getSubAttributeByIdData : endPoints.getAttributeByIdData,
        ...extraParams
      ),
    });
  }

  static editAttributeData(data, type, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(
        type === 'child' ? endPoints.getSubAttributeByIdData : endPoints.getAttributeByIdData,
        ...extraParams
      ),
      data,
    });
  }
}
export default AttributesService;
