import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import CorporateReportDataGrid from 'components/reports/corporate/CorporateReportDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import {
  dateFormat,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';
// actions
import { setCustomerCorporateReport } from 'store/corporate/CorporateActions';
// selectors
import { getCustomerCorporateReport } from 'store/corporate/CorporateSelectors';

const CustomerCorporateReportPage = () => {
  const customerCorporateData = useSelector((state) => getCustomerCorporateReport({ state }));
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    dateType: 0,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setCustomerCorporateReport(params));
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: '/report/customerCorporate',
      search: queryString,
    });
    fetchData(inputValues);
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Customer Corporate Report"
        defaultDate
        productType
      />
      <CorporateReportDataGrid data={customerCorporateData} type={'customer'} />
    </div>
  );
};

export default CustomerCorporateReportPage;
