import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/shared/PageTitle';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import SearchUserModal from 'components/invoices/consolidatedInvoices/SearchUserModal';
import GeneratedConsolidatedDataGrid from 'components/invoices/GeneratedConsolidatedDataGrid';
// constants
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
import { getGeneratedInvoicesPageUrl } from 'constant/AppUrls';
// actions
import { getGeneratedConsolidatedInvoices } from 'store/invoices/InvoicesActions';
// selectors
import { getGeneratedConsolidatedInvoicesData } from 'store/invoices/InvoicesSelectors';
import SectionLoader from 'components/common/SectionLoader';

const GeneratedConsolidatedInvoicesPage = () => {
  const [isSearchUserModalOpen, setIsSearchUserModalOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const countryId = getFromLocalStorage(localStorageKeys.COUNTRY_ID);
  const invoicesData = useSelector((state) => getGeneratedConsolidatedInvoicesData({ state }));
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setUserId(queryObj.userid);
      setUserName(queryObj.name);
      setUserEmail(queryObj.email);
    } else {
      const queryObj = { userid: -1, countryid: countryId };
      dispatch(getGeneratedConsolidatedInvoices(queryObj));
    }
  }, []);

  const fetchGeneratedInvoicesData = async (queryObj) => {
    setIsLoading(true);
    await dispatch(getGeneratedConsolidatedInvoices(queryObj));
    setIsLoading(false);
  };

  useEffect(() => {
    if (userId) {
      const queryObj = { userid: userId, countryid: countryId };
      fetchGeneratedInvoicesData(queryObj);
      const queryParams = convertObjectIntoQueryParams({
        ...queryObj,
        name: userName,
        email: userEmail,
      });
      navigate({
        pathname: getGeneratedInvoicesPageUrl(),
        search: queryParams,
      });
    }
  }, [userId]);

  return (
    <>
      {isSearchUserModalOpen && (
        <SearchUserModal
          isSearchUserModalOpen={isSearchUserModalOpen}
          setIsSearchUserModalOpen={setIsSearchUserModalOpen}
          setUserName={setUserName}
          setUserId={setUserId}
          setUserEmail={setUserEmail}
        />
      )}
      <div className="no-toolbar-table-style">
        <PageTitle title="Generated-Consolidated-Invoices" />
        <div className="card">
          <div className="card-footer ">
            <button
              onClick={() => setIsSearchUserModalOpen((prev) => !prev)}
              className="btn btn-primary mr-1"
              type="button"
            >
              Select User
            </button>
          </div>
          {userId && (
            <div className="row card-body">
              <div className="form-group col-12 col-sm-6">
                <input className="form-control" type="text" value={userName} readOnly />
              </div>
              <div className="form-group col-12 col-sm-6">
                <input className="form-control" type="text" value={userEmail} readOnly />
              </div>
            </div>
          )}
        </div>
        {isLoading ? (
          <SectionLoader />
        ) : (
          <AppErrorBoundary>
            <GeneratedConsolidatedDataGrid
              data={invoicesData}
              userId={userId}
              countryId={countryId}
            />
          </AppErrorBoundary>
        )}
      </div>
    </>
  );
};

export default GeneratedConsolidatedInvoicesPage;
