import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  DetailRow,
} from '@syncfusion/ej2-react-grids';
// components
import RestrictedSection from 'components/shared/RestrictedSection';
import visaIcon from 'assets/images/visa.png';
import masterIcon from 'assets/images/master.svg';
import madaIcon from 'assets/images/mada.svg';
// actions
import { deleteUserSavedCC } from 'store/permissions/PermissionsActions';

const UserCardsDataGrid = ({ data }) => {
  const toolbarOptions = ['Search'];
  const grid = useRef(GridComponent);
  const dispatch = useDispatch();

  const rowTemplate = (args) => {
    if (args.row) {
      const nowDate = new Date();
      let expirationDate = '';
      if (args.data.expYear.length === 2) {
        expirationDate = args.data.expMonth + '/1/20' + args.data.expYear;
      } else {
        expirationDate = args.data.expMonth + '/1/' + args.data.expYear;
      }
      const expireDate = new Date(expirationDate);
      if (nowDate > expireDate) {
        args.row.classList.add('expired');
      }
    }
  };

  const visaCard = () => {
    return (
      <div className="visacard-bg">
        <img src={visaIcon} className="visacard-icon" alt="visa" />
      </div>
    );
  };

  const masterCard = () => {
    return (
      <div className="mastercard-bg">
        <img src={masterIcon} className="mastercard-icon" alt="mastercard" />
      </div>
    );
  };

  const madaCard = () => {
    return (
      <div className="madacard-bg">
        <img src={madaIcon} className="madacard-icon" alt="mada" />
      </div>
    );
  };

  const getCardIcon = (type) => {
    switch (type) {
      case 'visa':
        return visaCard();

      case 'mastercard':
        return masterCard();

      case 'mada':
        return madaCard();

      default:
        return '';
    }
  };

  const handleType = (rowData) => {
    return (
      <span className="ccIcon-container">
        {getCardIcon(rowData.scheme?.toLowerCase())}
        {rowData.lastFour}
      </span>
    );
  };

  const handleTimeStamp = (rowData) => {
    let expirationDate = '';
    if (rowData.expYear.length === 2) {
      expirationDate = rowData.expMonth + '/20' + rowData.expYear;
    } else {
      expirationDate = rowData.expMonth + '/' + rowData.expYear;
    }
    return expirationDate;
  };

  // const handleValid = (rowData) => {
  // if (rowData.status === 1)
  // return <span className="valid-cc">{`Valid`}</span>;
  // else return <span className="invalid-cc">{`InValid`}</span>;
  // };

  // const handleDefault = (rowData) => {
  // if(rowData.default === 1)
  // return <span className="defaut-val"><i className="thin-check" aria-hidden="true"></i>Yes</span>;
  // else return <span className="defaut-val"><i className="thin-close">X</i>No</span>;
  // }

  const handleAccordian = () => {
    return (
      <div className="cc-details-container">
        <p className="cc-details">Details</p>
        <p className="cc-details-close">Close</p>
        <i className="fa fa-2x fa-angle-down extand-cc" aria-hidden="true"></i>
      </div>
    );
  };

  function copytxt() {
    const copiedtxt = document.getElementById('card-id-value');
    /* Select the text field */
    copiedtxt.select();
    copiedtxt.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand('copy');
  }

  function deleteCard(cardId) {
    const newdata = data.filter(function (d) {
      return d.id !== cardId;
    });
    dispatch(deleteUserSavedCC(cardId, newdata));
  }

  const gridTemplate = (props) => {
    return (
      <table className="detailtable w-100">
        <colgroup>
          <col className="w-50" />
          <col className="w-30" />
          <col className="w-20" />
        </colgroup>
        <tbody>
          <tr>
            <td>
              <p className="cc-details-title">Card ID</p>
            </td>
            <td>
              <p className="cc-details-title">Address</p>
            </td>
            <td>
              <RestrictedSection page="admin_user_info" action="delete_cc">
                <div className="remove-box" onClick={() => deleteCard(props.id)}>
                  <i className="fa fa-trash-o" aria-hidden="true"></i> Remove
                </div>
              </RestrictedSection>
            </td>
          </tr>
          <tr>
            <td>
              <div className="cardid-val-box">
                <input id="card-id-value" readOnly value={props.cardId} />
                <div className="copy-box" onClick={copytxt}>
                  <p>Copy</p>
                </div>
              </div>
            </td>
            <td>
              <p>{props.address === '' ? 'Unkown' : props.address}</p>
            </td>
            <td></td>
          </tr>
          <tr>
            <td className="cc-details-title avs-title">AVS check</td>
            <td></td>
            <td>
              <div className="expired-box">
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Expired
              </div>
            </td>
          </tr>
          <tr>
            <td>{!props.avsCheck ? 'Not applicable' : props.avsCheck}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    setTimeout(function () {
      const details = document.querySelectorAll(
        '.saved-credicard-table .e-gridcontent table tr td:last-child'
      );
      for (let i = 0; i < details.length; i++) {
        details[i].className = 'e-detailrowcollapse e-rowcell e-templatecell';
      }
    }, 2000);
  }, [data]);

  return (
    <div className="control-pane">
      <div className="control-section saved-credicard-table">
        <GridComponent
          ref={grid}
          dataSource={data}
          allowPaging={false}
          toolbar={toolbarOptions}
          allowTextWrap={true}
          rowHeight={50}
          showColumnChooser={false}
          detailTemplate={gridTemplate}
          rowDataBound={rowTemplate}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="Card type" template={handleType} width={150} />
            <ColumnDirective
              headerText="Expiration date"
              customAttributes={{ class: 'expiration-txt' }}
              template={handleTimeStamp}
              width={150}
            />
            {/* <ColumnDirective headerText="Status" template={handleValid} width={150} />
            <ColumnDirective headerText="Default" template={handleDefault} width={150} /> */}
            <ColumnDirective headerText="Actions" template={handleAccordian} width={150} />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, DetailRow]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(UserCardsDataGrid);
