import { updateObject } from '../utility';

const initialState = {
  totalOrders: '',
  todayOrders: '',
  totalUsers: '',
  todayRegisteredUsers: '',
  registeredUsers: '',
  subscriptionBilling: '',
  totalProducts: '',
  liveTotalProducts: '',
  timeSlotsList: [],
  premiumTimeSlotsList: [],
  timeSlotsPrintStatusList: [],
  corporateOrders: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DASHBOARD_TOTAL_ORDERS': {
      return updateObject(state, {
        totalOrders: action.payload,
      });
    }
    case 'DASHBOARD_TODAY_ORDERS': {
      return updateObject(state, {
        todayOrders: action.payload,
      });
    }
    case 'DASHBOARD_TOTAL_USERS': {
      return updateObject(state, {
        totalUsers: action.payload,
      });
    }
    case 'DASHBOARD_TODAY_REGISTERED_USERS': {
      return updateObject(state, {
        todayRegisteredUsers: action.payload,
      });
    }
    case 'DASHBOARD_REGISTERED_USERS': {
      return updateObject(state, {
        registeredUsers: action.payload,
      });
    }
    case 'DASHBOARD_SUBSCRIPTION_BILLING': {
      return updateObject(state, {
        subscriptionBilling: action.payload,
      });
    }
    case 'DASHBOARD_TOTAL_PRODUCTS': {
      return updateObject(state, {
        totalProducts: action.payload,
      });
    }
    case 'DASHBOARD_LIVE_TOTAL_PRODUCTS': {
      return updateObject(state, {
        liveTotalProducts: action.payload,
      });
    }
    case 'SET_DASHBOARD_TIME_SLOTS_LIST': {
      return updateObject(state, { timeSlotsList: action.list });
    }
    case 'SET_DASHBOARD_PREMIUM_TIME_SLOTS_LIST': {
      return updateObject(state, { premiumTimeSlotsList: action.list });
    }
    case 'SET_DASHBOARD_TIME_SLOTS_PRINT_STATUS_LIST': {
      return updateObject(state, { timeSlotsPrintStatusList: action.payload });
    }
    case 'DASHBOARD_CORPORATE_ORDERS': {
      return updateObject(state, { corporateOrders: action.payload });
    }
    default:
      return state;
  }
};

export default reducer;
