import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
  Resize,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { toast } from 'react-toastify';
// api
import ProductsReportService from 'services/reportsService/ProductsReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const OldProductsDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'ColumnChooser', 'Search'];
  const ref = useRef();

  const toolbarClick = async (args) => {
    switch (args.item.text) {
      case 'Excel Export':
        try {
          const res = await ProductsReportService.exportOldProductsReport();
          toast.success(res?.data?.message);
        } catch (err) {
          errorLogger('Old Products Report Page Export Data Error:', err);
          toast.error('Failed to export excel file!!');
        }
        break;

      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={false}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          allowTextWrap={true}
          rowHeight={80}
          allowResizing={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Product Id"
              field="productId"
              width={100}
              customExcelTemplate={(rowData) => rowData.productId}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product SKU"
              field="sku"
              width={100}
              customExcelTemplate={(rowData) => rowData.sku}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product Type"
              field="productType"
              width={100}
              customExcelTemplate={(rowData) => rowData.productType}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="BarCode"
              field="barcode"
              width={100}
              customExcelTemplate={(rowData) => rowData.barcode}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Status"
              field="inventoryStatus"
              width={100}
              customExcelTemplate={(rowData) => rowData.inventoryStatus}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, ColumnChooser, Resize]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(OldProductsDataGrid);
