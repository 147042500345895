import React from 'react';
import { useDispatch } from 'react-redux';
// material UI
import Tooltip from '@mui/material/Tooltip';
// actions
import { setToggleAddressModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';

const RecipientAddress = ({ id, opsID, recipient, opsInfo, orderCstep }) => {
  const dispatch = useDispatch();

  return (
    <div className="recipient-address text-center">
      <p className="recipient-area">
        {recipient && recipient.area ? recipient.area + ' - ' + recipient.zone : ''}
      </p>
      <p>{recipient ? recipient.address : ''}</p>
      <p>{recipient ? recipient.extra : ''}</p>
      {orderCstep !== 4 && (
        <Tooltip title="Edit">
          <i
            onClick={() => dispatch(setToggleAddressModalOpen(id, recipient, true, opsID, opsInfo))}
            className="fa fa-edit icon"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default RecipientAddress;
