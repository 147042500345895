import { updateObject } from '../../utility';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  driversExpensesData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DRIVERS_EXPENSES_DATA': {
      return updateObject(state, {
        driversExpensesData: action.payload,
      });
    }

    case 'GET_UPDATE_DRIVER_EXPENSES_STATUS': {
      const clonedDriversExpensesData = cloneDeep(state.driversExpensesData);
      clonedDriversExpensesData.data.find((item) => item.id === action.payload.id).status =
        action.payload.status;
      return updateObject(state, {
        driversExpensesData: clonedDriversExpensesData,
      });
    }
    default:
      return state;
  }
};

export default reducer;
