import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';

const gender = {
  0: 'Male',
  1: 'Female',
  2: 'None (not selected)',
};

const handleClick = (row) => {
  window.open(`/admin/users/${row.id}`, '_blank');
};

const UsersDataGrid = ({ data }) => {
  const toolbarOptions = ['Search'];
  const ref = useRef();

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          ref={ref}
          rowHeight={60}
          allowTextWrap={true}
          rowSelected={(row) => {
            handleClick(row.data);
          }}
          style={{ cursor: 'pointer' }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Name"
              template={(row) => (
                <span className="primary-color">
                  {row.name} {row.lastName}
                </span>
              )}
              width={80}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Email"
              template={(row) => <span className="primary-color">{row.email}</span>}
              width={120}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Phone"
              template={(row) => <span className="primary-color">{row.phone}</span>}
              width={100}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Gender"
              template={(row) => <span>{gender[row.gender]}</span>}
              width={80}
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Toolbar]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(UsersDataGrid);
