import React, { Fragment } from 'react';
// components
import PageTitle from 'components/shared/PageTitle';
import EditCouponSection from 'components/vouchers/EditCouponSection';

const AddCouponPage = () => {
  return (
    <Fragment>
      <PageTitle title="Add Coupon" />
      <EditCouponSection />
    </Fragment>
  );
};

export default AddCouponPage;
