import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import SectionLoader from 'components/common/SectionLoader';
// constants
import { dateFormatDDMM } from 'constant/Helpers';
// actions
import { setOrderInvoicesData } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getOrderInvoicesData } from 'store/viewOrder/ViewOrderSelectors';

const OrderInvoicesDataGrid = ({ orderId }) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const orderInvoicesData = useSelector((state) => getOrderInvoicesData({ state }));
  const ref = useRef();
  const dispatch = useDispatch();

  const invoiceTypeValue = {
    0: 'TaxInvoice',
    1: 'CreditNote',
    2: 'UserWallet',
  };

  const fetchInvoicesData = async (id) => {
    setIsLoaderOpen(true);
    await dispatch(setOrderInvoicesData(id));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchInvoicesData(orderId);
  }, []);

  const countryTemplate = (rowData) => (
    <span>
      {rowData.country}/{rowData.currency}
    </span>
  );

  const createdAtTemplate = (rowData) => <span>{dateFormatDDMM(rowData.createdAt)}</span>;

  const invoiceDateTemplate = (rowData) => <span>{dateFormatDDMM(rowData.invoiceDate)}</span>;

  const downInvoiceTemplate = (rowData) => (
    <button
      disabled={!rowData.filePath}
      className="btn btn-success"
      onClick={() => window.open(rowData.filePath, 'blank')}
    >
      <i className="fa fa-download"></i> Download
    </button>
  );

  const viewInvoiceTemplate = (rowData) => (
    <button
      disabled={!rowData.link}
      className="btn btn-info"
      onClick={() => window.open(rowData.link, 'blank')}
    >
      View
    </button>
  );

  const invoiceStatementTemplate = (rowData) => (
    <span>{invoiceTypeValue[rowData.invoiceStatmentType]}</span>
  );

  return (
    <div className="control-pane">
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="control-section">
          <GridComponent dataSource={orderInvoicesData} ref={ref} allowTextWrap={true}>
            <ColumnsDirective>
              <ColumnDirective
                field="serialNumberDisplay"
                headerText="Serial Number Display"
                width={110}
              />
              <ColumnDirective
                field="country"
                headerText="Country"
                template={countryTemplate}
                width={110}
              />
              <ColumnDirective
                field="createdAt"
                headerText="Created at"
                template={createdAtTemplate}
                width={110}
              />
              <ColumnDirective
                field="invoiceDate"
                headerText="Invoice Date"
                template={invoiceDateTemplate}
                width={110}
              />
              <ColumnDirective
                field="invoiceStatmentType"
                headerText="Invoice Statment Type"
                template={invoiceStatementTemplate}
                width={110}
              />
              <ColumnDirective
                field="link"
                headerText="View"
                template={viewInvoiceTemplate}
                width={110}
              />
              <ColumnDirective
                field="link"
                headerText="Download"
                template={downInvoiceTemplate}
                width={110}
              />
            </ColumnsDirective>
          </GridComponent>
        </div>
      )}
    </div>
  );
};

export default OrderInvoicesDataGrid;
