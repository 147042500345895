import React from 'react';
import { useNavigate } from 'react-router-dom';
import RestrictedSection from 'components/shared/RestrictedSection';

const CelebritiesActions = () => {
  const navigate = useNavigate();

  return (
    <div className="card">
      <div className="card-body btn-showcase">
        <RestrictedSection page="celebrities" action="add_celebrity">
          <button
            onClick={() => navigate('/celebrity/new')}
            type="button"
            className="btn btn-success"
          >
            <i className="fa fa-plus"></i> Add Celebrity
          </button>
        </RestrictedSection>
      </div>
    </div>
  );
};

export default CelebritiesActions;
