import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// selectors
import { getIsLoggedIn } from '../store/app/AppSelectors';
// constants
import { getLoginPageUrl } from '../constant/AppUrls';
import AppErrorBoundary from '../components/errorBoundary/AppErrorBoundary';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));

  if (isLoggedIn) {
    return (
      <AppErrorBoundary>
        <Component {...rest} />
      </AppErrorBoundary>
    );
  }
  return (
    <Navigate
      to={{
        pathname: getLoginPageUrl(),
        state: { from: rest.location },
      }}
    />
  );
};

export default PrivateRoute;
