import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import CustomSelect from 'components/shared/CustomSelect';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const DeliverySlotsFilter = ({ handleSubmit, inputValues, setInputValues }) => {
  const [accActive, setAccActive] = useState(false);
  const [status, setStatus] = useState(0);

  const handleStatusChange = (e) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.status = e.itemData.id;
    setStatus(e.itemData.id);
    setInputValues(updatedParams);
  };

  useEffect(() => {
    setStatus(Number(inputValues.status));
  }, [inputValues.status]);

  return (
    <div className="card ">
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading onClick={() => setAccActive((prev) => !prev)}>
            <AccordionItemButton>
              <div className="card-header filter-accordion-card">
                <h4>Filter</h4>
                {accActive ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-xl-3">
                    <div className="form-group">
                      <label className="bold-title" htmlFor="delvslots-status">
                        Status
                      </label>
                      <CustomSelect
                        id="delvslots-status"
                        listData={[
                          { id: 0, name: 'Published' },
                          { id: 1, name: 'Archived' },
                        ]}
                        handleChange={handleStatusChange}
                        placeholder="Select a status"
                        textPropName="name"
                        valuePropName="id"
                        selectedValue={status}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary mr-1" type="button" onClick={handleSubmit}>
                    Search
                  </button>
                </div>
              </div>
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default DeliverySlotsFilter;
