import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
// modals
import AddNewItemModal from '../modals/AddNewItemModal';
import AddQtyModal from '../modals/AddQtyModal';
import EditItemQtyModal from '../modals/EditItemQtyModal';
import UploadExcelFile from '../modals/UploadExcelFile';
import RestrictedSection from 'components/shared/RestrictedSection';
// actions
import {
  setExportInventory,
  setAllItemsExport,
  setAllItemsAllOpsExport,
} from 'store/inventory/InventoryActions';

const InventoryItemsActions = ({
  isAddItemModalOpen,
  setIsAddItemModalOpen,
  defaultParams,
  setClickedRow,
  clickedRow,
  isAddQtyModalOpen,
  setIsAddQtyModalOpen,
  isEditItemQtyModalOpen,
  setIsEditItemQtyModalOpen,
  isUploadExcelModalOpen,
  setIsUploadExcelModalOpen,
  isAllItemsPage,
  isAllCitiesPage,
}) => {
  const [damagedValue, setDamagedValue] = useState('');
  const dispatch = useDispatch();

  const handleExportInventory = () => {
    if (isAllCitiesPage) {
      dispatch(setAllItemsAllOpsExport(defaultParams));
      return;
    }
    if (isAllItemsPage) {
      dispatch(setAllItemsExport(defaultParams));
    } else {
      dispatch(setExportInventory(defaultParams));
    }
  };

  return (
    <Fragment>
      {isAddItemModalOpen && (
        <AddNewItemModal
          isAddItemModalOpen={isAddItemModalOpen}
          setIsAddItemModalOpen={setIsAddItemModalOpen}
          defaultParams={defaultParams}
          clickedRow={clickedRow}
        />
      )}
      {isAddQtyModalOpen && (
        <AddQtyModal
          isAddQtyModalOpen={isAddQtyModalOpen}
          setIsAddQtyModalOpen={setIsAddQtyModalOpen}
          defaultParams={defaultParams}
          damagedValue={damagedValue}
        />
      )}
      {isEditItemQtyModalOpen && (
        <EditItemQtyModal
          isEditItemQtyModalOpen={isEditItemQtyModalOpen}
          setIsEditItemQtyModalOpen={setIsEditItemQtyModalOpen}
          clickedRow={clickedRow}
          defaultParams={defaultParams}
        />
      )}
      {isUploadExcelModalOpen && (
        <UploadExcelFile
          isUploadExcelModalOpen={isUploadExcelModalOpen}
          setIsUploadExcelModalOpen={setIsUploadExcelModalOpen}
        />
      )}

      <div className="card">
        <div className="card-body btn-showcase">
          {!isAllItemsPage && (
            <>
              <RestrictedSection page="inventory" action="add_inventory_item">
                <button
                  type="button"
                  className="btn btn-success "
                  onClick={() => {
                    setClickedRow('');
                    setIsAddItemModalOpen((prev) => !prev);
                  }}
                >
                  <i className="fa fa-plus"></i> Add Item
                </button>
              </RestrictedSection>

              <button
                type="button"
                className="btn btn-success "
                onClick={() => {
                  setDamagedValue(0);
                  setIsAddQtyModalOpen((prev) => !prev);
                }}
              >
                <i className="fa fa-plus"></i> Add Qty
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setDamagedValue(1);
                  setIsAddQtyModalOpen((prev) => !prev);
                }}
              >
                <i className="fa fa-minus"></i> Remove Qty
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setDamagedValue(2);
                  setIsAddQtyModalOpen((prev) => !prev);
                }}
              >
                <i className="fa fa-minus"></i> Damaged Qty
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  setDamagedValue(3);
                  setIsAddQtyModalOpen((prev) => !prev);
                }}
              >
                <i className="fa fa-minus"></i> Variance Qty
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setIsUploadExcelModalOpen((prev) => !prev);
                }}
              >
                <i className="fa fa-upload"></i> Upload
              </button>
            </>
          )}
          <button type="button" className="btn btn-secondary" onClick={handleExportInventory}>
            <i className="fa fa-file"></i> Export
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryItemsActions;
