import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
import cloneDeep from 'lodash/cloneDeep';
import SenderModal from './modals/SenderModal';
import ProductModal from './modals/ProductModal';
import ConfirmationModal from './modals/ConfirmationModal';
import DatePicker from 'react-datepicker';
import CustomSelect from 'components/shared/CustomSelect';
// material UI
import Tooltip from '@mui/material/Tooltip';
// helpers
import { dateFormatDDMM, excelToJSON, isRowValid } from 'constant/Helpers';
import { localStorageKeys, orderTypes } from 'constant/Enums';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { toast } from 'react-toastify';
// api
import ViewOrderService from 'services/ViewOrderService';
// actions
import { setSalesOfficerList } from 'store/corporate/CorporateActions';
// selectors
import { getSalesOfficerList } from 'store/corporate/CorporateSelectors';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const CreateOrderPage = () => {
  const [dataTable, setDataTable] = useState([]);
  const [isValidList, setIsValidList] = useState([]);
  const [goNext, setGoNext] = useState(true);
  const [showNextPage, setShowNextPage] = useState(false);
  const [isSenderModalOpen, setIsSenderModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(-1);
  const [selectedDate, setSelectedDate] = useState('');
  const [isNationwide, setIsNationwide] = useState(false);
  const [orderType, setOrderType] = useState(3);
  const [salesOfficer, setSalesOfficer] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [goConfirm, setGoConfirm] = useState(false);
  const [processMessage, setProcessMessage] = useState('');
  const [processResType, setProcessResType] = useState('success');
  const [loading, setLoading] = useState(false);
  const opsId = getFromLocalStorage(localStorageKeys.OPS_ID);
  const salesOfficerList = useSelector((state) => getSalesOfficerList({ state }));
  const dispatch = useDispatch();

  const checkValid = (validList, data) => {
    const newValidList = cloneDeep(validList);
    const numberReg = /^[0-9]+$/;
    const priceReg = /^[0-9]+([.][0-9]+)?$/;
    data.forEach((row, rowInd) => {
      if (rowInd !== 0) {
        row.forEach((cell, cellInd) => {
          switch (cellInd) {
            case 0:
              if (cell === '') {
                newValidList[rowInd][cellInd] = false;
                setGoNext(false);
              }
              break;
            case 1:
              if (cell === '' || !numberReg.test(cell)) {
                newValidList[rowInd][cellInd] = false;
                setGoNext(false);
              }
              break;
            case 2:
              if (cell === '' || !priceReg.test(cell)) {
                newValidList[rowInd][cellInd] = false;
                setGoNext(false);
              }
              break;
            default:
              break;
          }
        });
      }
    });
    setIsValidList(newValidList);
  };

  const moveToNext = () => {
    setShowNextPage(true);
  };

  const handleChangeDate = async (date) => {
    const params = {
      date: dateFormatDDMM(date),
      fulfillmenttype: 0,
      opsid: opsId,
      nwDelivery: isNationwide,
    };
    try {
      const res = await ViewOrderService.getDeliveryTimeSlot(params);
      setTimeSlotsData(res.data.avilableSlots);
      setSelectedTimeSlot(-1);
    } catch (e) {
      errorLogger('CreateOrderPage:handleChangeDate', e);
      toast.error('No available slots');
    }
    setSelectedDate(date);
  };

  const handleTimeSlotChange = (e) => {
    setSelectedTimeSlot(e.itemData.id);
  };

  const handleOrderTypeChange = (e) => {
    setOrderType(e.itemData.value);
  };

  const handleSalesOfficerChange = (e) => {
    setSalesOfficer(e.itemData.id);
  };

  const moveToConfirm = () => {
    setShowConfirmationModal(true);
  };

  const goBackToStepOne = () => {
    setShowNextPage(false);
  };

  const validateConfirm = (
    _selectedProduct,
    _selectedUser,
    _selectedTimeSlot,
    _selectedDate,
    _orderType,
    _salesOfficer
  ) => {
    if (_selectedProduct.id && _selectedUser.userId && _selectedTimeSlot > -1 && _selectedDate) {
      if (_orderType === 0) {
        setGoConfirm(true);
      } else {
        if (_salesOfficer > 0) {
          setGoConfirm(true);
        } else {
          setGoConfirm(false);
        }
      }
    } else {
      setGoConfirm(false);
    }
  };

  const clearAfterSuccess = (msg, haveSuccessChunck) => {
    setIsValidList([]);
    setDataTable([]);
    goBackToStepOne();
    if (haveSuccessChunck) {
      if (msg.length > 0) {
        setProcessResType('success');
        setProcessMessage(
          `Orders have been created successfully. Some orders failed to be created, please check the uploaded file (${msg.join(
            '<br>'
          )})`
        );
      } else {
        setProcessResType('success');
        setProcessMessage('Orders have been created successfully.');
      }
    } else {
      setProcessResType('error');
      setProcessMessage(
        `Orders failed to be created, please check the uploaded file (${msg.join('<br>')})`
      );
    }
  };

  const validationMessages = {
    0: "This field is required and can't be empty",
    1: "This field is required, can't be empty and it should contain numbers only",
    2: "This field is required, can't be empty and it should contain numbers only, no commas. You can use a dot for ex: 23.5 or 23",
  };

  useEffect(() => {
    dispatch(setSalesOfficerList());
  }, []);

  useEffect(() => {
    validateConfirm(
      selectedProduct,
      selectedUser,
      selectedTimeSlot,
      selectedDate,
      orderType,
      salesOfficer
    );
  }, [selectedProduct, selectedUser, selectedTimeSlot, selectedDate, orderType, salesOfficer]);

  return (
    <>
      <PageTitle title="Orders" />
      {isSenderModalOpen && (
        <SenderModal
          isSenderModalOpen={isSenderModalOpen}
          setIsSenderModalOpen={setIsSenderModalOpen}
          setSelectedUser={setSelectedUser}
        />
      )}
      {isProductModalOpen && (
        <ProductModal
          isProductModalOpen={isProductModalOpen}
          setIsProductModalOpen={setIsProductModalOpen}
          setSelectedProduct={setSelectedProduct}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          dataTable={dataTable}
          selectedProduct={selectedProduct}
          selectedUser={selectedUser}
          selectedTimeSlot={selectedTimeSlot}
          selectedDate={selectedDate}
          orderType={orderType}
          salesOfficer={salesOfficer}
          salesOfficerList={salesOfficerList}
          timeSlotsData={timeSlotsData}
          clearAfterSuccess={clearAfterSuccess}
        />
      )}
      {!showNextPage && (
        <>
          {processMessage !== '' && (
            <div className={`card order-snackbar-msg ${processResType}-snackbar-msg`}>
              {processResType === 'success' && (
                <i className="fa fa-check-circle" aria-hidden="true"></i>
              )}
              {processResType === 'error' && (
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
              )}
              <p dangerouslySetInnerHTML={{ __html: processMessage }}></p>
            </div>
          )}
          <div className="card">
            <div className="card-header p-18">
              <h5 className="sz-14">Create Bulk Order</h5>
            </div>
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="col-form-label pt-0" htmlFor="excel-create-order">
                      Upload Excel (
                      <a
                        className="col-form-label mt-1 template-download-link normal-text"
                        href="https://floward.s3.eu-west-2.amazonaws.com/web/bulk-action.xlsx"
                        title="Download bulk order template"
                      >
                        download template
                      </a>
                      )
                    </label>
                    <input
                      id="excel-create-order"
                      onChange={(e) =>
                        excelToJSON(
                          e.target.files[0],
                          setDataTable,
                          setIsValidList,
                          checkValid,
                          setGoNext,
                          setLoading
                        )
                      }
                      onClick={(e) => (e.target.value = null)}
                      className="form-control"
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          {dataTable.length > 0 && !loading && (
            <div className="card p-18 bulk-orders-list">
              <p>
                <i className="m-r-5 fa fa-exclamation-circle" aria-hidden="true"></i>If any row was
                highlighted with red, hover over the row&apos;s cells to check which one is causing
                the error
              </p>
              <table className="m-b-0 w-100">
                <thead
                  className="align-center"
                  style={{ borderBottom: '1px solid black', textTransform: 'capitalize' }}
                >
                  <tr>
                    {dataTable[0].map((head, headInd) => {
                      return (
                        <th style={{ height: 70 }} key={headInd}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
              </table>
              <div className="confirmation-table-scroll">
                <table className="m-b-0 w-100">
                  <tbody className="align-center">
                    {dataTable.map((row, rowInd) => {
                      if (rowInd !== 0) {
                        return (
                          <tr
                            style={{
                              height: 40,
                              backgroundColor: !isRowValid(isValidList[rowInd])
                                ? 'rgba(255, 142, 142, 0.62)'
                                : 'white',
                            }}
                            key={rowInd}
                          >
                            {row.map((cell, cellInd) => {
                              if (isValidList[rowInd][cellInd]) {
                                return (
                                  <td key={cellInd}>
                                    {cellInd === row.length - 1 ? (
                                      <a
                                        className="primary-color text-underline"
                                        href={cell}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {cell}
                                      </a>
                                    ) : (
                                      cell
                                    )}
                                  </td>
                                );
                              } else {
                                return (
                                  <Tooltip title={validationMessages[cellInd] || ''} key={cellInd}>
                                    <td>
                                      {cellInd === row.length - 1 ? (
                                        <a
                                          className="primary-color text-underline"
                                          href={cell}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {cell}
                                        </a>
                                      ) : (
                                        cell
                                      )}
                                    </td>
                                  </Tooltip>
                                );
                              }
                            })}
                          </tr>
                        );
                      } else {
                        return <tr key={rowInd}></tr>;
                      }
                    })}
                  </tbody>
                </table>
              </div>
              <div className="card-footer">
                <button
                  disabled={!goNext}
                  onClick={moveToNext}
                  className="btn btn-primary"
                  type="button"
                  style={{ marginLeft: 'auto', display: 'block' }}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {showNextPage && (
        <div className="card p-18">
          <form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="form-group col-6">
                  <div className="row p-l-15">
                    <label htmlFor="select-product">Product Name</label>
                    {selectedProduct.name && (
                      <a
                        href={`/product/editProduct/${selectedProduct.id}/0`}
                        target="_blank"
                        rel="noreferrer"
                        className="m-l-10 primary-color bold-title"
                      >
                        {selectedProduct.name} {selectedProduct.sku}
                      </a>
                    )}
                  </div>
                  <div className="row m-b-30 p-l-15">
                    <button
                      id="select-product"
                      onClick={() => setIsProductModalOpen(true)}
                      className="btn btn-success"
                      type="button"
                    >
                      Search for product
                    </button>
                  </div>
                </div>
                <div className="form-group col-6">
                  <div className="row p-l-15">
                    <label htmlFor="select-sender">Sender Name</label>
                    {selectedUser.fName && (
                      <a
                        href={`/admin/users/${selectedUser.userId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="m-l-10 primary-color bold-title"
                      >
                        {selectedUser.fName} {selectedUser.lName}
                      </a>
                    )}
                  </div>
                  <div className="row m-b-30 p-l-15">
                    <button
                      id="select-sender"
                      onClick={() => setIsSenderModalOpen(true)}
                      className="btn btn-success"
                      type="button"
                    >
                      Search for sender
                    </button>
                  </div>
                </div>
              </div>
              <div className="row m-b-30">
                {opsId === 10 && (
                  <div className="form-group col-12">
                    <input
                      className="checkbox_animated"
                      type="checkbox"
                      checked={isNationwide}
                      onChange={(e) => setIsNationwide((prev) => !prev)}
                    />
                    <span className="bold-title">Nantionwide Delivery?</span>
                  </div>
                )}
                <div className="form-group col-6">
                  <label htmlFor="select-delivery-date">Delivery Date</label>
                  <DatePicker
                    id="select-delivery-date"
                    className="form-control digits bulk-datepicker"
                    placeholderText="Selected delivery date"
                    selected={selectedDate}
                    onChange={handleChangeDate}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                </div>
                {timeSlotsData.length > 0 && (
                  <div className="form-group col-6 filter-select delivery-time-select">
                    <label htmlFor="select-delivery-time">Delivery Time</label>
                    <CustomSelect
                      id="select-delivery-time"
                      listData={[{ id: -1, name: 'Select time slot' }].concat(timeSlotsData)}
                      handleChange={handleTimeSlotChange}
                      placeholder="Select an delivery time"
                      textPropName="name"
                      valuePropName="id"
                      selectedValue={selectedTimeSlot}
                    />
                  </div>
                )}
              </div>
              <div className="row m-b-30">
                <div className="form-group col-6 filter-select">
                  <label htmlFor="select-sales-officer">Sales Officer</label>
                  <CustomSelect
                    id="select-sales-officer"
                    listData={salesOfficerList}
                    handleChange={handleSalesOfficerChange}
                    placeholder="Select a sales officer"
                    textPropName="name"
                    valuePropName="id"
                    selectedValue={salesOfficer}
                  />
                </div>
                <div className="form-group col-6 filter-select">
                  <label htmlFor="select-order-type">Order Type</label>
                  <CustomSelect
                    id="select-order-type"
                    listData={orderTypes}
                    handleChange={handleOrderTypeChange}
                    placeholder="Select an order type"
                    textPropName="name"
                    valuePropName="value"
                    selectedValue={orderType}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="card-footer between-flex">
            <button onClick={goBackToStepOne} className="btn btn-outline-dark" type="button">
              Back
            </button>
            <button
              disabled={!goConfirm}
              onClick={moveToConfirm}
              className="btn btn-primary"
              type="button"
            >
              Check order
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateOrderPage;
