import React, { useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';

const UserActivityDataGrid = ({ data }) => {
  const ref = useRef();

  // custom columns
  const userTemplate = (rowData) => (
    <a
      className="primary-color"
      href={`/admin/users/${rowData.user_id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p>{rowData.user_name}</p>
      <p>{rowData.email}</p>
    </a>
  );

  const createdDateTemplate = (rowData) => {
    return (
      <p>{`${rowData?.created_date?.slice(0, 10)}, ${rowData?.created_date?.slice(11, 16)}`}</p>
    );
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent dataSource={data} ref={ref} rowHeight={70}>
          <ColumnsDirective>
            <ColumnDirective
              headerText="User"
              field="user_name"
              template={userTemplate}
              width={150}
              textAlign="center"
            />
            <ColumnDirective
              headerText="Ops"
              field="user_operation"
              width={150}
              textAlign="center"
            />
            <ColumnDirective headerText="Action" field="remarks" width={150} textAlign="center" />
            <ColumnDirective
              headerText="Created at"
              template={createdDateTemplate}
              width={150}
              textAlign="center"
            />
            <ColumnDirective
              headerText="Page"
              field="user_activity_page"
              width={150}
              textAlign="center"
            />
            <ColumnDirective
              headerText="Type"
              field="user_activity_type"
              width={150}
              textAlign="center"
            />
            <ColumnDirective
              headerText="Domain"
              field="user_activity_domain"
              width={150}
              textAlign="center"
            />
          </ColumnsDirective>
        </GridComponent>
      </div>
    </div>
  );
};

export default UserActivityDataGrid;
