import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class OrdersReportService {
  static getOrdersReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.ordersReport, ...extraParams),
      params,
    });
  }

  static exportOrdersReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportOrdersReport, ...extraParams),
      params,
    });
  }

  static getFinancialOrdersReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.financialOrdersReport, ...extraParams),
      params,
    });
  }

  static exportFinancialOrdersReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportFinancialOrdersReport, ...extraParams),
      params,
    });
  }

  static getDailyTrackerReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.dailyTrackerAllMethod, ...extraParams),
      params,
    });
  }

  static getDailySpendReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.giftsDailySpend, ...extraParams),
      params,
    });
  }

  static exportGetDailySpendReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportGiftsDailySpend, ...extraParams),
      params,
    });
  }

  static getDesignersDailyCommisions(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.designersDailyCommisions, ...extraParams),
      params,
    });
  }

  static exportGetDesignersDailyCommisions(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportDesignersDailyCommisions, ...extraParams),
      params,
    });
  }

  static getAcquisitionPerformanceReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.acquisitionPerformanceReport, ...extraParams),
      params,
    });
  }

  static orderCancellationReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.orderCancellation, ...extraParams),
      params,
    });
  }

  static getOrderAmountDetailReport(params) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.orderAmountDetails),
      params,
    });
  }

  static exportOrderAmountDetailReport(params) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportOrderAmountDetails),
      params,
    });
  }

  static getRevenuesReportData(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(reportsEndPoints.revenuesData),
      data,
    });
  }

  static getExportRevenuesReport(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(reportsEndPoints.exportRevenuesData),
      data,
    });
  }

  static getDefferedRevenuesReportData(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(reportsEndPoints.defferedRevenuesData),
      data,
    });
  }

  static getExportDefferedRevenuesReport(data) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(reportsEndPoints.exportDefferedRevenuesData),
      data,
    });
  }
}
export default OrdersReportService;
