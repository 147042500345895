import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageTitle from 'components/shared/PageTitle';
import CustomSelect from 'components/shared/CustomSelect';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// constants
import { HasPermission } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
// modals
import UserGroupsModal from './modals/UserGroupsModal';
import RequestOtpModal from './modals/RequestOtpModal';
import TempPasswordModal from './modals/TempPasswordModal';
import ClearWalletModal from './modals/ClearWalletModal';
import UserOrdersModal from './modals/UserOrdersModal';
import UserAddressesModal from './modals/UserAddressesModal';
import AddBalanceModal from './modals/AddBalanceModal';
// components
import RightSideModal from 'components/shared/RightSideModal';
import UserPointsContent from './components/userPoints/UserPointsContent';
import UserProfileContent from './components/userProfile/UserProfileContent';
import UserActivitiesContent from './components/userActivity/UserActivitiesContent';
import UserCorporateContent from './components/userCorporate/UserCorporateContent';
import TransactionsSection from './userInfoSections/TransactionsSection';
import RestrictedSection from 'components/shared/RestrictedSection';
import GroupsSection from './userInfoSections/GroupsSection';
import SavedCardsContent from './components/savedCreditCards/SavedCardsContent';
import WalletTransactionsContent from './components/userWalletTransactions/WalletTransactionsContent';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { setUserPageAllData } from 'store/user/UserActions';
import { setSignInSuccess } from 'store/auth/signIn/SignInAction';
// selectors
import {
  getUserPageDataById,
  getUserWalletBalanceById,
  getUserGroupsById,
  getUserPointsById,
} from 'store/user/UserSelectors';
import { getOpsCenters } from 'store/auth/signIn/SignInSelector';
import { getCountriesListData, getPermissionsList } from 'store/app/AppSelectors';
// api
import PermissionService from 'services/PermissionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const UserInfoPage = () => {
  const permissionsList = useSelector((state) => getPermissionsList({ state }));
  const userDataById = useSelector((state) => getUserPageDataById({ state }));
  const countryList = useSelector((state) => getCountriesListData({ state }));
  const userGroupsById = useSelector((state) => getUserGroupsById({ state }));
  const userWalletBalanceById = useSelector((state) => getUserWalletBalanceById({ state }));
  const userPointsById = useSelector((state) => getUserPointsById({ state }));
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isTempPasswordModalOpen, setIsTempPasswordModalOpen] = useState(false);
  const [isClearWalletModalOpen, setIsClearWalletModalOpen] = useState(false);
  const [isUserTotalOrdersOpen, setIsUserTotalOrdersOpen] = useState(false);
  const [isUserAddressesModalOpen, setIsUserAddressesModalOpen] = useState(false);
  const [isUserTransactionsModalOpen, setIsUserTransactionsModalOpen] = useState(false);
  const [isAddBalanceModalOpen, setIsAddBalanceModalOpen] = useState(false);
  const [isUserProfileModalOpen, setIsUserProfileModalOpen] = useState(false);
  const [isUserActivitiesModalOpen, setIsUserActivitiesModalOpen] = useState(false);
  const [isUserCorporateModalOpen, setIsUserCorporateModalOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [accountType, setAccounttype] = useState('');
  const [userOps, setUserOps] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isSavedCreditCardsModalOpen, setIsSavedCreditCardsModalOpen] = useState(false);
  const [isUserPointsModalOpen, setIsUserPointsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);
  const params = useParams();
  const opsCenters = useSelector((state) => getOpsCenters({ state }));

  useEffect(() => {
    dispatch(setUserPageAllData(params?.id));
    dispatch(setSignInSuccess());
  }, []);

  useEffect(() => {
    if (Object.keys(userDataById).length > 0) {
      setFirstName(userDataById.name);
      setLastName(userDataById.lastName);
      setEmail(userDataById.email);
      setPhone(userDataById.phone);
      setGender(userDataById.gender);
      setCountry(userDataById.countryID);
      setAccounttype(userDataById.type);
      setIsActive(userDataById.isActive);
      setUserOps(userDataById.opsID);
    }
  }, [userDataById]);

  const handleSaveUserDetails = async () => {
    const bodyData = {
      Phone: phone,
      Email: email,
      Name: firstName,
      LastName: lastName,
      IsActive: isActive,
      CountryID: country,
      Gender: gender,
      Type: accountType,
      OpsID: userOps,
    };
    setDisableBtn(true);
    dispatch(setLoaderOpenTrue());
    try {
      await PermissionService.saveUserDetails(bodyData, params?.id);
      setDisableBtn(false);
      toast.success('User details updated successfully');
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      setDisableBtn(false);
      toast.error('Failed to update user details!');
      errorLogger('UserInfoPage:handleSaveUserDetails', err);
      dispatch(setLoaderOpenFalse());
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e.itemData.Id);
  };

  const handleGenderChange = (e) => {
    setGender(e.itemData.Id);
  };

  const handleAccountTypeChange = (e) => {
    setAccounttype(e.itemData.Id);
  };

  const handleChangeOps = (e) => {
    setUserOps(e.itemData.id);
  };

  return (
    <Fragment>
      <PageTitle title={`User ${params?.id}`} />
      {isGroupModalOpen && (
        <UserGroupsModal
          isGroupModalOpen={isGroupModalOpen}
          setIsGroupModalOpen={setIsGroupModalOpen}
          userId={params?.id}
        />
      )}
      {isOtpModalOpen && (
        <RequestOtpModal
          isOtpModalOpen={isOtpModalOpen}
          setIsOtpModalOpen={setIsOtpModalOpen}
          userId={params?.id}
        />
      )}
      {isTempPasswordModalOpen && (
        <TempPasswordModal
          isTempPasswordModalOpen={isTempPasswordModalOpen}
          setIsTempPasswordModalOpen={setIsTempPasswordModalOpen}
          userId={params?.id}
        />
      )}
      {isClearWalletModalOpen && (
        <ClearWalletModal
          isClearWalletModalOpen={isClearWalletModalOpen}
          setIsClearWalletModalOpen={setIsClearWalletModalOpen}
          userId={params?.id}
        />
      )}
      {isUserTotalOrdersOpen && (
        <UserOrdersModal
          isUserTotalOrdersOpen={isUserTotalOrdersOpen}
          setIsUserTotalOrdersOpen={setIsUserTotalOrdersOpen}
          userId={params?.id}
        />
      )}
      {isUserAddressesModalOpen && (
        <UserAddressesModal
          isUserAddressesModalOpen={isUserAddressesModalOpen}
          setIsUserAddressesModalOpen={setIsUserAddressesModalOpen}
          userId={params?.id}
        />
      )}

      {isUserTransactionsModalOpen && (
        <RightSideModal
          isPaneOpen={isUserTransactionsModalOpen}
          setIsPaneOpen={setIsUserTransactionsModalOpen}
          direction="right"
        >
          <WalletTransactionsContent userId={params?.id} />
        </RightSideModal>
      )}

      {isSavedCreditCardsModalOpen && (
        <RightSideModal
          isPaneOpen={isSavedCreditCardsModalOpen}
          setIsPaneOpen={setIsSavedCreditCardsModalOpen}
          direction="right"
        >
          <SavedCardsContent userId={params?.id} />
        </RightSideModal>
      )}

      {isAddBalanceModalOpen && (
        <AddBalanceModal
          isAddBalanceModalOpen={isAddBalanceModalOpen}
          setIsAddBalanceModalOpen={setIsAddBalanceModalOpen}
          userId={params?.id}
        />
      )}
      {isUserProfileModalOpen && (
        <RightSideModal
          isPaneOpen={isUserProfileModalOpen}
          setIsPaneOpen={setIsUserProfileModalOpen}
          direction="right"
          width={window.screen.width < 500 ? '80%' : '50%'}
        >
          <UserProfileContent
            userId={params?.id}
            setIsUserProfileModalOpen={setIsUserProfileModalOpen}
          />
        </RightSideModal>
      )}
      {isUserActivitiesModalOpen && (
        <RightSideModal
          isPaneOpen={isUserActivitiesModalOpen}
          setIsPaneOpen={setIsUserActivitiesModalOpen}
          direction="right"
          width={window.screen.width < 500 ? '80%' : '70%'}
        >
          <UserActivitiesContent userId={params?.id} />
        </RightSideModal>
      )}
      {isUserPointsModalOpen && (
        <RightSideModal
          isPaneOpen={isUserPointsModalOpen}
          setIsPaneOpen={setIsUserPointsModalOpen}
          direction="right"
        >
          <UserPointsContent userId={params?.id} />
        </RightSideModal>
      )}
      {isUserCorporateModalOpen && (
        <RightSideModal
          isPaneOpen={isUserCorporateModalOpen}
          setIsPaneOpen={setIsUserCorporateModalOpen}
          direction="right"
          width={window.screen.width < 500 ? '80%' : '50%'}
        >
          <UserCorporateContent userId={params?.id} />
        </RightSideModal>
      )}
      <div className="card">
        <div className="card-header">
          {/* <button
            onClick={() => setIsOtpModalOpen((prev) => !prev)}
            className="btn btn-success m-r-10 m-b-8"
          >
            <i className="fa fa-envelope"></i> Request OTP
          </button> */}
          <RestrictedSection page="admin_user_info" action="temp_password">
            <button
              onClick={() => setIsTempPasswordModalOpen((prev) => !prev)}
              className="btn btn-secondary m-r-10 m-b-8"
            >
              <i className="fa fa-lock"></i> Temp Password
            </button>
          </RestrictedSection>

          <RestrictedSection page="admin_user_info" action="add_balance">
            <button
              onClick={() => setIsAddBalanceModalOpen((prev) => !prev)}
              className="btn btn-success m-r-10 m-b-8"
            >
              <i className="fa fa-dollar"></i> Add Balance
            </button>
          </RestrictedSection>
          <RestrictedSection page="admin_user_info" action="clear_wallet">
            <button
              onClick={() => setIsClearWalletModalOpen((prev) => !prev)}
              className="btn btn-danger m-r-10 m-b-8"
            >
              <i className="fa fa-trash"></i> Clear Wallet
            </button>
          </RestrictedSection>
          <button
            onClick={() => setIsUserTotalOrdersOpen((prev) => !prev)}
            className="btn btn-primary m-r-10 m-b-8"
          >
            <i className="fa fa-shopping-cart"></i> Recent Orders
          </button>
          <button
            onClick={() => setIsUserAddressesModalOpen((prev) => !prev)}
            className="btn btn-secondary m-r-10 m-b-8"
          >
            <i className="fa fa-map-marker"></i> Addresses
          </button>
          <RestrictedSection page="admin_user_info" action="user_transactions">
            <button
              onClick={() => setIsUserTransactionsModalOpen((prev) => !prev)}
              className="btn btn-success m-r-10 m-b-8"
            >
              <i className="fa fa-exchange"></i> Wallet Transactions History
            </button>
          </RestrictedSection>
          <RestrictedSection page="admin_user_info" action="user_points_history">
            <button
              onClick={() => setIsUserPointsModalOpen((prev) => !prev)}
              className="btn btn-success m-r-10 m-b-8"
            >
              <i className="fa fa-exchange"></i> User Points History
            </button>
          </RestrictedSection>
          <RestrictedSection page="admin_user_info" action="user_saved_cc">
            <button
              onClick={() => setIsSavedCreditCardsModalOpen(true)}
              className="btn btn-success m-r-10 m-b-8"
            >
              <i className="fa fa-credit-card"></i> Saved credit cards
            </button>
          </RestrictedSection>
          <RestrictedSection page="admin_user_info" action="user_profile">
            <button
              onClick={() => setIsUserProfileModalOpen((prev) => !prev)}
              className="btn btn-info m-r-10 m-b-8"
            >
              <i className="fa fa-user"></i> User Profile
            </button>
          </RestrictedSection>
          <RestrictedSection page="admin_user_info" action="user_activities">
            <button
              onClick={() => setIsUserActivitiesModalOpen((prev) => !prev)}
              className="btn btn-info m-r-10 m-b-8"
            >
              <i className="fa fa-user"></i> User Activities
            </button>
          </RestrictedSection>
          <RestrictedSection page="admin_user_info" action="user_corporate">
            <button
              onClick={() => setIsUserCorporateModalOpen((prev) => !prev)}
              className="btn btn-info m-r-10 m-b-8"
            >
              <i className="fa fa-user"></i> User Corporate
            </button>
          </RestrictedSection>
        </div>
        <div className="card-body row">
          <div className="form-group col-12 col-md-4">
            <label className="bold-title" htmlFor="user-fname">
              First Name
            </label>
            <input
              id="user-fname"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label className="bold-title" htmlFor="user-lname">
              Last Name
            </label>
            <input
              id="user-lname"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label className="bold-title" htmlFor="user-email">
              Email
            </label>
            <input
              id="user-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-12 col-md-3">
            <label className="bold-title" htmlFor="user-phone">
              Phone Number
            </label>
            <input
              id="user-phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-12 col-md-3 filter-select">
            <label className="bold-title" htmlFor="user-gender">
              Gender
            </label>
            <CustomSelect
              id="user-gender"
              listData={[
                { Name: 'Male', Id: 0 },
                { Name: 'Female', Id: 1 },
                { Name: 'None (not selected)', Id: 2 },
              ]}
              handleChange={handleGenderChange}
              placeholder="Select a gender"
              textPropName="Name"
              valuePropName="Id"
              selectedValue={gender}
            />
          </div>
          {countryList.length > 0 && (
            <div className="form-group col-12 col-md-3 filter-select">
              <label className="bold-title" htmlFor="user-country">
                Country
              </label>
              <CustomSelect
                id="user-country"
                listData={countryList}
                handleChange={handleCountryChange}
                placeholder="Select a country"
                textPropName="Name"
                valuePropName="Id"
                selectedValue={country}
              />
            </div>
          )}
          <div className="form-group col-12 col-md-3 filter-select">
            <label className="bold-title" htmlFor="user-type">
              Account Type
            </label>
            <CustomSelect
              id="user-type"
              listData={[
                { Name: 'Personal', Id: 0 },
                { Name: 'Corporate', Id: 1 },
              ]}
              handleChange={handleAccountTypeChange}
              placeholder="Select an account type"
              textPropName="Name"
              valuePropName="Id"
              selectedValue={accountType}
            />
          </div>
          <div className="form-group col-12 col-md-3">
            <label className="bold-title" htmlFor="user-date">
              Created Date
            </label>
            <input
              id="user-date"
              value={
                Object.keys(userDataById).length > 0
                  ? new Date(userDataById.createdDate).toString().slice(0, 25)
                  : ''
              }
              disabled={true}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-12 col-md-3">
            <label className="bold-title" htmlFor="user-lastseen">
              Last Seen
            </label>
            <input
              id="user-lastseen"
              value={
                Object.keys(userDataById).length > 0
                  ? new Date(userDataById.lastSeen).toString().slice(0, 25)
                  : ''
              }
              disabled={true}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-12 col-md-3">
            <label className="bold-title" htmlFor="user-wallet">
              Wallet Balance
            </label>
            <input
              id="user-wallet"
              value={
                userWalletBalanceById
                  ? userWalletBalanceById.toFixed(opsCenter?.country?.decimalNumber ?? 2)
                  : 0
              }
              disabled={true}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-12 col-md-3">
            <label className="bold-title" htmlFor="user-points">
              User Points
            </label>
            <input
              id="user-points"
              value={userPointsById ?? 0}
              disabled={true}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-12 col-md-3 filter-select">
            <label className="bold-title" htmlFor="user-points">
              User Ops
            </label>
            <CustomSelect
              listData={opsCenters}
              handleChange={handleChangeOps}
              placeholder="Select an ops"
              textPropName="nickName"
              valuePropName="id"
              selectedValue={userOps}
            />
          </div>
          <div className="form-group col-12">
            <input
              className="checkbox_animated"
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive((prev) => !prev)}
              disabled={!HasPermission(permissionsList, 'admin_user_info', 'active_user_access')}
            />
            <span className="bold-title">Is Active?</span>
          </div>
        </div>
        <div className="card-footer">
          <button
            disabled={disableBtn}
            onClick={handleSaveUserDetails}
            className="btn btn-primary"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
      <RestrictedSection page="admin_user_info" action="user_transactions">
        <TransactionsSection userId={params?.id} />
      </RestrictedSection>
      <GroupsSection
        setIsGroupModalOpen={setIsGroupModalOpen}
        userId={params?.id}
        userGroups={userGroupsById}
      />
    </Fragment>
  );
};
export default UserInfoPage;
