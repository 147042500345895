/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionLoader from 'components/common/SectionLoader';
import { toast } from 'react-toastify';
import CustomSelect from 'components/shared/CustomSelect';
import { cloneDeep } from 'lodash';
// api
import ProductsService from 'services/ProductsService';
import MerchandisingCategoriesService from 'services/MerchandisingCategoriesService';
import Chip from '@mui/material/Chip';
import {
  setAddProductAttributeById,
  setDeleteProductAttributeById,
} from 'store/products/ProductsActions';
import { getProductAttributesByIdData } from 'store/products/ProductsSelectors';
import AddAttributeModal from '../modals/AddAttributeModal';
import InlineErrorMessage from 'components/shared/InlineErrorMessage';
import MCAttributes from './MCAttributes';
import { HasPermission } from 'constant/Helpers';
import { getPermissionsList } from 'store/app/AppSelectors';

const ProductMCSection = ({
  productId,
  productOptionsById,
  productMCData,
  productAttributesData,
  isValid,
  notValidFields,
  validateMC,
  getMCAttributesFieldsData,
}) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [categories, setCategories] = useState([
    { mcLevel: 0, data: [] },
    { mcLevel: 1, data: [] },
    { mcLevel: 2, data: [] },
    { mcLevel: 3, data: [] },
    { mcLevel: 4, data: [] },
  ]);
  const [selectedCategories, setSelectedCategories] = useState([
    { mcLevel: 0, cat: 0 },
    { mcLevel: 1, cat: 0 },
    { mcLevel: 2, cat: 0 },
    { mcLevel: 3, cat: 0 },
    { mcLevel: 4, cat: 0 },
  ]);

  const [mcForAttributes, setMCForAttributes] = useState({
    MC0: null,
    MC1: null,
    MC2: null,
    MC3: null,
    MC4: null,
  });

  const [fields, setFields] = useState([]);
  const permissionsList = useSelector((state) => getPermissionsList({ state }));

  const isAuthorized = HasPermission(permissionsList, 'edit_product', 'edit_mc');

  const dispatch = useDispatch();
  const ref0 = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const [updatedAttributesArr, setUpdatedAttributesArr] = useState([]);
  const [updatedAttributesArrById, setUpdatedAttributesArrById] = useState([]);
  const attributesById = useSelector((state) => getProductAttributesByIdData({ state }));
  const [isAddAttrModalOpen, setIsAddAttrModalOpen] = useState(false);

  const updatedAttrArray = [];
  const updatedAttrArrayById = [];

  const handleMainCatChange = (e, mcLevel) => {
    if (!isAuthorized) return;
    if (e.itemData) {
      const oldCat = cloneDeep(selectedCategories);
      const newCat = oldCat.map((mc) => {
        if (mc.mcLevel === mcLevel) {
          mc.cat = e.itemData.id;
        }
        if (mc.mcLevel > mcLevel) {
          mc.cat = 0;
        }
        return mc;
      });
      const oldCats = cloneDeep(categories);
      const newCats = oldCats.map((mc) => {
        if (mc.mcLevel > mcLevel) {
          mc.data = [];
        }
        return mc;
      });
      setCategories(newCats);
      setSelectedCategories(newCat);
      if (mcLevel < 4) {
        fetchData('change', mcLevel + 1, newCats, newCat, e.itemData.id);
      }
    }
  };

  const handleGetMC = (type, val, mcLevel, categoriesOptions, selectedCats) => {
    const oldCat = cloneDeep(selectedCats);
    const newCat = oldCat.map((mc) => {
      if (mc.mcLevel === mcLevel) {
        mc.cat = val;
      }
      if (mc.mcLevel > mcLevel) {
        mc.cat = 0;
      }
      return mc;
    });
    const oldCats = cloneDeep(categoriesOptions);
    const newCats = oldCats.map((mc) => {
      if (mc.mcLevel > mcLevel) {
        mc.data = [];
      }
      return mc;
    });
    setCategories(newCats);
    setSelectedCategories(newCat);

    // update the attributes related to MC
    let newMCForAttr = {};
    newCat?.forEach((cat) => {
      newMCForAttr[`MC${cat?.mcLevel}`] = cat?.cat;
    });

    setMCForAttributes(newMCForAttr);

    if (mcLevel < 4) {
      fetchData(type, mcLevel + 1, newCats, newCat, val);
    }
  };

  const fetchData = async (type, mcLevel, categoriesData, selectedCats, parentId) => {
    const params = parentId ? { parentId, page: -1 } : { page: -1 };
    setDisableBtn(true);
    try {
      const res = await MerchandisingCategoriesService.getMerchandisingCategoriesData(params);
      const oldCat = cloneDeep(categoriesData);
      const newCat = oldCat.map((mc) => {
        if (mc.mcLevel === mcLevel) {
          mc.data = res.data;
        }
        return mc;
      });
      setCategories(newCat);
      let val = productMCData.mC0;
      if (type === 'effect') {
        switch (mcLevel) {
          case 0:
            val = productMCData.mC0;
            break;
          case 1:
            val = productMCData.mC1;
            break;
          case 2:
            val = productMCData.mC2;
            break;
          case 3:
            val = productMCData.mC3;
            break;
          case 4:
            val = productMCData.mC4;
            break;
          default:
            val = productMCData.mC0;
            break;
        }
      } else {
        switch (mcLevel) {
          case 0:
            val = selectedCats.filter((mc) => {
              return mc.mcLevel === 0;
            })[0].cat;
            break;
          case 1:
            val = selectedCats.filter((mc) => {
              return mc.mcLevel === 1;
            })[0].cat;
            break;
          case 2:
            val = selectedCats.filter((mc) => {
              return mc.mcLevel === 2;
            })[0].cat;
            break;
          case 3:
            val = selectedCats.filter((mc) => {
              return mc.mcLevel === 3;
            })[0].cat;
            break;
          case 4:
            val = selectedCats.filter((mc) => {
              return mc.mcLevel === 4;
            })[0].cat;
            break;
          default:
            val = selectedCats.filter((mc) => {
              return mc.mcLevel === 0;
            })[0].cat;
            break;
        }
      }
      if (val) handleGetMC(type, val, mcLevel, newCat, selectedCats);
      setDisableBtn(false);
    } catch (err) {
      toast.error('Failed to fetch categories data!!');
      setDisableBtn(false);
    }
  };

  const handleSave = async () => {
    const MC3 = document.getElementById('mc-level3');
    const MC4 = document.getElementById('mc-level4');
    setDisableBtn(true);

    const getMCLevelCat = (level) =>
      selectedCategories.filter((mc) => {
        return mc.mcLevel === level;
      })[0].cat;

    const data = {
      MC0: getMCLevelCat(0) === 0 ? null : getMCLevelCat(0),
      MC1: getMCLevelCat(1) === 0 ? null : getMCLevelCat(1),
      MC2: getMCLevelCat(2) === 0 ? (getMCLevelCat(1) !== 0 ? '' : null) : getMCLevelCat(2),
      MC3: getMCLevelCat(3) === 0 ? (getMCLevelCat(2) !== 0 && MC3 ? '' : null) : getMCLevelCat(3),
      MC4: getMCLevelCat(4) === 0 ? (getMCLevelCat(3) !== 0 && MC4 ? '' : null) : getMCLevelCat(4),
    };
    try {
      const res = await ProductsService.addProductMCById(data, productId);
      toast.success(res?.data?.message ?? 'Merchandising categories were updated successfully');
      dispatch({
        type: 'GET_PRODUCT_MC_BY_ID',
        payload: data,
      });
      setMCForAttributes(data);
      setDisableBtn(false);
      validateMC(data, true);
    } catch (err) {
      setDisableBtn(false);
    }
  };

  const handleClear = async (level) => {
    if (!isAuthorized) return;

    const oldCat = cloneDeep(selectedCategories);
    const newCat = oldCat.map((mc) => {
      if (mc.mcLevel >= level) {
        mc.cat = 0;
      }
      return mc;
    });
    await setSelectedCategories(newCat);
    const oldCats = cloneDeep(categories);
    const newCats = oldCats.map((mc) => {
      if (mc.mcLevel > level) {
        mc.data = [];
      }
      return mc;
    });
    await setCategories(newCats);
    if (level === 1) {
      ref1.current.clear();
    } else {
      if (level === 2) {
        ref2.current.clear();
      } else {
        if (level === 3) {
          ref3.current.clear();
        } else {
          if (level === 4) {
            ref4.current.clear();
          } else {
            ref0.current.clear();
          }
        }
      }
    }
  };

  const handleDeleteChip = (data) => () => {
    if (!isAuthorized) return;
    if (productOptionsById?.status !== 1) {
      dispatch(setDeleteProductAttributeById(productId, data.id, data.attId, validateMC));
    } else {
      toast.error("You can't do changes to published product");
    }
  };

  useEffect(() => {
    fetchData('effect', 0, categories, selectedCategories);
  }, [productMCData]);

  useEffect(() => {
    productAttributesData.forEach((item) => {
      updatedAttrArray.push(...item.listOfValues);
    });
    setUpdatedAttributesArr(updatedAttrArray);
  }, [productAttributesData]);

  useEffect(() => {
    attributesById.forEach((item) => {
      updatedAttrArrayById.push(...item.productValueAtrributes);
    });

    const newAttArrayById = updatedAttrArrayById.map((el) => el.id);
    setUpdatedAttributesArrById(newAttArrayById);
  }, [productAttributesData, attributesById]);

  const getAttributesRelatedToMC = async () => {
    let query = '';
    Object.keys(mcForAttributes)?.forEach((el) => {
      if (mcForAttributes[el]) {
        query += `mcids=${mcForAttributes[el]}&`;
      }
    });

    const { data } = await ProductsService.getAttributesRelatedToMC(query);
    setFields(data);
    getMCAttributesFieldsData(data);
  };

  useEffect(() => {
    getAttributesRelatedToMC();
  }, [mcForAttributes]);

  return (
    <>
      {isAddAttrModalOpen && (
        <AddAttributeModal
          isAddAttrModalOpen={isAddAttrModalOpen}
          setIsAddAttrModalOpen={setIsAddAttrModalOpen}
          setAddProductAttributeById={setAddProductAttributeById}
          setDeleteProductAttributeById={setDeleteProductAttributeById}
          updatedAttributesArr={updatedAttributesArr?.filter(
            (el) => fields?.filter((field) => field?.id === el?.attID)?.length < 1
          )}
          updatedAttributesArrById={updatedAttributesArrById}
          productId={productId}
          validateMC={validateMC}
        />
      )}
      <div className="form-group col-12">
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <div className="form-group col-12 m-t-30">
            <form className="row">
              {categories.filter((mc) => {
                return mc.mcLevel === 0;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="mc-level0">
                    Merchandising Category Level 0<span className="requird-asterisks ml-1">*</span>
                  </label>
                  <CustomSelect
                    id="mc-level0"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 0;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 0)}
                    placeholder="Select merchandising category level 0"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 0;
                      })[0].cat
                    }
                    disabled={!isAuthorized || productOptionsById?.status === 1 || disableBtn}
                    showClearButton={!(productOptionsById?.status === 1 || disableBtn)}
                    handleClear={() => handleClear(0)}
                    forwardedRef={ref0}
                  />
                  {!isValid && notValidFields?.includes('Merchandising Category Level 0') && (
                    <InlineErrorMessage message={'Please add "Merchandising Category Level 0"'} />
                  )}
                </div>
              )}
              {categories.filter((mc) => {
                return mc.mcLevel === 1;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="mc-level1">
                    Merchandising Category Level 1<span className="requird-asterisks ml-1">*</span>
                  </label>
                  <CustomSelect
                    id="mc-level1"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 1;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 1)}
                    placeholder="Select merchandising category level 1"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 1;
                      })[0].cat
                    }
                    disabled={!isAuthorized || productOptionsById?.status === 1 || disableBtn}
                    showClearButton={!(productOptionsById?.status === 1 || disableBtn)}
                    handleClear={() => handleClear(1)}
                    forwardedRef={ref1}
                  />
                  {!isValid && notValidFields?.includes('Merchandising Category Level 1') && (
                    <InlineErrorMessage message={'Please add "Merchandising Category Level 1"'} />
                  )}
                </div>
              )}
              {categories.filter((mc) => {
                return mc.mcLevel === 2;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="mc-level2">
                    Merchandising Category Level 2<span className="requird-asterisks ml-1">*</span>
                  </label>
                  <CustomSelect
                    id="mc-level2"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 2;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 2)}
                    placeholder="Select merchandising category level 2"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 2;
                      })[0].cat
                    }
                    disabled={!isAuthorized || productOptionsById?.status === 1 || disableBtn}
                    showClearButton={!(productOptionsById?.status === 1 || disableBtn)}
                    handleClear={() => handleClear(2)}
                    forwardedRef={ref2}
                  />
                  {!isValid && notValidFields?.includes('Merchandising Category Level 2') && (
                    <InlineErrorMessage message={'Please add "Merchandising Category Level 2"'} />
                  )}
                </div>
              )}
              {categories.filter((mc) => {
                return mc.mcLevel === 3;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="mc-level3">
                    Merchandising Category Level 3<span className="requird-asterisks ml-1">*</span>
                  </label>
                  <CustomSelect
                    id="mc-level3"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 3;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 3)}
                    placeholder="Select merchandising category level 3"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 3;
                      })[0].cat
                    }
                    disabled={!isAuthorized || productOptionsById?.status === 1 || disableBtn}
                    showClearButton={!(productOptionsById?.status === 1 || disableBtn)}
                    handleClear={() => handleClear(3)}
                    forwardedRef={ref3}
                  />
                  {!isValid && notValidFields?.includes('Merchandising Category Level 3') && (
                    <InlineErrorMessage message={'Please add "Merchandising Category Level 3"'} />
                  )}
                </div>
              )}
              {categories.filter((mc) => {
                return mc.mcLevel === 4;
              })[0].data.length > 0 && (
                <div className="col-md-6 col-xl-4">
                  <label className="bold-title" htmlFor="mc-level4">
                    Merchandising Category Level 4<span className="requird-asterisks ml-1">*</span>
                  </label>
                  <CustomSelect
                    id="mc-level4"
                    listData={
                      categories.filter((mc) => {
                        return mc.mcLevel === 4;
                      })[0].data
                    }
                    handleChange={(e) => handleMainCatChange(e, 4)}
                    placeholder="Select merchandising category level 4"
                    textPropName="titleEn"
                    valuePropName="id"
                    selectedValue={
                      selectedCategories.filter((mc) => {
                        return mc.mcLevel === 4;
                      })[0].cat
                    }
                    disabled={!isAuthorized || productOptionsById?.status === 1 || disableBtn}
                    showClearButton={!(productOptionsById?.status === 1 || disableBtn)}
                    handleClear={() => handleClear(4)}
                    forwardedRef={ref4}
                  />
                  {!isValid && notValidFields?.includes('Merchandising Category Level 4') && (
                    <InlineErrorMessage message={'Please add "Merchandising Category Level 4"'} />
                  )}
                </div>
              )}
            </form>
          </div>
        )}
        <div className="card-footer">
          <button
            disabled={!isAuthorized || disableBtn || productOptionsById?.status === 1}
            onClick={handleSave}
            className="btn btn-primary"
            type="button"
          >
            Save
          </button>
        </div>

        <div className="card attributes-card">
          <div className="col-md-6 col-xl-4 add-attr-col">
            <button
              onClick={() => setIsAddAttrModalOpen(true)}
              className="btn btn-success"
              type="button"
              disabled={!isAuthorized || productOptionsById?.status === 1}
            >
              <i className="fa fa-plus"></i> Add Attribute
            </button>
            {!isValid && notValidFields?.includes('min range for attributes') && (
              <InlineErrorMessage
                message={'Please add at least 2 "Attributes" to publish the product'}
              />
            )}
            {!isValid && notValidFields?.includes('max range for attributes') && (
              <InlineErrorMessage
                message={'You can only add up to 30 attributes here, please delete some items."'}
              />
            )}
            {!isValid && notValidFields?.includes('max range for "Flower Product Type"') && (
              <InlineErrorMessage
                message={'You can only add one "Product Type", please delete some items.'}
              />
            )}
            {!isValid && notValidFields?.includes('max range for "Packaging Size"') && (
              <InlineErrorMessage
                message={'You can only add one "Packaging Size", please delete some items.'}
              />
            )}
            {!isValid && notValidFields?.includes('max range for "Flower Packaging"') && (
              <InlineErrorMessage
                message={'You can only add one "Packaging", please delete some items.'}
              />
            )}
            {!isValid && notValidFields?.includes('max range for bundle types') && (
              <InlineErrorMessage
                message={'You can only add one "Bundle Type", please delete some items.'}
              />
            )}
            {!isValid && notValidFields?.includes('Max range of items per attribute') && (
              <InlineErrorMessage message={'You can only add up to 5 items for each attribute'} />
            )}
          </div>
          <MCAttributes
            fields={fields}
            attrData={updatedAttributesArr}
            selectedAttrData={updatedAttributesArrById}
            productId={productId}
            validateMC={validateMC}
            isValid={isValid}
            notValidFields={notValidFields}
            isAuthorized={isAuthorized}
            productOptions={productOptionsById}
          />
          <div className="card-body">
            <div className="col-flex m-10">
              {attributesById
                .filter((attr) => fields?.filter((field) => field?.id === attr?.id)?.length < 1)
                ?.map((item, i) => {
                  if (item.productValueAtrributes.length > 0) {
                    return (
                      <div key={i}>
                        <h6 className="m-r-8 no-wrap">{item.name}</h6>
                        <div className="attributes-container">
                          {item.productValueAtrributes.map((data, ii) => (
                            <li key={ii}>
                              <Chip
                                label={data.name}
                                onDelete={handleDeleteChip(data)}
                                className="attr-chip"
                              />
                            </li>
                          ))}
                        </div>
                      </div>
                    );
                  } else {
                    return <Fragment key={`ProductMCSection-Fragment-${i}`} />;
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductMCSection);
