import React from 'react';

const PermissionsCannotAccess = () => (
  <div className="align-center red-color">
    <img
      alt="access denied"
      className="access-denied-img"
      src="https://2.bp.blogspot.com/-TQC3t0WvV3M/XNVAuw5WA0I/AAAAAAAAAZ8/QRfcoJSl8VwxutjPp7SdtT5PQF-2JS22QCLcBGAs/s1600/Screenshot_189.png"
    />
    <h1 className="sz-20 m-t-20 bold-title">
      Sorry, you don&apos;t have permissions to access this page.
    </h1>
  </div>
);

export default PermissionsCannotAccess;
