import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
// components
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import HoursBreakDownDataGrid from 'components/reports/hoursAreasBreakDown/HoursBreakDownDataGrid';
// constants
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  dateFormat,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';
import { getHoursBreakdownPageUrl } from 'constant/AppUrls';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import HoursAreaReportService from 'services/reportsService/HoursAreaReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const HourseBreakDownPage = () => {
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
  });
  const [hoursBreakDownData, setHoursBreakDownData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await HoursAreaReportService.getHoursBreakdownReport(params);
      setHoursBreakDownData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Hours Breakdown Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: getHoursBreakdownPageUrl(),
      search: queryString,
    });
    fetchData(inputValues);
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        defaultDate
        title="Hours Breakdown"
      />
      <HoursBreakDownDataGrid data={hoursBreakDownData} />
    </div>
  );
};

export default HourseBreakDownPage;
