import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import RestrictedSection from 'components/shared/RestrictedSection';
import { CardBody, CardHeader, Media } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import AddNewGiftModal from './modals/AddNewGiftModal';
import { toast } from 'react-toastify';
// modals
import DeleteGiftModal from './modals/DeleteGiftModal';
// api
import ViewOrderService from 'services/ViewOrderService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const PremiumGift = ({ orderId, premiumGiftData, orderData }) => {
  const [isDeletegiftModalOpen, setIsDeleteGiftModalOpen] = useState(false);
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [isAddGiftModalOpen, setIsAddGiftModalOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleTimeStamp = (unixTime) => {
    const milliseconds = unixTime * 1000;
    const dateObject = new Date(milliseconds);
    const humanDateFormat = dateObject.toLocaleString();
    return humanDateFormat;
  };

  const handleDeleteGift = async (orderId) => {
    setDisableBtn(true);
    try {
      await ViewOrderService.deletePremiumGift(orderId);
      dispatch({
        type: 'SET_PREMIUM_GIFT_DATA',
        payload: '',
      });
      setDisableBtn(false);
    } catch (err) {
      errorLogger('PremiumGift:handleDeleteGift', err);
      toast.error('Failed to delete gift');
      setDisableBtn(false);
    }
  };

  return (
    <div className="row">
      <DeleteGiftModal
        isDeletegiftModalOpen={isDeletegiftModalOpen}
        setIsDeleteGiftModalOpen={setIsDeleteGiftModalOpen}
        orderId={orderId}
      />
      <AddNewGiftModal
        isAddGiftModalOpen={isAddGiftModalOpen}
        setIsAddGiftModalOpen={setIsAddGiftModalOpen}
        orderId={orderId}
        orderData={orderData}
      />
      <RestrictedSection page="order_details" action="customer_calls">
        <div className="col-12">
          <div className="card premium-gift-wrapper">
            <CardHeader className="card-header-border">
              <h5>
                Collecting Gift <i className="fa fa-star"></i>
              </h5>
            </CardHeader>
            <CardBody className="recent-notification">
              <div className="table-responsive shopping-table text-center">
                <table className="table table-bordernone">
                  <thead>
                    <tr>
                      <th scope="col">Description</th>
                      <th scope="col">Image</th>
                      <th className="collection-time-th" scope="col">
                        Collecting Time
                      </th>
                      <th scope="col">Address</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {premiumGiftData !== '' && (
                    <tbody>
                      <tr>
                        <td>{premiumGiftData.itemsDescription}</td>
                        <td className="attachments-wrapper">
                          {premiumGiftData.attachments.map((item, i) => (
                            <Media
                              key={i}
                              className="img-thumbnail img-fluid img-90"
                              src={item.fullImage}
                              onClick={() => {
                                setIsImgOpen(true);
                                setImageSrc(item.fullImage);
                              }}
                            />
                          ))}
                        </td>
                        <td>
                          {premiumGiftData.collectionDate &&
                            handleTimeStamp(premiumGiftData.collectionDate)}
                        </td>
                        <td>
                          {premiumGiftData.addressDetails}
                          <br></br>
                          {premiumGiftData.lat && premiumGiftData.lng && (
                            <a
                              className="view-on-map-link"
                              href={`https://www.google.com/maps/?q=${premiumGiftData.lat},${premiumGiftData.lng}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              view on map <i className="fa fa-map-marker"></i>
                            </a>
                          )}
                        </td>
                        <td>
                          <button
                            disabled={disableBtn}
                            onClick={() => handleDeleteGift(orderId)}
                            className="btn btn-danger"
                          >
                            <i className="fa fa-trash"></i> Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  )}
                  <tfoot>
                    <tr>
                      <td colSpan={6}>
                        <button
                          onClick={() => setIsAddGiftModalOpen((prev) => !prev)}
                          className="btn btn-success"
                        >
                          <i className="fa fa-plus"></i> Add Gift
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </CardBody>
          </div>
        </div>
      </RestrictedSection>
      {isImgOpen && <Lightbox onCloseRequest={() => setIsImgOpen(false)} mainSrc={imageSrc} />}
    </div>
  );
};

export default PremiumGift;
