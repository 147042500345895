import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Toolbar,
  ExcelExport,
  PdfExport,
  ColumnChooser,
} from '@syncfusion/ej2-react-grids';
import has from 'lodash/has';
import toString from 'lodash/toString';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
import { calibriFont } from 'constant/PdfFont';
// constants
import { localStorageKeys } from 'constant/Enums';

const PriceBreakDownDataGrid = ({ data }) => {
  const toolbarOptions = ['ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Search'];
  const ref = useRef();
  const opsCenter = getFromLocalStorage(localStorageKeys.OPS_CENTER);

  const toolbarClick = (args) => {
    const pdfExportProperties = {
      theme: {
        caption: { font: new PdfTrueTypeFont(calibriFont, 10) },
        header: { font: new PdfTrueTypeFont(calibriFont, 12) },
        record: { font: new PdfTrueTypeFont(calibriFont, 9) },
      },
    };
    switch (args.item.text) {
      case 'PDF Export':
        ref.current.pdfExport(pdfExportProperties);
        break;
      case 'Excel Export':
        ref.current.excelExport();
        break;
      case 'CSV Export':
        ref.current.csvExport();
        break;
      default:
        break;
    }
  };

  const excelQueryCellInfo = (args) => {
    if (has(args.column, 'customExcelTemplate')) {
      args.value = toString(args.column.customExcelTemplate(args.data));
    }
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent
          dataSource={data}
          allowPaging={true}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          allowPdfExport={true}
          excelQueryCellInfo={excelQueryCellInfo}
          pdfQueryCellInfo={excelQueryCellInfo}
          ref={ref}
          toolbarClick={toolbarClick}
          showColumnChooser={true}
          rowHeight={60}
          allowTextWrap={true}
          pageSettings={{ pageSizes: true, pageSize: 20 }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Range Value"
              field="rangeValue"
              width={100}
              customExcelTemplate={(rowData) => rowData.rangeValue}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="#Unique Product"
              field="totalUniqueProducts"
              width={100}
              customExcelTemplate={(rowData) => rowData.totalUniqueProducts}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Product on total"
              template={(rowData) => (
                <span>
                  {rowData.productOnTotalProduct != null &&
                    `${rowData.productOnTotalProduct.toFixed(
                      opsCenter?.country?.decimalNumber ?? 2
                    )}%`}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.productOnTotalProduct != null &&
                `${rowData.productOnTotalProduct.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="#Product View"
              field="toTalProductViews"
              width={100}
              customExcelTemplate={(rowData) => rowData.toTalProductViews}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="View on Total"
              template={(rowData) => (
                <span>
                  {rowData.viewsOnTotalViews != null &&
                    `${rowData.viewsOnTotalViews.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.viewsOnTotalViews != null &&
                `${rowData.viewsOnTotalViews.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="#Order Count"
              field="totalOrdersCount"
              width={100}
              customExcelTemplate={(rowData) => rowData.totalOrdersCount}
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Order on total"
              template={(rowData) => (
                <span>
                  {rowData.ordersOnTotalOrders != null &&
                    `${rowData.ordersOnTotalOrders.toFixed(
                      opsCenter?.country?.decimalNumber ?? 2
                    )}%`}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.ordersOnTotalOrders != null &&
                `${rowData.ordersOnTotalOrders.toFixed(opsCenter?.country?.decimalNumber ?? 2)}%`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Revenue (Sum price*QTY)"
              template={(rowData) => (
                <span>
                  {rowData.totalRevenueUSD != null &&
                    `${rowData.totalRevenueUSD.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.totalRevenueUSD != null &&
                `${rowData.totalRevenueUSD.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`
              }
              textAlign="Center"
            />
            <ColumnDirective
              headerText="Revenue Per Total"
              template={(rowData) => (
                <span>
                  {rowData.revenueOnTotalRevenue != null &&
                    `${rowData.revenueOnTotalRevenue.toFixed(
                      opsCenter?.country?.decimalNumber ?? 2
                    )}%`}
                </span>
              )}
              width={100}
              customExcelTemplate={(rowData) =>
                rowData.totalRevenueUSD != null &&
                `${rowData.totalRevenueUSD.toFixed(opsCenter?.country?.decimalNumber ?? 2)}`
              }
              textAlign="Center"
            />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, ExcelExport, PdfExport, ColumnChooser]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default React.memo(PriceBreakDownDataGrid);
