import React from 'react';
import { useParams } from 'react-router-dom';
// components
import MasterInventoryItemSection from 'components/masterInventory/MasterInventoryItemSection';
import RestrictedSection from 'components/shared/RestrictedSection';

const EditMasterInventoryPage = () => {
  const params = useParams();

  return (
    <RestrictedSection page="edit_master_inventory" action="view">
      <MasterInventoryItemSection parentId={params?.id} />
    </RestrictedSection>
  );
};

export default EditMasterInventoryPage;
