import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { errorLogger } from 'datadog/DDUtils';
// components
import DJHPagination from 'components/shared/Pagination';
// dataGrid
import AlertQtyItemsDataGrid from 'components/inventory/alertQty/AlertQtyItemsDataGrid';
// actions
import { setAlertQtyInventoryItems } from 'store/inventory/InventoryActions';
// selectors
import { getAlertQtyInventoryItems } from 'store/inventory/InventorySelectors';
// Services
import InventoryService from 'services/InventoryService';

const AlertQtyItemsPage = () => {
  const alertQtyItemsData = useSelector((state) => getAlertQtyInventoryItems({ state }));
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    page: 1,
  });
  const dispatch = useDispatch();

  const fetchAlertQtyInventoryData = (params) => {
    dispatch(setAlertQtyInventoryItems(params));
  };

  useEffect(() => {
    fetchAlertQtyInventoryData(params);
  }, []);

  const onPageChange = (i) => {
    setParams({ page: i });
    fetchAlertQtyInventoryData({ page: i });
    document.documentElement.scrollTop = 0;
  };

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const res = await InventoryService.exportAlertQtyInventoryData();
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Alert QTY.xlsx');
      document.body.appendChild(link);
      link.click();
      toast.success('Downloaded');
      setIsLoading(false);
    } catch (err) {
      errorLogger('AlertQtyItemsPage:handleExport', err);
      toast.error(err?.response?.data?.message ?? 'Failed to download');
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="text-right">
        <button
          onClick={handleExport}
          className="btn btn-success mr-1 mb-2"
          type="button"
          disabled={isLoading}
        >
          {isLoading ? (
            'Downloading...'
          ) : (
            <>
              <i className="fa fa-file-text" /> Download
            </>
          )}
        </button>
      </div>
      <AlertQtyItemsDataGrid data={alertQtyItemsData.data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+alertQtyItemsData.currentPage}
        pageSize={+alertQtyItemsData.pageSize}
        itemsCount={+alertQtyItemsData.rowCount}
      />
    </Fragment>
  );
};

export default AlertQtyItemsPage;
