import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import PageTitle from 'components/shared/PageTitle';
import CatalogActions from 'components/masterCatalog/localAvailability/CatalogActions';
import CatalogFilter from 'components/masterCatalog/localAvailability/CatalogFilter';
import LocalAvilabilityDataGrid from 'components/masterCatalog/localAvailability/LocalAvilabilityDataGrid';
import DJHPagination from 'components/shared/Pagination';
import {
  setExportUncategorizedMerch,
  setUncategorizedMerchandise,
} from 'store/masterCatalog/MasterCatalogActions';
import { getLocalAvailabilityData } from 'store/masterCatalog/MasterCatalogSelectors';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { convertObjectIntoQueryParams, convertQueryParamsIntoObject } from 'constant/Helpers';

const UncategorizedMerchandise = () => {
  const localAvailabilityData = useSelector((state) => getLocalAvailabilityData({ state }));
  const [inputValues, setInputValues] = useState({
    page: 1,
    opsId: getFromLocalStorage('opsId'),
    key: '',
    productionline: 0,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleExportCatalog = () => {
    dispatch(setExportUncategorizedMerch(inputValues));
  };

  const onPageChange = (i) => {
    dispatch(setUncategorizedMerchandise({ ...inputValues, page: i }));
    document.documentElement.scrollTop = 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: location.pathname,
      search: queryString,
    });
    dispatch(setUncategorizedMerchandise(inputValues));
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(setUncategorizedMerchandise(queryObj));
      setInputValues(queryObj);
    } else {
      dispatch(setUncategorizedMerchandise(inputValues));
    }
  }, []);

  return (
    <div className="toolbar-table-style">
      <div className="quick-actions-table">
        <PageTitle title="Master Catalog" />
        <CatalogActions
          inputValues={inputValues}
          handleExportCatalog={handleExportCatalog}
          isUncategorizedMerch
        />
        <CatalogFilter
          inputValues={inputValues}
          setInputValues={setInputValues}
          isUncategorizedMerch
          handleSubmit={handleSubmit}
        />
        <LocalAvilabilityDataGrid
          data={localAvailabilityData?.data}
          params={inputValues}
          isUncategorizedMerch
        />
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+localAvailabilityData.currentPage}
          pageSize={+localAvailabilityData.pageSize}
          itemsCount={+localAvailabilityData.rowCount}
        />
      </div>
    </div>
  );
};

export default UncategorizedMerchandise;
