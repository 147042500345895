import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SectionLoader from 'components/common/SectionLoader';
import CustomSelect from 'components/shared/CustomSelect';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import styles from '../FeaturedOccasion.module.css';
// constants
import { handleImageFile } from 'constant/Helpers';
import { FeaturedOccasionColorList } from 'constant/Enums';
// Service
import FeaturedOccasionService from 'services/FeaturedOccasionService';
// datadog
import { errorLogger } from 'datadog/DDUtils';
import { useParams } from 'react-router-dom';
const FeaturedOccasionInfo = ({ type }) => {
  const [activeCollections, setActiveCollections] = useState([]);
  const { id } = useParams();
  const handleActiveCollections = async () => {
    const res = await FeaturedOccasionService.getActiveCollection();
    if (res?.data) {
      const result = res?.data?.map((item) => {
        return { id: item?.id, name: item?.titleEn };
      });
      setActiveCollections(result);
    }
  };

  const [isLoaderOpen, setIsLoaderOpen] = useState(true);
  const [key, setKey] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [sortId, setSortId] = useState(0);
  const [titleEn, setTitleEn] = useState('');
  const [titleAr, setTitleAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [titleColor, setTitleColor] = useState('#000');
  const [descriptionColor, setDescriptionColor] = useState('#000');
  const [iconEn, setIconEn] = useState('');
  const [iconAr, setIconAr] = useState('');
  const [viewAllBtnTextEn, setViewAllBtnTextEn] = useState('');
  const [viewAllBtnTextAr, setviewAllBtnTextAr] = useState('');
  const [viewAllTextColor, setViewAllTextColor] = useState('#000');
  const [viewAllBackgroundColor, setviewAllBackgroundColor] = useState('#FFF');
  const [categoryId, setCategoryId] = useState(0);
  const [isValid, setValid] = useState(true);
  const [editData, setEditData] = useState({});
  const navigate = useNavigate();

  const handleCollectionChange = (e) => {
    setCategoryId(e?.itemData?.id);
  };

  const getFormData = () => {
    const data = {
      key: key,
      isActive: isActive,
      titleEn: titleEn,
      titleAr: titleAr,
      descriptionEn: descriptionEn,
      descriptionAr: descriptionAr,
      backgroundColor: backgroundColor,
      titleColor: titleColor,
      descriptionColor: descriptionColor,
      iconEn: iconEn,
      iconAr: iconAr,
      viewAllBtnTextEn: viewAllBtnTextEn,
      viewAllBtnTextAr: viewAllBtnTextAr,
      viewAllBtnTextColor: viewAllTextColor,
      viewAllBackgroundColor: viewAllBackgroundColor,
      categoryId,
    };
    if (type === 'edit') {
      Object.assign(data, { Id: id });
    }

    const formData = new FormData();
    for (const key in data) {
      if (data[key] !== null) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  };

  const handleNewFeaturedOccasion = async () => {
    const formData = getFormData();
    setValid(false);
    setIsLoaderOpen(true);
    try {
      const res = await FeaturedOccasionService.createFeaturedOccasion(formData);
      setValid(true);
      setIsLoaderOpen(false);
      toast.success(res.data.message ?? 'Added Featured Occasion successfuly!');
      navigate(-1);
    } catch (e) {
      errorLogger('Featured Occassion Info Add New Featured Occassion Error:', e);
      setValid(true);
      setIsLoaderOpen(false);
      toast.error(e.response?.data?.message ?? 'Failed to add Featured Occassion');
    }
  };

  const handleEditFeaturedOccasion = async () => {
    const formData = getFormData();
    setValid(false);
    setIsLoaderOpen(true);
    try {
      const res = await FeaturedOccasionService.editFeaturedOccasion(formData, id);
      setValid(true);
      setIsLoaderOpen(false);
      toast.success(res.data.message ?? 'Edited Occassion successfuly!');
      navigate(-1);
    } catch (e) {
      errorLogger('Occassion Info Edit Occassion Error:', e);
      setValid(true);
      setIsLoaderOpen(false);
      toast.error(e.response?.data?.message ?? 'Failed to edit Occassion');
    }
  };
  const colorTemplate = (data) => (
    <>
      <div
        style={{ backgroundColor: data.colorHex, width: data.colorHex ? '' : 0 }}
        className="colorTemplateDisplay"
      ></div>
      <span className="colorName">{data.colorName}</span>
    </>
  );
  const handleBackgroundColorChange = (e) => {
    setBackgroundColor(e.itemData.colorHex);
  };
  const selectedColorValueTemplate = (data) => {
    return (
      <>
        <div className="selectedColorValueTemplate">
          <div
            style={{
              backgroundColor: data.colorHex,
              width: data.colorHex ? '' : 0,
            }}
            className="selectedColorDisplay"
          ></div>
          <div>{data.colorName}</div>
        </div>
      </>
    );
  };
  const getFeaturedOccasionById = async () => {
    setValid(false);
    setIsLoaderOpen(true);
    try {
      const res = await FeaturedOccasionService.getFeaturedOccasionsById(id);
      setValid(true);
      setIsLoaderOpen(false);
      setEditData(res?.data);
    } catch (e) {
      errorLogger('Occassion Info Fetch Occassion Data By ID Error:', e);
      setValid(true);
      setIsLoaderOpen(false);
      toast.error(e.response?.data?.message ?? 'Failed to fetch Occassion');
    }
  };
  useEffect(() => {
    if (type === 'edit') {
      getFeaturedOccasionById();
    }
    handleActiveCollections();

    setIsLoaderOpen(false);
  }, []);

  useEffect(() => {
    if (type === 'edit' && Object.keys(editData).length !== 0) {
      setIsActive(editData?.isActive);
      setCategoryId(editData?.collection?.id);
      setKey(editData?.key);
      setBackgroundColor(editData?.backgroundColor);
      setSortId(editData?.sortId);
      setTitleEn(editData?.titleEn);
      setTitleAr(editData?.titleAr);
      setTitleColor(editData?.titleColor);
      setIconEn(editData?.iconEn);
      setIconAr(editData?.iconAr);
      setDescriptionEn(editData?.descriptionEn);
      setDescriptionAr(editData?.descriptionAr);
      setDescriptionColor(editData?.descriptionColor);
      setViewAllBtnTextEn(editData?.viewAllBtnTextEn);
      setviewAllBtnTextAr(editData?.viewAllBtnTextAr);
      setViewAllTextColor(editData?.viewAllTextColor);
      setviewAllBackgroundColor(editData?.viewAllBtnBgColor);
    }
  }, [editData, activeCollections]);
  const validateForm = () => {
    if (
      key === '' ||
      categoryId == '' ||
      backgroundColor == '' ||
      descriptionEn == '' ||
      descriptionAr == '' ||
      titleEn == '' ||
      titleAr == '' ||
      viewAllBtnTextEn == '' ||
      viewAllBtnTextAr == ''
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  };
  useEffect(() => {
    validateForm();
  }, [
    key,
    categoryId,
    backgroundColor,
    descriptionEn,
    descriptionAr,
    titleEn,
    titleAr,
    viewAllBtnTextEn,
    viewAllBtnTextAr,
  ]);
  return (
    <>
      {isLoaderOpen ? (
        <SectionLoader height="180px" />
      ) : (
        <div className="card-body row banner-info-card">
          <AppErrorBoundary>
            {activeCollections.length > 0 && (
              <div className="form-group col-6 filter-select">
                <label className="bold-title" htmlFor="collection">
                  Product Collection
                </label>
                <CustomSelect
                  id="collection"
                  listData={activeCollections}
                  handleChange={handleCollectionChange}
                  placeholder="Select a collection"
                  textPropName="name"
                  valuePropName="id"
                  selectedValue={categoryId}
                />
              </div>
            )}
          </AppErrorBoundary>

          <div className="form-group col-6">
            <label className="bold-title" htmlFor="occasion-key">
              Name (Key)
            </label>
            <input
              id="occasion-key"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-3 filter-select category-select ">
            <label className="font-weight-bold" htmlFor="section-bg">
              Background Color
            </label>
            <AppErrorBoundary>
              <CustomSelect
                id="section-bg"
                listData={FeaturedOccasionColorList}
                handleChange={handleBackgroundColorChange}
                placeholder="Select a color"
                textPropName="colorName"
                valuePropName="colorHex"
                selectedValue={backgroundColor}
                valueTemplate={selectedColorValueTemplate}
                allowFiltering={false}
                delayUpdate="true"
                template={colorTemplate}
              />
            </AppErrorBoundary>
          </div>
          {type === 'edit' && (
            <div className="form-group col-3">
              <label className="bold-title" htmlFor="sortId">
                Sort ID
              </label>
              <input
                id="sortId"
                disabled
                value={sortId}
                onChange={(e) => setSortId(e.target.value)}
                className="form-control"
                type="number"
              />
            </div>
          )}

          <hr className={styles['hr-text']} data-content="TITLE INFO" />
          <div className="form-group col-6">
            <label className="bold-title" htmlFor="featured-enTitle">
              Title (English)
            </label>
            <input
              id="featured-enTitle"
              value={titleEn}
              maxLength={30}
              onChange={(e) => setTitleEn(e.target.value)}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-6">
            <label className="bold-title" htmlFor="featured-arTitle">
              Title (Arabic)
            </label>
            <input
              id="featured-arTitle"
              value={titleAr}
              maxLength={30}
              onChange={(e) => setTitleAr(e.target.value)}
              className="form-control"
              type="text"
            />
          </div>
          <div className="form-group col-4 filter-select category-select ">
            <label className="font-weight-bold" htmlFor="title-txt">
              Title Color
            </label>
            <AppErrorBoundary>
              <CustomSelect
                id="title-txt"
                listData={[{ colorHex: '#000', colorName: 'Black' }]}
                handleChange={() => {}}
                placeholder="Select a color"
                textPropName="colorName"
                valuePropName="colorHex"
                selectedValue={titleColor}
                valueTemplate={selectedColorValueTemplate}
                allowFiltering={false}
                disabled={true}
                delayUpdate="true"
                template={colorTemplate}
              />
            </AppErrorBoundary>
          </div>
          <div className="row col-12">
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="enImgFile">
                Icon English
              </label>
              <div
                className="file-img"
                style={{
                  backgroundImage: `url(${handleImageFile(iconEn)})`,
                  height: iconEn === '' ? 0 : 50,
                }}
              ></div>
              <div className="file-input">
                <input
                  type="file"
                  className="file"
                  id="enImgFile"
                  onChange={(e) => setIconEn(e.target.files[0])}
                />
                <label htmlFor="enImgFile">
                  <p className="file-name">Select file</p>
                </label>
              </div>
            </div>
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="arImgFile">
                Icon Arabic
              </label>
              <div
                className="file-img"
                style={{
                  backgroundImage: `url(${handleImageFile(iconAr)})`,
                  height: iconAr === '' ? 0 : 50,
                }}
              ></div>
              <div className="file-input">
                <input
                  type="file"
                  className="file"
                  id="arImgFile"
                  onChange={(e) => setIconAr(e.target.files[0])}
                />
                <label htmlFor="arImgFile">
                  <p className="file-name">Select file</p>
                </label>
              </div>
            </div>
          </div>
          <hr className={styles['hr-text']} data-content="DESCRIPTION INFO" />
          <div className="form-group col-6">
            <label className="bold-title" htmlFor="featured-enDesc">
              Description (English)
            </label>
            <textarea
              id="featured-enDesc"
              value={descriptionEn}
              maxLength={40}
              onChange={(e) => setDescriptionEn(e.target.value)}
              className="form-control"
              type="text"
            ></textarea>
          </div>
          <div className="form-group col-6">
            <label className="bold-title" htmlFor="featured-arDesc">
              Description (Arabic)
            </label>
            <textarea
              id="featured-arDesc"
              value={descriptionAr}
              maxLength={40}
              onChange={(e) => setDescriptionAr(e.target.value)}
              className="form-control"
              type="text"
            ></textarea>
          </div>
          <div className="form-group col-4 filter-select category-select ">
            <label className="font-weight-bold" htmlFor="desc-txt">
              Description Color
            </label>
            <AppErrorBoundary>
              <CustomSelect
                id="desc-txt"
                listData={[{ colorHex: '#000', colorName: 'Black' }]}
                handleChange={() => {}}
                placeholder="Select a color"
                textPropName="colorName"
                valuePropName="colorHex"
                selectedValue={descriptionColor}
                valueTemplate={selectedColorValueTemplate}
                allowFiltering={false}
                delayUpdate="true"
                disabled={true}
                template={colorTemplate}
              />
            </AppErrorBoundary>
          </div>
          <hr className={styles['hr-text']} data-content="BUTTON INFO" />
          <div className="form-group col-6">
            <label className="bold-title" htmlFor="featured-enBtn">
              Button (English)
            </label>
            <input
              id="featured-enBtn"
              value={viewAllBtnTextEn}
              onChange={(e) => setViewAllBtnTextEn(e.target.value)}
              className="form-control"
              type="text"
              maxLength={25}
            />
          </div>
          <div className="form-group col-6">
            <label className="bold-title" htmlFor="featured-arBtn">
              Button (Arabic)
            </label>
            <input
              id="featured-arBtn"
              value={viewAllBtnTextAr}
              onChange={(e) => setviewAllBtnTextAr(e.target.value)}
              className="form-control"
              type="text"
              maxLength={25}
            />
          </div>
          <div className="form-group col-4 filter-select category-select ">
            <label className="font-weight-bold" htmlFor="button-txt">
              Button Text Color
            </label>
            <AppErrorBoundary>
              <CustomSelect
                id="button-txt"
                listData={[{ colorHex: '#000', colorName: 'Black' }]}
                placeholder="Select a color"
                handleChange={() => {}}
                textPropName="colorName"
                valuePropName="colorHex"
                selectedValue={viewAllTextColor}
                valueTemplate={selectedColorValueTemplate}
                allowFiltering={false}
                delayUpdate="true"
                disabled={true}
                template={colorTemplate}
              />
            </AppErrorBoundary>
          </div>
          <div className="form-group col-4 filter-select category-select ">
            <label className="font-weight-bold" htmlFor="button-bg">
              Button Background Color
            </label>
            <AppErrorBoundary>
              <CustomSelect
                id="button-bg"
                listData={[{ colorHex: '#FFF', colorName: 'White' }]}
                placeholder="Select a color"
                handleChange={() => {}}
                textPropName="colorName"
                valuePropName="colorHex"
                selectedValue={viewAllBackgroundColor}
                valueTemplate={selectedColorValueTemplate}
                allowFiltering={false}
                delayUpdate="true"
                disabled={true}
                template={colorTemplate}
              />
            </AppErrorBoundary>
          </div>
        </div>
      )}
      <div className="card-footer">
        <button
          disabled={!isValid}
          onClick={() =>
            type === 'edit' ? handleEditFeaturedOccasion() : handleNewFeaturedOccasion()
          }
          className="btn btn-primary"
          type="button"
        >
          Save
        </button>
      </div>
    </>
  );
};

export default FeaturedOccasionInfo;
