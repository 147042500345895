import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
import SectionLoader from 'components/common/SectionLoader';

const FeaturedOccasionsDataTable = React.lazy(() =>
  import('components/featuredOccasions/FeaturedOccasionsDataTable')
);

const FeaturedOccasionsPage = (props) => {
  const [activeItems, setActiveItems] = useState({
    isActive: true,
    page: 1,
  });
  const [inActiveItems, setInActiveItems] = useState({
    isActive: false,
    page: 1,
  });
  const location = useLocation();

  useEffect(() => {
    if (location?.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, [location?.search]);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <FeaturedOccasionsDataTable
          activeItems={activeItems}
          setActiveItems={setActiveItems}
          inActiveItems={inActiveItems}
          setInActiveItems={setInActiveItems}
        />
      </React.Suspense>
    </div>
  );
};

export default FeaturedOccasionsPage;
