import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class MasterCatalogService {
  static getLocalAvailabilityList(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getLocalAvailabilityList, ...extraParams),
      params: data,
    });
  }

  static createNewProduct(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addProduct, ...extraParams),
      data,
    });
  }

  static getSyncMasterCaltalogProduct(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getSyncMasterCaltalogProduct, ...extraParams),
    });
  }

  static getIndividualProductData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getIndividualProductData, ...extraParams),
    });
  }

  static getProductLog(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getProductLog, ...extraParams),
      params,
    });
  }

  static exportProductLog(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.exportProductLog, ...extraParams),
      params,
    });
  }

  static uploadRankingFile(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.uploadRankingFile, ...extraParams),
      data,
    });
  }

  static bulkStatusUpdate(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateStatusBulk, ...extraParams),
      data,
    });
  }

  static bulkTypeUpdate(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.updateTypeBulk, ...extraParams),
      data,
    });
  }

  static bulkCategoriesUpdate(data, ...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.updateCategoriesBulk, ...extraParams),
      data,
    });
  }

  static bulkMCUpdate(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.updateMCbulk, ...extraParams),
      data,
    });
  }

  static getExportMasterCatalog(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getExportMasterCatalog, ...extraParams),
      params: data,
      responseType: 'blob',
    });
  }

  static getUncategorizedMerch(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUncategorizedMerch, ...extraParams),
      params: data,
    });
  }

  static getExportUncategorizedMerch(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getExportUncategorizedMerch, ...extraParams),
      params: data,
      responseType: 'blob',
    });
  }
}
export default MasterCatalogService;
