import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PageTitle from 'components/shared/PageTitle';
import CorporateInvoicesDataTable from 'components/invoices/CorporateInvoicesDataTable';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import SearchUserModal from 'components/invoices/consolidatedInvoices/SearchUserModal';
// constants
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';
import { getConsolidatedInvoicesPageUrl } from 'constant/AppUrls';
// actions
import { getConsolidatedInvoicesData } from 'store/invoices/InvoicesActions';
// selectors
import { getConsolidatedInvoicesDataList } from 'store/invoices/InvoicesSelectors';

const ConsolidatedInvoicesPage = () => {
  const [isSearchUserModalOpen, setIsSearchUserModalOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const countryId = getFromLocalStorage(localStorageKeys.COUNTRY_ID);
  const invoicesData = useSelector((state) => getConsolidatedInvoicesDataList({ state }));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setUserId(queryObj.userid);
      setUserName(queryObj.name);
      setUserEmail(queryObj.email);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      const queryObj = { userid: userId, countryid: countryId };
      dispatch(getConsolidatedInvoicesData(queryObj));
      const queryParams = convertObjectIntoQueryParams({
        ...queryObj,
        name: userName,
        email: userEmail,
      });
      navigate({
        pathname: getConsolidatedInvoicesPageUrl(),
        search: queryParams,
      });
    }
  }, [userId]);

  return (
    <>
      {isSearchUserModalOpen && (
        <SearchUserModal
          isSearchUserModalOpen={isSearchUserModalOpen}
          setIsSearchUserModalOpen={setIsSearchUserModalOpen}
          setUserName={setUserName}
          setUserId={setUserId}
          setUserEmail={setUserEmail}
        />
      )}
      <div className="no-toolbar-table-style">
        <PageTitle title="Consolidated-Invoices" />
        <div className="card">
          <div className="card-footer ">
            <button
              onClick={() => setIsSearchUserModalOpen((prev) => !prev)}
              className="btn btn-primary mr-1"
              type="button"
            >
              Select User
            </button>
          </div>
          {userId && (
            <div className="row card-body">
              <div className="form-group col-12 col-sm-6">
                <input className="form-control" type="text" value={userName} readOnly />
              </div>
              <div className="form-group col-12 col-sm-6">
                <input className="form-control" type="text" value={userEmail} readOnly />
              </div>
            </div>
          )}
        </div>
        {userId ? (
          <AppErrorBoundary>
            <CorporateInvoicesDataTable data={invoicesData} consolidated userId={userId} />
          </AppErrorBoundary>
        ) : (
          <p style={{ textAlign: 'center' }}>No user selected</p>
        )}
      </div>
    </>
  );
};

export default ConsolidatedInvoicesPage;
