import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class NotificationsService {
  static getOldNotifications(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getOldNotifications, ...extraParams),
      params: data,
    });
  }

  static getOrderOldNotifications(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getOrderOldNotifications, ...extraParams),
    });
  }

  static acknowledgeNotification(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.acknowledgeNotifications, ...extraParams),
      data,
    });
  }
}
export default NotificationsService;
