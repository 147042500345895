import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import DesignersDailyCommisionsDataGrid from 'components/reports/orders/DesignersDailyCommisionsDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import OrdersReportService from 'services/reportsService/OrdersReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DesignerCommissionReportPage = () => {
  const [designersCommission, setDesignerCommission] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await OrdersReportService.getDesignersDailyCommisions(params);
      setDesignerCommission(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Designer Commission Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);
    navigate({
      pathname: '/report/DesignerCommissionReport',
      search: queryString,
    });
    fetchData(inputValues);
  };

  const handleExport = async () => {
    try {
      const res = await OrdersReportService.exportGetDesignersDailyCommisions(inputValues);
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('Designer Commission Report Page Export Data Error:', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        hasExport
        handleExport={handleExport}
        title="Designers Daily Commision"
        defaultDate
      />
      <DesignersDailyCommisionsDataGrid data={designersCommission} />
    </div>
  );
};

export default DesignerCommissionReportPage;
