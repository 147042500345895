import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
// Components
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import DJHPagination from 'components/shared/Pagination';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setProductMasterInventoryById } from 'store/products/ProductsActions';
// api
import ProductsService from 'services/ProductsService';

const AddNewInvenoryMasterModal = ({
  isAddMasterInvModalOpen,
  setIsAddMasterInvModalOpen,
  productId,
  validateMI,
}) => {
  const [name, setName] = useState('');
  const [isSearchLoaderOpen, setIsSearchLoaderOpen] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState({
    currentPage: 0,
    pageSize: 0,
    rowCount: 0,
  });
  const dispatch = useDispatch();

  const fetchSearchData = debounce(async (keyword, page) => {
    const params = {
      q: keyword,
      page,
    };
    setIsSearchLoaderOpen(true);
    try {
      const res = await ProductsService.searchProductMasterInventoryItem(params);
      setSearchResult(res?.data?.data);
      setPage({
        currentPage: res?.data?.currentPage,
        pageSize: res?.data?.pageSize,
        rowCount: res?.data?.rowCount,
      });
      setIsSearchLoaderOpen(false);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Error fetching data.');
      setIsSearchLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    setName(keyword);
    if (keyword.length >= 2) {
      fetchSearchData(keyword);
    }
  };

  const handleAddNewItem = async (item) => {
    const dataBody = {
      inventoryId: item.id,
      qty: 1,
    };
    try {
      const res = await ProductsService.addNewProductMasterInventoryItem(dataBody, productId);
      toast.success(res?.data?.message ?? 'Assigned');
      setTimeout(() => {
        dispatch(setProductMasterInventoryById(productId, validateMI));
      }, 1500);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Failed to assign');
    }
  };

  const onPageChange = (i) => {
    fetchSearchData(name, i);
  };

  const imageTemplate = (rowData) => (
    <img src={`${rowData?.fullImage}`} alt="Product" className="img-thumbnail img-fluid img-90" />
  );

  const addTemplate = (rowData) => (
    <Button color="success" onClick={() => handleAddNewItem(rowData)}>
      <i className="fa fa-plus"></i> Add
    </Button>
  );

  return (
    <Modal
      isOpen={isAddMasterInvModalOpen}
      toggle={() => setIsAddMasterInvModalOpen((prev) => !prev)}
      className="m-w-700"
    >
      <ModalHeader>Add New Item</ModalHeader>
      <ModalBody>
        <form className="card-body row">
          <div className="form-group col-12">
            <label className="bold-title" htmlFor="barcode">
              Barcode or Name
            </label>
            <input
              id="barcode"
              className="form-control"
              type="text"
              placeholder="Search barcode or name"
              onChange={(e) => handleSearch(e.target.value)}
              value={name}
            />
          </div>
          {isSearchLoaderOpen ? (
            <div className="form-group col-12">
              <SectionLoader height="180px" />
            </div>
          ) : (
            <div className="col-12">
              {searchResult?.length > 0 ? (
                <>
                  <GridComponent dataSource={searchResult} allowTextWrap={true}>
                    <ColumnsDirective>
                      <ColumnDirective
                        width="75"
                        headerText="Image"
                        template={imageTemplate}
                        textAlign="Center"
                      />
                      <ColumnDirective
                        width="75"
                        field="name"
                        headerText="Item"
                        textAlign="Center"
                      />
                      <ColumnDirective
                        width="75"
                        field="barCode"
                        headerText="Barcode"
                        textAlign="Center"
                      />
                      <ColumnDirective
                        width="75"
                        headerText="Add"
                        template={addTemplate}
                        textAlign="Center"
                      />
                    </ColumnsDirective>
                  </GridComponent>
                  <DJHPagination
                    onPageChange={onPageChange}
                    currentPage={page.currentPage}
                    pageSize={page.pageSize}
                    itemsCount={page.rowCount}
                  />
                </>
              ) : null}
            </div>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setIsAddMasterInvModalOpen((prev) => !prev)} color="primary">
          Done
        </Button>
        <Button color="light" onClick={() => setIsAddMasterInvModalOpen((prev) => !prev)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewInvenoryMasterModal;
