/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setZiwoRecordedCall } from 'store/viewOrder/ViewOrderActions';
// selectors
import { getZiwoRecordedCall } from 'store/viewOrder/ViewOrderSelectors';

const ZiwoRecordedCallModal = ({
  isZiwoRecordedCallModalOpen,
  setIsZiwoRecordedCallModalOpen,
  ziwoCallId,
}) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const ziwoRecordedCall = useSelector((state) => getZiwoRecordedCall({ state }));
  const dispatch = useDispatch();

  const fetchRecordedCall = async (id) => {
    setIsLoaderOpen(true);
    await dispatch(setZiwoRecordedCall(id));
    setIsLoaderOpen(false);
  };

  useEffect(() => {
    fetchRecordedCall(ziwoCallId);
  }, []);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isZiwoRecordedCallModalOpen}
        toggle={() => setIsZiwoRecordedCallModalOpen((prev) => !prev)}
        className="ziwo-recorded-calls-modal"
      >
        <ModalHeader toggle={() => setIsZiwoRecordedCallModalOpen((prev) => !prev)}>
          Recorded Call
        </ModalHeader>
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <ModalBody>
            <div className="card-body">
              <audio src={ziwoRecordedCall} playsInline={true} autoPlay="" controls></audio>
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button color="light" onClick={() => setIsZiwoRecordedCallModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ZiwoRecordedCallModal;
