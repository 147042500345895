import { toast } from 'react-toastify';
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { errorLogger } from 'datadog/DDUtils';
// api
import DeliveryService from 'services/DeliveryService';

export const setDriversExpensesData = (params) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await DeliveryService.getDriversExpensesData(params);
    dispatch({
      type: 'GET_DRIVERS_EXPENSES_DATA',
      payload: res.data.data,
    });
    dispatch(setLoaderOpenFalse());
  } catch (err) {
    errorLogger('DriversExpensesActions:setDriversExpensesData', err);
    toast.error(err?.response?.data?.message ?? 'Failed to fetch drivers list!!');
    dispatch(setLoaderOpenFalse());
  }
};

export const setChangeDriverExpensesStatus = (rowData) => async (dispatch) => {
  try {
    await DeliveryService.updateDriversExpensesStatus(rowData.id, rowData.status === 0 ? 1 : 0);
    toast.success('Updated Successfully');
    dispatch({
      type: 'GET_UPDATE_DRIVER_EXPENSES_STATUS',
      payload: { id: rowData.id, status: rowData.status === 0 ? 1 : 0 },
    });
  } catch (err) {
    errorLogger('DriversExpensesActions:setChangeDriverExpensesStatus', err);
    toast.error(err?.response?.data?.message ?? 'Failed to update driver status!!');
  }
};
