import React from 'react';
// constants
import { convertSecondsIntoTime, convertToDateAndTime } from 'constant/Helpers';
import { ArrowDownLeft, ArrowUpRight } from 'react-feather';
// components
import RestrictedSection from 'components/shared/RestrictedSection';

const ZiwoCallsDataTable = ({ item, setZiwoCallId, setIsZiwoRecordedCallModalOpen }) => {
  return (
    <>
      <tr>
        <td>
          <div className="d-inline-block text-center">
            <span className="f-w-600">{item.phoneNumber}</span>
            <p>{item.isSender ? 'Sender' : 'Receiver'}</p>
          </div>
        </td>
        <td>
          <div className="d-inline-block text-center">
            {item.direction === 'outbound' ? (
              <ArrowUpRight className="up-right-arrow" />
            ) : (
              <ArrowDownLeft className="down-left-arrow" />
            )}
            <p>{item.direction === 'outbound' ? 'Outgoing' : 'Incoming'}</p>
          </div>
        </td>
        <td className="text-align-center">
          <div className="d-inline-block text-center">
            <i
              className={`ziwo-status fa fa-${item.status === 'answered' ? 'check-circle' : 'ban'}`}
            ></i>
            <p>{item.status}</p>
          </div>
        </td>
        <td className="text-align-center">
          <div className="d-inline-block text-center">
            <span className="f-w-600">{item.date && convertToDateAndTime(item.date)}</span>
            <p>Time</p>
          </div>
        </td>
        <td className="text-align-right">
          <div className="d-inline-block text-center">
            <span className="f-w-600">
              {item.duration && convertSecondsIntoTime(item.duration)}
            </span>
            <p>Duration</p>
          </div>
        </td>
        <td className="text-align-center">
          <div className="d-inline-block text-center">
            {item.agent ? (
              <span className="f-w-600">{`${item.agent?.firstName} ${item.agent?.lastName}`}</span>
            ) : (
              <span className="f-w-600">NA</span>
            )}

            <p>Agent</p>
          </div>
        </td>
        <RestrictedSection page="order_details" action="ziwo_recorded_call">
          <td className="text-align-center">
            <div className="d-inline-block text-center">
              <button
                disabled={item.status !== 'answered'}
                type="button"
                className="btn btn-info"
                onClick={() => {
                  setZiwoCallId(item.callId);
                  setIsZiwoRecordedCallModalOpen((prev) => !prev);
                }}
              >
                <i className="fa fa-music"></i> Listen
              </button>
            </div>
          </td>
        </RestrictedSection>
      </tr>
    </>
  );
};

export default ZiwoCallsDataTable;
