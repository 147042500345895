import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// selector
import { getFloristDesignData } from 'store/floristCommunity/FloristCommunitySelectors';
// actions
import { getFloristDesignById } from 'store/floristCommunity/FloristCommunityActions';
// components
import SectionLoader from 'components/common/SectionLoader';
const EditFloristDesign = React.lazy(() => import('components/floristCommunity/EditFloristDesign'));

const FloristDesignEditPage = () => {
  const data = useSelector((state) => getFloristDesignData({ state }));
  const dispatch = useDispatch();
  const params = useParams();

  const fetchData = async () => {
    dispatch(getFloristDesignById(parseInt(params?.id, 10)));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <EditFloristDesign data={data} />
      </React.Suspense>
    </div>
  );
};
export default FloristDesignEditPage;
