import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import ProductTopTenDataGrid from 'components/reports/products/productTopTen/ProductTopTenDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import ProductsReportService from 'services/reportsService/ProductsReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const TopTenProductsPage = () => {
  const [typeData, setTypeData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [desingerData, setDesingerData] = useState([]);
  const [basketData, setBasketData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    sort: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res1 = await ProductsReportService.getTopTenProductsByType({
        ...params,
        productType: 0,
      });
      const res2 = await ProductsReportService.getTopTenProductsByType({
        ...params,
        productType: 2,
      });
      const res3 = await ProductsReportService.getTopTenProductsByType({
        ...params,
        productType: 1,
      });
      const res4 = await ProductsReportService.getTopTenProductsByType({
        ...params,
        productType: 5,
      });
      setTypeData(res1.data);
      setBrandData(res2.data);
      setDesingerData(res3.data);
      setBasketData(res4.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Top Ten Products Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      const updatedParams = cloneDeep(queryObj);
      updatedParams.sort = parseInt(updatedParams.sort, 10);
      fetchData(updatedParams);
      setInputValues(updatedParams);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(inputValues);

    navigate({
      pathname: '/report/topTenProducts',
      search: queryString,
    });
    fetchData(inputValues);
  };

  return (
    <div className="quick-actions-table toolbar-table-style">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Top Ten Products"
        sortType
        defaultDate
      />
      <ProductTopTenDataGrid data={typeData} title={'Product'} />
      <ProductTopTenDataGrid data={brandData} title={'Brand'} />
      <ProductTopTenDataGrid data={desingerData} title={'Desinger'} />
      <ProductTopTenDataGrid data={basketData} title={'Basket'} />
    </div>
  );
};

export default TopTenProductsPage;
