// utilities
import { updateObject } from '../../../../utility';
// custom validators
import {
  validateRequired,
  validateNumber,
  validateGreaterThanZero,
} from '../../../../../constant/CustomValidators';
// action types
import {
  CLOSE_ADD_INVENTORY_MODAL,
  OPEN_ADD_INVENTORY_MODAL,
  RESET_ADD_INVENTORY_FORM,
  SET_ADD_INVENTORY_BARCODE_PROPERTIES,
  SET_ADD_INVENTORY_IS_LOADING_FALSE,
  SET_ADD_INVENTORY_IS_LOADING_TRUE,
  SET_ADD_INVENTORY_IS_SEARCHING_FALSE,
  SET_ADD_INVENTORY_IS_SEARCHING_TRUE,
  SET_ADD_INVENTORY_PRODUCT_PROPERTIES,
  SET_ADD_INVENTORY_QUANTITY_PROPERTIES,
  SET_ADD_INVENTORY_SEARCH_LIST,
  SET_ADD_INVENTORY_SEARCH_PROPERTIES,
} from '../AddInventoryActionTypes';

const initialState = {
  search: {
    value: '',
  },
  barcode: {
    value: '',
    displayErrors: {
      isRequiredError: false,
    },
    valid: false,
    touched: false,
  },
  product: {
    value: '',
    displayErrors: {
      isRequiredError: false,
    },
    valid: true,
    touched: false,
  },
  quantity: {
    value: '',
    displayErrors: {
      isRequiredError: false,
      isNumError: false,
      isGreaterThanZeroError: false,
    },
    valid: false,
    touched: false,
  },
  isAddInventoryModal: false,
  searchInventoryList: [],
  isLoading: false,
  isSearching: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ADD_INVENTORY_MODAL: {
      return updateObject(state, { isAddInventoryModal: true });
    }
    case CLOSE_ADD_INVENTORY_MODAL: {
      return updateObject(state, { isAddInventoryModal: false });
    }
    case SET_ADD_INVENTORY_SEARCH_PROPERTIES: {
      return updateObject(state, {
        search: {
          value: action.value,
        },
      });
    }
    case SET_ADD_INVENTORY_BARCODE_PROPERTIES: {
      const isRequiredError = validateRequired(action.value);
      return updateObject(state, {
        barcode: {
          value: action.value,
          displayErrors: {
            isRequiredError,
          },
          valid: !isRequiredError,
          touched: true,
        },
      });
    }
    case SET_ADD_INVENTORY_PRODUCT_PROPERTIES: {
      const isRequiredError = validateRequired(action.value);
      return updateObject(state, {
        product: {
          value: action.value,
          displayErrors: {
            isRequiredError,
          },
          valid: !isRequiredError,
          touched: true,
        },
      });
    }
    case SET_ADD_INVENTORY_QUANTITY_PROPERTIES: {
      const displayErrors = {
        isRequiredError: validateRequired(action.value),
        isNumError: validateNumber(action.value),
        isGreaterThanZeroError: validateGreaterThanZero(action.value),
      };
      const isValid = !displayErrors.isRequiredError && !displayErrors.isNumError;
      return updateObject(state, {
        quantity: {
          value: action.value,
          displayErrors,
          valid: isValid,
          touched: true,
        },
      });
    }
    case SET_ADD_INVENTORY_IS_SEARCHING_TRUE: {
      return updateObject(state, { isSearching: true });
    }
    case SET_ADD_INVENTORY_IS_SEARCHING_FALSE: {
      return updateObject(state, { isSearching: false });
    }
    case SET_ADD_INVENTORY_SEARCH_LIST: {
      return updateObject(state, { searchInventoryList: action.list });
    }
    case SET_ADD_INVENTORY_IS_LOADING_TRUE: {
      return updateObject(state, { isLoading: true });
    }
    case SET_ADD_INVENTORY_IS_LOADING_FALSE: {
      return updateObject(state, { isLoading: false });
    }
    case RESET_ADD_INVENTORY_FORM: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
