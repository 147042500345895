import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CardMessage from '../../viewOrder/CardMessage';
// actions
import { setCardMessageModalOpen } from 'store/orderQuickActions/dataTable/DataTableAction';
// selectors
import {
  getIsCardModalOpen,
  getOrderId,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
import { getOrderCardMessage } from 'store/viewOrder/ViewOrderSelectors';

const CardMessageModal = () => {
  const isCardModalOpen = useSelector((state) => getIsCardModalOpen({ state }));
  const orderId = useSelector((state) => getOrderId({ state }));
  const cardMessage = useSelector((state) => getOrderCardMessage({ state }));
  const dispatch = useDispatch();

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isCardModalOpen}
        toggle={() => dispatch(setCardMessageModalOpen())}
        className="card-msg-modal"
      >
        <ModalHeader toggle={() => dispatch(setCardMessageModalOpen())}>Card Message</ModalHeader>
        <ModalBody>
          <CardMessage orderId={orderId} cardMessage={cardMessage} />
        </ModalBody>
        <ModalFooter className="center-modal-footer">
          <Button color="light" onClick={() => dispatch(setCardMessageModalOpen())}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CardMessageModal;
