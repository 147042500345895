import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
import InventorySuppliersDataGrid from 'components/inventory/suppliers/InventorySuppliersDataGrid';
// modals
import AddSupplierModal from 'components/inventory/modals/AddSupplierModal';
// actions
import { setInventorySuppliersData } from 'store/inventory/InventoryActions';
// selectors
import { getInventorySuppliersData } from 'store/inventory/InventorySelectors';

const InventorySuppliersPage = () => {
  const suppliersData = useSelector((state) => getInventorySuppliersData({ state }));
  const [isAddSupplierModalOpen, setIsAddSupplierModalOpen] = useState(false);
  const [clickedSupplier, setClickedSupplier] = useState('');
  const dispatch = useDispatch();

  const fetchInventorySuppliers = async () => {
    dispatch(setInventorySuppliersData());
  };

  useEffect(() => {
    fetchInventorySuppliers();
  }, []);

  const handleClick = (obj) => {
    setClickedSupplier(obj);
    setIsAddSupplierModalOpen((prev) => !prev);
  };

  return (
    <div className="no-toolbar-table-style">
      <PageTitle title="Inventory suppliers" />
      {isAddSupplierModalOpen && (
        <AddSupplierModal
          isAddSupplierModalOpen={isAddSupplierModalOpen}
          setIsAddSupplierModalOpen={setIsAddSupplierModalOpen}
          clickedSupplier={clickedSupplier}
        />
      )}
      <div className="card">
        <div className="card-body row btn-container">
          <button
            className="btn btn-success "
            onClick={() => {
              setClickedSupplier('');
              setIsAddSupplierModalOpen((prev) => !prev);
            }}
          >
            <i className="fa fa-plus"></i> Add New
          </button>
        </div>
      </div>

      <InventorySuppliersDataGrid data={suppliersData} handleClick={handleClick} />
    </div>
  );
};

export default InventorySuppliersPage;
