import { updateObject } from '../utility';

const initialState = {
  userDataById: {},
  userDetails: {},
  userRoles: [],
  userWalletBalanceById: '',
  userGroupsListById: [],
  userRecentTransactionsById: [],
  userPointsById: '',
  userPointsHistoryById: [],
  userActivitiesById: {
    currentPage: 0,
    pageSize: 15,
    rowCount: 0,
    data: [],
  },
  groupUsersList: [],
  pickersList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_DATA_BY_ID': {
      return updateObject(state, {
        userDataById: action.payload,
      });
    }
    case 'SET_USER_WALLET_BALANCE_BY_ID': {
      return updateObject(state, {
        userWalletBalanceById: action.payload,
      });
    }
    case 'SET_USER_GROUPS_BY_ID': {
      return updateObject(state, {
        userGroupsListById: action.payload,
      });
    }

    case 'SET_USER_RECENT_TRANSACTIONS_BY_ID': {
      return updateObject(state, {
        userRecentTransactionsById: action.payload,
      });
    }

    case 'SET_USER_POINTS_BY_ID': {
      return updateObject(state, {
        userPointsById: action.payload,
      });
    }

    case 'SET_USER_POINTS_HISTORY': {
      return updateObject(state, {
        userPointsHistoryById: action.payload,
      });
    }

    case 'SET_USER_ACTIVITIES': {
      return updateObject(state, {
        userActivitiesById: action.payload,
      });
    }

    case 'SET_GROUP_USERS_LIST': {
      return updateObject(state, {
        groupUsersList: action.payload,
      });
    }
    case 'SET_USER_DETAILS': {
      return updateObject(state, {
        userDetails: action.payload,
      });
    }
    case 'GET_USER_ROLES': {
      return updateObject(state, {
        userRoles: action.payload,
      });
    }

    case 'SET_PICKERS_USERS_LIST': {
      return updateObject(state, {
        pickersList: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
