import React from 'react';
import { toast } from 'react-toastify';
import { orderTypes } from 'constant/Enums';
// api
import ViewOrderService from 'services/ViewOrderService';
import CustomSelect from 'components/shared/CustomSelect';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const OrderTypeAndOccasion = ({ orderData, orderId }) => {
  const handleOrderType = async (e) => {
    const dataBody = {
      value: e.itemData.value,
    };
    try {
      await ViewOrderService.orderType(dataBody, orderId);
      toast.success('Order type changed successfully!');
    } catch (err) {
      errorLogger('OrderTypeAndOccasion:handleOrderType', err);
      toast.error('Failed to change order type');
    }
  };

  const handleOcassionChange = async (e) => {};

  return (
    <div className="card row">
      <div className="card-body">
        <div className="row">
          <div className="form-group col-6 p-r-0 filter-select">
            <label htmlFor="order-type">Order Type</label>
            <CustomSelect
              id="order-type"
              listData={orderTypes}
              handleChange={handleOrderType}
              placeholder="Select an order type"
              textPropName="name"
              valuePropName="value"
              selectedValue={orderData.type}
            />
          </div>
          <div className="form-group col-6 filter-select">
            <label htmlFor="order-occasion">Occasion</label>
            <CustomSelect
              id="order-occasion"
              listData={[{ value: -1, name: 'Select - Occasion' }]}
              handleChange={handleOcassionChange}
              placeholder="Select occasion"
              textPropName="name"
              valuePropName="value"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTypeAndOccasion;
