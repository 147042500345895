import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CardBody } from 'reactstrap';
// constants
import { copyPageUrl, handleCopyText } from 'constant/Helpers';
// components
import CardMessage from './CardMessage';
import Inventory from './Inventory';
import OrderInfo from './OrderInfo';
import OrderdetailsActions from './OrderdetailsActions';
import SecondaryActions from './SecondaryActions';
import CartDetails from './CartDetails';
import ProductTabs from './ProductTabs';
import Drivers from './Drivers';
// import CustomerCall from './CustomerCall';
import ActionBox from './ActionBox';
import RestrictedSection from 'components/shared/RestrictedSection';
import AddCallModal from './modals/AddCallModal';
import CommentBox from './CommentBox';
import OrderTypeAndOccasion from './OrderTypeAndOccasion';
import SupportTicketModal from './modals/SupportTicketModal';
import OrderNotificationsBox from './OrderNotificationsBox';
import TicketsListBox from './TicketsListBox';
import CustomerMessageModal from './modals/CustomerMessageModal';
import PremiumGift from './PremiumGift';
import PickupDetails from './PickupDetails';
import RightSideModal from 'components/shared/RightSideModal';
import ZiwoCallsContent from './ziwoCalls/ZiwoCallsContent';
import SalesOfficersContent from './SalesOfficersContent';
import AppErrorBoundary from 'components/errorBoundary/AppErrorBoundary';
import PageTitle from 'components/shared/PageTitle';
// material UI
import Tooltip from '@mui/material/Tooltip';
// actions
import { setViewOrderDetails } from 'store/viewOrder/ViewOrderActions';
import {
  // setToggleAddCallModal,
  setToggleSupportTicketModal,
} from 'store/orderQuickActions/dataTable/DataTableAction';
import { fetchOrderNewNotifications } from 'store/notifications/actions/NotificationsActions';
import { setSalesOfficerList } from 'store/corporate/CorporateActions';
// selectors
import {
  getViewOrderDetails,
  getOrderInvoice,
  getOrderCartDetails,
  getOrderCardMessage,
  // getCustomerCall,
  getInventoryData,
  getDriverData,
  getAdjustLog,
  getAdjustData,
  getGeneratedLinkData,
  getPremiumGiftData,
} from 'store/viewOrder/ViewOrderSelectors';
import { getSalesOfficerList } from 'store/corporate/CorporateSelectors';
import {
  // getIsAddCallModalOpen,
  getIsSupportTicketModalOpen,
} from 'store/orderQuickActions/dataTable/DataTableSelector';
import { getConnectionStatus } from 'store/app/AppSelectors';
import { getOrderTicketsList } from 'store/ticketing/TicketingSelectors';
import PreOrderWarning from './PreOrderWarning';

const OrderDetailsPage = () => {
  const orderData = useSelector((state) => getViewOrderDetails({ state }));
  const invoiceData = useSelector((state) => getOrderInvoice({ state }));
  const cartData = useSelector((state) => getOrderCartDetails({ state }));
  const cardMessage = useSelector((state) => getOrderCardMessage({ state }));
  // const customerCall = useSelector((state) => getCustomerCall({ state }));
  const inventoryData = useSelector((state) => getInventoryData({ state }));
  const driverData = useSelector((state) => getDriverData({ state }));
  const adjustLog = useSelector((state) => getAdjustLog({ state }));
  const adjustData = useSelector((state) => getAdjustData({ state }));
  const generatedLinkData = useSelector((state) => getGeneratedLinkData({ state }));
  // const isAddCallModalOpen = useSelector((state) => getIsAddCallModalOpen({ state }));
  const isSupportTicketModalOpen = useSelector((state) => getIsSupportTicketModalOpen({ state }));
  const connectionStatus = useSelector((state) => getConnectionStatus({ state }));
  const ticketsList = useSelector((state) => getOrderTicketsList({ state }));
  const premiumGiftData = useSelector((state) => getPremiumGiftData({ state }));
  const salesOfficerList = useSelector((state) => getSalesOfficerList({ state }));
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isZiwoModalOpen, setIsZiwoModalOpen] = useState(false);
  const pickupAndPackageFlag = cartData[0]?.isAvailableForPickUpAndPackage ?? false;
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(setViewOrderDetails(params?.id));
    dispatch(setSalesOfficerList());
  }, []);

  useEffect(() => {
    if (connectionStatus === 'connected') {
      dispatch(fetchOrderNewNotifications(params?.id));
    }
  }, [connectionStatus]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_VIEW_ORDER_DETAILS',
        payload: '',
      });
    };
  }, []);

  return (
    <Fragment>
      <PageTitle title={`Order ${params?.id}`} />
      {/* {isAddCallModalOpen && <AddCallModal orderId={params?.id} />} */}
      {isSupportTicketModalOpen && (
        <AppErrorBoundary>
          <SupportTicketModal
            orderId={params.id}
            orderData={orderData}
            isSupportTicketModalOpen={isSupportTicketModalOpen}
          />
        </AppErrorBoundary>
      )}
      {isMessageModalOpen && (
        <AppErrorBoundary>
          <CustomerMessageModal
            isMessageModalOpen={isMessageModalOpen}
            setIsMessageModalOpen={setIsMessageModalOpen}
            orderId={params.id}
          />
        </AppErrorBoundary>
      )}
      {isZiwoModalOpen && (
        <AppErrorBoundary>
          <RightSideModal
            isPaneOpen={isZiwoModalOpen}
            setIsPaneOpen={setIsZiwoModalOpen}
            direction="right"
          >
            <ZiwoCallsContent
              recepientNumber={orderData.recipient && orderData.recipient.recipientPhone}
              secondaryRecipientNumber={
                orderData.recipient && orderData.recipient.extraRecipientNumber
              }
              senderNumber={orderData.user && orderData.user.phone}
            />
          </RightSideModal>
        </AppErrorBoundary>
      )}
      {orderData !== '' && (
        <div className="container-fluid order-details-wrapper">
          <div className="row">
            <div
              className={`col-12 order-details-container ${
                window.screen.width < 500 ? 'order-details-mobile' : ''
              }`}
            >
              <div className="order-details-header">
                <Tooltip title="click to copy url">
                  <span className="order-details-title" onClick={copyPageUrl}>
                    #{params?.id}
                  </span>
                </Tooltip>{' '}
                <Tooltip title="copy order ID">
                  <i
                    className="fa fa-copy copy-order"
                    onClick={() => handleCopyText(params?.id)}
                  ></i>
                </Tooltip>{' '}
                <RestrictedSection page="order_details" action="view_priority">
                  {orderData?.orderPriority === 1 && (
                    <Tooltip title="Hight priority">
                      <i
                        className="fa fa-exclamation-circle priority-flag priority-flag-details"
                        aria-hidden="true"
                      ></i>
                    </Tooltip>
                  )}
                </RestrictedSection>
                - <span className="order-ops-title">{orderData.ops.nickName}</span>
                {orderData.nwDelivery && (
                  <div className="vip-order" style={{ backgroundColor: '#022069' }}>
                    NW
                  </div>
                )}
              </div>
              {orderData.productionLine === 1 && <i className="fa fa-star"></i>}
              {ticketsList.length > 0 && ticketsList.some((item) => item.status !== 2) && (
                <img
                  alt="flag"
                  className="order-flag"
                  src="https://img.favpng.com/22/10/17/red-flag-clip-art-png-favpng-m7EhuV4T38hc5JjxFfKtTJLB6.jpg"
                />
              )}
            </div>
            {orderData?.status === 7 ? (
              <PreOrderWarning
                message={`Note: Pre order status will be updated to "confirmed" automatically on the delivery date once stock is available for the pre order item`}
                isPreOrderPending
              />
            ) : (
              ''
            )}
            <div className="col-12">
              <OrderdetailsActions
                orderData={orderData}
                invoiceData={invoiceData}
                cartData={cartData}
                orderId={params?.id}
                driverData={driverData}
                inventoryData={inventoryData}
              />
            </div>
            <div className="col-12 col-sm-8 ">
              <AppErrorBoundary>
                <OrderInfo orderData={orderData} orderId={params.id} driverData={driverData} />
              </AppErrorBoundary>
              {pickupAndPackageFlag && <PickupDetails pickupDetails={premiumGiftData} />}
              {orderData.productionLine === 1 && (
                <PremiumGift
                  orderId={params?.id}
                  premiumGiftData={premiumGiftData}
                  orderData={orderData}
                />
              )}
              <AppErrorBoundary>
                <CardMessage
                  orderCstep={orderData.cStep}
                  orderId={params.id}
                  cardMessage={cardMessage}
                />
              </AppErrorBoundary>
              {cartData !== '' && (
                <RestrictedSection page="order_details" action="cart">
                  <AppErrorBoundary>
                    <CartDetails
                      carts={cartData}
                      subTotal={orderData?.paymentdetails?.subTotal}
                      delivery={orderData?.paymentdetails?.deliveryCost}
                      discount={orderData?.paymentdetails?.discountAmountG}
                      vat={orderData?.paymentdetails?.vAtAmount}
                      vatPercentage={orderData?.paymentdetails?.vat}
                      flwWallet={orderData?.paymentdetails?.paidFromWallet}
                      total={orderData?.paymentdetails?.grandTotal}
                      currency={orderData?.ops?.currencyISO}
                      paidAmount={orderData?.paymentdetails?.customerPaidAmount}
                      id={params?.id}
                      orderData={orderData}
                    />
                  </AppErrorBoundary>
                </RestrictedSection>
              )}
              <AppErrorBoundary>
                <Inventory
                  inventoryData={inventoryData}
                  orderId={params.id}
                  opsId={orderData.opsID}
                  orderCstep={orderData.cStep}
                  orderStatus={orderData.status}
                />
              </AppErrorBoundary>
              <AppErrorBoundary>
                <Drivers
                  orderCstep={orderData.cStep}
                  orderId={params.id}
                  driverData={driverData}
                  orderStatus={orderData.status}
                />
              </AppErrorBoundary>
              {/* <CustomerCall orderId={params?.id} customerCall={customerCall} /> */}
            </div>
            <div className="col-12 col-sm-4">
              <RestrictedSection page="order_details" action="action_box">
                <AppErrorBoundary>
                  <ActionBox orderId={params.id} paymentDate={orderData?.paymentDate} />
                </AppErrorBoundary>
              </RestrictedSection>
              <div className="row card">
                <AppErrorBoundary>
                  <CommentBox
                    orderId={params.id}
                    premium={false}
                    paymentDate={orderData?.paymentDate}
                  />
                </AppErrorBoundary>
              </div>
              <AppErrorBoundary>
                <OrderNotificationsBox orderId={params.id} />
              </AppErrorBoundary>
              <RestrictedSection page="order_details" action="order_tickets">
                <AppErrorBoundary>
                  <TicketsListBox
                    orderData={orderData}
                    orderId={params.id}
                    setToggleSupportTicketModal={setToggleSupportTicketModal}
                  />
                </AppErrorBoundary>
              </RestrictedSection>
              <RestrictedSection page="order_details" action="ziwo_calls">
                <div className="row card">
                  <CardBody
                    onClick={() => setIsZiwoModalOpen((prev) => !prev)}
                    className="recent-notification customer-call"
                  >
                    <i className="fa fa-headphones"></i>
                    <span className="customer-call-title">Ziwo Calls</span>
                  </CardBody>
                </div>
              </RestrictedSection>
              {/* <div className="row card">
                <CardBody
                  onClick={() => dispatch(setToggleAddCallModal())}
                  className="recent-notification customer-call"
                >
                  <i className="fa fa-phone"></i>
                  <span className="customer-call-title">Customer Call</span>
                </CardBody>
              </div> */}

              <RestrictedSection page="order_details" action="customer_message">
                <div className="row card">
                  <CardBody
                    onClick={() => setIsMessageModalOpen((prev) => !prev)}
                    className="recent-notification customer-call"
                  >
                    <i className="fa fa-envelope"></i>
                    <span className="customer-call-title">Customer Communications</span>
                  </CardBody>
                </div>
              </RestrictedSection>
              <AppErrorBoundary>
                <OrderTypeAndOccasion orderData={orderData} orderId={params.id} />
              </AppErrorBoundary>
              <AppErrorBoundary>
                <SalesOfficersContent
                  orderData={orderData}
                  orderId={params.id}
                  salesOfficerList={salesOfficerList}
                />
              </AppErrorBoundary>
            </div>
            {orderData.cStep !== 4 && (
              <AppErrorBoundary>
                <ProductTabs
                  orderId={params.id}
                  cardMessage={cardMessage}
                  // customerCall={customerCall}
                  driverData={driverData}
                  adjustLog={adjustLog}
                  adjustData={adjustData}
                  orderData={orderData}
                  generatedLinkData={generatedLinkData}
                />
              </AppErrorBoundary>
            )}
            <div className=" col-12 secondary-actions">
              <SecondaryActions orderData={orderData} cartData={cartData} orderId={params?.id} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OrderDetailsPage;
