import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// Components
import PageTitle from 'components/shared/PageTitle';
import InvoicesDataGrid from 'components/invoices/InvoicesDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import DJHPagination from 'components/shared/Pagination';
//  Actions
import { getInvoicesData } from 'store/invoices/InvoicesActions';
// Selectors
import {
  getInvoicesDataList,
  getInvoicesPageSize,
  getInvoicesCurrentPage,
  getInvoicesItemsCount,
} from 'store/invoices/InvoicesSelectors';
// Helpers
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import {
  isoStartFormat,
  isoEndFormat,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';
import { localStorageKeys } from 'constant/Enums';

const InvoicesPage = () => {
  const [inputValues, setInputValues] = useState({
    fromDate: isoStartFormat(new Date()),
    toDate: isoEndFormat(new Date()),
    orderId: '0',
    page: '1',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const countryId = getFromLocalStorage(localStorageKeys.COUNTRY_ID);
  const data = useSelector((state) => getInvoicesDataList({ state }));
  const pageSize = useSelector((state) => getInvoicesPageSize({ state }));
  const itemsCount = useSelector((state) => getInvoicesItemsCount({ state }));
  const currentPage = useSelector((state) => getInvoicesCurrentPage({ state }));
  const params = {
    ...inputValues,
    orderId: inputValues.orderId === '' ? '0' : inputValues.orderId,
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = convertQueryParamsIntoObject(location.search);
      setInputValues({
        fromDate: queryParams.fromDate,
        toDate: queryParams.toDate,
        orderId: queryParams.orderId,
        page: queryParams.page,
      });
      dispatch(getInvoicesData(queryParams, countryId));
    } else {
      dispatch(getInvoicesData(params, countryId));
    }
  }, []);

  const handleChange = (e) => {
    setInputValues({ ...inputValues, orderId: e.input.value });
  };

  const handleSubmit = async () => {
    const queryString = convertObjectIntoQueryParams(params);
    navigate({
      pathname: '/invoices',
      search: queryString,
    });
    dispatch(getInvoicesData(params, countryId));
  };

  const onPageChange = async (page) => {
    const newParams = {
      ...params,
      page: page || '1',
    };

    const queryString = convertObjectIntoQueryParams(newParams);

    navigate({
      pathname: '/invoices',
      search: queryString,
    });

    dispatch(getInvoicesData(newParams, countryId));
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="toolbar-table-style">
      <PageTitle title="Invoices" />
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        title="Invoice Filter"
        timeDate
        invoicesSearch
      />
      <InvoicesDataGrid data={data} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={currentPage}
        pageSize={pageSize}
        itemsCount={itemsCount}
      />
    </div>
  );
};

export default InvoicesPage;
