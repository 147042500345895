import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
import { getProductGroupsById } from 'store/products/ProductsActions';
// api
import ProductsService from 'services/ProductsService';

const CreateGroupItemModal = ({
  isAddItemModalOpen,
  setIsAddItemModalOpen,
  productOptionsById,
  productId,
  group,
  type,
  itemData,
}) => {
  const [name, setName] = useState('');
  const [minSelection, setMinSelection] = useState('');
  const [maxSelection, setMaxSelection] = useState('');
  const [orderId, setOrderId] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [disableBtn, setDisableBtn] = useState(type !== 'edit');
  const [showTable, setShowTable] = useState(true);
  const [isSearchLoaderOpen, setIsSearchLoaderOpen] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [disabledMax, setDisabledMax] = useState(false);
  const [selectedProductItemId, setSelectedProductItemId] = useState(0);
  const dispatch = useDispatch();

  const checkValidation = (item, max, oId) => {
    if (type === 'edit') {
      if (!max || !oId) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    } else {
      if (item === 0 || !max || !oId) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    }
  };

  const handleSubmitItem = async () => {
    setDisableBtn(true);
    dispatch(setLoaderOpenTrue());
    if (type === 'edit') {
      const data = {
        Id: itemData.id,
        Active: isActive,
        // minQty: minSelection,
        MaximumSelection: maxSelection,
        OrderId: orderId,
      };
      try {
        await ProductsService.editProductGroupItem(data);
        setDisableBtn(false);
        toast.success('Item updated successfully');
        dispatch(setLoaderOpenFalse());
        dispatch(getProductGroupsById(productId));
        setIsAddItemModalOpen(false);
      } catch (err) {
        setDisableBtn(false);
        toast.error('Failed to add item!');
        dispatch(setLoaderOpenFalse());
      }
    } else {
      const data = {
        ProductId: selectedProductItemId,
        Active: isActive,
        // minQty: minSelection,
        MaximumSelection: maxSelection,
        OrderId: orderId,
      };
      try {
        await ProductsService.addProductGroupItem(data, group.id);
        setDisableBtn(false);
        toast.success('Item added successfully');
        dispatch(setLoaderOpenFalse());
        dispatch(getProductGroupsById(productId));
        setIsAddItemModalOpen(false);
      } catch (err) {
        setDisableBtn(false);
        toast.error('Failed to add item!');
        dispatch(setLoaderOpenFalse());
      }
    }
  };

  const fetchSearchData = debounce(async (keyword) => {
    const params = {
      q: keyword,
      productType: productOptionsById?.type !== 10 ? 8 : 10,
    };
    setIsSearchLoaderOpen(true);
    try {
      const res = await ProductsService.searchBasketProduct(params);
      setSearchResult(res.data);
      setIsSearchLoaderOpen(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setIsSearchLoaderOpen(false);
    }
  }, 500);

  const handleSearch = (keyword) => {
    setName(keyword);
    if (keyword.length >= 3) {
      fetchSearchData(keyword);
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  useEffect(() => {
    if (type === 'edit') {
      setName(itemData.productName);
      // setMinSelection(itemData.minimumSelection)
      setMaxSelection(itemData.maximumSelection);
      setOrderId(itemData.orderId);
      setIsActive(itemData.active);
    }
  }, []);

  useEffect(() => {
    if (productOptionsById?.type === 10) {
      setMaxSelection(1);
      setDisabledMax(true);
    }
  }, [productOptionsById]);

  return (
    <div className="card-body btn-showcase">
      <Modal isOpen={isAddItemModalOpen} toggle={() => setIsAddItemModalOpen((prev) => !prev)}>
        <ModalHeader>
          {type === 'edit' ? `Edit ${itemData.productName} Item` : 'New Item'}
        </ModalHeader>
        <ModalBody>
          <form className="card-body row">
            <div className="form-group col-12">
              <label className="bold-title" htmlFor="group-item">
                Group
              </label>
              <input
                id="group-item"
                value={group.titleEn}
                readOnly
                className="form-control"
                type="text"
              />
            </div>
            <div className="form-group col-12">
              <label className="bold-title" htmlFor="group-name">
                Name
              </label>
              <input
                id="group-name"
                className="form-control"
                type="text"
                placeholder="item name, description"
                onChange={(e) => handleSearch(e.target.value)}
                value={name}
                disabled={type === 'edit'}
              />
            </div>
            {isSearchLoaderOpen && type !== 'edit' ? (
              <div className="form-group col-12">
                <SectionLoader height="180px" />
              </div>
            ) : (
              <>
                {searchResult.length > 0 && (
                  <div
                    className={`table-responsive shopping-table align-left m-h-350 ${
                      showTable ? 'h-auto' : ''
                    }`}
                  >
                    {showTable && (
                      <table className="table table-bordernone create-order-table m-t-40">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Item</th>
                            <th>Available Qty</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchResult.map((item, i) => (
                            <tr
                              className={`search-table-row pointer ${
                                item.id === selectedProductItemId ? 'table-row-selected' : ''
                              }`}
                              onClick={() => {
                                setSelectedProductItemId(item.id);
                                setName(item.name);
                                checkValidation(item.id, maxSelection, orderId);
                              }}
                              key={i}
                            >
                              <td>
                                <img
                                  src={item.image}
                                  alt="#"
                                  className="img-thumbnail img-fluid img-90"
                                />
                              </td>
                              <td>{item.name}</td>
                              <td>{item.availableQTY}</td>
                              <td>{item.price}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </>
            )}
            {/* <div className="form-group col-6">
              <label className="bold-title">Minimum Selection</label>
              <input
                value={minSelection}
                onChange={(e) => setMinSelection(e.target.value)}
                className="form-control"
                type="number"
              />
            </div> */}
            <div className="form-group col-6">
              <label className="bold-title" htmlFor="group-max">
                Maximum Selection
              </label>
              <input
                id="group-max"
                value={maxSelection}
                onChange={(e) => {
                  setMaxSelection(e.target.value);
                  checkValidation(selectedProductItemId, e.target.value, orderId);
                }}
                className="form-control"
                type="number"
                disabled={disabledMax}
              />
            </div>
            <div className="form-group col-12">
              <label className="bold-title" htmlFor="group-order">
                Order ID
              </label>
              <input
                id="group-order"
                value={orderId}
                onChange={(e) => {
                  setOrderId(e.target.value);
                  checkValidation(selectedProductItemId, maxSelection, e.target.value);
                }}
                className="form-control"
                type="number"
              />
            </div>
            <div className="form-group col-12 ">
              <input
                className="checkbox_animated"
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive((prev) => !prev)}
              />
              <span className="bold-title">Active</span>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleSubmitItem()} color="primary" disabled={disableBtn}>
            Save
          </Button>
          <Button color="light" onClick={() => setIsAddItemModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CreateGroupItemModal;
