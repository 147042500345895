import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import DriverPerformanceDetailsDataGrid from 'components/reports/delivery/DriverPerformanceDetailsDataGrid';
import PriceBreakDownFilter from 'components/reports/products/priceBreakDown/PriceBreakDownFilter';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  dateFormat,
} from 'constant/Helpers';
import { getDriverPerformanceDetailsReportPageUrl } from 'constant/AppUrls';
import DJHPagination from 'components/shared/Pagination';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// selector
import { getIsLoaderOpen } from 'store/app/AppSelectors';
// api
import DeliveryReportService from 'services/reportsService/DeliveryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DriverPerformanceDetailsReportPage = () => {
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    companyName: '',
    status: -1,
    page: 1,
  });
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getIsLoaderOpen({ state }));
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await DeliveryReportService.getDriverPerformanceDetails(params);
      setData(res.data);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Driver Performance Details Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      fetchData(queryObj);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues);
    }
  }, []);

  const handleSubmit = async () => {
    const newParams = cloneDeep(inputValues);
    newParams.page = 1;
    const queryString = convertObjectIntoQueryParams(newParams);
    navigate({
      pathname: '/report/DriverPerformanceDetailsReport',
      search: queryString,
    });
    fetchData(newParams);
  };

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);
    navigate({
      pathname: getDriverPerformanceDetailsReportPageUrl(),
      search: queryString,
    });
    fetchData(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  const handleExport = async () => {
    try {
      const res = await DeliveryReportService.exportDriverPerformanceDetails({
        ...inputValues,
        page: -1,
      });
      toast.success(res?.data?.message);
    } catch (err) {
      errorLogger('Driver Performance Details Report Page Export Data Error:', err);
      toast.error('Failed to export excel file!!');
    }
  };

  return (
    <div className="toolbar-table-style quick-actions-table">
      <PriceBreakDownFilter
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleSubmit={handleSubmit}
        title="Driver Performance Details"
        company
        status
        defaultDate
        hasExport
        handleExport={handleExport}
      />
      {!isLoading && <DriverPerformanceDetailsDataGrid data={data?.data} params={inputValues} />}
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+data?.currentPage}
        pageSize={+data?.pageSize}
        itemsCount={+data?.rowCount}
      />
    </div>
  );
};

export default DriverPerformanceDetailsReportPage;
