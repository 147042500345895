import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import DriverOrdersDataGrid from 'components/reports/delivery/DriverOrdersDataGrid';
import { toast } from 'react-toastify';
import {
  dateFormat,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
} from 'constant/Helpers';
import DJHPagination from 'components/shared/Pagination';
// actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// api
import DeliveryReportService from 'services/reportsService/DeliveryReportService';
// datadog
import { errorLogger } from 'datadog/DDUtils';

const DriverOrdersReportPage = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState(0);
  const [currentPage, setCurrentPage] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [rowCount, setRowCount] = useState('');
  const [inputValues, setInputValues] = useState({
    fromDate: dateFormat(new Date()),
    toDate: dateFormat(new Date()),
    status: -1,
    page: 1,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchData = async (params, newID) => {
    dispatch(setLoaderOpenTrue());
    try {
      const res = await DeliveryReportService.getDriverOrders(params, newID);
      setData(res.data.data);
      setCurrentPage(res.data.currentPage);
      setPageSize(res.data.pageSize);
      setRowCount(res.data.rowCount);
      dispatch(setLoaderOpenFalse());
    } catch (err) {
      errorLogger('Driver Orders Report Page Fetch Data Error:', err);
      dispatch(setLoaderOpenFalse());
      toast.error('Failed to get data!!');
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      const newID = queryObj?.id;
      setId(newID);
      delete queryObj?.id;
      fetchData(queryObj, newID);
      setInputValues(queryObj);
    } else {
      fetchData(inputValues, id);
    }
  }, []);

  const onPageChange = (i) => {
    const updatedParams = { ...inputValues, id, page: i };
    const queryString = convertObjectIntoQueryParams(updatedParams);

    navigate({
      pathname: '/report/DriverPerformanceOrdersReport',
      search: queryString,
    });
    fetchData(updatedParams, id);
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="toolbar-table-style quick-actions-table">
      <DriverOrdersDataGrid data={data} params={inputValues} id={id} />
      <DJHPagination
        onPageChange={onPageChange}
        currentPage={+currentPage}
        pageSize={+pageSize}
        itemsCount={+rowCount}
      />
    </div>
  );
};

export default DriverOrdersReportPage;
