import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import AreaForm from '../common/AreaForm';
import SectionLoader from 'components/common/SectionLoader';
import SubAreaForm from '../subAreas/SubAreaForm';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
// service
import DeliveryService from 'services/DeliveryService';
// actions
import { getAllAreas, getSubAreas } from 'store/delivery/deliveryAreas/areasActions';
// constants
import { localStorageKeys } from 'constant/Enums';
import { errorLogger } from 'datadog/DDUtils';

const AreaModal = ({ isAreaModalOpen, setIsAreaModalOpen, type, inputValues }) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const params = useParams();
  const [mainArea, setMainArea] = useState(parseInt(params?.id, 10));
  const [enName, setEnName] = useState('');
  const [arName, setArName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [zone, setZone] = useState('');
  const [deliveryCharge, setDeliveryCharge] = useState('');
  const [mapKey, setMapKey] = useState('');
  const dispatch = useDispatch();

  const handleMainAreaChange = (e) => {
    setMainArea(e.itemData.Id);
  };

  const validateForm = () => {
    if (type === 'area') {
      if (enName === '' || arName === '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    } else {
      if (enName === '' || arName === '' || mapKey === '' || deliveryCharge === '' || zone === '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    }
  };

  const handleCreateArea = async () => {
    const data = {
      Name: enName,
      NameAr: arName,
      CountryId: getFromLocalStorage(localStorageKeys.OPS_CENTER).id,
      IsActive: isActive,
      Type: 0,
    };
    setDisableBtn(true);
    try {
      let res = {};
      res = await DeliveryService.addArea(data);
      dispatch(getAllAreas(inputValues));
      setIsLoaderOpen(true);
      setIsAreaModalOpen((prev) => !prev);
      toast.success(res.data.message ?? 'Created area successfuly!');
    } catch (e) {
      errorLogger('AreaModal:handleCreateArea', e);
      setDisableBtn(false);
      setIsLoaderOpen(false);
      setIsAreaModalOpen((prev) => !prev);
      toast.error('Failed to create new area');
    }
  };

  const handleCreateSubArea = async () => {
    const data = {
      ParentId: parseInt(params?.id, 10),
      WorkShopId: null,
      MapNames: mapKey,
      DeliveryCost: deliveryCharge,
      Zone: parseInt(zone, 10),
      Name: enName,
      NameAr: arName,
      CountryId: getFromLocalStorage(localStorageKeys.OPS_CENTER).id,
      IsActive: isActive,
      Type: 0,
    };
    setDisableBtn(true);
    try {
      let res = {};
      res = await DeliveryService.addSubArea(data, params?.id);
      dispatch(getSubAreas(inputValues, params?.id));
      setIsLoaderOpen(true);
      setIsAreaModalOpen((prev) => !prev);
      toast.success(res.data.message ?? 'Created sub-area successfuly!');
    } catch (e) {
      errorLogger('AreaModal:handleCreateSubArea', e);
      setDisableBtn(false);
      setIsLoaderOpen(false);
      setIsAreaModalOpen((prev) => !prev);
      toast.error('Failed to create new sub-area');
    }
  };

  useEffect(() => {
    validateForm();
  }, [enName, arName, mapKey, deliveryCharge, zone]);

  return (
    <div className="card-body btn-showcase">
      <Modal
        isOpen={isAreaModalOpen}
        toggle={() => setIsAreaModalOpen((prev) => !prev)}
        className="m-w-900"
      >
        <ModalHeader>Create {type === 'area' ? 'Area' : 'Sub Area'}</ModalHeader>
        {isLoaderOpen ? (
          <SectionLoader height="180px" />
        ) : (
          <ModalBody>
            <div className="form-group col-12 card">
              <form className="card-body row">
                <AreaForm
                  mainArea={mainArea}
                  handleMainAreaChange={handleMainAreaChange}
                  enName={enName}
                  setEnName={setEnName}
                  arName={arName}
                  setArName={setArName}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  type={type}
                />
                {type !== 'area' && (
                  <SubAreaForm
                    zone={zone}
                    setZone={setZone}
                    deliveryCharge={deliveryCharge}
                    setDeliveryCharge={setDeliveryCharge}
                    mapKey={mapKey}
                    setMapKey={setMapKey}
                    isActive={isActive}
                    setIsActive={setIsActive}
                  />
                )}
              </form>
            </div>
          </ModalBody>
        )}
        <ModalFooter>
          <Button
            onClick={type === 'area' ? handleCreateArea : handleCreateSubArea}
            color="primary"
            disabled={disableBtn}
          >
            Create {type === 'area' ? 'area' : 'sub area'}
          </Button>
          <Button color="light" onClick={() => setIsAreaModalOpen((prev) => !prev)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AreaModal;
