import React, { useState } from 'react';

const RemoveUserTemplate = ({ handleRemoveArea, rowData, inputValues }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleRemoveArea(rowData, inputValues, setIsDisabled);
      }}
      className={`btn btn-danger ${isDisabled ? 'disabled' : 'pointer'}`}
      type="button"
      disabled={isDisabled}
    >
      <i className="fa fa-trash"></i>
    </button>
  );
};

export default RemoveUserTemplate;
