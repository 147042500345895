import { updateObject } from '../../utility';

const initialState = {
  deliverySlotsData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DELIVERY_SLOTS_ALL_DATA': {
      return updateObject(state, {
        deliverySlotsData: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
