import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// api
import ProductsService from 'services/ProductsService';
// selectors
import {
  getProductExcludedDaysData,
  getProductOptionsById,
} from 'store/products/ProductsSelectors';
// constant
import { days } from 'constant/Enums';

const ProductExcludeDaySection = ({ productId }) => {
  const excludedDays = useSelector((state) => getProductExcludedDaysData({ state }));
  const productOptionsById = useSelector((state) => getProductOptionsById({ state }));
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [isSatDisabled, setIsSatDisabled] = useState(false);
  const [isSunDisabled, setIsSunDisabled] = useState(false);
  const [isMonDisabled, setIsMonDisabled] = useState(false);
  const [isTueDisabled, setIsTueDisabled] = useState(false);
  const [isWedDisabled, setIsWedDisabled] = useState(false);
  const [isThuDisabled, setIsThuDisabled] = useState(false);
  const [isFriDisabled, setIsFriDisabled] = useState(false);

  const enableBtn = (type) => {
    switch (type) {
      case 6:
        setIsSatDisabled(false);
        break;
      case 0:
        setIsSunDisabled(false);
        break;
      case 1:
        setIsMonDisabled(false);
        break;
      case 2:
        setIsTueDisabled(false);
        break;
      case 3:
        setIsWedDisabled(false);
        break;
      case 4:
        setIsThuDisabled(false);
        break;
      case 5:
        setIsFriDisabled(false);
        break;
      default:
        break;
    }
  };

  const removeDay = async (type) => {
    try {
      const res = await ProductsService.deleteExcludedDay(productId, type);
      toast.success(res?.data?.message ?? `${days[type]} was removed successfully`);
      enableBtn(type);
    } catch (err) {
      enableBtn(type);
      toast.error(err?.response?.data?.message ?? `Failed to remove ${days[type]}`);
    }
  };

  const addDay = async (type) => {
    try {
      const res = await ProductsService.addExcludedDay(productId, type);
      toast.success(res?.data?.message ?? `${days[type]} was added successfully`);
      enableBtn(type);
    } catch (err) {
      enableBtn(type);
      toast.error(err?.response?.data?.message ?? `Failed to add ${days[type]}`);
    }
  };

  const sendValtoServer = (type) => {
    let status = false;
    switch (type) {
      case 6:
        status = saturday;
        setIsSatDisabled(true);
        break;
      case 0:
        status = sunday;
        setIsSunDisabled(true);
        break;
      case 1:
        status = monday;
        setIsMonDisabled(true);
        break;
      case 2:
        status = tuesday;
        setIsTueDisabled(true);
        break;
      case 3:
        status = wednesday;
        setIsWedDisabled(true);
        break;
      case 4:
        status = thursday;
        setIsThuDisabled(true);
        break;
      case 5:
        status = friday;
        setIsFriDisabled(true);
        break;
      default:
        break;
    }
    if (status) {
      removeDay(type);
    } else {
      addDay(type);
    }
  };

  useEffect(() => {
    setSaturday(excludedDays[0].Saturday.isExcluded);
    setSunday(excludedDays[0].Sunday.isExcluded);
    setMonday(excludedDays[0].Monday.isExcluded);
    setTuesday(excludedDays[0].Tuesday.isExcluded);
    setWednesday(excludedDays[0].Wednesday.isExcluded);
    setThursday(excludedDays[0].Thursday.isExcluded);
    setFriday(excludedDays[0].Friday.isExcluded);
  }, [excludedDays]);

  return (
    <div className="form-group col-12 card">
      <div className="card-body row">
        <table className="product-excluded-table">
          <tbody>
            <tr>
              <td>Saturday</td>
              <td>Sunday</td>
              <td>Monday</td>
              <td>Tuesday</td>
              <td>Wednesday</td>
              <td>Thursday</td>
              <td>Friday</td>
            </tr>
            <tr>
              <td>
                <div className="form-group col-12 ">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={saturday}
                    disabled={productOptionsById?.status === 1 || isSatDisabled}
                    onChange={(e) => {
                      setSaturday((prev) => !prev);
                      sendValtoServer(6);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="form-group col-12 ">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={sunday}
                    disabled={productOptionsById?.status === 1 || isSunDisabled}
                    onChange={(e) => {
                      setSunday((prev) => !prev);
                      sendValtoServer(0);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="form-group col-12 ">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={monday}
                    disabled={productOptionsById?.status === 1 || isMonDisabled}
                    onChange={(e) => {
                      setMonday((prev) => !prev);
                      sendValtoServer(1);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="form-group col-12 ">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={tuesday}
                    disabled={productOptionsById?.status === 1 || isTueDisabled}
                    onChange={(e) => {
                      setTuesday((prev) => !prev);
                      sendValtoServer(2);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="form-group col-12 ">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={wednesday}
                    disabled={productOptionsById?.status === 1 || isWedDisabled}
                    onChange={(e) => {
                      setWednesday((prev) => !prev);
                      sendValtoServer(3);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="form-group col-12 ">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={thursday}
                    disabled={productOptionsById?.status === 1 || isThuDisabled}
                    onChange={(e) => {
                      setThursday((prev) => !prev);
                      sendValtoServer(4);
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="form-group col-12 ">
                  <input
                    className="checkbox_animated"
                    type="checkbox"
                    checked={friday}
                    disabled={
                      productOptionsById?.status === 1 ||
                      productOptionsById?.status === 1 ||
                      isFriDisabled
                    }
                    onChange={(e) => {
                      setFriday((prev) => !prev);
                      sendValtoServer(5);
                    }}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductExcludeDaySection;
