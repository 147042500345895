/* eslint-disable indent */
import { errorLogger } from 'datadog/DDUtils';
// Socket param is the client.
export const socketMiddleware = (socket) => {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      /*
       * Socket middleware usage.
       * promise: (socket) => socket.emit('MESSAGE', 'hello world!')
       * type: always 'socket'
       * types: [REQUEST, SUCCESS, FAILURE]
       */
      const { promise, type, types, ...rest } = action;

      if (type !== 'socket' || !promise) {
        // Move on! Not a socket request or a badly formed one.
        return next(action);
      }

      // const [REQUEST, SUCCESS, FAILURE] = types;
      const [REQUEST, SUCCESS] = types;
      next({ ...rest, type: REQUEST });

      return promise(socket)
        .then((result) => {
          return next({ ...rest, result, type: SUCCESS });
        })
        .catch((error) => {
          errorLogger('socket middle ware', error);
          // return next({ ...rest, error, type: FAILURE });
        });
    };
};
