import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class WalletReportService {
  static getWalletBalances(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getWalletBalance, ...extraParams),
      params,
    });
  }

  static exportWalletBalances(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportWalletBalances, ...extraParams),
      params,
    });
  }

  static getWalletTransactions(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getWalletTransactions, ...extraParams),
      params,
    });
  }

  static exportWalletTransactions(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportWalletTransactions, ...extraParams),
      params,
    });
  }

  static exportCustomerLoyalityReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportCustomerLoyalityReport, ...extraParams),
      params,
      responseType: 'blob',
    });
  }
}
export default WalletReportService;
