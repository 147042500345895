import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class BrandsReportService {
  static getBrandsTotalOrders(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getTotalBrandsOrdersReport, ...extraParams),
      params,
    });
  }

  static getBrandsBasket(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getBrandBasket, ...extraParams),
      params,
    });
  }

  static getBrandsList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.getBrandsList, ...extraParams),
    });
  }
}
export default BrandsReportService;
