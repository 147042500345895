import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class CategoriesService {
  static getCategoriesData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCategoriesData, ...extraParams),
      params: data,
    });
  }

  static getSubCategoriesData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getSubCategoriesData, ...extraParams),
    });
  }

  static createNewCategory(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.createNewCategory, ...extraParams),
      data,
    });
  }

  static getCategoryOpsData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getCategoryOpsData, ...extraParams),
    });
  }

  static editCategoryOpsData(data, ...extraParams) {
    return apiGatewayService({
      method: 'Put',
      url: setEndPoint(endPoints.getCategoryOpsData, ...extraParams),
      data,
    });
  }

  static getCategoryMainData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.editCategory, ...extraParams),
    });
  }

  static editCategoryMainData(data, ...extraParams) {
    return apiGatewayService({
      method: 'Put',
      url: setEndPoint(endPoints.editCategory, ...extraParams),
      data,
    });
  }
}
export default CategoriesService;
