import React from 'react';
import UserCardsDetails from './UserCardsDetails';

const SavedCardsContent = ({ userId }) => {
  return (
    <div>
      <UserCardsDetails userId={userId} />
    </div>
  );
};

export default SavedCardsContent;
