import { toast } from 'react-toastify';
// api
import EditItemsService from '../../services/EditItemsService';

export const setEditItemsOrderDetails = (orderId) => async (dispatch) => {
  try {
    const res = await EditItemsService.gertOrderDetails(orderId);
    dispatch({
      type: 'SET_EDIT_ITEMS_ORDER_DETAILS',
      payload: res.data,
    });
  } catch (e) {
    toast.error('Something went wrong with edit items');
  }
};

export const setEditItemsPriceInfo = (orderId) => async (dispatch) => {
  try {
    const res = await EditItemsService.getOrderDetailsPriceInfo(orderId);
    dispatch({
      type: 'SET_EDIT_ITEMS_PRICE_INFO',
      payload: res.data,
    });
  } catch (e) {
    toast.error('Something went wrong with edit items');
  }
};

export const setToggleQtyModalOpen = (id, qty) => ({
  type: 'TOGGLE_EDIT_ITEM_QTY_MODAL_OPEN',
  payload: { id, qty },
});

export const setToggleCustomItemModalOpen = () => ({
  type: 'TOGGLE_CUSTOM_ITEM_MODAL_OPEN',
});
