import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageTitle from 'components/shared/PageTitle';
import { getFromLocalStorage } from 'localStorageService/LocalStorage';
import SectionLoader from 'components/common/SectionLoader';
// actions
import { setDashboardData } from 'store/dashboard/DashboardAction';
// constants
import { localStorageKeys } from 'constant/Enums';

const Dashboard = React.lazy(() => import('components/dashboard/Dashboard'));

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const opsId = getFromLocalStorage(localStorageKeys.OPS_ID);
    dispatch(setDashboardData(opsId));
    // dispatch(setCalenderData(opsId));
  }, []);

  return (
    <Fragment>
      <PageTitle title="Home" />
      <React.Suspense fallback={<SectionLoader />}>
        <Dashboard />
      </React.Suspense>
    </Fragment>
  );
};

export default HomePage;
