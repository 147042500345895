import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { convertQueryParamsIntoObject } from 'constant/Helpers';
// components
import SectionLoader from 'components/common/SectionLoader';
const CustomerServiceDataTable = React.lazy(() =>
  import('components/customerService/CustomerServiceDataTable')
);

const CustomerServicePage = () => {
  const [inputValues, setInputValues] = useState({
    searchkey: '',
    recipientKey: '',
    page: 1,
  });
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      setInputValues(queryObj);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'GET_CUSTOMERSERVICE_DATA',
        payload: [],
      });
    };
  }, []);

  return (
    <div>
      <React.Suspense fallback={<SectionLoader />}>
        <CustomerServiceDataTable inputValues={inputValues} setInputValues={setInputValues} />
      </React.Suspense>
    </div>
  );
};

export default CustomerServicePage;
