import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// selectors
import { getSelectedSubAreaData } from 'store/delivery/deliveryAreas/areasSelectors';
// action
import { getSubAreaById } from 'store/delivery/deliveryAreas/areasActions';
// components
import EditSubArea from 'components/delivery/areas/subAreas/EditSubArea';
import SectionLoader from 'components/common/SectionLoader';

const EditSubAreaPage = () => {
  const selectedSubAreaData = useSelector((state) => getSelectedSubAreaData({ state }));
  const dispatch = useDispatch();
  const params = useParams();

  const fetchData = async () => {
    await dispatch(getSubAreaById(parseInt(params?.id, 10), parseInt(params?.subId, 10)));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {!selectedSubAreaData ? (
        <SectionLoader height="300px" />
      ) : (
        <EditSubArea selectedSubAreaData={selectedSubAreaData} type={'subarea'} />
      )}
    </div>
  );
};

export default EditSubAreaPage;
