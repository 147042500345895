import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommentSvg from './CommentSvg';
import { convertUtcToLocalTime } from 'constant/Helpers';
// actions
import { setTicketCommentsList } from 'store/ticketing/TicketingActions';
// selectors
import { getTicketCommentsList } from 'store/ticketing/TicketingSelectors';

const TicketCommentsList = ({ ticketId, redirectToUserPage }) => {
  const commentsList = useSelector((state) => getTicketCommentsList({ state }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTicketCommentsList(ticketId));
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_TICKET_COMMENTS_LIST',
        payload: [],
      });
    };
  }, []);

  return (
    <div className="comment-container">
      {commentsList.length > 0 &&
        commentsList.map((item, i) => (
          <div key={i} className="col-sm-12 xl-60">
            <div className="card comment-wrapper">
              <div className="card-header">
                {item.createdDate && (
                  <div className="media-body comment-header">
                    <div className="comment-by-wrapper">
                      <CommentSvg />
                      <p className="comment-by">
                        By: {redirectToUserPage(item.commentFromName, item.commentFrom)}
                      </p>
                    </div>

                    <p className="comment-date">
                      Date:
                      {convertUtcToLocalTime(item.createdDate)}
                    </p>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div className="media">
                  <div className="media-body comment-content-wrapper">
                    <p className="comment-content">{item.comment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TicketCommentsList;
