import { updateObject } from '../utility';

const initialState = {
  floristsCommunityList: [],
  pageSize: '',
  currentPage: '',
  rowCount: '',
  floristsCommunityDesign: {},
  floristsCommunityUser: {},
  floristsCommunityActioned: {},
  floristsCommunityTransaction: {},
  floristsCommunityComments: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FLORIST_COMMUNITY_LIST': {
      return updateObject(state, {
        floristsCommunityList: action.payload.data,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        rowCount: action.payload.rowCount,
      });
    }
    case 'GET_FLORIST_COMMUNITY_DESIGN': {
      return updateObject(state, {
        floristsCommunityDesign: action.payload,
      });
    }
    case 'SET_FLORIST_COMMUNITY_DESIGN': {
      return updateObject(state, {
        floristsCommunityDesign: action.payload,
      });
    }
    case 'GET_FLORIST_COMMUNITY_USER': {
      return updateObject(state, {
        floristsCommunityUser: action.payload,
      });
    }
    case 'GET_FLORIST_COMMUNITY_ACTIONED': {
      return updateObject(state, {
        floristsCommunityActioned: action.payload,
      });
    }
    case 'GET_FLORIST_COMMUNITY_TRANSACTIONS': {
      return updateObject(state, {
        floristsCommunityTransactionList: action.payload.data,
        transactionPageSize: action.payload.pageSize,
        transactionCurrentPage: action.payload.currentPage,
        transactionRowCount: action.payload.rowCount,
      });
    }
    case 'SET_FLORIST_COMMUNITY_TRANSACTIONS_DETAILS_DATA': {
      return updateObject(state, {
        floristsCommunityTransaction: action.payload,
      });
    }
    case 'GET_FLORIST_COMMUNITY_COMMENTS': {
      return updateObject(state, {
        floristsCommunityComments: action.payload,
      });
    }
    case 'SET_FLORIST_COMMUNITY_COMMENTS': {
      return updateObject(state, {
        floristsCommunityComments: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
