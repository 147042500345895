import React, { useState } from 'react';
import CreateAttributeModal from './modals/CreateAttributeModal';
import RestrictedSection from 'components/shared/RestrictedSection';

const AttributesActions = ({ type, inputValues, parentName }) => {
  const [isAddAttributeModalOpen, setIsAddAttributeModalOpen] = useState(false);

  return (
    <>
      {isAddAttributeModalOpen && (
        <CreateAttributeModal
          isAddAttributeModalOpen={isAddAttributeModalOpen}
          setIsAddAttributeModalOpen={setIsAddAttributeModalOpen}
          type={type}
          inputValues={inputValues}
          parentName={parentName}
        />
      )}
      <div className="card">
        <div className="card-body btn-showcase">
          <RestrictedSection
            page="attributes"
            action={type === 'parent' ? 'create_attribute' : 'create_subattribute'}
          >
            <button
              onClick={() => setIsAddAttributeModalOpen((prev) => !prev)}
              type="button"
              className="btn btn-success"
            >
              <i className="fa fa-plus"></i> Create{' '}
              {type === 'parent' ? 'Attribute' : 'Sub Attribute'}
            </button>
          </RestrictedSection>
        </div>
      </div>
    </>
  );
};

export default AttributesActions;
