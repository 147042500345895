import { updateObject } from '../utility';

const initialState = {
  productsData: [],
  pageSize: '',
  currentPage: '',
  rowCount: '',
  productCategories: [],
  productAllCategoriesData: [],
  productMainCategoriesData: [],
  productExcludedSlots: [],
  productExcludedSlotsById: [],
  productAttributesById: [],
  productCategoriesById: [],
  productMCById: [],
  productArrtibutesFilterData: [],
  productDetailsById: {},
  productOptionsById: {},
  productAvailabilityById: {},
  productInventoryById: [],
  productMediaById: {},
  productMasterAvailabilityById: [],
  productMasterInventoryById: [],
  productGroups: [],
  productExcludedDays: [
    {
      Sunday: { isExcluded: false, id: 0 },
      Monday: { isExcluded: false, id: 0 },
      Tuesday: { isExcluded: false, id: 0 },
      Wednesday: { isExcluded: false, id: 0 },
      Thursday: { isExcluded: false, id: 0 },
      Friday: { isExcluded: false, id: 0 },
      Saturday: { isExcluded: false, id: 0 },
    },
  ],
  isSyncedProduct: true,
  productSizes: [],
  selectedProductSize: {},
  isFetchingProductSizes: false,
  productLabels: [],
  selectedProductLabel: [],
  // subcategories
  everydayOccasionsSubList: [],
  emotionsSubList: [],
  specialOccasionsSubList: [],
  festivalsSubList: [],
  costQtySum: '',
  productRanking: [],
  isValidating: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_CATEGORIES_FILTER': {
      return updateObject(state, { productCategories: action.payload });
    }

    case 'GET_PRODUCT_ALL_CATEGORIES_DATA': {
      return updateObject(state, { productAllCategoriesData: action.payload });
    }

    case 'GET_PRODUCT_MAIN_CATEGORIES': {
      return updateObject(state, { productMainCategoriesData: action.payload });
    }

    case 'GET_PRODUCT_EXCLUDED_SLOTS_DATA': {
      return updateObject(state, { productExcludedSlots: action.payload });
    }

    case 'GET_PRODUCT_EXCLUDED_SLOTS_BY_ID': {
      return updateObject(state, { productExcludedSlotsById: action.payload });
    }

    case 'GET_PRODUCT_CATEGORIES_BY_ID': {
      return updateObject(state, { productCategoriesById: action.payload });
    }

    case 'GET_PRODUCT_MC_BY_ID': {
      return updateObject(state, { productMCById: action.payload });
    }

    case 'GET_PRODUCT_ATTRIBUTES_DATA': {
      return updateObject(state, { productArrtibutesFilterData: action.payload });
    }

    case 'GET_PRODUCT_ATTRIBUTES_BY_ID': {
      return updateObject(state, { productAttributesById: action.payload });
    }

    case 'PRODUCT_DETAILS_BY_ID': {
      return updateObject(state, { productDetailsById: action.payload });
    }

    case 'PRODUCT_OPTIONS_BY_ID': {
      return updateObject(state, { productOptionsById: action.payload });
    }

    case 'PRODUCT_LABEL_LIST': {
      return updateObject(state, { productLabels: action.payload });
    }

    case 'SET_PRODUCT_LABEL': {
      return updateObject(state, { selectedProductLabel: action.payload });
    }

    case 'FETCHING_PRODUCT_LABEL': {
      return updateObject(state, { isFetchingLabel: action.payload });
    }

    case 'UPDATE_PRODUCT_LABEL': {
      return updateObject(state, { selectedProductLabel: action.payload });
    }

    case 'ADD_PRODUCT_LABEL': {
      return updateObject(state, { selectedProductLabel: action.payload });
    }

    case 'DELETE_PRODUCT_LABEL': {
      return updateObject(state, { selectedProductLabel: action.payload });
    }

    case 'PRODUCT_AVAILABILITY_BY_ID': {
      return updateObject(state, { productAvailabilityById: action.payload });
    }

    case 'PRODUCT_INVENTORY_BY_ID': {
      return updateObject(state, { productInventoryById: action.payload });
    }

    case 'PRODUCT_MEDIA_BY_ID': {
      return updateObject(state, { productMediaById: action.payload });
    }

    case 'PRODUCT_MASTER_AVAILABILITY_BY_ID': {
      return updateObject(state, { productMasterAvailabilityById: action.payload });
    }

    case 'PRODUCT_MASTER_INVENTORY_BY_ID': {
      return updateObject(state, { productMasterInventoryById: action.payload });
    }
    case 'PRODUCT_GROUPS_BY_ID': {
      return updateObject(state, { productGroups: action.payload });
    }

    case 'PRODUCT_EXCLUDED_DAYS_BY_ID': {
      return updateObject(state, { productExcludedDays: action.payload });
    }

    case 'PRODUCT_RANKING_BY_ID': {
      return updateObject(state, { productRanking: action.payload });
    }

    case 'SET_IS_EDIT_PRODUCT_SYNCED_FALSE': {
      return updateObject(state, { isSyncedProduct: false });
    }

    case 'SET_IS_EDIT_PRODUCT_SYNCED_TRUE': {
      return updateObject(state, { isSyncedProduct: true });
    }

    case 'GET_PRODUCT_SIZES': {
      return updateObject(state, { productSizes: action.payload });
    }

    case 'SET_SELECTED_PRODUCT_SIZE': {
      return updateObject(state, { selectedProductSize: action.payload });
    }

    case 'FETCHING_PRODUCT_SIZES': {
      return updateObject(state, { isFetchingProductSizes: action.payload });
    }

    case 'FETCHED_PRODUCT_SIZES': {
      return updateObject(state, { isFetchingProductSizes: action.payload });
    }

    // sub-categories
    case 'SET_EVERYDAY_SUB_CATEGORIES': {
      return updateObject(state, { everydayOccasionsSubList: action.payload });
    }
    case 'SET_EMOTIONS_SUB_CATEGORIES': {
      return updateObject(state, { emotionsSubList: action.payload });
    }
    case 'SET_SPECIAL_OCCASIONS_SUB_CATEGORIES': {
      return updateObject(state, { specialOccasionsSubList: action.payload });
    }
    case 'SET_FESTIVALS_SUB_CATEGORIES': {
      return updateObject(state, { festivalsSubList: action.payload });
    }
    case 'SET_COST_QTY_SUM': {
      return updateObject(state, { costQtySum: action.payload });
    }
    case 'VALIDATING_PRODUCT': {
      return updateObject(state, { isValidating: true });
    }
    case 'FINISHED_VALIDATING_PRODUCT': {
      return updateObject(state, { isValidating: false });
    }

    default:
      return state;
  }
};

export default reducer;
