import { updateObject } from '../utility';
// type
import {
  GET_TOTAL_ATTRIBUTES_DATA,
  GET_SUB_ATTRIBUTES,
  GET_ATTRIBUTE_BY_ID,
} from './AttributesActionTypes';

const initialState = {
  attributesData: [],
  pageSize: '',
  currentPage: '',
  rowCount: '',
  subAttributes: [],
  attributeById: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_ATTRIBUTES_DATA: {
      return updateObject(state, {
        attributesData: action.payload,
        pageSize: action.payload.pageSize,
        currentPage: action.payload.currentPage,
        rowCount: action.payload.rowCount,
      });
    }
    case GET_SUB_ATTRIBUTES: {
      return updateObject(state, {
        subAttributes: action.payload,
      });
    }
    case GET_ATTRIBUTE_BY_ID: {
      return updateObject(state, {
        attributeById: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
