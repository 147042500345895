import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import AreasDataGrid from './AreasDataGrid';
import AreasActions from './AreasActions';
import { convertQueryParamsIntoObject, convertObjectIntoQueryParams } from 'constant/Helpers';
import cloneDeep from 'lodash/cloneDeep';
// pagination
import DJHPagination from 'components/shared/Pagination';
// actions
import { getAllAreas } from 'store/delivery/deliveryAreas/areasActions';
// selectors
import { getAllDeliveryAreasData } from 'store/delivery/deliveryAreas/areasSelectors';

const AreasDataTable = ({ inputValues, setInputValues }) => {
  const areasData = useSelector((state) => getAllDeliveryAreasData({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (params) => {
    const queryParams = params ?? inputValues;
    const queryString = convertObjectIntoQueryParams(queryParams);

    navigate({
      pathname: '/delivery/areas',
      search: queryString,
    });
    dispatch(getAllAreas(queryParams));
  };

  const onPageChange = async (i) => {
    const updatedParams = cloneDeep(inputValues);
    updatedParams.page = i;
    setInputValues(updatedParams);
    handleSubmit(updatedParams);
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      dispatch(getAllAreas(queryObj));
    } else {
      dispatch(getAllAreas(inputValues));
    }
  }, []);

  return (
    <div className="quick-actions-table toolbar-table-style">
      <AreasActions type="area" inputValues={inputValues} />
      <AreasDataGrid data={areasData.Data} inputValues={inputValues} />
      {+areasData.RowCount > +areasData.PageSize && (
        <DJHPagination
          onPageChange={onPageChange}
          currentPage={+areasData.CurrentPage}
          pageSize={+areasData.PageSize}
          itemsCount={+areasData.RowCount}
        />
      )}
    </div>
  );
};

export default AreasDataTable;
