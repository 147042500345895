import { apiGatewayService } from './../apiService/HttpService';
import { setEndPoint } from './../apiService/SetEndPoint';
import { reportsEndPoints } from './../apiService/ReportsEndPoints';

class ProductsReportService {
  static getPriceBreakDown(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.priceBreakDown, ...extraParams),
      params,
    });
  }

  static getProductBreakDown(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.productBreakDown, ...extraParams),
      params,
    });
  }

  static getCategoryBreakDown(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.CategoryBreakDown, ...extraParams),
      params,
    });
  }

  static getProductTypeSales(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.productTypeSales, ...extraParams),
      params,
    });
  }

  static getProductTypeAttribute(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.ProductTypeAttribute, ...extraParams),
      params,
    });
  }

  static exportGetProductTypeAttribute(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportProductTypeAttribute, ...extraParams),
      params,
    });
  }

  static getLiveProductsReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.liveProductsReport, ...extraParams),
      params,
    });
  }

  static exportLiveProductsReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportLiveProductsReport, ...extraParams),
      params,
    });
  }

  static getCartProductDiscount(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.cartProductDiscount, ...extraParams),
      params,
    });
  }

  static getProductTypeBreakDown(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.productTypeBreakDown, ...extraParams),
      params,
    });
  }

  static getProductCategoryPercantage(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.productCategoryPercentage, ...extraParams),
      params,
    });
  }

  static getOrdersProductWise(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.ordersProductWise, ...extraParams),
      params,
    });
  }

  static getTopTenProductsByType(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.topTenProductsByType, ...extraParams),
      params,
    });
  }

  static getOldProductsReport(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.oldProducts, ...extraParams),
      params,
    });
  }

  static exportOldProductsReport(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(reportsEndPoints.exportOldProducts, ...extraParams),
    });
  }
}
export default ProductsReportService;
